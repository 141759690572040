import React, { useEffect, useState } from 'react'
import { Box, Typography, Stack } from '@mui/material'
import { connect } from 'react-redux';
import * as scaleActions from '../../store/actions/scale';
import { i18n } from '../../translate/i18n';

import "dayjs/locale/pt-br";
import 'dayjs/locale/es';
import dayjs from 'dayjs';
import OMS from '../../components/Costs/OMS';
import { FCDatePicker, FCSelect } from 'components-fullcomm';

const Costs = (props) => {
    const date = new Date();
    const monthAux = date.getMonth() + 1;
    const yearAux = date.getFullYear();
    const [month, setMonth] = useState(monthAux);
    const [year, setYear] = useState(yearAux);
    const [app, setApp] = useState('oms');

    useEffect(() => {
        props.getCostsOms({ month, year });
    }, [month, year])
    return (
        <Box padding="1rem 1.5rem">
            <Box display='flex' justifyContent='space-between'>
                <Typography variant='h4' mb="1rem">{i18n.t('components.sideBar.admin.costs')}</Typography>
            </Box>
            <Stack direction="row" spacing={2} style={{ marginBottom: '10px' }}>
                <FCDatePicker
                    minDate={dayjs("01/01/2023")}
                    maxDate={dayjs("01/01/2040")}
                    views={['month', 'year']}
                    label={'Data'}
                    onChange={(date) => {
                        setMonth(date.$M + 1)
                        setYear(date.$y)
                    }}
                    value={dayjs(new Date(`${year}-${month}-01`))}
                />
                <FCSelect
                    value={app}
                    onChange={(e, v) => setApp(e.target.value)}
                    label="APPS"
                    options={[
                        { value: 'oms', label: 'OMS FullComm' },
                        // { value: 'frete', label: 'Cálculo de Frete' }
                    ]}
                />
            </Stack>
            {(() => {
                switch (app) {
                    case 'oms':
                        return <OMS month={month} year={year} />;
                    case 'frete':
                        return 'Sem dados';
                    default:
                        return null; // Ou algum componente padrão se nenhum caso corresponder
                }
            })()}
        </Box>
    )
}

const mapStateToProps = (state) => ({
    costs: state.scale.costsOms,
})

export default connect(mapStateToProps, scaleActions)(Costs)