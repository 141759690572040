import React, { useEffect } from "react";
import { Box } from "@mui/material";
import ChatConversation from "./ChatConversation";

const ChatBody = ({
  messages,
  user,
  loading,
  divRef,
  setLoading,
  loadMoreMessages,
  activeConversation,
  oldMessages,
  loadMoreMessagesNew,
  newMessages,
  getNoReadMessages,
  setThread,
}) => {

  useEffect(() => {
    if (getNoReadMessages) getNoReadMessages();
  }, [activeConversation]);

  const handleScroll = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;
    if (scrollTop === 0) {
      loadMoreMessages();
    } else if (scrollTop + clientHeight === scrollHeight) {
      loadMoreMessagesNew();
    }
  };

  return (
    <Box
      id="chat-container"
      sx={{ flexGrow: 1, overflowY: "auto", wordBreak: "break-word" }}
      onScroll={handleScroll}
    >
      {
        activeConversation !== null && (
          <div ref={divRef}>
            <ChatConversation
              loading={loading}
              setLoading={setLoading}
              conversation={messages}
              activeConversation={activeConversation}
              user={user}
              oldMessages={oldMessages}
              newMessages={newMessages}
              setThread={setThread}
            />
          </div>
        )
      }
    </Box>
  );
};

export default ChatBody;
