import React, { useState, useEffect } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { TextField, Typography } from '@mui/material';
import { i18n } from '../../translate/i18n';


function KanbanBoard({ itemsFromBackend, onChange, onChangeDate }) {
  const [columns, setColumns] = useState({});
  useEffect(() => {
    const columnsFromBackend = {
      'backlog': {
        name: "Para fazer",
        items: itemsFromBackend.filter(elem => elem.status === 'backlog'),
        status: 'backlog'
      },
      'in_progress': {
        name: "Em progresso",
        items: itemsFromBackend.filter(elem => elem.status === 'in_progress'),
      },
      'concluded': {
        name: "Finalizado",
        items: itemsFromBackend.filter(elem => elem.status === 'concluded'),
      }
    };
    setColumns(columnsFromBackend);
  }, [itemsFromBackend])

  const onDragEnd = (result, columns, setColumns) => {
    if (!result.destination) return;
    const { source, destination } = result;

    if (source.droppableId !== destination.droppableId) {
      const sourceColumn = columns[source.droppableId];
      const destColumn = columns[destination.droppableId];
      const sourceItems = [...sourceColumn.items];
      const destItems = [...destColumn.items];
      const [removed] = sourceItems.splice(source.index, 1);
      onChange(destination.droppableId, removed._id);
      destItems.splice(destination.index, 0, removed);
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...sourceColumn,
          items: sourceItems
        },
        [destination.droppableId]: {
          ...destColumn,
          items: destItems
        }
      });
    } else {
      const column = columns[source.droppableId];
      const copiedItems = [...column.items];
      const [removed] = copiedItems.splice(source.index, 1);
      copiedItems.splice(destination.index, 0, removed);
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...column,
          items: copiedItems
        }
      });
    }
  };

  const handleStatus = (status) => {
    switch (status) {
      case "Para fazer": return i18n.t('components.kanbanBoard.toDo');
      case "Em progresso": return i18n.t('components.kanbanBoard.inProgress');
      case "Finalizado": return i18n.t('components.kanbanBoard.done');
      default: return status;
    }
  }

  return (
    <div style={{ display: "flex", justifyContent: "center", height: "100%", marginTop: '30px' }}>
      <DragDropContext
        onDragEnd={result => onDragEnd(result, columns, setColumns)}
      >
        {Object.entries(columns).map(([columnId, column], index) => {
          return (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center"
              }}
              key={columnId}
            >
              <Typography variant="h1">{column.name}</Typography>
              <div style={{ margin: 8 }}>
                <Droppable droppableId={columnId} key={columnId}>
                  {(provided, snapshot) => {
                    return (
                      <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={{
                          background: snapshot.isDraggingOver
                            ? "lightblue"
                            : "lightgrey",
                          padding: 4,
                          width: 250,
                          minHeight: 500
                        }}
                      >
                        {column.items.map((item, index) => {
                          return (
                            <Draggable
                              key={item._id}
                              draggableId={item._id}
                              index={index}
                            >
                              {(provided, snapshot) => {
                                return (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={{
                                      userSelect: "none",
                                      padding: 16,
                                      margin: "0 0 8px 0",
                                      minHeight: "50px",
                                      backgroundColor: snapshot.isDragging
                                        ? "#1e2835"
                                        : "#333",
                                      color: "white",
                                      ...provided.draggableProps.style
                                    }}
                                  >
                                    {item.task.name}
                                    <TextField
                                      InputLabelProps={{ shrink: true }}
                                      InputProps={{ inputProps: { min: new Date().toISOString().split("T")[0] } }}
                                      type="date"
                                      color="secondary"
                                      onChange={(e) => onChangeDate(item._id, e.target.value)}
                                      fullWidth
                                      defaultValue={item.deliveryDate?.substring(0, 10) || ""}
                                      className="fieldDate"
                                      label={i18n.t('components.kanbanBoard.deliveryForecast')} />
                                  </div>
                                );
                              }}
                            </Draggable>
                          );
                        })}
                        {provided.placeholder}
                      </div>
                    );
                  }}
                </Droppable>
              </div>
            </div>
          );
        })}
      </DragDropContext>
    </div>
  );
}

export default KanbanBoard;
