import { useAuth0 } from '@auth0/auth0-react';
import { CircularProgress } from '@mui/material';
import React, { useEffect } from 'react'

const Register = () => {
  const { loginWithRedirect } = useAuth0();

  useEffect(() => {
    loginWithRedirect({
      authorizationParams: {
        screen_hint: "signup",
      },
    });
  }, [])

  return (
    <CircularProgress sx={{position: 'absolute', top: '50%', left: '50%'}}/> 
  )
}

export default Register