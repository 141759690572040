import { Box } from "@mui/system";
import React from "react";
import CreditBalanceModal from "./CreditBalanceModal";
import CycleSelector from "./CycleSelector";
import HealthScore from "./HealthScore";

const HeaderButton = () => {
  return (
    <Box display={"flex"} alignItems={"center"} justifyContent={"end"} gap={2}>
      <CycleSelector />
      <CreditBalanceModal platform="sprint" />
      <HealthScore />
    </Box>
  );
};

export default HeaderButton;
