import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { getPermissionModels, getPermissions } from '../../store/actions/permissions';
import { getViewUser, handleUpdateUser } from '../../store/actions/users';
import { userPermissionCheck } from '../Sprint/utils/userPermissionCheck';
import { toast } from 'react-toastify';
import {
    Button,
    Tab,
    Box,
    FormGroup,
    Typography,
    CardContent,
} from '@mui/material';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { i18n } from '../../translate/i18n';
import { FCCard, FCCheckbox, FCReturnButton, FCSelect, FCTabs } from 'components-fullcomm';

const AdminPermissions = (props) => {
    const { id } = useParams();
    const [userPermissions, setUserPermissions] = useState([]);
    const [tabValue, setTabValue] = useState(0);
    const [selectedModel, setSelectedModel] = useState('');
    const history = useHistory();

    const handleRedirect = () => {
        history.push('/permissions');
    };


    useEffect(() => {
        props.getPermissions();
        props.getPermissionsModels();
        props.getViewUser(id);
    }, []);
    useEffect(() => {
        if (props.user) {
            setUserPermissions(props.user?.permissionAdmin);
            setSelectedModel(props.permissionModels.find(e => e._id === props.user?.permissionModel));
        }
    }, [props.user]);

    const handleCheckbox = (actionId, event) => {
        if (event.target.checked) {
            setUserPermissions([...userPermissions, actionId]);
        } else {
            setUserPermissions(userPermissions.filter(id => id !== actionId));
        }
    };


    const savePermissions = async () => {
        const permissionsData = {
            permissionModel: selectedModel?._id || null,
            _id: id,
            permissionAdmin: userPermissions,
        };
        try {
            const response = await props.handleUpdateUser(permissionsData);
            if (response.error) {
                switch (response.error.message) {
                    case "Usuário não encontrado.": return toast.success(i18n.t('permission.admin.userNotFound'));
                    case "E-mail já cadastrado.": return toast.success(i18n.t('permission.admin.emailAlreadyExist'));
                    case "Ocorreu um erro ao atualizar o usuário.": return toast.success(i18n.t('permission.admin.updateError'));
                    case "Erro interno do servidor.": return toast.success(i18n.t('permission.admin.internalError'));
                    default: return toast.success(response.error.message);
                }
            } else {
                if (response.message) {
                    toast.success(i18n.t('permission.admin.updateSuccess'));
                } else {
                    toast.success(response.message)
                }
            }

        } catch (error) {
            if (error.error.message === "Erro interno do servidor.") {
                toast.error(i18n.t('permission.admin.internalError'));
            } else {
                toast.error(error.error.message);
            }

        }

    };

    const handleChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const renderPermissions = (parent) => {
        const parentPermissions = props.permissions.filter((permission) => permission.parent === parent);

        return (
            <Box mt={'1rem'}>
                {parentPermissions.map((permission) => (
                    <FCCard key={permission._id} sx={{ marginBottom: '16px' }}>
                        <CardContent>
                            <Typography variant="subtitle2" component="div">
                                {permission.name}
                            </Typography>
                            <FormGroup row>
                                {permission.actions.map((action) => (
                                    <FCCheckbox
                                        key={action.id}
                                        disabled={selectedModel?.actions?.length > 0}
                                        checked={userPermissions?.includes(action.id) || false}
                                        onChange={(event) => handleCheckbox(action.id, event)}
                                        label={action.name}
                                    />
                                ))}
                            </FormGroup>
                        </CardContent>
                    </FCCard>
                ))}
            </Box>
        );
    };
    const handleModelChange = (model) => {
        setSelectedModel(model);
        if (model) {
            setUserPermissions(model.actions);
        } else {
            setUserPermissions([]);
        }
    };


    return (
        <div className="p-4">
            <Box display="flex" justifyContent={"space-between"} mb='1rem'>
                 <div style={{ display: 'flex', alignItems: 'center'}}>
                    <FCReturnButton margin={"0rem"}/>
                    <Typography variant="h5">{i18n.t('permission.admin.permissions')}</Typography>
                </div>                {
                    userPermissionCheck(props.auth, ["create_permission", "admin"]) && (
                        <Button className='btn-action medium' onClick={handleRedirect}>{i18n.t('permission.admin.edit')}</Button>
                    )
                }
            </Box>
            <FCSelect
                fullWidth
                label={i18n.t('permission.admin.permissionsModel')}
                sx={{ marginBottom: '1rem' }}
                value={selectedModel}
                onChange={e => handleModelChange(e.target.value)}
                options={[{ value: '', label: i18n.t('permission.admin.none') }, ...(props.permissionModels?.map(model => ({ value: model, label: model.name })) || [])]}
            />
            <FCTabs value={tabValue} onChange={handleChange}>
                <Tab label={i18n.t('permission.admin.general')} />
                <Tab label={i18n.t('permission.admin.implantation')} />
                <Tab label={i18n.t('permission.admin.performance')} />
                <Tab label={i18n.t('permission.admin.support')} />
                <Tab label={i18n.t('permission.admin.operation')} />
            </FCTabs>
            {tabValue === 0 && renderPermissions('geral')}
            {tabValue === 1 && renderPermissions('start')}
            {tabValue === 2 && renderPermissions('sprint')}
            {tabValue === 3 && renderPermissions('suporte')}
            {tabValue === 4 && renderPermissions('scale')}
            <Button className='btn-action large' sx={{ float: 'right', mb: '5rem' }} variant='contained' onClick={savePermissions}>{i18n.t('permission.savePermissions')}</Button>
        </div>
    );
};

const mapStateToProps = (state) => ({
    auth: state.auth.user,
    permissions: state.permission?.permissions?.filter(elem => elem.typeUser === 'admin') || [],
    user: state.user?.userDetails || {},
    permissionModels: state.permission?.permissionModels || [],
});

const mapDispatchToProps = (dispatch) => {
    return {
        getPermissionsModels: () => dispatch(getPermissionModels()),
        getPermissions: () => dispatch(getPermissions()),
        getViewUser: (props) => dispatch(getViewUser(props)),
        handleUpdateUser: (props) => dispatch(handleUpdateUser(props))
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(AdminPermissions);
