const messages = {
    pt: {
        translations: {
            documents: {
                index: {
                    title: "Documentos",
                    name: "Nome",
                    date: "Data",
                    type: "Tipo",
                    new: "Novo",
                    search: "Pesquisar",
                    edit: "Editar",
                    delete: "Excluir",
                    deleteDocument: "Excluir documento",
                    confirmDelete: "Tem certeza de que deseja excluir o documento?",
                    cancel: "Cancelar"
                },
                form: {
                    title1: "Registrar documento",
                    title2: "Editar documento",
                    errorName: "Nome é obrigatório.",
                    errorUrl: "Url é obrigatório.",
                    errorDate: "Data é obrigatória.",
                    errorType: "Tipo é obrigatório.",
                    name: "Nome",
                    type: "Tipo",
                    date: "Data",
                    save: "Salvar",
                    all: "Todos",
                    meetingNote: "Nota de reunião",
                    projectRequirements: "Requisitos de projeto",
                    operationalRequirements: "Requisitos operacionais",
                    report: "Relatório",
                    other: "Outros",
                    editSuccess: "Documento editado com sucesso!",
                    saveSuccess: "Documento salvo com sucesso!",
                    deleteSuccess: "Documento excluído com sucesso!",
                    internalError: "Erro interno do servidor.",
                    invalidData: "Dados inválidos.",
                    documentNotExist: "Documento não encontrado!",
                }
            },
            addCustomer: {
                active: "Ativo",
                inactive: "Inativo",
                nameRequired: "Nome é obrigatório.",
                urlValid: "Insira uma URL válida. Exemplo: https://www.checkstore.com.br.",
                urlRequired: "URL da loja é obrigatória.",
                invalidData: "Dados inválidos.",
                registerError: "Cliente não foi cadastrado com sucesso!",
                registerSuccess: "Cliente cadastrado com sucesso!",
                internalError: "Erro interno do servidor.",
                customerNotFound: "Cliente não encontrado.",
                updateError: "Ocorreu um erro ao atualizar cliente.",
                updateSuccess: "Cliente atualizado com sucesso!",
                edit: "Editar",
                register: "Cadastrar",
                store: "Loja",
                imageAllowed: "Permitido",
                name: "Nome",
                urlStore: "URL da loja",
                platformFullcomm: "Plataforma",
                plats: "Plataformas",
                implantation: "Implantação",
                performance: "Performance",
                operation: "Operação",
                support: "Suporte",
                platformEcommerce: "Plataforma de E-commerce",
                platEcom: "Plataformas E-commerce",
                other: "Outra",
                agency: "Agência",
                urlSales: "URL Dashboard Resume de Vendas",
                urlTraffic: "URL Dashboard Tráfego Pago",
                urlCRM: "URL Dashboard CRM",
                keyJira: "Chave do projeto do Jira",
                idGa: "ID GA",
                idFacebook: "ID Facebook Pixel",
                keyActive: "Chave API Active Campaign",
                idUptime: "Uptime status ID",
                platformDomain: "Domínio da plataforma",
                save: "Salvar",
                cancel: "Cancelar"
            },
            addTrialUser: {
                nameRequired: "Nome é obrigatório.",
                emailValid: "Informe um e-mail válido.",
                emailRequired: "E-mail é obrigatório.",
                phoneRequired: "Telefone é obrigatório.",
                passwordRequired: "Senha é obrigatória.",
                passwordRule: "A senha deve conter 8 caracteres, número e letra maiúscula.",
                passwordEqual: "As senhas devem ser iguais.",
                passwordConfirm: "É necessário confirmar a senha.",
                invalidData: "Dados inválidos.",
                alreadyRegistered: "Este e-mail já esta cadastrado.",
                registerError: "Usuário não foi cadastrado com sucesso!",
                registerSuccess: "Usuário cadastrado com sucesso!",
                completeName: "Nome completo",
                email: "E-mail",
                phone: "Telefone",
                password: "Senha",
                passwordConfirmShort: "Confirmar senha",
                createAccount: "Criar conta",
                createVia: "Criar via:",
                hasAccount: "Já possui conta? Faça login",
            },
            addUser: {
                nameRequired: "Nome é obrigatório.",
                emailValid: "Informe um e-mail válido.",
                emailRequired: "E-mail é obrigatório.",
                invalidData: "Dados inválidos.",
                alreadyRegistered: "Este e-mail já esta cadastrado.",
                registerError: "Usuário não foi cadastrado com sucesso!",
                registerSuccess: "Usuário cadastrado com sucesso!",
                userNotFound: "Usuário não encontrado.",
                alreadyRegistered2: "E-mail já cadastrado.",
                errorUpdate: "Ocorreu um erro ao atualizar usuário.",
                updateSuccess: "Usuário atualizado com sucesso!",
                internalError: "Erro interno do servidor.",
                edit: "Editar",
                register: "Cadastrar",
                user: "Usuário",
                name: "Nome",
                email: "E-mail",
                role: "Função",
                stores: "Lojas",
                agency: "Agência",
                permissions: "Permissões",
                accessStores: "Acessar todas as lojas",
                save: "Salvar",
                cancel: "Cancelar"
            },
            agency: {
                form: {
                    socialRequired: 'Razão social é obrigatória.',
                    cnpjRequired: 'CNPJ é obrigatório.',
                    clientIdRequired: 'ID do cliente é obrigatório.',
                    nameClientRequired: 'Nome do cliente é obrigatório.',
                    storeRequired: 'Loja é obrigatória.',
                    monthlyCreditRequired: 'Crédito mensal é obrigatório.',
                    initDateRequired: 'Data de início é obrigatória.',
                    invalidEmail: 'E-mail inválido.',
                    theStores: 'As lojas',
                    haveAgency: 'já possuem agência!',
                    theStore: 'A loja',
                    haveAgency2: 'já possui agência!',
                    registerError: 'Erro ao cadastrar agência!',
                    registerSuccess: 'Agência cadastrada com sucesso!',
                    internalError: 'Erro interno do servidor.',
                    agencyNotFound: 'Agência não encontrada.',
                    errorUpdateCustomer: 'Ocorreu um erro ao atualizar os clientes associados à agência.',
                    updateSuccess: 'Agência atualizada com sucesso!',
                    edit: 'Editar',
                    register: 'Cadastrar',
                    agency: 'Agência',
                    social: 'Razão social',
                    cnpj: 'CNPJ',
                    assocStores: 'Associar lojas',
                    monthlyCredits: 'Créditos mensais',
                    initDate: 'Data início',
                    financContacts: 'Contatos do financeiro',
                    name: 'Nome',
                    email: 'E-mail',
                    phone: 'Telefone',
                    respContact: 'Contatos do responsável',
                    save: 'Salvar',
                    cancel: 'Cancelar'
                },
                list: {
                    corporateName: 'Razão social',
                    endContract: 'Encerrar contrato com sucesso!',
                    agencies: 'Agências',
                    register: 'Cadastrar',
                    socialName: 'Razão social',
                    stores: 'Lojas',
                    cnpj: 'CNPJ',
                    status: 'Status',
                    active: 'Ativo',
                    finished: 'Finalizado',
                    edit: 'Editar',
                    end: 'Encerrar',
                    users: 'Usuários',
                    activities: 'Atividades'
                },
                specific: {
                    userNotExist: 'Usuário não existe!',
                    deleteError: "Erro ao excluir!",
                    deleteSuccess: "Usuário apagado com sucesso!",
                    internalError: "Erro interno do servidor.",
                    stores: 'Lojas',
                    register: 'Cadastrar',
                    id: 'ID',
                    name: 'Nome',
                    email: 'E-mail',
                    edit: 'Editar',
                    delete: 'Excluir',
                    deleteUser: 'Apagar usuário',
                    confirmDelete: 'Você tem certeza que deseja apagar o usuário?',
                    cancel: 'Cancelar'
                }
            },
            aiContent: {
                images: {
                    errorSize: "Coloque valor em apenas um item, altura ou largura",
                    internalError: "Erro interno do servidor.",
                    errorSend: "Erro ao enviar imagem",
                    categoryImg: "Imagens",
                    method: "Método",
                    send: "Enviar",
                    style: "Estilo",
                    noneStyle: "Nenhum estilo",
                    model3d: "Modelo 3D",
                    analogFilm: "Filme Analógico",
                    anime: "Anime",
                    cinematic: "Cinemático",
                    comicBook: "História em Quadrinhos",
                    digitalArt: "Arte Digital",
                    enhance: "Aperfeiçoar",
                    fantasy: "Fantasia",
                    isometric: "Isométrico",
                    lineArt: "Arte em Traço",
                    lowPoly: "Baixa Poligonalidade",
                    modelingClay: "Massa de Modelar",
                    neon: "Neon Punk",
                    origami: "Origami",
                    photographic: "Fotográfico",
                    pixelArt: "Pixel Art",
                    tileTexture: "Textura de Azulejo",
                    titleDifusion1: "Quão estritamente o processo de difusão adere ao texto do prompt (valores mais altos mantêm sua imagem mais próxima do seu prompt)",
                    scaleCFG: "Escala CFG",
                    refinements: "Refinamentos",
                    qntImages: "Quantidade de imagens geradas",
                    samples: "Amostras",
                    format: "Formato",
                    titleDifusion2: "Qual é o impacto da imagem inicial no processo de difusão. Valores próximos de 1 resultarão em imagens muito semelhantes à imagem inicial, enquanto valores próximos de 0 resultarão em imagens muito diferentes da imagem inicial.",
                    weightImg: "Peso da imagem",
                    addToImg: "Adicionar à imagem",
                    textToImg: "Texto para imagem",
                    intensityPropmt: "Intensidade do prompt",
                    removeImg: "Remover da Imagem",
                    width: "Largura",
                    height: "Altura",
                    allowed: "Permitido",
                    save: "Salvar",
                },
                library: {
                    activity: "Atividade",
                    action: "Ação",
                    none: "Nenhum",
                    delete: "Deletar",
                    library: "Biblioteca",
                    title: "Título",
                    action2: "Ação",
                    link: "Vínculo",
                    type: "Tipo",
                    samples: "Amostras",
                    date: "Data",
                    deleteAction: "Apagar Ação",
                    confirmDelete: "Você tem certeza que deseja apagar o item selecionado?",
                    detailsModal: {
                        selectActivity: "Selecione uma atividade",
                        contentNotFound: "Conteúdo não encontrado.",
                        sendJiraError: "Erro ao enviar arquivo para o Jira!",
                        integrationJiraError: "Erro na integração com Jira!",
                        updateSuccess: "Conteúdo atualizado com sucesso!",
                        internalError: "Erro interno do servidor.",
                        details: "Detalhes",
                        linkAction: "Vincular à Ação",
                        activity: "Atividade",
                        save: "Salvar",
                        cancel: "Cancelar"
                    },
                    filter: {
                        filter: "Filtrar",
                        filterBy: "Filtrar por",
                        title: "Título",
                        action: "Ação",
                        type: "Tipo",
                        aplic: "Aplicar",
                        reset: "Resetar filtros"
                    }
                },
                redacting: {
                    fillFields: "Preencha todos os campos!",
                    minChar: "O mínimo de caracteres em um campo é 3!",
                    errorContent: "Erro ao gerar conteúdo",
                    errorSave: "Erro ao salvar conteúdo",
                    redaction: "Redação",
                    method: "Método",
                    send: "Enviar",
                    quality: "Qualidade",
                    economy: "Economia",
                    medium: "Média",
                    high: "Alta",
                    premium: "Premium",
                    language: "Idioma",
                    portuguese: "Português",
                    english: "Inglês",
                    spanish: "Espanhol",
                    numberCopies: "Número de cópias",
                    save: "Salvar",
                    options: {
                        description1: "Descrição",
                        product: "Produto",
                        description2: "Descrição",
                        container: "Recipiente",
                        containerCharge: "Cargo do recipiente",
                        description3: "Descrição",
                        productName1: "Nome do produto",
                        productDescription1: "Descrição do produto",
                        occasion: "Ocasião",
                        promotion: "Promoção",
                        productName2: "Nome do produto",
                        productDescription2: "Descrição do produto",
                        searchTerm: "Termo de busca",
                        productName3: "Nome do produto",
                        productDescription3: "Descrição do produto",
                        companyName: "Nome da empresa",
                        companyDescription: "Descrição",
                        keyWord: "Palavra Chave",
                        productName4: "Nome do produto",
                        productDescription4: "Descrição do produto",
                        keyWords: "Palavras Chave",
                        targetAudience: "Audiência alvo",
                        description4: "Descrição",
                        voiceTone1: "Tom de voz",
                        content: "Conteúdo",
                        voiceTone2: "Tom de voz",
                        productDescription5: "Descrição do produto",
                        benefit: "Benefício",
                    }
                },
                utils: {
                    config: {
                        label0: 'Call to Action',
                        description0: `Crie chamadas para a ação atraentes que incentivarão as conversões e impulsionarão suas vendas. 
      
                        No campo **Descrição**, explique a empresa, produto ou serviço que está promovendo e receba ideias de chamadas para a ação.
                        
                        Qualidade: qual o nível de criatividade que gostaria de aplicar, sendo o modelo Premium, mais demorado e caro.
                        Idioma: escolha entre os 3 idiomas disponíveis.
                        Cópias: escolha quantas opções gostaria de escolher.`,

                        label1: 'Assunto de E-mail',
                        description1: `Crie assuntos de e-mail que aumentam sua taxa de abertura.
      
                        No campo **Produto** informe o nome da empresa, do produto ou do serviço, já no campo **Descrição** explique brevemente o que gostaria de promover.
                        
                        Qualidade: qual o nível de criatividade que gostaria de aplicar, sendo o modelo Premium, mais demorado e caro.
                        Idioma: escolha entre os 3 idiomas disponíveis.
                        Cópias: escolha quantas opções gostaria de escolher.`,

                        label2: 'Emails',
                        description2: `Crie e-mails em massa que se parecem personalizados.
      
                        No campo **Recipiente** informe o nome de quem receberá o e-mail ou coloque apenas “cliente”. No campo **Cargo do Recipiente** você informar o segmento ou o perfil do cliente e na **Descrição** descreva o objetivo do seu e-mail. Lembre-se que o sistema irá gerar um e-mail longo, portanto se está buscando algo curto, utilize os recursos “Assunto de e-mail” e “Call to action".
                        
                        Qualidade: qual o nível de criatividade que gostaria de aplicar, sendo o modelo Premium, mais demorado e caro.
                        Idioma: escolha entre os 3 idiomas disponíveis.
                        Cópias: escolha quantas opções gostaria de escolher.`,

                        label3: 'Facebook Ads',
                        description3: `Redação para Facebook e Instagram Ads que farão seus anúncios se destacarem.
      
                        No campo **Nome do Produto** informe o nome da empresa, do produto ou do serviço, já no campo **Descrição** explique brevemente o seu produto, informe a **Ocasião** de uso e em **Promoção** informe quaisquer ofertas que gostaria de destacar.
                        
                        Qualidade: qual o nível de criatividade que gostaria de aplicar, sendo o modelo Premium, mais demorado e caro.
                        Idioma: escolha entre os 3 idiomas disponíveis.
                        Cópias: escolha quantas opções gostaria de escolher.`,

                        label4: 'Google Ads',
                        description4: `Anúncios de qualidade que ranqueam nos resultados de busca e geram mais tráfego.
      
                        No campo **Nome do Produto** informe o nome da empresa, do produto ou do serviço, já no campo **Descrição do Produto** explique brevemente o seu produto, informe o **Termo de Busca** que gostaria de ranquear.
                        
                        Qualidade: qual o nível de criatividade que gostaria de aplicar, sendo o modelo Premium, mais demorado e caro.
                        Idioma: escolha entre os 3 idiomas disponíveis.
                        Cópias: escolha quantas opções gostaria de escolher.`,

                        label5: 'Descrições Google Ads',
                        description5: `Os melhores textos para anúncios do Google Ads que tornam visitantes em clientes.
      
                        No campo **Nome do Produto** informe o nome da empresa, do produto ou do serviço, já no campo **Descrição do Produto** explique brevemente o seu produto.
                        
                        Qualidade: qual o nível de criatividade que gostaria de aplicar, sendo o modelo Premium, mais demorado e caro.
                        Idioma: escolha entre os 3 idiomas disponíveis.
                        Cópias: escolha quantas opções gostaria de escolher.`,

                        label6: 'Título Google Ads',
                        description6: `Anúncios com títulos exclusivos e atraentes que induzam as pessoas a clicar em seu anúncio e comprar em seu site.
      
                        No campo **Nome da Empresa** informe o nome da empresa anunciante, já no campo **Descrição** explique brevemente o seu produto, informe a **Palavra-chave** que será usada na sua campanha.
                        
                        Qualidade: qual o nível de criatividade que gostaria de aplicar, sendo o modelo Premium, mais demorado e caro.
                        Idioma: escolha entre os 3 idiomas disponíveis.
                        Cópias: escolha quantas opções gostaria de escolher.`,

                        label7: 'Ideias de Crescimento',
                        description7: `Táticas de crescimento de alto impacto para ajudar sua empresa a crescer.
      
                        No campo **Nome do Produto** informe o nome da empresa, do produto ou do serviço, já no campo **Descrição do Produto** explique brevemente o seu produto. Em **Palavras-chave**, informe quais termos estão associados ao seu produto e em **Audiência Alvo** informe qual o público-alvo da sua tática.
                        
                        Qualidade: qual o nível de criatividade que gostaria de aplicar, sendo o modelo Premium, mais demorado e caro.
                        Idioma: escolha entre os 3 idiomas disponíveis.
                        Cópias: escolha quantas opções gostaria de escolher.`,

                        label8: 'Legendas do Instagram',
                        description8: `Capture a atenção dos seus posts com legendas interessantes e criativas.
      
                        No campo **Conteúdo** informe o assunto do post e no campo **Tom de voz** diga como sua marca se comunica com seus cliente.`,

                        label9: 'Reescrever texto',
                        description9: `Reescreva texto com um novo tom de voz ou um novo estilo ortográfico.
      
                        No campo **Conteúdo** informe o texto que deverá ser reescrito e no campo **Tom de voz** diga qual estilo ortográfico ou tom de voz que deverá ser aplicado.`,

                        label10: 'Recurso para Beneficio',
                        description10: `Transforme características do seu produto ou serviço em benefícios para gerar mais valor a seus clientes.`,

                        label11: 'Texto Para Imagem',
                        description11: `Crie imagens perfeitas para seus anúncios com criatividade e exclusividade. Adapte para o estilo da sua marca e aumente a escala da produção de conteúdo.`,
                        dropdownTitle11: 'Aprenda a criar imagens:',
                        dropdownText11: `**Estilo**: escolha entre os diversos estilos pré-definidos ou deixe em branco para definir o estilo diretamente no texto.

                        **Escala CFG**: este parâmetro controla o quanto o processo de geração de imagem seguirá as instruções de texto. Quanto maior o valor (máx 35), mais a imagem adere a uma determinada entrada de texto, quanto menor o valor (mín 0), mais criativo e livre será o resultado.
                        
                        **Refinamentos**: quando uma imagem é gerada, ela será refinada uma próxima vez conforme a quantidade de refinamentos definida. O processo de refinamento pode ser eficaz em imagens com muitos elementos complexos, mas pouco eficaz em imagens abstratas. Teste conforme sua imagem, porém recomendamos o padrão de 50. 
                        
                        **Amostras**: quantas imagens diferentes você deseja. 
                        
                        **Texto para Imagem**: descreva os elementos principais, o ambiente, o estilo da imagem e palavras-chave separadas por vírgula. `,

                        label12: 'Transformar Imagem',
                        description12: `Crie imagens perfeitas para seus anúncios com criatividade e exclusividade. Adapte para o estilo da sua marca e aumente a escala da produção de conteúdo.`,
                        dropdownTitle12: 'Aprenda a transformar imagens:',
                        dropdownText12: `**Estilo**: escolha entre os diversos estilos pré-definidos ou deixe em branco para manter o mesmo estilo da imagem referência.

                        **Escala CFG**: este parâmetro controla o quanto o processo de geração de imagem seguirá as instruções de texto. Quanto maior o valor (máx 35), mais a imagem adere a uma determinada entrada de texto, quanto menor o valor (mín 0), mais criativo e livre será o resultado.
                        
                        **Refinamentos**: quando uma imagem é gerada, ela será refinada uma próxima vez conforme a quantidade de refinamentos definida. O processo de refinamento pode ser eficaz em imagens com muitos elementos complexos, mas pouco eficaz em imagens abstratas. Teste conforme sua imagem, porém recomendamos o padrão de 50. 
                        
                        **Amostras**: quantas imagens diferentes você deseja. 
                        
                        **Peso da imagem**: Quanta influência a imagem de referência tem no processo de criação. Valores próximos a 1 produzirão imagens muito semelhantes à imagem de referência, enquanto valores próximos a 0 produzirão imagens totalmente diferentes.
                        
                        **Adicionar à Imagem**: descreva os elementos principais, o ambiente, o estilo da imagem e palavras-chave separadas por vírgula. 
                        
                        **Adicionar à Imagem**: descreva elementos como objetos, ambiente e estilos que gostaria de incluir na imagem e utilize a barra lateral para definir o nível de destaque que os elementos devem ter na imagem.
                        
                        **Adicionar da Imagem**: descreva o que você deseja remover da imagem de referência e utilize a barra lateral para definir a intensidade que os elementos deverão ser removidos da imagem.`,

                        label13: 'Image Upscaling',
                        description13: `Amplie imagens em baixa qualidade para tamanhos de alta resolução. Este modelo recria os detalhes da imagem em alta resolução de forma a manter a maior fidelidade possível em relação a imagem original.
                        Escolha entre altura ou largura como referência para o tamanho final. Os incrementos de tamanho são fixos em 64px, portanto não será possível chegar ao tamanho exato desejado.`,
                    },
                    searchAction: {
                        action: "Ação",
                    },
                    searchToJiraModal: {
                        selectActivity: "Selecione uma atividade",
                        sendJiraError: "Erro ao enviar arquivo para o Jira!",
                        integrationJiraError: "Erro na integração com Jira!",
                        storeSuccess: "Conteúdo armazenado com sucesso",
                        internalError: "Erro interno do servidor.",
                        relation: "Relação Jira/Plataforma",
                        title: "Título",
                        associate: "Associar à",
                        none: "Nenhum",
                        action: "Ação",
                        activity: "Atividade",
                        activity2: "Atividade",
                        commentJira: "Comentário Jira",
                        save: "Salvar"
                    }
                }
            },
            apps: {
                alerts: {
                    message: "Mensagens",
                    telephone: "Telefone",
                    start: "Início",
                    end: "Fim",
                    btnText: "Texto do botão",
                    weekends: "Finais de semana",
                    active: "Ativo",
                    alerts: "Alertas",
                    chatSupportMessage: "Mensagem de chat de suporte",
                },
                extendedCheckout: {
                    extendedCheckout: "Checkout Ampliado",
                },
                logistics: {
                    logistic1: "Logística",
                    logistic2: "Logística",
                },
                service: {
                    service1: "Atendimento",
                    service2: "Atendimento",
                },
                smartShipping: {
                    smartShipping1: "Frete Inteligente",
                    smartShipping2: "Frete Inteligente",
                },
                utils: {
                    appFormCms: {
                        sendFilesError: "Erro ao enviar arquivos!",
                        updateFilesError: "Erro ao atualizar arquivos!",
                        internalError: "Erro interno do servidor.",
                        createAppSuccess: "App criado com sucesso!",
                        updateAppError: "Erro ao atualizar app!",
                        updateAppSuccess: "App atualizado com sucesso!",
                        sincAlt: "Sincronizar alterações",
                        title1: "Título",
                        link1: "Link",
                        description1: "Descrição",
                        link2: "Link",
                        link3: "Link",
                        link4: "Link",
                        title2: "Título",
                        textButton: "Texto Botão",
                        description2: "Descrição",
                        subtitle1: "Subtítulo",
                        description3: "Descrição",
                        subtitle2: "Subtítulo",
                        description4: "Descrição",
                        subtitle3: "Subtítulo",
                        description5: "Descrição",
                        addDescription: "Adicionar descrição",
                    },
                    appFormNotifications: {
                        createAppSuccess: "App criado com sucesso!",
                        updateAppError: "Erro ao atualizar app!",
                        updateAppSuccess: "App atualizado com sucesso!",
                        internalError: "Erro interno do servidor.",
                        sincAlt: "Sincronizar alterações",
                        success: "Sucesso",
                        error: "Erro",
                        info: "Info",
                        other: "Outro",
                    },
                    appTabs: {
                        cms: "Cms",
                        notifications: "Notificações"
                    }
                }
            },
            chat: {
                indexAdm: {
                    selectChanel: "Selecione um canal ao lado."
                }
            },
            customer: {
                customerNotExist: "Cliente não existe!",
                deleteError: "Erro ao excluir!",
                deleteSuccess: "Cliente apagado com sucesso!",
                internalError: "Erro interno do servidor.",
                performance: "Desempenho",
                acessibility: "Acessibilidade",
                bestPractices: "Práticas",
                seo: "SEO",
                stores: "Lojas",
                register: "Cadastrar",
                search: "Pesquisar",
                store: "Loja",
                all: "Todos",
                implantation: "Implantação",
                operation: "Operação",
                performance2: "Performance",
                support: "Suporte",
                logo: "Logo",
                name: "Nome",
                healthScore: "Health Score",
                balanceCreditsPerformance: "Saldo/Créditos (Performance)",
                balanceCreditsSupport: "Saldo/Créditos (Suporte)",
                performance3: "Performance",
                platforms: "Plataformas",
                viewChecklist: "Visualzar Checklist",
                editChecklist: "Editar Checklist",
                users: "Usuários",
                edit: "Editar",
                delete: "Excluir",
                deleteUser: "Apagar usuário",
                deleteCustomer: "Apagar cliente",
                confirmDelete: "Você tem certeza que deseja apagar este cliente?",
                cancel: "Cancelar",
            },
            dashboard: {
                dashboards: "Dashboards",
                dashboard: "Dashboard",
                resumeSales: "Resumo de Vendas",
                paidTraffic: "Tráfego Pago",
                crm: "CRM",
                sales: "Vendas",
                invoicing: "Faturamento",
                products: "Produtos",
                strategy: "Estratégia",
                healthScore: "Health Score",
                immediateAction: "Ações Imediatas",
                activitiesWaitingApproval: "Atividades Aguardando Aprovação",
                checklistItens: "Itens de Checklist aguardando envio",
                roadmap: "Roadmap",
                operation: "Operação",
                created: "Pedido criado",
                error: "Erro de integração",
                shipment: "Em expedição",
                carrier: "Em trânsito",
                client: "Entregue ao cliente",
                tecnology: "Tecnologia",
                pleaseWaitTecnology: "Por favor aguarde, estamos buscando seus dados",
                performance: "Desempenho",
                accessibility: "Acessibilidade",
                recommendedPractices: "Práticas Recomendadas",
                seo: "SEO",
                last30days: "Últimos 30 dias",
                invoicingTotal: "Faturamento Total",
                ticketMedio: "Ticket Médio",
                orders: "Pedidos",
                textNoInstall: "Instale o app Métricas FullComm para trazer seus dados e gerar visualizações.",
                installInShopify: "Instalar App Shopify",
                startWithPerformance: "Comece com a Performance",
                startWithOperation: "Comece com a Operação",
                usePerformanceModule: "Utilize o módulo de performance para exibir os dados no dashboard",
                useOperationModule: "Utilize o módulo de operação para exibir os dados no dashboard",
                requestModule: "Solicitar módulo",
                goToPerformance: "Ir para Performance",
                unLockData: "Desbloqueie o poder dos dados",
                unblock: "Desbloquear",
            },

            dataEnrichmentModal: {
                integrations: {
                    integratedService: "Serviço integrado",
                    integrateService: "Integrar serviço",
                    createdStore: "Loja criada com sucesso!",
                    store: "Loja",
                    watchVideo: "Assista ao vídeo de como integrar os serviços que você usa na Plataforma FullComm.",
                    finishedIntegration: "Finalizar integração",
                    continueAfter: "Continuar mais tarde",
                },
                intro: {
                    userRequired: "Nome de usuário é obrigatório.",
                    phoneRequired: "Telefone é obrigatório.",
                    nameRequired: "Nome é obrigatório.",
                    termsOfUseRequired: "É necessário aceitar os termos de uso.",
                    urlValid: "Insira uma URL válida. Exemplo: https://www.checkstore.com.br.",
                    urlRequired: "URL da loja é obrigatória.",
                    helpUs: "Ajude-nos a personalizar sua experiência.",
                    name: "Nome",
                    phone: "Telefone",
                    storeName: "Nome da sua loja virtual",
                    urlStore: "Endereço da sua loja virtual",
                    termsOfUse: "Eu li e aceito os termos de uso",
                    initConfig: "Iniciar configuração",
                    continueAfter: "Continuar mais tarde",
                    segment: "Segmento",
                    segmentRequired: "Segmento é obrigatório.",
                    fashion: "Moda",
                    home: "Casa",
                    food: "Alimentos",
                    beauty: "Beleza",
                    health: "Saúde",
                    eletronics: "Eletrônicos",
                    other: "Outro",
                }
            },
            login: {
                informEmail: "Informe seu e-mail.",
                informPassword: "Informe sua senha.",
                auth0: {
                    verifiedEmail: "E-mail verificado? Clique aqui para tentar novamente.",
                    continueAfter: "Continuar mais tarde",
                    login: "Continuar para FullComm",
                    register: "Não possui conta? Experimente!",
                    title: "E-commerce equiparado aos gigantes",
                    subTitle: "Software FullComm integra estratégia, performance, logística: eficiência global em lojas."
                }
            },
            notifications: {
                all: "Todas",
                communications: "Comunicados",
                waitingApproval: "Aguardando aprovação",
                actions: "Ações",
                account: "Conta",
                alerts: "Alertas",
                notifications: "Notificações",
                utils: {
                    history: "Historia . . .",
                },
                icon: {
                    notifications: "Notificações",
                    noneNotifications: "Você não possui notificações no momento.",
                    showAll: "Mostrar todas as notificações",
                },
                notificationCard: {
                    newActionRegister: "Nova ação cadastrada",
                    updateAction: "Ação atualizada",
                    reproveActivity: "Atividade reprovada",
                    approveActivity: "Atividade aprovada",
                    deleteAction: "Ação excluída",
                    impediment: "Impedimento!",
                    pendingApproval: "Aprovação pendente!",
                    reproveProblem: "Problema reprovado",
                    approveProblem: "Problema aprovado",
                    requiresEstimation: "Ação necessita estimativa",

                    theAction: "A ação",
                    wasRegistered: "foi cadastrada com sucesso!",
                    wasUpdated: "foi atualizada com sucesso!",
                    theActivity: "A atividade",
                    ofAction: "da ação",
                    wasReproved: "foi reprovada!",
                    wasApproved: "foi aprovada!",
                    wasDeleted: "foi excluída!",
                    isImpediment: 'está com Impedimento!',
                    needApproval: "necessita ser aprovada!",
                    theProblemIncident: "O problema/incidente",
                    needApproval2: "necessita ser aprovado!",
                    theProblem: "O problema",
                    was: "foi",
                    wasReproved2: "foi reprovado!",
                    wasApproved2: "foi aprovado!",
                    moment: "{{timeElapsed}}",
                    pendingChecklist: "está com a checklist pendente!",
                    needEstimation: "necessita estimativa!",
                }
            },
            perfil: {
                information: "Informações",
                perfil: "Perfil",
                edit1: "Editar",
                actions: "Ações",
                edit2: "Editar",
                editPassword: "Editar Senha",
                picture: "Foto",
                name: "Nome",
                email: "E-mail",
                notifications: "Notificações",
                activate: "Ativar",
                language: "Linguagem",
                portuguese: "Português",
                english: "Inglês",
                spanish: "Espanhol",
                chatStyle: "Estilo do chat",
                workingHours: "Horário de trabalho",
                new: "Novo",
                legacy: "Legado",
                notDefined: "Não definido",
                invalidHours: "Horário inválido",
            },
            permission: {
                selectClient: "Selecione um cliente",
                invalidData: "Dados inválidos.",
                updateSuccess: "Permissões atualizadas com sucesso!",
                userNotFound: "Usuário não encontrado.",
                internalError: "Erro interno do servidor.",
                permissions: "Permissões",
                edit: "Editar",
                permissionsModel: "Modelo de Permissões",
                none: "Nenhum",
                general: "Geral",
                implantation: "Implantação",
                performance: "Performance",
                support: "Suporte",
                operation: "Operação",
                savePermissions: "Salvar",
                permissionModels: {
                    add: "Adicionar",
                    selectModel: "Selecione um modelo",
                    updatedPermissions: "Permissões atualizadas com sucesso",
                    errorUpdatePermissions: "Erro ao atualizar permissões",
                    createEdit: "Criar/Editar Permissões",
                    createModel: "Criar modelo",
                    save: "Salvar",
                    modelAplic: "Modelo se aplica sobre",
                    admin: "Admin",
                    client: "Cliente",
                    general: "Geral",
                    implantation: "Implantação",
                    performance: "Performance",
                    support: "Suporte",
                    operation: "Operação",
                    freeSoloCreateOption: {
                        createNewModel: "Criar novo modelo",
                        permissionsModel: "Modelo de Permissões",
                    },
                    modalEditPermission: {
                        permissionAlreadyExists: "A permissão já existe!",
                        createSuccess: "Permissão criada com sucesso!",
                        errorCreatePermission: "Erro ao criar permissão",
                        permissionNotFound: "Permissão não encontrada!",
                        updateSuccess: "Permissão atualizada com sucesso!",
                        internalError: "Erro interno do servidor.",
                        errorUpdatePermission: "Erro ao atualizar Permissão",
                        errorDeletePermission: "Erro ao deletar Permissão",
                        editPermission: "Editar Permissão",
                        name1: "Nome",
                        userType: "Tipo de usuário",
                        admin: "Admin",
                        client: "Cliente",
                        category: "Categoria",
                        general: "Geral",
                        implantation: "Implantação",
                        performance: "Performance",
                        support: "Suporte",
                        operation: "Operação",
                        permissions: "Permissões",
                        name2: "Nome",
                        id: "id",
                        addPermission: "Adicionar Permissão",
                        save: "Salvar",
                        delete: "Deletar",
                        cancel1: "Cancelar",
                        deletePermission: "Deletar Permissão",
                        confirmDelete: "Você tem certeza que deseja deletar a Permissão?",
                        remove: "Apagar",
                        cancel2: "Cancelar"
                    }
                },
                admin: {
                    userNotFound: "Usuário não encontrado.",
                    emailAlreadyExist: "E-mail já cadastrado.",
                    updateError: "Ocorreu um erro ao atualizar o usuário.",
                    updateSuccess: "Usuário atualizado com sucesso!",
                    internalError: "Erro interno do servidor.",
                    permissions: "Permissões",
                    edit: "Editar",
                    permissionsModel: "Modelo de Permissões",
                    none: "Nenhum",
                    general: "Geral",
                    implantation: "Implantação",
                    performance: "Performance",
                    support: "Suporte",
                    operation: "Operação",
                    savePermissions: "Salvar permissões"
                }
            },
            recoveryPassLogin: {
                emailRequired: "Informe o e-mail!",
                emailError: "E-mail inválido!",
                rescuePass: "Recuperar senha",
                email: "E-mail",
                writeEmail: "Digite seu e-mail",
                clickHere: "Clique aqui",
                toAccess: " para acessar",
            },
            scale: {
                logistics: {
                    logistic: "Logística",
                    tabInit: "Início",
                    tabOrders: "Pedidos",
                    orders: {
                        created: "Novo pedido",
                        error: "Erro de integração",
                        processed: "Pedido Integrado",
                        shipment: "Pedido em expedição",
                        shipping: "Entregue à transportadora",
                        invoiced: "Faturado",
                        store: "Loja",
                        customer: "Entregue ao cliente",
                        last7: "Últimos 7 dias",
                        last15: "Últimos 15 dias",
                        last30: "Últimos 30 dias",
                        last90: "Últimos 90 dias",
                        all: "Todos",
                        filterItens: "Filtrar itens",
                        statusType: "Status",
                        period: "Período",
                        order: "Pedido",
                        data: "Data",
                        client: "Cliente",
                        statusTitle: "Status",
                        value: "Valor",
                        linesPerPage: "Linhas por página:",
                        of: "de",
                    },
                    start: {
                        filterPeriod: "Filtrar período",
                        last7: "Últimos 7 dias",
                        last15: "Últimos 15 dias",
                        last30: "Últimos 30 dias",
                        last90: "Últimos 90 dias",
                        all: "Todos",
                        customer: "Entregues ao cliente",
                        shipping: "Entregues à transportadora",
                        shipment: "Expedição",
                        error: "Erro de integração",
                        invoicedOrders: "Pedidos faturados",
                        totalOrders: "Total de pedidos colocados",
                    }
                },
                platinumlog: {
                    orders: "Pedidos",
                    timeIndicators: "Indicadores de tempo",
                    order: {
                        loading: "Carregando...",
                        order: "Pedido",
                        orderDate: "Data do Pedido",
                        invoice: "Nota Fiscal",
                        nfe: "NF-e",
                        prevision: "Previsão",
                        carrier: "Transportadora",
                        modality: "Modalidade",
                        customer: "Cliente",
                        contact: "Contato",
                        address: "Endereço",
                        complement: "Complemento",
                        neighborhood: "Bairro",
                        city: "Cidade",
                        cep: "CEP",
                        orderDetails: "Detalhes do Pedido",
                        timeIndicators: "Indicadores de tempo",
                        internalEvents: "Eventos internos",
                        shippingEvents: "Eventos de envio",
                        goods: "Mercadorias",
                    },
                },
                service: {
                    service: "Atendimento",
                    tabInit: "Início",
                    tabOccurrences: "Ocorrências",
                    occurrences: {
                        inAnalysis: "Em análise",
                        inTransit: "Em trânsito",
                        inDispute: "Em disputa",
                        waitingPost: "Aguardando postagem",
                        codeOccurrenceFilter: "Código da ocorrência",
                        statusFilter: "Status",
                        period: "Período",
                        type: "Tipo",
                        statusTitle: "Status",
                        codeOccurrenceTitle: "Código da ocorrência",
                        elapsedTime: "Tempo decorrido",
                        data: "Qntd",
                        quantity: "Qntd",
                        value: "Valor da ocorrência",
                        immediateAction: "Ação imediata",
                        occurrences: "Ocorrências"
                    },
                    start: {
                        inAnalysis: "Em análise",
                        inTransit: "Em trânsito",
                        inDispute: "Em disputa",
                        waitingPost: "Aguardando postagem",
                        status: "Status",
                        codeOccurrence: "Código da ocorrência",
                        elapsedTime: "Tempo decorrido",
                    }
                },
                smartShipping: {
                    smartShipping: "Frete Inteligente",
                    filterPeriod: "Filtrar período",
                    last7: "Últimos 7 dias",
                    last15: "Últimos 15 dias",
                    last30: "Últimos 30 dias",
                    last90: "Últimos 90 dias",
                    all: "Todos",
                    quotes: "Cotações",
                    ZIPCodeDestination: "CEP destino",
                    generalMedia: "Média geral",
                    mediaV1: "Média V1",
                }
            },
            sprint: {
                results: {
                    results: "Resultados",
                },
                actionChecklist: {
                    checklistProgress: "Progresso do Checklist",
                    generalProgress: "PROGRESSO GERAL",
                    checklistStatus: "Status do Checklist",
                    create: {
                        actionNotExist: "Ação não existe!",
                        integrationJiraError: "Erro na integração com Jira, tente salvar a ação novamente!",
                        updateError: "Ação não encontrada ou não modificada!",
                        updateSuccess: "Ação atualizada com sucesso!",
                        internalError: "Erro interno do servidor.",
                        checklist: "Checklist",
                        save: "Salvar",
                        checklistAction: "Checklist de Ação",
                        subTitle: "sub-título",
                        title: "Título",
                        helperText: 'Helper text',
                        placeHolder: 'Placeholder',
                        type: 'Tipo',
                        text: 'Texto',
                        textArea: 'Textarea',
                        count: 'Contador Numérico',
                        money: 'Valor Monetário',
                        uniqueList: 'Lista Suspensa Única',
                        multipleList: 'Lista Suspensa Múltipla',
                        url: 'URL',
                        date: 'Data',
                        email: 'E-mail',
                        radio: 'Radio',
                        checkbox: 'Checkbox',
                        attachment: 'Anexo',
                        options: 'Opções',
                        mandatory: 'Obrigatório',
                        selectItem: 'Selecione um item ao lado!',
                    },
                    edit: {
                        registerError: "Checklist não foi cadastrado com sucesso!",
                        internalError: "Erro interno do servidor.",
                        registerSuccess: "Checklist cadastrado com sucesso!",
                        updateError: "Ocorreu um erro ao atualizar o Checklist.",
                        updateSuccess: "Tarefa atualizada com sucesso!",
                        errorIcons: "Erro ao buscar ícones:",
                        addForm: "Formulário adicionado com sucesso!",
                        editForm: "Formulário atualizado com sucesso!",
                        checklistAction: "Checklist de Ação",
                        add: "Adicionar",
                        title: "Título",
                        helperText: 'Helper text',
                        placeHolder: 'Placeholder',
                        type: 'Tipo',
                        text: 'Texto',
                        textArea: 'Textarea',
                        count: 'Contador Numérico',
                        money: 'Valor Monetário',
                        uniqueList: 'Lista Suspensa Única',
                        multipleList: 'Lista Suspensa Múltipla',
                        url: 'URL',
                        date: 'Data',
                        email: 'E-mail',
                        radio: 'Radio',
                        checkbox: 'Checkbox',
                        attachment: 'Anexo',
                        condition: 'Condição',
                        noCondition: 'Sem condição',
                        valueCondition: 'Valor condição',
                        mandatory: 'Obrigatório',
                        options: 'Opções',
                        addOption: 'Adicionar opção',
                        addField: 'Adicionar campo',
                        add2: 'Adicionar',
                        save: 'Salvar',
                        cancel: 'Cancelar',
                        delete: 'Excluir',
                        selectItem: 'Selecione um item ao lado!',
                        searchIcon: 'Pesquisar ícone'
                    },
                    inicial: {
                        fieldRequired: "Esse campo é obrigatório.",
                        fillFields: "Preencha todos os campos obrigatórios.",
                        actionNotFound: "Ação não encontrada!",
                        errorUpdate: "Ocorreu um erro ao atualizar a ação.",
                        updateSuccess: "Ação atualizada com sucesso!",
                        internalError: "Erro interno do servidor.",
                        archiveHere: "Solte os arquivos aqui",
                        dragOrClick: "Arraste e solte seus arquivos aqui ou clique para selecionar arquivos",
                        checklist: "Checklist",
                        checklistAction: "Checklist da Ação",
                        save: "Salvar",
                        selectItem: "Selecione um item ao lado!",
                    },
                    view: {
                        actionNotFound: "Ação não encontrada!",
                        errorUpdate: "Ocorreu um erro ao atualizar a ação.",
                        updateSuccess: "Ação atualizada com sucesso!",
                        internalError: "Erro interno do servidor.",
                        checklist: "Checklist",
                        checklistAction: "Checklist da Ação",
                        save: "Salvar",
                        selectItem: "Selecione um item ao lado!",
                    },
                    viewAll: {
                        errorIcons: "Erro ao buscar ícones:",
                        delivered: "Entregue",
                        onTime: "No prazo",
                        atRisk: "Sob risco",
                        late: "Atrasado",
                        checklists: "Checklists",
                        edit: "Editar",
                        search: "Buscar",
                        status: "Status",
                        all: "Todos",
                        approveds: "Aprovados",
                        validatingItems: "Validando itens",
                        waitingSend: "Aguardando envio",
                        repproveds: "Reprovados",
                        lateDelivered: "Atrasado/Entregue",
                        onTime2: "No prazo",
                        atRisk2: "Sob risco",
                        action: "Ação",
                        waitingSend2: "Aguardando envio",
                        validatingItems2: "Validando itens",
                        repproveds2: "Reprovados",
                        approveds2: "Aprovados",
                        time: "Prazo",
                        fillChecklist: "Preencher Checklist",
                        viewChecklist: "Visualizar Checklist",
                        editChecklist: "Editar Checklist",
                    }
                },
                actionPlan: {
                    actionPlan: "Plano de Ação",
                    roadMapLabel: "Roadmap",
                    calendary: "Calendário",
                    roadMap: {
                        activeActions: "Ações ativas",
                        filter: {
                            apply: "Aplicar",
                        }
                    },
                    calendarView: {
                        viewBy: "Visualizar por:",
                        month: "Mês",
                        week: "Semana",
                    }
                },
                contract: {
                    form: {
                        theStores: "As lojas",
                        haveContract: "já possuem contrato ativo!",
                        theStore: "A loja",
                        haveContract2: "já possui contrato ativo!",
                        registerError: "Contrato não foi cadastrado com sucesso!",
                        registerSuccess: "Contrato cadastrado com sucesso!",
                        internalError: "Erro interno do servidor.",
                        updateError: "Ocorreu um erro ao atualizar o contrato.",
                        updateSuccess: "Contrato atualizado com sucesso!",
                        reasonSocialRequired: "Razão social é obrigatório.",
                        cnpjRequired: "CNPJ é obrigatório.",
                        clientIdRequired: "ID do cliente é obrigatório.",
                        clientNameRequired: "Nome do cliente é obrigatório.",
                        storeRequired: "Loja é obrigatório.",
                        monthlyCreditsRequired: "Créditos mensais é obrigatório.",
                        initDateRequired: "Data de início é obrigatória.",
                        invalidEmail: "E-mail inválido.",
                        invalidEmail2: "E-mail inválido.",
                        edit: "Editar",
                        register: "Cadastrar",
                        contract: "Contrato",
                        socialReason: "Razão Social",
                        cnpj: "CNPJ",
                        associateStores: "Associar Lojas",
                        monthlyCredits: "Créditos Mensais (Módulo de Performance)",
                        monthlyCreditsSupport: "Créditos Mensais (Módulo de Suporte)",
                        initDate: "Data de Início",
                        monthlyHours: "Horas Mensais (Módulo de Suporte)",
                        changeMode: "Alterar modelo de cobrança do suporte",
                        comissionRate: "Alíquota de Comissão",
                        minimalRevenue: "Faturamento Mínimo",
                        contractFiles: "Arquivos do Contrato",
                        newContractFiles: "Novos arquivos",
                        addFiles: "Anexar contrato",
                        financContacts: "Contatos do Financeiro",
                        files: "Arquivos",
                        nameFinanc: "Nome",
                        emailFinanc: "E-mail",
                        phoneFinanc: "Telefone",
                        respContacts: "Contatos do Responsável",
                        nameResp: "Nome",
                        emailResp: "E-mail",
                        phoneResp: "Telefone",
                        save: "Salvar",
                        cancel: "Cancelar"
                    },
                    list: {
                        theStores: "As lojas",
                        haveContract: "já possuem contrato ativo!",
                        theStore: "A loja",
                        haveContract2: "já possui contrato ativo!",
                        internalError: "Erro interno do servidor.",
                        updateError: "Ocorreu um erro ao atualizar o contrato.",
                        updateSuccess: "Contrato atualizado com sucesso!",
                        contracts: "Contratos",
                        search: "Pesquisar",
                        register: "Cadastrar",
                        cnpj: "CNPJ",
                        stores: "Lojas",
                        status: "Status:",
                        active: "Ativo",
                        finished: "Finalizado",
                        all: "Todos",
                        edit: "Editar",
                        close: "Encerrar",
                        performance: "Performance",
                        creditsPerformance: "Créditos:",
                        balancePerformance: "Saldo:",
                        support: "Suporte",
                        creditsSupport: "Créditos:",
                        balanceSupport: "Saldo:",
                        comissionRate: "Alíquota de Comissão",
                    }
                },
                kanban: {
                    problemIncidents: "Problemas e Melhorias",
                    activities: "Atividades",
                    accept: "Aceitar",
                    sendTo: "Enviar para",
                    viewReproval: "Visualizar reprovação",
                    reproved: "Reprovado",
                    comments: "Comentários",
                    actionCardArea: {
                        toDo: "Para fazer",
                        inProgress: "Em andamento",
                        customerApproval: "Homologação",
                        doneApplied: "Concluído/Aplicado",
                    },
                    activityApproval: {
                        updateProblemError: "Erro ao atualizar o problema.",
                        updateProblemSuccess: "Problema atualizado com sucesso!",
                        internalError: "Erro interno do servidor.",
                        updateActivityError: "Erro ao atualizar a atividade.",
                        updateActivitySuccess: "Atividade atualizada com sucesso!",
                        addComentReprove: "É necessário adicionar um comentário para reprovar a atividade",
                        confirmApprove: 'Você está prestes a aprovar essa atividade. Os comentários registrados nos arquivos serão excluídos e a tarefa concluída. Caso você deseje um ajuste selecione "Reprovar"',
                        back: "Voltar",
                        attachments: "Anexos",
                        addComment: "Adicionar comentário",
                        approve: "Aprovar",
                        reprove: "Reprovar",
                        comments: "Comentários",
                        expandImage: "Expandir imagem",
                    },
                    activityCard: {
                        toDo: "Para fazer",
                        inProgress: "Em andamento",
                        customerApproval: "Homologação",
                        doneApplied: "Concluído/Aplicado",
                    },
                    reprovedActivity: {
                        back: "Voltar",
                        attachments: "Anexos",
                        customerComment: "Comentário do cliente:",
                    }
                },
                list: {
                    internalError: "Erro interno do servidor.",
                    updatedSuccess: "Ações atualizadas com sucesso!",
                    actionsList: "Lista de Ações",
                    improvementsList: "Lista de Melhorias",
                    updateActions: "Atualizar Ações",
                    modal: {
                        deleteActionModal: {
                            deleteAction: "Apagar Ação",
                            confirmDelete: "Você tem certeza que deseja apagar a ação?",
                            delete: "Apagar",
                            cancel: "Cancelar"
                        },
                        integrationsModal: {
                            actionNotExist: "Ação não existe ou sem vínculo ao Jira!",
                            unrelatedAction: "Ação sem vínculo ao Jira!",
                            actionNotModify: "Ação não modificada!",
                            syncActionSuccess: "Ação sincronizada com sucesso!",
                            actionNotExist2: "Ação não existe!",
                            unbindSuccess: "Ação desvinculada com sucesso!",
                            actionNotFound: "Ação não encontrada!",
                            actionNotModify2: "Ação não modificada!",
                            internalError: "Erro interno do servidor.",
                            updateActionSuccess: "Ação atualizada com sucesso!",
                            jiraPlatform: "Relação Jira/Plataforma",
                            syncStatus: "Sincronizar status Jira com a plataforma",
                            apply: "Aplicar",
                            unlinkEpic: "Desvincular ação do épico",
                            apply2: "Aplicar",
                            linkAction: "Vincular ação ao Jira",
                            jiraKey: "Chave Jira",
                            linkActivities: "Vincular atividades ao Jira",
                            jiraKey2: "Chave Jira",
                            save: "Salvar",
                        }
                    },
                    actionsTable: {
                        name: "Nome",
                        quadrant: "Quadrante",
                        init: "Começo",
                        end: "Fim",
                        status: "Status",
                        score: "Score",
                        actionNotFound: "Ação não encontrada!",
                        deleteError: "Não é possível excluir essa ação!",
                        updateError: "Erro ao atualizar ação!",
                        deleteSuccess: "Ação apagada com sucesso!",
                        internalError: "Erro interno do servidor.",
                        active: "Ativo",
                        backlog: "Backlog",
                        inProgress: "Em andamento",
                        done: "Concluído",
                        toDo: "Para fazer",
                        planning: "Planejamento",
                        canceled: "Cancelado",
                        activationDiscovery: "Ativação - Descoberta",
                        acquisitionContent: "Aquisição - Conteúdo",
                        engagementNextPurchase: "Engajamento - Próxima Compra",
                        conversionBuyingBarriers: "Conversão - Barreiras de Compra",
                        retentionAnalysis: "Retenção - Análise de experiência",
                        activationConsideration: "Ativação - Consideração",
                        acquisitionTracking: "Aquisição - Rastreamento",
                        engagementContext: "Engajamento - Contexto",
                        conversionOffers: "Conversão - Ofertas",
                        retentionTransmission: "Retenção - Transmissão",
                        activationProspecting: "Ativação - Prospecção",
                        acquisitionBenefit: "Aquisição - Benefício",
                        engagementRecovery: "Engajamento - Recuperação",
                        conversionAuthority: "Conversão - Autoridade",
                        retentionSegments: "Retenção - Segmentos",
                    },
                    optionsButton: {
                        edit: "Editar",
                        view: "Visualizar",
                        delete: "Excluir",
                        integration: "Integração",
                        jira: "Jira",
                    }
                },
                newAction: {
                    nameRequired: "Nome é obrigatório.",
                    categoryRequired: "Categoria é obrigatório.",
                    startRequired: "Início é obrigatório.",
                    estimateRequired: "Esta ação necessita uma estimativa!",
                    actionNotExist: "Ação não existe!",
                    errorIntegrationJira: "Erro na integração com Jira, tente salvar a ação novamente!",
                    actionNotFound: "Ação não encontrada ou não modificada!",
                    internalError: "Erro interno do servidor.",
                    contractNotFound: "Essa loja não possui um contrato!",
                    invalidData: "Dados inválidos!",
                    registerError: "Erro ao cadastrar ação!",
                    sendError: "Erro ao enviar arquivos!",
                    errorUpdateFiles: "Erro ao atualizar arquivos!",
                    errorCampaign: "Campanha não alterada.",
                    updateSuccess: "Ação atualizada com sucesso!",
                    createSuccess: "Ação cadastrada com sucesso!",
                    details: "Detalhes",
                    activities: "Atividades",
                    analysis: "Análise",
                    revision: "Revisão",
                    backToList: "Voltar para lista de ações",
                    edit: "Editar",
                    confirm: "Confirmar",
                    cancel: "Cancelar",
                    prev: "Anterior",
                    next: "Avançar",
                    actionFloatingButton: {
                        type: "Tipo:",
                        principalChannel: "Canal principal:",
                        credits: "Créditos:",
                        activitiesChannel: "Canais das atividades:",
                        impact: "Impacto:",
                        trust: "Confiança:",
                        facility: "Facilidade:",
                    },
                    modal: {
                        activation: "Ativação",
                        acquisition: "Aquisição",
                        engagement: "Engajamento",
                        conversion: "Conversão",
                        retention: "Retenção",
                        recommended: "Recomendado",
                        createAction: "Crie uma ação",
                        healthScore: "Health score",
                        briefExplanation: "O Health Score é uma nota de 0 a 100 que representa a maturidade da sua estratégia de e-commerce com base nas ações ativas em cada quadrante.",
                        categories: "Categorias",
                        all: "Todos",
                        createNewAction: "Criar nova ação",
                        search: "Pesquisar",
                        searchAction: "Buscar ação",
                        loading: {
                            pleaseWait: "Estamos processando sua requisição. Por favor aguarde...",
                        }
                    },
                    pages: {
                        activities: {
                            initialPage: "Página Inicial",
                            creditsLimitReached: "Limite de créditos atingido!",
                            interfaceDev: "Desenv. de Interface",
                            perTemplate: "por template",
                            descriptionDevInterface: "Desenv. de Interface - Página Inicial: Aqui, é desenvolvida a página inicial do site ou plataforma, que geralmente apresenta informações relevantes, promoções ou destaques dos produtos, com o objetivo de atrair e engajar os visitantes.",
                            categoryPage: "Página de Categoria",
                            descriptionDevCategory: "Desenv. de Interface - Página de Categoria: Essa atividade envolve o desenvolvimento da página de categoria, onde os produtos são agrupados de acordo com suas categorias ou tipos, facilitando a navegação e busca dos clientes.",
                            productPage: "Página de Produto",
                            descriptionDevProduct: "Desenv. de Interface - Página de Produto: É realizada a criação da página de produto, que exibe informações detalhadas sobre um item específico, como descrição, imagens, preço, opções de variação e botões de compra, proporcionando uma experiência completa ao cliente.",
                            priceUpdate: "Atualização de Preços via planilha",
                            inventory: "Inventário",
                            perHour: "por hora",
                            descriptionPriceUpdate: "Inventário - Atualização de Preços via planilha: É realizada a atualização dos preços dos produtos por meio de uma planilha, permitindo alterações em massa e facilitando a manutenção dos valores atualizados.",
                            productUpdate: "Atualização de Produtos",
                            unit: "unidade",
                            descriptionProductUpdate: "Inventário - Atualização de Produtos: Aqui, são realizadas as atualizações necessárias nos dados dos produtos cadastrados, como alterações de preço, descrição, imagens, atributos, entre outros.",
                            diversePage: "Página Diversas: carrinho, minha conta, sobre, etc",
                            descriptionDiversePage: 'Desenv. de Interface - Página Diversas: carrinho, minha conta, sobre, etc: Aqui, são desenvolvidas diferentes páginas secundárias ou complementares, como carrinho de compras, área de conta do usuário, página "Sobre nós" ou outras páginas institucionais.',
                            landingPage: "Landing Page promocional, upsell, carta de vendas, etc",
                            descriptionLandingPage: "Desenv. de Interface - Landing Page promocional, upsell, carta de vendas, etc: Essa atividade envolve o desenvolvimento de landing pages, que são páginas específicas criadas para campanhas promocionais, upsell (venda adicional) ou carta de vendas, com o objetivo de direcionar os visitantes para ações específicas e aumentar as conversões.",
                            interfaceDesign: "Design de Interface",
                            descriptionDesignInterface: "Design de Interface - Página Inicial: Nessa atividade, é realizado o design da página inicial do site ou plataforma, considerando aspectos visuais, como layout, cores, tipografia e elementos gráficos, buscando transmitir a identidade visual da marca e proporcionar uma experiência estética agradável aos usuários.",
                            promotionalHomePage: "Página Home promocional",
                            descriptionPromotionalHomePage: "Desenv. de Interface - Página Home promocional: Aqui, é desenvolvida uma versão promocional da página inicial, destacando ofertas, descontos ou produtos em destaque, com o objetivo de impulsionar as vendas e atrair a atenção dos visitantes.",
                            descriptionDesignCategory: "Design de Interface - Página de Categoria: É desenvolvido o design da página de categoria, levando em consideração a organização e disposição dos produtos, a aplicação de filtros de pesquisa, a apresentação de imagens e informações relevantes, com o objetivo de facilitar a navegação e tornar a experiência do usuário mais intuitiva.",
                            mainElements: "Elementos Principais: header, footer e folha de estilos",
                            descriptionMainElements: "Desenv. de Interface - Elementos Principais: header, footer e folha de estilos: Nessa atividade, são desenvolvidos os elementos principais de uma interface, como o cabeçalho (header), rodapé (footer) e a estilização geral da página (folha de estilos), garantindo uma aparência consistente e profissional em todo o site.",
                            spreadsheetCreation: "Criação/Correção de Planilha",
                            descriptionSpreadsheetCreation: "Inventário - Criação/Correção de Planilha: Essa atividade envolve a criação ou correção de uma planilha utilizada para o controle e organização dos produtos, garantindo a consistência e precisão das informações.",
                            descriptionDesignProduct: "Design de Interface - Página de Produto: Aqui, é realizado o design da página de produto, levando em conta a apresentação das imagens, descrições, opções de variação, botões de compra e demais elementos visuais, visando destacar as características e atrativos do produto, bem como fornecer uma interface clara e atraente aos clientes.",
                            descriptionDesignDiversePage: 'Design de Interface - Página Diversas: carrinho, minha conta, sobre, etc: São criados os designs das diferentes páginas secundárias ou complementares, como carrinho de compras, área de conta do usuário, página "Sobre nós" ou outras páginas institucionais, seguindo a identidade visual da marca e garantindo a consistência estética em todo o site.',
                            descriptionDesignLandingPage: "Design de Interface - Landing Page: promocional, upsell, carta de vendas, etc: É realizado o design de landing pages, que são projetadas de forma atrativa e persuasiva, com elementos visuais impactantes, conteúdo persuasivo e um call-to-action claro, com o objetivo de direcionar os visitantes para uma ação específica, como fazer uma compra ou fornecer informações de contato.",
                            descriptionDesignPromotionalHomePage: "Design de Interface - Página Home promocional: Essa atividade envolve o design de uma versão promocional da página inicial, destacando ofertas, descontos ou produtos em destaque por meio de imagens, banners ou outros elementos visuais, com o objetivo de chamar a atenção dos visitantes e incentivar a conversão.",
                            uiKit: "UI Kit",
                            descriptionUiKit: "Design de Interface - UI Kit: Nessa atividade, é criado um conjunto de elementos de interface (UI Kit), que inclui botões, ícones, caixas de seleção, barras de rolagem, entre outros, seguindo uma identidade visual consistente e facilitando o desenvolvimento e design de novas páginas e elementos da interface.",
                            uxDesignerHour: "Designer UX (por hora)",
                            descriptionUxDesignerHour: "Design de Interface - Designer UX (por hora): Um designer UX (User Experience) é responsável por criar uma experiência de usuário positiva e intuitiva, analisando fluxos de navegação, interações, organização de informações e usabilidade do site ou plataforma. Nessa atividade, o designer UX trabalha em projetos específicos por hora, visando melhorar a experiência do usuário.",
                            emailDesign: "Design de E-mail (Autoresponder, Broadcast e Transacional)",
                            graphicDesign: "Design Gráfico",
                            perNotification: "por notificação",
                            descriptionEmailDesign: "Design Gráfico - Design de E-mail (Autoresponder, Broadcast e Transacional): É realizado o design de e-mails para diferentes finalidades, como autoresponder (respostas automáticas), broadcast (envio em massa) e transacional (relacionado a ações específicas do usuário). Os e-mails podem incluir elementos gráficos, imagens, texto formatado e chamadas à ação.",
                            every10Pages: "a cada 10 páginas",
                            ebookDesign: "E-book Design e Diagramação",
                            descriptionEbookDesign: "Design Gráfico - E-book Design e Diagramação: Nessa atividade, é realizado o design e diagramação de e-books, que são materiais digitais mais longos, como guias, tutoriais, catálogos ou livros eletrônicos. O design e diagramação visam tornar o conteúdo visualmente atrativo e de fácil leitura, com elementos gráficos, ilustrações e formatação adequada.",
                            imageTreatment: "Tratamento de imagem (para web)",
                            imageEditing: "Edição de Imagem",
                            perPhoto: "por foto",
                            descriptionImageTreatment: "Edição de Imagem - Tratamento de imagem (para web): Aqui, as imagens são editadas e tratadas de forma adequada para a web, incluindo ajustes de tamanho, otimização de qualidade, recorte, correção de cores, remoção de imperfeições, entre outros. O objetivo é garantir que as imagens sejam visualmente atraentes e carreguem rapidamente nas páginas do site ou plataforma.",
                            imageBackgroundCutting: "Recortar fundo de imagem (para web)",
                            descriptionImageBackgroundCutting: "Edição de Imagem - Recortar fundo de imagem (para web): Nessa atividade, é realizado o recorte do fundo de uma imagem, removendo elementos indesejados ou isolando o objeto principal. Esse recorte é feito para uso específico na web, permitindo a inserção do objeto recortado em diferentes contextos sem o fundo original.",
                            videoAdsDesign: "Design de Ads em Vídeo (15seg)",
                            videoEditing: "Edição de Vídeo",
                            perAd: "por anúncio",
                            descriptionVideoAdsDesign: "Edição de Vídeo - Design de Ads em Vídeo (15seg): Essa atividade envolve a criação de anúncios em vídeo com duração de 15 segundos. É realizado o design visual do anúncio, incluindo a seleção de imagens, texto, elementos gráficos e transições, buscando capturar a atenção do público-alvo e transmitir a mensagem de forma eficiente no curto tempo disponível.",
                            motionGraphics: "Motion Graphics",
                            perMinute: "por minuto",
                            descriptionMotionGraphics: "Edição de Vídeo - Motion Graphics: Nessa atividade, são criados elementos gráficos em movimento para vídeos, utilizando técnicas de motion graphics. Esses elementos podem incluir animações, transições, infográficos animados, textos em movimento, entre outros, tornando o vídeo mais dinâmico e visualmente interessante.",
                            videoAdsDesign30: "Design de Ads em Vídeo (30seg)",
                            descriptionVideoAdsDesign30: "Edição de Vídeo - Design de Ads em Vídeo (30seg): Similar à atividade anterior, porém, nesse caso, os anúncios em vídeo têm duração de 30 segundos. O objetivo é criar conteúdo visualmente atraente e envolvente dentro desse período de tempo para atrair e engajar os espectadores.",
                            videoEditingPerMin: "Edição de vídeo (pontuação por minutagem)",
                            descriptionVideoEditingPerMin: "Edição de Vídeo - Edição de vídeo (pontuação por minutagem): Aqui, é realizada a edição de vídeos já gravados, incluindo corte, junção de cenas, adição de transições, correção de cor, ajuste de áudio e demais ajustes necessários. A atividade é remunerada com base na duração do vídeo, medida em minutos.",
                            videoCaptioningPerMin: "Legendação de vídeo (pontuação por minutagem)",
                            descriptionVideoCaptioningPerMin: "Edição de Vídeo - Legendação de vídeo (pontuação por minutagem): É adicionada a legenda aos vídeos, transcrevendo o conteúdo falado e sincronizando-o com o vídeo. Essa atividade é remunerada com base na duração do vídeo, medida em minutos.",
                            couponCreation: "Criação de Cupons",
                            ecommerceManagement: "Gestão de E-commerce",
                            perRule: "por regra",
                            descriptionCouponCreation: "Gestão de E-commerce - Criação de Cupons: Aqui, são criados cupons de desconto que os clientes podem utilizar em suas compras. Os cupons podem ser personalizados, com códigos exclusivos e regras específicas de utilização, contribuindo para aumentar as vendas e fidelizar os clientes.",
                            captationForm: "Formulário de Captação",
                            descriptionCaptationForm: "Gestão de E-commerce - Formulário de Captação: É criado um formulário de captação de dados, que pode ser utilizado para capturar informações de contato dos clientes, como nome, e-mail, telefone, entre outros. Esses formulários são integrados ao site ou plataforma e podem ser utilizados para construir uma lista de contatos ou segmentar o público-alvo.",
                            scriptManagement: "Gestão de Scripts e Rastreadores (via GTM)",
                            perScript: "por script",
                            descriptionScriptManagement: "Gestão de E-commerce - Gestão de Scripts e Rastreadores (via GTM): Nessa atividade, são gerenciados os scripts e rastreadores (tags) do site ou plataforma por meio do Google Tag Manager (GTM). Isso envolve a criação, edição e exclusão de tags para rastreamento de eventos, conversões, análise de comportamento do usuário, entre outros.",
                            manualPaymentReconciliation: "Conciliação Manual de Pagamentos",
                            perTransaction: "por transação",
                            descriptionManualPaymentReconciliation: "Gestão de E-commerce - Conciliação Manual de Pagamentos: Aqui, é realizada a conciliação manual dos pagamentos recebidos pela empresa, verificando se os valores registrados no sistema estão corretos e correspondem aos pagamentos efetivamente recebidos.",
                            merchandisingAutomation: "Configurar automação de merchandising (Launchpad)",
                            perAction: "por ação",
                            descriptionMerchandisingAutomation: "Gestão de E-commerce - Configurar automação de merchandising (Launchpad): Nessa atividade, é configurada a automação de merchandising por meio da plataforma Launchpad. Isso envolve a definição de regras e condições para a exibição de banners, vitrines ou promoções específicas com base em critérios como perfil do cliente, período de tempo, categorias de produtos, entre outros.",
                            taskAutomation: "Configurar automação de tarefas (Flow)",
                            perAutomation: "por automação",
                            descriptionTaskAutomation: "Gestão de E-commerce - Configurar automação de tarefas (Flow): São configuradas automações de tarefas por meio da plataforma Flow, visando automatizar processos internos do e-commerce. Isso pode incluir o envio automático de e-mails de acompanhamento de pedidos, atualizações de status, solicitações de feedback, entre outras ações relacionadas ao relacionamento com o cliente.",
                            reviewsModeration: "Moderação de avaliações",
                            perReview: "por avaliação",
                            descriptionReviewsModeration: "Gestão de E-commerce - Moderação de avaliações: Nessa atividade, é realizada a moderação das avaliações e comentários deixados pelos clientes nos produtos ou serviços da empresa. A moderação pode envolver a aprovação, edição ou exclusão de avaliações, garantindo a qualidade e apropriada representação das opiniões dos clientes.",
                            adConfiguration: "Configuração de Anúncio",
                            marketplaceManagement: "Gestão de Marketplace",
                            perMarketplace: "por marketplace",
                            descriptionAdConfiguration: "Gestão de Marketplace - Configuração de Anúncio: Nessa atividade, são realizadas as configurações necessárias para a publicação de anúncios em marketplaces, como Amazon, eBay ou Mercado Livre. Isso pode incluir a criação de títulos, descrições, imagens, categorias e informações adicionais para cada anúncio.",
                            adKitConfiguration: "Configuração de Kit para Anúncio",
                            descriptionAdKitConfiguration: "Gestão de Marketplace - Configuração de Kit para Anúncio: É realizada a configuração de kits de produtos para anúncios em marketplaces. Os kits são conjuntos de produtos vendidos em conjunto, com um único preço, permitindo oferecer pacotes ou combinações especiais aos clientes.",
                            priceManagement: "Gestão de Preços",
                            perProduct: "por produto",
                            descriptionPriceManagement: "Gestão de Marketplace - Gestão de Preços: Nessa atividade, é realizada a gestão de preços dos produtos nos marketplaces, considerando estratégias de precificação, análise da concorrência, margem de lucro e metas de vendas. Os preços são ajustados de acordo com as demandas e condições do mercado.",
                            rulesConfiguration: "Configuração de Regras",
                            descriptionRulesConfiguration: "Gestão de Marketplace - Configuração de Regras: São configuradas regras específicas nos marketplaces, considerando políticas de vendas, restrições de produtos, políticas de envio e demais diretrizes estabelecidas pela plataforma. Isso garante que a empresa esteja em conformidade com as regras e regulamentos de cada marketplace.",
                            freightManagement: "Gestão de Fretes",
                            descriptionFreightManagement: "Gestão de Marketplace - Gestão de Fretes: Aqui, é realizada a gestão dos fretes para os produtos vendidos nos marketplaces. Isso inclui a configuração das opções de entrega, cálculo dos custos de envio, integração com serviços de transporte e atualização das informações de rastreamento para os clientes.",
                            dnsEntriesEditing: "Edição entradas DNS",
                            deployment: "Implantação",
                            perPointing: "por apontamento",
                            descriptionDnsEntriesEditing: "Implantação - Edição entradas DNS: Nessa atividade, são realizadas edições nas entradas de DNS (Domain Name System) do site ou plataforma, configurando a resolução de domínio, redirecionamento de URLs ou definição de registros específicos, como SPF (Sender Policy Framework) ou DKIM (DomainKeys Identified Mail), para garantir a segurança e o correto funcionamento do domínio.",
                            integrationMiddleware: "Integração (via middleware)",
                            perConnection: "por conexão",
                            descriptionIntegrationMiddleware: "Implantação - Integração (via middleware): É realizada a integração entre diferentes sistemas ou plataformas de e-commerce por meio de um middleware da CheckStore (n8n), que atua como um intermediário para facilitar a troca de informações e sincronização de dados. Isso permite a comunicação eficiente entre diferentes sistemas e a integração de processos comerciais. ",
                            scriptInstallationCode: "Instalação de Scripts e Rastreadores (via código)",
                            descriptionScriptInstallationCode: "Implantação - Instalação de Scripts e Rastreadores (via código): Nessa atividade, são instalados scripts e rastreadores no site ou plataforma por meio da inserção de código personalizado. Esses scripts e rastreadores podem ser fornecidos por terceiros e são utilizados para coletar dados, como análises de tráfego, monitoramento de conversões, integração com ferramentas de marketing, entre outros.",
                            scriptInstallationGtm: "Instalação de Scripts e Rastreadores (via GTM)",
                            descriptionScriptInstallationGtm: "Implantação - Instalação de Scripts e Rastreadores (via GTM): Aqui, os scripts e rastreadores são instalados no site ou plataforma por meio do Google Tag Manager (GTM). O GTM facilita a implementação e gerenciamento de tags de rastreamento, permitindo a adição, edição e exclusão dessas tags sem a necessidade de modificar o código do site diretamente.",
                            frontEndDevHour: "Desenvolvimento Front-end (por hora)",
                            descriptionFrontEndDevHour: "Implantação - Desenvolvimento Front-end (por hora): Nessa atividade, é realizado o desenvolvimento da camada front-end do site ou plataforma. Isso envolve a criação e implementação de elementos visuais, como layouts, estilos, interações e animações, utilizando tecnologias como HTML, CSS e JavaScript para garantir uma experiência de usuário atraente e responsiva.",
                            fullStackDevHour: "Desenvolvimento Full-stack (por hora)",
                            descriptionFullStackDevHour: "Implantação - Desenvolvimento Full-stack (por hora): Aqui, é realizado o desenvolvimento full-stack do site ou plataforma, abrangendo tanto a camada front-end quanto a camada back-end. Isso inclui a implementação de funcionalidades, integrações com bancos de dados, lógica de negócios, autenticação de usuários, entre outras tarefas, utilizando linguagens de programação como JavaScript, Python, Ruby ou PHP, de acordo com as necessidades do projeto.",
                            emailScheduling: "Agendamento de E-mail",
                            digitalMarketing: "Marketing Digital",
                            descriptionEmailScheduling: "Marketing Digital - Agendamento de E-mail: Nessa atividade, são agendados o envio de e-mails promocionais, informativos ou transacionais para os clientes. Isso envolve a criação do conteúdo do e-mail, seleção dos destinatários, definição de datas e horários de envio, além da configuração de automações de e-mail marketing para fornecer mensagens personalizadas e relevantes aos destinatários.",
                            softwareArchitectHour: "Arquiteto de Software (por hora)",
                            descriptionSoftwareArchitectHour: "Implantação - Arquiteto de Software (por hora): Nessa atividade, um arquiteto de software é contratado por hora para fornecer orientações estratégicas e técnicas relacionadas à arquitetura do site ou plataforma. O arquiteto de software analisa os requisitos do projeto, define a estrutura do sistema, seleciona as tecnologias adequadas e estabelece as diretrizes de desenvolvimento para garantir um projeto robusto, escalável e de alta qualidade.",
                            pushNotificationSms: "Notificação Push/SMS",
                            descriptionPushNotificationSms: "Marketing Digital - Notificação Push/SMS: Aqui, são configuradas notificações push ou mensagens SMS que são enviadas aos usuários por meio de dispositivos móveis ou web. Essas notificações podem ser utilizadas para enviar atualizações, alertas, ofertas exclusivas ou lembretes aos clientes, visando manter o engajamento e promover a interação com o e-commerce.",
                            customerSegmentsConfig: "Configuração de segmentos de clientes",
                            perSegment: "por segmento",
                            descriptionCustomerSegmentsConfig: "Marketing Digital - Configuração de segmentos de clientes: Nessa atividade, são configurados segmentos de clientes com base em critérios específicos, como comportamento de compra, preferências, histórico de interações ou informações demográficas. A segmentação permite direcionar mensagens personalizadas para grupos específicos de clientes, aumentando a relevância e eficácia das campanhas de marketing.",
                            marketingAutoFunnelSetup: "Setup do Funil de Automação de Marketing",
                            perFunnel: "por funil",
                            descriptionMarketingAutoFunnelSetup: "Marketing Digital - Setup do Funil de Automação de Marketing: Aqui, é realizado o setup do funil de automação de marketing, que consiste em uma sequência de etapas automatizadas para nutrir leads, converter clientes em potencial e promover a fidelização. Isso envolve a criação de fluxos de automação, definição de gatilhos, configuração de e-mails automatizados e acompanhamento do desempenho do funil.",
                            customEventsSetup: "Setup de Eventos Personalizados (via GTM)",
                            perEvent: "por evento",
                            descriptionCustomEventsSetup: "Marketing Digital - Setup de Eventos Personalizados (via GTM): Nessa atividade, são configurados eventos personalizados no Google Tag Manager (GTM) para rastrear ações específicas dos usuários no site ou plataforma. Esses eventos podem incluir cliques em botões, envio de formulários, visualização de páginas, reprodução de vídeos, entre outros. O rastreamento dos eventos personalizados permite obter insights sobre o comportamento do usuário e otimizar as estratégias de marketing.",
                            scPressAdvisory: "Assessoria de Imprensa (mídia local SC)",
                            perCycle: "por ciclo",
                            descriptionScPressAdvisory: "Marketing Digital - Assessoria de Imprensa (mídia local SC): Essa atividade envolve a assessoria de imprensa focada na mídia local de Santa Catarina (SC). É realizado o contato com veículos de comunicação locais, elaboração de releases, agendamento de entrevistas e acompanhamento de notícias e menções à empresa na mídia local.",
                            nationalPressAdvisory: "Assessoria de Imprensa (mídia nacional)",
                            descriptionNationalPressAdvisory: "Marketing Digital - Assessoria de Imprensa (mídia nacional): Aqui, é realizada a assessoria de imprensa voltada para a mídia nacional. São estabelecidos contatos com jornalistas, elaboração de comunicados de imprensa, divulgação de notícias e conquista de espaços em veículos de comunicação de abrangência nacional.",
                            ebookTextOnly: "E-book (apenas texto)",
                            descriptionEbookTextOnly: "Marketing Digital - E-book (apenas texto): Nessa atividade, é desenvolvido um e-book contendo apenas texto, que aborda um tópico relevante e interessante para o público-alvo do e-commerce. O e-book pode servir como material educacional, guia prático, compilação de dicas ou qualquer outro formato que forneça informações valiosas aos leitores.",
                            pressAdvisoryClipping: "Assessoria de Imprensa (clipping)",
                            descriptionPressAdvisoryClipping: "Marketing Digital - Assessoria de Imprensa (clipping): Nessa atividade, é realizado o clipping de notícias relacionadas à empresa em veículos de comunicação. São monitoradas as menções, reportagens e artigos que mencionam a empresa, sendo compiladas e organizadas para análise e acompanhamento.",
                            productAdvertisingWriting: "Redação publicitária de produto",
                            advertisingWriting: "Redação Publicitária",
                            descriptionProductAdvertisingWriting: "Redação Publicitária - Redação publicitária de produto: Aqui, são elaborados textos publicitários específicos para a descrição e promoção de produtos do e-commerce. Os textos são escritos de forma persuasiva, destacando os benefícios, características e diferenciais dos produtos, com o objetivo de incentivar a compra e despertar o interesse do público-alvo.",
                            pagesContentWriting: "Redação de Conteúdo de Páginas",
                            descriptionPageContentWriting: "Redação Publicitária - Redação de Conteúdo de Páginas: Aqui, são elaborados textos publicitários para o conteúdo das páginas do e-commerce, incluindo a página inicial, páginas institucionais, páginas especiais de produto, páginas especiais de categoria, entre outras. Os textos são escritos de forma atraente, informativa e persuasiva, visando cativar os visitantes e levá-los a realizar ações desejadas, como efetuar uma compra ou entrar em contato.",
                            customMktReport: "Relatório Personalizado de Marketing",
                            reports: "Relatórios",
                            perMetric: "por métrica",
                            descriptionCustomMktReport: "Relatórios - Relatório Personalizado de Marketing: Nessa atividade, são elaborados relatórios personalizados que apresentam uma análise abrangente das estratégias de marketing adotadas pelo e-commerce. Os relatórios podem incluir métricas como tráfego do site, origem do tráfego, taxas de conversão, engajamento nas redes sociais, análise de campanhas de e-mail marketing, análise de conteúdo, entre outras informações que ajudam a avaliar a eficácia das estratégias de marketing e orientar decisões futuras.",
                            customSalesReport: "Relatório Personalizado de Vendas",
                            descriptionCustomSalesReport: "Relatórios - Relatório Personalizado de Vendas: Aqui, são desenvolvidos relatórios personalizados que fornecem uma visão abrangente das vendas realizadas no e-commerce. Os relatórios podem incluir métricas como volume de vendas, receita, ticket médio, margem de lucro, análise de produtos mais vendidos, análise de clientes, entre outras informações relevantes para monitorar o desempenho e o sucesso das vendas.",
                            seoContent: "Conteúdo de SEO",
                            seo: "SEO",
                            perUrl: "por URL",
                            descriptionSeoContent: "SEO - Conteúdo de SEO: Nessa atividade, é criado e otimizado o conteúdo do e-commerce para melhorar sua visibilidade nos mecanismos de busca. Isso envolve a pesquisa de palavras-chave relevantes, a criação de textos e descrições otimizadas, a implementação de tags HTML adequadas e outras práticas recomendadas para melhorar o posicionamento do site nos resultados de busca.",
                            seoAdjustment: "Ajuste técnico para SEO",
                            descriptionSeoAdjustment: "SEO - Ajuste técnico para SEO: Aqui, são realizados ajustes técnicos no site ou plataforma para otimização de mecanismos de busca. Isso pode incluir a otimização da estrutura do site, a melhoria da velocidade de carregamento das páginas, a configuração adequada de metadados, a otimização de URLs, a criação de sitemaps e a implementação de marcações estruturadas, entre outros.",
                            facebookBusiness: "Facebook Business",
                            marketingSetup: "Setup de Marketing",
                            perService: "por serviço",
                            descriptionFacebookBusiness: "Setup de Marketing - Facebook Business: Nessa atividade, é realizado o setup da conta do Facebook Business para o e-commerce. Isso envolve a criação da conta, a configuração das informações da empresa, a associação de páginas do Facebook, a definição de permissões de acesso e a realização de integrações com outras ferramentas de marketing do Facebook.",
                            facebookPage: "Facebook Página",
                            descriptionFacebookPage: "Setup de Marketing - Facebook Página: Aqui, é realizado o setup da página do Facebook para o e-commerce. Isso inclui a criação da página, a personalização do layout e das informações, a definição de categorias, a adição de elementos visuais e a configuração das configurações de privacidade e segurança.",
                            fbInstagramAccount: "Facebook Conta Instagram",
                            descriptionFbInstagramAccount: "Setup de Marketing - Facebook Conta Instagram: Nessa atividade, é realizado o setup da conta do Instagram vinculada ao e-commerce. Isso envolve a criação da conta, a configuração das informações do perfil, a associação com a página do Facebook e a realização de integrações com outras ferramentas de marketing do Instagram.",
                            fbAdsAccount: "Facebook Conta de Anúncios",
                            descriptionFbAdsAccount: "Setup de Marketing - Facebook Conta de Anúncios: Aqui, é realizado o setup da conta de anúncios do Facebook para o e-commerce. Isso inclui a criação da conta, a configuração de informações de faturamento, a definição de configurações de privacidade e segurança, e a associação com a página do Facebook e a conta do Instagram.",
                            fbPixel: "Facebook Pixel",
                            descriptionFbPixel: "Setup de Marketing - Facebook Pixel: Nessa atividade, é realizado o setup do Facebook Pixel, uma ferramenta que permite rastrear e medir as ações dos usuários no site ou plataforma. Isso inclui a geração do código do pixel, a inserção adequada no site, a configuração de eventos e conversões a serem rastreados, e a verificação do funcionamento correto do pixel.",
                            fbProductCatalog: "Facebook Catálogo de Produtos",
                            descriptionFbProductCatalog: "Setup de Marketing - Facebook Catálogo de Produtos: Aqui, é realizado o setup do catálogo de produtos do Facebook, que permite exibir e promover os produtos do e-commerce nas plataformas do Facebook, como o Instagram e o Facebook Marketplace. Isso inclui a criação e configuração do catálogo, a sincronização com os produtos do e-commerce, a adição de informações e imagens dos produtos e a configuração das configurações de exibição e segmentação.",
                            fbPixelTrafficPermissions: "Facebook Permissões de Tráfego do Pixel",
                            descriptionFbPixelTrafficPermissions: "Setup de Marketing - Facebook Permissões de Tráfego do Pixel: Nessa atividade, são configuradas as permissões de tráfego do Facebook Pixel, determinando quais domínios ou URLs estão autorizados a enviar dados para o pixel e quais domínios ou URLs têm permissão para receber dados do pixel. Isso ajuda a garantir a segurança e a integridade das informações rastreadas e compartilhadas pelo pixel.",
                            dataLayer: "DataLayer",
                            descriptionDataLayer: "Setup de Marketing - DataLayer: Aqui, é realizado o setup do DataLayer, uma estrutura de dados que permite a coleta e o compartilhamento de informações específicas sobre eventos e interações do usuário no site ou plataforma. Isso envolve a implementação adequada do DataLayer no código do site, a definição de variáveis e valores a serem capturados e a configuração de integrações com outras ferramentas de marketing.",
                            googleTagManager: "Google Tag Manager",
                            descriptionGtm: "Setup de Marketing - Google Tag Manager: Nessa atividade, é realizado o setup do Google Tag Manager (GTM), uma ferramenta que permite gerenciar de forma centralizada a implementação de tags e rastreadores no site ou plataforma. Isso inclui a criação da conta do GTM, a configuração da estrutura de contêiner, a implementação do código do GTM no site e a configuração das tags e acionadores necessários para rastrear eventos e interações específicas.",
                            googleAnalytics: "Google Analytics",
                            descriptionGoogleAnalytics: "Setup de Marketing - Google Analytics: Aqui, é realizado o setup do Google Analytics, uma ferramenta de análise de dados que fornece informações detalhadas sobre o tráfego, comportamento do usuário, conversões e outras métricas importantes do site ou plataforma. Isso envolve a criação da conta do Google Analytics, a configuração do código de acompanhamento, a definição de metas e conversões, e a personalização de relatórios e painéis de controle.",
                            advancedEcommerceGA: "Google Analytics Comércio Eletrônico Avançado",
                            descriptionAdvancedGA: "Setup de Marketing - Google Analytics Comércio Eletrônico Avançado: Nessa atividade, é realizado o setup de recursos avançados do Google Analytics para análise de comércio eletrônico. Isso inclui a configuração de acompanhamento de transações, análise de funil de conversão, análise de produtos e categorias, análise de desempenho de vendas, entre outras métricas específicas para o comércio eletrônico.",
                            googleSearchConsole: "Google Search Console",
                            descriptionGoogleSearchConsole: "Setup de Marketing - Google Search Console: Nessa atividade, é realizado o setup do Google Search Console, uma ferramenta que fornece informações detalhadas sobre a presença do site nos resultados de pesquisa do Google. Isso inclui a criação da conta do Search Console, a verificação da propriedade do site, o envio do sitemap, o acompanhamento do desempenho de pesquisa, a identificação de erros e problemas de indexação, e o monitoramento das palavras-chave e posições nos resultados de pesquisa.",
                            googleMerchantCenter: "Google Merchant Center",
                            descriptionGoogleMerchantCenter: "Setup de Marketing - Google Merchant Center: Aqui, é realizado o setup do Google Merchant Center, uma plataforma que permite que os comerciantes façam upload e gerenciem informações de produtos para serem exibidas nos resultados de pesquisa do Google e em outros serviços do Google, como o Google Shopping. Isso envolve a criação da conta do Merchant Center, a configuração das informações do negócio, a importação de dados do catálogo de produtos, a configuração de configurações de exibição e segmentação, e a verificação da conformidade com as políticas do Google.",
                            googleAds: "Google Ads",
                            descriptionGoogleAds: "Setup de Marketing - Google Ads: Nessa atividade, é realizado o setup do Google Ads (anteriormente conhecido como Google AdWords), uma plataforma de publicidade online que permite a criação e gerenciamento de campanhas de anúncios pagos nos mecanismos de busca do Google e em outros sites parceiros. Isso inclui a criação da conta do Google Ads, a configuração de informações de faturamento, a definição de configurações de campanha, a criação de grupos de anúncios, a seleção de palavras-chave relevantes e a criação de anúncios persuasivos.",
                            googleAdsConvBuy: "Google Ads Conv. Compra",
                            descriptionGoogleAdsConvBuy: "Setup de Marketing - Google Ads Conv. Compra: Aqui, é realizado o setup da configuração de conversões de compra no Google Ads. Isso envolve a configuração de tags e eventos para rastrear conversões específicas relacionadas a transações e compras no e-commerce, permitindo a medição do retorno sobre o investimento (ROI) das campanhas de anúncios pagos.",
                            googleAdsRemarketing: "Google Ads Remarketing",
                            descriptionGoogleAdsRemarketing: "Setup de Marketing - Google Ads Remarketing: Nessa atividade, é realizado o setup de campanhas de remarketing no Google Ads. Isso envolve a criação de listas de remarketing com base no comportamento do usuário no site ou plataforma, a configuração de anúncios personalizados direcionados a essas listas e a definição de estratégias de lances e segmentação para alcançar os usuários que já interagiram com o e-commerce.",
                            googleAdsAnalyticsLink: "Vinculação do Google Ads com Google Analytics",
                            descriptionGoogleAdsLinking: "Setup de Marketing - Vinculação do Google Ads com Google Analytics: Nessa atividade, é realizada a vinculação da conta do Google Ads com a conta do Google Analytics. Isso permite a sincronização de dados entre as duas plataformas, fornecendo uma visão integrada das métricas e do desempenho das campanhas de anúncios pagos e seu impacto nas interações dos usuários no site ou plataforma.",
                            googleAdsConvLink: "Google Ads Vinculador de conversões",
                            descriptionGoogleAdsConvLinker: "Setup de Marketing - Google Ads Vinculador de conversões: Aqui, é realizado o setup do vinculador de conversões do Google Ads, que permite associar conversões rastreadas no Google Analytics às campanhas e anúncios específicos no Google Ads. Isso facilita a análise do desempenho das campanhas e a atribuição correta das conversões geradas pelos anúncios pagos.",
                            googleAdsMerchantLink: "Vinculação do Google Ads com Merchant Center",
                            descriptionGoogleAdsMerchantLinking: "Setup de Marketing - Vinculação do Google Ads com Merchant Center: Aqui, é realizada a vinculação da conta do Google Ads com a conta do Google Merchant Center. Isso permite a sincronização de dados sobre produtos e campanhas de anúncios pagos, possibilitando a exibição de anúncios de produtos relevantes nos resultados de pesquisa e no Google Shopping.",
                            ga4EventsImplementation: "Implementação dos eventos do GA4 via Google Tag Manager",
                            descriptionGA4EventsImplementation: "Setup de Marketing - Implementação dos eventos do GA4 via Google Tag Manager: Aqui, é realizada a implementação dos eventos do Google Analytics 4 (GA4) por meio do Google Tag Manager. Isso inclui a configuração de eventos personalizados no GTM, a criação de variáveis e acionadores para capturar interações específicas do usuário no site ou plataforma e o envio dos dados correspondentes para o GA4 para análise.",
                            ga4ReportsImplementation: "Implementação dos relatórios do GA4",
                            descriptionGA4ReportsImplementation: "Setup de Marketing - Implementação dos relatórios do GA4: Nessa atividade, é realizada a implementação dos relatórios personalizados no Google Analytics 4 (GA4). Isso envolve a configuração de relatórios específicos para fornecer insights e métricas relevantes para o e-commerce, como análise de funil de conversão, análise de eventos, análise de audiência e outras informações que auxiliam na compreensão do comportamento e desempenho dos usuários.",
                            marketingCloudTag: "Tag da Plataforma de Marketing Cloud",
                            descriptionMarketingCloudTag: "Setup de Marketing - Tag da Plataforma de Marketing Cloud: Nessa atividade, é realizado o setup da tag da plataforma de marketing cloud no site ou plataforma. Isso permite a coleta de dados sobre o comportamento do usuário, interações, conversões e outras informações relevantes para análise e personalização de marketing baseado em dados.",
                            multilingualTranslation: "Tradução multilíngua por página/template",
                            translation: "Tradução",
                            descriptionMultilanguageTranslation: "Tradução - Tradução multilíngua por página/template: Nessa atividade, é realizada a tradução de páginas ou templates do e-commerce para outros idiomas. Isso inclui a tradução de textos, descrições de produtos, botões, menus e outros elementos visuais e de comunicação para fornecer uma experiência localizada aos usuários que falam diferentes idiomas.",
                            searchAdsCampaignSetup: "Setup Campanha de Search Ads",
                            paidTraffic: "Tráfego Pago",
                            adSet: "conjunto de anúncios",
                            descriptionSearchAdsCampaignSetup: "Tráfego Pago - Setup Campanha de Search Ads: Aqui, é realizado o setup de campanhas de anúncios de Search Ads, que são exibidos nos resultados de pesquisa dos mecanismos de busca. Isso envolve a configuração das palavras-chave, criação de anúncios relevantes e a definição de lances e orçamentos para alcançar os usuários interessados nos produtos ou serviços do e-commerce.",
                            productListingAdsCampaignSetup: "Setup Campanha de Product Listing Ads (Shopping)",
                            descriptionProductListingAdsCampaignSetup: "Tráfego Pago - Setup Campanha de Product Listing Ads (Shopping): Aqui, é realizado o setup de campanhas de anúncios de Product Listing Ads (PLAs), que são exibidos nos resultados de pesquisa do Google Shopping. Isso inclui a criação do catálogo de produtos no Merchant Center, a configuração das informações de produtos, a definição de lances e orçamentos para exibir os anúncios de produtos relevantes aos usuários.",
                            leadAdsCampaignSetup: "Setup Campanha de Lead Ads",
                            descriptionLeadAdsCampaignSetup: "Tráfego Pago - Setup Campanha de Lead Ads: Aqui, é realizado o setup de campanhas de anúncios de Lead Ads, que são projetados para capturar informações de contato e gerar leads qualificados. Isso envolve a criação de formulários de geração de leads, a definição de critérios e segmentação para exibir os anúncios aos usuários relevantes, e a configuração dos fluxos de acompanhamento e integrações com ferramentas de automação de marketing.",
                            displayAdsCampaignSetup: "Setup Campanha de Display Ads",
                            descriptionDisplayAdsCampaignSetup: "Tráfego Pago - Setup Campanha de Display Ads: Nessa atividade, é realizado o setup de campanhas de anúncios de Display Ads, que são exibidos em sites parceiros por meio de banners, imagens ou vídeos. Isso inclui a configuração dos critérios de segmentação, a criação de anúncios persuasivos e a definição de estratégias de lances e orçamentos para alcançar o público-alvo do e-commerce.",
                            videoAdsCampaignSetup: "Setup Campanha de Vídeo Ads",
                            descriptionVideoAdsCampaignSetup: "Tráfego Pago - Setup Campanha de Vídeo Ads: Aqui, é realizado o setup de campanhas de anúncios de Vídeo Ads, que são vídeos promocionais exibidos em plataformas de vídeo, como YouTube. Isso envolve a criação de vídeos relevantes e impactantes, a configuração dos critérios de segmentação, e a definição de estratégias de lances e orçamentos para alcançar o público-alvo do e-commerce.",
                            interestAudiencesConfig: "Configuração de públicos de interesse",
                            descriptionInterestAudiencesConfig: "Tráfego Pago - Configuração de públicos de interesse: Nessa atividade, são configurados públicos de interesse segmentados para as campanhas de tráfego pago. Isso envolve a definição de critérios demográficos, comportamentais e de interesse para segmentar os anúncios aos usuários mais propensos a se interessarem pelos produtos ou serviços do e-commerce.",
                            customAudiencesConfig: "Configuração de públicos personalizados",
                            descriptionCustomAudiencesConfig: "Tráfego Pago - Configuração de públicos personalizados: Aqui, são configurados públicos personalizados para as campanhas de tráfego pago. Isso pode incluir a criação de públicos com base em dados de clientes existentes, listas de e-mails, visitantes do site, interações em redes sociais e outras fontes de dados relevantes. Esses públicos personalizados permitem direcionar os anúncios aos usuários mais qualificados e propensos a se converterem em clientes.",
                            campaignOptimization: "Otimização de campanhas",
                            perDay: "por dia",
                            descriptionCampaignOptimization: "Tráfego Pago - Otimização de campanhas: Nessa atividade, são realizadas otimizações contínuas nas campanhas de tráfego pago para maximizar o desempenho e os resultados. Isso envolve análise de métricas, ajustes de lances e orçamentos, refinamento de segmentação, testes de criativos e mensagens, e implementação de estratégias de otimização para alcançar os objetivos definidos.",
                            navigationUsabilityAnalysis: "Análise de Usabilidade de Navegação",
                            uiUx: "UI & UX",
                            descriptionNavigationUsabilityAnalysis: "UI & UX - Análise de Usabilidade de Navegação: Nessa atividade, é realizada uma análise detalhada da usabilidade da navegação do site ou plataforma. Isso envolve a revisão da arquitetura de informação, organização de menus, fluxo de navegação, categorização de produtos e outras interações do usuário. O objetivo é identificar pontos de melhoria, simplificar a navegação e proporcionar uma experiência mais intuitiva e agradável para os usuários.",
                            imageCuttingResizing: "Recortes e Redimensionamento de Imagens",
                            descriptionImageCroppingResizing: "Design Gráfico - Recortes e Redimensionamento de Imagens: Nessa atividade, são realizados recortes precisos e redimensionamentos de imagens de acordo com as necessidades do projeto. Isso inclui a remoção de fundos indesejados, ajustes de proporção, resolução e tamanho, garantindo que as imagens estejam prontas para uso em diferentes contextos, como sites, redes sociais, impressão, entre outros.",
                            creativeAdjustments: "Ajustes de criativos",
                            descriptionCreativeAdjustments: "Design Gráfico - Ajustes de Criativos: Aqui, são feitos ajustes nos elementos visuais existentes, como banners, anúncios ou outros recursos gráficos. Isso envolve aprimorar a estética, fazer alterações no layout, ajustar cores, fontes, imagens e outros elementos, visando melhorar a comunicação visual e garantir a consistência da identidade da marca.",
                            seoReport: "Relatório de SEO (off-page)",
                            perStore: "por loja",
                            descriptionSeoOffPageReport: "SEO - Relatório de SEO (off-page): Nessa atividade, é elaborado um relatório abrangente que analisa as estratégias e ações de otimização de SEO realizadas fora do site. Isso inclui a análise de fatores externos que afetam a classificação nos motores de busca, como a qualidade e quantidade de backlinks, menções em mídias sociais, presença em diretórios e outras ações de marketing off-page.",
                            erpProductRegistration: "Cadastro de produtos ERP",
                            descriptionErpProductRegistration: "Inventário - Cadastro de Produtos ERP: Nessa atividade, são cadastrados os produtos no sistema de gestão empresarial (ERP) do e-commerce. Isso envolve a inserção de informações detalhadas sobre os produtos, como descrições, imagens, preços, estoque, categorias, atributos específicos e outras informações relevantes para o controle do inventário e a exposição adequada dos produtos.",
                            seoReportInPage: "Relatório de SEO (in-page)",
                            perPage: "por página",
                            descriptionSeoInPageReport: "SEO - Relatório de SEO (in-page): Aqui, é elaborado um relatório que se concentra nas otimizações realizadas diretamente nas páginas do site. Isso envolve a análise de fatores como palavras-chave, estrutura de URL, meta tags, uso adequado de heading tags, qualidade e relevância do conteúdo, velocidade de carregamento, entre outros aspectos que impactam a visibilidade e classificação nos mecanismos de busca.",
                            showcaseOptimization: "Otimização de Vitrine",
                            perShowcase: "por vitrine",
                            descriptionShowcaseOptimization: "Gestão de E-commerce - Otimização de Vitrine: Nessa atividade, é feita a otimização da vitrine do e-commerce, a área onde os produtos são exibidos na página inicial ou em destaque. Isso envolve a seleção cuidadosa dos produtos a serem exibidos, a ordenação estratégica, a personalização visual e a atualização periódica para promover os itens mais relevantes e atrair a atenção dos visitantes, aumentando as chances de conversão.",
                            appConfigurationHour: "Configuração de App (por hora)",
                            descriptionAppConfiguration: "Gestão de E-commerce - Configuração de App (por hora): Aqui, é realizada a configuração de um aplicativo móvel dedicado ao e-commerce. Isso pode incluir a integração com o sistema de gerenciamento existente, a configuração de funcionalidades específicas, como notificações push, personalização da interface de usuário, configurações de segurança e outras customizações para oferecer uma experiência aprimorada aos usuários do aplicativo.",
                            contentRegistration: "Cadastro de conteúdo (páginas institucionais, blog post, FAQ..)",
                            descriptionContentRegistration: "Gestão de E-commerce - Cadastro de Conteúdo (páginas institucionais, postagens de blog, FAQ etc.): Nessa atividade, é realizado o cadastro e atualização do conteúdo em diversas áreas do e-commerce. Isso inclui a criação e edição de páginas institucionais, postagens de blog, seções de perguntas frequentes (FAQ) e outros conteúdos relevantes para fornecer informações aos clientes, melhorar a experiência do usuário e impulsionar o engajamento.",
                            scriptManagementHour: "Gestão de Scripts e rastreadores (por hora)",
                            descriptionScriptsTrackersManagement: "Implantação - Gestão de Scripts e Rastreadores (por hora): Nessa atividade, é realizada a gestão de scripts e rastreadores no site ou plataforma do e-commerce. Isso envolve a implementação e configuração de scripts personalizados, como rastreamento de conversões, análise de dados, chatbots, personalização de conteúdo e outras funcionalidades que requerem conhecimento técnico para garantir o funcionamento adequado e o acompanhamento correto das ações realizadas pelos usuários.",
                            smsContentCreation: "Criação conteúdo SMS",
                            perMessage: "por mensagem",
                            descriptionSmsContentCreation: "Redação Publicitária - Criação de Conteúdo SMS: Aqui, é realizada a criação de conteúdo publicitário para ser enviado por meio de mensagens de texto (SMS). Isso inclui a redação de mensagens curtas e persuasivas que comuniquem de forma eficaz promoções, ofertas exclusivas, atualizações relevantes ou chamadas para ação específicas, visando alcançar e engajar o público-alvo por meio dessa plataforma de comunicação direta.",
                            trafficStrategyAnalysis: "Análise da estratégia de tráfego",
                            cro: "CRO",
                            perReport: "por relatório",
                            descriptionTrafficStrategyAnalysis: "CRO - Análise da Estratégia de Tráfego: Nessa atividade, é feita uma análise detalhada da estratégia de tráfego do e-commerce. Isso envolve a avaliação das fontes de tráfego, como orgânico, pago, social, referência, entre outros, para identificar quais canais estão gerando mais visitantes e conversões. Com base nessa análise, são propostas estratégias de otimização e realocação de recursos para maximizar o retorno sobre o investimento em marketing.",
                            navigationFlowAnalysis: "Análise do fluxo de navegação",
                            descriptionNavigationFlowAnalysis: "CRO - Análise do Fluxo de Navegação: Aqui, é realizada uma análise do fluxo de navegação dos usuários no site ou plataforma do e-commerce. Isso inclui o estudo do comportamento dos visitantes, as páginas mais visitadas, os pontos de entrada e saída, os caminhos percorridos e os possíveis obstáculos que podem prejudicar a experiência do usuário. Com base nessa análise, são propostas melhorias no layout, navegação e usabilidade para aumentar a taxa de conversão.",
                            conversionRateAnalysis: "Análise da taxa de conversão",
                            descriptionConversionRateAnalysis: "CRO - Análise da Taxa de Conversão: Nessa atividade, é realizada uma análise aprofundada da taxa de conversão do e-commerce. Isso envolve a identificação de métricas-chave, como taxa de conversão por canal, por página de destino, por segmento de público, entre outros. Com base nessa análise, são propostas estratégias de otimização, como testes A/B, personalização de conteúdo e melhorias na experiência do usuário, para aumentar a taxa de conversão global do e-commerce.",
                            onsiteNotification: "Notificação on-site",
                            descriptionOnSiteNotification: "Gestão de E-commerce - Notificação On-site: Aqui, é realizada a configuração e implementação de notificações on-site no e-commerce. Isso inclui a criação de mensagens personalizadas, como pop-ups, barras de notificação ou alertas, exibidos no próprio site para fornecer informações importantes, incentivar ações específicas (como descontos por tempo limitado) ou capturar leads. Essas notificações podem ser segmentadas com base no comportamento do usuário ou outros critérios relevantes.",
                            goalsProjectionsReports: "Relatórios de Metas e Projeções",
                            descriptionGoalsProjectionsReports: "Relatórios - Relatórios de Metas e Projeções: Nessa atividade, são criados relatórios que acompanham o progresso em relação às metas e fazem projeções para o desempenho futuro do e-commerce. Isso inclui análises de métricas relevantes, como vendas, tráfego, conversões, ROI, entre outras, para fornecer uma visão clara do desempenho atual e ajudar na tomada de decisões estratégicas para atingir as metas estabelecidas.",
                            seoPpcBenchmarkingReport: "Relatório de Benchmarking - SEO e PPC",
                            descriptionSeoPpcBenchmarkingReport: "Relatórios - Relatório de Benchmarking - SEO e PPC: Aqui, é elaborado um relatório de benchmarking que compara o desempenho do e-commerce em termos de SEO (otimização para mecanismos de busca) e PPC (pagamento por clique). Isso envolve a análise da posição nos resultados de busca, análise da concorrência, avaliação do desempenho das campanhas de tráfego pago, entre outros fatores importantes para identificar oportunidades de melhoria e se manter competitivo no mercado.",
                            organicTrafficMktFunnelDesign: "Desenho de Funil de Marketing para campanhas de Tráfego Orgânico",
                            descriptionOrganicTrafficMktFunnelDesign: "Marketing Digital - Desenho de Funil de Marketing para Campanhas de Tráfego Orgânico: Aqui, é realizado o desenho de um funil de marketing estratégico para campanhas de tráfego orgânico. Isso envolve a criação de conteúdo relevante para atrair visitantes, a segmentação do público-alvo com base em interesses e comportamentos, a nutrição dos leads por meio de e-mails ou outros canais e a otimização do funil para aumentar a conversão de visitantes em clientes.",
                            lifecycleMktFunnelDesign: "Desenho de Funil de Marketing para campanhas de Lifecycle Marketing",
                            descriptionLifecycleMktFunnelDesign: "Gestão de E-commerce - Desenho de Funil de Marketing para Campanhas de Lifecycle Marketing: Nessa atividade, é realizado o desenho de um funil de marketing estratégico para campanhas de lifecycle marketing, que acompanham o ciclo de vida dos clientes. Isso envolve a definição das diferentes fases do ciclo de vida, como aquisição, ativação, retenção e fidelização, e a criação de estratégias de marketing personalizadas para cada fase, visando maximizar o valor do cliente e promover a lealdade.",
                            gtmTagsReview: "Revisão de Tags implementadas no Google Tag Manager de diversas plataformas",
                            descriptionGtmTagsReview: "Relatórios - Revisão de Tags implementadas no Google Tag Manager de diversas plataformas: Aqui, é feita uma revisão completa das tags implementadas no Google Tag Manager do e-commerce para diversas plataformas. Isso inclui a verificação da correta implementação das tags de análise, remarketing, chatbots, otimização de conversão e outras ferramentas em diferentes páginas e eventos, garantindo que os dados sejam coletados de forma precisa e confiável para análise posterior.",
                            paidTrafficBillingReport: "Relatório de Cobranças das plataformas de Tráfego Pago",
                            descriptionPaidTrafficBillingReport: "Tráfego Pago - Relatório de Cobranças das Plataformas de Tráfego Pago: Aqui, é elaborado um relatório que apresenta um panorama detalhado das cobranças e gastos nas plataformas de tráfego pago, como Google Ads, Facebook Ads, entre outros. Isso inclui informações sobre os custos das campanhas, os gastos por canal, as métricas de desempenho associadas aos investimentos e outras análises financeiras relevantes para monitorar o orçamento e maximizar o retorno sobre o investimento.",
                            performanceReport: "Relatório de Performance (Velocidade, Usabilidade, Taxa de Conversão e Canais de Aquisição)",
                            descriptionPerformanceReport: "Relatórios - Relatório de Performance (Velocidade, Usabilidade, Taxa de Conversão e Canais de Aquisição): Nessa atividade, é elaborado um relatório abrangente que avalia a performance do e-commerce em relação à velocidade de carregamento, usabilidade, taxa de conversão e canais de aquisição de tráfego. Isso envolve análises detalhadas desses aspectos, identificando áreas de melhoria, oportunidades de otimização e oferecendo insights para direcionar a estratégia de marketing digital.",
                            lifecycleMktPerformanceReport: "Relatório e Análise de Performance de Lifecycle Marketing",
                            perBrand: "por marca",
                            descriptionLifecycleMktPerformanceReport: "Relatórios - Relatório e Análise de Performance de Lifecycle Marketing: Nessa atividade, são elaborados relatórios que analisam o desempenho das campanhas de lifecycle marketing do e-commerce. Isso envolve a análise de métricas-chave em cada fase do ciclo de vida do cliente, como taxa de conversão, retenção, valor médio do pedido, entre outros, para identificar oportunidades de melhoria e otimização das estratégias de marketing direcionadas ao longo do ciclo de vida.",
                            htmlTemplateDev: "Desenvolvimento de template HTML",
                            descriptionHtmlTemplateDevelopment: "Desenv. de Interface - Desenvolvimento de Template HTML: Nessa atividade, é realizado o desenvolvimento de um template HTML personalizado para o e-commerce. Isso envolve a criação de um design visualmente atraente e funcional, a codificação do template em HTML e CSS, a implementação de recursos interativos e a garantia de que o template seja responsivo, ou seja, se adapte a diferentes dispositivos e tamanhos de tela, proporcionando uma experiência consistente aos usuários.",
                            ecommerceBannerDesignDesktop: "Design de Banner E-commerce (desktop)",
                            descriptionEcommerceBannerDesign: "Design Gráfico - Design de Banner E-commerce (desktop e mobile): Aqui, é realizado o design de banners específicos para o e-commerce, considerando diferentes formatos e tamanhos adequados para visualização em desktop e dispositivos móveis. Os banners podem ser utilizados para promoções, anúncios ou destacar produtos específicos.",
                            ecommerceBannerDesignMobile: "Design de Banner E-commerce (mobile)",
                            staticAdDesign: "Design de Anúncio Estático",
                            descriptionDisplayAdsDesign: "Design Gráfico - Design de Anúncio de Display Ads: É realizado o design de anúncios de display, que são elementos visuais utilizados em campanhas de publicidade online, exibidos em sites ou aplicativos de terceiros. Esses anúncios podem incluir imagens, texto e chamadas à ação, buscando atrair a atenção dos usuários e direcioná-los ao site ou plataforma da empresa.",
                            carouselAdDesign: "Design de Anúncio Carrossel",
                            descriptionSocialAdsDesign: "Design Gráfico - Design de Anúncio de Display Social Ads: Essa atividade envolve o design de anúncios de display específicos para redes sociais, como Facebook, Instagram ou Twitter. Os anúncios podem ser projetados em diferentes formatos e tamanhos, com o objetivo de gerar engajamento e conversões por meio das plataformas de mídia social.",
                            storiesAdDesign: "Design de Anúncio Stories",
                            descriptionSocialAdsCarouselDesign: "Design Gráfico - Design de Anúncio de Carrossel Social Ads: É realizado o design de anúncios de carrossel para redes sociais, onde várias imagens ou cards são exibidos em sequência, permitindo contar uma história ou exibir vários produtos em um único anúncio.",
                            programmaticMediaCreativeSet: "Conjunto de Criativos para Mídia Programática",
                            for12Formats: "para 12 formatos",
                            descriptionGoogleDisplayCreativeSet: "Design Gráfico - Conjunto de Criativos para Google Display: É criado um conjunto de criativos visuais para campanhas de Google Display, incluindo diferentes formatos e tamanhos de anúncios visuais, como banners estáticos ou animados, buscando atrair a atenção do público-alvo e gerar cliques e conversões.",
                            googlePrfmMaxCreativeSet: "Conjunto de Criativos para Google Performance Max",
                            for6Formats: "para 6 formatos",
                            descriptionGoogleMaxPrfmCreativeSet: "Design Gráfico - Conjunto de Criativos para Google Max Performance: Nessa atividade, são criados conjuntos de criativos visuais para campanhas de Google Max Performance. Esses criativos podem incluir anúncios em vídeo, banners, anúncios de carrossel ou outros formatos adequados para a plataforma, visando otimizar o desempenho da campanha e gerar resultados eficientes.",
                            merchandisingInitialPage: "Merchandising (página inicial)",
                            descriptionMerchandising: "Gestão de E-commerce - Merchandising (página inicial): Essa atividade envolve a gestão de banners e vitrines do e-commerce, onde são configurados e atualizados os elementos visuais de destaque, como banners promocionais, vitrines temáticas ou destaques de produtos, buscando direcionar a atenção dos clientes para ofertas ou produtos específicos.",
                            simpleBannerRegistration: "Cadastro de banners simples",
                            descriptionSimpleBannerRegistration: "Gestão de E-commerce - Cadastro de banners simples: Essa atividade envolve a substituição dos banners atuais por novos sem alterar a estrutura base do layout da página.",
                            completeBannerRegistration: "Cadastro de banners completo",
                            descriptionFullBannerRegistration: "Gestão de E-commerce - Cadastro de banners completo: Essa atividade envolve a substituição dos banners atuais por novos, bem como a alteração das coleções e textos das seções sem alterar a estrutura base do layout da página.",
                            emailMktContactsMigration: "Migração de Segmentos e Contatos de E-mail Marketing",
                            descriptionEmailMktMigration: "Gestão de E-commerce - Migração de Segmentos e Contatos de E-mail Marketing: Nessa atividade, são realizadas a migração e a organização dos segmentos e contatos da lista de e-mail marketing do e-commerce. Isso inclui a revisão dos segmentos existentes, a criação de novos segmentos com base em critérios específicos, a importação e exportação de contatos, garantindo a atualização e a qualidade da lista para a implementação eficaz de campanhas de e-mail marketing.",
                            emailMktFlowsMigration: "Migração de Fluxos de E-mail Marketing",
                            perEmail: "por e-mail (layout e regras)",
                            descriptionEmailMktFlowsMigration: "Gestão de E-commerce - Migração de Fluxos de E-mail Marketing: Aqui, é realizada a migração dos fluxos de automação de e-mail marketing do e-commerce para uma nova plataforma ou ferramenta. Isso envolve a revisão dos fluxos existentes, a criação de fluxos automatizados personalizados, a configuração de gatilhos e segmentações, garantindo uma transição suave e eficiente para a nova plataforma de automação.",
                            collOptimizationAutomatic: "Ordenamento e Otimização de Coleções (automática)",
                            perCollection: "por coleção",
                            descriptionCollOptimizationAutomatic: "Gestão de E-commerce - Ordenamento e Otimização de Coleções (automática): Nessa atividade, é realizado o ordenamento e a otimização automática das coleções de produtos do e-commerce. Isso envolve a configuração de regras de exibição, como relevância, popularidade, preço, disponibilidade, entre outros critérios, para garantir que os produtos sejam apresentados aos clientes de forma mais adequada e atrativa, melhorando a experiência de compra.",
                            collOptimizationManual: "Ordenamento e Otimização de Coleções (manual)",
                            descriptionCollOptimizationManual: "Gestão de E-commerce - Ordenamento e Otimização de Coleções (manual): Aqui, é realizado o ordenamento e a otimização manual das coleções de produtos do e-commerce. Isso envolve a revisão e a classificação manual dos produtos com base em critérios específicos, como tendências de mercado, preferências do público-alvo, margem de lucro, estoque disponível, para garantir uma exibição eficiente e atraente dos produtos no site ou plataforma.",
                            shopifyAppInstallation: "Instalar Shopify App",
                            perApp: "por app",
                            descriptionShopifyAppImplementation: "Implantação - Shopify App: Nessa atividade, é realizada a implantação de um aplicativo específico para a plataforma Shopify, adicionando novas funcionalidades, recursos ou integrações ao e-commerce. Isso permite expandir as capacidades da loja virtual e atender às necessidades específicas do negócio.",
                            vtexAppInstallation: "Instalar App Vtex Marketplace",
                            descriptionVtexAppInstallation: "Implantação - Vtex Marketplace: Aqui, é realizada a implantação do e-commerce na plataforma Vtex Marketplace, configurando as integrações necessárias, definindo as configurações de catálogo, preços, estoque, frete e demais funcionalidades relacionadas à operação no marketplace.",
                            wordpressPluginInstallation: "Instalar Plugin Wordpress",
                            perPlugin: "por plugin",
                            descriptionWordpressImplementation: "Implantação - WordPress: Nessa atividade, é realizada a implantação do e-commerce na plataforma WordPress, que é uma solução de CMS. Isso envolve a configuração da plataforma, escolha de temas, instalação de plugins e personalização do ambiente.",
                            externalPlatformDataAutomationConfig: "Configuração de automação de dados via plataforma externa",
                            descriptionDataAutomationConfiguration: "Implantação - Configuração de Automação de Dados via Plataforma Externa: Nessa atividade, é realizada a configuração de automação de dados por meio de uma plataforma externa para o e-commerce. Isso pode incluir a integração e configuração de fluxos de dados automatizados entre diferentes sistemas e plataformas, como CRM, ERP, ferramentas de marketing, para garantir a sincronização eficiente e a troca de informações relevantes em tempo real.",
                            webhooksConfig: "Configuração de webhooks",
                            descriptionWebhooksConfiguration: "Implantação - Configuração de Webhooks: Aqui, é realizada a configuração de webhooks no e-commerce. Os webhooks são URLs que permitem que aplicativos externos sejam notificados sobre eventos ou ações específicas que ocorrem no site ou plataforma. Isso envolve a definição dos eventos a serem monitorados, a configuração dos URLs de destino e a integração com os sistemas ou ferramentas necessárias para automatizar processos e melhorar a eficiência operacional.",
                            blogPostContentShort: "Conteúdo blog post (curto)",
                            upTo700Words: "até 700 palavras",
                            descriptionBlogPostContentShort: "Marketing Digital - Conteúdo blog post: Nessa atividade, é criado conteúdo curto para postagens no blog do e-commerce. O conteúdo do blog pode abordar tópicos relacionados aos produtos, dicas, tendências, notícias do setor, entre outros. A criação de conteúdo relevante e informativo para o blog ajuda a atrair tráfego orgânico, fortalecer a autoridade da marca e fornecer valor aos clientes.",
                            blogPostContentLong: "Conteúdo blog post (longo)",
                            upTo1500Words: "até 1.500 palavras",
                            descriptionBlogPostContentLong: "Marketing Digital - Conteúdo blog post: Nessa atividade, é criado conteúdo longo para postagens no blog do e-commerce. O conteúdo do blog pode abordar tópicos relacionados aos produtos, dicas, tendências, notícias do setor, entre outros. A criação de conteúdo relevante e informativo para o blog ajuda a atrair tráfego orgânico, fortalecer a autoridade da marca e fornecer valor aos clientes.",
                            landingPageCreation: "Criação de Landing Page (ferramenta automação de marketing)",
                            descriptionLandingPageCreation: "Marketing Digital - Criação de Landing Page (ferramenta de automação de marketing): Aqui, é realizada a criação de landing pages utilizando uma ferramenta de automação de marketing. Isso inclui a concepção do design, a seleção e customização de modelos predefinidos, a configuração de formulários de captura de leads e a implementação de elementos interativos, como pop-ups ou chatbots, para aumentar a taxa de conversão e direcionar o público para ação específica.",
                            searchAdsAdWriting: "Redação de Anúncios de Search Ads",
                            perCampaign: "por campanha",
                            descriptionSearchAdsCopywriting: "Redação Publicitária - Redação para Anúncios de Search Ads: Nessa atividade, são criados textos publicitários para anúncios de Search Ads, que são exibidos nos resultados de pesquisa dos mecanismos de busca. Os textos são otimizados para chamar a atenção do usuário, transmitir a proposta de valor do produto ou serviço e incentivar o clique no anúncio.",
                            adsCallWriting: "Redação de Chamadas para Ads",
                            descriptionAdsCopywriting: "Redação Publicitária - Redação de Chamadas para Ads: Aqui, são desenvolvidos textos publicitários para anúncios que são exibidos em sites parceiros por meio de banners, imagens ou vídeos. Os textos são elaborados para atrair a atenção do público-alvo, gerar interesse e incentivar a interação com o anúncio.",
                            ecommerceBannerCallWriting: "Redação de Chamadas para banner e-commerce",
                            descriptionEcommerceBannerCopywriting: "Redação Publicitária - Redação banner e-commerce: Nessa atividade, são criados textos publicitários para banners utilizados no e-commerce. Os textos são elaborados para chamar a atenção dos usuários, transmitir mensagens persuasivas e incentivar a interação com os produtos, promoções ou chamadas de ação presentes nos banners.",
                            emailWritingLong: "Redação de E-mails (formato longo)",
                            perEmailOriginal: "por e-mail",
                            descriptionEmailCopywriting: "Redação Publicitária - Redação de e-mails: Nessa atividade, são desenvolvidos textos persuasivos para e-mails de marketing, incluindo e-mails de campanhas promocionais, boletins informativos e e-mails de relacionamento com os clientes. Os textos são elaborados para atrair a atenção, gerar engajamento e incentivar a ação dos destinatários.",
                            emailCallWriting: "Redação de Chamadas para E-mails",
                            descriptionCallEmailCopywriting: "Redação Publicitária - Redação de e-mails: Nessa atividade, são desenvolvidos chamadas persuasivas e CTA (chamadas para a ação) para e-mails de marketing, incluindo e-mails de campanhas promocionais, boletins informativos, e-mails transacionais e e-mails de relacionamento com os clientes. Os textos são elaborados para atrair a atenção, gerar engajamento e incentivar a ação dos destinatários.",
                            customPaidMediaReport: "Relatório Personalizado de Mídia Paga",
                            descriptionGoogleAdsCustomReport: "Relatórios - Relatório Personalizado de Mídia Paga (Google Ads): Nessa atividade, são elaborados relatórios personalizados que fornecem análises detalhadas dos resultados das campanhas de mídia paga realizadas por meio do Google Ads. Os relatórios podem incluir métricas como cliques, impressões, taxa de conversão, retorno sobre investimento (ROI) e outras informações relevantes para avaliar o desempenho das campanhas e direcionar ajustes estratégicos.",
                            testingPlatformTag: "Tag da Plataforma de Testes e Experimentos",
                            descriptionGoogleOptimizeSetup: "Setup de Marketing - Google Optimize: Nessa atividade, é realizado o setup do Google Optimize, uma ferramenta de teste e personalização de sites que permite a criação de experimentos para otimizar a experiência do usuário. Isso envolve a criação da conta do Optimize, a configuração dos experimentos, a definição de variantes e objetivos, e a realização de testes A/B e testes multivariados para avaliar e melhorar a eficácia do site ou plataforma.",
                            usabilityAnalysisPlatformTag: "Tag da Plataforma de Análise de Usabilidade",
                            descriptionHotjarSetup: "Setup de Marketing - Hotjar Heatmaps & Behavior Analytics: Aqui, é realizado o setup do Hotjar, uma ferramenta de análise de comportamento do usuário que fornece mapas de calor, gravações de sessões e outros recursos para entender como os visitantes interagem com o site ou plataforma. Isso envolve a criação da conta do Hotjar, a configuração do código de rastreamento, a definição de configurações de acompanhamento e a análise dos dados coletados para obter insights valiosos sobre o comportamento do usuário e a usabilidade do site.",
                            adsPlatformsPixelsTagsEventsConfig: "Configuração de Pixels, Tags e Eventos de plataformas de Ads",
                            perTag: "por tag",
                            descriptionAdsPlatformsSetup: "Setup de Marketing - Configuração de Pixels, Tags e Eventos de plataformas Ads: Nessa atividade, é realizada a configuração dos pixels de rastreamento, tags e eventos do TikTok Ads, Pinterest Ads, Twitter Ads, Linkedin Ads e outros no site ou plataforma do e-commerce. Isso permite o monitoramento e rastreamento adequado das ações dos usuários, como visualizações de vídeos, cliques em anúncios e conversões, para fins de análise e otimização das campanhas de publicidade.",
                            performanceMaxCampaignSetup: "Setup Campanha de Performance Max",
                            descriptionGoogleMaxPrfmCampaignSetup: "Tráfego Pago - Setup Campanha de Google Max Performance: Aqui, é realizado o setup de campanhas de Google Max Performance, uma modalidade de campanha automatizada que otimiza lances, segmentação e criativos para alcançar os melhores resultados de desempenho. Isso envolve a configuração das configurações da campanha, a definição de objetivos e restrições, e a análise contínua dos resultados para ajustes e otimizações.",
                            socialAdsCampaignSetup: "Setup Campanha de Social Ads",
                            descriptionSocialAdsCampaignSetup: "Tráfego Pago - Setup Campanha de Social Ads: Aqui, é realizado o setup de campanhas de anúncios de Display Social Ads, que são exibidos nas plataformas de redes sociais, como Facebook, Instagram, TikTok, Pinterest, Twitch, Twitter ou LinkedIn. Isso envolve a configuração dos critérios de segmentação, a criação de anúncios visualmente atraentes e persuasivos, e a definição de estratégias de lances e orçamentos para alcançar o público-alvo do e-commerce.",
                            paidTrafficMktFunnelDesign: "Desenho de Funil de Marketing para campanhas da Tráfego Pago",
                            descriptionPaidTrafficMktFunnelDesign: "Tráfego Pago - Desenho de Funil de Marketing para Campanhas de Tráfego Pago: Nessa atividade, é realizado o desenho de um funil de marketing estratégico para campanhas de tráfego pago. Isso envolve a definição das etapas do funil, desde a conscientização até a conversão, a criação de segmentações e ofertas específicas para cada etapa, e a configuração das campanhas de anúncios para direcionar o tráfego qualificado ao longo do funil, visando maximizar as conversões e o retorno sobre o investimento.",
                            abTestTextImage: "Teste A/B (texto ou imagem)",
                            perExperiment: "por experimento",
                            descriptionAbTestTextImage: "CRO - Teste A/B (texto ou imagem): São realizados testes A/B, nos quais diferentes versões de textos ou imagens são apresentadas aleatoriamente aos visitantes do site. Isso permite avaliar qual versão gera melhores resultados, como taxas de conversão ou engajamento, auxiliando na otimização das páginas e no aumento das conversões.",
                            abTestCodeSnippet: "Teste A/B (snippet de código)",
                            descriptionAbTestCodeSnippet: "CRO - Teste A/B (snippet de código): Nessa atividade, são realizados testes A/B utilizando snippets de código, nos quais diferentes variantes de código são testadas em partes específicas do site. Isso permite avaliar qual versão gera melhores resultados em termos de desempenho, funcionalidade ou experiência do usuário.",
                            promotionCreation: "Criação de Promoções",
                            descriptionPromotionsCreation: "Gestão de E-commerce - Criação de Promoções: Nessa atividade, são criadas promoções ou descontos especiais para os produtos ou serviços da empresa. Isso pode incluir a definição das regras de desconto, período de validade e comunicação da promoção aos clientes.",
                            collectionCreation: "Criação de Coleções",
                            descriptionCollectionsCreation: "Gestão de E-commerce - Criação de Coleções: Essa atividade envolve a criação de coleções de produtos agrupados com base em um tema ou critério específico. As coleções podem ser utilizadas para destacar produtos relacionados, promover tendências ou facilitar a navegação dos clientes dentro do catálogo de produtos.",
                            navigationManagement: "Gestão da Navegação (menus e links)",
                            descriptionNavigationManagement: "Gestão de E-commerce - Gestão da Navegação (menus e links): Nessa atividade, são gerenciados os menus e links de navegação do site ou plataforma, garantindo a organização e usabilidade adequadas. Isso inclui a criação, edição e exclusão de categorias, subcategorias, páginas e links de navegação, buscando facilitar a experiência do usuário ao encontrar os produtos desejados.",
                            freightRulesConfig: "Configuração de regras de frete",
                            descriptionShippingRulesConfiguration: "Gestão de E-commerce - Configuração de regras de frete: Aqui, são configuradas as regras relacionadas ao cálculo e exibição das opções de frete para os clientes durante o processo de compra. Isso inclui definição de faixas de peso, regiões de entrega, valores mínimos de pedido, entre outros critérios para o cálculo do frete.",
                            scriptDevScriptsEditor: "Desenvolvimento de script via Scripts Editor",
                            descriptionCheckoutScriptDevelopment: "Gestão de E-commerce - Desenvolvimento de script de checkout (Scripts Editor): Essa atividade envolve o desenvolvimento de scripts personalizados para o processo de checkout do e-commerce. Esses scripts podem automatizar tarefas, adicionar funcionalidades específicas ou personalizar o fluxo do checkout, visando melhorar a experiência do usuário e facilitar o processo de compra.",
                            productRegistration: "Cadastro de Produtos",
                            descriptionProductsRegistration: "Inventário - Cadastro de Produtos: Essa atividade consiste em cadastrar os produtos da empresa em um sistema ou plataforma específica, inserindo informações como nome, descrição, código, categoria, preço, entre outros dados relevantes.",
                            productImport: "Importação de Produtos via planilha padronizada",
                            perSpreadsheet: "por planilha",
                            descriptionProductsImport: "Inventário - Importação de Produtos via planilha padronizada: Nessa atividade, os produtos são importados para o sistema por meio de uma planilha padronizada, agilizando o processo de atualização do inventário.",
                            catalogFiltersAdd: "Adicionar filtros no catálogo (via tags ou especificação de produto)",
                            perFilter: "por filtro",
                            descriptionCatalogFiltersAddition: "Inventário - Adicionar filtros no catálogo (via tags): São adicionados filtros ao catálogo de produtos, utilizando tags ou metacampos, facilitando a navegação e pesquisa dos clientes dentro da plataforma.",
                            variationsConfig: "Configurar variações (via opção ou metacampo)",
                            perVariation: "por variação",
                            descriptionVariationsSwatchesConfiguration: "Inventário - Configurar swatches de variações: É realizada a configuração de swatches, que são pequenas amostras de cores, tamanhos ou outras variações de um produto, permitindo aos clientes visualizar e selecionar diferentes opções.",
                            catalogFiltersAddProductSpec: "Adicionar filtros no catálogo (via especificação de produto)",
                            variationsConfigProductSpec: "Configurar variações (via especificação de produto)",
                            variationsConfigOption: "Configurar variações (via opção)",
                            catalogFiltersAddTagsOptionMetacamp: "Adicionar filtros no catálogo (via tags, opção ou metacampos)",
                            categoryCRO: "CRO",
                            categoryInterfaceDevelopment: "Desenv. de Interface",
                            categoryGraphicDesign: "Design Gráfico",
                            categoryInterfaceDesign: "Design de Interface",
                            categoryEcommerceManagement: "Gestão de E-commerce",
                            categoryImplementation: "Implantação",
                            categoryInventory: "Inventário",
                            categoryDigitalMarketing: "Marketing Digital",
                            categoryCopywriting: "Redação Publicitária",
                            categoryReports: "Relatórios",
                            categorySEO: "SEO",
                            categoryMarketingSetup: "Setup de Marketing",
                            categoryPaidTraffic: "Tráfego Pago",
                            categoryUiUx: "UI & UX",
                            categoryImageEditing: "Edição de Imagem",
                            categoryVideoEditing: "Edição de Vídeo",
                            categoryMarketplaceManagement: "Gestão de Marketplace",
                            categoryTranslation: "Tradução",
                            availableActivities: "Atividades Disponíveis",
                            search: "Pesquisar",
                            searchActivity: "Pesquisar atividades",
                            categories: "Categorias",
                            moreUseds: "Mais usados",
                            all: "Todas",
                            credits: "Créditos",
                            quantity: "Quantidade",
                            activitiesAdded: "Atividades Adicionadas",
                            requestEstimate: "Solicitar estimativa",
                            credits2: "Créditos",
                            quantity2: "Quantidade",
                            activityAccordion: {
                                responsible: "Responsável",
                                squad: "Squad",
                                none: "Nenhum",
                                status: "status",
                                backlog: "Backlog",
                                doneApplied: "Concluído/Aplicado",
                                active: "Ativo",
                                activityDetails: "Detalhes da atividade",

                            },
                            activityOptions: {
                                code: "Código",
                                writeCouponCode: "Escreva o código do cupom",
                                discount: "Desconto",
                                describeDiscount: "Descreva o desconto",
                                theme: "Tema",
                                themeEmail: "Tema do e-mail",
                                date: "Data",
                                sendDate: "Data de envio",
                                metric: "Métrica",
                                metricName: "Nome da métrica",
                                to: "até",
                                upload: "Upload",
                            }
                        },
                        analysis: {
                            of: "da",
                            sessions: "Sessões",
                            conversionRate: "Taxa de Conversão",
                            averageTicket: "Ticket Médio",
                            revenue: "Receita",
                            newCustomersRevenue: "Receita de Novos Clientes",
                            oldCustomersRevenue: "Receita de Clientes Antigos",
                            recoveredRevenue: "Receita Recuperada",
                            customerLifetimeValue: "Customer Lifetime Value",
                            nps: "NPS",
                            couponUsage: "Uso do Cupom",
                            analysis: "Análise",
                            metricsTitle: "Métricas",
                            channels: "Canais",
                            metrics: "Métricas",
                            utm: "UTM",
                            completeUtm: "UTM Completa",
                            copy: "Copiar",
                            prioritization: "Priorização",
                            trust: "Confiança",
                            impact: "Impacto",
                            facility: "Facilidade",
                        },
                        details: {
                            startBroadcast: "Início da veiculação",
                            endBroadcast: "Fim da veiculação",
                            futureDate: "Data que a ação deve estar no ar.",
                            endDate: "Data de encerramento da ação ou marcar ação perpétua quando se tratar de uma ação permanente.",
                            detailsOf: "Detalhes da",
                            name: "Nome",
                            description: "Descrição",
                            deadlineChecklist: "Prazo envio do checklist",
                            week: "Semana",
                            weeks: "Semanas",
                            language: "Linguagem",
                            portuguese: "Português",
                            english: "Inglês",
                            spanish: "Espanhol",
                            perpetualAction: "Ação perpétua",
                        },
                        revision: {
                            active: "Ativo",
                            backlog: "Backlog",
                            inProgress: "Em andamento",
                            done: "Concluído",
                            toDo: "Para fazer",
                            planning: "Planejamento",
                            cancelled: "Cancelado",
                            typeCampaign: "Campanha",
                            typeImplementation: "Implementação",
                            typeImprovement: "Melhoria",
                            detailsOf: "Detalhes da",
                            revision: "Revisão",
                            details: "Detalhes",
                            name: "Nome:",
                            description: "Descrição:",
                            type: "Tipo:",
                            category: "Categoria:",
                            quadrant: "Quadrante:",
                            executionTime: "Tempo de execução:",
                            start: "Início:",
                            end: "Fim:",
                            perpetual: "Perpétua",
                            channel: "Canal:",
                            metric: "Métrica:",
                            notSpecified: "Não especificado",
                            perpetuates: "Perpétua",
                            weeks: "semanas",
                            activities: "Atividades",
                            creditsQuantity: "Créditos / Quantidade:",
                            resume: "Resumo",
                            credits: "Créditos:",
                            score: "Score:",
                            campaign: "Campanha:",
                            status: "Status",
                            includeChecklist: "Incluir checklist",
                            jira: "Jira:",
                            jira2: "Jira",
                            integration: "Integração",
                        },
                        type: {
                            whatTypeAction: "Qual o tipo da Ação?",
                            campaign: "Campanha",
                            requestCampaign: "Solicite uma campanha de vendas ou escolha entre diversos modelos prontos de campanhas broadcast e automações de marketing digital com base em nosso sistema de recomendação.",
                            implementation: "Implementação",
                            requestReports: "Solicite a criação de relatórios, instalação de apps, integração de sistemas, inserção de tags, disparo de eventos, configuração de ferramentas e automação de processos.",
                            improvement: "Melhoria",
                            requestImprovement: "Solicite melhorias no layout, otimização da velocidade ou funcionalidades sob demanda que necessitem o envolvimento de um arquiteto de solução e um time de desenvolvimento.",
                        },

                    },
                    utils: {
                        categorySelect: {
                            activation: "Ativação",
                            discovery: "Descoberta",
                            consideration: "Consideração",
                            prospection: "Prospecção",
                            acquisition: "Aquisição",
                            content: "Conteúdo",
                            tracking: "Rastreamento",
                            benefit: "Benefício",
                            engagement: "Engajamento",
                            nextPurchase: "Próxima Compra",
                            context: "Contexto",
                            recovery: "Recuperação",
                            conversion: "Conversão",
                            purchaseBarriers: "Barreiras de Compra",
                            offers: "Ofertas",
                            authority: "Autoridade",
                            retention: "Retenção",
                            experienceAnalysis: "Análise de experiência",
                            transmission: "Transmissão",
                            segments: "Segmentos",
                            category: "Categoria",
                        },
                        editCampaignModal: {
                            campaignNotFound: "Campanha não encontrada.",
                            internalError: "Erro interno do servidor.",
                            campaignUpdated: "Campanha atualizada com sucesso!",
                            campaignDeleted: "Sucesso ao deletar campanha!",
                            editCampaign: "Editar campanha",
                            apply: "Aplicar",
                            delete: "Deletar",
                            cancel: "Cancelar",
                            deleteCampaign: "Apagar campanha",
                            confirmDelete: "Você tem certeza que deseja apagar a campanha?",
                            remove: "Apagar",
                            cancel2: "Cancelar",
                        },
                        fileSelect: {
                            fileTooBig: "Arquivo muito grande, tente outro arquivo menor que 20MB"
                        },
                        freeSoloCreateOption: {
                            errorCreate: "Erro ao criar a campanha.",
                            internalError: "Erro interno do servidor.",
                            campaignCreated: "Campanha criada com sucesso!",
                            campaign: "Campanha",
                        }
                    }
                },
                strategy: {
                    internalError: "Erro interno do servidor.",
                    updatedSuccess: "Ações atualizadas com sucesso!",
                    activation: "Ativação",
                    acquisition: "Aquisição",
                    engagement: "Engajamento",
                    conversion: "Conversão",
                    retention: "Retenção",
                    actionsQuadrant: "Estratégia",
                    updateActions: "Atualizar Ações",
                    actionCard: {
                        active: "Ativo",
                        backlog: "Backlog",
                        inProgress: "Em andamento",
                        done: "Concluído",
                        toDo: "Para fazer",
                        planning: "Planejamento",
                        cancelled: "Cancelado",
                        credits: "créditos",
                    },
                    actionCardArea: {
                        discovery: "Descoberta",
                        content: "Conteúdo",
                        nextPurchase: "Próxima Compra",
                        buyingBarriers: "Barreiras de Compra",
                        expAnalysis: "Análise de Experiência",
                        consideration: "Consideração",
                        tracking: "Rastreamento",
                        context: "Contexto",
                        offers: "Ofertas",
                        transmission: "Transmissão",
                        prospecting: "Prospecção",
                        benefit: "Benefício",
                        recovery: "Recuperação",
                        authority: "Autoridade",
                        segments: "Segmentos",
                        newAction: "Nova Ação",
                        dontCreate: "Você ainda não criou uma atividade para esse quadrante",
                        createNow: "Criar agora",
                    },
                    actionCardAreaMobile: {
                        discovery: "Descoberta",
                        content: "Conteúdo",
                        nextPurchase: "Próxima Compra",
                        buyingBarriers: "Barreiras de Compra",
                        expAnalysis: "Análise de Experiência",
                        consideration: "Consideração",
                        tracking: "Rastreamento",
                        context: "Contexto",
                        offers: "Ofertas",
                        transmission: "Transmissão",
                        prospecting: "Prospecção",
                        benefit: "Benefício",
                        recovery: "Recuperação",
                        authority: "Autoridade",
                        segments: "Segmentos",
                        newAction: "Nova Ação",
                        dontCreate: "Você ainda não criou uma atividade para esse quadrante",
                        createNow: "Criar agora",
                    }
                },
                tableActivity: {
                    table: {
                        description: "Descrição",
                        noneSelected: "Nenhuma atividade selecionada",
                        agencyNotFound: "Não foi possível encontrar a Agência!",
                        updateSuccess: "Atividades atualizadas com sucesso!",
                        internalError: "Erro interno do servidor.",
                        activitiesTable: "Tabela de Atividades",
                        activeTable: "Tabela ativa",
                        activateTable: "Ativar tabela",
                        addActivities: "Adicionar atividades",
                        version: "Versão",
                        categoryLabel: "Categoria",
                        all: "Todas",
                        searchActivity: "Buscar atividade",
                        category: "Categoria",
                        activities: "Atividades",
                        credits: "Créditos",
                        unitMensurement: "Unidade de medida",
                        linesPerPage: "Linhas por página:",
                    },
                    tables: {
                        updateSuccess: "Tabela de atividades atualizadas com sucesso!",
                        errorUpdate: "Erro ao atualizar tabela de atividades",
                        internalError: "Erro interno do servidor.",
                        activitiesTable: "Tabela de Atividades",
                        upload: "Upload",
                        version: "Versão",
                        activeTable: "Tabela ativa",

                    }
                },
                utils: {
                    filter: {
                        filter: "Filtrar",
                        filterBy: "Filtrar por:",
                        apply: "Aplicar",
                        resetFilters: "Resetar filtros",

                        campaign: {
                            campaign: "Campanha:",
                            selectCampaign: "Selecionar campanha",
                        },
                        category: {
                            category: "Categoria:",
                        },
                        end: {
                            end: "Fim:",
                        },
                        name: {
                            name: "Nome:",
                        },
                        responsible: {
                            responsible: "Responsável:",
                            selectResponsible: "Selecionar responsável",
                        },
                        score: {
                            score: "Score:",
                        },
                        start: {
                            start: "Começo:",
                        },
                        status: {
                            status: "Status:",
                            unmarkAll: "Desmarcar todos",
                            markAll: "Marcar todos",
                            active: "Ativo",
                            backlog: "Backlog",
                            inProgress: "Em andamento",
                            done: "Concluído",
                            toDo: "Para fazer",
                            planning: "Planejamento",
                            cancelled: "Cancelado",
                        }
                    },
                    headerButtons: {
                        creditBalanceModal: {
                            refunded: "Reembolsado",
                            debited: "Debitado",
                            transaction: "Transação",
                            transactions: "Transações",
                            freeBalance: "Saldo livre:",
                            balance: "Saldo",
                            cycleBalance: "Saldo do ciclo:",
                            avaiable: "Disponível:",
                            planned: "Planejado",
                            provisioned: "Provisionado",
                            debited: "Debitado",
                            actionCard: {
                                credits: "créditos",
                            },
                            editCreditsModal: {
                                contractNotFound: "Não foi possível encontrar o contrato!",
                                registerError: "Não foi possível executar a solicitação!",
                                registerSuccess: "Cobrança cadastrada com sucesso!",
                                internalError: "Erro interno do servidor.",
                                syncSuccess: "Ações sincronizadas com sucesso!",
                                edit: "Editar",
                                editCredits: "Editar créditos",
                                actuallyCredits: "Créditos atuais:",
                                sync: "Sincronizar",
                                credits: "Créditos",
                                motive: "Motivo",
                                add: "Adicionar",
                                remove: "Remover",
                                cancel: "Cancelar",
                            }
                        },
                        customSearchInput: {
                            research: "Pesquisar...",
                        },
                        exportButton: {
                            export: "Exportar",
                        },
                        fileSelectArea: {
                            addFiles: "Adicionar arquivos",
                        },
                        returnButton: {
                            return: "Voltar",
                        },
                        showMoreLessButton: {
                            seeLess: "Ver menos",
                            seeMore: "Ver mais",
                        },
                        trimesterUpdateModal: {
                            updateTrimester: "Atualizar trimestre das ações",
                            transferActions: 'Clique em "Continuar" para transferir as ações para o próximo trimestre, ou em "Remover" para remover as ações do trimestre atual.',
                            selectActions: "Selecione as ações em planejamento para também passar ao próximo trimestre",
                            remove: "Remover",
                            continue: "Continuar",
                            cancel: "Cancelar",
                        }
                    }
                },
            },
            start: {
                checklist: {
                    checklistProgress: "Progresso do Checklist",
                    generalProgress: "PROGRESSO GERAL",
                    checklistStatus: "Status do Checklist",
                    create: {
                        invalidData: "Dados inválidos.",
                        customerNotFound: "Cliente não encontrado.",
                        updateError: "Ocorreu um erro ao atualizar o cliente.",
                        updateSuccess: "Cliente atualizado com sucesso!",
                        internalError: "Erro interno do servidor.",
                        saveSuccess: "Checklist criado com sucesso!",
                        checklist: "Checklist",
                        save: "Salvar",
                        subTitle: "sub-título",
                        title: "Título",
                        helperText: 'Helper text',
                        placeHolder: 'Placeholder',
                        type: 'Tipo',
                        text: 'Texto',
                        textArea: 'Textarea',
                        count: 'Contador Numérico',
                        money: 'Valor Monetário',
                        uniqueList: 'Lista Suspensa Única',
                        multipleList: 'Lista Suspensa Múltipla',
                        url: 'URL',
                        date: 'Data',
                        email: 'E-mail',
                        radio: 'Radio',
                        checkbox: 'Checkbox',
                        attachment: 'Anexo',
                        options: 'Opções',
                        mandatory: 'Obrigatório',
                        selectItem: 'Selecione um item ao lado!',
                    },
                    edit: {
                        errorIcons: "Erro ao buscar ícones:",

                        registerError: "Checklist não foi cadastrado com sucesso!",
                        registerSuccess: "Checklist cadastrado com sucesso!",
                        internalError: "Erro interno do servidor.",
                        updateError: "Ocorreu um erro ao atualizar o Checklist.",
                        updateSuccess: "Tarefa atualizada com sucesso!",
                        add: "Adicionar",
                        subTitle: "sub-título",
                        title: "Título",
                        helperText: 'Helper text',
                        placeHolder: 'Placeholder',
                        type: 'Tipo',
                        text: 'Texto',
                        textArea: 'Textarea',
                        count: 'Contador Numérico',
                        money: 'Valor Monetário',
                        uniqueList: 'Lista Suspensa Única',
                        multipleList: 'Lista Suspensa Múltipla',
                        url: 'URL',
                        date: 'Data',
                        email: 'E-mail',
                        radio: 'Radio',
                        checkbox: 'Checkbox',
                        attachment: 'Anexo',
                        condition: 'Condição',
                        noCondition: 'Sem condição',
                        valueCondition: 'Valor Condição',
                        mandatory: 'Obrigatório',
                        options: 'Opções',
                        addOption: 'Adicionar opção',
                        addField: 'Adicionar campo',
                        add2: 'Adicionar',
                        save: 'Salvar',
                        cancel: 'Cancelar',
                        delete: 'Excluir',
                        selectItem: 'Selecione um item ao lado!',
                        searchIcon: 'Pesquisar ícone'
                    },
                    inicial: {
                        fieldRequired: "Esse campo é obrigatório.",
                        fillFields: "Preencha todos os campos obrigatórios.",
                        customerNotFound: "Cliente não encontrado!",
                        updateError: "Ocorreu um erro ao atualizar o cliente.",
                        updateSuccess: "Cliente atualizado com sucesso!",
                        internalError: "Erro interno do servidor.",
                        archiveHere: "Solte os arquivos aqui",
                        dragOrClick: "Arraste e solte seus arquivos aqui ou clique para selecionar arquivos",
                        checklist: "Checklist",
                        save: "Salvar",
                        selectItem: "Selecione um item ao lado!",
                    },
                    view: {
                        customerNotFound: "Cliente não encontrado!",
                        updateError: "Ocorreu um erro ao atualizar o cliente.",
                        updateSuccess: "Cliente atualizado com sucesso!",
                        internalError: "Erro interno do servidor.",
                        checklist: "Checklist",
                        save: "Salvar",
                        selectItem: "Selecione um item ao lado!",
                    },
                    viewAll: {
                        checklists: "Checklists",
                        edit: "Editar",
                        search: "Buscar",
                        store: "Loja",
                        all: "Todos",
                        finisheds: "Finalizados",
                        inAnalysis: "Em análise",
                        waiting: "Aguardando",
                        error: "Erro",
                        withoutChecklist: "Sem checklist",
                        name: "Nome",
                        waiting2: "Aguardando",
                        inAnalysis2: "Em análise",
                        error2: "Erro",
                        finisheds2: "Finalizados",
                        total: "Total",
                        viewChecklist: "Visualizar Checklist",
                        editChecklist: "Editar Checklist",
                    }
                },
                createChecklist: {
                    addField: "Adicionar campo",
                    label: "Label",
                    typeField: "Tipo de campo",
                },
                form: {
                    question: "Questão",
                    type: "Tipo:",
                    condition: "Condição",
                    text: "Texto",
                    updateSuccess: "Formulário atualizado com sucesso!",
                    internalError: "Erro interno do servidor.",
                    refresh: "Atualizar",
                    configForm: {
                        name: "Nome",
                        descriptionHere: "Digite a descrição aqui",
                        typeField: "Tipo de campo",
                        uniqueList: "Seleção Lista Única",
                        multipleList: "Seleção Lista Múltipla",
                        uniqueOption: "Opção única",
                        multipleOption: "Opção múltipla",
                        count: "Contador",
                        options: "Opções",
                        option: "Opção",
                        descriptionOption: "Descrição da opção",
                        delete: "Excluir",
                        addOption: "Adicionar Opção",
                        cancel: "Cancelar",
                        save: "Salvar",
                    }
                },
                project: {
                    projectNotExist: "Projeto não existe!",
                    deleteError: "Erro ao excluir!",
                    deleteSuccess: "Projeto apagado com sucesso!",
                    internalError: "Erro interno do servidor.",
                    projects: "Projetos",
                    store: "Loja",
                    all: "Todos",
                    status: "Status",
                    all2: "Todos",
                    incomplete: "Incompleto",
                    development: "Desenvolvimento",
                    waitingBudget: "Aguardando Orçamento",
                    waitingDevelopment: "Aguardando Desenvolvimento",
                    waitingCustomer: "Aguardando Cliente",
                    done: "Concluído",
                    createIn: "Criar em",
                    updatedIn: "Atualizado em",
                    details: "DETALHES",
                    remove: "Apagar",
                    confirmRemove: "Você tem certeza que deseja apagar?",
                    cancel: "Cancelar",
                },
                task: {
                    updateError: "Ocorreu um erro ao atualizar o tarefa.",
                    updateSuccess: "Tarefa atualizada com sucesso!",
                    internalError: "Erro interno do servidor.",
                    tasks: "Tarefas",
                    taskName: "Nome da tarefa",
                    add: "Adicionar",
                },
                viewProject: {
                    selectBreakDown: "Selecione o detalhamento.",
                    projectNotFound: "Projeto não encontrado!",
                    updateSuccess: "Projeto atualizado com sucesso!",
                    internalError: "Erro interno do servidor.",
                    updateError: "Ocorreu um erro ao atualizar o projeto.",
                    updateSuccess2: "Projeto atualizado com sucesso!",
                    proposalSent: "Proposta enviada com sucesso.",
                    tasksSet: "Tarefas definidas com sucesso!",
                    list: "Listar",
                    storeInformation: "Informações da loja",
                    name: "Nome",
                    email: "E-mail",
                    phone: "Telefone",
                    proposals: "Propostas",
                    date: "Data",
                    apresentation: "Apresentação",
                    detailing: "Detalhamento",
                    apresentation2: "Apresentação",
                    detailing2: "Detalhamento",
                    noProposals: "Sem propostas no momento.",
                    uploadDetailing: "UPLOAD Detalhamento",
                    uploadApresentation: "UPLOAD Apresentação",
                    noFileSelect: "Nenhum arquivo selecionado.",
                    noFileSelect2: "Nenhum arquivo selecionado.",
                    send: "Enviar",
                },
                roadmap: {
                    title: "Roadmap",
                    isBlockedBy: "É bloqueado por",
                    blocks: "Bloqueia",
                }
            },
            support: {
                hourTracking: {
                    credits: "Créditos",
                    timeTracking: "Acompanhamento de Horas",
                    filter: "Filtrar",
                    contractedHours: "Horas contratadas:",
                    usedHours: "Horas utilizadas:",
                    remainingHours: "Horas restantes:",
                    hoursTable: {
                        taskCode: "Código da Tarefa",
                        quantityHours: "Quantidade de Horas",
                        title: "Título",
                        timeRecord: "Registro de Horas",
                        date: "Data",
                        comment: "Comentário",
                        hours: "Horas",
                        deleteAction: "Apagar Ação",
                        confirmDelete: "Você tem certeza que deseja apagar a ação?",
                        delete: "Apagar",
                        cancel: "Cancelar",
                    }
                },
                performance: {
                    performance: "Performance",
                    strategy: "Estratégia",
                    desktop: "Desktop",
                    mobile: "Mobile",
                    details: "Detalhes",
                    graphics: "Gráficos",
                    statusLabel: "Status",
                    graphs: {
                        firstContent: "Primeiro conteúdo pintado",
                        firstDelay: "Primeiro atraso de entrada",
                        greaterContent: "Maior conteúdo pintado",
                        changeLayout: "Mudança de layout cumulativa",
                        interactivityRendering: "Interatividade até a próxima renderização",
                        firstByte: "Tempo para primeiro byte",
                        noneDataFound: "Nenhum dado encontrado",
                        textAvise: "*Algumas escalas foram alteradas para gerar uma visualização mais amigável da variação das Core Web Vitals",
                        chargingExperience: "Experiência de Carregamento",
                        noDataFound: "Nenhum dado encontrado...",
                        notEnoughData: "Não há dados suficientes para gerar o gráfico",
                    },
                    status: {
                        statusNotAvailable: "Status não disponível...",
                    },
                    webVitals: {
                        fix: "Corrigir",
                        slow: "Lento",
                        average: "Médio",
                        fast: "Rápido",
                        notRated: "Não avaliado",
                        valuesCanVariate: "Os valores são estimados e podem variar.",
                        acessibility: "Essas verificações destacam oportunidades para melhorar a acessibilidade do seu aplicativo da web. Apenas um subconjunto de problemas de acessibilidade pode ser detectado automaticamente, portanto, o teste manual também é incentivado.",
                        bestPractices: "Essas verificações destacam as práticas recomendadas para o desenvolvimento de aplicativos da web. Ignorar essas práticas recomendadas pode levar a problemas de desempenho, acessibilidade e confiabilidade.",
                        seo: "Essas verificações garantem que sua página esteja seguindo os conselhos básicos de otimização de mecanismos de pesquisa. Há muitos fatores adicionais que o Lighthouse não pontua aqui que podem afetar sua classificação de pesquisa, incluindo o desempenho no Core Web Vitals. Saiba mais sobre os fundamentos da pesquisa do Google.",
                        searchInfos: "Buscando informações. Isso pode levar alguns momentos...",
                        usersExperiencing: "Descubra o que os seus usuários estão experienciando",
                        assessmentPoints: "Avaliação dos Principais Pontos Vitais da Web:",
                        firstContent: "Primeiro conteúdo pintado (FCP):",
                        firstDelay: "Primeiro atraso de entrada (FID):",
                        greaterContent: "Maior conteúdo pintado (LCP):",
                        changeLayout: "Mudança de layout cumulativo (CLS):",
                        interactivityRendering: "Interatividade até a Próxima Renderização (INP):",
                        firstByte: "Tempo para primeiro byte (TTFB):",
                        generalObservations: "Observações Gerais",
                        diagnosisOf: "Diagnóstico de",
                    }
                },
                problemsIncidents: {
                    problemsIncidents: "Problemas e Incidentes",
                    filter: "Filtrar",
                    research: "Pesquisar",
                    status: "Status",
                    all: "Todos",
                    backlog: "Backlog",
                    toDo: "Para fazer",
                    inProgress: "Em andamento",
                    qa: "Q/A",
                    clientApproval: "Homologação",
                    done: "Concluído",
                    modalForm: {
                        invalidData: "Dados inválidos!",
                        problemNotFound: "Problema não encontrado!",
                        updateError: "Erro ao editar problema!",
                        internalError: "Erro interno do servidor.",
                        sendError: "Erro ao enviar arquivos!",
                        updateFileError: "Erro ao atualizar arquivos!",
                        sendSuccess: "Arquivos enviados com sucesso!",
                        updateSuccess: "Problema editado com sucesso!",
                        deleteError: "Erro ao deletar arquivos!",
                        deleteSuccess: "Arquivo removido com sucesso!",
                        errorProblem: "Erro ao cadastrar problema",
                        fieldRequired: "Campo obrigatório",
                        fillFields: "Preencha todos os campos obrigatórios.",
                        undefinedRootCause: "Causa raiz não identificada",
                        installApp: "Instalação do app",
                        themeUpdate: "Atualização de tema",
                        systemIntegration: "Integração de sistema",
                        externalSupplier: "Fornecedor externo",
                        platformInstability: "Instabilidade da plataforma",
                        initialPage: "Página inicial",
                        productPage: "Página de produto",
                        collectionPage: "Página de coleção",
                        cart: "Carrinho",
                        loginRegister: "Login ou cadastro",
                        form: "Formulário",
                        promotion: "Promoção",
                        appFunctionality: "Funcionalidade do app",
                        erpIntegration: "Integração de ERP",
                        shippingCalculate: "Cálculo de frete",
                        analyticsError: "Erro de analytics",
                        marketingTool: "Ferramenta de marketing",
                        fbCatalogIntegration: "Integração de catálogo Facebook",
                        googleCatalogIntegration: "Integração de catálogo Google",
                        desktopWindows: "Desktop Windows",
                        desktopMac: "Desktop Mac",
                        desktopLinux: "Desktop Linux",
                        tabletAndroid: "Tablet Android",
                        tabletIos: "Tablet iOS",
                        mobileAndroid: "Mobile Android",
                        mobileIos: "Mobile iOS",
                        yes: "Sim",
                        no: "Não",
                        emergency: "Emergência",
                        critical: "Crítica",
                        high: "Alta",
                        normal: "Normal",
                        low: "Baixa",
                        noPriority: "Sem prioridade",
                        other: "Outro...",
                        rootCause: "Causa raiz",
                        problemLocation: "Local do Problema",
                        dispositive: "Dispositivo",
                        navigator: "Navegador",
                        reproducedError: "Já reproduziu o erro?",
                        priority: "Prioridade",
                        actionAndSystem: "Qual foi a ação ou qual é o sistema que causou o problema.",
                        whichEquipment: "Qual equipamento que o problema foi identificado",
                        whichNavigator: "Qual navegador que o problema foi identificado",
                        emergency2: "Emergência",
                        critical2: "Crítica",
                        high2: "Alta",
                        normal2: "Normal",
                        low2: "Baixa",
                        noPriority2: "Sem prioridade",
                        emergencyDescription: "As vendas estão sendo impactadas com a solução deste item",
                        criticalDescription: "As vendas podem ser impactadas com a solução deste item",
                        highDescription: "As vendas poderão ser levemente reprimidas até a solução deste item",
                        normalDescription: "As vendas poderão melhorar com a resolução deste item",
                        lowDescription: "As vendas não serão impactadas com a solução deste item",
                        noneDescription: "A criticidade deste item não pode ser definida",
                        problemsIncidents: "Problemas e Incidentes",
                        problemDetails: "Detalhes do Problema",
                        title: "Título",
                        description: "Descrição",
                        helpToolAndClient: "Descreva qual ferramenta e cliente o problema foi encontrado. Exemplo: Checkout Arezzo travando.",
                        expectedResult: "Resultado esperado",
                        helpActionAndSystem: "Qual foi a ação ou qual é o sistema que causou o problema. Especificar com o mínimo de detalhes o resultado esperado, caso contrário a solicitação será recusada. “Resolver o problema” não será aceito.",
                        priorities: "Prioridades",
                        cancel: "Cancelar",
                        save: "Salvar",
                        new: "NOVO",
                        fileSelect: {
                            largeFile: "Arquivo muito grande, tente outro arquivo menor que 20MB",
                        },
                        modalSummary: {
                            resume: "Resumo",
                            rootCause: "Causa raiz:",
                            dispositive: "Dispositivo:",
                            reproducedError: "Reproduziu o Erro:",
                            problemLocation: "Local do Problema:",
                            navigator: "Navegador:",
                            store: "Loja:",
                            priority: "Prioridade:",
                            cancel: "Cancelar",
                            end: "Finalizar",
                        }
                    },
                    table: {
                        deleteSuccess: "Problema apagado com sucesso!",
                        problemNotFound: "Problema não encontrado!",
                        deleteError: "Erro ao apagar problema!",
                        internalError: "Erro interno do servidor.",
                        taskCode: "Código da Tarefa",
                        title: "Título",
                        date: "Data",
                        status: "Status",
                        active: "Ativo",
                        backlog: "Backlog",
                        inProgress: "Em andamento",
                        done: "Concluído",
                        toDo: "Para fazer",
                        planning: "Planejamento",
                        canceled: "Cancelado",
                        customerApproval: "Homologação",
                        code: "Código",
                        deleteProblem: "Apagar Problema",
                        confirmDelete: "Você tem certeza que deseja apagar o problema?",
                        delete: "Apagar",
                        cancel: "Cancelar",
                        problemOptions: {
                            history: "Histórico",
                            resume: "Resumo",
                            edit: "Editar",
                            jira: "Jira",
                            remove: "Excluir",
                        }
                    }
                }
            },
            unauthorized: {
                unauthorized: "Você não tem permissão para acessar essa página.",
            },
            updatePassRecovery: {
                informPassword: "Informe a senha",
                minChars: "A senha precisa ter no mínimo 6 caracteres!",
                invalidKey: "Chave inválida, solicite um novo link para atualizar senha!",
                updatePassword: "Atualizar senha",
                password: "Senha",
                loading: "Carregando...",
                newPassword: "Digite sua nova senha",
                clickHere: "Clique aqui",
                toAccess: " para acessar"
            },
            updatePerfil: {
                informName: "Informe o nome!",
                errorUpdate: "Ocorreu um erro ao atualizar o usuário.",
                successUpdate: "Perfil atualizado com sucesso!",
                editPerfil: "Editar Perfil",
                perfil: "Perfil",
                name: "Nome",
                chatStyle: "Estilo do chat",
                new: "Novo",
                legacy: "Legado",
                save: "Salvar",
                completName: "Digite o nome completo",
                invalidHours: "Horário inválido!",
                startWorkingHours: "Início do expediente",
                endWorkingHours: "Fim do expediente",
            },
            updatePerfilPass: {
                informPassword: "Informe a senha!",
                minChars: "A senha precisa ter no mínimo 6 caracteres!",
                editPassword: "Editar Senha",
                perfil: "Perfil",
                newPassword: "Nova senha",
                enterNewPassword: "Digite a nova senha!",
            },
            updatePerfilPhoto: {
                photoRequired: "Necessário selecionar uma imagem!",
                editPhoto: "Editar foto",
                perfil: "Perfil",
                photo: "Foto",
                perfilPhoto: "Foto do Perfil",
                update: "Atualizar",
            },
            updateUserPass: {
                informPassword: "Informe a senha!",
                minChars: "A senha precisa ter no mínimo 6 caracteres!",
                editPassword: "Editar Senha",
                loading: "Carregando...",
                list: "Listar",
                view: "Visualizar",
                actions: "Ações",
                list2: "Listar",
                view2: "Visualizar",
                newPassword: "Nova senha",
                enterNewPassword: "Digite a nova senha!",
            },
            user: {
                userNotExist: "Usuário não existe!",
                deleteError: "Erro ao excluir!",
                internalError: "Erro interno do servidor.",
                deleteSuccess: "Usuário apagado com sucesso!",
                admin: "Administrador",
                customer: "Cliente",
                agency: "Agência",
                users: "Usuários",
                register: "Cadastrar",
                search: "Pesquisar",
                function: "Função",
                all: "Todos",
                id: "ID",
                name: "Nome",
                email: "E-mail",
                function2: "Função",
                edit: "Editar",
                delete: "Excluir",
                permissions: "Permissões",
                deleteUser: "Apagar Usuário",
                confirmDelete: "Você tem certeza que deseja apagar o usuário?",
                cancel: "Cancelar",
                specific: {
                    userNotExist: "Usuário não existe!",
                    deleteError: "Erro ao excluir!",
                    internalError: "Erro interno do servidor.",
                    deleteSuccess: "Usuário apagado com sucesso!",
                    users: "Usuários",
                    register: "Cadastrar",
                    id: "ID",
                    name: "Nome",
                    email: "E-mail",
                    edit: "Editar",
                    delete: "Excluir",
                    permissions: "Permissões",
                    deleteUser: "Apagar Usuário",
                    confirmDelete: "Você tem certeza que deseja apagar o usuário?",
                    cancel: "Cancelar"
                }
            },
            viewUser: {
                deleteSuccess: "Usuário apagado com sucesso!",
                viewUser: "Visualizar Usuário",
                list: "Listar",
                edit: "Editar",
                editPassword: "Editar Senha",
                actions: "Ações",
                list2: "Listar",
                edit2: "Editar",
                editPassword2: "Editar Senha",
                delete: "Apagar",
                detailsUser: {
                    id: "ID",
                    name: "Nome",
                    email: "E-mail",
                    function: "Função",
                    dateRegister: "Data Cadastro",
                    editIn: "Editado em"
                }
            },
            components: {
                uploadAvatar: {
                    upload: 'Fazer upload de foto'
                },
                actionForm: {
                    quickActivity: "Atividade Rápida",
                    activityDetails: "Detalhes da Atividade",
                    quickImprovement: "Melhoria Rápida",
                    improvementDetails: "Detalhes da Melhoria",
                    type: "Tipo",
                    implementation: "Implantação",
                    improvement: "Melhoria",
                    save: "Salvar",
                },
                chat: {
                    chatHeader: {
                        notificationsEnabled: "Notificações habilitadas",
                        notificationsDisabled: "Notificações desabilitadas",
                        openAnotherTab: "Abrir em outra aba",
                        unreadThreads: "conversas não lidas",
                        openAnotherTab: "Abrir em outra aba",
                        unreadMessagesError: "Erro ao buscar mensagens não lidas.",
                        unreadMessagesSuccess: "Mensagens não lidas atualizadas com sucesso!",
                        chat: "Conversa",
                        others: "Outros",
                        markAllRead: "Marcar todas como lidas",
                    },
                    chatHeaderDrawer: {
                        online: "Disponível",
                        busy: "Ocupado",
                        attachments: "Anexos",
                        seeAll: "Ver todos",
                        marks: "Mensagens salvas",
                        seeAll2: "Ver todos",
                        attachments2: "Anexos",
                        savedMessages2: "Mensagens salvas",
                        messages: "Mensagens",
                        search: "Pesquisar",
                        searchMessages: "Pesquisar mensagens",
                    },
                    chatInput: {
                        send: "Enviar",
                        placeholder: "Digite uma mensagem...",
                        addActions: "Adicionar ações",
                        createImprovement: "Criar Melhoria",
                        createActivity: "Criar Atividade",
                        reportProblem: "Reportar Problema",
                        attachments: "Anexos",
                    },
                    chatConversation: {
                        save: "Salvar",
                        cancel: "Cancelar",
                        reply: "resposta",
                        replies: "respostas",
                        thread: "Thread",
                        edited: "Editado",
                        messageDeleted: "Mensagem apagada",
                        deleteMessage: "Apagar mensagem",
                        confirmDelete: "Você tem certeza que deseja apagar a mensagem?",
                        messageDeletedSuccess: "Mensagem apagada com sucesso!",
                        messageDeletedError: "Erro ao apagar mensagem!",
                        erase: "Apagar",
                    },

                    chatSideBar: {
                        channels: "Canais",
                    },
                    chatSideBarAdm: {
                        operation: "Operação",
                        performance: "Performance",
                        implantation: "Implantação",
                        support: "Suporte",
                        administrative: "Administrativo",
                        research: "Pesquisar",
                        settings: "Configurações",
                    }
                },
                chipProject: {
                    quiz: "Questionário",
                    waitingDevelopment: "Aguardando Desenvolvimento",
                    waitingBudget: "Aguardando Orçamento",
                    development: "Em desenvolvimento",
                    waitingCustomer: "Proposta enviada",
                    done: "Concluído",
                    unkwnown: "Desconhecido",
                },
                date: {
                    start: "Início",
                    end: "Fim"
                },
                header: {
                    perfil: "Perfil",
                },
                kanbanBoard: {
                    toDo: "Para fazer",
                    inProgress: "Em progresso",
                    done: "Finalizado",
                    deliveryForecast: "Previsão de entrega",
                },
                platinumlog: {
                    filterBar: {
                        awaitingWMS: "Aguardando WMS",
                        awaitingInvoice: "Aguardando Nota Fiscal",
                        awaitingPicking: "Aguardando Picking",
                        awaitingCheckout: "Aguardando Checkout",
                        readyForCollection: "Pronto para Coleta",
                        dispatched: "Despachado",
                        inTransit: "Em trânsito",
                        outForDelivery: "Saiu para entrega",
                        delivered: "Entregue",
                        canceled: "Cancelado",
                        lateInIntegration: "Atrasado na Integração",
                        lateInDelivery: "Atrasado na Entrega",
                        deliveryToday: "Entrega hoje",
                        withOccurrence: "Com ocorrência",
                        search: "Pesquisar",
                        status: "Status",
                        status2: "Status",
                    },
                    internalEvents: {
                        ordersReceived: "Pedidos Recebidos",
                        created: "Criado",
                        awaitingWMS: "Aguardando WMS",
                        awaitingPicking: "Aguardando Picking",
                        integratedWMS: "Integrado WMS",
                        awaitingNote: "Aguardando Nota",
                        digitalPickingDone: "Picking Digital Realizado",
                        canceled: "Cancelado",
                        physicalPickingDone: "Picking Físico Realizado",
                        noteReceived: "Nota Recebida",
                        picking: "Picking",
                        receivedTrackerViaCustomer: "Rastreador Recebido (via Cliente)",
                        awaitingCheckout: "Aguardando Checkout",
                        checkout: "Checkout",
                        readyForCollection: "Pronto para Coleta",
                        dispatched: "Despachado",
                        pickedUpByCarrier: "Coletado pela Transportadora",
                        inTransit: "Em Trânsito",
                        outForDelivery: "Saiu para Entrega",
                        occurrenceWithOrder: "Houve Alguma Ocorrência com o Pedido",
                        delivered: "Entregue",
                        deliveryFailure: "Houve Falha na Entrega",
                        orderRejected: "Pedido Recusado",
                        orderCanceled: "Pedido Cancelado",
                        orderReturnedToOrigin: "Pedido Devolvido à Origem",
                        orderLost: "Pedido Extraviado",
                        cargoTheft: "Roubo de Carga",
                        redelivery: "Redespacho",
                        carrierRecords: "Registros da Transportadora",
                        incorrectAddress: "Endereço Incorreto",
                        recipientAbsent: "Destinatário Ausente",
                        objectAwaitingWithdrawal: "Objeto Aguardando Retirada",
                        unclaimedObject: "Objeto Não Procurado",
                        integrationDelay: "Atraso na Integração",
                        deliveryDelay: "Atraso na Entrega",
                        unknowStatus: "Status desconhecido",
                    },
                    mediaTime: {
                        invoicing: "Faturamento",
                        timeCreateToSendNf: "Tempo da criação do pedido na e-commerce até o envio da nota fiscal.",
                        expedition: "Expedição",
                        timeSendToDispatching: "Tempo do envio da nota fiscal até o fim do checkout.",
                        delivery: "Entrega",
                        timeCollectToDelivered: "Tempo do fim do checkout até a entrega ao cliente pela transportadora.",
                        total: "Total",
                        totalAverage: "Média total da criação do pedido no e-commerce até a entrega ao cliente.",

                    },
                    products: {
                        sku: "SKU",
                        merchandise: "MERCADORIA",
                        amount: "QUANTIDADE"
                    },
                    shippingEvents: {
                        description: "DESCRIÇÃO",
                        start: "INÍCIO",
                        end: "FIM",
                    },
                    statusErrorView: {
                        cancelled: "CANCELADO",
                        withOccurrence: "COM OCORRÊNCIA",
                        delayInIntegration: "ATRASO NA INTEGRAÇÃO",
                        deliveryDelay: "ATRASO NA ENTREGA"
                    },
                    tableOrders: {
                        order: "Pedido",
                        date: "Data",
                        prediction: "Previsão",
                        customer: 'Cliente',
                        shipping: 'Transportadora',
                        tracking: 'Rastreio',
                        invoice: 'Nota',
                        status: "Status",
                        wmsIntegration: "Ingração WMS:",
                        timeIntegration: "Tempo de integração:",
                        displaying: "Exibindo",
                        of: "de",
                        results: "resultados"
                    }
                },
                sideBar: {
                    store: "Loja",
                    dashboard: "Dashboard",
                    messages: "Mensagens",
                    admin: {
                        settings: "Configurações",
                        usersAdmin: "Usuários",
                        stores: "Lojas",
                        agencies: "Agências",
                        agency: "Agência",
                        contracts: "Contratos",
                        tableActivitiesAdmin: "Tabela de Atividades",
                        costs: "Custos",
                        usersCustomer: "Usuários",
                        tableActivitiesCustomer: "Tabela de Atividades",
                        usersAgency: "Usuários",
                        tableActivitiesAgency: "Tabela de Atividades",
                        perfil: "Perfil",
                        logout: "Sair",
                        projects: "Projetos",
                    },
                    aiContent: {
                        creation: "Criação",
                        redaction: "Redação",
                        images: "Imagens",
                        library: "Biblioteca",
                    },
                    apps: {
                        apps: "Apps",
                        extendedCheckout: "Checkout Ampliado",
                        logistic: "Logística",
                        smartShipping: "Frete Inteligente",
                        service: "Atendimento",
                        alerts: "Alertas",
                    },
                    scale: {
                        operation: "Operação",
                        logistic: "Logística",
                        smartShipping: "Frete Inteligente",
                        inventory: "Inventário",
                        products: "Produtos",
                        orders: "Pedidos",
                    },
                    sprint: {
                        strategy: "Estratégia",
                        actionsList: "Lista de Ações",
                        actionPlan: "Plano de Ação",
                        kanban: "Kanban Board",
                        checklists: "Checklists",
                        performance: "Performance",
                        newAction: "Nova Ação",
                        results: "Resultados",
                    },
                    start: {
                        implantation: "Implantação",
                        form: "Formulário",
                        projects: "Projetos",
                        checklists: "Checklists",
                        checklistCustomer: "Checklist",
                        checklistAgency: "Checklist",
                    },
                    support: {
                        improvements: "Nova Melhoria",
                        improvementsList: "Lista de Melhorias",
                        support: "Suporte e Evolução",
                        hoursControl: "Controle de Horas",
                        problems: "Problemas",
                        performance: "Performance",
                        kanban: "Kanban Board",
                    }
                },
                spinnerAdd: {
                    processing: "Processando",
                    register: "Caddastrar"
                },
                spinnerDelete: {
                    processing: "Processando...",
                    delete: "Apagar"
                },
                spinnerLogin: {
                    wait: "Aguarde...",
                    enter: "Entrar"
                },
                spinnerRecovery: {
                    processing: "Processando...",
                    recovery: "Recuperar"
                },
                spinnerUp: {
                    processing: "Processando",
                    update: "Atualizar"
                },
                spinnerUpdateRecovery: {
                    processing: "Processando...",
                    update: "Atualizar"
                },
                upload: {
                    avatar: {
                        update: "Atualizar",
                        send: "Enviar"
                    },
                    image: {
                        update: "Atualizar",
                        send: "Enviar"
                    }
                }
            },
            config: {
                ecommerceOperation: "Operação de E-commerce",
                platformImplantation: "Implantação da Plataforma",
                ecommecerManagement: "Gestão de E-commerce",
                support: "Suporte",
            },
            store: {
                actions: {
                    errorHandling: {
                        internalError: "Erro interno do servidor."
                    }
                }
            },
            containers: {
                dashboard: {
                    baseDashboard: {
                        dashboard: "Dashboard",
                        actions: "Ações",
                        notifications: "Notificações",
                        chat: "Chat",
                    }
                }
            },
            "insight": {
                "title": "Inventário",
                "startDate": "Data de Início",
                "endDate": "Data de Término",
                "selectLocation": "Selecione a Localização",
                "selectTypeProduct": "Selecione o Tipo de Produto",
                "selectProduct": "Selecione o Produto",
                "type": "Tipo de Produto",
                "product": "Produto",
                "variant": "Variação",
                "search": "Pesquisar",
                "daysPredict": "Previsão de esgotamento de estoque",
                "day": "dia",
                "days": "dias",
                "media": "Média",
                "perDay": "por dia",
                "curveABC": "Curva ABC",
                "riskStock": "Risco de Estoque",
                "curve": "Giro de Estoque",
            },
            apexChart: {
                jan: "Jan",
                feb: "Fev",
                mar: "Mar",
                apr: "Abr",
                may: "Mai",
                jun: "Jun",
                jul: "Jul",
                aug: "Ago",
                sep: "Set",
                oct: "Out",
                nov: "Nov",
                dec: "Dez",
                january: "Janeiro",
                february: "Fevereiro",
                march: "Março",
                april: "Abril",
                may2: "Maio",
                june: "Junho",
                july: "Julho",
                august: "Agosto",
                september: "Setembro",
                october: "Outubro",
                november: "Novembro",
                december: "Dezembro",
                sunday: "Domingo",
                monday: "Segunda-feira",
                tuesday: "Terça-feira",
                wednesday: "Quarta-feira",
                thursday: "Quinta-feira",
                friday: "Sexta-feira",
                saturday: "Sábado",
                sun: "Dom",
                mon: "Seg",
                tue: "Ter",
                wed: "Qua",
                thu: "Qui",
                fri: "Sex",
                sat: "Sáb",
                toolbar: {
                    exportToSVG: 'Baixar SVG',
                    exportToPNG: 'Baixar PNG',
                    menu: 'Menu',
                    selection: 'Seleção',
                    selectionZoom: 'Seleção Zoom',
                    zoomIn: 'Aumentar',
                    zoomOut: 'Diminuir',
                    pan: 'Panning',
                    reset: 'Reiniciar Zoom'

                }

            },
            "curveABC": {
                "loading": "Carregando...",
                "title": "Curva ABC",
                "totalSales": "Total de Vendas",
                "period": "Período",
                "stockPerTypeProducts": "Estoque por Tipos de Produto",
                "tableHeaders": {
                    "title": "Título",
                    "sku": "SKU",
                    "type": "Tipo",
                    "quantity": "Qnt",
                    "amount": "Faturamento",
                    "percent": "%",
                    "accumulatedPercent": "% Acumulado",
                    "curve": "Curva ABC",
                    "stock": "Estoque",
                    "expectedFinish": "Esgotamento",
                    "valueStock": "Valor em Estoque",
                    "media_day": "Média por dia",
                    "expected_days": "Dias para esgotar",
                },
                noInstall: {
                    benefit1: "Acesso instantâneo a métricas cruciais de vendas e estoque.",
                    benefit2: "Acompanhamento de eventos do cliente em tempo real.",
                    benefit3: "Tomada de decisões mais informadas para otimizar estratégias.",
                    benefit4: "Aumento da lucratividade com base em dados sólidos.",
                    benefit5: "Interface amigável e fácil de usar.",
                    new: "Novo",
                    title: "Desbloqueie o Potencial Máximo com Nosso Aplicativo!",
                    text1: "Desbloqueie o potencial máximo do seu e-commerce Shopify com acesso instantâneo a métricas cruciais de vendas, estoque e eventos do cliente. Tome decisões mais informadas, otimize suas estratégias e aumente sua lucratividade como nunca antes.",
                    text2: "Não perca tempo - comece a dominar seus dados agora mesmo!",
                    text3: "Instale o nosso aplicativo para uma experiência completa e aproveite ao máximo todas as vantagens que oferecemos.",
                    unblock: "Desbloquear Potencial Máximo",
                    info: "Informações",
                    major100: "Maior que 100%: + venda - estoque.",
                    between100: "Menor que 100%: - venda + estoque.",
                }
            },
            costsApp: {
                oms: {
                    spending: 'Gastos',
                    spedningText: 'Valor da instância AWS',
                    earnings: 'Receita',
                    earningsText: 'Valor total dos ganhos com as lojas',
                    profit: 'Lucro',
                    stores: 'Lojas',
                    storesText: 'Lojas que utilizaram o aplicativo',
                    fulfillments: 'Fulfillments',
                    fulfillmentsText: 'Número de fullfilments integrados',
                    costResource: 'Custo/recurso',
                    costResourceText: 'Custo por cada recurso utilizado',
                    store: 'Loja',
                    resources: 'Recursos',
                    cost: 'Custo',
                    pricePerUse: 'Preço por uso',
                    pricePlan: 'Preço mensal',
                    totalPrice: 'Preço total',
                    loading: 'Carregando...',
                    noData: 'Não há dados para o mês selecionado'
                }
            }
        }
    }
}

export { messages };