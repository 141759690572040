import React from 'react';
import {Button, Spinner} from 'reactstrap';
import { i18n } from '../../translate/i18n';

const SpinnerUp = (props) => {
    if(props.loading) return(
        <Button className="btn btn-custom-1 btn-sm" disabled >{i18n.t('components.spinnerUp.processing')}<Spinner className="ml-1" size="sm" color="light"/></Button>
    )

    return (
        <Button className="btn btn-custom-1 btn-sm">{i18n.t('components.spinnerUp.update')}</Button>
    )
}

export default SpinnerUp;