const messages = {
    en: {
        translations: {
            documents: {
                index: {
                    title: "Documents",
                    name: "Name",
                    date: "Date",
                    type: "Type",
                    new: "New",
                    search: "Search",
                    edit: "Edit",
                    delete: "Delete",
                    deleteDocument: "Delete document",
                    confirmDelete: "Are you sure you want to delete the document?",
                    cancel: "Cancel"
                },
                form: {
                    title1: "Register document",
                    title2: "Edit document",
                    errorName: "Name is required.",
                    errorType: "Type is required.",
                    errorUrl: "Url is required.",
                    errorDate: "Date is required.",
                    name: "Name",
                    type: "Type",
                    date: "Date",
                    save: "Save",
                    all: "All",
                    meetingNote: "Meeting Note",
                    projectRequirements: "Project Requirements",
                    operationalRequirements: "Operational Requirements",
                    report: "Report",
                    other: "Other",
                    editSuccess: "Document edited successfully!",
                    saveSuccess: "Document saved successfully!",
                    deleteSuccess: "Document deleted successfully!",
                    internalError: "Internal server error.",
                    invalidData: "Invalid data.",
                    documentNotExist: "Document was not found.",
                }
            },
            addCustomer: {
                active: "Active",
                inactive: "Inactive",
                nameRequired: "Name is required.",
                urlValid: "Enter a valid URL. Example: https://www.checkstore.com.br.",
                urlRequired: "Store URL is required.",
                invalidData: "Invalid data.",
                registerError: "Customer registration was not successful.",
                registerSuccess: "Customer registered successfully.",
                internalError: 'Internal server error.',
                customerNotFound: "Customer not found.",
                updateError: "An error occurred while updating the customer.",
                updateSuccess: "Customer updated successfully!",
                edit: "Edit",
                register: "Register",
                store: "Store",
                imageAllowed: "Allowed",
                name: "Name",
                urlStore: "Store URL",
                platformFullcomm: "Platform",
                plats: "Platforms",
                implantation: "Implantation",
                performance: "Performance",
                operation: "Operation",
                support: "Support",
                platformEcommerce: "E-commerce Platform",
                platEcom: "E-commerce Platforms",
                other: "Other",
                agency: "Agency",
                urlSales: "Sales Dashboard URL",
                urlTraffic: "Paid Traffic Dashboard URL",
                urlCRM: "CRM Dashboard URL",
                keyJira: "Jira Project Key",
                idGa: "Google Analytics ID",
                idFacebook: "Facebook Pixel ID",
                keyActive: "Active Campaign API Key",
                idUptime: "Uptime Status ID",
                platformDomain: "Platform Domain",
                save: "Save",
                cancel: "Cancel"
            },
            addTrialUser: {
                nameRequired: "Name is required.",
                emailValid: "Enter a valid email.",
                emailRequired: "Email is required.",
                phoneRequired: "Phone is required.",
                passwordRequired: "Password is required.",
                passwordRule: "The password must contain 8 characters, a number, and an uppercase letter.",
                passwordEqual: "Passwords must match.",
                passwordConfirm: "Password confirmation is required.",
                invalidData: "Invalid data.",
                alreadyRegistered: "This email is already registered.",
                registerError: "User registration was not successful.",
                registerSuccess: "User registered successfully.",
                completeName: "Full Name",
                email: "Email",
                phone: "Phone",
                password: "Password",
                passwordConfirmShort: "Confirm Password",
                createAccount: "Create Account",
                createVia: "Create via:",
                hasAccount: "Already have an account? Log in",
            },
            addUser: {
                nameRequired: "Name is required.",
                emailValid: "Enter a valid email.",
                emailRequired: "Email is required.",
                invalidData: "Invalid data.",
                alreadyRegistered: "This email is already registered.",
                registerError: "User registration was not successful.",
                registerSuccess: "User registered successfully.",
                userNotFound: "User not found.",
                alreadyRegistered2: "Email already registered.",
                errorUpdate: "An error occurred while updating the user.",
                updateSuccess: "User updated successfully.",
                internalError: 'Internal server error.',
                edit: "Edit",
                register: "Register",
                user: "User",
                name: "Name",
                email: "Email",
                role: "Role",
                stores: "Stores",
                agency: "Agency",
                permissions: "Permissions",
                accessStores: "Access all stores",
                save: "Save",
                cancel: "Cancel"
            },
            agency: {
                form: {
                    socialRequired: 'Social name is required.',
                    cnpjRequired: 'CNPJ is required.',
                    clientIdRequired: 'Client ID is required.',
                    nameClientRequired: 'Client name is required.',
                    storeRequired: 'Store is required.',
                    monthlyCreditRequired: 'Monthly credit is required.',
                    initDateRequired: 'Start date is required.',
                    invalidEmail: 'Invalid email.',
                    theStores: 'The stores',
                    haveAgency: 'already have an agency!',
                    theStore: 'The store',
                    haveAgency2: 'already has an agency!',
                    registerError: 'Error while registering agency!',
                    registerSuccess: 'Agency registered successfully!',
                    internalError: 'Internal server error.',
                    agencyNotFound: 'Agency not found',
                    errorUpdateCustomer: 'An error occurred while updating the customers associated with the agency.',
                    updateSuccess: 'Agency updated successfully!',
                    edit: 'Edit',
                    register: 'Register',
                    agency: 'Agency',
                    social: 'Social name',
                    cnpj: 'CNPJ',
                    assocStores: 'Associate stores',
                    monthlyCredits: 'Monthly credits',
                    initDate: 'Start date',
                    financContacts: 'Financial contacts',
                    name: 'Name',
                    email: 'Email',
                    phone: 'Phone',
                    respContact: 'Responsible contacts',
                    save: 'Save',
                    cancel: 'Cancel'
                },
                list: {
                    corporateName: 'Corporate name',
                    endContract: 'Contract ended successfully!',
                    agencies: 'Agencies',
                    register: 'Register',
                    socialName: 'Social name',
                    stores: 'Stores',
                    cnpj: 'CNPJ',
                    status: 'Status',
                    active: 'Active',
                    finished: 'Finished',
                    edit: 'Edit',
                    end: 'End',
                    users: 'Users',
                    activities: 'Activities'
                },
                specific: {
                    userNotExist: 'User does not exist!',
                    deleteError: 'Error deleting!',
                    deleteSuccess: 'User deleted successfully!',
                    internalError: 'Internal server error.',
                    stores: 'Stores',
                    register: 'Register',
                    id: 'ID',
                    name: 'Name',
                    email: 'Email',
                    edit: 'Edit',
                    delete: 'Delete',
                    deleteUser: 'Delete user',
                    confirmDelete: 'Are you sure you want to delete the user?',
                    cancel: 'Cancel'
                }
            },
            aiContent: {
                images: {
                    errorSize: "Enter a value in only one field, height or width",
                    internalError: 'Internal server error.',
                    errorSend: "Error sending image",
                    categoryImg: "Images",
                    method: "Method",
                    send: "Send",
                    style: "Style",
                    noneStyle: "None",
                    model3d: "3D Model",
                    analogFilm: "Analog Film",
                    anime: "Anime",
                    cinematic: "Cinematic",
                    comicBook: "Comic Book",
                    digitalArt: "Digital Art",
                    enhance: "Enhance",
                    fantasy: "Fantasy",
                    isometric: "Isometric",
                    lineArt: "Line Art",
                    lowPoly: "Low Poly",
                    modelingClay: "Modeling Clay",
                    neon: "Neon Punk",
                    origami: "Origami",
                    photographic: "Photographic",
                    pixelArt: "Pixel Art",
                    tileTexture: "Tile Texture",
                    titleDifusion1: "How strictly the diffusion process adheres to the prompt text (higher values keep the image closer to the prompt)",
                    scaleCFG: "CFG Scale",
                    refinements: "Refinements",
                    qntImages: "Number of generated images",
                    samples: "Samples",
                    format: "Format",
                    titleDifusion2: "What is the impact of the initial image on the diffusion process? Values close to 1 will result in images very similar to the initial image, while values close to 0 will result in images very different from the initial image.",
                    weightImg: "Image weight",
                    addToImg: "Add to image",
                    textToImg: "Text to image",
                    intensityPropmt: "Prompt intensity",
                    removeImg: "Remove from Image",
                    width: "Width",
                    height: "Height",
                    allowed: "Allowed",
                    save: "Save"
                },
                library: {
                    activity: "Activity",
                    action: "Action",
                    none: "None",
                    delete: "Delete",
                    library: "Library",
                    title: "Title",
                    action2: "Action",
                    link: "Link",
                    type: "Type",
                    samples: "Samples",
                    date: "Date",
                    deleteAction: "Delete Action",
                    confirmDelete: "Are you sure you want to delete the selected item?",

                    detailsModal: {
                        selectActivity: "Select an activity",
                        contentNotFound: "Content not found.",
                        sendJiraError: "Error sending file to Jira!",
                        integrationJiraError: "Error in Jira integration!",
                        updateSuccess: "Content updated successfully!",
                        internalError: "Internal server error.",
                        details: "Details",
                        linkAction: "Link to Action",
                        activity: "Activity",
                        save: "Save",
                        cancel: "Cancel"
                    },
                    filter: {
                        filter: "Filter",
                        filterBy: "Filter by",
                        title: "Title",
                        action: "Action",
                        type: "Type",
                        aplic: "Apply",
                        reset: "Reset filters"
                    }
                },
                redacting: {
                    fillFields: "Fill in all fields!",
                    minChar: "The minimum characters in a field is 3!",
                    errorContent: "Error generating content",
                    errorSave: "Error saving content",
                    redaction: "Copywrite",
                    method: "Method",
                    send: "Send",
                    quality: "Quality",
                    economy: "Economy",
                    medium: "Medium",
                    high: "High",
                    premium: "Premium",
                    language: "Language",
                    portuguese: "Portuguese",
                    english: "English",
                    spanish: "Spanish",
                    numberCopies: "Number of copies",
                    save: "Save",
                    options: {
                        description1: "Description",
                        product: "Product",
                        description2: "Description",
                        container: "Container",
                        containerCharge: "Container Charge",
                        description3: "Description",
                        productName1: "Product Name",
                        productDescription1: "Product Description",
                        occasion: "Occasion",
                        promotion: "Promotion",
                        productName2: "Product Name",
                        productDescription2: "Product Description",
                        searchTerm: "Search Term",
                        productName3: "Product Name",
                        productDescription3: "Product Description",
                        companyName: "Company Name",
                        companyDescription: "Description",
                        keyWord: "Keyword",
                        productName4: "Product Name",
                        productDescription4: "Product Description",
                        keyWords: "Keywords",
                        targetAudience: "Target Audience",
                        description4: "Description",
                        voiceTone1: "Voice Tone",
                        content: "Content",
                        voiceTone2: "Voice Tone",
                        productDescription5: "Product Description",
                        benefit: "Benefit"
                    }
                },
                utils: {
                    config: {
                        label0: 'Call to Action',
                        description0: `Create compelling calls to action that will encourage conversions and boost your sales. 
                        
                        In the **Description** field, explain the company, product, or service you're promoting and get call to action ideas.
                
                        Creativity: What level of creativity would you like to apply, with Premium being the more comprehensive but slower and more expensive model?
                        Language: Choose from the 3 available languages.
                        Copies: Select how many options you'd like to choose.`,

                        label1: 'Email Subject',
                        description1: `Create email subjects that increase your open rate. 
                        
                        In the **Product** field, mention the name of the company, product, or service. In the **Description** field, briefly explain what you'd like to promote.
                
                        Creativity: What level of creativity would you like to apply, with Premium being the more comprehensive but slower and more expensive model?
                        Language: Choose from the 3 available languages.
                        Copies: Select how many options you'd like to choose.`,

                        label2: 'Emails',
                        description2: `Create mass emails that appear personalized. 
                        
                        In the **Recipient** field, provide the name of the email recipient or simply use "customer." In the **Recipient's Position** field, specify the customer segment or profile, and in the **Description** field, describe your email's objective. Remember that the system will generate a lengthy email, so if you're looking for something short, use the "Email Subject" and "Call to Action" features.
                
                        Creativity: What level of creativity would you like to apply, with Premium being the more comprehensive but slower and more expensive model?
                        Language: Choose from the 3 available languages.
                        Copies: Select how many options you'd like to choose.`,

                        label3: 'Facebook Ads',
                        description3: `Copy for Facebook and Instagram Ads that will make your ads stand out. 
                        
                        In the **Product Name** field, provide the name of the company, product, or service. In the **Description** field, briefly explain your product, mention the use **Occasion**, and in **Promotion**, detail any offers you'd like to highlight.
                
                        Creativity: What level of creativity would you like to apply, with Premium being the more comprehensive but slower and more expensive model?
                        Language: Choose from the 3 available languages.
                        Copies: Select how many options you'd like to choose.`,

                        label4: 'Google Ads',
                        description4: `High-quality ads that rank in search results and generate more traffic. 
                        
                        In the **Product Name** field, provide the name of the company, product, or service. In the **Product Description** field, briefly explain your product, and in the **Search Term**, indicate the term you'd like to rank for.
                
                        Creativity: What level of creativity would you like to apply, with Premium being the more comprehensive but slower and more expensive model?
                        Language: Choose from the 3 available languages.
                        Copies: Select how many options you'd like to choose.`,

                        label5: 'Google Ads Descriptions',
                        description5: `The best texts for Google Ads that turn visitors into customers. 
                        
                        In the **Product Name** field, provide the name of the company, product, or service. In the **Product Description** field, briefly explain your product.
                
                        Creativity: What level of creativity would you like to apply, with Premium being the more comprehensive but slower and more expensive model?
                        Language: Choose from the 3 available languages.
                        Copies: Select how many options you'd like to choose.`,

                        label6: 'Google Ads Title',
                        description6: `Ads with unique and compelling titles that encourage people to click on your ad and buy from your site. 
                        
                        In the **Company Name** field, provide the name of the advertising company. In the **Description** field, briefly explain your product and indicate the **Keyword** that will be used in your campaign.
                
                        Creativity: What level of creativity would you like to apply, with Premium being the more comprehensive but slower and more expensive model?
                        Language: Choose from the 3 available languages.
                        Copies: Select how many options you'd like to choose.`,

                        label7: 'Growth Ideas',
                        description7: `High-impact growth tactics to help your company grow. 
                        
                        In the **Product Name** field, provide the name of the company, product, or service. In the **Product Description** field, briefly explain your product. In **Keywords**, indicate which terms are associated with your product, and in **Target Audience**, specify the audience for your tactic.
                
                        Creativity: What level of creativity would you like to apply, with Premium being the more comprehensive but slower and more expensive model?
                        Language: Choose from the 3 available languages.
                        Copies: Select how many options you'd like to choose.`,

                        label8: 'Instagram Captions',
                        description8: `Capture attention with interesting and creative captions for your posts. 
                        
                        In the **Content** field, indicate the post's subject, and in the **Tone of Voice** field, explain how your brand communicates with its customers.`,

                        label9: 'Rewrite Text',
                        description9: `Rewrite text with a new tone of voice or orthographic style. 
                        
                        In the **Content** field, provide the text to be rewritten, and in the **Tone of Voice** field, indicate the desired orthographic style or tone of voice.`,

                        label10: 'Feature to Benefit',
                        description10: `Transform your product's features into benefits to generate more value for your customers.`,

                        label11: 'Text to Image',
                        description11: `Create perfect images for your ads with creativity and exclusivity. Adapt to your brand's style and increase content production.`,
                        dropdownTitle11: 'Learn to create images:',
                        dropdownText11: `**Style**: Choose from various predefined styles or leave it blank to define the style directly in the text.
                
                        **CFG Scale**: This parameter controls how closely the image generation process follows the text instructions. A higher value (max 35) adheres the image more to a specific text input, while a lower value (min 0) yields a more creative and free result.
                        
                        **Refinements**: Once an image is generated, it's refined further based on the defined refinement count. The refinement process can be effective for images with complex elements but less effective for abstract images. Test according to your image, but we recommend the default of 50.
                        
                        **Samples**: How many different images would you like.
                        
                        **Text for Image**: Describe the main elements, environment, image style, and keywords separated by commas.`,

                        label12: 'Transform Image',
                        description12: `Create perfect images for your ads with creativity and exclusivity. Adapt to your brand's style and increase content production.`,
                        dropdownTitle12: 'Learn to transform images:',
                        dropdownText12: `**Style**: Choose from various predefined styles or leave it blank to maintain the same style as the reference image.
                
                        **CFG Scale**: This parameter controls how closely the image generation process follows the text instructions. A higher value (max 35) adheres the image more to a specific text input, while a lower value (min 0) yields a more creative and free result.
                        
                        **Refinements**: Once an image is generated, it's refined further based on the defined refinement count. The refinement process can be effective for images with complex elements but less effective for abstract images. Test according to your image, but we recommend the default of 50.
                        
                        **Samples**: How many different images would you like.
                        
                        **Image Weight**: How much influence the reference image has on the creation process. Values close to 1 will produce images very similar to the reference image, while values close to 0 will produce entirely different images.
                        
                        **Add to Image**: Describe the main elements, environment, image style, and keywords separated by commas.
                        
                        **Add to Image**: Describe elements like objects, environment, and styles you'd like to include in the image, and use the sidebar to set the prominence level these elements should have in the image.
                        
                        **Remove from Image**: Describe what you want to remove from the reference image and use the sidebar to set the intensity with which these elements should be removed from the image.`,

                        label13: 'Image Upscaling',
                        description13: `Enlarge low-quality images to high-resolution sizes. This model recreates image details in high resolution while maintaining fidelity to the original image. Choose between height or width as the reference for the final size. Size increments are fixed at 64px, so reaching the exact desired size may not be possible.`,
                    },
                    searchAction: {
                        action: "Action",
                    },
                    searchToJiraModal: {
                        selectActivity: "Select an activity",
                        sendJiraError: "Error sending file to Jira!",
                        integrationJiraError: "Error in Jira integration!",
                        storeSuccess: "Content stored successfully",
                        internalError: "Internal server error.",
                        relation: "Jira/Platform Relationship",
                        title: "Title",
                        associate: "Associate with",
                        none: "None",
                        action: "Action",
                        activity: "Activity",
                        activity2: "Activity",
                        commentJira: "Jira Comment",
                        save: "Save"
                    }
                }
            },
            apps: {
                alerts: {
                    message: "Messages",
                    telephone: "Telephone",
                    start: "Start",
                    end: "End",
                    btnText: "Button Text",
                    weekends: "Weekends",
                    active: "Active",
                    alerts: "Alerts",
                    chatSupportMessage: "Chat Support Message",
                },
                extendedCheckout: {
                    extendedCheckout: "Extended Checkout",
                },
                logistics: {
                    logistic1: "Logistics",
                    logistic2: "Logistics",
                },
                service: {
                    service1: "Service",
                    service2: "Service",
                },
                smartShipping: {
                    smartShipping1: "Smart Shipping",
                    smartShipping2: "Smart Shipping",
                },
                utils: {
                    appFormCms: {
                        sendFilesError: "Error sending files!",
                        updateFilesError: "Error updating files!",
                        internalError: "Internal server error.",
                        createAppSuccess: "App created successfully!",
                        updateAppError: "Error updating app!",
                        updateAppSuccess: "App updated successfully!",
                        sincAlt: "Sync Changes",
                        title1: "Title",
                        link1: "Link",
                        description1: "Description",
                        link2: "Link",
                        link3: "Link",
                        link4: "Link",
                        title2: "Title",
                        textButton: "Button Text",
                        description2: "Description",
                        subtitle1: "Subtitle",
                        description3: "Description",
                        subtitle2: "Subtitle",
                        description4: "Description",
                        subtitle3: "Subtitle",
                        description5: "Description",
                        addDescription: "Add Description",
                    },
                    appFormNotifications: {
                        createAppSuccess: "App created successfully!",
                        updateAppError: "Error updating app!",
                        updateAppSuccess: "App updated successfully!",
                        internalError: "Internal server error.",
                        sincAlt: "Sync Changes",
                        success: "Success",
                        error: "Error",
                        info: "Info",
                        other: "Other",
                    },
                    appTabs: {
                        cms: "CMS",
                        notifications: "Notifications"
                    }
                }
            },
            chat: {
                indexAdm: {
                    selectChanel: "Select a channel on the side."
                }
            },
            customer: {
                customerNotExist: "Customer does not exist!",
                deleteError: "Error deleting!",
                deleteSuccess: "Customer deleted successfully!",
                internalError: "Internal server error.",
                performance: "Performance",
                acessibility: "Acessibility",
                bestPractices: "Practices",
                seo: "SEO",
                stores: "Stores",
                register: "Register",
                search: "Search",
                store: "Store",
                all: "All",
                implantation: "Implementation",
                operation: "Operation",
                performance2: "Performance",
                support: "Support",
                logo: "Logo",
                name: "Name",
                healthScore: "Health Score",
                balanceCreditsPerformance: "Balance/Credits (Performance)",
                balanceCreditsSupport: "Balance/Credits (Support)",
                performance3: "Performance",
                platforms: "Platforms",
                viewChecklist: "View Checklist",
                editChecklist: "Edit Checklist",
                users: "Users",
                edit: "Edit",
                delete: "Delete",
                deleteUser: "Delete user",
                confirmDelete: "Are you sure you want to delete the user?",
                cancel: "Cancel",
            },
            dashboard: {
                dashboards: "Dashboards",
                dashboard: "Dashboard",
                resumeSales: "Sales Summary",
                paidTraffic: "Paid Traffic",
                crm: "CRM",
                sales: "Sales",
                invoicing: "Invoicing",
                products: "Products",
                strategy: "Strategy",
                healthScore: "Health Score",
                immediateAction: "Immediate Actions",
                activitiesWaitingApproval: "Activities Waiting Approval",
                checklistItens: "Checklist itens waiting to be sent",
                roadmap: "Roadmap",
                operation: "Operation",
                created: "Order created",
                error: "Integration error",
                shipment: "In shipment",
                carrier: "In transit",
                client: "Delivered to the client",
                tecnology: "Tecnology",
                pleaseWaitTecnology: "Please wait, we are fetching your data",
                performance: "Performance",
                accessibility: "Acessibility",
                recommendedPractices: "Recommended Practices",
                seo: "SEO",
                last30days: "Last 30 days",
                invoicingTotal: "Invoicing Total",
                ticketMedio: "Average Ticket",
                orders: "Orders",
                textNoInstall: "Install the Métricas FullComm app to bring your data and generate visualizations.",
                installInShopify: "Install Shopify App",
                startWithPerformance: "Start with Performance",
                startWithOperation: "Start with Operation",
                requestModule: "Request Module",
                goToPerformance: "Go to Performance",
                usePerformanceModule: "Use the performance module to display the data on the dashboard.",
                useOperationModule: "Use the operation module to display the data on the dashboard.",
                unLockData: "Unlock the power of data",
                unblock: "Unlock",
            },
            dataEnrichmentModal: {
                integrations: {
                    integratedService: "Integrated Service",
                    integrateService: "Integrate Service",
                    createdStore: "Store created successfully!",
                    store: "Store",
                    watchVideo: "Watch the video on how to integrate the services you use on FullComm Platform.",
                    finishedIntegration: "Finish integration",
                    continueAfter: "Continue later",
                },
                intro: {
                    userRequired: "Username is required.",
                    phoneRequired: "Phone is required.",
                    nameRequired: "Name is required.",
                    termsOfUseRequired: "You must accept the terms of use.",
                    urlValid: "Enter a valid URL. Example: https://www.yourstore.com.",
                    urlRequired: "Store URL is required.",
                    helpUs: "Help us personalize your experience.",
                    name: "Name",
                    phone: "Phone",
                    storeName: "Name of your virtual store",
                    urlStore: "Address of your virtual store",
                    termsOfUse: "I have read and accept the terms of use",
                    initConfig: "Start configuration",
                    continueAfter: "Continue later",
                    segment: "Segment",
                    segmentRequired: "Segment is required.",
                    fashion: "Fashion",
                    home: "Home",
                    food: "Food",
                    beauty: "Beauty",
                    health: "Health",
                    electronics: "Electronics",
                    other: "Other"
                }
            },
            login: {
                informEmail: "Enter your email.",
                informPassword: "Enter your password.",
                auth0: {
                    verifiedEmail: "Verified email? Click here to try again.",
                    continueAfter: "Continue later",
                    login: "Continue to FullComm",
                    register: "Don't have an account? Try it out!",
                    title: "E-commerce on par with giants",
                    subTitle: "FullComm software integrates strategy, performance, logistics: overall efficiency in stores."
                }
            },
            notifications: {
                all: "All",
                communications: "Communications",
                waitingApproval: "Waiting Approval",
                actions: "Actions",
                account: "Account",
                alerts: "Alerts",
                notifications: "Notifications",
                utils: {
                    history: "History . . .",
                },
                icon: {
                    notifications: "Notifications",
                    noneNotifications: "You currently have no notifications.",
                    showAll: "Show all notifications",
                },
                notificationCard: {
                    newActionRegister: "New action registered",
                    updateAction: "Action updated",
                    reproveActivity: "Activity disapproved",
                    approveActivity: "Activity approved",
                    deleteAction: "Action deleted",
                    impediment: "Impediment!",
                    pendingApproval: "Pending approval!",
                    reproveProblem: "Problem disapproved",
                    approveProblem: "Problem approved",
                    requiresEstimation: "Action requires estimation",

                    theAction: "The action",
                    wasRegistered: "was registered successfully!",
                    wasUpdated: "was updated successfully!",
                    theActivity: "The activity",
                    ofAction: "of the action",
                    wasReproved: "was disapproved!",
                    wasApproved: "was approved!",
                    wasDeleted: "was deleted!",
                    isImpediment: 'is with impediment!',
                    needApproval: "needs approval!",
                    theProblemIncident: "The problem/incident",
                    needApproval2: "needs approval!",
                    theProblem: "The problem",
                    was: "was",
                    wasReproved2: "was disapproved!",
                    wasApproved2: "was approved!",
                    moment: "{{timeElapsed}}",
                    pendingChecklist: "has pending checklist!",
                    needEstimation: "needs estimation!"
                }
            },
            perfil: {
                information: "Information",
                perfil: "Profile",
                edit1: "Edit",
                actions: "Actions",
                edit2: "Edit",
                editPassword: "Edit Password",
                picture: "Picture",
                name: "Name",
                email: "Email",
                notifications: "Notifications",
                activate: "Activate",
                language: "Language",
                portuguese: "Portuguese",
                english: "English",
                spanish: "Spanish",
                chatStyle: "Chat Style",
                workingHours: "Working Hours",
                new: "New",
                legacy: "Legacy",
                notDefined: "Not defined",
                invalidHours: "Invalid hours.",
            },
            permission: {
                selectClient: "Select a client",
                invalidData: "Invalid data.",
                updateSuccess: "Permissions updated successfully!",
                userNotFound: "User not found.",
                internalError: "Internal server error.",
                permissions: "Permissions",
                edit: "Edit",
                permissionsModel: "Permission Model",
                none: "None",
                general: "General",
                implantation: "Implantation",
                performance: "Performance",
                support: "Support",
                operation: "Operation",
                savePermissions: "Save",
                permissionModels: {
                    add: "Add",
                    selectModel: "Select a model",
                    updatedPermissions: "Permissions updated successfully",
                    errorUpdatePermissions: "Error updating permissions",
                    createEdit: "Create/Edit Permissions",
                    createModel: "Create model",
                    save: "Save",
                    modelAplic: "Model applies to",
                    admin: "Admin",
                    client: "Client",
                    general: "General",
                    implantation: "Implantation",
                    performance: "Performance",
                    support: "Support",
                    operation: "Operation",
                    freeSoloCreateOption: {
                        createNewModel: "Create new model",
                        permissionsModel: "Permission Model",
                    },
                    modalEditPermission: {
                        permissionAlreadyExists: "Permission already exists!",
                        createSuccess: "Permission created successfully!",
                        errorCreatePermission: "Error creating permission",
                        permissionNotFound: "Permission not found!",
                        updateSuccess: "Permission updated successfully!",
                        internalError: "Internal server error.",
                        errorUpdatePermission: "Error updating Permission",
                        errorDeletePermission: "Error deleting Permission",
                        editPermission: "Edit Permission",
                        name1: "Name",
                        userType: "User type",
                        admin: "Admin",
                        client: "Client",
                        category: "Category",
                        general: "General",
                        implantation: "Implantation",
                        performance: "Performance",
                        support: "Support",
                        operation: "Operation",
                        permissions: "Permissions",
                        name2: "Name",
                        id: "id",
                        addPermission: "Add Permission",
                        save: "Save",
                        delete: "Delete",
                        cancel1: "Cancel",
                        deletePermission: "Delete Permission",
                        confirmDelete: "Are you sure you want to delete the Permission?",
                        remove: "Remove",
                        cancel2: "Cancel"
                    }
                },
                admin: {
                    userNotFound: "User not found.",
                    emailAlreadyExist: "Email already registered.",
                    updateError: "An error occurred while updating the user.",
                    updateSuccess: "User updated successfully.",
                    internalError: "Internal server error.",
                    permissions: "Permissions",
                    edit: "Edit",
                    permissionsModel: "Permission Model",
                    none: "None",
                    general: "General",
                    implantation: "Implantation",
                    performance: "Performance",
                    support: "Support",
                    operation: "Operation",
                    savePermissions: "Save permissions"
                }
            },
            recoveryPassLogin: {
                emailRequired: "Please provide the email!",
                emailError: "Invalid email!",
                rescuePass: "Retrieve Password",
                email: "Email",
                writeEmail: "Enter your email",
                clickHere: "Click here",
                toAccess: " to access",
            },
            scale: {
                logistics: {
                    logistic: "Logistics",
                    tabInit: "Start",
                    tabOrders: "Orders",
                    orders: {
                        created: "New order",
                        error: "Integration error",
                        processed: "Integrated order",
                        shipment: "Order in shipment",
                        shipping: "Delivered to carrier",
                        customer: "Delivered to customer",
                        invoiced: "Invoiced order",
                        store: "Store",
                        last7: "Last 7 days",
                        last15: "Last 15 days",
                        last30: "Last 30 days",
                        last90: "Last 90 days",
                        all: "All",
                        filterItens: "Filter items",
                        statusType: "Status",
                        period: "Period",
                        order: "Order",
                        data: "Date",
                        client: "Client",
                        statusTitle: "Status",
                        value: "Value",
                        linesPerPage: "Lines per page:",
                        of: "of",
                    },
                    start: {
                        filterPeriod: "Filter period",
                        last7: "Last 7 days",
                        last15: "Last 15 days",
                        last30: "Last 30 days",
                        last90: "Last 90 days",
                        all: "All",
                        customer: "Delivered to customer",
                        shipping: "Delivered to carrier",
                        shipment: "Shipment",
                        error: "Integration error",
                        invoicedOrders: "Invoiced orders",
                        totalOrders: "Total orders placed",
                    }
                },
                platinumlog: {
                    orders: "Orders",
                    timeIndicators: "Time Indicators",
                    order: {
                        loading: "Loading...",
                        order: "Order",
                        orderDate: "Order Date",
                        invoice: "Invoice",
                        nfe: "NF-e",
                        prevision: "Prevision",
                        carrier: "Carrier",
                        modality: "Modality",
                        customer: "Customer",
                        contact: "Contact",
                        address: "Address",
                        complement: "Complement",
                        neighborhood: "Neighborhood",
                        city: "City",
                        cep: "CEP",
                        orderDetails: "Order Details",
                        timeIndicators: "Time Indicators",
                        internalEvents: "Internal Events",
                        shippingEvents: "Shipping Events",
                        goods: "Goods",
                    },
                },
                service: {
                    service: "Service",
                    tabInit: "Start",
                    tabOccurrences: "Occurrences",
                    occurrences: {
                        inAnalysis: "In analysis",
                        inTransit: "In transit",
                        inDispute: "In dispute",
                        waitingPost: "Waiting for posting",
                        codeOccurrenceFilter: "Occurrence code",
                        statusFilter: "Status",
                        period: "Period",
                        type: "Type",
                        statusTitle: "Status",
                        codeOccurrenceTitle: "Occurrence code",
                        elapsedTime: "Elapsed time",
                        data: "Date",
                        quantity: "Qty",
                        value: "Occurrence value",
                        immediateAction: "Immediate action",
                        occurrences: "Occurrences"
                    },
                    start: {
                        inAnalysis: "In analysis",
                        inTransit: "In transit",
                        inDispute: "In dispute",
                        waitingPost: "Waiting for posting",
                        status: "Status",
                        codeOccurrence: "Occurrence code",
                        elapsedTime: "Elapsed time",
                    }
                },
                smartShipping: {
                    smartShipping: "Smart Shipping",
                    filterPeriod: "Filter period",
                    last7: "Last 7 days",
                    last15: "Last 15 days",
                    last30: "Last 30 days",
                    last90: "Last 90 days",
                    all: "All",
                    quotes: "Quotes",
                    ZIPCodeDestination: "Destination ZIP Code",
                    generalMedia: "Overall average",
                    mediaV1: "Media V1",
                }
            },
            sprint: {
                results: {
                    results: "Results",
                },
                actionChecklist: {
                    checklistProgress: "Checklist Progress",
                    generalProgress: "GENERAL PROGRESS",
                    checklistStatus: "Checklist Status",
                    create: {
                        actionNotExist: "Action does not exist!",
                        integrationJiraError: "Error in Jira integration, please try saving the action again!",
                        updateError: "Action not found or not modified!",
                        updateSuccess: "Action updated successfully!",
                        internalError: "Internal server error.",
                        checklist: "Checklist",
                        save: "Save",
                        checklistAction: "Action Checklist",
                        subTitle: "Sub-title",
                        title: "Title",
                        helperText: 'Helper text',
                        placeHolder: 'Placeholder',
                        type: 'Type',
                        text: 'Text',
                        textArea: 'Text Area',
                        count: 'Numeric Counter',
                        money: 'Monetary Value',
                        uniqueList: 'Unique Dropdown List',
                        multipleList: 'Multiple Dropdown List',
                        url: 'URL',
                        date: 'Date',
                        email: 'Email',
                        radio: 'Radio',
                        checkbox: 'Checkbox',
                        attachment: 'Attachment',
                        options: 'Options',
                        mandatory: 'Mandatory',
                        selectItem: 'Select an item on the side!',
                    },
                    edit: {
                        registerError: "Checklist was not registered successfully!",
                        internalError: "Internal server error.",
                        registerSuccess: "Checklist registered successfully!",
                        updateError: "An error occurred while updating the Checklist.",
                        updateSuccess: "Task updated successfully!",
                        errorIcons: "Error fetching icons:",
                        addForm: "Form added successfully!",
                        editForm: "Form updated successfully!",
                        checklistAction: "Action Checklist",
                        add: "Add",
                        title: "Title",
                        helperText: 'Helper text',
                        placeHolder: 'Placeholder',
                        type: 'Type',
                        text: 'Text',
                        textArea: 'Text Area',
                        count: 'Numeric Counter',
                        money: 'Monetary Value',
                        uniqueList: 'Unique Dropdown List',
                        multipleList: 'Multiple Dropdown List',
                        url: 'URL',
                        date: 'Date',
                        email: 'Email',
                        radio: 'Radio',
                        checkbox: 'Checkbox',
                        attachment: 'Attachment',
                        condition: 'Condition',
                        noCondition: 'No Condition',
                        valueCondition: 'Value Condition',
                        mandatory: 'Mandatory',
                        options: 'Options',
                        addOption: 'Add Option',
                        addField: 'Add Field',
                        add2: 'Add',
                        save: 'Save',
                        cancel: 'Cancel',
                        delete: 'Delete',
                        selectItem: 'Select an item on the side!',
                        searchIcon: 'Search icon'
                    },
                    inicial: {
                        fieldRequired: "This field is required.",
                        fillFields: "Please fill in all mandatory fields.",
                        actionNotFound: "Action not found!",
                        errorUpdate: "An error occurred while updating the action.",
                        updateSuccess: "Action updated successfully!",
                        internalError: "Internal server error.",
                        archiveHere: "Drop files here",
                        dragOrClick: "Drag and drop your files here or click to select files",
                        checklist: "Checklist",
                        checklistAction: "Action Checklist",
                        save: "Save",
                        selectItem: "Select an item on the side!"
                    },
                    view: {
                        actionNotFound: "Action not found!",
                        errorUpdate: "An error occurred while updating the action.",
                        updateSuccess: "Action updated successfully!",
                        internalError: "Internal server error.",
                        checklist: "Checklist",
                        checklistAction: "Action Checklist",
                        save: "Save",
                        selectItem: "Select an item on the side!"
                    },
                    viewAll: {
                        errorIcons: "Error fetching icons:",
                        delivered: "Delivered",
                        onTime: "On Time",
                        atRisk: "At Risk",
                        late: "Late",
                        checklists: "Checklists",
                        edit: "Edit",
                        search: "Search",
                        status: "Status",
                        all: "All",
                        approveds: "Approved",
                        validatingItems: "Validating Items",
                        waitingSend: "Waiting to Send",
                        repproveds: "Repproved",
                        lateDelivered: "Late/Delivered",
                        onTime2: "On Time",
                        atRisk2: "At Risk",
                        action: "Action",
                        waitingSend2: "Waiting to Send",
                        validatingItems2: "Validating Items",
                        repproveds2: "Repproved",
                        approveds2: "Approved",
                        time: "Time",
                        fillChecklist: "Fill Checklist",
                        viewChecklist: "View Checklist",
                        editChecklist: "Edit Checklist",
                    }
                },
                actionPlan: {
                    actionPlan: "Action Plan",
                    roadMapLabel: "Roadmap",
                    calendary: "Calendar",
                    roadMap: {
                        activeActions: "Active Actions",
                        filter: {
                            apply: "Apply",
                        }
                    },
                    calendarView: {
                        viewBy: "View by:",
                        month: "Month",
                        week: "Week",
                    }
                },
                contract: {
                    form: {
                        theStores: "The stores",
                        haveContract: "already have an active contract!",
                        theStore: "The store",
                        haveContract2: "already has an active contract!",
                        registerError: "Contract was not registered successfully!",
                        registerSuccess: "Contract registered successfully!",
                        internalError: "Internal server error.",
                        updateError: "An error occurred while updating the contract.",
                        updateSuccess: "Contract updated successfully!",
                        reasonSocialRequired: "Social reason is required.",
                        cnpjRequired: "CNPJ is required.",
                        clientIdRequired: "Client ID is required.",
                        clientNameRequired: "Client name is required.",
                        storeRequired: "Store is required.",
                        monthlyCreditsRequired: "Monthly credits are required.",
                        initDateRequired: "Start date is required.",
                        invalidEmail: "Invalid email.",
                        invalidEmail2: "Invalid email.",
                        edit: "Edit",
                        register: "Register",
                        contract: "Contract",
                        socialReason: "Social Reason",
                        cnpj: "CNPJ",
                        associateStores: "Associate Stores",
                        monthlyCredits: "Monthly Credits (Performance Module)",
                        monthlyCreditsSupport: "Monthly Credits (Support Module)",
                        initDate: "Start Date",
                        monthlyHours: "Monthly Hours (Support Module)",
                        changeMode: "Change Support Billing Model",
                        comissionRate: "Commission Rate",
                        minimalRevenue: "Minimum Revenue",
                        contractFiles: "Contract Files",
                        newContractFiles: "New Files",
                        addFiles: "Add Files",
                        financContacts: "Financial Contacts",
                        nameFinanc: "Name",
                        emailFinanc: "Email",
                        phoneFinanc: "Phone",
                        respContacts: "Responsible Contacts",
                        nameResp: "Name",
                        emailResp: "Email",
                        phoneResp: "Phone",
                        save: "Save",
                        cancel: "Cancel"
                    },
                    list: {
                        theStores: "The stores",
                        haveContract: "already have an active contract!",
                        theStore: "The store",
                        haveContract2: "already has an active contract!",
                        internalError: "Internal server error.",
                        updateError: "An error occurred while updating the contract.",
                        updateSuccess: "Contract updated successfully!",
                        contracts: "Contracts",
                        search: "Search",
                        register: "Register",
                        cnpj: "CNPJ",
                        stores: "Stores",
                        status: "Status:",
                        active: "Active",
                        finished: "Finished",
                        all: "All",
                        edit: "Edit",
                        close: "Close",
                        performance: "Performance",
                        creditsPerformance: "Credits:",
                        balancePerformance: "Balance:",
                        support: "Support",
                        creditsSupport: "Credits:",
                        balanceSupport: "Balance:",
                        comissionRate: "Commission Rate",
                    }
                },
                kanban: {
                    problemIncidents: "Improvements and Problems",
                    activities: "Activities",
                    accept: "Accept",
                    sendTo: "Send to",
                    viewReproval: "View Reproval",
                    reproved: "Reproved",
                    comments: "Comments",
                    actionCardArea: {
                        toDo: "To Do",
                        inProgress: "In Progress",
                        customerApproval: "Customer Approval",
                        doneApplied: "Done/Applied",
                    },
                    activityApproval: {
                        updateProblemError: "Error updating the problem.",
                        updateProblemSuccess: "Problem updated successfully!",
                        internalError: "Internal server error.",
                        updateActivityError: "Error updating the activity.",
                        updateActivitySuccess: "Activity updated successfully!",
                        addComentReprove: "You need to add a comment to disapprove the activity.",
                        confirmApprove: 'You are about to approve this activity. Comments recorded in the files will be deleted, and the task will be marked as completed. If you wish to make an adjustment, select "Disapprove".',
                        back: "Back",
                        attachments: "Attachments",
                        addComment: "Add Comment",
                        approve: "Approve",
                        reprove: "Disapprove",
                        comments: "Comments",
                        expandImage: "Expand Image",
                    },
                    activityCard: {
                        toDo: "To Do",
                        inProgress: "In Progress",
                        customerApproval: "Customer Approval",
                        doneApplied: "Done/Applied",
                    },
                    reprovedActivity: {
                        back: "Back",
                        attachments: "Attachments",
                        customerComment: "Customer Comment:",
                    }
                },
                list: {
                    internalError: "Internal server error.",
                    updatedSuccess: "Actions updated successfully!",
                    actionsList: "Actions List",
                    improvementsList: "Improvements List",
                    updateActions: "Update Actions",
                    modal: {
                        deleteActionModal: {
                            deleteAction: "Delete Action",
                            confirmDelete: "Are you sure you want to delete the action?",
                            delete: "Delete",
                            cancel: "Cancel"
                        },
                        integrationsModal: {
                            actionNotExist: "Action does not exist or is not linked to Jira!",
                            unrelatedAction: "Action not linked to Jira!",
                            actionNotModify: "Action not modified!",
                            syncActionSuccess: "Action synchronized successfully!",
                            actionNotExist2: "Action does not exist!",
                            unbindSuccess: "Action unlinked successfully!",
                            actionNotFound: "Action not found!",
                            actionNotModify2: "Action not modified!",
                            internalError: "Internal server error.",
                            updateActionSuccess: "Action updated successfully!",
                            jiraPlatform: "Jira/Platform Relationship",
                            syncStatus: "Sync Jira status with platform",
                            apply: "Apply",
                            unlinkEpic: "Unlink action from epic",
                            apply2: "Apply",
                            linkAction: "Link action to Jira",
                            jiraKey: "Jira Key",
                            linkActivities: "Link activities to Jira",
                            jiraKey2: "Jira Key",
                            save: "Save",
                        }
                    },
                    actionsTable: {
                        name: "Name",
                        quadrant: "Quadrant",
                        init: "Start",
                        end: "End",
                        status: "Status",
                        score: "Score",
                        actionNotFound: "Action not found!",
                        deleteError: "Unable to delete this action!",
                        updateError: "Error updating action!",
                        deleteSuccess: "Action deleted successfully!",
                        internalError: "Internal server error.",
                        active: "Active",
                        backlog: "Backlog",
                        inProgress: "In Progress",
                        done: "Done",
                        toDo: "To Do",
                        planning: "Planning",
                        canceled: "Canceled",
                        activationDiscovery: "Activation - Discovery",
                        acquisitionContent: "Acquisition - Content",
                        engagementNextPurchase: "Engagement - Next Purchase",
                        conversionBuyingBarriers: "Conversion - Buying Barriers",
                        retentionAnalysis: "Retention - Experience Analysis",
                        activationConsideration: "Activation - Consideration",
                        acquisitionTracking: "Acquisition - Tracking",
                        engagementContext: "Engagement - Context",
                        conversionOffers: "Conversion - Offers",
                        retentionTransmission: "Retention - Transmission",
                        activationProspecting: "Activation - Prospecting",
                        acquisitionBenefit: "Acquisition - Benefit",
                        engagementRecovery: "Engagement - Recovery",
                        conversionAuthority: "Conversion - Authority",
                        retentionSegments: "Retention - Segments",
                    },
                    optionsButton: {
                        edit: "Edit",
                        view: "View",
                        delete: "Delete",
                        integration: "Integration",
                        jira: "Jira",
                    }
                },
                newAction: {
                    nameRequired: "Name is required.",
                    categoryRequired: "Category is required.",
                    startRequired: "Start is required.",
                    estimateRequired: "This action requires an estimate!",
                    actionNotExist: "Action does not exist!",
                    errorIntegrationJira: "Error in Jira integration, please try saving the action again!",
                    actionNotFound: "Action not found or not modified!",
                    internalError: "Internal server error.",
                    contractNotFound: "This store does not have a contract!",
                    invalidData: "Invalid data!",
                    registerError: "Error registering the action!",
                    sendError: "Error sending files!",
                    errorUpdateFiles: "Error updating files!",
                    errorCampaign: "Campaign not modified.",
                    updateSuccess: "Action updated successfully!",
                    createSuccess: "Action created successfully!",
                    details: "Details",
                    activities: "Activities",
                    analysis: "Analysis",
                    revision: "Review",
                    backToList: "Back to action list",
                    edit: "Edit",
                    confirm: "Confirm",
                    cancel: "Cancel",
                    prev: "Previous",
                    next: "Next",
                    actionFloatingButton: {
                        type: "Type:",
                        principalChannel: "Principal Channel:",
                        credits: "Credits:",
                        activitiesChannel: "Activities Channels:",
                        impact: "Impact:",
                        trust: "Trust:",
                        facility: "Facility:"
                    },
                    modal: {
                        activation: "Activation",
                        acquisition: "Acquisition",
                        engagement: "Engagement",
                        conversion: "Conversion",
                        retention: "Retention",
                        recommended: "Recommended",
                        createAction: "Create an action",
                        healthScore: "Health score",
                        briefExplanation: "The Health Score is a rating from 0 to 100 that represents the maturity of your e-commerce strategy based on active actions in each quadrant.",
                        categories: "Categories",
                        all: "All",
                        createNewAction: "Create new action",
                        search: "Search",
                        searchAction: "Search action",
                        loading: {
                            pleaseWait: "We are processing your request. Please wait...",
                        }
                    },
                    pages: {
                        activities: {
                            creditsLimitReached: "Credits limit reached!",
                            initialPage: "Home Page",
                            interfaceDev: "Interface Development",
                            perTemplate: "per template",
                            descriptionDevInterface: "Interface Development - Home Page: Here, the home page of the website or platform is developed, which usually presents relevant information, promotions, or product highlights, with the aim of attracting and engaging visitors.",
                            categoryPage: "Category Page",
                            descriptionDevCategory: "Interface Development - Category Page: This activity involves the development of the category page, where products are grouped according to their categories or types, facilitating navigation and search for customers.",
                            productPage: "Product Page",
                            descriptionDevProduct: "Interface Development - Product Page: The creation of the product page is carried out, which displays detailed information about a specific item, such as description, images, price, variation options, and purchase buttons, providing a complete customer experience.",
                            priceUpdate: "Price Update via Spreadsheet",
                            inventory: "Inventory",
                            perHour: "per hour",
                            descriptionPriceUpdate: "Inventory - Price Update via Spreadsheet: The prices of products are updated through a spreadsheet, allowing bulk changes and facilitating the maintenance of updated values.",
                            productUpdate: "Product Update",
                            unit: "unit",
                            descriptionProductUpdate: "Inventory - Product Update: Here, necessary updates are made to the data of registered products, such as price changes, description, images, attributes, among others.",
                            diversePage: "Various Pages: cart, my account, about us, etc.",
                            descriptionDiversePage: 'Interface Development - Various Pages: cart, my account, about us, etc.: Here, different secondary or complementary pages are developed, such as the shopping cart, user account area, "About Us" page, or other institutional pages.',
                            landingPage: "Promotional Landing Page, upsell, sales letter, etc.",
                            descriptionLandingPage: "Interface Development - Promotional Landing Page, upsell, sales letter, etc.: This activity involves the development of landing pages, which are specific pages created for promotional campaigns, upsell (additional sales), or sales letters, with the aim of directing visitors to specific actions and increasing conversions.",
                            interfaceDesign: "Interface Design",
                            descriptionDesignInterface: "Interface Design - Home Page: In this activity, the design of the home page of the website or platform is carried out, considering visual aspects such as layout, colors, typography, and graphic elements, aiming to convey the brand's visual identity and provide a pleasant aesthetic experience to users.",
                            promotionalHomePage: "Promotional Home Page",
                            descriptionPromotionalHomePage: "Interface Development - Promotional Home Page: Here, a promotional version of the home page is developed, highlighting offers, discounts, or featured products, with the aim of boosting sales and attracting the attention of visitors.",
                            descriptionDesignCategory: "Interface Design - Category Page: The design of the category page is developed, taking into account the organization and arrangement of products, the application of search filters, the presentation of images and relevant information, with the aim of facilitating navigation and making the user experience more intuitive.",
                            mainElements: "Main Elements: header, footer, and style sheet",
                            descriptionMainElements: "Interface Development - Main Elements: header, footer, and style sheet: In this activity, the main elements of an interface are developed, such as the header, footer, and the overall styling of the page (style sheet), ensuring a consistent and professional appearance throughout the site.",
                            spreadsheetCreation: "Spreadsheet Creation/Correction",
                            descriptionSpreadsheetCreation: "Inventory - Spreadsheet Creation/Correction: This activity involves the creation or correction of a spreadsheet used for product control and organization, ensuring consistency and accuracy of information.",
                            descriptionDesignProduct: "Interface Design - Product Page: Here, the design of the product page is carried out, taking into account the presentation of images, descriptions, variation options, purchase buttons, and other visual elements, with the aim of highlighting the product's features and attractions, as well as providing a clear and attractive interface for customers.",
                            descriptionDesignDiversePage: 'Interface Design - Various Pages: cart, my account, about us, etc.: The designs of different secondary or complementary pages are created here, such as the shopping cart, user account area, "About Us" page, or other institutional pages, following the brand\'s visual identity and ensuring aesthetic consistency throughout the site.',
                            descriptionDesignLandingPage: "Interface Design - Landing Page: promotional, upsell, sales letter, etc.: Landing pages are designed attractively and persuasively here, with impactful visual elements, persuasive content, and a clear call to action, aiming to direct visitors to a specific action, such as making a purchase or providing contact information.",
                            descriptionDesignPromotionalHomePage: "Interface Design - Promotional Home Page: This activity involves the design of a promotional version of the home page, highlighting offers, discounts, or featured products through images, banners, or other visual elements, with the goal of grabbing visitors' attention and encouraging conversion.",
                            uiKit: "UI Kit",
                            descriptionUiKit: "Interface Design - UI Kit: In this activity, a set of interface elements (UI Kit) is created, including buttons, icons, checkboxes, scroll bars, and more, following a consistent visual identity and facilitating the development and design of new pages and interface elements.",
                            uxDesignerHour: "UX Designer (per hour)",
                            descriptionUxDesignerHour: "Interface Design - UX Designer (User Experience) (per hour): A UX designer is responsible for creating a positive and intuitive user experience, analyzing navigation flows, interactions, information organization, and site or platform usability. In this activity, the UX designer works on specific projects per hour, aiming to improve the user experience.",
                            emailDesign: "Email Design (Autoresponder, Broadcast, and Transactional)",
                            graphicDesign: "Graphic Design",
                            perNotification: "per notification",
                            descriptionEmailDesign: "Graphic Design - Email Design (Autoresponder, Broadcast, and Transactional): Email designs are created here for different purposes, such as autoresponder (automatic responses), broadcast (mass sending), and transactional (related to specific user actions). Emails may include graphic elements, images, formatted text, and calls to action.",
                            every10Pages: "every 10 pages",
                            ebookDesign: "E-book Design and Layout",
                            descriptionEbookDesign: "Graphic Design - E-book Design and Layout: In this activity, the design and layout of e-books are carried out, which are longer digital materials such as guides, tutorials, catalogs, or e-books. The design and layout aim to make the content visually attractive and easy to read, with graphic elements, illustrations, and proper formatting.",
                            imageTreatment: "Image Treatment (for web)",
                            imageEditing: "Image Editing",
                            perPhoto: "per photo",
                            descriptionImageTreatment: "Image Editing - Image Treatment (for web): Here, images are edited and treated properly for the web, including size adjustments, quality optimization, cropping, color correction, removal of imperfections, and more. The goal is to ensure that images are visually appealing and load quickly on the site or platform's pages.",
                            imageBackgroundCutting: "Image Background Cutting (for web)",
                            descriptionImageBackgroundCutting: "Image Editing - Image Background Cutting (for web): In this activity, the background of an image is cut, removing unwanted elements or isolating the main object. This cutting is done specifically for web use, allowing the inserted object to be used in different contexts without the original background.",
                            videoAdsDesign: "Video Ads Design (15 seconds)",
                            videoEditing: "Video Editing",
                            perAd: "per ad",
                            descriptionVideoAdsDesign: "Video Editing - Video Ads Design (15 seconds): This activity involves creating video ads with a duration of 15 seconds. The visual design of the ad is done, including the selection of images, text, graphic elements, and transitions, aiming to capture the attention of the target audience and efficiently convey the message in the short available time.",
                            motionGraphics: "Motion Graphics",
                            perMinute: "per minute",
                            descriptionMotionGraphics: "Video Editing - Motion Graphics: In this activity, moving graphic elements are created for videos using motion graphics techniques. These elements can include animations, transitions, animated infographics, moving text, among others, making the video more dynamic and visually appealing.",
                            videoAdsDesign30: "Video Ads Design (30 seconds)",
                            descriptionVideoAdsDesign30: "Video Editing - Video Ads Design (30 seconds): Similar to the previous activity, but in this case, the video ads have a duration of 30 seconds. The goal is to create visually attractive and engaging content within this time frame to attract and engage viewers.",
                            videoEditingPerMin: "Video Editing (per minute rate)",
                            descriptionVideoEditingPerMin: "Video Editing - Video Editing (per minute rate): Here, the editing of already recorded videos is done, including cutting, merging scenes, adding transitions, color correction, audio adjustment, and other necessary adjustments. The activity is remunerated based on the duration of the video, measured in minutes.",
                            videoCaptioningPerMin: "Video Captioning (per minute rate)",
                            descriptionVideoCaptioningPerMin: "Video Editing - Video Captioning (per minute rate): Subtitles are added to videos by transcribing spoken content and synchronizing it with the video. This activity is remunerated based on the duration of the video, measured in minutes.",
                            couponCreation: "Coupon Creation",
                            ecommerceManagement: "E-commerce Management",
                            perRule: "per rule",
                            descriptionCouponCreation: "E-commerce Management - Coupon Creation: In this activity, discount coupons that customers can use for their purchases are created. Coupons can be customized with unique codes and specific usage rules, contributing to increasing sales and customer loyalty.",
                            captationForm: "Captation Form",
                            descriptionCaptationForm: "E-commerce Management - Captation Form: A data capture form is created, which can be used to collect customer contact information such as name, email, phone, among others. These forms are integrated into the website or platform and can be used to build a contact list or target the audience.",
                            scriptManagement: "Script and Tracker Management (via GTM)",
                            perScript: "per script",
                            descriptionScriptManagement: "E-commerce Management - Script and Tracker Management (via GTM): In this activity, website or platform scripts and trackers (tags) are managed through Google Tag Manager (GTM). This involves creating, editing, and deleting tags for tracking events, conversions, user behavior analysis, among others.",
                            manualPaymentReconciliation: "Manual Payment Reconciliation",
                            perTransaction: "per transaction",
                            descriptionManualPaymentReconciliation: "E-commerce Management - Manual Payment Reconciliation: In this activity, manual reconciliation of payments received by the company is performed, verifying if the values recorded in the system are correct and correspond to payments actually received.",
                            merchandisingAutomation: "Merchandising Automation Configuration (Launchpad)",
                            perAction: "per action",
                            descriptionMerchandisingAutomation: "E-commerce Management - Merchandising Automation Configuration (Launchpad): In this activity, merchandising automation is configured through the Launchpad platform. This involves defining rules and conditions for displaying specific banners, showcases, or promotions based on criteria such as customer profile, time period, product categories, among others.",
                            taskAutomation: "Task Automation Configuration (Flow)",
                            perAutomation: "per automation",
                            descriptionTaskAutomation: "E-commerce Management - Task Automation Configuration (Flow): Task automations are configured through the Flow platform, aiming to automate internal e-commerce processes. This may include the automatic sending of order follow-up emails, status updates, feedback requests, among other actions related to customer relationship.",
                            reviewsModeration: "Reviews Moderation",
                            perReview: "per review",
                            descriptionReviewsModeration: "E-commerce Management - Reviews Moderation: In this activity, moderation is performed on reviews and comments left by customers on the company's products or services. Moderation may involve approval, editing, or deletion of reviews, ensuring quality and appropriate representation of customer opinions.",
                            adConfiguration: "Ad Configuration",
                            marketplaceManagement: "Marketplace Management",
                            perMarketplace: "per marketplace",
                            descriptionAdConfiguration: "Marketplace Management - Ad Configuration: In this activity, necessary configurations are made for advertising on marketplaces such as Amazon, eBay, or Mercado Libre. This may include creating titles, descriptions, images, categories, and additional information for each ad.",
                            adKitConfiguration: "Ad Kit Configuration",
                            descriptionAdKitConfiguration: "Marketplace Management - Ad Kit Configuration: Configuration of product kits for marketplace ads is performed. Kits are sets of products sold together at a single price, allowing for special packages or combinations to be offered to customers.",
                            priceManagement: "Price Management",
                            perProduct: "per product",
                            descriptionPriceManagement: "Marketplace Management - Price Management: In this activity, price management for products on marketplaces is carried out, considering pricing strategies, competition analysis, profit margins, and sales goals. Prices are adjusted according to market demands and conditions.",
                            rulesConfiguration: "Rules Configuration",
                            descriptionRulesConfiguration: "Marketplace Management - Rules Configuration: Specific rules are configured on marketplaces, considering sales policies, product restrictions, shipping policies, and other guidelines established by the platform. This ensures that the company complies with the rules and regulations of each marketplace.",
                            freightManagement: "Freight Management",
                            descriptionFreightManagement: "Marketplace Management - Freight Management: Here, freight management is performed for products sold on marketplaces. This includes configuring delivery options, calculating shipping costs, integrating with transportation services, and updating tracking information for customers.",
                            dnsEntriesEditing: "DNS Entries Editing",
                            deployment: "Deployment",
                            perPointing: "per pointing",
                            descriptionDnsEntriesEditing: "Deployment - DNS Entries Editing: In this activity, edits are made to the DNS (Domain Name System) entries of the website or platform, configuring domain resolution, URL redirection, or defining specific records such as SPF (Sender Policy Framework) or DKIM (DomainKeys Identified Mail) to ensure domain security and proper functionality.",
                            integrationMiddleware: "Integration (via middleware)",
                            perConnection: "per connection",
                            descriptionIntegrationMiddleware: "Deployment - Integration (via middleware): Integration between different e-commerce systems or platforms is performed through a CheckStore middleware (n8n), which acts as an intermediary to facilitate information exchange and data synchronization. This enables efficient communication between different systems and the integration of business processes.",
                            scriptInstallationCode: "Script and Tracker Installation (via code)",
                            descriptionScriptInstallationCode: "Deployment - Script and Tracker Installation (via code): In this activity, scripts and trackers are installed on the website or platform by inserting custom code. These scripts and trackers can be provided by third parties and are used to collect data such as traffic analysis, conversion tracking, integration with marketing tools, and more.",
                            scriptInstallationGtm: "Script and Tracker Installation (via GTM)",
                            descriptionScriptInstallationGtm: "Deployment - Script and Tracker Installation (via GTM): Here, scripts and trackers are installed on the website or platform through Google Tag Manager (GTM). GTM facilitates the implementation and management of tracking tags, allowing for the addition, editing, and removal of these tags without the need to directly modify the website's code.",
                            frontEndDevHour: "Front-end Development (per hour)",
                            descriptionFrontEndDevHour: "Deployment - Front-end Development (per hour): In this activity, the front-end layer of the website or platform is developed. This involves creating and implementing visual elements such as layouts, styles, interactions, and animations using technologies like HTML, CSS, and JavaScript to ensure an attractive and responsive user experience.",
                            fullStackDevHour: "Full-stack Development (per hour)",
                            descriptionFullStackDevHour: "Deployment - Full-stack Development (per hour): Here, full-stack development of the website or platform is carried out, covering both the front-end and back-end layers. This includes implementing features, integrating with databases, handling business logic, user authentication, and other tasks using programming languages such as JavaScript, Python, Ruby, or PHP, depending on project requirements.",
                            emailScheduling: "Email Scheduling",
                            digitalMarketing: "Digital Marketing",
                            descriptionEmailScheduling: "Digital Marketing - Email Scheduling: In this activity, the scheduling of promotional, informational, or transactional emails to customers is done. This involves creating email content, selecting recipients, defining dates and times for sending, and configuring email marketing automations to deliver personalized and relevant messages to recipients.",
                            softwareArchitectHour: "Software Architect (per hour)",
                            descriptionSoftwareArchitectHour: "Deployment - Software Architect (per hour): In this activity, a software architect is hired on an hourly basis to provide strategic and technical guidance related to the architecture of the website or platform. The software architect analyzes project requirements, defines the system structure, selects appropriate technologies, and establishes development guidelines to ensure a robust, scalable, and high-quality project.",
                            pushNotificationSms: "Push Notification/SMS",
                            descriptionPushNotificationSms: "Digital Marketing - Push Notification/SMS: Here, push notifications or SMS messages are configured and sent to users via mobile devices or the web. These notifications can be used to send updates, alerts, exclusive offers, or reminders to customers, aiming to maintain engagement and promote interaction with the e-commerce.",
                            customerSegmentsConfig: "Customer Segments Configuration",
                            perSegment: "per segment",
                            descriptionCustomerSegmentsConfig: "Digital Marketing - Customer Segments Configuration: In this activity, customer segments are configured based on specific criteria such as buying behavior, preferences, interaction history, or demographic information. Segmentation allows for personalized messaging to specific groups of customers, increasing the relevance and effectiveness of marketing campaigns.",
                            marketingAutoFunnelSetup: "Marketing Automation Funnel Setup",
                            perFunnel: "per funnel",
                            descriptionMarketingAutoFunnelSetup: "Digital Marketing - Marketing Automation Funnel Setup: In this activity, the setup of the marketing automation funnel is performed. This consists of a sequence of automated steps to nurture leads, convert potential customers, and promote loyalty. This involves creating automation flows, defining triggers, configuring automated emails, and monitoring funnel performance.",
                            customEventsSetup: "Custom Events Setup (via GTM)",
                            perEvent: "per event",
                            descriptionCustomEventsSetup: "Digital Marketing - Custom Events Setup (via GTM): In this activity, custom events are configured in Google Tag Manager (GTM) to track specific user actions on the website or platform. These events can include button clicks, form submissions, page views, video playback, among others. Tracking custom events allows for insights into user behavior and optimization of marketing strategies.",
                            scPressAdvisory: "Press Advisory (Local SC Media)",
                            perCycle: "per cycle",
                            descriptionScPressAdvisory: "Digital Marketing - Press Advisory (Local SC Media): This activity involves press advisory focused on the local media in Santa Catarina (SC). It includes contacting local media outlets, drafting press releases, scheduling interviews, and monitoring news and mentions of the company in local media.",
                            nationalPressAdvisory: "Press Advisory (National Media)",
                            descriptionNationalPressAdvisory: "Digital Marketing - Press Advisory (National Media): Here, press advisory is conducted for national media. Contacts are established with journalists, press releases are prepared, news is disseminated, and spaces are secured in nationally-reaching media outlets.",
                            ebookTextOnly: "E-book (text only)",
                            descriptionEbookTextOnly: "Digital Marketing - E-book (text only): In this activity, an e-book containing only text is developed, addressing a relevant and interesting topic for the e-commerce target audience. The e-book can serve as educational material, a practical guide, a compilation of tips, or any other format that provides valuable information to readers.",
                            pressAdvisoryClipping: "Press Advisory (clipping)",
                            descriptionPressAdvisoryClipping: "Digital Marketing - Press Advisory (clipping): In this activity, news related to the company in media outlets is clipped. Mentions, reports, and articles mentioning the company are monitored, compiled, and organized for analysis and tracking.",
                            productAdvertisingWriting: "Product Advertising Writing",
                            advertisingWriting: "Advertising Writing",
                            descriptionProductAdvertisingWriting: "Advertising Writing - Product Advertising Writing: Here, specific advertising texts are crafted for the description and promotion of e-commerce products. The texts are written persuasively, highlighting the benefits, features, and differentiators of the products, with the aim of encouraging purchases and sparking the interest of the target audience.",
                            pagesContentWriting: "Page Content Writing",
                            descriptionPageContentWriting: "Advertising Writing - Page Content Writing: Here, advertising texts are crafted for the content of e-commerce pages, including the homepage, institutional pages, special product pages, special category pages, among others. The texts are written attractively, informatively, and persuasively, aiming to captivate visitors and prompt them to take desired actions, such as making a purchase or getting in touch.",
                            customMktReport: "Custom Marketing Report",
                            reports: "Reports",
                            perMetric: "per metric",
                            descriptionCustomMktReport: "Reports - Custom Marketing Report: In this activity, custom reports are prepared that provide a comprehensive analysis of the marketing strategies adopted by the e-commerce business. The reports may include metrics such as website traffic, traffic source, conversion rates, social media engagement, email marketing campaign analysis, content analysis, and other information that helps evaluate the effectiveness of marketing strategies and guide future decisions.",
                            customSalesReport: "Custom Sales Report",
                            descriptionCustomSalesReport: "Reports - Custom Sales Report: Here, custom reports are developed that offer a comprehensive view of the sales made in the e-commerce business. The reports may include metrics such as sales volume, revenue, average order value, profit margin, analysis of best-selling products, customer analysis, and other relevant information for monitoring sales performance and success.",
                            seoContent: "SEO Content",
                            seo: "SEO",
                            perUrl: "per URL",
                            descriptionSeoContent: "SEO - SEO Content: In this activity, e-commerce content is created and optimized to improve its visibility in search engines. This involves researching relevant keywords, creating optimized texts and descriptions, implementing appropriate HTML tags, and other best practices to enhance the website's position in search results.",
                            seoAdjustment: "Technical SEO Adjustment",
                            descriptionSeoAdjustment: "SEO - Technical SEO Adjustment: In this activity, technical adjustments are made to the website or platform for search engine optimization. This may include optimizing the site structure, improving page loading speed, configuring metadata properly, optimizing URLs, creating sitemaps, and implementing structured markup, among other tasks.",
                            facebookBusiness: "Facebook Business",
                            marketingSetup: "Marketing Setup",
                            perService: "per service",
                            descriptionFacebookBusiness: "Marketing Setup - Facebook Business: In this activity, the setup of the Facebook Business account for e-commerce is performed. This involves creating the account, configuring company information, associating with Facebook pages, defining access permissions, and integrating with other Facebook marketing tools.",
                            facebookPage: "Facebook Page",
                            descriptionFacebookPage: "Marketing Setup - Facebook Page: Here, the setup of the Facebook page for e-commerce is carried out. This includes creating the page, customizing layout and information, defining categories, adding visual elements, and configuring privacy and security settings.",
                            fbInstagramAccount: "Facebook Instagram Account",
                            descriptionFbInstagramAccount: "Marketing Setup - Facebook Instagram Account: In this activity, the setup of the Instagram account linked to e-commerce is performed. This involves creating the account, configuring profile information, associating it with the Facebook page, and integrating with other Instagram marketing tools.",
                            fbAdsAccount: "Facebook Ads Account",
                            descriptionFbAdsAccount: "Marketing Setup - Facebook Ads Account: Here, the setup of the Facebook Ads account for e-commerce is conducted. This includes creating the account, configuring billing information, defining privacy and security settings, and associating it with the Facebook page and Instagram account.",
                            fbPixel: "Facebook Pixel",
                            descriptionFbPixel: "Marketing Setup - Facebook Pixel: In this activity, the setup of the Facebook Pixel, a tool that allows tracking and measuring user actions on the website or platform, is carried out. This includes generating the pixel code, inserting it correctly on the site, configuring events and conversions to be tracked, and verifying its proper functionality.",
                            fbProductCatalog: "Facebook Product Catalog",
                            descriptionFbProductCatalog: "Marketing Setup - Facebook Product Catalog: Here, the setup of the Facebook Product Catalog is performed, allowing for the display and promotion of e-commerce products on Facebook platforms such as Instagram and Facebook Marketplace. This includes creating and configuring the catalog, synchronizing with e-commerce products, adding product information and images, and configuring display and targeting settings.",
                            fbPixelTrafficPermissions: "Facebook Pixel Traffic Permissions",
                            descriptionFbPixelTrafficPermissions: "Marketing Setup - Facebook Pixel Traffic Permissions: In this activity, permissions for Facebook Pixel traffic are configured, determining which domains or URLs are authorized to send data to the pixel and which domains or URLs have permission to receive data from the pixel. This helps ensure the security and integrity of the tracked and shared information by the pixel.",
                            dataLayer: "DataLayer",
                            descriptionDataLayer: "Marketing Setup - DataLayer: Here, the DataLayer setup is performed, which is a data structure that enables the collection and sharing of specific information about user events and interactions on the website or platform. This involves the proper implementation of DataLayer in the site's code, defining variables and values to capture, and configuring integrations with other marketing tools.",
                            googleTagManager: "Google Tag Manager",
                            descriptionGtm: "Marketing Setup - Google Tag Manager: In this activity, the setup of Google Tag Manager (GTM) is performed, a tool that allows centralized management of tag and tracker implementations on the website or platform. This includes creating the GTM account, configuring the container structure, implementing GTM code on the site, and configuring the necessary tags and triggers to track specific events and interactions.",
                            googleAnalytics: "Google Analytics",
                            descriptionGoogleAnalytics: "Marketing Setup - Google Analytics: Here, the setup of Google Analytics, a data analysis tool that provides detailed information about traffic, user behavior, conversions, and other important metrics on the website or platform, is performed. This involves creating the Google Analytics account, configuring tracking code, defining goals and conversions, and customizing reports and dashboards.",
                            advancedEcommerceGA: "Advanced Ecommerce Google Analytics",
                            descriptionAdvancedGA: "Marketing Setup - Advanced Ecommerce Google Analytics: In this activity, the setup of advanced Google Analytics features for ecommerce analysis is performed. This includes configuring transaction tracking, conversion funnel analysis, product and category analysis, sales performance analysis, and other specific metrics for ecommerce.",
                            googleSearchConsole: "Google Search Console",
                            descriptionGoogleSearchConsole: "Marketing Setup - Google Search Console: In this activity, the setup of Google Search Console, a tool that provides detailed information about the site's presence in Google search results, is performed. This includes creating the Search Console account, verifying site ownership, submitting the sitemap, monitoring search performance, identifying indexing errors and issues, and tracking keywords and positions in search results.",
                            googleMerchantCenter: "Google Merchant Center",
                            descriptionGoogleMerchantCenter: "Marketing Setup - Google Merchant Center: Here, the setup of Google Merchant Center is performed, a platform that allows merchants to upload and manage product information for display in Google search results and other Google services such as Google Shopping. This involves creating the Merchant Center account, configuring business information, importing data from the product catalog, configuring display and targeting settings, and verifying compliance with Google policies.",
                            googleAds: "Google Ads",
                            descriptionGoogleAds: "Marketing Setup - Google Ads: In this activity, the setup of Google Ads (formerly known as Google AdWords) is performed, which is an online advertising platform that allows for the creation and management of paid advertising campaigns on Google search engines and other partner websites. This includes creating the Google Ads account, configuring billing information, defining campaign settings, creating ad groups, selecting relevant keywords, and creating persuasive ads.",
                            googleAdsConvBuy: "Google Ads Conversion Tracking (Purchase)",
                            descriptionGoogleAdsConvBuy: "Marketing Setup - Google Ads Conversion Tracking (Purchase): Here, the setup of purchase conversion tracking in Google Ads is carried out. This involves configuring tags and events to track specific conversions related to transactions and purchases in e-commerce, enabling the measurement of return on investment (ROI) for paid advertising campaigns.",
                            googleAdsRemarketing: "Google Ads Remarketing",
                            descriptionGoogleAdsRemarketing: "Marketing Setup - Google Ads Remarketing: In this activity, the setup of remarketing campaigns in Google Ads is performed. This includes creating remarketing lists based on user behavior on the website or platform, configuring custom ads targeted to these lists, and defining bidding and targeting strategies to reach users who have already interacted with the e-commerce.",
                            googleAdsAnalyticsLink: "Google Ads and Google Analytics Integration",
                            descriptionGoogleAdsLinking: "Marketing Setup - Google Ads and Google Analytics Integration: In this activity, the linking of the Google Ads account with the Google Analytics account is conducted. This allows for data synchronization between the two platforms, providing an integrated view of metrics and performance for paid advertising campaigns and their impact on user interactions on the website or platform.",
                            googleAdsConvLink: "Google Ads Conversion Linker",
                            descriptionGoogleAdsConvLinker: "Marketing Setup - Google Ads Conversion Linker: Here, the setup of the Google Ads Conversion Linker is performed, which allows associating conversions tracked in Google Analytics with specific campaigns and ads in Google Ads. This facilitates campaign performance analysis and accurate attribution of conversions generated by paid ads.",
                            googleAdsMerchantLink: "Google Ads and Merchant Center Integration",
                            descriptionGoogleAdsMerchantLinking: "Marketing Setup - Google Ads and Merchant Center Integration: In this activity, the linking of the Google Ads account with the Google Merchant Center account is carried out. This enables data synchronization regarding products and paid advertising campaigns, allowing for the display of relevant product ads in search results and on Google Shopping.",
                            ga4EventsImplementation: "GA4 Events Implementation via Google Tag Manager",
                            descriptionGA4EventsImplementation: "Marketing Setup - GA4 Events Implementation via Google Tag Manager: Here, the implementation of Google Analytics 4 (GA4) events through Google Tag Manager is performed. This includes configuring custom events in GTM, creating variables and triggers to capture specific user interactions on the website or platform, and sending the corresponding data to GA4 for analysis.",
                            ga4ReportsImplementation: "GA4 Reports Implementation",
                            descriptionGA4ReportsImplementation: "Marketing Setup - GA4 Reports Implementation: In this activity, the implementation of custom reports in Google Analytics 4 (GA4) is carried out. This involves setting up specific reports to provide insights and relevant metrics for e-commerce, such as conversion funnel analysis, event analysis, audience analysis, and other information that aids in understanding user behavior and performance.",
                            marketingCloudTag: "Marketing Cloud Platform Tag",
                            descriptionMarketingCloudTag: "Marketing Setup - Marketing Cloud Platform Tag: In this activity, the setup of the marketing cloud platform tag on the website or platform is performed. This allows for the collection of data on user behavior, interactions, conversions, and other information relevant for data-driven marketing analysis and personalization.",
                            multilingualTranslation: "Multilingual Translation per Page/Template",
                            translation: "Translation",
                            descriptionMultilanguageTranslation: "Translation - Multilingual Translation per Page/Template: In this activity, the translation of e-commerce pages or templates into other languages is conducted. This includes translating text, product descriptions, buttons, menus, and other visual and communication elements to provide a localized experience for users who speak different languages.",
                            searchAdsCampaignSetup: "Search Ads Campaign Setup",
                            paidTraffic: "Paid Traffic",
                            adSet: "ad set",
                            descriptionSearchAdsCampaignSetup: "Paid Traffic - Search Ads Campaign Setup: Here, the setup of Search Ads campaigns, which are displayed in search engine results, is performed. This involves configuring keywords, creating relevant ads, and defining bids and budgets to reach users interested in the e-commerce products or services.",
                            productListingAdsCampaignSetup: "Product Listing Ads (Shopping) Campaign Setup",
                            descriptionProductListingAdsCampaignSetup: "Paid Traffic - Product Listing Ads (Shopping) Campaign Setup: Here, the setup of Product Listing Ads (PLAs) campaigns, which are displayed in Google Shopping search results, is performed. This includes creating the product catalog in the Merchant Center, configuring product information, and setting bids and budgets to display ads for products relevant to users.",
                            leadAdsCampaignSetup: "Lead Ads Campaign Setup",
                            descriptionLeadAdsCampaignSetup: "Paid Traffic - Lead Ads Campaign Setup: Here, the setup of Lead Ads campaigns, designed to capture contact information and generate qualified leads, is performed. This involves creating lead generation forms, defining criteria and targeting to display ads to relevant users, and configuring tracking flows and integrations with marketing automation tools.",
                            displayAdsCampaignSetup: "Display Ads Campaign Setup",
                            descriptionDisplayAdsCampaignSetup: "Paid Traffic - Display Ads Campaign Setup: In this activity, the setup of Display Ads campaigns, which are displayed on partner websites through banners, images, or videos, is performed. This includes configuring targeting criteria, creating persuasive ads, and defining bidding and budget strategies to reach the e-commerce's target audience.",
                            videoAdsCampaignSetup: "Video Ads Campaign Setup",
                            descriptionVideoAdsCampaignSetup: "Paid Traffic - Video Ads Campaign Setup: Here, the setup of Video Ads campaigns, which are promotional videos displayed on video platforms like YouTube, is performed. This involves creating relevant and impactful videos, configuring targeting criteria, and defining bidding and budget strategies to reach the e-commerce's target audience.",
                            interestAudiencesConfig: "Interest Audiences Configuration",
                            descriptionInterestAudiencesConfig: "Paid Traffic - Interest Audiences Configuration: In this activity, targeted interest audiences are configured for paid traffic campaigns. This involves defining demographic, behavioral, and interest criteria to target ads to users most likely to be interested in the e-commerce's products or services.",
                            customAudiencesConfig: "Custom Audiences Configuration",
                            descriptionCustomAudiencesConfig: "Paid Traffic - Custom Audiences Configuration: Here, custom audiences are configured for paid traffic campaigns. This may include creating audiences based on existing customer data, email lists, website visitors, social media interactions, and other relevant data sources. These custom audiences enable targeting ads to highly qualified users likely to convert into customers.",
                            campaignOptimization: "Campaign Optimization",
                            perDay: "per day",
                            descriptionCampaignOptimization: "Paid Traffic - Campaign Optimization: In this activity, continuous optimizations are performed on paid traffic campaigns to maximize performance and results. This includes metrics analysis, bid and budget adjustments, segmentation refinement, creative and messaging testing, and implementation of optimization strategies to achieve defined objectives.",
                            navigationUsabilityAnalysis: "Navigation Usability Analysis",
                            uiUx: "UI & UX",
                            descriptionNavigationUsabilityAnalysis: "UI & UX - Navigation Usability Analysis: In this activity, a detailed analysis of the usability of the website or platform navigation is conducted. This involves reviewing information architecture, menu organization, navigation flow, product categorization, and other user interactions. The goal is to identify areas for improvement, simplify navigation, and provide a more intuitive and pleasant experience for users.",
                            imageCuttingResizing: "Image Cropping and Resizing",
                            descriptionImageCroppingResizing: "Graphic Design - Image Cropping and Resizing: In this activity, precise image cropping and resizing are performed according to project needs. This includes removing unwanted backgrounds, adjusting proportions, resolution, and size, ensuring that images are ready for use in different contexts such as websites, social media, print, and more.",
                            creativeAdjustments: "Creative Adjustments",
                            descriptionCreativeAdjustments: "Graphic Design - Creative Adjustments: Here, adjustments are made to existing visual elements such as banners, ads, or other graphic resources. This involves enhancing aesthetics, making layout changes, adjusting colors, fonts, images, and other elements to improve visual communication and ensure brand identity consistency.",
                            seoReport: "SEO Off-Page Report",
                            perStore: "per store",
                            descriptionSeoOffPageReport: "SEO - Off-Page SEO Report: In this activity, a comprehensive report is prepared that analyzes off-page SEO optimization strategies and actions. This includes the analysis of external factors affecting search engine ranking, such as the quality and quantity of backlinks, social media mentions, directory listings, and other off-page marketing actions.",
                            erpProductRegistration: "ERP Product Registration",
                            descriptionErpProductRegistration: "Inventory - ERP Product Registration: In this activity, products are registered in the e-commerce's Enterprise Resource Planning (ERP) system. This involves entering detailed information about products, such as descriptions, images, prices, inventory, categories, specific attributes, and other information relevant for inventory control and proper product exposure.",
                            seoReportInPage: "In-Page SEO Report",
                            perPage: "per page",
                            descriptionSeoInPageReport: "SEO - In-Page SEO Report: Here, a report is prepared that focuses on optimizations made directly on the website's pages. This includes the analysis of factors such as keywords, URL structure, meta tags, proper use of heading tags, content quality and relevance, page loading speed, and other aspects affecting visibility and ranking in search engines.",
                            showcaseOptimization: "Showcase Optimization",
                            perShowcase: "per showcase",
                            descriptionShowcaseOptimization: "E-commerce Management - Showcase Optimization: In this activity, the optimization of the e-commerce showcase, the area where products are displayed on the homepage or featured, is performed. This involves careful selection of products to be displayed, strategic ordering, visual customization, and periodic updates to promote the most relevant items and capture visitors' attention, increasing the chances of conversion.",
                            appConfigurationHour: "App Configuration (per hour)",
                            descriptionAppConfiguration: "E-commerce Management - App Configuration (per hour): Here, the configuration of a mobile application dedicated to e-commerce is carried out. This may include integration with the existing management system, configuration of specific features such as push notifications, user interface customization, security settings, and other customizations to offer an enhanced experience to app users.",
                            contentRegistration: "Content Registration (institutional pages, blog posts, FAQ, etc.)",
                            descriptionContentRegistration: "E-commerce Management - Content Registration (institutional pages, blog posts, FAQ, etc.): In this activity, the registration and updating of content in various areas of e-commerce are carried out. This includes creating and editing institutional pages, blog posts, frequently asked questions (FAQ) sections, and other relevant content to provide information to customers, improve the user experience, and boost engagement.",
                            scriptManagementHour: "Scripts and Trackers Management (per hour)",
                            descriptionScriptsTrackersManagement: "Deployment - Scripts and Trackers Management (per hour): In this activity, the management of scripts and trackers on the e-commerce website or platform is performed. This involves the implementation and configuration of custom scripts, such as conversion tracking, data analysis, chatbots, content personalization, and other functionalities that require technical knowledge to ensure proper functioning and accurate tracking of user actions.",
                            smsContentCreation: "SMS Content Creation",
                            perMessage: "per message",
                            descriptionSmsContentCreation: "Advertising Copywriting - SMS Content Creation: Here, advertising content is created to be sent via text messages (SMS). This includes writing short and persuasive messages that effectively communicate promotions, exclusive offers, relevant updates, or specific calls to action, aiming to reach and engage the target audience through this direct communication platform.",
                            trafficStrategyAnalysis: "Traffic Strategy Analysis",
                            cro: "CRO",
                            perReport: "per report",
                            descriptionTrafficStrategyAnalysis: "CRO - Traffic Strategy Analysis: In this activity, a detailed analysis of the e-commerce traffic strategy is conducted. This involves evaluating traffic sources, such as organic, paid, social, referral, among others, to identify which channels are generating the most visitors and conversions. Based on this analysis, optimization and resource reallocation strategies are proposed to maximize the return on investment in marketing.",
                            navigationFlowAnalysis: "Navigation Flow Analysis",
                            descriptionNavigationFlowAnalysis: "CRO - Navigation Flow Analysis: Here, an analysis of user navigation flow on the e-commerce website or platform is conducted. This includes studying visitor behavior, the most visited pages, entry and exit points, paths taken, and potential obstacles that may affect the user experience. Based on this analysis, improvements in layout, navigation, and usability are proposed to increase the conversion rate.",
                            conversionRateAnalysis: "Conversion Rate Analysis",
                            descriptionConversionRateAnalysis: "CRO - Conversion Rate Analysis: In this activity, a comprehensive analysis of the e-commerce conversion rate is performed. This involves identifying key metrics, such as conversion rate by channel, by landing page, by audience segment, among others. Based on this analysis, optimization strategies such as A/B testing, content personalization, and improvements in user experience are proposed to increase the overall e-commerce conversion rate.",
                            onsiteNotification: "On-Site Notification",
                            descriptionOnSiteNotification: "E-commerce Management - On-Site Notification: Here, the configuration and implementation of on-site notifications on the e-commerce website are carried out. This includes creating personalized messages, such as pop-ups, notification bars, or alerts, displayed on the website itself to provide important information, encourage specific actions (such as limited-time discounts), or capture leads. These notifications can be segmented based on user behavior or other relevant criteria.",
                            goalsProjectionsReports: "Goals and Projections Reports",
                            descriptionGoalsProjectionsReports: "Reports - Goals and Projections Reports: In this activity, reports are created that track progress toward goals and make projections for the future performance of the e-commerce business. This includes analysis of relevant metrics such as sales, traffic, conversions, ROI, among others, to provide a clear view of current performance and assist in making strategic decisions to achieve established goals.",
                            seoPpcBenchmarkingReport: "Benchmarking Report - SEO and PPC",
                            descriptionSeoPpcBenchmarkingReport: "Reports - Benchmarking Report - SEO and PPC: Here, a benchmarking report is prepared that compares the performance of the e-commerce business in terms of SEO (search engine optimization) and PPC (pay-per-click). This involves analyzing search engine ranking, competitive analysis, evaluating the performance of paid traffic campaigns, among other important factors to identify improvement opportunities and remain competitive in the market.",
                            organicTrafficMktFunnelDesign: "Marketing Funnel Design for Organic Traffic Campaigns",
                            descriptionOrganicTrafficMktFunnelDesign: "Digital Marketing - Marketing Funnel Design for Organic Traffic Campaigns: Here, the strategic design of a marketing funnel for organic traffic campaigns is carried out. This involves creating relevant content to attract visitors, segmenting the target audience based on interests and behaviors, nurturing leads through emails or other channels, and optimizing the funnel to increase the conversion of visitors into customers.",
                            lifecycleMktFunnelDesign: "Marketing Funnel Design for Lifecycle Marketing Campaigns",
                            descriptionLifecycleMktFunnelDesign: "E-commerce Management - Marketing Funnel Design for Lifecycle Marketing Campaigns: In this activity, the strategic design of a marketing funnel for lifecycle marketing campaigns, which follow the customer's lifecycle, is carried out. This involves defining the different stages of the lifecycle, such as acquisition, activation, retention, and loyalty, and creating personalized marketing strategies for each stage to maximize customer value and promote loyalty.",
                            gtmTagsReview: "Review of Tags Implemented in Google Tag Manager for Various Platforms",
                            descriptionGtmTagsReview: "Reports - Review of Tags Implemented in Google Tag Manager for Various Platforms: Here, a comprehensive review of tags implemented in the Google Tag Manager for e-commerce across various platforms is conducted. This includes verifying the correct implementation of analytics tags, remarketing tags, chatbots, conversion optimization, and other tools on different pages and events, ensuring that data is collected accurately and reliably for further analysis.",
                            paidTrafficBillingReport: "Paid Traffic Platforms Billing Report",
                            descriptionPaidTrafficBillingReport: "Paid Traffic - Paid Traffic Platforms Billing Report: Here, a report is prepared that provides a detailed overview of billing and expenses on paid traffic platforms, such as Google Ads, Facebook Ads, among others. This includes information about campaign costs, expenses by channel, performance metrics associated with investments, and other financial analyses relevant for budget monitoring and maximizing return on investment.",
                            performanceReport: "Performance Report (Speed, Usability, Conversion Rate, and Acquisition Channels)",
                            descriptionPerformanceReport: "Reports - Performance Report (Speed, Usability, Conversion Rate, and Acquisition Channels): In this activity, a comprehensive report evaluating the e-commerce performance in terms of loading speed, usability, conversion rate, and traffic acquisition channels is prepared. This involves detailed analyses of these aspects, identifying areas for improvement, optimization opportunities, and providing insights to guide the digital marketing strategy.",
                            lifecycleMktPerformanceReport: "Lifecycle Marketing Performance Report and Analysis",
                            perBrand: "per brand",
                            descriptionLifecycleMktPerformanceReport: "Reports - Lifecycle Marketing Performance Report and Analysis: In this activity, reports analyzing the performance of e-commerce lifecycle marketing campaigns are prepared. This includes analyzing key metrics at each stage of the customer lifecycle, such as conversion rate, retention, average order value, among others, to identify improvement opportunities and optimize marketing strategies targeted throughout the customer lifecycle.",
                            htmlTemplateDev: "HTML Template Development",
                            descriptionHtmlTemplateDevelopment: "Interface Development - HTML Template Development: In this activity, the development of a custom HTML template for e-commerce is carried out. This involves creating a visually appealing and functional design, coding the template in HTML and CSS, implementing interactive features, and ensuring that the template is responsive, adapting to different devices and screen sizes, providing a consistent user experience.",
                            ecommerceBannerDesignDesktop: "E-commerce Banner Design (desktop)",
                            descriptionEcommerceBannerDesign: "Graphic Design - E-commerce Banner Design (desktop and mobile): Here, the design of specific banners for e-commerce is done, considering different formats and sizes suitable for viewing on desktop and mobile devices. Banners can be used for promotions, advertisements, or highlighting specific products.",
                            ecommerceBannerDesignMobile: "E-commerce Banner Design (mobile)",
                            staticAdDesign: "Static Ad Design",
                            descriptionDisplayAdsDesign: "Graphic Design - Display Ad Design: The design of display ads is carried out, which are visual elements used in online advertising campaigns, displayed on third-party websites or applications. These ads can include images, text, and calls to action, aiming to attract user attention and direct them to the company's website or platform.",
                            carouselAdDesign: "Carousel Ad Design",
                            descriptionSocialAdsDesign: "Graphic Design - Social Media Display Ad Design: This activity involves the design of display ads specifically for social media platforms such as Facebook, Instagram, or Twitter. Ads can be designed in different formats and sizes with the goal of generating engagement and conversions through social media platforms.",
                            storiesAdDesign: "Stories Ad Design",
                            descriptionSocialAdsCarouselDesign: "Graphic Design - Social Ads Carousel Ad Design: Carousel ads for social media are designed, where multiple images or cards are displayed in sequence, allowing for storytelling or showcasing multiple products in a single ad.",
                            programmaticMediaCreativeSet: "Programmatic Media Creative Set",
                            for12Formats: "for 12 formats",
                            descriptionGoogleDisplayCreativeSet: "Graphic Design - Google Display Creative Set: A set of visual creatives is created for Google Display campaigns, including different formats and sizes of visual ads, such as static or animated banners, aiming to capture the attention of the target audience and generate clicks and conversions.",
                            googlePrfmMaxCreativeSet: "Google Performance Max Creative Set",
                            for6Formats: "for 6 formats",
                            descriptionGoogleMaxPrfmCreativeSet: "Graphic Design - Google Max Performance Creative Set: In this activity, sets of visual creatives are created for Google Max Performance campaigns. These creatives can include video ads, banners, carousel ads, or other formats suitable for the platform, with the goal of optimizing campaign performance and generating efficient results.",
                            merchandisingInitialPage: "Merchandising (Home Page)",
                            descriptionMerchandising: "E-commerce Management - Merchandising (Home Page): This activity involves the management of e-commerce banners and storefronts, where prominent visual elements such as promotional banners, thematic displays, or product highlights are configured and updated to direct customers' attention to specific offers or products.",
                            simpleBannerRegistration: "Simple Banner Registration",
                            descriptionSimpleBannerRegistration: "E-commerce Management - Simple Banner Registration: This activity involves replacing current banners with new ones without altering the basic page layout structure.",
                            completeBannerRegistration: "Complete Banner Registration",
                            descriptionFullBannerRegistration: "E-commerce Management - Complete Banner Registration: This activity involves replacing current banners with new ones and changing collections and text in sections without altering the basic page layout structure.",
                            emailMktContactsMigration: "Email Marketing Segments and Contacts Migration",
                            descriptionEmailMktMigration: "E-commerce Management - Email Marketing Segments and Contacts Migration: In this activity, the migration and organization of segments and contacts from the e-commerce email marketing list are carried out. This includes reviewing existing segments, creating new segments based on specific criteria, importing and exporting contacts, ensuring list updates and quality for effective implementation of email marketing campaigns.",
                            emailMktFlowsMigration: "Email Marketing Flows Migration",
                            perEmail: "per email (design and rules)",
                            descriptionEmailMktFlowsMigration: "E-commerce Management - Email Marketing Flows Migration: Here, the migration of e-commerce email marketing automation flows to a new platform or tool is carried out. This involves reviewing existing flows, creating custom automated flows, setting triggers and segmentations, ensuring a smooth and efficient transition to the new automation platform.",
                            collOptimizationAutomatic: "Automatic Collection Sorting and Optimization",
                            perCollection: "per collection",
                            descriptionCollOptimizationAutomatic: "E-commerce Management - Automatic Collection Sorting and Optimization: In this activity, automatic sorting and optimization of e-commerce product collections are performed. This includes configuring display rules such as relevance, popularity, price, availability, and other criteria to ensure that products are presented to customers in a suitable and appealing manner, enhancing the shopping experience.",
                            collOptimizationManual: "Manual Collection Sorting and Optimization",
                            descriptionCollOptimizationManual: "E-commerce Management - Manual Collection Sorting and Optimization: Here, manual sorting and optimization of e-commerce product collections are performed. This involves manually reviewing and categorizing products based on specific criteria such as market trends, target audience preferences, profit margin, available stock, to ensure an efficient and attractive presentation of products on the website or platform.",
                            shopifyAppInstallation: "Shopify App Installation",
                            perApp: "per app",
                            descriptionShopifyAppImplementation: "Implementation - Shopify App: In this activity, the implementation of a specific app for the Shopify platform is carried out, adding new functionalities, features, or integrations to the e-commerce. This allows expanding the capabilities of the online store and meeting specific business needs.",
                            vtexAppInstallation: "Vtex Marketplace App Installation",
                            descriptionVtexAppInstallation: "Implementation - Vtex Marketplace: Here, the implementation of the e-commerce on the Vtex Marketplace platform is performed, configuring the necessary integrations, defining catalog, pricing, inventory, shipping settings, and other functionalities related to operation on the marketplace.",
                            wordpressPluginInstallation: "WordPress Plugin Installation",
                            perPlugin: "per plugin",
                            descriptionWordpressImplementation: "Implementation - WordPress: In this activity, the implementation of the e-commerce on the WordPress platform, which is a content management system (CMS), is carried out. This involves configuring the platform, selecting themes, installing plugins, and customizing the environment.",
                            externalPlatformDataAutomationConfig: "External Platform Data Automation Configuration",
                            descriptionDataAutomationConfiguration: "Implementation - External Platform Data Automation Configuration: In this activity, the configuration of data automation through an external platform for e-commerce is performed. This may include the integration and configuration of automated data flows between different systems and platforms, such as CRM, ERP, marketing tools, to ensure efficient synchronization and exchange of relevant information in real-time.",
                            webhooksConfig: "Webhooks Configuration",
                            descriptionWebhooksConfiguration: "Implementation - Webhooks Configuration: Here, the configuration of webhooks in e-commerce is carried out. Webhooks are URLs that allow external applications to be notified about specific events or actions that occur on the website or platform. This involves defining the events to be monitored, configuring target URLs, and integrating with the necessary systems or tools to automate processes and improve operational efficiency.",
                            blogPostContentShort: "Blog Post Content (Short)",
                            upTo700Words: "up to 700 words",
                            descriptionBlogPostContentShort: "Digital Marketing - Blog Post Content: In this activity, short content is created for blog posts on the e-commerce website. Blog content can cover topics related to products, tips, trends, industry news, among others. Creating relevant and informative content for the blog helps attract organic traffic, strengthen brand authority, and provide value to customers.",
                            blogPostContentLong: "Blog Post Content (Long)",
                            upTo1500Words: "up to 1,500 words",
                            descriptionBlogPostContentLong: "Digital Marketing - Blog Post Content: In this activity, long content is created for blog posts on the e-commerce website. Blog content can cover topics related to products, tips, trends, industry news, among others. Creating relevant and informative content for the blog helps attract organic traffic, strengthen brand authority, and provide value to customers.",
                            landingPageCreation: "Landing Page Creation (Marketing Automation Tool)",
                            descriptionLandingPageCreation: "Digital Marketing - Landing Page Creation (Marketing Automation Tool): Here, the creation of landing pages using a marketing automation tool is performed. This includes conceptual design, selection and customization of predefined templates, setting up lead capture forms, and implementing interactive elements such as pop-ups or chatbots to increase the conversion rate and guide the audience towards a specific action.",
                            searchAdsAdWriting: "Search Ads Ad Writing",
                            perCampaign: "per campaign",
                            descriptionSearchAdsCopywriting: "Advertising Copywriting - Copywriting for Search Ads: In this activity, advertising texts are created for Search Ads, which are displayed in search engine results. The texts are optimized to grab the user's attention, convey the product or service's value proposition, and encourage clicks on the ad.",
                            adsCallWriting: "Ad Calls Writing",
                            descriptionAdsCopywriting: "Advertising Copywriting - Ad Calls Writing: Here, advertising texts are developed for ads displayed on partner websites through banners, images, or videos. The texts are crafted to attract the target audience's attention, generate interest, and encourage interaction with the ad.",
                            ecommerceBannerCallWriting: "E-commerce Banner Calls Writing",
                            descriptionEcommerceBannerCopywriting: "Advertising Copywriting - E-commerce Banner Writing: In this activity, advertising texts are created for banners used in e-commerce. The texts are designed to capture users' attention, convey persuasive messages, and encourage interaction with the products, promotions, or calls to action present in the banners.",
                            emailWritingLong: "Email Writing (Long Format)",
                            perEmailOriginal: "per email",
                            descriptionEmailCopywriting: "Advertising Copywriting - Email Copywriting: In this activity, persuasive texts are developed for marketing emails, including promotional campaign emails, newsletters, and customer relationship emails. The texts are crafted to attract attention, generate engagement, and encourage recipients to take action.",
                            emailCallWriting: "Email Call Writing",
                            descriptionCallEmailCopywriting: "Advertising Copywriting - Email Call Writing: In this activity, persuasive calls and CTAs (calls to action) are developed for marketing emails, including promotional campaign emails, newsletters, transactional emails, and customer relationship emails. The texts are crafted to attract attention, generate engagement, and encourage recipients to take action.",
                            customPaidMediaReport: "Custom Paid Media Report",
                            descriptionGoogleAdsCustomReport: "Reports - Custom Paid Media Report (Google Ads): In this activity, custom reports are prepared that provide detailed analysis of the results of paid media campaigns conducted through Google Ads. Reports may include metrics such as clicks, impressions, conversion rate, return on investment (ROI), and other information relevant to evaluate campaign performance and guide strategic adjustments.",
                            testingPlatformTag: "Testing and Experimentation Platform Tag",
                            descriptionGoogleOptimizeSetup: "Setup - Google Optimize: In this activity, the setup of Google Optimize, a website testing and personalization tool, is performed. This includes creating the Optimize account, configuring experiments, defining variants and objectives, and conducting A/B tests and multivariate tests to assess and improve site or platform effectiveness.",
                            usabilityAnalysisPlatformTag: "Usability Analysis Platform Tag",
                            descriptionHotjarSetup: "Setup - Hotjar Heatmaps & Behavior Analytics: Here, the setup of Hotjar, a user behavior analysis tool that provides heatmaps, session recordings, and other features to understand how visitors interact with the website or platform, is performed. This includes creating the Hotjar account, configuring the tracking code, defining tracking settings, and analyzing collected data to gain valuable insights into user behavior and site usability.",
                            adsPlatformsPixelsTagsEventsConfig: "Ads Platforms Pixels, Tags, and Events Configuration",
                            perTag: "per tag",
                            descriptionAdsPlatformsSetup: "Setup - Ads Platforms Pixels, Tags, and Events Configuration: In this activity, the configuration of tracking pixels, tags, and events for TikTok Ads, Pinterest Ads, Twitter Ads, Linkedin Ads, and other platforms on the e-commerce site or platform is carried out. This enables proper tracking and monitoring of user actions, such as video views, ad clicks, and conversions, for analysis and optimization of advertising campaigns.",
                            performanceMaxCampaignSetup: "Performance Max Campaign Setup",
                            descriptionGoogleMaxPrfmCampaignSetup: "Paid Traffic - Google Max Performance Campaign Setup: Here, the setup of Google Max Performance campaigns, an automated campaign type that optimizes bids, targeting, and creatives to achieve the best performance results, is performed. This includes campaign configuration, goal and constraint definition, and ongoing analysis of results for adjustments and optimizations.",
                            socialAdsCampaignSetup: "Social Ads Campaign Setup",
                            descriptionSocialAdsCampaignSetup: "Paid Traffic - Social Ads Campaign Setup: Here, the setup of Social Ads Display ad campaigns, which are displayed on social media platforms such as Facebook, Instagram, TikTok, Pinterest, Twitch, Twitter, or LinkedIn, is performed. This includes configuring targeting criteria, creating visually appealing and persuasive ads, and defining bidding and budget strategies to reach the e-commerce's target audience.",
                            paidTrafficMktFunnelDesign: "Paid Traffic Marketing Funnel Design",
                            descriptionPaidTrafficMktFunnelDesign: "Paid Traffic - Marketing Funnel Design for Paid Traffic Campaigns: In this activity, the strategic design of a marketing funnel for paid traffic campaigns is performed. This involves defining the funnel stages, from awareness to conversion, creating segmentations and specific offers for each stage, and configuring ad campaigns to direct qualified traffic through the funnel, aiming to maximize conversions and return on investment.",
                            abTestTextImage: "A/B Test (text or image)",
                            perExperiment: "per experiment",
                            descriptionAbTestTextImage: "CRO - A/B Test (text or image): A/B tests are conducted in which different versions of text or images are randomly presented to website visitors. This allows for evaluating which version generates better results, such as conversion rates or engagement, assisting in page optimization and increased conversions.",
                            abTestCodeSnippet: "A/B Test (code snippet)",
                            descriptionAbTestCodeSnippet: "CRO - A/B Test (code snippet): In this activity, A/B tests are conducted using code snippets, where different code variants are tested in specific parts of the website. This allows for evaluating which version generates better results in terms of performance, functionality, or user experience.",
                            promotionCreation: "Promotion Creation",
                            descriptionPromotionsCreation: "E-commerce Management - Promotion Creation: In this activity, special promotions or discounts are created for the company's products or services. This may include defining discount rules, validity periods, and communicating the promotion to customers.",
                            collectionCreation: "Collection Creation",
                            descriptionCollectionsCreation: "E-commerce Management - Collection Creation: This activity involves creating collections of products grouped based on a specific theme or criterion. Collections can be used to highlight related products, promote trends, or facilitate customer navigation within the product catalog.",
                            navigationManagement: "Navigation Management (menus and links)",
                            descriptionNavigationManagement: "E-commerce Management - Navigation Management (menus and links): In this activity, the site or platform's navigation menus and links are managed to ensure proper organization and usability. This includes creating, editing, and deleting categories, subcategories, pages, and navigation links, aiming to enhance the user experience in finding desired products.",
                            freightRulesConfig: "Freight Rules Configuration",
                            descriptionShippingRulesConfiguration: "E-commerce Management - Freight Rules Configuration: In this activity, rules related to the calculation and display of shipping options for customers during the purchase process are configured. This includes defining weight ranges, delivery regions, minimum order values, and other criteria for calculating shipping costs.",
                            scriptDevScriptsEditor: "Script Development via Scripts Editor",
                            descriptionCheckoutScriptDevelopment: "E-commerce Management - Checkout Script Development (Scripts Editor): This activity involves the development of custom scripts for the e-commerce checkout process. These scripts can automate tasks, add specific functionalities, or customize the checkout flow to improve the user experience and streamline the purchase process.",
                            productRegistration: "Product Registration",
                            descriptionProductsRegistration: "Inventory - Product Registration: This activity involves registering the company's products in a specific system or platform, entering information such as name, description, code, category, price, and other relevant data.",
                            productImport: "Product Import via Standardized Spreadsheet",
                            perSpreadsheet: "per spreadsheet",
                            descriptionProductsImport: "Inventory - Product Import via Standardized Spreadsheet: In this activity, products are imported into the system using a standardized spreadsheet, streamlining the inventory update process.",
                            catalogFiltersAdd: "Add Filters to the Catalog (via tags or product specifications)",
                            perFilter: "per filter",
                            descriptionCatalogFiltersAddition: "Inventory - Add Filters to the Catalog (via tags): Filters are added to the product catalog using tags or metadata, making it easier for customers to navigate and search within the platform.",
                            variationsConfig: "Configure Variations (via option or metadata)",
                            perVariation: "per variation",
                            descriptionVariationsSwatchesConfiguration: "Inventory - Configure Variations Swatches: Configuration of swatches, which are small samples of colors, sizes, or other product variations, allowing customers to view and select different options.",
                            catalogFiltersAddProductSpec: "Add Filters to the Catalog (via product specifications)",
                            variationsConfigProductSpec: "Configure Variations (via product specifications)",
                            variationsConfigOption: "Configure Variations (via options)",
                            catalogFiltersAddTagsOptionMetacamp: "Add filters to the catalog (via tags, option, or metacamps)",
                            categoryCRO: "CRO",
                            categoryInterfaceDevelopment: "Interface Development",
                            categoryGraphicDesign: "Graphic Design",
                            categoryInterfaceDesign: "Interface Design",
                            categoryEcommerceManagement: "Ecommerce Management",
                            categoryImplementation: "Implementation",
                            categoryInventory: "Inventory",
                            categoryDigitalMarketing: "Digital Marketing",
                            categoryCopywriting: "Copywriting",
                            categoryReports: "Reports",
                            categorySEO: "SEO",
                            categoryMarketingSetup: "Marketing Setup",
                            categoryPaidTraffic: "Paid Traffic",
                            categoryUiUx: "UI & UX",
                            categoryImageEditing: "Image Editing",
                            categoryVideoEditing: "Video Editing",
                            categoryMarketplaceManagement: "Marketplace Management",
                            categoryTranslation: "Translation",
                            availableActivities: "Available Activities",
                            search: "Search",
                            searchActivity: "Search Activities",
                            categories: "Categories",
                            moreUseds: "More Used",
                            all: "All",
                            credits: "Credits",
                            quantity: "Quantity",
                            activitiesAdded: "Added Activities",
                            requestEstimate: "Request Estimate",
                            credits2: "Credits",
                            quantity2: "Quantity",
                            activityAccordion: {
                                responsible: "Responsible",
                                squad: "Squad",
                                none: "None",
                                status: "status",
                                backlog: "Backlog",
                                doneApplied: "Done/Applied",
                                active: "Active",
                                activityDetails: "Activity details",
                            },
                            activityOptions: {
                                code: "Code",
                                writeCouponCode: "Write Coupon Code",
                                discount: "Discount",
                                describeDiscount: "Describe Discount",
                                theme: "Theme",
                                themeEmail: "Email Theme",
                                date: "Date",
                                sendDate: "Send Date",
                                metric: "Metric",
                                metricName: "Metric Name",
                                to: "to",
                                upload: "Upload",
                            }
                        },
                        analysis: {
                            of: "of",
                            sessions: "Sessions",
                            conversionRate: "Conversion Rate",
                            averageTicket: "Average Ticket",
                            revenue: "Revenue",
                            newCustomersRevenue: "New Customers Revenue",
                            oldCustomersRevenue: "Old Customers Revenue",
                            recoveredRevenue: "Recovered Revenue",
                            customerLifetimeValue: "Customer Lifetime Value",
                            nps: "NPS",
                            couponUsage: "Coupon Usage",
                            analysis: "Analysis",
                            metricsTitle: "Metrics",
                            channels: "Channels",
                            metrics: "Metrics",
                            utm: "UTM",
                            completeUtm: "Complete UTM",
                            copy: "Copy",
                            prioritization: "Prioritization",
                            trust: "Trust",
                            impact: "Impact",
                            facility: "Facility",
                        },
                        details: {
                            startBroadcast: "Start of broadcast",
                            endBroadcast: "End of broadcast",
                            futureDate: "Date when the action must be on the air.",
                            endDate: "Closing date of the action or mark as perpetual action when it's a permanent action.",
                            detailsOf: "Details of",
                            name: "Name",
                            description: "Description",
                            deadlineChecklist: "Checklist submission deadline",
                            week: "Week",
                            weeks: "Weeks",
                            language: "Language",
                            portuguese: "Portuguese",
                            english: "English",
                            spanish: "Spanish",
                            perpetualAction: "Perpetual action",
                        },
                        revision: {
                            active: "Active",
                            backlog: "Backlog",
                            inProgress: "In Progress",
                            done: "Done",
                            toDo: "To Do",
                            planning: "Planning",
                            cancelled: "Cancelled",
                            typeCampaign: "Campaign",
                            typeImplementation: "Implementation",
                            typeImprovement: "Improvement",
                            detailsOf: "Details of",
                            revision: "Revision",
                            details: "Details",
                            name: "Name:",
                            description: "Description:",
                            type: "Type:",
                            category: "Category:",
                            quadrant: "Quadrant:",
                            executionTime: "Execution Time:",
                            start: "Start:",
                            end: "End:",
                            perpetual: "Perpetual",
                            channel: "Channel:",
                            metric: "Metric:",
                            notSpecified: "Not specified",
                            perpetuates: "Perpetual",
                            weeks: "weeks",
                            activities: "Activities",
                            creditsQuantity: "Credits / Quantity:",
                            resume: "Summary",
                            credits: "Credits:",
                            score: "Score:",
                            campaign: "Campaign:",
                            status: "Status",
                            includeChecklist: "Include checklist",
                            jira: "Jira:",
                            jira2: "Jira",
                            integration: "Integration",
                        },
                        type: {
                            whatTypeAction: "What type of Action?",
                            campaign: "Campaign",
                            requestCampaign: "Request a sales campaign or choose from various ready-made models of broadcast campaigns and digital marketing automation based on our recommendation system.",
                            implementation: "Implementation",
                            requestReports: "Request the creation of reports, app installations, system integrations, tag insertions, event triggers, tool configurations, and process automation.",
                            improvement: "Improvement",
                            requestImprovement: "Request improvements in design, speed optimization, or on-demand features that require the involvement of a solution architect and a development team.",
                        },
                    },
                    utils: {
                        categorySelect: {
                            activation: "Activation",
                            discovery: "Discovery",
                            consideration: "Consideration",
                            prospection: "Prospection",
                            acquisition: "Acquisition",
                            content: "Content",
                            tracking: "Tracking",
                            benefit: "Benefit",
                            engagement: "Engagement",
                            nextPurchase: "Next Purchase",
                            context: "Context",
                            recovery: "Recovery",
                            conversion: "Conversion",
                            purchaseBarriers: "Purchase Barriers",
                            offers: "Offers",
                            authority: "Authority",
                            retention: "Retention",
                            experienceAnalysis: "Experience Analysis",
                            transmission: "Transmission",
                            segments: "Segments",
                            category: "Category"
                        },
                        editCampaignModal: {
                            campaignNotFound: "Campaign not found.",
                            internalError: "Internal server error.",
                            campaignUpdated: "Campaign updated successfully!",
                            campaignDeleted: "Campaign deleted successfully!",
                            editCampaign: "Edit campaign",
                            apply: "Apply",
                            delete: "Delete",
                            cancel: "Cancel",
                            deleteCampaign: "Delete campaign",
                            confirmDelete: "Are you sure you want to delete the campaign?",
                            remove: "Remove",
                            cancel2: "Cancel",
                        },
                        fileSelect: {
                            fileTooBig: "File is too large, please try a smaller file of 20MB or less."
                        },
                        freeSoloCreateOption: {
                            errorCreate: "Error creating the campaign.",
                            internalError: "Internal server error.",
                            campaignCreated: "Campaign created successfully!",
                            campaign: "Campaign",
                        }
                    }
                },
                strategy: {
                    internalError: "Internal server error.",
                    updatedSuccess: "Actions updated successfully!",
                    activation: "Activation",
                    acquisition: "Acquisition",
                    engagement: "Engagement",
                    conversion: "Conversion",
                    retention: "Retention",
                    actionsQuadrant: "Strategy",
                    updateActions: "Update Actions",
                    actionCard: {
                        active: "Active",
                        backlog: "Backlog",
                        inProgress: "In Progress",
                        done: "Done",
                        toDo: "To Do",
                        planning: "Planning",
                        cancelled: "Cancelled",
                        credits: "credits",
                    },
                    actionCardArea: {
                        discovery: "Discovery",
                        content: "Content",
                        nextPurchase: "Next Purchase",
                        buyingBarriers: "Buying Barriers",
                        expAnalysis: "Experience Analysis",
                        consideration: "Consideration",
                        tracking: "Tracking",
                        context: "Context",
                        offers: "Offers",
                        transmission: "Transmission",
                        prospecting: "Prospecting",
                        benefit: "Benefit",
                        recovery: "Recovery",
                        authority: "Authority",
                        segments: "Segments",
                        newAction: "New Action",
                        dontCreate: "You haven't created an activity for this quadrant yet",
                        createNow: "Create Now",
                    },
                    actionCardAreaMobile: {
                        discovery: "Discovery",
                        content: "Content",
                        nextPurchase: "Next Purchase",
                        buyingBarriers: "Buying Barriers",
                        expAnalysis: "Experience Analysis",
                        consideration: "Consideration",
                        tracking: "Tracking",
                        context: "Context",
                        offers: "Offers",
                        transmission: "Transmission",
                        prospecting: "Prospecting",
                        benefit: "Benefit",
                        recovery: "Recovery",
                        authority: "Authority",
                        segments: "Segments",
                        newAction: "New Action",
                        dontCreate: "You haven't created an activity for this quadrant yet",
                        createNow: "Create Now",
                    }
                },
                tableActivity: {
                    table: {
                        description: "Description",
                        noneSelected: "No activity selected",
                        agencyNotFound: "Agency not found!",
                        updateSuccess: "Activities updated successfully!",
                        internalError: "Internal server error.",
                        activitiesTable: "Activities Table",
                        activeTable: "Active Table",
                        activateTable: "Activate Table",
                        addActivities: "Add Activities",
                        version: "Version",
                        categoryLabel: "Category",
                        all: "All",
                        searchActivity: "Search activity",
                        category: "Category",
                        activities: "Activities",
                        credits: "Credits",
                        unitMensurement: "Unit of Measurement",
                        linesPerPage: "Lines per page:",
                    },
                    tables: {
                        updateSuccess: "Activities table updated successfully!",
                        errorUpdate: "Error while updating activities table",
                        internalError: "Internal server error.",
                        activitiesTable: "Activities Table",
                        upload: "Upload",
                        version: "Version",
                        activeTable: "Active Table",
                    }
                },
                utils: {
                    filter: {
                        filter: "Filter",
                        filterBy: "Filter by:",
                        apply: "Apply",
                        resetFilters: "Reset filters",

                        campaign: {
                            campaign: "Campaign:",
                            selectCampaign: "Select campaign",
                        },
                        category: {
                            category: "Category:",
                        },
                        end: {
                            end: "End:",
                        },
                        name: {
                            name: "Name:",
                        },
                        responsible: {
                            responsible: "Responsible:",
                            selectResponsible: "Select responsible",
                        },
                        score: {
                            score: "Score:",
                        },
                        start: {
                            start: "Start:",
                        },
                        status: {
                            status: "Status:",
                            unmarkAll: "Unmark all",
                            markAll: "Mark all",
                            active: "Active",
                            backlog: "Backlog",
                            inProgress: "In Progress",
                            done: "Done",
                            toDo: "To Do",
                            planning: "Planning",
                            cancelled: "Cancelled",
                        }
                    },
                    headerButtons: {
                        creditBalanceModal: {
                            refunded: "Refunded",
                            debited: "Debited",
                            transaction: "Transaction",
                            transactions: "Transactions",
                            freeBalance: "Credits:",
                            balance: "Balance",
                            cycleBalance: "Cycle Balance:",
                            avaiable: "Available:",
                            planned: "Planned",
                            provisioned: "Provisioned",
                            debited: "Debited",
                            actionCard: {
                                credits: "credits",
                            },
                            editCreditsModal: {
                                contractNotFound: "Contract not found!",
                                registerError: "Unable to execute the request!",
                                registerSuccess: "Charge registered successfully!",
                                internalError: "Internal server error!",
                                syncSuccess: "Actions synchronized successfully!",
                                edit: "Edit",
                                editCredits: "Edit Credits",
                                actuallyCredits: "Current Credits:",
                                sync: "Sync",
                                credits: "Credits",
                                motive: "Motive",
                                add: "Add",
                                remove: "Remove",
                                cancel: "Cancel",
                            }
                        },
                        customSearchInput: {
                            research: "Search...",
                        },
                        exportButton: {
                            export: "Export",
                        },
                        fileSelectArea: {
                            addFiles: "Add files",
                        },
                        returnButton: {
                            return: "Back",
                        },
                        showMoreLessButton: {
                            seeLess: "See less",
                            seeMore: "See more",
                        },
                        trimesterUpdateModal: {
                            updateTrimester: "Update Actions Trimester",
                            transferActions: 'Click "Continue" to transfer the actions to the next quarter, or "Remove" to remove the actions from the current quarter.',
                            remove: "Remove",
                            selectActions: "Select planning actions to transfer to the next trimester as well",
                            continue: "Continue",
                            cancel: "Cancel",
                        }
                    }
                }
            },
            start: {
                checklist: {
                    checklistProgress: "Checklist Progress",
                    generalProgress: "GENERAL PROGRESS",
                    checklistStatus: "Checklist Status",
                    create: {
                        invalidData: "Invalid data.",
                        customerNotFound: "Customer not found.",
                        updateError: "An error occurred while updating the customer.",
                        updateSuccess: "Customer updated successfully.",
                        internalError: "Internal server error.",
                        saveSuccess: "Checklist created successfully!",
                        checklist: "Checklist",
                        save: "Save",
                        subTitle: "Sub-title",
                        title: "Title",
                        helperText: 'Helper text',
                        placeHolder: 'Placeholder',
                        type: 'Type',
                        text: 'Text',
                        textArea: 'Textarea',
                        count: 'Numeric Counter',
                        money: 'Monetary Value',
                        uniqueList: 'Unique Dropdown List',
                        multipleList: 'Multiple Dropdown List',
                        url: 'URL',
                        date: 'Date',
                        email: 'Email',
                        radio: 'Radio',
                        checkbox: 'Checkbox',
                        attachment: 'Attachment',
                        options: 'Options',
                        mandatory: 'Mandatory',
                        selectItem: 'Select an item on the side!',
                    },
                    edit: {
                        errorIcons: "Error fetching icons:",
                        registerError: "Checklist was not registered successfully!",
                        registerSuccess: "Checklist registered successfully!",
                        internalError: "Internal server error.",
                        updateError: "An error occurred while updating the Checklist.",
                        updateSuccess: "Task updated successfully!",
                        add: "Add",
                        subTitle: "Sub-title",
                        title: "Title",
                        helperText: 'Helper text',
                        placeHolder: 'Placeholder',
                        type: 'Type',
                        text: 'Text',
                        textArea: 'Textarea',
                        count: 'Numeric Counter',
                        money: 'Monetary Value',
                        uniqueList: 'Unique Dropdown List',
                        multipleList: 'Multiple Dropdown List',
                        url: 'URL',
                        date: 'Date',
                        email: 'Email',
                        radio: 'Radio',
                        checkbox: 'Checkbox',
                        attachment: 'Attachment',
                        condition: 'Condition',
                        noCondition: 'No Condition',
                        valueCondition: 'Value Condition',
                        mandatory: 'Mandatory',
                        options: 'Options',
                        addOption: 'Add Option',
                        addField: 'Add Field',
                        add2: 'Add',
                        save: 'Save',
                        cancel: 'Cancel',
                        delete: 'Delete',
                        selectItem: 'Select an item on the side!',
                        searchIcon: 'Search icon'
                    },
                    inicial: {
                        fieldRequired: "This field is required.",
                        fillFields: "Please fill in all required fields.",
                        customerNotFound: "Customer not found!",
                        updateError: "An error occurred while updating the customer.",
                        updateSuccess: "Customer updated successfully!",
                        internalError: "Internal server error.",
                        archiveHere: "Drop files here",
                        dragOrClick: "Drag and drop your files here or click to select files",
                        checklist: "Checklist",
                        save: "Save",
                        selectItem: "Select an item on the side!"
                    },
                    view: {
                        customerNotFound: "Customer not found!",
                        updateError: "An error occurred while updating the customer.",
                        updateSuccess: "Customer updated successfully!",
                        internalError: "Internal server error.",
                        checklist: "Checklist",
                        save: "Save",
                        selectItem: "Select an item on the side!"
                    },
                    viewAll: {
                        checklists: "Checklists",
                        edit: "Edit",
                        search: "Search",
                        store: "Store",
                        all: "All",
                        finisheds: "Finished",
                        inAnalysis: "In Analysis",
                        waiting: "Waiting",
                        error: "Error",
                        withoutChecklist: "Without Checklist",
                        name: "Name",
                        waiting2: "Waiting",
                        inAnalysis2: "In Analysis",
                        error2: "Error",
                        finisheds2: "Finished",
                        total: "Total",
                        viewChecklist: "View Checklist",
                        editChecklist: "Edit Checklist",
                    }
                },
                createChecklist: {
                    addField: "Add Field",
                    label: "Label",
                    typeField: "Field Type",
                },
                form: {
                    question: "Question",
                    type: "Type:",
                    condition: "Condition",
                    text: "Text",
                    updateSuccess: "Form updated successfully!",
                    internalError: "Internal server error.",
                    successUpdate: "Form updated successfully!",
                    refresh: "Refresh",
                    configForm: {
                        name: "Name",
                        descriptionHere: "Enter description here",
                        typeField: "Field Type",
                        uniqueList: "Unique Selection List",
                        multipleList: "Multiple Selection List",
                        uniqueOption: "Unique Option",
                        multipleOption: "Multiple Option",
                        count: "Counter",
                        options: "Options",
                        option: "Option",
                        descriptionOption: "Option Description",
                        delete: "Delete",
                        addOption: "Add Option",
                        cancel: "Cancel",
                        save: "Save",
                    }
                },
                project: {
                    projectNotExist: "Project does not exist!",
                    deleteError: "Error deleting!",
                    deleteSuccess: "Project deleted successfully!",
                    internalError: "Internal server error.",
                    projects: "Projects",
                    store: "Store",
                    all: "All",
                    status: "Status",
                    all2: "All",
                    incomplete: "Incomplete",
                    development: "Development",
                    waitingBudget: "Waiting for Budget",
                    waitingDevelopment: "Waiting for Development",
                    waitingCustomer: "Waiting for Customer",
                    done: "Done",
                    createIn: "Created on",
                    updatedIn: "Updated on",
                    details: "DETAILS",
                    remove: "Remove",
                    confirmRemove: "Are you sure you want to delete?",
                    cancel: "Cancel",
                },
                task: {
                    updateError: "An error occurred while updating the task.",
                    updateSuccess: "Task updated successfully.",
                    internalError: "Internal server error.",
                    taskName: "Task Name",
                    add: "Add",
                },
                viewProject: {
                    selectBreakDown: "Select the breakdown.",
                    projectNotFound: "Project not found!",
                    updateSuccess: "Project updated successfully!",
                    internalError: "Internal server error.",
                    updateError: "An error occurred while updating the project.",
                    updateSuccess2: "Project updated successfully.",
                    proposalSent: "Proposal sent successfully.",
                    tasksSet: "Tasks set successfully!",
                    list: "List",
                    storeInformation: "Store Information",
                    name: "Name",
                    email: "Email",
                    phone: "Phone",
                    proposals: "Proposals",
                    date: "Date",
                    apresentation: "Presentation",
                    detailing: "Detailing",
                    apresentation2: "Presentation",
                    detailing2: "Detailing",
                    noProposals: "No proposals at the moment.",
                    uploadDetailing: "UPLOAD Detailing",
                    uploadApresentation: "UPLOAD Presentation",
                    noFileSelect: "No file selected.",
                    noFileSelect2: "No file selected.",
                    send: "Send",
                }
            },
            support: {
                hourTracking: {
                    credits: "Credits",
                    timeTracking: "Time Tracking",
                    filter: "Filter",
                    contractedHours: "Contracted Hours:",
                    usedHours: "Used Hours:",
                    remainingHours: "Remaining Hours:",
                    hoursTable: {
                        taskCode: "Task Code",
                        quantityHours: "Quantity of Hours",
                        title: "Title",
                        timeRecord: "Time Record",
                        date: "Date",
                        comment: "Comment",
                        hours: "Hours",
                        deleteAction: "Delete Action",
                        confirmDelete: "Are you sure you want to delete the action?",
                        delete: "Delete",
                        cancel: "Cancel",
                    }
                },
                performance: {
                    performance: "Performance",
                    strategy: "Strategy",
                    desktop: "Desktop",
                    mobile: "Mobile",
                    details: "Details",
                    graphics: "Graphics",
                    statusLabel: "Status",
                    graphs: {
                        firstContent: "First Contentful Paint",
                        firstDelay: "First Input Delay",
                        greaterContent: "Largest Contentful Paint",
                        changeLayout: "Cumulative Layout Shift",
                        interactivityRendering: "Interactivity Until Next Render",
                        firstByte: "Time to First Byte",
                        noneDataFound: "No data found",
                        textAvise: "*Some scales have been adjusted to generate a more user-friendly visualization of the Core Web Vitals variation",
                        chargingExperience: "Loading Experience",
                        noDataFound: "No data found...",
                        notEnoughData: "Not enough data to generate the graph",
                    },
                    status: {
                        statusNotAvailable: "Status not available...",
                    },
                    webVitals: {
                        fix: "Fix",
                        slow: "Slow",
                        average: "Average",
                        fast: "Fast",
                        notRated: "Not Rated",
                        valuesCanVariate: "Values are estimated and can vary.",
                        acessibility: "These checks highlight opportunities to improve your web app's accessibility. Only a subset of accessibility issues can be automatically detected, so manual testing is also encouraged.",
                        bestPractices: "These checks highlight recommended best practices for web app development. Ignoring these best practices can lead to performance, accessibility, and reliability issues.",
                        seo: "These checks ensure that your page follows basic search engine optimization advice. There are many additional factors that Lighthouse does not score here that can affect your search ranking, including performance on Core Web Vitals. Learn more about Google's search fundamentals.",
                        searchInfos: "Seeking information. This may take a moment...",
                        usersExperiencing: "Discover what your users are experiencing",
                        assessmentPoints: "Assessment of Core Web Vitals:",
                        firstContent: "First Contentful Paint (FCP):",
                        firstDelay: "First Input Delay (FID):",
                        greaterContent: "Largest Contentful Paint (LCP):",
                        changeLayout: "Cumulative Layout Shift (CLS):",
                        interactivityRendering: "Interactivity Until Next Render (INP):",
                        firstByte: "Time to First Byte (TTFB):",
                        generalObservations: "General Observations",
                        diagnosisOf: "Diagnosis of",
                    }
                },
                problemsIncidents: {
                    problemsIncidents: "Problems and Incidents",
                    filter: "Filter",
                    research: "Research",
                    status: "Status",
                    all: "All",
                    backlog: "Backlog",
                    toDo: "To-Do",
                    inProgress: "In Progress",
                    qa: "Q/A",
                    clientApproval: "Client Approval",
                    done: "Done",
                    modalForm: {
                        invalidData: "Invalid data!",
                        problemNotFound: "Problem not found!",
                        updateError: "Error editing problem!",
                        internalError: "Internal server error.",
                        sendError: "Error sending files!",
                        updateFileError: "Error updating files!",
                        sendSuccess: "Files sent successfully!",
                        updateSuccess: "Problem edited successfully!",
                        deleteError: "Error deleting files!",
                        deleteSuccess: "File removed successfully!",
                        errorProblem: "Error while registering the problem",
                        fieldRequired: "Field required",
                        fillFields: "Fill in all required fields.",
                        undefinedRootCause: "Undefined root cause",
                        installApp: "App installation",
                        themeUpdate: "Theme update",
                        systemIntegration: "System integration",
                        externalSupplier: "External supplier",
                        platformInstability: "Platform instability",
                        initialPage: "Initial page",
                        productPage: "Product page",
                        collectionPage: "Collection page",
                        cart: "Cart",
                        loginRegister: "Login or registration",
                        form: "Form",
                        promotion: "Promotion",
                        appFunctionality: "App functionality",
                        erpIntegration: "ERP integration",
                        shippingCalculate: "Shipping calculation",
                        analyticsError: "Analytics error",
                        marketingTool: "Marketing tool",
                        fbCatalogIntegration: "Facebook catalog integration",
                        googleCatalogIntegration: "Google catalog integration",
                        desktopWindows: "Desktop Windows",
                        desktopMac: "Desktop Mac",
                        desktopLinux: "Desktop Linux",
                        tabletAndroid: "Tablet Android",
                        tabletIos: "Tablet iOS",
                        mobileAndroid: "Mobile Android",
                        mobileIos: "Mobile iOS",
                        yes: "Yes",
                        no: "No",
                        emergency: "Emergency",
                        critical: "Critical",
                        high: "High",
                        normal: "Normal",
                        low: "Low",
                        noPriority: "No priority",
                        other: "Other...",
                        rootCause: "Root Cause",
                        problemLocation: "Problem Location",
                        dispositive: "Device",
                        navigator: "Browser",
                        reproducedError: "Have you reproduced the error?",
                        priority: "Priority",
                        actionAndSystem: "What was the action or which system caused the problem?",
                        whichEquipment: "Which equipment was the problem identified on",
                        whichNavigator: "Which browser was the problem identified on",
                        emergency2: "Emergency",
                        critical2: "Critical",
                        high2: "High",
                        normal2: "Normal",
                        low2: "Low",
                        noPriority2: "No priority",
                        emergencyDescription: "Sales are impacted with the solution of this item",
                        criticalDescription: "Sales may be impacted with the solution of this item",
                        highDescription: "Sales may be slightly restricted until the solution of this item",
                        normalDescription: "Sales may improve with the resolution of this item",
                        lowDescription: "Sales will not be impacted with the solution of this item",
                        noneDescription: "The criticality of this item cannot be defined",
                        problemsIncidents: "Problems and Incidents",
                        problemDetails: "Problem Details",
                        title: "Title",
                        description: "Description",
                        helpToolAndClient: "Describe which tool and client encountered the problem. Example: Arezzo Checkout freezing.",
                        expectedResult: "Expected Result",
                        helpActionAndSystem: "What was the action or which system caused the problem. Specify with the minimum of details the expected result, otherwise the request will be rejected. 'Solve the problem' will not be accepted.",
                        priorities: "Priorities",
                        cancel: "Cancel",
                        save: "Save",
                        new: "NEW",
                        fileSelect: {
                            largeFile: "File too large, please try another file smaller than 20MB",
                        },
                        modalSummary: {
                            resume: "Summary",
                            rootCause: "Root Cause:",
                            dispositive: "Device:",
                            reproducedError: "Reproduced the Error:",
                            problemLocation: "Problem Location:",
                            navigator: "Browser:",
                            store: "Store:",
                            priority: "Priority:",
                            cancel: "Cancel",
                            end: "End",
                        }
                    },
                    table: {
                        deleteSuccess: "Problem deleted successfully!",
                        problemNotFound: "Problem not found!",
                        deleteError: "Error deleting problem!",
                        internalError: "Internal server error.",
                        taskCode: "Task Code",
                        title: "Title",
                        date: "Date",
                        status: "Status",
                        active: "Active",
                        backlog: "Backlog",
                        inProgress: "In Progress",
                        done: "Done",
                        toDo: "To Do",
                        planning: "Planning",
                        canceled: "Canceled",
                        customerApproval: "Customer Approval",
                        code: "Code",
                        deleteProblem: "Delete Problem",
                        confirmDelete: "Are you sure you want to delete the problem?",
                        delete: "Delete",
                        cancel: "Cancel",
                        problemOptions: {
                            history: "History",
                            resume: "Summary",
                            edit: "Edit",
                            jira: "Jira",
                            remove: "Remove",
                        }
                    }
                }
            },
            unauthorized: {
                unauthorized: "You do not have permission to access this page.",
            },
            updatePassRecovery: {
                informPassword: "Enter the password",
                minChars: "The password must have at least 6 characters!",
                invalidKey: "Invalid key, request a new link to update the password!",
                updatePassword: "Update Password",
                password: "Password",
                loading: "Loading...",
                newPassword: "Enter your new password",
                clickHere: "Click here",
                toAccess: " to access"
            },
            updatePerfil: {
                informName: "Enter the name!",
                errorUpdate: "There is an error in updating the user.",
                successUpdate: "Profile updated successfully!",
                editPerfil: "Edit Profile",
                perfil: "Profile",
                chatStyle: "Chat Style",
                new: "New",
                legacy: "Legacy",
                save: "Save",
                name: "Name",
                completName: "Enter the full name",
                startWorkingHours: "Start of the working day",
                endWorkingHours: "End of the working day",
            },
            updatePerfilPass: {
                informPassword: "Enter the password!",
                minChars: "The password must have at least 6 characters!",
                editPassword: "Edit Password",
                perfil: "Profile",
                newPassword: "New password",
                enterNewPassword: "Enter the new password!",
            },
            updatePerfilPhoto: {
                photoRequired: "You need to select an image!",
                editPhoto: "Edit photo",
                perfil: "Profile",
                photo: "Photo",
                perfilPhoto: "Profile Photo",
                update: "Update",
            },
            updateUserPass: {
                informPassword: "Enter the password!",
                minChars: "The password must have at least 6 characters!",
                editPassword: "Edit Password",
                loading: "Loading...",
                list: "List",
                view: "View",
                actions: "Actions",
                list2: "List",
                view2: "View",
                newPassword: "New password",
                enterNewPassword: "Enter the new password!",
            },
            user: {
                userNotExist: "User does not exist!",
                deleteError: "Error deleting!",
                internalError: "Internal server error.",
                deleteSuccess: "User deleted successfully!",
                admin: "Administrator",
                customer: "Customer",
                agency: "Agency",
                users: "Users",
                register: "Register",
                search: "Search",
                function: "Function",
                all: "All",
                id: "ID",
                name: "Name",
                email: "Email",
                function2: "Function",
                edit: "Edit",
                delete: "Delete",
                permissions: "Permissions",
                deleteUser: "Delete User",
                confirmDelete: "Are you sure you want to delete the user?",
                cancel: "Cancel",
                specific: {
                    userNotExist: "User does not exist!",
                    deleteError: "Error deleting!",
                    internalError: "Internal server error.",
                    deleteSuccess: "User deleted successfully!",
                    users: "Users",
                    register: "Register",
                    id: "ID",
                    name: "Name",
                    email: "Email",
                    edit: "Edit",
                    delete: "Delete",
                    permissions: "Permissions",
                    deleteUser: "Delete User",
                    confirmDelete: "Are you sure you want to delete the user?",
                    cancel: "Cancel"
                }
            },
            viewUser: {
                deleteSuccess: "User deleted successfully!",
                viewUser: "View User",
                list: "List",
                edit: "Edit",
                editPassword: "Edit Password",
                actions: "Actions",
                list2: "List",
                edit2: "Edit",
                editPassword2: "Edit Password",
                delete: "Delete",
                detailsUser: {
                    id: "ID",
                    name: "Name",
                    email: "Email",
                    function: "Function",
                    dateRegister: "Date Registered",
                    editIn: "Edited on"
                }
            },
            components: {
                uploadAvatar: {
                    upload: 'Upload photo'
                },
                actionForm: {
                    quickActivity: "Quick Activity",
                    activityDetails: "Activity Details",
                    quickImprovement: "Quick Improvement",
                    improvementDetails: "Improvement Details",
                    type: "Type",
                    implementation: "Implementation",
                    improvement: "Improvement",
                    save: "Save",
                },
                chat: {
                    chatHeader: {
                        notificationsEnabled: "Notifications enabled",
                        notificationsDisabled: "Notifications disabled",
                        openAnotherTab: "Open in another tab",
                        unreadThreads: "Unread Threads",
                        openAnotherTab: "Open in another tab",
                        unreadMessagesError: "Error marking messages as unread.",
                        unreadMessagesSuccess: "Messages set as unread successfully.",
                        chat: "Chat",
                        others: "Others",
                        markAllRead: "Mark all as read",
                    },
                    chatHeaderDrawer: {
                        online: "Online",
                        busy: "Busy",
                        attachments: "Attachments",
                        seeAll: "See All",
                        marks: "Saved Messages",
                        seeAll2: "See All",
                        attachments2: "Attachments",
                        savedMessages2: "Saved Messages",
                        messages: "Messages",
                        search: "Search",
                        searchMessages: "Search messages",
                    },
                    chatConversation: {
                        save: "Save",
                        cancel: "Cancel",
                        reply: "reply",
                        replies: "replies",
                        thread: "Thread",
                        edited: "Edited",
                        "messageDeleted": "Message deleted",
                        "deleteMessage": "Delete message",
                        "confirmDelete": "Are you sure you want to delete the message?",
                        "erase": "Erase",
                        messageDeletedSuccess: "Message deleted successfully!",
                        messageDeletedError: "Error deleting message!",
                    },
                    chatInput: {
                        send: "Send",
                        placeholder: "Type a message...",
                        addActions: "Add actions",
                        createImprovement: "Create improvement",
                        createActivity: "Create activity",
                        reportProblem: "Report problem",
                        attachments: "Attachments",
                    },
                    chatSideBar: {
                        channels: "Channels",
                    },
                    chatSideBarAdm: {
                        operation: "Operation",
                        performance: "Performance",
                        implantation: "Implementation",
                        support: "Support",
                        administrative: "Administrative",
                        research: "Research",
                        settings: "Settings",
                    }
                },
                chipProject: {
                    quiz: "Quiz",
                    waitingDevelopment: "Waiting Development",
                    waitingBudget: "Waiting Budget",
                    development: "Development",
                    waitingCustomer: "Proposal Sent",
                    done: "Done",
                    unknown: "Unknown",
                },
                date: {
                    start: "Start",
                    end: "End"
                },
                header: {
                    perfil: "Profile",
                },
                kanbanBoard: {
                    toDo: "To Do",
                    inProgress: "In Progress",
                    done: "Done",
                    deliveryForecast: "Delivery Forecast",
                },
                platinumlog: {
                    filterBar: {
                        awaitingWMS: "Awaiting WMS",
                        awaitingInvoice: "Awaiting Invoice",
                        awaitingPicking: "Awaiting Picking",
                        awaitingCheckout: "Awaiting Checkout",
                        readyForCollection: "Ready for Collection",
                        dispatched: "Dispatched",
                        inTransit: "In Transit",
                        outForDelivery: "Out for Delivery",
                        delivered: "Delivered",
                        canceled: "Canceled",
                        lateInIntegration: "Late in Integration",
                        lateInDelivery: "Late in Delivery",
                        deliveryToday: "Delivery Today",
                        withOccurrence: "With Occurrence",
                        search: "Search",
                        status: "Status",
                        status2: "Status",
                    },
                    internalEvents: {
                        ordersReceived: "Orders Received",
                        created: "Created",
                        awaitingWMS: "Awaiting WMS",
                        awaitingPicking: "Awaiting Picking",
                        integratedWMS: "Integrated WMS",
                        awaitingNote: "Awaiting Note",
                        digitalPickingDone: "Digital Picking Done",
                        canceled: "Canceled",
                        physicalPickingDone: "Physical Picking Done",
                        noteReceived: "Note Received",
                        picking: "Picking",
                        receivedTrackerViaCustomer: "Received Tracker (via Customer)",
                        awaitingCheckout: "Awaiting Checkout",
                        checkout: "Checkout",
                        readyForCollection: "Ready for Collection",
                        dispatched: "Dispatched",
                        pickedUpByCarrier: "Picked Up by Carrier",
                        inTransit: "In Transit",
                        outForDelivery: "Out for Delivery",
                        occurrenceWithOrder: "Occurrence with Order",
                        delivered: "Delivered",
                        deliveryFailure: "Delivery Failure",
                        orderRejected: "Order Rejected",
                        orderCanceled: "Order Canceled",
                        orderReturnedToOrigin: "Order Returned to Origin",
                        orderLost: "Order Lost",
                        cargoTheft: "Cargo Theft",
                        redelivery: "Redelivery",
                        carrierRecords: "Carrier Records",
                        incorrectAddress: "Incorrect Address",
                        recipientAbsent: "Recipient Absent",
                        objectAwaitingWithdrawal: "Object Awaiting Withdrawal",
                        unclaimedObject: "Unclaimed Object",
                        integrationDelay: "Integration Delay",
                        deliveryDelay: "Delivery Delay",
                        unknowStatus: "Unknown Status",
                    },
                    mediaTime: {
                        invoicing: "Invoicing",
                        timeCreateToSendNf: "Time from the creation of the order in the e-commerce until the sending of the invoice.",
                        expedition: "Expedition",
                        timeSendToDispatching: "Time from sending the invoice until the end of checkout.",
                        delivery: "Delivery",
                        timeCollectToDelivered: "Time from the end of checkout until delivery to the customer by the carrier.",
                        total: "Total",
                        totalAverage: "Total average from the creation of the order in the e-commerce until delivery to the customer.",
                    },
                    products: {
                        sku: "SKU",
                        merchandise: "MERCHANDISE",
                        amount: "QUANTITY"
                    },
                    shippingEvents: {
                        description: "DESCRIPTION",
                        start: "START",
                        end: "END",
                    },
                    statusErrorView: {
                        cancelled: "CANCELLED",
                        withOccurrence: "WITH OCCURRENCE",
                        delayInIntegration: "DELAY IN INTEGRATION",
                        deliveryDelay: "DELIVERY DELAY"
                    },
                    tableOrders: {
                        order: "Order",
                        date: "Date",
                        prediction: "Prediction",
                        customer: 'Customer',
                        shipping: 'Carrier',
                        tracking: 'Tracking',
                        invoice: 'Invoice',
                        status: "Status",
                        wmsIntegration: "WMS Integration:",
                        timeIntegration: "Integration Time:",
                        displaying: "Displaying",
                        of: "of",
                        results: "results"
                    }
                },
                sideBar: {
                    store: "Store",
                    dashboard: "Dashboard",
                    messages: "Messages",

                    admin: {
                        settings: "Settings",
                        usersAdmin: "Users",
                        stores: "Stores",
                        agencies: "Agencies",
                        agency: "Agency",
                        contracts: "Contracts",
                        tableActivitiesAdmin: "Activities Table",
                        costs: "Costs",
                        usersCustomer: "Users",
                        tableActivitiesCustomer: "Activities Table",
                        usersAgency: "Users",
                        tableActivitiesAgency: "Activities Table",
                        perfil: "Profile",
                        logout: "Logout",
                        projects: "Projects",
                    },
                    aiContent: {
                        creation: "Creation",
                        redaction: "Copywrite",
                        images: "Images",
                        library: "Library",
                    },
                    apps: {
                        apps: "Apps",
                        extendedCheckout: "Extended Checkout",
                        logistic: "Logistics",
                        smartShipping: "Smart Shipping",
                        service: "Service",
                        alerts: "Alerts",
                    },
                    scale: {
                        operation: "Operation",
                        logistic: "Logistics",
                        smartShipping: "Smart Shipping",
                        inventory: "Inventory",
                        products: "Products",
                        orders: "Orders"
                    },
                    sprint: {
                        strategy: "Strategy",
                        actionsList: "Actions List",
                        actionPlan: "Action Plan",
                        kanban: "Kanban Board",
                        checklists: "Checklists",
                        performance: "Performance",
                        newAction: "New Action",
                        results: "Results",
                    },
                    start: {
                        implantation: "Implantation",
                        form: "Form",
                        projects: "Projects",
                        checklists: "Checklists",
                        checklistCustomer: "Checklist",
                        checklistAgency: "Checklist",
                        roadmap: {
                            title: "Roadmap",
                            isBlockedBy: "Is blocked by",
                            blocks: "Blocks",
                        }
                    },
                    support: {
                        improvements: "New Improvement",
                        improvementsList: "Improvements List",
                        support: "Support and Evolution",
                        hoursControl: "Hour Control",
                        problems: "Problems",
                        performance: "Performance",
                        kanban: "Kanban Board",
                    }
                },
                spinnerAdd: {
                    processing: "Processing",
                    register: "Register"
                },
                spinnerDelete: {
                    processing: "Processing...",
                    delete: "Delete"
                },
                spinnerLogin: {
                    wait: "Wait...",
                    enter: "Enter"
                },
                spinnerRecovery: {
                    processing: "Processing...",
                    recovery: "Recover"
                },
                spinnerUp: {
                    processing: "Processing",
                    update: "Update"
                },
                spinnerUpdateRecovery: {
                    processing: "Processing...",
                    update: "Update"
                },
                upload: {
                    avatar: {
                        update: "Update",
                        send: "Send"
                    },
                    image: {
                        update: "Update",
                        send: "Send"
                    }
                }
            },
            config: {
                ecommerceOperation: "E-commerce Operation",
                platformImplantation: "Platform Implantation",
                ecommecerManagement: "E-commerce Management",
                support: "Support",
            },
            store: {
                actions: {
                    errorHandling: {
                        internalError: "Internal server error."
                    }
                }
            },
            containers: {
                dashboard: {
                    baseDashboard: {
                        dashboard: "Dashboard",
                        actions: "Actions",
                        notifications: "Notifications",
                        chat: "Chat",
                    }

                }
            },
            "insight": {
                "title": "Inventory",
                "startDate": "Start Date",
                "endDate": "End Date",
                "selectLocation": "Select Location",
                "selectTypeProduct": "Select Product Type",
                "selectProduct": "Select Product",
                "type": "Product Type",
                "product": "Product",
                "variant": "Variant",
                "search": "Search",
                "daysPredict": "Stock Depletion Forecast",
                "day": "day",
                "days": "days",
                "media": "Average",
                "perDay": "per day",
                "curveABC": "ABC Curve",
                "riskStock": "Stock Risk",
                "curve": "Stock Turnover"
            },
            "apexChart": {
                "jan": "Jan",
                "feb": "Feb",
                "mar": "Mar",
                "apr": "Apr",
                "may": "May",
                "jun": "Jun",
                "jul": "Jul",
                "aug": "Aug",
                "sep": "Sep",
                "oct": "Oct",
                "nov": "Nov",
                "dec": "Dec",
                "january": "January",
                "february": "February",
                "march": "March",
                "april": "April",
                "may2": "May",
                "june": "June",
                "july": "July",
                "august": "August",
                "september": "September",
                "october": "October",
                "november": "November",
                "december": "December",
                "sunday": "Sunday",
                "monday": "Monday",
                "tuesday": "Tuesday",
                "wednesday": "Wednesday",
                "thursday": "Thursday",
                "friday": "Friday",
                "saturday": "Saturday",
                "sun": "Sun",
                "mon": "Mon",
                "tue": "Tue",
                "wed": "Wed",
                "thu": "Thu",
                "fri": "Fri",
                "sat": "Sat",
                "toolbar": {
                    "exportToSVG": "Download SVG",
                    "exportToPNG": "Download PNG",
                    "menu": "Menu",
                    "selection": "Selection",
                    "selectionZoom": "Selection Zoom",
                    "zoomIn": "Zoom In",
                    "zoomOut": "Zoom Out",
                    "pan": "Panning",
                    "reset": "Reset Zoom"
                }
            },
            "curveABC": {
                "loading": "Loading...",
                "title": "ABC Curve",
                "totalSales": "Total Sales",
                "period": "Period",
                "stockPerTypeProducts": "Stock by Product Types",
                "tableHeaders": {
                    "title": "Title",
                    "sku": "SKU",
                    "type": "Type",
                    "quantity": "Qty",
                    "amount": "Revenue",
                    "percent": "%",
                    "accumulatedPercent": "Accumulated %",
                    "curve": "ABC Curve",
                    "stock": "Stock",
                    "expectedFinish": "Depletion",
                    "valueStock": "Stock Value",
                    "media_day": "Average per day",
                    "expected_days": "Days to Deplete"
                },
                noInstall: {
                    benefit1: "Instant access to crucial sales and inventory metrics.",
                    benefit2: "Real-time tracking of customer events.",
                    benefit3: "Make more informed decisions to optimize strategies.",
                    benefit4: "Increase profitability based on solid data.",
                    benefit5: "User-friendly and easy-to-use interface.",
                    new: "New",
                    title: "Unlock Maximum Potential with Our App!",
                    text1: "Unlock the full potential of your Shopify e-commerce with instant access to crucial sales, inventory, and customer event metrics. Make more informed decisions, optimize your strategies, and increase your profitability like never before.",
                    text2: "Don't waste time—start mastering your data right away!",
                    text3: "Install our app for a complete experience and take full advantage of all the benefits we offer.",
                    unblock: "Unlock Maximum Potential",
                    info: "Information",
                    major100: "Greater than 100%: + sales - inventory.",
                    between100: "Less than 100%: - sales + inventory.",

                }
            },
            costsApp: {
                oms: {
                    spending: 'Spending',
                    spedningText: 'AWS instance value',
                    earnings: 'Earnings',
                    earningsText: 'Total value of earnings from stores',
                    profit: 'Profit',
                    stores: 'Stores',
                    storesText: 'Stores that used the application',
                    fulfillments: 'Fulfillments',
                    fulfillmentsText: 'Number of integrated fulfillments',
                    costResource: 'Cost/resource',
                    costResourceText: 'Cost per each resource used',
                    store: 'Store',
                    resources: 'Resources',
                    cost: 'Cost',
                    pricePerUse: 'Price per use',
                    pricePlan: 'Monthly price',
                    totalPrice: 'Total price',
                    loading: 'Loading...',
                    noData: 'There is no data for the selected month'
                }
            }






        }

    }
}

export { messages };