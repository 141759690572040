const customers_reduce = (state = {}, actions) => {
    switch (actions.type) {
        case "GET_CUSTOMERS":
            return {
                ...state,
                customers: actions.payload.customers
            }
        case "GET_CUSTOMERS_SIDEBAR":
            return {
                ...state,
                customersSidebar: actions.payload.customers
            }
        case "GET_CUSTOMERS_CHECKLIST":
            return {
                ...state,
                customersChecklist: actions.payload.customers
            }

        case "GET_CUSTOMER":
            return {
                ...state,
                customerDetails: actions.payload.customer
            }
        case "GET_CUSTOMER_CHECKLIST":
            return {
                ...state,
                customerChecklist: actions.payload.checklist
            }
        case "GET_CUSTOMER_CHECKLIST_INFOS":
            return {
                ...state,
                customerChecklistInfos: {
                    strike: actions.payload.strike,
                    scale: actions.payload.scale,
                    total: actions.payload.total,
                    sprint: actions.payload.sprint,
                    errors: actions.payload.errors,
                    waitings: actions.payload.waitings,
                    in_analysis: actions.payload.in_analysis,
                }
            }
        case "GET_EPICS":
            return {
                ...state, 
                epics: actions.payload
            }
        case "CLEAR_CUSTOMER":
            return {
                ...state,
                customerDetails: null
            }
        case "CLEAR_CUSTOMERS":
            return {
                ...state,
                customers: null
            }
        case "GET_METRICS":
            return {
                ...state,
                metrics: actions.payload
            }
        default:
            return state;
    }
}

export default customers_reduce;