import { Box, Button, IconButton, Typography } from '@mui/material';
import { FCTextField } from 'components-fullcomm';
import React, { useState } from 'react'
import { Modal } from 'reactstrap';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

const ProjectsModal = ({ open, setOpen, setProjects, projects, getProjects}) => {
  const [newValue, setNewValue] = useState(projects);

  return (
    <Modal
    size="sm"
    centered
    toggle={() => {
      setOpen(false);
    }}
    isOpen={open}
  >
    <Box padding="1rem">
    <Typography variant="h6" mb='1rem' >Projetos</Typography>
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "1rem",
      }}
    >
      {newValue.map((project, index) => (
        <Box display='flex' gap='1rem' alignItems='center'>
          <FCTextField 
            value={project} 
            onChange={(e) => {
              const newProjects = [...newValue];
              newProjects[index] = e.target.value;
              setNewValue(newProjects);
            }}
          />
          <IconButton
           onClick={() => {
            const newProjects = [...newValue];
            newProjects.splice(index, 1);
            setNewValue(newProjects);
           }}
          >
            <DeleteOutlineOutlinedIcon style={{ height: '20px' }} />
          </IconButton>
        </Box>
      ))}
      <Button
        className="btn-white large"
        onClick={() => {
          setNewValue([...newValue, ""]);
        }}
        >
        Adicionar Projeto
      </Button>
      </Box>
      <Box display='flex' gap="1rem" justifyContent='right' mt='2rem'>
        <Button
          className="btn-action medium"
          onClick={() => {
            setProjects(newValue);
            getProjects(newValue);
            setOpen(false);
          }}
        >
          Salvar
        </Button>
        <Button
          className="btn-white medium"
          onClick={() => {
            setProjects(projects);
            setOpen(false);
          }}
        >
          Cancelar
        </Button>
      </Box>
    </Box>
  </Modal>
  )
}

export default ProjectsModal