import axios from 'axios';
import { api } from '../../config';
import { getHeaders } from './localStorage';
import errorHandling from './errorHandling';

export const addDocument = (props) => async () => {
  try {
    const response = await axios.post(api + `/documents`, props, getHeaders());
    return response.data;
  } catch (err) {
    return errorHandling(err);
  }
};

export const getDocuments = (query) => async (dispatch) => {
  try {
    const response = await axios.get(api + `/documents/?${query}`, getHeaders());
    dispatch({ type: "GET_DOCUMENTS", payload: response.data.documents });
    return response.data.documents;
  } catch (err) {
    return errorHandling(err);
  }
};

export const getDocument = (id) => async (dispatch) => {
  try {
    const response = await axios.get(api + `/documents/${id}`, getHeaders());
    dispatch({ type: "GET_DOCUMENT", payload: response.data.document });
  } catch (err) {
    return errorHandling(err);
  }
};

export const deleteDocument = (id) => async () => {
  try {
    const response = await axios.delete(api + `/documents/${id}`, getHeaders());
    return response.data;
  } catch (err) {
    return errorHandling(err);
  }
};

export const editDocument = (id, props) => async () => {
  try {
    const response = await axios.put(api + `/documents/${id}`, props, getHeaders());
    return response.data;
  } catch (err) {
    return errorHandling(err);
  }
};
