import { Box, Button, ButtonGroup, ClickAwayListener, Grow, Paper } from '@mui/material'
import React, { useState } from 'react'
import * as XLSX from 'xlsx';
import IosShareIcon from '@mui/icons-material/IosShare';
import { i18n } from '../../../translate/i18n';

const ExportButton = ({ data }) => {
  const [visible, setVisible] = useState(false);

  function convertArrayOfObjectsToCSV(data) {
    const csvContent = [];
    const keysSet = new Set();

    for (const item of data) {
      for (const key in item) {
        if (key === 'description' || key === 'name') item[key] = item[key].replace(/[<>"']/g, ' ');
        keysSet.add(key);
      }
    }

    csvContent.push(Array.from(keysSet).join(','));
    const headers = Array.from(keysSet);

    data.forEach(item => {
        const values = headers.map(header => `"${item[header]}"`);
        csvContent.push(values.join(','));
    });

    const csvString = csvContent.join('\n');

    return csvString;
}

function exportCSV(data, fileName) {
    const csvString = convertArrayOfObjectsToCSV(data);
    const blob = new Blob([csvString], { type: 'text/csv' });
    const link = document.createElement('a');

    link.href = URL.createObjectURL(blob);
    link.download = fileName || 'export.csv';

    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
}

  function exportXLS(toXLS) {
    const sheetName = 'Sheet1';
    const worksheet = XLSX.utils.json_to_sheet(toXLS);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
    const xlsFile = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

      const blob = new Blob([xlsFile], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = `data.xlsx`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
  }

  return (
    <ClickAwayListener 
      onClickAway={() => {setVisible(false)}}
    >
      <Box position={'relative'} display={"flex"} alignItems={"center"}>
        <Grow in={data?.length > 0}>
          <Button
            variant='contained'
            onClick={() => {
              setVisible(!visible);
            }}
            className='btn-gray'
            >
            <IosShareIcon />{i18n.t('sprint.utils.headerButtons.exportButton.export')}
          </Button>
        </Grow>
          {visible && 
            <Paper
            className='export-button'
            sx={{
              width: '140px',
              height: '60px',
              display: 'flex',
              zIndex: '1000',
              right: '0',
              padding: 1.5,
              position: 'absolute',
              borderRadius: '4px',
            }}
          > 
            <ButtonGroup>
              <Button
                color='backlog'
                onClick={()=>exportCSV(data)}
                sx={{
                  color: 'black',
                  border: '1px solid #bbbfc3',
                }}
              >
                CSV
              </Button>
              <Button
                color='backlog'
                onClick={()=>exportXLS(data)}
                sx={{
                  color: 'black',
                  border: '1px solid #bbbfc3',
                }}
              >
                XLS
              </Button>
            </ButtonGroup>
          </Paper>
          }
      </Box>
    </ClickAwayListener>
  )
}

export default ExportButton