import React, { useEffect, useState } from "react";
import NotificationCard from "./NotificationCard";
import { connect } from 'react-redux';
import * as actions from '../../store/actions/notifications';
import { Box, Pagination, Paper } from "@mui/material";

function SectionNotifications(props) {
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    props.getNotifications(props.user, props.section, currentPage);
  },[currentPage]);

  useEffect(() => {
    const { notifications, section, user, readNotifications } = props;
    const unreadNotifications = notifications?.docs.filter(notification => !notification.read.includes(user?._id));
    
    if (notifications?.docs.every(notification => notification.category === section) && unreadNotifications.length > 0) {
      readNotifications(user, unreadNotifications.map(notification => notification._id), props.section);
    }
  }, [props.notifications])

  return (
    <>
      <Paper>
        { props.notifications &&
          props.notifications.docs.map((notification) => {
            if (!notification) return null;
            return <NotificationCard key={notification._id} user={props.user} {...notification} />
          })

        }
      </Paper>
      <Box sx={{ display: "flex", justifyContent: "center", marginTop: "1rem" }}>
        { props.notifications?.totalPages > 1 &&
          <Pagination
          variant="outlined"
          shape="rounded"
          count={props.notifications?.totalPages}
          page={currentPage}
          onChange={(e, page) => setCurrentPage(page)}
        />
        }
      </Box>
    </>
  );
}

const mapStateToProps = state =>
({
    user: state.auth.user,
    notifications: state.notifications.notifications
})



export default connect(mapStateToProps, actions)(SectionNotifications);
