import React from 'react';
import { Route } from 'react-router-dom';
import ActionPlan from '../pages/Sprint/ActionPlan';
import Strategy from '../pages/Sprint/Strategy';
import Kanban from '../pages/Sprint/Kanban';
import ReprovedActivity from '../pages/Sprint/Kanban/ReprovedActivity';
import List from '../pages/Sprint/List';
import NewAction from '../pages/Sprint/V2NewAction';

import Tables from '../pages/Sprint/TableActivity/Tables';

import Table from '../pages/Sprint/TableActivity/Table';


import FormContract from '../pages/Sprint/Contract/Form';
import ListContracts from '../pages/Sprint/Contract/List';

import Inicial from '../pages/Sprint/ActionChecklist/Inicial';
import ViewAll from '../pages/Sprint/ActionChecklist/ViewAll';
import Create from '../pages/Sprint/ActionChecklist/Create';
import Edit from '../pages/Sprint/ActionChecklist/Edit';
import View from '../pages/Sprint/ActionChecklist/View';
import ActionList from '../components/ActionList';
import Results from '../pages/Sprint/Results';

const Sprint = ({ baseDashboard }) => {
    return (
        <>
            <Route path="/estrategia" exact component={baseDashboard({ Component: Strategy, permissions: ['admin', 'customer', 'agency'], platform: ['sprint'], action: 'view_action' })} />
            <Route path="/action-list" exact component={baseDashboard({ Component: ActionList, permissions: ['admin', 'customer', 'agency'], platform: ['sprint'], action: 'view_action' })} />
            <Route path="/action-plan" exact component={baseDashboard({ Component: ActionPlan, permissions: ['admin', 'customer', 'agency'], platform: ['sprint'], action: 'view_action' })} />
            <Route path="/kanban" exact component={baseDashboard({ Component: Kanban, permissions: ['admin', 'customer', 'agency'], platform: ['sprint'], action: 'view_action' })} />
            <Route path="/kanban/:id" exact component={baseDashboard({ Component: Kanban, permissions: ['admin', 'customer', 'agency'], platform: ['sprint'], action: 'view_action' })} />
            <Route path="/kanban/reproved-activity/:actionId/:activityId" exact component={baseDashboard({ Component: ReprovedActivity, permissions: ['admin', 'agency'], platform: ['sprint']})} />
            <Route path="/new-action" exact component={baseDashboard({ Component: NewAction, permissions: ['admin', 'customer', 'agency'], platform: ['sprint'], action: 'create_action' })} />
            <Route path="/edit-action/:id" exact component={baseDashboard({ Component: NewAction, permissions: ['admin', 'customer', 'agency'], platform: ['sprint'], action: 'view_action' })} />
            <Route path="/new-action/:model" exact component={baseDashboard({ Component: NewAction, permissions: ['admin', 'customer', 'agency'], platform: ['sprint'], action: 'edit_action' })} />
            <Route path="/action/checklists" exact component={baseDashboard({ Component: ViewAll, permissions: ['admin', 'customer', 'agency'], platform: ['sprint'] })} />
            <Route path="/action/checklist/:id" exact component={baseDashboard({ Component: Inicial, platform: ['sprint'], permissions: ['admin', 'customer', 'agency'], action: 'view_action' })} />
            <Route path="/action/create-checklists/:id" exact component={baseDashboard({ Component: Create, permissions: ['admin', 'customer', 'agency'], platform: ['sprint'] })} />
            <Route path="/action/edit-checklist" exact component={baseDashboard({ Component: Edit, permissions: ['admin'], platform: ['sprint'], action: 'edit_action' })} />
            <Route path="/action/view-checklist/:id" exact component={baseDashboard({ Component: View, permissions: ['admin'], platform: ['sprint'], action: 'view_action' })} />

            <Route path="/tables" exact component={baseDashboard({ Component: Tables, permissions: ['admin'], platform: ['sprint'], action: 'view_activity_table' })} />
            <Route path="/table/:version" exact component={baseDashboard({ Component: Table, permissions: ['admin'], platform: ['sprint'], action: 'view_activity_table' })} />
            <Route path="/table" exact component={baseDashboard({ Component: Table, permissions: ['admin', 'customer', 'agency'], platform: ['sprint'], action: 'view_activity_table' })} />

            <Route path="/add-contract" exact component={baseDashboard({ Component: FormContract, permissions: ['admin'], platform: ['sprint'], action: 'create_contract' })} />
            <Route path="/edit-contract/:id" exact component={baseDashboard({ Component: FormContract, permissions: ['admin'], platform: ['sprint'], action: 'edit_contract' })} />
            <Route path="/contracts" exact component={baseDashboard({ Component: ListContracts, permissions: ['admin'], platform: ['sprint'], action: 'view_contract' })} />
        
            <Route path="/results" exact component={baseDashboard({ Component: Results, permissions: ['admin'], platform: ['sprint'] })} />
        </>
    )
}

export default Sprint;