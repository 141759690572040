import {
  Box,
  Typography,
  Button,
  Icon,
  Divider,
  CircularProgress,
  TextField,
  MenuItem,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import React, { useState } from "react";
import { connect } from "react-redux";
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import { toast } from "react-toastify";
import * as actions from '../../../store/actions/aiContent';
import SearchActions from "../utils/SearchActions";
import { i18n } from '../../../translate/i18n';

const DetailsModal = (props) => {
  const [loading, setLoading] = useState(false);
  const [action, setAction] = useState({});
  const [checkBox, setCheckBox] = useState(false);
  const [activity, setActivity] = useState("");

  const handleClick = async () => {
    if (loading) return;
    setLoading(true);
    try {
      const aiContent = props.selected;
      if (action?._id) aiContent.action = action._id;
      if (activity && !checkBox) aiContent.activity = activity;
      if (!activity && !checkBox) {
        toast.error(i18n.t('aiContent.library.detailsModal.selectActivity'));
        setLoading(false);
        return;
      }
      const response = await props.updateAiContent(props.selected?._id, { ...aiContent });
      props.setOpen(false);

      if (response.error) {
        switch (response.error.message) {
          case 'Conteúdo não encontrado.': return toast.error(i18n.t('aiContent.library.detailsModal.contentNotFound'));
          case 'Erro ao enviar arquivo para o Jira!': return toast.error(i18n.t('aiContent.library.detailsModal.sendJiraError'));
          case 'Erro na integração com Jira!': return toast.error(i18n.t('aiContent.library.detailsModal.integrationJiraError'));
          case 'Erro interno do servidor.': return toast.error(i18n.t('aiContent.library.detailsModal.internalError'));
          default: return toast.error(response.error.message);
        }
      } else {
        if (response.message === "Conteúdo atualizado com sucesso!") {
          toast.success(i18n.t('aiContent.library.detailsModal.updateSuccess'));
        } else {
          toast.success(response.message);
        }
      }
    } catch (error) {
      if (error.error.message === 'Erro interno do servidor.') {
        toast.error(i18n.t('aiContent.library.detailsModal.internalError'));
      } else {
        toast.error(error.error.message);
      }
    };

    setLoading(false);
  }

  return (
    <Modal
      centered
      toggle={() => props.setOpen(false)}
      isOpen={props.open}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          padding: 2,
          alignItems: "center"
        }}
      >
        <Typography variant="h3">{i18n.t('aiContent.library.detailsModal.details')}</Typography>
        <Icon
          sx={{
            color: "#86888c",
            cursor: "pointer",
          }}
          onClick={() => props.setOpen(false)}
          className="material-icons-outlined"
          fontSize="large"
        >
          cancel
        </Icon>
      </Box>
      <Divider />
      <ModalBody>
        <Box display="flex" gap="1rem">
          <SearchActions
            action={action}
            actions={props.actions}
            setAction={setAction}
          />
          <FormControlLabel
            sx={{ minWidth: "fit-content" }}
            control={<Checkbox color="backlog" onChange={(e) => { setCheckBox(e.target.checked); setActivity("") }} checked={checkBox} />}
            label={<small>{i18n.t('aiContent.library.detailsModal.linkAction')}</small>}
          />
        </Box>
        <TextField
          fullWidth
          label={i18n.t('aiContent.library.detailsModal.activity')}
          size="small"
          disabled={checkBox}
          value={activity}
          onChange={(e) => setActivity(e.target.value)}
          select
        >
          {
            action?.activities?.map((activity, index) => (
              <MenuItem key={index} value={activity._id}>{activity?.activity?.name || ""}</MenuItem>
            ))
          }
        </TextField>
      </ModalBody>
      <ModalFooter style={{ display: "flex", justifyContent: "space-between" }}>
        <Button variant="contained" className="btn-action" sx={{ width: "fit-content" }} onClick={handleClick}>
          {loading ? (
            <CircularProgress size={25} sx={{ color: "white" }} thickness={4} />
          ) : (
            <>{i18n.t('aiContent.library.detailsModal.save')}</>
          )}
        </Button>
        <Button onClick={() => props.setOpen(false)} variant="contained" className="btn-gray" sx={{ width: "fit-content" }}>
          {i18n.t('aiContent.library.detailsModal.cancel')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

const mapStateToProps = (state) => ({
  actions: state.actions.actions,
});

export default connect(mapStateToProps, actions)(DetailsModal);