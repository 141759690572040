const projects_reduce = (state = {}, actions) => {
    switch(actions.type){
        case "GET_PROJECTS":
            return{
                ...state,
                projects: actions.payload.projects
            }
        case "GET_PROJECT":
            return{
                ...state,
                projectDetails: actions.payload.project
            }
        case "CLEAR_PROJECT":
            return{
                ...state,
                projectDetails: null
            }
        case "CLEAR_PROJECTS":
            return{
                ...state,
                projects: null
            }
        default: 
            return state;
    }
}

export default projects_reduce;