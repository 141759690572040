import React, { useState } from 'react'
import { Box, Tab, Tabs, Typography } from '@mui/material'
import Start from './Start';
import Ocurrencies from './Ocurrencies';
import { i18n } from '../../../translate/i18n';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      style={{ width: "100%", paddingTop: "1rem" }}
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <>
          {children}
        </>
      )}
    </div>
  );
}

const Service = () => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box padding="1rem 1.5rem">
      <Typography variant='h1' mb="1rem">{i18n.t('scale.service.service')}</Typography>
        <Tabs 
          value={value} 
          onChange={handleChange}               
          sx={{
            "& .MuiButtonBase-root": {
              textTransform: "none",
            },
          }}
        >
          <Tab label={i18n.t('scale.service.tabInit')}/>
          <Tab label={i18n.t('scale.service.tabOccurrences')}/>
        </Tabs>
        <TabPanel value={value} index={0}>
          <Start />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Ocurrencies />
        </TabPanel>
    </Box>
  )
}

export default Service