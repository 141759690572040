import React, { useEffect, useState } from 'react'
import AppTabs from '../utils/AppTabs'
import { Box, Typography } from '@mui/material'
import { connect } from 'react-redux';
import * as actions from '../../../store/actions/apps'
import { i18n } from '../../../translate/i18n';

const SmartShipping = (props) => {

  useEffect(()=>{
    props.getApp("category=Frete Inteligente")
  }, [])


  return (
    <Box className="dashboard">
      <Typography variant={"h4"}>{i18n.t('apps.smartShipping.smartShipping1')}</Typography>
      <AppTabs category={i18n.t('apps.smartShipping.smartShipping2')} notifications={{}}/>
    </Box>
  )
}

const mapStateToProps = (state) => ({
  app: state.app
});

export default connect(mapStateToProps, actions)(SmartShipping)