import React, { useState } from 'react'
import { Box, List, ListItem, Rating, Typography } from '@mui/material'
import dayjs from 'dayjs'
import StarIcon from '@mui/icons-material/Star';
import CsvDownloadButton from 'react-json-to-csv';
import { format } from 'date-fns'
import { isMobile } from 'react-device-detect';

const Messages = ({ boteriaMessages, styles }) => {
  const [tab, setTab] = useState(0);
  const [messages, setMessages] = useState([]);

  const getExportDataHandled = () => {
    let dataToExport = boteriaMessages?.sortedReviews?.reduce((acc, curr) => {
      return acc = [...acc, ...curr.messages];
    }, []);
    dataToExport = dataToExport?.map((item) => {
      return {
        humanPresent: item.humanPresent,
        when: format(new Date(item.when), 'dd/MM/yyyy HH:mm:ss'),
        userSent: item.userSent,
        message: item.text,
      }
    })
    return dataToExport
  }
  const exportData = getExportDataHandled();

  return (
    <Box
          borderRadius='5px' 
          p='1rem'
        >
          <Typography variant='h6'>Pesquisas de Satisfação - CSAT</Typography>
          <Box display='flex' alignItems='center' justifyContent='space-between' width='100%'>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '0.5rem'
              }}
            >
              <Rating 
                value={Number(boteriaMessages?.avgRating || 0)} 
                readOnly 
                precision={0.25}
                emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
                />
              <Typography fontSize='18px'>{boteriaMessages?.avgRating}</Typography>
            </div>
            <CsvDownloadButton data={exportData} style={{ border: 'none', background: 'none', cursor: 'pointer' }} filename={'data.csv'}>
              <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14 11.5V14.5H2V11.5H0V14.5C0 15.6 0.9 16.5 2 16.5H14C15.1 16.5 16 15.6 16 14.5V11.5H14ZM13 7.5L11.59 6.09L9 8.67V0.5H7V8.67L4.41 6.09L3 7.5L8 12.5L13 7.5Z" fill="#888888"/>
              </svg>
            </CsvDownloadButton>
          </Box>
          <Box
            display='flex'
            width='100%'
            borderBottom='0.5px solid #D1D5DB'
          >
            <div>
              <Typography 
                variant='body2'
                sx={styles.tab}
                onClick={() => setTab(0)}
              >
                Geral
              </Typography>
              <div style={{...styles.active, opacity: tab === 0 ? 1 : 0}}/>
            </div>
            <div>
              <Typography 
                variant='body2'
                sx={styles.tab}
                onClick={() => setTab(1)}
              >
                Humano
              </Typography>
              <div style={{...styles.active, opacity: tab === 1 ? 1 : 0}}/>
            </div>
            <div>
              <Typography 
                variant='body2'
                sx={styles.tab}
                onClick={() => setTab(2)}
              >
                Bot
              </Typography>
                <div style={{...styles.active, opacity: tab === 2 ? 1 : 0}}/>
            </div>
          </Box>
          <Box 
            display='flex' 
            flexDirection={isMobile ? 'column-reverse' : 'row'}
            gap='1rem'
            mt='1rem'
          >
            <List 
              sx={{ 
                maxWidth: '350px',
                minWidth: '200px',
                border: '1px solid #D1D5DB',
                maxHeight:'700px',
                overflow:'auto',
              }}
            >

            {
              boteriaMessages?.sortedReviews?.map((review, index) => {
                if (tab === 1 && review?.ratingPrompt !== 'human') {
                  return <></>;
                }
                if (tab === 2 && review?.ratingPrompt !== 'bot') {
                  return <></>;
                }
                return (
                  <ListItem
                    key={review.id}
                    onClick={() => {
                      setMessages(review.messages);
                    }}
                    sx={{
                      cursor: "pointer",
                      display: "flex",
                      justifyContent: "space-between",
                      padding: '16px 15px 16px 15px',
                      borderBottom: '1px solid #E0E0E0',
                      fontSize: '14px',
                      '&:hover': {
                        backgroundColor: "#e3e3e3 !important",
                      }
                    }}
                  >
                    {review.name || 'Anônimo'} - {review.rating}
                  </ListItem>
                  
                )
              })
            }
            </List>
            <Box
              width='100%'
              maxHeight='700px'
              overflow='auto'
              border='1px solid #D1D5DB'
            >
              {
                messages.map((message, index) => {
                  const { userSent, text, when, humanPresent } = message;
                  return (
                    <Box
                      key={index}
                      p='1rem'
                      width='100%'
                      display='flex'
                      justifyContent={userSent ? 'flex-end' : 'flex-start'}
                    >
                      <Box
                        display='flex'
                        flexDirection='column'
                        bgcolor={userSent ? '#e3e3e3' : '#f3f3f3'}
                        borderRadius='5px'
                        p='1rem'
                      >
                        <Typography fontSize='12px' fontWeight='500'>{userSent ? 'Usuário' : humanPresent ? 'Atendente' : 'Bot'} - {dayjs(when).format('DD/MM/YYYY - HH[h]mm')}</Typography>
                        
                        {
                          text.includes('https://apiwhatsapp.sendfy.io') ? 
                          <img src={text} alt={text} style={{maxWidth: '100%', maxHeight: '100%'}}/>
                          : 
                          <Typography fontSize='14' color='#888888'>{text}</Typography>
                        }
                      </Box>
                    </Box>
                  )
                })
              }
            </Box>
          </Box>
        </Box>
  )
}

export default Messages