import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import * as actions from '../../../../store/actions/action';
import { FCSelect } from 'components-fullcomm';

export const giveSelectTrimesters = (today) => {
    let month = firstMonthOfTrimester(today.getMonth()) ;
    let year = today.getFullYear();
    const trimester = [];
    const values = [0, 3, 6, 9];
    let indexCurrentTri = values.indexOf(month);
    year--
    for (let i = 0; i < 12; i++) {
      if (indexCurrentTri == values.length) {
        indexCurrentTri = 0;
        year++;
      }
      trimester.push({
        label: `${indexCurrentTri + 1}° TRI ${year}`, 
        value: { month: values[indexCurrentTri], year }
      })
      indexCurrentTri++;
    }
    trimester.splice(0, 2)
    trimester.splice(trimester.length - 5, 5)
    return trimester;
  }

export const firstMonthOfTrimester = (month) => {
    if(month < 3){
      return 0;
    } else if(month < 6){
      return 3;
    } else if(month < 9){
      return 6;
    } else {
      return 9;
    }
  }

const cycles = giveSelectTrimesters(new Date());


const CycleSelector = (props) => {
  const [cycle, setCycle] = useState(props.cycle || '');
  const { size = 'small' } = props;
  useEffect(() => {
    setCycle(props.cycle);
  },[props.cycle])

  useEffect(() => {
    const currentCycle = cycle ? cycle : cycles[2].label;
    props.getCycle(currentCycle);
  },[cycle])

  return (
    <FCSelect
      size={size}
      value={cycle}
      onChange={(e)=>setCycle(e.target.value)}
      options={cycles.map((cycle) => (
        { value: cycle.label, label: cycle.label }
      ))}
    />
  )
}

const mapStateToProps = state =>
({
  cycle: state.actions.cycle,
})

export default connect(mapStateToProps, actions)(CycleSelector);