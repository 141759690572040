import React, { useState } from "react";
import {
  Fab,
} from "@mui/material";
import { Modal } from "reactstrap";
import AddIcon from "@mui/icons-material/Add";
import ContentModal from '../Modal'
import { connect } from "react-redux";
import { userPermissionCheck } from "../../utils/userPermissionCheck";
import { isMobile } from "react-device-detect";

const ActionModal = (props) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      {
        userPermissionCheck(props.user, ["create_action"]) && (
          <Fab
        sx={{
          position: "fixed",
          bottom: isMobile ? 66 : 16,
          right: 16,
          background: "#c84f4f",
          color: "white",
          "&:hover": {
            background: "#993d3d",
          },
        }}
        onClick={() => {
          setOpen(true);
        }}
      >
        <AddIcon />
      </Fab>
        )
      }
      <Modal
        isOpen={open}
        centered={true}
        size="lg"
        toggle={() => setOpen(false)}
      >
        <ContentModal setOpen={setOpen}/>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(ActionModal);
