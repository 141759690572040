import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux'
import * as actions from '../../store/actions'
import { Link } from 'react-router-dom';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { Box, ButtonBase, CardMedia, Typography } from '@mui/material';
import OneSignal from 'react-onesignal';

import Loading from '../../components/Loading'
import { i18n } from '../../translate/i18n';
import dayjs from 'dayjs';
import { isMobile } from 'react-device-detect';
import { FCReturnButton, FCSelect, FCSwitch } from 'components-fullcomm';

library.add(fas)

const I18N_STORAGE_KEY = 'i18nextLng';

const Perfil = (props) => {

    const [user, setUser] = useState()
    const [language, setLanguage ] = useState(localStorage.getItem(I18N_STORAGE_KEY) || user?.language)

    useEffect(() => {
        const { user } = props;
        setUser(user);

    }, [props.user]);

    const handleChange = (event) => {
        localStorage.setItem(I18N_STORAGE_KEY, event.target.value);
        window.location.reload();
    }

    if (!user) return (<Loading />)

    return (
        <div className='p-4'>
            <div className="d-flex">
                <div className="mr-auto p-2 d-flex align-items-center">
                    <FCReturnButton />
                    <Typography variant="h4">{i18n.t('perfil.perfil')}</Typography>
                </div>
            </div><hr />
            <Box display='flex' justifyContent='space-between'>
                <Typography sx={{ display: isMobile && 'none'}} variant='subtitle1'>{i18n.t('perfil.information')}</Typography>
                <Box minWidth={isMobile ? '100%' : '30%'}>
                    <div className='d-flex align-items-center'>
                        <CardMedia
                            sx={{
                                borderRadius: '8px',
                                width: '56px',
                                height: '56px',
                                objectFit: 'cover',
                                borderRadius: '8px',
                                border: '0.5px solid #D1D5DB',
                            }}
                            component="img" image={user.fileLocation ? user.fileLocation : user.url || '/favicon.ico'} alt={user.name} />
                            <Link to={"/update-perfil-photo"}>
                                <ButtonBase className="btn-white small ml-2">Fazer upload de foto</ButtonBase>
                            </Link>
                    </div>
                    <Typography variant='body2' mt='1rem'>{i18n.t('perfil.name')}</Typography>
                    <Box display='flex' justifyContent='space-between'>
                        <Typography variant='body2' sx={{ color: '#888888'}} >{user.name}</Typography>
                        <Link to={"/update-perfil"}>
                            <Typography variant='caption' sx={{ color: '#00D6CF'}}>{i18n.t('perfil.edit1')}</Typography>
                        </Link>
                    </Box>
                    <Typography variant='body2' mt='1rem'>{i18n.t('perfil.email')}</Typography>
                    <Typography variant='body2' sx={{ color: '#888888'}} >{user.email}</Typography>
                    <Typography variant='body2' mt='1rem'>{i18n.t('dataEnrichmentModal.intro.phone')}</Typography>
                    <Typography variant='body2' sx={{ color: '#888888'}} >{user.telephone}</Typography>
                {
                    user.role === "admin" && (
                        <>
                            <Typography variant='body2' mt='1rem'>{i18n.t('perfil.chatStyle')}</Typography>
                            <Typography variant='body2' sx={{ color: '#888888'}} >{user.legacyChatStyle ? i18n.t('perfil.legacy') : i18n.t('perfil.new')}</Typography>
                            <Typography variant='body2' mt='1rem'>{i18n.t('perfil.workingHours')}</Typography>
                            <Typography variant='body2' sx={{ color: '#888888'}} >
                                {
                                    (!user.workingHours?.start || !user.workingHours?.start) ? i18n.t('perfil.notDefined') : `${dayjs(user.workingHours?.start).format('HH:mm')} - ${dayjs(user.workingHours?.end).format('HH:mm')}`
                                }
                            </Typography>
                        </>
                    )
                }
                <Typography variant='body2' mt='1rem'>
                    {i18n.t('perfil.notifications')}
                </Typography>
                    <FCSwitch onClick={() => {OneSignal.showSlidedownPrompt({force: true})}} />
                <Typography variant='body2' mt='1rem'>{i18n.t('perfil.language')}</Typography>
                <FCSelect
                    value={language}
                    onChange={handleChange}
                    fullWidth
                    options={[
                        { value: 'pt-BR', label: i18n.t('perfil.portuguese') },
                        { value: 'en-US', label: i18n.t('perfil.english') },
                        { value: 'es', label: i18n.t('perfil.spanish') },
                    ]}
                />
                </Box>
                <span/>
            </Box>
        </div>
    )
}


const mapStateToProps = state =>
({
    user: state.auth.user
})


export default connect(mapStateToProps, actions)(Perfil);