import React, { useState } from 'react'
import { Box, Button, Divider, IconButton, Typography } from '@mui/material'
import { Modal, ModalBody } from 'reactstrap'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import * as actions from "../../../store/actions/permissions";
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { i18n } from '../../../translate/i18n';
import { isMobile } from 'react-device-detect';
import { FCModalHeader, FCSelect, FCTextField } from 'components-fullcomm';

const ModalEditPermissions = (props) => {
  const [openModal, setOpenModal] = useState(false);
  const [PermissionAction, setPermissionAction] = useState(props.permission);
  const [deleteModal, setDeleteModal] = useState(false);

  const handleUpdatePermissionAction = async (id, permission) => {
    try {
      const response = await (id ? props.updatePermission(id, permission) : props.createPermission(permission));
      if (response.error) {
        switch (response.error.message) {
          case "A permissão já existe!": return toast.error(i18n.t('permission.permissionModels.modalEditPermission.permissionAlreadyExists'));
          case "Erro ao criar permissão!": return toast.error(i18n.t('permission.permissionModels.modalEditPermission.errorCreatePermission'));
          case "Permissão não encontrada!": return toast.error(i18n.t('permission.permissionModels.modalEditPermission.permissionNotFound'));
          case "Erro interno do servidor.": return toast.error(i18n.t('permission.permissionModels.modalEditPermission.internalError'));
          default: return toast.error(response.error.message)
        }
      } else {
        if (response.message === 'Permissão criada com sucesso!') {
          toast.success(i18n.t('permission.permissionModels.modalEditPermission.createSuccess'));
        } else if (response.message === 'Permissão atualizada com sucesso!') {
          toast.success(i18n.t('permission.permissionModels.modalEditPermission.updateSuccess'));
        } else {
          toast.success(response.message);
        }
      }
      setOpenModal(false);
      props.getPermissions();
    } catch (error) {
      if (error.error.message === 'Erro interno do servidor.') {
        toast.error(i18n.t('permission.permissionModels.modalEditPermission.internalError'))
      } else {
        toast.error(i18n.t('permission.permissionModels.modalEditPermission.errorUpdatePermission'))
      }
    }
  }

  const handleDeletePermission = async (id) => {
    try {
      const response = await props.deletePermission(id);
      toast.success(response.message);
      setDeleteModal(false);
      setOpenModal(false);
      props.getPermissions();
    } catch (error) {
      toast.error(i18n.t('permission.permissionModels.modalEditPermission.errorDeletePermission'))
    }
  }

  const updateAction = (id, field, value) => {

    setPermissionAction(prevState => ({
      ...prevState,
      actions: prevState.actions.map(action => {
        if (action.id === id) {
          return { ...action, [field]: value };
        }
        return action;
      })
    }));
  };


  const deleteAction = (id) => {
    setPermissionAction(prevState => ({
      ...prevState,
      actions: prevState.actions.filter(action => action.id !== id)
    }));
  };

  return (
    <>
      <IconButton
        disabled={props.disabled}
        onClick={() => setOpenModal(!openModal)}
      >
        <EditOutlinedIcon fontSize={"small"} />
      </IconButton>
      <Modal size='lg' centered toggle={() => setOpenModal(!openModal)} isOpen={openModal}>
        <FCModalHeader title={i18n.t('permission.permissionModels.modalEditPermission.editPermission')} onClick={() => setOpenModal(false)} />
        <ModalBody>
          <FCTextField
            fullWidth
            size={"small"}
            label={i18n.t('permission.permissionModels.modalEditPermission.name1')}
            value={PermissionAction?.name}
            onChange={(e) => setPermissionAction({ ...PermissionAction, name: e.target.value })}
          />
          <Box display={"flex"} gap={"1rem"} mb={"1rem"} mt={"1rem"} alignItems={"center"}>
            <FCSelect
              fullWidth
              select
              size={"small"}
              label={i18n.t('permission.permissionModels.modalEditPermission.userType')}
              value={PermissionAction?.typeUser}
              onChange={(e) => setPermissionAction({ ...PermissionAction, typeUser: e.target.value })}
              options={[
                { value: "admin", label: i18n.t('permission.permissionModels.modalEditPermission.admin') },
                { value: "customer", label: i18n.t('permission.permissionModels.modalEditPermission.client') },
              ]}
            />
            <FCSelect
              fullWidth
              select
              size={"small"}
              label={i18n.t('permission.permissionModels.modalEditPermission.category')}
              value={PermissionAction?.parent}
              onChange={(e) => setPermissionAction({ ...PermissionAction, parent: e.target.value })}
              options={[
                { value: "geral", label: i18n.t('permission.permissionModels.modalEditPermission.general') },
                { value: "start", label: i18n.t('permission.permissionModels.modalEditPermission.implantation') },
                { value: "sprint", label: i18n.t('permission.permissionModels.modalEditPermission.performance') },
                { value: "suporte", label: i18n.t('permission.permissionModels.modalEditPermission.support') },
                { value: "scale", label: i18n.t('permission.permissionModels.modalEditPermission.operation') },
              ]}
            />
          </Box>
          <Box display={"flex"} justifyContent={"space-between"} alignItems={'center'} my="1.5rem">
            <Typography variant="h7">
              {i18n.t('permission.permissionModels.modalEditPermission.permissions')}
            </Typography>
            <Button variant="contained" className="btn-gray large" onClick={() => setPermissionAction({ ...PermissionAction, actions: PermissionAction?.actions.concat({ name: '', id: '' }) })}>
              {i18n.t('permission.permissionModels.modalEditPermission.addPermission')}
            </Button>
          </Box>
          {
            PermissionAction?.actions.map((action, i) => (
              <Box display={"flex"} gap={"1rem"} mb={"1rem"} alignItems={"end"}>
                <FCTextField
                  fullWidth
                  size={"small"}
                  label={i === 0 ? i18n.t('permission.permissionModels.modalEditPermission.name2') : ''}
                  value={action.name}
                  onChange={(e) => updateAction(action.id, "name", e.target.value)}
                />
                <FCTextField
                  fullWidth
                  size={"small"}
                  label={i === 0 ? "ID" : ''}
                  value={action.id}
                  onChange={(e) => updateAction(action.id, "id", e.target.value)}
                />

                <DeleteOutlineOutlinedIcon
                  sx={{ cursor: "pointer", mb: '0.75rem', color: '#888888' }}
                  onClick={() => deleteAction(action.id)}
                  fontSize={"small"}
                />
              </Box>
            ))
          }
        </ModalBody>
        <Divider />
        <Box display={"flex"} gap={1} justifyContent={'space-between'} padding={2}>
        <Button variant="contained" className="btn-reprove medium" onClick={() => setDeleteModal(true)}>
              {i18n.t('permission.permissionModels.modalEditPermission.delete')}
            </Button>
          <Box display={"flex"} gap={1}>
            <Button variant="contained" className={`btn-gray ${!isMobile ? 'large' : 'medium'}`} onClick={() => setOpenModal(!openModal)}>
              {i18n.t('permission.permissionModels.modalEditPermission.cancel1')}
            </Button>
            <Button variant="contained" className={`btn-action ${!isMobile ? 'large' : 'medium'}`} onClick={() => handleUpdatePermissionAction(props.permission?._id, PermissionAction)}>
              {i18n.t('permission.permissionModels.modalEditPermission.save')}
            </Button>
          </Box>
        </Box>
      </Modal>
      <Modal size='lg' centered toggle={() => setDeleteModal(!deleteModal)} isOpen={deleteModal}>
        <Box

          sx={{
            display: "flex",
            justifyContent: "space-between",
            padding: 2,
            alignItems: "center"
          }}
        >
          <Typography variant="h5">{i18n.t('permission.permissionModels.modalEditPermission.deletePermission')}</Typography>
          <CloseOutlinedIcon
            sx={{
              color: "#86888c",
              cursor: "pointer",
              float: 'top'
            }}
            onClick={() => setDeleteModal(false)}
          />
        </Box>
        <ModalBody>
          <Typography variant='body2'>{i18n.t('permission.permissionModels.modalEditPermission.confirmDelete')}</Typography>
        </ModalBody>
          <Box padding='1rem' display={"flex"} gap={1} justifyContent='end'>
            <Button variant="contained" className="btn-reprove large" onClick={() => handleDeletePermission(props.permission?._id)}>
              {i18n.t('permission.permissionModels.modalEditPermission.delete')}
            </Button>
            <Button variant="contained" className="btn-gray large" onClick={() => setDeleteModal(!deleteModal)}>
              {i18n.t('permission.permissionModels.modalEditPermission.cancel2')}
            </Button>
          </Box>
      </Modal>
    </>
  )
}
const mapStateToProps = (state) => ({
  user: state.auth.user,
});
export default connect(mapStateToProps, actions)(ModalEditPermissions);