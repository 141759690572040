const documents_reducer = (state = {}, actions) => {
  switch (actions.type) {
    case "GET_DOCUMENTS":
          return {
              ...state,
              documents: actions.payload
          }
      case "GET_DOCUMENT":
          return {
              ...state,
              documentDetails: actions.payload
          }
      default:
          return state;
  }
}

export default documents_reducer;