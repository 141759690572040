import { Box, Chip, TableCell, TableRow, Tooltip, Typography, makeStyles } from "@mui/material";
import React from "react";
import { statusColor } from "../../Sprint/utils/statusColor";
import { isDateToday } from "../../Sprint/utils/isDateToday";
import { isMobile } from "react-device-detect";
import LinkOutlinedIcon from '@mui/icons-material/LinkOutlined';

const GenttRow = ({ row, classes, dates, setAnchorEl, setIssuelinks, setSelectedRow }) => {
  if (row.startDate >= dates.length) return <></>;
  if (row.startDate > dates.length || row.endDate < 0) return <></>;
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setIssuelinks(row.issuelinks);
    setSelectedRow(row);
  }
  return (
    <TableRow key={row.name} className={classes.visibleRow} style={{ cursor: row?.issuelinks?.length > 0 ? "pointer" : "default"}}>

      <TableCell sx={{ position: 'sticky', left: 0, zIndex: 100, paddingLeft: "2rem" }}
        onClick={handleClick}
        className={classes.taskCell}>
        <Tooltip title={row.name} >
          <Typography className={classes.titleCell}>{row.name}</Typography>
        </Tooltip>
        {
          !isMobile && <Chip label={row.status} color={statusColor(row.status).type} size="small" />
        }
      </TableCell>

      {dates.map((date, i) => {
        const isExecutionCell = row.executionDate <= i && row.startDate >= i;
        const isProgressCell = row.startDate <= i && row.endDate >= i;
        const isToday = isDateToday(date);
        const execClasses = [classes.executionCell];
        const boxClasses = [classes.progressCell];
        const cellClasses = [classes.dateCell];
        const options = {
          year: "2-digit",
          month: "2-digit",
          day: "2-digit",
        };
        if (row.executionDate === i) execClasses.push(classes.startExecCell)
        if (row.startDate === i) boxClasses.push(classes.startCell);
        if (row.endDate === i) boxClasses.push(classes.endCell);
        return (
          <TableCell key={`${row.name}-${date}-${i}`} onClick={handleClick} className={cellClasses.join(" ")} >
            {isProgressCell && (
              <Box
                backgroundColor={statusColor(row.status).bgcolor}
                className={boxClasses.join(" ")}
              >
                {row.startDate === i && (
                  <Typography
                    sx={{
                      position: "absolute",
                      right: "150%",
                      top: "20%",
                      display: "none",
                      color: "#67696D",
                    }}
                  >
                    {date.toLocaleString("pt-BR", options)}
                  </Typography>
                )}
                {row.endDate === i && (
                  <Typography
                    sx={{
                      position: "absolute",
                      left: "150%",
                      top: "20%",
                      display: "none",
                      color: "#67696D",
                    }}
                  >
                    {date.toLocaleString("pt-BR", options)}
                  </Typography>
                )}
              </Box>
            )}
            {
              row.endDate === i && row.issuelinks?.length > 0 && (
                <LinkOutlinedIcon
                  style={{ color: '#bFbFbF', position: 'absolute', right: '0.1rem', top: '0.6rem', cursor: 'pointer' }}
                />
              )
            }
            {isToday && <Box className={classes.todayCell} />}
            {isExecutionCell && row.executionDate !== row.startDate && (
              <Box
                className={execClasses.join(" ")}
              />
            )}
          </TableCell>
        );
      })}
    </TableRow>
  );
};

export default GenttRow;
