import React, { Component } from 'react'
import Annotation from 'react-image-annotation'

export default class ImageEditor extends Component {
  state = {
    annotations: this.props.annotations || [],
    annotation: {}
  }

  onChange = (annotation) => {
    this.setState({ annotation })
  }

  onSubmit = (annotation) => {
    const { geometry, data } = annotation
    const { setAnnotations, setIndex, index } = this.props;

    this.setState({
      annotation: {},
      annotations: this.state.annotations.concat({
        geometry,
        data: {
          ...data,
          id: Math.random()
        }
      }),
    })
    setIndex(index)
    setAnnotations(this.state.annotations.concat({
      geometry,
      data: {
        ...data,
        id: Math.random()
      }
    }),);
  }

  render () {
    const { imageUrl, alt, disableEdit } = this.props;

    return (
      <Annotation
        dissableAnnotation={disableEdit}
        disableSelector={disableEdit}
        disableEditor={disableEdit}
        src={imageUrl}
        alt={alt}
        annotations={this.state.annotations}
        type={this.state.type}
        value={this.state.annotation}
        onChange={this.onChange}
        onSubmit={this.onSubmit}
        allowTouch
      />
    )
  }
}