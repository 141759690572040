import React from 'react'
import FileSelectArea from '../../../Sprint/utils/FileSelectArea'
import { useField } from 'formik';
import { toast } from 'react-toastify';
import { i18n } from '../../../../translate/i18n';

const FileSelect = () => {
  const [file, , fileHelpers] = useField("files");

  const handleFile = (event) => {
    const newFiles = file.value ? [...event.target.files, ...file.value] : [...event.target.files];
    const size = newFiles.reduce((acc, file) => acc + file.size, 0);
    if (size > 20000000) {
      toast.error(i18n.t('support.problemsIncidents.modalForm.fileSelect.largeFile'));
      return;
    }
    fileHelpers.setValue(newFiles);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const newFiles = [];
    for (let i = 0; i < e.dataTransfer.files.length; i++) {
      const files = e.dataTransfer.files[i];
      const reader = new FileReader();
      reader.onload = (event) => {
        const newFile = {
          name: files.name,
          type: files.type,
          size: files.size,
          data: event.target.result,
        };
        newFiles.push(newFile);
        if (newFiles.length === e.dataTransfer.files.length) {
          const size = [...newFiles, ...file?.value].reduce((acc, file) => acc + file.size, 0);
          if (size > 20000000) {
            toast.error(i18n.t('support.problemsIncidents.modalForm.fileSelect.largeFile'));
            return;
          }
          fileHelpers.setValue([...file?.value, ...newFiles]);
        }
      };
      reader.readAsDataURL(files);
    }
  };

  const handleDelete = (fileName) => {
    const updatedFiles = file?.value.filter((file) => file.name !== fileName);
    fileHelpers.setValue(updatedFiles);
  };

  return (
    <FileSelectArea 
    handleDrop={handleDrop}
    file={file}
    handleFile={handleFile}
    handleDelete={handleDelete}
  />
  )
}

export default FileSelect