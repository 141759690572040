// reducers/permissions_reducer.js
const permissions_reducer = (state = {}, actions) => {
    switch (actions.type) {
      case 'GET_PERMISSIONS':
        return {
          ...state,
          permissions: actions.payload,
        };
      case 'UPDATE_PERMISSION':
        return {
          ...state,
          permissions: state.permissions.map(permission =>
            permission._id === actions.payload._id ? actions.payload : permission
          ),
        };
      case 'CREATE_PERMISSION':
        return {
          ...state,
          permissions: [...state.permissions, actions.payload],
        };
      case 'DELETE_PERMISSION':
        return {
          ...state,
          permissions: state.permissions.filter(permission => permission._id !== actions.payload),
        };
      case 'GET_PERMISSION_MODELS':
        return {
          ...state,
          permissionModels: actions.payload,
        };
      default:
        return state;
    }
  };
  
  export default permissions_reducer;
  