import React, { useState, useEffect } from 'react';

import { Typography, Collapse, List} from '@mui/material';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import WidgetsIcon from '@mui/icons-material/Widgets';
import PeopleIcon from '@mui/icons-material/People';
import FeedbackIcon from '@mui/icons-material/Feedback';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import { i18n } from '../../translate/i18n';

const Apps = ({ user, MenuLink, shopDetails }) => {
    const [isOpen, setIsOpen] = useState(localStorage.getItem('AppsSidebar') === 'false' ? false : true);

    const handleToggleCollapse = () => {
        setIsOpen(!isOpen);
    };

    useEffect(() => {
        localStorage.setItem('AppsSidebar', isOpen);
    }, [isOpen]);

    return (
        <div>
            <Typography onClick={handleToggleCollapse} variant="h6" sx={{ cursor: 'pointer', color: 'white', fontSize: '14px', fontWeight: 'bold', marginTop: '10px', marginBottom: '10px' }}>
                {i18n.t('components.sideBar.apps.apps')}
            </Typography>
            <Collapse in={isOpen}>
                <List component="nav">
                <MenuLink to="/apps/extended-checkout" Icon={AddShoppingCartIcon}>{i18n.t('components.sideBar.apps.extendedCheckout')}</MenuLink>
                <MenuLink to="/apps/logistics" Icon={WidgetsIcon}>{i18n.t('components.sideBar.apps.logistic')}</MenuLink>
                <MenuLink to="/apps/smart-shipping" Icon={LocalShippingIcon}>{i18n.t('components.sideBar.apps.smartShipping')}</MenuLink>
                <MenuLink to="/apps/service" Icon={PeopleIcon}>{i18n.t('components.sideBar.apps.service')}</MenuLink>
                <MenuLink to="/apps/alerts" Icon={FeedbackIcon}>{i18n.t('components.sideBar.apps.alerts')}</MenuLink>
                </List>
            </Collapse>
        </div>
    );
};

export default Apps;
