import axios from "axios"
import errorHandling from "./errorHandling"
import { api, apiLogistics, apiSmartShipping, apiPlatinumService } from "../../config"
import { getHeaders, getToken } from "./localStorage"
import { queryString } from '../../utils/String';

export const fetchToken = () => async (dispath) => {
  try {
    const tokenApi = await axios.get(`${api}/api-fullcomm-token`, getHeaders())
    axios.defaults.headers.common['Authorization'] = `Bearer ${tokenApi.data.token}`;
    dispath({ type: 'FETCH_TOKEN', payload: tokenApi.data.token })
  } catch (error) {
    dispath({ type: 'FETCH_TOKEN', payload: false })
    axios.defaults.headers.common['Authorization'] = ``;
    return errorHandling(error);
  }
}

export const clearToken = () => async (dispath) => {
  try {
    axios.defaults.headers.common['Authorization'] = ``;
    dispath({ type: 'FETCH_TOKEN', payload: false })
  } catch (error) {
    return errorHandling(error);
  }
}

export const getLogisticsInfoHome = (period = '7') => async (dispatch) => {
  try {
    const aux = await (await axios.get(`${apiLogistics}/info-home?period=${period}`)).data.data;
    dispatch({ type: 'GET_LOGISTICS_INFO_HOME', payload: aux })
  } catch (error) {
    dispatch({ type: 'GET_LOGISTICS_INFO_HOME', payload: {} })
    return errorHandling(error);
  }
}

export const getLogisticsInfoHomeAll = (period = '7') => async (dispatch) => {
  try {
    const aux = await (await axios.get(`${apiLogistics}/info-home-all?period=${period}`)).data.data;
    dispatch({ type: 'GET_LOGISTICS_INFO_HOME_ALL', payload: aux })
  } catch (error) {
    dispatch({ type: 'GET_LOGISTICS_INFO_HOME_ALL', payload: {} })
    return errorHandling(error);
  }
}

export const getLogisticsOrders = (page = 1, period = '7', status = [], search = '') => async (dispatch) => {
  try {
    const params = new URLSearchParams({
      period,
      status,
      name: search,
    });
    const auxOrders = await axios.get(`${apiLogistics}/orders/${page}?${params.toString()}`);
    dispatch({ type: 'GET_LOGISTICS_ORDERS', payload: auxOrders.data.data })
  } catch (error) {
    dispatch({ type: 'GET_LOGISTICS_ORDERS', payload: {} })
    return errorHandling(error);
  }
}

export const getAllLogisticsOrders = (page = 1, period = '7', status = [], search = '') => async (dispatch) => {
  try {
    const params = new URLSearchParams({
      period,
      status,
      name: search,
    });
    const auxOrders = await axios.get(`${apiLogistics}/all-customers-orders/${page}?${params.toString()}`);
    dispatch({ type: 'GET_LOGISTICS_ORDERS_ALL', payload: auxOrders.data.data })
  } catch (error) {
    dispatch({ type: 'GET_LOGISTICS_ORDERS_ALL', payload: {} })
    return errorHandling(error);
  }
}

export const getSmartShipping = (period = '7') => async (dispatch) => {
  try {
    const aux = await axios.get(`${apiSmartShipping}/info-home?period=${period}`);
    dispatch({ type: 'GET_SMART_SHIPPING', payload: aux.data })
  } catch (error) {
    dispatch({ type: 'GET_SMART_SHIPPING', payload: {} })
    return errorHandling(error);
  }
}
export const getOrderShippingValue = (startDate, endDate) => async (dispatch) => {
  try {
    const aux = await axios.get(`${apiSmartShipping}/orders?page=1&date_min=${startDate}&date_max=${endDate}&limit=9999&search=`);
    dispatch({ type: 'GET_SMART_SHIPPING_ORDERS', payload: aux.data })
  } catch (error) {
    dispatch({ type: 'GET_SMART_SHIPPING_ORDERS', payload: {} })
    return errorHandling(error);
  }
}
  
export const getOrdersStatusPlatinum = ({ options }) => async (dispatch) => {
  try {
    options = queryString(options);
    const aux = await axios.get(`${apiPlatinumService}/order/status?${options}`);
    dispatch({ type: 'GET_ORDERS_STATUS_PLATINUM', payload: aux.data })
  } catch (error) {
    dispatch({ type: 'GET_ORDERS_STATUS_PLATINUM', payload: {} })
    return errorHandling(error);
  }

}

export const getOrdersPlatinum = ({ options }) => async (dispatch) => {
  try {
    const query = queryString(options);
    const aux = await axios.get(`${apiPlatinumService}/order?${query}`);
    dispatch({ type: 'GET_ORDERS_PLATINUM', payload: aux.data })
  } catch (error) {
    dispatch({ type: 'GET_ORDERS_PLATINUM', payload: {} })
    return errorHandling(error);
  }
}

export const exportOrdersPlatinum = ({ options }) => async () => {
  try {
    const query = queryString(options);
    const aux = await axios.get(`${apiPlatinumService}/order/export-csv?${query}`);
    return aux;
  } catch (error) {
    return errorHandling(error);
  }
}

export const getOrdersPlatinumDetail = ({ id }) => async (dispatch) => {
  try {
    const aux = await axios.get(`${apiPlatinumService}/order/${id}`);
    dispatch({ type: 'GET_ORDERS_PLATINUM_DETAIL', payload: aux.data.order })
  } catch (error) {
    dispatch({ type: 'GET_ORDERS_PLATINUM_DETAIL', payload: {} })
    return errorHandling(error);
  }
}

export const getBoteria = ({id, start_date, end_date}) => async (dispatch) => {
  try {
      const response = await axios.get(`${api}/boteria?id=${id}&start_date=${start_date}&end_date=${end_date}`, getHeaders());
      dispatch({ type: 'GET_BOTERIA', payload: response.data?.data })
  } catch (error) {
      return errorHandling(error);
  }
}

export const getBoteriaMessages = ({id, start_date, end_date}) => async (dispatch) => {
  try {
      const response = await axios.get(`${api}/boteria/messages?id=${id}&start_date=${start_date}&end_date=${end_date}`, getHeaders());
      dispatch({ type: 'GET_BOTERIA_MESSAGES', payload: response.data?.data })
  } catch (error) {
      return errorHandling(error);
  }
}

export const getCostsOms = ({month, year}) => async (dispatch) => {
  try {
      const response = await axios.get(`${apiLogistics}/costs?month=${month}&year=${year}`, getHeaders());
      dispatch({ type: 'GET_COSTS_OMS', payload: response.data })
  } catch (error) {
      return errorHandling(error);
  }
}