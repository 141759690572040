import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { ToggleButtonGroup, ToggleButton, Button, Container, Grid, Typography, CircularProgress, InputAdornment, Box, TextField, InputLabel, FormGroup, Select, MenuItem, FormControlLabel, Checkbox, RadioGroup, Radio, useMediaQuery, } from '@mui/material';

import { parents, api } from '../../../config';
import '../../../styles/dashboard/start/checklist.css'
import { Icon } from '@iconify/react';
import { getViewActionChecklist, handleUpdateActionChecklist } from '../../../store/actions/action';
import { i18n } from '../../../translate/i18n';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 8,
    borderRadius: 4,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: "#008060"
    },
}));



const Inicial = (props) => {
    const [form, setForm] = useState(null);
    const [data, setData] = useState(null);
    const [values, setValues] = useState({})
    const [checklist, setChecklist] = useState([]);
    const isMobileDevice = useMediaQuery('(max-width:600px)');

    useEffect(() => {
        props.getViewActionChecklist(props.match.params.id)
    }, []);

    useEffect(() => {
        if (props.checklists) {
            const uniqueChecklists = props.checklists.reduce((acc, item) => {
                if (!acc.some(checklist => checklist._id === item.checklist._id)) {
                    acc.push(item.checklist);
                }
                return acc;
            }, []);
            setChecklist(uniqueChecklists)
        }
    }, [props.checklists]);

    const handleForm = (id) => {
        setForm(id);
        let aux = props.checklists.filter(e => e.checklist._id === id)
        aux = aux.sort((a, b) => a.field?.order - b.field?.order);
        setData(aux)
        let result = {};
        aux.forEach(element => {
            result = {
                ...result,
                [element._id]: element.comment || ''
            }
        });
        setValues(result)
    }

    const handleField = (id, value) => {
        setData((prevData) =>
            prevData.map((item) =>
                item._id === id ? { ...item, status: value } : item
            )
        );
    };

    const handleComment = (event) => {
        const { name, value } = event.target;
        let aux = { ...values };
        aux[name] = value;
        setValues(aux)
    };



    const save = async () => {
        try {
            const checklist = data.map(e => {
                return {
                    _id: e._id,
                    status: e.status,
                    comment: values[e._id] || ''
                }
            })
            const response = await props.handleUpdateActionChecklist(props.match.params.id, checklist);
            if (response.error) {
                switch (response.error.message) {
                    case "Ação não encontrada!": return toast.error(i18n.t('sprint.actionChecklist.view.actionNotFound'));
                    case "Ocorreu um erro ao atualizar a ação.": return toast.error(i18n.t('sprint.actionChecklist.view.errorUpdate'));
                    case "Erro interno do servidor.": return toast.error(i18n.t('sprint.actionChecklist.view.internalError'));
                    default: return toast.error(response.error.message);
                }
            } else {
                if (response.message === "Ação atualizada com sucesso!") {
                    toast.success(i18n.t('sprint.actionChecklist.view.updateSuccess'));
                } else {
                    toast.success(response.message)
                }
            }
            props.getViewActionChecklist(props.match.params.id)
        } catch (error) {
            if (error.error.message === "Erro interno do servidor.") {
                toast.error(i18n.t('sprint.actionChecklist.view.internalError'))
            } else {
                toast.error(error.error.message)
            }
        }
    }

    const Field = (props) => {
        props.data = props
        const dataProps = props.data.field;
        if(!dataProps || props.status === 'd') return false;
        if (dataProps?.parent && !dataProps?.parentValue?.some(el => {
            const res = data.find(e => e.field._id === dataProps?.parent)?.value
            return Array.isArray(res) ? res.includes(el) : res === el
        })) {
            return false;
        }
        const comum = {
            required: dataProps.required,
            value: props.data.value,
            disabled: true,
            placeholder: dataProps.placeholder,
        }
        const input = <Box display={isMobileDevice ? "block" : "flex"} justifyContent={"space-between"} marginTop={"0.5rem"}>
            <Typography color='#202223' fontSize='0.85rem' >
                {dataProps.title} {dataProps.required && <span style={{ color: 'red' }}>*</span>}
            </Typography>

            <ToggleButtonGroup
                sx={{ marginLeft: isMobileDevice ? 'auto' : '', float: isMobileDevice ? 'right' : '', marginTop: isMobileDevice ? '10px' : '' }}
                size="small"
                exclusive
                value={props.data.status}
                onChange={(e, value) => handleField(props.data._id, value)}
            >
                <ToggleButton value="in_analysis"><Icon color="#6B7280" icon="material-symbols:hourglass-bottom" width="20" /></ToggleButton>
                <ToggleButton value="error" ><Icon width='20px' color='#d82c0d' icon="material-symbols:error" /></ToggleButton>
                <ToggleButton value="success"><Icon color="green" width='20px' icon="material-symbols:check-circle" /></ToggleButton>
            </ToggleButtonGroup>
        </Box>
        const infosSmall = <small style={{ color: "rgba(0, 0, 0, 0.6)" }}>{dataProps.helperText}</small>
        const textError = props.data.status === 'error' &&
            <div style={{ paddingBottom: '10px', marginBottom: '10px', borderBottom: '1px solid #ccc' }}>
                <TextField fullWidth defaultValue={values[props.data._id] || ''}
                    name={props.data._id} onChange={handleComment} multiline rows='2' placeholder='Detalhe o erro.' InputProps={{
                        style: { fontSize: '12px' },
                    }} />
            </div>

        switch (dataProps.type) {
            case 'textarea':
            case 'money':
            case 'url':
            case 'number':
            case 'date':
            case 'email':
            case 'text':
                return <><FormGroup className={`formGroup`}>
                    {input}
                    <TextField
                        multiline={dataProps.type === 'textarea'}
                        rows={dataProps.type === 'textarea' ? 4 : 1}
                        fullWidth
                        type={dataProps.type}
                        size="small"
                        {...comum}
                        InputProps={{
                            style: { fontSize: '12px' },
                            startAdornment: dataProps.type === 'money' && <InputAdornment position="start">R$</InputAdornment>
                        }}
                    />
                    {infosSmall}
                </FormGroup>{textError}</>
            case 'multiselect':
            case 'select':
                const { value } = comum;
                if (typeof value === 'string' && dataProps.type === 'multiselect') {
                    comum.value = [value];
                }
                if (dataProps.type === 'multiselect' && !value) {
                    comum.value = []
                }
                return <><FormGroup className={`formGroup`}>
                    {input}
                    <Select
                        fullWidth
                        multiple={dataProps.type === 'multiselect'}
                        size="small"
                        {...comum}
                        InputProps={{
                            style: { fontSize: '12px' },
                        }}
                    >
                        {
                            dataProps.options.map((e, i) => {
                                return <MenuItem key={i} value={e}>{e}</MenuItem>
                            })
                        }
                    </Select>

                    {infosSmall}</FormGroup>{textError}</>
            case 'checkbox':
                return <><FormGroup className={`formGroup`}>
                    {input}
                    <FormControlLabel
                        {...comum}
                        control={<Checkbox checked={props.data.value}  {...comum} />}
                        label={dataProps.title}
                    />

                    {infosSmall}</FormGroup>{textError}</>
            case 'radio':
                return <><FormGroup className='formGroup'>
                    {input}
                    <RadioGroup
                        {...comum}>
                        {
                            dataProps.options.map((e, i) => {
                                return <FormControlLabel key={i} value={e} control={<Radio />} label={e} />
                            })
                        }
                    </RadioGroup>

                    {infosSmall}
                </FormGroup>{textError}</>
            case 'attachment':

                let files = ""
                if (props.data.value?.length > 0) {
                    files = props.data.value?.map(file => (
                        <li key={file.newName}>
                            <a href={file.location ? file.location : `${api}${file.path}`} target='_blank'><small>{file.name}</small></a>
                        </li>
                    ));
                }

                return (
                    <><FormGroup className='formGroup'>
                        {input}
                        <ul>{files}</ul>
                    </FormGroup>{textError}</>
                );

            default:
                return <></>

        }
    }

    const fieldsInput = data ? data.map(item => Field(item)) : null;
    return (
        <div class='body'>
            <Container style={{ paddingTop: "20px", paddingBottom: "20px" }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant="h1">
                        {i18n.t('sprint.actionChecklist.view.checklist')}
                    </Typography>

                    <Typography style={{ textAlign: 'right', alignItems: "center" }}>
                        {`${Math.round(props.infos?.action)}%`}
                    </Typography>
                </div>
                <BorderLinearProgress colorBar={props.color} variant="determinate" value={props.infos?.action} />
            </Container>
            <Grid style={{ borderTop: '1px solid silver', height: '91%' }} container>
                <Grid item xs={isMobileDevice ? 1.5 : 3} className="menu">
                    <div className={isMobileDevice ? 'item-mobile' : 'item'}>
                        {
                            !isMobileDevice &&
                            <Grid container>
                                <Grid className='alignCenterVertical' item xs={8}>
                                    <h3>{i18n.t('sprint.actionChecklist.view.checklistAction')}</h3>
                                </Grid>
                                <Grid item xs={4}>
                                    <Box sx={{ position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'flex-end', height: '100%' }}>
                                        <CircularProgress variant="determinate" value={props.infos?.action} style={{ color: 'white' }} />
                                        <Box className="circularProgress">
                                            {`${Math.round(props.infos?.action)}%`}
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                        }
                    </div>
                    <div className='subitens'>
                        {checklist?.length > 0 && checklist?.filter(e => e.parent === "action").map((item, index) => {
                            return (
                                <div className={`${isMobileDevice ? 'subitem-mobile' : 'subitem'} ${form === item._id ? 'selected' : ''}`} onClick={() => handleForm(item._id)}>
                                    <Grid container>
                                        <Grid item xs={isMobileDevice ? 10 : 2} className='alignCenterVertical'>
                                            <Icon icon={item.icon} width='30px' />
                                        </Grid>
                                        {
                                            !isMobileDevice && (
                                                <Grid item xs={8} className='alignCenterVertical'>
                                                    <h4 className='break-word'>{item.title}</h4>
                                                </Grid>
                                            )
                                        }
                                        <Grid item xs={2} style={{ display: 'flex', alignItems: 'center', justifyContent: 'right' }}>
                                            {props.infos?.errors?.includes(item._id) ? <Icon width='20px' icon="material-symbols:error" color="#d82c0d" />
                                                : (props.infos?.waitings?.includes(item._id) ? <Icon icon="ic:baseline-keyboard-arrow-right" color="#6B7280" /> : (props.infos?.in_analysis?.includes(item._id) ? <Icon icon="material-symbols:hourglass-bottom" color="#6B7280" width="20" /> : <Icon icon="material-symbols:check-circle" color="#008060" />))
                                            }
                                        </Grid>
                                    </Grid>
                                </div>
                            )
                        })}
                    </div>
                </Grid>
                <Grid item xs={isMobileDevice ? 10.5 : 9}>
                    <Container className={isMobileDevice ? 'p-3' : 'p-5'} style={{ heigth: '100%' }}>
                        {data ? <>
                            <h3>{data[0].checklist.title}</h3>
                            <p>{data[0].checklist.subtitle}</p>
                            {
                                fieldsInput
                            }
                            <Grid container spacing={2} style={{ marginTop: '30px' }}>
                                <Grid item xs={12}>
                                    <Button className='saveButton' onClick={() => save()} fullWidth>{i18n.t('sprint.actionChecklist.view.save')}</Button>
                                </Grid>
                            </Grid>
                        </> : <>{i18n.t('sprint.actionChecklist.view.selectItem')}</>}

                    </Container>
                </Grid>
            </Grid>
        </div>
    );
};

const mapStateToProps = state => ({
    checklists: state.actions.actionChecklist || [],
    infos: state.actions.actionChecklistInfos,
})


const mapDispatchToProps = (dispatch) => {
    return {
        getViewActionChecklist: (id) => dispatch(getViewActionChecklist(id)),
        handleUpdateActionChecklist: (id, data) => dispatch(handleUpdateActionChecklist(id, data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Inicial);
