import { Button, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { i18n } from '../../../translate/i18n';

const FileSelectArea = ({ handleDrop, file, handleFile, handleDelete}) => {
  return (
    <Box
      onDrop={handleDrop}
      onDragOver={(e) => e.preventDefault()}
      display={"flex"}
      border={"2px dashed #C1C1C1"}
      alignItems={"center"}
      paddingY={"3rem"}
      borderRadius={"8px"}
      justifyContent={"center"}
      gap={1}
      sx={{
        "&:hover": {
          borderColor: "#A2A2A2",
        },
      }}
    >
      <Button
        {...file}
        onChange={handleFile}
        variant="contained"
        component="label"
        size="small"
        sx={{
          background: "#e5effd",
          color: "#1f5199",
          textTransform: "none",
          ":hover": {
            background: "#d2dcea",
          },
        }}
      >
        {i18n.t("sprint.utils.headerButtons.fileSelectArea.addFiles")}
        <input
          name="myFileInput"
          id="myFileInput"
          type="file"
          multiple
          hidden
        />
      </Button>
      <Box display={'flex'} flexDirection={'column'} gap={1}>
        {file.value &&
          [...file.value].map((file, i) => (
            <Box key={`${file.name}-${i}`} display={"flex"} gap={"0.25rem"} marginLeft={"1rem"}>
              <HighlightOffIcon
                sx={{ cursor: "pointer" }}
                fontSize={"small"}
                color={"backlog"}
                onClick={() => handleDelete(file.name)}
              />
              <Typography>
                {file.name.length > 20 ? `${file.name.substring(0, 20)}...` : file.name}
              </Typography>
            </Box>
          ))}
      </Box>
    </Box>
  )
}

export default FileSelectArea