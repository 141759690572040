export const capitalizeFirstLetter = (str) => {
  if (!str || typeof str !== 'string') return str;

  return str
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
};

export const queryString = (obj) => {
  const parts = [];

  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      const value = obj[key];

      if (Array.isArray(value)) {
        for (let i = 0; i < value.length; i++) {
          const innerObj = value[i];

          for (let innerKey in innerObj) {
            if (innerObj.hasOwnProperty(innerKey)) {
              const innerValue = innerObj[innerKey];
              const part = `${encodeURIComponent(key)}[${i}][${encodeURIComponent(innerKey)}]=${encodeURIComponent(innerValue)}`;
              parts.push(part);
            }
          }
        }
      } else {
        const part = `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
        parts.push(part);
      }
    }
  }

  return parts.join('&');
}

export const parseQueryString = (query) => {
  const result = {};
  const pairs = query.substring(1).split('&');

  for (let pair of pairs) {
    const [key, value] = pair.split('=');
    const decodedKey = decodeURIComponent(key);
    const decodedValue = decodeURIComponent(value);

    if (decodedKey.includes('[')) {
      const nestedKeys = decodedKey.split(/[\[\]]/).filter(Boolean);
      let currentObject = result;

      for (let i = 0; i < nestedKeys.length - 1; i++) {
        const nestedKey = nestedKeys[i];
        if (!(nestedKey in currentObject)) {
          currentObject[nestedKey] = {};
        }
        currentObject = currentObject[nestedKey];
      }

      currentObject[nestedKeys[nestedKeys.length - 1]] = decodedValue;
    } else {
      result[decodedKey] = decodedValue;
    }
  }

  for (let key in result) {
    if (result.hasOwnProperty(key) && key in pairs) {
      delete result[key];
    }
  }

  return result;
};

export const jsonToFormData = (json, formData = new FormData(), parentKey = '') => {
  for (let key in json) {
    if (json.hasOwnProperty(key)) {
      const value = json[key];
      const newKey = parentKey ? `${parentKey}[${key}]` : key;

      if (value instanceof File) {
        formData.append(newKey, value, value.name);
      } else if (typeof value === 'object' && !Array.isArray(value)) {
        jsonToFormData(value, formData, newKey);
      } else {
        formData.append(newKey, value);
      }
    }
  }
  return formData;
};


export const slugify = (str) => {
  str = str?.normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .replace(/^\s+|\s+$/g, "")
    .toLowerCase()
    .replace(/[^a-z0-9 -]/g, "")
    .replace(/\s+/g, "-")
    .replace(/-+/g, "-");
  return str;
}

export const minCurrency = (value) => {
  if (!value || isNaN(value)) return 'R$ 0,00';
  if (value >= 1000000) {
    return `R$ ${(value / 1000000).toLocaleString('pt-BR', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })}M`;
  } else if (value >= 1000) {
    return `R$ ${(value / 1000).toLocaleString('pt-BR', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })}k`;
  } else {
    return `R$ ${value?.toLocaleString('pt-BR', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })}`;
  }
}

export const secondsToHMS = (seconds) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const secs = (seconds % 60).toFixed(0);

  const formattedHours = String(hours).padStart(2, '0');
  const formattedMinutes = String(minutes).padStart(2, '0');
  const formattedSeconds = String(secs).padStart(2, '0');

  return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
}

export function formatTime(time) {
  if (!time) return null;
  const parts = time.split(':');
  let hours = parseInt(parts[0], 10);
  let minutes = parseInt(parts[1], 10);
  let seconds = parseInt(parts[2], 10);

  const days = Math.floor(hours / 24);
  hours = hours % 24;

  let result = '';
  if (days > 0) result += `${days}d `;
  if (hours > 0) result += `${hours}h `;
  if (minutes > 0) result += `${minutes}m `;
  if (seconds > 0) result += `${seconds}s`;

  return result.trim();
}

export function dateLocaleString(date) {
  if (!date) return null;
  return new Date(date).toLocaleString('pt-BR');
  
}

export function currencyFormat(value) {
  value = parseFloat(value);
  return `R$ ${value?.toFixed(2)?.replace('.', ',')}`;
}