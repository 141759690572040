import React, { useEffect, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux'
import * as actions from '../../../store/actions/action'
import { Typography, Tooltip, Table, TableBody, TableCell, TableRow, Button, Box } from '@mui/material';
import { Icon } from '@iconify/react';
import debounce from 'lodash.debounce';
import { userPermissionCheck } from '../../Sprint/utils/userPermissionCheck';
import { i18n } from '../../../translate/i18n';
import { FCPagination, FCSelect, FCTableContainer, FCTableHeader, FCTextField } from 'components-fullcomm';

const ViewAll = (props) => {
    const [pageCurrent, setPageCurrent] = useState(1);
    const limit = 10;
    const [shopFilter, setShopFilter] = useState('all');
    const [query, setQuery] = useState('');
    const debouncedSearch = useCallback(
        debounce(async (pageCurrent, limit, shopFilter, query) => {
            try {
                props.getActionsChecklist(pageCurrent, limit, shopFilter, query, props.user?.role)
            } catch (error) {
                console.error(i18n.t('sprint.actionChecklist.viewAll.errorIcons'), error);
            }
        }, 1000),
        []
    );

    function checkElapsedTime(inputDate, approved, total) {
        if (approved === total) return i18n.t('sprint.actionChecklist.viewAll.delivered'); 
        const currentDate = new Date();
        const pastDate = new Date(inputDate);
        const timeDifference = currentDate - pastDate;
        const daysPassed = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
      
        if (daysPassed < 5) {
          return i18n.t('sprint.actionChecklist.viewAll.onTime');
        } else if (daysPassed >= 5 && daysPassed <= 7) {
          return i18n.t('sprint.actionChecklist.viewAll.atRisk');
        } else {
          return i18n.t('sprint.actionChecklist.viewAll.late');
        }
      }

      
    useEffect(() => {
        debouncedSearch(pageCurrent, limit, shopFilter, query);
    }, [pageCurrent, shopFilter, query, props.shop]);

    let actions = [];

    if (props.actions) {
        actions = props.actions.docs
    }

    return (
        <div style={{padding: "1rem 1.5rem"}}>
            <div className="d-flex">
                <div className="mr-auto">
                <Typography variant="h4">{i18n.t('sprint.actionChecklist.viewAll.checklists')}</Typography>
                </div>
                {
                    userPermissionCheck(props.user, ["create_action"], "admin") &&                 
                    <Link to={"/action/edit-checklist"}>
                        <Button className="btn-action medium">{i18n.t('sprint.actionChecklist.viewAll.edit')}</Button>
                    </Link>
                }

            </div>
            <br />
            <Box
                mb='1rem'
                display='flex'
                gap='1rem'
            >
                <FCTextField value={query} label={i18n.t('sprint.actionChecklist.viewAll.search')} onChange={e => setQuery(e.target.value)} />
                <FCSelect
                    value={shopFilter}
                    label={i18n.t('sprint.actionChecklist.viewAll.status')}
                    onChange={e => setShopFilter(e.target.value)}
                    options={[
                        { value: "all", label: i18n.t('sprint.actionChecklist.viewAll.all') },
                        { value: "success", label: i18n.t('sprint.actionChecklist.viewAll.approveds') },
                        { value: "in_analysis", label: i18n.t('sprint.actionChecklist.viewAll.validatingItems') },
                        { value: "waiting", label: i18n.t('sprint.actionChecklist.viewAll.waitingSend') },
                        { value: "error", label: i18n.t('sprint.actionChecklist.viewAll.repproveds') },
                        { value: "expired", label: i18n.t('sprint.actionChecklist.viewAll.lateDelivered') },
                        { value: "on_time", label: i18n.t('sprint.actionChecklist.viewAll.onTime2') },
                        { value: "risk", label: i18n.t('sprint.actionChecklist.viewAll.atRisk2') }
                    ]}
                />
            </Box>
            <FCTableContainer >
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <FCTableHeader>
                        <TableRow>
                            <TableCell>{i18n.t('sprint.actionChecklist.viewAll.action')}</TableCell>
                            <TableCell>{i18n.t('sprint.actionChecklist.viewAll.waitingSend2')}</TableCell>
                            <TableCell>{i18n.t('sprint.actionChecklist.viewAll.validatingItems2')}</TableCell>
                            <TableCell>{i18n.t('sprint.actionChecklist.viewAll.repproveds2')}</TableCell>
                            <TableCell>{i18n.t('sprint.actionChecklist.viewAll.approveds2')}</TableCell>
                            <TableCell>{i18n.t('sprint.actionChecklist.viewAll.time')}</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </FCTableHeader>
                    <TableBody>
                        {actions?.map(row => (
                            <TableRow
                                key={row._id}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell>{row.name}</TableCell>
                                <TableCell>{row.checklist.filter(e => e.status === 'waiting').length}</TableCell>
                                <TableCell>{row.checklist.filter(e => e.status === 'in_analysis').length}</TableCell>
                                <TableCell>{row.checklist.filter(e => e.status === 'error').length}</TableCell>
                                <TableCell>{row.checklist.filter(e => e.status === 'success').length}</TableCell>
                                <TableCell>{checkElapsedTime(row.createdAt, row.checklist.filter(e => e.status === 'success').length, row.checklist.length)}</TableCell>
                                <TableCell align="right">
                                    {
                                        userPermissionCheck(props.user, ["view_action"], 'customer') && row.checklist.length > 0 && <Tooltip title={i18n.t('sprint.actionChecklist.viewAll.fillChecklist')}><Link to={"/action/checklist/" + row._id}><Icon width="25" style={{marginRight: '5px'}} icon="tabler:checklist" /></Link></Tooltip>
                                    }
                                    {
                                        userPermissionCheck(props.user, ["view_action"], 'admin') && row.checklist.length > 0 && <Tooltip title={i18n.t('sprint.actionChecklist.viewAll.viewChecklist')}><Link to={"/action/view-checklist/" + row._id}><Icon width="25" style={{marginRight: '5px'}} icon="tabler:checklist" /></Link></Tooltip>
                                    }
                                    {
                                        userPermissionCheck(props.user, ["create_action"], 'admin') && <Tooltip title={i18n.t('sprint.actionChecklist.viewAll.editChecklist')} onClick={()=>localStorage.setItem('previousPath', '/action/checklists')}><Link to={"/action/create-checklists/" + row._id}><Icon width="25" style={{marginRight: '5px'}} icon="material-symbols:edit-attributes" /></Link></Tooltip>
                                    }
                            </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </FCTableContainer>
            <br />
            {!props.actions ? "" :
                <FCPagination count={props.actions.totalPages} page={pageCurrent} onChange={(e, page) => setPageCurrent(page)} />
            }
        </div>
    )
}


const mapStateToProps = state => ({
    actions: state.actions.actionsChecklist || [],
    shop: state.auth.shop,
})

export default connect(mapStateToProps, actions)(ViewAll);

