import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../../store/actions/scale';
import '../../../styles/dashboard/scale/PlatinumLog.scss';

import {
    Stack, Grid, Box, Card
} from '@mui/material';
import { currencyFormat, dateLocaleString } from '../../../utils/String';
import MediaTime from '../../../components/PlatinumLog/MediaTime';
import InternalEvents from '../../../components/PlatinumLog/InternalEvents';
import ShippingEvents from '../../../components/PlatinumLog/ShippingEvents';
import Products from '../../../components/PlatinumLog/Products';
import { i18n } from '../../../translate/i18n';

const PlatinumLog = (props) => {
    const dispatch = useDispatch();
    const orderPlatinum = useSelector(state => state.scale.ordersPlatinumDetail);
    const id = props.match.params.id;

    useEffect(() => {
        dispatch(actions.getOrdersPlatinumDetail({ id }));
    }, [dispatch]);

    if (!orderPlatinum) {
        return <div>{i18n.t('scale.platinumlog.order.loading')}</div>
    }

    const data_first = [
        {
            name: i18n.t('scale.platinumlog.order.order'),
            value: orderPlatinum?.info.number
        },
        {
            name: i18n.t('scale.platinumlog.order.orderDate'),
            value: dateLocaleString(orderPlatinum.createdCommerce)
        },
        {
            name: i18n.t('scale.platinumlog.order.invoice'),
            value: orderPlatinum?.invoice[0].number ? `${orderPlatinum?.invoice[0]?.number}, ${orderPlatinum?.invoice[0]?.volumes} vol(s), ${currencyFormat(orderPlatinum?.invoice[0]?.value)}` : '-'
        },
        {
            name: i18n.t('scale.platinumlog.order.nfe'),
            value: <small>{orderPlatinum?.invoice[0]?.key ? orderPlatinum?.invoice[0]?.key : '-'}</small>
        },
        {
            name: i18n.t('scale.platinumlog.order.prevision'),
            value: dateLocaleString(orderPlatinum?.info?.prediction)
        },
        {
            name: i18n.t('scale.platinumlog.order.carrier'),
            value: orderPlatinum?.shippment?.nick
        },
        {
            name: i18n.t('scale.platinumlog.order.modality'),
            value: orderPlatinum?.shippment?.method
        },

    ]

    const data_second = [
        {
            name: i18n.t('scale.platinumlog.order.customer'),
            value: orderPlatinum?.deliveryTo.document + ' / ' + orderPlatinum?.deliveryTo.to
        },
        {
            name: i18n.t('scale.platinumlog.order.contact'),
            value: orderPlatinum?.deliveryTo.phone + " / " + orderPlatinum?.deliveryTo.mail
        },
        {
            name: i18n.t('scale.platinumlog.order.address'),
            value: orderPlatinum?.deliveryTo.street + ', ' + orderPlatinum?.deliveryTo.number
        },
        {
            name: i18n.t('scale.platinumlog.order.complement'),
            value: orderPlatinum?.deliveryTo.complement
        },
        {
            name: i18n.t('scale.platinumlog.order.neighborhood'),
            value: orderPlatinum?.deliveryTo.district
        },
        {
            name: i18n.t('scale.platinumlog.order.city'),
            value: orderPlatinum?.deliveryTo.city + '/' + orderPlatinum?.deliveryTo.state
        },
        {
            name: i18n.t('scale.platinumlog.order.cep'),
            value: orderPlatinum?.deliveryTo.zipcode
        }
    ]

    return (
        <div className='dashboard'>
            <h3>{i18n.t('scale.platinumlog.order.orderDetails')}</h3>
            <br />
            <Card>
                <Grid container spacing={2} padding={3}>
                    <Grid item xs={12} lg={6}>
                        <Box>
                            <Grid container spacing={2} className='item-details'>
                                {data_first.map((item, index) => (
                                    <React.Fragment key={index}>
                                        <Grid item xs={4}>
                                            <b>{item.name}</b>
                                        </Grid>
                                        <Grid item xs={8}>
                                            {item.value || '-'}
                                        </Grid>
                                    </React.Fragment>
                                ))}

                            </Grid>
                        </Box>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <Box>
                            <Grid container spacing={2} className='item-details'>
                                {data_second.map((item, index) => (
                                    <React.Fragment key={index}>
                                        <Grid item xs={4}>
                                            <b>{item.name}</b>
                                        </Grid>
                                        <Grid item xs={8}>
                                            {item.value || '-'}
                                        </Grid>
                                    </React.Fragment>
                                ))}

                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
            </Card>
            <br />
            <h4>{i18n.t('scale.platinumlog.order.timeIndicators')}</h4>
            <Stack direction={{ xs: 'row' }} columnGap={2} rowGap={2} className='box-media-time' >
                <MediaTime ordersStatusPlatinum={orderPlatinum} />
            </Stack>
            <br />
            <h4>{i18n.t('scale.platinumlog.order.internalEvents')}</h4>
            <Stack direction={{ xs: 'row' }} columnGap={2} rowGap={2} >
                <InternalEvents internalEvents={orderPlatinum.internalevents[0]} createdCommerce={orderPlatinum.createdCommerce} />
            </Stack>
            <br />
            <h4>{i18n.t('scale.platinumlog.order.shippingEvents')}</h4>
            <Stack direction={{ xs: 'row' }} columnGap={2} rowGap={2} >
                <ShippingEvents shippingEvents={orderPlatinum.shippingevents} />
            </Stack>
            <br />
            <h4>{i18n.t('scale.platinumlog.order.goods')}</h4>
            <Stack direction={{ xs: 'row' }} columnGap={2} rowGap={2} >
                <Products products={orderPlatinum.items} />
            </Stack>
            <br />
        </div>

    )
}


export default (PlatinumLog);