import React, {Component} from 'react';
import {Form, FormGroup, Input, Label} from 'reactstrap'
import {Link} from 'react-router-dom'

import AlertDanger from '../../components/AlertDanger'
import AlertSuccess from '../../components/AlertSuccess'
import SpinnerLogin from '../../components/SpinnerLogin'

import logo from '../../assets/logo-fullcomm-vertical.png';
import {connect} from 'react-redux'
import * as actions from '../../store/actions'
import AuthLogin from './Auth0/Login';
import { i18n } from '../../translate/i18n';

class Login extends Component{

    state = {
        email: "",
        password: "",
        error: "",
        loading: false,
        msg: ""
    }

    onChangeInput = (ev) => {
        this.setState({[ev.target.name]: ev.target.value})
    }

    componentDidMount() {
        if (this.props.location.state) {
            this.setState({ msg: this.props.location.state.msg });
            this.props.location.state.msg = "";
        }
    }

    handleLogin(){
        const {email, password} = this.state;

        if(!this.validate()) return
        this.setState({loading: true});
        this.setState({msg: ""});
        this.props.handleLogin({email, password}, (msg) => {
            if(msg.error){
                this.setState({error: {message: msg.error.message}})
                this.setState({loading: false});
            }
            
        });
    }

    validate(){
        const {email, password} = this.state;
        if(!email){
            this.setState({error: {message: i18n.t('login.informEmail')}})
            return false;
        }else if(!password){
            this.setState({error: {message:  i18n.t('login.informPassword')}})
            return false;
        }

        return true;
    }

    render(){        
        const { loading, email, password, error, msg } = this.state;
        return (
            <>     
                <div className="container-login">
                    <AuthLogin />
                </div>
            </>
        )
    }
    
}

export default connect(null, actions)(Login);