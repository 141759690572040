import {
  Box,
  Typography,
  Button,
  Icon,
  Divider,
  CircularProgress,
  TextField,
  Grid,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Modal, ModalBody } from "reactstrap";
import * as actions from "../../../../store/actions/action";
import { queryString } from "../../../../utils/String";
import { toast } from "react-toastify";
import { i18n } from "../../../../translate/i18n";

const IntegrationsModal = (props) => {
  const [loading, setLoading] = useState(false);
  const [updatedAction, setUpdatedAction] = useState({});

  const handleClick = async (type) => {
    if (loading) return;
    setLoading(true);
    try {

      let response;
      if (type === "jira") {
        response = await props.syncActionWithJira(props.actionId);
      } else {
        response = await props.unbindActionFromJira(props.actionId);
      }
      const query = queryString({ limit: 10, page: 1, cycle: props.cycle })
      await props.getActions(query);
      await props.getAction(props.actionId);

      props.setOpen(false);
      if (response.error) {
        switch (response.error.message) {
          case "Ação não existe ou sem vínculo ao Jira!": return toast.error(i18n.t('sprint.list.modal.integrationsModal.actionNotExist'));
          case "Ação sem vínculo ao Jira!": return toast.error(i18n.t('sprint.list.modal.integrationsModal.unrelatedAction'));
          case "Ação não modificada!": return toast.error(i18n.t('sprint.list.modal.integrationsModal.actionNotModify'));
          case "Erro interno do servidor.": return toast.error(i18n.t('sprint.list.modal.integrationsModal.internalError'));
          case "Ação não existe!": return toast.error(i18n.t('sprint.list.modal.integrationsModal.actionNotExist2'))
          default: return toast.error(response.error.message);
        }
      } else {
        if (response.message === "Ação sincronizada com sucesso!") {
          toast.success(i18n.t('sprint.list.modal.integrationsModal.syncActionSuccess'));
        } else if (response.message === "Ação desvinculada com sucesso!") {
          toast.success(i18n.t('sprint.list.modal.integrationsModal.unbindSuccess'));
        } else {
          toast.success(response.message);
        }
      }
    } catch (error) {
      if (error.error.message) {
        toast.error(i18n.t('sprint.list.modal.integrationsModal.internalError'))
      } else {
        toast.error(error.error.message)
      }
    }
    setLoading(false);
  };

  const updateAction = async () => {
    if (loading) return;
    setLoading(true);

    try {
      const response = await props.linkActionToJira(props.actionId, updatedAction);
      setLoading(false);
      if (response.error) {
        switch (response.error.message) {
          case "Ação não encontrada!": return toast.error(i18n.t('sprint.list.modal.integrationsModal.actionNotFound'));
          case "Ação não modificada!": return toast.error(i18n.t('sprint.list.modal.integrationsModal.actionNotModify2'));
          case "Erro interno do servidor.": return toast.error(i18n.t('sprint.list.modal.integrationsModal.internalError'));
          default: return toast.error(response.error.message);
        }

      } else {
        if (response.message === "Ação atualizada com sucesso!") {
          toast.success(i18n.t('sprint.list.modal.integrationsModal.updateActionSuccess'))
        } else {
          toast.success(response.message);
        }
        await props.syncActionWithJira(props.actionId);
        const query = queryString({ limit: 10, page: 1, cycle: props.cycle })
        await props.getActions(query);
        await props.getAction(props.actionId);
        props.setOpen(false);
      }
    } catch (error) {
      if (error.error.message === "Erro interno do servidor.") {
        toast.error(i18n.t('sprint.list.modal.integrationsModal.internalError'))
      } else {
        toast.error(error.error.message)
      }
    }
    setLoading(false);
  }

  useEffect(() => {
    if (props.open) props.getAction(props.actionId);
  }, [props.open]);

  useEffect(() => {
    if (props.action) {
      setUpdatedAction(props.action);
    }
  }, [props.action]);

  return (
    <Modal
      centered
      toggle={() => props.setOpen(false)}
      isOpen={props.open}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          padding: 2,
          alignItems: "center"
        }}
      >
        <Typography variant="h3">{i18n.t('sprint.list.modal.integrationsModal.jiraPlatform')}</Typography>
        <Icon
          sx={{
            color: "#86888c",
            cursor: "pointer",
          }}
          onClick={() => props.setOpen(false)}
          className="material-icons-outlined"
          fontSize="large"
        >
          cancel
        </Icon>
      </Box>
      <Divider />
      <ModalBody>
        {
          props.action?.keyJira ? (
            <>
              <Box display={"flex"} gap={"1rem"} alignItems={"center"} justifyContent={"space-between"} mb={"2rem"}>
                <Typography>{i18n.t('sprint.list.modal.integrationsModal.syncStatus')}</Typography>
                <Button variant="contained" className="btn-action" onClick={() => {
                  handleClick("jira");
                }}>
                  {loading ? (
                    <CircularProgress size={25} sx={{ color: "white" }} thickness={4} />
                  ) : (
                    <>{i18n.t('sprint.list.modal.integrationsModal.apply')}</>
                  )}
                </Button>
              </Box>
              <Box display={"flex"} gap={"1rem"} alignItems={"center"} justifyContent={"space-between"}>
                <Typography>{i18n.t('sprint.list.modal.integrationsModal.unlinkEpic')}</Typography>
                <Button variant="contained" className="btn-reprove" onClick={() => {
                  handleClick();
                }}>
                  {loading ? (
                    <CircularProgress size={25} sx={{ color: "white" }} thickness={4} />
                  ) : (
                    <>{i18n.t('sprint.list.modal.integrationsModal.apply2')}</>
                  )}
                </Button>
              </Box>
            </>
          ) : (
            <>
              <Typography variant="h4">{i18n.t('sprint.list.modal.integrationsModal.linkAction')}</Typography>
              <Grid container spacing={2} mb={"1rem"}>
                <Grid item xs={9} display={"flex"} alignItems={"center"}>
                  <Typography>{props.action?.name}</Typography>
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    label={i18n.t('sprint.list.modal.integrationsModal.jiraKey')}
                    size="small"
                    value={updatedAction.keyJira}
                    onChange={(e) => setUpdatedAction({ ...updatedAction, keyJira: e.target.value })}
                  />
                </Grid>
              </Grid>
              {
                props.action?.activities?.filter(el => el.responsible === "FullPRO").length > 0 && (
                  <Typography variant="h4">{i18n.t('sprint.list.modal.integrationsModal.linkActivities')}</Typography>
                )
              }
              <Grid container spacing={2}>
                {
                  props.action?.activities?.filter(el => el.responsible === "FullPRO").map((activity, index) => {
                    if (!updatedAction?.activities) return <></>;
                    return (
                      <>
                        <Grid item xs={9} display={"flex"} alignItems={"center"}>
                          <Typography>{activity?.activity?.name}</Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <TextField
                            label={i18n.t('sprint.list.modal.integrationsModal.jiraKey2')}
                            size="small"
                            value={updatedAction?.activities[index]?.keyJira || ""}
                            onChange={(e) => {
                              const activities = [...updatedAction.activities];
                              activities[index].keyJira = e.target.value;
                              setUpdatedAction({ ...updatedAction, activities });
                            }
                            }
                          />
                        </Grid>
                      </>
                    )
                  })
                }
              </Grid>
              <Divider sx={{ mt: "1rem" }} />
              <Button variant="contained" className="btn-action" onClick={updateAction} sx={{ mt: "1rem", float: "right" }}>
                {loading ? (
                  <CircularProgress size={25} sx={{ color: "white" }} thickness={4} />
                ) : (
                  <>{i18n.t('sprint.list.modal.integrationsModal.save')}</>
                )}
              </Button>
            </>
          )
        }
      </ModalBody>
    </Modal>
  )
}

const mapStateToProps = (state) => ({
  cycle: state.actions.cycle,
  action: state.actions.action,
});

export default connect(mapStateToProps, actions)(IntegrationsModal)