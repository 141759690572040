import { Box, Button, Chip, FormHelperText, Icon, Typography } from '@mui/material'
import { FormikProvider, useFormik } from 'formik';
import React, { useEffect, useState } from 'react'
import { Form, Modal, ModalBody } from "reactstrap";
import { isMobile } from 'react-device-detect';
import AddIcon from '@mui/icons-material/Add';
import FileSelect from './FileSelect';
import * as Yup from "yup";
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import * as actions from '../../../../store/actions/problems'
import * as actionsCustomer from '../../../../store/actions/customers'
import ModalSummary from './ModalSummary';
import { browsers, devices, priorities, rootCause, problemLocation } from '../utils';
import { api } from '../../../../config';
import { i18n } from '../../../../translate/i18n';
import ReactQuill from 'react-quill';
import { FCSelect, FCTextField } from 'components-fullcomm';

const ModalForm = (props) => {
  const [openModal, setOpenModal] = useState(false);
  const [page, setPage] = useState("fill");
  const [customerOptions, setCustomerOptions] = useState([]);
  const [files, setFiles] = useState([]);

  const modules = {
    toolbar: [
      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
      ['bold', 'italic', 'underline', 'link'],
      [{ 'list': 'ordered'}, { 'list': 'bullet' }],
    ],
    clipboard: {
      matchVisual: false,
    },
  };

  const FormSchema = Yup.object().shape({
    title: Yup.string().required(),
    description: Yup.string().required(),
    rootCause: Yup.string().required(),
    problemLocation: Yup.string().required(),
    expectedResult: Yup.string().required(),
    device: Yup.string().required(),
    browser: Yup.string().required(),
    repeated: Yup.string().required(),
    customer: Yup.string().required(),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: props.isEdit?.title || "",
      description: props.isEdit?.description || "",
      rootCause: props.isEdit?.rootCause || "",
      problemLocation: props.isEdit?.problemLocation || "",
      expectedResult: props.isEdit?.expectedResult || "",
      device: props.isEdit?.device || "",
      browser: props.isEdit?.browser || "",
      repeated: props.isEdit?.repeated || "",
      customer: props.isEdit?.customer?._id || props.shopOverride || props.shop,
      priority: props.isEdit?.priority || "Sem Prioridade",
    },

    validationSchema: FormSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
        const files = values.files;
        delete values.files;
        const result = props.isEdit ? await props.updateProblem(props.isEdit._id, values) : await props.addProblem(values);
        if (result.error) {
          switch (result.error.message) {
            case "Dados inválidos!": toast.error(i18n.t('support.problemsIncidents.modalForm.invalidData'))
              break;
            case "Problema não encontrado!": toast.error(i18n.t('support.problemsIncidents.modalForm.problemNotFound'))
              break;
            case "Erro ao editar problema!": toast.error(i18n.t('support.problemsIncidents.modalForm.updateError'))
              break;
            case "Erro interno do servidor.": toast.error(i18n.t('support.problemsIncidents.modalForm.internalError'))
              break;
            default: toast.error(result.error.message)
              break;
          }
        } else {
          if (files) {
            const formData = new FormData();
            for (let i = 0; i < files.length; i++) {
              formData.append("file", files[i]);
            }
            const upload = await props.uploadProblemFile(result.problem._id, formData);
            if (upload.error) {
              switch (upload.error.message) {
                case "Erro ao enviar arquivos!": toast.error(i18n.t('support.problemsIncidents.modalForm.sendError'))
                  break;
                case "Erro ao atualizar arquivos!": toast.error(i18n.t('support.problemsIncidents.modalForm.updateFileError'))
                  break;
                case "Erro interno do servidor.": toast.error(i18n.t('support.problemsIncidents.modalForm.internalError'))
                  break;
                default: toast.error(upload.error.message)
                  break;
              }
            } else {
              if (upload.message === "Arquivos enviados com sucesso!") {
                toast.success(i18n.t('support.problemsIncidents.modalForm.sendSuccess'))
              } else {
                toast.success(upload.message)
              }
            }
          }
        }
        props.getProblems("customer="+props.shop+"&date="+new Date());
        props.setIsEdit(null);
        if (result.message === "Problema editado com sucesso!") {
          toast.success(i18n.t('support.problemsIncidents.modalForm.updateSuccess'));
        } else {
          toast.success(result.message);
        }
        setOpenModal(false);
        props.setProblemModal && props.setProblemModal(false);
        resetForm();

      } catch (error) {
        if (error.error.message === "Erro interno do servidor.") {
          toast.error(i18n.t('support.problemsIncidents.modalForm.internalError'));
        } else {
          toast.error(error.error.message)
        }
        toast.error(i18n.t('support.problemsIncidents.modalForm.errorProblem'));
      }
    }
  })

  const validate = (values) => {
    const requiredFields = ['title', 'description', 'rootCause', 'problemLocation', 'expectedResult', 'device', 'browser', 'repeated', 'customer'];
    const errors = {};

    requiredFields.forEach((field) => {
      if (!values[field]) {
        errors[field] = i18n.t('support.problemsIncidents.modalForm.fieldRequired');
        setFieldError(field, true);
        setFieldTouched(field, true);
      }
    });

    if (Object.keys(errors).length) {
      toast.error(i18n.t('support.problemsIncidents.modalForm.fillFields'));
      return errors;
    }

    setPage('review');
  };

  const handleDelete = async (fileName, problemId) => {
    try {

      const response = await props.deleteProblemFile(problemId, fileName)
      if (response.error) {
        switch (response.error.message) {
          case "Erro ao deletar arquivos!": return toast.error(i18n.t('support.problemsIncidents.modalForm.deleteError'));
          case "Erro interno do servidor.": return toast.error(i18n.t('support.problemsIncidents.modalForm.internalError'));
          default: return toast.error(response.error.message);
        }
      } else {
        if (response.message === "Arquivo removido com sucesso!") {
          toast.success(i18n.t('support.problemsIncidents.modalForm.deleteSuccess'));
        } else {
          toast.success(response.message);
        }
        const index = files.findIndex((file) => file.name === fileName);
        if (index !== -1) {
          const newFiles = [...files];
          newFiles.splice(index, 1);
          setFiles(newFiles);
        }
      }
    } catch (error) {
      if (error.error.message === "Erro interno do servidor.") {
        toast.error(i18n.t('support.problemsIncidents.modalForm.internalError'))
      } else {
        toast.error(error.error.message)
      }
    }
  };

  const { errors, touched, getFieldProps, handleSubmit, setFieldError, values, setFieldTouched, setFieldValue } = formik;

  useEffect(() => {
    if (props.user?.role === "admin") {
      props.getCustomers(1, 5555);
    }
  }, [props.user]);

  useEffect(() => {
    setCustomerOptions((props.user?.role === "admin" ? props.customers : props.user?.customer) || []);
  }, [props.customers, props.user?.customer, props.user?.role]);

  useEffect(() => {
    if (props.isEdit) {
      setFiles(props.isEdit?.files);
      setPage("fill");
      setOpenModal(true);
    } else {
      setFiles([]);
      formik.resetForm();
    }
  }, [props.isEdit]);

  const handleOption = (option) => {
    switch (option) {
      case "Causa raiz não identificada": return i18n.t('support.problemsIncidents.modalForm.undefinedRootCause');
      case "Instalação do app": return i18n.t('support.problemsIncidents.modalForm.installApp');
      case "Atualização de tema": return i18n.t('support.problemsIncidents.modalForm.themeUpdate');
      case "Integração de sistema": return i18n.t('support.problemsIncidents.modalForm.systemIntegration');
      case "Fornecedor externo": return i18n.t('support.problemsIncidents.modalForm.externalSupplier');
      case "Instabilidade da plataforma": return i18n.t('support.problemsIncidents.modalForm.platformInstability');
      case "Página inicial": return i18n.t('support.problemsIncidents.modalForm.initialPage');
      case "Página de produto": return i18n.t('support.problemsIncidents.modalForm.productPage');
      case "Página de coleção": return i18n.t('support.problemsIncidents.modalForm.collectionPage');
      case "Carrinho": return i18n.t('support.problemsIncidents.modalForm.cart');
      case "Login ou Cadastro": return i18n.t('support.problemsIncidents.modalForm.loginRegister');
      case "Formulário": return i18n.t('support.problemsIncidents.modalForm.form');
      case "Promoção": return i18n.t('support.problemsIncidents.modalForm.promotion');
      case "Funcionalidade de App": return i18n.t('support.problemsIncidents.modalForm.appFunctionality');
      case "Integração de ERP": return i18n.t('support.problemsIncidents.modalForm.erpIntegration');
      case "Cálculo de frete": return i18n.t('support.problemsIncidents.modalForm.shippingCalculate');
      case "Erro de Analytics": return i18n.t('support.problemsIncidents.modalForm.analyticsError');
      case "Ferramenta de Marketing": return i18n.t('support.problemsIncidents.modalForm.marketingTool');
      case "Integração de Catálogo Facebook": return i18n.t('support.problemsIncidents.modalForm.fbCatalogIntegration');
      case "Integração de Catálogo Google": return i18n.t('support.problemsIncidents.modalForm.googleCatalogIntegration');
      case "Desktop Windows": return i18n.t('support.problemsIncidents.modalForm.desktopWindows');
      case "Desktop Mac": return i18n.t('support.problemsIncidents.modalForm.desktopMac');
      case "Desktop Linux": return i18n.t('support.problemsIncidents.modalForm.desktopLinux');
      case "Tablet Android": return i18n.t('support.problemsIncidents.modalForm.tabletAndroid');
      case "Tablet iOS": return i18n.t('support.problemsIncidents.modalForm.tabletIos');
      case "Mobile Android": return i18n.t('support.problemsIncidents.modalForm.mobileAndroid');
      case "Mobile iOS": return i18n.t('support.problemsIncidents.modalForm.mobileIos');
      case "Chrome": return "Chrome";
      case "Safari": return "Safari";
      case "Firefox": return "Firefox";
      case "Edge": return "Edge";
      case "Internet Explorer": return "Internet Explorer";
      case "Opera": return "Opera";
      case "Sim": return i18n.t('support.problemsIncidents.modalForm.yes');
      case "Não": return i18n.t('support.problemsIncidents.modalForm.no');
      case "Emergência": return i18n.t('support.problemsIncidents.modalForm.emergency');
      case "Crítica": return i18n.t('support.problemsIncidents.modalForm.critical');
      case "Alta": return i18n.t('support.problemsIncidents.modalForm.high');
      case "Normal": return i18n.t('support.problemsIncidents.modalForm.normal');
      case "Baixa": return i18n.t('support.problemsIncidents.modalForm.low');
      case "Sem Prioridade": return i18n.t('support.problemsIncidents.modalForm.noPriority')
      default: return i18n.t('support.problemsIncidents.modalForm.other')
    }
  }

  const handleLabel = (label) => {
    switch (label) {
      case "Causa Raiz": return i18n.t('support.problemsIncidents.modalForm.rootCause');
      case "Local do Problema": return i18n.t('support.problemsIncidents.modalForm.problemLocation');
      case "Dispositivo": return i18n.t('support.problemsIncidents.modalForm.dispositive');
      case "Navegador": return i18n.t('support.problemsIncidents.modalForm.navigator');
      case "Já reproduziu o erro?": return i18n.t('support.problemsIncidents.modalForm.reproducedError');
      case "Prioridade": return i18n.t('support.problemsIncidents.modalForm.priority');
      default: return "";
    }
  }

  const handleText = (text) => {
    switch (text) {
      case "Qual foi a ação ou qual é o sistema que causou o problema.": return i18n.t('support.problemsIncidents.modalForm.actionAndSystem');
      case "Qual equipamento que o problema foi identificado.": return i18n.t('support.problemsIncidents.modalForm.whichEquipment');
      case "Qual navegador que o problema foi identificado.": return i18n.t('support.problemsIncidents.modalForm.whichNavigator');
      default: return "";
    }
  }

  const handlePriorityTitle = (title) => {
    switch (title) {
      case "Emergência": return i18n.t('support.problemsIncidents.modalForm.emergency2');
      case "Crítica": return i18n.t('support.problemsIncidents.modalForm.critical2');
      case "Alta": return i18n.t('support.problemsIncidents.modalForm.high2');
      case "Normal": return i18n.t('support.problemsIncidents.modalForm.normal2');
      case "Baixa": return i18n.t('support.problemsIncidents.modalForm.low2');
      case "Sem Prioridade": return i18n.t('support.problemsIncidents.modalForm.noPriority2');
      default: return "";
    }
  }

  const handlePriorityDescription = (description) => {
    switch (description) {
      case "As vendas estão sendo impactadas com a solução deste item": return i18n.t('support.problemsIncidents.modalForm.emergencyDescription');
      case "As vendas podem ser impactadas com a solução deste item": return i18n.t('support.problemsIncidents.modalForm.criticalDescription');
      case "As vendas poderão ser levemente reprimidas até a solução deste item": return i18n.t('support.problemsIncidents.modalForm.highDescription');
      case "As vendas poderão melhorar com a resolução deste item": return i18n.t('support.problemsIncidents.modalForm.normalDescription');
      case "As vendas não serão impactadas com a solução deste item": return i18n.t('support.problemsIncidents.modalForm.lowDescription');
      case "A criticidade deste item não pode ser definida": return i18n.t('support.problemsIncidents.modalForm.noneDescription');
      default: return "";
    }
  }


  const baseDropdown = (options, label, field, text = "", required) => {
    return (
      <FCSelect
        required={required}
        label={handleLabel(label)}
        options={options.map(option => ({ value: option, label: handleOption(option) }))}
        {...getFieldProps(field)}
        fullWidth
        size={isMobile ? "small" : "medium"}
        error={Boolean(touched[field] && errors[field])}
        helperText={handleText(text)}
      />
    );
  };

  const showModal = () => {
    switch (page) {
      case "fill":
        return (
          <Modal
            size="lg"
            toggle={() => {
              setOpenModal(false);
              props.setIsEdit(null);
              props.setProblemModal && props.setProblemModal(false);
            }}
            isOpen={openModal}
          >
            <ModalBody>
              <Box display={"flex"} justifyContent={"space-between"}>
                <Typography>{i18n.t('support.problemsIncidents.modalForm.problemsIncidents')}</Typography>
                <Icon
                  sx={{
                    color: "#86888c",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setOpenModal(false);
                    props.setIsEdit(null);
                    props.setProblemModal && props.setProblemModal(false);
                  }}
                  className="material-icons-outlined"
                  fontSize="large"
                >
                  cancel
                </Icon>
              </Box>
              <Box
                display={"flex"}
                justifyContent={"center"}
                gap={"1.5rem"}
                flexDirection={"column"}
              >
                <Typography variant='h4'>{i18n.t('support.problemsIncidents.modalForm.problemDetails')}</Typography>
                <FCTextField
                  required
                  fullWidth
                  label={i18n.t('support.problemsIncidents.modalForm.title')}
                  {...getFieldProps("title")}
                  error={Boolean(touched.title && errors.title)}
                />
                {/* <TextField
                  required
                  fullWidth
                  label={i18n.t('support.problemsIncidents.modalForm.description')}
                  multiline
                  rows={4}
                  {...getFieldProps("description")}
                  error={Boolean(touched.description && errors.description)}
                  helperText={i18n.t('support.problemsIncidents.modalForm.helpToolAndClient')}
                /> */}
                <Box>
                  <ReactQuill
                    theme="snow"
                    style={{
                      border: `0.5px solid #888888`,
                      boxShadow: '0px 1px 0px 0px #00000040 inset',
                      borderRadius: '8px',
                    }}
                    value={values.description}
                    onChange={(html) => setFieldValue("description", html)}
                    className={Boolean(touched.description && errors.description) ? "ql-error" : null}
                    modules={modules}
                    bounds=".app"
                    formats={[
                      'header',
                      'bold', 'italic', 'underline', 'strike', 'blockquote',
                      'list', 'bullet', 'indent',
                      'link'
                    ]}
                    placeholder={i18n.t("sprint.newAction.pages.details.description") + "*"}
                    />
                  <FormHelperText 
                    sx={{ml: "14px"}} 
                    error={Boolean(touched.description && errors.description)}
                  >
                    {i18n.t('support.problemsIncidents.modalForm.helpToolAndClient')}
                  </FormHelperText>
                </Box>
                <Box>
                  <FileSelect />
                  {
                    files.map((file, index) => {
                      return (
                        <Chip
                          sx={{ marginLeft: '0.5rem', marginY: '0.5rem' }}
                          key={index}
                          label={file.name}
                          onClick={() => {
                            const url = file.location ? file.location : `${api}/files/problems/${props.isEdit?._id}/${file.id}`;
                            window.open(url, '_blank');
                          }}
                          onDelete={() => handleDelete(file.name, props.isEdit?._id)}
                        />
                      )
                    }
                    )}
                </Box>
                <Box display={"flex"} gap={"0.5rem"}>
                  {
                    baseDropdown(rootCause, "Causa Raiz", "rootCause", "Qual foi a ação ou qual é o sistema que causou o problema.", true)
                  }
                  {
                    baseDropdown(problemLocation, "Local do Problema", "problemLocation", "", true)
                  }
                </Box>
                {/* <TextField
                  required
                  fullWidth
                  label={i18n.t('support.problemsIncidents.modalForm.expectedResult')}
                  multiline
                  rows={4}
                  {...getFieldProps("expectedResult")}
                  error={Boolean(touched.expectedResult && errors.expectedResult)}
                  helperText={i18n.t('support.problemsIncidents.modalForm.helpActionAndSystem')}
                /> */}
                <Box>
                  <ReactQuill
                    theme="snow"
                    style={{
                      border: `0.5px solid #888888`,
                      boxShadow: '0px 1px 0px 0px #00000040 inset',
                      borderRadius: '8px',
                    }}
                    value={values.expectedResult}
                    onChange={(html) => setFieldValue("expectedResult", html)}
                    className={Boolean(touched.expectedResult && errors.expectedResult) ? "ql-error" : null}
                    modules={modules}
                    bounds=".app"
                    placeholder={i18n.t("support.problemsIncidents.modalForm.expectedResult") + "*"}
                    />
                  <FormHelperText 
                    sx={{ml: "14px"}} 
                    error={Boolean(touched.expectedResult && errors.expectedResult)}
                  >
                    {i18n.t('support.problemsIncidents.modalForm.helpActionAndSystem')}
                  </FormHelperText>
                </Box>
                <Box display={"flex"} gap={"0.5rem"}>
                  {
                    baseDropdown(devices, "Dispositivo", "device", "Qual equipamento que o problema foi identificado.", true)
                  }
                  {
                    baseDropdown(browsers, "Navegador", "browser", "Qual navegador que o problema foi identificado.", true)
                  }
                </Box>
                <Box display={"flex"} gap={"0.5rem"}>
                  {
                    baseDropdown(["Sim", "Não"], "Já reproduziu o erro?", "repeated", "", true)
                  }
                  {
                    baseDropdown(['Emergência', "Crítica", 'Alta', 'Normal', "Baixa", "Sem Prioridade"], "Prioridade", "priority")
                  }
                </Box>
                <Typography variant='h5'>{i18n.t('support.problemsIncidents.modalForm.priorities')}</Typography>
                <Box
                  display="flex"
                  padding="24px"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  gap="16px"
                  alignSelf="stretch"
                  borderRadius="4px"
                  background="#FFF"
                  boxShadow="0px 0px 4px 0px rgba(0, 0, 0, 0.15)"
                >
                  {
                    priorities.map((priority, index) => {
                      return (
                        <Box
                          width="100%"
                          display="flex"
                          gap="0.5rem"
                          justify-content="center"
                          align-items="center"
                          key={index}
                        >
                          <img style={{ height: "32px", width: "32px" }} src={priority.image} alt={priority.title} />
                          <Box>
                            <Typography fontSize={"16px"} fontWeight={700}>{handlePriorityTitle(priority.title)}</Typography>
                            <Typography fontSize={"14px"}>{handlePriorityDescription(priority.description)}</Typography>
                          </Box>
                        </Box>
                      )
                    })
                  }
                </Box>
              </Box>
              <Box
                display="flex"
                marginTop={"16px"}
                alignItems="flex-start"
                gap={"8px"}
                alignSelf="stretch"
              >
                <Button fullWidth variant="contained" className='btn-white fullwidth'
                  onClick={() => {
                    setOpenModal(false);
                    props.setIsEdit(null);
                    props.setProblemModal && props.setProblemModal(false);
                  }}
                >
                  {i18n.t('support.problemsIncidents.modalForm.cancel')}
                </Button>
                <Button
                  fullWidth
                  className="btn-action fullwidth"
                  variant="contained"
                  onClick={() => validate(values)}
                >
                  {i18n.t('support.problemsIncidents.modalForm.save')}
                </Button>
              </Box>
            </ModalBody>
          </Modal>
        )
      case "review":
        return (
          <ModalSummary
            formik={formik}
            problem={values}
            setIsEdit={props.setIsEdit}
            openModal={openModal}
            setOpenModal={setOpenModal}
            customer={customerOptions.find(cus => cus._id === formik.values.customer)?.name || ""}
            setPage={setPage}
            buttons={true}
          />
        )
      default: return null;
    }
  }

  const handleClick = () => {
    setPage("fill");
    props.setIsEdit(null);
    setOpenModal(true);
  }

  return (
    <FormikProvider value={formik}>
      <Form noValidate autoComplete="off" onSubmit={handleSubmit} style={{ margin: 0 }}>
        {
          props.alternativeVariant ?
            <Box 
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '0.5rem',
                padding: '0.5rem',
                cursor: 'pointer',
                justifyContent: 'space-between',
                color: '#000',
                '&:hover': {
                  backgroundColor: '#F3F3F3',
                },
              }}
              onClick={()=> {handleClick(); props.setProblemModal(true)}}
            >
              <Typography variant='body2' >{i18n.t('components.chat.chatInput.reportProblem')}</Typography>
              <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.90111 0H4.09889L0 4.09889V9.90111L4.09889 14H9.90111L14 9.90111V4.09889L9.90111 0ZM12.4444 9.25556L9.25556 12.4444H4.74444L1.55556 9.25556V4.74444L4.74444 1.55556H9.25556L12.4444 4.74444V9.25556Z" fill="#888888"/>
                <path d="M7 10.8889C7.42955 10.8889 7.77778 10.5407 7.77778 10.1111C7.77778 9.68156 7.42955 9.33333 7 9.33333C6.57045 9.33333 6.22222 9.68156 6.22222 10.1111C6.22222 10.5407 6.57045 10.8889 7 10.8889Z" fill="#888888"/>
                <path d="M6.22222 3.11111H7.77778V8.55556H6.22222V3.11111Z" fill="#888888"/>
              </svg>
            </Box>
            :
            <Button
              className='btn-action medium'
              variant='contained'
              onClick={handleClick}
            >
              <AddIcon /> {i18n.t('support.problemsIncidents.modalForm.new')}
            </Button>
        }

        {showModal()}
      </Form>
    </FormikProvider>

  )
}

const mapStateToProps = (state) => ({
  shop: state.auth.shop,
  user: state.auth.user,
  customers: state.customer?.customersSidebar?.docs || [],
});

export default connect(mapStateToProps, { ...actions, ...actionsCustomer })(ModalForm);