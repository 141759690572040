import React, { useEffect, useState } from 'react';
import * as insightsActions from '../../../store/actions/insights';
import * as customerActions from '../../../store/actions/customers';
import { connect } from 'react-redux';
import { Spinner } from 'reactstrap';
import { Button, Grid, Select, TextField, Card, CardContent, Typography, Stack, Tooltip, IconButton, Dialog } from '@mui/material';
import MultipleYAxis from '../../../components/Insights/MultipleYAxis';
import LevelTypes from '../../../components/Insights/LevelTypes';
import { isMobile } from 'react-device-detect';
import { i18n } from '../../../translate/i18n';
import { format } from 'date-fns';
import InfoIcon from '@mui/icons-material/Info';


const InventoryLevel = (props) => {
    const [productTypes, setProductTypes] = useState([]);
    const [loading, setLoading] = useState(true);
    const [loadingData, setLoadingData] = useState(false);
    const [location, setLocation] = useState('');
    const [option, setOption] = useState('type');
    const [product, setProduct] = useState('');
    const [startDate, setStartDate] = useState(new Date(new Date().getFullYear(), new Date().getMonth(), 1).toISOString().split('T')[0]);
    const [endDate, setEndDate] = useState(new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).toISOString().split('T')[0]);
    const [type, setType] = useState('');
    useEffect(() => {
        props.getViewCustomer(props.shop);
    }, [props.shop])

    useEffect(() => {
        if (props.customer) {
            props.fetchToken(props.customer.platformDomain)
        }
    }, [props.customer])

    useEffect(() => {
        if (props.token) {
            props.getProduct(props.token)
            props.getLocations(props.token)
            setLoading(false)
        }
    }, [props.token])

    useEffect(() => {
        if (props.products) {
            const aux = [];
            props.products.forEach(product => {
                if (!aux.includes(product.product_type)) {
                    if (product.product_type !== '') aux.push(product.product_type)
                }
            });
            setProductTypes(aux)
        }
    }, [props.products]);

    const search = async () => {
        setLoadingData(true)
        await props.getInventoryLevel(props.token, startDate, endDate, location, option, product, type)
        await props.getInventoryLevelTypes(props.token, startDate, endDate);
        setLoadingData(false)
    };

    if (loading) {
        return (
            <>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
                    <Spinner loading={loading} />
                </div>

            </>
        );
    }
    return (
        <div style={{ padding: isMobile ? '16px 16px 66px 16px' : '16px' }} >
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={2}>
                    <TextField value={startDate} onChange={e => setStartDate(e.target.value)} style={{ width: '100%' }} id="date" label={i18n.t('insight.startDate')} type="date" InputLabelProps={{ shrink: true }} />
                </Grid>
                <Grid item xs={12} sm={6} md={2}>
                    <TextField value={endDate} onChange={e => setEndDate(e.target.value)} style={{ width: '100%' }} id="date" label={i18n.t('insight.endDate')} type="date" InputLabelProps={{ shrink: true }} />
                </Grid>
                <Grid item xs={12} sm={6} md={2}>
                    <Select onChange={e => setLocation(e.target.value)} style={{ width: '100%' }} native>
                        <option value="">{i18n.t('insight.selectLocation')}</option>
                        {props.locations?.map((location, index) => (
                            <option key={index} value={location.id}>{location.name}</option>
                        ))}
                    </Select>
                </Grid>
                <Grid item xs={12} sm={6} md={2}>
                    <Select onChange={e => setOption(e.target.value)} style={{ width: '100%' }} native>
                        <option value="type">{i18n.t('insight.type')}</option>
                        <option value="product">{i18n.t('insight.product')}</option>
                    </Select>
                </Grid>
                {option === 'product' &&
                    <Grid item xs={12} sm={6} md={2}>
                        <Select onChange={e => setProduct(e.target.value)} style={{ width: '100%' }} native>
                            <option value="">{i18n.t('insight.selectProduct')}</option>
                            {props.products?.map((p, index) => (
                                <option key={index} value={p.variant_id}>{p.title} - {p.sku}</option>
                            ))}
                        </Select>
                    </Grid>}
                {option === 'type' &&
                    <Grid item xs={12} sm={6} md={2}>
                        <Select onChange={e => setType(e.target.value)} style={{ width: '100%' }} native>
                            <option value="">{i18n.t('insight.selectTypeProduct')}</option>
                            {productTypes?.map((product, index) => (
                                <option key={index} value={product}>{product}</option>
                            ))}
                        </Select>
                    </Grid>
                }
                <Grid item xs={12} sm={6} md={2}>
                    <Button fullWidth onClick={() => search()} variant="contained" color="primary">{i18n.t('insight.search')}</Button>
                </Grid>
            </Grid>
            {loadingData ? <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
                <Spinner loading={loadingData} />
            </div> :
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12}>
                        {props.inventoryLevel?.expected && <>
                            <Stack direction={'row'} justifyContent="space-evenly" alignItems="start" spacing={2} mt={2} mb={2}>
                                <Card style={{
                                    width: '300px', color: 'white', backgroundColor: props.inventoryLevel?.expected.expected_days <= 30 ? '#e74c3c' : props.inventoryLevel?.expected.expected_days <= 60 ? '#f1c40f' : '#2ecc71',
                                }}>
                                    <CardContent>
                                        <Typography>
                                            <b>{i18n.t('insight.daysPredict')}</b>
                                        </Typography>
                                        <Typography variant="body2">
                                            {props.inventoryLevel?.expected.expected_days} {props.inventoryLevel?.expected.expected_days !== 1 ? i18n.t('insight.days') : i18n.t('insight.day')} - {format(new Date(props.inventoryLevel?.expected.expected_date), 'dd/MM/yyyy')}
                                        </Typography>
                                    </CardContent>
                                </Card>
                                <Card style={{
                                    width: '300px'
                                }}>
                                    <CardContent>
                                        <Typography>
                                            <b> {i18n.t('insight.media')}</b>
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary">
                                            {props.inventoryLevel?.expected.media_day} {i18n.t('insight.perDay')}
                                        </Typography>
                                    </CardContent>
                                </Card>

                                <div style={{ position: 'relative' }}>
                                    <Card style={{
                                        width: '300px', color: 'white', backgroundColor: props.inventoryLevel?.expected.curve >= 200 || props.inventoryLevel?.expected.curve < 50 ? '#e74c3c' : props.inventoryLevel?.expected.curve >= 150 || props.inventoryLevel?.expected.curve < 50 ? '#f1c40f' : '#2ecc71',
                                    }}>
                                        <CardContent>
                                            <Typography>
                                                <b> {i18n.t('insight.curve')}</b>
                                            </Typography>
                                            <Typography variant="body2">
                                                {props.inventoryLevel?.expected.curve} %
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                    <Tooltip title={<>Menor que 100%: - venda + estoque.<br />Maior que 100%: + venda - estoque.</>}>
                                        <IconButton
                                            aria-label="Informações"
                                            style={{ position: 'absolute', top: 0, right: 0 }}
                                        >
                                            <InfoIcon />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                            </Stack>

                        </>}
                        {props.inventoryLevel?.inventory?.length > 0 && <MultipleYAxis inventory={props.inventoryLevel?.inventory} total={props.inventoryLevel?.total} />}

                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        {props.inventoryLevelTypes?.dates?.length > 0 && <LevelTypes inventory={props.inventoryLevelTypes?.inventory} dates={props.inventoryLevelTypes?.dates} />}

                    </Grid>
                </Grid>
            }
        </div>
    );

};


const mapStateToProps = (state) => ({
    shop: state.auth.shop,
    customer: state.customer.customerDetails,
    token: state.insight.tokenApi,
    products: state.insight.products,
    locations: state.insight.locations,
    inventoryLevel: state.insight.inventoryLevel,
    inventoryLevelTypes: state.insight.inventoryLevelTypes,
})

export default connect(mapStateToProps, { ...insightsActions, ...customerActions })(InventoryLevel);

