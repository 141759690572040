import React, { useState } from 'react';
import { Typography, Box, Collapse, List, ListItem, ListItemIcon, ListItemText, Avatar, IconButton, useMediaQuery } from '@mui/material';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import StoreOutlinedIcon from '@mui/icons-material/StoreOutlined';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import BackupTableIcon from '@mui/icons-material/BackupTable';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import SettingsIcon from '@mui/icons-material/Settings';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import ViewTimelineIcon from '@mui/icons-material/ViewTimeline';
import LogoutIcon from '@mui/icons-material/Logout';
import { useAuth0 } from '@auth0/auth0-react';
import { i18n } from '../../translate/i18n';

import LocationCityIcon from '@mui/icons-material/LocationCity';

const Admin = ({ user, MenuLink, handleLogout }) => {
    const { logout } = useAuth0();
    const [isOpenConfig, setIsOpenConfig] = useState(false);
    const [isOpenUser, setIsOpenUser] = useState(false);
    const isMobileDevice = useMediaQuery('(max-width:600px)');


    const handleToggleCollapseConfig = () => {
        setIsOpenConfig(!isOpenConfig);
        setIsOpenUser(false);
    };

    const handleToggleCollapseUser = () => {
        setIsOpenUser(!isOpenUser);
        setIsOpenConfig(false);
    };
    return (
        <Box style={{ backgroundColor: '#2B2B2B', paddingBottom: isMobileDevice ? "15px" : 0 }} >
            {/* <IconButton onClick={handleToggleCollapseConfig}>
                <SettingsIcon style={{ color: 'white', width: '35px', height: '35px' }} />
            </IconButton>
            <IconButton onClick={handleToggleCollapseUser}>
                <Avatar style={{ width: '35px', height: '35px' }} src={user?.url} />
            </IconButton> */}
            <Typography onClick={handleToggleCollapseConfig} variant="h6" sx={{ cursor: 'pointer', color: 'white', fontSize: '14px', fontWeight: 'bold', marginTop: '10px', marginBottom: '10px' }}>
                {i18n.t('components.sideBar.admin.settings')}
            </Typography>
            <Collapse in={isOpenConfig}>
                <List component="nav">
                    {user?.role === 'admin' && (
                        <>
                            {user?.permissionAdmin?.includes('view_user_admin') && <MenuLink to="/user" Icon={GroupOutlinedIcon}>{i18n.t('components.sideBar.admin.usersAdmin')}</MenuLink>}
                            {user?.permissionAdmin?.includes('view_customer') && <MenuLink to="/customer" Icon={StoreOutlinedIcon}>{i18n.t('components.sideBar.admin.stores')}</MenuLink>}
                            {user?.permissionAdmin?.includes('view_agency') && <MenuLink to="/agencies" Icon={LocationCityIcon}>{i18n.t('components.sideBar.admin.agencies')}</MenuLink>}
                            {user?.permissionAdmin?.includes('view_contract') && <MenuLink to="/contracts" Icon={DocumentScannerIcon}>{i18n.t('components.sideBar.admin.contracts')}</MenuLink>}
                            {user?.permissionAdmin?.includes('view_activity_table') && <MenuLink to="/tables" Icon={BackupTableIcon}>{i18n.t('components.sideBar.admin.tableActivitiesAdmin')}</MenuLink>}
                            {<MenuLink to="/advanced-roadmap" Icon={ViewTimelineIcon}>{i18n.t('components.sideBar.admin.projects')}</MenuLink>}
                            {<MenuLink to="/costs" Icon={AttachMoneyIcon}>{i18n.t('components.sideBar.admin.costs')}</MenuLink>}

                        </>
                    )}

                    {user?.role === 'customer' && (
                        <>
                            {user?.permissionCustomer?.find(e => e.customer === localStorage.getItem('shop'))?.actions?.includes('view_user') && <MenuLink to={`/user/customer/${localStorage.getItem('shop')}`} Icon={GroupOutlinedIcon}>{i18n.t('components.sideBar.admin.usersCustomer')}</MenuLink>}
                            {user?.permissionCustomer?.find(e => e.customer === localStorage.getItem('shop'))?.actions?.includes('view_activity_table') && <MenuLink to="/table" Icon={BackupTableIcon}>{i18n.t('components.sideBar.admin.tableActivitiesCustomer')}</MenuLink>}


                        </>

                    )}
                    {
                        user?.role === 'agency' &&
                        <>
                            {user?.permissionCustomer?.find(e => e.customer === localStorage.getItem('shop'))?.actions?.includes('view_agency') && <MenuLink to={`/agency/${user.agency?._id}`} Icon={LocationCityIcon}>{i18n.t('components.sideBar.admin.agency')}</MenuLink>}
                            <MenuLink to={`/user/customer/${localStorage.getItem('shop')}`} Icon={GroupOutlinedIcon}>{i18n.t('components.sideBar.admin.usersAgency')}</MenuLink>
                            <MenuLink to="/table" Icon={BackupTableIcon}>{i18n.t('components.sideBar.admin.tableActivitiesAgency')}</MenuLink>
                        </>
                    }
                    <MenuLink to="/perfil" Icon={GroupOutlinedIcon}>{i18n.t('components.sideBar.admin.perfil')}</MenuLink>
                    <MenuLink Icon={LogoutIcon} onClick={() => {
                        handleLogout();
                        logout();
                    }}>{i18n.t('components.sideBar.admin.logout')}</MenuLink>
                </List>
                {/* </Collapse> */}

                {/* <Collapse in={isOpenUser}> */}
                {/* <List component="nav">
                    <MenuLink to="/perfil" Icon={GroupOutlinedIcon}>{i18n.t('components.sideBar.admin.perfil')}</MenuLink>
                    <MenuLink Icon={LogoutIcon} onClick={() => {
                        handleLogout();
                        logout();
                    }}>{i18n.t('components.sideBar.admin.logout')}</MenuLink>
                </List> */}
            </Collapse>
        </Box>
    );
};

export default Admin;
