import { i18n } from "../translate/i18n";
const node_env = process.env.REACT_APP_ENV


export const api = (() => {
    switch (node_env) {
        case "production":
            return "https://api.fullcomm.app";
        case "homologation":
            return "https://api-beta.checkstore.app";
        default:
            return "http://localhost:8080";
    }
})();
export const repository = (() => {
    switch (node_env) {
        case "production":
            return "https://api.fullcomm.app";
        case "homologation":
            return "https://api-beta.checkstore.app";
        default:
            return "http://localhost:8080";
    }
})();
export const appKeyOneSignal = (() => {
    switch (node_env) {
        case "production":
            return "24abc762-46b1-43ef-963d-8abcb84ae266";
        case "homologation":
            return "4e1616b0-7e07-45f1-bcf6-3b5114be7239";
        default:
            return "393082eb-4190-41a3-b352-017953fd43e5";
    }
})();

export const urlAuth0 = (() => {
    switch (node_env) {
        case "production":
            return "login.fullcomm.app";
        case "homologation":
            return "checkstore-beta.us.auth0.com";
        default:
            return "checkstore-development.us.auth0.com";
    }
})();
export const clientIdAuth0 = (() => {
    switch (node_env) {
        case "production":
            return "0xtUoMFRiSiTEihYMKey90twtVp43SYW";
        case "homologation":
            return "6C42M1tXJYt8Fth38yN1ZLiaVeCOEPA9";
        default:
            return "6RgVmM2AuQBpmidmg86YhaUoVjGXDQ7s";
    }
})();

export const apiLogistics = (() => {
    switch (node_env) {
        case "production":
            return "https://apilogistica.checkstore.app";
        default:
            return "http://localhost:8000";
    }
})();

export const apiSmartShipping = 'https://contingencia.checkstore.com.br';

export const apiMetrics = (() => {
    switch (node_env) {
        case "production":
        case "homologation":
            return 'https://api-analytics.fullcomm.app';
        default:
            return 'http://localhost:8000';
    }
})();

export const productFruits = (() => {
    switch (node_env) {
        case "production":
        case "homologation":
            return 'vgnDxJafmtsDPucj';
        default:
            return null;
    }
})();

export const keyCrypto = 'e7b14fb9a2408d6b457e024630106263';

export const apiPlatinumService = (() => {
    switch (node_env) {
        case "production":
            return "https://platinum.fullcomm.app";
        default:
            return "http://localhost:8888";
    }
})();

/* Roles */
export const roles = new Map();
roles.set("admin", "Administrador");
roles.set("customer", "Cliente");
roles.set("agency", "Agência");

/* Parents */
export const parents = new Map();
parents.set("scale", i18n.t('config.ecommerceOperation'));
parents.set("strike", i18n.t('config.platformImplantation'));
parents.set("sprint", i18n.t('config.ecommecerManagement'));
parents.set("suporte", i18n.t('config.support'));