import { Avatar, Icon, Tooltip, Typography, useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { statusColor } from "../utils/statusColor";
import { api } from "../../../config";
import { handleChangeCategory, handleChangeName } from "../../../translate/translates/Translates";
import favicon from "../../../assets/favicon.png";
import { i18n } from "../../../translate/i18n";
import { FCChip } from "components-fullcomm";

const ActivityCard = ({ info, status, responsible, section, repeat, userResponsible, reproved, customer }) => {
  const { bgcolor } = statusColor(status);
  status = reproved && status === "Em andamento" ? "Reprovado" : status;
  const isMobileDevice = useMediaQuery('(max-width:1500px)');
  const amount = repeat > 1 ? `${repeat}x` : ``
  const { type, icon } = statusColor(status);
  const userImg = responsible === "FullPRO" ? favicon : userResponsible ? userResponsible.fileLocation ? userResponsible.fileLocation : `${api}/files/users/${userResponsible.fileName}` : customer.fileLocation ? customer.fileLocation : `${api}/files/customers/${customer?.fileName}`

  return (
    <Box
      key={info?._id}
      sx={{
        minHeight: "4rem",
        padding: "1rem",
        paddingX: "3%",
        backgroundColor: "white",
        borderRadius: "5px",
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        gap: 1,
        borderBottom: `solid 3px ${bgcolor}`,
        transition: "all 0.2s ease-in-out",
        "&:hover": {
          cursor: "pointer",
          backgroundColor: "#eee",
        },
      }}
    >
      <Box display={"flex"} flexDirection={"column"} gap={isMobileDevice ? "0.5rem" : 0} justifyContent="space-between">
        {status === "Reprovado" && (
          <FCChip
            color={type}
            size={"small"}
            label={i18n.t("sprint.kanban.reproved")}
            sx={{width: "fit-content", mb: 1}}
            icon={
              <Icon
                className="material-icons-outlined"
                style={{
                  fontSize: "10px",
                }}
              >
                {icon}
              </Icon>
            }
          />
        )}
        <Typography><b>{amount}</b> {handleChangeName(info?.name)}</Typography>
      </Box>
        <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"} width={"100%"} position={"relative"}>
          <Typography variant={"caption"}>{handleChangeCategory(info.category)}</Typography>
          {
            userImg && (
              <Tooltip title={responsible === "FullPRO" ? responsible : userResponsible?.name ? userResponsible?.name : customer?.name}>
                <Avatar sx={{height: "24px", width: "24px", position: "absolute", right: 0}} src={userImg} />
              </Tooltip>
            )
          }
          <Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ActivityCard;
