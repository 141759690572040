import { Box, Button, Grid, Paper, TextField, Typography, Link, CircularProgress, Chip, Modal, IconButton, Tooltip, CardActionArea } from "@mui/material";
import React, { useCallback, useEffect, useRef, useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { connect } from "react-redux";
import { toast } from "react-toastify";
import "../../../styles/dashboard/sprint/index.css";
import { api } from "../../../config";
import * as actions from "../../../store/actions/action";
import * as actions2 from "../../../store/actions/problems";
import * as actions3 from "../../../store/actions/messages";
import ImageEditor from "./ImageEditor";
import { isMobile } from 'react-device-detect';
import { i18n } from '../../../translate/i18n';
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { handleChangeName } from "../../../translate/translates/Translates";
import Message from "../../../components/Chat/Message";
import AspectRatioIcon from '@mui/icons-material/AspectRatio';

export const makeUrlsClickable = (data, activity, action) => {
  const text = activity === "645ad33b66ad3f1f5ddcfe6d" ? `${action}\n${data}` : data;
  const urlRegex = /(\[([^\]]+)\|([^\]]+)\])|(https?:\/\/[^\s]+)/g;
  return text.replace(urlRegex, (match, _1, url, displayText) => {
    if (url) {
      return `<a href="${url}" target="_blank" rel="noopener noreferrer">${displayText || url}</a>`;
    } else {
      return `<a href="${match}" target="_blank" rel="noopener noreferrer">${match}</a>`;
    }
  });
};

const ActivityApproval = (props) => {
  const [comment, setComment] = useState("");
  const { activity, title } = props.currentPageInfo;
  const isPerformance = !activity?.name;
  const [annotations, setAnnotations] = useState([]);
  const [attachmentsJira, setAttachmentsJira] = useState(props.currentPageInfo.attachmentsJira || []);
  const [index, setIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const userId = props.user?._id;
  const fileInputRef = useRef(null);
  const [attachedFiles, setAttachedFiles] = useState([]);
  const [viewImage, setViewImage] = useState(null);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [scale, setScale] = useState(1);
  const [imageLoaded, setImageLoaded] = useState(false);
  const imageRef = useRef(null);

  const handleZoomIn = useCallback(() => {
    setScale((prev) => prev + 0.1);
  }, []);

  const handleZoomOut = useCallback(() => {
    setScale((prev) => prev - 0.1 > 0 ? prev - 0.1 : 0.1);
  }, []);

  const handleFileInputClick = () => {
    fileInputRef.current.click();
  };

  const handleFileInputChange = (e) => {
    const files = e.target.files;
    setAttachedFiles((prevFiles) => [...prevFiles, ...files]);
    e.target.value = "";
  };

  const handleFileRemove = (file) => {
    setAttachedFiles((prevFiles) => prevFiles.filter((f) => f !== file));
  };

  const handleApproval = async (status) => {
    if (status === "Em andamento" && !comment) return toast.error(i18n.t('sprint.kanban.activityApproval.addComentReprove'));
    if (status === "Concluído/Aplicado" && (comment || annotations.length > 0 || attachedFiles.length > 0)) return toast.error(i18n.t('sprint.kanban.activityApproval.confirmApprove'));

    setLoading(true);

    try {
      let attachmentComment = "";
      const formData = new FormData();
      if (attachedFiles.length > 0) {
        if (attachedFiles) {
          for (let i = 0; i < attachedFiles.length; i++) {
            formData.append("file", attachedFiles[i]);
            attachmentComment += `${attachedFiles[i].name} `;
          }
        }
      }
      if (attachmentComment) attachmentComment = `${comment}\n\nAnexos: ${attachmentComment}`;
      const response = await (isPerformance ? props.problemApproveReprove(props.currentPageInfo._id, { status, comment: attachmentComment ? attachmentComment : comment, userId, attachmentsJira }) : props.putActionActivity(props.currentPageInfo.action._id, props.currentPageInfo._id, { status, comment: attachmentComment ? attachmentComment : comment, userId, attachmentsJira }));
      isPerformance ? props.uploadProblemFile(props.currentPageInfo._id, formData) : props.uploadActivitiesFile(props.currentPageInfo._id, formData);
      setLoading(false);
      if (!response.error) {
        if (response.message === "Problema atualizado com sucesso!") {
          toast.success(i18n.t('sprint.kanban.activityApproval.updateProblemSuccess'));
        } else if (response.message === "Atividade atualizada com sucesso!") {
          toast.success(i18n.t('sprint.kanban.activityApproval.updateActivitySuccess'));
         } else{
            toast.success(response.message);
          }
        props.setCurrentPageInfo({ page: "Kanban" });
      } else {
        switch (response?.error?.message) {
          case "Erro ao atualizar o problema.": return toast.error(i18n.t('sprint.kanban.activityApproval.updateProblemError'));
          case "Erro ao atualizar a atividade.": return toast.error(i18n.t('sprint.kanban.activityApproval.updateActivityError'));
          case "Erro interno do servidor.": return toast.error(i18n.t('sprint.kanban.activityApproval.internalError'));
          default: return toast.error(response?.error?.message)
        }
      }
    } catch (error) {
      setLoading(false);
      if (error.error.message === "Erro interno do servidor.") {
        toast.error(i18n.t('sprint.kanban.activityApproval.internalError'));
      } else {
        toast.error(error.error.message);
      }
    }

  };

  useEffect(() => {
    if (annotations.length > 0) {
      setAttachmentsJira(prev => {
        const newAttachments = [...prev];
        newAttachments[index] = { ...newAttachments[index], annotations };
        return newAttachments;
      })
    }
  }, [annotations]);

  useEffect(() => {
    setImageLoaded(prev => !prev);
  }, [viewImage]);

  useEffect(() => {
    const image = imageRef.current
    let isDragging = false;
    let previousPosition = { x: 0, y: 0 };

    const handleMouseDown = (e) => {
      isDragging = true;
      previousPosition = {
        x: e.clientX,
        y: e.clientY,
      };
    };

    const handleMouseMove = (e) => {
      if (!isDragging) return;
      
      const dx = e.clientX - previousPosition.x;
      const dy = e.clientY - previousPosition.y;
      previousPosition = {
        x: e.clientX,
        y: e.clientY,
      };
      setPosition((prev) => ({
        x: prev.x + dx,
        y: prev.y + dy,
      }));
    };

    const handleMouseUp = () => {
      isDragging = false;
    };

    if (!image) return;

    image.addEventListener("mousedown", handleMouseDown);
    image.addEventListener("mousemove", handleMouseMove);
    image.addEventListener("mouseup", handleMouseUp);

    return () => {
      image.removeEventListener("mousedown", handleMouseDown);
      image.removeEventListener("mousemove", handleMouseMove);
      image.removeEventListener("mouseup", handleMouseUp);
    };
  }, [imageRef, scale, imageLoaded]);
      

  useEffect(() => {
    const customer = props.currentPageInfo?.action?.customerId || props.currentPageInfo?.customer?._id;
    props.getMessages({ platform: "action", customer, activity: props.currentPageInfo._id });
  }, [props.currentPageInfo]);

  return (
    <Box>
      <Button
        className="btn-default"
        onClick={() => props.setCurrentPageInfo({ page: "Kanban" })}
      >
        <ArrowBackIcon fontSize="small" />
        {i18n.t('sprint.kanban.activityApproval.back')}
      </Button>
      <Grid container columnSpacing={5} marginTop={"1rem"}>
        <Grid item xs={12} md={8}>
          <Paper
            sx={{
              paddingY: "1.5rem",
              paddingX: "2.5rem",
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
            }}
          >
            <Typography variant="h4">
              {handleChangeName(activity?.name) || title}
            </Typography>
            <Typography>
              <div style={{ font: 'inherit', whiteSpace: "pre-wrap", wordWrap: "break-word" }} dangerouslySetInnerHTML={{ __html: makeUrlsClickable(props.currentPageInfo?.commentJira || '', activity?._id, props.currentPageInfo?.action?.name) }}></div>
            </Typography>
            <Modal open={viewImage !== null} onClose={()=> setViewImage(null)}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%', 
                }}
              >
                <Box position='relative'>
                  <Box
                    sx={{
                      display: 'flex',
                      background: "#fff",
                      width: '2.5rem',
                      borderRadius: '8px',
                      flexDirection: 'column',
                      border: '1px solid #888888',
                      marginBottom: '1rem',
                      position: 'fixed',
                      left: '0.5rem',
                      top: '1rem',
                      zIndex: 999,
                    }}
                  >
                    <CardActionArea
                      sx={{
                        padding: '0.5rem',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                      onClick={()=> setViewImage(null)}
                    >
                      <span style={{ color: '#888888'}} className="material-icons">close</span>
                    </CardActionArea>
                    <CardActionArea
                      sx={{
                        padding: '0.5rem',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                      onClick={handleZoomIn}
                    >
                      <span style={{ color: '#888888'}} className="material-icons">zoom_in</span>
                    </CardActionArea>
                    <CardActionArea
                      sx={{
                        padding: '0.5rem',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                      onClick={handleZoomOut}
                    >
                      <span style={{ color: '#888888'}} className="material-icons">zoom_out</span>
                    </CardActionArea>
                  </Box>
                  <img
                    ref={imageRef}
                    src={viewImage}
                    alt="Imagem"
                    draggable={false}
                    style={{ maxWidth: '100%', overflow: 'auto', display: 'block', margin: 'auto', transform: `scale(${scale})`, cursor: "move", left: position.x, top: position.y, position: 'relative'}}
                  />
                </Box>
              </Box>
            </Modal>
            {attachmentsJira.length > 0 && <b>{i18n.t('sprint.kanban.activityApproval.attachments')}</b>}
            {attachmentsJira.length > 0 &&
              attachmentsJira.map((attachment, i) => {
                return (
                  <div key={attachment.id} style={{ position: 'relative' }}>
                    {attachment.mimeType?.startsWith('image/') ? (
                        <ImageEditor
                          setIndex={setIndex}
                          index={i}
                          alt={attachment.filename}
                          setAnnotations={setAnnotations}
                          imageUrl={attachment.location ? attachment.location : `${api}/files/jira/${attachment.id}.${attachment.mimeType.split('/')[1]}`}
                        />
                    ) : (
                      <Link href={attachment.location ? attachment.location : `${api}/files/jira/${attachment.id}.${attachment.mimeType?.split('/')[1]}`} target="_blank" rel="noopener noreferrer">
                        {attachment.location ? attachment.location : `${api}/files/jira/${attachment.id}.${attachment.mimeType?.split('/')[1]}`}
                        <InsertDriveFileIcon fontSize="small" />
                      </Link>
                    )}
                    <Tooltip
                      title={i18n.t('sprint.kanban.activityApproval.expandImage')}
                    >
                      <IconButton
                        sx={{
                          position: 'absolute',
                          right: '0.5rem',
                          top: '0.5rem',
                          fontSize: '1rem',
                        }}
                        onClick={() => {
                          setViewImage(attachment.location ? attachment.location : `${api}/files/jira/${attachment.id}.${attachment.mimeType?.split('/')[1]}`);
                        }}
                      >
                        <AspectRatioIcon sx={{ color: '#888888' }}/>
                      </IconButton>
                    </Tooltip>
                  </div>
                )
              })}
          </Paper>
        </Grid>
        <Grid item xs={12} md={4} mb={isMobile ? "5rem" : 0} mt={isMobile ? "1.5rem" : 0}>
          <Paper
            sx={{
              padding: "1.5rem",
            }}
          >
            <TextField
              onChange={(e) => setComment(e.target.value)}
              label={i18n.t('sprint.kanban.activityApproval.addComment')}
              fullWidth
              multiline
              rows={4}
            />
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
                gap: 1,
              }}
            >
              <Button style={{color: '#5E6469', border: '1px solid #C9CCCF', marginTop: '0.5rem',}} onClick={handleFileInputClick}>
                <AttachFileIcon style={{width: '20px'}} />
                <input
                  ref={fileInputRef}
                  type="file"
                  multiple
                  style={{ display: "none" }}
                  onChange={handleFileInputChange}
                  />
              </Button>
              {attachedFiles.map((file) => (
                <Chip
                  key={file.name}
                  label={file.name}
                  onDelete={() => handleFileRemove(file)}
                />
              ))}
            </Box>
          </Paper>
          <Box display={"flex"} gap={1} marginTop={"1rem"}>
            <Button
              onClick={() => { return loading ? null : handleApproval("Concluído/Aplicado") }}
              className="btn-action fullwidth"
              variant="contained"
            >
              {
                loading ? (
                  <CircularProgress sx={{ color: "white" }} size={24} />
                ) : i18n.t('sprint.kanban.activityApproval.approve')
              }
            </Button>
            <Button
              onClick={() => { return loading ? null : handleApproval("Em andamento") }}
              className="btn-reprove fullwidth"
              variant="contained"
            >
              {
                loading ? (
                  <CircularProgress sx={{ color: "white" }} size={24} />
                ) : i18n.t('sprint.kanban.activityApproval.reprove')
              }
            </Button>
          </Box>
          {
            props.messages.length > 0 && (
              <Paper sx={{ mt: "1rem", padding: "0.5rem" }}>
                <Typography variant="h4" sx={{ padding: '0.5rem'}}>{i18n.t('sprint.kanban.activityApproval.comments')}:</Typography>
                {
                  props.messages?.map((message, index) => (
                    <Message
                      key={message._id} 
                      index={index} 
                      message={message} 
                      user={props.user} 
                    />
                    )
                  )
                }
              </Paper>
            )
          }
        </Grid>
      </Grid>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  messages: state.message.messages || [],
});
export default connect(mapStateToProps, { ...actions, ...actions2, ...actions3 })(ActivityApproval);
