import React, {useEffect, useState} from 'react';
import { getHeaders } from '../../../store/actions/localStorage'
import { connect } from 'react-redux';
import axios from 'axios';
import { Grid, Typography, Table, TableHead, TableRow, TableBody, TableCell} from '@mui/material';
import { api } from '../../../config';
import CardProcess from '../../../components/CardProcess'
import { i18n } from '../../../translate/i18n';

const styles = {
  nãoenviado: '#ffebee',
  sobanálise: '#fff9c4',
  nãoaprovado: '#ffcdd2',
  aprovado:  '#c8e6c9',
  nãoseaplica: '#f5f5f5',
  publicada: 'green'
};

const Checklist = (props) => {
  const [data, setData] = useState({});
  const fetchData = async () => {
    try {
      if (props.user?.role === 'customer') {
        const res = await axios.get(`${api}/checklist/${localStorage.getItem("shop")}`, getHeaders());
        setData(res.data);
      }
    } catch (err) {
      console.log(err);
    }
  }
  
  useEffect(() => {
    fetchData();
  }, [props.user]);
  return (
    <div>
      <Typography variant="h1">{i18n.t('start.checklist.checklistProgress')}</Typography>
      <CardProcess color="green" name={i18n.t('start.checklist.generalProgress')} value={90} />
      <br />
      <br />
      <Typography variant="h1">{i18n.t('start.checklist.checklistStatus')}</Typography>
      {props.user?.role === 'customer' && data &&
      <Grid container spacing={4}>
        {Object.entries(data).slice(1, 13)?.map((key) => {
          const value = key[1];
          key = key[0];
          return (
            <Grid item xs={12} sm={6} md={6} key={key}>
              <div>
                <CardProcess color={styles[value.toLowerCase().replace(/ /g, '')]} name={key.replace(/([A-Z])/g, ' $1').toUpperCase()} value={value} />
              </div>
            </Grid>
          );
        })}
      </Grid>
      }
    </div>
  );
};

const mapStateToProps = state =>
({
    user: state.auth.user,
    shop: state.auth.shop,
})

const mapDispatchToProps = (dispatch) => {};

export default connect(mapStateToProps, mapDispatchToProps)(Checklist);
