import {
  Box,
  Icon,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import React from "react";
import { useHistory } from "react-router-dom";
import { userPermissionCheck } from "../utils/userPermissionCheck";
import { i18n } from "../../../translate/i18n";

const OptionsButton = ({ i, action, toggleModal, user }) => {
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEdit = (data) => {
    history.push(`/edit-action/${data._id}`);
  };

  return (
    <>
      {
        (userPermissionCheck(user, ["view_action", "delete_action"]) || action.keyJira) && (
          <Box
            border={"1px solid #babfc3"}
            borderRadius={"5px"}
            height={"2rem"}
            width={"2rem"}
          >
            <Box position={"relative"}>
              <IconButton
                onClick={handleClick}
                sx={{
                  borderRadius: "5px",
                  width: "100%",
                  height: "2rem",
                }}
              >
                <Icon className="material-icons-outlined">more_vert</Icon>
              </IconButton>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                {
                  userPermissionCheck(user, ["view_action"]) &&
                  <MenuItem onClick={(e) => handleEdit(action)}
                  >
                    {
                      userPermissionCheck(user, ["edit_action"]) ? (
                        i18n.t('sprint.list.optionsButton.edit')
                      ) : (
                        i18n.t('sprint.list.optionsButton.view')
                      )
                    }
                  </MenuItem>
                }
                {
                  userPermissionCheck(user, ["delete_action"]) &&
                  <MenuItem onClick={(e) => toggleModal(action._id, "delete")}
                  >
                    {i18n.t('sprint.list.optionsButton.delete')}
                  </MenuItem>
                }
                {
                  userPermissionCheck(user, ["edit_action"], "admin") && (action.keyJira || action.status === "Planejamento") &&
                  <MenuItem onClick={(e) => toggleModal(action._id, "jira")}
                  >
                    {i18n.t('sprint.list.optionsButton.integration')}
                  </MenuItem>
                }
                {
                  action.keyJira && user?.role === "admin" &&
                  <MenuItem onClick={(e) => window.open(`https://checkstore.atlassian.net/browse/${action.keyJira}`, '_blank')}>
                    {i18n.t('sprint.list.optionsButton.jira')}
                  </MenuItem>
                }
              </Menu>
            </Box>
          </Box>
        )
      }
    </>
  );
};

export default OptionsButton;
