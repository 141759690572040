import { useEffect, useState } from "react";
import { roles } from "../../config";
import { connect } from 'react-redux'
import * as Yup from 'yup';
import { Form, FormikProvider, useFormik } from 'formik';
import { toast } from 'react-toastify';

import { getViewUser, handleAddUser, handleUpdateUser } from '../../store/actions/users'
import { getCustomers } from '../../store/actions/customers'
import { LoadingButton } from '@mui/lab';
import { Box, Grid, Stack, Typography, Button, Grow } from '@mui/material';
import { getAgencies } from "../../store/actions/agencies";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { userPermissionCheck } from "../Sprint/utils/userPermissionCheck";
import { i18n } from '../../translate/i18n';
import { isMobile } from 'react-device-detect';
import { FCAutocomplete, FCCheckbox, FCReturnButton, FCSelect, FCTelInput, FCTextField } from "components-fullcomm";

const AddUser = (props) => {
  const history = useHistory();
  const [isEdit, setIsEdit] = useState(false);
  const [user, setUser] = useState({});
  const [spec, setSpec] = useState([]);
  const [specAgency, setSpecAgency] = useState([]);
  const NewUserSchema = Yup.object().shape({
    name: Yup.string().required(i18n.t('addUser.nameRequired')),
    email: Yup.string().email(i18n.t('addUser.emailValid')).required(i18n.t('addUser.emailRequired'))
  });

  useEffect(() => {
    if (props.match.params.id) {
      setIsEdit(true);
      props.getViewUser(props.match.params.id);
    }
    if (!props.match.params.role) {
      props.getAgencies(1, 1000)
      props.getCustomers(1, 1000)
    }
  }, []);

  useEffect(() => {
    setSpec(props.auth?.role === 'admin' ? props.customers?.docs : props.auth?.customer)
    setSpecAgency(props.auth?.role === 'admin' ? props.agencies?.docs : [props.auth?.agency])
  }, [props.customers, props.auth, props.agencies]);

  useEffect(() => {
    if (props.match.params.id) {
      setUser(props.user);
    }
  }, [props.user]);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: user?.name || '',
      email: user?.email || '',
      trial: user?.trial || false,
      telephone: user?.telephone || '+55',
      allCustomer: user?.allCustomer || false,
      role: user?.role || 'customer',
      customer: (user?.customer?.map(elem => ({
        id: elem._id,
        label: elem.name
      })) || []),
      agency: isEdit && user?.agency ? { id: user?.agency?._id, label: user?.agency?.name } : ''

    },
    validationSchema: NewUserSchema,
    onSubmit: async (values, { setSubmitting, resetForm, setErrors }) => {
      try {
        if (isEdit) {
          values._id = props.match.params.id || props.user?._id;
        }
        const auxValues = { ...values, customer: values.customer.map(elem => elem.id), agency: values.agency?.id || '', createdBy: props.auth?._id };
        if (auxValues.agency === '') auxValues.agency = null;
        const result = isEdit ? await props.updateUser(auxValues) : await props.addUser(auxValues);
        if (result.error) {
          setSubmitting(false);
          switch (result.error.message) {
            case "Dados inválidos.": return toast.error(i18n.t('addUser.invalidData'));
            case "Este e-mail já esta cadastrado.": return toast.error(i18n.t('addUser.alreadyRegistered'));
            case "Usuário não foi cadastrado com sucesso!": return toast.error(i18n.t('addUser.registerError'));
            case "Usuário não encontrado.": return toast.error(i18n.t('addUser.userNotFound'));
            case "E-mail já cadastrado.": return toast.error(i18n.t('addUser.alreadyRegistered2'));
            case "Ocorreu um erro ao atualizar usuário.": return toast.error(i18n.t('addUser.errorUpdate'));
            case "Erro interno do servidor.": return toast.error(i18n.t('addUser.internalError'));
            default: return toast.error(result.error.message);;
          }

        } else {
          props.getViewUser(result.data?._id);
          if (result.message === "Usuário cadastrado com sucesso!") {
            toast.success(i18n.t('addUser.registerSuccess'));
          } else if (result.message === "Usuário atualizado com sucesso!") {
            toast.success(i18n.t('addUser.updateSuccess'));
          } else {
            toast.error(result.message);
          }
          setSubmitting(false);
          setIsEdit(true);
        }
      } catch (error) {
        setSubmitting(false);
        if (error.error.message === 'Erro interno do servidor.') {
          toast.error(i18n.t('addUser.internalError'));
        } else {
          toast.error(error.error.message);
        }
      }
    }
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps, values, setFieldValue } = formik;

  return (
    <div className="p-4">
      <div className="d-flex mt-2">
        <div className="mr-auto p-2 d-flex align-items-center">
          <FCReturnButton margin="0rem" />
          <Typography variant="h4">{isEdit ? i18n.t('addUser.edit') : i18n.t('addUser.register')} {i18n.t('addUser.user')}</Typography>
        </div>
      </div>
      <FormikProvider value={formik}>
        <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
                <Stack spacing={3}>
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                    <FCTextField
                      fullWidth
                      label={i18n.t('addUser.name')}
                      {...getFieldProps('name')}
                      error={Boolean(touched.name && errors.name)}
                      helperText={touched.name && errors.name}
                    />
                    </Stack>
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                    <FCTextField
                      fullWidth
                      type="email"
                      label={i18n.t('addUser.email')}
                      {...getFieldProps('email')}
                      error={Boolean(touched.email && errors.email)}
                      helperText={touched.email && errors.email}
                    />
                      <FCTelInput
                        fullWidth
                        sx={{maxWidth: isMobile ? "100%" : "200px"}}
                        label={i18n.t('dataEnrichmentModal.intro.phone')}
                        lang={localStorage.getItem('i18nextLng') || this.props?.user?.language}
                        value={values.telephone}
                        onChange={(e) => setFieldValue('telephone', e)}
                      />
                  </Stack>
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                    {props.match.params.role !== "customer" &&
                      <FCSelect
                        fullWidth
                        label={i18n.t('addUser.role')}
                        {...getFieldProps('role')}
                        error={Boolean(touched.role && errors.role)}
                        helperText={touched.role && errors.role}
                        options={Array.from(roles.entries()).map(([value, label]) => {
                          if (value === "admin" && props.match.params?.role) return null;
                          return { value, label }
                        })}
                      />
                    }
                    <FCAutocomplete
                      fullWidth
                      label={i18n.t('addUser.agency')}
                      {...getFieldProps('agency')}
                      value={values.agency}
                      onChange={(event, newValue) => {
                        setFieldValue('agency', newValue);
                      }}
                      options={specAgency?.map(elem =>
                      ({
                        id: elem?._id,
                        label: elem?.name
                      })) || []}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                    />
                  </Stack>
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                    <FCAutocomplete
                      fullWidth
                      label={i18n.t('addUser.stores')}
                      multiple
                      {...getFieldProps('customer')}
                      value={values.customer}
                      onChange={(event, newValue) => {
                        setFieldValue('customer', newValue);
                      }}
                      options={spec?.map(elem =>
                      ({
                        id: elem._id,
                        label: elem.name
                      })) || []}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                    />
                  </Stack>
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                    {
                      props.auth?.role === 'admin' && (
                        <FCCheckbox label='Trial' {...getFieldProps('trial')} checked={values.trial} />
                      )
                    }
                    {(!props.match.params.role && values.role === 'admin') && (
                      <FCCheckbox label={i18n.t('addUser.accessStores')} {...getFieldProps('allCustomer')} checked={values.allCustomer} />
                      )
                    }
                    {
                      userPermissionCheck(props.auth, ["edit_user_permissions"]) && (
                        <Grow in={isEdit}>
                          <Button variant="contained" className="btn-gray" onClick={() => history.push(props.user?.role === "admin" ? `/permissions/${props.user?._id}` : `/permissions/customer/${props.user?._id}`)}>{i18n.t('addUser.permissions')}</Button>
                        </Grow>
                      )
                    }
                  </Stack>
                  <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: "1rem" }}>
                    <LoadingButton className="btn-white large" variant="contained" loading={isSubmitting} onClick={() => window.history.back()}>{i18n.t('addUser.cancel')}</LoadingButton>
                    <LoadingButton className="btn-action large" type="submit" variant="contained" loading={isSubmitting}>{i18n.t('addUser.save')}</LoadingButton>
                  </Box>
                </Stack>
            </Grid>
          </Grid>
        </Form>
      </FormikProvider >
    </div>
  )
}



const mapStateToProps = state => {
  return ({
    user: state.user.userDetails,
    auth: state.auth.user,
    customers: state.customer.customers,
    agencies: state.agencies.agencies,
  })
}

const mapDispatchToProps = (dispatch) => {
  return {
    addUser: (data) => dispatch(handleAddUser(data)),
    getViewUser: (id) => dispatch(getViewUser(id)),
    updateUser: (data) => dispatch(handleUpdateUser(data)),
    getCustomers: (page, limit) => dispatch(getCustomers(page, limit)),
    getAgencies: (page, limit) => dispatch(getAgencies(page, limit)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddUser);