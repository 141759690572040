import React, { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { i18n } from '../../../../translate/i18n';
import { useField } from 'formik';

const ReactQuillAction = () => {
  const [description, , descriptionHelper] = useField("description")

  // Define the modules for React Quill with custom formats
  const modules = {
    toolbar: [
      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
      ['bold', 'italic', 'underline', 'link'],
      [{ 'list': 'ordered'}, { 'list': 'bullet' }],
    ],
    clipboard: {
      matchVisual: false,
    },
  };

  return (
    <ReactQuill
      theme="snow"
      style={{
        border: `0.5px solid #888888`,
        boxShadow: '0px 1px 0px 0px #00000040 inset',
        borderRadius: '8px',
      }}
      value={description.value}
      onChange={(html) => descriptionHelper.setValue(html)}
      modules={modules}
      bounds=".app"
      placeholder={i18n.t("sprint.newAction.pages.details.description")}
      formats={[
        'background',
        'bold',
        'color',
        'font',
        'code',
        'italic',
        'link',
        'size',
        'strike',
        'script',
        'underline',
        'blockquote',
        'header',
        'indent',
        'list',
        'align',
        'direction',
        'code-block',
        'formula'
      ]}
    />
  );
};

export default ReactQuillAction