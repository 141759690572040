import {
  Box,
  Icon,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import React from "react";
import { userPermissionCheck } from "../../../Sprint/utils/userPermissionCheck";
import { i18n } from "../../../../translate/i18n";

const ProblemOptions = ({ i, problem, toggleModal, user, toggleSummaryModal, setIsEdit, setOpen }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Box
        border={"1px solid #babfc3"}
        borderRadius={"5px"}
        height={"2rem"}
        width={"2rem"}
      >
        <Box position={"relative"}>
          <IconButton
            onClick={handleClick}
            sx={{
              borderRadius: "5px",
              width: "100%",
              height: "2rem",
            }}
          >
            <Icon className="material-icons-outlined">more_vert</Icon>
          </IconButton>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
              <MenuItem onClick={(e) => toggleSummaryModal(problem)}
              >
                {i18n.t('support.problemsIncidents.table.problemOptions.resume')}
              </MenuItem>
            {
              userPermissionCheck(user, ["edit_problem"]) &&
              <MenuItem onClick={(e) => {setIsEdit(problem)}}
              >
                {i18n.t('support.problemsIncidents.table.problemOptions.edit')}
              </MenuItem>
            }
            {
              user.role === "admin" && (
                <MenuItem onClick={(e) => window.open(`https://checkstore.atlassian.net/browse/${problem.jiraCode}`, '_blank')}>
                  {i18n.t('support.problemsIncidents.table.problemOptions.jira')}
                </MenuItem>
              )
            }
            {
              userPermissionCheck(user, ["delete_problem"]) &&
              <MenuItem onClick={(e) => toggleModal(problem)}
              >
                {i18n.t('support.problemsIncidents.table.problemOptions.remove')}
              </MenuItem>
            }
            <MenuItem onClick={() => setOpen(prev=> !prev)}
              >
                {i18n.t('support.problemsIncidents.table.problemOptions.history')}
              </MenuItem>
          </Menu>
        </Box>
      </Box>
    </>
  );
};

export default ProblemOptions;
