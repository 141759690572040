import React from 'react';
import { Route } from 'react-router-dom';
import Form from '../pages/Start/Form'
import Project from '../pages/Start/Project'
import ViewProject from '../pages/Start/ViewProject'
import Task from '../pages/Start/Task'
import Checklist from '../pages/Start/Checklist'
import Inicial from '../pages/Start/Checklist/Inicial'
import Edit from '../pages/Start/Checklist/Edit'
import Create from '../pages/Start/Checklist/Create'
import View from '../pages/Start/Checklist/View'
import ViewAll from '../pages/Start/Checklist/ViewAll'
import RoadmapImplementation from '../pages/Start/Roadmap'


const Start = ({ baseDashboard }) => {
    return (
        <>
            <Route path="/project" exact component={baseDashboard({ Component: Project, permissions: ['admin'], platform: ['start'], action: 'view_project' })} />
            <Route path="/project/:id" exact component={baseDashboard({ Component: ViewProject, permissions: ['admin'], platform: ['start'], action: 'view_project' })} />
            <Route path="/task" exact component={baseDashboard({ Component: Task, permissions: ['admin'], platform: ['start'], action: 'view_task' })} />
            <Route path="/form" exact component={baseDashboard({ Component: Form, permissions: ['admin'], platform: ['start'], action: 'edit_form' })} />
            <Route path="/checklist" exact component={baseDashboard({ Component: Inicial, platform: ['start'], permissions: ['admin', 'customer', 'agency'], action: 'view_checklist' })} />
            <Route path="/edit-checklist" exact component={baseDashboard({ Component: Edit, permissions: ['admin'], platform: ['start'], action: 'edit_checklist' })} />
            <Route path="/create-checklist/:id" exact component={baseDashboard({ Component: Create, permissions: ['admin'], platform: ['start'], action: 'create_checklist' })} />
            <Route path="/view-checklist/:id" exact component={baseDashboard({ Component: View, permissions: ['admin'], platform: ['start'], action: 'view_checklist' })} />
            <Route path="/checklists" exact component={baseDashboard({ Component: ViewAll, permissions: ['admin'], platform: ['start'], action: 'view_checklist' })} />
            <Route path="/roadmap" exact component={baseDashboard({ Component: RoadmapImplementation, permissions: ['admin', 'customer'], platform: ['start'], action: 'view_roadmap' })} />
        </>
    )
}

export default Start;