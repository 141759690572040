import React, { useEffect, useState } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { Box, Typography } from '@mui/material';
import dayjs from 'dayjs';
import "dayjs/locale/pt-br";
import 'dayjs/locale/es';
import { i18n } from '../../../translate/i18n';
import { isMobile } from "react-device-detect";
import { FCDatePicker } from 'components-fullcomm';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);


const colorsRGB = [
  "rgb(255, 0, 0)",
  "rgb(0, 150, 0)",
  "rgb(0, 0, 200)",        
  "rgb(225, 225, 0)",
  "rgb(0, 225, 225)",
  "rgb(225, 0, 225)",
  "rgb(150, 150, 150)",
];

const handleLabel = (key) => {
  switch (key) {
    case "Primeiro conteúdo pintado": return i18n.t('support.performance.graphs.firstContent');
    case "Primeiro atraso de entrada": return i18n.t('support.performance.graphs.firstDelay');
    case "Maior conteúdo pintado": return i18n.t('support.performance.graphs.greaterContent');
    case "Mudança de layout cumulativo": return i18n.t('support.performance.graphs.changeLayout');
    case "Interatividade até a Próxima Renderização": return i18n.t('support.performance.graphs.interactivityRendering');
    case "Tempo para primeiro byte": return i18n.t('support.performance.graphs.firstByte');
    case "Nenhum dado encontrado": return i18n.t('support.performance.graphs.noneDataFound');
    case "Desempenho": return i18n.t('dashboard.performance');
    case "Acessibilidade": return i18n.t('dashboard.accessibility');
    case "Práticas recomendadas": return i18n.t('dashboard.recommendedPractices');
    case "SEO": return i18n.t('dashboard.seo');
    case "PWA": return i18n.t('dashboard.pwa');
    default: return "";
  }
}

const abreviateString = (str) => {
  const arr = str.split(" ");
  const abreaviated = arr.map(el => el[0].toUpperCase()).join("");
  return abreaviated;
};

export const MultiGraph = ({ dataSet, dates, caption = false, title, height = "500px" }) => {
  const data = {
    labels: dates?.map((el) => dayjs(el).format("DD/MM")) || [],
    datasets: Object.keys(dataSet).map((key, i) => (
      {
        label: handleLabel(key),
        data: dataSet[key]?.map(el => el) || [],
        fill: false,
        borderColor: colorsRGB[i],
        backgroundColor: colorsRGB[i],
        tension: 0.25
      }
    )),
  };
  const options = {
    maintainAspectRatio: false,
    pointRadius: 0,
    pointHitRadius: 10,
    plugins: {
      tooltp: {
        enabled: true,
      },
      legend: {
        position: isMobile ? "bottom" : "top",
        labels:{
          boxWidth: 10,
          generateLabels: (chart) => {
            const { data } = chart;
            if (data.datasets.length) {
              return data.datasets.map((dataset, i) => {
                return {
                  text: isMobile ? abreviateString(dataset.label) : dataset.label,
                  fillStyle: dataset.backgroundColor,
                  hidden: !chart.isDatasetVisible(i),
                  index: i,
                  lineWidth: 1,
                  strokeStyle: dataset.borderColor,
                  pointStyle: dataset.pointStyle,
                  ...dataset,
                };
              });
            }
            return [];
          }        
        }
      }
    },
    scales: {
      y: {
        beginAtZero: true,
        type: 'linear',
      },
    },
  };
  return (
    <Box padding={"2rem"}>
        <Typography variant={"h5"} mb={isMobile ? "0.5rem" : 0} align='center'>{title}</Typography>
      {
         (
           <Box display={"flex"} flexDirection={"column"} alignItems={"center"} justifyContent={"center"} position={"relative"} height={height}>
            {
              dates?.length < 7 ? (
                <Typography variant={"h6"}>{i18n.t('support.performance.graphs.notEnoughData')}...</Typography>
              ) : (
                <>
                  <Line data={data} options={options}/>
                  {
                    caption && (
                      <Typography variant={"caption"}>{i18n.t('support.performance.graphs.textAvise')}</Typography>
                    )
                  }
                </>
              )
            }
          </Box>
        )
      }
    </Box>
  )
}

const manipulateData = (data) => {
  switch (data) {
    case "FIRST_CONTENTFUL_PAINT_MS":
      return {name: "Primeiro conteúdo pintado", multiplier: 10000};
    case "FIRST_INPUT_DELAY_MS":
      return {name: "Primeiro atraso de entrada", multiplier: 10};
    case "LARGEST_CONTENTFUL_PAINT_MS":
      return {name: "Maior conteúdo pintado", multiplier: 10000};
    case "CUMULATIVE_LAYOUT_SHIFT_SCORE":
      return {name: "Mudança de layout cumulativo", multiplier: 100};
    case "INTERACTION_TO_NEXT_PAINT":
      return {name: "Interatividade até a Próxima Renderização", multiplier: 100};
    case "EXPERIMENTAL_TIME_TO_FIRST_BYTE":
      return {name: "Tempo para primeiro byte", multiplier: 1000};
    default:
      return {name: "Nenhum dado encontrado"};
  }
}



const Graphs = ({ history, strategy }) => {
  const [dateFilter, setDateFilter] = useState(dayjs(new Date()));
  const [lightHouse, setLightHouse] = useState({});
  const [loadingExperience, setLoadingExperience] = useState({});
  const [dates, setDates] = useState([]);

  useEffect(() => {
    const filteredHistory = history?.filter(el => {
      const parts = el.date.split('-');

      const year = Number(parts[0]);
      const month = Number(parts[1] - 1); 
      const currentDate = new Date(dateFilter);
      return month === currentDate.getMonth() && year === currentDate.getFullYear();
    }) || [];
    setDates(filteredHistory.filter(el => el[strategy]?.loadingExperience)?.map(el => new Date(el.date).toLocaleString('en-US', { timeZone: 'UTC' })));

    setLightHouse(
      filteredHistory?.map(el => el[strategy]?.lighthouseResult)?.reduce((acc, el) => {
      if (!el) return acc;
      Object.keys(el).forEach(key => {
        if (acc[el[key].title]) {
          acc[el[key].title].push(el[key].score * 100);
        } else {
          acc[el[key].title] = [el[key].score * 100];
        }
      })
      return acc;
    }, {}) || {});

    setLoadingExperience(
      filteredHistory.map(el => el[strategy]?.loadingExperience)?.reduce((acc, el) => {
        if (!el) return acc;
      Object.keys(el).forEach(key => {
        const { name, multiplier = 1 } = manipulateData(key);
        if (name === "Nenhum dado encontrado") return;
        if (acc[name]) {
          acc[name].push(el[key]?.percentile/multiplier);
        } else {
          acc[name] = [el[key]?.percentile/multiplier];
        }
      })
      return acc;
    }, {}) || {});

  }, [history, strategy, dateFilter]);

  return (
    <Box display={"flex"} flexDirection={"column"}>
          <Box sx={{alignSelf: "flex-end", marginRight: "2rem", maxWidth: "200px"}}>
            <FCDatePicker
              label="Filtrar"
              value={dateFilter}
              onChange={(date) => setDateFilter(date || '')}
              views={['month', 'year']}
              minDate={dayjs("01/01/2020")}
              maxDate={dayjs("01/01/2040")}
              
            />
          </Box>
          {
            (Object.keys(lightHouse).length > 0 && Object.keys(loadingExperience).length > 0) ? (
              <>
                <MultiGraph dataSet={lightHouse} dates={dates} title={"LightHouse"}/>
                <MultiGraph dataSet={loadingExperience} dates={dates} caption={true} title={i18n.t('support.performance.graphs.chargingExperience')}/>
              </>
            ) : (
              <Typography variant={"h4"}>{i18n.t('support.performance.graphs.noDataFound')}</Typography>
            )

          }
    </Box>
  )
}

export default Graphs