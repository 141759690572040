const contracts_reduce = (state = {}, actions) => {
    switch (actions.type) {
        case "GET_CONTRACTS":
            return {
                ...state,
                contracts: actions.payload.contracts
            }
        case "GET_CONTRACT":
            return {
                ...state,
                contractDetails: actions.payload.contract
            }
        case "GET_CONTRACT_VIEW":
            return {
                ...state,
                contractDetailsView: actions.payload.contract
            }
        case "CLEAR_CONTRACT":
            return {
                ...state,
                contractDetails: null
            }
        case "CLEAR_CONTRACTS":
            return {
                ...state,
                contracts: null
            }
        default:
            return state;
    }
}

export default contracts_reduce;