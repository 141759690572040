import React, { useCallback, useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  CircularProgress,
  AccordionSummary,
  Button,
  Chip,
  MenuItem,
  Typography,
  Tooltip,
  IconButton
} from "@mui/material";
import { connect } from "react-redux";
import { Box } from "@mui/system";
import * as actions from "../../../../store/actions/action";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AddIcon from "@mui/icons-material/Add";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import ModelCard from "../ActionFloatingButton/ModelCard";
import { categories } from "../../utils/objects";
import { debounce } from 'lodash';
import InfoIcon from '@mui/icons-material/Info';
import { isMobile } from "react-device-detect";
import { handleCategory } from '../utils/CategorySelect';
import { i18n } from "../../../../translate/i18n";
import ApexDashedChart from "../../../../components/Insights/ApexDashedChart";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { FCTextField } from "components-fullcomm";

const ContentModal = (props) => {
  const [healthStatus, setHealthStatus] = useState(handleHealthStatus(props.health));
  const [search, setSearch] = useState("");
  const [recommended, setRecommended] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState({});
  const [expanded, setExpanded] = useState(false);
  const [models, setModels] = useState([]);
  const history = useHistory();

  function handleHealthStatus(score) {
    if (score <= 25) {
      return "#db4e2f";
    } else if (score >= 75) {
      return "#007f60";
    }
    return "#e0a409";
  }

  const filterActionsModels = useCallback(
    debounce(() => {
      const userLanguage = localStorage.getItem('i18nextLng');

      const aux = props.models?.filter((model) => {
        const isInSelectedCategory =
          Object.keys(selectedCategory).length === 0 ||
          (model.category === selectedCategory.name &&
            model.quadrant === selectedCategory.quadrant);
        if (Object.keys(model?.language || {}).find(el => el === userLanguage)) {
          return model?.language[userLanguage]?.name.toLowerCase()?.includes(search.toLowerCase()) &&
            isInSelectedCategory
        }
        return (
          model?.name.toLowerCase()?.includes(search.toLowerCase()) &&
          isInSelectedCategory
        );
      });
      setModels(aux);
    }, 500),
    [search, selectedCategory]
  );

  useEffect(() => {
    if (search !== "" || Object.keys(selectedCategory).length !== 0) {
      filterActionsModels();
    }
  }, [search, selectedCategory]);

  useEffect(() => {
    props.getActionsModel();
  }, []);

  useEffect(() => {
    if (props.models) {
      setModels(props.models);
    }
  }, [props.models]);

  const handleCategories = (category) => {
    switch (category) {
      case "Ativação": return i18n.t('sprint.newAction.modal.activation')
      case "Aquisição": return i18n.t('sprint.newAction.modal.acquisition')
      case "Engajamento": return i18n.t('sprint.newAction.modal.engagement')
      case "Conversão": return i18n.t('sprint.newAction.modal.conversion')
      case "Retenção": return i18n.t('sprint.newAction.modal.retention')
      default: return category;
    }
  }


  const categoriesDropdown = ({ name, quadrants }, i) => {
    return (
      <>
        <Accordion
          expanded={expanded === i}
          onChange={() => setExpanded(expanded === i ? false : i)}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>
              {handleCategories(name)}
              {recommended.indexOf(name) !== -1 && (
                <Chip
                  style={{
                    fontSize: "10px",
                    marginLeft: "10px",
                    color: "white",
                    backgroundColor: "#008060",
                  }}
                  size="small"
                  label={i18n.t('sprint.newAction.modal.recommended')}
                />
              )}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            {quadrants.map((quadrant) => {
              if (props.healthAux[quadrant] !== 100) {
                if (recommended.indexOf(name) === -1) {
                  setRecommended([...recommended, name]);
                }
              }
              return (
                <MenuItem onClick={() => setSelectedCategory({ name, quadrant })}>
                  {props.healthAux[quadrant] !== 100 && (
                    <div
                      style={{
                        width: "6px",
                        height: "6px",
                        background: "#008060",
                        borderRadius: "100%",
                        marginRight: "15px",
                      }}
                    />
                  )}
                  {handleCategory(quadrant)}
                </MenuItem>
              );
            })}
          </AccordionDetails>
        </Accordion>
      </>
    );
  };

  return (
    <>
      <Box paddingX={{ xs: "1rem", md: "2rem" }} paddingY="1rem">
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
          height="4rem"
          alignItems="center"
        >
          <Typography id="modal-modal-title" variant="h4">
            {i18n.t('sprint.newAction.modal.createAction')}
          </Typography>
          <IconButton
            sx={{
              float: 'top'
            }}
            onClick={() => props.setOpen(false)}
          >
            <CloseOutlinedIcon
              sx={{
                color: "#86888c",
              }}
            />
          </IconButton>
        </Box>
        <Box display={{ xs: "block", md: "grid" }} gap={2} gridTemplateColumns={{ md: "1fr 1fr" }} alignItems="start">
          <Box position={"relative"} height={{ xs: "auto", md: "100%" }}>
            <Box
              display={"flex"}
              gap={2}
              alignItems={"center"}
              style={{ marginBottom: "14px" }}
            >
              <Box position={"relative"}>
                <CircularProgress
                  variant="determinate"
                  sx={{
                    color: "#e9eaea",
                  }}
                  size={50}
                  thickness={5}
                  value={100}
                />
                <CircularProgress
                  variant="determinate"
                  size={50}
                  value={props.health}
                  sx={{
                    color: healthStatus,
                    position: "absolute",
                    left: 0,
                  }}
                  thickness={5}
                />
                <Box
                  sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: "absolute",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "50px",
                  }}
                >
                  <Typography variant="h5" sx={{ color: healthStatus }}>
                    {props.health}
                  </Typography>
                </Box>
              </Box>
              <Box width={"75%"} display={"grid"} gap={1}>
                <Typography variant="h5">
                  {i18n.t('sprint.newAction.modal.healthScore')}
                  <Tooltip
                    title={i18n.t('sprint.newAction.modal.briefExplanation')}
                  >
                    <InfoIcon
                      style={{ width: "20px", marginLeft: "10px", color: "#86888C" }}
                    />
                  </Tooltip>
                </Typography>
              </Box>
            </Box>
            <Typography variant="h4" paddingLeft="1rem" marginBottom="0.5rem">
              {i18n.t('sprint.newAction.modal.categories')}
            </Typography>

            <AccordionSummary onClick={() => setSelectedCategory({})}>
              <Typography>{i18n.t('sprint.newAction.modal.all')}</Typography>
            </AccordionSummary>
            {categories.map((category, i) => categoriesDropdown(category, i))}
            <Box marginTop='1rem'>
              <ApexDashedChart 
                seriesData={[{
                  name: i18n.t('sprint.newAction.modal.healthScore'),
                  data: props.healthScoreHistory?.map((el) => el.score),
                }]}
                categories={props.healthScoreHistory?.map((el) => el.date)}
                title="Histórico de HealthScore"
                height={250}
              />
            </Box>
            <Button
              sx={{
                position: { xs: "static", md: "absolute" },
                marginTop: { xs: "1rem", md: 0 },
                bottom: 0,
              }}
              onClick={() => history.push(`/new-action?`)}
              className="btn-action fullwidth"
              variant="contained"
            >
              <AddIcon sx={{ marginRight: "0.5rem" }} />
              {i18n.t('sprint.newAction.modal.createNewAction')}
            </Button>
          </Box>
          <Box width={"100%"} marginTop={isMobile ? 2 : 0}>
            <FCTextField
              fullWidth
              value={search}
              placeholder={i18n.t('sprint.newAction.modal.search')}
              onChange={(e) => setSearch(e.target.value)}
              searchIcon
            />
            <Box
              overflow="auto"
              maxHeight="70vh"
              display="flex"
              flexDirection="column"
              gap="1rem"
              paddingX="1px"
              paddingTop="1rem"
            >
              {selectedCategory.name && (
                <Typography variant="h4">
                  {selectedCategory.name} - {selectedCategory.quadrant}
                </Typography>
              )}
              {models?.map((model) => {
                if (
                  localStorage.getItem('i18nextLng') !== 'pt-BR' &&
                  !model.language?.[localStorage.getItem('i18nextLng')]?.name
                ) {
                  return null;
                } else {
                  return <ModelCard model={model} history={history} />
                }
              })}
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  models: state.actions.models,
  health: state.actions.health,
  healthAux: state.actions.healthAux,
});

export default connect(mapStateToProps, actions)(ContentModal);
