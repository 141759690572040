import React from 'react';
import ReactDOM from 'react-dom';
import { Helmet } from 'react-helmet'
import App from './App';
import { Auth0Provider } from "@auth0/auth0-react";
import { register } from './serviceWorker';

import 'bootstrap/dist/css/bootstrap.min.css';
import { datadogRum } from '@datadog/browser-rum';
import { urlAuth0, clientIdAuth0 } from './config';
datadogRum.init({
  applicationId: 'd60440dc-9bc7-4d5d-828f-ba662621f956',
  clientToken: 'pub90c34ecfb1283ad8d86db31b3e6d9f2f',
  site: 'us5.datadoghq.com',
  service: 'plataforma-checkstore',
  env: 'staging-1',
  // Specify a version number to identify the deployed version of your application in Datadog 
  // version: '1.0.0',
  sessionSampleRate: 100,
  premiumSampleRate: 100,
  trackUserInteractions: true,
  defaultPrivacyLevel: 'mask-user-input'
});

datadogRum.startSessionReplayRecording();
const domain = urlAuth0;
const clientId = clientIdAuth0;
ReactDOM.render(
  <React.Fragment>
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        redirect_uri: window.location.origin
      }}
    >
      <Helmet>
        <title>Plataforma FullComm</title>
      </Helmet>
      <App />
    </Auth0Provider>
  </React.Fragment>,
  document.getElementById('root')
);

register();
