import axios from 'axios';
import {api} from '../../config'
import {getHeaders} from './localStorage'
import errorHandling from './errorHandling'

export const getNotifications = (user, section = "", page = 1, limit = 20) => async (dispatch) => {
  try {
      if (user._id) {
        const query = `?userId=${user._id}&customers=${user.customer.map(c => c._id)}&category=${section}&page=${page}&limit=${limit}`;
        const response = await axios.get(api + `/notifications/${query}`, getHeaders());
        dispatch({ type: "GET_NOTIFICATIONS", payload: response.data.notifications });
      }
  } catch (err) {
      return errorHandling(err);
  }
}

export const readNotifications = (user, notificationIdArray, category) => async () => {
  try {
    if (user._id) {
      const response = await axios.put(api + `/notifications`, {user, notificationIdArray, category}, getHeaders());
      return response.data
    }
  } catch (err) {
      return errorHandling(err);
  }
}
