const apps_reducer = (state = {}, app) => {
  switch(app.type){
      case "GET_APP":
          return{
              ...state,
              app: app.payload.app
          }
      default: 
          return state;
  }
}

export default apps_reducer;