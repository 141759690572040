import React from 'react';
import { Card, Grid } from '@mui/material';
import { getIcon, getStatusDescription } from '../../utils/statusPlatinum';


const InternalEvents = ({ internalEvents, createdCommerce }) => {
    const statusShow = [
        {
            value: createdCommerce,
            code: 1
        },
        {
            value: internalEvents?.os,
            code: 7
        },
        {
            value: internalEvents?.invoice,
            code: 25
        },
        {
            value: internalEvents?.endPicking,
            code: 27
        },
        {
            value: internalEvents?.endCheckout,
            code: 40
        },
        {
            value: internalEvents?.dispatched,
            code: 50
        },
        {
            value: internalEvents?.in_transit,
            code: 70
        },
        {
            value: internalEvents?.out_for_delivery,
            code: 75
        },
        {
            value: internalEvents?.delivered,
            code: 90
        }
    ];
    return (
        <Card className="card-general internal-events">
            <Grid container spacing={2} className="main-grid-stack" columns={{ desktop: statusShow?.length, sm: 5, mobile: 4, xs: 3 }} justifyContent="space-around">
                {statusShow.map((status, index) => (
                    <Grid className='item-grid-stack' item xs={1} sm={1} md={1} key={index}>
                        <div className="box-status">
                            <div className="icon">{getIcon(status.code)}</div>
                            <div className="title"><b>{getStatusDescription(status.code)}</b></div>
                            <div className="mediaHours">{status.value ? <>
                                {new Date(status?.value)?.toLocaleDateString('pt-BR')}<br />{new Date(status?.value)?.toLocaleTimeString('pt-BR')?.slice(0, 5)}
                            </> : "-"}</div>
                        </div>
                    </Grid>
                ))}
            </Grid>
        </Card>
    )
}

export default InternalEvents;