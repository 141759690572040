import {
  Box,
  Table,
  TableHead,
  TableRow,
  Typography,
  TableCell,
  TableBody,
  Checkbox,
  Chip,
  IconButton,
  useMediaQuery,
  Collapse,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import ArrowDownwardOutlinedIcon from '@mui/icons-material/ArrowDownwardOutlined';
import { Modal, ModalBody } from "reactstrap";
import dayjs from "dayjs";
import ModalSummary from "../ModalForm/ModalSummary";
import ProblemOptions from "./ProblemOptions";
import * as actions from '../../../../store/actions/problems'
import { toast } from "react-toastify";
import { statusColor } from "../../../Sprint/utils/statusColor"
import { i18n } from "../../../../translate/i18n";
import moment from "moment";
import { FCModalFooter, FCModalHeader, FCTableContainer, FCTableHeader } from "components-fullcomm";

const columns = [
  {
    id: "jiraCode",
    label: "Código da Tarefa",
    sx: {
      width: '19%',
    },
  },
  {
    id: "title",
    label: "Título",
    sx: {
      width: '19%',
    },
  },
  {
    id: "createdAt",
    label: "Data",
    sx: {
      width: '19%',
    },
  },
  {
    id: "aggregateTimeSpent",
    label: i18n.t("support.hourTracking.hoursTable.quantityHours"),
    sx: {
      width: "19%",
    },
  },
  {
    id: "status",
    label: "Status",
    sx: {
      width: '19%',
    },
  },
  {
    id: "control",
    label: "",
    sx: {
      width: '5%',
      minWidth: "25px",
    },
  },
];

const Row = ({ dateFilter, problem, i, isSelected, handleClick, handleValues, user, setIsEdit, toggleModal, toggleSummaryModal, getTotalTime }) => {
  const [open, setOpen] = useState(false);
  const today = new Date(dateFilter);
  const month = today.getMonth();
  const year = today.getFullYear();
  const currentDate = `${year}-${month}`;
  const isItemSelected = isSelected(problem);
  const labelId = `enhanced-table-checkbox-${i}`;
  return (
    <>
    <TableRow key={problem._id} role="checkbox" tabIndex={-1}>
      <TableCell padding="checkbox">
        <Checkbox
          color="backlog"
          onClick={(event) => handleClick(event, problem)}
          checked={isItemSelected}
          inputProps={{
            "aria-labelledby": labelId,
          }}
        />
      </TableCell>
      {columns.map((column) => {
        const value = column.id === "createdAt" ? dayjs(problem[column.id]).format("DD/MM/YYYY") : problem[column.id]; 
        const { type } = statusColor(problem.status);                     
        return  column.id === "control" ? (
          <TableCell key={column.id} sx={column.sx}>
            <Box display="flex" justifyContent="flex-end">
              <ProblemOptions
                setOpen={setOpen}
                setIsEdit={setIsEdit}
                user={user}
                problem={problem}
                toggleModal={toggleModal}
                i={i}
                toggleSummaryModal={toggleSummaryModal}
              />
            </Box>
          </TableCell>
        ) : (column.id === "jiraCode" && value) ? (
          <TableCell key={column.id} sx={column.sx}>
            <Chip label={`#${value}`} color="backlog" size="small" />
          </TableCell>
        ) : column.id === "status" ? (
          <TableCell key={column.id} sx={column.sx}>
            <Chip label={handleValues(value)} color={type} size="small" />
          </TableCell>
        ) : column.id === "aggregateTimeSpent" ? (
          <TableCell key={column.id} sx={column.sx}>
            {getTotalTime(problem.history, currentDate)}
          </TableCell>
        ) : (
          <TableCell key={column.id} sx={column.sx}>
            {value}
          </TableCell>
        );
      })}
    </TableRow>
    <TableRow>
    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <Box sx={{ margin: 1 }}>
          <Typography variant="h6" gutterBottom component="div">
            {i18n.t("support.hourTracking.hoursTable.timeRecord")}
          </Typography>
          <Table size="small" aria-label="purchases">
            <TableHead>
              <TableRow>
                <TableCell>{i18n.t("support.hourTracking.hoursTable.date")}</TableCell>
                <TableCell>{i18n.t("support.hourTracking.hoursTable.comment")}</TableCell>
                <TableCell>{i18n.t("support.hourTracking.hoursTable.hours")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {problem.history?.filter(el => el.date === currentDate)?.map((historyRow) => (
                <TableRow key={historyRow.id}>
                  <TableCell component="th" scope="row">
                    {moment(historyRow.created).format("DD/MM/YYYY")}
                  </TableCell>
                  <TableCell>{historyRow?.comment}</TableCell>
                  <TableCell>{historyRow.timeSpent}</TableCell>
                </TableRow>
                ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

const ProblemIncidentsTable = (props) => {
  const [problem, setProblem] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [openSummaryModal, setOpenSummaryModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [numSelected, setNumSelected] = useState(0);
  const isMobileDevice = useMediaQuery('(max-width:600px)');

  const getTotalTime = (time, date) => {

    const totalSeconds = time?.reduce((acc, el) => {
      if (el.date === date) {
        acc += el.timeSpentSeconds;
      }
      return acc;
    }, 0);

    const duration = moment.duration(totalSeconds, 'seconds');
    const hours = duration.hours();
    const minutes = duration.minutes();
    return `${hours}h ${minutes}m`;
  }

  const deleteAction = async () => {
    setLoading(true);
    try {
      const result = await props.deleteProblem(problem._id);

      setLoading(false);
      toggleModal();
      if (!result.error) {
        props.getProblems(`date=${new Date()}`);
        if (result.message === "Problema apagado com sucesso!") {
          toast.success(i18n.t('support.problemsIncidents.table.deleteSuccess'))
        } else {
          toast.success(result.message);
        }
      } else {
        switch (result.error.message) {
          case "Problema não encontrado!": return toast.error(i18n.t('support.problemsIncidents.table.problemNotFound'));
          case "Erro ao apagar problema!": return toast.error(i18n.t('support.problemsIncidents.table.deleteError'));
          case "Erro interno do servidor.": return toast.error(i18n.t('support.problemsIncidents.table.internalError'));
          default: return toast.error(result.error.message);
        }
      }
    } catch (error) {
      if (error.error.message === "Erro interno do servidor.") {
        toast.error(i18n.t('support.problemsIncidents.table.internalError'));
      } else {
        toast.error(error.error.message);
      }
    }
  };

  const toggleModal = (params = {}) => {
    setProblem(params);
    setOpenModal((prev) => !prev);
  };

  const toggleSummaryModal = (params) => {
    setProblem(params);
    setOpenSummaryModal((prev) => !prev);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = props.data?.map((n) => n);
      props.setSelected(newSelected);
      return;
    }
    props.setSelected([]);
  };

  const handleClick = (event, problems) => {
    const selectedIndex = props.selected.indexOf(problems);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(props.selected, problems);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(props.selected.slice(1));
    } else if (selectedIndex === props.selected.length - 1) {
      newSelected = newSelected.concat(props.selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        props.selected.slice(0, selectedIndex),
        props.selected.slice(selectedIndex + 1)
      );
    }

    props.setSelected(newSelected);
  };

  const isSelected = (problems) => props.selected.indexOf(problems) !== -1;

  useEffect(() => {
    setNumSelected(props.selected?.length);
  }, [props.selected]);

  const handleLabel = (label) => {
    switch (label) {
      case "Código da Tarefa": return i18n.t('support.problemsIncidents.table.taskCode');
      case "Título": return i18n.t('support.problemsIncidents.table.title');
      case "Data": return i18n.t('support.problemsIncidents.table.date');
      case "Status": return i18n.t('support.problemsIncidents.table.status');
      default: return label;
    }
  }

  const handleValues = (value) => {
    switch (value) {
      case 'Ativo': return i18n.t('sprint.list.actionsTable.active');
      case 'Backlog': return i18n.t('sprint.list.actionsTable.backlog');
      case 'Em andamento': return i18n.t('sprint.list.actionsTable.inProgress');
      case 'Concluído': return i18n.t('sprint.list.actionsTable.done');
      case 'Para fazer': return i18n.t('sprint.list.actionsTable.toDo');
      case 'Planejamento': return i18n.t('sprint.list.actionsTable.planning');
      case 'Cancelado': return i18n.t('sprint.list.actionsTable.canceled');
      case 'Homologação': return i18n.t('sprint.kanban.actionCardArea.customerApproval');
      default: return value;
    }
  }

  return (
    <>
      <ModalSummary
        problem={problem}
        openModal={openSummaryModal}
        setOpenModal={setOpenSummaryModal}
        setPage={props.setPage}
        buttons={false}
        formik={props.formik}
        customer={problem.customer?.name || ""}
      />
        <FCTableContainer>
          <Table stickyHeader>
            <FCTableHeader sx={{ position: "sticky", top: 0, zIndex: 5 }}>
              <TableRow>
                <TableCell padding="checkbox" sx={{ background: "#F0F0F0" }}>
                  <Checkbox
                    color="backlog"
                    indeterminate={
                      numSelected > 0 && numSelected < props.data?.length
                    }
                    checked={
                      props.data?.length > 0 && numSelected === props.data?.length
                    }
                    onChange={handleSelectAllClick}
                    inputProps={{
                      "aria-label": "select all desserts",
                    }}
                  />
                </TableCell>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                    sx={{ bgcolor: "#F0F0F0" }}
                  >
                    <Box
                      display={"flex"}
                      gap={1}
                    >
                      {column.id === "jiraCode" &&
                        <IconButton sx={{ padding: 0 }}>
                          <ArrowDownwardOutlinedIcon
                            onClick={() => props.setSortDirection((prev) => -prev)}
                          />
                        </IconButton>
                      }
                      <Typography fontWeight={"bold"}>
                        {(column.id === "jiraCode" && isMobileDevice) ? i18n.t('support.problemsIncidents.table.code') : handleLabel(column.label)}
                      </Typography>
                    </Box>
                  </TableCell>
                ))}
              </TableRow>
            </FCTableHeader>
            <TableBody>
              {props.data?.map((problem, i) => {
                return (
                  <Row
                    key={problem._id}
                    problem={problem}
                    i={i}
                    isSelected={isSelected}
                    dateFilter={props.dateFilter}
                    handleClick={handleClick}
                    handleValues={handleValues}
                    getTotalTime={getTotalTime}
                    toggleModal={toggleModal}
                    toggleSummaryModal={toggleSummaryModal}
                    setIsEdit={props.setIsEdit}
                    user={props.user}
                  />
                );
              })}
            </TableBody>
          </Table>
        </FCTableContainer>
      <Modal centered toggle={() => toggleModal()} isOpen={openModal}>
        <FCModalHeader
          title={i18n.t('support.problemsIncidents.table.deleteProblem')}
          onClick={() => setOpenModal(false)}
        />
        <ModalBody>
          <Typography height={"5vh"}>{i18n.t('support.problemsIncidents.table.confirmDelete')}</Typography>
        </ModalBody>
        <FCModalFooter
          loading={loading}
          handleAction={deleteAction}
          handleCancel={() => toggleModal()}
          actionLabel={i18n.t('support.problemsIncidents.table.delete')}
          cancelLabel={i18n.t('support.problemsIncidents.table.cancel')}
        />
      </Modal>
    </>
  );
};
const mapStateToProps = (state) => ({
  user: state.auth.user,
  customer: state.customer.customerDetails
});

export default connect(mapStateToProps, actions)(ProblemIncidentsTable);
