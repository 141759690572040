
import { i18n } from '../../translate/i18n'
export const chartLocale = {
    name: 'global',
    options: {
        months: [
            i18n.t('apexChart.january'),
            i18n.t('apexChart.february'),
            i18n.t('apexChart.march'),
            i18n.t('apexChart.april'),
            i18n.t('apexChart.may2'), // Nota: Usando 'may2' para "Maio" baseado em seu objeto
            i18n.t('apexChart.june'),
            i18n.t('apexChart.july'),
            i18n.t('apexChart.august'),
            i18n.t('apexChart.september'),
            i18n.t('apexChart.october'),
            i18n.t('apexChart.november'),
            i18n.t('apexChart.december')
        ],
        shortMonths: [
            i18n.t('apexChart.jan'),
            i18n.t('apexChart.feb'),
            i18n.t('apexChart.mar'),
            i18n.t('apexChart.apr'),
            i18n.t('apexChart.may'),
            i18n.t('apexChart.jun'),
            i18n.t('apexChart.jul'),
            i18n.t('apexChart.aug'),
            i18n.t('apexChart.sep'),
            i18n.t('apexChart.oct'),
            i18n.t('apexChart.nov'),
            i18n.t('apexChart.dec')
        ],
        days: [
            i18n.t('apexChart.sunday'),
            i18n.t('apexChart.monday'),
            i18n.t('apexChart.tuesday'),
            i18n.t('apexChart.wednesday'),
            i18n.t('apexChart.thursday'),
            i18n.t('apexChart.friday'),
            i18n.t('apexChart.saturday')
        ],
        shortDays: [
            i18n.t('apexChart.sun'),
            i18n.t('apexChart.mon'),
            i18n.t('apexChart.tue'),
            i18n.t('apexChart.wed'),
            i18n.t('apexChart.thu'),
            i18n.t('apexChart.fri'),
            i18n.t('apexChart.sat')
        ],
        toolbar: {
            exportToSVG: i18n.t('apexChart.toolbar.exportToSVG'),
            exportToPNG: i18n.t('apexChart.toolbar.exportToPNG'),
            menu: i18n.t('apexChart.toolbar.menu'),
            selection: i18n.t('apexChart.toolbar.selection'),
            selectionZoom: i18n.t('apexChart.toolbar.selectionZoom'),
            zoomIn: i18n.t('apexChart.toolbar.zoomIn'),
            zoomOut: i18n.t('apexChart.toolbar.zoomOut'),
            pan: i18n.t('apexChart.toolbar.pan'),
            reset: i18n.t('apexChart.toolbar.reset')
        }
    }
};