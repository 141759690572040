import React, { useState, useEffect } from 'react';

import { Typography, Collapse, List } from '@mui/material';
import TableRowsOutlinedIcon from '@mui/icons-material/TableRowsOutlined';
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';
import RuleIcon from '@mui/icons-material/Rule';
import ViewTimelineIcon from '@mui/icons-material/ViewTimeline';
import TopicIcon from '@mui/icons-material/Topic';
import { i18n } from '../../translate/i18n';

const Start = ({ user, MenuLink, shopDetails }) => {
    const [isOpen, setIsOpen] = useState(localStorage.getItem('startSidebar') === 'false' ? false : true);

    const handleToggleCollapse = () => {
        setIsOpen(!isOpen);
    };

    useEffect(() => {
        localStorage.setItem('startSidebar', isOpen);
    }, [isOpen]);

    return (
        <div>
            <Typography onClick={handleToggleCollapse} variant="h6" sx={{ cursor: 'pointer', color: 'white', fontSize: '14px', fontWeight: 'bold', marginTop: '10px', marginBottom: '10px' }}>
                {i18n.t('components.sideBar.start.implantation')}
            </Typography>
            <Collapse in={isOpen}>
                <List component="nav">
                    {user?.role === 'admin' && (
                        <>
                            {user?.permissionAdmin?.includes('edit_form') && (
                                <MenuLink to="/form" Icon={TableRowsOutlinedIcon}>
                                    {i18n.t('components.sideBar.start.form')}
                                </MenuLink>
                            )}
                            {user?.permissionAdmin?.includes('view_project') && (
                                <MenuLink to="/project" Icon={AccountTreeOutlinedIcon}>
                                    {i18n.t('components.sideBar.start.projects')}
                                </MenuLink>
                            )}
                            {user?.permissionAdmin?.includes('view_checklist') && (
                                <MenuLink to="/checklists" Icon={RuleIcon}>
                                    {i18n.t('components.sideBar.start.checklists')}
                                </MenuLink>
                            )}
                            {user?.permissionAdmin?.includes('view_roadmap') && (
                                <MenuLink to="/roadmap" Icon={ViewTimelineIcon}>
                                    {i18n.t('start.roadmap.title')}
                                </MenuLink>
                            )}
                            {user?.permissionAdmin?.includes('view_document') && (
                                <MenuLink to="/documents/start" Icon={TopicIcon}>
                                    {i18n.t('documents.index.title')}
                                </MenuLink>
                            )}
                        </>
                    )}

                    {user?.role === 'customer' && (
                        <>
                            {user?.permissionCustomer?.find((e) => e.customer === localStorage.getItem('shop'))?.actions?.includes('view_checklist') && (
                                <MenuLink to="/checklist" Icon={RuleIcon}>
                                    {i18n.t('components.sideBar.start.checklistCustomer')}
                                </MenuLink>
                            )}
                            {user?.permissionCustomer?.find((e) => e.customer === localStorage.getItem('shop'))?.actions?.includes('view_roadmap') && (
                                <MenuLink to="/roadmap" Icon={ViewTimelineIcon}>
                                    {i18n.t('start.roadmap.title')}
                                </MenuLink>
                            )}
                            {user?.permissionCustomer?.find((e) => e.customer === localStorage.getItem('shop'))?.actions?.includes('view_document') && (
                                <MenuLink to="/documents/start" Icon={TopicIcon}>
                                    {i18n.t('documents.index.title')}
                                </MenuLink>
                            )}
                        </>
                    )}

                    {
                        user?.role === 'agency' && <>
                            <MenuLink to="/checklist" Icon={RuleIcon}>{i18n.t('components.sideBar.start.checklistAgency')}</MenuLink>
                        </>
                    }
                </List>
            </Collapse>
        </div>
    );
};

export default Start;
