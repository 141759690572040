import React, { useCallback, useState, useRef, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { styled } from '@mui/material/styles';
import { connect } from 'react-redux';
import axios from 'axios';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

import debounce from 'lodash.debounce';
import { Button, Container, InputAdornment, Grid, Modal, Fade, Typography, Accordion, Box, TextField, InputLabel, FormControl, Select, MenuItem, FormControlLabel, Checkbox, RadioGroup, Radio, AccordionSummary, AccordionDetails, useMediaQuery } from '@mui/material';
import { parents } from '../../../config';

import { getChecklists, handleAddChecklist, getChecklist, handleUpdateChecklist, handleUpdateChecklistOrder, deleteChecklist } from '../../../store/actions/checklist'
import '../../../styles/dashboard/start/checklist.css'
import { Icon } from '@iconify/react';
import { i18n } from '../../../translate/i18n';

const BackdropUnstyled = React.forwardRef((props, ref) => {
    const { open, ...other } = props;
    return (
        <Fade in={open}>
            <div ref={ref} {...other} />
        </Fade>
    );
});

BackdropUnstyled.propTypes = {
    open: PropTypes.bool,
};

const Backdrop = styled(BackdropUnstyled)`
    z-index: -1;
    position: fixed;
    right: 0;
    bottom: 0;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.3);
    -webkit-tap-highlight-color: transparent;
  `;

const style = (theme) => ({
    position: 'absolute',
    borderRadius: '20px',
    minHeight: '50vh',
    maxHeight: '80vh',
    overflowY: 'auto',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: 700,
    width: '100%',
    backgroundColor: theme.palette.mode === 'dark' ? '#0A1929' : 'white',
    boxShadow: 24,
    padding: '16px 32px 24px 32px',
});

const Edit = (props) => {
    const [searchIcon, setSearchIcon] = useState('');
    const [form, setForm] = useState(null);
    const [icons, setIcons] = useState([]);
    const [add, setAdd] = useState(null);
    const [data, setData] = useState({});
    const [openModal, setOpenModal] = useState(false)
    const [isEditingTitle, setIsEditingTitle] = useState(false);
    const [isEditingSubTitle, setIsEditingSubTitle] = useState(false);
    const containerRef = useRef(null);
    const [items, setItems] = useState([]);
    const isMobileDevice = useMediaQuery('(max-width:600px)');

    useEffect(() => {
        props.getChecklists()
    }, []);

    useEffect(() => {
        if (props.checklists?.length > 0) {
            let aux = {}
            parents.forEach((value, key) => {
                aux = { ...aux, [key]: props.checklists?.filter(e => e.parent === key) }
            });
            setItems(aux)
        }
    }, [props.checklists]);

    const debouncedSearch = useCallback(
        debounce(async (e) => {
            try {
                const response = await axios.get(`https://api.iconify.design/search?query=${e}`);
                setIcons(response.data.icons || []);
            } catch (error) {
                console.error(i18n.t('start.checklist.edit.errorIcons'), error);
            }
        }, 1000),
        []
    );


    const handleSearchIcons = (e) => {
        setSearchIcon(e);
        debouncedSearch(e);
    };

    const handleClickOutside = (e) => {
        if (containerRef.current && !containerRef.current.contains(e.target)) {
            setIsEditingTitle(false);
            setIsEditingSubTitle(false);
        }
    };

    const save = async () => {
        try {
            if (add) {
                setData({});
                setAdd(null);
                const response = await props.handleAddChecklist(data);
                if (response.error) {
                    switch (response.error.message) {
                        case "Checklist não foi cadastrado com sucesso!": return toast.error(i18n.t('start.checklist.edit.registerError'));
                        case "Erro interno do servidor.": return toast.error(i18n.t('start.checklist.edit.internalError'));
                        default: return toast.error(response.error.message)
                    }
                } else {
                    if (response.message === "Checklist cadastrado com sucesso!") {
                        toast.success(i18n.t('start.checklist.edit.registerSuccess'))
                    } else {
                        toast.success(response.message);
                    }
                }
            } else {
                const response = await props.handleUpdateChecklist(data);
                if (response.error) {
                    switch (response.error.message) {
                        case "Ocorreu um erro ao atualizar o Checklist.": return toast.error(i18n.t('start.checklist.edit.updateError'));
                        case "Erro interno do servidor.": return toast.error(i18n.t('start.checklist.edit.internalError'));
                        default: return toast.error(response.error.message)
                    }
                } else {
                    if (response.message === "Tarefa atualizada com sucesso!") {
                        toast.success(i18n.t('start.checklist.edit.updateSuccess'))
                    } else {
                        toast.success(response.message);
                    }
                }
                setData({});
                setForm(null);
            }

            props.getChecklists()
            setItems(props.checklists);
        } catch (error) {
            if (error.error.message === "Erro interno do servidor.") {
                toast.error(i18n.t('start.checklist.edit.internalError'))
            } else {
                toast.error(error.error.message)
            }
        }
    }

    const deleteChecklist = async (id) => {
        await props.deleteChecklist(id);
        props.getChecklists()
        setItems(props.checklists);
    };

    const handleForm = async (e) => {
        setForm(e)
        const response = await props.getChecklist(e);
        setData(response.data)
        setAdd(null)
    };

    const handleAdd = (e) => {
        setData({
            title: "Título",
            subtitle: "Subtítulo",
            fields: [],
            icon: "iconoir:file-not-found",
            parent: e
        })
        setForm(null)
        setAdd(e)
    };

    const handleFields = (e, field, index) => {
        let value = null;
        if (e.target?.type === 'checkbox') {
            value = e.target.checked;
        } else if (e.target) {
            value = e.target.value;
        } else {
            value = e;
        }
        setData(
            prevState => {
                const newData = [...prevState.fields];
                newData[index][field] = value;
                return { ...prevState, fields: newData };
            }
        )
    }


    const handleOptions = (e, index, indexOption) => {
        setData(
            prevState => {
                const newData = [...prevState.fields];
                newData[index]['options'][indexOption] = e.target?.value || e;
                return { ...prevState, fields: newData };
            }
        )
    }

    const addField = () => {
        const fields = data.fields;
        const generateObjectId = () => {
            const timestamp = Math.floor(Date.now() / 1000).toString(16).padStart(8, '0');
            const randomValue = Math.random().toString(16).substr(2, 8).padEnd(16, '0').substring(0, 16);
            return timestamp + randomValue;
        };
    
        const uniqueId = generateObjectId();
        fields.push({
            title: "Campo",
            type: 'text',
            helperText: "",
            required: false,
            parentName: '',
            parentValue: [],
            options: [],
            placeholder: "",
            new: true,
            status: 'a',
            _id: uniqueId
        })
        setData({ ...data, fields })
    };

    const handleDragEnd = (result, key) => {
        if (!result.destination) return;
        const itemsAux = { ...items };
        const itemsCopy = [...items[key]];
        const [reorderedItem] = itemsCopy.splice(result.source.index, 1);
        itemsCopy.splice(result.destination.index, 0, reorderedItem);
        setItems({ ...itemsAux, [key]: itemsCopy });
        const payload = itemsCopy.map((e, index) => e._id)
        props.handleUpdateChecklistOrder(payload)
    };

    const handleDragEndFields = (result) => {
        if (!result.destination) return;
        const itemsCopy = [...data.fields];
        const [reorderedItem] = itemsCopy.splice(result.source.index, 1);
        itemsCopy.splice(result.destination.index, 0, reorderedItem);

        const aux = data;
        aux.fields = itemsCopy;
        setData(aux)
    };
    return (
        <div onClick={handleClickOutside} class='body'>
            <Grid style={{ borderTop: '1px solid silver', height: '100%' }} container>
                <Grid item xs={isMobileDevice ? 1.5 : 3} className="menu">
                    {Array.from(parents, ([key, value]) => (
                        <>
                            <div className={isMobileDevice ? 'item-mobile' : 'item'}>
                                {
                                    !isMobileDevice && (
                                        <h3>{value}</h3>
                                    )
                                }
                            </div>

                            <div className='subitens'>
                                <DragDropContext onDragEnd={result => handleDragEnd(result, key)}>
                                    <Droppable droppableId={`items-${key}`}>
                                        {(provided) => (
                                            <div {...provided.droppableProps} ref={provided.innerRef}>
                                                {items[key]?.map((item, index) => (
                                                    <Draggable key={item._id} draggableId={item._id} index={index}>
                                                        {(provided) => (
                                                            <div className={`${isMobileDevice ? 'subitem-mobile' : 'subitem'} ${form === item._id ? 'selected' : ''}`} onClick={() => handleForm(item._id)}
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                                ref={provided.innerRef}
                                                            >
                                                                <Grid container>
                                                                    <Grid item xs={isMobileDevice ? 10 : 2} display={"flex"} justifyContent={"center"} className='alignCenterVertical'>
                                                                        <Icon icon={item.icon} width='30px' />
                                                                    </Grid>
                                                                    {
                                                                        !isMobileDevice && (
                                                                            <Grid item xs={8} className='alignCenterVertical'>
                                                                                <h4 className='break-word'>{item.title}</h4>
                                                                            </Grid>
                                                                        )
                                                                    }
                                                                    {
                                                                        !isMobileDevice && (
                                                                            <Grid item xs={2} className='alignCenterVertical' justifyContent='right'>
                                                                                <Icon icon='material-symbols:drag-indicator' width='1.75rem' />
                                                                            </Grid>
                                                                        )
                                                                    }
                                                                </Grid>
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                ))}
                                                {provided.placeholder}
                                            </div>
                                        )}
                                    </Droppable>
                                </DragDropContext>
                                <div className={`${isMobileDevice ? 'subitem-mobile' : 'subitem'} ${add === key ? 'selected' : ''}`} onClick={() => handleAdd(key)}>
                                    <Grid container>
                                        <Grid item xs={isMobileDevice ? 12 : 2} display={"flex"} justifyContent={"center"} className='alignCenterVertical'>
                                            <Icon icon="material-symbols:add" width='30px' />
                                        </Grid>
                                        {
                                            !isMobileDevice && (
                                                <Grid item xs={10} className='alignCenterVertical'>
                                                    <h4>{i18n.t('start.checklist.edit.add')}</h4>
                                                </Grid>
                                            )
                                        }
                                    </Grid>
                                </div>
                            </div></>
                    ))}
                </Grid>
                <Grid item xs={isMobileDevice ? 10.5 : 9}>
                    <Container className={isMobileDevice ? 'p-3' : 'p-5'} style={{ heigth: '100%' }}>
                        {data._id || add ?
                            <>
                                <Grid container columns={20}>
                                    <Grid textAlign='center' xs={1}>
                                        {
                                            !isMobileDevice && (
                                                <Icon style={{ cursor: 'pointer' }} onClick={() => setOpenModal(true)} icon={data.icon} width='30px' />
                                            )
                                        }
                                    </Grid>
                                    <Grid xs={19}>
                                        <div ref={containerRef}>
                                            {isEditingTitle ? (
                                                <>
                                                    <input
                                                        style={{ width: '100%' }}
                                                        type="text"
                                                        className='clearInput inputH3'
                                                        value={data.title}
                                                        onChange={e => setData({ ...data, title: e.target.value })}
                                                    />
                                                </>
                                            ) : (
                                                <>
                                                    <h3 onDoubleClick={() => setIsEditingTitle(true)}>{data.title}</h3>
                                                </>)}
                                            {isEditingSubTitle ? (
                                                <>
                                                    <input
                                                        style={{ width: '100%' }}
                                                        type="text"
                                                        className='clearInput inputP'
                                                        value={data.subtitle}
                                                        onChange={e => setData({ ...data, subtitle: e.target.value })}
                                                    />
                                                </>
                                            ) : (
                                                <>
                                                    <p onDoubleClick={() => setIsEditingSubTitle(true)}>{data.subtitle || <small>{i18n.t('start.checklist.edit.subTitle')}</small>}</p>
                                                </>)}
                                        </div></Grid>
                                </Grid>
                                <DragDropContext onDragEnd={handleDragEndFields}>
                                    <Droppable droppableId="itemsFields">
                                        {(provided) => {
                                            return <div {...provided.droppableProps} ref={provided.innerRef}>
                                                {data.fields && data.fields.map((field, index) => {
                                                    if (field.status === 'd') return <></>

                                                if (field.hasOwnProperty("parentName")) {
                                                  const parentTitle = field.parentName;
                                                  const exists = data.fields.some(item => item.title === parentTitle && item.status !== 'd');
                                                  if (!exists) {
                                                    field.parent = null;
                                                    field.parentName = "";
                                                    field.parentValue = [];
                                                  }
                                                }

                                                    return (
                                                        <Draggable key={field._id} draggableId={field._id} index={index}>
                                                            {(provided) => (
                                                                <Accordion
                                                                    {...provided.draggableProps}
                                                                    {...provided.dragHandleProps}
                                                                    ref={provided.innerRef}
                                                                >
                                                                    <AccordionSummary>
                                                                        <Grid container>
                                                                            <Grid xs={11}>
                                                                                <Typography>{field.title}</Typography>
                                                                            </Grid>
                                                                            <Grid textAlign='right' xs={1}>
                                                                                <Icon icon='material-symbols:drag-indicator' width='25px' />
                                                                                <Icon onClick={(e) => {
                                                                                    e.stopPropagation()
                                                                                    handleFields('d', 'status', index)
                                                                                }} icon='material-symbols:delete-forever-outline' width='25px' />
                                                                            </Grid>
                                                                        </Grid>
                                                                    </AccordionSummary>
                                                                    <AccordionDetails>
                                                                        <Typography>
                                                                            <Grid container spacing={2} rowSpacing={2}>
                                                                                <Grid item xs={12} md={4}>
                                                                                    <TextField
                                                                                        size='small' fullWidth placeholder="Nome do campo" value={field.title} label={i18n.t('start.checklist.edit.title')} onChange={e => handleFields(e, 'title', index)} />
                                                                                </Grid>
                                                                                <Grid xs={12} item md={4}>
                                                                                    <TextField
                                                                                        size='small' fullWidth value={field.helperText} label={i18n.t('start.checklist.edit.helperText')} onChange={e => handleFields(e, 'helperText', index)} />
                                                                                </Grid>
                                                                                <Grid xs={12} item md={4}>
                                                                                    <TextField
                                                                                        size='small' value={field.placeholder} fullWidth label={i18n.t('start.checklist.edit.placeHolder')} onChange={e => handleFields(e, 'placeholder', index)} />
                                                                                </Grid>
                                                                                <Grid xs={12} item md={4}>
                                                                                    <FormControl fullWidth>
                                                                                        <InputLabel>{i18n.t('start.checklist.edit.type')}</InputLabel>
                                                                                        <Select
                                                                                            size='small'
                                                                                            value={field.type}
                                                                                            label={i18n.t('start.checklist.edit.type')}
                                                                                            onChange={e => handleFields(e, 'type', index)}
                                                                                        >
                                                                                            <MenuItem value='text'>{i18n.t('start.checklist.edit.text')}</MenuItem>
                                                                                            <MenuItem value={'textarea'}>{i18n.t('start.checklist.edit.textArea')}</MenuItem>
                                                                                            <MenuItem value={'number'}>{i18n.t('start.checklist.edit.count')}</MenuItem>
                                                                                            <MenuItem value={'money'}>{i18n.t('start.checklist.edit.money')} (R$)</MenuItem>
                                                                                            <MenuItem value={'select'}>{i18n.t('start.checklist.edit.uniqueList')}</MenuItem>
                                                                                            <MenuItem value={'multiselect'}>{i18n.t('start.checklist.edit.multipleList')}</MenuItem>
                                                                                            <MenuItem value={'url'}>{i18n.t('start.checklist.edit.url')}</MenuItem>
                                                                                            <MenuItem value={'date'}>{i18n.t('start.checklist.edit.date')}</MenuItem>
                                                                                            <MenuItem value={'email'}>{i18n.t('start.checklist.edit.email')}</MenuItem>
                                                                                            <MenuItem value={'radio'}>{i18n.t('start.checklist.edit.radio')}</MenuItem>
                                                                                            <MenuItem value={'checkbox'}>{i18n.t('start.checklist.edit.checkbox')}</MenuItem>
                                                                                            <MenuItem value={'attachment'}>{i18n.t('start.checklist.edit.attachment')}</MenuItem>
                                                                                        </Select>
                                                                                    </FormControl>
                                                                                </Grid>
                                                                                <Grid xs={12} item md={4}>
                                                                                    <FormControl fullWidth>
                                                                                        <InputLabel>{i18n.t('start.checklist.edit.condition')}</InputLabel>
                                                                                        <Select
                                                                                            size='small'
                                                                                            value={field.parentName || ''}
                                                                                            label={i18n.t('start.checklist.edit.condition')}
                                                                                            onChange={e => {
                                                                                                handleFields(e, 'parentName', index);
                                                                                                const parent = data.fields.find(item => item.title === e.target.value && item.status !== 'd');
                                                                                                handleFields({target: {value: parent?._id || null}}, 'parent', index);
                                                                                            }}
                                                                                        >
                                                                                            <MenuItem value={''}>{i18n.t('start.checklist.edit.noCondition')}</MenuItem>
                                                                                            {data.fields.filter(e => e.title !== field.title && ['select', 'multiselect', 'radio'].includes(e.type)).map((item, index) => (
                                                                                                <MenuItem key={index} value={item.title}>{item.title}</MenuItem>
                                                                                            ))}
                                                                                        </Select>
                                                                                    </FormControl>
                                                                                </Grid>
                                                                                {
                                                                                    field.parentName && <Grid item xs={12} md={4}>
                                                                                        <FormControl fullWidth>
                                                                                            <InputLabel>{i18n.t('start.checklist.edit.valueCondition')}</InputLabel>
                                                                                            <Select
                                                                                                multiple
                                                                                                size='small'
                                                                                                value={field.parentValue}
                                                                                                label={i18n.t('start.checklist.edit.valueCondition')}
                                                                                                onChange={e => handleFields(e, 'parentValue', index)}
                                                                                            >
                                                                                                {data.fields.find(e => e.title === field.parentName)?.options?.map((item, index) => (
                                                                                                    <MenuItem key={index} value={item}>{item}</MenuItem>
                                                                                                ))}
                                                                                            </Select>
                                                                                        </FormControl>
                                                                                    </Grid>
                                                                                }

                                                                                <Grid item md={4}>
                                                                                    <FormControlLabel control={<Checkbox checked={field.required} onChange={e => handleFields(e, 'required', index)} />} label={i18n.t('start.checklist.edit.mandatory')} />
                                                                                </Grid>

                                                                                {field.type === 'select' || field.type === 'multiselect' || field.type === 'radio' ? (
                                                                                    <Grid item xs={12}>
                                                                                        <p><b>{i18n.t('start.checklist.edit.options')}</b></p>
                                                                                        <Grid spacing={2} container rowSpacing={2}>
                                                                                            {field?.options && field?.options?.map((option, indexOpt) =>
                                                                                                <Grid item xs={4}>
                                                                                                    <TextField
                                                                                                        size='small' InputProps={{
                                                                                                            endAdornment: <InputAdornment style={{ cursor: 'pointer' }} onClick={() => {
                                                                                                                const aux = field?.options
                                                                                                                aux.splice(indexOpt, 1)
                                                                                                                handleFields(aux, 'options', index)
                                                                                                            }} position="end">x</InputAdornment>,
                                                                                                        }} value={option} onChange={(e) => handleOptions(e, index, indexOpt)} fullWidth />
                                                                                                </Grid>
                                                                                            )}
                                                                                        </Grid>

                                                                                        <Button sx={{ mt: 1 }} onClick={() => {
                                                                                            const aux = field?.options
                                                                                            aux.push("")
                                                                                            handleFields(aux, 'options', index)
                                                                                        }}>{i18n.t('start.checklist.edit.addOption')}</Button>
                                                                                    </Grid>
                                                                                ) : <></>}
                                                                            </Grid>
                                                                        </Typography>
                                                                    </AccordionDetails>
                                                                </Accordion>
                                                            )}
                                                        </Draggable>
                                                    )
                                                })}
                                                {provided.placeholder}
                                            </div>
                                        }}
                                    </Droppable>
                                </DragDropContext>
                                <Button sx={{ mt: 2 }} className='saveButton' onClick={addField} fullWidth><Icon style={{ marginRight: '10px' }} icon="material-symbols:add-circle" width="20px" />{i18n.t('start.checklist.edit.addField')}</Button>
                                <Grid container spacing={2} style={{ marginTop: '30px' }}>
                                    <Grid item xs={6}>
                                        <Button onClick={save} className='saveButton' fullWidth>{add ? i18n.t('start.checklist.edit.add2') : i18n.t('start.checklist.edit.save')}</Button>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Button onClick={() => {
                                            !add && deleteChecklist(data._id)
                                            setAdd(false)
                                            setData({})
                                        }} className='cancelButton' fullWidth>{add ? i18n.t('start.checklist.edit.cancel') : i18n.t('start.checklist.edit.delete')}</Button>
                                    </Grid>
                                </Grid>
                            </>
                            : <>{i18n.t('start.checklist.edit.selectItem')}</>}
                    </Container>
                </Grid>
            </Grid>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={openModal}
                onClose={() => setOpenModal(false)}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
            >
                <Fade in={openModal}>
                    <Box sx={style}>
                        <TextField fullWidth label={i18n.t('start.checklist.edit.searchIcon')} value={searchIcon} onChange={e => handleSearchIcons(e.target.value)} />
                        <Grid textAlign='center' container spacing={1} rowSpacing={3} style={{ marginTop: '5px' }}>
                            {icons.map((icon, index) => (
                                <Grid item key={index} xs={6} sm={4} md={1} lg={1} xl={1}>
                                    <Icon
                                        icon={icon}
                                        width="30px"
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => setData({ ...data, icon })}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    </Box>
                </Fade>
            </Modal>
        </div>
    );
};

const mapStateToProps = state => ({
    checklists: state.checklist.checklists
})


const mapDispatchToProps = (dispatch) => {
    return {
        getChecklists: () => dispatch(getChecklists()),
        handleAddChecklist: (data) => dispatch(handleAddChecklist(data)),
        getChecklist: (id) => dispatch(getChecklist(id)),
        deleteChecklist: (id) => dispatch(deleteChecklist(id)),
        handleUpdateChecklist: (data) => dispatch(handleUpdateChecklist(data)),
        handleUpdateChecklistOrder: (data) => dispatch(handleUpdateChecklistOrder(data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Edit);
