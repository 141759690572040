import { Box, Typography } from '@mui/material'
import React from 'react'
import { i18n } from '../../../../translate/i18n';
import { FCTextField } from 'components-fullcomm';

const Name = ({ name, setName}) => {
  return (
    <Box display="flex" height="fit-content" width="100%" alignItems="center">
      <Typography marginRight={'0.5rem'}>{i18n.t('sprint.utils.filter.name.name')}</Typography>
      <FCTextField
        fullWidth
        size='small'
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
    </Box>
  )
}

export default Name