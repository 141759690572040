import { Box, Button, CircularProgress, Step, StepLabel, Stepper } from '@mui/material'
import { FormikProvider, useFormik } from 'formik'
import React, { useCallback, useEffect, useState } from 'react'
import { Form } from 'reactstrap'
import * as Yup from "yup";
import * as actions from "../../../store/actions/action";
import * as actions2 from '../../../store/actions/activities';
import { getViewCustomer } from "../../../store/actions/customers";
import { connect } from 'react-redux';
import Type from './pages/Type';
import Details from './pages/Details';
import Activities from './pages/Activities';
import Analysis from './pages/Analysis';
import Revision from './pages/Revision';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { debounce } from 'lodash';
import { isMobile } from 'react-device-detect'
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { userPermissionCheck } from '../utils/userPermissionCheck';
import { parseQueryString, slugify } from '../../../utils/String';
import { i18n } from '../../../translate/i18n';
import Loading from '../utils/Loading';

const steps = ["Detalhes", "Atividades", "Análise", "Revisão"];

const NewAction = (props) => {
  const { search } = useLocation();
  const { platform } = props;
  const history = useHistory();
  const [page, setPage] = useState(-1);
  const [selectedActivities, setSelectedActivities] = useState([]);
  const [localActivities, setLocalActivities] = useState([]);
  const [checklist, setChecklist] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [preset, setPreset] = useState(false);
  const [query, setQuery] = useState({});
  const [creationStartDate, setCreationStartDate] = useState(new Date().toISOString())

  const updateUnfinishedCount = async (action) => {
    if (!isEdit) {
      await props.updateUnfinishedActionsCount(props.user._id, action)
    }
  }

  const NewActionSchema = Yup.object().shape({
    name: Yup.string().required(i18n.t('sprint.newAction.nameRequired')),
    category: Yup.string().when('type', {
      is: (val) => val === 'Melhoria' || val === 'Implementação',
      then: schema => schema,
      otherwise: Yup.string().required(i18n.t('sprint.newAction.categoryRequired'))
    }),
    start: Yup.string().required(i18n.t('sprint.newAction.startRequired')),
  });

  const formik = useFormik({
    enableReinitialize: true,
    validateOnMount: true,
    initialValues: {
      name: preset ? query.name : "",
      description: preset ? query.description : "",
      type: preset ? query.type : "",
      category: query.category
        ? JSON.stringify({ category: query.category, quadrant: query.quadrant })
        : "",
      quadrant: preset ? query.quadrant : "",
      score: preset ? query.score : "",
      channel: preset ? query.channel : "",
      start: query.start ? dayjs(query.start) : dayjs(new Date().toISOString().split("T")[0]),
      end: query.end ? dayjs(query.end) : "",
      trust: preset ? query.trust : 0,
      ease: preset ? query.ease : 0,
      impact: preset ? query.impact : 0,
      credits: preset ? query.credits : 0,
      score: preset ? query.score : 0,
      status: preset ? query.status : "Planejamento",
      metric: preset ? query.metric : "",
      utm: preset ? query.utm : "UTM",
      urlUtm: preset ? query.urlUtm : "",
      checklist: preset ? query.checklist : [],
      campaign: preset ? query.campaign : "",
      executionTime: preset ? query.executionTime : 0,
      perpetual: preset ? query.perpetual : false,
      requireEstimate: preset ? query.requireEstimate : false,
      userLanguage: localStorage.getItem('i18nextLng') || 'pt-BR',
      language: preset ? query.language : {},
      platform: preset ? query.platform : platform[0],
      creationStartDate,
    },
    validationSchema: NewActionSchema,

    onSubmit: async (values, { setSubmitting }) => {
      values.creationEndDate = new Date().toISOString()
      const { category = '', quadrant = '' } = JSON.parse(values.category || '{}');
      const formatedStart = values.start && dayjs(values.start).format("YYYY-MM-DD");
      let formatedEnd = values.end && dayjs(values.end).format("YYYY-MM-DD");
      if (values.perpetual) formatedEnd = values.start && dayjs(values.start).add(2, "years").format("YYYY-MM-DD");
      const formData = new FormData();
      selectedActivities.map(item => {
        item.history ? item.history.push({ status: item.status }) : item.history = [{ status: item.status }]
        return item.description?.map(item => {
          if (item !== null && item?.name == "file") {
            formData.append("file", item.value);
          }
        })
      });
      if ((localStorage.getItem('shop') === '64599fe285284405feff3961') || (localStorage.getItem('shop') === '6455addbdbf2797467494658')) {
        values.language[values.userLanguage] = { name: values.name, description: values.description }
      }
      let activityHasFile = false;
      if (values.userLanguage) delete values.userLanguage;
      if (values.campaign === '') delete values.campaign;
      values = {
        ...values,
        userId: props.user._id,
        start: formatedStart,
        end: formatedEnd,
        category,
        quadrant,
        score: values.ease * values.impact * values.trust,
        cycle: isEdit ? query.cycle : [props.cycle],
        activities: selectedActivities,
      };
      try {
        const result = isEdit
          ? await props.putAction(query._id, values)
          : await props.addAction(values);

        if (result.error) {
          switch (result.error.message) {
            case "Ação não existe!":
              toast.error(i18n.t('sprint.newAction.actionNotExist'));
              break;
            case "Erro na integração com Jira, tente salvar a ação novamente!":
              toast.error(i18n.t('sprint.newAction.errorIntegrationJira'));
              break;
            case "Ação não encontrada ou não modificada!":
              toast.error(i18n.t('sprint.newAction.actionNotFound'));
              break;
            case "Erro interno do servidor.":
              toast.error(i18n.t('sprint.newAction.internalError'));
              break;
            case "Essa loja não possui um contrato!":
              toast.error(i18n.t('sprint.newAction.contractNotFound'));
              break;
            case "Dados inválidos!":
              toast.error(i18n.t('sprint.newAction.invalidData'));
              break;
            case "Erro ao cadastrar ação!":
              toast.error(i18n.t('sprint.newAction.registerError'));
              break;
            default:
              toast.error(result.error.message);
          }
          setSubmitting(false);
        } else {
          if (values.file) {
            const formData = new FormData();
            for (let i = 0; i < values.file.length; i++) {
              formData.append("file", values.file[i]);
            }
            const handleUpload = async (uploadFunction, errorMessage) => {
              const upload = await uploadFunction(result.data, formData);
              if (upload.error) {
                switch (upload.error.message) {
                  case "Erro ao enviar arquivos!":
                    toast.error(i18n.t('sprint.newAction.sendError'));
                    break;
                  case "Erro ao atualizar arquivos!":
                    toast.error(i18n.t('sprint.newAction.errorUpdateFiles'));
                    break;
                  case "Erro interno do servidor.":
                    toast.error(i18n.t('sprint.newAction.internalError'));
                    break;
                  default:
                    toast.error(upload.error.message);
                }
              }
            };
            handleUpload(props.uploadActionFile, "Erro ao atualizar arquivos!");
            if (activityHasFile) {
              handleUpload(props.uploadActivitiesFile, "Erro ao enviar arquivos!");
            }
          }
          if (values.campaign) props.handleUpdateCampaign(values?.campaign?._id, { action: result.data });

          if (result.message === "Ação cadastrada com sucesso!") {
            toast.success(i18n.t('sprint.newAction.createSuccess'))
          } else if (result.message === "Ação atualizada com sucesso!") {
            toast.success(i18n.t('sprint.newAction.updateSuccess'))
          } else {
            toast.success(result.message);
          }
          updateUnfinishedCount('remove')
          setSubmitting(false);

          if (checklist && values.checklist?.length === 0) {
            values.type !== 'Melhoria' ?
              localStorage.setItem('previousPath', '/estrategia') :
              localStorage.setItem('previousPath', '/action-list');

            history.push(`/action/create-checklists/${result.data}`);
          } else {
            history.push(props.platform?.includes('sprint') ? `/edit-action/${result.data}` : `/support-edit-action/${result.data}`);
          }
        }
      } catch (error) {
        setSubmitting(false);
        if (error.error.message === "Erro interno do servidor.") {
          toast.error(i18n.t('sprint.newAction.internalError'))
        } else {
          toast.error(error.message);
        }
      }
    }
  })

  const { values, errors, touched, handleSubmit, isSubmitting, setFieldValue, setFieldTouched, getFieldProps, validateField, initialValues } = formik;

  const showPage = (page) => {
    switch (page) {
      case -1:
        return <Type updateUnfinishedCount={updateUnfinishedCount} setFieldValue={setFieldValue} setPage={setPage} platform={platform[0]} />
      case 0:
        return (
          <Details
            getFieldProps={getFieldProps}
            values={values}
            campaigns={props.campaigns}
            type={values.type}
            errors={errors}
            touched={touched}
            queryFiles={query.files}
            id={query._id}
            deleteActionFile={props.deleteActionFile}
          />
        )
      case 1:
        return (
          <Activities
            platform={platform[0]}
            selectedActivities={selectedActivities}
            setSelectedActivities={setSelectedActivities}
            setLocalActivities={setLocalActivities}
            localActivities={localActivities}
            setFieldValue={setFieldValue}
            values={values}
          />
        )
      case 2:
        return <Analysis getFieldProps={getFieldProps} values={values} />
      case 3:
        return (
          <Revision
            values={values}
            localActivities={localActivities}
            selectedActivities={selectedActivities}
            getFieldProps={getFieldProps}
            setFieldValue={setFieldValue}
            user={props.user}
            checklist={checklist}
            setChecklist={setChecklist}
            isEdit={isEdit}
            isEditing={isEditing}
            initialValues={initialValues}
            action={query}
          />
        )
      default:
        return null
    }
  }

  const setPageAndScroll = (nextPage) => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    };
    if (values.type === "Implementação" && nextPage === 2) {
      if (page === 1) nextPage = 3;
      else nextPage = 1;
    }

    if (nextPage === 1) {
      setFieldTouched('name');
      setFieldTouched('category');
      setFieldTouched('start');
      validateField('name');
      validateField('category');
      validateField('start');
      const hasErrors = Object.keys(formik.errors).length > 0;
      if (!hasErrors) {
        scrollToTop();
        setPage(nextPage);
      }
    } else {
      scrollToTop();
      setPage(nextPage);
    }
  };

  useEffect(() => {
    props.getViewCustomer(localStorage.getItem("shop"));
    props.getCampaigns();
    if (props.match.params.id) {
      setIsEdit(true);
      props.getAction(props.match.params.id);
    } else if (props.match.params.model) {
      props.getActionModel(props.match.params.model);
    }
    if (props.match.params.id || props.match.params.model) {
      setPreset(true);
      setPage(3);
    }
  }, [props.shop]);

  useEffect(() => {
    if (props.action && isEdit) {
      setQuery(props.action || {});
      const arr = props.action?.activities ? props.action?.activities.map(el => ({ ...el, activity: el.activity?._id })) : [];
      setSelectedActivities(arr);
      if (props.action?.checklist?.length > 0) {
        setChecklist(true);
      }
    }
  }, [props.action]);

  useEffect(() => {
    if (props.model && preset) {
      props.model.status = "Planejamento";
      setQuery(props.model || {});
      const arr = props.model?.activities ? Object.values(props.model?.activities) : [];
      setSelectedActivities(arr);
    }
  }, [props.model]);

  const getActivity = useCallback(
    debounce(async (_id) => {
      const params = new URLSearchParams({
        _id,
      });
      const arr = await props.getActivities(params);
      if (!arr.error?.error) setLocalActivities(arr);
      else setLocalActivities([]);
    }, 250),
    []
  );

  useEffect(() => {
    if (selectedActivities.length === 0) {
      setLocalActivities([]);
      return;
    }
    const params = selectedActivities.reduce((acc, activity) => {
      if (acc.find((id) => id === activity.activity)) {
        return acc;
      }
      return [...acc, activity.activity];
    }, []);
    getActivity(params);
  }, [selectedActivities]);

  useEffect(() => {
    setFieldValue('utm', slugify(values.name))
  }, [values.name]);

  useEffect(() => {
    const updateUrlUtm = debounce(() => {
      setFieldValue(
        'urlUtm',
        `${props.customer?.storeUrl}?utm_source=${values.channel}&utm_medium=${values.metric}&utm_campaign=${values.utm}`
      );
    }, 300);

    updateUrlUtm();

    return () => {
      updateUrlUtm.cancel();
    };
  }, [values.utm, values.channel, values.metric, props.shop]);

  useEffect(() => {
    if (search) {
      const parse = parseQueryString(search);
      setQuery(parse);
      if (parse.type === "Melhoria") {
        setFieldValue("type", "Melhoria");
        setFieldValue("name", parse.name);
        setPage(0);
      }
    }
  }, [search]);

  const handleLabel = (label) => {
    switch (label) {
      case "Detalhes": return i18n.t('sprint.newAction.details');
      case "Atividades": return i18n.t('sprint.newAction.activities');
      case "Análise": return i18n.t('sprint.newAction.analysis');
      case "Revisão": return i18n.t('sprint.newAction.revision');
      default: return label;
    }
  }

  return (
    <>
      <Loading loading={isSubmitting} />
      <Box sx={{ mt: "1rem", mx: "1rem" }}>
        {
          (page !== -1 && (isEditing || !isEdit)) ? (
            <Stepper activeStep={page}>
              {steps.map((label) => {
                return (
                  <Step key={label}>
                    <StepLabel>{isMobile ? "" : handleLabel(label)}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
          ) : (
            <Button
              className="btn-default"
              onClick={() => history.push(platform[0] === "sprint" ? "/action-list" : "/support-action-list")}
            >
              <ArrowBackIcon fontSize="small" />
              {i18n.t('sprint.newAction.backToList')}
            </Button>
          )
        }
      </Box>
      <FormikProvider value={formik}>
        <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
          <Box className="dashboard" mb={isMobile ? "8rem" : "4rem"} >
            {showPage(page)}
          </Box>
          {
            page !== -1 && userPermissionCheck(props.user, ['edit_action']) && !(props.user?.role === "customer" && values.requireEstimate) && (
              <Box
                position="fixed"
                bottom="0"
                height={isMobile ? "8rem" : "4rem"}
                width="-webkit-fill-available"
                backgroundColor="white"
                boxShadow={"4px -4px 4px rgb(0 0 0 / 8%)"}
                zIndex={990}
              >
                <Box position="relative">
                  {
                    (page === 3 || isEdit) && (
                      <Box
                        display="flex"
                        gap="1rem"
                        justifyContent="center"
                        alignItems='center'
                        position="absolute"
                        padding="0.5rem 1rem 0.5rem 1rem"
                        width="100%"
                      >
                        {
                          (isEdit && !isEditing) ? (
                            <Button
                              onClick={() => setIsEditing(true)}
                              className="btn-action medium"
                              variant="contained"
                              sx={{
                                zIndex: 1,
                              }}
                            >
                              {i18n.t('sprint.newAction.edit')}
                            </Button>
                          ) : (
                            <Button
                              onClick={() => handleSubmit()}
                              className="btn-action medium"
                              variant="contained"
                              sx={{
                                zIndex: 1,
                              }}
                            >
                              {isSubmitting ? (
                                <CircularProgress size={25} sx={{ color: "white" }} thickness={4} />
                              ) : (
                                <>{i18n.t('sprint.newAction.confirm')}</>
                              )}
                            </Button>
                          )
                        }
                        {
                          !isMobile && (
                            <Button
                              onClick={() => window.history.back()}
                              className="btn-gray medium"
                              variant="contained"
                              sx={{
                                zIndex: 1,
                              }}
                            >
                              {i18n.t('sprint.newAction.cancel')}
                            </Button>
                          )
                        }
                      </Box>
                    )
                  }
                  {
                    (isEditing || !isEdit) && (
                      <Box
                        display="flex"
                        justifyContent={(["Em andamento"].includes(query.status) && page === 1) ? "flex-end" : "space-between"}
                        alignItems="center"
                        position="absolute"
                        padding="0.5rem 1rem 0 1rem"
                        width="100%"
                      >
                        {
                          !(["Em andamento"].includes(query.status) && page === 1) && (
                            <Button
                              disableElevation
                              className='btn-default'
                              onClick={() => setPageAndScroll(["Em andamento"].includes(query.status) ? 1 : page - 1)}
                              sx={{ display: (page === -1 || ["Ativo", "Concluído"].includes(query.status)) ? "none" : "block" }}
                            >
                              {i18n.t('sprint.newAction.prev')}
                            </Button>
                          )
                        }
                        <Button
                          className='btn-default'
                          disableElevation
                          onClick={() => setPageAndScroll(["Em andamento"].includes(query.status) ? 3 : page + 1)}
                          sx={{ display: (page === -1 || page === 3) ? "none" : "block" }}
                        >
                          {i18n.t('sprint.newAction.next')}
                        </Button>
                      </Box>
                    )
                  }
                </Box>
              </Box>
            )
          }
        </Form>
      </FormikProvider>
    </>
  )
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  cycle: state.actions.cycle,
  action: state.actions.action,
  campaigns: state.actions.campaigns,
  model: state.actions.model,
  customer: state.customer.customerDetails,
  shop: state.auth.shop,
});

export default connect(mapStateToProps, { ...actions, ...actions2, getViewCustomer })(NewAction);
