import React, { useState } from 'react'
import { Modal, ModalBody } from 'reactstrap'
import { i18n } from '../../../translate/i18n'
import { toast } from 'react-toastify'
import { FCModalFooter, FCModalHeader } from 'components-fullcomm'

const MessageDeleteModal = ({ open, setOpen, deleteMessage, id, setDeleteId }) => {
  const [loading, setLoading] = useState(false);

  const processDeleteMessage = async () => {
    try {
      setLoading(true);
    const res  = await deleteMessage(id);
    setDeleteId(null);
    setLoading(false);
    res.error ? 
      toast.error(i18n.t('components.chat.chatConversation.messageDeletedError'))
    : 
      toast.success(i18n.t('components.chat.chatConversation.messageDeletedSuccess'));
    setOpen(false);
    } catch (error) {
      setLoading(false);
      toast.error(i18n.t('components.chat.chatConversation.messageDeletedError'));
    }
  }

  return (
    <Modal isOpen={open} centered>
        <FCModalHeader title={i18n.t('components.chat.chatConversation.deleteMessage')} onClick={() => setOpen(false)} />
        <ModalBody>
            {i18n.t('components.chat.chatConversation.confirmDelete')}
        </ModalBody>
        <FCModalFooter
          actionLabel={i18n.t('components.spinnerDelete.delete')}
          cancelLabel={i18n.t('components.chat.chatConversation.cancel')}
          handleAction={processDeleteMessage}
          handleCancel={() => setOpen(false)}
          loading={loading}
        />
    </Modal>
  )
}

export default MessageDeleteModal