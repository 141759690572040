import React, { useState } from 'react';
import Header from '../../../components/Header'
import Sidebar from '../../../components/Sidebar'
import { connect } from 'react-redux'
import * as actions from '../../../store/actions'
import { Container } from '@mui/material';
import '../../../styles/dashboard/index.css'

const WideDashboard = (props) => {
    const [sitMenu, setSitMenu] = useState(true);
    const [shop, setShop] = useState(localStorage.getItem('shop'))
    const changeShop = (value) => {
        setShop(value)
        props.handleShop(value)
    }
    const altSitMenu = () => {
        setSitMenu(!sitMenu)
    }
    return (
        <>
            <div className="d-flex">
                <Sidebar setShop={changeShop} shop={shop} active={sitMenu} handleLogout={props.handleLogout} />
                <div shop style={{ width: '100%' }}>

                    <Header handleLogout={props?.handleLogout} altSitMenu={altSitMenu} dataUser={props} />
                    {props.children.props.location.pathname !== '/form' ? <Container maxWidth={'false'} sx={{ marginTop: '20px', marginX: 0 }}>{{...props.children, shop}}</Container> : <div className='p-1'>{{...props.children, shop}}</div>}
                </div>
            </div>
        </>
    )

}

export default connect(null, actions)(WideDashboard);