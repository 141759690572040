import React, { useState } from 'react'
import { Box, Button, Divider, Icon, IconButton, TextField, Typography } from '@mui/material'
import { Modal, ModalBody, ModalFooter } from 'reactstrap'
import EditIcon from '@mui/icons-material/Edit';
import * as actions from "../../../../store/actions/action";
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { useField } from 'formik';
import { i18n } from '../../../../translate/i18n';

const EditCampaignModal = (props) => {
  const [campaign, , campaignHelpers] = useField("campaign");
  const [openModal, setOpenModal] = useState(false);
  const [campaignName, setCampaignName] = useState(props.campaign?.name);
  const [deleteModal, setDeleteModal] = useState(false);

  const handleUpdateCampaignName = async (id, name) => {
    try {
      const response = await props.updateCampaignName(id, name);
      if (response.error) {
        switch (response.error.message) {
          case "Campanha não encontrada.": return toast.error(i18n.t('sprint.newAction.utils.editCampaignModal.campaignNotFound'));
          case "Erro interno do servidor.": return toast.error(i18n.t('sprint.newAction.utils.editCampaignModal.internalError'));
          default: return toast.error(response.error.message)
        }
      }
      else {
        if (response.message === "Campanha atualizada com sucesso!") {
          toast.success(i18n.t('sprint.newAction.utils.editCampaignModal.campaignUpdated'))
        } else {
          toast.success(response.message)
        }
      }
      setOpenModal(false);
      props.getCampaigns();
      campaignHelpers.setValue(response.campaign);
    } catch (error) {
      if (error.error.message === "Erro interno do servidor.") {
        toast.error(i18n.t('sprint.newAction.utils.editCampaignModal.internalError'))
      } else {
        toast.error(error.error.message)
      }
    }
  }

  const handleDeleteCampaign = async (id) => {
    try {
      const response = await props.deleteCampaign(id);
      if (response.error) {
        switch (response.error.message) {
          case "Campanha não encontrada.": return toast.error(i18n.t('sprint.newAction.utils.editCampaignModal.campaignNotFound'));
          case "Erro interno do servidor.": return toast.error(i18n.t('sprint.newAction.utils.editCampaignModal.internalError'));
          default: return toast.error(response.error.message)
        }
      }
      else {
        if (response.message === "Sucesso ao deletar campanha!") {
          toast.success(i18n.t('sprint.newAction.utils.editCampaignModal.campaignDeleted'))
        } else {
          toast.success(response.message)
        }
      }
      setDeleteModal(false);
      setOpenModal(false);
      props.getCampaigns();
      campaignHelpers.setValue(null);
      setCampaignName('');
    } catch (error) {
      if (error.error.message === "Erro interno do servidor.") {
        toast.error(i18n.t('sprint.newAction.utils.editCampaignModal.internalError'))
      } else {
        toast.error(error.error.message)
      }
    }
  }

  return (
    <>
      <IconButton
        disabled={props.disabled}
        sx={{
          mb: '0.5rem'
        }}
        onClick={() => setOpenModal(!openModal)}
      >
        <EditIcon fontSize={props.isMobile ? "small" : "medium"} />
      </IconButton>
      <Modal centered toggle={() => setOpenModal(!openModal)} isOpen={openModal}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            padding: 2,
            alignItems: "center"
          }}
        >
          <Typography variant="h3">{i18n.t('sprint.newAction.utils.editCampaignModal.editCampaign')}</Typography>
          <Icon
            sx={{
              color: "#86888c",
              cursor: "pointer",
            }}
            onClick={() => setOpenModal(false)}
            className="material-icons-outlined"
            fontSize="large"
          >
            cancel
          </Icon>
        </Box>
        <Divider />
        <ModalBody>
          <TextField
            fullWidth
            size={props.isMobile ? "small" : "medium"}
            label="Campanha"
            variant="outlined"
            value={campaignName}
            onChange={(e) => setCampaignName(e.target.value)}
          />

        </ModalBody>
        <Divider />
        <Box display={"flex"} gap={1} justifyContent={'space-between'} padding={2}>
          <Box display={"flex"} gap={1}>
            <Button variant="contained" className="btn-action" onClick={() => handleUpdateCampaignName(props.campaign?._id, campaignName)}>
              {i18n.t('sprint.newAction.utils.editCampaignModal.apply')}
            </Button>
            <Button variant="contained" className="btn-reprove" onClick={() => setDeleteModal(true)}>
              {i18n.t('sprint.newAction.utils.editCampaignModal.delete')}
            </Button>
          </Box>
          <Button variant="contained" className="btn-gray" onClick={() => setOpenModal(!openModal)}>
            {i18n.t('sprint.newAction.utils.editCampaignModal.cancel')}
          </Button>
        </Box>
      </Modal>
      <Modal centered toggle={() => setDeleteModal(!deleteModal)} isOpen={deleteModal}>
        <Box

          sx={{
            display: "flex",
            justifyContent: "space-between",
            padding: 2,
            alignItems: "center"
          }}
        >
          <Typography variant="h3">{i18n.t('sprint.newAction.utils.editCampaignModal.deleteCampaign')}</Typography>
          <Icon
            sx={{
              color: "#86888c",
              cursor: "pointer",
            }}
            onClick={() => setDeleteModal(false)}
            className="material-icons-outlined"
            fontSize="large"
          >
            cancel
          </Icon>
        </Box>
        <Divider />
        <ModalBody>
          <Typography height={"5vh"}>{i18n.t('sprint.newAction.utils.editCampaignModal.confirmDelete')}</Typography>
        </ModalBody>
        <ModalFooter>
          <Box display={"flex"} gap={1}>
            <Button variant="contained" className="btn-reprove" onClick={() => handleDeleteCampaign(props.campaign?._id)}>
              {i18n.t('sprint.newAction.utils.editCampaignModal.remove')}
            </Button>
            <Button variant="contained" className="btn-gray" onClick={() => setDeleteModal(!deleteModal)}>
              {i18n.t('sprint.newAction.utils.editCampaignModal.cancel2')}
            </Button>
          </Box>
        </ModalFooter>
      </Modal>
    </>
  )
}
const mapStateToProps = (state) => ({
  user: state.auth.user,
});
export default connect(mapStateToProps, actions)(EditCampaignModal);