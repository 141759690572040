export const userPermissionCheck = (user, permissions, role) => {
    if (!user) return false;
    
    // if (user.role === "agency" && role !== "admin") return true;
    if (role && user.role !== role) return false;

    if (user.role === "admin") {
      const adminPermissions = user.permissionAdmin;
      const hasAllPermissions = permissions.some(permission => adminPermissions?.includes(permission));
      if (hasAllPermissions) {
        return true;
      }
    }
  
    const customer = localStorage.getItem("shop");
    const customerPermission = user.permissionCustomer?.find(permission => permission.customer === customer);
  
    if (customerPermission) {
      const customerActions = customerPermission.actions;
      const hasAllPermissions = permissions.some(permission => customerActions?.includes(permission));
      if (hasAllPermissions) {
        return true;
      }
    }
  
    return false;
  }