import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Avatar, Box, Typography, Button } from "@mui/material";
import { api } from "../../config";
import { toast } from "react-toastify";
import FullCommLogo from '../../assets/favicon.png';
import axios from "axios";
import * as actions from "../../store/actions/apps";

const ChatAutomaticMessage = ({ message, getApp, app, user, activeConversation, customer }) => {
  const msgDate = new Date(message?.createdAt);
  const currentDate = new Date();
  const startDate = new Date(app?.description?.start);
  const endDate = new Date(app?.description?.end);

  const isWeekend = () => {
    const currentDate = new Date();
    const dayOfWeek = currentDate.getDay();
  
    return dayOfWeek === 0 || dayOfWeek === 6;
  }
  

  const submit = async () => {
    try {
      await axios.post(`${api}/notifications/notify-support/${app?.description?.telephone}`, {platform: activeConversation, user, customer});
      toast.success('Solicitação enviada com sucesso! Em breve entraremos em contato.');
    } catch (error) {
      toast.error('Erro ao enviar solicitação. Tente novamente mais tarde.');
    }
  }

  useEffect(() => {
    getApp("category=Alertas");
  }, [])

  return (((msgDate >= startDate && msgDate <= endDate && currentDate >= startDate && currentDate <= endDate) || (app?.description?.recurring && isWeekend())) && app?.description?.active) ? (
    <Box
      sx={{
        display: "flex",
        alignItems: "flex-start",
        p: '10px',
        backgroundColor: "transparent",
      }}
    >
      <Avatar
        src={FullCommLogo}
        alt={"FullComm"}
        sx={{ mr: 1 }}
      />
      <Box className="message" sx={{ display: "flex", flexDirection: "column", width: '100%' }}>
        <Box sx={{ display: "flex", alignItems: "baseline" }}>
          <Typography variant="subtitle2" sx={{ fontWeight: "bold", mr: 1 }}>
            FullComm
          </Typography>
        </Box>
        <Box>
          <Typography variant="body2" whiteSpace="pre-line" mb="0.5rem">
            {app?.description?.message}
          </Typography>
          <Button variant="contained" className="btn-action" onClick={submit}>{app?.description?.btnText}</Button>
        </Box>
      </Box>
    </Box>
  ) : (
    <></>
  );
}

const mapStateToProps = (state) => ({
  app: state.app.app,
  user: state.auth.user,
  customer: state.customer.customerDetails,
});

export default connect(mapStateToProps, actions)(ChatAutomaticMessage)