import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { connect } from "react-redux";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Badge from '@mui/material/Badge';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import NotificationIcon from '../../pages/Notifications/Icon';
import HeaderButton from '../../pages/Sprint/utils/HeaderButtons';
import ChatSidebar from '../Chat/ChatSideBar'
import { useAuth0 } from '@auth0/auth0-react';
import { isMobile } from 'react-device-detect';
import { repository } from '../../config';
import { Avatar } from '@mui/material';
import { i18n } from '../../translate/i18n';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import KeyboardArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardArrowLeftOutlined';
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined';
import CreditBalanceModal from '../../pages/Sprint/utils/HeaderButtons/CreditBalanceModal';
import CycleSelector from '../../pages/Sprint/utils/HeaderButtons/CycleSelector';

const Header = ({ handleLogout, dataUser, altSitMenu, sitMenu, customer }) => {
    const { logout } = useAuth0();
    const location = useLocation();
    const currentRoute = location.pathname;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const isMenuOpen = Boolean(anchorEl);

    const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const menuId = 'primary-search-account-menu';
    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            id={menuId}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={isMenuOpen}
            onClose={handleMenuClose}
        >
            <MenuItem component={Link} to="/perfil">{i18n.t('components.header.perfil')}</MenuItem>
            <MenuItem onClick={() => {
                handleLogout();
                logout();
            }}>Sair</MenuItem>
        </Menu>
    );
    return (
        <>
            <Box sx={{ flexGrow: 1 }}>
                <AppBar sx={{ backgroundColor: 'white', color: '#6B7280', zIndex: 1001 }} position={isMobile ? 'static' : 'relative'}>
                    <Toolbar>
                        <IconButton
                            size="medium"
                            edge="start"
                            color="inherit"
                            aria-label="open drawer"
                            sx={{ mr: isMobile ? 0 : 1 }}
                            onClick={() => altSitMenu()}
                        >
                            {sitMenu ? (
                                    <KeyboardArrowLeftOutlinedIcon />
                                ) : (
                                (customer?.fileName === "default_product.jpg" || (!customer?.fileName && !customer?.fileLocation)) ? (
                                    <KeyboardArrowRightOutlinedIcon />
                                ) : (
                                    <Avatar src={customer?.fileLocation ? customer.fileLocation : `${repository}/files/customers/${customer?.fileName}`} />
                                )
                            )
                        }
                        </IconButton>

                        <Box sx={{ flexGrow: 1 }} />
                        {dataUser.platform?.includes("sprint") &&
                            <HeaderButton />
                        }
                        {
                            dataUser.platform?.includes("suporte") &&
                            <>
                                <Box display="none">
                                    <CycleSelector />
                                </Box>
                                <CreditBalanceModal platform="suporte" />
                            </>
                        }
                        <Box display={'flex'} gap={"1.5rem"} ml={isMobile ? "0.4rem" : "1rem"}>
                            <>
                                <NotificationIcon />
                            {/* {dataUser?.user?.role === 'customer' && !isMobile &&
                                <IconButton
                                    size="large"
                                    edge="start"
                                    color="inherit"
                                    onClick={() => setChatOpen(!chatOpen)}
                                >

                                    <Badge badgeContent={noReadMessages.total} color="secondary">
                                        <ChatBubbleIcon />
                                    </Badge>
                                    <ChatSidebar style={{ display: chatOpen ? 'block' : 'none', position: 'absolute', top: '64px', right: '0', zIndex: '999999' }} />
                                </IconButton>
                            } */}
                            </>
                        </Box>

                    </Toolbar>
                </AppBar>
                {renderMenu}
            </Box>
        </>
    );
}
 
const mapStateToProps = state =>
({
    authorized: state.auth.authorized,
    noReadMessages: state.message.noReadMessages || [],
    customer: state.customer.customerDetails,
})
export default connect(mapStateToProps, {})(Header);