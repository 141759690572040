import { Typography } from "@mui/material";
import React from "react";
import { statusColor } from "../statusColor";
import { i18n } from '../../../../translate/i18n';
import { FCChip } from "components-fullcomm";

const options = [
  "Ativo",
  "Backlog",
  "Em andamento",
  "Concluído",
  "Para fazer",
  "Planejamento",
  "Cancelado",
];

const Status = ({ status, setStatus }) => {
  const handleStatus = (value) => {
    setStatus((prevValue) => {
      const newValue = prevValue.includes(value)
        ? prevValue.filter((v) => v !== value)
        : [...prevValue, value];
      return newValue;
    });
  };

  const handleOption = (option) => {
    switch (option) {
      case "Ativo": return i18n.t('sprint.utils.filter.status.active')
      case "Backlog": return i18n.t('sprint.utils.filter.status.backlog')
      case "Em andamento": return i18n.t('sprint.utils.filter.status.inProgress')
      case "Concluído": return i18n.t('sprint.utils.filter.status.done')
      case "Para fazer": return i18n.t('sprint.utils.filter.status.toDo')
      case "Planejamento": return i18n.t('sprint.utils.filter.status.planning')
      case "Cancelado": return i18n.t('sprint.utils.filter.status.cancelled')
      default: return ""
    }
  }

  return (
    <>
      <Typography>{i18n.t('sprint.utils.filter.status.status')}</Typography>
      {options.map((option) => {
        let { color, type, bgcolor } = statusColor(option);
        let variant;
        if (status?.includes(option)) {
          variant = "filled";
          color = "black";
        } else {
          variant = "outlined";
        }
       
        return type === "cancelled" ? (
          <FCChip
            key={option}
            sx={{
              backgroundColor: "#f0f0f0",
              color: "#00033",
            }}
            size="small"
            label={handleOption(option)}
            onClick={() => handleStatus(option)}
          ></FCChip>
        ) : (
          <FCChip
            key={option}
            size="small"
            color={type}
            variant={variant}
            label={handleOption(option)}
            onClick={() => handleStatus(option)}
          ></FCChip>
        );
      })}
      <FCChip
        label={options.length == status.length ? i18n.t('sprint.utils.filter.status.unmarkAll') : i18n.t('sprint.utils.filter.status.markAll')}
        sx={{
          backgroundColor: "#f0f0f0",
          color: "#878c8e",
        }}
        size="small"
        onClick={() => setStatus(options.length == status.length ? [] : options)}
      ></FCChip>
    </>
  );
};

export default Status;
