const notifications_reducer = (state = {}, notifications) => {
  switch(notifications.type){
      case "GET_NOTIFICATIONS":
          return{
              ...state,
              notifications: notifications.payload
          }
      default: 
          return state;
  }
}

export default notifications_reducer;