import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../../store/actions/scale';
import {
    Stack, Grid, Box
} from '@mui/material';
import '../../../styles/dashboard/scale/PlatinumLog.scss';
import FilterBar from '../../../components/PlatinumLog/FilterBar';
import StatusView from '../../../components/PlatinumLog/StatusView';
import StatusErrorView from '../../../components/PlatinumLog/StatusErrorView';
import TableOrders from '../../../components/PlatinumLog/TableOrders';
import { debounce } from 'lodash';
import MediaTime from '../../../components/PlatinumLog/MediaTime';
import { Icon } from '@iconify/react';
import { LoadingButton } from '@mui/lab';
import { i18n } from '../../../translate/i18n'; 

const PlatinumLog = (props) => {
    const dispatch = useDispatch();
    const shop = useSelector(state => state.auth.shop);
    const ordersStatusPlatinum = useSelector(state => state.scale.ordersStatusPlatinum);
    const ordersPlatinum = useSelector(state => state.scale.ordersPlatinum);
    const [selectedStatus, setSelectedStatus] = useState([]);
    const [search, setSearch] = useState('');
    const [page, setPage] = useState(0);
    const [loadingExport, setLoadingExport] = useState(false);
    const today = new Date();
    const oneMonthAgo = new Date(today.getFullYear(), today.getMonth() - 1, today.getDate());
    const [begin, setBegin] = useState(oneMonthAgo.toISOString().split('T')[0]);
    const [end, setEnd] = useState(today.toISOString().split('T')[0]);
    const [isFilterBarFixed, setIsFilterBarFixed] = useState(false);

    const checkScroll = () => {
        const filterBarPosition = document.querySelector('.anchor').getBoundingClientRect().top;
        setIsFilterBarFixed(filterBarPosition < -65);
    };
    useEffect(() => {
        if (shop) {
            dispatch(actions.getOrdersStatusPlatinum({ options: { shop, begin, end, search } }));
        }
    }, [shop, dispatch]);

    useEffect(() => {
        if (shop) {
            dispatch(actions.getOrdersPlatinum({ options: { shop, begin, end, page, search, status: selectedStatus.toString() } }));
        }
    }, [shop, page, dispatch]);

    const debounceFetch = useCallback(
        debounce((query, beginF, endF, statusF) => {
            const date = new Date(beginF);
            const isValidBegin = !isNaN(date.getTime());
            const date2 = new Date(endF);
            const isValidEnd = !isNaN(date2.getTime());
            if (isValidBegin && isValidEnd) {
                dispatch(actions.getOrdersPlatinum({ options: { shop, begin: beginF, end: endF, page, search: query, status: statusF.toString() } }));
                dispatch(actions.getOrdersStatusPlatinum({ options: { shop, begin: beginF, end: endF, search: query } }));
            }
        }, 750),
        [shop]
    );

    useEffect(() => {
        if (shop) {
            debounceFetch(search, begin, end, selectedStatus);
        }
    }, [search, begin, end, selectedStatus])

    useEffect(() => {
        window.addEventListener('scroll', checkScroll);
        return () => window.removeEventListener('scroll', checkScroll);
    }, []);

    const exportData = async () => {
        setLoadingExport(true);
        await new Promise(r => setTimeout(r, 2000));
        const csv = await dispatch(actions.exportOrdersPlatinum({ options: { shop, begin, end, search, status: selectedStatus } }));
        const url = window.URL.createObjectURL(new Blob([csv.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `Pedidos-${new Date().getTime()}.csv`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        await new Promise(r => setTimeout(r, 2000));
        setLoadingExport(false);
    }


    return (
        <div style={{ position: 'relative' }}>
            <Stack className='dashboard anchor' direction={'row'} justifyContent={'space-between'} alignItems={'center'} >
                <h4>{i18n.t('scale.platinumlog.orders')}</h4>
                <LoadingButton loading={loadingExport}><Icon width={24} onClick={() => exportData()} icon="ph:export" /></LoadingButton>
            </Stack>
            <Box className={isFilterBarFixed ? 'fixed-filter-bar filter-bar' : 'filter-bar'} >
                <FilterBar begin={begin} setBegin={setBegin} end={end} setEnd={setEnd} search={search} setSearch={setSearch} selectedStatus={selectedStatus} setSelectedStatus={setSelectedStatus} />
            </Box>
            <div className='padding'>
                <Box className="search-box">
                    <br />
                    <StatusView ordersStatusPlatinum={ordersStatusPlatinum} setSelectedStatus={setSelectedStatus} />
                    <br />
                    <Stack direction={{ xs: 'row' }} columnGap={2} rowGap={2} className='box-status-error'>
                        <StatusErrorView ordersStatusPlatinum={ordersStatusPlatinum} setSelectedStatus={setSelectedStatus} />
                    </Stack>
                    <br />
                    <h4>{i18n.t('scale.platinumlog.timeIndicators')}</h4>
                    <Stack direction={{ tablet: 'row' }} columnGap={2} rowGap={2} className='box-media-time' >
                        <MediaTime ordersStatusPlatinum={ordersStatusPlatinum} />
                    </Stack>
                    <br />
                    <TableOrders dataOrders={ordersPlatinum} page={page} setPage={setPage} />
                </Box>
            </div >
        </div>

    )
}


export default (PlatinumLog);