import axios from 'axios';
import {api} from '../../config'
import {getHeaders} from './localStorage'
import errorHandling from './errorHandling'

export const getCustomers = (pageCurrent, limit, start, search, status = ['a'], cycle, platform = 'all') => async (dispatch) => {
    try {
        const response = await axios.get(api + `/customers?page=${pageCurrent}&limit=${limit}&start=${start}&search=${search}&status=${status}&cycle=${cycle}&platform=${platform}`, getHeaders());
        if(limit === 5555) {
            dispatch({ type: "GET_CUSTOMERS_SIDEBAR", payload: response.data });
        }else {
            dispatch({ type: "GET_CUSTOMERS", payload: response.data });
            return response.data;
        }
    } catch (err) {
        return errorHandling(err);
    }
}

export const getCustomersChecklist = (pageCurrent, limit, query, search) => async (dispatch) => {
    try {
        const response = await axios.get(api + `/customers-checklist?page=${pageCurrent}&query=${query}&limit=${limit}&search=${search}`, getHeaders());
        dispatch({ type: "GET_CUSTOMERS_CHECKLIST", payload: response.data });
    } catch (err) {
        return errorHandling(err);
    }
}

export const deleteCustomer = (_id, customers) => async (dispatch) => {
    try {
        const response = await axios.delete(api+"/customers/" + _id, getHeaders());
        return response.data;
    } catch (err) {
        return errorHandling(err);
    }
}

export const getViewCustomer = (id) => async (dispatch) => {
    try {
        const response = await axios.get(`${api}/customers/${id}`, getHeaders());
        dispatch({ type: 'GET_CUSTOMER', payload: response.data })
    } catch (error) {
        return errorHandling(error);
    }
}

export const getEpics = (id) => async (dispatch) => {
    try {
        const response = await axios.get(`${api}/roadmap/${id}`, getHeaders());
        dispatch({ type: 'GET_EPICS', payload: response.data })
    } catch (error) {
        return errorHandling(error);
    }
}

export const getProjects = (jiraCodes) => async (dispatch) => {
    try {
        const response = await axios.get(`${api}/advanced-roadmap?jiraCodes=${jiraCodes.join(',')}`, getHeaders());
        dispatch({ type: 'GET_EPICS', payload: response.data })
    } catch (error) {
        return errorHandling(error);
    }
}

export const getViewCustomerChecklist = (id) => async (dispatch) => {
    try {
        const response = await axios.get(`${api}/customers-checklist/${id}`, getHeaders());
        dispatch({ type: 'GET_CUSTOMER_CHECKLIST', payload: response.data })
        dispatch({ type: 'GET_CUSTOMER_CHECKLIST_INFOS', payload: response.data })
    } catch (error) {
        return errorHandling(error);
    }
}

export const handleAddCustomer = (props) => async () => {
    try {
        const response = await axios.post(api+"/customers", props, getHeaders());
        return response.data;
    } catch (err) {
        return errorHandling(err);
    }
}

export const handleAddTrialCustomer = (props) => async () => {
    try {
        const response = await axios.post(api+"/customers/trial", props, getHeaders());
        return response.data;
    } catch (err) {
        return errorHandling(err);
    }
}

export const handleUpdateCustomer = (props) => async () => {
    try {
        const response = await axios.put(api+"/customers", props, getHeaders());
        return response.data;
    } catch (err) {
        return errorHandling(err);
    }
}

export const handleUpdateCustomerChecklist = (id, props) => async () => {
    try {
        const response = await axios.put(api+"/customers-checklist/" + id, props, getHeaders());
        return response.data;
    } catch (err) {
        return errorHandling(err);
    }
}

export const clearCustomer = () => {
    return function(dispatch){
        dispatch({type: "CLEAR_CUSTOMER"})
    } 
}

export const clearCustomers = () => {
    return function(dispatch){
        dispatch({type: "CLEAR_CUSTOMERS"})
    } 
}

export const getMetrics = (platformDomain, cycle) => async (dispatch) => {
    try {
        const response = await axios.get(`${api}/customers-metrics?platformDomain=${platformDomain}&cycle=${cycle}`, getHeaders());
        dispatch({ type: 'GET_METRICS', payload: response.data })
    } catch (error) {
        return errorHandling(error);
    }
}