import React, { useState, useEffect, useRef, useContext } from "react";
import { AppBar, Badge, Box, CircularProgress, List, ListItem, Toolbar, Typography } from "@mui/material";
import { connect } from "react-redux";
import { sendMessage, getMessages, readMessages, getNoReadMessages, getMessagesAdd, getFiles, getMarks, unreadMessages } from "../../store/actions/messages";
import ChatSideBarAdm from "../../components/Chat/ChatSideBarAdm";
import ChatHeader from "../../components/Chat/ChatHeader";
import ChatBody from "../../components/Chat/ChatBody";
import ChatInput from "../../components/Chat/ChatInput";
import '../../styles/dashboard/Chat.css';
import { SocketContext } from "../../contexts/SocketContext";
import ChatHeaderDrawer from "../../components/Chat/ChatHeaderDrawer";
import { isMobile } from "react-device-detect";
import { i18n } from '../../translate/i18n';
import ChatThread from "../../components/Chat/ChatThread";
import { muteChatChannel } from "../../store/actions/users";
import { parseQueryString } from "../../utils/String";
import { userPermissionCheck } from "../Sprint/utils/userPermissionCheck";
import { handleShop } from "../../store/actions";
import { containsOnlyEmptyTags } from "./utils/containsOnlyEmptyTags";
import { getTaggedUsers } from "./utils/getTaggedUsers";

const deparaPlataforma = {
  start: {
    text: i18n.t('components.chat.chatSideBarAdm.implantation'),
  },
  sprint: {
    text: i18n.t('components.chat.chatSideBarAdm.performance'),
  },
  scale: {
    text: i18n.t('components.chat.chatSideBarAdm.operation'),
  },
  suporte: {
    text: i18n.t('components.chat.chatSideBarAdm.support'),
  },
  administrative: {
    text: i18n.t('components.chat.chatSideBarAdm.administrative'),
  },
}

const ChatAdm = (props) => {
  const socket = useContext(SocketContext);
  const divRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [activeConversation, setActiveConversation] = useState(parseQueryString(props.history.location.search)?.platform);
  const [selectedTab, setSelectedTab] = useState((activeConversation === 'platform' && !props.user?.legacyChatStyle) ? 0 : 1);
  const [conversations, setConversations] = useState([]);
  const [platforms, setPlatforms] = useState(['start', 'sprint', 'scale', 'suporte', 'administrative']);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [layout, setLayout] = useState('geral');
  const [messages, setMessages] = useState([]);
  const [noReadMessages, setNoReadMessages] = useState([]);
  const [loadingMessages, setLoadingMessages] = useState(false)

  const [thread, setThread] = useState({});
  const [threadMessages, setThreadMessages] = useState([]);
  const [unreadThreads, setUnreadThreads] = useState([]);

  const [oldMessages, setOldMessages] = useState(true);
  const [newMessages, setNewMessages] = useState(false);

  const [openDrawer, setOpenDrawer] = useState(false);

  useEffect(() => {
    setOldMessages(true);
  }, [activeConversation, props.position]);

  useEffect(() => {
    if (!socket) return;
    const customer = selectedCustomer;
    const platform = activeConversation;
    const receiveMessageHandler = async (data) => {
      if (data.message.author._id !== props.user._id) {
        if (data.message?.platform === platform && data.message?.customer === customer?._id) {
          props.readMessages({ customer: customer._id, platform })
          if (!newMessages) {
            if (data.message.parent && thread?._id === data.message.parent && thread?._id) {
              setThreadMessages(prev => ([...prev, data.message]));
              setMessages((prevMessages) => {
                return prevMessages.map((message) => {
                  if (message._id === data.message.parent) {
                    message.children = [...message.children, data.message];
                    return message;
                  }
                  return message;
                });
              });
            } else if (!data.message.parent) {
              setMessages((prevMessages) => [...prevMessages, data.message]);
            } else {
              setMessages((prevMessages) => {
                return prevMessages.map((message) => {
                  if (message._id === data.message.parent) {
                    message.children = [...message.children, data.message];
                    return message;
                  }
                  return message;
                });
              });
            }
          }     
        } else {
          props.getNoReadMessages();
        }
      }
    };

    const messageEditedHandler = async (data) => {
      if (data.message?.platform === platform && data.message?.customer === customer?._id) {
        if (data.message.parent && thread?._id === data.message.parent) {
          setThreadMessages(prev => {
            return prev.map((message) => {
              if (message._id === data.message._id) {
                return data.message;
              }
              return message;
            });
          });
        } else {
          setMessages((prevMessages) => {
            return prevMessages.map((message) => {
              if (message._id === data.message._id) {
                return data.message;
              }
              return message;
            });
          });
        }
      }
    };

    const connectHandler = () => {
        if (selectedCustomer?._id) {
          props.getMessages({ platform: activeConversation, customer: selectedCustomer._id });
          props.getFiles({ customer: selectedCustomer._id, platform: activeConversation, limit: 4 });
          props.getMarks({ customer: selectedCustomer._id, platform: activeConversation, limit: 4 });
        }
    };
    socket.on("connect", connectHandler);
    socket.on('receiveMessage', receiveMessageHandler);
    socket.on('messageEdited', messageEditedHandler);

    return () => {
      socket.off('receiveMessage', receiveMessageHandler);
      socket.off('messageEdited', messageEditedHandler);
      socket.off("connect", connectHandler);
    };
  }, [props.user, activeConversation, newMessages, selectedCustomer]);

  useEffect(() => {
    props.getNoReadMessages();
  }, []);

  useEffect(() => {
    setNoReadMessages(props.noReadMessages)
  }, [props.noReadMessages]);


  useEffect(() => {
    if (props.messages) {
      setMessages(props.messages)
    }
  }, [props.messages]);

  useEffect(() => {
    setUnreadThreads(props.messages.filter(message => message.children.some(child => !child.read?.includes(props.user?._id))))
  }, [messages]);

  useEffect(() => {
    setConversations(props.customers?.filter(elem => elem.platform?.length > 0))
  }, [props.customers]);

  const loadMoreMessages = async () => {
    if (oldMessages === false) return;
    if (activeConversation === null) return;
    const container = document.getElementById("chat-container");
    const oldHeight = container.scrollHeight;
    const newMessages = await props.getMessagesAdd({ customer: selectedCustomer._id, platform: activeConversation, beforeId: messages[0]?._id });
    if (newMessages.length === 0) {
      setOldMessages(false);
      setLoading(false);
      return;
    }
    setMessages((prevMessages) => {
      return [...newMessages, ...prevMessages];
    });
  };

  const loadMoreMessagesNew = async () => {
    if (newMessages === false) return;
    const newMessagesAux = await props.getMessagesAdd({ customer: selectedCustomer._id, platform: activeConversation, afterId: messages[messages.length - 1]?._id });
    if (newMessagesAux.length === 0) {
      setNewMessages(false);
      setLoading(false);
      return;
    }
    setMessages((prevMessages) => {
      return [...prevMessages, ...newMessagesAux];
    });
  };

  const scrollToBottom = () => {
    const chatContainer = document.getElementById("chat-container");
    if (chatContainer) {
      chatContainer.scrollTop = chatContainer.scrollHeight + 90;
    }
  };

  const handleInputChange = (text) => {
    setInputValue(text);
  };

  const handleSendMessage = async (event, files, action, taggedData) => {
    event.preventDefault();
    if (containsOnlyEmptyTags(inputValue) && files.length === 0 && !action) return;

    const taggedUsersData = getTaggedUsers(inputValue);
    const taggedUsers = taggedData.filter((tagged) => taggedUsersData?.includes(tagged) || taggedUsersData?.includes('all'));
    const formData = new FormData();
    files.forEach((file) => {
      formData.append("files", file);
    });
    formData.append("text", inputValue);
    formData.append("platform", activeConversation);
    if (taggedUsers) formData.append("taggedUsers", taggedUsers);
    if (action) formData.append("action", action);
    const newMessage = await props.sendMessage(formData, selectedCustomer._id);

    setMessages([...messages, newMessage]);
    setInputValue("");
    scrollToBottom();
  };

  const handleConversationChange = async (e) => {
    if (!e.customer)  return setSelectedCustomer(null);
    setLoadingMessages(true);
    setSelectedCustomer(e.customer);
    // props.handleShop(e.customer._id);
    await props.getMessages({ platform: e.platform, customer: e.customer._id })
    props.getNoReadMessages();
    props.getFiles({ customer: e.customer._id, platform: e.platform, limit: 4 })
    props.getMarks({ customer: e.customer._id, platform: e.platform, limit: 4 })
    setLoadingMessages(false);
  };

  useEffect(() => {
    if(thread?._id) setOpenDrawer(false);
  }, [thread]);

  useEffect(() => {
    if (openDrawer) setThread({});
  }, [openDrawer]);

  useEffect(() => {
    if (activeConversation === 'platform' || !selectedCustomer) setMessages([]);
    setThread({});
  }, [activeConversation, selectedCustomer]);

  useEffect(() => {
    setActiveConversation(parseQueryString(props.history.location.search)?.platform);
  }, [props.history.location.search]);

  useEffect(() => {
    const platforms = ['start', 'sprint', 'scale', 'suporte', 'administrative'].map((c) => ({
      conversation: c,
      text: deparaPlataforma[c].text,
      unreadMessages: Object.values(noReadMessages).reduce((sum, customer) => sum + (customer[c] || 0), 0),
      taggedAmount: Object.values(noReadMessages).reduce((sum, customer) => sum + (customer['tagged_' + c] || 0), 0),
    })).sort((a, b) => b.taggedAmount - a.taggedAmount || b.unreadMessages - a.unreadMessages);
    setPlatforms(platforms);
  }, [noReadMessages, deparaPlataforma]);  

  return (
    <Box sx={{
      display: "flex", 
      height: isMobile ? window.matchMedia('(display-mode: standalone)').matches ? `calc(100vh - ${(activeConversation === 'platform' || !selectedCustomer) ? '130px' : '190px'})` : `calc(100vh - ${(activeConversation === 'platform' || !selectedCustomer) ? '210px' : '255px'})` : "calc(100vh - 4rem)", 
      position: 'relative',
      overflow: 'hidden',
    }}>
        {
          (selectedTab === 0 && !props.user?.legacyChatStyle) ? (
            <Box 
              className={`chat-sidebar`}
              sx={{
                transition: 'all 0.5s ease',
              }}
            >
            {
              isMobile ? (
                <AppBar position="relative" 
                sx={{
                  backgroundColor: '#F3F3F3',
              }}>
                  <Toolbar>
                      <Typography variant="h6" style={{ flexGrow: 1, color: 'black' }} align="center">
                          {i18n.t('components.chat.chatSideBar.channels')}
                      </Typography>
                  </Toolbar>
              </AppBar>
              ) : (
                <Box display='flex' width='100%' padding='1rem' alignItems='center'>
                  <Typography variant="h6" style={{ flexGrow: 1 }} align="center">
                    {i18n.t('components.chat.chatSideBar.channels')}
                  </Typography>
                </Box>
              )
            }
            <List>
              {
                platforms.map((platform, index) => (
                  userPermissionCheck(props.user, [`chat_${platform.conversation}`]) && (
                    <ListItem
                      key={platform.conversation}
                      onClick={() => {
                        setActiveConversation(platform.conversation);
                        setSelectedTab(1);
                      }}
                      sx={{
                        cursor: "pointer",
                        display: "flex",
                        justifyContent: "space-between",
                        backgroundColor: "inherit",
                        padding: '16px 15px 16px 15px',
                        borderTop: index === 0 ?  'none' : '1px solid #E0E0E0',
                        borderBottom: '1px solid #E0E0E0',
                        '&:hover': {
                          backgroundColor: "#e3e3e3 !important",
                        }
                      }}
                    >
                      <Typography fontWeight={platform.unreadMessages ? 700 : 400}>{platform.text}</Typography>
                      <Badge badgeContent={platform.unreadMessages} color="greenNotification" sx={{marginRight: "0.5rem"}}/>
                    </ListItem>
                  )
                ))
              }
            </List>
          </Box>
          ) : (selectedTab === 1 || !isMobile) && (
            <ChatSideBarAdm
              setSelectedTab={setSelectedTab}
              noReadMessages={noReadMessages}
              conversations={conversations}
              activeConversation={activeConversation}
              setActiveConversation={setActiveConversation}
              customer={selectedCustomer}
              handleConversationChange={handleConversationChange}
              unreadChatMessages={props.unreadChatMessages}
              user={props.user}
              getNoReadMessages={props.getNoReadMessages}
              muteChatChannel={props.muteChatChannel}
            />
          ) 
        }
      <Box 
        sx={{ 
          display: ((openDrawer || thread?._id) && isMobile) ? 'none' : 'flex',
          flexGrow: 1, 
          overflowX: 'hidden',
          flexDirection: "column", 
          marginRight: ((openDrawer || thread?._id) && !isMobile) ? '375px' : '0px', 
          marginLeft: !isMobile ? '350px' : '0px',
          transition: 'all 0.5s ease',
          backgroundColor: 'white',
        }}
      >
        {
          (!isMobile || (['start', 'sprint', 'scale', 'suporte', 'administrative'].includes(activeConversation) && selectedCustomer)) && (
            <ChatHeader 
              setThread={setThread}
              unreadThreads={unreadThreads} 
              setUnreadThreads={setUnreadThreads}
              setSidebarOpen={()=>{}} 
              isSidebarOpen={true} 
              adm={true} 
              setSelectedTab={setSelectedTab}
              setActiveConversation={setActiveConversation}
              activeConversation={activeConversation} 
              setOpenDrawer={setOpenDrawer} 
              openDrawer={openDrawer}
              setSelectedCustomer={setSelectedCustomer}
              selectedCustomer={selectedCustomer}
            />
          )
        }
        { ((!thread?._id && !openDrawer && selectedCustomer && activeConversation !== 'platform' && isMobile) || !isMobile) && (
          loadingMessages ? (
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                <CircularProgress />
              </Box>
            ) :
            <>
              <ChatBody
                loadMoreMessages={loadMoreMessages}
                messages={messages}
                user={props.user}
                divRef={divRef}
                loading={loading}
                setLoading={setLoading}
                activeConversation={activeConversation}
                oldMessages={oldMessages}
                newMessages={newMessages}
                loadMoreMessagesNew={loadMoreMessagesNew}
                setThread={setThread}
              />
              {selectedCustomer && (
                <ChatInput
                  inputValue={inputValue}
                  customer={selectedCustomer?._id}
                  handleInputChange={handleInputChange}
                  handleSendMessage={handleSendMessage}
                  platform={activeConversation}
                />
              )}
            </>
          )
        }
      </Box>
      <ChatHeaderDrawer 
        user={props.user} 
        open={openDrawer}
        openDrawer={setOpenDrawer} 
        getMessages={props.getMessages} 
        files={props.files} 
        getFiles={props.getFiles} 
        marks={props.marks} 
        getMarks={props.getMarks} 
        shop={selectedCustomer?._id} 
        activeConversation={activeConversation} 
        newMessages={newMessages} 
        setNewMessages={setNewMessages} 
        layout={layout}
        setLayout={setLayout}
      />
      <ChatThread 
        thread={thread} 
        setThread={setThread}
        threadMessages={threadMessages}
        setThreadMessages={setThreadMessages} 
        setMessages={setMessages}        
      />
    </Box>
  );
};

const mapStateToProps = state =>
({
  user: state.auth.user,
  customers: state.customer?.customersSidebar?.docs || [],
  messages: state.message.messages || [],
  noReadMessages: state.message.noReadMessages || [],
  files: state.message.files || [],
  marks: state.message.marks || [],
  position: state.message.position || null
})


const mapDispatchToProps = (dispatch) => {
  return {
    sendMessage: (props, shop) => dispatch(sendMessage(props, shop)),
    getMessages: (props) => dispatch(getMessages(props)),
    getNoReadMessages: () => dispatch(getNoReadMessages()),

    getFiles: (props) => dispatch(getFiles(props)),
    getMarks: (props) => dispatch(getMarks(props)),
    getMessagesAdd: (props) => dispatch(getMessagesAdd(props)),
    readMessages: (props) => dispatch(readMessages(props)),

    muteChatChannel: (props) => dispatch(muteChatChannel(props)),
    unreadChatMessages: (id, shop, platform) => dispatch(unreadMessages(id, shop, platform)),
    handleShop: (shop) => dispatch(handleShop(shop)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChatAdm);