import { FormControl, FormHelperText, Typography } from '@mui/material'
import React from 'react'
import { isMobile } from "react-device-detect";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/pt-br";
import 'dayjs/locale/es';
import { i18n } from '../../../../translate/i18n';
import { useField } from 'formik';

const Icon = () => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.6 1.27273H11.9V0H10.5V1.27273H3.5V0H2.1V1.27273H1.4C0.63 1.27273 0 1.84545 0 2.54545V12.7273C0 13.4273 0.63 14 1.4 14H12.6C13.37 14 14 13.4273 14 12.7273V2.54545C14 1.84545 13.37 1.27273 12.6 1.27273ZM12.6 12.7273H1.4V5.72727H12.6V12.7273ZM12.6 4.45455H1.4V2.54545H12.6V4.45455Z" fill="#888888"/>
    </svg>

  )
}

export const BaseDatePicker = ({ fieldName, disabled, getFieldProps, errors, touched }) => {
  const [field, , helper] = useField(fieldName);
  const [start] = useField("start");
  const value = field.value
  const label = fieldName === "start" ? i18n.t("sprint.newAction.pages.details.startBroadcast") : i18n.t("sprint.newAction.pages.details.endBroadcast");
  const helperText = fieldName === "start" ? i18n.t("sprint.newAction.pages.details.futureDate") : i18n.t("sprint.newAction.pages.details.endDate");
  const minDate = fieldName === "start" ? '' : start.value;
  return (
    <FormControl
      fullWidth
    >
      <Typography variant='body2' mb='0.5rem' color={'#000'}>{label}</Typography>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={localStorage.getItem('i18nextLng') === "pt-BR" ? "pt-br" : localStorage.getItem('i18nextLng')}>
        <DatePicker
          disabled={disabled}
          {...getFieldProps(fieldName)}
          minDate={minDate}
          slots={{
            openPickerIcon: Icon
            
          }}
          slotProps={{
            textField: {
              error: Boolean(touched[fieldName] && errors[fieldName]),
              size: isMobile ? "small" : "medium",
              sx: {
                border: '0.5px solid #888888',
                boxShadow: '0px 1px 0px 0px #00000040 inset',
                borderRadius: '8px',
                '& fieldset': {
                  border: 'none',
                },
              }
            },
          }}
          value={disabled ? '' : value}
          onChange={(date) => {
            helper.setValue(date || '');
          }}
        />
      </LocalizationProvider>
      {touched[fieldName] && errors[fieldName] && (
        <FormHelperText>{errors[fieldName]}</FormHelperText>
      )}
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
};