import { Typography, Box } from "@mui/material";
import React from "react";
import { statusColor } from "../utils/statusColor";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { userPermissionCheck } from "../utils/userPermissionCheck";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import SportsScoreIcon from '@mui/icons-material/SportsScore';
import dayjs from "dayjs";
import { FCChip } from "components-fullcomm";

const redirectUser = (action, user, history) => {
  if (userPermissionCheck(user, ['view_action'])) history.push(`/edit-action/${action._id}`);
}

const ActionCard = ({ action, user, handleClick = redirectUser }) => {
  const history = useHistory();
  const { bgcolor } = statusColor(action.status);

  return (
    <Box
      onClick={() => handleClick(action, user, history)} 
      key={action._id}
      maxWidth={'300px'}
      margin={"0 5px 5px 5px"}
      padding={"0.5rem"}
      bgcolor={"#fafafa"}
      borderRadius={"5px"}
      borderBottom={`solid 3px ${bgcolor}`}
      display={"grid"}
      alignItems={"center"}
      overflow={"hidden"}
      style={{cursor: userPermissionCheck(user, ['view_action']) ? "pointer" : "default"}}
    >
      <Typography variant="body1">
        {action.name}
      </Typography>
      {
        action.campaign && (
          <FCChip label={action.campaign?.name} size="small" color="campaign"  sx={{width: "fit-content", mt: "5px", height: "18px", fontSize: '12px'}}/>
        )

        
      }
      <Box display={"flex"} gap="0.5rem" mt="5px">
        <Box display="flex" alignItems="center">
          <CalendarMonthIcon sx={{height: "10px", color: '#888888'}}/>
          <Typography color='#888888' fontSize="10px">
            {dayjs(action.start).format("DD/MM/YY")} 
            {
              action.end && (
                <>
                  {" "}- {dayjs(action.end).format("DD/MM/YY")}
                </>
              )
            }
          </Typography>
        </Box>
        <Box display="flex" alignItems="center">
          <MonetizationOnIcon sx={{height: "10px", color: '#888888'}}/>
          <Typography color='#888888' fontSize="10px">
            {action.credits}
          </Typography>
        </Box>
        <Box display="flex" alignItems="center">
          <SportsScoreIcon sx={{height: "10px", color: '#888888'}}/>
          <Typography color='#888888' fontSize="10px">
            {action.score}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default ActionCard;
