import { Paper, Typography, Grid, Icon, Button, useMediaQuery, Tabs, Tab } from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { Box } from "@mui/system";
import ActionModal from "../V2NewAction/ActionFloatingButton";
import * as actions from "../../../store/actions/action";
import Filter from "../utils/Filter";
import ActionCardArea from "./ActionCardArea";
import ActionCardAreaMobile from "./ActionCardAreaMobile";
import { categories } from "../utils/objects";
import { updateActionsTrimester } from "../utils/updateActionsTrimester";
import { toast } from "react-toastify";
import TrimesterUpdateModal from "../utils/TrimesterUpdateModal";
import { isMobile } from "react-device-detect";
import Slider from 'react-slick';
import { i18n } from "../../../translate/i18n";

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
const sections = [
  ["Descoberta", "Ativação"],
  ["Conteúdo", "Aquisição"],
  ["Próxima Compra", "Engajamento"],
  ["Barreiras de Compra", "Conversão"],
  ["Análise de experiência", "Retenção"],
  ["Consideração", "Ativação"],
  ["Rastreamento", "Aquisição"],
  ["Contexto", "Engajamento"],
  ["Ofertas", "Conversão"],
  ["Transmissão", "Retenção"],
  ["Prospecção", "Ativação"],
  ["Benefício", "Aquisição"],
  ["Recuperação", "Engajamento"],
  ["Autoridade", "Conversão"],
  ["Segmentos", "Retenção"],
];

const Strategy = (props) => {
  const [actions, setActions] = useState(props.actions);
  const [open, setOpen] = useState(false);
  const isMobileDevice = useMediaQuery('(max-width:600px)');
  const [activeTab, setActiveTab] = useState(0);
  const sliderRef = useRef(null);
  const sliderSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: '10%',
    beforeChange: (current, next) => setActiveTab(next),
  };


  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
    sliderRef.current.slickGoTo(newValue);
  };

  useEffect(() => {
    setActions(props?.actions);
  }, [props.actions]);

  const updateActions = (actions) => {
    try {
      const result = props.handleUpdateActionsTrimester(updateActionsTrimester(actions));
      if (result.error) {
        if (result.error.message === "Erro interno do servidor.") {
          toast.error(i18n.t('sprint.list.internalError'));
        } else {
          toast.error(result.error.message);
        }
      } else {
        toast.success(i18n.t('sprint.list.updatedSuccess'));
      }

    } catch (error) {
      if (error.error.message === "Erro interno do servidor.") {
        toast.error(i18n.t('sprint.list.internalError'));
      } else {
        toast.error(error.error.message);
      }
    }
  };

  const CardHeader = ({ title }) => {
    const icon = categories.find((obj) => obj.name === title)?.icon;
    return (
      <Grid item xs={isMobileDevice ? 12 : 2.4}>
        <Paper elevation={0}>
          <Box
            sx={{
              padding: "1rem",
              background: "#F4F4F4",
              borderTopLeftRadius: "5px",
              borderTopRightRadius: "5px",
              overflow: "hidden",
            }}
          >
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <Typography variant="h5">
                {handleTitle(title)}
              </Typography>
              <Icon className="material-icons-outlined" fontSize="large">
                {icon}
              </Icon>
            </Box>
          </Box>
        </Paper>
      </Grid>
    );
  };

  const handleTitle = (title) => {
    switch (title) {
      case "Ativação": return i18n.t('sprint.strategy.activation');
      case "Aquisição": return i18n.t('sprint.strategy.acquisition');
      case "Engajamento": return i18n.t('sprint.strategy.engagement');
      case "Conversão": return i18n.t('sprint.strategy.conversion');
      case "Retenção": return i18n.t('sprint.strategy.retention');
      default: return "";
    }
  };

  return (
    <Box className="dashboard" paddingBottom={isMobile ? '66px' : '1rem'}>
      <ActionModal />
      <Grid container marginBottom={"1rem"} columnSpacing={"1rem"}>
        <Grid item md={10} xs={12}>
          <Typography variant="h4">
            {i18n.t('sprint.strategy.actionsQuadrant')}
          </Typography>
        </Grid>
        <Grid display={"grid"} justifyContent={"right"} item md={2} xs={12} marginTop={isMobileDevice ? 1 : 0}>
          {
            (props.user?.role === "admin" && !isMobile) && (
              <Button
                sx={{ alignSelf: "center", width: "10rem" }}
                variant="contained"
                className="btn-gray large"
                onClick={() => setOpen(true)}
              >
                {i18n.t('sprint.strategy.updateActions')}
              </Button>
            )
          }
        </Grid>
      </Grid>

      <Filter type={"Strategy"} typeFilter={"Campanha,Implementação"} />
      <TrimesterUpdateModal actions={props.actions?.docs} open={open} setOpen={setOpen} handleUpdate={updateActions} />
      <Box>
      </Box>
      {isMobileDevice ? (
        <div>
          <Tabs value={activeTab} onChange={handleTabChange}
            variant="scrollable"
            scrollButtons="auto">
            {categories.map((elem, index) => (
              <Tab key={index} label={handleTitle(elem.name)} />
            ))}
          </Tabs>
          <br />
          <Slider ref={sliderRef}  {...sliderSettings}>
            {categories.map((elem) => (
              <div key={elem.name}>
                <CardHeader title={elem.name} />
                <ActionCardAreaMobile
                  actions={actions?.docs}
                  category={elem.name}
                  quadrant={elem.quadrants}
                />
              </div>
            ))}
          </Slider>
        </div>
      ) : (
        <Grid container columnSpacing={"1vw"}>
          <CardHeader title={"Ativação"} />
          <CardHeader title={"Aquisição"} />
          <CardHeader title={"Engajamento"} />
          <CardHeader title={"Conversão"} />
          <CardHeader title={"Retenção"} />

          {sections.map((quadrants) => {
            const [quadrant, category] = quadrants;
            return (
              <ActionCardArea
                user={props.user}
                key={quadrant}
                actions={actions?.docs}
                quadrant={quadrant}
                category={category}
              />
            );
          })}
        </Grid>
      )}
    </Box>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  actions: state.actions.actions,
  cycle: state.actions.cycle,
});

export default connect(mapStateToProps, actions)(Strategy);
