import { Box, Button, CircularProgress, Typography } from '@mui/material'
import React, { useState } from 'react'
import { Modal, ModalBody } from 'reactstrap'
import { toast } from 'react-toastify'
import { connect } from 'react-redux'
import * as actions from '../../../../../store/actions/contracts'
import * as actions2 from '../../../../../store/actions/action'
import { i18n } from '../../../../../translate/i18n'
import { FCModalHeader, FCTextField } from 'components-fullcomm'

const EditCreditsModal = (props) => {
    const [open, setOpen] = useState(false)
    const [credits, setCredits] = useState(0)
    const [comment, setComment] = useState('')
    const [loading, setLoading] = useState(false)

    const handleBilling = async (type) => {
        try {
            const today = new Date(props.date);
            const month = today.getMonth();
            const year = today.getFullYear();

            const body = {
                credits: Math.abs(credits > 0 ? credits : 0),
                date: `${year}-${month}`,
                type,
                platform: props.platform,
                contract: props.contract?._id,
                customer: props.customer?._id,
                comment,
                cycle: props.cycle
            }

            const response = await props.addBilling(body);

            if (response.error) {
                switch (response.error.message === "") {
                    case "Não foi possível encontrar o contrato!": return toast.error(i18n.t('sprint.utils.headerButtons.creditBalanceModal.editCreditsModal.contractNotFound'))
                    case "Não foi possível executar a solicitação!": return toast.error(i18n.t('sprint.utils.headerButtons.creditBalanceModal.editCreditsModal.registerError'))
                    case "Erro interno do servidor.": return toast.error(i18n.t('sprint.utils.headerButtons.creditBalanceModal.editCreditsModal.internalError'))
                    default: return toast.error(response.error.message)
                }
            } else {
                if (response.message === "Cobrança cadastrada com sucesso!") {
                    toast.success(i18n.t('sprint.utils.headerButtons.creditBalanceModal.editCreditsModal.registerSuccess'))
                } else {
                    toast.success(response.message);
                }
            }
            setCredits(0);
            setOpen(false);
            props.getViewContractSpecific();
        } catch (error) {
            if (error.error.message === "Erro interno do servidor.") {
                toast.error(i18n.t('sprint.utils.headerButtons.creditBalanceModal.editCreditsModal.internalError'))
            } else {
                toast.error(error.error.message)
            }
        }
    }

    const handleSync = async () => {
        setLoading(true);
        try {
            if (loading) return;

            const response = await props.syncChargedActions(props.cycle);
            props.getViewContractSpecific();

            if (response.error) {
                if (response.error.message === "Erro interno do servidor.") {
                    toast.error(i18n.t('sprint.utils.headerButtons.creditBalanceModal.editCreditsModal.internalError'))
                } else {
                    toast.error(response.error.message)
                }
            }
            else {
                if (response.message === "Ações sincronizadas com sucesso!") {
                    toast.success(i18n.t('sprint.utils.headerButtons.creditBalanceModal.editCreditsModal.syncSuccess'))
                } else {
                    toast.success(response.message);
                }
            }
        } catch (error) {
            if (error.error.message === "Erro interno do servidor.") {
                toast.error(i18n.t('sprint.utils.headerButtons.creditBalanceModal.editCreditsModal.internalError'))
            } else {
                toast.error(error.error.message)
            }
        }
        setLoading(false);
    }

    return (
        <>
            <Button className='btn-action medium' onClick={() => setOpen(true)}>
                {i18n.t('sprint.utils.headerButtons.creditBalanceModal.editCreditsModal.edit')}
            </Button>
            <Modal centered={true} isOpen={open} toggle={() => setOpen(false)}>
                <FCModalHeader onClick={() => setOpen(false)}title={i18n.t('sprint.utils.headerButtons.creditBalanceModal.editCreditsModal.editCredits')} />
                <ModalBody>
                    <Box display="flex" flexDirection={"column"} gap={"1rem"}>
                        <Box display="flex" justifyContent="space-between" alignItems="center" mb={"0.5rem"}>
                            <Typography variant={"h6"}>{i18n.t('sprint.utils.headerButtons.creditBalanceModal.editCreditsModal.actuallyCredits')} {props.handleCreditsDisplay}</Typography>
                            <Button className='btn-action medium' onClick={handleSync}>
                                {
                                    loading ? (
                                        <CircularProgress size={25} sx={{ color: "white" }} thickness={4} />
                                    ) : i18n.t('sprint.utils.headerButtons.creditBalanceModal.editCreditsModal.sync')
                                }
                            </Button>
                        </Box>
                        <FCTextField
                            fullWidth
                            label={i18n.t('sprint.utils.headerButtons.creditBalanceModal.editCreditsModal.credits')}
                            name="credits"
                            type='number'
                            onChange={(e) => setCredits(e.target.value >= 0 ? e.target.value : 0)}
                            value={credits}
                            variant="outlined"
                        />
                        <FCTextField
                            fullWidth
                            multiline
                            rows={4}
                            label={i18n.t('sprint.utils.headerButtons.creditBalanceModal.editCreditsModal.motive')}
                            name="comment"
                            onChange={(e) => setComment(e.target.value)}
                            value={comment}
                            variant="outlined"
                        />
                    </Box>
                </ModalBody>
                <Box padding='1rem' width={"100%"} display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
                    <Box alignItems={"center"} display={"flex"} gap={"1rem"}>
                        <Button className='btn-action large' onClick={() => handleBilling('refund')}>
                            {i18n.t('sprint.utils.headerButtons.creditBalanceModal.editCreditsModal.add')}
                        </Button>
                        <Button className='btn-reprove large' onClick={() => handleBilling('charge')}>
                            {i18n.t('sprint.utils.headerButtons.creditBalanceModal.editCreditsModal.remove')}
                        </Button>
                    </Box>
                    <Button className="btn-white medium" onClick={() => setOpen(false)}>
                        {i18n.t('sprint.utils.headerButtons.creditBalanceModal.editCreditsModal.cancel')}
                    </Button>
                </Box>
            </Modal>
        </>
    )
}

const mapStateToProps = state =>
({
    customer: state.customer.customerDetails,
    cycle: state.actions.cycle,
    contract: state.contract.contractDetails,
})


export default connect(mapStateToProps, { ...actions, ...actions2 })(EditCreditsModal);