import React, { useEffect, useState } from 'react'
import { Box, CircularProgress, Grid, Paper, Typography } from '@mui/material'
import axios from 'axios';
import * as actions from '../../store/actions/customers'
import { MultiGraph } from '../Support/Performance/Graphs';
import { getHeaders } from '../../store/actions/localStorage';
import { api } from '../../config';
import { connect } from 'react-redux';
import { i18n } from '../../translate/i18n';
import { isMobile } from "react-device-detect";
import { translatePerformanceMetrics } from '../../translate/translates/Translates';

const manipulateData = (data) => {
  switch (data) {
    case "FIRST_CONTENTFUL_PAINT_MS":
      return {name: "Primeiro conteúdo pintado", multiplier: 10000};
    case "FIRST_INPUT_DELAY_MS":
      return {name: "Primeiro atraso de entrada", multiplier: 10};
    case "LARGEST_CONTENTFUL_PAINT_MS":
      return {name: "Maior conteúdo pintado", multiplier: 10000};
    case "CUMULATIVE_LAYOUT_SHIFT_SCORE":
      return {name: "Mudança de layout cumulativo", multiplier: 100};
    case "INTERACTION_TO_NEXT_PAINT":
      return {name: "Interatividade até a Próxima Renderização", multiplier: 100};
    case "EXPERIMENTAL_TIME_TO_FIRST_BYTE":
      return {name: "Tempo para primeiro byte", multiplier: 1000};
    default:
      return {name: "Nenhum dado encontrado"};
  }
}

const getColour = (data) => {
  if (data >= 90) {
    return '#018642';
  } else if (data >= 50) {
    return '#fa3';
  } else {
    return '#eb0f00';
  }
}

const Tecnology = (props) => {

  const [lightHouse, setLightHouse] = useState({});
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [dates, setDates] = useState([]);
  const [loadingExperience, setLoadingExperience] = useState({});

  const getPerformanceData = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${api}/performance/`, getHeaders());
      const performanceData = response.data?.performance || {};
      setData(performanceData);

      setLoading(false);
    return null;
    } catch (error) {
      setLoading(false);
    }
  };

  const updatePerformanceData = () => {
    setLightHouse(data.desktop?.lighthouseResult?.categories || {});
  }

  useEffect(() => {
    getPerformanceData();
    props.getViewCustomer(props.shop);
  }, [props.shop]);

  useEffect(() => {
    updatePerformanceData();
  }, [props.shop, data]);

  useEffect(() => {
    const filteredHistory = data.history?.slice(-30) || [];
    setDates(filteredHistory.filter(el => el.desktop?.loadingExperience)?.map(el => new Date(el.date).toLocaleString('en-US', { timeZone: 'UTC' })));

    setLoadingExperience(
      filteredHistory.map(el => el.desktop?.loadingExperience)?.reduce((acc, el) => {
        if (!el) return acc;
      Object.keys(el).forEach(key => {
        const { name, multiplier = 1 } = manipulateData(key);
        if (name === "Nenhum dado encontrado") return;
        if (acc[name]) {
          acc[name].push(el[key]?.percentile/multiplier);
        } else {
          acc[name] = [el[key]?.percentile/multiplier];
        }
      })
      return acc;
    }, {}) || {});

  }, [data]);
  
  return (
    <Box my="2.5rem">
      <Typography variant="h4" mb="1.5rem">{i18n.t('dashboard.tecnology')}</Typography>
      <Box display="flex" gap="1.5rem" flexDirection={isMobile ? "column" : "row"}>
        <Paper sx={{width: isMobile ? "100%" : "50%", borderRadius: "1.5rem", display: "flex", alignItems: "center", backgroundColor: "#F3F3F3"}}>
        {
            loading ? (
              <Box display="flex" justifyContent="center" alignItems="center" height="150px" width="100%">
                <CircularProgress />
              </Box>
            ) : (
              <Grid container spacing="1rem" padding="1rem">
                {
                  Object.keys(lightHouse)?.length > 0 ? (
                    <>
                      {Object.keys(lightHouse).map((key) => {
                        const score = lightHouse[key].score * 100;
                        const title = lightHouse[key].title;
                        const color = getColour(score);
                        return (
                          (
                            <Grid key={key} item xs={6} display={"flex"} flexDirection={"column"} justifyContent={"center"} alignItems={"center"}>
                              <Box position={"relative"} mb={"0.5rem"}>
                                <CircularProgress
                                  variant="determinate"
                                  value={100}
                                  sx={{ color: "#e0e0e0", position: "absolute" }}
                                  size={isMobile ? "4rem" : "8rem"}
                                  />
                                <CircularProgress sx={{color}} variant="determinate" size={isMobile ? "4rem" : "8rem"} value={score} />
                                <Box position={"absolute"} display={"flex"} alignItems={"center"} justifyContent={"center"} height={"100%"} width={"100%"} top={0}>
                                  <Typography fontSize={isMobile ? "0.75rem" : "1.5rem"}  sx={{color}} >{score?.toString()?.match(/[^.]+/)}%</Typography>
                                </Box>
                              </Box>
                              <Typography fontSize={isMobile ? "0.75rem" : "1.5rem"} align='center' mb={"1rem"}>{translatePerformanceMetrics(title)}</Typography>
                            </Grid>
                          )
                        )
                      })}
                    </>
                  ) : (
                    <Typography variant={"h6"} display={"flex"} justifyContent={"center"} width={"100%"}>{i18n.t('dashboard.pleaseWaitTecnology')}</Typography>
                   )
                 }
              </Grid>
            )
          }
        </Paper>
        <Paper sx={{width: isMobile ? "100%" : "50%", borderRadius: "1.5rem", backgroundColor: "#F3F3F3"}}>
          {
            loading ? (
              <Box display="flex" justifyContent="center" alignItems="center" height="150px">
                <CircularProgress />
              </Box>
            ) : (
              <MultiGraph dataSet={loadingExperience} dates={dates} caption={true} title={i18n.t('support.performance.graphs.chargingExperience')} height={isMobile ? "350px" : "450px"}/>
            )
          }
        </Paper>
      </Box>
    </Box>
  )
}

const mapStateToProps = (state) => ({
  customer: state.customer.customerDetails,
  shop: state.auth.shop,
  user: state.auth.user,
});

export default connect(mapStateToProps, actions)(Tecnology)