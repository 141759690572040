import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getPermissionModels, getPermissions, storePermissionModel, updatePermissionModel } from '../../../store/actions/permissions';
import { getViewUser, handleUpdateUser } from '../../../store/actions/users';
import { toast } from 'react-toastify';
import {
    Button,
    Tab,
    Box,
    FormGroup,
    Typography,
    CardContent,
} from '@mui/material';
import ModalEditPermission from './ModalEditPermission';
import FreeSoloCreateOption from './FreeSoloCreateOption';
import { i18n } from '../../../translate/i18n';
import { FCCard, FCCheckbox, FCReturnButton, FCSelect, FCTabs } from 'components-fullcomm';

const PermissionModels = (props) => {
    const [isNewModel, setIsNewModel] = useState(true);
    const [permissions, setPermissions] = useState([]);
    const [tabValue, setTabValue] = useState(0);
    const [selectedModel, setSelectedModel] = useState('');
    const [typeUser, setTypeUser] = useState(["admin"]);
    const [permissionModels, setPermissionModels] = useState([
    ]);


    useEffect(() => {
        props.getPermissions();
        props.getPermissionsModels();
    }, []);

    useEffect(() => {
        if (props.permissionModels) {
            setPermissionModels(props.permissionModels);
        }
    }, [props.permissionModels]);

    const handleCheckbox = (actionId, event) => {
        if (event.target.checked) {
            setPermissions([...permissions, actionId]);
        } else {
            setPermissions(permissions.filter(id => id !== actionId));
        }
    };


    const savePermissions = async () => {
        if (!selectedModel?.name) {
            toast.error(i18n.t('permission.permissionModels.selectModel'));
            return;
        }
        const permissionsData = {
            name: selectedModel.name,
            actions: permissions,
            roles: typeUser,
        };
        if (selectedModel._id) permissionsData._id = selectedModel._id;
        try {
            await (isNewModel ? props.storePermissionModel(permissionsData) : props.updatePermissionModel(permissionsData));
            props.getPermissionsModels();
            toast.success(i18n.t('permission.permissionModels.updatedPermissions'));
        } catch (error) {
            console.error(error)
            toast.error(i18n.t('permission.permissionModels.errorUpdatePermissions'));
        }

    };

    const handleChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const RenderPermissions = ({parent}) => {
        const [parentPermissions, setParentPermissions] = useState(props.permissions?.filter((permission) => permission.parent === parent && typeUser?.includes(permission.typeUser)) || []);
        
        useEffect(() => {
            setParentPermissions(props.permissions?.filter((permission) => permission.parent === parent && typeUser?.includes(permission.typeUser)) || []);
        }, [props.permissions, typeUser]);        

        return (
            <Box mt="1rem">
                {parentPermissions.map((permission) => (
                    <FCCard key={permission._id} sx={{ marginBottom: '16px' }}>
                        <CardContent>
                            <Box display={'flex'} justifyContent={"space-between"} alignItems={"center"}>
                                <Typography variant="subtitle2" component="div">
                                    {permission.name} {permission.typeUser ? `(${permission.typeUser})` : ""}
                                </Typography>
                                <ModalEditPermission permission={permission} key={permission._id}/>
                            </Box>
                            <FormGroup row>
                                {permission.actions.map((action) => (
                                    <FCCheckbox
                                        key={action.id}
                                        checked={permissions?.includes(action.id) || false}
                                        onChange={(event) => handleCheckbox(action.id, event)}
                                        label={action.name}
                                    />
                                ))}
                            </FormGroup>
                        </CardContent>
                    </FCCard>
                ))}
                <Box display={'flex'} justifyContent={'space-between'} mt='2rem' mb='4rem'>
                    <Button className='btn-gray large' variant="contained" onClick={() => {
                        setParentPermissions([...parentPermissions, {parent, actions: [], name: "nome"}]);
                    }}>{i18n.t('permission.permissionModels.add')}</Button>
                    <Button variant='contained' className='btn-action large' onClick={savePermissions}>{isNewModel ? i18n.t('permission.permissionModels.createModel') : i18n.t('permission.permissionModels.save')}</Button>
                </Box>
            </Box>
        );
    };
    const handleModelChange = (model) => {
        setSelectedModel(model);
        if (model) {
                setPermissions(model?.actions || []);
                setTypeUser(model?.roles || []);
        } else {
            setPermissions([]);
        }
    };


    return (
        <div className="p-4">
            <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                <div style={{ display: 'flex', alignItems: 'center'}}>
                    <FCReturnButton />
                    <Typography variant="h5">{i18n.t('permission.permissionModels.createEdit')}</Typography>
                </div>
            </Box>
            <Box display={"flex"} gap={"1rem"}>
                <FreeSoloCreateOption
                    handleModelChange={handleModelChange}
                    permissionModels={permissionModels}
                    selectedModel={selectedModel}
                    setIsNewModel={setIsNewModel}
                />
                <FCSelect
                    label={i18n.t('permission.permissionModels.modelAplic')}
                    fullWidth
                    value={typeUser}
                    onChange={(e)=>{
                        setTypeUser(
                            typeof e.target.value === 'string' ? e.target.value.split(',') : e.target.value,
                          );
                    }}
                    multiple
                    options={[
                        { value: "admin", label: i18n.t('permission.permissionModels.admin') },
                        { value: "customer", label: i18n.t('permission.permissionModels.client') }
                    ]}
                />
            </Box>

            <FCTabs value={tabValue} onChange={handleChange}>
                <Tab label={i18n.t('permission.permissionModels.general')} />
                <Tab label={i18n.t('permission.permissionModels.implantation')} />
                <Tab label={i18n.t('permission.permissionModels.performance')} />
                <Tab label={i18n.t('permission.permissionModels.support')} />
                <Tab label={i18n.t('permission.permissionModels.operation')} />
            </FCTabs>
            {tabValue === 0 && <RenderPermissions parent='geral'/>}
            {tabValue === 1 && <RenderPermissions parent='start'/>}
            {tabValue === 2 && <RenderPermissions parent='sprint'/>}
            {tabValue === 3 && <RenderPermissions parent='suporte'/>}
            {tabValue === 4 && <RenderPermissions parent='scale'/>}
        </div>
    );
};

const mapStateToProps = (state) => ({
    permissions: state.permission?.permissions || [],
    permissionModels: state.permission?.permissionModels || [],
    user: state.user?.userDetails || {},
});

const mapDispatchToProps = (dispatch) => {
    return {
        updatePermissionModel: (props) => dispatch(updatePermissionModel(props)),
        storePermissionModel: (props) => dispatch(storePermissionModel(props)),
        getPermissionsModels: () => dispatch(getPermissionModels()),
        getPermissions: () => dispatch(getPermissions()),
        getViewUser: (props) => dispatch(getViewUser(props)),
        handleUpdateUser: (props) => dispatch(handleUpdateUser(props))
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(PermissionModels);
