import React from 'react';
import { Popper, Grow, Paper, ClickAwayListener, MenuList, MenuItem, Checkbox, ListItemText, RadioGroup, FormControlLabel, Radio } from '@mui/material';

const CustomPopper = ({ open, anchorEl, handleClose, options, handleMenuItemClick, selected, setSelected, type }) => {
  return (
    <Popper
      sx={{
        zIndex: 999,
      }}
      open={open}
      anchorEl={anchorEl}
      role={undefined}
      transition
      disablePortal
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
          }}
        >
          <Paper>
            <ClickAwayListener onClickAway={handleClose}>
              <MenuList id="split-button-menu" autoFocusItem>
                {options.map((option) => {
                  return type === 'status' ? (
                  <MenuItem
                    key={option.label}
                    onClick={() => handleMenuItemClick(option.value)}
                  >
                    <Checkbox checked={selected.indexOf(option.value) > -1} />
                    <ListItemText primary={option.label} />
                  </MenuItem>
                ):
                (
                  <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  sx={{padding: "0 1rem"}}
                  value={selected}
                  onChange={(e) => setSelected(e.target.value)}
                  name="radio-buttons-group"
                  >
                  <FormControlLabel value={option.value} control={<Radio />} label={option.label} />
                  </RadioGroup>
                  )
                  })}
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
};

export default CustomPopper;
