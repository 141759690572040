import {
  Box,
  CircularProgress,
  Typography,
  IconButton,
} from "@mui/material";
import { Icon } from "@mui/material";

import React, { useState, useEffect } from "react";
import { Modal } from "reactstrap";
import CloseIcon from "@mui/icons-material/Close";
import "../../../../styles/dashboard/sprint/index.css";
import { connect } from "react-redux";
import * as actions from "../../../../store/actions/action";
import * as actions2 from '../../../../store/actions/customers'
import ContentModal from "../../V2NewAction/Modal";
import isMobile from "react-device-detect";
import { handleHealthStatus } from "../statusColor";

const HealthScore = ({ health, shop, getViewCustomer, customerDetails }) => {
  const [healthStatus, setHealthStatus] = useState("");
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setHealthStatus(handleHealthStatus(health));
  }, [health]);

  useEffect(() => {
    if (shop) getViewCustomer(shop);
  }, [shop]);

  return (
    <>
      <Box
        position={"relative"}
        display={"grid"}
        alignItems={"center"}
        sx={{ cursor: "pointer" }}
        onClick={() => setOpen(true)}
      >
        <CircularProgress
          variant="determinate"
          value={100}
          sx={{ color: "#e0e0e0", position: "absolute" }}
          thickness={5}
        />
        <CircularProgress
          variant="determinate"
          value={health}
          sx={{ color: healthStatus }}
          thickness={5}
        />
        <Typography
          sx={{
            color: healthStatus,
            fontWeight: "600",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          {health}
        </Typography>
      </Box>
      <Modal
        isOpen={open}
        centered={true}
        size="lg"
        toggle={() => setOpen(false)}
      >
        <Box sx={{ display: "flex", justifyContent: "flex-end", padding: "1rem", position: 'absolute', right: 0, top: '0px' }}>
        </Box>
        <ContentModal setOpen={setOpen} healthScoreHistory={customerDetails?.healthScoreHistory}/>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => ({
  health: state.actions.health,
  customerDetails: state.customer.customerDetails,
  shop: state.auth.shop,
});

export default connect(mapStateToProps, {...actions, ...actions2})(HealthScore);
