import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import {Form, FormGroup, Label, Input, UncontrolledButtonDropdown,  DropdownToggle,DropdownMenu } from 'reactstrap';
import {connect} from 'react-redux'
import AlertDanger from '../../components/AlertDanger'
import AlertSuccess from '../../components/AlertSuccess'
import SpinnerUp from '../../components/SpinnerUp'
import * as actions from '../../store/actions/users'
import { i18n } from '../../translate/i18n';

class UpdateUserPass extends Component{

    state = {
        password: "",
        error: "",
        success: "",
        loading: false,
    }

    componentDidMount(){
        const {id} = this.props.match.params
        this.props.getViewUser(id)
    } 

    componentDidUpdate(nextProps){
        const {id} = this.props.match.params
        if(!this.props.user && nextProps.user) this.props.getViewUser(id)
    }

    componentWillUnmount(){
        this.props.clearUser()
    }

    onChangeInput = (ev) => {
        this.setState({[ev.target.name]: ev.target.value})
    }

    validate(){
        const {password} = this.state;
        if(!password){
            return this.setState({error: {message:  i18n.t('updateUserPass.informPassword')}})
        }else if(password.length < 6){
            return this.setState({error: {message:  i18n.t('updateUserPass.minChars')}})
        }

        return true;
    }

    

    handleUpdate(){
        const {password} = this.state;
        const _id = this.props.match.params.id
        if(!this.validate()) return
        
        this.setState({loading: true});

        this.props.handleUpdateUser({password, _id}, (msg) => {
            if(msg.error.error){
                this.setState({error: {message: msg.error.message}})                
                this.setState({success: ""});
            }else{
                this.setState({error: ""});
                this.setState({success: {message: msg.error.message}});
                this.setState({formSuccess: true});
            }
            
            this.setState({loading: false});
        });

    }

    render(){
        const {password, loading, error, success} = this.state
        return(
            <div className='p-4'>
                <div className="d-flex">
                <div className="mr-auto p-2">
                    <h2 className="titulo">{i18n.t('updateUserPass.editPassword')} - {this.props.userDetails ? this.props.userDetails.name : i18n.t('updateUserPass.loading')}</h2>
                </div>
                <span className="d-none d-md-block">
                    <Link to={"/user"}>
                        <button className="btn btn-info btn-sm">{i18n.t('updateUserPass.list')}</button>
                    </Link>
                    <Link to={"/view-user/" + this.props.match.params.id}>
                        <button className="ml-1 btn btn-primary btn-sm">{i18n.t('updateUserPass.view')}</button>
                    </Link>
                </span>
                <div className="dropdown d-block d-md-none">
                    <UncontrolledButtonDropdown >
                        <DropdownToggle color="primary" size="sm" caret>
                            {i18n.t('updateUserPass.actions')}
                        </DropdownToggle>
                        <DropdownMenu right>
                            <Link className="dropdown-item" to={"/user"}>{i18n.t('updateUserPass.list2')}</Link>
                            <Link className="dropdown-item" to={"/view-user/" + this.props.match.params.id}>{i18n.t('updateUserPass.view2')}</Link>
                        </DropdownMenu>
                    </UncontrolledButtonDropdown>
                </div>
            </div>
            <hr />
            <AlertDanger error={error}/>
                <AlertSuccess error={success}/>
            <Form>
                    <FormGroup>
                        <Label for="password">{i18n.t('updateUserPass.newPassword')}</Label>
                        <Input type="password" value={password} name="password" id="password" placeholder={i18n.t('updateUserPass.enterNewPassword')} autoComplete="password" onChange={(ev) => this.onChangeInput(ev)}/>  
                    </FormGroup>
                    <Link onClick={() => this.handleUpdate()} to="#">
                        <SpinnerUp loading={loading}></SpinnerUp>
                    </Link>
                </Form>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    userDetails: state.user.userDetails
})

export default connect(mapStateToProps, actions)(UpdateUserPass);