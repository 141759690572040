import { Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { i18n } from '../../../../translate/i18n';
import { FCSelect } from 'components-fullcomm';

const Responsible = ({ responsible, setResponsible, customer}) => {
  const [options, setOptions] = useState(['FullPRO', customer?.name, ...(customer?.agency || []).map(agency => agency.name)]);

  const handleResponsible = (value) => {
    setResponsible(
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  useEffect(() => {
    const options = ['FullPRO', customer?.name, ...(customer?.agency || []).map(agency => agency.name)];
    setOptions(options);
    setResponsible(options);
    
  }, [customer]);

  return (
    <>
      <Typography marginRight={'0.5rem'}>{i18n.t('sprint.utils.filter.responsible.responsible')}</Typography>
        <FCSelect
          multiple
          fullWidth
          value={responsible}
          onChange={(e)=>handleResponsible(e.target.value)}
          renderValue={(selected) => selected.join(', ')}
          options={options.map((option) => ({ value: option, label: option }))}
        />
    </>
  )
}

export default Responsible