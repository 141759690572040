import React, { useEffect } from 'react'
import { useAuth0 } from "@auth0/auth0-react";
import { Box, Button, Chip, Paper, Typography, useMediaQuery } from '@mui/material';
import logo from '../../../assets/logo-fullcomm-horizontal.svg'
import icon from '../../../assets/simbolo-fullcomm.png'
import dashboardImg from '../../../assets/login_image.png'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { connect } from 'react-redux';
import { i18n } from '../../../translate/i18n';
import HttpsOutlinedIcon from '@mui/icons-material/HttpsOutlined';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';

const Login = (props) => {
  const history = useHistory();
  const { loginWithRedirect, error, logout } = useAuth0();
  const isMobileDevice = useMediaQuery('(max-width: 600px)');
  
  const handleSignUp = async () => {
    history.push('/add-trial-user');
  };

  useEffect(() => {
    if (error && error.message === "Invalid state") {
      logout();
    }
  }, [error, logout]);
  
  return (
    <>
      
        {
          error ?
          (
            <Paper sx={{padding: "2rem", borderRadius: "10px"}}>
            <Typography variant='h2' align='center' marginBottom={"1rem"}>{error.message}</Typography>
            <Typography 
              sx={{
                cursor: "pointer",
                "&:hover": {
                  textDecoration: "underline"
                }
              }}
              color={"#635dff"} 
              className='underline-hover' 
              variant='h5' 
              align='center' 
              marginBottom={"1rem"} 
              onClick={() => loginWithRedirect()}
            >
             {i18n.t('login.auth0.verifiedEmail')}
            </Typography>
            <Typography 
                align="center"
                mt={"0.5rem"}
                sx={{
                  color: '#919191',
                  cursor: 'pointer', 
                  '&:hover': {
                    textDecoration: 'underline'
                  }
                }}
                onClick={logout}
                >
                {i18n.t('login.auth0.continueAfter')}
              </Typography>
          </Paper>
          ) : (
            <Box 
            sx={{
              display: "flex",
              width: "100%",
              background: "rgb(0, 2, 7)"
            }}
          >
            <Box
              width={isMobileDevice ? "100%" : "60%"}
              display={"flex"}
              justifyContent={"center"}
              padding={isMobileDevice ? "2.5rem 1.5rem" : "6rem 2.5rem"}
              height={"100vh"}
            >
              <Box 
                display="flex" 
                flexDirection={"column"}
                justifyContent={"space-between"}
                alignItems={isMobileDevice ? "center" : "flex-start"}
                maxWidth={"480px"}
              >
                <img src={logo} alt="logo" style={{height: "24px"}}/>
                <Box>
                  <Typography sx={{
                      fontWeight: 500,
                      fontSize: '26px',
                      lineHeight: '132%',
                      textAlign: 'left',
                      letterSpacing: '-0.02em',
                      color: 'rgb(238, 239, 252)',
                    }}
                  >
                    {i18n.t('login.auth0.title')}
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: 400,
                      fontSize: '16px',
                      lineHeight: '152%',
                      textAlign: 'left',
                      letterSpacing: '-0.01em',
                      color: 'rgb(180, 188, 208)',
                      maxWidth: '348px',
                      marginTop: '12px',
                    }}
                  >
                    {i18n.t('login.auth0.subTitle')}
                  </Typography>
                  <Button 
                    onClick={loginWithRedirect} 
                    startIcon={<img src={icon} alt="icon" style={{width: "25px", marginRight: "0.5rem"}}/>}
                    endIcon={<ArrowForwardOutlinedIcon sx={{ position: "absolute", right: "0.5rem", top: "50%", transform: 'translateY(-50%)' }}/>}
                    sx={{
                      mt: "2rem",
                      mb: "1rem",
                      width: "100%", 
                      background: "rgba(252, 252, 253, 0.1)", 
                      color: "#fff", 
                      textTransform: "none",
                      justifyContent: "flex-start",
                      position: "relative",
                      padding: "1rem 2rem",
                      borderRadius: "8px",
                      ":hover": {
                        background: "rgba(252, 252, 253, 0.2) !important"
                      }
                    }}
                    >
                    {i18n.t('login.auth0.login')}
                  </Button>
                  <Typography
                    onClick={handleSignUp}
                    sx={{
                      fontWeight: 400,
                      width: 'fit-content',
                      fontSize: '14px',
                      lineHeight: '152%',
                      letterSpacing: '-0.01em',
                      textDecorationLine: 'underline',
                      color: 'rgba(255, 255, 255, 0.5)',
                      cursor: 'pointer',
                    }}
                    variant='body'
                  >
                    {i18n.t('login.auth0.register')}
                  </Typography>
                </Box>
                <Chip 
                  icon={<HttpsOutlinedIcon fontSize='10px' color='rgb(108, 233, 166)'/>} 
                  label="Secured by 256-bit AES and 256-bit SSL/TLS encryption" 
                  sx={{color: "rgb(108, 233, 166)", background: "rgba(101, 220, 157, 0.2)"}}
                />
              </Box>
            </Box>
            <Box
              width={"40%"}
              display={isMobileDevice ? "none" : "flex"}
              position={"relative"}
            >
              <img src={dashboardImg} alt="background" style={{width: "100%", objectFit: "cover"}}/>
              <div style={{
                  position: 'absolute',
                  top: -1,
                  left: -1,
                  bottom: 0,
                  width: '5px',
                  backgroundImage: 'linear-gradient(to right, rgb(0, 2, 7), rgba(255, 0, 0, 0))',
                }}
              />
            </Box>
          </Box>
          )
        }
    </>
  );
};

const mapStateToProps = (state) => ({
  authorized: state.auth.authorized,
});

export default connect(mapStateToProps)(Login);