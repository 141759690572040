// services/permissions.js
import axios from 'axios';
import { api } from '../../config';
import { getHeaders } from './localStorage';
import errorHandling from './errorHandling';

export const getApp = (category) => async (dispatch) => {
  try {
    const response = await axios.get(api + "/app?" + category, getHeaders());
    dispatch({ type: "GET_APP", payload: response.data });
  } catch (err) {
    return errorHandling(err);
  }
};

export const updateApp = (AppData) => async () => {
  try {
    const response = await axios.put(api + `/app`, AppData, getHeaders());
    return response.data;
  } catch (err) {
    return errorHandling(err);
  }
};

export const createApp = (AppData) => async () => {
  try {
    const response = await axios.post(api + "/app", AppData, getHeaders());
    return response.data;
  } catch (err) {
    return errorHandling(err);
  }
};

export const updateAppFiles = (category, AppData) => async () => {
  try {
    const response = await axios.post(api + `/app/files/${category}`, AppData, getHeaders());
    return response.data;
  } catch (err) {
    return errorHandling(err);
  }
};

export const deleteAppFiles = (category, fileName) => async () => {
  try {
    const response = await axios.delete(api + `/app/files/${category}/${fileName}`, getHeaders());
    return response.data;
  } catch (err) {
    return errorHandling(err);
  }
};