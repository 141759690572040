import React from 'react'
import { Box, Chip, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import moment from 'moment';
import { i18n } from '../../../translate/i18n';

const mockData = [
  {
    status: "Em análise",
    code: "abc123",
    date: "01/06/2023 09:30:00"
  },
  {
    status: "Em trânsito",
    code: "def456",
    date: "02/06/2023 14:45:00"
  },
  {
    status: "Em disputa",
    code: "ghi789",
    date: "03/06/2023 18:15:00"
  },
  {
    status: "Aguardando postagem",
    code: "jkl012",
    date: "11/05/2023 12:00:00"
  },
  // Add more objects with the same format...
];


const Start = () => {
  const getChipColour = (status) => {
    switch (status) {
      case "Em análise":
        return "info"
      case "Em trânsito":
        return "warning"
      case "Em disputa":
        return "default"
      case "Aguardando postagem":
        return "yellow"
      default:
        return "default"
    }
  }

  const getTitleOccurrence = (status) => {
    switch (status) {
      case "Em análise":
        return i18n.t('scale.service.start.inAnalysis')
      case "Em trânsito":
        return i18n.t('scale.service.start.inTransit')
      case "Em disputa":
        return i18n.t('scale.service.start.inDispute')
      case "Aguardando postagem":
        return i18n.t('scale.service.start.waitingPost')
      default:
        return ""
    }
  }

  return (
    <Grid container columnSpacing={"1rem"} rowGap="1rem">
      <Grid item xs={12} md={10}>
        <Paper sx={{padding: "1rem"}}>
          <TableContainer sx={{marginTop: "1.5rem"}}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell width="33.33%">{i18n.t('scale.service.start.status')}</TableCell>
                  <TableCell width="33.33%">{i18n.t('scale.service.start.codeOccurrence')}</TableCell>
                  <TableCell width="33.33%">{i18n.t('scale.service.start.elapsedTime')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {mockData.map((row) => (
                  <TableRow
                  key={row.orderId}
                  >
                    <TableCell><Chip label={row.status} color={getChipColour(row.status)} /></TableCell>
                    <TableCell>{row.code}</TableCell>
                    <TableCell>{moment(row.date, 'DD HH mm').fromNow()}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Grid>
      <Grid item xs={12} md={2}>
        <Paper>
          <Grid container>
            {
              ['Em análise', 'Em disputa', 'Em trânsito', 'Aguardando postagem'].map((status, index) => {
                const amount = mockData.filter((item) => item.status === status).length
                return (
                  <Grid key={index} xs={6} md={12}  sx={{padding: "1rem"}}>
                    <Chip label={getTitleOccurrence(status)} color={getChipColour(status)} />
                    <Typography variant="h1">{amount}</Typography>
                  </Grid>
                )
              })
            }
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  )
}

export default Start