import { Autocomplete, Box, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { i18n } from '../../../../translate/i18n';

const Campaign = ({campaigns = [], campaign, setCampaign}) => {

  const campaignArray = campaigns?.map(campaign => {
    return campaign.name
  });

  return (

    <Box display="flex" height="fit-content" width="100%" alignItems="center">
      <Typography marginRight={'0.5rem'}>{i18n.t('sprint.utils.filter.campaign.campaign')}</Typography>
      <Autocomplete
        disablePortal
        options={campaignArray}
        size='small'
        fullWidth    
        inputValue={campaign || ''}
        onInputChange={(event, value) => {
          setCampaign(value)
          }
        }
        value={campaign}
        onChange={(event, value) => {
          setCampaign(value)
        }}
        renderInput={(params) => <TextField {...params} label={i18n.t('sprint.utils.filter.campaign.selectCampaign')} />}
      />
    </Box>
  )
}

export default Campaign