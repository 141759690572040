import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  todayCell: {
    position: "absolute",
    zIndex: 1,
    top: 0,
    bottom: 0,
    fontWeight: 500,
    borderRight: "4px solid #3F4247 !important",
  },
  dropdownCell: {
    width: "20vw",
    padding: 0,
    fontWeight: 400,
    fontSize: "16px",
    zIndex: "999",
    position: "relative",
    backgroundColor: "#FFFFFF",
    border: "none",
  },
  paper: {
    alignItems: "center",
    paddingLeft: "16px",
    paddingBottom: "5px",
    paddingTop: "5px",
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "1px",
    marginLeft: "1px",
  },
  taskCell: {
    width: "20vw",
    minWidth: "150px",
    paddingBottom: 10,
    paddingTop: 10,
    fontWeight: 400,
    fontSize: "16px",
    zIndex: "1",
    backgroundColor: "#FFFFFF",
    border: "none",
    position: "relative",
    display: "flex",
    justifyContent: "space-between",
  },
  titleCell: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  dateCell: {
    borderBottom: "none",
    position: "relative",
    padding: "0px",
    textAlign: "center",
  },
  monthCell: {
    position: "relative",
    zIndex: "999",
    paddingInline: 0,
    textAlign: "center",
    background: "#e7e7e7",
    "& > *:first-child": {
      borderRight: "1px solid #BABFC3",
    },
  },
  progressCell: {
    position: "absolute",
    top: "10%",
    left: 0,
    right: 0,
    bottom: "10%",
    fontWeight: 500,
    // backgroundColor: "#a7aaaf",
  },
  executionCell: {
    position: "absolute",
    top: "10%",
    left: 0,
    right: 0,
    bottom: "10%",
    fontWeight: 500,
    borderTop: "1px dotted #a7aaaf",
    borderBottom: "1px dotted #a7aaaf",
  },
  startExecCell: {
    borderTopLeftRadius: "10px",
    borderBottomLeftRadius: "10px",
    borderLeft: "1px dotted #a7aaaf",
  },
  endCell: {
    borderTopRightRadius: "10px",
    borderBottomRightRadius: "10px",
  },
  startCell: {
    borderTopLeftRadius: "10px",
    borderBottomLeftRadius: "10px",
  },
  startMonth: {
    paddingInline: 0,
    textAlign: "center",
    background: "#e7e7e7",
    borderTopLeftRadius: "5px",
    borderBottomLeftRadius: "5px",
    "& > *:first-child": {
      borderRight: "1px solid #BABFC3",
    },
  },
  endMonth: {
    paddingInline: 0,
    textAlign: "center",
    background: "#e7e7e7",
    borderTopRightRadius: "5px",
    borderBottomRightRadius: "5px",
  },
  hiddenRow: {
    display: "none",
  },
  visibleRow: {
    cursor: "pointer",
    backgroundColor: "#fafafa",
    "&:nth-of-type(even)": {
      backgroundColor: "#e7e7e7",
    },
    "&:hover": {
      "& $progressCell": {
        // backgroundColor: "#9B9EA5 !important",
        opacity: 0.75,
      },
      "& $p": {
        display: "block",
      },
    },
  }
});
