import { Badge, Box, ClickAwayListener, IconButton, Paper, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import NotificationsIcon from '@mui/icons-material/Notifications';
import NotificationCard from './NotificationCard';
import * as actions from "../../store/actions/notifications";
import { i18n } from '../../translate/i18n';


function NotificationIcon(props) {
  const [notificationsCount, setNotificationsCount] = useState(0);
  const [showPopup, setShowPopup] = useState(false);
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    props.getNotifications(props.user, undefined, undefined, 9999);
  }, [props.user, props.shop]);

  useEffect(() => {
    setNotifications(props.notifications?.docs);
    setNotificationsCount(props.notifications?.amountUnread?.reduce((acc, curr) => acc + curr.amount, 0));
  }, [props.notifications]);

  useEffect(() => {
    if (showPopup) props.readNotifications(props.user, notifications?.slice(0, 6)?.map((notification) => notification._id));
  }, [showPopup]);

  return (
    <ClickAwayListener
      onClickAway={() => setShowPopup(false)}
    >
      <Box position={'relative'}>
        <IconButton
          size="large"
          edge="start"
          aria-label="route to notifications"
          aria-haspopup="true"
          color="inherit"
          onClick={() => setShowPopup((prev) => !prev)}
        >
          <Badge badgeContent={notificationsCount} color="info"
            sx={{ color: '#6B7280' }}
          >
            <NotificationsIcon />
          </Badge>
        </IconButton>
        {
          showPopup && (
            <Paper
              sx={{
                position: 'absolute',
                right: '0rem',
                bgcolor: '#FFFFFF',
                zIndex: 9999,
                maxWidth: '100%',
                width: '100%',
                minWidth: '380px',
                overflow: 'auto',
                borderRadius: '8px',
                boxShadow: '0px 4px 4px 0px #00000026',
              }}
            >
              <Box display={'flex'} justifyContent={'space-between'} alignItems='center'>
                <Typography variant='subtitle1' padding={2}>{i18n.t('notifications.icon.notifications')}</Typography>
                <Box display='flex' gap='0.25rem' marginRight='1rem'>
                  <Tooltip
                    title='Ver apenas não lidas'
                  >
                    <IconButton
                      onClick={() => {
                        const filteredNotifications = notifications.filter((notification) => !notification.read.includes(props.user._id));
                        setNotifications(filteredNotifications);
                      }}
                    >
                      <svg width="18" height="13" viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7 12.5H11V10.5H7V12.5ZM0 0.5V2.5H18V0.5H0ZM3 7.5H15V5.5H3V7.5Z" fill="#888888"/>
                      </svg>
                    </IconButton>
                  </Tooltip>
                  <Tooltip
                    title='Marcar todas como lidas'
                  >
                    <IconButton
                      onClick={async () => {
                        await props.readNotifications(props.user, notifications?.map((notification) => notification._id));
                        props.getNotifications(props.user, undefined, undefined, 9999);
                      }}
                    >
                      <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8 0.5C3.584 0.5 0 4.084 0 8.5C0 12.916 3.584 16.5 8 16.5C12.416 16.5 16 12.916 16 8.5C16 4.084 12.416 0.5 8 0.5ZM8 14.9C4.472 14.9 1.6 12.028 1.6 8.5C1.6 4.972 4.472 2.1 8 2.1C11.528 2.1 14.4 4.972 14.4 8.5C14.4 12.028 11.528 14.9 8 14.9ZM11.672 4.964L6.4 10.236L4.328 8.172L3.2 9.3L6.4 12.5L12.8 6.1L11.672 4.964Z" fill="#888888"/>
                      </svg>
                    </IconButton>
                  </Tooltip>
                </Box>
              </Box>
              {notifications.length ? (
                notifications?.slice(0, 6).map((notification) => {
                  return (
                    <NotificationCard
                      key={notification._id}
                      user={props.user}
                      {...notification}
                    />
                  )
                })) : (
                <Typography variant='body2' padding={"1rem"} color={'#888888'} align='center'>
                  {i18n.t('notifications.icon.noneNotifications')}
                </Typography>
              )}
            </Paper>
          )
        }
      </Box>
    </ClickAwayListener>
  );
}

const mapStateToProps = state =>
({
  user: state.auth.user,
  notifications: state.notifications.notifications,
  shop: state.auth.shop,
})



export default connect(mapStateToProps, actions)(NotificationIcon);
