import { Avatar, Box, Button, Chip, Icon, Typography } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { Modal } from 'reactstrap'
import { i18n } from '../../../../translate/i18n'
import { statusColor } from '../../utils/statusColor'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { handleLabelActivityStatus } from '../../../../translate/translates/Translates'
import ChatInput from '../../../../components/Chat/ChatInput'
import { connect } from 'react-redux'
import * as actions from '../../../../store/actions/messages'
import Message from '../../../../components/Chat/Message'
import { isMobile } from 'react-device-detect';
import { formatText } from '../../utils/formatText'
import { FCChip } from 'components-fullcomm'

const DescriptionItem = ({ label, value }) => {
  if (!value) return (<></>);
  return (
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <Typography>{label}</Typography>
      <Typography>{value}</Typography>
    </Box>
  )
}

const ProblemModal = ({problem, setProblem, user, sendMessage, getMessages, messages, updateMessage}) => {
  const history = useHistory()
  const [inputValue, setInputValue] = useState("");
  const [localMessages, setLocalMessages] = useState([]);
  const messagesRef = useRef(null);

  const handleInputChange = (text) => {
    setInputValue(text);
  };

  const handleUpdateMessage = async (messageId, text) => {
    const response = await updateMessage(messageId, text);
    setLocalMessages(prev => prev.map(message => message._id === messageId ? response.messageData : message))
    return response;
  }

  const handleSendMessage = async (event, files, taggedUsers) => {
    event.preventDefault();
    if (inputValue.trim() === "" && files.length === 0) return;
    const formData = new FormData();
    files.forEach((file) => {
      formData.append("files", file);
    });
    formData.append("text", inputValue);
    formData.append("activity", problem._id);
    formData.append("platform", "action");
    if (taggedUsers) formData.append("taggedUsers", taggedUsers);
    const newMessage = await sendMessage(formData, problem.customer?._id);

    setLocalMessages(prev => ([...prev, newMessage]));
    setInputValue("");
  };

  useEffect(() => {
    if (messages) {
      setLocalMessages(messages);
    }
  }, [messages]);

  useEffect(() => {
    if (problem) getMessages({ platform: "action", customer: problem.customer?._id, activity: problem._id });
    else setLocalMessages([]);
  }, [problem]);

  useEffect(() => {
    if (messagesRef.current) {
      messagesRef.current.scrollTop = messagesRef.current.scrollHeight;
    }
  }, [localMessages]);

  return (
    <Modal
      isOpen={problem}
      centered={true}
      size="lg"
      toggle={() => setProblem(null)}
    >
      <Box padding="1rem">
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="bold" fontSize="12px"><b>{problem?.title}</b></Typography>
          <Icon
            sx={{
              color: "#86888c",
              cursor: "pointer",
            }}
            onClick={() => setProblem(null)}
            className="material-icons-outlined"
            fontSize="large"
          >
            cancel
          </Icon>
        </Box>
        <Box display="flex" gap={isMobile ? 0 : "1rem"} flexDirection={isMobile ? "column-reverse" : "row"}>
          <Box width={isMobile ? "100%" : "65%"}>
            <Box sx={{ padding: "0.5rem", mt: "1.5rem" }}>
              <div style={{textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap"}} dangerouslySetInnerHTML={{ __html: formatText(problem?.description) }} />
              <Box mt="2.5rem">
                <Typography variant='caption'>{i18n.t("sprint.kanban.comments")}:</Typography>
                <Box maxHeight="25rem" overflow="auto" ref={messagesRef}>
                  {
                    localMessages?.map((message, index) => (
                      <Message
                      key={message._id} 
                      index={index} 
                      message={message} 
                      user={user} 
                      updateMessage={handleUpdateMessage}
                      />
                      )
                    )
                  }
                </Box>
                <Box 
                  sx={{
                    mt: "0.5rem",
                    "& > *:first-child": {
                      padding: "0 !important",
                    },
                  }}
                  >
                  <ChatInput 
                    customer={problem?.customer?._id}
                    handleInputChange={handleInputChange}
                    handleSendMessage={handleSendMessage}
                    platform={null}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
          <Box width={isMobile ? "100%" :  "35%"}>
            <Box display="flex" flexDirection="column" gap="0.5rem" padding="0.5rem" border="solid 1px #ccc" borderRadius="5px">
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography>{i18n.t("sprint.newAction.pages.revision.status")}:</Typography>
                <FCChip
                  size="small"
                  color={statusColor(problem?.status)?.type}
                  label={handleLabelActivityStatus(problem?.status)}
                />
              </Box>
              {
                DescriptionItem({ label: i18n.t("support.problemsIncidents.modalForm.modalSummary.rootCause"), value: problem?.rootCause })
              }
              {
                DescriptionItem({ label: i18n.t("support.problemsIncidents.modalForm.modalSummary.dispositive"), value: problem?.device })
              }
              {
                DescriptionItem({ label: i18n.t("support.problemsIncidents.modalForm.modalSummary.reproducedError"), value: problem?.repeated })
              }
              {
                DescriptionItem({ label: i18n.t("support.problemsIncidents.modalForm.modalSummary.problemLocation"), value: problem?.problemLocation })
              }
              {
                DescriptionItem({ label: i18n.t("support.problemsIncidents.modalForm.modalSummary.navigator"), value: problem?.browser })
              }
            </Box>
            {
              problem?.status === "Em andamento" && problem.reproved && (user.role === "admin") && <Button className='btn-action' variant="contained" sx={{ mt: "0.5rem" }} onClick={() => {history.push(`/support-performance/reproved-problem/${problem._id}`)
            }}>{i18n.t("sprint.kanban.viewReproval")}</Button>
            }
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}

const mapStateToProps = (state) => ({
  messages: state.message.messages || [],
  customer: state.customer.customerDetails,
});

export default connect(mapStateToProps, actions)(ProblemModal)