import axios from 'axios';
import { api } from '../../config'
import { getHeaders } from './localStorage'
import errorHandling from './errorHandling'

export const getForm = () => async (dispatch) => {
    try {
        const response = await axios.get(api + '/form', getHeaders());
        dispatch({ type: "GET_FORM", payload: response.data.form });
    } catch (err) {
        return errorHandling(err);
    }
}

export const updateForm = (props) => async () => {
    try {
        const response = await axios.put(api + "/form", props, getHeaders());
        return response.data;
    } catch (err) {
        return errorHandling(err);
    }
}

export const clearType = () => {
    return function (dispatch) {
        dispatch({ type: "CLEAR_FORM" })
    }
}