import axios from 'axios';
import {api} from '../../config'
import {getHeaders} from './localStorage'
import errorHandling from './errorHandling'

export const getTasks = () => async (dispatch) => {
    try {
        const response = await axios.get(api + `/tasks`, getHeaders());
        dispatch({ type: "GET_TASKS", payload: response.data });
    } catch (err) {
        return errorHandling(err);
    }
}

export const deleteTask = (_id) => async (dispatch) => {
    try {
        const response = await axios.delete(api+"/tasks/" + _id, getHeaders());
        return response.data;
    } catch (err) {
        return errorHandling(err);
    }
}

export const handleAddTask = (props) => async () => {
    try {
        const response = await axios.post(api+"/tasks", props, getHeaders());
        return response.data;
    } catch (err) {
        return errorHandling(err);
    }
}

export const handleUpdateTask = (props) => async () => {
    try {
        const response = await axios.put(api+"/tasks", props, getHeaders());
        return response.data;
    } catch (err) {
        return errorHandling(err);
    }
}

export const clearTask = () => {
    return function(dispatch){
        dispatch({type: "CLEAR_TASK"})
    } 
}

export const clearTasks = () => {
    return function(dispatch){
        dispatch({type: "CLEAR_TASKS"})
    } 
}