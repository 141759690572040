import { Accordion, AccordionDetails, AccordionSummary, Box, Button, CardActionArea, CircularProgress, Divider, Grid, Grow, Paper, Slider, Tooltip, Typography, useMediaQuery } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import UploadImage from '../../../components/Upload/Image';
import { FormikProvider, useFormik } from 'formik';
import { Form } from 'reactstrap';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions/aiContent';
import * as actions2 from '../../../store/actions/action';
import SendToJiraModal from '../utils/SendToJiraModal';
import { dimensions, formattedContent, imageDescriptions } from '../utils/config';
import SearchActions from '../utils/SearchActions';
import { i18n } from '../../../translate/i18n'
import { FCSelect, FCTextField } from 'components-fullcomm';

const Images = (props) => {
  const isMobileDevice = useMediaQuery('(max-width:600px)');
  const [value, setValue] = useState(1);
  const [image, setImage] = useState('');
  const [blob, setBlob] = useState([]);
  const [open, setOpen] = useState(false);
  const [bookmarked, setBookmarked] = useState([]);
  const [action, setAction] = useState({});

  const formik = useFormik({
    initialValues: {
      img: null,
      samples: 3,
      steps: 50,
      format: 0,
      cfg_scale: 7,
      image_strength: 0.35,
      width: 0,
      height: 0,
      addText: '',
      addWeight: 1,
      removeText: '',
      removeWeight: 1,
      style_preset: 0,
    },
    onSubmit: async (values) => {
      try {
        if (value === 2 && value.height > 0 && value.width > 0) {
          toast.error(i18n.t('aiContent.images.errorSize'));
          return;
        }
        const formData = new FormData();
        if (image) formData.append('image_file', image);
        formData.append('type', value);
        formData.append('cfg_scale', values.cfg_scale);
        if (values.format !== -1) {
          formData.append('width', dimensions[values.format].width);
          formData.append('height', dimensions[values.format].height);
        }
        if (values.width > 0) formData.append('width', values.width);
        if (values.height > 0) formData.append('height', values.height);
        formData.append('steps', values.steps);
        formData.append('samples', values.samples);
        formData.append('image_strength', values.image_strength);
        formData.append('addText', values.addText);
        formData.append('addWeight', values.addWeight);
        formData.append('removeText', values.removeText || " ");
        formData.append('removeWeight', -values.removeWeight);
        if (values.style_preset !== 0) formData.append('style_preset', values.style_preset);
        if (value === 2) {
          if (values.height > 0) formData.append('width', null);
          else {
            formData.append('height', null);
          }
        }

        const res = await props.createClipDrop(formData);
        if (res.error) {
          if (res.message === 'Erro interno do servidor.') {
            toast.error(i18n.t('aiContent.images.internalError'))
          } else {
            toast.error(res.message);
          }
        } else {
          setBlob(res.image || []);
        }
      } catch (error) {
        if (error.error.message === 'Erro interno do servidor.') {
          toast.error(i18n.t('aiContent.images.internalError'))
        } else {
          toast.error(error.error.message);
        }
        
      }
    },
  });

  const { values, handleSubmit, touched, errors, setFieldValue, isSubmitting, getFieldProps, resetForm } = formik;

  const handleDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];
      if (file) {
        setFieldValue('img', {
          ...file,
          preview: URL.createObjectURL(file)
        });
        setImage(file);
      }
    },
    [setFieldValue]
  );

  const saveImg = async (jiraCode = {}, jiraComment = "") => {
    const aux = {
      images: bookmarked,
      type: imageDescriptions.find(item => item.value === value).label,
      ...jiraCode,
      jiraComment,
      prompt: {
        samples: values.samples,
        steps: values.steps,
        height: values.height,
        width: values.width,
        cfg_scale: values.cfg_scale,
        image_strength: values.image_strength,
        addText: values.addText,
        addWeight: values.addWeight,
        removeText: values.removeText,
        removeWeight: values.removeWeight,
        style_preset: values.style_preset,
      },
      category: i18n.t('aiContent.images.categoryImg')
    }
    const response = await props.storeAiContent(aux);
    setBookmarked([]);
    setOpen(true);
    return response;
  }

  const bookmark = (image) => {
    if (bookmarked.includes(image)) {
      setBookmarked(bookmarked.filter(item => item !== image));
    } else {
      setBookmarked([...bookmarked, image]);
    }
  }

  const handleChange = (event) => {
    setValue(event.target.value);
    resetForm();
    if (event.target.value !== 1) setFieldValue('format', -1);
  };

  const handleFieldChange = (min, max, fieldName) => (event) => {
    const inputValue = event.target.value;
    let newValue = inputValue;

    if (inputValue < min) {
      newValue = min;
    } else if (inputValue > max) {
      newValue = max;
    }
    if (fieldName === 'width') setFieldValue('height', 0);
    else if (fieldName === 'height') setFieldValue('width', 0);

    setFieldValue(fieldName, newValue);
  };

  useEffect(() => {
    props.getActions();
  }, [props.shop])

  return (
    <Box className="dashboard">
      <SendToJiraModal setOpen={setOpen} open={open} action={action} store={saveImg} />
      <Typography variant="h4">Imagens</Typography>
      <FormikProvider value={formik}>
        <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
          <Paper sx={{ height: "100%", mt: "1rem", mb: isMobileDevice ? "5rem" : 0, padding: "1rem", borderRadius: "10px" }}>
            <Box
              height={"100%"}
              display={"flex"}
              flexDirection={isMobileDevice ? "column" : "row"}
              justifyContent={"space-between"}
              gap={"1rem"}
            >
              <Box width={isMobileDevice ? "auto" : "25vw"} minWidth={isMobileDevice ? 0 : "15rem"} mt={'1rem'}>
                <SearchActions action={action} actions={props.actions} setAction={setAction} />
                <Box display={"flex"} gap={"1rem"} my={"1rem"} alignItems={"end"}>
                  <FCSelect
                    fullWidth
                    label={i18n.t('aiContent.images.method')}
                    select
                    size='small'
                    value={value}
                    onChange={handleChange}
                    options={imageDescriptions.map((option) => ({ value: option.value, label: option.label }))}
                  />
                  <Button className='btn-action medium' sx={{ float: "right" }} type="submit" variant="contained">
                    {isSubmitting ? (
                      <CircularProgress size={25} sx={{ color: "white" }} thickness={4} />
                    ) : (
                      <>{i18n.t('aiContent.images.send')}</>
                    )}
                  </Button>
                </Box>
                <Grid container spacing={"0.75rem"} mb={"1rem"}>
                  {
                    value !== 2 && (
                      <>
                        <Grid item xs={value === 0 ? 12 : 6}>
                          <FCSelect
                            fullWidth
                            label={i18n.t('aiContent.images.style')}
                            select
                            {...getFieldProps('style_preset')}
                            options={[
                              { value: 0, label: i18n.t('aiContent.images.noneStyle') },
                              { value: "3d-model", label: i18n.t('aiContent.images.model3d') },
                              { value: "analog-film", label: i18n.t('aiContent.images.analogFilm') },
                              { value: "anime", label: i18n.t('aiContent.images.anime') },
                              { value: "cinematic", label: i18n.t('aiContent.images.cinematic') },
                              { value: "comic-book", label: i18n.t('aiContent.images.comicBook') },
                              { value: "digital-art", label: i18n.t('aiContent.images.digitalArt') },
                              { value: "enhance", label: i18n.t('aiContent.images.enhance') },
                              { value: "fantasy-art", label: i18n.t('aiContent.images.fantasy') },
                              { value: "isometric", label: i18n.t('aiContent.images.isometric') },
                              { value: "line-art", label: i18n.t('aiContent.images.lineArt') },
                              { value: "low-poly", label: i18n.t('aiContent.images.lowPoly') },
                              { value: "modeling-compound", label: i18n.t('aiContent.images.modelingClay') },
                              { value: "neon-punk", label: i18n.t('aiContent.images.neon') },
                              { value: "origami", label: i18n.t('aiContent.images.origami') },
                              { value: "photographic", label: i18n.t('aiContent.images.photographic') },
                              { value: "pixel-art", label: i18n.t('aiContent.images.pixelArt') },
                              { value: "tile-texture", label: i18n.t('aiContent.images.tileTexture') },
                            ]}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <Tooltip title={i18n.t('aiContent.images.titleDifusion1')}>
                            <FCTextField
                              fullWidth
                              label={i18n.t('aiContent.images.scaleCFG')}
                              type='number'
                              inputProps={{ step: 1 }}
                              onChange={handleFieldChange(0, 35, 'cfg_scale')}
                              value={values.cfg_scale}
                            />
                          </Tooltip>
                        </Grid>
                        <Grid item xs={6}>
                          <FCTextField
                            fullWidth
                            label={i18n.t('aiContent.images.refinements')}
                            type='number'
                            inputProps={{ min: 10, max: 150, step: 1 }}
                            onChange={handleFieldChange(10, 150, 'steps')}
                            value={values.steps}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <Tooltip title={i18n.t('aiContent.images.qntImages')}>
                            <FCTextField
                              fullWidth
                              label={i18n.t('aiContent.images.samples')}
                              type='number'
                              inputProps={{ min: 0, max: 10, step: 1 }}
                              onChange={handleFieldChange(0, 10, 'samples')}
                              value={values.samples}
                            />
                          </Tooltip>
                        </Grid>
                      </>
                    )
                  }
                  {
                    value === 1 && (
                      <>
                        <Grid item xs={12}>
                          <FCSelect
                            fullWidth
                            label={i18n.t('aiContent.images.format')}
                            type='number'
                            select
                            {...getFieldProps('format')}
                            options={[
                              { value: -1, label: 'Facebook Ads (1024 x 1024px)' },
                              { value: 0, label: 'Instagram - Single image (1024 x 1024px)' },
                              { value: 1, label: 'Instagram - Carousel (1024 x 1024px)' },
                              { value: 2, label: 'Banner Mobile (832 x 1216px)' },
                              { value: 3, label: 'Banner Desktop (1216 x 832px)' },
                              { value: 4, label: 'Instagram - Single image (768 x 1344px)' },
                              { value: 5, label: 'Instagram - Carousel (768 x 1344px)' },
                              { value: 6, label: 'Banner Desktop Wide (1536 x 640px)' },
                              { value: 7, label: 'Instagram - Stories (640 x 1536px)' },
                            ]}
                          />
                        </Grid>
                      </>
                    )
                  }
                  {
                    value === 0 && (
                      <Grid item xs={6}>
                        <Tooltip title={i18n.t('aiContent.images.titleDifusion2')}>
                          <FCTextField
                            fullWidth
                            label={i18n.t('aiContent.images.weightImg')}
                            type='number'
                            inputProps={{ min: 0, max: 1, step: 0.1 }}
                            onChange={handleFieldChange(0, 1, 'image_strength')}
                            value={values.image_strength}
                          />
                        </Tooltip>
                      </Grid>
                    )
                  }
                  {
                    value !== 2 && (
                      <Grid item xs={12} display={"flex"}>
                        <FCTextField
                          fullWidth
                          label={value === 0 ? i18n.t('aiContent.images.addToImg') : i18n.t('aiContent.images.textToImg')}
                          size='small'
                          multiline
                          rows={4}
                          {...formik.getFieldProps('addText')}
                        />
                        {
                          value === 0 && (
                            <Tooltip title={i18n.t('aiContent.images.intensityPropmt')}>
                              <Slider
                                fullWidth
                                orientation='vertical'
                                min={0}
                                max={1}
                                step={0.05}
                                {...getFieldProps('addWeight')}
                              />
                            </Tooltip>
                          )
                        }
                      </Grid>
                    )
                  }
                  {
                    value === 0 && (
                      <>
                        <Grid item xs={12} display={"flex"}>
                          <FCTextField
                            fullWidth
                            label={i18n.t('aiContent.images.removeImg')}
                            multiline
                            rows={4}
                            {...getFieldProps('removeText')}
                          />
                          <Tooltip title={i18n.t('aiContent.images.intensityPropmt')}>
                            <Slider
                              fullWidth
                              orientation='vertical'
                              min={0}
                              max={1}
                              step={0.05}
                              {...getFieldProps('removeWeight')}
                            />
                          </Tooltip>
                        </Grid>
                      </>
                    )
                  }
                  {
                    value === 2 && (
                      <>
                        <Grid item xs={6}>
                          <FCTextField
                            fullWidth
                            label={i18n.t('aiContent.images.width')}
                            type='number'
                            inputProps={{ min: 540, max: 10000, step: 1 }}
                            onChange={handleFieldChange(0, 10000, 'width')}
                            value={values.width}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <FCTextField
                            fullWidth
                            label={i18n.t('aiContent.images.height')}
                            type='number'
                            inputProps={{ min: 540, max: 10000, step: 1 }}
                            onChange={handleFieldChange(0, 10000, 'height')}
                            value={values.height}
                          />
                        </Grid>
                      </>
                    )
                  }
                  {
                    value !== 1 && (
                      <Grid item xs={12}>
                        <UploadImage
                          accept="image/*"
                          file={values.img}
                          maxSize={20000000}
                          onDrop={handleDrop}
                          error={Boolean(touched.img && errors.img)}
                          caption={
                            <Typography
                              variant="caption"
                              sx={{
                                display: 'block',
                                textAlign: 'center',
                                color: 'text.secondary'
                              }}
                              style={{ marginTop: '5px' }}
                            >
                              {i18n.t('aiContent.images.allowed')} *.jpeg, *.jpg, *.png
                            </Typography>
                          }
                        />
                      </Grid>
                    )
                  }
                </Grid>
                <Box padding="1rem" border="1px solid #ccc" borderRadius="5px">
                  <Typography variant='body2' color={"#757575"} sx={{ whiteSpace: 'pre-line' }}>
                    {imageDescriptions.find(item => item.value === value)?.description}
                  </Typography>
                  <Accordion elevation={0} defaultExpanded={true}>
                    <AccordionSummary
                      sx={{ padding: 0 }}
                    // expandIcon={<ExpandMoreIcon />}
                    >
                      <Typography variant='body2' color={"#757575"} sx={{ whiteSpace: 'pre-line' }}>{imageDescriptions.find(item => item.value === value)?.dropdownTitle}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography variant='body2' color={"#757575"} sx={{ whiteSpace: 'pre-line' }}>
                        {formattedContent(imageDescriptions.find(item => item.value === value)?.drowpdownText || '')}
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </Box>
              </Box>
              <Divider orientation={isMobileDevice ? "horizontal" : "vertical"} flexItem />
              <Grid
                container
                spacing={"1rem"}
                height={"fit-content"}
                marginLeft={"auto"}
              >
                {
                  blob?.length > 0 && blob.map((item, index) => {
                    return (
                      <Grid item xs={12} md={6} lg={4} key={index}>
                        <CardActionArea sx={{ borderRadius: "10px" }} onClick={() => bookmark(item.base64)}>
                          <img
                            style={{
                              borderRadius: "10px",
                              width: "auto",
                              height: "auto",
                              maxWidth: "100%",
                              maxHeight: "100%",
                              boxSizing: "border-box",
                              border: bookmarked.includes(item.base64) ? "5px solid rgb(255 184 0)" : "none"
                            }}
                            src={`data:image/png;base64,${item.base64}`}
                            alt={`Image ${index}`}
                          />
                        </CardActionArea>
                      </Grid>
                    )
                  })
                }
                <Grid item xs={12} display={"flex"} justifyContent={"end"}>
                  <Grow in={bookmarked?.length > 0}>
                    <Button className='btn-action' variant="contained" onClick={() => setOpen(true)}>
                      {isSubmitting ? (
                        <CircularProgress size={25} sx={{ color: "white" }} thickness={4} />
                      ) : (
                        <>{i18n.t('aiContent.images.save')}</>
                      )}
                    </Button>
                  </Grow>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Form>
      </FormikProvider>
    </Box>
  )
}

const mapStateToProps = (state) => ({
  actions: state.actions.actions,
  shop: state.auth.shop,
});

export default connect(mapStateToProps, { ...actions, ...actions2 })(Images)