import React, { useCallback, useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

import { Container, Button, Grid,Typography, Accordion, TextField, InputLabel, FormControl, Select, MenuItem, FormControlLabel, Checkbox, AccordionSummary, AccordionDetails, useMediaQuery } from '@mui/material';
import { parents } from '../../../config';

import { getChecklists, handleAddChecklist, getChecklist, handleUpdateChecklist, handleUpdateChecklistOrder, deleteChecklist } from '../../../store/actions/checklist'
import { getViewCustomer, handleUpdateCustomer } from '../../../store/actions/customers'
import '../../../styles/dashboard/start/checklist.css'
import { Icon } from '@iconify/react';
import { i18n } from '../../../translate/i18n';

const Create = (props) => {
    const [form, setForm] = useState(null);
    const [add, setAdd] = useState(null);
    const [data, setData] = useState({});
    const [items, setItems] = useState([]);
    const [itensCheck, setItensCheck] = useState([]);
    const isMobileDevice = useMediaQuery('(max-width:600px)');

    const handleItensCheck = (e, id) => {
        const aux = [...itensCheck];
        const find = aux.find(e => e.field === id);
        if (find) {
            if(find.status === 'd') {
                aux[aux.indexOf(find)].status = 'a';
            } else {
                aux[aux.indexOf(find)].status = 'd';
            }
        } else {
          aux.push({field: id, checklist: form});
        }
        setItensCheck(aux);
      }
    useEffect(() => {
        localStorage.setItem('shop', props.match.params.id);
        props.getChecklists()
    }, []);

    useEffect(() => {
        if(props.customer) setItensCheck(props.customer.checklist)
    }, [props.customer]);

    useEffect(() => {
        if (props.checklists?.length > 0) {
            let aux = {}
            parents.forEach((value, key) => {
                aux = { ...aux, [key]: props.checklists?.filter(e => e.parent === key) }
            });
            setItems(aux)
        }
    }, [props.checklists]);

    const handleForm = async (e) => {
        setForm(e)
        const response = await props.getChecklist(e);
        setData(response.data)
        setAdd(null)
    };

    const save = async () => {
        try {
        const response = await props.handleUpdateCustomer({_id: props.shop, checklist: itensCheck });
        if (response.error) {
            switch (response.error.message) {
                case "Dados inválidos.": return toast.error(i18n.t('start.checklist.create.invalidData'));
                case "Cliente não encontrado.": return toast.error(i18n.t('start.checklist.create.customerNotFound'));
                case "Ocorreu um erro ao atualizar o cliente.": return toast.error(i18n.t('start.checklist.create.updateError'));
                case "Erro interno do servidor.": return toast.error(i18n.t('start.checklist.create.internalError'));
                default: return toast.error(response.error.message);
            }
        } else {
            if (response.message === "Cliente atualizado com sucesso!") {
                toast.success(i18n.t('start.checklist.create.updateSuccess'));
            } else {
                toast.success(response.message)
            }
        }
    } catch (error) {
        if (error.error.message === "Erro interno do servidor.") {
            toast.error(i18n.t('start.checklist.create.internalError'));
        } else {
            toast.error(error.error.message);
        }

    }
}

    return (
        <div class='body'>
            <Grid style={{ borderTop: '1px solid silver', height: '100%' }} container>
                <Grid item xs={3} className="menu">
                    <Grid container className='header'>
                    {
                        isMobileDevice ? (
                            <>
                                <small style={{ padding: 0 }}>{i18n.t('start.checklist.create.checklist')}</small>
                                <h3>{props.customer?.name}</h3>
                                <Button className='saveButton' onClick={() => save()}>{i18n.t('start.checklist.create.save')}</Button>

                            </>
                        ) : (
                            <>
                                <Grid item xs={8}>
                                    <small style={{ padding: 0 }}>{i18n.t('start.checklist.create.checklist')}</small>
                                    <h3>{props.customer?.name}</h3>
                                </Grid>
                        
                                <Grid className='alignCenterVertical' item xs={4} justifyContent='right'>
                                    <Button className='saveButton' onClick={() => save()}>{i18n.t('start.checklist.create.save')}</Button>
                                </Grid>
                            </>
                        )
                    }

                    </Grid>
                    {Array.from(parents, ([key, value]) => (
                        <>
                            <div className={isMobileDevice ? 'item-mobile' : 'item'}>
                                {
                                    !isMobileDevice && (
                                        <h3>{value}</h3>
                                    )
                                }
                            </div>

                            <div className='subitens'>
                                {items[key]?.map((item, index) => {
                                    const selects = itensCheck.filter(e => e.checklist === item._id && e.status !== 'd').length;
                                    return (
                                    <div className={`${isMobileDevice ? 'subitem-mobile' : 'subitem'} ${form === item._id ? 'selected' : ''}`} onClick={() => handleForm(item._id)}>
                                        <Grid container>
                                            <Grid item xs={isMobileDevice ? 6 : 2} className='alignCenterVertical'>
                                                <Icon icon={item.icon} width='30px' />
                                            </Grid>
                                            {
                                                !isMobileDevice && (
                                                    <Grid item xs={8} className='alignCenterVertical'>
                                                        <h4 className='break-word'>{item.title}</h4>
                                                    </Grid>
                                                )
                                            }

                                            <Grid item xs={isMobileDevice ? 6 : 2} className='alignCenterVertical' justifyContent={isMobileDevice ? 'center' : 'right'}>
                                                <small>{selects}/{item.total}</small>
                                            </Grid>
                                        </Grid>
                                    </div>
                                )})}
                            </div>
                        </>
                    ))}
                </Grid>
                <Grid item xs={9}>
                    <Container className={isMobileDevice ? 'p-3' : 'p-5'} style={{ heigth: '100%' }}>
                        {data?._id || add ?
                            <>
                                <Grid container columns={20}>
                                    <Grid textAlign='center' xs={1}>
                                    {
                                        !isMobileDevice && (
                                            <Icon style={{ cursor: 'pointer' }} icon={data.icon} width='30px' />
                                        )
                                    }                                    </Grid>
                                    <Grid xs={19}><h3>{data.title}</h3><p>{data.subtitle || <small>{i18n.t('start.checklist.create.subTitle')}</small>}</p></Grid>
                                </Grid>
                                {data.fields && data.fields.map((field, index) => (
                                    <Accordion
                                    >
                                        <AccordionSummary expandIcon={<Icon onClick={(e) => {
                                            e.stopPropagation();
                                            handleItensCheck(e, field._id)
                                        }} icon={itensCheck.find(e => e.field === field._id && e.status !== 'd') ? 'material-symbols:radio-button-checked-outline' : 'ic:baseline-radio-button-unchecked'} color={itensCheck.find(e => e.field === field._id && e.status !== 'd') ? 'green' : 'grey'} width='30px' />}>
                                            <Typography>{field.title}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                        <Typography>
                                                <Grid container spacing={2} rowSpacing={2}>
                                                    <Grid item xs={12} md={4}>
                                                        <TextField disabled
                                                            size='small' fullWidth value={field.title} label={i18n.t('start.checklist.create.title')} />
                                                    </Grid>
                                                    <Grid item xs={12} md={4}>
                                                        <TextField disabled
                                                            size='small' fullWidth value={field.helperText} label={i18n.t('start.checklist.create.helperText')} />
                                                    </Grid>
                                                    <Grid item xs={12} md={4}>
                                                        <TextField disabled
                                                            size='small' value={field.placeholder} fullWidth label={i18n.t('start.checklist.create.placeHolder')}  />
                                                    </Grid>
                                                    <Grid item xs={12} md={4}>
                                                        <FormControl fullWidth>
                                                            <InputLabel>{i18n.t('start.checklist.create.type')}</InputLabel>
                                                            <Select
                                                                disabled
                                                                size='small'
                                                                value={field.type}
                                                                label={i18n.t('start.checklist.create.type')}
                                                            >
                                                                <MenuItem value='text'>{i18n.t('start.checklist.create.text')}</MenuItem>
                                                                <MenuItem value={'textarea'}>{i18n.t('start.checklist.create.textArea')}</MenuItem>
                                                                <MenuItem value={'number'}>{i18n.t('start.checklist.create.count')}</MenuItem>
                                                                <MenuItem value={'money'}>{i18n.t('start.checklist.create.money')} (R$)</MenuItem>
                                                                <MenuItem value={'select'}>{i18n.t('start.checklist.create.uniqueList')}</MenuItem>
                                                                <MenuItem value={'multiselect'}>{i18n.t('start.checklist.create.multipleList')}</MenuItem>
                                                                <MenuItem value={'url'}>{i18n.t('start.checklist.create.url')}</MenuItem>
                                                                <MenuItem value={'date'}>{i18n.t('start.checklist.create.date')}</MenuItem>
                                                                <MenuItem value={'email'}>{i18n.t('start.checklist.create.email')}</MenuItem>
                                                                <MenuItem value={'radio'}>{i18n.t('start.checklist.create.radio')}</MenuItem>
                                                                <MenuItem value={'checkbox'}>{i18n.t('start.checklist.create.checklist')}</MenuItem>
                                                                <MenuItem value={'attachment'}>{i18n.t('start.checklist.create.attachment')}</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item xs={12} md={4}>
                                                    </Grid>
                                                    <Grid item xs={12} md={4}>
                                                        <FormControlLabel disabled control={<Checkbox checked={field.required} />} label={i18n.t('start.checklist.create.mandatory')} />
                                                    </Grid>

                                                    {field.type === 'select' || field.type === 'multiselect' || field.type === 'radio' ? (
                                                        <Grid item xs={12}>
                                                            <p><b>{i18n.t('start.checklist.create.options')}</b></p>
                                                            <Grid spacing={2} container rowSpacing={2}>
                                                                {field.options && field.options.map((option, indexOpt) =>
                                                                    <Grid item xs={12} md={4}>
                                                                        <TextField
                                                                            size='small' InputProps={{
                                                                            }} value={option} disabled fullWidth />
                                                                    </Grid>
                                                                )}
                                                            </Grid>
                                                        </Grid>
                                                    ) : <></>}
                                                </Grid>
                                            </Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                ))}
                            </>
                            : <>{i18n.t('start.checklist.create.selectItem')}</>}
                    </Container>
                </Grid>
            </Grid>
        </div >
    );
};

const mapStateToProps = state => ({
    checklists: state.checklist.checklists,
    customer: state.customer.customerDetails,
    shop: state.auth.shop,
})


const mapDispatchToProps = (dispatch) => {
    return {
        getChecklists: () => dispatch(getChecklists()),
        handleAddChecklist: (data) => dispatch(handleAddChecklist(data)),
        getChecklist: (id) => dispatch(getChecklist(id)),
        deleteChecklist: (id) => dispatch(deleteChecklist(id)),
        handleUpdateCustomer: (data) => dispatch(handleUpdateCustomer(data)),
        getViewCustomer: (id) => dispatch(getViewCustomer(id)),
        handleUpdateChecklist: (data) => dispatch(handleUpdateChecklist(data)),
        handleUpdateChecklistOrder: (data) => dispatch(handleUpdateChecklistOrder(data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Create);
