import ForumIcon from '@mui/icons-material/Forum';
import SmsIcon from '@mui/icons-material/Sms';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import EmailIcon from '@mui/icons-material/Email';
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import InstagramIcon from '@mui/icons-material/Instagram';
import SendToMobileIcon from '@mui/icons-material/SendToMobile';
import { Box, Grid, Typography } from '@mui/material';

export function convertMsToTime(milliseconds) {
  let totalSeconds = milliseconds / 1000;

  let hours = Math.floor(totalSeconds / 3600);
  hours = hours < 10 ? `0${hours}` : hours;

  totalSeconds %= 3600;

  let minutes = Math.floor(totalSeconds / 60);
  minutes = minutes < 10 ? `0${minutes}` : minutes;
  let seconds = Math.floor(totalSeconds % 60);
  seconds = seconds < 10 ? `0${seconds}` : seconds;

  return `${hours}:${minutes}:${seconds}`;
}

export const getIcon = (channel) => {
  switch (channel) {
    case 'whatsapp':
      return <WhatsAppIcon sx={{ height: '16px', width: '16px', color: '#888888' }}/>
    case 'sms':
      return <SmsIcon sx={{ height: '16px', width: '16px', color: '#888888'  }}/>
    case 'email':
      return <EmailIcon sx={{ height: '16px', width: '16px', color: '#888888'  }}/>
    case 'messenger':
      return <FacebookOutlinedIcon sx={{ height: '16px', width: '16px', color: '#888888' }}/>
    case 'instagram':
      return <InstagramIcon sx={{ height: '16px', width: '16px', color: '#888888' }}/>
    case 'mobile':
      return <SendToMobileIcon sx={{ height: '16px', width: '16px', color: '#888888' }}/>
    default:
      return <svg width="16" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1.00067 15L0.070702 14.63C0.00425373 14.7972 -0.0158565 14.9792 0.0124908 15.1569C0.0408381 15.3346 0.116593 15.5013 0.23177 15.6395C0.346947 15.7777 0.497279 15.8823 0.66692 15.9422C0.83656 16.0022 1.01922 16.0152 1.19566 15.98L1.00067 15ZM5.70648 14.064L6.18046 13.183L5.86347 13.013L5.51149 13.083L5.70648 14.064ZM2.6246 10.917L3.55457 11.287L3.71756 10.873L3.52157 10.474L2.6246 10.917ZM16.0001 8C16.0001 11.246 13.1472 14 9.47033 14V16C14.1112 16 18 12.486 18 8H16.0001ZM2.94159 8C2.94159 4.754 5.79548 2 9.47133 2V0C4.83052 0 0.940668 3.514 0.940668 8H2.94159ZM9.47133 2C13.1472 2 16.0001 4.754 16.0001 8H18C18 3.514 14.1122 0 9.47133 0V2ZM9.47033 14C8.26538 14 7.14342 13.7 6.18046 13.183L5.2325 14.944C6.53629 15.6414 7.99178 16.0042 9.47033 16V14ZM1.19566 15.98L5.90147 15.044L5.51149 13.083L0.805673 14.019L1.19566 15.981V15.98ZM3.52157 10.474C3.13966 9.7052 2.94181 8.85844 2.94159 8H0.940668C0.940668 9.2 1.22266 10.338 1.72664 11.36L3.52157 10.474ZM1.69564 10.547L0.070702 14.631L1.92863 15.369L3.55257 11.286L1.69464 10.547H1.69564Z" fill="#888888"/>
        <path d="M6.00051 9C6.55277 9 7.00047 8.55228 7.00047 8C7.00047 7.44772 6.55277 7 6.00051 7C5.44825 7 5.00055 7.44772 5.00055 8C5.00055 8.55228 5.44825 9 6.00051 9Z" fill="#888888"/>
        <path d="M9.50036 9C10.0526 9 10.5003 8.55228 10.5003 8C10.5003 7.44772 10.0526 7 9.50036 7C8.94809 7 8.5004 7.44772 8.5004 8C8.5004 8.55228 8.94809 9 9.50036 9Z" fill="#888888"/>
        <path d="M13.0002 9C13.5525 9 14.0002 8.55228 14.0002 8C14.0002 7.44772 13.5525 7 13.0002 7C12.4479 7 12.0002 7.44772 12.0002 8C12.0002 8.55228 12.4479 9 13.0002 9Z" fill="#888888"/>
      </svg>
      
  }
}

export const dataGrid = ({data, title, Icon}) => {
  return (
    <Box borderBottom='1px solid #D1D5DB' boxShadow='0px 4px 4px 0px #0000001A' borderRadius='8px' p='12px 16px 12px 16px' width="100%" mt='1rem'>
      <Box display='flex' gap='0.5rem' alignItems='center' mb='0.25rem'>
        {Icon && <Icon sx={{ color: '#888888'}} />}
        <Typography variant='body2'>{title}</Typography>
      </Box>
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6} md={4} sx={{ borderRight: "1px solid #dadce3"}}>
        <Typography variant='h7'>{convertMsToTime(data?.min || 0)}</Typography>
        <Typography fontSize='12px'>Tempo mínimo</Typography>
      </Grid>
      <Grid item xs={12} sm={6} md={4} sx={{ borderRight: "1px solid #dadce3"}}>
        <Typography variant='h7'>{convertMsToTime(data?.avg || 0)}</Typography>
        <Typography fontSize='12px'>Tempo médio</Typography>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <Typography variant='h7'>{convertMsToTime(data?.max || 0)}</Typography>
        <Typography fontSize='12px'>Tempo máximo</Typography>
      </Grid>
    </Grid>
  </Box>
  )
}
