import { Box, Button, Grid, Paper } from '@mui/material'
import { FormikProvider, useFormik } from 'formik'
import React, { useEffect } from 'react'
import { toast } from 'react-toastify'
import { Form } from 'reactstrap'
import * as actions from '../../../store/actions/apps'
import { connect } from 'react-redux'
import { i18n } from '../../../translate/i18n';
import { FCTextField } from 'components-fullcomm'

const AppFormCms = ({ category, updateApp, app, getApp }) => {
  useEffect(() => {

  }, [app]);

  var formik = useFormik({
    enableReinitialize: true,
    validateOnMount: true,
    initialValues: {
      success: app?.notifications?.success || '',
      error: app?.notifications?.error || '',
      info: app?.notifications?.info || '',
      other: app?.notifications?.other || '',
    },
    // validationSchema: NewActionSchema,

    onSubmit: async (values, { setSubmitting, resetForm }) => {
      try {
        const response = await updateApp({ category, notifications: values });
        getApp(`category=${category}`);

        if (response.error) {
          switch (response.error.message) {
            case "Erro ao atualizar app!": return toast.error(i18n.t('apps.utils.appFormNotifications.updateAppError'));
            case 'Erro interno do servidor.': return toast.error(i18n.t('apps.utils.appFormNotifications.internalError'));
            default: toast.error(response.error.message);
          }
        } else {
          if (response.message === "App criado com sucesso!") {
            toast.success(i18n.t('apps.utils.appFormNotifications.createAppSuccess'))
          } else if (response.message === "App atualizado com sucesso!") {
            toast.success(i18n.t('apps.utils.appFormNotifications.updateAppSuccess'))
          } else {
            toast.success(response.message)
          }
        }
      } catch (error) {
        if (error.error.message === 'Erro interno do servidor.') {
          toast.error(i18n.t('apps.utils.appFormNotifications.internalError'))
        } else {
          toast.error(error.message)
        }
      }
    },
  });

  const { isSubmitting, handleSubmit, getFieldProps, touched, errors } = formik;

  return (
    <Box>
      <FormikProvider value={formik}>
        <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
          <Box display={"flex"} justifyContent={"end"} mb={"1rem"}>
            <Button variant="contained" className='btn-action' type='submit' onClick={() => { }}>
              {i18n.t('apps.utils.appFormNotifications.sincAlt')}
            </Button>
          </Box>
          <Paper sx={{ padding: "1rem", my: "1rem", borderRadius: '5px' }}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <FCTextField
                  fullWidth
                  label={i18n.t('apps.utils.appFormNotifications.success')}
                  placeholder={i18n.t('apps.utils.appFormNotifications.success')}
                  {...getFieldProps("success")}
                  error={Boolean(touched.success && errors.success)}
                  helperText={touched.success && errors.success}
                />
              </Grid>
              <Grid item xs={6}>
                <FCTextField
                  fullWidth
                  label={i18n.t('apps.utils.appFormNotifications.error')}
                  placeholder={i18n.t('apps.utils.appFormNotifications.error')}
                  {...getFieldProps("error")}
                  error={Boolean(touched.error && errors.error)}
                  helperText={touched.error && errors.error}
                />
              </Grid>
              <Grid item xs={6}>
                <FCTextField
                  fullWidth
                  label={i18n.t('apps.utils.appFormNotifications.info')}
                  placeholder={i18n.t('apps.utils.appFormNotifications.info')}
                  {...getFieldProps("info")}
                  error={Boolean(touched.info && errors.info)}
                  helperText={touched.info && errors.info}
                />
              </Grid>
              <Grid item xs={6}>
                <FCTextField
                  fullWidth
                  label={i18n.t('apps.utils.appFormNotifications.other')}
                  placeholder={i18n.t('apps.utils.appFormNotifications.other')}
                  {...getFieldProps("other")}
                  error={Boolean(touched.other && errors.other)}
                  helperText={touched.other && errors.other}
                />
              </Grid>
            </Grid>
          </Paper>
        </Form>
      </FormikProvider>
    </Box>
  )
}

const mapStateToProps = (state) => ({
  app: state.app.app
});

export default connect(mapStateToProps, actions)(AppFormCms);