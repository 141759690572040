import { Box, CardActionArea, Paper, Typography } from '@mui/material'
import React from 'react'
import { isMobile } from "react-device-detect";
import CampaignIcon from '../../../../assets/campanha.svg'
import ImprovementIcon from '../../../../assets/melhoria.svg'
import ImplementationIcon from '../../../../assets/implementação.svg'
import { i18n } from '../../../../translate/i18n';

const style = {
  paper: {borderRadius: "5px", maxWidth: isMobile ? "100%" : "18rem"},
  contentBox: {display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", minWidth: "40%", border: "1px solid #ccc", borderRadius: "5px", padding: isMobile ? "0.5rem" : "1rem", marginBottom: isMobile ? 0 : "1rem"},
  cardActionArea: {padding: isMobile ? "0.5rem" : "1rem", display: "flex", flexDirection: isMobile ? "row" : "column", height: "100%"}
}

const Type = ({updateUnfinishedCount, setFieldValue, setPage, platform }) => {
  const handleChange = (value) => {
    updateUnfinishedCount('add')
    setFieldValue("type", value);
    setPage(0);
  }

  return (
    <Box>
      <Typography variant="h4" align="center">{i18n.t("sprint.newAction.pages.type.whatTypeAction")}</Typography>
      <Box display="flex" flexDirection={isMobile ? "column" : "row"} justifyContent="center" gap={isMobile ? "1rem" : "2rem"} mt={isMobile ? "1rem" : "2rem"} mb={isMobile ? "4rem" : 0}>
        {
          platform === "sprint" && (
            <Paper sx={style.paper}>
              <CardActionArea sx={style.cardActionArea} onClick={() => handleChange("Campanha")}>
                <Box sx={style.contentBox}>
                  <img src={CampaignIcon} alt="Campanha" style={{width: isMobile ? "5rem" : "13rem"}} />
                  <Typography variant={isMobile ? "h6" : "h5"} align="center">{i18n.t("sprint.newAction.pages.type.campaign")}</Typography>
                </Box>
                <Typography color="#757575" padding={isMobile ? 0 : "0.5rem"} align="center">{i18n.t("sprint.newAction.pages.type.requestCampaign")}</Typography>
              </CardActionArea>
            </Paper>
          )
        }
        <Paper sx={style.paper}>
          <CardActionArea sx={style.cardActionArea } onClick={() => handleChange("Implementação")}>
            <Box sx={style.contentBox}>
              <img src={ImplementationIcon} alt="Implementação" style={{width: isMobile ? "5rem" : "13rem"}} />
              <Typography variant={isMobile ? "h6" : "h5"} align="center">{i18n.t("sprint.newAction.pages.type.implementation")}</Typography>
            </Box>
            <Typography color="#757575" padding={isMobile ? 0 : "0.5rem"} align="center">{i18n.t("sprint.newAction.pages.type.requestReports")}</Typography>
          </CardActionArea>
        </Paper>
        <Paper sx={style.paper}>
          <CardActionArea sx={style.cardActionArea} onClick={() => handleChange("Melhoria")}>
            <Box sx={style.contentBox}>
              <img src={ImprovementIcon} alt="Melhoria" style={{width: isMobile ? "5rem" : "13rem"}} />
              <Typography variant={isMobile ? "h6" : "h5"} align="center">{i18n.t("sprint.newAction.pages.type.improvement")}</Typography>
            </Box>
            <Typography color="#757575" padding={isMobile ? 0 : "0.5rem"} align="center">{i18n.t("sprint.newAction.pages.type.requestImprovement")}</Typography>
          </CardActionArea>
        </Paper>
      </Box>
    </Box>
  )
}

export default Type
