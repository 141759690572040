import React, { useEffect } from 'react'
import { Box, Button, Paper, Step, Stepper, Typography } from '@mui/material'
import * as scaleActions from '../../store/actions/scale';
import * as customerActions from '../../store/actions/customers';
import { connect } from 'react-redux';
import { isMobile } from "react-device-detect";
import { i18n } from '../../translate/i18n';
import LockIcon from '@mui/icons-material/Lock';

const style = {
  step: {
    border: "1px solid #D5D5D5",
    borderRadius: "12px",
    padding: "1rem",
    minWidth: "160px",
    display: "flex", 
    flexDirection: isMobile ? "row" : "column",
    justifyContent: isMobile ? "space-between" : "flex-start",
    alignItems: isMobile ? "center" : "flex-start",
  },
  typography: {
    display: "flex",
    alignItems: "center",
    fontSize: "15px",
    fontWeight: "500",
    width: "94px",
    height: "32px",
    lineHeight: "17px",
    letterSpacing: "0.46px"
  }
}

const Operation = (props) => {

  const handleClick = () => {
    window.location.href = `mailto:help@fullcomm.io`;
  }

  useEffect(() => {
    props.fetchToken();
    props.getViewCustomer(props.shop);
  }, [props.shop])

  useEffect(() => {
    props.getLogisticsInfoHome('30')
}, [props.tokenApi])

  return (
    <Box mt="2.5rem">
      <Typography variant="h4" mb="1.5rem">{i18n.t('dashboard.operation')}</Typography>
      <Paper sx={{ p: 2, display: 'flex', alignItems: 'center', justifyContent: "space-between", borderRadius: "1.5rem", minHeight: "200px", backgroundColor: "#F3F3F3" }}>
        {
          props.customer?.platform?.includes('scale') ? (
            <Stepper sx={{width: "100%", overflowY: "auto"}} orientation={isMobile ? "vertical" : "horizontal"}>
            <Step sx={style.step}>
              <Box display="flex" gap="10px" alignItems="center">
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M9 5V19H11V7H25V15H27V5H9ZM13 11V13H15V11H13ZM17 11V13H23V11H17ZM13 15V17H15V15H13ZM17 15V17H18.5V15H17ZM20 16V18H21V20C21 21.6016 21.8008 23.1016 23 24C21.8008 24.8984 21 26.3984 21 28V30H20V32H32V30H31V28C31 26.3984 30.1992 24.8984 29 24C30.1992 23.1016 31 21.6016 31 20V18H32V16H20ZM23 18H29V20C29 21.6992 27.6992 23 26 23C24.3008 23 23 21.6992 23 20V18ZM5 20V23C5 25.207 6.79297 27 9 27H19.582C19.6953 26.3008 19.9258 25.6289 20.2539 25H9C7.89844 25 7 24.1016 7 23V22H19.8281C19.6172 21.3594 19.5 20.6836 19.5 20H5ZM24 20C24 20 24 22 26 22C28 22 28 20 28 20H24ZM26 25C27.6992 25 29 26.3008 29 28V30H28C28 28.8984 27.1016 28 26 28C24.8984 28 24 28.8984 24 30H23V28C23 26.3008 24.3008 25 26 25Z" fill="black"/>
                </svg>
                <Typography sx={style.typography}>{i18n.t('dashboard.created')}</Typography>
              </Box>
              <Typography fontWeight={600} fontSize="25.63px" align='center'>{props.logisticsInfo?.total_orders || 0}</Typography>
            </Step>
            <Step sx={style.step}>
              <Box display="flex" gap="10px" alignItems="center">
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M16 4C11.8299 4 8.1512 6.13611 6 9.375V7H4V13H10V11H7.33789C9.06496 8.01322 12.2912 6 16 6C21.5345 6 26 10.4655 26 16H28C28 9.38453 22.6155 4 16 4ZM4 16C4 22.6155 9.38453 28 16 28C20.1701 28 23.8488 25.8639 26 22.625V25H28V19H22V21H24.6621C22.935 23.9868 19.7088 26 16 26C10.4655 26 6 21.5345 6 16H4Z" fill="black"/>
                  <path d="M14.0796 11L15.9907 14.3306L17.939 11H20.3975L17.3545 15.9263L20.5181 21.0381H18.0596L16.0186 17.5684L13.9775 21.0381H11.5098L14.6641 15.9263L11.6304 11H14.0796Z" fill="black"/>
                </svg>
                <Typography sx={style.typography}>{i18n.t('dashboard.error')}</Typography>
              </Box>
              <Typography fontWeight={600} fontSize="25.63px" align='center'>{props.logisticsInfo?.statusError || 0}</Typography>
            </Step>
            <Step sx={style.step}>
              <Box display="flex" gap="10px" alignItems="center">
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M24 3L20 7L24 11V8H28V6H24V3ZM12 4C7.59357 4 4 7.59357 4 12C4 16.4064 7.59357 20 12 20C16.4064 20 20 16.4064 20 12C20 7.59357 16.4064 4 12 4ZM12 6C15.3256 6 18 8.67445 18 12C18 15.3256 15.3256 18 12 18C8.67445 18 6 15.3256 6 12C6 8.67445 8.67445 6 12 6ZM21.7988 14C21.6568 14.696 21.4372 15.364 21.1582 16H26V26H16V21.1582C15.364 21.4372 14.696 21.6568 14 21.7988V28H28V14H21.7988ZM20 18C19.8675 17.9981 19.7359 18.0226 19.613 18.072C19.49 18.1214 19.3781 18.1948 19.2837 18.2878C19.1893 18.3809 19.1144 18.4918 19.0632 18.614C19.0121 18.7363 18.9858 18.8675 18.9858 19C18.9858 19.1325 19.0121 19.2637 19.0632 19.386C19.1144 19.5082 19.1893 19.6191 19.2837 19.7122C19.3781 19.8052 19.49 19.8786 19.613 19.928C19.7359 19.9774 19.8675 20.0019 20 20H22C22.1325 20.0019 22.2641 19.9774 22.387 19.928C22.51 19.8786 22.6219 19.8052 22.7163 19.7122C22.8107 19.6191 22.8856 19.5082 22.9368 19.386C22.9879 19.2637 23.0142 19.1325 23.0142 19C23.0142 18.8675 22.9879 18.7363 22.9368 18.614C22.8856 18.4918 22.8107 18.3809 22.7163 18.2878C22.6219 18.1948 22.51 18.1214 22.387 18.072C22.2641 18.0226 22.1325 17.9981 22 18H20ZM8 21V24H4V26H8V29L12 25L8 21Z" fill="black"/>
                  <path d="M15 9L10.707 13.293L8.41406 11L7 12.4141L10.707 16.1211L16.4141 10.4141L15 9Z" fill="black"/>
                </svg>
                <Typography sx={style.typography}>{i18n.t('dashboard.shipment')}</Typography>
              </Box>
              <Typography fontWeight={600} fontSize="25.63px" align='center'>{props.logisticsInfo?.statusShipment || 0}</Typography>
            </Step>
            <Step sx={style.step}>
            <Box display="flex" gap="10px" alignItems="center">
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M5 5V27H27V21L25 23V25H7V7H25V9L27 11V5H5ZM13.3125 9C12.7617 9.05078 12.3555 9.54297 12.4062 10.0938C12.457 10.6445 12.9492 11.0508 13.5 11H18.5C18.8594 11.0039 19.1953 10.8164 19.3789 10.5039C19.5586 10.1914 19.5586 9.80859 19.3789 9.49609C19.1953 9.18359 18.8594 8.99609 18.5 9H13.5C13.4688 9 13.4375 9 13.4062 9C13.375 9 13.3438 9 13.3125 9ZM24.2188 10.7812L22.7812 12.2188L25.5625 15H17V17H25.5625L22.7812 19.7812L24.2188 21.2188L28.7188 16.7188L29.4062 16L28.7188 15.2812L24.2188 10.7812Z" fill="black"/>
                </svg>
                <Typography sx={style.typography}>{i18n.t('dashboard.carrier')}</Typography>
              </Box>
              <Typography fontWeight={600} fontSize="25.63px" align='center'>{props.logisticsInfo?.statusShipping || 0}</Typography>
            </Step>
            <Step sx={style.step}>
              <Box display="flex" gap="10px" alignItems="center">
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M4 5V11H5V27H27V11H28V5H4ZM6 7H26V9H6V7ZM7 11H25V25H7V11ZM16.2051 13L14.6797 16.4668L10.8945 16.8672L13.6836 19.334L12.9512 23L16.2051 21.1328L19.4609 23L18.7305 19.332L21.5195 16.8652L17.7344 16.4668L16.2051 13Z" fill="black"/>
                </svg>
                <Typography sx={style.typography}>{i18n.t('dashboard.client')}</Typography>
              </Box>
              <Typography fontWeight={600} fontSize="25.63px" align='center'>{props.logisticsInfo?.statusCustomer || 0}</Typography>
            </Step>
          </Stepper>
          ) : (
            <Box display="flex" justifyContent="center" alignItems="center" width="100%">
              <Paper sx={{ padding: '1rem', width: "50%", minWidth: "300px", maxWidth: "450px" }}>
                <Box fullWidth style={{ textAlign: 'center' }}>
                  <Typography variant="h6" style={{ marginTop: '5px', display: "flex", gap: "5px", alignItems: "center", justifyContent: "center" }}>
                    <LockIcon />
                    {i18n.t('dashboard.startWithOperation')}
                  </Typography>
                  <Typography variant="body2" style={{ marginTop: '5px' }}>
                    {i18n.t('dashboard.useOperationModule')}
                  </Typography>
                  <br />
                  <Button className='btn-action' variant='contained' onClick={handleClick}>
                    {i18n.t('dashboard.requestModule')}
                  </Button>
                </Box>
              </Paper>
            </Box>
          )
        } 
      </Paper>
    </Box>
  )
}

const mapStateToProps = (state) => ({
  shop: state.auth.shop,
  tokenApi: state.scale.tokenApi,
  logisticsInfo: state.scale.logisticsInfo,
  customer: state.customer.customerDetails,
})

export default connect(mapStateToProps, {...scaleActions, ...customerActions})(Operation);