import { Avatar, Box, Chip, IconButton, Modal, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { api, repository } from '../../config';
import moment from 'moment';
import { i18n } from '../../translate/i18n';
import FullCommLogo from '../../assets/favicon.png';
import Attachment from './utils/Attachtment';
import ActionCard from '../../pages/Sprint/Strategy/ActionCard';
import sanitizeHtml from 'sanitize-html';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

function formatText (text) {

  const formattedText =  text?.replace(/@~~~([^~]+)~~~/g, (match, username) => {
    return `<span class='mention-text'> @${username.replace(/[^a-zA-Z0-9]/g, '')}</span>`;
  });

  return sanitizeHtml(formattedText, {
    allowedTags: ['p', 'em', 'span', 'strong', 'ul', 'li', 'a'],
    allowedClasses: {
      'span': ["mention-text", "mention"],
      li: ["ql-indent-1"]
    },
  });
}

const Message = ({ message, user, updateMessage, setOpenModal, setDeleteMessageId }) => {
  const [hovered, setHovered] = useState(false);
  const [lightboxImage, setLightboxImage] = useState(null);
  const [text, setText] = useState(formatText(message.text));
  const tagged = message.taggedUsers?.find((tagged) => tagged === user?._id);

  const closeLightbox = () => {
    setLightboxImage(null);
  };

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  useEffect(() => {
    setText(formatText(message.text));
  }, [message.text]);

  return (
    <Box
      key={message?._id}
      sx={{
        display: "flex",
        alignItems: "flex-start",
        p: '10px',
        backgroundColor: tagged ? "#f7f7cd" : "transparent",
        wordBreak: "break-word",
      }}
    >
      <Avatar
        src={
          message.author ?
          message.author.fileLocation ? 
          message.author.fileLocation
          :
          `${api}/files/users/${message.author?.fileName}` 
          :
          FullCommLogo
        }
        alt={message.author?.name}
        sx={{ mr: 1 }}
      />
      <Box className="message" sx={{ display: "flex", flexDirection: "column", width: '100%' }}>
        <Box sx={{ display: "flex", alignItems: "baseline" }}>
          <Typography variant="subtitle2" sx={{ fontWeight: "bold", mr: 1, fontSize: "14px" }}>
            {message.author?.name || 'FullComm'}
          </Typography>
          <Typography variant="caption" sx={{ color: "text.secondary" }}>
            {moment(message.createdAt).format("DD/MM/YYYY - HH[h]mm")}
          </Typography>
          {
            message.reproved && (
              <Chip label={i18n.t("sprint.kanban.reproved")} size='small' color="to_do" sx={{marginLeft: "0.25rem"}}/>
            )
          }
        </Box>
        {
          message.deleted ? (
            <Typography variant="body2" sx={{ color: "#979797" }}>
              {i18n.t('components.chat.chatConversation.messageDeleted')}
            </Typography>
          ) : (
            <>
              <Box
                  id={message._id}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  style={{ backgroundColor: hovered ? '#d3d3d340' : 'inherit', width: '100%', position: 'relative' }}
                >
                  <Typography variant="body2" whiteSpace="pre-line">
                  <div
                    dangerouslySetInnerHTML={{__html: text}} 
                    className="dangerous-html"
                  /> 
                  {message.edited ? <i style={{color: "#979797"}}>({i18n.t('components.chat.chatConversation.edited')})</i> : ""}
                  </Typography>
                  {
                    message.action && 
                    <Box 
                      sx={{
                        marginTop: '1rem',
                        '& > *:first-child': {
                          border:'0px 0px 2px 0px', 
                          boxShadow:'0px 4px 4px 0px #00000026',
                        }
                      }}
                    >
                      <ActionCard action={message.action} user={user} />
                    </Box>
                  }
                  {message.files?.length > 0 && (
                    <Box sx={{ display: "flex", flexDirection: "column", mb: '10px' }}>
                      {message.files.map((file, j) => (
                        <Attachment
                          key={j} 
                          {...file} 
                          setLightboxImage={()=> setLightboxImage(file.location ? file.location : `${repository}/files/chat/${file.filename}`)} 
                        />
                      ))}
                    </Box>
                  )}
                  <Box
                    sx={{
                      position: 'absolute',
                      top: '-30px',
                      bottom: 'auto',
                      right: "10px",
                      visibility: hovered ? 'visible' : 'hidden'
                    }}
                  >

                  {
                    message?.author?._id === user?._id && !message.reproved && updateMessage &&
                    <>
                      <IconButton size="small" onClick={() => updateMessage(message)}>
                        <EditOutlinedIcon style={{ height: '20px' }} />
                      </IconButton>
                      {
                        user?.role === 'admin' &&
                        <IconButton size="small" onClick={() => {setOpenModal(true); setDeleteMessageId(message._id)}}>
                          <DeleteOutlineOutlinedIcon style={{ height: '20px' }} />
                        </IconButton>
                      }
                    </>
                  }
                  </Box>
                </Box>

            </>
          )
        }
      </Box>
      <Modal open={lightboxImage !== null} onClose={closeLightbox}>
        <Box
          sx={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <img
            src={lightboxImage}
            alt="Imagem"
            style={{ maxWidth: "80vw", maxHeight: "80vh" }}
          />
        </Box>
      </Modal>
    </Box>
  );
};

export default Message;