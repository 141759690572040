import React from 'react'
import { Route } from 'react-router-dom';

import Logistics from '../pages/Scale/Logistics';
import Service from '../pages/Scale/Service';
import SmartShipping from '../pages/Scale/SmartShipping';
import InventoryLevel from '../pages/Insight/Product/InventoryLevel';
import CurveABC from '../pages/Insight/Product/CurveABC';
import ProductsInfo from '../pages/Scale/ProductsInfo';
import PlatinumLog from '../pages/Scale/PlatinumLog';
import Order from '../pages/Scale/PlatinumLog/Order';
import Boteria from '../pages/Scale/Boteria';

const Scale = ({ baseDashboard }) => {
  return (
    <>
      <Route path="/logistics" exact component={baseDashboard({ Component: Logistics, permissions: ['admin', 'customer', 'agency'], platform: ['scale'], })} />
      <Route path="/service" exact component={baseDashboard({ Component: Service, permissions: ['admin', 'customer', 'agency'], platform: ['scale'], })} />
      <Route path="/smart-shipping" exact component={baseDashboard({ Component: SmartShipping, permissions: ['admin', 'customer', 'agency'], platform: ['scale'], })} />
      <Route path="/insights/product/inventory-level" exact component={baseDashboard({ Component: InventoryLevel, permissions: ['admin', 'customer', 'agency'], platform: ['scale'], })} />
      <Route path="/insights/inventory" exact component={baseDashboard({ Component: CurveABC, permissions: ['admin', 'customer', 'agency'], platform: ['scale'], })} />
      <Route path="/insights/sales" exact component={baseDashboard({ Component: ProductsInfo, permissions: ['admin', 'customer', 'agency'], platform: ['scale'], })} />
      <Route path="/orders" exact component={baseDashboard({ Component: PlatinumLog, permissions: ['admin', 'customer', 'agency'], platform: ['scale'], })} />
      <Route path="/order/:id" exact component={baseDashboard({ Component: Order, permissions: ['admin', 'customer', 'agency'], platform: ['scale'], })} />
      <Route path="/boteria" exact component={baseDashboard({ Component: Boteria, permissions: ['admin', 'customer', 'agency'], platform: ['scale'], })} />

    </>
  )
}

export default Scale