import axios from 'axios';
import {api} from '../../config'
import {getHeaders} from './localStorage'
import errorHandling from './errorHandling'

export const getProjects = (pageCurrent, limit, customer = "", status = "") => async (dispatch) => {
    try {
        const response = await axios.get(api + `/projects?page=${pageCurrent}&limit=${limit}&customer=${customer}&status=${status}`, getHeaders());
        dispatch({ type: "GET_PROJECTS", payload: response.data });
    } catch (err) {
        return errorHandling(err);
    }
}

export const deleteProject = (_id) => async (dispatch) => {
    try {
        const response = await axios.delete(api+"/projects/" + _id, getHeaders());
        return response.data;
    } catch (err) {
        return errorHandling(err);
    }
}

export const getViewProject = (id) => async (dispatch) => {
    try {
        const response = await axios.get(`${api}/projects/${id}`, getHeaders());
        dispatch({ type: 'GET_PROJECT', payload: response.data })
    } catch (error) {
        return errorHandling(error);
    }
}

export const handleAddProject = (props) => async () => {
    try {
        const response = await axios.post(api+"/projects", props, getHeaders());
        return response.data;
    } catch (err) {
        return errorHandling(err);
    }
}

export const handleUpdateProject = (props) => async () => {
    try {
        const response = await axios.put(api+"/projects", props, getHeaders());
        return response.data;
    } catch (err) {
        return errorHandling(err);
    }
}

export const handleUpdateStatusTask = (props) => async () => {
    try {
        const response = await axios.put(api+"/projects/tasks", props, getHeaders());
        return response.data;
    } catch (err) {
        return errorHandling(err);
    }
}

export const updateProp = (props, id) => async () => {
    try {
        const response = await axios.post(api+"/projects/props/"+id, props, getHeaders());
        return response.data;
    } catch (err) {
        return errorHandling(err);
    }
}

export const clearProject = () => {
    return function(dispatch){
        dispatch({type: "CLEAR_PROJECT"})
    } 
}

export const clearProjects = () => {
    return function(dispatch){
        dispatch({type: "CLEAR_PROJECTS"})
    } 
}