import React, { useCallback, useEffect, useState } from 'react';
import { Box, Chip, Grid, Typography, useMediaQuery } from '@mui/material';
import * as actions from '../../../store/actions/scale';
import { i18n } from '../../../translate/i18n'
import { connect } from 'react-redux';
import { debounce } from "lodash";
import { FCSelect } from 'components-fullcomm';

const Start = (props) => {
  const [data, setData] = useState({})
  const [period, setPeriod] = useState('7')
  const isMobileDevice = useMediaQuery('(max-width:600px)');

  const debouncedGetAll = useCallback(debounce(async (period) => {
    props.getLogisticsInfoHomeAll(period);
  }, 1000), []);

  const debounceGetSingle = useCallback(debounce(async (period) => {
    props.getLogisticsInfoHome(period);
  }, 1000), []);

  useEffect(() => {
      props.view === 'admin'?
      debouncedGetAll(period) 
      :
      debounceGetSingle(period)
  }, [props.tokenApi, period, props.view])

  useEffect(() => {
    setData(props.view ==='admin' ? props.logisticsInfoAll : props.logisticsInfo)
  }, [props.logisticsInfo, props.logisticsInfoAll, props.view])

  return (
    <Box padding={"1.5rem 2rem"} mb="50px">
      <Typography variant='h6' mb="0.5rem">{i18n.t('scale.logistics.start.filterPeriod')}</Typography>
      <FCSelect 
        select
        fullWidth
        size='small'
        value={period}
        onChange={(e) => setPeriod(e.target.value)}
        options={[
          { value: '7', label: i18n.t('scale.logistics.start.last7')},
          { value: '15', label: i18n.t('scale.logistics.start.last15')},
          { value: '30', label: i18n.t('scale.logistics.start.last30')},
          { value: '90', label: i18n.t('scale.logistics.start.last90')},
          { value: 'all', label: i18n.t('scale.logistics.start.all')}
        ]}
      />
      <Box mt="1.5rem" display="flex" justifyContent="space-between">
        {
          isMobileDevice ? (
            <Grid container spacing="1rem">
              <Grid item xs={12}>
                <Box borderLeft="3px solid #2E7D32" padding="10px" alignItems="center">
                  <Typography variant='subtitle2' mb="0.5rem">{i18n.t('scale.logistics.start.invoicedOrders')}</Typography>
                  <Typography variant='h1'>R${data?.total_price || 0}</Typography>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box borderLeft="3px solid #2E7D32" padding="10px" alignItems="center">
                  <Typography variant='subtitle2' mb="0.5rem">{i18n.t('scale.logistics.start.totalOrders')}</Typography>
                  <Typography variant='h1'>{data?.total_orders || 0}</Typography>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Chip label={i18n.t('scale.logistics.start.customer')} color='info'/>
                <Typography variant='h1' mt="10px">{data?.statusCustomer || 0}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Chip label={i18n.t('scale.logistics.start.shipping')} color='success'/>
                <Typography variant='h1' mt="10px">{data?.statusShipping || 0}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Chip label={i18n.t('scale.logistics.start.shipment')} color='warning'/>
                <Typography variant='h1' mt="10px">{data?.statusShipment || 0}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Chip label={i18n.t('scale.logistics.start.error')} color='error'/>
                <Typography variant='h1' mt="10px">{data?.statusError || 0}</Typography>
              </Grid>
            </Grid>
          ) : (
            <Grid container rowSpacing="1.5rem">
            <Grid item xs={1.5}>
              <Chip label={i18n.t('scale.logistics.start.customer')} color='info'/>
              <Typography variant='h1' mt="10px">{data?.statusCustomer || 0}</Typography>
            </Grid>
            <Grid item xs={1.5}>
              <Chip label={i18n.t('scale.logistics.start.shipping')} color='success'/>
              <Typography variant='h1' mt="10px">{data?.statusShipping || 0}</Typography>
            </Grid>
            <Grid item xs={9} display="flex" justifyContent="end">
              <Box borderLeft="3px solid #2E7D32" padding="10px" alignItems="center" marginRight="10%">
                <Typography variant='subtitle2' mb="0.5rem">{i18n.t('scale.logistics.start.invoicedOrders')}</Typography>
                <Typography variant='h1'>R${data?.total_price || 0}</Typography>
              </Box>
              <Box borderLeft="3px solid #2E7D32" padding="10px" alignItems="center">
                <Typography variant='subtitle2' mb="0.5rem">{i18n.t('scale.logistics.start.totalOrders')}</Typography>
                <Typography variant='h1'>{data?.total_orders || 0}</Typography>
              </Box>
            </Grid>
            <Grid item xs={1.5}>
              <Chip label={i18n.t('scale.logistics.start.shipment')} color='warning'/>
              <Typography variant='h1' mt="10px">{data?.statusShipment || 0}</Typography>
            </Grid>
            <Grid item xs={1.5}>
              <Chip label={i18n.t('scale.logistics.start.error')} color='error'/>
              <Typography variant='h1' mt="10px">{data?.statusError || 0}</Typography>
            </Grid>
          </Grid>
          )
        }
      </Box>
    </Box>
  )
}

const mapStateToProps = (state) => ({
  tokenApi: state.scale.tokenApi,
  logisticsInfo: state.scale.logisticsInfo,
  logisticsInfoAll: state.scale.logisticsInfoAll,
})

export default connect(mapStateToProps, actions)(Start)