import axios from 'axios';
import {api} from '../../config'
import {getHeaders} from './localStorage'
import errorHandling from './errorHandling'
import {queryString} from '../../utils/String'

export const getAgencies = (params) => async (dispatch) => {
    try {
        const response = await axios.get(api + `/agencies?${queryString(params)}`, getHeaders());
        dispatch({ type: "GET_AGENCIES", payload: response.data });
    } catch (err) {
        return errorHandling(err);
    }
}

export const getViewAgency = (id) => async (dispatch) => {
    try {
        const response = await axios.get(`${api}/agencies/${id}`, getHeaders());
        dispatch({ type: 'GET_AGENCY_VIEW', payload: response.data })
    } catch (error) {
        return errorHandling(error);
    }
}

export const getViewAgencySpecific = (id, query) => async (dispatch) => {
    try {
        const response = await axios.get(`${api}/agency/${id}?${queryString(query)}`, getHeaders());
        dispatch({ type: 'GET_AGENCY', payload: response.data })
    } catch (error) {
        return errorHandling(error);
    }
}

export const handleAddAgency = (props) => async () => {
    try {
        const response = await axios.post(api+"/agencies", props, getHeaders());
        return response.data;
    } catch (err) {
        return errorHandling(err);
    }
}

export const handleUpdateAgency = (props) => async () => {
    try {
        const response = await axios.put(api+"/agencies", props, getHeaders());
        return response.data;
    } catch (err) {
        return errorHandling(err);
    }
}

export const UpdateAgencyCustomers = (props) => async () => {
    try {
        const response = await axios.put(api+"/agency-customers", props, getHeaders());
        return response.data;
    } catch (err) {
        return errorHandling(err);
    }
}

export const clearAgency = () => {
    return function(dispatch){
        dispatch({type: "CLEAR_AGENCY"})
    } 
}

export const clearAgencies = () => {
    return function(dispatch){
        dispatch({type: "CLEAR_AGENCIES"})
    } 
}

export const addBilling = (props) => async () => {
    try {
        const response = await axios.post(api + `/agency/billing`, props, getHeaders());
        return response.data;
    } catch (err) {
        return errorHandling(err);
    }
}