import { Box, Button, Paper } from "@mui/material";
import React, { useEffect, useState } from "react";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import ActionCard from "./ActionCard";
import ShowMoreLessButton from "../../ShowMoreLessButton";

const ActionCardArea = ({ actions, dateFilter }) => {
  const [sectionActions, setSectionActions] = useState(actions?.slice(0, 4));

  const showMore = () => {
    setSectionActions(actions);
  };

  const showLess = () => {
    setSectionActions(actions?.slice(0, 4));
  };

  useEffect(() => {
    setSectionActions(actions?.slice(0, 4));
  }, [actions]);

  return (
    <Paper
      sx={{
        minHeight: "250px",
        maxHeight: "calc(100vh - 35rem)",
        overflow: "auto",
        background: "#fafafa",
        borderRadius: "10px",
      }}
    >
      {sectionActions?.map((action, i) => (
        <ActionCard action={action} index={i} length={sectionActions.length} dateFilter={dateFilter} />
      ))}
      <Box paddingX={4} paddingBottom={2}>
      <ShowMoreLessButton 
        originalArray={actions} 
        setDisplayArray={setSectionActions}
        displayArray={sectionActions}
      />
      </Box>
    </Paper>
  );
};

export default ActionCardArea;
