import { Box, Typography } from '@mui/material';
import React from 'react';
import { i18n } from '../../../translate/i18n';
import { FCTextField } from 'components-fullcomm';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box display={"flex"} flexDirection={"column"} gap={"1rem"} paddingBottom={"1rem"}>
          {children}
        </Box>
      )}<Typography></Typography>
    </div>
  );
}

const Options = ({getFieldProps, value}) => {
  return (
    <>
      <CustomTabPanel value={value} index={0}>
        <FCTextField
          fullWidth
          label={i18n.t('aiContent.redacting.options.description1')}
          multiline
          rows={4}
          inputProps={{ maxLength: 600, minLength: 3 }} 
          {...getFieldProps('description')}
        />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <FCTextField
            fullWidth
            label={i18n.t('aiContent.redacting.options.product')}
            inputProps={{ maxLength: 100, minLength: 3 }} 
            {...getFieldProps('product')}
          />
        <FCTextField
            fullWidth
            label={i18n.t('aiContent.redacting.options.description2')}
            multiline
            rows={4}
            inputProps={{ maxLength: 600, minLength: 3 }} 
            {...getFieldProps('description')}
        />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <FCTextField
          fullWidth
          label={i18n.t('aiContent.redacting.options.container')}
          inputProps={{ maxLength: 100, minLength: 3 }} 
          {...getFieldProps('recipient')}
        />
        <FCTextField
          fullWidth
          label={i18n.t('aiContent.redacting.options.containerCharge')}
          inputProps={{ maxLength: 100, minLength: 3 }} 
          {...getFieldProps('recipient_position')}
        />
        <FCTextField
          fullWidth
          label={i18n.t('aiContent.redacting.options.description3')}
          multiline
          rows={4}
          inputProps={{ maxLength: 300, minLength: 3 }} 
          {...getFieldProps('description')}
        />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={3}>
        <FCTextField
            fullWidth
            label={i18n.t('aiContent.redacting.options.productName1')}
            inputProps={{ maxLength: 100, minLength: 3 }} 
            {...getFieldProps('product_name')}
        />
        <FCTextField
          fullWidth
          label={i18n.t('aiContent.redacting.options.productDescription1')}
          multiline
          rows={4}
          inputProps={{ maxLength: 600, minLength: 3 }} 
          {...getFieldProps('product_description')}
        />
        <FCTextField
          fullWidth
          label={i18n.t('aiContent.redacting.options.ocasion')}
          inputProps={{ maxLength: 100, minLength: 3 }} 
          {...getFieldProps('occasion')}
        />
        <FCTextField
          fullWidth
          label={i18n.t('aiContent.redacting.options.promotion')}
          inputProps={{ maxLength: 100, minLength: 3 }} 
          {...getFieldProps('promotion')}
        />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={4}>
        <FCTextField
          fullWidth
          label={i18n.t('aiContent.redacting.options.productName2')}
          inputProps={{ maxLength: 100, minLength: 3 }} 
          {...getFieldProps('product_name')}
        />
        <FCTextField
          fullWidth
          label={i18n.t('aiContent.redacting.options.productDescription2')}
          multiline
          rows={4}
          inputProps={{ maxLength: 600, minLength: 3 }} 
          {...getFieldProps('product_description')}
        />
        <FCTextField
          fullWidth
          label={i18n.t('aiContent.redacting.options.searchTerm')}
          inputProps={{ maxLength: 100, minLength: 3 }} 
          {...getFieldProps('search_term')}
        />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={5}>
        <FCTextField
          fullWidth
          label={i18n.t('aiContent.redacting.options.productName3')}
          inputProps={{ maxLength: 100, minLength: 3 }} 
          {...getFieldProps('name')}
        />
        <FCTextField
          fullWidth
          label={i18n.t('aiContent.redacting.options.productDescription3')}
          multiline
          rows={4}
          inputProps={{ maxLength: 600, minLength: 3 }} 
          {...getFieldProps('details')}
        />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={6}>
        <FCTextField
          fullWidth
          label={i18n.t('aiContent.redacting.options.companyName')}
          inputProps={{ maxLength: 100, minLength: 3 }} 
          {...getFieldProps('company_name')}
        />
        <FCTextField
          fullWidth
          label={i18n.t('aiContent.redacting.options.companyDescription')}
          multiline
          rows={4}
          inputProps={{ maxLength: 600, minLength: 3 }} 
          {...getFieldProps('description')}
        />
        <FCTextField
          fullWidth
          label={i18n.t('aiContent.redacting.options.keyWord')}
          inputProps={{ maxLength: 100, minLength: 3 }} 
          {...getFieldProps('keyword')}
        />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={7}>
        <FCTextField
          fullWidth
          label={i18n.t('aiContent.redacting.options.productName4')}
          inputProps={{ maxLength: 100, minLength: 3 }} 
          {...getFieldProps('product_name')}
        />
        <FCTextField
          fullWidth
          label={i18n.t('aiContent.redacting.options.productDescription4')}
          multiline
          rows={4}
          inputProps={{ maxLength: 600, minLength: 3 }} 
          {...getFieldProps('product_description')}
        />
        <FCTextField
          fullWidth
          label={i18n.t('aiContent.redacting.options.keyWords')}
          inputProps={{ maxLength: 100, minLength: 3 }} 
          {...getFieldProps('target_keywords')}
        />
        <FCTextField
          fullWidth
          label={i18n.t('aiContent.redacting.options.targetAudience')}
          inputProps={{ maxLength: 100, minLength: 3 }} 
          {...getFieldProps('target_audience')}
        />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={8}>
        <FCTextField
          fullWidth
          label={i18n.t('aiContent.redacting.options.description4')}
          multiline
          rows={4}
          inputProps={{ maxLength: 600, minLength: 3 }} 
          {...getFieldProps('description')}
        />
        <FCTextField
          fullWidth
          label={i18n.t('aiContent.redacting.options.voiceTone1')}
          inputProps={{ maxLength: 100, minLength: 3 }} 
          {...getFieldProps('tone_of_voice')}
        />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={9}>
        <FCTextField
          fullWidth
          label={i18n.t('aiContent.redacting.options.content')}
          multiline
          rows={4}
          inputProps={{ maxLength: 500, minLength: 3 }} 
          {...getFieldProps('content')}
        />
        <FCTextField
          fullWidth
          label={i18n.t('aiContent.redacting.options.voiceTone2')}
          inputProps={{ maxLength: 100, minLength: 3 }} 
          {...getFieldProps('tone')}
        />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={10}>
        <FCTextField
          fullWidth
          label={i18n.t('aiContent.redacting.options.productDescription5')}
          multiline
          rows={4}
          inputProps={{ maxLength: 600, minLength: 3 }} 
          {...getFieldProps('product_description')}
        />
        <FCTextField
          fullWidth
          label={i18n.t('aiContent.redacting.options.benefit')}
          inputProps={{ maxLength: 100, minLength: 3 }} 
          {...getFieldProps('feature')}
        />
      </CustomTabPanel>
    </>
  )
}

export default Options