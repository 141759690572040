import React from 'react'
import { Route } from 'react-router-dom';

import Redacting from '../pages/AiContent/Redacting';
import Images from '../pages/AiContent/Images';
import Library from '../pages/AiContent/Library';

const AiContent = ({ baseDashboard }) => {
  return (
    <>
      <Route path="/aicontent/redacting" exact component={baseDashboard({ Component: Redacting, permissions: ['admin', 'customer'], action: 'view_aicontent'})} />
      <Route path="/aicontent/images" exact component={baseDashboard({ Component: Images, permissions: ['admin', 'customer'], action: 'view_aicontent'})} />
      <Route path="/aicontent/library" exact component={baseDashboard({ Component: Library, permissions: ['admin', 'customer'], action: 'view_aicontent'})} />
    </>
    )
}

export default AiContent;