import React from 'react';
import { Box, Card, Typography } from '@mui/material';
import { i18n } from '../../translate/i18n';

const StatusErrorView = ({ ordersStatusPlatinum, setSelectedStatus  }) =>
    <>
        <Card className='card' onClick={() => setSelectedStatus([13])}>
            <Box>
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                    <path d="M0.5 12C0.5 14.3734 1.20379 16.6934 2.52236 18.6668C3.84094 20.6402 5.71508 22.1783 7.9078 23.0865C10.1005 23.9948 12.5133 24.2324 14.8411 23.7694C17.1689 23.3064 19.307 22.1635 20.9853 20.4853C22.6635 18.807 23.8064 16.6689 24.2694 14.3411C24.7324 12.0133 24.4948 9.60051 23.5865 7.4078C22.6783 5.21508 21.1402 3.34094 19.1668 2.02236C17.1934 0.703788 14.8734 0 12.5 0C9.3174 0 6.26515 1.26428 4.01472 3.51472C1.76428 5.76515 0.5 8.8174 0.5 12ZM20.3429 18.6429L5.85714 4.15714C7.82912 2.51571 10.3434 1.67055 12.9064 1.78754C15.4695 1.90452 17.8963 2.97521 19.7105 4.78946C21.5248 6.6037 22.5955 9.03051 22.7125 11.5936C22.8294 14.1566 21.9843 16.6709 20.3429 18.6429ZM5.84857 19.8514C3.77285 18.0887 2.48086 15.5748 2.25591 12.8609C2.03096 10.147 2.8914 7.4547 4.64857 5.37429L19.1257 19.8514C17.2693 21.42 14.9175 22.2806 12.4871 22.2806C10.0568 22.2806 7.70495 21.42 5.84857 19.8514Z" fill="#888888" />
                </svg>
                <Typography className="title">
                {i18n.t('components.platinumlog.statusErrorView.cancelled')}
                </Typography>
            </Box>
            <Typography className='number'>
                {ordersStatusPlatinum?.[13] || 0}
            </Typography>
        </Card>
        <Card className='card card-yellow' onClick={() => setSelectedStatus([80])}>
            <Box>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M10.8 15.6H13.2V18H10.8V15.6ZM10.8 6H13.2V13.2H10.8V6ZM12 0C5.364 0 0 5.4 0 12C0 15.1826 1.26428 18.2348 3.51472 20.4853C4.62902 21.5996 5.95189 22.4835 7.4078 23.0866C8.86371 23.6896 10.4241 24 12 24C15.1826 24 18.2348 22.7357 20.4853 20.4853C22.7357 18.2348 24 15.1826 24 12C24 10.4241 23.6896 8.86371 23.0866 7.4078C22.4835 5.95189 21.5996 4.62902 20.4853 3.51472C19.371 2.40042 18.0481 1.5165 16.5922 0.913446C15.1363 0.310389 13.5759 0 12 0ZM12 21.6C9.45392 21.6 7.01212 20.5886 5.21178 18.7882C3.41143 16.9879 2.4 14.5461 2.4 12C2.4 9.45392 3.41143 7.01212 5.21178 5.21178C7.01212 3.41143 9.45392 2.4 12 2.4C14.5461 2.4 16.9879 3.41143 18.7882 5.21178C20.5886 7.01212 21.6 9.45392 21.6 12C21.6 14.5461 20.5886 16.9879 18.7882 18.7882C16.9879 20.5886 14.5461 21.6 12 21.6Z" fill="#FFAA33" />
                </svg>
                <Typography className="title">
                {i18n.t('components.platinumlog.statusErrorView.withOccurrence')}
                </Typography>
            </Box>
            <Typography className='number'>
                {ordersStatusPlatinum?.[80] || 0}
            </Typography>
        </Card>
        <Card className='card card-red' onClick={() => setSelectedStatus([9001])}>
            <Box>
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                    <path d="M11.3 15.6H13.7V18H11.3V15.6ZM11.3 6H13.7V13.2H11.3V6ZM12.5 0C5.864 0 0.5 5.4 0.5 12C0.5 15.1826 1.76428 18.2348 4.01472 20.4853C5.12902 21.5996 6.45189 22.4835 7.9078 23.0866C9.36371 23.6896 10.9241 24 12.5 24C15.6826 24 18.7348 22.7357 20.9853 20.4853C23.2357 18.2348 24.5 15.1826 24.5 12C24.5 10.4241 24.1896 8.86371 23.5866 7.4078C22.9835 5.95189 22.0996 4.62902 20.9853 3.51472C19.871 2.40042 18.5481 1.5165 17.0922 0.913446C15.6363 0.310389 14.0759 0 12.5 0ZM12.5 21.6C9.95392 21.6 7.51212 20.5886 5.71178 18.7882C3.91143 16.9879 2.9 14.5461 2.9 12C2.9 9.45392 3.91143 7.01212 5.71178 5.21178C7.51212 3.41143 9.95392 2.4 12.5 2.4C15.0461 2.4 17.4879 3.41143 19.2882 5.21178C21.0886 7.01212 22.1 9.45392 22.1 12C22.1 14.5461 21.0886 16.9879 19.2882 18.7882C17.4879 20.5886 15.0461 21.6 12.5 21.6Z" fill="#E74C3C" />
                </svg>
                <Typography className="title">
                {i18n.t('components.platinumlog.statusErrorView.delayInIntegration')}
                </Typography>
            </Box>
            <Typography className='number'>
                {ordersStatusPlatinum?.[9001] || 0}
            </Typography>
        </Card>
        <Card className='card card-red'  onClick={() => setSelectedStatus([9002])}>
            <Box>
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                    <path d="M11.3 15.6H13.7V18H11.3V15.6ZM11.3 6H13.7V13.2H11.3V6ZM12.5 0C5.864 0 0.5 5.4 0.5 12C0.5 15.1826 1.76428 18.2348 4.01472 20.4853C5.12902 21.5996 6.45189 22.4835 7.9078 23.0866C9.36371 23.6896 10.9241 24 12.5 24C15.6826 24 18.7348 22.7357 20.9853 20.4853C23.2357 18.2348 24.5 15.1826 24.5 12C24.5 10.4241 24.1896 8.86371 23.5866 7.4078C22.9835 5.95189 22.0996 4.62902 20.9853 3.51472C19.871 2.40042 18.5481 1.5165 17.0922 0.913446C15.6363 0.310389 14.0759 0 12.5 0ZM12.5 21.6C9.95392 21.6 7.51212 20.5886 5.71178 18.7882C3.91143 16.9879 2.9 14.5461 2.9 12C2.9 9.45392 3.91143 7.01212 5.71178 5.21178C7.51212 3.41143 9.95392 2.4 12.5 2.4C15.0461 2.4 17.4879 3.41143 19.2882 5.21178C21.0886 7.01212 22.1 9.45392 22.1 12C22.1 14.5461 21.0886 16.9879 19.2882 18.7882C17.4879 20.5886 15.0461 21.6 12.5 21.6Z" fill="#E74C3C" />
                </svg>
                <Typography className="title">
                    {i18n.t('components.platinumlog.statusErrorView.deliveryDelay')}
                </Typography>
            </Box>
            <Typography className='number'>
                {ordersStatusPlatinum?.[9002] || 0}
            </Typography>
        </Card>
    </>

export default StatusErrorView;