import { Box, Paper, Typography } from '@mui/material';
import React from 'react';
import { i18n } from '../../translate/i18n';

const Unauthorized = () => {
  return (
    <Box sx={{display: "flex", justifyContent: "center", alignItems: "center", height: "100vh"}}>
        <Paper sx={{padding: "2rem", borderRadius: "10px"}}>
            <Typography variant='h2' align='center' marginBottom={"1rem"}>{i18n.t('unauthorized.unauthorized')}</Typography>
        </Paper>
    </Box>
    
  )
}

export default Unauthorized