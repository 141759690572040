import React, { Component } from 'react'
import ReactQuill, { Quill } from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import 'react-quill/dist/quill.bubble.css'
import "quill-mention";
import { Box, IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { isMobile } from 'react-device-detect';
import { i18n } from '../../../translate/i18n';
import MagicUrl from 'quill-magic-url';

Quill.register('modules/magicUrl', MagicUrl);

const CustomToolbar = ({ setOpenPopover, setPopoverAnchor, desktopSendButton, mobileSendButton, isFocused, isEdit, isEditButton }) => {
  const style = {
    height: '1.75rem',
    width: '1.75rem'
  }
  return (
  <div 
    id="toolbar"
    style={{
      display: (isMobile && !isFocused) ? 'none' : 'flex',
      gap: '0.25rem',
      alignItems: 'center',
      width: '100%',
      border: 'none',
      backgroundColor: 'white',
      borderRadius: '5px',
    }}
  >
    {
      isEdit ? isEditButton() 
      :
      (
          <IconButton 
            size="small" 
            sx={{ 
                color: '#88888', 
                background: '#f3f3f3 !important', 
                height: isMobile ? '2rem !important' : '1.5rem !important', 
                width: isMobile ? '2rem !important' : '1.5rem !important',
                display: 'flex !important',
                justifyContent: 'center !important',
              }}
            onClick={(e) => {
              e.stopPropagation();
              setPopoverAnchor(document.getElementById('chat-input'));
              setOpenPopover(true);
            }}
          >
            <AddIcon />
          </IconButton>
      )
    }
    <button className="ql-bold" style={style}></button>
    <button className="ql-italic" style={style}></button>
    <button className="ql-list" value='bullet' style={style}></button>
    { 
      isMobile ?
        mobileSendButton() 
      :
        desktopSendButton() 
    }
  </div>
  );
};

export default class RichTextInputWithMentions extends Component {

  handleChange = (value) => {
    this.props.onChange(value)
  }
  mentionModule = {
    allowedChars: /^[A-Za-z\s]*$/,
    isolateCharacter: true,
    mentionDenotationChars: ["@"],
    defaultMenuOrientation: 'top',
    source: (searchTerm, renderList) => {
      const values = this.props.isEdit ? [] : this.props?.users || [];
    
      const sanitizeString = (str) =>
      str
        .toLowerCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "");
    
      if (searchTerm.length === 0) {
        renderList(values.splice(0, 7), searchTerm);
      } else {
        const matches = [];
        const sanitizedSearchTerm = sanitizeString(searchTerm.toLowerCase());
    
        for (const value of values) {
          const sanitizedValue = sanitizeString(value.value.toLowerCase());
          if (~sanitizedValue.indexOf(sanitizedSearchTerm)) {
            matches.push(value);
          }
        }
    
        renderList(matches.splice(0, 7), searchTerm);
      }
    },    
    renderItem: (item, searchTerm) => {
      const node = document.createElement('div');
      node.style.display = 'flex';
      node.style.alignItems = 'center';
      node.style.padding = '5px 15px';
      node.style.cursor = 'pointer';
      node.style.borderBottom = '1px solid #D1D5DB';
      
      const avatar = document.createElement('div');
      avatar.style.width = '32px';
      avatar.style.height = '32px';
      avatar.style.marginRight = '8px';
      avatar.style.objectFit = 'cover';
    
      const avatarImg = document.createElement('img');
      avatarImg.src = item.img;
      avatarImg.style.width = '100%';
      avatarImg.style.height = '100%';
      avatarImg.style.borderRadius = '50%';
    
      avatar.appendChild(avatarImg);

      const typography = document.createElement('div');
      const highlightedDisplay = item.value.replace(
        new RegExp(`(${searchTerm})`, 'gi'),
        (match, text) => `<strong>${text}</strong>`
      );
    
      typography.innerHTML = highlightedDisplay;
    
      node.appendChild(avatar);
      node.appendChild(typography);
    
      return node;
    },
    onSelect: (item, insertItem) => {
      if (item.id === 'all') this.props.setTaggedUsers(prev => [...prev, ...this.props?.users.map(user => user.id).filter(id => id != 'all')]);
      else this.props.setTaggedUsers(prev => [...prev, item.id]);
      insertItem(item);
    }
  }

  render () {

    return (
      <Box 
        id="chat-input-quill"
        marginBottom={(this.props.isFocused && isMobile) ? '0' : '1rem'}
        border={isMobile ? 'none' : this.props.isDragging ? "2px dashed #aaa" : '1px solid #D1D5DB'}
        borderRadius='5px'
        width='100%'
        height={isMobile ? this.props.isFocused ? 'auto' : '30px' : 'auto'}
        sx={{
          backgroundColor: 'white',
        }}
      >
        <ReactQuill 
          ref={(el) => {
            this.reactQuillRef = el;
          }}    
          onFocus={()=> this.props.setIsFocused(true)}
          onBlur={()=> this.props.setIsFocused(false)}
          placeholder={i18n.t('components.chat.chatInput.placeholder')}
          onKeyDown={this.props.onKeyPress}
          value={this.props.value}
          onChange={this.handleChange}
          modules={{
            mention: this.mentionModule,
            toolbar: {
              container: "#toolbar",
              },
            magicUrl: true,
            clipboard: {
              matchVisual: false,
            },
          }}
        />
        <CustomToolbar
          setOpenPopover={this.props.setOpenPopover}
          setPopoverAnchor={this.props.setPopoverAnchor}
          desktopSendButton={this.props.desktopSendButton}
          mobileSendButton={this.props.mobileSendButton}
          isFocused={this.props.isFocused}
          isEdit={this.props.isEdit}
          isEditButton={this.props.isEditButton}
        />
      </Box>
    )
  }
}