import {
  Box,
  Typography,
  Button,
  CircularProgress,
  Icon,
  Divider,
} from "@mui/material";
import React from "react";
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import { i18n} from '../../../../translate/i18n';

const DeleteActionModal = ({toggleModal, openModal, deleteAction, loading, setOpenModal}) => {
  return (
    <Modal toggle={() => setOpenModal(false)} isOpen={openModal}>
    <Box 
      sx={{
        display: "flex", 
        justifyContent: "space-between", 
        padding: 2, 
        alignItems: "center"
      }}
      >
      <Typography variant="h3">{i18n.t('sprint.list.modal.deleteActionModal.deleteAction')}</Typography>
      <Icon
        sx={{
          color: "#86888c",
          cursor: "pointer",
        }}
        onClick={() => setOpenModal(false)}
        className="material-icons-outlined"
        fontSize="large"
      >
        cancel
      </Icon>
    </Box>
    <Divider />
    <ModalBody>
      <Typography height={"5vh"}>{i18n.t('sprint.list.modal.deleteActionModal.confirmDelete')}</Typography>
    </ModalBody>
    <ModalFooter>
      <Box display={"flex"} gap={1}>
        <Button variant="contained" className="btn-reprove" onClick={() => deleteAction()}>
          {loading ? (
              <CircularProgress size={25} sx={{ color: "white" }} thickness={4} />
            ) : (
              <>{i18n.t('sprint.list.modal.deleteActionModal.delete')}</>
            )}
        </Button>
        <Button variant="contained" className="btn-gray" onClick={() => setOpenModal(false)}>
          {i18n.t('sprint.list.modal.deleteActionModal.cancel')}
        </Button>
      </Box>
    </ModalFooter>
  </Modal>
  )
}

export default DeleteActionModal