import React from 'react';
import { Table, TableRow, TableCell, TableSortLabel, TableBody, Tooltip } from '@mui/material';
import { getStatusDescription } from '../../utils/statusPlatinum';
import { secondsToHMS } from '../../utils/String';
import { Link } from 'react-router-dom';
import { i18n } from '../../translate/i18n';
import { FCPagination, FCTableContainer, FCTableHeader } from 'components-fullcomm';


const TableOrders = ({ dataOrders, page, setPage }) => {
    const orders = dataOrders?.data || [];
    const headCells = [
        { id: 'id', label: i18n.t('components.platinumlog.tableOrders.order') },
        { id: 'date', label: i18n.t('components.platinumlog.tableOrders.date') },
        { id: 'prediction', label: i18n.t('components.platinumlog.tableOrders.prediction') },
        { id: 'customer', label: i18n.t('components.platinumlog.tableOrders.customer') },
        { id: 'shipping', label: i18n.t('components.platinumlog.tableOrders.shipping') },
        { id: 'tracking', label: i18n.t('components.platinumlog.tableOrders.tracking') },
        { id: 'invoice', label: i18n.t('components.platinumlog.tableOrders.invoice') },
        { id: 'status', label: i18n.t('components.platinumlog.tableOrders.status') }
    ];
    return (
        <>
            <FCTableContainer>
                <Table >
                    <FCTableHeader>
                        <TableRow>
                            {headCells.map((headCell) => (
                                <TableCell
                                    key={headCell.id}
                                >
                                    <TableSortLabel
                                        active={false}
                                        direction={'asc'}
                                    >
                                        {headCell.label}
                                    </TableSortLabel>
                                </TableCell>
                            ))}
                        </TableRow>
                    </FCTableHeader>
                    <TableBody >
                        {orders.map((row, index) => {
                            const timeIntegration = (new Date(row.internalevents[0]?.created) - new Date(row.createdCommerce).getTime()) / 1000;
                            const todayDate = new Date();
                            todayDate.setHours(0,0,0,0);
                            const latedOrder = (new Date(row.info?.prediction) < todayDate && row.info?.prediction);
                            const timeIntegrationHours = secondsToHMS(timeIntegration);
                            return (
                                <TableRow
                                    sx={{
                                        backgroundColor: (index % 2 === 0 && timeIntegration < 3600 && !latedOrder) ? '#F3F3F3' :
                                            (latedOrder) ? '#ffd5d5' : (timeIntegration > 3600) && '#ffecaa',
                                    }}
                                    key={row.info.id}
                                >
                                    <TableCell><Link to={'/order/' + row._id} >{row.info?.number}</Link></TableCell>

                                    <TableCell>
                                        <Tooltip title={
                                            <>
                                                {i18n.t('components.platinumlog.tableOrders.wmsIntegration')} <b>{new Date(row.internalevents[0].created).toLocaleString('pt-BR')}</b>
                                                <br />
                                                {i18n.t('components.platinumlog.tableOrders.timeIntegration')} <b>{timeIntegrationHours}</b>
                                            </>
                                        } arrow>
                                            <span>
                                                {new Date(row.createdCommerce).toLocaleString('pt-BR')}
                                            </span>
                                        </Tooltip>
                                    </TableCell>
                                    <TableCell>
                                        {row.info.prediction ? new Date(row.info.prediction).toLocaleDateString('pt-BR') : '-'}
                                    </TableCell>
                                    <TableCell>{row.deliveryTo.to}</TableCell>
                                    <TableCell>{row.shippment.nick}</TableCell>
                                    <TableCell>{row.shippment.trackerUrl ? <a target='_blank' href={row.shippment.trackerUrl}><u>{row.shippment.tracker}</u></a> : row.shippment.tracker}</TableCell>
                                    <TableCell>{row.invoice?.map(e => e.number).toString()}</TableCell>
                                    <TableCell>{getStatusDescription(row.code)}</TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </FCTableContainer>
            <FCPagination
                sx={{
                    marginY: '1rem'
                }}
                count={dataOrders?.total || 0}
                page={page}
                onChange={(e, value) => setPage(value)}
            />
        </>
    )
}
export default TableOrders;