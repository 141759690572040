import { Box, Button, Chip, Icon, TextField, Typography } from '@mui/material'
import React, { useRef, useState } from 'react'
import { Modal } from 'reactstrap'
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { i18n } from '../../../../translate/i18n';

const SubmitActivityModal = ({ activity, setActivity, SendActivityToApproval }) => {
  const [comment, setComment] = useState('');
  const [isDragging, setIsDragging] = useState(false);
  const [attachedFiles, setAttachedFiles] = useState([]);
  const fileInputRef = useRef(null);

  const handleFileDrop = (e) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    setIsDragging(false);
    setAttachedFiles((prevFiles) => [...prevFiles, ...files]);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setIsDragging(false);
  };

  const handleFileRemove = (file) => {
    setAttachedFiles((prevFiles) => prevFiles.filter((f) => f !== file));
  };

  const handleFileInputClick = () => {
    fileInputRef.current.click();
  };

  const handleFileInputChange = (e) => {
    const files = e.target.files;
    setAttachedFiles((prevFiles) => [...prevFiles, ...files]);
    e.target.value = "";
  };

  return (
    <Modal
      isOpen={activity}
      centered={true}
      size="lg"
      toggle={() => setActivity(false)}
    >
      <Box padding="1rem">
        <Box display="flex" justifyContent="space-between" alignItems="center" mb="1.5rem">
          <Typography variant="h3">Enviar para aprovação do cliente</Typography>
          <Icon
            sx={{
              color: "#86888c",
              cursor: "pointer",
            }}
            onClick={() => setActivity(false)}
            className="material-icons-outlined"
            fontSize="large"
          >
            cancel
          </Icon>
        </Box>
        <Box
          component="form"
          sx={{
            display: "flex",
            flexDirection: "column",
            borderRadius: "12px",
            alignItems: "flex-end",
            bgcolor: "background.paper",
            border: isDragging ? "2px dashed #aaa" : "1px solid #ccc",
          }}
          onDrop={handleFileDrop}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
        >
          <textarea
            onChange={(e) => setComment(e.target.value)}
            placeholder="Para adicionar imagens, arraste-as aqui."
            style={{
              width: "100%",
              border: "none",
              borderRadius: "12px",
              padding: "0.5rem"
            }}
            rows={4}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
            gap: 1,
          }}
          >
          <Button style={{color: '#5E6469', border: '1px solid #C9CCCF'}} onClick={handleFileInputClick}>
            <AttachFileIcon style={{width: '20px'}} />
            <input
              ref={fileInputRef}
              type="file"
              multiple
              style={{ display: "none" }}
              onChange={handleFileInputChange}
              />
          </Button>
          {attachedFiles.map((file) => (
            <Chip
              key={file.name}
              label={file.name}
              onDelete={() => handleFileRemove(file)}
            />
          ))}
        </Box>   
        <Box display="flex" justifyContent="flex-end" gap="0.5rem" padding="0.5rem">
          <Button 
            variant='contained' 
            className='btn-action' 
            onClick={() => {
              SendActivityToApproval(activity, comment, attachedFiles);
              setActivity(false);
            }}
          >
            Enviar
          </Button>
          <Button variant='contained' className='btn-gray' onClick={() => setActivity(false)}>Cancelar</Button>
        </Box>
      </Box>
    </Modal>
  )
}

export default SubmitActivityModal