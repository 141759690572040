import React, { useState } from 'react'
import { Box, Button, ButtonGroup, InputAdornment, Table, TableCell, TableContainer, TableHead, TableRow, TextField, Paper, TableBody, TablePagination, IconButton, TableFooter, Chip, Typography } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import moment from 'moment';
import { i18n } from '../../../translate/i18n';

const mockData = [
  {
    status: "Em análise",
    code: "abc123",
    date: "01/06/2023 09:30:00",
    amount: 3,
    value: "R$ 100.00"
  },
  {
    amount: 3,
    status: "Em trânsito",
    code: "def456",
    date: "02/06/2023 14:45:00",
    value: "R$ 75.50"
  },
  {
    amount: 3,
    status: "Em disputa",
    code: "ghi789",
    date: "03/06/2023 18:15:00",
    value: "R$ 120.00"
  },
  {
    amount: 3,
    status: "Aguardando postagem",
    code: "jkl012",
    date: "11/05/2023 12:00:00",
    value: "R$ 55.25"
  },
  // Add more objects with the same format...
];

const getChipColour = (status) => {
  switch (status) {
    case "Em análise":
      return "info"
    case "Em trânsito":
      return "warning"
    case "Em disputa":
      return "default"
    case "Aguardando postagem":
      return "yellow"
    default:
      return "default"
  }
}

const getTitleOccurrence = (status) => {
  switch (status) {
    case "Em análise":
      return i18n.t('scale.service.occurrences.inAnalysis')
    case "Em trânsito":
      return i18n.t('scale.service.occurrences.inTransit')
    case "Em disputa":
      return i18n.t('scale.service.occurrences.inDispute')
    case "Aguardando postagem":
      return i18n.t('scale.service.occurrences.waitingPost')
    default:
      return ""
  }
}

const OcurrenciesTable = ({data, title}) => {
  const [search, setSearch] = useState("");

  return (
    <Box mb="2.5rem">
      <Typography variant="h4" mb="0.75rem">{title}</Typography>
      <Paper sx={{padding: "2rem 1rem"}}>
        <Box display="flex" flexDirection={{xs: "column", md: "row"}} justifyContent="center" alignItems="center" gap="1.5rem" paddingX="1rem">
          <TextField
            fullWidth
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            label={i18n.t('scale.service.occurrences.codeOccurrenceFilter')}
            />
            <Box display={"flex"} alignItems={"center"}>
            <ButtonGroup variant="contained" sx={{height: "80%"}} color='default'>
              <Button className="btn-gray">{i18n.t('scale.service.occurrences.statusFilter')} <ArrowDropDownIcon/></Button>
              <Button className="btn-gray">{i18n.t('scale.service.occurrences.period')} <ArrowDropDownIcon/></Button>
              <Button className="btn-gray">{i18n.t('scale.service.occurrences.type')} <ArrowDropDownIcon/></Button>
            </ButtonGroup>
          </Box>
        </Box>
        <TableContainer sx={{marginTop: "1.5rem"}}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow sx={{background: "#F0F0F0"}}>
                <TableCell width="5%" sx={{minWidth: "150px"}}>{i18n.t('scale.service.occurrences.statusTitle')}</TableCell>
                <TableCell width="19%">{i18n.t('scale.service.occurrences.codeOccurrenceTitle')}</TableCell>
                <TableCell width="19%">{i18n.t('scale.service.occurrences.elapsedTime')}</TableCell>
                <TableCell width="19%">{i18n.t('scale.service.occurrences.data')}</TableCell>
                <TableCell width="19%">{i18n.t('scale.service.occurrences.quantity')}</TableCell>
                <TableCell width="19%">{i18n.t('scale.service.occurrences.value')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row) => (
                <TableRow
                  key={row.code}
                >
                  <TableCell><Chip label={getTitleOccurrence(row.status)} color={getChipColour(row.status)} /></TableCell>
                  <TableCell>{row.code}</TableCell>
                  <TableCell>{moment(row.date, 'DD HH mm').fromNow()}</TableCell>
                  <TableCell>{moment(row.date).format('DD/MM/YYYY')}</TableCell>
                  <TableCell>{row.amount}</TableCell>
                  <TableCell>{row.value}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  )
  }
const Ocurrencies = () => {

  return (
    <>
      <OcurrenciesTable data={mockData} title={i18n.t('scale.service.occurrences.immediateAction')}/>
      <OcurrenciesTable data={mockData} title={i18n.t('scale.service.occurrences.occurrences')}/>
    </>
  )
}

export default Ocurrencies