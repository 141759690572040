import React, { useEffect } from 'react';
import BaseLogin from './BaseLogin';
import { connect } from 'react-redux';
import * as actions from '../../store/actions';
import { useAuth0 } from "@auth0/auth0-react";
import { CircularProgress } from '@mui/material';

const baseLogin = Component => {
  const ComponentBaseLogin = (props) => {
    const { user, isAuthenticated, isLoading, logout } = useAuth0();
    const { authorized, history, getAuth0User } = props;

    useEffect(() => {
      if(isAuthenticated) getAuth0User({ email: user.email, sub: user.sub }, logout);
      
    }, [isAuthenticated]);

    useEffect(() => {
      if (authorized && isAuthenticated) history.replace('/dashboard');
      
    }, [authorized, history]);

    return (
      <BaseLogin>
        {
          isLoading || isAuthenticated ? 
          <CircularProgress sx={{position: 'absolute', top: '50%', left: '50%'}}/> 
          : 
          <Component {...props} />
        }
      </BaseLogin>
    );
  };

  const mapStateToProps = state => ({
    authorized: state.auth.authorized,
    user: state.auth.user
  });

  return connect(mapStateToProps, actions)(ComponentBaseLogin);
};

export default baseLogin;
