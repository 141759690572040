const auth_reducer = (state = {}, action) => {
    switch(action.type){
        case "LOGIN_USER":
            return {
                ...state, 
                user: action.payload.user,
                authorized: true
            }
        case "UPDATE_USER":
            return {
                ...state, 
                user: {...state.user, isBusy: action.payload.user?.isBusy, mutedChatChannels: action.payload.user?.mutedChatChannels}
            }
        case "LOGOUT_USER":
            return {
                ...state, 
                user: null,
                authorized: false
            }
        case "SET_SHOP":
            return {
                ...state, 
                shop: action.payload
            }
        default:
            return state;
    }
}

export default auth_reducer;