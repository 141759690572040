import { Box, ClickAwayListener, Paper, Popper, Typography } from '@mui/material'
import React, { useState } from 'react'
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { i18n } from '../../../translate/i18n';
import { userPermissionCheck } from '../../../pages/Sprint/utils/userPermissionCheck';
import { connect } from 'react-redux';
import ModalForm from '../../../pages/Support/ProblemsIncidents/ModalForm';
import ActionForm from '../../ActionForm';

const style = {
  box: {
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
    padding: '0.5rem',
    cursor: 'pointer',
    justifyContent: 'space-between',
    color: '#000',
    '&:hover': {
      backgroundColor: '#F3F3F3',
    },
  }
}

const PopoverContent = ({ open, setOpen, anchor, handleFileInputChange, handleFileInputClick, fileInputRef, setOpenActionDrawer, user, customer }) => {
  const id = open ? 'simple-popover' : undefined;
  const [actionPlatform, setActionPlatform] = useState(null);
  const [problemModal, setProblemModal] = useState(false);
  const [filePopUpOpen, setFilePopUpOpen] = useState(false);

  const handleClose = () => {
    if (actionPlatform || problemModal) return;
    if (filePopUpOpen) {
      setFilePopUpOpen(false);
      return;
    }
    setOpen(false);
  };

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <Popper
        id={id}
        open={open}
        anchorEl={anchor}
        placement='top-start'
      >
        <ActionForm
          open={actionPlatform}
          setOpen={setActionPlatform}
          platform={actionPlatform}
          shopOverride={customer}
        />
        <Paper
          elevation={8}
          sx={{
            margin: '0 0 0.2rem 0.5rem',
            borderRadius: '10px',
            padding: '0.2rem',
          }}
        >
          {
            userPermissionCheck(user, ["create_problem"]) && (
              <ModalForm 
                shopOverride={customer}
                isEdit={null} 
                setIsEdit={()=>{}} 
                hideButton={true} 
                alternativeVariant={true} 
                setProblemModal={setProblemModal}
                />
                )
              }
          {
            userPermissionCheck(user, ["create_action"]) && (
              <>
                <Box 
                  sx={style.box}
                  onClick={()=> {
                    setActionPlatform('sprint');
                  }}
                  >
                  <Typography variant='body2' >{i18n.t('components.chat.chatInput.createActivity')}</Typography>
                  <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.44444 13.5C1.04722 13.5 0.707296 13.3587 0.424667 13.0761C0.142037 12.7934 0.000481481 12.4533 0 12.0556V1.94444C0 1.54722 0.141556 1.2073 0.424667 0.924667C0.707778 0.642037 1.0477 0.500481 1.44444 0.5H5.77778V1.94444H1.44444V12.0556H11.5556V7.72222H13V12.0556C13 12.4528 12.8587 12.7929 12.5761 13.0761C12.2934 13.3592 11.9533 13.5005 11.5556 13.5H1.44444ZM9.38889 6.27778V4.11111H7.22222V2.66667H9.38889V0.5H10.8333V2.66667H13V4.11111H10.8333V6.27778H9.38889Z" fill="#888888"/>
                  </svg>
                </Box>
                <Box 
                  sx={style.box}
                  onClick={()=> {
                    setActionPlatform('suporte');
                  }}
                  >
                  <Typography variant='body2' >{i18n.t('components.chat.chatInput.createImprovement')}</Typography>
                  <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.44444 13.5C1.04722 13.5 0.707296 13.3587 0.424667 13.0761C0.142037 12.7934 0.000481481 12.4533 0 12.0556V1.94444C0 1.54722 0.141556 1.2073 0.424667 0.924667C0.707778 0.642037 1.0477 0.500481 1.44444 0.5H5.77778V1.94444H1.44444V12.0556H11.5556V7.72222H13V12.0556C13 12.4528 12.8587 12.7929 12.5761 13.0761C12.2934 13.3592 11.9533 13.5005 11.5556 13.5H1.44444ZM9.38889 6.27778V4.11111H7.22222V2.66667H9.38889V0.5H10.8333V2.66667H13V4.11111H10.8333V6.27778H9.38889Z" fill="#888888"/>
                  </svg>
                </Box>
              </>
            )
          }
          <Box 
            sx={style.box}
            onClick={()=> {
              handleClose();
              setOpenActionDrawer(prev => !prev);
            }}
          >
            <Typography variant='body2' >{i18n.t('components.chat.chatInput.addActions')}</Typography>
            <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 12.0556V1.94444C1 1.56135 1.13696 1.19395 1.38076 0.923068C1.62456 0.652182 1.95522 0.5 2.3 0.5H12.7C13.0448 0.5 13.3754 0.652182 13.6192 0.923068C13.863 1.19395 14 1.56135 14 1.94444V12.0556C14 12.4386 13.863 12.806 13.6192 13.0769C13.3754 13.3478 13.0448 13.5 12.7 13.5H2.3C1.95522 13.5 1.62456 13.3478 1.38076 13.0769C1.13696 12.806 1 12.4386 1 12.0556Z" stroke="#888888"/>
              <path d="M1 3.38867H14M5.55 8.44423H7.5M7.5 8.44423H9.45M7.5 8.44423V6.27756M7.5 8.44423V10.6109" stroke="#888888" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
          </Box>
          <Box 
            sx={style.box}
            onClick={()=>{
              setFilePopUpOpen(true);
              handleFileInputClick();
            }}
          >
            <Typography variant='body2' >{i18n.t('components.chat.chatInput.attachments')}</Typography>
            <AttachFileIcon color='neutral' style={{rotate: '45deg', height: '13px', width: '13px'}} />
            <input
              ref={fileInputRef}
              type="file"
              multiple
              style={{ display: "none" }}
              onChange={(e)=> {
                handleFileInputChange(e);
                handleClose();
              }}
              />
          </Box>
        </Paper>
      </Popper>
    </ClickAwayListener>
  )
}

export default connect(state => ({ user: state.auth.user }))(PopoverContent);