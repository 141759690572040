import React, { useContext, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import { BuilderContext, useDrawer } from 'react-flow-builder';
import { Form } from 'antd';
import { Button, InputLabel, MenuItem, Select, FormControl, TextField, Avatar, Box, Icon } from '@mui/material';
import {getHeaders} from '../../../store/actions/localStorage'
import axios from 'axios';
import { api, repository } from '../../../config';
import { i18n } from '../../../translate/i18n';

const ConfigForm = () => {
    const { selectedNode: node } = useContext(BuilderContext);

    const { closeDrawer: cancel, saveDrawer: save } = useDrawer();

    const [form] = Form.useForm();
    const handleSubmit = async () => {
        deleteIds.forEach(async (id) => {
            axios.delete(`${api}/form/files/${id || '-1'}`, getHeaders());
    });

        try {
            save?.({
                name, 
                description,
                fieldType,
                options,
            });
        } catch (error) {
            const values = form.getFieldsValue();
            save?.(values, !!error);
        }
    };

    const [name, setName] = useState(node.data?.name || '');
    const [description, setDescription] = useState(node.data?.description || '');
    const [fieldType, setFieldType] = useState(node.data?.fieldType || '');
    const [options, setOptions] = useState(node.data?.options || []);
    const [currentOption, setCurrentOption] = useState({ option: '', description: '' });
    const [deleteIds, setDeleteIds] = useState([]);

    const handleChange = (event) => {
        setFieldType(event.target.value);
    };

    const handleOptionChange = (value, index) => {
        setOptions(
            options.map((option, i) => {
              if (i === index) {
                return { ...option, value: value };
              }
              return option;
            })
          );
    };

    const handleOptionDescriptionChange = (value, index) => {
        setOptions(
          options.map((option, i) => {
            if (i === index) {
              return { ...option, description: value };
            }
            return option;
          })
        );
      };
      

    const addOption = () => {
        setOptions([...options, { option: currentOption.option, description: currentOption.description }]);
        setCurrentOption({ option: '', description: '' });
    };

    const handleDeleteOption = (index, option) => {
        setOptions(options.filter((_, i) => i !== index));
        setDeleteIds([...deleteIds, option?.thumbnail?.origin || '-1']);
    };

    return (
        <div 
            style={{
                position: 'fixed',
                top: '0',
                right: '0',
                overflow: 'auto',
                backgroundColor: 'white',
                width: "25%",
                minWidth: '400px',
                height: '100vh',
                padding: '20px',
                borderLeft: '1px solid #EDEDED',
                zIndex: '999',
            }}
        >
            <form>
                <Icon
                    sx={{
                        color: "#86888c",
                        cursor: "pointer",
                    }}
                    onClick={cancel}
                    className="material-icons-outlined"
                    fontSize="large"
                >
                    cancel
                </Icon>
                <TextField
                    label={i18n.t('start.form.configForm.name')}
                    value={name}
                    onChange={(event) => setName(event.target.value)}
                    fullWidth
                    margin="normal"
                />
                <ReactQuill
                        placeholder={i18n.t('start.form.configForm.descriptionHere')}
                        margin="normal" value={description}
                        onChange={setDescription} />
                <FormControl
                    margin="normal" style={{ margin: '20px 0px' }}
                    fullWidth>
                    <InputLabel id="field-type-select-label">{i18n.t('start.form.configForm.typeField')}</InputLabel>
                    <Select
                        labelId="field-type-select-label"
                        id="field-type-select"
                        value={fieldType}
                        label={i18n.t('start.form.configForm.typeField')}
                        onChange={handleChange}
                    >
                        <MenuItem value="single">{i18n.t('start.form.configForm.uniqueList')}</MenuItem>
                        <MenuItem value="multiple">{i18n.t('start.form.configForm.multipleList')}</MenuItem>
                        <MenuItem value="single_option">{i18n.t('start.form.configForm.uniqueOption')}</MenuItem>
                        <MenuItem value="multiple_option">{i18n.t('start.form.configForm.multipleOption')}</MenuItem>
                        <MenuItem value="count">{i18n.t('start.form.configForm.count')}</MenuItem>
                    </Select>
                </FormControl>
                {['single', 'multiple', 'multiple_option', 'single_option'].includes(fieldType) ? (
                    <>
                        <h5>{i18n.t('start.form.configForm.options')}</h5>
                        {options.map((option, index) => (
                            <div className="optionsForm"key={index}>
                                <hr />
                                <TextField
                                    label={`${i18n.t('start.form.configForm.option')} ${index + 1}`}
                                    value={option.value}
                                    name={`option_${index}`}
                                    onChange={(value) => handleOptionChange(value.target.value, index)}
                                    fullWidth
                                    margin="normal"
                                    style={{margin: '0px'}}
                                />
                                {['multiple_option', 'single_option'].includes(fieldType) &&
                                <FormControl
                                    fullWidth>
                                    <ReactQuill
                                        name={`description_${index}`}
                                        placeholder={`${i18n.t('start.form.configForm.descriptionOption')} ${index + 1}`}
                                        value={option.description}
                                        onChange={(value) => handleOptionDescriptionChange(value, index)} />
                                </FormControl>
                                }
                                {['single_option', 'single'].includes(fieldType) &&
                                <Box display="flex"  gap="0.5rem">
                                    <Avatar 
                                        variant="square" 
                                        src={`${repository}/files/form/${option?.thumbnail?.origin}`} 
                                        sx={{
                                            height: "auto", 
                                            maxHeight: "3.5rem",
                                            minWidth: "3.5rem",
                                            maxWidth: "3.5rem",
                                        }}
                                    >
                                        ?
                                    </Avatar>
                                    <TextField 
                                        InputLabelProps={{ shrink: true }} 
                                        type="file" 
                                        fullWidth
                                        onChange={async (event) =>
                                        {
                                            const file = event.target.files[0];
                                            const formData = new FormData();
                                            formData.append('file', file);

                                            const data = await axios.post(`${api}/form/files/${option?.thumbnail?.origin || '-1'}`, formData, getHeaders());
                                            const { files } = data.data;
                                            setOptions(
                                                options.map((option, i) => {
                                                    if (i === index) {
                                                        return { ...option, thumbnail: files };
                                                    }
                                                    return option;
                                                })
                                            );
                                        }}
                                    />
                                </Box>
                                }
                                <Button onClick={() => handleDeleteOption(index, option)}>{i18n.t('start.form.configForm.delete')}</Button>
                            </div>
                        ))}
                        <Button variant='contained' onClick={addOption}>{i18n.t('start.form.configForm.addOption')}</Button>
                    </>
                ) : null}
            </form>
            <div>
                        <br />
                <Button sx={{mr: '5px'}} onClick={cancel}>{i18n.t('start.form.configForm.cancel')}</Button>
                <Button  variant='contained'  onClick={handleSubmit}>
                {i18n.t('start.form.configForm.save')}
                </Button>
            </div>
        </div>
    );
};

export default ConfigForm;