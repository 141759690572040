import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { chartLocale } from './locale';
import { abbreviateNumber } from '../../pages/Sprint/utils/abbreviateNumber';

const ApexDashedChart = ({ 
  datetime = true, 
  seriesData = [], 
  categories = [], 
  title =' ', 
  subtitle = '', 
  type = 'line', 
  stacked = false, 
  horizontal = false, 
  height = 350, 
  opposite = false, 
  currency = false,
  dashArray = [],
  colors = [],
}) => {
  const [series, setSeries] = useState([]);

  const [options, setOptions] = useState({
    chart: {
      locales: [chartLocale],
      defaultLocale: 'global',
      height: 350,
      stacked,
      type,
      zoom: {
        enabled: false
      },
    },
    stroke: {
      curve: 'smooth',
      dashArray,
    },
    colors,
    responsive: [{
      breakpoint: 480,
      options: {
        legend: {
          position: 'bottom',
          offsetX: -10,
          offsetY: 0
        }
      }
    }],
    plotOptions: {
      bar: {
        horizontal,
        borderRadius: 4,
        dataLabels: {
          total: {
            enabled: false,
            style: {
              fontSize: '13px',
              fontWeight: 900
            }
          }
        }
      },
    },
    dataLabels: {
      enabled: false
    },
    title: {
      text: '',
      align: 'left',
      style: {
        fontSize: '22px',
        fontFamily: 'Roboto',
        color: '#000000',
        fontWeight: 500
      }
    },
    subtitle: {
      text: subtitle,
      align: 'left',
      style: {
        fontSize: '14px',
        color: '#888888'
      }
    },
    legend: {
      tooltipHoverFormatter: function (val, opts) {
        return val + ' - <strong>' + opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] + '</strong>'
      }
    },
    markers: {
      size: 0,
      hover: {
        sizeOffset: 6
      }
    },
    xaxis: {
      type: datetime ? 'datetime' : 'category',
      categories: [],
    },
    yaxis: opposite ? [{
      labels: {
        formatter: (val) => abbreviateNumber(val, currency),
      },
      min: 0,
    },
    {
      opposite,
      min: 0,
    }] 
    :
    {
      min: 0,
      labels: {
        formatter: (val) => abbreviateNumber(val, currency)
      },
    },
    tooltip: {
      y: [
        {
          formatter: (val) => abbreviateNumber(val, currency),
          title: {
            formatter: function (val) {
              return val
            }
          }
        },
        {
          formatter: (val) => abbreviateNumber(val, currency),
          title: {
            formatter: function (val) {
              return val
            }
          }
        },
      ]
    },
    grid: {
      borderColor: '#f1f1f1',
    }
  });

  useEffect(() => {
    setSeries(seriesData);
    setOptions({
      ...options,
      chart: {
        ...options.chart,
        stacked,
        type
      },
      title: {
        ...options.title,
        text: title
      },
      subtitle: {
        ...options.subtitle,
        text: subtitle
      },
      xaxis: {
        ...options.xaxis,
        categories
      }
    });

  }, [seriesData, categories]);

  return (
    <div>
      <div id="chart">
        <ReactApexChart options={options} series={series} type={type} height={height} />
      </div>
      <div id="html-dist"></div>
    </div>
  );
};

export default ApexDashedChart;
