import {
  Box,
  Button,
  Divider,
  Icon,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import GenttRow from "./GenttRow";
import { connect } from "react-redux";
import * as actions from "../../../store/actions/customers";
import { useStyles } from "./styles";
import dayjs from "dayjs";
import "../../../styles/dashboard/sprint/index.css";
import { isDateToday } from "../../Sprint/utils/isDateToday";
import Filter from "./Filter";
import { calculateSingleTimeArea, calculateTimeArea } from "../../Sprint/utils/calculateTimeArea";
import { i18n } from '../../../translate/i18n';
import LinkOutlinedIcon from '@mui/icons-material/LinkOutlined';
import { statusColor } from "../../Sprint/utils/statusColor";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import ProjectsModal from "./ProjectsModal";

const dateRange = (date) => {
  const today = new Date(date);
  const start = new Date(today.getFullYear(), today.getMonth() - 2, 1);
  const end = new Date(today.getFullYear(), today.getMonth() + 3, 0);
  return [dayjs(start), dayjs(end)];
};

const popoverContent = (issuelinks, selectedRow, target) => {
  return (
    issuelinks.some((el) => el.target === target) && (
      <Box
        border="1px solid #888888"
        borderRadius='8px'
        padding='0.5rem'
      >
        <Box
          display='flex'
          gap='0.5rem'
          alignItems='center'
        >
          <div style={{ background: statusColor(selectedRow.status)?.bgcolor, width: '0.5rem', height: '0.5rem', borderRadius: '50%' }} />
          <Typography>
            {selectedRow.name}
          </Typography>
        </Box>
        <Box
          display='flex'
          gap='1rem'
          height='3rem'
          alignItems='center'
          position='relative'
          paddingX='0.5rem'
          marginY='0.5rem'
        >
          <Divider 
            orientation="vertical" 
            flexItem
            sx={{
              border: "1px solid #bFbFbF",
            }}
          />
          <Icon
            sx={{
              borderRadius: "50%",
              backgroundColor: "#bFbFbF",
              position: "absolute",
              top: "0.75rem",
              left: "-0.20rem",
              padding: "5px",
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              fontSize: "1.5rem",
            }}
          >
            <LinkOutlinedIcon 
              sx={{
                rotate: "135deg",
              }} 
            />
          </Icon>
          <Typography
            variant="caption"
          >
            {target === "outward" ? i18n.t('start.roadmap.blocks') : i18n.t('start.roadmap.isBlockedBy')}
          </Typography>
        </Box>
        {
          issuelinks.reduce((acc, el) => {
            if (el.target === target) {
              acc.push({
                name: el.name,
                key: el.key,
                status: el.status,
              });
            }
            return acc;
          }, [])
          .map((el) => (
            <Box
              sx={{
                display: "flex",
                gap: '0.5rem',
                alignItems: "center",
                marginTop: "1rem",
              }}
            >
              <div style={{ background: statusColor(el.status)?.bgcolor, width: '0.5rem', height: '0.5rem', borderRadius: '50%' }} />
              <Typography>
                {el.name}
              </Typography>
            </Box>
          ))
        }
      </Box>
    )
  )
  }

const RoadmapImplementation = (props) => {
  const classes = useStyles();
  const location = useLocation();
  const [dates, setDates] = useState([]);
  const [months, setMonths] = useState([]);
  const [range, setRange] = useState(dateRange(new Date()));
  const [data, setData] = useState([]);
  const [rows, setRows] = useState([]);
  const [checklistDates, setChecklistDates] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const [issuelinks, setIssuelinks] = useState([]);
  const [selectedRow, setSelectedRow] = useState({});
  const [projects, setProjects] = useState(['STM', 'PRICE', 'SPLIT', 'PICK']);
  const [projectsPopover, setProjectsPopover] = useState(false);

  useEffect(() => {
    const isProjects = (location.pathname === "/advanced-roadmap" || props.shop === '663a6551e3c28dd498dd3553')
    const isRoadmap = (location.pathname === "/roadmap" && props.shop !== '663a6551e3c28dd498dd3553');
    if (props.shop && isRoadmap) props.getEpics(props.shop);
    else if (isProjects ) props.getProjects(projects);
    else setData([]);
  }, [props.shop, location]);

  useEffect(() => {
    if (props.epics) {
      setData(props.epics)
    }
  }, [props.epics]);

  const newRange = (range) => {
    setRange(range)
  }

  useEffect(() => {
    const { defaultRows, dates, months } = calculateTimeArea(
      data,
      range,
      true
    );
    setRows(defaultRows);
    setDates(dates);
    setMonths(months);
    setChecklistDates(calculateSingleTimeArea(props.customer?.createdAt, props.customer?.checklistCompletionDate, range))
  }, [range, data]);

  useEffect(async () => {
    props.getViewCustomer(props.shop);
  }, [props.shop])

  return (
    <Box className="dashboard">
      <Box display='flex' justifyContent='space-between'>
        <Typography variant="h4">{i18n.t('start.roadmap.title')}</Typography>
        {location.pathname === "/advanced-roadmap" &&
          <Button className="btn-white medium" onClick={()=> setProjectsPopover(e => !e)}>
            Projetos
          </Button>
        }
      </Box>
      <ProjectsModal
        open={projectsPopover}
        setOpen={setProjectsPopover}
        setProjects={setProjects}
        projects={projects}
        getProjects={props.getProjects}
      />
      <Popover
        open={issuelinks.length > 0}
        anchorEl={anchorEl}
        onClose={() => {
          setIssuelinks([]);
          setAnchorEl(null);
          setSelectedRow({});
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box
          sx={{
            width: "20vw",
            padding: "1rem",
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
          }}
        >
          {popoverContent(issuelinks, selectedRow, "outward")}
          {popoverContent(issuelinks, selectedRow, "inward")}
        </Box>
      </Popover>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <div>
          <Filter
            range={range}
            setRange={newRange}
          />
          <div style={{ overflowX: "auto" }}>
            <TableContainer
              sx={{
                marginTop: 2,
                paddingBottom: "1rem",
                overflowY: "auto",
                position: "relative",
                minWidth: "1000px",
              }}
              className={classes.tableContainer}
            >
              <Table stickyHeader>
                <TableHead>
                  <TableRow sx={{ position: "sticky", top: 0, zIndex: 5 }}>
                    <TableCell
                      sx={{ background: "#FAFAFA", border: "none", width: "16vw" }}
                    />
                    {months.map((month, i) => {
                      let monthCell = [classes.monthCell];
                      if (i === 0) monthCell = [classes.startMonth];
                      if (i === months.length - 1) monthCell = [classes.endMonth];
                      return (
                        <TableCell
                          key={month.name}
                          className={monthCell.join(" ")}
                          colSpan={month.days}
                        >
                          <Typography>{month.name}</Typography>
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    data.length > 0 && (
                      <>
                        <TableRow className={classes.visibleRow}>
                          {dates.map((date, i) => {
                            const cellClasses = [classes.dateCell];
                            const isToday = isDateToday(date);
                            return (
                              <TableCell
                                key={`${date}-${i}`}
                                className={cellClasses.join(" ")}
                              >
                                {isToday && <Box className={classes.todayCell} />}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      </>
                    )
                  }
                  {
                    checklistDates && (
                      <GenttRow 
                        row={checklistDates} 
                        dates={dates} 
                        classes={classes} 
                      />
                    )
                  }
                  {rows?.map((row, i) => (
                    <GenttRow 
                      key={row.name} 
                      row={row} 
                      dates={dates} 
                      classes={classes} 
                      setAnchorEl={setAnchorEl} 
                      setIssuelinks={setIssuelinks} 
                      setSelectedRow={setSelectedRow} 
                    />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </Box>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  shop: state.auth.shop,
  cycle: state.actions.cycle,
  epics: state.customer?.epics,
  customer: state.customer.customerDetails,
});

export default connect(mapStateToProps, actions)(RoadmapImplementation);
