// services/permissions.js
import axios from 'axios';
import { api } from '../../config';
import { getHeaders } from './localStorage';
import errorHandling from './errorHandling';

export const getPermissions = () => async (dispatch) => {
  try {
    const response = await axios.get(api + "/permissions", getHeaders());
    dispatch({ type: "GET_PERMISSIONS", payload: response.data });
  } catch (err) {
    return errorHandling(err);
  }
};

export const updatePermission = (id, permissionData) => async () => {
  try {
    const response = await axios.put(api + `/permissions/${id}`, permissionData, getHeaders());
    return response.data;
  } catch (err) {
    return errorHandling(err);
  }
};

export const createPermission = (permissionData) => async () => {
  try {
    const response = await axios.post(api + "/permissions", permissionData, getHeaders());
    return response.data;
  } catch (err) {
    return errorHandling(err);
  }
};

export const deletePermission = (permissionId) => async () => {
  try {
    const response = await axios.delete(api + "/permissions/" + permissionId, getHeaders());
    return response.data;
  } catch (err) {
    return errorHandling(err);
  }
};


export const updateUserPermissions = (props) => async () => {
    try {
      const response = await axios.put(api + "/users/permissions", props, getHeaders());
      return response.data;
    } catch (err) {
      return errorHandling(err);
    }
  };

export const getPermissionModels = () => async (dispatch) => {
  try {
    const response = await axios.get(api + `/permissions/models/`, getHeaders());
    dispatch({ type: "GET_PERMISSION_MODELS", payload: response.data });
  } catch (err) {
    return errorHandling(err);
  }
}

export const storePermissionModel = (permissionData) => async () => {
  try {
    const response = await axios.post(api + "/permissions/models", permissionData, getHeaders());
    return response.data;
  } catch (err) {
    return errorHandling(err);
  }
}

export const updatePermissionModel = (permissionData) => async () => {
  try {
    const response = await axios.put(api + "/models/permissions", permissionData, getHeaders());
    return response.data;
  } catch (err) {
    return errorHandling(err);
  }
}