import React from 'react'
import { Box } from '@mui/material';
import "../../../styles/dashboard/sprint/index.css";
import dayjs from "dayjs";
import 'dayjs/locale/es';
import { FCDatePicker } from 'components-fullcomm';

const Filter = ({ range, setRange }) => {

  const handleRangeSelect = (date) => {
    const maxDate = new Date(
      new Date(date).getFullYear(),
      new Date(date).getMonth() + 12,
      0
    );
    if (range[1] > maxDate) {
      setRange([date, dayjs(maxDate)]);
    } else if (range[1] < date) {
      setRange([
        date,
        dayjs(
          new Date(
            new Date(date).getFullYear(),
            new Date(date).getMonth() + 1,
            0
          )
        ),
      ]);
    } else {
      setRange([date, range[1]]);
    }
    return;
  };

  let languageCalendar = localStorage.getItem('i18nextLng');

  return (
    <Box
    display={"flex"}
    justifyContent={"flex-end"}
    alignItems={"center"}
    marginTop={2}
  >
    <Box
      display={"flex"}
      gap={2}
    >
      <FCDatePicker
        value={range[0]}
        onChange={handleRangeSelect}
        views={["month", "year"]}
        sx={{
          width: "12rem",
          ".css-nxo287-MuiInputBase-input-MuiOutlinedInput-input": {
            paddingY: "0.5rem",
          },
        }}
      />
      <FCDatePicker
        minDate={range[0]}
        maxDate={dayjs(range[0]).add(11, 'month').endOf('month')}
        value={range[1]}
        onChange={(e) =>
          setRange([
            range[0],
            dayjs(
              new Date(
                new Date(e).getFullYear(),
                new Date(e).getMonth() + 1,
                0
              )
            ),
          ])
        }
        views={["month", "year"]}
        sx={{
          width: "12rem",
          ".css-nxo287-MuiInputBase-input-MuiOutlinedInput-input": {
            paddingY: "0.5rem",
          },
        }}
      />
    </Box>
  </Box>
  )
}

export default Filter