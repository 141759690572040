import React from "react";
import { statusColor } from "../../statusColor";
import { Avatar, Divider, Grid, Typography } from "@mui/material";
import { api } from "../../../../../config";
import { i18n } from '../../../../../translate/i18n'
import { FCChip } from "components-fullcomm";

const ActionCard = ({ action, index, length, dateFilter, hideAvatar = false }) => {
  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear().toString();

    return `${day}/${month}/${year}`;
  }
  
  const problemCreditValue = (problem, currDate) => {
    if (!problem.history) return 0;

    const date = new Date(currDate);
    const month = date.getMonth();
    const year = date.getFullYear();

    const seconds = problem.history.reduce((acc, cur) => {
      if (cur.date === `${year}-${month}`) {
        return acc + cur.timeSpentSeconds;
      }
      return acc;
    }, 0);

    return Math.ceil(Math.ceil(seconds / 1800) * 12.5);
  }

  const { type } = statusColor(action.status);
  return (
    <div key={action._id}>
      <Grid
        container
        alignItems={"center"}
        width={"100%"}
        padding={2}
      >
        {!hideAvatar && (
          <Grid item xs={2} sm={1}>
            <Avatar sx={{ width: "44px", height: "44px" }} alt={"user image"} src={(action.customerId?.fileLocation || action.customer?.fileLocation) ? (action.customerId?.fileLocation || action.customer?.fileLocation) : `${api}/files/customers/${action.customerId?.fileName || action.customer?.fileName}`} />
          </Grid>
        )}
        <Grid item xs={7} sm={8} paddingLeft={!hideAvatar && "1rem"}>
          <Typography fontWeight={"700"}>
            {action.name || action.title}
          </Typography>
          <Typography variant="caption">
            {(action.status === 'Backlog' || action.status === 'Planejamento') && formatDate(action.createdAt)}
            {action.status === 'Para fazer' && formatDate(action.date?.provisioned || action.updatedAt)}
            {(action.status === 'Em andamento' || action.status === 'Concluído' || action.status === 'Ativo') && (formatDate(action.date?.debited || action.updatedAt))}
          </Typography>
        </Grid>
        <Grid item xs={3} sm={3} display={"grid"}>
          <Typography textAlign={"end"}><small>{action.credits || problemCreditValue(action, dateFilter)} {i18n.t('sprint.utils.headerButtons.creditBalanceModal.actionCard.credits')}</small></Typography>
          <FCChip
            sx={{
              width: "fit-content",
              justifySelf: "end",
            }}
            color={type}
            label={action.status}
            size="small"
          />
        </Grid>
      </Grid>
      {(length > index + 1 || length < 4) && <Divider />}
    </div>
  );
};

export default ActionCard;
