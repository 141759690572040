const messages_reducers = (state = {}, actions) => {
    switch (actions.type) {
        case 'GET_MESSAGES':
            return {
                ...state,
                messages: actions.payload.messages
            }
            case 'GET_POSITION':
                return {
                    ...state,
                    position: actions.payload.position
                }
        case 'GET_FILES':
            return {
                ...state,
                files: actions.payload
            }
        case 'GET_MARKS':
            return {
                ...state,
                marks: actions.payload
            }
        case 'GET_NOREAD_MESSAGES':
            return {
                ...state,
                noReadMessages: actions.payload
            }
        case 'GET_THREADS':
            return {
                ...state,
                thread: actions.payload
            }
        case 'EDIT_MESSAGE':
            return {
                ...state,
                messageToEdit: actions.payload
            }
        default:
            return state;
    }
}

export default messages_reducers;