// services/permissions.js
import axios from 'axios';
import { api } from '../../config';
import { getHeaders } from './localStorage';
import errorHandling from './errorHandling';

export const getAiContent = (query) => async (dispatch) => {
  try {
    const response = await axios.get(api + "/aicontent?" + query, getHeaders());
    dispatch({ type: "GET_AICONTENT", payload: response.data });
  } catch (err) {
    return errorHandling(err);
  }
};

export const updateAiContent = (id, AiContentData) => async () => {
  try {
    const response = await axios.put(api + `/aicontent/${id}`, AiContentData, getHeaders());
    return response.data;
  } catch (err) {
    return errorHandling(err);
  }
};

export const storeAiContent = (AiContentData) => async () => {
  try {
    const response = await axios.post(api + "/aicontent", AiContentData, getHeaders());
    return response.data;
  } catch (err) {
    return errorHandling(err);
  }
};

export const createWriteSonic = (AiContentData) => async () => {
  try {
    const response = await axios.post(api + "/aicontent/writesonic", AiContentData, getHeaders());
    return response.data;
  } catch (err) {
    return errorHandling(err);
  }
};

export const createClipDrop = (AiContentData) => async () => {
  try {
    const response = await axios.post(api + "/aicontent/clipdrop", AiContentData, getHeaders());
    return response.data;
  } catch (err) {
    return errorHandling(err);
  }
};

export const updateAiContentFiles = (category, AiContentData) => async () => {
  try {
    const response = await axios.post(api + `/aicontent/files/${category}`, AiContentData, getHeaders());
    return response.data;
  } catch (err) {
    return errorHandling(err);
  }
};

export const deleteAiContent = (id) => async () => {
  try {
    const response = await axios.delete(api + `/aicontent/${id}`, getHeaders());
    return response.data;
  } catch (err) {
    return errorHandling(err);
  }
};