import React, { useEffect, useState, useLayoutEffect, useRef } from "react";
import moment from "moment";
import { connect } from "react-redux";
import { deleteMessage, localEditMessage, markMessage, unmarkMessage, updateMessage } from "../../store/actions/messages";
import "moment/locale/pt-br";
import { Avatar, Box, Typography, IconButton, Modal } from "@mui/material";
import { repository, api } from "../../config";
import BookmarkIcon from '@mui/icons-material/Bookmark';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import { Skeleton } from '@mui/material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { i18n } from "../../translate/i18n";
import ChatAutomaticMessage from "./ChatAutomaticMessage";
import ActionCard from "../../pages/Sprint/Strategy/ActionCard";
import Attachment from "./utils/Attachtment";
import sanitizeHtml from 'sanitize-html';
import MessageDeleteModal from "./utils/MessageDeleteModal";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

const ChatConversation = (props) => {
  const chatRef = useRef(null);
  const { user } = props;
  const [conversation, setConversation] = useState();
  const [lightboxImage, setLightboxImage] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [deleteMessageId, setDeleteMessageId] = useState(null);

  useEffect(() => {
    setConversation(props.conversation);
  }, [props.conversation]);
  
  const groupMessagesByUserAndDay = (messages) => {
    const groupedMessages = [];
    let prevDate = null;

    messages?.forEach((message) => {
      if (!message) return;
      const messageDay = moment(message.createdAt).format("YYYY-MM-DD");

      if (prevDate !== messageDay) {
        groupedMessages.push({ day: messageDay, type: "day" });
        prevDate = messageDay;
      }
      const tagged = message.taggedUsers?.includes(user?._id);
      const prevMessage = groupedMessages[groupedMessages.length - 1];
      const timeDiffMinutes = moment(message.createdAt).diff(moment(prevMessage.createdAt), 'minutes');
      if (
        prevMessage &&
        prevMessage.type === "message" &&
        prevMessage.author?._id === message.author?._id &&
        moment(prevMessage.createdAt).format("YYYY-MM-DD") === messageDay &&
        timeDiffMinutes < 2
      ) {
        prevMessage.text.push({ author: message.author?._id, text: message.text, edited: message.edited, files: message.files, _id: message._id, createdAt: message.createdAt, mark: message.marked?.includes(user?._id), tagged, children: message.children, action: message.action, deleted: message.deleted });
      } else {
        groupedMessages.push({
          ...message,
          text: [{ author: message.author?._id, text: message.text, edited: message.edited, files: message.files, _id: message._id, createdAt: message.createdAt, mark: message.marked?.includes(user?._id), tagged, children: message.children, action: message.action, deleted: message.deleted }],
          type: "message",
          tagged,
        });
      }
    });

    return groupedMessages;
  };

  const openImageInLightbox = (file) => {
    setLightboxImage(file.location ? file.location : `${api}/files/chat/${file.filename}`);
  };

  const closeLightbox = () => {
    setLightboxImage(null);
  };

  const handleMarkMessage = (messageId) => {
    const updatedConversation = conversation.map((message) => {
      if (message._id === messageId) {
        if (message.marked.includes(user?._id)) {
          props.unmarkMessage(messageId);
          message.marked = message.marked.filter((userId) => userId !== user?._id);
        } else {
          props.markMessage(messageId);
          message.marked.push(user?._id);
        }
      }
      return message;
    });
    setConversation(updatedConversation);
  };

  const MessageItem = ({ message, handleMarkMessage, i, info }) => {
    const [hovered, setHovered] = useState(false);
    const [linkTitle, setLinkTitle] = useState('');
    const [linkDescription, setLinkDescription] = useState('');
    const [linkHref, setLinkHref] = useState('');
    const [text, setText] = useState(formatText(message.text));
    const handleMouseEnter = () => {
      setHovered(true);
    };

    const handleMouseLeave = () => {
      setHovered(false);
    };

    function formatText (text) {

      const formattedText =  text?.replace(/@~~~([^~]+)~~~/g, (match, username) => {
        return `<span class='mention-text'> @${username.replace(/[^a-zA-Z0-9]/g, '')}</span>`;
      });

      return sanitizeHtml(formattedText, {
        allowedTags: ['p', 'em', 'span', 'strong', 'ul', 'li', 'a', 'br'],
        allowedClasses: {
          'span': ["mention-text", "mention"],
          li: ["ql-indent-1"]
        },
      });
    }

    const getInfoFromWebsite = async (url) => {
      const response = await fetch(url);
      const html = await response.text();
      const parser = new DOMParser();
      const doc = parser.parseFromString(html, 'text/html');
      const titleElement = doc.querySelector('title')?.textContent;
      const descriptionMeta = doc.querySelector('meta[name="description"]')?.getAttribute('content');

      setLinkTitle(titleElement);
      setLinkDescription(descriptionMeta);
      setLinkHref(url);
    };

    useEffect(() => {
      const urlRegex = new RegExp(`(https?:\/\/fullcomm\.app\/[^\/]+)`, 'gi');
      const urls = text?.match(urlRegex);
      if (urls?.length) getInfoFromWebsite(urls[0]);

    }, [text])

    return message.deleted ? <i style={{color: "#979797"}}>{i18n.t('components.chat.chatConversation.messageDeleted')}</i> : (
      <Box
        id={message._id}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        style={{ backgroundColor: hovered ? '#d3d3d340' : 'inherit', width: '100%', position: 'relative' }}
      >
        <Typography variant="body2" whiteSpace="pre-line">
          <div 
            dangerouslySetInnerHTML={{__html: text}} 
            className="dangerous-html"
          /> 
          {message.edited ? <i style={{color: "#979797"}}>({i18n.t('components.chat.chatConversation.edited')})</i> : ""}
          {
            linkTitle && (
              <Box mt="0.5rem" ml="0.5rem" pl="0.25rem" borderLeft="2px solid #a9a9a9">
                <span>
                  <img src={`https://fullcomm.app/favicon.ico`} style={{ width: '16px', height: '16px', marginRight: '5px', borderRadius: '5px' }} />
                  <a target="_blank" href={linkHref} style={{fontWeight: 500}}>{" "}{linkTitle}</a>
                </span>
                <Typography variant="body2">{linkDescription}</Typography>
              </Box>
            )
          }
        </Typography>
        {
          message.action && 
          <Box 
            sx={{
              marginTop: '1rem',
              '& > *:first-child': {
                border:'0px 0px 2px 0px', 
                boxShadow:'0px 4px 4px 0px #00000026',
              }
            }}
          >
            <ActionCard action={message.action} user={user} />
          </Box>
        }
        {message.children?.length > 0 && 
          <Box
            sx={{ cursor: "pointer", mt: "0.25rem", display: 'flex', gap: '0.25rem', alignItems: 'center'}}
          >
            <Typography 
              variant="subtitle2" 
              color='#00D6CF'
              onClick={() => props.setThread({...info, text: message.text, _id: message._id})}
            >
              {message.children.length} {message.children.length === 1 ? i18n.t('components.chat.chatConversation.reply') : i18n.t('components.chat.chatConversation.replies')}
            </Typography>
            <svg style={{ marginBottom: '0.25rem'}} width="15" height="12" viewBox="0 0 15 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M10 0.5L8.825 1.60786L10 2.72357L11.8083 4.42857H4.16667C1.86667 4.42857 0 6.18857 0 8.35714V11.5H1.66667V8.35714C1.66667 7.06071 2.79167 6 4.16667 6H11.8083L8.825 8.81286L10 9.92857L15 5.21429L10 0.5Z" fill="#00D6CF"/>
            </svg>
          </Box>
        }
        {message.files?.length > 0 && (
          <Box sx={{ display: "flex", flexDirection: "column", mb: '10px' }}>
            {message.files.map((file, j) => (
              <Attachment 
                key={j} 
                {...file} 
                setLightboxImage={()=> setLightboxImage(file.location ? file.location : `${repository}/files/chat/${file.filename}`)} 
              />
            ))}
          </Box>
        )}
        <Box
          sx={{
            position: 'absolute',
            top: '-30px',
            bottom: 'auto',
            right: "10px",
            paddingBottom: '0',
            visibility: (hovered) ? 'visible' : 'hidden'
          }}
        >
          <IconButton size="small" onClick={() => props.setThread({...info, text: message.text, _id: message._id} )}>
            <svg width="17" height="13" viewBox="0 0 17 13" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M11.3333 0L10.0017 1.30929L11.3333 2.62786L13.3828 4.64286H4.72222C2.11556 4.64286 0 6.72286 0 9.28571V13H1.88889V9.28571C1.88889 7.75357 3.16389 6.5 4.72222 6.5H13.3828L10.0017 9.82429L11.3333 11.1429L17 5.57143L11.3333 0Z" fill="#888888"/>
            </svg>


          </IconButton>
          <IconButton size="small" onClick={() => handleMarkMessage(message._id)}>
            {message.mark ? <BookmarkIcon style={{ height: '20px' }} /> : <BookmarkBorderIcon style={{ height: '20px' }} />}
          </IconButton>
          {
            message.author === user?._id &&
            <>
              <IconButton size="small" onClick={() => props.localEditMessage(message)}>
                <EditOutlinedIcon style={{ height: '20px' }} />

              </IconButton>
              {
                user?.role === 'admin' &&
                <IconButton size="small" onClick={() => {setOpenModal(true); setDeleteMessageId(message._id)}}>
                  <DeleteOutlineOutlinedIcon style={{ height: '20px' }} />
                </IconButton>
              }
            </>
          }
        </Box>
      </Box>
    );
  };


  const Message = ({ index, message, user, handleMarkMessage }) => {
    const tagged = message.text.some((text) => text.tagged) || message.children?.some(el => !el.read?.includes(user?._id));
    return (
      <Box
        key={index}
        sx={{
          display: "flex",
          alignItems: "flex-start",
          p: '10px',
          backgroundColor: tagged ? "#f7f7cd" : "transparent",
        }}
      >
        <Avatar
          src={
            message.author?.fileLocation ?
              message.author?.fileLocation :
            `${repository}/files/users/${message.author?.fileName}`
          }
          alt={message.author?.name}
          sx={{ mr: 1 }}
        />
        <Box className="message" sx={{ display: "flex", flexDirection: "column", width: '100%' }}>
          <Box sx={{ display: "flex", alignItems: "baseline" }}>
            <Typography variant="subtitle1" sx={{ fontWeight: "bold", mr: 1, fontSize: "14px" }}>
              {message.author?.name}
            </Typography>
            <Typography variant="caption" sx={{ color: "text.secondary" }}>
              {moment(message.createdAt).format("DD/MM/YYYY - HH[h]mm")}
            </Typography>
          </Box>
          {message.text.map((text, i) => (
            <MessageItem
              key={i}
              message={text}
              info={message}
              handleMarkMessage={handleMarkMessage}
            />
          ))}
        </Box>
      </Box>
    );
  };


  const renderMessage = (index, message) => (
    <Message
      key={index}
      index={index}
      message={message}
      user={user}
      handleMarkMessage={handleMarkMessage}
    />
  );

  const groupedConversation = groupMessagesByUserAndDay(conversation);

  const [oldHeight, setOldHeight] = useState(0);
  const [firstMessage, setFirstMessage] = useState(null);
  const [lastMessage, setLastMessage] = useState(null);


  useEffect(() => {
    setFirstMessage(null);
    setLastMessage(null);
  }, [props.activeConversation]);

  useLayoutEffect(() => {
    if (conversation?.length > 0) {
      const container = document.getElementById("chat-container");
      const height = oldHeight
      setOldHeight(container.scrollHeight);
      if (props.position !== null) {
        setTimeout(() => {
          const message = document.getElementById(props.position);
          let parentDiv = message.parentElement.parentElement;
          parentDiv.scrollIntoView({ behavior: 'smooth', block: 'start' });
          parentDiv.style.background = '#e0e0e0';
          setTimeout(() => {
            parentDiv.style.background = 'transparent';
          }, 3000);
        }, 500);
      } else if (firstMessage !== conversation[conversation?.length - 1]._id) {
        if (!props.newMessages) container.scrollTop = container.scrollHeight;
      } else if (oldHeight != 0 && container.scrollHeight > oldHeight && container.scrollHeight - height > 50) {
        const newHeight = container.scrollHeight;
        container.scrollTop = newHeight - oldHeight;
      } else if (lastMessage === null) {
        container.scrollTop = container.scrollHeight;
      }

      setFirstMessage(conversation?.[conversation?.length - 1]._id);
      setLastMessage(conversation?.[0]._id);
    }
  }, [groupedConversation]);

  const MessageItemSkeleton = () => {
    return (
      <Box sx={{ display: 'flex', alignItems: 'flex-start', mb: 2, p: '10px' }}>
        <Skeleton variant="circular" width={40} height={40} sx={{ mr: 1 }} />
        <Box className="message" sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
          <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
            <Skeleton variant="text" width={150} height={20} sx={{ mr: 1 }} />
            <Skeleton variant="text" width={100} height={16} />
          </Box>
          <Skeleton variant="text" width="70%" height={16} sx={{ mt: 1, mb: 0.5 }} />
          <Skeleton variant="text" width="80%" height={16} sx={{ mb: 0.5 }} />
          <Skeleton variant="text" width="60%" height={16} sx={{ mb: 0.5 }} />
          <Skeleton variant="text" width="90%" height={16} sx={{ mb: 0.5 }} />
        </Box>
      </Box>
    );
  };

  return (
    <div style={{ position: 'relative' }}>
      <MessageDeleteModal 
        id={deleteMessageId}
        setDeleteId={setDeleteMessageId}
        open={openModal}
        setOpen={setOpenModal}
        deleteMessage={props.deleteMessage}
      />
      {props.oldMessages && conversation?.length >= 40 &&
        <div >
          <MessageItemSkeleton />
        </div>}

      <Box id="scrollableDiv" ref={chatRef} sx={{ pt: 2 }}>
        {groupedConversation.map((item, index) => {
          if (item.type === "day") {
            return (
              <Box
                id={index === 0 && 'firstMessage'}
                key={index}
                sx={{
                  display: "flex",
                  position: "relative",
                  justifyContent: "center",
                  alignItems: "center",
                  mb: 1,
                  mt: index !== 0 ? 2 : 0,
                }}
              >
                <Typography
                  variant="caption"
                  style={{
                    borderRadius: "4px",
                    backgroundColor: "white",
                    border: '1px solid #F3F3F3',
                    padding: "4px",
                    color: "#888888",
                    zIndex: "1",
                  }}
                >
                  {moment(item.day).format("D [de] MMM[.] YYYY")}
                </Typography>
                <div
                  style={{
                    width: "100%",
                    height: "1px",
                    backgroundColor: "#e3e3e3",
                    position: "absolute",
                    zIndex: "0",
                  }}
                ></div>
              </Box>
            );
          } else {
            return (
              <Box key={index} sx={{ mt: 0 }}>
                {renderMessage(index, item)}
              </Box>
            );
          }
        })}
        <ChatAutomaticMessage message={groupedConversation[groupedConversation?.length - 1]} activeConversation={props.activeConversation} />
        <Modal open={lightboxImage !== null} onClose={closeLightbox}>
          <Box
            sx={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <img
              src={lightboxImage}
              alt="Imagem"
              style={{ maxWidth: "80vw", maxHeight: "80vh" }}
            />
          </Box>
        </Modal>
      </Box>

      {props.newMessages && groupedConversation.length > 0 &&
        <div >
          <MessageItemSkeleton />
        </div>}
    </div>
  );
};

const mapStateToProps = state =>
({
  position: state.message.position,
})


const mapDispatchToProps = (dispatch) => {
  return {
    updateMessage: (id, text) => dispatch(updateMessage(id, text)),
    markMessage: (id) => dispatch(markMessage(id)),
    unmarkMessage: (id) => dispatch(unmarkMessage(id)),
    localEditMessage: (message) => dispatch(localEditMessage(message)),
    deleteMessage: (id) => dispatch(deleteMessage(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChatConversation);
