import React from 'react'
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import AppFormCms from './AppFormCms';
import AppFormNotifications from './AppFormNotifications';
import { i18n } from '../../../translate/i18n';
import { FCTabs } from 'components-fullcomm';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}




const AppTabs = ({ category }) => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box width={"100%"}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <FCTabs value={value} onChange={handleChange} aria-label="basic tabs">
          <Tab label={i18n.t('apps.utils.appTabs.cms')} />
          <Tab label={i18n.t('apps.utils.appTabs.notifications')} />
        </FCTabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <AppFormCms category={category} />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <AppFormNotifications category={category} />
      </CustomTabPanel>
    </Box>
  );
}

export default AppTabs