import { Badge, Tab, Tabs, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState} from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/notifications';
import PropTypes from "prop-types";
import SectionNotifications from './SectionNotifications';
import { i18n } from '../../translate/i18n';


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function Notifications(props) {
  const [index, setIndex] = useState(0);
  const [notifications, setNotifications] = useState([]);

  const handleChange = (event, newIndex) => {
    setIndex(newIndex);
  };

  const getLength = (section, indexValue) => {
    if (indexValue === index) return 0;
    if (section) {
      return notifications?.filter(notification => notification.category == section && !notification.read.includes(props.user._id)).length
    }
    // return notifications?.filter(notification => !notification.read.includes(props.user._id)).length
  }

  useEffect(() => {
    if (index != 0) return;
    setNotifications(props.notifications?.docs)
  }, [props.notifications])
  
  useEffect(() => {
    props.getNotifications(props.user?._id)
  }, [])

  const tab = (section, i) => {
    const {amount} = props.notifications?.amountUnread?.find(notification => notification.category == section) || {amount: 0};
    switch (section) {
      case "Todas": section = i18n.t('notifications.all'); break;
      case "Comunicados": section = i18n.t('notifications.communications'); break;
      case "Aguardando aprovação": section = i18n.t('notifications.waitingApproval'); break;
      case "Ações": section = i18n.t('notifications.actions'); break;
      case "Conta": section = i18n.t('notifications.account'); break;
      case "Alertas": section = i18n.t('notifications.alerts'); break;
      default: break;
    }

    if (section === "Todas" || section === "All") return (
      
      <Tab label={section}
        iconPosition='end'
        icon={<Badge sx={{paddingLeft: "5px"}} badgeContent={0} color='to_do'/>}
      />
    )
    return (
    <Tab label={section} 
      iconPosition='end'
      icon={<Badge sx={{paddingLeft: "5px"}} badgeContent={amount} color='to_do'/>}
    />
    )
  }

  return (
    <Box padding={"2rem"}>
      <Typography variant="h1">{i18n.t('notifications.notifications')}</Typography>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={index}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          sx={{
            "& .MuiTabs-indicator": { backgroundColor: "#008060" },
            "& .MuiButtonBase-root": {
              color: "#a2a4a7",
              textTransform: "none",
            },
            "& .Mui-selected": {
              color: "#008060 !important",
            },
          }}
        > 
          { tab("Todas", 0) }
          { tab("Comunicados", 1) }
          { tab("Aguardando aprovação", 2) }
          { tab("Ações", 3) }
          { tab("Conta", 4) }
          { tab("Alertas", 5) }
        </Tabs>
      </Box>
      <TabPanel value={index} index={0}>
        <SectionNotifications section="" />
      </TabPanel>
      <TabPanel value={index} index={1}>
        <SectionNotifications section="Comunicados" />
      </TabPanel>
      <TabPanel value={index} index={2}>
        <SectionNotifications section="Aguardando aprovação" />
      </TabPanel>
      <TabPanel value={index} index={3}>
        <SectionNotifications section="Ações" />
      </TabPanel>
      <TabPanel value={index} index={4}>
        <SectionNotifications section="Conta" />
      </TabPanel>
      <TabPanel value={index} index={5}>
        <SectionNotifications section="Alertas" />
      </TabPanel>
    </Box>
  );  
}

const mapStateToProps = state =>
({
    user: state.auth.user,
    notifications: state.notifications.notifications
})



export default connect(mapStateToProps, actions)(Notifications);
