import { Button } from '@mui/material';
import React from 'react'
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { i18n } from '../../../translate/i18n';

const styles = {
  background: "#b8babb",
  border: "none",
  color: "black",
  textTransform: "none",
  '&:hover': {
    background: "#aeaeae",
  }
};


const ShowMoreLessButton = ({ originalArray, setDisplayArray, displayArray }) => {

  const showMore = () => {
    setDisplayArray(originalArray);
  };

  const showLess = () => {
    setDisplayArray(
      originalArray.slice(0, 4)
    );
  };

  return (
    <>
    {originalArray?.length > 4 && (
        displayArray?.length > 4 ? (
          <Button
            fullWidth
            sx={styles}
            onClick={showLess}
          >
            <RemoveIcon /> {i18n.t('sprint.utils.headerButtons.showMoreLessButton.seeLess')} {originalArray.length - 4}
          </Button>
        ) : (
          <Button
            fullWidth
            sx={styles}
            onClick={showMore}
          >
            <AddIcon /> {i18n.t('sprint.utils.headerButtons.showMoreLessButton.seeMore')} {originalArray.length - 4}
          </Button>
        )
    )}
    </>
  )
}

export default ShowMoreLessButton