import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import moment from 'moment';
import { repository } from '../../../config';

const Attachment = ({ originalname, size, createdAt, filename, setLightboxImage, location, shadow = true }) => {
  const formattedSize = `${(size / 1024).toFixed(0)} KB`;
  const formattedDate = moment(createdAt).format('DD/MM/YY - HH:mm')
  const isImage = /\.(gif|jpe?g|png|webp)$/i.test(originalname);

  const handleDownload = (event) => {
    event.stopPropagation();
    if (isImage) {
        window.open(location ? location : `${repository}/files/chat/${filename}`, '_blank');
    } else {
        window.location.href = location ? location : `${repository}/files/chat/${filename}`;
    }
  };



  return (
      <Box style={{
          maxWidth: '400px',
          display: 'flex',
          padding: '10px',
          cursor: 'pointer',
          backgroundColor: '#FFFFFF',
          boxShadow: shadow ? '0px 2px 4px 0px #00000014' : 'none',
          mt: shadow ? 'none' : '10px',
          marginBottom: "0.5rem",
      }}
          onClick={() =>
              isImage && setLightboxImage({filename, location})
          }
      >
          {isImage && <Box
              sx={{
                  width: "50px",
                  height: "50px",
                  overflow: "hidden",
                  marginRight: "10px",
              }}
          >
              <img
                  src={location ? location : `${repository}/files/chat/${filename}`}
                  alt={originalname}
                  style={{ height: "100%", width: "100%", borderRadius: "8px", objectFit: "cover", objectPosition: "center"}}
              />
          </Box>}
          <Box>
              <Typography variant='body2' style={{ lineBreak: 'anywhere' }}>{originalname}</Typography>
              <Typography variant='caption' color={'#686B6C'}>{formattedSize} | {formattedDate}</Typography>
          </Box>
          <Box sx={{ ml: "auto" }}>
              <Button
                  type="submit"
                  style={{ color: '#5E6469', minWidth: 'initial' }}
                  onClick={handleDownload}
              >
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M8 12L3 7L4.4 5.55L7 8.15V0H9V8.15L11.6 5.55L13 7L8 12ZM2 16C1.45 16 0.979333 15.8043 0.588 15.413C0.196666 15.0217 0.000666667 14.5507 0 14V11H2V14H14V11H16V14C16 14.55 15.8043 15.021 15.413 15.413C15.0217 15.805 14.5507 16.0007 14 16H2Z" fill="#888888"/>
                  </svg>

              </Button>
          </Box>
      </Box>
  );
};

export default Attachment;