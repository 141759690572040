import React, { useCallback, useState, useRef, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { styled } from '@mui/material/styles';
import { connect } from 'react-redux';
import axios from 'axios';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

import debounce from 'lodash.debounce';
import { Button, Container, InputAdornment, Grid, Modal, Fade, Typography, Accordion, Box, TextField, InputLabel, FormControl, Select, MenuItem, FormControlLabel, Checkbox, RadioGroup, Radio, AccordionSummary, AccordionDetails, Divider, useMediaQuery } from '@mui/material';

import { getActionChecklists, handleAddActionChecklist, getActionChecklist, deleteActionChecklist, handleUpdateActionChecklist, handleUpdateActionChecklistOrder } from '../../../store/actions/checklist'
import '../../../styles/dashboard/start/checklist.css'
import { Icon } from '@iconify/react';
import { i18n } from '../../../translate/i18n';

const BackdropUnstyled = React.forwardRef((props, ref) => {
    const { open, ...other } = props;
    return (
        <Fade in={open}>
            <div ref={ref} {...other} />
        </Fade>
    );
});

BackdropUnstyled.propTypes = {
    open: PropTypes.bool,
};

const Backdrop = styled(BackdropUnstyled)`
    z-index: -1;
    position: fixed;
    right: 0;
    bottom: 0;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.3);
    -webkit-tap-highlight-color: transparent;
  `;

const style = (theme) => ({
    position: 'absolute',
    borderRadius: '20px',
    minHeight: '50vh',
    maxHeight: '80vh',
    overflowY: 'auto',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: 700,
    width: '100%',
    backgroundColor: theme.palette.mode === 'dark' ? '#0A1929' : 'white',
    boxShadow: 24,
    padding: '16px 32px 24px 32px',
});

const Edit = (props) => {
    const [searchIcon, setSearchIcon] = useState('');
    const [form, setForm] = useState(null);
    const [icons, setIcons] = useState([]);
    const [add, setAdd] = useState(null);
    const [data, setData] = useState({});
    const [openModal, setOpenModal] = useState(false)
    const [isEditingTitle, setIsEditingTitle] = useState(false);
    const [isEditingSubTitle, setIsEditingSubTitle] = useState(false);
    const isMobileDevice = useMediaQuery('(max-width:600px)');
    const containerRef = useRef(null);
    const [items, setItems] = useState([]);

    useEffect(() => {
        props.getChecklists()
    }, []);

    useEffect(() => {
        if (props.checklists?.length > 0) {
            setItems(props.checklists?.filter(e => e.parent === "action"));
        }
    }, [props.checklists]);

    const debouncedSearch = useCallback(
        debounce(async (e) => {
            try {
                const response = await axios.get(`https://api.iconify.design/search?query=${e}`);
                setIcons(response.data.icons || []);
            } catch (error) {
                console.error(i18n.t('sprint.actionChecklist.edit.errorIcons'), error);
            }
        }, 1000),
        []
    );


    const handleSearchIcons = (e) => {
        setSearchIcon(e);
        debouncedSearch(e);
    };

    const handleClickOutside = (e) => {
        if (containerRef.current && !containerRef.current.contains(e.target)) {
            setIsEditingTitle(false);
            setIsEditingSubTitle(false);
        }
    };

    const save = async () => {
        if (add) {
            try {
                setData({})
                setAdd(null)
                const response = await props.handleAddChecklist(data);
                if (response.error) {
                    switch (response.error.message) {
                        case "Checklist não foi cadastrado com sucesso!": return toast.error(i18n.t('sprint.actionChecklist.registerError'));;
                        case "Erro interno do servidor.": return toast.error(i18n.t('sprint.actionChecklist.internalError'));;
                        default: return toast.error(response.error.message);
                    }
                } else {
                    if (response.message === "Checklist cadastrado com sucesso!") {
                        toast.success(i18n.t('sprint.actionChecklist.registerSuccess'));
                    } else {
                        toast.success(response.message);
                    }
                }
            } catch (error) {
                if (error.error.message === "Erro interno do servidor.") {
                    toast.error(i18n.t('sprint.actionChecklist.internalError'));
                } else {
                    toast.error(error.error.message);
                }
            }
        } else {
            try {
                const response = await props.handleUpdateChecklist(data);
                if (response.error) {
                    switch (response.error.message) {
                        case "Ocorreu um erro ao atualizar o Checklist.": return toast.error(i18n.t('sprint.actionChecklist.edit.updateError'));
                        case "Erro interno do servidor.": return toast.error(i18n.t('sprint.actionChecklist.internalError'));
                        default: return toast.error(response.error.message);
                    }
                } else {
                    if (response.message === "Tarefa atualizada com sucesso!") {
                        toast.success(i18n.t('sprint.actionChecklist.edit.updateSuccess'));
                    } else {
                        toast.success(response.message);
                    }
                }
            } catch (error) {
                if (error.error.message === "Erro interno do servidor.") {
                    toast.error(i18n.t('sprint.actionChecklist.internalError'));
                } else {
                    toast.error(error.error.message);
                }
            }
        }

        props.getChecklists()
    };

    const deleteChecklist = async (id) => {
        await props.deleteChecklist(id);
        props.getChecklists()
    };

    const handleForm = async (e) => {
        setForm(e)
        const response = await props.getChecklist(e);
        setData(response.data)
        setAdd(null)
    };

    const handleAdd = (e) => {
        setData({
            title: "Título",
            subtitle: "Subtítulo",
            fields: [],
            icon: "iconoir:file-not-found",
            parent: e
        })
        setForm(null)
        setAdd(e)
    };

    const handleFields = (e, field, index) => {
        let value = null;
        if (e.target?.type === 'checkbox') {
            value = e.target.checked;
        } else if (e.target) {
            value = e.target.value;
        } else {
            value = e;
        }
        setData(
            prevState => {
                const newData = [...prevState.fields];
                newData[index][field] = value;
                return { ...prevState, fields: newData };
            }
        )
    }


    const handleOptions = (e, index, indexOption) => {
        setData(
            prevState => {
                const newData = [...prevState.fields];
                newData[index]['options'][indexOption] = e.target?.value || e;
                return { ...prevState, fields: newData };
            }
        )
    }

    const addField = () => {
        const fields = data.fields;
        const uniqueId = Date.now().toString(36) + Math.random().toString(36).substr(2);
        fields.push({
            title: "Campo",
            type: 'text',
            helperText: "",
            required: false,
            parentName: '',
            parentValue: [],
            options: [],
            placeholder: "",
            new: true,
            status: 'a',
            _id: uniqueId
        })
        setData({ ...data, fields })
    };

    const handleDragEnd = (result, key) => {
        if (!result.destination) return;
        const itemsAux = items.find(e => e._id === result.draggableId);
        const itemsCopy = [...items]
        const [reorderedItem] = itemsCopy.splice(result.source.index, 1);
        itemsCopy.splice(result.destination.index, 0, reorderedItem);
        setItems([...itemsCopy]);
        const payload = itemsCopy.map((e, index) => e._id)
        props.handleUpdateChecklistOrder(payload)
    };

    const handleDragEndFields = (result) => {
        if (!result.destination) return;
        const itemsCopy = [...data.fields];
        const [reorderedItem] = itemsCopy.splice(result.source.index, 1);
        itemsCopy.splice(result.destination.index, 0, reorderedItem);

        const aux = data;
        aux.fields = itemsCopy;
        setData(aux)
    };
    return (
        <div onClick={handleClickOutside} class='body'>
            <Grid style={{ borderTop: '1px solid silver', height: '100%' }} container>
                <Grid item xs={isMobileDevice ? 1.5 : 3} className="menu">
                    <div className='item'>
                        {
                            !isMobileDevice && (
                                <h3>{i18n.t('sprint.actionChecklist.edit.checklistAction')}</h3>
                            )
                        }
                    </div>
                    <div className='subitens'>
                        <DragDropContext onDragEnd={result => handleDragEnd(result, "action")}>
                            <Droppable droppableId={`items-action`}>
                                {(provided) => (
                                    <div {...provided.droppableProps} ref={provided.innerRef}>
                                        {(items.length ? items : [])?.map((item, index) => (
                                            <Draggable key={item._id} draggableId={item._id} index={index}>
                                                {(provided) => (
                                                    <div className={`${isMobileDevice ? 'subitem-mobile' : 'subitem'} ${form === item._id ? 'selected' : ''}`} onClick={() => handleForm(item._id)}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        ref={provided.innerRef}
                                                    >
                                                        <Grid container>
                                                            <Grid item xs={isMobileDevice ? 10 : 2} display={"flex"} justifyContent={"center"} className='alignCenterVertical'>
                                                                <Icon icon={item.icon} width='30px' />
                                                            </Grid>
                                                            {
                                                                !isMobileDevice && (
                                                                    <Grid item xs={8} className='alignCenterVertical'>
                                                                        <h4 className='break-word'>{item.title}</h4>
                                                                    </Grid>
                                                                )
                                                            }
                                                            {
                                                                !isMobileDevice && (
                                                                    <Grid item xs={2} className='alignCenterVertical' justifyContent='right'>
                                                                        <Icon icon='material-symbols:drag-indicator' width='1.75rem' />
                                                                    </Grid>
                                                                )
                                                            }
                                                        </Grid>
                                                    </div>
                                                )}
                                            </Draggable>
                                        ))}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                        <div className={`${isMobileDevice ? 'subitem-mobile' : 'subitem'} ${add === "action" ? 'selected' : ''}`} onClick={() => handleAdd("action")}>
                            <Grid container>
                                <Grid item xs={isMobileDevice ? 12 : 2} display={"flex"} justifyContent={"center"} className='alignCenterVertical'>
                                    <Icon icon="material-symbols:add" width='30px' />
                                </Grid>
                                {
                                    !isMobileDevice && (
                                        <Grid item xs={10} className='alignCenterVertical'>
                                            <h4>{i18n.t('sprint.actionChecklist.edit.add')}</h4>
                                        </Grid>
                                    )
                                }
                            </Grid>
                        </div>
                    </div>
                </Grid>
                <Grid item xs={isMobileDevice ? 10.5 : 9}>
                    <Container className={isMobileDevice ? 'p-3' : 'p-5'} style={{ heigth: '100%' }}>
                        {data._id || add ?
                            <>
                                <Grid container columns={20}>
                                    <Grid textAlign='center' md={1} xs={4}>
                                        {
                                            !isMobileDevice && (
                                                <Icon style={{ cursor: 'pointer' }} onClick={() => setOpenModal(true)} icon={data.icon} width='30px' />
                                            )
                                        }
                                    </Grid>
                                    <Grid md={19} xs={16}>
                                        <div ref={containerRef}>
                                            {isEditingTitle ? (
                                                <>
                                                    <input
                                                        style={{ width: '100%' }}
                                                        type="text"
                                                        className='clearInput inputH3'
                                                        value={data.title}
                                                        onChange={e => setData({ ...data, title: e.target.value })}
                                                    />
                                                </>
                                            ) : (
                                                <>
                                                    <h3 onDoubleClick={() => setIsEditingTitle(true)}>{data.title}</h3>
                                                </>)}
                                            {isEditingSubTitle ? (
                                                <>
                                                    <input
                                                        style={{ width: '100%' }}
                                                        type="text"
                                                        className='clearInput inputP'
                                                        value={data.subtitle}
                                                        onChange={e => setData({ ...data, subtitle: e.target.value })}
                                                    />
                                                </>
                                            ) : (
                                                <>
                                                    <p onDoubleClick={() => setIsEditingSubTitle(true)}>{data.subtitle || <small>sub-título</small>}</p>
                                                </>)}
                                        </div></Grid>
                                </Grid>
                                <DragDropContext onDragEnd={handleDragEndFields}>
                                    <Droppable droppableId="itemsFields">
                                        {(provided) => {
                                            return <div {...provided.droppableProps} ref={provided.innerRef}>
                                                {data.fields && data.fields.map((field, index) => {
                                                    if (field.status === 'd') return <></>

                                                    if (field.hasOwnProperty("parentName")) {
                                                        const parentTitle = field.parentName;
                                                        const exists = data.fields.some(item => item.title === parentTitle && item.status !== 'd');
                                                        if (!exists) {
                                                            field.parent = null;
                                                            field.parentName = "";
                                                            field.parentValue = [];
                                                        }
                                                    }

                                                    return (
                                                        <Draggable key={field._id} draggableId={field._id} index={index}>
                                                            {(provided) => (
                                                                <Accordion
                                                                    {...provided.draggableProps}
                                                                    {...provided.dragHandleProps}
                                                                    ref={provided.innerRef}
                                                                >
                                                                    <AccordionSummary>
                                                                        <Grid container>
                                                                            <Grid xs={11}>
                                                                                <Typography>{field.title}</Typography>
                                                                            </Grid>
                                                                            <Grid textAlign='right' xs={1}>
                                                                                <Icon icon='material-symbols:drag-indicator' width='25px' />
                                                                                <Icon onClick={(e) => {
                                                                                    e.stopPropagation()
                                                                                    handleFields('d', 'status', index)
                                                                                }} icon='material-symbols:delete-forever-outline' width='25px' />
                                                                            </Grid>
                                                                        </Grid>
                                                                    </AccordionSummary>
                                                                    <AccordionDetails>
                                                                        <Typography>
                                                                            <Grid container spacing={2} rowSpacing={2}>
                                                                                <Grid item xs={12} md={4}>
                                                                                    <TextField
                                                                                        size='small' fullWidth placeholder="Nome do campo" value={field.title} label={i18n.t('sprint.actionChecklist.edit.title')} onChange={e => handleFields(e, 'title', index)} />
                                                                                </Grid>
                                                                                <Grid xs={12} item md={4}>
                                                                                    <TextField
                                                                                        size='small' fullWidth value={field.helperText} label={i18n.t('sprint.actionChecklist.edit.helperText')} onChange={e => handleFields(e, 'helperText', index)} />
                                                                                </Grid>
                                                                                <Grid xs={12} item md={4}>
                                                                                    <TextField
                                                                                        size='small' value={field.placeholder} fullWidth label={i18n.t('sprint.actionChecklist.edit.placeHolder')} onChange={e => handleFields(e, 'placeholder', index)} />
                                                                                </Grid>
                                                                                <Grid xs={12} item md={4}>
                                                                                    <FormControl fullWidth>
                                                                                        <InputLabel>{i18n.t('sprint.actionChecklist.edit.type')}</InputLabel>
                                                                                        <Select
                                                                                            size='small'
                                                                                            value={field.type}
                                                                                            label={i18n.t('sprint.actionChecklist.edit.type')}
                                                                                            onChange={e => handleFields(e, 'type', index)}
                                                                                        >
                                                                                            <MenuItem value='text'>{i18n.t('sprint.actionChecklist.edit.text')}</MenuItem>
                                                                                            <MenuItem value={'textarea'}>{i18n.t('sprint.actionChecklist.edit.textArea')}</MenuItem>
                                                                                            <MenuItem value={'number'}>{i18n.t('sprint.actionChecklist.edit.count')}</MenuItem>
                                                                                            <MenuItem value={'money'}>{i18n.t('sprint.actionChecklist.edit.money')}</MenuItem>
                                                                                            <MenuItem value={'select'}>{i18n.t('sprint.actionChecklist.edit.uniqueList')}</MenuItem>
                                                                                            <MenuItem value={'multiselect'}>{i18n.t('sprint.actionChecklist.edit.multipleList')}</MenuItem>
                                                                                            <MenuItem value={'url'}>{i18n.t('sprint.actionChecklist.edit.url')}</MenuItem>
                                                                                            <MenuItem value={'date'}>{i18n.t('sprint.actionChecklist.edit.date')}</MenuItem>
                                                                                            <MenuItem value={'email'}>{i18n.t('sprint.actionChecklist.edit.email')}</MenuItem>
                                                                                            <MenuItem value={'radio'}>{i18n.t('sprint.actionChecklist.edit.radio')}</MenuItem>
                                                                                            <MenuItem value={'checkbox'}>{i18n.t('sprint.actionChecklist.edit.checkbox')}</MenuItem>
                                                                                            <MenuItem value={'attachment'}>{i18n.t('sprint.actionChecklist.edit.attachment')}</MenuItem>
                                                                                        </Select>
                                                                                    </FormControl>
                                                                                </Grid>
                                                                                <Grid xs={12} item md={4}>
                                                                                    <FormControl fullWidth>
                                                                                        <InputLabel>{i18n.t('sprint.actionChecklist.edit.condition')}</InputLabel>
                                                                                        <Select
                                                                                            size='small'
                                                                                            value={field.parentName || ''}
                                                                                            label={i18n.t('sprint.actionChecklist.edit.condition')}
                                                                                            onChange={e => handleFields(e, 'parentName', index)}
                                                                                        >
                                                                                            <MenuItem value={''}>{i18n.t('sprint.actionChecklist.edit.noCondition')}</MenuItem>
                                                                                            {data.fields.filter(e => e.title !== field.title && ['select', 'multiselect', 'radio'].includes(e.type)).map((item, index) => (
                                                                                                <MenuItem key={index} value={item.title}>{item.title}</MenuItem>
                                                                                            ))}
                                                                                        </Select>
                                                                                    </FormControl>
                                                                                </Grid>
                                                                                {
                                                                                    field.parentName && <Grid item xs={12} md={4}>
                                                                                        <FormControl fullWidth>
                                                                                            <InputLabel>{i18n.t('sprint.actionChecklist.edit.valueCondition')}</InputLabel>
                                                                                            <Select
                                                                                                multiple
                                                                                                size='small'
                                                                                                value={field.parentValue}
                                                                                                label={i18n.t('sprint.actionChecklist.edit.valueCondition')}
                                                                                                onChange={e => handleFields(e, 'parentValue', index)}
                                                                                            >
                                                                                                {data.fields.find(e => e.title === field.parentName)?.options?.map((item, index) => (
                                                                                                    <MenuItem key={index} value={item}>{item}</MenuItem>
                                                                                                ))}
                                                                                            </Select>
                                                                                        </FormControl>
                                                                                    </Grid>
                                                                                }

                                                                                <Grid item md={4}>
                                                                                    <FormControlLabel control={<Checkbox checked={field.required} onChange={e => handleFields(e, 'required', index)} />} label={i18n.t('sprint.actionChecklist.edit.mandatory')} />
                                                                                </Grid>

                                                                                {field.type === 'select' || field.type === 'multiselect' || field.type === 'radio' ? (
                                                                                    <Grid item xs={12}>
                                                                                        <p><b>{i18n.t('sprint.actionChecklist.edit.options')}</b></p>
                                                                                        <Grid spacing={2} container rowSpacing={2}>
                                                                                            {field?.options && field?.options?.map((option, indexOpt) =>
                                                                                                <Grid item xs={4}>
                                                                                                    <TextField
                                                                                                        size='small' InputProps={{
                                                                                                            endAdornment: <InputAdornment style={{ cursor: 'pointer' }} onClick={() => {
                                                                                                                const aux = field?.options
                                                                                                                aux.splice(indexOpt, 1)
                                                                                                                handleFields(aux, 'options', index)
                                                                                                            }} position="end">x</InputAdornment>,
                                                                                                        }} value={option} onChange={(e) => handleOptions(e, index, indexOpt)} fullWidth />
                                                                                                </Grid>
                                                                                            )}
                                                                                        </Grid>

                                                                                        <Button sx={{ mt: 1 }} onClick={() => {
                                                                                            const aux = field?.options
                                                                                            aux.push("")
                                                                                            handleFields(aux, 'options', index)
                                                                                        }}>{i18n.t('sprint.actionChecklist.edit.addOption')}</Button>
                                                                                    </Grid>
                                                                                ) : <></>}
                                                                            </Grid>
                                                                        </Typography>
                                                                    </AccordionDetails>
                                                                </Accordion>
                                                            )}
                                                        </Draggable>
                                                    )
                                                })}
                                                {provided.placeholder}
                                            </div>
                                        }}
                                    </Droppable>
                                </DragDropContext>
                                <Button sx={{ mt: 2 }} className='saveButton' onClick={addField} fullWidth><Icon style={{ marginRight: '10px' }} icon="material-symbols:add-circle" width="20px" />{i18n.t('sprint.actionChecklist.edit.addField')}</Button>
                                <Grid container spacing={2} style={{ marginTop: '30px' }}>
                                    <Grid item xs={6}>
                                        <Button onClick={save} className='saveButton' fullWidth>{add ? i18n.t('sprint.actionChecklist.edit.add2') : i18n.t('sprint.actionChecklist.edit.attachment')}</Button>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Button onClick={() => {
                                            !add && deleteChecklist(data._id)
                                            setAdd(false)
                                            setData({})
                                        }} className='cancelButton' fullWidth>{add ? i18n.t('sprint.actionChecklist.edit.cancel') : i18n.t('sprint.actionChecklist.edit.delete')}</Button>
                                    </Grid>
                                </Grid>
                            </>
                            : <>{i18n.t('sprint.actionChecklist.edit.selectItem')}</>}
                    </Container>
                </Grid>
            </Grid>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={openModal}
                onClose={() => setOpenModal(false)}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
            >
                <Fade in={openModal}>
                    <Box sx={style}>
                        <TextField fullWidth label={i18n.t('sprint.actionChecklist.edit.searchIcon')} value={searchIcon} onChange={e => handleSearchIcons(e.target.value)} />
                        <Grid textAlign='center' container spacing={1} rowSpacing={3} style={{ marginTop: '5px' }}>
                            {icons.map((icon, index) => (
                                <Grid item key={index} xs={6} sm={4} md={1} lg={1} xl={1}>
                                    <Icon
                                        icon={icon}
                                        width="30px"
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => setData({ ...data, icon })}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    </Box>
                </Fade>
            </Modal>
        </div>
    );
};

const mapStateToProps = state => ({
    checklists: state.checklist.checklists
})


const mapDispatchToProps = (dispatch) => {
    return {
        getChecklists: () => dispatch(getActionChecklists()),
        handleAddChecklist: (data) => dispatch(handleAddActionChecklist(data)),
        getChecklist: (id) => dispatch(getActionChecklist(id)),
        deleteChecklist: (id) => dispatch(deleteActionChecklist(id)),
        handleUpdateChecklist: (data) => dispatch(handleUpdateActionChecklist(data)),
        handleUpdateChecklistOrder: (data) => dispatch(handleUpdateActionChecklistOrder(data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Edit);
