import {
  Box,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TableCell,
  Paper,
  TableBody,
  Checkbox,
  Chip,
  Button,
  CircularProgress,
  Icon,
  Divider,
  IconButton,
  Menu,
  useMediaQuery,
  Collapse,
  Avatar,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import ArrowDownwardOutlinedIcon from '@mui/icons-material/ArrowDownwardOutlined';
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import { toast } from "react-toastify";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import moment from "moment";
import { repository } from "../../../config";
import { i18n } from "../../../translate/i18n";

const columns = [
  {
    id: "jiraCode",
    label: i18n.t("support.hourTracking.hoursTable.taskCode"),
    sx: {
      width: "30%",
    }
  },
  {
    id: "aggregateTimeSpent",
    label: i18n.t("support.hourTracking.hoursTable.quantityHours"),
    sx: {
      width: "30%",
    },
  },
  {
    id: "title",
    label: i18n.t("support.hourTracking.hoursTable.title"),
    sx: {
      width: "30%",
    },
  },
  {
    id: "control",
    label: "",
    sx: {
      width: '10%',
      minWidth: "25px",
    },
  },
];

const getTotalTime = (time, date) => {
  const totalSeconds = time?.reduce((acc, el) => {
    if (el.date === date) {
      acc += el.timeSpentSeconds;
    }
    return acc;
  }, 0);
  const duration = moment.duration(totalSeconds, 'seconds');
  const hours = duration.hours();
  const minutes = duration.minutes();
  return `${hours}h ${minutes}m`;
}

const Row = ({data, i, handleClick, isSelected, date}) => {
  const labelId = `enhanced-table-checkbox-${i}`;
  const [open, setOpen] = useState(false);
  const [time, setTime] = useState(getTotalTime(data.history, date));
  const isItemSelected = isSelected(data);

  useEffect(() => {
    setTime(getTotalTime(data.history, date));
  }, [data, date]);
  return (
    <>
      <TableRow key={data._id} role="checkbox" tabIndex={-1}>
        <TableCell padding="checkbox">
          <Checkbox
            color="backlog"
            onClick={(event) => handleClick(event, data)}
            checked={isItemSelected}
            inputProps={{
              "aria-labelledby": labelId,
            }}
            />
        </TableCell>
        {columns.map((column) => {
          let value = data[column.id];
          return  column.id === "control" ? (
            <TableCell key={column.id} sx={column.sx}>
              <Box display="flex" justifyContent="flex-end">
                <Box
                  border={"1px solid #babfc3"}
                  borderRadius={"5px"}
                  height={"2rem"}
                  width={"2rem"}
                  >
                  <Box position={"relative"}>
                    <KeyboardArrowDownIcon
                      onClick={()=>setOpen((prev) => !prev)}
                      sx={{
                        cursor: "pointer",
                        color: "black",
                        opacity: 0.56,
                        borderRadius: "5px",
                        width: "100%",
                        height: "2rem",
                      }}
                      />
                  </Box>
                </Box>
              </Box>
            </TableCell>
          ) : (column.id === "jiraCode" && value) ? (
            <TableCell key={column.id} sx={column.sx}>
              <Box display={"flex"} gap={1} justifyContent={"start"} alignItems={'center'}>
                <Chip label={`#${value}`} color="backlog" size="small"/>
                <Avatar src={data?.customer?.fileLocation ? data?.customer?.fileLocation : `${repository}/files/customers/${data?.customer?.fileName}`} />
              </Box>
            </TableCell>
          ) : column.id === "aggregateTimeSpent" ? (
            <TableCell key={column.id} sx={column.sx}>
              {time}
            </TableCell>
          ) : (
            <TableCell key={column.id} sx={column.sx}>
              {value}
            </TableCell>
          );
        })}
      </TableRow>
      <TableRow>
      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <Box sx={{ margin: 1 }}>
            <Typography variant="h6" gutterBottom component="div">
              {i18n.t("support.hourTracking.hoursTable.timeRecord")}
            </Typography>
            <Table size="small" aria-label="purchases">
              <TableHead>
                <TableRow>
                  <TableCell>{i18n.t("support.hourTracking.hoursTable.date")}</TableCell>
                  <TableCell>{i18n.t("support.hourTracking.hoursTable.comment")}</TableCell>
                  <TableCell>{i18n.t("support.hourTracking.hoursTable.hours")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.history?.filter(el => el.date === date)?.map((historyRow) => (
                  <TableRow key={historyRow.id}>
                    <TableCell component="th" scope="row">
                      {moment(historyRow.created).format("DD/MM/YYYY")}
                    </TableCell>
                    <TableCell>{historyRow?.comment}</TableCell>
                    <TableCell>{historyRow.timeSpent}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </Collapse>
      </TableCell>
    </TableRow>
  </>
  );
};
const HoursTable = (props) => {
  const isMobileDevice = useMediaQuery('(max-width:600px)');
  const [deleteId, setDeleteId] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [numSelected, setNumSelected] = useState(0);
  
  const toggleModal = (params = "") => {
    setDeleteId(params);
    setOpenModal((prev) => !prev);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = props.data?.map((n) => n);
      props.setSelected(newSelected);
      return;
    }
    props.setSelected([]);
  };

  const handleClick = (event, problems) => {
    const selectedIndex = props.selected.indexOf(problems);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(props.selected, problems);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(props.selected.slice(1));
    } else if (selectedIndex === props.selected.length - 1) {
      newSelected = newSelected.concat(props.selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        props.selected.slice(0, selectedIndex),
        props.selected.slice(selectedIndex + 1)
      );
    }

    props.setSelected(newSelected);
  };

  const isSelected = (problems) => props.selected.indexOf(problems) !== -1;

  useEffect(() => {
    setNumSelected(props.selected?.length);
  }, [props.selected]);

  return (
    <>
      <Paper id='data-table'>
        <TableContainer>
          <Table stickyHeader>
            <TableHead sx={{ position: "sticky", top: 0, zIndex: 5 }}>
              <TableRow>
                <TableCell padding="checkbox" sx={{ background: "#F0F0F0" }}>
                  <Checkbox
                    color="backlog"
                    indeterminate={
                      numSelected > 0 && numSelected < props.data?.length
                    }
                    checked={
                      props.data?.length > 0 && numSelected === props.data?.length
                    }
                    onChange={handleSelectAllClick}
                    inputProps={{
                      "aria-label": "select all desserts",
                    }}
                  />
                </TableCell>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                    sx={{ bgcolor: "#F0F0F0" }}
                  >
                    <Box
                      display={"flex"}
                      gap={1}
                    >
                      {column.id === "jiraCode" && 
                      <IconButton sx={{padding: 0}}>
                          <ArrowDownwardOutlinedIcon 
                            onClick={() => props.setSortDirection((prev) => -prev)}
                          />
                          </IconButton>
                        }
                      <Typography fontWeight={"bold"}>
                      {(column.id === "jiraCode" && isMobileDevice) ? "Código" : column.label}
                      </Typography>
                    </Box>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {props.data?.map((data, i) => <Row key={data._id} data={data} i={i} handleClick={handleClick} isSelected={isSelected} date={props.date}/>)}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <Modal toggle={() => toggleModal()} isOpen={openModal}>
        <Box 
          sx={{
            display: "flex", 
            justifyContent: "space-between", 
            padding: 2, 
            alignItems: "center"
          }}
          >
          <Typography variant="h3">{i18n.t("support.hourTracking.hoursTable.deleteAction")}</Typography>
          <Icon
            sx={{
              color: "#86888c",
              cursor: "pointer",
            }}
            onClick={() => setOpenModal(false)}
            className="material-icons-outlined"
            fontSize="large"
          >
            cancel
          </Icon>
        </Box>
        <Divider />
        <ModalBody>
          <Typography height={"5vh"}>{i18n.t("support.hourTracking.hoursTable.confirmDelete")}</Typography>
        </ModalBody>
        <ModalFooter>
          <Box display={"flex"} gap={1}>
            <Button variant="contained" className="btn-reprove" onClick={() => {}}>
              {loading ? (
                  <CircularProgress size={25} sx={{ color: "white" }} thickness={4} />
                ) : (
                  <>{i18n.t("support.hourTracking.hoursTable.delete")}</>
                )}
            </Button>
            <Button variant="contained" className="btn-gray" onClick={() => toggleModal()}>
              {i18n.t("support.hourTracking.hoursTable.cancel")}
            </Button>
          </Box>
        </ModalFooter>
      </Modal>
    </>
  );
};
const mapStateToProps = (state) => ({
  user: state.auth.user,
  customer: state.customer.customerDetails
});

export default connect(mapStateToProps)(HoursTable);
