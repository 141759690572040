import { Avatar, Box, Button, Icon, Typography } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { Modal } from 'reactstrap'
import { i18n } from '../../../../translate/i18n'
import { statusColor } from '../../utils/statusColor'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { handleChangeCategory, handleChangeDescription, handleChangeName, handleLabelActivityStatus } from '../../../../translate/translates/Translates'
import { api } from '../../../../config'
import favicon from "../../../../assets/favicon.png";
import ChatInput from '../../../../components/Chat/ChatInput'
import { connect } from 'react-redux'
import * as actions from '../../../../store/actions/messages'
import Message from '../../../../components/Chat/Message'
import { isMobile } from 'react-device-detect';
import MessageDeleteModal from '../../../../components/Chat/utils/MessageDeleteModal'
import { FCChip, FCSelect } from 'components-fullcomm'

const DescriptionItem = ({ label, value }) => {
  if (!value) return (<></>);
  return (
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <Typography>{label}:</Typography>
      <Typography>{value}</Typography>
    </Box>
  )
}

const ActivityModal = ({open, action, activityData, setOpen, updateActivityStatus, user, sendMessage, getMessages, messages, updateMessage, localEditMessage, customer, deleteMessage}) => {
  const history = useHistory()
  const activity = {...activityData?.activity, ...activityData};
  const userImg = activity.responsible === "FullPRO" ? favicon : activity.userResponsible ? activity.userResponsible.fileLocation ? activity.userResponsible.fileLocation : `${api}/files/users/${activity.userResponsible.fileName}` : customer.fileLocation ? customer.fileLocation : `${api}/files/customers/${customer?.fileName}`
  const [status, setStatus] = useState(activity?.status);
  const [inputValue, setInputValue] = useState("");
  const [localMessages, setLocalMessages] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [deleteMessageId, setDeleteMessageId] = useState(null);
  const messagesRef = useRef(null);
  const options = {
    "Backlog": ["Backlog", "Para fazer"],
    "Para fazer": ["Backlog", "Para fazer", "Em andamento"],
    "Em andamento": ["Para fazer", "Em andamento", "Homologação", "Concluído/Aplicado"],
    "Homologação": ["Homologação"],
    "Concluído/Aplicado": ["Concluído/Aplicado"],
  };

  const handleInputChange = (text) => {
    setInputValue(text);
  };

  const handleDeleteMessage = async (messageId) => {
    const response = await deleteMessage(messageId);
    setLocalMessages(prev => prev.map(message => message._id === messageId ? response.messageData : message))
    return response;
  }

  // const handleUpdateMessage = async (messageId, text) => {
  //   const response = await updateMessage(messageId, text);
  //   setLocalMessages(prev => prev.map(message => message._id === messageId ? response.messageData : message))
  //   return response;
  // }

  const handleSendMessage = async (event, files, taggedUsers) => {
    event.preventDefault();
    if (inputValue.trim() === "" && files.length === 0) return;
    const formData = new FormData();
    files.forEach((file) => {
      formData.append("files", file);
    });
    formData.append("text", inputValue);
    formData.append("activity", activity._id);
    formData.append("platform", "action");
    if (taggedUsers) formData.append("taggedUsers", taggedUsers);
    const newMessage = await sendMessage(formData, action.customerId);

    setLocalMessages(prev => ([...prev, newMessage]));
    setInputValue("");
  };

  useEffect(() => {
    if (messages) {
      setLocalMessages(messages);
    }
  }, [messages]);

  useEffect(() => {
    if (open) getMessages({ platform: "action", customer: action.customerId, activity: activity._id });
    else setLocalMessages([]);
  }, [open]);

  useEffect(() => {
    if (messagesRef.current) {
      messagesRef.current.scrollTop = messagesRef.current.scrollHeight;
    }
  }, [localMessages]);

  return (
    <Modal
      isOpen={open}
      centered={true}
      size="lg"
      toggle={() => setOpen(false)}
    >
      <MessageDeleteModal open={openModal} setOpen={setOpenModal} deleteMessage={handleDeleteMessage} id={deleteMessageId} setDeleteId={setDeleteMessageId} />
      <Box padding="1rem">
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="bold" fontSize="12px"><b>{action?.name}</b></Typography>
          <Icon
            sx={{
              color: "#86888c",
              cursor: "pointer",
            }}
            onClick={() => setOpen(false)}
            className="material-icons-outlined"
            fontSize="large"
          >
            cancel
          </Icon>
        </Box>
        <Box display="flex" gap={isMobile ? 0 : "1rem"} flexDirection={isMobile ? "column-reverse" : "row"}>
          <Box width={isMobile ? "100%" : "65%"}>
            {
              !isMobile && (
                <Typography variant="h6" fontWeight="400">{activity.repeat > 1 && <b>{activity.repeat}x </b>}{handleChangeName(activity?.name)}</Typography>
              )
            }
            <Box sx={{ padding: "0.5rem", mt: "1.5rem" }}>
              <Typography>{handleChangeDescription(activity?.description)}</Typography>
              {
                activity?.descriptions?.map((item, index) => (
                  item?.map((description, i) => (
                    description?.title && <Typography key={index}>{description.title}: {description.value}</Typography>
                  ))
                ))
              }
              <Box mt="2.5rem">
                <Typography variant='caption'>{i18n.t("sprint.kanban.comments")}:</Typography>
                <Box maxHeight="25rem" overflow="auto" ref={messagesRef}>
                  {
                    localMessages?.map((message, index) => (
                      <Message
                      key={message._id} 
                      index={index} 
                      message={message} 
                      user={user} 
                      updateMessage={localEditMessage}
                      setOpenModal={setOpenModal}
                      setDeleteMessageId={setDeleteMessageId}
                      />
                      )
                    )
                  }
                </Box>
                <Box 
                  sx={{
                    mt: "0.5rem",
                    "& > *:first-child": {
                      padding: "0 !important",
                    },
                  }}
                  >
                  <ChatInput 
                    customer={action?.customerId}
                    handleInputChange={handleInputChange}
                    handleSendMessage={handleSendMessage}
                    // updateMessage={handleUpdateMessage}
                    platform={null}
                    tagging={false}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
          <Box width={isMobile ? "100%" :  "35%"}>
            {
              isMobile && (
                <Typography variant="h6" fontWeight="400" mb="1rem">{activity.repeat > 1 && <b>{activity.repeat}x </b>}{activity?.name}</Typography>
              )
            }
              <FCSelect
                fullWidth
                disabled={activity?.responsible === "FullPRO" || user?.role === "customer"}
                sx={{mb: "0.5rem"}}
                label={i18n.t("sprint.newAction.pages.revision.status")}
                value={status}
                onChange={async (e) => {
                  const data = e.target.value;
                  const res = await updateActivityStatus(activity._id, data, status);
                  if (res) setStatus(data);
                  if (data === "Concluído/Aplicado" || data === "Homologação") setOpen(false);
                }}
                renderValue={() => {
                  const { type } = statusColor(status);
                  return (
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                      {status && (
                        <FCChip
                          size="small"
                          color={type}
                          label={handleLabelActivityStatus(status)}
                        />
                      )}
                    </Box>
                  );
                }}
                options={options[status]?.map((option) => ({ value: option, label: handleLabelActivityStatus(option) }))}
              />
            <Box display="flex" flexDirection="column" gap="0.5rem" padding="0.5rem" border="solid 1px #ccc" borderRadius="5px">
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography>{i18n.t('sprint.newAction.pages.activities.activityAccordion.responsible')}:</Typography>
                <Box display="flex" alignItems="center" gap="0.25rem">
                  <Typography>{activity?.responsible === "FullPRO" ? "FullPRO" : activity?.userResponsible?.name ? activity?.userResponsible?.name : activity.responsible}</Typography>
                  {
                    userImg && (
                      <Avatar sx={{height: "24px", width: "24px"}} src={userImg} />
                    )
                  }
                </Box>
                </Box>
              <DescriptionItem label={i18n.t("sprint.newAction.pages.revision.category")} value={handleChangeCategory(activity?.category)} />
              {
                action?.campaign && (
                  <Box display="flex" alignItems="center" justifyContent="space-between">
                    <Typography>{i18n.t("sprint.newAction.pages.revision.campaign")} </Typography>
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        color: "#FFFFFF",
                        width: "fit-content",
                        background: "#352c6342",
                        borderRadius: "5px",
                        padding: "0.25rem 0.5rem",
                      }}
                    >
                      {action.campaign?.name}
                    </Typography>
                  </Box>  
                )
              }
            </Box>
            {
              status === "Em andamento" && activity.reproved && (user.role === "admin" || activity.responsible !== "FullPRO") && <Button className='btn-action large' variant="contained" sx={{ mt: "0.5rem" }} onClick={() => {history.push(`/kanban/reproved-activity/${action._id}/${activity._id}`)
            }}>{i18n.t("sprint.kanban.viewReproval")}</Button>
            }
            {
              status === "Backlog" && <Button className='btn-action medium' variant="contained" sx={{ mt: "0.5rem" }} onClick={() => {updateActivityStatus(activity._id, "Para fazer", "Backlog"); setOpen(false)}}>{i18n.t("sprint.kanban.accept")}</Button>
            }
            {
              status === "Backlog" && <Button className='btn-gray medium' variant="contained" sx={{ mt: "0.5rem", float: "right" }} onClick={() => {updateActivityStatus(activity._id, "refused"); setOpen(false)}}>{i18n.t("sprint.kanban.sendTo")} FullPRO</Button>
            }
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}

const mapStateToProps = (state) => ({
  messages: state.message.messages || [],
  customer: state.customer.customerDetails,
});

export default connect(mapStateToProps, actions)(ActivityModal)