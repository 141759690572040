import { Box, TableCell, TableRow, Typography } from "@mui/material";
import React from "react";
import { statusColor } from "../../utils/statusColor";
import { isDateToday } from "../../utils/isDateToday";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { isMobile } from "react-device-detect";
import { FCChip } from "components-fullcomm";

const GenttRow = ({ row, classes, dates, hidden }) => {
  const history = useHistory();
  const redirectUser = () => {
    if (row?._id) history.push(`/edit-action/${row._id}`);
  };

  if (row.startDate >= dates.length) return <></>;
  if (row.startDate > dates.length || row.endDate < 0) return <></>;
  return (
    <TableRow key={row._id} className={hidden ? classes.hiddenRow : classes.visibleRow}>
      {hidden === false ? (
        <TableCell onClick={redirectUser} sx={{ zIndex: 1, paddingLeft: "2rem" }} className={classes.taskCell}>
          <Typography className={classes.titleCell}>{row.name}</Typography>
          {
            !isMobile && <FCChip label={row.status} color={statusColor(row.status).type} size="small"/>
          }
        </TableCell>
      ) : (
        <TableCell onClick={redirectUser} className={classes.taskCell}>
          <Typography className={classes.titleCell}>{row.name}</Typography>
          {
            !isMobile && <FCChip label={row.status} color={statusColor(row.status).type} size="small"/>
          }
        </TableCell>
      )}
      {dates.map((date, i) => {
        const isExecutionCell = row.executionDate <= i && row.startDate >= i;
        const isProgressCell = row.startDate <= i && row.endDate >= i;
        const isToday = isDateToday(date);
        const execClasses = [classes.executionCell];
        const boxClasses = [classes.progressCell];
        const cellClasses = [classes.dateCell];
        const options = {
          year: "2-digit",
          month: "2-digit",
          day: "2-digit",
        };
        if (row.executionDate === i) execClasses.push(classes.startExecCell)
        if (row.startDate === i) boxClasses.push(classes.startCell);
        if (row.endDate === i) boxClasses.push(classes.endCell);
        return (
          <TableCell key={`${row.name}-${date}-${i}`} className={cellClasses.join(" ")} onClick={redirectUser}>
            {isProgressCell && (
              <Box
                backgroundColor={statusColor(row.status).bgcolor}
                className={boxClasses.join(" ")}
              >
                {row.startDate === i && (
                  <Typography
                    sx={{
                      position: "absolute",
                      right: "150%",
                      top: "20%",
                      display: "none",
                      color: "#67696D",
                    }}
                  >
                    {date.toLocaleString("pt-BR", options)}
                  </Typography>
                )}
                {row.endDate === i && (
                  <Typography
                    sx={{
                      position: "absolute",
                      left: "150%",
                      top: "20%",
                      display: "none",
                      color: "#67696D",
                    }}
                  >
                    {date.toLocaleString("pt-BR", options)}
                  </Typography>
                )}
              </Box>
            )}
            {isToday && <Box className={classes.todayCell} />}
            {isExecutionCell && row.executionDate !== row.startDate && (
              <Box
                className={execClasses.join(" ")}
              />
            )}
          </TableCell>
        );
      })}
    </TableRow>
  );
};

export default GenttRow;
