const tasks_reduce = (state = {}, actions) => {
    switch(actions.type){
        case "GET_TASKS":
            return{
                ...state,
                tasks: actions.payload.tasks
            }
        case "GET_TASK":
            return{
                ...state,
                taskDetails: actions.payload.task
            }
        case "CLEAR_TASK":
            return{
                ...state,
                taskDetails: null
            }
        case "CLEAR_TASKS":
            return{
                ...state,
                tasks: null
            }
        default: 
            return state;
    }
}

export default tasks_reduce;