import React from 'react';
import { TableBody, Paper, Table, TableContainer, TableHead, TableRow, TableCell } from '@mui/material';
import { dateLocaleString } from '../../utils/String';
import { i18n } from '../../translate/i18n';

const ShippingEvents = ({ shippingEvents }) => {

    return (
        <TableContainer component={Paper} sx={{ marginTop: '16px' }}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>{i18n.t('components.platinumlog.shippingEvents.description')}</TableCell>
                        <TableCell>{i18n.t('components.platinumlog.shippingEvents.start')}</TableCell>
                        <TableCell>{i18n.t('components.platinumlog.shippingEvents.end')}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {shippingEvents.map((event, index) => (
                        <TableRow key={index} sx={{
                            backgroundColor: (index % 2 === 0) ? '#F3F3F3' :
                                '',
                        }}>
                            <TableCell>{event.info}</TableCell>
                            <TableCell>{dateLocaleString(event.date)}</TableCell>
                            <TableCell>{dateLocaleString(event.final)}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default ShippingEvents;