import { Icon, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { statusColor } from "../utils/statusColor";
import BugReportIcon from '@mui/icons-material/BugReport';
import { FCChip } from "components-fullcomm";

const ProblemCard = ({ problem, status }) => {
  const { bgcolor } = statusColor(status);

  return (
    <Box
      key={problem?._id}
      sx={{
        minHeight: "4rem",
        padding: "1rem",
        paddingX: "3%",
        backgroundColor: "white",
        borderRadius: "5px",
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        gap: 1,
        borderBottom: `solid 3px ${bgcolor}`,
      }}
    >
      <Box>
        <Typography>{problem?.title}</Typography>
        <Box display={"flex"} gap={1} alignItems={"center"} width="100%" justifyContent={problem.reproved && problem.status === "Em andamento" ? "space-between" : "flex-end"}>
            {problem.reproved && problem.status === "Em andamento" && (
              <FCChip
              color={statusColor("Reprovado").type}
              size={"small"}
              label={"Reprovado"}
              sx={{width: "fit-content"}}
              icon={
                <Icon
                className="material-icons-outlined"
                style={{
                  fontSize: "10px",
                }}
                  >
                    {statusColor("Reprovado").icon}
                  </Icon>
                }
                />
            )}
            <BugReportIcon color="backlog" fontSize="small" />
          </Box>
      </Box>
    </Box>
  );
};


export default ProblemCard