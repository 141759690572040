import axios from 'axios';
import {api} from '../../config'
import {getHeaders} from './localStorage'
import errorHandling from './errorHandling'
import {queryString} from '../../utils/String'

export const getUsers = (params) => async (dispatch) => {
    try {
        const response = await axios.get(api + `/users?${queryString(params)}`, getHeaders());
        dispatch({ type: "GET_USERS", payload: response.data });
    } catch (err) {
        return errorHandling(err);
    }
}

export const getUsersShortInfo = ({name, customer, platform}) => async (dispatch) => {
    try {
        const response = await axios.get(api + `/users-customer/${customer}?name=${name}${platform && '&platform=' + platform}`, getHeaders());
        dispatch({ type: "GET_USERS", payload: response.data });
        return response.data;
    } catch (err) {
        return errorHandling(err);
    }
}

export const getUsersSpecific = (spec, id, pageCurrent, limit) => async (dispatch) => {
    try {
        const response = await axios.get(api + `/users/${spec}/${id}/?page=${pageCurrent}&limit=${limit}`, getHeaders());
        dispatch({ type: "GET_USERS", payload: response.data });
    } catch (err) {
        return errorHandling(err);
    }
}

export const deleteUser = (_id, users) => async (dispatch) => {
    try {
        const response = await axios.delete(api+"/users/" + _id, getHeaders());
        return response.data;
    } catch (err) {
        return errorHandling(err);
    }
}

export const getViewUser = (id) => async (dispatch) => {
    try {
        const response = await axios.get(`${api}/users/${id}`, getHeaders());
        dispatch({ type: 'GET_USER', payload: response.data })
    } catch (error) {
        return errorHandling(error);
    }
}

export const handleAddUser = (props) => async () => {
    try {
        const response = await axios.post(api+"/users", props, getHeaders());
        return response.data;
    } catch (err) {
        return errorHandling(err);
    }
}

export const handleTrialAddUser = (props) => async () => {
    try {
        const response = await axios.post(api+"/users/trial", props, getHeaders());
        return response.data;
    } catch (err) {
        return errorHandling(err);
    }
}

export const updateTrialUser = (props) => async () => {
    try {
        const response = await axios.put(api+"/users/trial", props, getHeaders());
        return response.data;
    } catch (err) {
        return errorHandling(err);
    }
}

export const handleUpdateUser = (props) => async () => {
    try {
        const response = await axios.put(api+"/users", props, getHeaders());
        return response.data;
    } catch (err) {
        return errorHandling(err);
    }
}

export const handleUpdatePerfil = (props, callback) => {
    return function(dispatch){
        axios.put(api+"/perfil", props, getHeaders()).then((response) => {
            callback({error: response.data});
        }).catch((err) => callback(errorHandling(err)))
    }
}


export const clearUser = () => {
    return function(dispatch){
        dispatch({type: "CLEAR_USER"})
    } 
}

export const clearUsers = () => {
    return function(dispatch){
        dispatch({type: "CLEAR_USERS"})
    } 
}

export const viewPerfil = () => {
    return function(dispatch){
            axios.get(api+"/perfil", getHeaders()).then((response) => {
                dispatch({type: "LOGIN_USER", payload: response.data})
            }).catch((err) => {
                dispatch({type: "LOGOUT_USER"})
            })
        }
}

export const handleUpdatePerfilPhoto = (props, callback) => {
    return function(){
        axios.put(api+"/perfil-img", props, getHeaders()).then((response) => {
            callback({error: response.data});
        }).catch((err) => callback(errorHandling(err)))
    }
}

export const recoveryPass = (props, callback) => {
    return function(){
        axios.post(api+"/recovery-pass", props, getHeaders()).then((response) => {
            callback({error: response.data});
        }).catch((err) => callback(errorHandling(err)))
    }
}

export const validKeyRecovery = (key, callback) => {
    return function(){
        axios.get(api + `/recovery-pass/${key}`).then((response) => {            
            callback({error: response.data})
        }).catch((err) => {
            callback(errorHandling(err))
        })
    }
}

export const updateUserPassRecovery = (props, callback) => {
    return function(){
        axios.put(api+"/recovery-pass", props).then((response) => {
            callback({error: response.data});
        }).catch((err) => callback(errorHandling(err)))
    }
}

export const updateUserPermissions = (props) => async () => {
    try {
        const response = await axios.put(api+"/users/permissions", props, getHeaders());
        return response.data;
    } catch (err) {
        return errorHandling(err);
    }
}

export const muteChatChannel = (props) => async (dispatch) => {
    try {
        const response = await axios.put(api+"/user/mute-chat-channel", props, getHeaders());
        dispatch({ type: 'UPDATE_USER', payload: response.data })
        return response.data;
    } catch (err) {
        return errorHandling(err);
    }
}