import React from "react";
import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { connect } from "react-redux";
import * as actions from "../../../store/actions/action";
import { toast } from "react-toastify";
import { i18n } from '../../../translate/i18n';
import { Typography } from "@mui/material";
 
const filter = createFilterOptions();

const FreeSoloCreateOption = (props) => {

  return (
    <div style={{ width: '100%' }}>
      <Typography variant='body2' mb='0.5rem' color={'#000'}>{i18n.t('permission.permissionModels.freeSoloCreateOption.permissionsModel')}</Typography>
      <Autocomplete
        size="small"
        fullWidth
        disabled={props.disabled}
        value={props.selectedModel}
        onChange={async (event, newValue) => {
          try {
            if (typeof newValue?.inputValue === 'string') {
              props.handleModelChange({name: newValue.inputValue, roles: ["admin"]});
            } else if (newValue && newValue?.inputValue) {
              props.handleModelChange(newValue.inputValue);
            } else {
              props.handleModelChange(newValue);
              props.setIsNewModel(newValue === null);
            }
          } catch (error) {
            toast.error(error.message);
          }
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);

          const { inputValue } = params;
          // Suggest the creation of a new value
          const isExisting = options.some((option) => inputValue === option.name);
          if (inputValue !== '' && !isExisting) {
            filtered.push({
              inputValue,
              name: `${i18n.t('permission.permissionModels.freeSoloCreateOption.createNewModel')} "${inputValue}"`,
            });
          }

          return filtered;
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        id="free-solo-with-text"
        options={props.permissionModels}
        getOptionLabel={(option) => {
          // Value selected with enter, right from the input
          if (typeof option.name === 'string') {
            return option.name;
          }
          // Add "xxx" option created dynamically
          if (option.inputValue) {
            return option.inputValue;
          }
          // Regular option
          return '';
        }}
        renderOption={(props, option) => <li {...props}>{option.name}</li>}
        freeSolo
        renderInput={(params) => (
          <TextField 
            {...params}
            sx={{
              '& .MuiInputBase-root': {
                border: `0.5px solid #888888`,
                boxShadow: '0px 1px 0px 0px #00000040 inset',
                borderRadius: '8px',
              },
              '& fieldset': {
                border: 'none',
              },
            }}
          />
        )}
      />
    </div>
  );
}

export default connect((state) => ({ campaigns: state.actions.campaigns, }), actions)(FreeSoloCreateOption);