import {
  Box,
  Divider,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import GenttRow from "./GenttRow";
import { connect } from "react-redux";
import * as actions from "../../../../store/actions/action";
import { useStyles } from "../styles";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import dayjs from "dayjs";
import "../../../../styles/dashboard/sprint/index.css";
import { isDateToday } from "../../utils/isDateToday";
import Filter from "./Filter";
import { calculateTimeArea } from "../../utils/calculateTimeArea";
import { i18n } from '../../../../translate/i18n';

const cycleFormatter = (cycle) => {
  let month;
  if (cycle) month = Number(cycle.charAt(0));
  if (month === 4) month = 9;
  if (month === 3) month = 6;
  if (month === 2) month = 3;
  if (month === 1) month = 0;
  const year = Number(cycle?.split(" ")[2]);
  return { month, year };
};

const getCurrentTrimesterStartMonth = () => {
  const currentMonth = new Date().getMonth();
  return [0, 3, 6, 9][Math.floor(currentMonth / 3)];
};

const dateRange = (date) => {
  const today = new Date(date);
  const start = new Date(today.getFullYear(), getCurrentTrimesterStartMonth() - 1, 1);
  const end = new Date(today.getFullYear(), getCurrentTrimesterStartMonth() + 4, 0);
  return [dayjs(start), dayjs(end)];
};

const Roadmap = (props) => {
  const classes = useStyles();

  const [dates, setDates] = useState([]);
  const [defaultRows, setDefaultRows] = useState([]);
  const [activeRows, setActiveRows] = useState([]);
  const [months, setMonths] = useState([]);
  const [range, setRange] = useState(dateRange(new Date()));
  const [collapse, setCollapse] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const getActions = () => {
    const trimester = range?.map((date) => date.toISOString().slice(0, 10));
    const params = new URLSearchParams({
      trimester,
    });
    setSubmitting(true);
    props.getActions(params);
  };

  useEffect(() => {
    getActions();
  }, [props.shop]);

  useEffect(() => {
    setSubmitting(false);
    const filteredArray =
      props.actions?.docs.filter((obj) => {
        if (obj.status === "Concluído") {
          if (obj?.cycle?.includes(props.cycle)) {
            return true;
          } else {
            return false;
          }
        } else {
          return true;
        }
      }) || [];
    const trimester = cycleFormatter(props.cycle);
    if (trimester) {
      const { dates, defaultRows, activeRows, months } = calculateTimeArea(
        filteredArray,
        range
      );
      setDates(dates);
      setDefaultRows(defaultRows);
      setActiveRows(activeRows);
      setMonths(months);
    }
  }, [props.actions, props.cycle]);

  return (
    <div>
      <Filter
        range={range}
        setRange={setRange}
        getActions={getActions}
        submitting={submitting}
      />
      <div style={{ overflowX: "auto" }}>
        <TableContainer
          sx={{
            marginTop: 2,
            paddingBottom: "1rem",
            minWidth: "1000px",
          }}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{ background: "#FAFAFA", border: "none", width: "20vw" }}
                />
                {months.map((month, i) => {
                  let monthCell = [classes.monthCell];
                  if (i === 0) monthCell = [classes.startMonth];
                  if (i === months.length - 1) monthCell = [classes.endMonth];
                  return (
                    <TableCell
                      key={month.name}
                      className={monthCell.join(" ")}
                      colSpan={month.days}
                    >
                      <Typography>{month.name}</Typography>
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
            {
              activeRows.length > 0 && (
                <>
                  <TableRow className={classes.visibleRow}>
                    <TableCell className={classes.dropdownCell}>
                      <Paper className={classes.paper}>
                        <Typography>{i18n.t('sprint.actionPlan.roadMap.activeActions')}</Typography>
                        <IconButton
                          aria-label="expand row"
                          size="small"
                          onClick={() => setCollapse(!collapse)}
                        >
                          {collapse ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                      </Paper>
                    </TableCell>
                    {dates.map((date, i) => {
                      const cellClasses = [classes.dateCell];
                      const isToday = isDateToday(date);
                      return (
                        <TableCell
                          key={`${date}-${i}`}
                          className={cellClasses.join(" ")}
                        >
                          {isToday && <Box className={classes.todayCell} />}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                  {activeRows.map((row) => (
                    <GenttRow
                      key={row._id}
                      row={row}
                      dates={dates}
                      classes={classes}
                      hidden={collapse}
                    />
                  ))}
                  {!collapse && <Divider />}
                </>
              )
            }

              {defaultRows?.map((row) => (
                <GenttRow key={row._id} row={row} dates={dates} classes={classes} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  shop: state.auth.shop,
  cycle: state.actions.cycle,
  actions: state.actions.actions,
});

export default connect(mapStateToProps, actions)(Roadmap);
