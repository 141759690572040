import axios from 'axios';
import {api} from '../../config'
import {getHeaders} from './localStorage'
import errorHandling from './errorHandling'

export const addProblem = (props) => async () => {
    try {
        const response = await axios.post(api + `/problems`,props , getHeaders());
        return response.data
    } catch (err) {
        return errorHandling(err);
    }
}

export const uploadProblemFile = (id, file) => async () => {
  try {
      const response = await axios.post(api + `/problems/files/${id}`,file , getHeaders());
      return response.data
  } catch (err) {
      return errorHandling(err);
  }
}

export const getProblems = (query, useDispatch = true) => async (dispatch) => {
  try {
      const response = await axios.get(api + `/problems/?${query}`, getHeaders());
      if (useDispatch) dispatch({ type: "GET_PROBLEMS", payload: response.data.problems });
      else return response.data.problems;
  } catch (err) {
      return errorHandling(err);
  }
}

export const updateProblem = (id, props) => async () => {
    try {
        const response = await axios.put(api + `/problems/${id}`,props , getHeaders());
        return response.data
    } catch (err) {
        return errorHandling(err);
    }
}

export const deleteProblemFile = (id, fileName) => async () => {
    try {
        const response = await axios.delete(api+`/problems-file/${id}/${fileName}`, getHeaders());
        return response.data;
    } catch (err) {
        return errorHandling(err);        
    }
}

export const deleteProblem = (params) => async () => {
    try {
        const response = await axios.delete(api + `/problems/${params}`, getHeaders());
        return response.data
    } catch (err) {
        return errorHandling(err);
    }
}
export const problemApproveReprove = (id, props) => async () => {
    try {
        const response = await axios.put(api + `/approve-problem/${id}`,props , getHeaders());
        return response.data
    } catch (err) {
        return errorHandling(err);
    }
}

export const getProblem = (id) => async (dispatch) => {
    try {
        const response = await axios.get(api + `/problems/${id}`, getHeaders());
        dispatch({ type: "GET_PROBLEM", payload: response.data });
    } catch (err) {
        return errorHandling(err);
    }
}
