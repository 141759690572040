import { Grid, Typography, Tab, Tabs } from '@mui/material';
import React, { useState, useRef, useCallback, useEffect } from 'react';
import { Box } from '@mui/system';
import ActionCardArea from './ActionCardArea';
import ActivityApproval from './ActivityApproval';
import { isMobile } from 'react-device-detect';
import * as actions from "../../../store/actions/action";
import * as actions2 from "../../../store/actions/problems";

import Slider from 'react-slick';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { connect } from 'react-redux';
import { queryString } from '../../../utils/String';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { i18n } from '../../../translate/i18n';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import Loading from '../utils/Loading';
import SubmitActivityModal from './modal/SubmitActivityModal';

const options = ['Para fazer', 'Em andamento', 'Homologação', 'Concluído/Aplicado']

const DroppableContent = ({ droppableId, setCurrentPageInfo, user, updateActivityStatus, actions, isValidTransition, draggedActivity }) => {
  return (
    <Droppable droppableId={droppableId} isDropDisabled={!isValidTransition(draggedActivity?.status, droppableId)}>
      {(provided, snapshot) => {
        let status;
        if (actions && snapshot.draggingOverWith) {
          const activity = actions.reduce((selectedActivity, action) => {
            if (!selectedActivity) {
              const activity = action.activities.find(activity => activity._id === snapshot.draggingOverWith);
              if (activity) {
                selectedActivity = activity;
              }
            }
            return selectedActivity;
          }, null);
          status = activity?.status;
        }
        return <Grid item xs={user?.role === "agency" ? 2.4 : 3}>
          <div ref={provided.innerRef} {...provided.droppableProps}>
            <Box position="relative"
            >
              {
                snapshot.isDraggingOver && !snapshot.draggingFromThisWith && isValidTransition(status, droppableId)  && (
                  <Box
                  sx={{
                      position: "absolute",
                      zIndex: 999,
                      minWidth: "100%",
                      minHeight: "100%",
                      bgcolor: "#F0F0F0",
                      padding: 1,
                      borderRadius: "5px",
                      border: "1px dashed #000",
                    } 
                  }
                />
                )
              }
              <ActionCardArea
                label={droppableId}
                setCurrentPageInfo={setCurrentPageInfo}
                updateActivityStatus={updateActivityStatus}
              />
            </Box>
          {provided.placeholder}
        </div>
        </Grid>
      }}
    </Droppable>
  );
};

function Kanban(props) {
  const location = useLocation();
  const isPerformance = location.pathname.split('/')[1] === "support-performance";
  const [currentPageInfo, setCurrentPageInfo] = useState({ page: 'Kanban' });
  const [activeTab, setActiveTab] = useState(0);
  const [submitActivity, setSubmitActivity] = useState(false);
  const [draggedActivity, setDraggedActivity] = useState({});
  const [loading, setLoading] = useState(false);
  const sliderRef = useRef(null);
  const sliderSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: '10%',
    beforeChange: (current, next) => setActiveTab(next),
  };

  if (props.user?.role === "agency" && options[0] != "Backlog") options.unshift("Backlog")

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
    sliderRef.current.slickGoTo(newValue);
  };

  const isValidTransition = (source, destination) => {
    if (source === "Backlog" && destination === "Para fazer") return true;
    if (source === "Para fazer" && (destination === "Em andamento" || destination === "Backlog") ) return true;
    if (source === "Em andamento" && (destination === "Homologação" || destination === "Para fazer")) return true;
    if (source === "Homologação" && destination === "Concluído/Aplicado") return true;
    return false;
  }

  const handleDragEnd = async (id, status, source) => {
    setDraggedActivity({});
    if ((!status || !isValidTransition(source, status)) && status !== "refused") return;

    if (status === "Homologação") {
      setSubmitActivity(id);
      return false;
    }
    setLoading(true);
    await props.updateActivityStatus(id, status, props.user?._id);
    getActions();
    setLoading(false);
    return true;
  };

  const handleDragStart = (result) => {
    const foundActivity = props.actions?.docs?.reduce((selectedActivity, action) => {
        if (!selectedActivity) {
          const activity = action.activities.find(activity => activity._id === result.draggableId);
          if (activity) {
            selectedActivity = activity;
          }
        }
        return selectedActivity;
      }, null);
    setDraggedActivity(foundActivity);
  }

  const SendActivityToApproval = async (activity, comment, files) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("comment", comment);
    files.forEach((file) => formData.append("file", file));
    await props.sendActivityToApproval(activity, formData);
    getActions();
    setLoading(false);
  };

  const getActions = useCallback(() => {
    if (!isPerformance && (props.cycle === "" || !props.cycle)) return;
    const params = new URLSearchParams({
      cycle: props.cycle,
      platform: props.platform.includes('suporte') ? 'suporte' : 'sprint',
    });
    const query = queryString({customer: localStorage.getItem('shop'), limit: 9999 });

    if (isPerformance) {
      props.getProblems(query);
      params.delete('cycle');
      props.getActions(params);
    } else {
      props.getActions(params);
    }
  }, [props.cycle, props.shop]);

  useEffect(() => {
    getActions();
  }, [props.cycle, props.shop, currentPageInfo]);

  useEffect(() => {
    if (props.match.params.id) localStorage.setItem('shop', props.match.params.id);
    getActions();
  }, [props.match.params.id]);

  const handleChangePage = (currentPageInfo) => {
    switch (currentPageInfo.page) {
      case 'Approval':
        return (
          <ActivityApproval
            currentPageInfo={currentPageInfo}
            setCurrentPageInfo={setCurrentPageInfo}
          />
        )
      default:
        return (
          <Box>
            <Loading loading={loading} />
            <SubmitActivityModal activity={submitActivity} setActivity={setSubmitActivity} SendActivityToApproval={SendActivityToApproval} />
            <Typography variant='h4' marginBottom={"1rem"}>{isPerformance ? i18n.t('sprint.kanban.problemIncidents') : i18n.t('sprint.kanban.activities')}</Typography>
            <DragDropContext 
              onDragEnd={result => handleDragEnd(result.draggableId, result.destination?.droppableId, result.source?.droppableId)}
              onDragStart={handleDragStart}
            >
              {
                !isMobile ?
                    <Grid container spacing={2}>
                      {
                        options.map((elem, index) => (
                          <DroppableContent
                            key={elem}
                            actions={props.actions?.docs}
                            droppableId={elem}
                            setCurrentPageInfo={setCurrentPageInfo}
                            user={props.user}
                            draggedActivity={draggedActivity}
                            updateActivityStatus={handleDragEnd}
                            isValidTransition={isValidTransition}
                          />
                        ))
                      }
                    </Grid>
                  :
                  <>
                    <Tabs value={activeTab} onChange={handleTabChange}
                      variant="scrollable"
                      scrollButtons="auto">
                      {options.map((elem, index) => (
                            <Tab key={index} label={elem} />
                        ))}
                    </Tabs>
                    <br />
                    <Slider ref={sliderRef}  {...sliderSettings}>
                      {
                        options.map((elem, index) => (
                          <DroppableContent
                            key={elem}
                            actions={props.actions?.docs}
                            droppableId={elem}
                            setCurrentPageInfo={setCurrentPageInfo}
                            user={props.user}
                            draggedActivity={draggedActivity}
                            updateActivityStatus={handleDragEnd}
                            isValidTransition={isValidTransition}
                          />
                        ))
                      }
                    </Slider>
                  </>
              }
            </DragDropContext>
          </Box>
        )
    }
  }
  return (
    <Box
      className="dashboard"
    >
      {handleChangePage(currentPageInfo)}
    </Box>
  );
}

const mapStateToProps = (state) => ({
  actions: state.actions.actions,
  shop: state.auth.shop,
  cycle: state.actions.cycle,
  user: state.auth.user,
});

export default connect(mapStateToProps, {...actions, ...actions2})(Kanban);
