import { Form, FormikProvider, useFormik } from 'formik';
import React, { useCallback, useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { Modal, ModalBody } from 'reactstrap';
import * as actions from '../../store/actions/action';
import * as actions2 from '../../store/actions/customers';
import * as actions3 from '../../store/actions/activities';
import { debounce } from 'lodash';
import * as Yup from "yup";
import { i18n } from '../../translate/i18n';
import dayjs from 'dayjs';
import { Box, Grid, Typography, Icon, CircularProgress, Button} from '@mui/material'
import { isMobile } from 'react-device-detect';
import ReactQuillAction from '../../pages/Sprint/V2NewAction/utils/ReactQuillAction';
import FileSelect from '../../pages/Sprint/V2NewAction/utils/FileSelect';
import { BaseDatePicker } from '../../pages/Sprint/V2NewAction/utils/BaseDatePicker';
import Activities from '../../pages/Sprint/V2NewAction/pages/Activities';
import CycleSelector from '../../pages/Sprint/utils/HeaderButtons/CycleSelector';
import { FCSelect, FCTextField } from 'components-fullcomm';

const ActionForm = ({ 
  open, 
  setOpen, 
  platform,
  uploadActionFile,
  uploadActivitiesFile,
  addAction,
  handleUpdateCampaign,
  getViewCustomer,
  getActivities,
  user,
  shop,
  shopOverride,
  cycle,
}) => {
  const [selectedActivities, setSelectedActivities] = useState([]);
  const [localActivities, setLocalActivities] = useState([]);

  const getActivity = useCallback(
    debounce(async (_id) => {
      const params = new URLSearchParams({
        _id,
      });
      const arr = await getActivities(params);
      if (!arr.error?.error) setLocalActivities(arr);
      else setLocalActivities([]);
    }, 250),
    []
  );

  useEffect(() => {
    if (selectedActivities.length === 0) {
      setLocalActivities([]);
      return;
    }
    const params = selectedActivities.reduce((acc, activity) => {
      if (acc.find((id) => id === activity.activity)) {
        return acc;
      }
      return [...acc, activity.activity];
    }, []);
    getActivity(params);
  }, [selectedActivities]);

  useEffect(() => {
    getViewCustomer(shop);
  }, [shop]);

  const NewActionSchema = Yup.object().shape({
    name: Yup.string().required(i18n.t('sprint.newAction.nameRequired')),
    category: Yup.string().when('type', {
      is: (val) => val === 'Melhoria' || val === 'Implementação',
      then: schema => schema,
      otherwise: Yup.string().required(i18n.t('sprint.newAction.categoryRequired'))
    }),
    start: Yup.string().required(i18n.t('sprint.newAction.startRequired')),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      description: "",
      type: "Melhoria",
      category: "",
      quadrant: "",
      score: "",
      channel: "",
      start: dayjs(new Date().toISOString().split("T")[0]),
      end: "",
      trust: 0,
      ease: 0,
      impact: 0,
      credits: 0,
      score: 0,
      status: "Backlog",
      metric: "",
      utm: "UTM",
      urlUtm: "",
      checklist: [],
      campaign: "",
      executionTime: 0,
      perpetual: false,
      requireEstimate: false,
      userLanguage: localStorage.getItem('i18nextLng') || 'pt-BR',
      language: {},
      customerId: shopOverride,
      platform: platform,
      creationStartDate: new Date().toISOString(),
    },
    validationSchema: NewActionSchema,

    onSubmit: async (values, { setSubmitting, resetForm }) => {
      values.creationEndDate = new Date().toISOString()
      const { category = '', quadrant = '' } = JSON.parse(values.category || '{}');
      const formatedStart = values.start && dayjs(values.start).format("YYYY-MM-DD");
      let formatedEnd = values.end && dayjs(values.end).format("YYYY-MM-DD");
      if (values.perpetual) formatedEnd = values.start && dayjs(values.start).add(2, "years").format("YYYY-MM-DD");
      const formData = new FormData();
      selectedActivities.map(item => {
        item.history ? item.history.push({ status: item.status }) : item.history = [{ status: item.status }]
        return item.description?.map(item => {
          if (item !== null && item?.name == "file") {
            formData.append("file", item.value);
          }
        })
      });

      let activityHasFile = false;
      if (values.userLanguage) delete values.userLanguage;
      if (values.campaign === '') delete values.campaign;
      values = {
        ...values,
        platform,
        userId: user._id,
        start: formatedStart,
        end: formatedEnd,
        category,
        quadrant,
        score: values.ease * values.impact * values.trust,
        cycle: [cycle],
        activities: selectedActivities,
      };
      try {
        const result = await addAction(values);

        if (result.error) {
          switch (result.error.message) {
            case "Ação não existe!":
              toast.error(i18n.t('sprint.newAction.actionNotExist'));
              break;
            case "Erro na integração com Jira, tente salvar a ação novamente!":
              toast.error(i18n.t('sprint.newAction.errorIntegrationJira'));
              break;
            case "Ação não encontrada ou não modificada!":
              toast.error(i18n.t('sprint.newAction.actionNotFound'));
              break;
            case "Erro interno do servidor.":
              toast.error(i18n.t('sprint.newAction.internalError'));
              break;
            case "Essa loja não possui um contrato!":
              toast.error(i18n.t('sprint.newAction.contractNotFound'));
              break;
            case "Dados inválidos!":
              toast.error(i18n.t('sprint.newAction.invalidData'));
              break;
            case "Erro ao cadastrar ação!":
              toast.error(i18n.t('sprint.newAction.registerError'));
              break;
            default:
              toast.error(result.error.message);
          }
          setSubmitting(false);
        } else {
          if (values.file) {
            const formData = new FormData();
            for (let i = 0; i < values.file.length; i++) {
              formData.append("file", values.file[i]);
            }
            const handleUpload = async (uploadFunction, errorMessage) => {
              const upload = await uploadFunction(result.data, formData);
              if (upload.error) {
                switch (upload.error.message) {
                  case "Erro ao enviar arquivos!":
                    toast.error(i18n.t('sprint.newAction.sendError'));
                    break;
                  case "Erro ao atualizar arquivos!":
                    toast.error(i18n.t('sprint.newAction.errorUpdateFiles'));
                    break;
                  case "Erro interno do servidor.":
                    toast.error(i18n.t('sprint.newAction.internalError'));
                    break;
                  default:
                    toast.error(upload.error.message);
                }
              }
            };
            handleUpload(uploadActionFile, "Erro ao atualizar arquivos!");
            if (activityHasFile) {
              handleUpload(uploadActivitiesFile, "Erro ao enviar arquivos!");
            }
          }
          if (values.campaign) handleUpdateCampaign(values?.campaign?._id, { action: result.data });

          if (result.message === "Ação cadastrada com sucesso!") {
            toast.success(i18n.t('sprint.newAction.createSuccess'))
          } else if (result.message === "Ação atualizada com sucesso!") {
            toast.success(i18n.t('sprint.newAction.updateSuccess'))
          } else {
            toast.success(result.message);
          }
          setSubmitting(false);
          resetForm();          
          setOpen(null);
        }
      } catch (error) {
        setSubmitting(false);
        if (error.error.message === "Erro interno do servidor.") {
          toast.error(i18n.t('sprint.newAction.internalError'))
        } else {
          toast.error(error.message);
        }
      }
    }
  })
  const { values, errors, touched, handleSubmit, isSubmitting, setFieldValue, getFieldProps } = formik;
  return (
    <Modal
      size="lg"
      toggle={() => {
        setOpen(null);
      }}
      isOpen={open}
    >
      <ModalBody>
        <Box 
          display={"flex"} 
          justifyContent={"space-between"}
        >
          <Typography>{i18n.t(`components.actionForm.${platform === 'sprint' ? 'quickActivity' : 'quickImprovement'}` )}</Typography>
          <Icon
            sx={{
              color: "#86888c",
              cursor: "pointer",
            }}
            onClick={() => {
              setOpen(null);
            }}
            className="material-icons-outlined"
            fontSize="large"
          >
            cancel
          </Icon>
        </Box>
        <FormikProvider value={formik}>
          <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <Box padding={0}>
              <Typography variant='h3' mb='1.5rem'>{i18n.t(`components.actionForm.${platform === 'sprint' ? 'activityDetails' : 'improvementDetails'}`)}</Typography>
              <Grid container columnSpacing={2} rowSpacing={isMobile ? 2 : 4}>
                <Grid item xs={(isMobile || platform === 'suporte') ? 12 : 9.5} >
                  <FCTextField
                    size={isMobile ? 'small' : 'medium'}
                    fullWidth
                    // label={i18n.t('sprint.newAction.pages.details.name')}
                    placeholder={i18n.t('sprint.newAction.pages.details.name')}
                    {...getFieldProps('name')}
                    error={Boolean(touched.name && errors.name)}
                    helperText={touched.name && errors.name}
                  />
                </Grid>
                {
                  platform === 'sprint' &&
                  (
                    <Grid item xs={isMobile ? 12 : 2.5}>
                      <CycleSelector size={isMobile ? 'small' : 'large'}/>
                    </Grid>
                  )
                }
                <Grid item xs={12} paddingBottom={"2.5rem"}>
                  <ReactQuillAction />
                </Grid>
                <Grid item xs={6}>
                  <FCSelect
                    fullWidth
                    select
                    size={isMobile ? 'small' : 'medium'}
                    label={i18n.t("components.actionForm.type")}
                    {...getFieldProps('type')}
                    error={Boolean(touched.type && errors.type)}
                    helperText={touched.type && errors.type}
                    options={[
                      { value: 'Melhoria', label: i18n.t("components.actionForm.improvement") },
                      { value: 'Implementação', label: i18n.t("components.actionForm.implementation") },
                    ]}
                  />
                </Grid>
                <Grid item xs={6}>
                  <BaseDatePicker
                    fieldName="start"
                    disabled={false}
                    getFieldProps={getFieldProps}
                    touched={touched}
                    errors={errors}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FileSelect />
                </Grid>
              </Grid>
            </Box>
            <Activities
              platform={platform}
              selectedActivities={selectedActivities}
              setSelectedActivities={setSelectedActivities}
              setLocalActivities={setLocalActivities}
              localActivities={localActivities}
              setFieldValue={setFieldValue}
              values={values}
              shrink={true}
            />
            <Box
              display={"flex"}
              alignItems={"center"}
              gap={"1rem"}
              padding={"1rem 0 1rem 1rem"}
              width={"100%"}
            >
              <Button
                fullWidth
                variant="contained"
                className="btn-gray"
                onClick={() => {
                  setOpen(null);
                }}>
                  {i18n.t('support.problemsIncidents.modalForm.cancel')}
              </Button>
              <Button
                fullWidth
                type="submit"
                variant="contained"
                className="btn-action"
                disabled={isSubmitting}
              >
                {
                  isSubmitting
                    ? <CircularProgress size={24} />
                    : i18n.t('components.actionForm.save')
                }
              </Button>
            </Box>
          </Form>
        </FormikProvider>
      </ModalBody>
    </Modal> 
  )
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  cycle: state.actions.cycle,
  shop: state.auth.shop,
});

export default connect(mapStateToProps, {...actions, ...actions2, ...actions3})(ActionForm);