const scale_reducer = (state = {}, action) => {
    switch (action.type) {
        case "GET_LOGISTICS_INFO_HOME":
            return {
                ...state,
                logisticsInfo: action.payload,
            }
        case "GET_LOGISTICS_INFO_HOME_ALL":
            return {
                ...state,
                logisticsInfoAll: action.payload,
            }
        case "GET_LOGISTICS_ORDERS":
            return {
                ...state,
                logisticsOrders: action.payload,
            }
        case "GET_LOGISTICS_ORDERS_ALL":
            return {
                ...state,
                logisticsOrdersAll: action.payload,
            }
        case "GET_SMART_SHIPPING":
            return {
                ...state,
                smartShipping: action.payload,
            }
        case "FETCH_TOKEN":
            return {
                ...state,
                tokenApi: action.payload,
            }
        case "GET_SMART_SHIPPING_ORDERS":
            return {
                ...state,
                smartShippingOrders: action.payload,
            }
        case "GET_ORDERS_PLATINUM":
            return {
                ...state,
                ordersPlatinum: action.payload,
            }
        case "GET_ORDERS_PLATINUM_DETAIL":
            return {
                ...state,
                ordersPlatinumDetail: action.payload,
            }
        case "GET_ORDERS_STATUS_PLATINUM":
            return {
                ...state,
                ordersStatusPlatinum: action.payload,
            }
        case "GET_BOTERIA":
            return {
                ...state,
                boteria: action.payload
            }
        case "GET_BOTERIA_MESSAGES":
            return {
                ...state,
                boteriaMessages: action.payload
            }
        case "GET_COSTS_OMS":
            return {
                ...state,
                costsOms: action.payload
            }
        default:
            return state;
    }
}

export default scale_reducer;