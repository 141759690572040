import React from 'react';
import { TableBody, Paper, Table, TableContainer, TableHead, TableRow, TableCell } from '@mui/material';
import { i18n } from '../../translate/i18n';

const Products = ({ products }) => {

    return (
        <TableContainer component={Paper} sx={{ marginTop: '16px' }}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>{i18n.t('components.platinumlog.products.sku')}</TableCell>
                        <TableCell>{i18n.t('components.platinumlog.products.merchandise')}</TableCell>
                        <TableCell>{i18n.t('components.platinumlog.products.amount')}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {products.map((item, index) => (
                        <TableRow key={index} sx={{
                            backgroundColor: (index % 2 === 0) ? '#F3F3F3' :
                                '',
                        }}>
                            <TableCell>{item.sku}</TableCell>
                            <TableCell>{item.name}</TableCell>
                            <TableCell>{item.amount}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default Products;