import { Box, CardActionArea, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import ProblemCard from './ProblemCard';
import ShowMoreLessButton from '../utils/ShowMoreLessButton';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { userPermissionCheck } from '../utils/userPermissionCheck';
import ProblemModal from './modal/ProblemModal';

const filterActivities = (problems, label) => problems?.filter(problem =>
  (problem.status === "Para fazer" && label === "Para fazer") ||
  (problem.status === "Em andamento" && label === "Em andamento") ||
  (problem.status === "Q/A" && label === "Em andamento") ||
  (problem.status === "Homologação" && label === "Homologação") ||
  (problem.status === "Reprovado" && label === "Em andamento") ||
  (problem.status === "Concluído/Aplicado" && label === "Concluído/Aplicado")
);

const ProblemCardArea = ({ problems, setCurrentPageInfo, label, user }) => {
  const history = useHistory();
  const [filteredProblems, setFilteredProblems] = useState(filterActivities(problems, label));
  const [sectionProblems, setSectionProblems] = useState([]);
  const [problem, setProblem] = useState(null);

  useEffect(() => {
    setSectionProblems(filteredProblems?.slice(0, 4))

  }, [filteredProblems])

  useEffect(() => {
    setFilteredProblems(filterActivities(problems, label))
  }, [label, problems])
  return (
    <>
    {problem && <ProblemModal problem={problem} setProblem={setProblem} user={user} />}
    { filteredProblems?.length > 0 &&
      <Box
    sx={{
      marginBottom: "1rem",
      bgcolor: "#E7E7E7",
      borderRadius: "5px",
      paddingBottom: "5px",
    }}
  > 
    <Box display={"grid"} gap={"5px"} padding={"5px"}>
      {sectionProblems?.map((problem) => {
        const { responsible, problemStatus, status} = problem

        return status === "Homologação" ? (
          <CardActionArea
            key={problem._id}
            sx={{borderRadius: '5px'}}
            onClick={() =>
              setCurrentPageInfo({
                page: "Approval",
                ...problem,
              })
            }
          >
            <ProblemCard
              problem={problem}
              responsible={responsible}
              status={status}
              section={problemStatus}
            />
          </CardActionArea>
        ) : (
          <CardActionArea onClick={()=> setProblem(problem)} key={problem._id}>
            <ProblemCard
              problem={problem}
              responsible={responsible}
              status={problem.status}
              section={problemStatus}
            />
          </CardActionArea>
        );
      })}
      <ShowMoreLessButton 
        originalArray={filteredProblems} 
        setDisplayArray={setSectionProblems}
        displayArray={sectionProblems}
      />
    </Box>
  </Box>
    }
    </>
  )
}

export default ProblemCardArea;