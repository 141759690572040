import { Box, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import ActivityCard from './ActivityCard';
import ShowMoreLessButton from '../utils/ShowMoreLessButton';
import { userPermissionCheck } from '../utils/userPermissionCheck';
import { Draggable } from 'react-beautiful-dnd';
import ActivityModal from './modal/ActivityModal';

const filterActivities = (action, label, user) => action.activities.filter(activity =>
  (activity.status === "Backlog" && label === "Backlog" && activity.responsible === user?.agency?.name) ||
  (activity.status === "Para fazer" && label === "Para fazer") ||
  (activity.status === "Em andamento" && label === "Em andamento") ||
  (activity.status === "Homologação" && label === "Homologação") ||
  (activity.status === "Reprovado" && label === "Em andamento") ||
  (activity.status === "Concluído/Aplicado" && label === "Concluído/Aplicado")
);

const ActivityCardArea = ({ action, setCurrentPageInfo, label, user, updateActivityStatus, customer }) => {
  const [filteredActivities, setFilteredActivities] = useState(filterActivities(action, label, user));
  const [sectionActivities, setSectionActivities] = useState([]);
  const [activity, setActivity] = useState(null);
  const [open, setOpen] = useState(false);
  const [concluded, setConcluded] = useState(0);

  const handleClick = (activity, status) => {
    if (status === "Homologação" && user?.role !== "agency") {
      setCurrentPageInfo({
        page: "Approval",
        ...activity,
        action: action,
        _id: activity._id,
      })
    }
    // else if ((activity.reproved && status === "Em andamento" || status === "Concluído/Aplicado") && (user?.role === "admin" || (activity.responsible !== "FullPRO" && user.role === "agency"))) {
    //   history.push(`/kanban/reproved-activity/${action._id}/${activity._id}`)
    // }
    else {
      setActivity(activity)
      setOpen(true)
    }
  }

  useEffect(() => {
    setSectionActivities(filteredActivities?.slice(0, 4))
    setConcluded(filteredActivities?.filter(activity => activity.status === "Concluído/aplicado").length)

  }, [filteredActivities])

  useEffect(() => {
    setFilteredActivities(filterActivities(action, label, user))
  }, [label, action])
  return (
    <>
    {
      activity && <ActivityModal action={action} open={open} setOpen={setOpen} activityData={activity} updateActivityStatus={updateActivityStatus} user={user}/>
    }
    { filteredActivities?.length > 0 &&
      <Box
      key={action._id}
      sx={{
        marginBottom: "1rem",
        bgcolor: "#E7E7E7",
        borderRadius: "5px",
        paddingBottom: "5px",
      }}
    > 
      <Box display={"flex"} justifyContent={"space-between"}>
          <Typography sx={{ padding: "0.5rem" }}>
            <b>{action.name}</b>
          </Typography>
          {
            label === "Concluído" &&
            <Typography sx={{ padding: "0.5rem" }}>
              <b>{concluded}/{action.activities.length}</b>
            </Typography>
          }
      </Box>
      <Box display={"grid"} gap={"5px"} padding={"5px"}>
        {sectionActivities?.map((activity, index) => {
          const {repeat, responsible, activityStatus, status} = activity

          return  (
            <Draggable
              isDragDisabled={responsible === "FullPRO" || !userPermissionCheck(user, ["edit_action"]) || status === "Homologação" || status === "Concluído/Aplicado"}
              key={activity._id}
              draggableId={activity._id}
              index={index}
            >
              {(provided) => (
                <div
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                >
                  <div key={activity._id} onClick={()=> {
                    handleClick(activity, status)
                  }}>
                    <ActivityCard
                      info={activity.activity}
                      repeat={repeat}
                      userResponsible={activity.userResponsible}
                      responsible={responsible}
                      reproved={activity.reproved}
                      status={status}
                      section={activityStatus}
                      customer={customer}
                    />
                  </div>
                </div>
              )}
            </Draggable>
          )})}
        <ShowMoreLessButton 
          originalArray={filteredActivities} 
          setDisplayArray={setSectionActivities}
          displayArray={sectionActivities}
        />
      </Box>
    </Box>
    }
    </>
  )
}

export default ActivityCardArea