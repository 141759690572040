import { LinearProgress, Typography } from '@mui/material'
import React from 'react'
import { Modal, ModalBody } from 'reactstrap'
import { i18n } from '../../../translate/i18n';

const Loading = ({ loading }) => {
  return (
    <Modal
      isOpen={loading}
      centered
      size="md"
    >
      <ModalBody>
        <Typography align='center' mb="1rem" variant="h6">{i18n.t('sprint.newAction.modal.loading.pleaseWait')}</Typography>
        <LinearProgress />
      </ModalBody>
    </Modal>
  )
}

export default Loading