import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from '../store/store'
import Login from '../pages/Login'
import RecoveryPassLogin from '../pages/RecoveryPassLogin'
import Dashboard from '../pages/Dashboard'
import Perfil from '../pages/Perfil'
import ViewUser from '../pages/ViewUser'
import UpdateUserPass from '../pages/UpdateUserPass';
import UpdatePerfil from '../pages/UpdatePerfil';
import UpdatePerfilPass from '../pages/UpdatePerfilPass';
import UpdatePerfilPhoto from '../pages/UpdatePerfilPhoto';
import UpdatePassRecovery from '../pages/UpdatePassRecovery';
import User from '../pages/User';
import Specific from '../pages/User/Specific';
import AddUser from '../pages/AddUser'
import baseLogin from '../containers/login';
import baseDashboard from '../containers/dashboard';
import { SocketProvider } from "../contexts/SocketContext";

import Permission from '../pages/Permission';
import PermissionAdmin from '../pages/Permission/Admin';
import Customer from '../pages/Customer'
import AddCustomer from '../pages/AddCustomer'

import Chat from '../pages/Chat'
import ChatAdm from '../pages/Chat/indexAdm'
import CombinedRoutes from './CombinedRoutes';

import Notifications from '../pages/Notifications';
import wideDashboard from '../containers/dashboard/WideDashboard';
import AddTrialUser from '../pages/AddTrialUser';
import Unauthorized from '../pages/Unauthorized';
import List from '../pages/Agency/List';
import Form from '../pages/Agency/Form';
import AgencySpecific from '../pages/Agency/Specific';
import Table from '../pages/Sprint/TableActivity/Table';
import Register from '../pages/Login/Register';
import PermissionModels from '../pages/Permission/PermissionModels';

import Documents from '../components/Documents/index'
import DocumentForm from '../components/Documents/Form'
import Costs from '../pages/Admin/Costs';
import Roadmap from '../pages/Start/Roadmap';
import TotvsEcommerceBrasil from '../pages/Totvs/EcommerceBrasil';

export default function Routes() {
    return (
        <Provider store={store}>
            <BrowserRouter >
                <SocketProvider>
                    <Switch>
                        <Route path="/" exact component={baseLogin(Login)} />
                        <Route path="/totvs" exact component={TotvsEcommerceBrasil} />
                        <Route path="/register" exact component={Register} />
                        <Route path="/recovery-pass" exact component={baseLogin(RecoveryPassLogin)} />
                        <Route path="/update-pass-recovery/:key" exact component={baseLogin(UpdatePassRecovery)} />
                        <Route path="/dashboard" exact component={baseDashboard({ Component: Dashboard })} />
                        <Route path="/perfil" exact component={baseDashboard({ Component: Perfil })} />
                        <Route path="/update-perfil" exact component={baseDashboard({ Component: UpdatePerfil })} />
                        <Route path="/update-perfil-pass" exact component={baseDashboard({ Component: UpdatePerfilPass })} />
                        <Route path="/update-perfil-photo" exact component={baseDashboard({ Component: UpdatePerfilPhoto })} />
                        <Route path="/notifications" exact component={baseDashboard({ Component: Notifications, permissions: ['admin', "customer", 'agency'] })} />

                        <Route path="/user" exact component={baseDashboard({ Component: User, permissions: ['admin'], action: 'view_user' })} />
                        <Route path="/user/:role/:store" exact component={baseDashboard({ Component: Specific, permissions: ['admin', 'customer', 'agency'], action: 'view_user' })} />
                        <Route path="/view-user/:id" exact component={baseDashboard({ Component: ViewUser, permissions: ['admin', 'customer', 'agency'], action: 'view_user' })} />
                        <Route path="/add-user" exact component={baseDashboard({ Component: AddUser, permissions: ['admin'], action: 'create_user' })} />
                        <Route path="/add-user/:role/:store" exact component={baseDashboard({ Component: AddUser, permissions: ['admin', 'customer', 'agency'], action: 'create_user' })} />
                        <Route path="/update-user/:id" exact component={baseDashboard({ Component: AddUser, permissions: ['admin'], action: 'edit_user' })} />
                        <Route path="/update-user/:role/:store/:id" exact component={baseDashboard({ Component: AddUser, permissions: ['admin', 'customer', 'agency'], action: 'edit_user' })} />
                        <Route path="/update-user-pass/:id" exact component={baseDashboard({ Component: UpdateUserPass, permissions: ['admin'] })} />

                        <Route path="/customer" exact component={baseDashboard({ Component: Customer, permissions: ['admin'], action: 'view_customer' })} />
                        <Route path="/add-customer" exact component={baseDashboard({ Component: AddCustomer, permissions: ['admin'], action: 'create_customer' })} />
                        <Route path="/update-customer/:id" exact component={baseDashboard({ Component: AddCustomer, permissions: ['admin'], action: 'edit_customer' })} />\
                        <Route path="/chat/:platform" exact component={baseDashboard({ Component: Chat })} />
                        <Route path="/chat/:platform/:store" exact component={baseDashboard({ Component: Chat })} />
                        <Route path="/chat-adm" exact component={baseDashboard({ Component: ChatAdm })} />

                        <Route path="/agencies" exact component={baseDashboard({ Component: List, permissions: ['admin'], action: 'view_agency' })} />
                        <Route path="/add-agency" exact component={baseDashboard({ Component: Form, permissions: ['admin'], action: 'create_agency' })} />
                        <Route path="/edit-agency/:id" exact component={baseDashboard({ Component: Form, permissions: ['admin', 'agency'], action: 'edit_agency' })} />
                        <Route path="/user/:role/:agency" exact component={baseDashboard({ Component: Specific, permissions: ['admin', 'agency'], action: 'view_user' })} />
                        <Route path="/agency/:id" exact component={baseDashboard({ Component: AgencySpecific, permissions: ['admin', 'agency'], action: 'view_agency' })} />
                        <Route path="/agency-table/:agency" exact component={baseDashboard({ Component: Table, permissions: ['admin'], platform: ['sprint'], action: 'view_activity_table' })} />

                        <Route path="/permissions" exact component={baseDashboard({ Component: PermissionModels, permissions: ['admin'], action: 'edit_user_permissions' })} />
                        <Route path="/permissions/customer/:id" exact component={baseDashboard({ Component: Permission, permissions: ['admin', 'customer', 'agency'], action: 'edit_user_permissions' })} />
                        <Route path="/permissions/:id" exact component={baseDashboard({ Component: PermissionAdmin, permissions: ['admin'], action: 'edit_user_permissions' })} />

                        <Route path="/documents/:platform" exact component={baseDashboard({ Component: Documents, permissions: ['admin', 'customer', 'agency'], platform: ['start'], action: 'view_document' })} />
                        <Route path="/new-document/:platform" exact component={baseDashboard({ Component: DocumentForm, permissions: ['admin', 'customer', 'agency'], platform: ['start'], action: 'create_document' })} />
                        <Route path="/edit-document/:id" exact component={baseDashboard({ Component: DocumentForm, permissions: ['admin', 'customer', 'agency'], platform: ['start'], action: 'edit_document' })} />

                        <Route path="/add-trial-user" exact component={AddTrialUser} />

                        <Route path="/unauthorized" exact component={baseDashboard({ Component: Unauthorized })} />
                        <Route path="/costs" exact component={baseDashboard({ Component: Costs, permissions: ['admin'] })} />
                        <Route path="/advanced-roadmap" exact component={baseDashboard({ Component: Roadmap, permissions: ['admin'] })} />
                        <CombinedRoutes baseDashboard={baseDashboard} wideDashboard={wideDashboard} />

                    </Switch>
                </SocketProvider>
            </BrowserRouter>
        </Provider>
    )
} 