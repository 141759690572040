import React from 'react';
import { Avatar, Button, CardActionArea, Typography } from '@mui/material';
import { Box } from '@mui/system';
import moment from 'moment';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/notifications';
import { api } from '../../config';
import { i18n } from '../../translate/i18n';
import 'moment/locale/pt-br';
import 'moment/locale/es';

const NotificationCard = ({ _id, createdAt, avatarImgUrl, imageUrl, title, description, read = [], user, category, linkUrl, customer, readNotifications }) => {

  const currentLanguage = localStorage.getItem('i18nextLng');
  const momentLocale = {
    'pt-BR': 'pt-br',
    'en-US': 'en-us',
    'es': 'es',
  }[currentLanguage] || 'pt-br';
  moment.locale(momentLocale);
  const elapsed = moment(createdAt).fromNow();
  const avatar = avatarImgUrl ? `${api}/files/${avatarImgUrl}` : null;

  const handleTitle = (title) => {
    switch (title) {
      case "Nova ação cadastrada": return i18n.t('notifications.notificationCard.newActionRegister');
      case "Ação atualizada": return i18n.t('notifications.notificationCard.updateAction');
      case "Atividade reprovada": return i18n.t('notifications.notificationCard.reproveActivity');
      case "Atividade aprovada": return i18n.t('notifications.notificationCard.approveActivity');
      case "Ação excluída": return i18n.t('notifications.notificationCard.deleteAction');
      case "Impedimento!": return i18n.t('notifications.notificationCard.impediment');
      case "Aprovação pendente!": return i18n.t('notifications.notificationCard.pendingApproval');
      case "Problema reprovado": return i18n.t('notifications.notificationCard.reproveProblem');
      case "Problema aprovado": return i18n.t('notifications.notificationCard.approveProblem');
      case "Ação necessita estimativa": return i18n.t('notifications.notificationCard.requiresEstimation');
      default: return title;
    }
  }

  const handleDescription = (description) => {
    if (description.includes('A ação') && description.includes('foi cadastrada com sucesso!')) {
      description = description.replace('A ação', i18n.t('notifications.notificationCard.theAction'))
      description = description.replace('foi cadastrada com sucesso!', i18n.t('notifications.notificationCard.wasRegistered'))
    }
    if (description.includes('A ação') && description.includes('foi atualizada com sucesso!')) {
      description = description.replace('A ação', i18n.t('notifications.notificationCard.theAction'))
      description = description.replace('foi atualizada com sucesso!', i18n.t('notifications.notificationCard.wasUpdated'))
    }
    if (description.includes('A atividade') && description.includes('da ação') && !description.includes('está com Impedimento!')) {
      description = description.replace('A atividade', i18n.t('notifications.notificationCard.theActivity'))
      description = description.replace('da ação', i18n.t('notifications.notificationCard.ofAction'))
      if (description.includes('reprovada')) {
        description = description.replace('foi reprovada!', i18n.t('notifications.notificationCard.wasReproved'))
      } else if (description.includes('aprovada')) {
        description = description.replace('foi aprovada!', i18n.t('notifications.notificationCard.wasApproved'))
      }
    }
    if (description.includes('A ação') && description.includes('foi excluída!')) {
      description = description.replace('A ação', i18n.t('notifications.notificationCard.theAction'))
      description = description.replace('foi excluída!', i18n.t('notifications.notificationCard.wasDeleted'))
    }
    if (description.includes('A atividade') && description.includes('da ação') && description.includes('está com Impedimento!')) {
      description = description.replace('A atividade', i18n.t('notifications.notificationCard.theActivity'))
      description = description.replace('da ação', i18n.t('notifications.notificationCard.ofAction'))
      description = description.replace('está com Impedimento!', i18n.t('notifications.notificationCard.isImpediment'))
    }
    if (description.includes('A atividade') && description.includes('da ação') && description.includes('necessita ser aprovada!')) {
      description = description.replace('A atividade', i18n.t('notifications.notificationCard.theActivity'))
      description = description.replace('da ação', i18n.t('notifications.notificationCard.ofAction'))
      description = description.replace('necessita ser aprovada!', i18n.t('notifications.notificationCard.needApproval'))
    }
    if (description.includes('O problema/incidente') && description.includes('necessita ser aprovado!')) {
      description = description.replace('O problema/incidente', i18n.t('notifications.notificationCard.theProblemIncident'))
      description = description.replace('necessita ser aprovado!', i18n.t('notifications.notificationCard.needApproval2'))
    }
    if (description.includes('O problema') && description.includes('foi')) {
      description = description.replace('O problema', i18n.t('notifications.notificationCard.theProblem'))
      if (description.includes('reprovado')) {
        description = description.replace('foi reprovado!', i18n.t('notifications.notificationCard.wasReproved'))
      } else if (description.includes('aprovado')) {
        description = description.replace('foi aprovado!', i18n.t('notifications.notificationCard.wasApproved'))
      }
    }
    if (description.includes('A ação') && description.includes('está com a checklist pendente!')) {
      description = description.replace('A ação', i18n.t('notifications.notificationCard.theAction'))
      description = description.replace('está com a checklist pendente!', i18n.t('notifications.notificationCard.pendingChecklist'))
    }
    if (description.includes('A ação') && description.includes('necessita estimativa!')) {
      description = description.replace('A ação', i18n.t('notifications.notificationCard.theAction'))
      description = description.replace('necessita estimativa!', i18n.t('notifications.notificationCard.needEstimation'))
    }

    return description
  }


  return (
    <CardActionArea
      sx={{
        display: "flex",
        justifyContent: "space-between",
        paddingX: "1.5rem",
        paddingY: "1rem"
      }}
      onClick={() => {
        // readNotifications(user, [_id], category);
        localStorage.setItem('shop', customer?._id);
        window.location.href = linkUrl
      }}
    >
      <Box width={'100%'} ml={2}>
        <Box display={'flex'} justifyContent={'space-between'}>
          <Box display='flex' gap='1rem'>
            <Avatar alt={"user image"} src={avatar}  sx={{ height: '24px', width: '24px'}}/>
            <Typography variant="subtitle2">
              {handleTitle(title)}
            </Typography>
          </Box>
          <Typography variant="caption" textAlign={'end'} alignSelf={'center'} noWrap sx={{ width: '100px' }}>
            {elapsed}
          </Typography>
        </Box>
        <Typography
          variant="body2"
          sx={{
            marginTop: "0.5rem",
            fontWeight: read.includes(user?._id) ? "400" : "bold",
            display: "-webkit-box",
            "-webkit-box-orient": "vertical",
            "-webkit-line-clamp": "2",
            overflow: "hidden",
            textOverflow: "ellipsis"
          }}>
          {handleDescription(description)}
        </Typography>
        {
          imageUrl && (
            <Box
              display={'flex'}
              justifyContent={'center'}
              mt={"0.5rem"}
              maxHeight={"10rem"}
              maxWidth={"20rem"}
            >
              <img src={imageUrl} alt="notification image" style={{ width: '100%' }} />
            </Box>
          )
        }
        {/* {
            category === "Comunicados" && (
              <Button variant='contained' className='btn-action'sx={{marginTop: "0.5rem"}}>
                Ver mensagem
              </Button>
            )
          } */}
      </Box>
    </CardActionArea>
  );
};

const mapStateToProps = state =>
({

})

export default connect(mapStateToProps, actions)(NotificationCard);