import React, { useState } from 'react';
import { i18n } from '../../translate/i18n';
import { format } from 'date-fns';

import { Table, TableBody, TableCell, TableRow, Paper, Tooltip, TableSortLabel, Avatar } from '@mui/material';
import { FCTableContainer, FCTableHeader } from 'components-fullcomm';

function CurveRiskStockTable({ tab, data, showDetails, replace }) {
    const [orderBy, setOrderBy] = useState(null);
    const [order, setOrder] = useState('asc');

    const handleSort = (property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const sortedData = [...data || []].sort((a, b) => {
        const valA = a[orderBy];
        const valB = b[orderBy];

        if (typeof valA === 'string' && typeof valB === 'string') {
            const comparison = valA.localeCompare(valB, undefined, { sensitivity: 'accent' });
            return order === 'asc' ? comparison : -comparison;
        }

        if (valA < valB) {
            return order === 'asc' ? -1 : 1;
        }
        if (valA > valB) {
            return order === 'asc' ? 1 : -1;
        }
        return 0;
    });
    let option = '';
    if (tab === 0) {
        option = 'product'
    } else if (tab === 1) {
        option = 'product_id'
    } else if (tab === 2) {
        option = 'type'
    }
    return (<FCTableContainer component={Paper}>
        <Table>
            <FCTableHeader>
                <TableRow>
                    {(tab === 0 || tab === 1) &&
                        <TableCell>
                            <TableSortLabel
                                active={orderBy === 'title'}
                                direction={order}
                                onClick={() => handleSort('title')}
                            >
                                {i18n.t('curveABC.tableHeaders.title')}
                            </TableSortLabel>
                        </TableCell>
                    }
                    {tab === 0 &&
                        <TableCell>
                            <TableSortLabel
                                active={orderBy === 'sku'}
                                direction={order}
                                onClick={() => handleSort('sku')}
                            >
                                {i18n.t('curveABC.tableHeaders.sku')}
                            </TableSortLabel>
                        </TableCell>
                    }
                    <TableCell>
                        <TableSortLabel
                            active={orderBy === 'product_type'}
                            direction={order}
                            onClick={() => handleSort('product_type')}
                        >
                            {i18n.t('curveABC.tableHeaders.type')}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell>
                        <TableSortLabel
                            active={orderBy === 'avaiable'}
                            direction={order}
                            onClick={() => handleSort('avaiable')}
                        >
                            {i18n.t('curveABC.tableHeaders.stock')}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell>
                        <TableSortLabel
                            active={orderBy === 'media_day'}
                            direction={order}
                            onClick={() => handleSort('media_day')}
                        >
                            {i18n.t('curveABC.tableHeaders.media_day')}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell>
                        <TableSortLabel
                            active={orderBy === 'expected_days'}
                            direction={order}
                            onClick={() => handleSort('expected_days')}
                        >
                            {i18n.t('curveABC.tableHeaders.expected_days')}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell>
                        <TableSortLabel
                            active={orderBy === 'date_finish'}
                            direction={order}
                            onClick={() => handleSort('date_finish')}
                        >
                            {i18n.t('curveABC.tableHeaders.expectedFinish')}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell>
                        <TableSortLabel
                            active={orderBy === 'abc'}
                            direction={order}
                            onClick={() => handleSort('abc')}
                        >
                            {i18n.t('curveABC.tableHeaders.curve')}
                        </TableSortLabel>
                    </TableCell>
                </TableRow>
            </FCTableHeader>

            <TableBody>
                {sortedData?.map(product => {
                    let d = {}
                    if (tab === 0) {
                        d = {
                            product: product.variant_id
                        }
                    } else if (tab === 1) {
                        d = {
                            type: product.product_id
                        }
                    } else {
                        d = {
                            type: product.product_type
                        }
                    }

                    product.title = replace ? product.title?.replace(/care/gi, 'FullComm') : product.title;
                    product.sku = replace ? product.sku?.replace(/care/gi, 'FullComm') : product.sku;
                    return (
                        <TableRow style={{ cursor: 'pointer' }} onClick={() => showDetails({ option, ...d })} key={product.variant_id}>
                            {(tab === 0 || tab === 1) &&
                                <>
                                    <TableCell sx={{display: 'flex', gap: '0.5rem', alignItems: 'center'}}>
                                        <Avatar 
                                            src={product.image_url} 
                                            alt={product.title}
                                        />
                                        {product.title?.length > 20 ? <Tooltip title={product.title}><span>{product.title?.substr(0, 20) + '...'}</span></Tooltip> : product.title}
                                    </TableCell>
                                </>
                            }
                            {tab === 0 &&
                                <>
                                    <TableCell>{product.sku}</TableCell>
                                </>
                            }
                            <TableCell>{product.product_type}</TableCell>
                            <TableCell>{product.avaiable}</TableCell>
                            <TableCell>{parseFloat(product.media_day.toFixed(2))}</TableCell>
                            <TableCell>{parseInt(product.day_finish)}</TableCell>
                            <TableCell
                                style={{
                                    backgroundColor: product.day_finish <= 30 ? '#e74c3c' : product.day_finish <= 60 ? '#f1c40f' : '#2ecc71',
                                    color: 'white',
                                    fontWeight: 'bold'
                                }}
                            >{format(new Date(product?.date_finish), "dd/MM/yyyy")}</TableCell>
                            <TableCell style={{
                                backgroundColor: product.abc === 'A' ? '#2ecc71' : product.abc === 'B' ? '#f1c40f' : '#e74c3c',
                                color: 'white',
                                fontWeight: 'bold'

                            }}>{product.abc}</TableCell>
                        </TableRow>
                    )
                })}
            </TableBody>
        </Table>
    </FCTableContainer>
    )
}
export default CurveRiskStockTable;
