import { useEffect, useState, useRef } from "react";
import { connect } from 'react-redux'
import * as Yup from 'yup';
import { Form, FormikProvider, useFormik } from 'formik';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { addContractFiles, RemoveContractFile } from '../../../store/actions/contracts'
import { getViewContract, handleAddContract, handleUpdateContract } from '../../../store/actions/contracts'
import { getCustomers } from '../../../store/actions/customers'
import { LoadingButton } from '@mui/lab';
import {  Grid, Typography, Button, Tooltip, Box, Chip, InputAdornment } from '@mui/material';
import { giveSelectTrimesters } from "../utils/HeaderButtons/CycleSelector";
import { i18n } from '../../../translate/i18n';
import AttachFileIcon from "@mui/icons-material/AttachFile";
import CloseIcon from "@mui/icons-material/Close";
import { FCAutocomplete, FCReturnButton, FCTextField } from "components-fullcomm";

const FormContract = (props) => {
    const [isEdit, setIsEdit] = useState(false);
    const [isCredits, setIsCredits] = useState(false);
    const [contract, setContract] = useState({});
    const [spec, setSpec] = useState([]);
    const fileInputRef = useRef(null);
    const [attachedFiles, setAttachedFiles] = useState([]);
    const NewContractSchema = Yup.object().shape({
        name: Yup.string().required(i18n.t('sprint.contract.form.reasonSocialRequired')),
        cnpj: Yup.string().required(i18n.t('sprint.contract.form.cnpjRequired')),
        customers: Yup.array()
            .of(Yup.object().shape({
                id: Yup.string().required(i18n.t('sprint.contract.form.clientIdRequired')),
                label: Yup.string().required(i18n.t('sprint.contract.form.clientNameRequired'))
            }))
            .required(i18n.t('sprint.contract.form.storeRequired')),
        monthlyCredits: Yup.number().required(i18n.t('sprint.contract.form.monthlyCreditsRequired')),
        startDate: Yup.date().required(i18n.t('sprint.contract.form.initDateRequired')),
        contactName: Yup.string(),
        contactEmail: Yup.string().email(i18n.t('sprint.contract.form.invalidEmail')),
        contactPhone: Yup.string(),
        contactFinancialName: Yup.string(),
        contactFinancialEmail: Yup.string().email(i18n.t('sprint.contract.form.invalidEmail2')),
        contactFinancialPhone: Yup.string(),
        percentComission: Yup.number(),
        minimalRevenue: Yup.number(),
        files: Yup.array()
    });
    useEffect(() => {
        if (props.match.params.id) {
            setIsEdit(true);
            props.getViewContract(props.match.params.id);
        }
        props.getCustomers(1, 1000)
    }, []);

    useEffect(() => {
        if (props.customers?.docs) setSpec(props.customers?.docs)
    }, [props.customers]);

    useEffect(() => {
        if (props.match.params.id) {
            setContract(props.contract);
        }
    }, [props.contract]);
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: contract?.name || '',
            cnpj: contract?.cnpj || '',
            startDate: contract?.startDate?.slice(0, 10) || new Date().toDateString().slice(0, 10),
            monthlyCredits: contract?.monthlyCredits || '',
            monthlyCreditsSupport: contract?.monthlyCreditsSupport || '',
            monthlyHours: contract?.monthlyHours || '',
            contactName: contract?.contact?.name || '',
            contactEmail: contract?.contact?.email || '',
            contactPhone: contract?.contact?.phone || '',
            contactFinancialName: contract?.contactFinancial?.name || '',
            contactFinancialEmail: contract?.contactFinancial?.email || '',
            contactFinancialPhone: contract?.contactFinancial?.phone || '',
            customers: isEdit ? (contract?.customers?.map(elem => ({
                id: elem._id,
                label: elem.name
            })) || []) : [],
            percentComission: contract?.commissionRate?.percentComission || '',
            minimalRevenue: contract?.commissionRate?.minimalRevenue || '',
            files: contract?.files || []
        },
        validationSchema: NewContractSchema,
        onSubmit: async (values, { setSubmitting, resetForm, setErrors }) => {
            try {
                const fieldName = !isCredits ? 'monthlyCreditsSupport': 'monthlyHours';
                const fieldValue = !isCredits ? values.monthlyHours*25 : values.monthlyCreditsSupport/25;

                values[fieldName] = fieldValue;
                let auxValue = { ...values };
                if (isEdit) {
                    auxValue._id = props.match.params.id;
                }
                auxValue.customers = values.customers.map(elem => elem.id)
                auxValue.contact = {
                    name: values.contactName,
                    email: values.contactEmail,
                    phone: values.contactPhone
                }
                auxValue.contactFinancial = {
                    name: values.contactFinancialName,
                    email: values.contactFinancialEmail,
                    phone: values.contactFinancialPhone
                }
                auxValue.commissionRate = {
                    percentComission: values.percentComission,
                    minimalRevenue: values.minimalRevenue
                }
                delete auxValue.contactName;
                delete auxValue.contactEmail;
                delete auxValue.contactPhone;
                delete auxValue.contactFinancialName;
                delete auxValue.contactFinancialEmail;
                delete auxValue.contactFinancialPhone;
                delete auxValue.percentComission;
                delete auxValue.minimalRevenue;

                if (!isEdit) {
                    const trimesters = giveSelectTrimesters(new Date());
                    auxValue.credits = trimesters.splice(trimesters.length - 3, 4).map(elem => ({
                        cycle: elem.label,
                        amount: values.monthlyCredits * 3
                    }))
                }
                if (attachedFiles.length > 0) {
                    handleSendContract(attachedFiles)
                }
                
                let result = isEdit ? await props.updateContract(auxValue) : await props.addContract(auxValue);

                if (result.error) {
                    setSubmitting(false);
                    if (result.error.message.includes("As lojas")) {
                        result.error.message = result.error.message.replace("As lojas", i18n.t('sprint.contract.form.theStores'));
                        result.error.message = result.error.message.replace("já possuem contrato ativo!", i18n.t('sprint.contract.form.haveContract'));
                    }

                    if (result.error.message.includes("A loja")) {
                        result.error.message = result.error.message.replace("A loja", i18n.t('sprint.contract.form.theStore'));
                        result.error.message = result.error.message.replace("já possui contrato ativo!", i18n.t('sprint.contract.form.haveContract2'));
                    }
                    switch (result.error.message) {
                        case "Contrato não foi cadastrado com sucesso!": return toast.error(i18n.t('sprint.contract.form.registerError'));
                        case "Ocorreu um erro ao atualizar o contrato.": return toast.error(i18n.t('sprint.contract.form.updateError'));
                        case "Erro interno do servidor.": return toast.error(i18n.t('sprint.contract.form.internalError'));
                        default: return toast.error(result.error.message);;
                    }
                } else {
                    if (!isEdit) resetForm() 
                    if (result.message === "Contrato cadastrado com sucesso!") {
                        toast.success(i18n.t('sprint.contract.form.registerSuccess'));
                    } else if (result.message === "Contrato atualizado com sucesso!") {
                        toast.success(i18n.t('sprint.contract.form.updateSuccess'));
                    } else {
                        toast.success(result.message);
                    }
                    setSubmitting(false);
                }
            } catch (error) {
                setSubmitting(false);
                if (error.error.message === 'Erro interno do servidor.') {
                    toast.error(i18n.t('sprint.contract.form.internalError'));
                } else {
                    toast.error(error.error.message);
                }
            }
        }
    });

    const handleFileInputChange = (e) => {
        const files = e.target.files;
        setAttachedFiles((prevFiles) => [...prevFiles, ...files]);
        e.target.value = "";
      };

    const handleFileInputClick = () => {
        fileInputRef.current.click();
    };

    const handleFileRemove = (file) => {
        setAttachedFiles((prevFiles) => prevFiles.filter((f) => f !== file));
      };

    const handleSendContract = async (files) => {
        if (files?.length === 0) return;
        const formData = new FormData();
        files?.forEach((file) => {
          formData.append("files", file);
        });
        const response = await addContractFiles(formData, contract._id);
        props.getViewContract(contract._id)
    
        setAttachedFiles([]);
    };

    const handleRemoveFile = async (file) => {
        console.log(file)
        const response = await RemoveContractFile(contract._id, file.key);
        props.getViewContract(contract._id)
        
    }

    const { errors, touched, handleSubmit, isSubmitting, getFieldProps, values, setFieldValue } = formik;

    return (
        <div className="p-4">
            <div className="d-flex">
                <div className="mr-auto p-2 d-flex align-items-center">
                    <FCReturnButton />
                    <Typography variant="h4">{isEdit ? i18n.t('sprint.contract.form.edit') : i18n.t('sprint.contract.form.register')} {i18n.t('sprint.contract.form.contract')}</Typography>
                </div>
            </div>

            <FormikProvider value={formik}>
                <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <FCTextField
                                            fullWidth
                                            required={true}
                                            label={i18n.t('sprint.contract.form.socialReason')}
                                            {...getFieldProps('name')}
                                            error={Boolean(touched.name && errors.name)}
                                            helperText={touched.name && errors.name}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <FCAutocomplete
                                            fullWidth
                                            {...getFieldProps('customers')}
                                            multiple
                                            required={true}
                                            value={values.customers}
                                            onChange={(event, newValue) => {
                                                setFieldValue('customers', newValue);
                                            }}
                                            options={spec.map(elem =>
                                            ({
                                                id: elem._id,
                                                label: elem.name
                                            }))}
                                            isOptionEqualToValue={(option, value) => option.id === value.id}
                                            label={i18n.t('sprint.contract.form.associateStores')}
                                            placeholder={i18n.t('sprint.contract.form.associateStores')}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <FCTextField
                                            fullWidth
                                            required={true}
                                            label={i18n.t('sprint.contract.form.cnpj')}
                                            {...getFieldProps('cnpj')}
                                            error={Boolean(touched.cnpj && errors.cnpj)}
                                            helperText={touched.cnpj && errors.cnpj}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <FCTextField
                                            fullWidth
                                            required={true}
                                            type="date"
                                            label={i18n.t('sprint.contract.form.initDate')}
                                            {...getFieldProps('startDate')}
                                            error={Boolean(touched.startDate && errors.startDate)}
                                            helperText={touched.startDate && errors.startDate}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <FCTextField
                                            fullWidth
                                            required={true}
                                            type="number"
                                            label={i18n.t('sprint.contract.form.monthlyCredits')}
                                            {...getFieldProps('monthlyCredits')}
                                            error={Boolean(touched.monthlyCredits && errors.monthlyCredits)}
                                            helperText={touched.monthlyCredits && errors.monthlyCredits}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <FCTextField
                                            fullWidth
                                            required={false}
                                            type="number"
                                            label={i18n.t('sprint.contract.form.comissionRate')}
                                            {...getFieldProps('percentComission')}
                                            InputProps={{endAdornment: <InputAdornment position="end">%</InputAdornment>}}
                                            error={Boolean(touched.comissionRate && errors.comissionRate)}
                                            helperText={touched.comissionRate && errors.comissionRate}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6} display="flex" gap="1rem" alignItems="end">
                                        {
                                            isCredits ? (
                                                <FCTextField
                                                    fullWidth
                                                    required
                                                    type="number"
                                                    label={i18n.t('sprint.contract.form.monthlyCreditsSupport')}
                                                    {...getFieldProps('monthlyCreditsSupport')}
                                                />
                                            ) : (
                                                <FCTextField
                                                    required
                                                    fullWidth
                                                    type="number"
                                                    label={i18n.t('sprint.contract.form.monthlyHours')}
                                                    {...getFieldProps('monthlyHours')}
                                                />
                                            )
                                        }
                                        <Tooltip
                                            title={i18n.t('sprint.contract.form.changeMode')}
                                        >
                                            <Typography 
                                                sx={{
                                                    cursor: 'pointer',
                                                    fontSize: '14px',
                                                    color: '#00D6CF',
                                                    lineHeight: '16.4px',
                                                    marginBottom: '1rem'
                                                }}
                                                onClick={()=> {
                                                    const fieldName = !isCredits ? 'monthlyCreditsSupport': 'monthlyHours';
                                                    const fieldValue = !isCredits ? values.monthlyHours*25 : values.monthlyCreditsSupport/25;

                                                    setFieldValue(fieldName, fieldValue);
                                                    setIsCredits(!isCredits);
                                                }}
                                                >
                                                Alterar
                                            </Typography>
                                        </Tooltip>
                                    </Grid>
                                    {(values?.percentComission > 0) && (
                                        <Grid item xs={12} md={6}>
                                        <FCTextField
                                            fullWidth
                                            required={false}
                                            type="number"
                                            label={i18n.t('sprint.contract.form.minimalRevenue')}
                                            {...getFieldProps('minimalRevenue')}
                                            InputProps={{startAdornment: <InputAdornment position="start">R$</InputAdornment>}}
                                            error={Boolean(touched.minimalRevenue && errors.minimalRevenue)}
                                            helperText={touched.minimalRevenue && errors.minimalRevenue}
                                        />
                                    </Grid>
                                    )}                        
                                </Grid>
                                <Grid item xs={12} paddingTop={'2rem'} paddingBottom={'1rem'}>
                                    <Typography variant="h7">{i18n.t('sprint.contract.form.financContacts')}</Typography>
                                </Grid>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={6}>
                                        <FCTextField
                                            fullWidth
                                            label={i18n.t('sprint.contract.form.nameFinanc')}
                                            {...getFieldProps('contactFinancialName')}
                                            error={Boolean(touched.contactFinancialName && errors.contactFinancialName)}
                                            helperText={touched.contactFinancialName && errors.contactFinancialName}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <FCTextField
                                            fullWidth
                                            type='email'
                                            label={i18n.t('sprint.contract.form.emailFinanc')}
                                            {...getFieldProps('contactFinancialEmail')}
                                            error={Boolean(touched.contactFinancialEmail && errors.contactFinancialEmail)}
                                            helperText={touched.contactFinancialEmail && errors.contactFinancialEmail}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <FCTextField
                                            fullWidth
                                            label={i18n.t('sprint.contract.form.phoneFinanc')}
                                            {...getFieldProps('contactFinancialPhone')}
                                            error={Boolean(touched.contactFinancialPhone && errors.contactFinancialPhone)}
                                            helperText={touched.contactFinancialPhone && errors.contactFinancialPhone}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} paddingTop={'2rem'} paddingBottom={'1rem'}>
                                    <Typography variant="h7">{i18n.t('sprint.contract.form.respContacts')}</Typography>
                                </Grid>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={6}>
                                        <FCTextField
                                            fullWidth
                                            label={i18n.t('sprint.contract.form.nameResp')}
                                            {...getFieldProps('contactName')}
                                            error={Boolean(touched.contactName && errors.contactName)}
                                            helperText={touched.contactName && errors.contactName}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <FCTextField
                                            fullWidth
                                            type='email'
                                            label={i18n.t('sprint.contract.form.emailResp')}
                                            {...getFieldProps('contactEmail')}
                                            error={Boolean(touched.contactEmail && errors.contactEmail)}
                                            helperText={touched.contactEmail && errors.contactEmail}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <FCTextField
                                            fullWidth
                                            label={i18n.t('sprint.contract.form.phoneResp')}
                                            {...getFieldProps('contactPhone')}
                                            error={Boolean(touched.contactPhone && errors.contactPhone)}
                                            helperText={touched.contactPhone && errors.contactPhone}
                                        />
                                    </Grid>
                                </Grid>
                                <div style={{ padding: '20px 0px'}} />
                                <Grid container spacing={4}>
                                    <Grid item xs={12} md={6} >
                                            <Button 
                                                variant="contained" 
                                                className="btn-gray" 
                                                onClick={handleFileInputClick}
                                                >
                                                <input
                                                    ref={fileInputRef}
                                                    type="file"
                                                    multiple
                                                    style={{ display: "none" }}
                                                    onChange={handleFileInputChange}
                                                />    
                                                {i18n.t('sprint.contract.form.addFiles')}
                                                <AttachFileIcon style={{width: '20px'}} />
                                            </Button>
                                    </Grid>
                                </Grid>
                                <div style={{ padding: '20px 0px'}} />
                                <Grid container spacing={4}>
                                    <Grid item xs={12}>
                                        {(contract?.files?.length > 0 || attachedFiles.length > 0) && (
                                            <Grid container spacing={4}>
                                                {contract?.files?.map((file) => (
                                                    <Grid item sx={{ alignItems: 'center'}}>
                                                        <Chip
                                                        key={file.key}
                                                        label={<a target="_blank" rel="noopener noreferrer" href={file.location}>{file.name} </a>}
                                                        onDelete={() => handleRemoveFile(file)}
                                                        deleteIcon={<CloseIcon />}
                                                        />
                                                    </Grid>
                                                ))}
                                                {attachedFiles?.map((file) => (
                                                    <Grid item >
                                                        <Chip
                                                        key={file.name}
                                                        label={file.name}
                                                        onDelete={() => handleFileRemove(file)}
                                                        deleteIcon={<CloseIcon />}
                                                        />
                                                    </Grid>
                                                ))}
                                            </Grid>
                                        )}
                                    </Grid>
                                </Grid>
                                <Box display='flex' justifyContent='end' gap='1rem' marginBottom='2.5rem'>
                                    <Link to='/contracts'>
                                        <LoadingButton type="submit"
                                            fullWidth
                                            variant="contained"
                                            className="btn-white large" loading={isSubmitting}>{i18n.t('sprint.contract.form.cancel')}</LoadingButton></Link>
                                    <LoadingButton type="submit"
                                        fullWidth
                                        className="btn-action large"
                                        variant="contained" loading={isSubmitting}>{i18n.t('sprint.contract.form.save')}</LoadingButton>
                                </Box>
                        </Grid>
                    </Grid>
                </Form>
            </FormikProvider >
        </div>
    )
}



const mapStateToProps = state => {
    return ({
        contract: state.contract?.contractDetailsView,
        customers: state.customer?.customers
    })
}

const mapDispatchToProps = (dispatch) => {
    return {
        addContract: (data) => dispatch(handleAddContract(data)),
        getViewContract: (id) => dispatch(getViewContract(id)),
        updateContract: (data) => dispatch(handleUpdateContract(data)),
        getCustomers: (page, limit) => dispatch(getCustomers(page, limit))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(FormContract);