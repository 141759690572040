import React, { useState, useRef, useEffect } from "react";
import { Box, Chip, Button, IconButton, CircularProgress } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { i18n } from "../../../translate/i18n";
import { connect } from "react-redux";
import * as actions from "../../../store/actions/users";
import * as actions2 from "../../../store/actions/messages";
import { api } from "../../../config";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { isMobile } from "react-device-detect";
import AddIcon from '@mui/icons-material/Add';
import ActionsDrawer from "./ActionsDrawer";
import PopoverContent from "./PopoverContent";
import { toast } from "react-toastify";
import userIcon from "../../../assets/icone_usuario.png";
import RichTextInputWithMentions from "./RichTextInputWithMentions";
import { containsOnlyEmptyTags } from "../../../pages/Chat/utils/containsOnlyEmptyTags";
import groupIcon from "../../../assets/groupIcon.png";

const ChatInput = ({ handleInputChange, handleSendMessage, getUsersShortInfo, users, customer, platform, thread, messageToEdit, updateMessage, localEditMessage }) => {
  const [isDragging, setIsDragging] = useState(false);
  const [attachedFiles, setAttachedFiles] = useState([]);
  const fileInputRef = useRef(null);
  const [editorContent, setEditorContent] = useState('');
  const [loading, setLoading] = useState(false);
  const [openPopover, setOpenPopover] = useState(false);
  const [popoverAnchor, setPopoverAnchor] = useState(null);
  const [openActionDrawer, setOpenActionDrawer] = useState(false);
  const [selectedAction, setSelectedAction] = useState(null);
  const [isFocused, setIsFocused] = useState(false);
  const [taggedUsers, setTaggedUsers] = useState([]);
  const location = useLocation();
  let wakeLock = null;

  const requestWakeLock = async () => {
    if ('wakeLock' in navigator && 'request' in navigator.wakeLock) {
      try {
        wakeLock = await navigator.wakeLock.request('screen');
      } catch (error) {
      }
    }
  };

  const releaseWakeLock = () => {
    if (wakeLock) {
      wakeLock.release();
      wakeLock = null;
    }
  };

  const handlePaste = (e) => {
    
    const items = (e.clipboardData || e.originalEvent.clipboardData).items;
    const pastedFiles = [];
    
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      if (item.kind === 'file') {
        const file = item.getAsFile();
        pastedFiles.push(file);
      }
      setAttachedFiles((prevFiles) => [...prevFiles, ...pastedFiles]);
    }
  }

  useEffect(() => {
    requestWakeLock();

    return () => {
      releaseWakeLock();
    };
  }, []);

  useEffect(() => {
    return () => {
      releaseWakeLock();
    };
  }, [location.pathname]);

  const handleFileDrop = (e) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    setIsDragging(false);
    setAttachedFiles((prevFiles) => [...prevFiles, ...files]);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setIsDragging(false);
  };

  const handleFileRemove = (file) => {
    setAttachedFiles((prevFiles) => prevFiles.filter((f) => f !== file));
  };

  const handleFileInputClick = () => {
    fileInputRef.current.click();
  };

  const handleFileInputChange = (e) => {
    const files = e.target.files;
    setAttachedFiles((prevFiles) => [...prevFiles, ...files]);
    e.target.value = "";
  };

  useEffect(() => {
    handleInputChange(editorContent);
  }, [editorContent]);

  const handleKeyPress = async (e) => {
    if (e.key === "Enter" && !e.shiftKey && !loading && !isMobile) {
      setLoading(true);
      if (messageToEdit?._id && ((!thread?._id && !messageToEdit?.parent) || thread?._id === messageToEdit?.parent)) await editMessage(messageToEdit._id, editorContent);
      else await handleSendMessage(e, attachedFiles, selectedAction?._id, taggedUsers);
      setSelectedAction(null);
      setAttachedFiles([]);
      setEditorContent("");
      setTaggedUsers([]);
      setLoading(false);
    }
  };

  const handleSend = async (e) => {
    if (loading) return;
    setLoading(true);
    if (messageToEdit?._id && ((!thread?._id && !messageToEdit?.parent) || thread?._id === messageToEdit?.parent)) await editMessage(messageToEdit._id, editorContent, e);
    else await handleSendMessage(e, attachedFiles, selectedAction?._id, taggedUsers);
    setSelectedAction(null);
    setAttachedFiles([]);
    setEditorContent("");
    setTaggedUsers([]);
    setLoading(false);
  };

  const editMessage = async (id, text, e) => {
    e?.preventDefault();
    if (containsOnlyEmptyTags(text)) return;
    const response = await updateMessage(id, text);
    localEditMessage(null);
    setEditorContent('')
    if (response.error) {
      toast.error(response.error.message);
    } else {
      toast.success(response.message);
    }
  };

  const isEditable = () => {
    const isParent = messageToEdit?.parent === thread?._id;
    const isPlatform = messageToEdit?.platform === platform;
    return messageToEdit?._id && (isParent || (!messageToEdit?.parent && !thread?._id && isPlatform));
  };

  useEffect(() => {
    if (customer) getUsersShortInfo({ name: "", customer, platform: platform ? platform : thread?.platform});
    localEditMessage(null);
  }, [customer, platform]);

  useEffect(() => {
    if (isEditable()) setEditorContent(messageToEdit.text?.
      replace(/\s+$/, '')?.
      replace(/@~~~([^~]+)~~~/g, (match, username) => {
        return `@${username.replace(/[^a-zA-Z0-9]/g, '')}`;
      }));
    else setEditorContent('');
  }, [messageToEdit, thread]);

  return (
    <>
      <PopoverContent
        customer={customer}
        open={openPopover}
        setOpen={setOpenPopover}
        anchor={popoverAnchor}
        setAnchor={setPopoverAnchor}
        handleFileInputChange={handleFileInputChange}
        handleFileInputClick={handleFileInputClick}
        fileInputRef={fileInputRef}
        setOpenActionDrawer={setOpenActionDrawer}
      />
      <ActionsDrawer
        open={openActionDrawer}
        setOpen={setOpenActionDrawer}
        customer={customer || thread?.customer}
        platform={platform}
        thread={thread}
        setSelectedAction={setSelectedAction}
        selectedAction={selectedAction}
      />
      <Box 
        id="chat-input"
        sx={{ 
          position: isMobile ? "fixed" : "static", 
          bottom: isFocused ? '0' : '4.5rem', 
          left: 0, 
          right: 0, 
          zIndex: 998,
          paddingX: !isMobile &&'1rem',
          paddingY: !isMobile &&'1rem',
          backgroundColor: isMobile ? "white" : "transparent",
        }}
      >
        <div 
          style={{ position: "relative"}}
          onPaste={handlePaste}
        >
          <Box
            component="form"
            sx={{
              display: "flex",
              flexDirection: isMobile ? (isFocused || loading || attachedFiles.length > 0 || selectedAction) ? "column-reverse" :"row" : "column",
              borderRadius: !isMobile && '5px',
              borderTopLeftRadius: isMobile && "12px",
              borderTopRightRadius: isMobile && "12px",
              alignItems: isMobile ? "top" : "center",
              px: isMobile ? 1 : 2,
              paddingTop: 1,
              marginBottom: 0,
              borderTop: isMobile && "1px solid #C9CCCF",
            }}
            onDrop={handleFileDrop}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
          >
            {
              (isMobile && !isFocused) && (
                <Box display="flex"  justifyContent="space-between" >
                  <Box display="flex">
                    {
                      isEditable() ? (
                        <IconButton 
                          size="small" 
                          sx={{ color: '#FAFAFA', background: '#00D6CF', height: '2rem', width: '2rem'}}
                          onClick={() => {
                            localEditMessage(null);
                            setEditorContent('')
                          }}
                        >
                          <CloseIcon />
                        </IconButton>
                      ) : (
                        <IconButton 
                          size="small" 
                          sx={{ color: '#b0b0b0', background: '#f3f3f3', height: '2rem', width: '2rem'}}
                          onClick={(e) => {
                            e.stopPropagation();
                            setPopoverAnchor(document.getElementById('chat-input'));
                            setOpenPopover(true);
                          }}
                        >
                          <AddIcon />
                        </IconButton>
                      )
                    }
                  </Box>
                  {
                    (!isFocused && (attachedFiles.length > 0 || selectedAction)) && (
                      <IconButton
                        type="submit"
                        variant="contained"
                        onClick={handleSend} 
                        disabled={loading}
                        sx={{ padding: 0, marginRight: '1rem' }}
                      >
                        {
                          loading ? 
                          <CircularProgress size={20} /> 
                          : 
                          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1.88717 5.56526L2.42068 6.62083C2.76041 7.29444 2.92966 7.63055 2.92966 8C2.92966 8.36945 2.76041 8.70556 2.42068 9.37778L1.88717 10.4347C0.368816 13.4445 -0.390361 14.9487 0.200791 15.6959C0.79317 16.4417 2.19624 15.7473 4.99992 14.3584L12.6972 10.5445C14.8986 9.45417 16 8.90834 16 8C16 7.09166 14.8986 6.54583 12.6972 5.45554L4.99992 1.64163C2.19624 0.252735 0.79317 -0.441713 0.200791 0.304124C-0.390361 1.04996 0.368816 2.55414 1.88717 5.56526Z" fill="#888888"/>
                          </svg>
                        }
                      </IconButton>
                    )
                  }
                </Box>
              )
            }
            <RichTextInputWithMentions
              setTaggedUsers={setTaggedUsers}
              getUsersShortInfo={getUsersShortInfo}
              platform={platform}
              customer={customer}
              thread={thread}
              setIsFocused={setIsFocused}
              isDragging={isDragging}
              isFocused={isFocused}
              value={editorContent}
              onChange={(value) => setEditorContent(value)}
              onKeyPress={handleKeyPress}
              setOpenPopover={setOpenPopover}
              setPopoverAnchor={setPopoverAnchor}
              users={[
                {
                  id: "all",
                  value: "Todos",
                  img: groupIcon,
                },
                ...(users?.docs.map((user) => ({
                id: user._id,
                value: user.name,
                img: user.fileLocation ? user.fileLocation : user.fileName ? `${api}/files/users/${user.fileName}` : userIcon,
                })) || [])
              ]}
              isEdit={isEditable()}
              isEditButton={()=>(
                <IconButton 
                  size="small" 
                  sx={{ 
                    display: 'flex !important',
                    justifyContent: 'center !important',
                    color: '#FAFAFA !important', 
                    background: '#00D6CF !important', 
                    height: '25px !important', 
                    width: '25px !important'
                  }}
                  onClick={() => {
                    localEditMessage(null);
                    setEditorContent('')
                  }}
                >
                  <CloseIcon />
                </IconButton>
              )}
              mobileSendButton={()=>(
                <IconButton
                  type="submit"
                  variant="contained"
                  onClick={handleSend} 
                  disabled={loading}
                  sx={{
                    marginLeft: 'auto !important',
                    alignContent: 'center !important',
                    display: 'flex !important',
                  }}
                >
                  {
                    loading ? 
                    <CircularProgress size={20} /> 
                    : 
                    <svg width="20" height="20" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M1.88717 5.56526L2.42068 6.62083C2.76041 7.29444 2.92966 7.63055 2.92966 8C2.92966 8.36945 2.76041 8.70556 2.42068 9.37778L1.88717 10.4347C0.368816 13.4445 -0.390361 14.9487 0.200791 15.6959C0.79317 16.4417 2.19624 15.7473 4.99992 14.3584L12.6972 10.5445C14.8986 9.45417 16 8.90834 16 8C16 7.09166 14.8986 6.54583 12.6972 5.45554L4.99992 1.64163C2.19624 0.252735 0.79317 -0.441713 0.200791 0.304124C-0.390361 1.04996 0.368816 2.55414 1.88717 5.56526Z" fill="#888888"/>
                    </svg>
                  }
                </IconButton>
              )}
              desktopSendButton={()=>(
                <Button
                  id="custom-button"
                  type="submit"
                  className="btn-action"
                  sx={{
                    color: "white !important",
                    backgroundColor: "#00D6CF !important",
                    border: "1px solid #00D6CF !important",
                    borderRadius: "8px",
                    boxShadow: "0px 2px 1.7000000476837158px -2px #00000033, 0px 0px 0.699999988079071px 0px #00000024, 0px 4px 9.100000381469727px 0px #0000001F !important",
                    textTransform: "none !important",
                    fontWeight: "400",
                    padding: "10px 16px !important",
                    marginLeft: 'auto !important',
                    width: '71px !important',
                    height: '36px !important',
                    '&:hover': {
                      backgroundColor: '#39985F !important',
                    },
                    alignContent: 'center !important',
                    display: 'flex !important',
                  }}
                  variant="contained"
                  disabled={loading}
                  onClick={handleSend} >
                  {
                    loading ? 
                    <CircularProgress size={20} /> 
                    : 
                    <>
                      {i18n.t('components.chat.chatInput.send')}
                    </>
                  }
                </Button>
              )}
            />
          </Box>
          {(attachedFiles.length > 0 || selectedAction) && (
            <Box
              sx={{
                backgroundColor: "white",
                display: "flex",
                flexWrap: "wrap",
                gap: 1,
                px: 2,
                paddingY: 1,
                marginTop: "0px",
              }}
            >
              {attachedFiles.map((file) => (
                <Chip
                  key={file.name}
                  label={file.name}
                  onDelete={() => handleFileRemove(file)}
                  deleteIcon={<CloseIcon />}
                />
              ))}
              {
                selectedAction && (
                  <Chip
                    label={selectedAction.name}
                    onDelete={() => setSelectedAction(null)}
                    deleteIcon={<CloseIcon />}
                  />
                )
              }
            </Box>
          )}
        </div>
      </Box>
    </>
  );
};

const mapStateToProps = state =>
({
  users: state.user.users,
  messageToEdit: state.message.messageToEdit,
})

export default connect(mapStateToProps, {...actions, ...actions2})(ChatInput);
