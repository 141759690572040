export const calculateTimeArea = (actions, range, start = false) => {
  const startedDate = new Date(range[0]);
  const endedDate = new Date(range[1]);

  // Calculate the number of days between the start and end dates
  let diffInDays =
    Math.floor((endedDate - startedDate) / (1000 * 60 * 60 * 24)) + 1;
  if (!diffInDays || diffInDays < 0) return;

  // Generate an array of dates for the column headers
  const dates = [...Array(diffInDays)].map((_, i) => {
    const date = new Date(startedDate);
    date.setDate(i + 1);
    return date;
  });
  // Generate an array of row data for each action
  const generateRowData = (action, implementation = false) => {
    let { name, start, end, status, executionTime, _id } = action;
    const execution = new Date(start);
    if (implementation && start) {
      start = new Date(start);
      end = end ? new Date(end) : null;
    } else {
      start = new Date(start);
      end = (status === "Ativo" && !end) ? new Date() : new Date(end);
    }
    execution.setDate(start.getDate() - (7 * executionTime));

    const startDate = Math.ceil(
      (start - startedDate) / (1000 * 60 * 60 * 24)
    );
    const executionDate = Math.ceil(
      (execution - startedDate) / (1000 * 60 * 60 * 24)
    );

    let endDate = end ? Math.ceil((end - startedDate) / (1000 * 60 * 60 * 24)) : null;
    if (implementation && !end && start) endDate = dates.length
    const year = start.getFullYear();
    return { name, startDate, endDate, year, status, executionDate, _id, ...action };
  };
  
  let defaultRows = []
  let activeRows = []
  let monthsObj = []
  if (!start) {
    defaultRows = actions
      .filter((action) => action.status !== "Ativo" && action.end)
      .map(generateRowData)
    activeRows = actions
      .filter((action) => action.status == "Ativo")
      .map(generateRowData);
    monthsObj = dates.reduce((acc, date) => {
      const month = date.toLocaleString(localStorage.getItem('i18nextLng') || "pt-BR", { month: "short" }).substring(0, 3).toUpperCase();
      return { ...acc, [month]: (acc[month] || 0) + 1 };
    }, {});
  } else {
    defaultRows = actions.map(e => (
      { ...generateRowData(e, start) }
    ))
    monthsObj = dates.reduce((acc, date) => {
      const month = date.toLocaleString(localStorage.getItem('i18nextLng') || "pt-BR", { month: "short" }).substring(0, 3).toUpperCase();
      return { ...acc, [month]: (acc[month] || 0) + 1 };
    }, {});
  }
  const monthsArr = Object.entries(monthsObj).map(([name, days]) => ({
    name,
    days,
  }));

  return { dates, defaultRows, activeRows, months: monthsArr };
};

export const calculateSingleTimeArea = (startDate, endDate, range) => {
  const startedDate = new Date(range[0]);
  const endedDate = new Date(range[1]);

  const diffInDays = Math.floor((endedDate - startedDate) / (1000 * 60 * 60 * 24)) + 1;
  if (!diffInDays || diffInDays < 0) return;

  const start = new Date(startDate);
  const end = endDate ? new Date(endDate) : null;

  const startDateIndex = Math.ceil((start - startedDate) / (1000 * 60 * 60 * 24));

  let endDateIndex = Math.ceil((end - startedDate) / (1000 * 60 * 60 * 24));
  
  const year = startedDate.getFullYear();

  const singleRow = {
    name: "Checklist",
    status: "Concluído",
    _id: "Default ID",
    startDate: startDateIndex,
    endDate: endDateIndex,
    year: year,
    executionDate: startDateIndex,
  };

  return singleRow;
};
