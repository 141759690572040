import axios from 'axios';
import { api } from '../../config'
import { getHeaders } from './localStorage'
import errorHandling from './errorHandling'

export const updateUnfinishedActionsCount = async (userId, actionType) => {
  try {
    await axios.patch(api + `/users/updateUnfinishedActionsCounter/${actionType}`, {userId}, getHeaders()).catch()
  } catch (err) {
    return errorHandling(err);
  }
}

export const addAction = (props) => async () => {
  try {
    const response = await axios.post(api + `/actions`, props, getHeaders());
    return response.data
  } catch (err) {
    return errorHandling(err);
  }
}

export const uploadActionFile = (id, file) => async () => {
  try {
    const response = await axios.post(api + `/actions/files/${id}`, file, getHeaders());
    return response.data
  } catch (err) {
    return errorHandling(err);
  }
}

export const uploadActivitiesFile = (id, file) => async () => {
  try {
    const response = await axios.post(api + `/actions/activities-files/${id}`, file, getHeaders());
    return response.data
  } catch (err) {
    return errorHandling(err);
  }
}

export const getActions = (query, dispatchActions = true) => async (dispatch) => {
  try {
    const response = await axios.get(api + `/actions/?${query}`, getHeaders());
    if (dispatchActions) dispatch({ type: "GET_ACTIONS", payload: response.data.actions });
    return response.data.actions
  } catch (err) {
    return errorHandling(err);
  }
}

export const getActionsContract = (query) => async (dispatch) => {
  try {
    const response = await axios.get(api + `/actions-contract/?${query}`, getHeaders());
    dispatch({ type: "GET_ACTIONS_CONTRACT", payload: response.data.actions });
  } catch (err) {
    return errorHandling(err);
  }
}

export const getAction = (id) => async (dispatch) => {
  try {
    const response = await axios.get(api + `/actions/${id}`, getHeaders());
    dispatch({ type: "GET_ACTION", payload: response.data.action });
  } catch (err) {
    return errorHandling(err);
  }
}

export const deleteAction = (params, userId) => async () => {
  try {
    const response = await axios.delete(api + `/actions/${params}`, getHeaders());
    return response.data
  } catch (err) {
    return errorHandling(err);
  }
}

export const putAction = (query, props) => async () => {
  try {
    const response = await axios.put(api + `/actions/${query}`, props, getHeaders());
    return response.data
  } catch (err) {
    return errorHandling(err);
  }
}

export const putActionActivity = (action, activity, props) => async () => {
  try {
    const response = await axios.put(api + `/actions/activities/${action}/${activity}`, props, getHeaders());
    return response.data
  } catch (err) {
    return errorHandling(err);
  }
}

export const getCycle = (cycle) => (dispatch) => {
  try {
    dispatch({ type: "GET_CYCLE", payload: cycle });
  } catch (err) {
    return errorHandling(err);
  }
}

export const getFilterParams = (params) => (dispatch) => {
  try {
    dispatch({ type: "GET_FILTER_PARAMS", payload: params });
  } catch (err) {
    return errorHandling(err);
  }
}

export const getActionsModel = () => async (dispatch) => {
  try {
    const response = await axios.get(api + `/actions-model?language=${localStorage.getItem('i18nextLng')}`, getHeaders());
    dispatch({ type: "GET_ACTIONS_MODEL", payload: response.data.actions });
  } catch (err) {
    return errorHandling(err);
  }
}

export const getActionModel = (id) => async (dispatch) => {
  try {
    const response = await axios.get(api + `/actions-model/${id}`, getHeaders());
    dispatch({ type: "GET_ACTION_MODEL", payload: response.data.action });
  } catch (err) {
    return errorHandling(err);
  }
}

export const getHealthScore = (tri) => async (dispatch) => {
  try {
    const response = await axios.get(api + `/health-score/${tri}`, getHeaders());
    dispatch({ type: "GET_HEALTH_SCORE", payload: response.data });
  } catch (err) {
    return errorHandling(err);
  }
}

export const getActionsChecklist = (pageCurrent, limit, query, search, role) => async (dispatch) => {
  try {
    const response = await axios.get(api + `/actions-checklist?page=${pageCurrent}&query=${query}&limit=${limit}&search=${search}&role=${role}`, getHeaders());
    dispatch({ type: "GET_ACTIONS_CHECKLIST", payload: response.data });
  } catch (err) {
    return errorHandling(err);
  }
}

export const getViewActionChecklist = (id) => async (dispatch) => {
  try {
    const response = await axios.get(`${api}/actions-checklist/${id}`, getHeaders());
    dispatch({ type: 'GET_ACTION_CHECKLIST', payload: response.data })
    dispatch({ type: 'GET_ACTIONS_CHECKLIST_INFOS', payload: response.data })
  } catch (error) {
    return errorHandling(error);
  }
}

export const handleUpdateActionChecklist = (id, props) => async () => {
  try {
    const response = await axios.put(api + "/actions-checklist/" + id, props, getHeaders());
    return response.data;
  } catch (err) {
    return errorHandling(err);
  }
}

export const handleUpdateActionsTrimester = (props) => async () => {
  try {
    const response = await axios.put(api + "/trimester", props, getHeaders());
    return response.data;
  } catch (err) {
    return errorHandling(err);
  }
}

export const deleteActionFile = (id, fileName) => async () => {
  try {
    const response = await axios.delete(api + `/actions-file/${id}/${fileName}`, getHeaders());
    return response.data;
  } catch (err) {
    return errorHandling(err);
  }
}

export const getCampaigns = () => async (dispatch) => {
  const response = await axios.get(api + `/campaigns`, getHeaders());
  dispatch({ type: "GET_CAMPAIGNS", payload: response.data.campaigns });
}

export const getActionsByCampaign = (id) => async (dispatch) => {
  const response = await axios.get(api + `/actions/campaign/${id}`, getHeaders());
  dispatch({ type: "GET_ACTIONS", payload: response.data.actions });
}

export const handleAddCampaign = (props) => async () => {
  try {
    const response = await axios.post(api + `/campaigns`, props, getHeaders());
    return response.data;
  } catch (err) {
    return errorHandling(err);
  }
}

export const handleUpdateCampaign = (id, props) => async () => {
  try {
    const response = await axios.put(api + `/campaigns/${id}`, props, getHeaders());
    return response.data;
  } catch (err) {
    return errorHandling(err);
  }
}

export const updateCampaignName = (id, name) => async () => {
  try {

    const response = await axios.put(api + `/campaigns/name/${id}`, { name }, getHeaders());
    return response.data;
  } catch (err) {
    return errorHandling(err);
  }
}

export const deleteCampaign = (id) => async () => {
  try {
    const response = await axios.delete(api + `/campaigns/${id}`, getHeaders());
    return response.data;
  } catch (err) {
    return errorHandling(err);
  }
}

export const syncActionWithJira = (id) => async () => {
  try {
    const response = await axios.post(api + `/actions/sync/${id}`, {}, getHeaders());
    return response.data;
  } catch (err) {
    return errorHandling(err);
  }
}

export const unbindActionFromJira = (id) => async () => {
  try {
    const response = await axios.post(api + `/actions/unbind/${id}`, {}, getHeaders());
    return response.data;
  } catch (err) {
    return errorHandling(err);
  }
}

export const getActivityWithKeyJira = (key) => async (dispatch) => {
  try {
    const response = await axios.get(api + `/actions/activity/jira/${key}`, getHeaders());
    dispatch({ type: "GET_ACTION", payload: response.data.action });
  } catch (err) {
    return errorHandling(err);
  }
}

export const linkActionToJira = (id, props) => async () => {
  try {
    const response = await axios.put(api + `/actions/linktojira/${id}`, props, getHeaders());
    return response.data
  } catch (err) {
    return errorHandling(err);
  }
}

export const syncChargedActions = (cycle) => async () => {
  try {
    const response = await axios.get(api + `/actions/sync-charged/${cycle}`, getHeaders());
    return response.data;
  } catch (error) {
    return errorHandling(error);
  }
}

export const getDebitedActions = (cycle, customer, platform, createdAt) => async (dispatch) => {
  try {
    const response = await axios.get(api + `/debited-actions-trimester?cycle=${cycle}&customer=${customer}&platform=${platform}&createdAt=${createdAt}`, getHeaders());
    return response.data;
  } catch (error) {
    return errorHandling(error);
  }
}

export const updateActivityStatus = (id, status, user) => async () => {
  try {
    const response = await axios.put(api + `/actions/activity/status/${id}`, { status, user }, getHeaders());
    return response.data;
  } catch (error) {
    return errorHandling(error);
  }
}

export const sendActivityToApproval = (id, formData) => async () => {
  try {
    const response = await axios.put(api + `/actions/activity/approval/${id}`, formData, getHeaders());
    return response.data;
  } catch (error) {
    return errorHandling(error);
  }
}
