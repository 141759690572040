import {
  Box,
  Typography,
  Button,
  Icon,
  Divider,
  CircularProgress,
  TextField,
  MenuItem,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Modal, ModalBody } from "reactstrap";
import { toast } from "react-toastify";
import * as actions from "../../../store/actions/action";
import * as actions2 from '../../../store/actions/aiContent';
import { i18n } from '../../../translate/i18n';

const SendToJiraModal = (props) => {
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState(0);
  const [activity, setActivity] = useState({});
  const [jiraComment, setJiraComment] = useState("");
  const [title, setTitle] = useState("");

  const handleClick = async () => {
    if (loading) return;
    setLoading(true);

    try {
      if (value === 2 && !activity._id) {
        toast.error(i18n.t('aiContent.utils.searchToJiraModal.selectActivity'));
        setLoading(false);
        return;
      }

      let keyJira = {};
      if (value > 0) {
        keyJira.action = props.action._id;
      }
      if (value > 1) {
        keyJira.activity = activity._id;
      }
      const response = await props.store({ title, ...keyJira }, jiraComment);
      props.setOpen(false);
      setLoading(false);

      if (response.error) {
        switch (response.error.message) {
          case 'Erro ao enviar arquivo para o Jira!': return toast.error(i18n.t('aiContent.utils.searchToJiraModal.sendJiraError'));
          case 'Erro na integração com Jira!': return toast.error(i18n.t('aiContent.utils.searchToJiraModal.integrationJiraError'))
          case 'Erro interno do servidor.': return toast.error(i18n.t('aiContent.utils.searchToJiraModal.internalError'))
          default: return toast.error(response.error.message);
        }
      } else {
        if (response.message === 'Conteúdo armazenado com sucesso') {
          toast.success(i18n.t('aiContent.utils.searchToJiraModal.storeSuccess'));
        } else {
          toast.success(response.message);
        }
      }
    } catch (error) {
      setLoading(false);
      if (error.error.message === 'Erro interno do servidor.') {
        toast.error(i18n.t('aiContent.utils.searchToJiraModal.internalError'));
      } else {
        toast.error(error.error.message);
      }
    }

    setLoading(false);
  };

  useEffect(() => {
    setValue(0);
    setActivity({});
    setJiraComment("");
  }, [props.action]);

  return (
    <Modal
      centered
      toggle={() => props.setOpen(false)}
      isOpen={props.open}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          padding: 2,
          alignItems: "center"
        }}
      >
        <Typography variant="h3">{i18n.t('aiContent.utils.searchToJiraModal.relation')}</Typography>
        <Icon
          sx={{
            color: "#86888c",
            cursor: "pointer",
          }}
          onClick={() => props.setOpen(false)}
          className="material-icons-outlined"
          fontSize="large"
        >
          cancel
        </Icon>
      </Box>
      <Divider />
      <ModalBody>
        <Box display={"flex"} flexDirection={"column"} gap={"1rem"}>
          <TextField
            fullWidth
            label={i18n.t('aiContent.utils.searchToJiraModal.title')}
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            size='small'
          />
          <TextField
            fullWidth
            label={i18n.t('aiContent.utils.searchToJiraModal.associate')}
            select
            value={value}
            onChange={(e) => {
              setValue(e.target.value);
              setJiraComment("");
              setActivity({});
            }}
            size='small'
          >
            <MenuItem value={0}><i>{i18n.t('aiContent.utils.searchToJiraModal.none')}</i></MenuItem>
            <MenuItem value={1} disabled={!props?.action}>{i18n.t('aiContent.utils.searchToJiraModal.action')}</MenuItem>
            <MenuItem value={2} disabled={!props?.action}>{i18n.t('aiContent.utils.searchToJiraModal.activity')}</MenuItem>
          </TextField>
          <TextField
            fullWidth
            label={i18n.t('aiContent.utils.searchToJiraModal.activity2')}
            select
            value={activity}
            disabled={value !== 2 || props?.action?.activities?.length < 1}
            onChange={(e) => setActivity(e.target.value)}
            size='small'
          >
            {
              props.action?.activities?.map((activity) => (
                <MenuItem value={activity}>{activity.activity.name}</MenuItem>
              ))
            }
          </TextField>
        </Box>
        <Box display={"flex"} flexDirection={"column"} gap={"1rem"} mt={"1rem"}>
          <TextField
            fullWidth
            label={i18n.t('aiContent.utils.searchToJiraModal.commentJira')}
            disabled={value === 0}
            multiline
            rows={4}
            value={jiraComment}
            onChange={(e) => setJiraComment(e.target.value)}
          />
          <Button variant="contained" className="btn-action" onClick={handleClick} sx={{ width: "fit-content" }}>
            {loading ? (
              <CircularProgress size={25} sx={{ color: "white" }} thickness={4} />
            ) : (
              <>{i18n.t('aiContent.utils.searchToJiraModal.save')}</>
            )}
          </Button>
        </Box>
      </ModalBody>
    </Modal>
  )
}

const mapStateToProps = (state) => ({
  // action: state.actions.action,
});

export default connect(mapStateToProps, { ...actions, ...actions2 })(SendToJiraModal)