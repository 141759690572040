import React from "react";
import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { useField } from 'formik';
import { connect } from "react-redux";
import * as actions from "../../../../store/actions/action";
import { toast } from "react-toastify";
import { i18n } from "../../../../translate/i18n";
import { Box, Typography } from "@mui/material";

const filter = createFilterOptions();

const FreeSoloCreateOption = (props) => {
  const [campaign, , campaignHelpers] = useField("campaign");

  const handleKeyPress = async (event, newValue) => {
    try {
      if (event.key === 'Enter') {
        event.preventDefault();
        const response = await props.handleAddCampaign({ name: event.target.value });
        if (response.error) {
          switch (response.error.message) {
            case "Erro ao criar a campanha.": return toast.error(i18n.t('sprint.newAction.utils.freeSoloCreateOption.errorCreate'));
            case "Erro interno do servidor.": return toast.error(i18n.t('sprint.newAction.utils.freeSoloCreateOption.internalError'));
            default: toast.error(response.error.message);
          }
        } else {
          if (response.message === "Campanha criada com sucesso!") {
            toast.success(i18n.t('sprint.newAction.utils.freeSoloCreateOption.campaignCreated'))
          } else {
            toast.success(response.message)
          }
        }
        // Create a new value from the user input
        campaignHelpers.setValue(response.campaign);
        props.getCampaigns();
      }
    } catch (error) {
      if (error.error.message === "Erro interno do servidor.") {
        toast.error(i18n.t('sprint.newAction.utils.freeSoloCreateOption.internalError'))
      } else {
        toast.error(error.error.message);
      }
    }
  };

  return (
    <Box width='100%'>
      <Typography variant='body2' mb='0.5rem' color={'#000'}>{i18n.t('sprint.newAction.utils.freeSoloCreateOption.campaign')}</Typography>
      <Autocomplete
        size={props.isMobile ? "small" : "medium"}
        fullWidth
        disabled={props.disabled}
        value={campaign.value}
        onChange={async (event, newValue) => {
          try {
            if (typeof newValue === 'string') {
              campaignHelpers.setValue({ name: newValue });
            } else if (newValue && newValue.inputValue) {
              const response = await props.handleAddCampaign({ name: newValue.inputValue });
              if (response.error) {
                switch (response.error.message) {
                  case "Erro ao criar a campanha.": return toast.error(i18n.t('sprint.newAction.utils.freeSoloCreateOption.errorCreate'));
                  case "Erro interno do servidor.": return toast.error(i18n.t('sprint.newAction.utils.freeSoloCreateOption.internalError'));
                  default: toast.error(response.error.message);
                }
              } else {
                if (response.message === "Campanha criada com sucesso!") {
                  toast.success(i18n.t('sprint.newAction.utils.freeSoloCreateOption.campaignCreated'))
                } else {
                  toast.success(response.message)
                }
              }
              // Create a new value from the user input
              campaignHelpers.setValue(response.campaign);
              props.getCampaigns();
            } else {
              campaignHelpers.setValue(newValue);
            }
          } catch (error) {
            if (error.error.message === "Erro interno do servidor.") {
              toast.error(i18n.t('sprint.newAction.utils.freeSoloCreateOption.internalError'))
            } else {
              toast.error(error.error.message);
            }
          }
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);

          const { inputValue } = params;
          // Suggest the creation of a new value
          const isExisting = options.some((option) => inputValue === option.name);
          if (inputValue !== '' && !isExisting) {
            filtered.push({
              inputValue,
              name: `Criar nova campanha "${inputValue}"`,
            });
          }

          return filtered;
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        id="free-solo-with-text"
        options={props.campaigns}
        getOptionLabel={(option) => {
          // Value selected with enter, right from the input
          if (typeof option.name === 'string') {
            return option.name;
          }
          // Add "xxx" option created dynamically
          if (option.inputValue) {
            return option.inputValue;
          }
          // Regular option
          return '';
        }}
        renderOption={(props, option) => <li {...props}>{option.name}</li>}
        freeSolo
        renderInput={(params) => (
          <TextField
            {...params}
            onKeyPress={handleKeyPress}
            sx={{
              '& .MuiInputBase-root': {
                border: `0.5px solid #888888`,
                boxShadow: '0px 1px 0px 0px #00000040 inset',
                borderRadius: '8px',
              },
              '& fieldset': {
                border: 'none',
              },
            }}
          />
        )}
      />
    </Box>
  );
}

export default connect((state) => ({ campaigns: state.actions.campaigns, }), actions)(FreeSoloCreateOption);