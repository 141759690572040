import React from 'react'
import { Route } from 'react-router-dom';

import Logistics from '../pages/Apps/Logistics';
import Service from '../pages/Apps/Service';
import SmartShipping from '../pages/Apps/SmartShipping';
import Alerts from '../pages/Apps/Alerts';
import ExtendedCheckout from '../pages/Apps/ExtendedCheckout';

const Apps = ({ baseDashboard }) => {
  return (
    <>
      <Route path="/apps/extended-checkout" exact component={baseDashboard({ Component: ExtendedCheckout, permissions: ['admin'], })} />
      <Route path="/apps/logistics" exact component={baseDashboard({ Component: Logistics, permissions: ['admin'], })} />
      <Route path="/apps/service" exact component={baseDashboard({ Component: Service, permissions: ['admin'], })} />
      <Route path="/apps/smart-shipping" exact component={baseDashboard({ Component: SmartShipping, permissions: ['admin'], })} />
      <Route path="/apps/alerts" exact component={baseDashboard({ Component: Alerts, permissions: ['admin'], })} />
    </>
    )
}

export default Apps