import { i18n } from '../../../translate/i18n';

export const options = [
  {
    value: 0,
    label: i18n.t('aiContent.utils.config.label0'),
    description: i18n.t('aiContent.utils.config.description0')
  },
  {
    value: 1,
    label: i18n.t('aiContent.utils.config.label1'),
    description: i18n.t('aiContent.utils.config.description1')
  },
  {
    value: 2,
    label: i18n.t('aiContent.utils.config.label2'),
    description: i18n.t('aiContent.utils.config.description2')
  },
  {
    value: 3,
    label: i18n.t('aiContent.utils.config.label3'),
    description: i18n.t('aiContent.utils.config.description3')
  },
  {
    value: 4,
    label: i18n.t('aiContent.utils.config.label4'),
    description: i18n.t('aiContent.utils.config.description4')
  },
  {
    value: 5,
    label: i18n.t('aiContent.utils.config.label5'),
    description: i18n.t('aiContent.utils.config.description5')
  },
  {
    value: 6,
    label: i18n.t('aiContent.utils.config.label6'),
    description: i18n.t('aiContent.utils.config.description6')
  },
  {
    value: 7,
    label: i18n.t('aiContent.utils.config.label7'),
    description: i18n.t('aiContent.utils.config.description7')
  },
  {
    value: 8,
    label: i18n.t('aiContent.utils.config.label8'),
    description: i18n.t('aiContent.utils.config.description8')
  },
  {
    value: 9,
    label: i18n.t('aiContent.utils.config.label9'),
    description:i18n.t('aiContent.utils.config.description9')
  },
  {
    value: 10,
    label: i18n.t('aiContent.utils.config.label10'),
    description: i18n.t('aiContent.utils.config.description10')
  },
];

export const imageDescriptions = [
  {
    value: 1,
    label: i18n.t('aiContent.utils.config.label11'),
    description: i18n.t('aiContent.utils.config.description11'),
    dropdownTitle: i18n.t('aiContent.utils.config.dropdownTitle11'),
    drowpdownText: i18n.t('aiContent.utils.config.dropdownText11')
  },
  {
    value: 0,
    label: i18n.t('aiContent.utils.config.label12'),
    description: i18n.t('aiContent.utils.config.description12'),
    dropdownTitle: i18n.t('aiContent.utils.config.dropdownTitle12'),
    drowpdownText: i18n.t('aiContent.utils.config.dropdownText12')
  },
  {
    value: 2,
    label: i18n.t('aiContent.utils.config.label13'),
    description: i18n.t('aiContent.utils.config.description13')
    
  },
];

export const dimensions = [
  {width: 1024, height: 1024},
  {width: 1024, height: 1024},
  {width: 1024, height: 1024},
  {width: 832, height: 1216},
  {width: 1216, height: 832},
  {width: 768, height: 1344},
  {width: 768, height: 1344},
  {width: 1536, height: 640},
  {width: 640, height: 1536},
];
export const  formattedContent = (content) => content.split('**').map((part, index) => {
  if (index % 2 === 1) {
    return <strong key={index}>{part}</strong>;
  }
  return part;
});