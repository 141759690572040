import {
  Box,
  Button,
  Icon,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import React from "react";
import { useHistory } from "react-router-dom";
import { userPermissionCheck } from "../../pages/Sprint/utils/userPermissionCheck";
import { i18n } from "../../translate/i18n";

const OptionsButton = ({ i, action, toggleModal, user, platform }) => {
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEdit = (data) => {
    history.push(platform?.includes('sprint') ? `/edit-action/${data._id}` : `/support-edit-action/${data._id}`);
  };

  return (
    <>
      {
        (userPermissionCheck(user, ["view_action", "delete_action"]) || action.keyJira) && (
          <Box>
            <Box position={"relative"}>
              <Button
                sx={{
                  color: '#888888',
                }}
                onClick={handleClick}
              >
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M7.2 12.2C7.2 11.9347 7.30535 11.6804 7.49289 11.4928C7.68043 11.3053 7.93478 11.2 8.2 11.2C8.46521 11.2 8.71957 11.3053 8.9071 11.4928C9.09464 11.6804 9.2 11.9347 9.2 12.2C9.2 12.4652 9.09464 12.7195 8.9071 12.9071C8.71957 13.0946 8.46521 13.2 8.2 13.2C7.93478 13.2 7.68043 13.0946 7.49289 12.9071C7.30535 12.7195 7.2 12.4652 7.2 12.2ZM7.2 8.19995C7.2 7.93473 7.30535 7.68038 7.49289 7.49284C7.68043 7.30531 7.93478 7.19995 8.2 7.19995C8.46521 7.19995 8.71957 7.30531 8.9071 7.49284C9.09464 7.68038 9.2 7.93473 9.2 8.19995C9.2 8.46517 9.09464 8.71952 8.9071 8.90706C8.71957 9.09459 8.46521 9.19995 8.2 9.19995C7.93478 9.19995 7.68043 9.09459 7.49289 8.90706C7.30535 8.71952 7.2 8.46517 7.2 8.19995ZM7.2 4.19995C7.2 3.93473 7.30535 3.68038 7.49289 3.49284C7.68043 3.30531 7.93478 3.19995 8.2 3.19995C8.46521 3.19995 8.71957 3.30531 8.9071 3.49284C9.09464 3.68038 9.2 3.93473 9.2 4.19995C9.2 4.46517 9.09464 4.71952 8.9071 4.90706C8.71957 5.09459 8.46521 5.19995 8.2 5.19995C7.93478 5.19995 7.68043 5.09459 7.49289 4.90706C7.30535 4.71952 7.2 4.46517 7.2 4.19995Z" fill="#888888"/>
                </svg>
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                {
                  userPermissionCheck(user, ["view_action"]) &&
                  <MenuItem onClick={(e) => handleEdit(action)}
                  >
                    {
                      userPermissionCheck(user, ["edit_action"]) ? (
                        i18n.t('sprint.list.optionsButton.edit')
                      ) : (
                        i18n.t('sprint.list.optionsButton.view')
                      )
                    }
                  </MenuItem>
                }
                {
                  userPermissionCheck(user, ["delete_action"]) &&
                  <MenuItem onClick={(e) => toggleModal(action._id, "delete")}
                  >
                    {i18n.t('sprint.list.optionsButton.delete')}
                  </MenuItem>
                }
                {
                  userPermissionCheck(user, ["edit_action"], "admin") && (action.keyJira || action.status === "Planejamento") &&
                  <MenuItem onClick={(e) => toggleModal(action._id, "jira")}
                  >
                    {i18n.t('sprint.list.optionsButton.integration')}
                  </MenuItem>
                }
                {
                  action.keyJira && user?.role === "admin" &&
                  <MenuItem onClick={(e) => window.open(`https://checkstore.atlassian.net/browse/${action.keyJira}`, '_blank')}>
                    {i18n.t('sprint.list.optionsButton.jira')}
                  </MenuItem>
                }
              </Menu>
            </Box>
          </Box>
        )
      }
    </>
  );
};

export default OptionsButton;
