import { Box, Button, Collapse, Grid, Paper, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { options } from '../utils/config';
import SearchActions from '../utils/SearchActions';
import { i18n } from '../../../translate/i18n'
import { FCSelect, FCTextField } from 'components-fullcomm';

const Filter = ({ actions, shop, currentPage, setCurrentPage, filterActions }) => {
  const [showFilters, setShowFilters] = useState(false);
  const [title, setTitle] = useState("");
  const [action, setAction] = useState({});
  const [type, setType] = useState("");
  const [associated, setAssociated] = useState("");

  const resetFilters = () => {
    setTitle("");
    setAction({});
    setType("");
    setCurrentPage(1);
    setAssociated("");
    filterActions();
  }
  
  useEffect(()=>{
    filterActions(title, action._id, type, associated, currentPage);
  },[shop, currentPage]);

  return (
    <Box marginBottom={1} width="100%" mt="1rem">
    <Button
      color="default"
      variant="contained"
      onClick={() => setShowFilters(!showFilters)}
      sx={{
        color: "#202223",
        background: "#FFFFFF",
        fontWeight: "400",
        border: "1px solid #babfc3",
        textTransform: "none",
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      {i18n.t('aiContent.library.filter.filter')} {showFilters ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
    </Button>
    <Grid item xs={12}>
      <Collapse in={showFilters}>
        <Paper
          className="filter-container"
          sx={{
            marginTop: "0.5rem",
            paddingX: "2rem",
            paddingY: "1rem",
            borderRadius: "10px",
          }}
        >
          <Grid container columnSpacing={4} rowSpacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6">{i18n.t('aiContent.library.filter.filterBy')}:</Typography>
            </Grid>
              <Grid item xs={12} md={6} display="flex" alignItems="center">
                <Typography marginRight={'0.5rem'}>{i18n.t('aiContent.library.filter.title')}:</Typography>
                <FCTextField
                  fullWidth
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} md={6} display="flex" alignItems="center">
                <Typography marginRight={'0.5rem'}>{i18n.t('aiContent.library.filter.action')}:</Typography>
                <SearchActions action={action} setAction={setAction} actions={actions} label={false} />
              </Grid>
              <Grid item xs={12} md={6} display="flex" alignItems="center">
                <Typography marginRight={'0.5rem'}>{i18n.t('aiContent.library.filter.type')}:</Typography>
                <FCSelect
                  fullWidth
                  value={type}
                  onChange={(e) => setType(e.target.value)}
                  options={options.map(option => ({value: option.label, label: option.label}))}
                />
              </Grid>
              {/* <Grid item xs={12} md={6} display="flex" alignItems="center">
                <Typography marginRight={'0.5rem'}>Vínculo:</Typography>
                <TextField
                  fullWidth
                  label="Vínculo"
                  size='small'
                  value={associated}
                  onChange={(e) => setAssociated(e.target.value)}
                  select
                  >
                    <MenuItem value="none">Nenhum</MenuItem>
                    <MenuItem value="action">Ação</MenuItem>
                    <MenuItem value="activity">Atividade</MenuItem>
                  </TextField>
              </Grid> */}
            <Grid item xs={12} display={"flex"} alignItems={"center"} gap={1}>
              <Button
                variant="contained"
                className="btn-action medium"
                onClick={()=>filterActions(title, action._id, type, associated, currentPage)}
              >
                {i18n.t('aiContent.library.filter.aplic')}
              </Button>
              <Button 
                className="btn-white"
                sx={{
                  height: '36px'
                }} 
                onClick={resetFilters}
              >
                {i18n.t('aiContent.library.filter.reset')}
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Collapse>
    </Grid>
  </Box>
  )
}

export default Filter