import React from 'react';
import { i18n } from '../../translate/i18n';
import { FCTextField } from 'components-fullcomm';

const DateRange = ({ begin, setBegin, end, setEnd }) => {

    const handleBeginChange = (e) => {
        setBegin(e.target.value);
    };

    const handleEndChange = (e) => {
        setEnd(e.target.value);
    };

    return (
        <>
            <FCTextField
                size='small'
                id="date-begin"
                label={i18n.t('components.date.start')}
                type="date"
                value={begin}
                onChange={handleBeginChange}
                fullWidth
            />
            <FCTextField
                size='small'
                id="date-end"
                label={i18n.t('components.date.end')}
                type="date"
                value={end}
                onChange={handleEndChange}
                fullWidth
            />
        </>
    );
};

export default DateRange;
