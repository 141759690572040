import React, { useEffect, useRef, useState } from 'react'
import { Box, Button, ButtonGroup, Table, TableCell, TableRow, Paper, TableBody, IconButton, Chip } from '@mui/material'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDownwardOutlinedIcon from '@mui/icons-material/ArrowDownwardOutlined';
import dayjs from 'dayjs';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { useTheme } from '@mui/material/styles';
import CustomPopper from './CustomPopper';
import { debounce } from 'lodash';
import { connect } from 'react-redux';
import * as actions from '../../../../store/actions/scale'
import { i18n } from '../../../../translate/i18n';
import { traslateLogisticStatus } from '../../../../translate/translates/Translates';
import { FCPagination, FCTableContainer, FCTableHeader, FCTextField } from 'components-fullcomm';

const statusData = [
  {value: 'created', label: i18n.t('scale.logistics.orders.created')},
  {value: 'error', label: i18n.t('scale.logistics.orders.error')},
  {value: 'processed', label: i18n.t('scale.logistics.orders.processed')},
  {value: 'shipment', label: i18n.t('scale.logistics.orders.shipment')},
  {value: 'shipping', label: i18n.t('scale.logistics.orders.shipping')},
  {value: 'customer', label: i18n.t('scale.logistics.orders.customer')},
];

const periodData = [
  {value: '7', label: i18n.t('scale.logistics.orders.last7')},
  {value: '15', label: i18n.t('scale.logistics.orders.last15')},
  {value: '30', label: i18n.t('scale.logistics.orders.last30')},
  {value: '90', label: i18n.t('scale.logistics.orders.last90')},
  {value: 'all', label: i18n.t('scale.logistics.orders.all')},
];

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
          <IconButton
              onClick={handleFirstPageButtonClick}
              disabled={page === 0}
              aria-label="first page"
          >
              {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
          </IconButton>
          <IconButton
              onClick={handleBackButtonClick}
              disabled={page === 0}
              aria-label="previous page"
          >
              {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
          </IconButton>
          <IconButton
              onClick={handleNextButtonClick}
              disabled={page >= Math.ceil(count / rowsPerPage) - 1}
              aria-label="next page"
          >
              {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
          </IconButton>
          <IconButton
              onClick={handleLastPageButtonClick}
              disabled={page >= Math.ceil(count / rowsPerPage) - 1}
              aria-label="last page"
          >
              {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
          </IconButton>
      </Box>
  );
}


const Orders = (props) => {
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [data, setData] = useState({})
  const [openStatus, setOpenStatus] = useState(false);
  const [openPeriod, setOpenPeriod] = useState(false);
  const anchorRef = useRef(null);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [selectedPeriod, setSelectedPeriod] = useState('7')


  const debouncedFetchData = debounce(async (selectedPeriod, selectedStatus, page, search, view) => {
      view === 'admin' ? 
      props.getAllLogisticsOrders(page, selectedPeriod, selectedStatus, search)
       : 
      props.getLogisticsOrders(page, selectedPeriod, selectedStatus, search);
  }, 1000);

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpenPeriod(false);
    setOpenStatus(false);
  };

  const handleMenuItemClick = (value) => {
    let updatedSelectedStatus;
    if (selectedStatus.indexOf(value) === -1) {
      updatedSelectedStatus = [...selectedStatus, value];
    } else {
      updatedSelectedStatus = selectedStatus.filter((item) => item !== value);
    }
    
    setSelectedStatus(updatedSelectedStatus);
  };

  useEffect(() => {
    setPage(1);
  }, [selectedPeriod, selectedStatus, search]);

  useEffect(() => {
    setData(props.view === 'admin' ? props.logisticsOrdersAll : props.logisticsOrders);
  }, [props.logisticsOrders, props.view, props.logisticsOrdersAll])

  useEffect(() => {
    debouncedFetchData(selectedPeriod, selectedStatus, page, search, props.view);
    
    return () => {
      debouncedFetchData.cancel();
    };
  }, [props.tokenApi, selectedPeriod, selectedStatus, page, search, props.view]);

  return (
    <Box padding="2rem 1rem">
      <Box display="flex" flexDirection={{xs: "column", md: "row"}} justifyContent="center" alignItems="center" gap="1.5rem" paddingX="1rem">
        <FCTextField
          fullWidth
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          searchIcon
          placeholder={i18n.t('scale.logistics.orders.filterItens')}
          />
          <Box display={"flex"} alignItems={"center"} ref={anchorRef} width={{xs: "100%", md: "auto"}}>
          <ButtonGroup variant="contained" sx={{height: "80%", borderRadius: '8px'}} color='default' fullWidth>
            <Button className="btn-gray" onClick={()=> {setOpenStatus(prev=> !prev); setOpenPeriod(false)}}>{i18n.t('scale.logistics.orders.statusType')} <ArrowDropDownIcon/></Button>
            <Button className="btn-gray" onClick={()=> {setOpenPeriod(prev=> !prev); setOpenStatus(false)}}>{i18n.t('scale.logistics.orders.period')} <ArrowDropDownIcon/></Button>
          </ButtonGroup>
          <CustomPopper
            open={openStatus}
            anchorEl={anchorRef.current}
            handleClose={handleClose}
            options={statusData}
            handleMenuItemClick={handleMenuItemClick}
            selected={selectedStatus}
            setSelected={setSelectedStatus}
            type={'status'}
          />
          <CustomPopper
            open={openPeriod}
            anchorEl={anchorRef.current}
            handleClose={handleClose}
            options={periodData}
            selected={selectedPeriod}
            setSelected={setSelectedPeriod}
            type={'period'}
          />
        </Box>
      </Box>
      <FCTableContainer component={Paper} sx={{marginTop: "1.5rem"}}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <FCTableHeader>
            <TableRow sx={{background: "#F0F0F0"}}>
              <TableCell width="5%" sx={{minWidth: "150px"}}><ArrowDownwardOutlinedIcon sx={{color: "#0000008F", width: "1rem", height: "1rem"}}/>{i18n.t('scale.logistics.orders.order')}</TableCell>
              {
                props.view === 'admin' &&
                <TableCell width={props.view === 'admin' ? '19%' : '23.75%'}>{i18n.t('scale.logistics.orders.store')}</TableCell>
              }
              <TableCell width={props.view === 'admin' ? '19%' : '23.75%'}>{i18n.t('scale.logistics.orders.data')}</TableCell>
              <TableCell width={props.view === 'admin' ? '19%' : '23.75%'}>{i18n.t('scale.logistics.orders.client')}</TableCell>
              <TableCell width={props.view === 'admin' ? '19%' : '23.75%'}>{i18n.t('scale.logistics.orders.statusTitle')}</TableCell>
              <TableCell width={props.view === 'admin' ? '19%' : '23.75%'}>{i18n.t('scale.logistics.orders.value')}</TableCell>
            </TableRow>
          </FCTableHeader>
          <TableBody>
            {data?.docs?.map((row) => {
              const {status, color} = traslateLogisticStatus(row.status)
              return (
                <TableRow
                  key={row.name}
                >
                  <TableCell><a target='_blank' href={`https://${props.customer?.platformDomain}/admin/apps/logistic-checkstore/order?id_order=${row.id}`}>{row.name}</a></TableCell>
                  {
                    props.view === 'admin' &&
                    <TableCell>{row?.idStore?.idShopify?.split('.')[0] || ''}</TableCell>
                  }
                  <TableCell>{dayjs(row.created_at).format('DD/MM/YYYY, HH:mm:ss')}</TableCell>
                  <TableCell>{row.customer?.first_name || ''} {row.customer?.last_name || ''}</TableCell>
                  <TableCell><Chip label={status} color={color}/></TableCell>
                  <TableCell>R${row?.current_total_price || 0}</TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </FCTableContainer>
      <FCPagination
        sx={{
          marginTop: "1.5rem",
        }}
        count={data?.totalDocs || 1}
        page={page}
        rowsPerPage={10}
        onChange={(e, page) => setPage(page)}
      />
    </Box>
  )
}

const mapStateToProps = (state) => {
  return {
    customer: state.customer.customerDetails,
    logisticsOrders: state.scale.logisticsOrders,
    logisticsOrdersAll: state.scale.logisticsOrdersAll,
    tokenApi: state.scale.tokenApi,
  }
}

export default connect(mapStateToProps, actions)(Orders);