const messages = {
    es: {
        translations: {
            documents: {
                index: {
                    title: "Documentos",
                    name: "Nombre",
                    date: "Fecha",
                    type: "Tipo",
                    new: "Nuevo",
                    search: "Buscar",
                    edit: "Editar",
                    delete: "Borrar",
                    deleteDocument: "Borrar documento",
                    confirmDelete: "¿Está seguro de que desea borrar el documento?",
                    cancel: "Cancelar"
                },
                form: {
                    title1: "Registrar documento",
                    title2: "Editar documento",
                    errorName: "El nombre es obligatorio.",
                    errorUrl: "La URL es obligatoria.",
                    errorDate: "La fecha es obligatoria.",
                    errorType: "El tipo es obligatorio.",
                    name: "Nombre",
                    type: "Tipo",
                    date: "Fecha",
                    save: "Guardar",
                    all: "Todos",
                    meetingNote: "Nota de reunión",
                    projectRequirements: "Requisitos de proyecto",
                    operationalRequirements: "Requisitos operacionales",
                    report: "Informe",
                    other: "Otros",
                    editSuccess: "Documento editado con éxito.",
                    saveSuccess: "Documento guardado con éxito.",
                    deleteSuccess: "Documento eliminado con éxito.",
                    internalError: "Error interno del servidor.",
                    invalidData: "Datos inválidos.",
                    documentNotExist: "Documento no encontrado.",
                }
            },
            addCustomer: {
                active: "Activo",
                inactive: "Inactivo",
                nameRequired: "El nombre es obligatorio.",
                urlValid: "Ingrese una URL válida. Ejemplo: https://www.checkstore.com.br.",
                urlRequired: "La URL de la tienda es obligatoria.",
                invalidData: "Datos inválidos.",
                registerError: "El cliente no se registró con éxito.",
                registerSuccess: "Cliente registrado con éxito.",
                internalError: "Error interno del servidor.",
                customerNotFound: "Cliente no encontrado.",
                updateError: "Se produjo un error al actualizar el cliente.",
                updateSuccess: "Cliente actualizado con éxito!",
                edit: "Editar",
                register: "Registrar",
                store: "Tienda",
                imageAllowed: "Permitido",
                name: "Nombre",
                urlStore: "URL de la tienda",
                platformFullcomm: "Plataforma",
                plats: "Plataformas",
                implantation: "Implantación",
                performance: "Rendimiento",
                operation: "Operación",
                support: "Soporte",
                platformEcommerce: "Plataforma de E-commerce",
                platEcom: "Plataformas E-commerce",
                other: "Otra",
                agency: "Agencia",
                urlSales: "URL del Resumen del Panel de Ventas",
                urlTraffic: "URL del Panel de Tráfico de Pago",
                urlCRM: "URL del Panel de CRM",
                keyJira: "Clave del proyecto Jira",
                idGa: "ID de GA",
                idFacebook: "ID del Píxel de Facebook",
                keyActive: "Clave de API de Active Campaign",
                idUptime: "ID del estado de disponibilidad",
                platformDomain: "Dominio de la plataforma",
                save: "Guardar",
                cancel: "Cancelar"
            },
            addTrialUser: {
                nameRequired: "El nombre es obligatorio.",
                emailValid: "Ingrese un correo electrónico válido.",
                emailRequired: "El correo electrónico es obligatorio.",
                phoneRequired: "El teléfono es obligatorio.",
                passwordRequired: "La contraseña es obligatoria.",
                passwordRule: "La contraseña debe contener 8 caracteres, un número y una letra mayúscula.",
                passwordEqual: "Las contraseñas deben ser iguales.",
                passwordConfirm: "Debe confirmar la contraseña.",
                invalidData: "Datos inválidos.",
                alreadyRegistered: "Este correo electrónico ya está registrado.",
                registerError: "El usuario no se registró con éxito.",
                registerSuccess: "Usuario registrado con éxito.",
                completeName: "Nombre completo",
                email: "Correo electrónico",
                phone: "Teléfono",
                password: "Contraseña",
                passwordConfirmShort: "Confirmar contraseña",
                createAccount: "Crear cuenta",
                createVia: "Crear vía:",
                hasAccount: "¿Ya tiene una cuenta? Iniciar sesión",
            },
            addUser: {
                nameRequired: "El nombre es obligatorio.",
                emailValid: "Ingrese un correo electrónico válido.",
                emailRequired: "El correo electrónico es obligatorio.",
                invalidData: "Datos inválidos.",
                alreadyRegistered: "Este correo electrónico ya está registrado.",
                registerError: "El usuario no se registró con éxito.",
                registerSuccess: "Usuario registrado con éxito.",
                userNotFound: "Usuario no encontrado.",
                alreadyRegistered2: "Correo electrónico ya registrado.",
                errorUpdate: "Ocurrió un error al actualizar el usuario.",
                updateSuccess: "Usuario actualizado con éxito.",
                internalError: "Error interno del servidor.",
                edit: "Editar",
                register: "Registrar",
                user: "Usuario",
                name: "Nombre",
                email: "Correo electrónico",
                role: "Rol",
                stores: "Tiendas",
                agency: "Agencia",
                permissions: "Permisos",
                accessStores: "Acceder a todas las tiendas",
                save: "Guardar",
                cancel: "Cancelar"
            },
            agency: {
                form: {
                    socialRequired: 'La razón social es obligatoria.',
                    cnpjRequired: 'El CNPJ es obligatorio.',
                    clientIdRequired: 'El ID del cliente es obligatorio.',
                    nameClientRequired: 'El nombre del cliente es obligatorio.',
                    storeRequired: 'La tienda es obligatoria.',
                    monthlyCreditRequired: 'El crédito mensual es obligatorio.',
                    initDateRequired: 'La fecha de inicio es obligatoria.',
                    invalidEmail: 'Correo electrónico inválido.',
                    theStores: 'Las tiendas',
                    haveAgency: 'ya tienen agencia!',
                    theStore: 'La tienda',
                    haveAgency2: 'ya tiene agencia!',
                    registerError: 'Error al registrar la agencia!',
                    registerSuccess: '¡Agencia registrada exitosamente!',
                    internalError: 'Error interno del servidor.',
                    agencyNotFound: 'Agencia no encontrada',
                    errorUpdateCustomer: 'Hubo un error al actualizar los clientes asociados a la agencia.',
                    updateSuccess: '¡Agencia actualizada exitosamente!',
                    edit: 'Editar',
                    register: 'Registrar',
                    agency: 'Agencia',
                    social: 'Razón social',
                    cnpj: 'CNPJ',
                    assocStores: 'Asociar tiendas',
                    monthlyCredits: 'Créditos mensuales',
                    initDate: 'Fecha de inicio',
                    financContacts: 'Contactos financieros',
                    name: 'Nombre',
                    email: 'Correo electrónico',
                    phone: 'Teléfono',
                    respContact: 'Contactos del responsable',
                    save: 'Guardar',
                    cancel: 'Cancelar'
                },
                list: {
                    corporateName: 'Razón social',
                    endContract: '¡Contrato finalizado con éxito!',
                    agencies: 'Agencias',
                    register: 'Registrar',
                    socialName: 'Razón social',
                    stores: 'Tiendas',
                    cnpj: 'CNPJ',
                    status: 'Status',
                    active: 'Activo',
                    finished: 'Finalizado',
                    edit: 'Editar',
                    end: 'Finalizar',
                    users: 'Usuarios',
                    activities: 'Actividades'
                },
                specific: {
                    userNotExist: 'Usuario no existe!',
                    deleteError: 'Error al eliminar!',
                    deleteSuccess: 'Usuario eliminado exitosamente!',
                    internalError: 'Error interno del servidor.',
                    stores: 'Tiendas',
                    register: 'Registrar',
                    id: 'ID',
                    name: 'Nombre',
                    email: 'Correo electrónico',
                    edit: 'Editar',
                    delete: 'Eliminar',
                    deleteUser: 'Borrar usuario',
                    confirmDelete: '¿Está seguro de que desea borrar el usuario?',
                    cancel: 'Cancelar'
                }
            },
            aiContent: {
                images: {
                    errorSize: "Ingrese un valor en solo un campo, altura o ancho",
                    internalError: 'Error interno del servidor.',
                    errorSend: "Error al enviar la imagen",
                    categoryImg: "Imágenes",
                    method: "Método",
                    send: "Enviar",
                    style: "Estilo",
                    noneStyle: "Ningún estilo",
                    model3d: "Modelo 3D",
                    analogFilm: "Película Analógica",
                    anime: "Anime",
                    cinematic: "Cinematográfico",
                    comicBook: "Cómic",
                    digitalArt: "Arte Digital",
                    enhance: "Mejorar",
                    fantasy: "Fantasía",
                    isometric: "Isométrico",
                    lineArt: "Arte Lineal",
                    lowPoly: "Baja Poligonalidad",
                    modelingClay: "Arcilla de Modelar",
                    neon: "Neón Punk",
                    origami: "Origami",
                    photographic: "Fotográfico",
                    pixelArt: "Pixel Art",
                    tileTexture: "Textura de Azulejo",
                    titleDifusion1: "Cuán estrictamente el proceso de difusión sigue el texto del indicador (valores más altos mantienen la imagen más cerca de su indicador)",
                    scaleCFG: "Escala CFG",
                    refinements: "Refinamientos",
                    qntImages: "Cantidad de imágenes generadas",
                    samples: "Muestras",
                    format: "Formato",
                    titleDifusion2: "¿Cuál es el impacto de la imagen inicial en el proceso de difusión? Los valores cercanos a 1 resultarán en imágenes muy similares a la imagen inicial, mientras que los valores cercanos a 0 resultarán en imágenes muy diferentes de la imagen inicial.",
                    weightImg: "Peso de la imagen",
                    addToImg: "Agregar a la imagen",
                    textToImg: "Texto para imagen",
                    intensityPropmt: "Intensidad del indicador",
                    removeImg: "Quitar de la imagen",
                    width: "Ancho",
                    height: "Altura",
                    allowed: "Permitido",
                    save: "Guardar"
                },
                library: {
                    activity: "Actividad",
                    action: "Acción",
                    none: "Ninguno",
                    delete: "Eliminar",
                    library: "Biblioteca",
                    title: "Título",
                    action2: "Acción",
                    link: "Enlace",
                    type: "Tipo",
                    samples: "Muestras",
                    date: "Fecha",
                    deleteAction: "Eliminar Acción",
                    confirmDelete: "¿Estás seguro de que deseas eliminar el elemento seleccionado?",

                    detailsModal: {
                        selectActivity: "Seleccionar una actividad",
                        contentNotFound: "Contenido no encontrado.",
                        sendJiraError: "Error al enviar archivo a Jira!",
                        integrationJiraError: "Error en la integración con Jira!",
                        updateSuccess: "Contenido actualizado con éxito!",
                        internalError: "Error interno del servidor.",
                        details: "Detalles",
                        linkAction: "Vincular a la Acción",
                        activity: "Actividad",
                        save: "Guardar",
                        cancel: "Cancelar"
                    },
                    filter: {
                        filter: "Filtrar",
                        filterBy: "Filtrar por",
                        title: "Título",
                        action: "Acción",
                        type: "Tipo",
                        aplic: "Aplicar",
                        reset: "Restablecer filtros"
                    }
                },
                redacting: {
                    fillFields: "¡Complete todos los campos!",
                    minChar: "¡El mínimo de caracteres en un campo es 3!",
                    errorContent: "Error al generar contenido",
                    errorSave: "Error al guardar contenido",
                    redaction: "Redacción",
                    method: "Método",
                    send: "Enviar",
                    quality: "Calidad",
                    economy: "Economía",
                    medium: "Media",
                    high: "Alta",
                    premium: "Premium",
                    language: "Idioma",
                    portuguese: "Portugués",
                    english: "Inglés",
                    spanish: "Español",
                    numberCopies: "Número de copias",
                    save: "Guardar",
                    options: {
                        description1: "Descripción",
                        product: "Producto",
                        description2: "Descripción",
                        container: "Recipiente",
                        containerCharge: "Cargo del recipiente",
                        description3: "Descripción",
                        productName1: "Nombre del producto",
                        productDescription1: "Descripción del producto",
                        occasion: "Ocasión",
                        promotion: "Promoción",
                        productName2: "Nombre del producto",
                        productDescription2: "Descripción del producto",
                        searchTerm: "Término de búsqueda",
                        productName3: "Nombre del producto",
                        productDescription3: "Descripción del producto",
                        companyName: "Nombre de la empresa",
                        companyDescription: "Descripción",
                        keyWord: "Palabra Clave",
                        productName4: "Nombre del producto",
                        productDescription4: "Descripción del producto",
                        keyWords: "Palabras Clave",
                        targetAudience: "Audiencia objetivo",
                        description4: "Descripción",
                        voiceTone1: "Tono de voz",
                        content: "Contenido",
                        voiceTone2: "Tono de voz",
                        productDescription5: "Descripción del producto",
                        benefit: "Beneficio"
                    }
                },
                utils: {
                    config: {
                        label0: 'Llamada a la Acción',
                        description0: `Crea llamadas a la acción atractivas que incentiven las conversiones y aumenten tus ventas. 
                        
                        En el campo **Descripción**, explica la empresa, producto o servicio que estás promocionando y obtén ideas para las llamadas a la acción.
                
                        Creatividad: ¿qué nivel de creatividad te gustaría aplicar, siendo Premium el modelo más completo pero también más lento y costoso?
                        Idioma: elige entre los 3 idiomas disponibles.
                        Copias: selecciona cuántas opciones te gustaría elegir.`,

                        label1: 'Asunto de Correo Electrónico',
                        description1: `Crea asuntos de correo electrónico que aumenten tu tasa de apertura. 
                        
                        En el campo **Producto**, indica el nombre de la empresa, producto o servicio. En el campo **Descripción**, explica brevemente lo que te gustaría promocionar.
                
                        Creatividad: ¿qué nivel de creatividad te gustaría aplicar, siendo Premium el modelo más completo pero también más lento y costoso?
                        Idioma: elige entre los 3 idiomas disponibles.
                        Copias: selecciona cuántas opciones te gustaría elegir.`,

                        label2: 'Correos Electrónicos',
                        description2: `Crea correos electrónicos en masa que parezcan personalizados. 
                        
                        En el campo **Destinatario**, indica el nombre de quien recibirá el correo o simplemente coloca "cliente". En el campo **Cargo del Destinatario**, indica el segmento o perfil del cliente, y en la **Descripción**, describe el objetivo de tu correo. Recuerda que el sistema generará un correo largo, así que si buscas algo breve, utiliza las funciones "Asunto de Correo Electrónico" y "Llamada a la Acción".
                
                        Creatividad: ¿qué nivel de creatividad te gustaría aplicar, siendo Premium el modelo más completo pero también más lento y costoso?
                        Idioma: elige entre los 3 idiomas disponibles.
                        Copias: selecciona cuántas opciones te gustaría elegir.`,

                        label3: 'Anuncios de Facebook',
                        description3: `Redacción para anuncios de Facebook e Instagram que harán que tus anuncios destaquen. 
                        
                        En el campo **Nombre del Producto**, indica el nombre de la empresa, producto o servicio. En el campo **Descripción**, explica brevemente tu producto, menciona la **Ocasión** de uso y en **Promoción**, informa cualquier oferta que te gustaría destacar.
                
                        Creatividad: ¿qué nivel de creatividad te gustaría aplicar, siendo Premium el modelo más completo pero también más lento y costoso?
                        Idioma: elige entre los 3 idiomas disponibles.
                        Copias: selecciona cuántas opciones te gustaría elegir.`,

                        label4: 'Anuncios de Google',
                        description4: `Anuncios de calidad que se posicionan en los resultados de búsqueda y generan más tráfico. 
                        
                        En el campo **Nombre del Producto**, indica el nombre de la empresa, producto o servicio. En el campo **Descripción del Producto**, explica brevemente tu producto, y en **Término de Búsqueda**, indica qué término te gustaría posicionar.
                
                        Creatividad: ¿qué nivel de creatividad te gustaría aplicar, siendo Premium el modelo más completo pero también más lento y costoso?
                        Idioma: elige entre los 3 idiomas disponibles.
                        Copias: selecciona cuántas opciones te gustaría elegir.`,

                        label5: 'Descripciones de Anuncios de Google',
                        description5: `Los mejores textos para anuncios de Google que convierten visitantes en clientes. 
                        
                        En el campo **Nombre del Producto**, indica el nombre de la empresa, producto o servicio. En el campo **Descripción del Producto**, explica brevemente tu producto.
                
                        Creatividad: ¿qué nivel de creatividad te gustaría aplicar, siendo Premium el modelo más completo pero también más lento y costoso?
                        Idioma: elige entre los 3 idiomas disponibles.
                        Copias: selecciona cuántas opciones te gustaría elegir.`,

                        label6: 'Título de Anuncios de Google',
                        description6: `Anuncios con títulos exclusivos y atractivos que incitan a las personas a hacer clic en tu anuncio y comprar en tu sitio. 
                        
                        En el campo **Nombre de la Empresa**, indica el nombre de la empresa anunciante. En el campo **Descripción**, explica brevemente tu producto y menciona la **Palabra Clave** que usarás en tu campaña.
                
                        Creatividad: ¿qué nivel de creatividad te gustaría aplicar, siendo Premium el modelo más completo pero también más lento y costoso?
                        Idioma: elige entre los 3 idiomas disponibles.
                        Copias: selecciona cuántas opciones te gustaría elegir.`,

                        label7: 'Ideas de Crecimiento',
                        description7: `Tácticas de alto impacto para el crecimiento de tu empresa. 
                        
                        En el campo **Nombre del Producto**, indica el nombre de la empresa, producto o servicio. En el campo **Descripción del Producto**, explica brevemente tu producto. En **Palabras Clave**, indica qué términos están relacionados con tu producto y en **Audiencia Objetivo**, indica a quién está dirigida tu táctica.
                
                        Creatividad: ¿qué nivel de creatividad te gustaría aplicar, siendo Premium el modelo más completo pero también más lento y costoso?
                        Idioma: elige entre los 3 idiomas disponibles.
                        Copias: selecciona cuántas opciones te gustaría elegir.`,

                        label8: 'Subtítulos de Instagram',
                        description8: `Captura la atención de tus publicaciones con subtítulos interesantes y creativos. 
                        
                        En el campo **Contenido**, indica el tema de la publicación, y en el campo **Tono de Voz**, explica cómo se comunica tu marca con tus clientes.`,

                        label9: 'Reescribir Texto',
                        description9: `Reescribe texto con un nuevo tono de voz o estilo ortográfico. 
                        
                        En el campo **Contenido**, indica el texto que deseas reescribir, y en el campo **Tono de Voz**, indica qué estilo ortográfico o tono de voz deseas aplicar.`,

                        label10: 'Recurso para Beneficio',
                        description10: `Transforma las características de tu producto o servicio en beneficios para generar más valor para tus clientes.`,

                        label11: 'Texto para Imagen',
                        description11: `Crea imágenes perfectas para tus anuncios con creatividad y exclusividad. Adáptalas al estilo de tu marca y aumenta la producción de contenido.`,
                        dropdownTitle11: 'Aprende a crear imágenes:',
                        dropdownText11: `**Estilo**: elige entre los diversos estilos predefinidos o déjalo en blanco para definir el estilo directamente en el texto.
                
                        **Escala CFG**: este parámetro controla cómo el proceso de generación de imágenes seguirá las instrucciones del texto. Cuanto mayor sea el valor (máx. 35), más se adherirá la imagen a una entrada de texto específica. Cuanto menor sea el valor (mín. 0), más creativo y libre será el resultado.
                        
                        **Refinamientos**: cuando se genera una imagen, se refinada nuevamente según la cantidad de refinamientos establecida. El proceso de refinamiento puede ser efectivo en imágenes con muchos elementos complejos, pero menos efectivo en imágenes abstractas. Prueba según tu imagen, pero recomendamos el valor predeterminado de 50.
                        
                        **Muestras**: cuántas imágenes diferentes deseas.
                        
                        **Texto para Imagen**: describe los elementos principales, el entorno, el estilo de la imagen y las palabras clave separadas por comas.`,

                        label12: 'Transformar Imagen',
                        description12: `Crea imágenes perfectas para tus anuncios con creatividad y exclusividad. Adáptalas al estilo de tu marca y aumenta la producción de contenido.`,
                        dropdownTitle12: 'Aprende a transformar imágenes:',
                        dropdownText12: `**Estilo**: elige entre los diversos estilos predefinidos o déjalo en blanco para mantener el mismo estilo que la imagen de referencia.
                
                        **Escala CFG**: este parámetro controla cómo el proceso de generación de imágenes seguirá las instrucciones del texto. Cuanto mayor sea el valor (máx. 35), más se adherirá la imagen a una entrada de texto específica. Cuanto menor sea el valor (mín. 0), más creativo y libre será el resultado.
                        
                        **Refinamientos**: cuando se genera una imagen, se refinada nuevamente según la cantidad de refinamientos establecida. El proceso de refinamiento puede ser efectivo en imágenes con muchos elementos complejos, pero menos efectivo en imágenes abstractas. Prueba según tu imagen, pero recomendamos el valor predeterminado de 50.
                        
                        **Muestras**: cuántas imágenes diferentes deseas.
                        
                        **Peso de la imagen**: cuánta influencia tiene la imagen de referencia en el proceso de creación. Los valores cercanos a 1 producirán imágenes muy similares a la imagen de referencia, mientras que los valores cercanos a 0 generarán imágenes totalmente diferentes.
                        
                        **Agregar a la Imagen**: describe los elementos principales, el entorno, el estilo de la imagen y las palabras clave separadas por comas.
                        
                        **Agregar a la Imagen**: describe elementos como objetos, entorno y estilos que te gustaría incluir en la imagen y utiliza la barra lateral para establecer el nivel de protagonismo que deben tener los elementos en la imagen.
                        
                        **Eliminar de la Imagen**: describe qué deseas eliminar de la imagen de referencia y utiliza la barra lateral para establecer la intensidad con la que los elementos deben eliminarse de la imagen.`,

                        label13: 'Ampliación de Imágenes',
                        description13: `Amplía imágenes de baja calidad a tamaños de alta resolución. Este modelo recrea los detalles de la imagen en alta resolución para mantener la mayor fidelidad posible con respecto a la imagen original. Elige entre altura o anchura como referencia para el tamaño final. Los incrementos de tamaño son fijos en 64 píxeles, por lo que no será posible llegar al tamaño exacto deseado.`,
                    },
                    searchAction: {
                        action: "Acción",
                    },
                    searchToJiraModal: {
                        selectActivity: "Seleccionar una actividad",
                        sendJiraError: "Error al enviar archivo a Jira!",
                        integrationJiraError: "Error en la integración con Jira!",
                        storeSuccess: "Contenido almacenado con éxito",
                        internalError: "Error interno del servidor.",
                        relation: "Relación Jira/Plataforma",
                        title: "Título",
                        associate: "Asociar a",
                        none: "Ninguno",
                        action: "Acción",
                        activity: "Actividad",
                        activity2: "Actividad",
                        commentJira: "Comentario Jira",
                        save: "Guardar"
                    }
                }
            },
            apps: {
                alerts: {
                    message: "Mensajes",
                    telephone: "Teléfono",
                    start: "Inicio",
                    end: "Fin",
                    btnText: "Texto del botón",
                    weekends: "Fines de semana",
                    active: "Activo",
                    alerts: "Alertas",
                    chatSupportMessage: "Mensaje de soporte de chat",
                },
                extendedCheckout: {
                    extendedCheckout: "Checkout Extendido",
                },
                logistics: {
                    logistic1: "Logística",
                    logistic2: "Logística",
                },
                service: {
                    service1: "Servicio al Cliente",
                    service2: "Servicio al Cliente",
                },
                smartShipping: {
                    smartShipping1: "Envío Inteligente",
                    smartShipping2: "Envío Inteligente",
                },
                utils: {
                    appFormCms: {
                        sendFilesError: "Error al enviar archivos!",
                        updateFilesError: "Error al actualizar archivos!",
                        internalError: "Error interno del servidor.",
                        createAppSuccess: "Aplicación creada con éxito!",
                        updateAppError: "Error al actualizar la aplicación!",
                        updateAppSuccess: "Aplicación actualizada con éxito!",
                        sincAlt: "Sincronizar cambios",
                        title1: "Título",
                        link1: "Enlace",
                        description1: "Descripción",
                        link2: "Enlace",
                        link3: "Enlace",
                        link4: "Enlace",
                        title2: "Título",
                        textButton: "Texto del Botón",
                        description2: "Descripción",
                        subtitle1: "Subtítulo",
                        description3: "Descripción",
                        subtitle2: "Subtítulo",
                        description4: "Descripción",
                        subtitle3: "Subtítulo",
                        description5: "Descripción",
                        addDescription: "Agregar descripción",
                    },
                    appFormNotifications: {
                        createAppSuccess: "Aplicación creada con éxito!",
                        updateAppError: "Error al actualizar la aplicación!",
                        updateAppSuccess: "Aplicación actualizada con éxito!",
                        internalError: "Error interno del servidor.",
                        sincAlt: "Sincronizar cambios",
                        success: "Éxito",
                        error: "Error",
                        info: "Información",
                        other: "Otro",
                    },
                    appTabs: {
                        cms: "Cms",
                        notifications: "Notificaciones"
                    }
                }
            },
            chat: {
                indexAdm: {
                    selectChanel: "Seleccione un canal en el lateral."
                }
            },
            customer: {
                customerNotExist: "El cliente no existe!",
                deleteError: "Error al eliminar!",
                deleteSuccess: "Cliente eliminado exitosamente!",
                internalError: "Error interno del servidor.",
                performance: "Rendimiento",
                acessibility: "Accesibilidad",
                bestPractices: "Prácticas",
                seo: "SEO",
                stores: "Tiendas",
                register: "Registrarse",
                search: "Buscar",
                store: "Tienda",
                all: "Todos",
                implantation: "Implantación",
                operation: "Operación",
                performance2: "Rendimiento",
                support: "Soporte",
                logo: "Logo",
                name: "Nombre",
                healthScore: "Puntuación de Salud",
                balanceCreditsPerformance: "Balance/Créditos (Rendimiento)",
                balanceCreditsSupport: "Balance/Créditos (Soporte)",
                performance3: "Rendimiento",
                platforms: "Plataformas",
                viewChecklist: "Ver Checklist",
                editChecklist: "Editar Checklist",
                users: "Usuarios",
                edit: "Editar",
                delete: "Eliminar",
                deleteUser: "Eliminar usuario",
                confirmDelete: "¿Estás seguro de que deseas eliminar al usuario?",
                cancel: "Cancelar",
            },
            dashboard: {
                dashboards: "Tableros",
                dashboard: "Tablero",
                resumeSales: "Resumen de Ventas",
                paidTraffic: "Tráfico Pagado",
                crm: "CRM",
                sales: "Ventas",
                invoicing: "Facturación",
                products: "Productos",
                strategy: "Estrategia",
                healthScore: "Puntuación de Salud",
                immediateAction: "Acciones Inmediatas",
                activitiesWaitingApproval: "Actividades en Espera de Aprobación",
                checklistItens: "Ítems de Lista de Verificación en Espera de Envío",
                roadmap: "Hoja de Ruta",
                operation: "Operación",
                created: "Pedido creado",
                error: "Error de integración",
                shipment: "En proceso de envío",
                carrier: "En tránsito",
                client: "Entregado al cliente",
                tecnology: "Tecnología",
                pleaseWaitTecnology: "Por favor espere, estamos buscando sus datos",
                performance: "Rendimiento",
                accessibility: "Accesibilidad",
                recommendedPractices: "Prácticas Recomendadas",
                seo: "SEO",
                last30days: "Últimos 30 días",
                invoicingTotal: "Facturación Total",
                ticketMedio: "Ticket Medio",
                orders: "Pedidos",
                textNoInstall: "Instala la app Métricas FullComm para traer tus datos y generar visualizaciones.",
                installInShopify: "Instalar la aplicación de Shopify",
                startWithPerformance: "Comienza con Rendimiento",
                startWithOperation: "Comienza con Operación",
                usePerformanceModule: "Utiliza el módulo de rendimiento para mostrar los datos en el panel de control.",
                useOperationModule: "Utiliza el módulo de operación para mostrar los datos en el panel de control.",
                goToPerformance: "Ir al módulo de rendimiento",
                requestModule: "Solicitar módulo",
                unLockData: "Desbloquea el poder de los datos",
                unblock: "Desbloquear",
            },
            dataEnrichmentModal: {
                integrations: {
                    integratedService: "Servicio integrado",
                    integrateService: "Integrar servicio",
                    createdStore: "¡Tienda creada con éxito!",
                    store: "Tienda",
                    watchVideo: "Mira el video sobre cómo integrar los servicios que usas en Plataforma FullComm.",
                    finishedIntegration: "Finalizar integración",
                    continueAfter: "Continuar más tarde",
                },
                intro: {
                    userRequired: "El nombre de usuario es obligatorio.",
                    phoneRequired: "El teléfono es obligatorio.",
                    nameRequired: "El nombre es obligatorio.",
                    termsOfUseRequired: "Debes aceptar los términos de uso.",
                    urlValid: "Ingresa una URL válida. Ejemplo: https://www.tutienda.com.",
                    urlRequired: "La URL de la tienda es obligatoria.",
                    helpUs: "Ayúdanos a personalizar tu experiencia.",
                    name: "Nombre",
                    phone: "Teléfono",
                    storeName: "Nombre de tu tienda virtual",
                    urlStore: "Dirección de tu tienda virtual",
                    termsOfUse: "He leído y acepto los términos de uso",
                    initConfig: "Iniciar configuración",
                    continueAfter: "Continuar más tarde",
                    segment: "Segmento",
                    segmentRequired: "Segmento es obligatorio.",
                    fashion: "Moda",
                    home: "Casa",
                    food: "Alimentos",
                    beauty: "Belleza",
                    health: "Salud",
                    electronics: "Electrónica",
                    other: "Otro"
                }
            },
            login: {
                informEmail: "Ingrese su correo electrónico.",
                informPassword: "Ingrese su contraseña.",
                auth0: {
                    verifiedEmail: "¿Correo electrónico verificado? Haga clic aquí para intentarlo nuevamente.",
                    continueAfter: "Continuar más tarde",
                    login: "Continuar a FullComm",
                    register: "¿No tienes una cuenta? ¡Inténtalo!",
                    title: "Comercio electrónico equiparado a los gigantes",
                    subTitle: "Software FullComm integra estrategia, rendimiento, logística: eficiencia global en tiendas."
                }
            },
            notifications: {
                all: "Todas",
                communications: "Comunicados",
                waitingApproval: "Esperando aprobación",
                actions: "Acciones",
                account: "Cuenta",
                alerts: "Alertas",
                notifications: "Notificaciones",
                utils: {
                    history: "Historia . . .",
                },
                icon: {
                    notifications: "Notificaciones",
                    noneNotifications: "Actualmente no tienes notificaciones.",
                    showAll: "Mostrar todas las notificaciones",
                },
                notificationCard: {
                    newActionRegister: "Nueva acción registrada",
                    updateAction: "Acción actualizada",
                    reproveActivity: "Actividad reprobada",
                    approveActivity: "Actividad aprobada",
                    deleteAction: "Acción eliminada",
                    impediment: "Impedimento!",
                    pendingApproval: "Aprobación pendiente!",
                    reproveProblem: "Problema reprobado",
                    approveProblem: "Problema aprobado",
                    requiresEstimation: "La acción requiere estimación",

                    theAction: "La acción",
                    wasRegistered: "se ha registrado con éxito!",
                    wasUpdated: "se ha actualizado con éxito!",
                    theActivity: "La actividad",
                    ofAction: "de la acción",
                    wasReproved: "ha sido reprobada!",
                    wasApproved: "ha sido aprobada!",
                    wasDeleted: "ha sido eliminada!",
                    isImpediment: 'está con impedimento!',
                    needApproval: "necesita aprobación!",
                    theProblemIncident: "El problema/incidente",
                    needApproval2: "necesita aprobación!",
                    theProblem: "El problema",
                    was: "ha sido",
                    wasReproved2: "ha sido reprobado!",
                    wasApproved2: "ha sido aprobado!",
                    moment: "{{timeElapsed}}",
                    pendingChecklist: "tiene la lista de verificación pendiente!",
                    needEstimation: "necesita estimación!"
                }
            },
            perfil: {
                information: "Información",
                perfil: "Perfil",
                edit1: "Editar",
                actions: "Acciones",
                edit2: "Editar",
                editPassword: "Editar contraseña",
                picture: "Foto",
                name: "Nombre",
                notifications: "Notificaciones",
                activate: "Activar",
                email: "Correo electrónico",
                language: "Idioma",
                portuguese: "Portugués",
                english: "Inglés",
                spanish: "Español",
                chatStyle: "Estilo de chat",
                workingHours: "Horario de trabajo",
                new: "Nuevo",
                legacy: "Legado",
                notDefined: "No definido",
                invalidHours: "Horas inválidas",
            },
            permission: {
                selectClient: "Seleccione un cliente",
                invalidData: "Datos inválidos.",
                updateSuccess: "Permisos actualizados con éxito!",
                userNotFound: "Usuario no encontrado.",
                internalError: "Error interno del servidor.",
                permissions: "Permisos",
                edit: "Editar",
                permissionsModel: "Modelo de Permisos",
                none: "Ninguno",
                general: "General",
                implantation: "Implantación",
                performance: "Rendimiento",
                support: "Soporte",
                operation: "Operación",
                savePermissions: "Guardar",
                permissionModels: {
                    add: "Agregar",
                    selectModel: "Seleccione un modelo",
                    updatedPermissions: "Permisos actualizados exitosamente",
                    errorUpdatePermissions: "Error al actualizar los permisos",
                    createEdit: "Crear/Editar Permisos",
                    createModel: "Crear modelo",
                    save: "Guardar",
                    modelAplic: "El modelo se aplica sobre",
                    admin: "Admin",
                    client: "Cliente",
                    general: "General",
                    implantation: "Implantación",
                    performance: "Rendimiento",
                    support: "Soporte",
                    operation: "Operación",
                    freeSoloCreateOption: {
                        createNewModel: "Crear nuevo modelo",
                        permissionsModel: "Modelo de Permisos",
                    },
                    modalEditPermission: {
                        permissionAlreadyExists: "El permiso ya existe!",
                        createSuccess: "Permiso creado exitosamente!",
                        errorCreatePermission: "Error al crear el permiso",
                        permissionNotFound: "Permiso no encontrado!",
                        updateSuccess: "Permiso actualizado exitosamente!",
                        internalError: "Error interno del servidor.",
                        errorUpdatePermission: "Error al actualizar el Permiso",
                        errorDeletePermission: "Error al eliminar el Permiso",
                        editPermission: "Editar Permiso",
                        name1: "Nombre",
                        userType: "Tipo de usuario",
                        admin: "Admin",
                        client: "Cliente",
                        category: "Categoría",
                        general: "General",
                        implantation: "Implantación",
                        performance: "Rendimiento",
                        support: "Soporte",
                        operation: "Operación",
                        permissions: "Permisos",
                        name2: "Nombre",
                        id: "id",
                        addPermission: "Agregar Permiso",
                        save: "Guardar",
                        delete: "Eliminar",
                        cancel1: "Cancelar",
                        deletePermission: "Eliminar Permiso",
                        confirmDelete: "¿Estás seguro de que deseas eliminar el Permiso?",
                        remove: "Eliminar",
                        cancel2: "Cancelar"
                    }
                },
                admin: {
                    userNotFound: "Usuario no encontrado.",
                    emailAlreadyExist: "Correo electrónico ya registrado.",
                    updateError: "Se produjo un error al actualizar el usuario.",
                    updateSuccess: "Usuario actualizado con éxito.",
                    internalError: "Error interno del servidor.",
                    permissions: "Permisos",
                    edit: "Editar",
                    permissionsModel: "Modelo de Permisos",
                    none: "Ninguno",
                    general: "General",
                    implantation: "Implantación",
                    performance: "Rendimiento",
                    support: "Soporte",
                    operation: "Operación",
                    savePermissions: "Guardar permisos"
                }
            },
            recoveryPassLogin: {
                emailRequired: "¡Ingrese el correo electrónico!",
                emailError: "¡Correo electrónico inválido!",
                rescuePass: "Recuperar contraseña",
                email: "Correo electrónico",
                writeEmail: "Escribe tu correo electrónico",
                clickHere: "Haz clic aquí",
                toAccess: " para acceder",
            },
            scale: {
                logistics: {
                    logistic: "Logística",
                    tabInit: "Inicio",
                    tabOrders: "Pedidos",
                    orders: {
                        created: "Nuevo pedido",
                        error: "Error de integración",
                        processed: "Pedido integrado",
                        shipment: "Pedido en expedición",
                        shipping: "Entregado a la transportadora",
                        invoiced: "Facturado",
                        store: "Tienda",
                        customer: "Entregado al cliente",
                        last7: "Últimos 7 días",
                        last15: "Últimos 15 días",
                        last30: "Últimos 30 días",
                        last90: "Últimos 90 días",
                        all: "Todos",
                        filterItens: "Filtrar elementos",
                        statusType: "Estado",
                        period: "Período",
                        order: "Pedido",
                        data: "Fecha",
                        client: "Cliente",
                        statusTitle: "Estado",
                        value: "Valor",
                        linesPerPage: "Líneas por página:",
                        of: "de",
                    },
                    start: {
                        filterPeriod: "Filtrar período",
                        last7: "Últimos 7 días",
                        last15: "Últimos 15 días",
                        last30: "Últimos 30 días",
                        last90: "Últimos 90 días",
                        all: "Todos",
                        customer: "Entregados al cliente",
                        shipping: "Entregados a la transportadora",
                        shipment: "Expedición",
                        error: "Error de integración",
                        invoicedOrders: "Pedidos facturados",
                        totalOrders: "Total de pedidos realizados",
                    }
                },
                platinumlog: {
                    orders: "Pedidos",
                    timeIndicators: "Indicadores de Tiempo",
                    order: {
                        loading: "Cargando...",
                        order: "Pedido",
                        orderDate: "Fecha del Pedido",
                        invoice: "Factura",
                        nfe: "NF-e",
                        prevision: "Previsión",
                        carrier: "Transportista",
                        modality: "Modalidad",
                        customer: "Cliente",
                        contact: "Contacto",
                        address: "Dirección",
                        complement: "Complemento",
                        neighborhood: "Barrio",
                        city: "Ciudad",
                        cep: "CEP",
                        orderDetails: "Detalles del Pedido",
                        timeIndicators: "Indicadores de Tiempo",
                        internalEvents: "Eventos Internos",
                        shippingEvents: "Eventos de Envío",
                        goods: "Mercancías",
                    },
                },
                service: {
                    service: "Atención",
                    tabInit: "Inicio",
                    tabOccurrences: "Ocurrencias",
                    occurrences: {
                        inAnalysis: "En análisis",
                        inTransit: "En tránsito",
                        inDispute: "En disputa",
                        waitingPost: "Esperando envío",
                        codeOccurrenceFilter: "Código de ocurrencia",
                        statusFilter: "Estado",
                        period: "Período",
                        type: "Tipo",
                        statusTitle: "Estado",
                        codeOccurrenceTitle: "Código de ocurrencia",
                        elapsedTime: "Tiempo transcurrido",
                        data: "Fecha",
                        quantity: "Cant.",
                        value: "Valor de la ocurrencia",
                        immediateAction: "Acción inmediata",
                        occurrences: "Ocurrencias"
                    },
                    start: {
                        inAnalysis: "En análisis",
                        inTransit: "En tránsito",
                        inDispute: "En disputa",
                        waitingPost: "Esperando envío",
                        status: "Estado",
                        codeOccurrence: "Código de ocurrencia",
                        elapsedTime: "Tiempo transcurrido",
                    }
                },
                smartShipping: {
                    smartShipping: "Envío Inteligente",
                    filterPeriod: "Filtrar período",
                    last7: "Últimos 7 días",
                    last15: "Últimos 15 días",
                    last30: "Últimos 30 días",
                    last90: "Últimos 90 días",
                    all: "Todos",
                    quotes: "Cotizaciones",
                    ZIPCodeDestination: "Código postal destino",
                    generalMedia: "Media general",
                    mediaV1: "Media V1",
                }
            },
            sprint: {
                results: {
                    results: "Resultados",
                },
                actionChecklist: {
                    checklistProgress: "Progreso del Checklist",
                    generalProgress: "PROGRESO GENERAL",
                    checklistStatus: "Estado del Checklist",
                    create: {
                        actionNotExist: "Acción no existe!",
                        integrationJiraError: "Error en la integración con Jira, ¡intente guardar la acción nuevamente!",
                        updateError: "Acción no encontrada o no modificada!",
                        updateSuccess: "Acción actualizada con éxito!",
                        internalError: "Error interno del servidor.",
                        checklist: "Lista de verificación",
                        save: "Guardar",
                        checklistAction: "Lista de verificación de acción",
                        subTitle: "subtítulo",
                        title: "Título",
                        helperText: 'Texto de ayuda',
                        placeHolder: 'Marcador de posición',
                        type: 'Tipo',
                        text: 'Texto',
                        textArea: 'Área de texto',
                        count: 'Contador numérico',
                        money: 'Valor monetario',
                        uniqueList: 'Lista desplegable única',
                        multipleList: 'Lista desplegable múltiple',
                        url: 'URL',
                        date: 'Fecha',
                        email: 'Correo electrónico',
                        radio: 'Radio',
                        checkbox: 'Casilla de verificación',
                        attachment: 'Adjunto',
                        options: 'Opciones',
                        mandatory: 'Obligatorio',
                        selectItem: '¡Seleccione un elemento al lado!',
                    },
                    edit: {
                        registerError: "¡La lista de verificación no se registró correctamente!",
                        internalError: "Error interno del servidor.",
                        registerSuccess: "¡La lista de verificación se registró correctamente!",
                        updateError: "Se produjo un error al actualizar la lista de verificación.",
                        updateSuccess: "Tarea actualizada con éxito!",
                        errorIcons: "Error al buscar iconos:",
                        addForm: "¡Formulario agregado con éxito!",
                        editForm: "¡Formulario actualizado con éxito!",
                        checklistAction: "Lista de verificación de acción",
                        add: "Agregar",
                        title: "Título",
                        helperText: 'Texto de ayuda',
                        placeHolder: 'Marcador de posición',
                        type: 'Tipo',
                        text: 'Texto',
                        textArea: 'Área de texto',
                        count: 'Contador numérico',
                        money: 'Valor monetario',
                        uniqueList: 'Lista desplegable única',
                        multipleList: 'Lista desplegable múltiple',
                        url: 'URL',
                        date: 'Fecha',
                        email: 'Correo electrónico',
                        radio: 'Radio',
                        checkbox: 'Casilla de verificación',
                        attachment: 'Adjunto',
                        condition: 'Condición',
                        noCondition: 'Sin condición',
                        valueCondition: 'Valor de condición',
                        mandatory: 'Obligatorio',
                        options: 'Opciones',
                        addOption: 'Agregar opción',
                        addField: 'Agregar campo',
                        add2: 'Agregar',
                        save: 'Guardar',
                        cancel: 'Cancelar',
                        delete: 'Eliminar',
                        selectItem: '¡Seleccione un elemento al lado!',
                        searchIcon: 'Buscar icono'
                    },
                    inicial: {
                        fieldRequired: "Este campo es obligatorio.",
                        fillFields: "Complete todos los campos obligatorios.",
                        actionNotFound: "Acción no encontrada!",
                        errorUpdate: "Se produjo un error al actualizar la acción.",
                        updateSuccess: "Acción actualizada con éxito!",
                        internalError: "Error interno del servidor.",
                        archiveHere: "Suelte los archivos aquí",
                        dragOrClick: "Arrastre y suelte sus archivos aquí o haga clic para seleccionar archivos",
                        checklist: "Lista de verificación",
                        checklistAction: "Lista de verificación de acción",
                        save: "Guardar",
                        selectItem: "¡Seleccione un elemento al lado!"
                    },
                    view: {
                        actionNotFound: "Acción no encontrada!",
                        errorUpdate: "Se produjo un error al actualizar la acción.",
                        updateSuccess: "Acción actualizada con éxito!",
                        internalError: "Error interno del servidor.",
                        checklist: "Lista de verificación",
                        checklistAction: "Lista de verificación de acción",
                        save: "Guardar",
                        selectItem: "¡Seleccione un elemento al lado!"
                    },
                    viewAll: {
                        errorIcons: "Error al buscar iconos:",
                        delivered: "Entregado",
                        onTime: "A tiempo",
                        atRisk: "En riesgo",
                        late: "Tarde",
                        checklists: "Listas de verificación",
                        edit: "Editar",
                        search: "Buscar",
                        status: "Estado",
                        all: "Todos",
                        approveds: "Aprobados",
                        validatingItems: "Validando elementos",
                        waitingSend: "Esperando envío",
                        repproveds: "Reprobados",
                        lateDelivered: "Tarde/Entregado",
                        onTime2: "A tiempo",
                        atRisk2: "En riesgo",
                        action: "Acción",
                        waitingSend2: "Esperando envío",
                        validatingItems2: "Validando elementos",
                        repproveds2: "Reprobados",
                        approveds2: "Aprobados",
                        time: "Tiempo",
                        fillChecklist: "Completar Lista de verificación",
                        viewChecklist: "Ver Lista de verificación",
                        editChecklist: "Editar Lista de verificación",
                    }
                },
                actionPlan: {
                    actionPlan: "Plan de Acción",
                    roadMapLabel: "Hoja de Ruta",
                    calendary: "Calendario",
                    roadMap: {
                        activeActions: "Acciones Activas",
                        filter: {
                            apply: "Aplicar",
                        }
                    },
                    calendarView: {
                        viewBy: "Ver por:",
                        month: "Mes",
                        week: "Semana",
                    }
                },
                contract: {
                    form: {
                        theStores: "Las tiendas",
                        haveContract: "ya tienen contrato activo!",
                        theStore: "La tienda",
                        haveContract2: "ya tiene contrato activo!",
                        registerError: "¡El contrato no se registró con éxito!",
                        registerSuccess: "¡Contrato registrado con éxito!",
                        internalError: "Error interno del servidor.",
                        updateError: "Se produjo un error al actualizar el contrato.",
                        updateSuccess: "¡Contrato actualizado con éxito!",
                        reasonSocialRequired: "La razón social es obligatoria.",
                        cnpjRequired: "El CNPJ es obligatorio.",
                        clientIdRequired: "El ID del cliente es obligatorio.",
                        clientNameRequired: "El nombre del cliente es obligatorio.",
                        storeRequired: "La tienda es obligatoria.",
                        monthlyCreditsRequired: "Los créditos mensuales son obligatorios.",
                        initDateRequired: "La fecha de inicio es obligatoria.",
                        invalidEmail: "Correo electrónico inválido.",
                        invalidEmail2: "Correo electrónico inválido.",
                        edit: "Editar",
                        register: "Registrar",
                        contract: "Contrato",
                        socialReason: "Razón Social",
                        cnpj: "CNPJ",
                        associateStores: "Asociar Tiendas",
                        monthlyCredits: "Créditos Mensuales (Módulo de Rendimiento)",
                        monthlyCreditsSupport: "Créditos Mensuales (Módulo de Soporte)",
                        initDate: "Fecha de Inicio",
                        monthlyHours: "Horas Mensuales (Módulo de Soporte)",
                        changeMode: "Cambiar Modelo de Facturación de Soporte",
                        comissionRate: "Tasa de Comisión",
                        minimalRevenue: "Ingresos Mínimos",
                        contractFiles: "Archivos del Contrato",
                        newContractFiles: "Nuevos Archivos",
                        addFiles: "Agregar Archivos",
                        financContacts: "Contactos Financieros",
                        nameFinanc: "Nombre",
                        emailFinanc: "Correo Electrónico",
                        phoneFinanc: "Teléfono",
                        respContacts: "Contactos del Responsable",
                        nameResp: "Nombre",
                        emailResp: "Correo Electrónico",
                        phoneResp: "Teléfono",
                        save: "Guardar",
                        cancel: "Cancelar"
                    },
                    list: {
                        theStores: "Las tiendas",
                        haveContract: "¡ya tienen un contrato activo!",
                        theStore: "La tienda",
                        haveContract2: "ya tiene un contrato activo!",
                        internalError: "Error interno del servidor.",
                        updateError: "Se produjo un error al actualizar el contrato.",
                        updateSuccess: "Contrato actualizado con éxito!",
                        contracts: "Contratos",
                        search: "Buscar",
                        register: "Registrar",
                        cnpj: "CNPJ",
                        stores: "Tiendas",
                        status: "Estado:",
                        active: "Activo",
                        finished: "Finalizado",
                        all: "Todos",
                        edit: "Editar",
                        close: "Cerrar",
                        performance: "Rendimiento",
                        creditsPerformance: "Créditos:",
                        balancePerformance: "Saldo:",
                        support: "Soporte",
                        creditsSupport: "Créditos:",
                        balanceSupport: "Saldo:",
                        comissionRate: "Tasa de Comisión",
                    }
                },
                kanban: {
                    problemIncidents: "Mejoras e Problemas",
                    activities: "Actividades",
                    accept: "Aceptar",
                    sendTo: "Enviar a",
                    viewReproval: "Ver Reprobación",
                    reproved: "Reprobado",
                    comments: "Comentarios",
                    actionCardArea: {
                        toDo: "Por Hacer",
                        inProgress: "En Progreso",
                        customerApproval: "Aprobación del Cliente",
                        doneApplied: "Completado/Aplicado",
                    },
                    activityApproval: {
                        updateProblemError: "Error al actualizar el problema.",
                        updateProblemSuccess: "Problema actualizado con éxito!",
                        internalError: "Error interno del servidor.",
                        updateActivityError: "Error al actualizar la actividad.",
                        updateActivitySuccess: "Actividad actualizada con éxito!",
                        addComentReprove: "Es necesario agregar un comentario para reprobar la actividad.",
                        confirmApprove: 'Estás a punto de aprobar esta actividad. Los comentarios registrados en los archivos se eliminarán y la tarea se dará por completada. Si deseas hacer un ajuste, selecciona "Reprobar".',
                        back: "Volver",
                        attachments: "Adjuntos",
                        addComment: "Agregar comentario",
                        approve: "Aprobar",
                        reprove: "Reprobar",
                        comments: "Comentarios",
                        expandImage: "Ampliar imagen",
                    },
                    activityCard: {
                        toDo: "Por Hacer",
                        inProgress: "En Progreso",
                        customerApproval: "Aprobación del Cliente",
                        doneApplied: "Completado/Aplicado",
                    },
                    reprovedActivity: {
                        back: "Volver",
                        attachments: "Adjuntos",
                        customerComment: "Comentario del cliente:",
                    }
                },
                list: {
                    internalError: "Error interno del servidor.",
                    updatedSuccess: "Acciones actualizadas exitosamente",
                    actionsList: "Lista de Acciones",
                    improvementsList: "Lista de Mejoras",
                    updateActions: "Actualizar Acciones",
                    modal: {
                        deleteActionModal: {
                            deleteAction: "Eliminar Acción",
                            confirmDelete: "¿Estás seguro de que deseas eliminar la acción?",
                            delete: "Eliminar",
                            cancel: "Cancelar"
                        },
                        integrationsModal: {
                            actionNotExist: "La acción no existe o no está vinculada a Jira!",
                            unrelatedAction: "Acción no vinculada a Jira!",
                            actionNotModify: "Acción no modificada!",
                            syncActionSuccess: "Acción sincronizada con éxito!",
                            actionNotExist2: "La acción no existe!",
                            unbindSuccess: "Acción desvinculada con éxito!",
                            actionNotFound: "Acción no encontrada!",
                            actionNotModify2: "Acción no modificada!",
                            internalError: "Error interno del servidor.",
                            updateActionSuccess: "Acción actualizada con éxito!",
                            jiraPlatform: "Relación Jira/Plataforma",
                            syncStatus: "Sincronizar estado de Jira con la plataforma",
                            apply: "Aplicar",
                            unlinkEpic: "Desvincular acción del épico",
                            apply2: "Aplicar",
                            linkAction: "Vincular acción a Jira",
                            jiraKey: "Clave de Jira",
                            linkActivities: "Vincular actividades a Jira",
                            jiraKey2: "Clave de Jira",
                            save: "Guardar",
                        }
                    },
                    actionsTable: {
                        name: "Nombre",
                        quadrant: "Cuadrante",
                        init: "Inicio",
                        end: "Fin",
                        status: "Estado",
                        score: "Puntuación",
                        actionNotFound: "Acción no encontrada!",
                        deleteError: "No es posible eliminar esta acción!",
                        updateError: "Error al actualizar la acción!",
                        deleteSuccess: "Acción eliminada con éxito!",
                        internalError: "Error interno del servidor.",
                        active: "Activo",
                        backlog: "Backlog",
                        inProgress: "En progreso",
                        done: "Completado",
                        toDo: "Por hacer",
                        planning: "Planificación",
                        canceled: "Cancelado",
                        activationDiscovery: "Activación - Descubrimiento",
                        acquisitionContent: "Adquisición - Contenido",
                        engagementNextPurchase: "Participación - Próxima Compra",
                        conversionBuyingBarriers: "Conversão - Barreras de Compra",
                        retentionAnalysis: "Conversión - Análisis de experiencia",
                        activationConsideration: "Activación - Consideración",
                        acquisitionTracking: "Adquisición - Seguimiento",
                        engagementContext: "Participación - Contexto",
                        conversionOffers: "Conversión - Ofertas",
                        retentionTransmission: "Retención - Transmisión",
                        activationProspecting: "Activación - Prospección",
                        acquisitionBenefit: "Adquisición - Beneficio",
                        engagementRecovery: "Participación - Recuperación",
                        conversionAuthority: "Conversión - Autoridad",
                        retentionSegments: "Retención - Segmentos",
                    },
                    optionsButton: {
                        edit: "Editar",
                        view: "Visualizar",
                        delete: "Eliminar",
                        integration: "Integración",
                        jira: "Jira",
                    }
                },
                newAction: {
                    nameRequired: "El nombre es obligatorio.",
                    categoryRequired: "La categoría es obligatoria.",
                    startRequired: "El inicio es obligatorio.",
                    estimateRequired: "¡Esta acción requiere una estimación!",
                    actionNotExist: "¡La acción no existe!",
                    errorIntegrationJira: "Error en la integración con Jira, intente guardar la acción nuevamente!",
                    actionNotFound: "Acción no encontrada o no modificada!",
                    internalError: "Error interno del servidor.",
                    contractNotFound: "¡Esta tienda no tiene un contrato!",
                    invalidData: "Datos inválidos!",
                    registerError: "Error al registrar la acción!",
                    sendError: "Error al enviar archivos!",
                    errorUpdateFiles: "Error al actualizar archivos!",
                    errorCampaign: "Campaña no modificada.",
                    updateSuccess: "Acción actualizada con éxito!",
                    createSuccess: "Acción registrada con éxito!",
                    details: "Detalles",
                    activities: "Actividades",
                    analysis: "Análisis",
                    revision: "Revisión",
                    backToList: "Volver a la lista de acciones",
                    edit: "Editar",
                    confirm: "Confirmar",
                    cancel: "Cancelar",
                    prev: "Anterior",
                    next: "Siguiente",
                    actionFloatingButton: {
                        type: "Tipo:",
                        principalChannel: "Canal principal:",
                        credits: "Créditos:",
                        activitiesChannel: "Canales de actividades:",
                        impact: "Impacto:",
                        trust: "Confianza:",
                        facility: "Facilidad:"
                    },
                    modal: {
                        activation: "Activación",
                        acquisition: "Adquisición",
                        engagement: "Participación",
                        conversion: "Conversión",
                        retention: "Retención",
                        recommended: "Recomendado",
                        createAction: "Crear una acción",
                        healthScore: "Puntuación de salud",
                        briefExplanation: "El Health Score es una calificación de 0 a 100 que representa la madurez de su estrategia de comercio electrónico en función de las acciones activas en cada cuadrante.",
                        categories: "Categorías",
                        all: "Todos",
                        createNewAction: "Crear nueva acción",
                        search: "Buscar",
                        searchAction: "Buscar acción",
                        loading: {
                            pleaseWait: "Estamos procesando su solicitud. Por favor espere...",
                        }
                    },
                    pages: {
                        activities: {
                            initialPage: "Página Inicial",
                            creditsLimitReached: "¡Límite de créditos alcanzado!",
                            interfaceDev: "Desarrollo de Interfaz",
                            perTemplate: "por plantilla",
                            descriptionDevInterface: "Desarrollo de Interfaz - Página de Inicio: Aquí se desarrolla la página de inicio del sitio o plataforma, que generalmente presenta información relevante, promociones o destacados de productos, con el objetivo de atraer y comprometer a los visitantes.",
                            categoryPage: "Página de Categoría",
                            descriptionDevCategory: "Desarrollo de Interfaz - Página de Categoría: Esta actividad involucra el desarrollo de la página de categoría, donde los productos se agrupan según sus categorías o tipos, facilitando la navegación y búsqueda para los clientes.",
                            productPage: "Página de Producto",
                            descriptionDevProduct: "Desarrollo de Interfaz - Página de Producto: Aquí se realiza la creación de la página de producto, que muestra información detallada sobre un artículo específico, como descripción, imágenes, precio, opciones de variación y botones de compra, proporcionando una experiencia completa al cliente.",
                            priceUpdate: "Actualización de Precios a través de hoja de cálculo",
                            inventory: "Inventario",
                            perHour: "por hora",
                            descriptionPriceUpdate: "Inventario - Actualización de Precios a través de hoja de cálculo: Se realiza la actualización de precios de productos mediante una hoja de cálculo, permitiendo cambios masivos y facilitando el mantenimiento de los valores actualizados.",
                            productUpdate: "Actualización de Productos",
                            unit: "unidad",
                            descriptionProductUpdate: "Inventario - Actualización de Productos: Aquí se realizan las actualizaciones necesarias en los datos de los productos registrados, como cambios de precio, descripción, imágenes, atributos, entre otros.",
                            diversePage: "Páginas Varias: carrito, mi cuenta, sobre nosotros, etc.",
                            descriptionDiversePage: 'Desarrollo de Interfaz - Páginas Varias: carrito, mi cuenta, sobre nosotros, etc.: Aquí se desarrollan diferentes páginas secundarias o complementarias, como el carrito de compras, el área de la cuenta del usuario, la página "Sobre nosotros" u otras páginas institucionales.',
                            landingPage: "Página de Aterrizaje promocional, upsell, carta de ventas, etc.",
                            descriptionLandingPage: "Desarrollo de Interfaz - Página de Aterrizaje promocional, upsell, carta de ventas, etc.: Esta actividad involucra el desarrollo de páginas de aterrizaje, que son páginas específicas creadas para campañas promocionales, ventas adicionales (upsell) o cartas de ventas, con el objetivo de dirigir a los visitantes a acciones específicas y aumentar las conversiones.",
                            interfaceDesign: "Diseño de Interfaz",
                            descriptionDesignInterface: "Diseño de Interfaz - Página de Inicio: En esta actividad se realiza el diseño de la página de inicio del sitio o plataforma, teniendo en cuenta aspectos visuales como diseño, colores, tipografía y elementos gráficos, buscando transmitir la identidad visual de la marca y proporcionar una experiencia estética agradable a los usuarios.",
                            promotionalHomePage: "Página de Inicio Promocional",
                            descriptionPromotionalHomePage: "Desarrollo de Interfaz - Página de Inicio Promocional: Aquí se desarrolla una versión promocional de la página de inicio, destacando ofertas, descuentos o productos destacados, con el objetivo de impulsar las ventas y llamar la atención de los visitantes.",
                            descriptionDesignCategory: "Diseño de Interfaz - Página de Categoría: Se desarrolla el diseño de la página de categoría, teniendo en cuenta la organización y disposición de los productos, la aplicación de filtros de búsqueda, la presentación de imágenes e información relevante, con el objetivo de facilitar la navegación y hacer que la experiencia del usuario sea más intuitiva.",
                            mainElements: "Elementos Principales: encabezado, pie de página y hoja de estilos",
                            descriptionMainElements: "Desarrollo de Interfaz - Elementos Principales: encabezado, pie de página y hoja de estilos: En esta actividad se desarrollan los elementos principales de una interfaz, como el encabezado (header), el pie de página (footer) y la estilización general de la página (hoja de estilos), garantizando una apariencia consistente y profesional en todo el sitio.",
                            spreadsheetCreation: "Creación/Corrección de Hoja de Cálculo",
                            descriptionSpreadsheetCreation: "Inventario - Creación/Corrección de Hoja de Cálculo: Esta actividad implica la creación o corrección de una hoja de cálculo utilizada para el control y organización de productos, garantizando la consistencia y precisión de la información.",
                            descriptionDesignProduct: "Diseño de Interfaz - Página de Producto: Aquí se realiza el diseño de la página de producto, teniendo en cuenta la presentación de imágenes, descripciones, opciones de variación, botones de compra y otros elementos visuales, con el objetivo de resaltar las características y atractivos del producto, así como proporcionar una interfaz clara y atractiva para los clientes.",
                            descriptionDesignDiversePage: 'Diseño de Interfaz - Páginas Varias: carrito, mi cuenta, sobre nosotros, etc.: Se crean los diseños de las diferentes páginas secundarias o complementarias, como el carrito de compras, el área de cuenta del usuario, la página "Sobre nosotros" u otras páginas institucionales, siguiendo la identidad visual de la marca y garantizando la consistencia estética en todo el sitio.',
                            descriptionDesignLandingPage: "Diseño de Interfaz - Página de Aterrizaje: promocional, upsell, carta de ventas, etc.: Se realiza el diseño de páginas de aterrizaje, que se diseñan de manera atractiva y persuasiva, con elementos visuales impactantes, contenido persuasivo y un claro llamado a la acción, con el objetivo de dirigir a los visitantes hacia una acción específica, como realizar una compra o proporcionar información de contacto.",
                            descriptionDesignPromotionalHomePage: "Diseño de Interfaz - Página de Inicio Promocional: Esta actividad implica el diseño de una versión promocional de la página de inicio, destacando ofertas, descuentos o productos destacados a través de imágenes, banners u otros elementos visuales, con el objetivo de llamar la atención de los visitantes y fomentar la conversión.",
                            uiKit: "UI Kit",
                            descriptionUiKit: "Diseño de Interfaz - UI Kit: En esta actividad, se crea un conjunto de elementos de interfaz (UI Kit), que incluye botones, iconos, casillas de verificación, barras de desplazamiento, entre otros, siguiendo una identidad visual consistente y facilitando el desarrollo y diseño de nuevas páginas y elementos de interfaz.",
                            uxDesignerHour: "Diseñador de UX (por hora)",
                            descriptionUxDesignerHour: "Diseño de Interfaz - Diseñador de UX (Experiencia de Usuario) (por hora): Un diseñador de UX (Experiencia de Usuario) es responsable de crear una experiencia de usuario positiva e intuitiva, analizando flujos de navegación, interacciones, organización de información y usabilidad del sitio o plataforma. En esta actividad, el diseñador de UX trabaja en proyectos específicos por hora, con el objetivo de mejorar la experiencia del usuario.",
                            emailDesign: "Diseño de Correo Electrónico (Autorespondedor, Difusión y Transaccional)",
                            graphicDesign: "Diseño Gráfico",
                            perNotification: "por notificación",
                            descriptionEmailDesign: "Diseño Gráfico - Diseño de Correo Electrónico (Autorespondedor, Difusión y Transaccional): Se realiza el diseño de correos electrónicos para diferentes fines, como autorespondedor (respuestas automáticas), difusión (envío masivo) y transaccional (relacionado con acciones específicas del usuario). Los correos electrónicos pueden incluir elementos gráficos, imágenes, texto formateado y llamados a la acción.",
                            every10Pages: "cada 10 páginas",
                            ebookDesign: "Diseño y Maquetación de E-books",
                            descriptionEbookDesign: "Diseño Gráfico - Diseño y Diagramación de E-books: En esta actividad, se realiza el diseño y diagramación de e-books, que son materiales digitales más extensos, como guías, tutoriales, catálogos o libros electrónicos. El diseño y la diagramación tienen como objetivo hacer que el contenido sea visualmente atractivo y de fácil lectura, con elementos gráficos, ilustraciones y formato adecuado.",
                            imageTreatment: "Tratamiento de Imágenes (para web)",
                            imageEditing: "Edición de Imágenes",
                            perPhoto: "por foto",
                            descriptionImageTreatment: "Edición de Imágenes - Tratamiento de Imágenes (para web): En esta actividad, se editan y tratan las imágenes adecuadamente para la web, incluyendo ajustes de tamaño, optimización de calidad, recorte, corrección de colores, eliminación de imperfecciones, entre otros. El objetivo es asegurar que las imágenes sean visualmente atractivas y se carguen rápidamente en las páginas del sitio o plataforma.",
                            imageBackgroundCutting: "Recorte de Fondo de Imagen (para web)",
                            descriptionImageBackgroundCutting: "Edición de Imagen - Recorte de Fondo de Imagen (para web): En esta actividad se realiza el recorte del fondo de una imagen, eliminando elementos no deseados o aislando el objeto principal. Este recorte se hace específicamente para su uso en la web, permitiendo la inserción del objeto recortado en diferentes contextos sin el fondo original.",
                            videoAdsDesign: "Diseño de Anuncios en Video (15 segundos)",
                            videoEditing: "Edición de Video",
                            perAd: "por anuncio",
                            descriptionVideoAdsDesign: "Edición de Video - Diseño de Anuncios en Video (15 segundos): Esta actividad implica la creación de anuncios en video con una duración de 15 segundos. Se realiza el diseño visual del anuncio, que incluye la selección de imágenes, texto, elementos gráficos y transiciones, con el objetivo de captar la atención del público objetivo y transmitir el mensaje de manera eficiente en el corto tiempo disponible.",
                            motionGraphics: "Motion Graphics",
                            perMinute: "por minuto",
                            descriptionMotionGraphics: "Edición de Video - Motion Graphics: En esta actividad se crean elementos gráficos en movimiento para videos utilizando técnicas de motion graphics. Estos elementos pueden incluir animaciones, transiciones, gráficos animados, texto en movimiento, entre otros, lo que hace que el video sea más dinámico y visualmente interesante.",
                            videoAdsDesign30: "Diseño de Anuncios en Video (30 segundos)",
                            descriptionVideoAdsDesign30: "Edición de Video - Diseño de Anuncios en Video (30 segundos): Similar a la actividad anterior, pero en este caso, los anuncios en video tienen una duración de 30 segundos. El objetivo es crear contenido visualmente atractivo y atractivo dentro de este período de tiempo para atraer y comprometer a los espectadores.",
                            videoEditingPerMin: "Edición de Video (tarifa por minuto)",
                            descriptionVideoEditingPerMin: "Edición de Video - Edición de Video (tarifa por minuto): Aquí se realiza la edición de videos ya grabados, que incluye recortar, unir escenas, agregar transiciones, corregir el color, ajustar el audio y otros ajustes necesarios. La actividad se remunera en función de la duración del video, medida en minutos.",
                            videoCaptioningPerMin: "Subtitulado de Video (tarifa por minuto)",
                            descriptionVideoCaptioningPerMin: "Edición de Video - Subtitulado de Video (tarifa por minuto): Se agregan subtítulos a los videos, transcribiendo el contenido hablado y sincronizándolo con el video. Esta actividad se remunera en función de la duración del video, medida en minutos.",
                            couponCreation: "Creación de Cupones",
                            ecommerceManagement: "Gestión de Comercio Electrónico",
                            perRule: "por regla",
                            descriptionCouponCreation: "Gestión de Comercio Electrónico - Creación de Cupones: En esta actividad se crean cupones de descuento que los clientes pueden utilizar en sus compras. Los cupones pueden ser personalizados, con códigos exclusivos y reglas específicas de uso, lo que contribuye a aumentar las ventas y fidelizar a los clientes.",
                            captationForm: "Formulario de Captación",
                            descriptionCaptationForm: "Gestión de Comercio Electrónico - Formulario de Captación: Se crea un formulario de captación de datos que se puede utilizar para recopilar información de contacto de los clientes, como nombre, correo electrónico, teléfono, entre otros. Estos formularios se integran en el sitio web o plataforma y se pueden utilizar para construir una lista de contactos o segmentar al público objetivo.",
                            scriptManagement: "Gestión de Scripts y Rastreadores (a través de GTM)",
                            perScript: "por script",
                            descriptionScriptManagement: "Gestión de Comercio Electrónico - Gestión de Scripts y Rastreadores (a través de GTM): En esta actividad, se gestionan los scripts y rastreadores (etiquetas) del sitio web o plataforma a través de Google Tag Manager (GTM). Esto implica la creación, edición y eliminación de etiquetas para el seguimiento de eventos, conversiones, análisis del comportamiento del usuario, entre otros.",
                            manualPaymentReconciliation: "Conciliación Manual de Pagos",
                            perTransaction: "por transacción",
                            descriptionManualPaymentReconciliation: "Gestión de Comercio Electrónico - Conciliación Manual de Pagos: En esta actividad, se realiza la conciliación manual de los pagos recibidos por la empresa, verificando si los valores registrados en el sistema son correctos y corresponden a los pagos recibidos efectivamente.",
                            merchandisingAutomation: "Configuración de Automatización de Merchandising (Launchpad)",
                            perAction: "por acción",
                            descriptionMerchandisingAutomation: "Gestión de Comercio Electrónico - Configuración de Automatización de Merchandising (Launchpad): En esta actividad, se configura la automatización de merchandising a través de la plataforma Launchpad. Esto implica definir reglas y condiciones para mostrar banners, escaparates o promociones específicas en función de criterios como el perfil del cliente, el período de tiempo, las categorías de productos, entre otros.",
                            taskAutomation: "Configuración de Automatización de Tareas (Flow)",
                            perAutomation: "por automatización",
                            descriptionTaskAutomation: "Gestión de Comercio Electrónico - Configuración de Automatización de Tareas (Flow): Se configuran automatizaciones de tareas a través de la plataforma Flow, con el objetivo de automatizar procesos internos del comercio electrónico. Esto puede incluir el envío automático de correos electrónicos de seguimiento de pedidos, actualizaciones de estado, solicitudes de retroalimentación, entre otras acciones relacionadas con la relación con el cliente.",
                            reviewsModeration: "Moderación de Comentarios y Reseñas",
                            perReview: "por reseña",
                            descriptionReviewsModeration: "Gestión de Comercio Electrónico - Moderación de Comentarios y Reseñas: En esta actividad, se realiza la moderación de las reseñas y comentarios dejados por los clientes en los productos o servicios de la empresa. La moderación puede implicar la aprobación, edición o eliminación de reseñas, asegurando la calidad y representación adecuada de las opiniones de los clientes.",
                            adConfiguration: "Configuración de Anuncio",
                            marketplaceManagement: "Gestión de Marketplace",
                            perMarketplace: "por marketplace",
                            descriptionAdConfiguration: "Gestión de Marketplace - Configuración de Anuncio: En esta actividad, se realizan las configuraciones necesarias para la publicación de anuncios en marketplaces como Amazon, eBay o Mercado Libre. Esto puede incluir la creación de títulos, descripciones, imágenes, categorías e información adicional para cada anuncio.",
                            adKitConfiguration: "Configuración de Kit para Anuncio",
                            descriptionAdKitConfiguration: "Gestión de Marketplace - Configuración de Kit para Anuncio: Se realiza la configuración de kits de productos para anuncios en marketplaces. Los kits son conjuntos de productos vendidos juntos a un precio único, lo que permite ofrecer paquetes o combinaciones especiales a los clientes.",
                            priceManagement: "Gestión de Precios",
                            perProduct: "por producto",
                            descriptionPriceManagement: "Gestión de Marketplace - Gestión de Precios: En esta actividad, se lleva a cabo la gestión de precios de los productos en los marketplaces, teniendo en cuenta estrategias de fijación de precios, análisis de la competencia, márgenes de beneficio y objetivos de ventas. Los precios se ajustan según las demandas y condiciones del mercado.",
                            rulesConfiguration: "Configuración de Reglas",
                            descriptionRulesConfiguration: "Gestión de Marketplace - Configuración de Reglas: Se configuran reglas específicas en los marketplaces, considerando políticas de ventas, restricciones de productos, políticas de envío y otras pautas establecidas por la plataforma. Esto garantiza que la empresa cumpla con las normas y regulaciones de cada marketplace.",
                            freightManagement: "Gestión de Fletes",
                            descriptionFreightManagement: "Gestión de Marketplace - Gestión de Fletes: Aquí, se lleva a cabo la gestión de los fletes para los productos vendidos en los marketplaces. Esto incluye la configuración de opciones de entrega, cálculo de costos de envío, integración con servicios de transporte y actualización de la información de seguimiento para los clientes.",
                            dnsEntriesEditing: "Edición de Entradas DNS",
                            deployment: "Implementación",
                            perPointing: "por apuntamiento",
                            descriptionDnsEntriesEditing: "Implementación - Edición de Entradas DNS: En esta actividad, se realizan ediciones en las entradas de DNS (Domain Name System) del sitio web o plataforma, configurando la resolución de dominio, redireccionando URL o definiendo registros específicos, como SPF (Sender Policy Framework) o DKIM (DomainKeys Identified Mail), para garantizar la seguridad y el correcto funcionamiento del dominio.",
                            integrationMiddleware: "Integración (a través de middleware)",
                            perConnection: "por conexión",
                            descriptionIntegrationMiddleware: "Implementación - Integración (a través de middleware): Se realiza la integración entre diferentes sistemas o plataformas de comercio electrónico a través de un middleware de CheckStore (n8n), que actúa como intermediario para facilitar el intercambio de información y sincronización de datos. Esto permite una comunicación eficiente entre diferentes sistemas y la integración de procesos comerciales.",
                            scriptInstallationCode: "Instalación de Scripts y Rastreadores (a través de código)",
                            descriptionScriptInstallationCode: "Implementación - Instalación de Scripts y Rastreadores (a través de código): En esta actividad, se instalan scripts y rastreadores en el sitio web o plataforma mediante la inserción de código personalizado. Estos scripts y rastreadores pueden ser proporcionados por terceros y se utilizan para recopilar datos, como análisis de tráfico, seguimiento de conversiones, integración con herramientas de marketing, entre otros.",
                            scriptInstallationGtm: "Instalación de Scripts y Rastreadores (a través de GTM)",
                            descriptionScriptInstallationGtm: "Implementación - Instalación de Scripts y Rastreadores (a través de GTM): Aquí, los scripts y rastreadores se instalan en el sitio web o plataforma a través de Google Tag Manager (GTM). GTM facilita la implementación y gestión de etiquetas de seguimiento, lo que permite agregar, editar y eliminar estas etiquetas sin necesidad de modificar el código del sitio directamente.",
                            frontEndDevHour: "Desarrollo Front-end (por hora)",
                            descriptionFrontEndDevHour: "Implementación - Desarrollo Front-end (por hora): En esta actividad, se realiza el desarrollo de la capa front-end del sitio web o plataforma. Esto implica la creación e implementación de elementos visuales como diseños, estilos, interacciones y animaciones, utilizando tecnologías como HTML, CSS y JavaScript para garantizar una experiencia de usuario atractiva y receptiva.",
                            fullStackDevHour: "Desarrollo Full-stack (por hora)",
                            descriptionFullStackDevHour: "Implementación - Desarrollo Full-stack (por hora): Aquí, se realiza el desarrollo full-stack del sitio web o plataforma, abarcando tanto la capa front-end como la capa back-end. Esto incluye la implementación de funcionalidades, integración con bases de datos, lógica de negocios, autenticación de usuarios y otras tareas, utilizando lenguajes de programación como JavaScript, Python, Ruby o PHP, según las necesidades del proyecto.",
                            emailScheduling: "Programación de Correos Electrónicos",
                            digitalMarketing: "Marketing Digital",
                            descriptionEmailScheduling: "Marketing Digital - Programación de Correos Electrónicos: En esta actividad, se programan el envío de correos electrónicos promocionales, informativos o transaccionales a los clientes. Esto implica la creación del contenido del correo electrónico, la selección de destinatarios, la definición de fechas y horas de envío, además de la configuración de automatizaciones de correo electrónico para proporcionar mensajes personalizados y relevantes a los destinatarios.",
                            softwareArchitectHour: "Arquitecto de Software (por hora)",
                            descriptionSoftwareArchitectHour: "Implementación - Arquitecto de Software (por hora): En esta actividad, se contrata a un arquitecto de software por hora para brindar orientación estratégica y técnica relacionada con la arquitectura del sitio web o plataforma. El arquitecto de software analiza los requisitos del proyecto, define la estructura del sistema, selecciona las tecnologías adecuadas y establece las pautas de desarrollo para garantizar un proyecto sólido, escalable y de alta calidad.",
                            pushNotificationSms: "Notificación Push/SMS",
                            descriptionPushNotificationSms: "Marketing Digital - Notificación Push/SMS: Aquí, se configuran notificaciones push o mensajes SMS que se envían a los usuarios a través de dispositivos móviles o web. Estas notificaciones se pueden utilizar para enviar actualizaciones, alertas, ofertas exclusivas o recordatorios a los clientes, con el objetivo de mantener el compromiso y promover la interacción con el comercio electrónico.",
                            customerSegmentsConfig: "Configuración de Segmentos de Clientes",
                            perSegment: "por segmento",
                            descriptionCustomerSegmentsConfig: "Marketing Digital - Configuración de Segmentos de Clientes: En esta actividad, se configuran segmentos de clientes en función de criterios específicos, como el comportamiento de compra, las preferencias, el historial de interacciones o la información demográfica. La segmentación permite dirigir mensajes personalizados a grupos específicos de clientes, aumentando la relevancia y la eficacia de las campañas de marketing.",
                            marketingAutoFunnelSetup: "Configuración del Embudo de Automatización de Marketing",
                            perFunnel: "por embudo",
                            descriptionMarketingAutoFunnelSetup: "Marketing Digital - Configuración del Embudo de Automatización de Marketing: En esta actividad, se realiza la configuración del embudo de automatización de marketing, que consiste en una secuencia de etapas automatizadas para nutrir leads, convertir clientes potenciales y fomentar la fidelización. Esto implica la creación de flujos de automatización, la definición de desencadenantes, la configuración de correos electrónicos automatizados y el seguimiento del rendimiento del embudo.",
                            customEventsSetup: "Configuración de Eventos Personalizados (a través de GTM)",
                            perEvent: "por evento",
                            descriptionCustomEventsSetup: "Marketing Digital - Configuración de Eventos Personalizados (a través de GTM): En esta actividad, se configuran eventos personalizados en Google Tag Manager (GTM) para rastrear acciones específicas de los usuarios en el sitio web o plataforma. Estos eventos pueden incluir clics en botones, envío de formularios, visualización de páginas, reproducción de videos, entre otros. El seguimiento de eventos personalizados permite obtener información sobre el comportamiento del usuario y optimizar las estrategias de marketing.",
                            scPressAdvisory: "Asesoría de Prensa (medios locales de SC)",
                            perCycle: "por ciclo",
                            descriptionScPressAdvisory: "Marketing Digital - Asesoría de Prensa (medios locales de SC): Esta actividad involucra la asesoría de prensa centrada en los medios locales de Santa Catarina (SC). Se establece contacto con medios de comunicación locales, se elaboran comunicados de prensa, se programan entrevistas y se realiza un seguimiento de noticias y menciones de la empresa en los medios locales.",
                            nationalPressAdvisory: "Asesoría de Prensa (medios nacionales)",
                            descriptionNationalPressAdvisory: "Marketing Digital - Asesoría de Prensa (medios nacionales): Aquí, se lleva a cabo la asesoría de prensa dirigida a los medios de comunicación a nivel nacional. Se establecen contactos con periodistas, se elaboran comunicados de prensa, se divulgan noticias y se obtienen espacios en los medios de comunicación de alcance nacional.",
                            ebookTextOnly: "E-book (solo texto)",
                            descriptionEbookTextOnly: "Marketing Digital - E-book (solo texto): En esta actividad, se desarrolla un e-book que contiene solo texto y aborda un tema relevante e interesante para el público objetivo del comercio electrónico. El e-book puede servir como material educativo, guía práctica, recopilación de consejos o cualquier otro formato que proporcione información valiosa a los lectores.",
                            pressAdvisoryClipping: "Asesoría de Prensa (seguimiento de noticias)",
                            descriptionPressAdvisoryClipping: "Marketing Digital - Asesoría de Prensa (seguimiento de noticias): En esta actividad, se realiza un seguimiento de noticias relacionadas con la empresa en los medios de comunicación. Se monitorean las menciones, reportajes y artículos que mencionan la empresa, se recopilan y organizan para su análisis y seguimiento.",
                            productAdvertisingWriting: "Redacción publicitaria de productos",
                            advertisingWriting: "Redacción Publicitaria",
                            descriptionProductAdvertisingWriting: "Redacción Publicitaria - Redacción publicitaria de productos: Aquí, se elaboran textos publicitarios específicos para la descripción y promoción de productos del comercio electrónico. Los textos se escriben de manera persuasiva, resaltando los beneficios, características y ventajas de los productos, con el objetivo de fomentar la compra y despertar el interés del público objetivo.",
                            pagesContentWriting: "Redacción de Contenido de Páginas",
                            descriptionPageContentWriting: "Redacción Publicitaria - Redacción de Contenido de Páginas: En esta actividad, se crean textos publicitarios para el contenido de las páginas del comercio electrónico, incluyendo la página de inicio, páginas institucionales, páginas especiales de productos, páginas especiales de categorías, entre otras. Los textos se redactan de manera atractiva, informativa y persuasiva, con el objetivo de cautivar a los visitantes y llevarlos a realizar acciones deseadas, como realizar una compra o ponerse en contacto.",
                            customMktReport: "Informe Personalizado de Marketing",
                            reports: "Informes",
                            perMetric: "por métrica",
                            descriptionCustomMktReport: "Informes - Informe Personalizado de Marketing: En esta actividad, se elaboran informes personalizados que presentan un análisis exhaustivo de las estrategias de marketing adoptadas por el comercio electrónico. Los informes pueden incluir métricas como tráfico del sitio web, origen del tráfico, tasas de conversión, compromiso en las redes sociales, análisis de campañas de marketing por correo electrónico, análisis de contenido, entre otra información que ayuda a evaluar la eficacia de las estrategias de marketing y orientar las decisiones futuras.",
                            customSalesReport: "Informe Personalizado de Ventas",
                            descriptionCustomSalesReport: "Informes - Informe Personalizado de Ventas: Aquí, se desarrollan informes personalizados que brindan una visión completa de las ventas realizadas en el comercio electrónico. Los informes pueden incluir métricas como el volumen de ventas, los ingresos, el promedio de ventas por pedido, el margen de beneficio, el análisis de los productos más vendidos, el análisis de los clientes, entre otra información relevante para supervisar el rendimiento y el éxito de las ventas.",
                            seoContent: "Contenido de SEO",
                            seo: "SEO",
                            perUrl: "por URL",
                            descriptionSeoContent: "SEO - Contenido de SEO: En esta actividad, se crea y optimiza el contenido del comercio electrónico para mejorar su visibilidad en los motores de búsqueda. Esto implica la investigación de palabras clave relevantes, la creación de textos y descripciones optimizadas, la implementación de etiquetas HTML adecuadas y otras prácticas recomendadas para mejorar la posición del sitio web en los resultados de búsqueda.",
                            seoAdjustment: "Ajuste Técnico para SEO",
                            descriptionSeoAdjustment: "SEO - Ajuste Técnico para SEO: En esta actividad, se realizan ajustes técnicos en el sitio web o plataforma para la optimización en los motores de búsqueda. Esto puede incluir la optimización de la estructura del sitio, mejorar la velocidad de carga de las páginas, configuración adecuada de metadatos, optimización de URLs, creación de sitemaps e implementación de marcado estructurado, entre otros.",
                            facebookBusiness: "Facebook Business",
                            marketingSetup: "Configuración de Marketing",
                            perService: "por servicio",
                            descriptionFacebookBusiness: "Configuración de Marketing - Facebook Business: En esta actividad, se realiza la configuración de la cuenta de Facebook Business para el comercio electrónico. Esto involucra la creación de la cuenta, configuración de la información de la empresa, asociación de páginas de Facebook, definición de permisos de acceso y realización de integraciones con otras herramientas de marketing de Facebook.",
                            facebookPage: "Página de Facebook",
                            descriptionFacebookPage: "Configuración de Marketing - Página de Facebook: Aquí, se realiza la configuración de la página de Facebook para el comercio electrónico. Esto incluye la creación de la página, personalización del diseño e información, definición de categorías, adición de elementos visuales y configuración de la privacidad y seguridad.",
                            fbInstagramAccount: "Cuenta de Instagram de Facebook",
                            descriptionFbInstagramAccount: "Configuración de Marketing - Cuenta de Instagram de Facebook: En esta actividad, se realiza la configuración de la cuenta de Instagram vinculada al comercio electrónico. Esto involucra la creación de la cuenta, configuración de información del perfil, asociación con la página de Facebook y realización de integraciones con otras herramientas de marketing de Instagram.",
                            fbAdsAccount: "Cuenta de Anuncios de Facebook",
                            descriptionFbAdsAccount: "Configuración de Marketing - Cuenta de Anuncios de Facebook: Aquí, se realiza la configuración de la cuenta de anuncios de Facebook para el comercio electrónico. Esto incluye la creación de la cuenta, configuración de información de facturación, definición de configuraciones de privacidad y seguridad, y asociación con la página de Facebook y la cuenta de Instagram.",
                            fbPixel: "Facebook Pixel",
                            descriptionFbPixel: "Configuración de Marketing - Facebook Pixel: En esta actividad, se realiza la configuración del Facebook Pixel, una herramienta que permite rastrear y medir las acciones de los usuarios en el sitio web o plataforma. Esto incluye la generación del código del píxel, su inserción adecuada en el sitio, configuración de eventos y conversiones a rastrear, y la verificación de su correcto funcionamiento.",
                            fbProductCatalog: "Catálogo de Productos de Facebook",
                            descriptionFbProductCatalog: "Configuración de Marketing - Catálogo de Productos de Facebook: Aquí, se realiza la configuración del catálogo de productos de Facebook, que permite mostrar y promocionar los productos del comercio electrónico en las plataformas de Facebook, como Instagram y Facebook Marketplace. Esto incluye la creación y configuración del catálogo, la sincronización con los productos del comercio electrónico, la adición de información e imágenes de los productos y la configuración de las opciones de visualización y segmentación.",
                            fbPixelTrafficPermissions: "Permisos de Tráfico de Pixel de Facebook",
                            descriptionFbPixelTrafficPermissions: "Configuración de Marketing - Permisos de Tráfico de Pixel de Facebook: En esta actividad, se configuran los permisos de tráfico del píxel de Facebook, determinando qué dominios o URL están autorizados para enviar datos al píxel y qué dominios o URL tienen permiso para recibir datos del píxel. Esto ayuda a garantizar la seguridad e integridad de la información rastreada y compartida por el píxel.",
                            dataLayer: "DataLayer",
                            descriptionDataLayer: "Configuración de Marketing - DataLayer: Aquí, se realiza la configuración del DataLayer, una estructura de datos que permite la recopilación y compartición de información específica sobre eventos e interacciones del usuario en el sitio web o plataforma. Esto implica la implementación adecuada del DataLayer en el código del sitio, la definición de variables y valores a capturar y la configuración de integraciones con otras herramientas de marketing.",
                            googleTagManager: "Google Tag Manager",
                            descriptionGtm: "Configuración de Marketing - Google Tag Manager: En esta actividad, se realiza la configuración del Google Tag Manager (GTM), una herramienta que permite gestionar de forma centralizada la implementación de etiquetas y rastreadores en el sitio web o plataforma. Esto incluye la creación de la cuenta de GTM, la configuración de la estructura del contenedor, la implementación del código de GTM en el sitio y la configuración de las etiquetas y disparadores necesarios para rastrear eventos e interacciones específicas.",
                            googleAnalytics: "Google Analytics",
                            descriptionGoogleAnalytics: "Configuración de Marketing - Google Analytics: Aquí, se realiza la configuración de Google Analytics, una herramienta de análisis de datos que proporciona información detallada sobre el tráfico, el comportamiento del usuario, las conversiones y otras métricas importantes del sitio web o plataforma. Esto implica la creación de la cuenta de Google Analytics, la configuración del código de seguimiento, la definición de objetivos y conversiones, y la personalización de informes y paneles de control.",
                            advancedEcommerceGA: "Google Analytics Comercio Electrónico Avanzado",
                            descriptionAdvancedGA: "Configuración de Marketing - Google Analytics Comercio Electrónico Avanzado: En esta actividad, se realiza la configuración de funciones avanzadas de Google Analytics para el análisis de comercio electrónico. Esto incluye la configuración del seguimiento de transacciones, análisis de embudo de conversión, análisis de productos y categorías, análisis del rendimiento de ventas, entre otras métricas específicas para el comercio electrónico.",
                            googleSearchConsole: "Google Search Console",
                            descriptionGoogleSearchConsole: "Configuración de Marketing - Google Search Console: En esta actividad, se realiza la configuración de Google Search Console, una herramienta que proporciona información detallada sobre la presencia del sitio en los resultados de búsqueda de Google. Esto incluye la creación de la cuenta de Search Console, la verificación de la propiedad del sitio, el envío del mapa del sitio, el seguimiento del rendimiento de búsqueda, la identificación de errores y problemas de indexación, y el monitoreo de palabras clave y posiciones en los resultados de búsqueda.",
                            googleMerchantCenter: "Google Merchant Center",
                            descriptionGoogleMerchantCenter: "Configuración de Marketing - Google Merchant Center: Aquí, se realiza la configuración de Google Merchant Center, una plataforma que permite a los comerciantes cargar y gestionar información de productos para que aparezcan en los resultados de búsqueda de Google y en otros servicios de Google, como Google Shopping. Esto implica la creación de la cuenta de Merchant Center, la configuración de la información comercial, la importación de datos del catálogo de productos, la configuración de opciones de visualización y segmentación, y la verificación del cumplimiento de las políticas de Google.",
                            googleAds: "Google Ads",
                            descriptionGoogleAds: "Configuración de Marketing - Google Ads: En esta actividad, se realiza la configuración de Google Ads (anteriormente conocido como Google AdWords), una plataforma de publicidad en línea que permite la creación y gestión de campañas de anuncios pagados en los motores de búsqueda de Google y en otros sitios web asociados. Esto incluye la creación de la cuenta de Google Ads, la configuración de la información de facturación, la definición de la configuración de la campaña, la creación de grupos de anuncios, la selección de palabras clave relevantes y la creación de anuncios persuasivos.",
                            googleAdsConvBuy: "Conversiones de Compra en Google Ads",
                            descriptionGoogleAdsConvBuy: "Configuración de Marketing - Conversiones de Compra en Google Ads: Aquí, se realiza la configuración de conversiones de compra en Google Ads. Esto implica la configuración de etiquetas y eventos para rastrear conversiones específicas relacionadas con transacciones y compras en el comercio electrónico, lo que permite medir el retorno de la inversión (ROI) de las campañas de anuncios pagados.",
                            googleAdsRemarketing: "Remarketing en Google Ads",
                            descriptionGoogleAdsRemarketing: "Configuración de Marketing - Remarketing en Google Ads: En esta actividad, se realiza la configuración de campañas de remarketing en Google Ads. Esto incluye la creación de listas de remarketing basadas en el comportamiento del usuario en el sitio web o plataforma, la configuración de anuncios personalizados dirigidos a estas listas y la definición de estrategias de puja y segmentación para llegar a los usuarios que ya interactuaron con el comercio electrónico.",
                            googleAdsAnalyticsLink: "Vinculación de Google Ads con Google Analytics",
                            descriptionGoogleAdsLinking: "Configuración de Marketing - Vinculación de Google Ads con Google Analytics: En esta actividad, se realiza la vinculación de la cuenta de Google Ads con la cuenta de Google Analytics. Esto permite la sincronización de datos entre ambas plataformas, proporcionando una vista integrada de las métricas y el rendimiento de las campañas de anuncios pagados y su impacto en las interacciones de los usuarios en el sitio web o plataforma.",
                            googleAdsConvLink: "Vinculador de Conversiones de Google Ads",
                            descriptionGoogleAdsConvLinker: "Configuración de Marketing - Vinculador de Conversiones de Google Ads: Aquí, se realiza la configuración del Vinculador de Conversiones de Google Ads, que permite asociar conversiones rastreadas en Google Analytics a campañas y anuncios específicos en Google Ads. Esto facilita el análisis del rendimiento de las campañas y la atribución correcta de las conversiones generadas por los anuncios pagados.",
                            googleAdsMerchantLink: "Vinculación de Google Ads con Merchant Center",
                            descriptionGoogleAdsMerchantLinking: "Configuración de Marketing - Vinculación de Google Ads con Merchant Center: En esta actividad, se realiza la vinculación de la cuenta de Google Ads con la cuenta de Google Merchant Center. Esto permite la sincronización de datos sobre productos y campañas de anuncios pagados, lo que posibilita la visualización de anuncios de productos relevantes en los resultados de búsqueda y en Google Shopping.",
                            ga4EventsImplementation: "Implementación de eventos de GA4 a través de Google Tag Manager",
                            descriptionGA4EventsImplementation: "Configuración de Marketing - Implementación de eventos de GA4 a través de Google Tag Manager: En esta actividad, se realiza la implementación de eventos de Google Analytics 4 (GA4) a través de Google Tag Manager. Esto incluye la configuración de eventos personalizados en GTM, la creación de variables y desencadenadores para capturar interacciones específicas de los usuarios en el sitio web o plataforma y el envío de los datos correspondientes a GA4 para su análisis.",
                            ga4ReportsImplementation: "Implementación de informes de GA4",
                            descriptionGA4ReportsImplementation: "Configuración de Marketing - Implementación de informes de GA4: En esta actividad, se realiza la implementación de informes personalizados en Google Analytics 4 (GA4). Esto implica la configuración de informes específicos para proporcionar información y métricas relevantes para el comercio electrónico, como análisis de embudo de conversión, análisis de eventos, análisis de audiencia y otros datos que ayudan a comprender el comportamiento y el rendimiento de los usuarios.",
                            marketingCloudTag: "Etiqueta de la Plataforma de Marketing en la Nube",
                            descriptionMarketingCloudTag: "Configuración de Marketing - Etiqueta de la Plataforma de Marketing en la Nube: En esta actividad, se realiza la configuración de la etiqueta de la plataforma de marketing en la nube en el sitio web o plataforma. Esto permite la recopilación de datos sobre el comportamiento del usuario, interacciones, conversiones y otra información relevante para el análisis y la personalización del marketing basado en datos.",
                            multilingualTranslation: "Traducción multilingüe por página/plantilla",
                            translation: "Traducción",
                            descriptionMultilanguageTranslation: "Traducción - Traducción multilingüe por página/plantilla: En esta actividad, se realiza la traducción de páginas o plantillas del comercio electrónico a otros idiomas. Esto incluye la traducción de textos, descripciones de productos, botones, menús y otros elementos visuales y de comunicación para proporcionar una experiencia localizada a los usuarios que hablan diferentes idiomas.",
                            searchAdsCampaignSetup: "Configuración de campaña de Search Ads",
                            paidTraffic: "Tráfico de pago",
                            adSet: "conjunto de anuncios",
                            descriptionSearchAdsCampaignSetup: "Tráfico de pago - Configuración de campaña de Search Ads: Aquí, se realiza la configuración de campañas de anuncios de Search Ads, que se muestran en los resultados de búsqueda de los motores de búsqueda. Esto implica la configuración de palabras clave, la creación de anuncios relevantes y la definición de pujas y presupuestos para llegar a usuarios interesados en los productos o servicios del comercio electrónico.",
                            productListingAdsCampaignSetup: "Configuración de Campañas de Anuncios de Listado de Productos (Shopping)",
                            descriptionProductListingAdsCampaignSetup: "Tráfico Pago - Configuración de Campañas de Anuncios de Listado de Productos (Shopping): Aquí se realiza la configuración de campañas de anuncios de Listado de Productos (PLAs), que se muestran en los resultados de búsqueda de Google Shopping. Esto incluye la creación de un catálogo de productos en el Centro de comerciantes, la configuración de la información de los productos y la definición de ofertas y presupuestos para mostrar anuncios de productos relevantes a los usuarios.",
                            leadAdsCampaignSetup: "Configuración de la Campaña de Anuncios de Generación de Leads",
                            descriptionLeadAdsCampaignSetup: "Tráfico de Pago - Configuración de la Campaña de Anuncios de Generación de Leads: Aquí se lleva a cabo la configuración de campañas de anuncios de Generación de Leads, diseñadas para capturar información de contacto y generar leads calificados. Esto implica la creación de formularios de generación de leads, la definición de criterios de segmentación para mostrar anuncios a usuarios relevantes y la configuración de flujos de seguimiento e integraciones con herramientas de automatización de marketing.",
                            displayAdsCampaignSetup: "Configuración de la Campaña de Anuncios de Display",
                            descriptionDisplayAdsCampaignSetup: "Tráfico de Pago - Configuración de la Campaña de Anuncios de Display: En esta actividad, se realiza la configuración de campañas de anuncios de Display Ads, que se muestran en sitios web asociados a través de banners, imágenes o videos. Esto incluye la configuración de criterios de segmentación, la creación de anuncios persuasivos y la definición de estrategias de ofertas y presupuestos para llegar al público objetivo del comercio electrónico.",
                            videoAdsCampaignSetup: "Configuración de la Campaña de Anuncios de Video",
                            descriptionVideoAdsCampaignSetup: "Tráfico de Pago - Configuración de la Campaña de Anuncios de Video: Aquí se lleva a cabo la configuración de campañas de anuncios de Video Ads, que son videos promocionales mostrados en plataformas de video como YouTube. Esto implica la creación de videos relevantes e impactantes, la configuración de criterios de segmentación y la definición de estrategias de ofertas y presupuestos para llegar al público objetivo del comercio electrónico.",
                            interestAudiencesConfig: "Configuración de Audiencias de Interés",
                            descriptionInterestAudiencesConfig: "Tráfico de Pago - Configuración de Audiencias de Interés: En esta actividad, se configuran audiencias de interés segmentadas para las campañas de tráfico de pago. Esto implica la definición de criterios demográficos, de comportamiento e interés para segmentar los anuncios a usuarios más propensos a interesarse en los productos o servicios del comercio electrónico.",
                            customAudiencesConfig: "Configuración de Audiencias Personalizadas",
                            descriptionCustomAudiencesConfig: "Tráfico de Pago - Configuración de Audiencias Personalizadas: Aquí se configuran audiencias personalizadas para las campañas de tráfico de pago. Esto puede incluir la creación de audiencias basadas en datos de clientes existentes, listas de correo electrónico, visitantes del sitio web, interacciones en redes sociales y otras fuentes de datos relevantes. Estas audiencias personalizadas permiten dirigir los anuncios a usuarios más calificados y propensos a convertirse en clientes.",
                            campaignOptimization: "Optimización de Campañas",
                            perDay: "por día",
                            descriptionCampaignOptimization: "Tráfico de Pago - Optimización de Campañas: En esta actividad, se realizan optimizaciones continuas en las campañas de tráfico de pago para maximizar el rendimiento y los resultados. Esto incluye el análisis de métricas, ajustes de ofertas y presupuestos, refinamiento de segmentación, pruebas de creatividades y mensajes, e implementación de estrategias de optimización para alcanzar los objetivos definidos.",
                            navigationUsabilityAnalysis: "Análisis de Usabilidad de Navegación",
                            uiUx: "UI & UX",
                            descriptionNavigationUsabilityAnalysis: "UI & UX - Análisis de Usabilidad de Navegación: En esta actividad, se realiza un análisis detallado de la usabilidad de la navegación del sitio web o plataforma. Esto incluye la revisión de la arquitectura de la información, la organización de menús, el flujo de navegación, la categorización de productos y otras interacciones del usuario. El objetivo es identificar puntos de mejora, simplificar la navegación y proporcionar una experiencia más intuitiva y agradable para los usuarios.",
                            imageCuttingResizing: "Recorte y Redimensionamiento de Imágenes",
                            descriptionImageCroppingResizing: "Diseño Gráfico - Recorte y Redimensionamiento de Imágenes: En esta actividad, se realizan recortes precisos y redimensionamientos de imágenes según las necesidades del proyecto. Esto incluye la eliminación de fondos no deseados, ajustes de proporción, resolución y tamaño, asegurando que las imágenes estén listas para su uso en diferentes contextos, como sitios web, redes sociales, impresión, entre otros.",
                            creativeAdjustments: "Ajustes Creativos",
                            descriptionCreativeAdjustments: "Diseño Gráfico - Ajustes Creativos: Aquí se realizan ajustes en los elementos visuales existentes, como banners, anuncios u otros recursos gráficos. Esto implica mejorar la estética, realizar cambios en el diseño, ajustar colores, fuentes, imágenes y otros elementos, con el objetivo de mejorar la comunicación visual y garantizar la consistencia de la identidad de la marca.",
                            seoReport: "Informe de SEO (off-page)",
                            perStore: "por tienda",
                            descriptionSeoOffPageReport: "SEO - Informe de SEO (off-page): En esta actividad, se elabora un informe integral que analiza las estrategias y acciones de optimización de SEO realizadas fuera del sitio. Esto incluye el análisis de factores externos que afectan la clasificación en los motores de búsqueda, como la calidad y cantidad de backlinks, menciones en redes sociales, presencia en directorios y otras acciones de marketing fuera de la página.",
                            erpProductRegistration: "Registro de Productos en ERP",
                            descriptionErpProductRegistration: "Inventario - Registro de Productos en ERP: En esta actividad, se registran los productos en el sistema de gestión empresarial (ERP) del comercio electrónico. Esto implica ingresar información detallada sobre los productos, como descripciones, imágenes, precios, inventario, categorías, atributos específicos y otra información relevante para el control del inventario y la exposición adecuada de los productos.",
                            seoReportInPage: "Informe de SEO (in-page)",
                            perPage: "por página",
                            descriptionSeoInPageReport: "SEO - Informe de SEO (in-page): Aquí se elabora un informe que se centra en las optimizaciones realizadas directamente en las páginas del sitio. Esto incluye el análisis de factores como palabras clave, estructura de URL, meta etiquetas, uso adecuado de etiquetas de encabezado, calidad y relevancia del contenido, velocidad de carga y otros aspectos que afectan la visibilidad y clasificación en los motores de búsqueda.",
                            showcaseOptimization: "Optimización de Escaparate",
                            perShowcase: "por escaparate",
                            descriptionShowcaseOptimization: "Gestión de Comercio Electrónico - Optimización de Escaparate: En esta actividad, se realiza la optimización del escaparate del comercio electrónico, la zona donde se muestran los productos en la página de inicio o destacados. Esto implica una cuidadosa selección de los productos a mostrar, una ordenación estratégica, personalización visual y actualizaciones periódicas para promover los artículos más relevantes y captar la atención de los visitantes, aumentando las posibilidades de conversión.",
                            appConfigurationHour: "Configuración de Aplicación (por hora)",
                            descriptionAppConfiguration: "Gestión de Comercio Electrónico - Configuración de Aplicación (por hora): Aquí se realiza la configuración de una aplicación móvil dedicada al comercio electrónico. Esto puede incluir la integración con el sistema de gestión existente, la configuración de funciones específicas como notificaciones push, personalización de la interfaz de usuario, configuración de seguridad y otras personalizaciones para ofrecer una experiencia mejorada a los usuarios de la aplicación.",
                            contentRegistration: "Registro de Contenido (páginas institucionales, entradas de blog, preguntas frecuentes, etc.)",
                            descriptionContentRegistration: "Gestión de Comercio Electrónico - Registro de Contenido (páginas institucionales, entradas de blog, preguntas frecuentes, etc.): En esta actividad, se realiza el registro y actualización de contenido en diversas áreas del comercio electrónico. Esto incluye la creación y edición de páginas institucionales, entradas de blog, secciones de preguntas frecuentes (FAQ) y otro contenido relevante para proporcionar información a los clientes, mejorar la experiencia del usuario y aumentar el compromiso.",
                            scriptManagementHour: "Gestión de Scripts y Seguimientos (por hora)",
                            descriptionScriptsTrackersManagement: "Despliegue - Gestión de Scripts y Seguimientos (por hora): En esta actividad, se realiza la gestión de scripts y seguimientos en el sitio web o plataforma de comercio electrónico. Esto implica la implementación y configuración de scripts personalizados, como seguimiento de conversiones, análisis de datos, chatbots, personalización de contenido y otras funcionalidades que requieren conocimientos técnicos para garantizar un funcionamiento adecuado y un seguimiento preciso de las acciones realizadas por los usuarios.",
                            smsContentCreation: "Creación de Contenido SMS",
                            perMessage: "por mensaje",
                            descriptionSmsContentCreation: "Redacción Publicitaria - Creación de Contenido SMS: Aquí se realiza la creación de contenido publicitario para ser enviado a través de mensajes de texto (SMS). Esto incluye la redacción de mensajes cortos y persuasivos que comuniquen de manera efectiva promociones, ofertas exclusivas, actualizaciones relevantes o llamados a la acción específicos, con el objetivo de llegar y comprometer al público objetivo a través de esta plataforma de comunicación directa.",
                            trafficStrategyAnalysis: "Análisis de Estrategia de Tráfico",
                            cro: "CRO",
                            perReport: "por informe",
                            descriptionTrafficStrategyAnalysis: "CRO - Análisis de Estrategia de Tráfico: En esta actividad, se realiza un análisis detallado de la estrategia de tráfico del comercio electrónico. Esto incluye la evaluación de las fuentes de tráfico, como orgánico, pago, social, referencia, entre otros, para identificar qué canales están generando más visitantes y conversiones. Sobre la base de este análisis, se proponen estrategias de optimización y reasignación de recursos para maximizar el retorno de la inversión en marketing.",
                            navigationFlowAnalysis: "Análisis del Flujo de Navegación",
                            descriptionNavigationFlowAnalysis: "CRO - Análisis del Flujo de Navegación: Aquí se realiza un análisis del flujo de navegación de los usuarios en el sitio web o plataforma de comercio electrónico. Esto incluye el estudio del comportamiento de los visitantes, las páginas más visitadas, los puntos de entrada y salida, los caminos recorridos y los posibles obstáculos que pueden afectar la experiencia del usuario. Sobre la base de este análisis, se proponen mejoras en el diseño, la navegación y la usabilidad para aumentar la tasa de conversión.",
                            conversionRateAnalysis: "Análisis de la Tasa de Conversión",
                            descriptionConversionRateAnalysis: "CRO - Análisis de la Tasa de Conversión: En esta actividad, se realiza un análisis exhaustivo de la tasa de conversión del comercio electrónico. Esto implica la identificación de métricas clave, como la tasa de conversión por canal, por página de destino, por segmento de público, entre otros. Sobre la base de este análisis, se proponen estrategias de optimización, como pruebas A/B, personalización de contenido y mejoras en la experiencia del usuario, para aumentar la tasa de conversión global del comercio electrónico.",
                            onsiteNotification: "Notificación en el Sitio",
                            descriptionOnSiteNotification: "Gestión de Comercio Electrónico - Notificación en el Sitio: Aquí se realiza la configuración e implementación de notificaciones en el sitio web de comercio electrónico. Esto incluye la creación de mensajes personalizados, como ventanas emergentes, barras de notificación o alertas, que se muestran en el sitio web para proporcionar información importante, fomentar acciones específicas (como descuentos por tiempo limitado) o capturar leads. Estas notificaciones pueden segmentarse según el comportamiento del usuario u otros criterios relevantes.",
                            goalsProjectionsReports: "Informes de Objetivos y Proyecciones",
                            descriptionGoalsProjectionsReports: "Informes - Informes de Objetivos y Proyecciones: En esta actividad, se crean informes que siguen el progreso hacia los objetivos y hacen proyecciones para el rendimiento futuro del comercio electrónico. Esto incluye análisis de métricas relevantes, como ventas, tráfico, conversiones, ROI, entre otros, para proporcionar una visión clara del rendimiento actual y ayudar en la toma de decisiones estratégicas para alcanzar los objetivos establecidos.",
                            seoPpcBenchmarkingReport: "Informe de Comparación - SEO y PPC",
                            descriptionSeoPpcBenchmarkingReport: "Informes - Informe de Comparación - SEO y PPC: En esta actividad, se elabora un informe de comparación que compara el rendimiento del comercio electrónico en términos de SEO (optimización para motores de búsqueda) y PPC (pago por clic). Esto implica el análisis de la posición en los resultados de búsqueda, el análisis de la competencia, la evaluación del rendimiento de las campañas de tráfico pago y otros factores importantes para identificar oportunidades de mejora y mantenerse competitivo en el mercado.",
                            organicTrafficMktFunnelDesign: "Diseño de Embudo de Marketing para Campañas de Tráfico Orgánico",
                            descriptionOrganicTrafficMktFunnelDesign: "Marketing Digital - Diseño de Embudo de Marketing para Campañas de Tráfico Orgánico: Aquí se realiza el diseño de un embudo de marketing estratégico para campañas de tráfico orgánico. Esto implica la creación de contenido relevante para atraer visitantes, la segmentación del público objetivo según intereses y comportamientos, la nutrición de leads a través de correos electrónicos u otros canales y la optimización del embudo para aumentar la conversión de visitantes en clientes.",
                            lifecycleMktFunnelDesign: "Diseño de Embudo de Marketing para Campañas de Marketing del Ciclo de Vida",
                            descriptionLifecycleMktFunnelDesign: "Gestión de Comercio Electrónico - Diseño de Embudo de Marketing para Campañas de Marketing del Ciclo de Vida: En esta actividad, se realiza el diseño de un embudo de marketing estratégico para campañas de marketing del ciclo de vida, que siguen el ciclo de vida de los clientes. Esto implica definir las diferentes etapas del ciclo de vida, como adquisición, activación, retención y fidelización, y crear estrategias de marketing personalizadas para cada etapa, con el objetivo de maximizar el valor del cliente y fomentar la lealtad.",
                            gtmTagsReview: "Revisión de Etiquetas implementadas en el Administrador de Etiquetas de Google en diversas plataformas",
                            descriptionGtmTagsReview: "Informes - Revisión de Etiquetas implementadas en el Administrador de Etiquetas de Google en diversas plataformas: Aquí se realiza una revisión completa de las etiquetas implementadas en el Administrador de Etiquetas de Google para diversas plataformas de comercio electrónico. Esto incluye verificar la implementación correcta de etiquetas de análisis, remarketing, chatbots, optimización de la conversión y otras herramientas en diferentes páginas y eventos, asegurando que los datos se recopilen de manera precisa y confiable para análisis posteriores.",
                            paidTrafficBillingReport: "Informe de Facturación de Tráfico Pago en Plataformas",
                            descriptionPaidTrafficBillingReport: "Tráfico Pago - Informe de Facturación de Plataformas de Tráfico Pago: Aquí se elabora un informe que presenta una visión detallada de la facturación y gastos en las plataformas de tráfico pago, como Google Ads, Facebook Ads, entre otras. Esto incluye información sobre los costos de las campañas, los gastos por canal, las métricas de rendimiento asociadas a las inversiones y otros análisis financieros relevantes para monitorear el presupuesto y maximizar el retorno de la inversión.",
                            performanceReport: "Informe de Rendimiento (Velocidad, Usabilidad, Tasa de Conversión y Canales de Adquisición)",
                            descriptionPerformanceReport: "Informes - Informe de Rendimiento (Velocidad, Usabilidad, Tasa de Conversión y Canales de Adquisición): En esta actividad se elabora un informe integral que evalúa el rendimiento del comercio electrónico en términos de velocidad de carga, usabilidad, tasa de conversión y canales de adquisición de tráfico. Esto implica análisis detallados de estos aspectos, identificando áreas de mejora, oportunidades de optimización y brindando ideas para orientar la estrategia de marketing digital.",
                            lifecycleMktPerformanceReport: "Informe y Análisis de Rendimiento de Marketing del Ciclo de Vida",
                            perBrand: "por marca",
                            descriptionLifecycleMktPerformanceReport: "Informes - Informe y Análisis de Rendimiento de Marketing del Ciclo de Vida: En esta actividad se elaboran informes que analizan el rendimiento de las campañas de marketing del ciclo de vida en el comercio electrónico. Esto implica el análisis de métricas clave en cada etapa del ciclo de vida del cliente, como la tasa de conversión, la retención, el valor promedio del pedido, entre otros, para identificar oportunidades de mejora y optimizar las estrategias de marketing dirigidas a lo largo del ciclo de vida.",
                            htmlTemplateDev: "Desarrollo de Plantilla HTML",
                            descriptionHtmlTemplateDevelopment: "Desarrollo de Interfaz - Desarrollo de Plantilla HTML: En esta actividad se lleva a cabo el desarrollo de una plantilla HTML personalizada para el comercio electrónico. Esto implica la creación de un diseño visualmente atractivo y funcional, la codificación de la plantilla en HTML y CSS, la implementación de características interactivas y asegurar que la plantilla sea receptiva, es decir, que se adapte a diferentes dispositivos y tamaños de pantalla, brindando una experiencia consistente a los usuarios.",
                            ecommerceBannerDesignDesktop: "Diseño de Banner para Comercio Electrónico (escritorio)",
                            descriptionEcommerceBannerDesign: "Diseño Gráfico - Diseño de Banner para Comercio Electrónico (escritorio y móvil): Aquí se realiza el diseño de banners específicos para el comercio electrónico, teniendo en cuenta diferentes formatos y tamaños adecuados para su visualización en escritorio y dispositivos móviles. Los banners pueden utilizarse para promociones, anuncios o destacar productos específicos.",
                            ecommerceBannerDesignMobile: "Diseño de Banner para Comercio Electrónico (móvil)",
                            staticAdDesign: "Diseño de Anuncio Estático",
                            descriptionDisplayAdsDesign: "Diseño Gráfico - Diseño de Anuncios de Display: Se realiza el diseño de anuncios de display, que son elementos visuales utilizados en campañas de publicidad en línea, mostrados en sitios web o aplicaciones de terceros. Estos anuncios pueden incluir imágenes, texto y llamadas a la acción, con el objetivo de atraer la atención de los usuarios y dirigirlos al sitio web o plataforma de la empresa.",
                            carouselAdDesign: "Diseño de Anuncio Carrusel",
                            descriptionSocialAdsDesign: "Diseño Gráfico - Diseño de Anuncios de Display en Redes Sociales: Esta actividad implica el diseño de anuncios de display específicos para redes sociales, como Facebook, Instagram o Twitter. Los anuncios pueden diseñarse en diferentes formatos y tamaños, con el objetivo de generar participación y conversiones a través de las plataformas de medios sociales.",
                            storiesAdDesign: "Diseño de Anuncio Stories",
                            descriptionSocialAdsCarouselDesign: "Diseño Gráfico - Diseño de Anuncio de Carrusel en Redes Sociales: Se realiza el diseño de anuncios de carrusel para redes sociales, donde se muestran varias imágenes o tarjetas en secuencia, lo que permite contar una historia o mostrar varios productos en un solo anuncio.",
                            programmaticMediaCreativeSet: "Conjunto de Creativos para Publicidad Programática",
                            for12Formats: "para 12 formatos",
                            descriptionGoogleDisplayCreativeSet: "Diseño Gráfico - Conjunto de Creativos para Google Display: Se crea un conjunto de creativos visuales para campañas de Google Display, que incluye diferentes formatos y tamaños de anuncios visuales, como banners estáticos o animados, con el objetivo de captar la atención del público objetivo y generar clics y conversiones.",
                            googlePrfmMaxCreativeSet: "Conjunto de Creativos para Google Performance Max",
                            for6Formats: "para 6 formatos",
                            descriptionGoogleMaxPrfmCreativeSet: "Diseño Gráfico - Conjunto de Creativos para Google Max Performance: En esta actividad, se crean conjuntos de creativos visuales para campañas de Google Max Performance. Estos creativos pueden incluir anuncios de video, banners, anuncios de carrusel u otros formatos adecuados para la plataforma, con el objetivo de optimizar el rendimiento de la campaña y generar resultados eficientes.",
                            merchandisingInitialPage: "Merchandising (página de inicio)",
                            descriptionMerchandising: "Gestión de Comercio Electrónico - Merchandising (página de inicio): Esta actividad implica la gestión de banners y escaparates en el comercio electrónico, donde se configuran y actualizan los elementos visuales destacados, como banners promocionales, escaparates temáticos o destacados de productos, con el objetivo de dirigir la atención de los clientes hacia ofertas o productos específicos.",
                            simpleBannerRegistration: "Registro de Banners Simples",
                            descriptionSimpleBannerRegistration: "Gestión de Comercio Electrónico - Registro de Banners Simples: Esta actividad implica reemplazar los banners actuales por nuevos sin cambiar la estructura base del diseño de la página.",
                            completeBannerRegistration: "Registro de Banners Completo",
                            descriptionFullBannerRegistration: "Gestión de Comercio Electrónico - Registro de Banners Completo: Esta actividad implica reemplazar los banners actuales por nuevos, así como cambiar las colecciones y textos de las secciones sin modificar la estructura base del diseño de la página.",
                            emailMktContactsMigration: "Migración de Segmentos y Contactos de Email Marketing",
                            descriptionEmailMktMigration: "Gestión de Comercio Electrónico - Migración de Segmentos y Contactos de Email Marketing: En esta actividad, se realiza la migración y organización de segmentos y contactos de la lista de email marketing del comercio electrónico. Esto incluye la revisión de segmentos existentes, la creación de nuevos segmentos basados en criterios específicos, la importación y exportación de contactos, garantizando la actualización y calidad de la lista para la implementación efectiva de campañas de email marketing.",
                            emailMktFlowsMigration: "Migración de Flujos de Email Marketing",
                            perEmail: "por email (diseño y reglas)",
                            descriptionEmailMktFlowsMigration: "Gestión de Comercio Electrónico - Migración de Flujos de Email Marketing: Aquí se realiza la migración de flujos de automatización de email marketing del comercio electrónico a una nueva plataforma o herramienta. Esto implica la revisión de flujos existentes, la creación de flujos automatizados personalizados, la configuración de disparadores y segmentaciones, garantizando una transición suave y eficiente a la nueva plataforma de automatización.",
                            collOptimizationAutomatic: "Optimización y Ordenación Automática de Colecciones",
                            perCollection: "por colección",
                            descriptionCollOptimizationAutomatic: "Gestión de Comercio Electrónico - Optimización y Ordenación Automática de Colecciones: En esta actividad, se lleva a cabo la optimización y ordenación automática de las colecciones de productos del comercio electrónico. Esto implica configurar reglas de visualización como relevancia, popularidad, precio, disponibilidad y otros criterios para asegurar que los productos se presenten de manera adecuada y atractiva a los clientes, mejorando la experiencia de compra.",
                            collOptimizationManual: "Optimización y Ordenación Manual de Colecciones",
                            descriptionCollOptimizationManual: "Gestión de Comercio Electrónico - Optimización y Ordenación Manual de Colecciones: Aquí se realiza la optimización y ordenación manual de las colecciones de productos del comercio electrónico. Esto implica revisar y clasificar manualmente los productos en función de criterios específicos como las tendencias del mercado, las preferencias del público objetivo, el margen de beneficio, el stock disponible, con el fin de garantizar una presentación eficiente y atractiva de los productos en el sitio o plataforma.",
                            shopifyAppInstallation: "Instalación de la Aplicación Shopify",
                            perApp: "por aplicación",
                            descriptionShopifyAppImplementation: "Implementación - Aplicación Shopify: En esta actividad, se realiza la implementación de una aplicación específica para la plataforma Shopify, agregando nuevas funcionalidades, características o integraciones al comercio electrónico. Esto permite expandir las capacidades de la tienda en línea y satisfacer las necesidades específicas del negocio.",
                            vtexAppInstallation: "Instalación de la Aplicación Vtex Marketplace",
                            descriptionVtexAppInstallation: "Implementación - Vtex Marketplace: Aquí se realiza la implementación del comercio electrónico en la plataforma Vtex Marketplace, configurando las integraciones necesarias, definiendo las configuraciones de catálogo, precios, inventario, envío y otras funcionalidades relacionadas con la operación en el marketplace.",
                            wordpressPluginInstallation: "Instalación del Plugin WordPress",
                            perPlugin: "por plugin",
                            descriptionWordpressImplementation: "Implementación - WordPress: En esta actividad, se realiza la implementación del comercio electrónico en la plataforma WordPress, que es una solución de gestión de contenido (CMS). Esto implica la configuración de la plataforma, la elección de temas, la instalación de plugins y la personalización del entorno.",
                            externalPlatformDataAutomationConfig: "Configuración de automatización de datos a través de plataforma externa",
                            descriptionDataAutomationConfiguration: "Implementación - Configuración de Automatización de Datos a través de Plataforma Externa: En esta actividad, se realiza la configuración de la automatización de datos a través de una plataforma externa para el comercio electrónico. Esto puede incluir la integración y configuración de flujos de datos automatizados entre diferentes sistemas y plataformas, como CRM, ERP, herramientas de marketing, para garantizar una sincronización eficiente y el intercambio de información relevante en tiempo real.",
                            webhooksConfig: "Configuración de webhooks",
                            descriptionWebhooksConfiguration: "Implementación - Configuración de Webhooks: Aquí se realiza la configuración de webhooks en el comercio electrónico. Los webhooks son URLs que permiten que las aplicaciones externas reciban notificaciones sobre eventos o acciones específicas que ocurren en el sitio o plataforma. Esto implica definir los eventos a monitorear, configurar las URLs de destino y la integración con los sistemas o herramientas necesarias para automatizar procesos y mejorar la eficiencia operativa.",
                            blogPostContentShort: "Contenido de entradas de blog (corto)",
                            upTo700Words: "hasta 700 palabras",
                            descriptionBlogPostContentShort: "Marketing Digital - Contenido de entradas de blog: En esta actividad, se crea contenido corto para las publicaciones del blog del comercio electrónico. El contenido del blog puede abordar temas relacionados con productos, consejos, tendencias, noticias del sector, entre otros. La creación de contenido relevante e informativo para el blog ayuda a atraer tráfico orgánico, fortalecer la autoridad de la marca y proporcionar valor a los clientes.",
                            blogPostContentLong: "Contenido de entradas de blog (largo)",
                            upTo1500Words: "hasta 1,500 palabras",
                            descriptionBlogPostContentLong: "Marketing Digital - Contenido de entradas de blog: En esta actividad, se crea contenido largo para las publicaciones del blog del comercio electrónico. El contenido del blog puede abordar temas relacionados con productos, consejos, tendencias, noticias del sector, entre otros. La creación de contenido relevante e informativo para el blog ayuda a atraer tráfico orgánico, fortalecer la autoridad de la marca y proporcionar valor a los clientes.",
                            landingPageCreation: "Creación de Página de Aterrizaje (herramienta de automatización de marketing)",
                            descriptionLandingPageCreation: "Marketing Digital - Creación de Página de Aterrizaje (herramienta de automatización de marketing): En esta actividad, se realiza la creación de páginas de aterrizaje utilizando una herramienta de automatización de marketing. Esto incluye el diseño conceptual, la selección y personalización de plantillas predefinidas, la configuración de formularios de captura de leads y la implementación de elementos interactivos como pop-ups o chatbots para aumentar la tasa de conversión y dirigir al público hacia una acción específica.",
                            searchAdsAdWriting: "Redacción de Anuncios de Search Ads",
                            perCampaign: "por campaña",
                            descriptionSearchAdsCopywriting: "Redacción Publicitaria - Redacción para Anuncios de Search Ads: En esta actividad, se crean textos publicitarios para anuncios de Search Ads, que se muestran en los resultados de búsqueda de los motores de búsqueda. Los textos están optimizados para llamar la atención del usuario, transmitir la propuesta de valor del producto o servicio e incentivar a hacer clic en el anuncio.",
                            adsCallWriting: "Redacción de Llamadas para Anuncios",
                            descriptionAdsCopywriting: "Redacción Publicitaria - Redacción de Llamadas para Anuncios: Aquí se desarrollan textos publicitarios para anuncios que se muestran en sitios web asociados a través de banners, imágenes o vídeos. Los textos se elaboran para atraer la atención del público objetivo, generar interés e incentivar la interacción con el anuncio.",
                            ecommerceBannerCallWriting: "Redacción de Llamadas para Banners de Comercio Electrónico",
                            descriptionEcommerceBannerCopywriting: "Redacción Publicitaria - Redacción de Banners de Comercio Electrónico: En esta actividad, se crean textos publicitarios para banners utilizados en el comercio electrónico. Los textos se elaboran para llamar la atención de los usuarios, transmitir mensajes persuasivos e incentivar la interacción con los productos, promociones o llamadas a la acción presentes en los banners.",
                            emailWritingLong: "Redacción de Correos Electrónicos (formato largo)",
                            perEmailOriginal: "por correo electrónico",
                            descriptionEmailCopywriting: "Redacción Publicitaria - Redacción de Correos Electrónicos: En esta actividad, se desarrollan textos persuasivos para correos electrónicos de marketing, que incluyen correos electrónicos de campañas promocionales, boletines informativos y correos electrónicos de relación con los clientes. Los textos se elaboran para atraer la atención, generar participación e incentivar la acción de los destinatarios.",
                            emailCallWriting: "Redacción de Llamadas para Correos Electrónicos",
                            descriptionCallEmailCopywriting: "Redacción Publicitaria - Redacción de Correos Electrónicos: En esta actividad, se desarrollan llamadas persuasivas y CTA (llamadas a la acción) para correos electrónicos de marketing, que incluyen correos electrónicos de campañas promocionales, boletines informativos, correos electrónicos transaccionales y correos electrónicos de relación con los clientes. Los textos se elaboran para atraer la atención, generar participación e incentivar la acción de los destinatarios.",
                            customPaidMediaReport: "Informe Personalizado de Medios Pagados",
                            descriptionGoogleAdsCustomReport: "Informes - Informe Personalizado de Medios Pagados (Google Ads): En esta actividad, se elaboran informes personalizados que proporcionan análisis detallados de los resultados de las campañas de medios pagados realizadas a través de Google Ads. Los informes pueden incluir métricas como clics, impresiones, tasa de conversión, retorno de la inversión (ROI) y otra información relevante para evaluar el rendimiento de las campañas y dirigir ajustes estratégicos.",
                            testingPlatformTag: "Etiqueta de la Plataforma de Pruebas y Experimentos",
                            descriptionGoogleOptimizeSetup: "Configuración - Google Optimize: En esta actividad, se realiza la configuración de Google Optimize, una herramienta de pruebas y personalización de sitios web que permite la creación de experimentos para optimizar la experiencia del usuario. Esto incluye la creación de la cuenta de Optimize, la configuración de los experimentos, la definición de variantes y objetivos, y la realización de pruebas A/B y pruebas multivariadas para evaluar y mejorar la eficacia del sitio o plataforma.",
                            usabilityAnalysisPlatformTag: "Etiqueta de la Plataforma de Análisis de Usabilidad",
                            descriptionHotjarSetup: "Configuración - Hotjar Heatmaps & Behavior Analytics: En esta actividad, se realiza la configuración de Hotjar, una herramienta de análisis del comportamiento del usuario que proporciona mapas de calor, grabaciones de sesiones y otros recursos para comprender cómo interactúan los visitantes con el sitio web o plataforma. Esto incluye la creación de la cuenta de Hotjar, la configuración del código de seguimiento, la definición de configuraciones de seguimiento y el análisis de los datos recopilados para obtener información valiosa sobre el comportamiento del usuario y la usabilidad del sitio.",
                            adsPlatformsPixelsTagsEventsConfig: "Configuración de Píxeles, Etiquetas y Eventos de Plataformas de Anuncios",
                            perTag: "por etiqueta",
                            descriptionAdsPlatformsSetup: "Configuración - Configuración de Píxeles, Etiquetas y Eventos de Plataformas de Anuncios: En esta actividad, se realiza la configuración de píxeles de seguimiento, etiquetas y eventos de TikTok Ads, Pinterest Ads, Twitter Ads, Linkedin Ads y otras plataformas en el sitio o plataforma de comercio electrónico. Esto permite el seguimiento y rastreo adecuados de las acciones de los usuarios, como visualizaciones de videos, clics en anuncios y conversiones, con fines de análisis y optimización de campañas publicitarias.",
                            performanceMaxCampaignSetup: "Configuración de Campaña de Rendimiento Máximo",
                            descriptionGoogleMaxPrfmCampaignSetup: "Tráfico Pagado - Configuración de Campaña de Google Max Performance: En esta actividad, se realiza la configuración de campañas de Google Max Performance, una modalidad de campaña automatizada que optimiza ofertas, segmentación y creatividades para obtener los mejores resultados de rendimiento. Esto incluye la configuración de la campaña, la definición de objetivos y restricciones, y el análisis continuo de los resultados para ajustes y optimizaciones.",
                            socialAdsCampaignSetup: "Configuración de Campaña de Anuncios Sociales",
                            descriptionSocialAdsCampaignSetup: "Tráfico Pagado - Configuración de Campaña de Anuncios Sociales: En esta actividad, se realiza la configuración de campañas de anuncios de Display Social Ads, que se muestran en plataformas de redes sociales como Facebook, Instagram, TikTok, Pinterest, Twitch, Twitter o LinkedIn. Esto incluye la configuración de criterios de segmentación, la creación de anuncios visualmente atractivos y persuasivos, y la definición de estrategias de ofertas y presupuestos para llegar al público objetivo del comercio electrónico.",
                            paidTrafficMktFunnelDesign: "Diseño de Embudo de Marketing para Campañas de Tráfico Pago",
                            descriptionPaidTrafficMktFunnelDesign: "Tráfico Pago - Diseño de Embudo de Marketing para Campañas de Tráfico Pago: En esta actividad, se lleva a cabo el diseño de un embudo de marketing estratégico para campañas de tráfico pago. Esto implica la definición de las etapas del embudo, desde la concienciación hasta la conversión, la creación de segmentaciones y ofertas específicas para cada etapa, y la configuración de las campañas de anuncios para dirigir el tráfico calificado a lo largo del embudo, con el objetivo de maximizar las conversiones y el retorno de la inversión.",
                            abTestTextImage: "Prueba A/B (texto o imagen)",
                            perExperiment: "por experimento",
                            descriptionAbTestTextImage: "CRO - Prueba A/B (texto o imagen): Se realizan pruebas A/B en las que se presentan aleatoriamente diferentes versiones de texto o imágenes a los visitantes del sitio. Esto permite evaluar cuál versión genera mejores resultados, como tasas de conversión o participación, lo que ayuda en la optimización de las páginas y el aumento de las conversiones.",
                            abTestCodeSnippet: "Prueba A/B (fragmento de código)",
                            descriptionAbTestCodeSnippet: "CRO - Prueba A/B (fragmento de código): En esta actividad, se realizan pruebas A/B utilizando fragmentos de código, en las que se prueban diferentes variantes de código en partes específicas del sitio. Esto permite evaluar cuál versión genera mejores resultados en términos de rendimiento, funcionalidad o experiencia del usuario.",
                            promotionCreation: "Creación de Promociones",
                            descriptionPromotionsCreation: "Gestión de E-commerce - Creación de Promociones: En esta actividad, se crean promociones o descuentos especiales para los productos o servicios de la empresa. Esto puede incluir la definición de las reglas de descuento, el período de validez y la comunicación de la promoción a los clientes.",
                            collectionCreation: "Creación de Colecciones",
                            descriptionCollectionsCreation: "Gestión de E-commerce - Creación de Colecciones: Esta actividad implica la creación de colecciones de productos agrupados según un tema o criterio específico. Las colecciones se pueden utilizar para destacar productos relacionados, promover tendencias o facilitar la navegación de los clientes dentro del catálogo de productos.",
                            navigationManagement: "Gestión de la Navegación (menús y enlaces)",
                            descriptionNavigationManagement: "Gestión de E-commerce - Gestión de la Navegación (menús y enlaces): En esta actividad, se gestionan los menús y enlaces de navegación del sitio o plataforma para garantizar una organización y usabilidad adecuadas. Esto incluye la creación, edición y eliminación de categorías, subcategorías, páginas y enlaces de navegación, con el objetivo de facilitar la experiencia del usuario al encontrar los productos deseados.",
                            freightRulesConfig: "Configuración de reglas de envío",
                            descriptionShippingRulesConfiguration: "Gestión de E-commerce - Configuración de reglas de envío: En esta actividad, se configuran las reglas relacionadas con el cálculo y la visualización de las opciones de envío para los clientes durante el proceso de compra. Esto incluye la definición de rangos de peso, regiones de entrega, valores mínimos de pedido y otros criterios para el cálculo del envío.",
                            scriptDevScriptsEditor: "Desarrollo de script a través del Editor de Scripts",
                            descriptionCheckoutScriptDevelopment: "Gestión de E-commerce - Desarrollo de script de pago (Editor de Scripts): Esta actividad implica el desarrollo de scripts personalizados para el proceso de pago del comercio electrónico. Estos scripts pueden automatizar tareas, agregar funcionalidades específicas o personalizar el flujo de pago, con el objetivo de mejorar la experiencia del usuario y facilitar el proceso de compra.",
                            productRegistration: "Registro de productos",
                            descriptionProductsRegistration: "Inventario - Registro de productos: Esta actividad consiste en registrar los productos de la empresa en un sistema o plataforma específica, ingresando información como nombre, descripción, código, categoría, precio y otros datos relevantes.",
                            productImport: "Importación de productos a través de una hoja de cálculo estandarizada",
                            perSpreadsheet: "por hoja de cálculo",
                            descriptionProductsImport: "Inventario - Importación de productos a través de una hoja de cálculo estandarizada: En esta actividad, los productos se importan al sistema mediante una hoja de cálculo estandarizada, agilizando el proceso de actualización del inventario.",
                            catalogFiltersAdd: "Agregar filtros al catálogo (a través de etiquetas o especificaciones de productos)",
                            perFilter: "por filtro",
                            descriptionCatalogFiltersAddition: "Inventario - Agregar filtros al catálogo (a través de etiquetas): Se agregan filtros al catálogo de productos utilizando etiquetas o metacampos, lo que facilita la navegación y búsqueda de los clientes dentro de la plataforma.",
                            variationsConfig: "Configurar variaciones (a través de opciones o metacampos)",
                            perVariation: "por variación",
                            descriptionVariationsSwatchesConfiguration: "Inventario - Configurar muestras de variaciones: Se realiza la configuración de muestras de variaciones, que son pequeñas muestras de colores, tallas u otras variaciones de un producto, lo que permite a los clientes ver y seleccionar diferentes opciones.",
                            catalogFiltersAddProductSpec: "Agregar filtros al catálogo (a través de especificaciones de productos)",
                            variationsConfigProductSpec: "Configurar variaciones (a través de especificaciones de productos)",
                            variationsConfigOption: "Configurar variaciones (a través de opciones)",
                            catalogFiltersAddTagsOptionMetacamp: "Agregar filtros al catálogo (a través de etiquetas, opción o metacampos)",
                            categoryCRO: "CRO",
                            categoryInterfaceDevelopment: "Desarrollo de Interfaz",
                            categoryGraphicDesign: "Diseño Gráfico",
                            categoryInterfaceDesign: "Diseño de Interfaz",
                            categoryEcommerceManagement: "Gestión de E-commerce",
                            categoryImplementation: "Implementación",
                            categoryInventory: "Inventario",
                            categoryDigitalMarketing: "Marketing Digital",
                            categoryCopywriting: "Redacción Publicitaria",
                            categoryReports: "Informes",
                            categorySEO: "SEO",
                            categoryMarketingSetup: "Configuración de Marketing",
                            categoryPaidTraffic: "Tráfico de Pago",
                            categoryUiUx: "UI y UX",
                            categoryImageEditing: "Edición de Imágenes",
                            categoryVideoEditing: "Edición de Videos",
                            categoryMarketplaceManagement: "Gestión de Marketplace",
                            categoryTranslation: "Traducción",
                            availableActivities: "Actividades Disponibles",
                            search: "Buscar",
                            searchActivity: "Buscar actividades",
                            categories: "Categorías",
                            moreUseds: "Más utilizados",
                            all: "Todos",
                            credits: "Créditos",
                            quantity: "Cantidad",
                            activitiesAdded: "Actividades Agregadas",
                            requestEstimate: "Solicitar estimación",
                            credits2: "Créditos",
                            quantity2: "Cantidad",
                            activityAccordion: {
                                responsible: "Responsable",
                                squad: "Squad",
                                none: "Ninguno",
                                status: "estado",
                                backlog: "Backlog",
                                doneApplied: "Completado/Aplicado",
                                active: "Activo",
                                activityDetails: "Detalles de la actividad",
                            },
                            activityOptions: {
                                code: "Código",
                                writeCouponCode: "Escriba el código del cupón",
                                discount: "Descuento",
                                describeDiscount: "Describa el descuento",
                                theme: "Tema",
                                themeEmail: "Tema del correo electrónico",
                                date: "Fecha",
                                sendDate: "Fecha de envío",
                                metric: "Métrica",
                                metricName: "Nombre de la métrica",
                                to: "hasta",
                                upload: "Cargar",
                            }
                        },
                        analysis: {
                            of: "de",
                            sessions: "Sesiones",
                            conversionRate: "Tasa de Conversión",
                            averageTicket: "Ticket Promedio",
                            revenue: "Ingresos",
                            newCustomersRevenue: "Ingresos de Nuevos Clientes",
                            oldCustomersRevenue: "Ingresos de Clientes Antiguos",
                            recoveredRevenue: "Ingresos Recuperados",
                            customerLifetimeValue: "Valor de Vida del Cliente",
                            nps: "NPS",
                            couponUsage: "Uso de Cupones",
                            analysis: "Análisis",
                            metricsTitle: "Métricas",
                            channels: "Canales",
                            metrics: "Métricas",
                            utm: "UTM",
                            completeUtm: "UTM Completa",
                            copy: "Copiar",
                            prioritization: "Priorización",
                            trust: "Confianza",
                            impact: "Impacto",
                            facility: "Facilidad",
                        },
                        details: {
                            startBroadcast: "Comienzo de la transmisión",
                            endBroadcast: "Fin de la transmisión",
                            futureDate: "Fecha en que la acción debe estar en el aire.",
                            endDate: "Fecha de cierre de la acción o marcar como acción perpetua cuando se trate de una acción permanente.",
                            detailsOf: "Detalles de",
                            name: "Nombre",
                            description: "Descripción",
                            deadlineChecklist: "Plazo para enviar la lista de verificación",
                            week: "Semana",
                            weeks: "Semanas",
                            language: "Idioma",
                            portuguese: "Portugués",
                            english: "Inglés",
                            spanish: "Español",
                            perpetualAction: "Acción perpetua",
                        },
                        revision: {
                            active: "Activo",
                            backlog: "Backlog",
                            inProgress: "En progreso",
                            done: "Completado",
                            toDo: "Por hacer",
                            planning: "Planificación",
                            cancelled: "Cancelado",
                            typeCampaign: "Campaña",
                            typeImplementation: "Implementación",
                            typeImprovement: "Mejora",
                            detailsOf: "Detalles de",
                            revision: "Revisión",
                            details: "Detalles",
                            name: "Nombre:",
                            description: "Descripción:",
                            type: "Tipo:",
                            category: "Categoría:",
                            quadrant: "Cuadrante:",
                            executionTime: "Tiempo de ejecución:",
                            start: "Inicio:",
                            end: "Fin:",
                            perpetual: "Perpetuo",
                            channel: "Canal:",
                            metric: "Métrica:",
                            notSpecified: "No especificado",
                            perpetuates: "Perpetua",
                            weeks: "semanas",
                            activities: "Actividades",
                            creditsQuantity: "Créditos / Cantidad:",
                            resume: "Resumen",
                            credits: "Créditos:",
                            score: "Puntuación:",
                            campaign: "Campaña:",
                            status: "Estado",
                            includeChecklist: "Incluir checklist",
                            jira: "Jira:",
                            jira2: "Jira",
                            integration: "Integración",
                        },
                        type: {
                            whatTypeAction: "¿Cuál es el tipo de acción?",
                            campaign: "Campaña",
                            requestCampaign: "Solicita una campaña de ventas o elige entre varios modelos listos de campañas de transmisión y automatización de marketing digital basados en nuestro sistema de recomendación.",
                            implementation: "Implementación",
                            requestReports: "Solicita la creación de informes, instalación de aplicaciones, integración de sistemas, inserción de etiquetas, activación de eventos, configuración de herramientas y automatización de procesos.",
                            improvement: "Mejora",
                            requestImprovement: "Solicita mejoras en el diseño, optimización de la velocidad o características bajo demanda que requieran la participación de un arquitecto de soluciones y un equipo de desarrollo.",
                        },
                    },
                    utils: {
                        categorySelect: {
                            activation: "Activación",
                            discovery: "Descubrimiento",
                            consideration: "Consideración",
                            prospection: "Prospecto",
                            acquisition: "Adquisición",
                            content: "Contenido",
                            tracking: "Seguimiento",
                            benefit: "Beneficio",
                            engagement: "Participación",
                            nextPurchase: "Próxima Compra",
                            context: "Contexto",
                            recovery: "Recuperación",
                            conversion: "Conversión",
                            purchaseBarriers: "Barreras de Compra",
                            offers: "Ofertas",
                            authority: "Autoridad",
                            retention: "Retención",
                            experienceAnalysis: "Análisis de Experiencia",
                            transmission: "Transmisión",
                            segments: "Segmentos",
                            category: "Categoría"
                        },
                        editCampaignModal: {
                            campaignNotFound: "Campaña no encontrada.",
                            internalError: "Error interno del servidor.",
                            campaignUpdated: "¡Campaña actualizada con éxito!",
                            campaignDeleted: "¡Éxito al eliminar la campaña!",
                            editCampaign: "Editar campaña",
                            apply: "Aplicar",
                            delete: "Eliminar",
                            cancel: "Cancelar",
                            deleteCampaign: "Eliminar campaña",
                            confirmDelete: "¿Estás seguro de que deseas eliminar la campaña?",
                            remove: "Eliminar",
                            cancel2: "Cancelar",
                        },
                        fileSelect: {
                            fileTooBig: "Archivo demasiado grande, prueba con un archivo más pequeño de 20MB o menos."
                        },
                        freeSoloCreateOption: {
                            errorCreate: "Error al crear la campaña.",
                            internalError: "Error interno del servidor.",
                            campaignCreated: "¡Campaña creada con éxito!",
                            campaign: "Campaña",
                        }
                    }
                },
                strategy: {
                    internalError: "Error interno del servidor.",
                    updatedSuccess: "Acciones actualizadas con éxito!",
                    activation: "Activación",
                    acquisition: "Adquisición",
                    engagement: "Compromiso",
                    conversion: "Conversión",
                    retention: "Retención",
                    actionsQuadrant: "Estrategia",
                    updateActions: "Actualizar Acciones",
                    actionCard: {
                        active: "Activo",
                        backlog: "Backlog",
                        inProgress: "En progreso",
                        done: "Completado",
                        toDo: "Por hacer",
                        planning: "Planificación",
                        cancelled: "Cancelado",
                        credits: "créditos",
                    },
                    actionCardArea: {
                        discovery: "Descubrimiento",
                        content: "Contenido",
                        nextPurchase: "Próxima Compra",
                        buyingBarriers: "Barreras de Compra",
                        expAnalysis: "Análisis de Experiencia",
                        consideration: "Consideración",
                        tracking: "Seguimiento",
                        context: "Contexto",
                        offers: "Ofertas",
                        transmission: "Transmisión",
                        prospecting: "Prospecto",
                        benefit: "Beneficio",
                        recovery: "Recuperación",
                        authority: "Autoridad",
                        segments: "Segmentos",
                        newAction: "Nueva Acción",
                        dontCreate: "Todavía no has creado una actividad para este cuadrante",
                        createNow: "Crear ahora",
                    },
                    actionCardAreaMobile: {
                        discovery: "Descubrimiento",
                        content: "Contenido",
                        nextPurchase: "Próxima Compra",
                        buyingBarriers: "Barreras de Compra",
                        expAnalysis: "Análisis de Experiencia",
                        consideration: "Consideración",
                        tracking: "Seguimiento",
                        context: "Contexto",
                        offers: "Ofertas",
                        transmission: "Transmisión",
                        prospecting: "Prospecto",
                        benefit: "Beneficio",
                        recovery: "Recuperación",
                        authority: "Autoridad",
                        segments: "Segmentos",
                        newAction: "Nueva Acción",
                        dontCreate: "Todavía no has creado una actividad para este cuadrante",
                        createNow: "Crear ahora",
                    }
                },
                tableActivity: {
                    table: {
                        description: "Descripción",
                        noneSelected: "Ninguna actividad seleccionada",
                        agencyNotFound: "No se pudo encontrar la agencia!",
                        updateSuccess: "Actividades actualizadas con éxito!",
                        internalError: "Error interno del servidor.",
                        activitiesTable: "Tabla de Actividades",
                        activeTable: "Tabla activa",
                        activateTable: "Activar tabla",
                        addActivities: "Agregar actividades",
                        version: "Versión",
                        categoryLabel: "Categoría",
                        all: "Todas",
                        searchActivity: "Buscar actividad",
                        category: "Categoría",
                        activities: "Actividades",
                        credits: "Créditos",
                        unitMensurement: "Unidad de medida",
                        linesPerPage: "Líneas por página:",
                    },
                    tables: {
                        updateSuccess: "Tabla de actividades actualizada exitosamente",
                        errorUpdate: "Error al actualizar la tabla de actividades",
                        internalError: "Error interno del servidor.",
                        activitiesTable: "Tabla de Actividades",
                        upload: "Subir",
                        version: "Versión",
                        activeTable: "Tabla activa",
                    }
                },
                utils: {
                    filter: {
                        filter: "Filtrar",
                        filterBy: "Filtrar por:",
                        apply: "Aplicar",
                        resetFilters: "Restablecer filtros",

                        campaign: {
                            campaign: "Campaña:",
                            selectCampaign: "Seleccionar campaña",
                        },
                        category: {
                            category: "Categoría:",
                        },
                        end: {
                            end: "Fin:",
                        },
                        name: {
                            name: "Nombre:",
                        },
                        responsible: {
                            responsible: "Responsable:",
                            selectResponsible: "Seleccionar responsable",
                        },
                        score: {
                            score: "Puntuación:",
                        },
                        start: {
                            start: "Inicio:",
                        },
                        status: {
                            status: "Estado:",
                            unmarkAll: "Desmarcar todos",
                            markAll: "Marcar todos",
                            active: "Activo",
                            backlog: "Backlog",
                            inProgress: "En progreso",
                            done: "Completado",
                            toDo: "Por hacer",
                            planning: "Planificación",
                            cancelled: "Cancelado",
                        }
                    },
                    headerButtons: {
                        creditBalanceModal: {
                            refunded: "Reintegrado",
                            debited: "Debitado",
                            transaction: "Transacción",
                            transactions: "Transacciones",
                            freeBalance: "Saldo libre:",
                            balance: "Saldo",
                            cycleBalance: "Saldo del ciclo:",
                            avaiable: "Disponible:",
                            planned: "Planeado",
                            provisioned: "Provisionado",
                            debited: "Debitado",
                            actionCard: {
                                credits: "créditos",
                            },
                            editCreditsModal: {
                                contractNotFound: "No se pudo encontrar el contrato!",
                                registerError: "No se pudo realizar la solicitud!",
                                registerSuccess: "Cobro registrado con éxito!",
                                internalError: "Error interno del servidor.",
                                syncSuccess: "Acciones sincronizadas con éxito!",
                                edit: "Editar",
                                editCredits: "Editar créditos",
                                actuallyCredits: "Créditos actuales:",
                                sync: "Sincronizar",
                                credits: "Créditos",
                                motive: "Motivo",
                                add: "Agregar",
                                remove: "Eliminar",
                                cancel: "Cancelar",
                            }
                        },
                        customSearchInput: {
                            research: "Buscar...",
                        },
                        exportButton: {
                            export: "Exportar",
                        },
                        fileSelectArea: {
                            addFiles: "Agregar archivos",
                        },
                        returnButton: {
                            return: "Volver",
                        },
                        showMoreLessButton: {
                            seeLess: "Ver menos",
                            seeMore: "Ver más",
                        },
                        trimesterUpdateModal: {
                            updateTrimester: "Actualizar trimestre de las acciones",
                            transferActions: 'Haga clic en "Continuar" para transferir las acciones al próximo trimestre, o en "Remover" para eliminar las acciones del trimestre actual.',
                            selectActions: "Selecciona las acciones en planificación para también transferirlas al próximo trimestre",
                            remove: "Remover",
                            continue: "Continuar",
                            cancel: "Cancelar",
                        }
                    }
                }
            },
            start: {
                checklist: {
                    checklistProgress: "Progreso del Checklist",
                    generalProgress: "PROGRESO GENERAL",
                    checklistStatus: "Estado del Checklist",
                    create: {
                        invalidData: "Datos inválidos.",
                        customerNotFound: "Cliente no encontrado.",
                        updateError: "Se produjo un error al actualizar el cliente.",
                        updateSuccess: "Cliente actualizado con éxito.",
                        internalError: "Error interno del servidor.",
                        saveSuccess: "¡Checklist creado exitosamente!",
                        checklist: "Checklist",
                        save: "Guardar",
                        subTitle: "subtítulo",
                        title: "Título",
                        helperText: 'Texto de ayuda',
                        placeHolder: 'Marcador de posición',
                        type: 'Tipo',
                        text: 'Texto',
                        textArea: 'Área de texto',
                        count: 'Contador Numérico',
                        money: 'Valor Monetario',
                        uniqueList: 'Lista Desplegable Única',
                        multipleList: 'Lista Desplegable Múltiple',
                        url: 'URL',
                        date: 'Fecha',
                        email: 'Correo Electrónico',
                        radio: 'Radio',
                        checkbox: 'Casilla de verificación',
                        attachment: 'Adjunto',
                        options: 'Opciones',
                        mandatory: 'Obligatorio',
                        selectItem: '¡Seleccione un elemento a continuación!',
                    },
                    edit: {
                        errorIcons: "Error al buscar iconos:",
                        registerError: "¡El Checklist no se registró correctamente!",
                        registerSuccess: "¡Checklist registrado correctamente!",
                        internalError: "Error interno del servidor.",
                        updateError: "Se produjo un error al actualizar el Checklist.",
                        updateSuccess: "Tarea actualizada con éxito!",
                        add: "Añadir",
                        subTitle: "subtítulo",
                        title: "Título",
                        helperText: 'Texto de ayuda',
                        placeHolder: 'Marcador de posición',
                        type: 'Tipo',
                        text: 'Texto',
                        textArea: 'Área de texto',
                        count: 'Contador Numérico',
                        money: 'Valor Monetario',
                        uniqueList: 'Lista Desplegable Única',
                        multipleList: 'Lista Desplegable Múltiple',
                        url: 'URL',
                        date: 'Fecha',
                        email: 'Correo Electrónico',
                        radio: 'Radio',
                        checkbox: 'Casilla de verificación',
                        attachment: 'Adjunto',
                        condition: 'Condición',
                        noCondition: 'Sin condición',
                        valueCondition: 'Valor de Condición',
                        mandatory: 'Obligatorio',
                        options: 'Opciones',
                        addOption: 'Añadir opción',
                        addField: 'Añadir campo',
                        add2: 'Añadir',
                        save: 'Guardar',
                        cancel: 'Cancelar',
                        delete: 'Eliminar',
                        selectItem: '¡Seleccione un elemento a continuación!',
                        searchIcon: 'Buscar icono'
                    },
                    inicial: {
                        fieldRequired: "Este campo es obligatorio.",
                        fillFields: "Complete todos los campos obligatorios.",
                        customerNotFound: "Cliente no encontrado!",
                        updateError: "Se produjo un error al actualizar el cliente.",
                        updateSuccess: "Cliente actualizado con éxito!",
                        internalError: "Error interno del servidor.",
                        archiveHere: "Suelta los archivos aquí",
                        dragOrClick: "Arrastra y suelta tus archivos aquí o haz clic para seleccionar archivos",
                        checklist: "Checklist",
                        save: "Guardar",
                        selectItem: "¡Seleccione un elemento a continuación!"
                    },
                    view: {
                        customerNotFound: "Cliente no encontrado!",
                        updateError: "Se produjo un error al actualizar el cliente.",
                        updateSuccess: "Cliente actualizado con éxito!",
                        internalError: "Error interno del servidor.",
                        checklist: "Checklist",
                        save: "Guardar",
                        selectItem: "¡Seleccione un elemento a continuación!"
                    },
                    viewAll: {
                        checklists: "Checklists",
                        edit: "Editar",
                        search: "Buscar",
                        store: "Tienda",
                        all: "Todos",
                        finisheds: "Finalizados",
                        inAnalysis: "En análisis",
                        waiting: "Esperando",
                        error: "Error",
                        withoutChecklist: "Sin checklist",
                        name: "Nombre",
                        waiting2: "Esperando",
                        inAnalysis2: "En análisis",
                        error2: "Error",
                        finisheds2: "Finalizados",
                        total: "Total",
                        viewChecklist: "Ver Checklist",
                        editChecklist: "Editar Checklist",
                    }
                },
                createChecklist: {
                    addField: "Añadir campo",
                    label: "Etiqueta",
                    typeField: "Tipo de campo",
                },
                form: {
                    question: "Pregunta",
                    type: "Tipo:",
                    condition: "Condición",
                    text: "Texto",
                    updateSuccess: "Formulario actualizado con éxito!",
                    internalError: "Error interno del servidor.",
                    successUpdate: "Formulario actualizado con éxito!",
                    refresh: "Actualizar",
                    configForm: {
                        name: "Nombre",
                        descriptionHere: "Escribe la descripción aquí",
                        typeField: "Tipo de campo",
                        uniqueList: "Lista Única de Selección",
                        multipleList: "Lista Múltiple de Selección",
                        uniqueOption: "Opción Única",
                        multipleOption: "Opción Múltiple",
                        count: "Contador",
                        options: "Opciones",
                        option: "Opción",
                        descriptionOption: "Descripción de la opción",
                        delete: "Eliminar",
                        addOption: "Añadir Opción",
                        cancel: "Cancelar",
                        save: "Guardar",
                    }
                },
                project: {
                    projectNotExist: "El proyecto no existe!",
                    deleteError: "Error al eliminar!",
                    deleteSuccess: "Proyecto eliminado exitosamente!",
                    internalError: "Error interno del servidor.",
                    projects: "Proyectos",
                    store: "Tienda",
                    all: "Todos",
                    status: "Estado",
                    all2: "Todos",
                    incomplete: "Incompleto",
                    development: "Desarrollo",
                    waitingBudget: "Esperando Presupuesto",
                    waitingDevelopment: "Esperando Desarrollo",
                    waitingCustomer: "Esperando Cliente",
                    done: "Completado",
                    createIn: "Crear en",
                    updatedIn: "Actualizado en",
                    details: "DETALLES",
                    remove: "Eliminar",
                    confirmRemove: "¿Estás seguro de que deseas eliminar?",
                    cancel: "Cancelar",
                }, // A PARTIR DAQUI ESTÁ SENDO UTILIZADO CASTELHANO
                task: {
                    updateError: "Se produjo un error al actualizar la tarea.",
                    updateSuccess: "Tarea actualizada con éxito.",
                    internalError: "Error interno del servidor.",
                    tasks: "Tareas",
                    taskName: "Nombre de la tarea",
                    add: "Agregar",
                },
                viewProject: {
                    selectBreakDown: "Seleccione el desglose.",
                    projectNotFound: "Proyecto no encontrado!",
                    updateSuccess: "Proyecto actualizado exitosamente!",
                    internalError: "Error interno del servidor.",
                    updateError: "Se produjo un error al actualizar el proyecto.",
                    updateSuccess2: "Proyecto actualizado exitosamente.",
                    proposalSent: "Propuesta enviada exitosamente.",
                    tasksSet: "Tareas definidas exitosamente",
                    list: "Listar",
                    storeInformation: "Información de la tienda",
                    name: "Nombre",
                    email: "Correo electrónico",
                    phone: "Teléfono",
                    proposals: "Propuestas",
                    date: "Fecha",
                    apresentation: "Presentación",
                    detailing: "Detallado",
                    apresentation2: "Presentación",
                    detailing2: "Detallado",
                    noProposals: "Sin propuestas en este momento.",
                    uploadDetailing: "SUBIR Detallado",
                    uploadApresentation: "SUBIR Presentación",
                    noFileSelect: "Ningún archivo seleccionado.",
                    noFileSelect2: "Ningún archivo seleccionado.",
                    send: "Enviar",
                }
            },
            support: {
                hourTracking: {
                    credits: "créditos",
                    timeTracking: "Seguimiento de Horas",
                    filter: "Filtrar",
                    contractedHours: "Horas Contratadas:",
                    usedHours: "Horas Utilizadas:",
                    remainingHours: "Horas Restantes:",
                    hoursTable: {
                        taskCode: "Código de Tarea",
                        quantityHours: "Cantidad de Horas",
                        title: "Título",
                        timeRecord: "Registro de Horas",
                        date: "Fecha",
                        comment: "Comentario",
                        hours: "Horas",
                        deleteAction: "Borrar Acción",
                        confirmDelete: "¿Está seguro de que desea borrar la acción?",
                        delete: "Borrar",
                        cancel: "Cancelar",
                    }
                },
                performance: {
                    performance: "Rendimiento",
                    strategy: "Estrategia",
                    desktop: "Escritorio",
                    mobile: "Móvil",
                    details: "Detalles",
                    graphics: "Gráficos",
                    statusLabel: "Estado",
                    graphs: {
                        firstContent: "Primera Pintura de Contenido",
                        firstDelay: "Primer Retraso de Interacción",
                        greaterContent: "Mayor Pintura de Contenido",
                        changeLayout: "Cambio de Diseño Cumulativo",
                        interactivityRendering: "Interactividad Hasta la Próxima Renderización",
                        firstByte: "Tiempo hasta el Primer Byte",
                        noneDataFound: "No se encontraron datos",
                        textAvise: "*Algunas escalas se han ajustado para generar una visualización más amigable de la variación de las Core Web Vitals",
                        chargingExperience: "Experiencia de Carga",
                        noDataFound: "No se encontraron datos...",
                        notEnoughData: "No hay suficientes datos para generar el gráfico.",
                    },
                    status: {
                        statusNotAvailable: "Estado no disponible...",
                    },
                    webVitals: {
                        fix: "Corregir",
                        slow: "Lento",
                        average: "Promedio",
                        fast: "Rápido",
                        notRated: "No calificado",
                        valuesCanVariate: "Los valores son estimados y pueden variar.",
                        acessibility: "Estas comprobaciones resaltan oportunidades para mejorar la accesibilidad de su aplicación web. Solo se pueden detectar automáticamente un subconjunto de problemas de accesibilidad, por lo que se recomienda realizar pruebas manuales.",
                        bestPractices: "Estas comprobaciones resaltan las mejores prácticas recomendadas para el desarrollo de aplicaciones web. Ignorar estas mejores prácticas puede generar problemas de rendimiento, accesibilidad y confiabilidad.",
                        seo: "Estas comprobaciones garantizan que su página siga los consejos básicos de optimización para motores de búsqueda. Hay muchos factores adicionales que Lighthouse no puntúa aquí que pueden afectar su clasificación en la búsqueda, incluido el rendimiento en Core Web Vitals. Conozca más sobre los fundamentos de la búsqueda de Google.",
                        searchInfos: "Buscando información. Esto puede llevar un tiempo...",
                        usersExperiencing: "Descubra lo que están experimentando sus usuarios",
                        assessmentPoints: "Evaluación de los Principales Puntos Vitales de la Web:",
                        firstContent: "Primera Pintura de Contenido (FCP):",
                        firstDelay: "Primer Retraso de Interacción (FID):",
                        greaterContent: "Mayor Pintura de Contenido (LCP):",
                        changeLayout: "Cambio de Diseño Cumulativo (CLS):",
                        interactivityRendering: "Interactividad Hasta la Próxima Renderización (INP):",
                        firstByte: "Tiempo hasta el Primer Byte (TTFB):",
                        generalObservations: "Observaciones Generales",
                        diagnosisOf: "Diagnóstico de",
                    }
                },
                problemsIncidents: {
                    problemsIncidents: "Problemas e Incidentes",
                    filter: "Filtrar",
                    research: "Investigar",
                    status: "Estado",
                    all: "Todos",
                    backlog: "Backlog",
                    toDo: "Por hacer",
                    inProgress: "En progreso",
                    qa: "Q/A",
                    clientApproval: "Aprobación del cliente",
                    done: "Completado",
                    modalForm: {
                        invalidData: "Datos inválidos!",
                        problemNotFound: "Problema no encontrado!",
                        updateError: "Error al editar el problema!",
                        internalError: "Error interno del servidor.",
                        sendError: "Error al enviar archivos!",
                        updateFileError: "Error al actualizar archivos!",
                        sendSuccess: "Archivos enviados exitosamente!",
                        updateSuccess: "Problema editado exitosamente!",
                        deleteError: "Error al eliminar archivos!",
                        deleteSuccess: "Archivo eliminado exitosamente!",
                        errorProblem: "Error al registrar el problema",
                        fieldRequired: "Campo obligatorio",
                        fillFields: "Complete todos los campos obligatorios.",
                        undefinedRootCause: "Causa raíz no identificada",
                        installApp: "Instalación de la aplicación",
                        themeUpdate: "Actualización del tema",
                        systemIntegration: "Integración del sistema",
                        externalSupplier: "Proveedor externo",
                        platformInstability: "Inestabilidad de la plataforma",
                        initialPage: "Página de inicio",
                        productPage: "Página de producto",
                        collectionPage: "Página de colección",
                        cart: "Carrito",
                        loginRegister: "Inicio de sesión o registro",
                        form: "Formulario",
                        promotion: "Promoción",
                        appFunctionality: "Funcionalidad de la aplicación",
                        erpIntegration: "Integración de ERP",
                        shippingCalculate: "Cálculo de envío",
                        analyticsError: "Error de análisis",
                        marketingTool: "Herramienta de marketing",
                        fbCatalogIntegration: "Integración de catálogo de Facebook",
                        googleCatalogIntegration: "Integración de catálogo de Google",
                        desktopWindows: "Escritorio Windows",
                        desktopMac: "Escritorio Mac",
                        desktopLinux: "Escritorio Linux",
                        tabletAndroid: "Tableta Android",
                        tabletIos: "Tableta iOS",
                        mobileAndroid: "Móvil Android",
                        mobileIos: "Móvil iOS",
                        yes: "Sí",
                        no: "No",
                        emergency: "Emergencia",
                        critical: "Crítico",
                        high: "Alto",
                        normal: "Normal",
                        low: "Bajo",
                        noPriority: "Sin prioridad",
                        other: "Otro...",
                        rootCause: "Causa raíz",
                        problemLocation: "Ubicación del problema",
                        dispositive: "Dispositivo",
                        navigator: "Navegador",
                        reproducedError: "¿Ha reproducido el error?",
                        priority: "Prioridad",
                        actionAndSystem: "¿Cuál fue la acción o cuál es el sistema que causó el problema?",
                        whichEquipment: "¿En qué equipo se identificó el problema?",
                        whichNavigator: "¿En qué navegador se identificó el problema?",
                        emergency2: "Emergencia",
                        critical2: "Crítico",
                        high2: "Alto",
                        normal2: "Normal",
                        low2: "Bajo",
                        noPriority2: "Sin prioridad",
                        emergencyDescription: "Las ventas se ven afectadas con la solución de este ítem",
                        criticalDescription: "Las ventas pueden verse afectadas con la solución de este ítem",
                        highDescription: "Las ventas podrían verse ligeramente restringidas hasta la solución de este ítem",
                        normalDescription: "Las ventas podrían mejorar con la resolución de este ítem",
                        lowDescription: "Las ventas no se verán afectadas con la solución de este ítem",
                        noneDescription: "La criticidad de este ítem no se puede definir",
                        problemsIncidents: "Problemas e Incidentes",
                        problemDetails: "Detalles del Problema",
                        title: "Título",
                        description: "Descripción",
                        helpToolAndClient: "Describa qué herramienta y cliente encontraron el problema. Ejemplo: Checkout de Arezzo se bloquea.",
                        expectedResult: "Resultado esperado",
                        helpActionAndSystem: "¿Cuál fue la acción o cuál es el sistema que causó el problema? Especifique con el mínimo de detalles el resultado esperado, de lo contrario, la solicitud será rechazada. No se aceptará 'Resolver el problema'.",
                        priorities: "Prioridades",
                        cancel: "Cancelar",
                        save: "Guardar",
                        new: "NUEVO",
                        fileSelect: {
                            largeFile: "Archivo demasiado grande, intente con un archivo más pequeño de menos de 20MB",
                        },
                        modalSummary: {
                            resume: "Resumen",
                            rootCause: "Causa raíz:",
                            dispositive: "Dispositivo:",
                            reproducedError: "¿Ha reproducido el error?",
                            problemLocation: "Ubicación del Problema:",
                            navigator: "Navegador:",
                            store: "Tienda:",
                            priority: "Prioridad:",
                            cancel: "Cancelar",
                            end: "Finalizar",
                        }
                    },
                    table: {
                        deleteSuccess: "Problema eliminado exitosamente!",
                        problemNotFound: "Problema no encontrado!",
                        deleteError: "Error al eliminar el problema!",
                        internalError: "Error interno del servidor.",
                        taskCode: "Código de Tarea",
                        title: "Título",
                        date: "Fecha",
                        status: "Estado",
                        active: "Activo",
                        backlog: "Backlog",
                        inProgress: "En progreso",
                        done: "Completado",
                        toDo: "Por hacer",
                        planning: "Planificación",
                        canceled: "Cancelado",
                        customerApproval: "Aprobación del cliente",
                        code: "Código",
                        deleteProblem: "Eliminar Problema",
                        confirmDelete: "¿Estás seguro de que deseas eliminar el problema?",
                        delete: "Eliminar",
                        cancel: "Cancelar",
                        problemOptions: {
                            history: "Historia",
                            resume: "Resumen",
                            edit: "Editar",
                            jira: "Jira",
                            remove: "Eliminar",
                        }
                    }
                }
            },
            unauthorized: {
                unauthorized: "No tienes permiso para acceder a esta página.",
            },
            updatePassRecovery: {
                informPassword: "Ingresa la contraseña",
                minChars: "¡La contraseña debe tener al menos 6 caracteres!",
                invalidKey: "Clave no válida, solicita un nuevo enlace para actualizar la contraseña!",
                updatePassword: "Actualizar contraseña",
                password: "Contraseña",
                loading: "Cargando...",
                newPassword: "Ingresa tu nueva contraseña",
                clickHere: "Haz clic aquí",
                toAccess: " para acceder"
            },
            updatePerfil: {
                informName: "Ingresa el nombre!",
                errorUpdate: "Ocorrió un error al actualizar al usuario.",
                successUpdate: "Perfil actualizado con éxito!",
                editPerfil: "Editar Perfil",
                perfil: "Perfil",
                chatStyle: "Estilo de chat",
                new: "Nuevo",
                legacy: "Legado",
                save: "Guardar",
                name: "Nombre",
                completName: "Ingresa el nombre completo",
                startWorkingHours: "Inicio del expediente",
                endWorkingHours: "Fin del expediente",
            },
            updatePerfilPass: {
                informPassword: "Ingresa la contraseña!",
                minChars: "¡La contraseña debe tener al menos 6 caracteres!",
                editPassword: "Editar Contraseña",
                perfil: "Perfil",
                newPassword: "Nueva contraseña",
                enterNewPassword: "Ingresa la nueva contraseña!",
            },
            updatePerfilPhoto: {
                photoRequired: "¡Debes seleccionar una imagen!",
                editPhoto: "Editar foto",
                perfil: "Perfil",
                photo: "Foto",
                perfilPhoto: "Foto de Perfil",
                update: "Actualizar",
            },
            updateUserPass: {
                informPassword: "Ingresa la contraseña!",
                minChars: "¡La contraseña debe tener al menos 6 caracteres!",
                editPassword: "Editar Contraseña",
                loading: "Cargando...",
                list: "Listar",
                view: "Ver",
                actions: "Acciones",
                list2: "Listar",
                view2: "Ver",
                newPassword: "Nueva contraseña",
                enterNewPassword: "Ingresa la nueva contraseña!",
            },
            user: {
                userNotExist: "Usuario no existe!",
                deleteError: "Error al eliminar!",
                internalError: "Error interno del servidor.",
                deleteSuccess: "Usuario eliminado exitosamente!",
                admin: "Administrador",
                customer: "Cliente",
                agency: "Agencia",
                users: "Usuarios",
                register: "Registrar",
                search: "Buscar",
                function: "Función",
                all: "Todos",
                id: "ID",
                name: "Nombre",
                email: "Correo electrónico",
                function2: "Función",
                edit: "Editar",
                delete: "Eliminar",
                permissions: "Permisos",
                deleteUser: "Eliminar Usuario",
                confirmDelete: "¿Estás seguro de que deseas eliminar al usuario?",
                cancel: "Cancelar",
                specific: {
                    userNotExist: "Usuario no existe!",
                    deleteError: "Error al eliminar!",
                    internalError: "Error interno del servidor.",
                    deleteSuccess: "Usuario eliminado exitosamente!",
                    users: "Usuarios",
                    register: "Registrar",
                    id: "ID",
                    name: "Nombre",
                    email: "Correo electrónico",
                    edit: "Editar",
                    delete: "Eliminar",
                    permissions: "Permisos",
                    deleteUser: "Eliminar Usuario",
                    confirmDelete: "¿Estás seguro de que deseas eliminar al usuario?",
                    cancel: "Cancelar"
                }
            },
            viewUser: {
                deleteSuccess: "Usuario eliminado exitosamente!",
                viewUser: "Ver Usuario",
                list: "Listar",
                edit: "Editar",
                editPassword: "Editar Contraseña",
                actions: "Acciones",
                list2: "Listar",
                edit2: "Editar",
                editPassword2: "Editar Contraseña",
                delete: "Eliminar",
                detailsUser: {
                    id: "ID",
                    name: "Nombre",
                    email: "Correo electrónico",
                    function: "Función",
                    dateRegister: "Fecha de Registro",
                    editIn: "Editado en"
                }
            },
            components: {
                uploadAvatar: {
                    upload: "Subir foto",
                },
                actionForm: {
                    quickActivity: "Actividad Rápida",
                    activityDetails: "Detalles de la Actividad",
                    quickImprovement: "Mejora Rápida",
                    improvementDetails: "Detalles de la Mejora",
                    type: "Tipo",
                    implementation: "Implantación",
                    improvement: "Mejora",
                    save: "Guardar",
                },
                chat: {
                    chatHeader: {
                        notificationsEnabled: "Notificaciones habilitadas",
                        notificationsDisabled: "Notificaciones deshabilitadas",
                        openAnotherTab: "Abrir en otra pestaña",
                        unreadThreads: "Hilos no leídos",
                        openAnotherTab: "Abrir en otra pestaña",
                        unreadMessagesError: "Error al recuperar mensajes no leídos.",
                        unreadMessagesSuccess: "Mensajes no leídos recuperados con éxito.",
                        chat: "Conversación",
                        others: "Otros",
                        markAllRead: "Marcar todo como leído",
                    },
                    chatHeaderDrawer: {
                        online: "En línea",
                        busy: "Ocupado",
                        attachments: "Adjuntos",
                        seeAll: "Ver todo",
                        marks: "Mensajes guardados",
                        seeAll2: "Ver todo",
                        attachments2: "Adjuntos",
                        savedMessages2: "Mensajes guardados",
                        messages: "Mensajes",
                        search: "Buscar",
                        searchMessages: "Buscar mensajes",
                    },
                    chatInput: {
                        send: "Enviar",
                        placeholder: "Escribe un mensaje...",
                        addActions: "Agregar acciones",
                        createImprovement: "Crear mejora",
                        createActivity: "Crear actividad",
                        reportProblem: "Reportar problema",
                        attachments: "Adjuntos",
                    },
                    chatConversation: {
                        save: "Guardar",
                        cancel: "Cancelar",
                        reply: "respuesta",
                        replies: "respuestas",
                        thread: "Tema",
                        edited: "Editado",
                        "messageDeleted": "Mensaje eliminado",
                        "deleteMessage": "Eliminar mensaje",
                        "confirmDelete": "¿Estás seguro de que deseas eliminar el mensaje?",
                        "erase": "Borrar",
                        messageDeletedSuccess: "Mensaje eliminado con éxito!",
                        messageDeletedError: "Error al eliminar el mensaje!",
                    },
                    chatSideBar: {
                        channels: "Canales",
                    },
                    chatSideBarAdm: {
                        operation: "Operación",
                        performance: "Rendimiento",
                        implantation: "Implantación",
                        support: "Soporte",
                        administrative: "Administrativo",
                        research: "Investigación",
                        settings: "Configuraciones",
                    }
                },
                chipProject: {
                    quiz: "Cuestionario",
                    waitingDevelopment: "Esperando Desarrollo",
                    waitingBudget: "Esperando Presupuesto",
                    development: "En desarrollo",
                    waitingCustomer: "Propuesta enviada",
                    done: "Completado",
                    unknown: "Desconocido",
                },
                date: {
                    start: "Inicio",
                    end: "Fin"
                },
                header: {
                    perfil: "Perfil",
                },
                kanbanBoard: {
                    toDo: "Por hacer",
                    inProgress: "En progreso",
                    done: "Completado",
                    deliveryForecast: "Pronóstico de entrega",
                },
                platinumlog: {
                    filterBar: {
                        awaitingWMS: "Esperando WMS",
                        awaitingInvoice: "Esperando Factura",
                        awaitingPicking: "Esperando Picking",
                        awaitingCheckout: "Esperando Checkout",
                        readyForCollection: "Listo para Recoger",
                        dispatched: "Despachado",
                        inTransit: "En tránsito",
                        outForDelivery: "En reparto",
                        delivered: "Entregado",
                        canceled: "Cancelado",
                        lateInIntegration: "Atrasado en Integración",
                        lateInDelivery: "Atrasado en Entrega",
                        deliveryToday: "Entrega hoy",
                        withOccurrence: "Con Ocurrencia",
                        search: "Buscar",
                        status: "Estado",
                        status2: "Estado",
                    },
                    internalEvents: {
                        ordersReceived: "Pedidos Recibidos",
                        created: "Creado",
                        awaitingWMS: "Esperando WMS",
                        awaitingPicking: "Esperando Picking",
                        integratedWMS: "WMS Integrado",
                        awaitingNote: "Esperando Nota",
                        digitalPickingDone: "Picking Digital Realizado",
                        canceled: "Cancelado",
                        physicalPickingDone: "Picking Físico Realizado",
                        noteReceived: "Nota Recibida",
                        picking: "Picking",
                        receivedTrackerViaCustomer: "Rastreador Recibido (a través del Cliente)",
                        awaitingCheckout: "Esperando Checkout",
                        checkout: "Checkout",
                        readyForCollection: "Listo para Recoger",
                        dispatched: "Despachado",
                        pickedUpByCarrier: "Recogido por la Transportista",
                        inTransit: "En Tránsito",
                        outForDelivery: "En Reparto",
                        occurrenceWithOrder: "Hubo Algún Incidente con el Pedido",
                        delivered: "Entregado",
                        deliveryFailure: "Fallo en la Entrega",
                        orderRejected: "Pedido Rechazado",
                        orderCanceled: "Pedido Cancelado",
                        orderReturnedToOrigin: "Pedido Devuelto al Origen",
                        orderLost: "Pedido Extraviado",
                        cargoTheft: "Robo de Carga",
                        redelivery: "Reenvío",
                        carrierRecords: "Registros de la Transportista",
                        incorrectAddress: "Dirección Incorrecta",
                        recipientAbsent: "Destinatario Ausente",
                        objectAwaitingWithdrawal: "Objeto Esperando Retiro",
                        unclaimedObject: "Objeto No Reclamado",
                        integrationDelay: "Retraso en la Integración",
                        deliveryDelay: "Retraso en la Entrega",
                        unknowStatus: "Estado Desconocido",
                    },
                    mediaTime: {
                        invoicing: "Facturación",
                        timeCreateToSendNf: "Tiempo desde la creación del pedido en el comercio electrónico hasta el envío de la factura.",
                        expedition: "Expedición",
                        timeSendToDispatching: "Tiempo desde el envío de la factura hasta la expedición del pedido.",
                        delivery: "Entrega",
                        timeCollectToDelivered: "Tiempo desde el envío de la factura hasta el final del proceso de pago.",
                        total: "Total",
                        totalAverage: "Media total desde la creación del pedido en el comercio electrónico hasta la entrega al cliente.",
                    },
                    products: {
                        sku: "SKU",
                        merchandise: "MERCANCÍA",
                        amount: "CANTIDAD"
                    },
                    shippingEvents: {
                        description: "DESCRIPCIÓN",
                        start: "INICIO",
                        end: "FIN",
                    },
                    statusErrorView: {
                        cancelled: "CANCELADO",
                        withOccurrence: "CON OCURRENCIA",
                        delayInIntegration: "ATRASO EN LA INTEGRACIÓN",
                        deliveryDelay: "ATRASO EN LA ENTREGA"
                    },
                    tableOrders: {
                        order: "Pedido",
                        date: "Fecha",
                        prediction: "Predicción",
                        customer: 'Cliente',
                        shipping: 'Transportista',
                        tracking: 'Rastreo',
                        invoice: 'Factura',
                        status: "Estado",
                        wmsIntegration: "Integración WMS:",
                        timeIntegration: "Tiempo de integración:",
                        displaying: "Mostrando",
                        of: "de",
                        results: "resultados"
                    }
                },
                sideBar: {
                    store: "Tienda",
                    dashboard: "Tablero",
                    messages: "Mensajes",

                    admin: {
                        settings: "Configuraciones",
                        usersAdmin: "Usuarios",
                        stores: "Tiendas",
                        agencies: "Agencias",
                        agency: "Agencia",
                        contracts: "Contratos",
                        tableActivitiesAdmin: "Tabla de Actividades",
                        costs: "Costos",
                        usersCustomer: "Usuarios",
                        tableActivitiesCustomer: "Tabla de Actividades",
                        usersAgency: "Usuarios",
                        tableActivitiesAgency: "Tabla de Actividades",
                        perfil: "Perfil",
                        logout: "Cerrar sesión",
                        projects: "Proyectos",
                    },
                    aiContent: {
                        creation: "Creación",
                        redaction: "Redacción",
                        images: "Imágenes",
                        library: "Biblioteca",
                    },
                    apps: {
                        apps: "Aplicaciones",
                        extendedCheckout: "Checkout Extendido",
                        logistic: "Logística",
                        smartShipping: "Envío Inteligente",
                        service: "Servicio",
                        alerts: "Alertas",
                    },
                    scale: {
                        operation: "Operación",
                        logistic: "Logística",
                        smartShipping: "Envío Inteligente",
                        invetory: "Inventario",
                        products: "Productos",
                        orders: "Pedidos"
                    },
                    sprint: {
                        strategy: "Estrategia",
                        actionsList: "Lista de Acciones",
                        actionPlan: "Plan de Acción",
                        kanban: "Tablero Kanban",
                        checklists: "Listas de Verificación",
                        performance: "Rendimiento",
                        newAction: "Nueva Acción",
                        results: "Resultados",
                    },
                    start: {
                        implantation: "Implantación",
                        form: "Formulario",
                        projects: "Proyectos",
                        checklists: "Listas de Verificación",
                        checklistCustomer: "Lista de Verificación",
                        checklistAgency: "Lista de Verificación",
                        roadmap: {
                            title: "Roadmap",
                            isBlockedBy: "Bloqueado por",
                            blocks: "Bloqueos",
                        }
                    },
                    support: {
                        improvements: "Nueva Mejora",
                        improvementsList: "Lista de Mejoras",
                        support: "Soporte y Evolución",
                        hoursControl: "Control de Horas",
                        problems: "Problemas",
                        performance: "Rendimiento",
                        kanban: "Tablero Kanban",
                    }
                },
                spinnerAdd: {
                    processing: "Procesando",
                    register: "Registrar"
                },
                spinnerDelete: {
                    processing: "Procesando...",
                    delete: "Borrar"
                },
                spinnerLogin: {
                    wait: "Espera...",
                    enter: "Ingresar"
                },
                spinnerRecovery: {
                    processing: "Procesando...",
                    recovery: "Recuperar"
                },
                spinnerUp: {
                    processing: "Procesando",
                    update: "Actualizar"
                },
                spinnerUpdateRecovery: {
                    processing: "Procesando...",
                    update: "Actualizar"
                },
                upload: {
                    avatar: {
                        update: "Actualizar",
                        send: "Enviar"
                    },
                    image: {
                        update: "Actualizar",
                        send: "Enviar"
                    }
                }
            },
            config: {
                ecommerceOperation: "Operación de Comercio Electrónico",
                platformImplantation: "Implantación de Plataforma",
                ecommecerManagement: "Gestión de Comercio Electrónico",
                support: "Soporte",
            },
            store: {
                actions: {
                    errorHandling: {
                        internalError: "Error interno del servidor."
                    }
                }
            },
            containers: {
                dashboard: {
                    baseDashboard: {
                        dashboard: "Tablero",
                        actions: "Acciones",
                        notifications: "Notificaciones",
                        chat: "Chat",
                    }
                }
            },
            "insight": {
                "title": "Inventario",
                "startDate": "Fecha de Inicio",
                "endDate": "Fecha de Finalización",
                "selectLocation": "Seleccionar Ubicación",
                "selectTypeProduct": "Seleccionar Tipo de Producto",
                "selectProduct": "Seleccionar Producto",
                "type": "Tipo de Producto",
                "product": "Producto",
                "variant": "Variante",
                "search": "Buscar",
                "daysPredict": "Pronóstico de Agotamiento de Stock",
                "day": "día",
                "days": "días",
                "media": "Promedio",
                "perDay": "por día",
                "curveABC": "Curva ABC",
                "riskStock": "Riesgo de Stock",
                "curve": "Rotación de Stock"
            },
            "apexChart": {
                "jan": "Ene",
                "feb": "Feb",
                "mar": "Mar",
                "apr": "Abr",
                "may": "May",
                "jun": "Jun",
                "jul": "Jul",
                "aug": "Ago",
                "sep": "Sep",
                "oct": "Oct",
                "nov": "Nov",
                "dec": "Dic",
                "january": "Enero",
                "february": "Febrero",
                "march": "Marzo",
                "april": "Abril",
                "may2": "Mayo",
                "june": "Junio",
                "july": "Julio",
                "august": "Agosto",
                "september": "Septiembre",
                "october": "Octubre",
                "november": "Noviembre",
                "december": "Diciembre",
                "sunday": "Domingo",
                "monday": "Lunes",
                "tuesday": "Martes",
                "wednesday": "Miércoles",
                "thursday": "Jueves",
                "friday": "Viernes",
                "saturday": "Sábado",
                "sun": "Dom",
                "mon": "Lun",
                "tue": "Mar",
                "wed": "Mié",
                "thu": "Jue",
                "fri": "Vie",
                "sat": "Sáb",
                "toolbar": {
                    "exportToSVG": "Descargar SVG",
                    "exportToPNG": "Descargar PNG",
                    "menu": "Menú",
                    "selection": "Selección",
                    "selectionZoom": "Zoom de Selección",
                    "zoomIn": "Aumentar",
                    "zoomOut": "Disminuir",
                    "pan": "Desplazar",
                    "reset": "Restablecer Zoom"
                }
            },
            "curveABC": {
                "loading": "Cargando...",
                "title": "Curva ABC",
                "totalSales": "Ventas Totales",
                "period": "Período",
                "stockPerTypeProducts": "Inventario por Tipos de Producto",
                "tableHeaders": {
                    "title": "Título",
                    "sku": "SKU",
                    "type": "Tipo",
                    "quantity": "Cantidad",
                    "amount": "Ingresos",
                    "percent": "%",
                    "accumulatedPercent": "% Acumulado",
                    "curve": "Curva ABC",
                    "stock": "Inventario",
                    "expectedFinish": "Agotamiento",
                    "valueStock": "Valor del Inventario",
                    "media_day": "Promedio por día",
                    "expected_days": "Días para Agotar"
                },
                noInstall: {
                    benefit1: "Acceso instantáneo a métricas cruciales de ventas y inventario.",
                    benefit2: "Seguimiento en tiempo real de eventos de clientes.",
                    benefit3: "Toma de decisiones más informadas para optimizar estrategias.",
                    benefit4: "Aumento de la rentabilidad basado en datos sólidos.",
                    benefit5: "Interfaz amigable y fácil de usar.",
                    new: "Nuevo",
                    title: "¡Desbloquea el Potencial Máximo con Nuestra Aplicación!",
                    text1: "Desbloquea el potencial máximo de tu comercio electrónico Shopify con acceso instantáneo a métricas cruciales de ventas, inventario y eventos de clientes. Toma decisiones más informadas, optimiza tus estrategias y aumenta tu rentabilidad como nunca antes.",
                    text2: "No pierdas tiempo; ¡comienza a dominar tus datos ahora mismo!",
                    text3: "Instala nuestra aplicación para una experiencia completa y aprovecha al máximo todos los beneficios que ofrecemos.",
                    unblock: "Desbloquear Potencial Máximo",
                    info: "Información",
                    major100: "Mayor al 100%: + ventas - inventario.",
                    between100: "Menor al 100%: - ventas + inventario.",
                }
            },

            costsApp: {
                oms: {
                    spending: 'Gastos',
                    spedningText: 'Valor de la instancia de AWS',
                    earnings: 'Ingresos',
                    earningsText: 'Valor total de los ingresos de las tiendas',
                    profit: 'Beneficio',
                    stores: 'Tiendas',
                    storesText: 'Tiendas que utilizaron la aplicación',
                    fulfillments: 'Cumplimientos',
                    fulfillmentsText: 'Número de cumplimientos integrados',
                    costResource: 'Costo/recursos',
                    costResourceText: 'Costo por cada recurso utilizado',
                    store: 'Tienda',
                    resources: 'Recursos',
                    cost: 'Costo',
                    pricePerUse: 'Precio por uso',
                    pricePlan: 'Precio mensual',
                    totalPrice: 'Precio total',
                    loading: 'Cargando...',
                    noData: 'No hay datos para el mes seleccionado'
                }
            }

        }
    }
}

export { messages };