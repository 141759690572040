import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { connect } from 'react-redux'
import * as actions from '../../store/actions/agencies'
import { Table, TableBody, TableCell, TableRow, Paper, Button, Box, Typography, Popover } from '@mui/material';
import { toast } from 'react-toastify';
import { userPermissionCheck } from '../Sprint/utils/userPermissionCheck';
import { i18n } from '../../translate/i18n';
import { isMobile } from 'react-device-detect';
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded';
import { FCChip, FCDropdownButton, FCPagination, FCTableContainer, FCTableHeader } from 'components-fullcomm';

const ListAgencies = (props) => {
    const history = useHistory();

    const [pageCurrent, setPageCurrent] = useState(1);
    const limit = 20;
    const [loading, setLoading] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [customers, setCustomers] = useState([]);
    useEffect(() => {
        props.getAgencies({pageCurrent, limit})
    }, [pageCurrent]);

    const finishedAgency = async (id) => {
        setLoading(true)
        const result = await props.handleUpdateAgency({_id: id, status: 'finished'})
        await props.getAgencies(pageCurrent, limit)
        toast.success(i18n.t('agency.list.endContract'));
        setLoading(false)
    }

    let agencies = [];
    const handleClick = (event, customers) => {
        setAnchorEl(event.currentTarget);
        setCustomers(customers);
      };
    
      const handleClose = () => {
        setAnchorEl(null);
        setCustomers([]);
      };
    
      const open = Boolean(anchorEl);

    if (props.agencies) {
        agencies = props.agencies.docs
    }

    return (
        <div class={`p-4 ${isMobile && 'mb-5'}`}>
            <div className="d-flex">
                <div className="mr-auto p-2">
                <Typography variant="h4">{i18n.t('agency.list.agencies')}</Typography>
                </div>
                {
                    userPermissionCheck(props.user, ["create_agency"]) && (
                        <Link to={"/add-agency"}>
                            <Button className="btn-action medium">{i18n.t('agency.list.register')}</Button>
                        </Link>
                    )
                }

            </div>
            {isMobile ? (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        borderRadius: '4px',
                        border: '1px solid #D1D5DB',
                    }}
                >
                    <Popover
                      open={open}
                      anchorEl={anchorEl}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                      }}
                    >
                        <Box
                            sx={{
                                padding: '1rem',
                                width: '200px',
                            }}
                        >
                            {customers.map(elem => elem)}
                        </Box>
                    </Popover>
                    {agencies?.map((row, i) => (
                        <Box 
                            key={row._id}
                            borderTop={i !== 0 ? '1px solid #D1D5DB' : ''}
                            paddingY='1rem'
                            paddingX='0.5rem'
                            display={'flex'}
                            justifyContent={'space-between'}
                        >
                            <Box>
                                <Typography variant="subtitle2">{i18n.t('agency.list.corporateName')}</Typography>
                                <Typography variant="body2">{row.name}</Typography>
                                <Typography variant="subtitle2">CNPJ</Typography>
                                <Typography variant="body2">{row.cnpj}</Typography>
                            </Box>
                            <Box display='flex'>
                                <Box>
                                    <Typography variant="subtitle2">
                                        {i18n.t('agency.list.stores')}
                                        {" "}                                   
                                        <ArrowDropDownRoundedIcon 
                                            sx={{ height: '24px', width: '24px', cursor: 'pointer', color: '#888888'}} 
                                            onClick={(e) => {handleClick(e, row.customers?.map(elem => elem.name))}}
                                        />
                                    </Typography>
                                    <Typography variant='body2' sx={{color: 'white'}}>0</Typography>
                                    <Typography variant="subtitle2">{i18n.t('agency.list.status')}</Typography>
                                    {row.status === 'active' ? <FCChip color='green-success' label={i18n.t('agency.list.active')}/> : <FCChip color='danger-red' label={i18n.t('agency.list.finished')} />}
                                </Box>
                                <Box className="d-flex justify-content-end">
                                    <FCDropdownButton
                                        items={[i18n.t('agency.list.edit'), row.status === 'active' ? i18n.t('agency.list.end') : '', i18n.t('agency.list.users'), i18n.t('agency.list.activities')]}
                                        itemClickHandlers={[() => history.push(`/edit-agency/${row._id}`), () => finishedAgency(row._id), () => history.push(`/user/agency/${row._id}`), () => history.push(`/agency-table/${row._id}`)]}
                                    />
                                </Box>
                            </Box>
                        </Box>
                    ))}
                </Box>
            ) : (
                <FCTableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <FCTableHeader sx={{backgroundColor: '#F0F0F0'}}>
                        <TableRow>
                            <TableCell width='35%'>{i18n.t('agency.list.socialName')}</TableCell>
                            <TableCell width='35%'>{i18n.t('agency.list.stores')}</TableCell>
                            <TableCell width='20%'>{i18n.t('agency.list.cnpj')}</TableCell>
                            <TableCell width='20%'>{i18n.t('agency.list.status')}</TableCell>
                            <TableCell width='10%'></TableCell>
                        </TableRow>
                    </FCTableHeader>
                    <TableBody>
                        {agencies?.map((row, i) => (
                            <TableRow
                                key={row._id}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell>
                                    {row.name}
                                </TableCell>
                                <TableCell>{row.customers.map(elem => elem.name).join(', ')}</TableCell>
                                <TableCell>{row.cnpj}</TableCell>
                                <TableCell>{row.status === 'active' ? <FCChip color='green-success' label={i18n.t('agency.list.active')}/> : <FCChip color='danger-red' label={i18n.t('agency.list.finished')} />}</TableCell>
                                <TableCell align="right">
                                <FCDropdownButton
                                    items={[i18n.t('agency.list.edit'), row.status === 'active' ? i18n.t('agency.list.end') : '', i18n.t('agency.list.users'), i18n.t('agency.list.activities')]}
                                    itemClickHandlers={[() => history.push(`/edit-agency/${row._id}`), () => finishedAgency(row._id), () => history.push(`/user/agency/${row._id}`), () => history.push(`/agency-table/${row._id}`)]}
                                    i
                                />
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </FCTableContainer>
            )}
            <br />
            {!props.agencies ? "" :
                <Box display="flex" justifyContent="center" alignItems="center">
                    <FCPagination count={props.agencies.totalPages} page={pageCurrent} onChange={(e, page) => setPageCurrent(page)} />
                </Box>
            }
        </div>
    )
}


const mapStateToProps = state => ({
    user: state.auth.user,
    agencies: state.agencies.agencies,
})

export default connect(mapStateToProps, actions)(ListAgencies);

