import { Box, Button, CardActionArea, Collapse, Divider, Grid, Icon, IconButton, Table, TableBody, TableCell, TableRow, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import * as actions from '../../../store/actions/aiContent';
import * as actions2 from '../../../store/actions/action';
import { api } from '../../../config';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import dayjs from 'dayjs';
import Filter from './Filter';
import DetailsModal from './DetailsModal';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import { queryString } from '../../../utils/String';
import { i18n } from '../../../translate/i18n';
import { FCPagination, FCTableContainer, FCTableHeader } from 'components-fullcomm';

function Row({ row, handleClick, handleSendToJira, handleDelete }) {
  const [open, setOpen] = useState(false);
  const samples = row.output?.length ? row.output : row.files;
  const associated = row.activity ? i18n.t('aiContent.library.activity') : row.action ? i18n.t('aiContent.library.action') : i18n.t('aiContent.library.none');
  const keysToRemove = ['cfg_scale', 'height', 'image_strength', 'removeText', 'removeWeight', 'samples', 'steps', 'style_preset', 'width', 'addWeight'];

  const text = Object.keys(row.prompt)
    .filter(key => !keysToRemove.includes(key))
    .map(key => (row.prompt[key]))

  return (
    <>
      <TableRow
        key={row._id}
        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
      >
        <TableCell component="th" scope="row">
          {row.title}
        </TableCell>
        <TableCell>{row.action?.name || ''}</TableCell>
        <TableCell>{associated}</TableCell>
        <TableCell>{row.type}</TableCell>
        <TableCell>{samples.length || 0}</TableCell>
        <TableCell>{dayjs(row.createdAt).format('DD/MM/YYYY')}</TableCell>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell sx={{ py: 0 }} colSpan={7}>
          <Collapse in={open} timeout="auto" unmountOnExit sx={{padding: "1rem"}}>
            <Grid container spacing={2}>
            {
              samples?.map((sample, index) => (
                <Grid item xs={12} md={6} lg={4} key={index}>
                  {
                    sample.name && (
                      <CardActionArea onClick={() => handleClick(sample.name)} sx={{borderRadius: "5px"}}>
                        <img src={`${api}/files/aicontent/${sample.name}`} alt={sample.name} style={{ width: '100%', borderRadius: "5px" }} />
                      </CardActionArea>
                    )
                  }
                  {
                    (sample.text || sample.ad_description || sample.ad_title ) && (
                      <Box 
                        sx={{border: "1px solid #ccc", borderRadius: '5px', padding: '1rem'}}
                      >
                        {
                          sample.ad_title && (
                            <Typography variant='h5' mb={"1rem"}>{sample.ad_title}</Typography>
                          )
                        }
                        {
                          sample.ad_description && (
                            <Typography>{sample.ad_description}</Typography>
                          )
                        }
                        {
                          sample.text && (
                            <Typography>{sample.text}</Typography>
                          )
                        }
                      </Box>
                    )
                  }
                </Grid>
              ))
            }
            </Grid>
            <Divider sx={{marginTop: "1rem", marginBottom: "1rem"}}/>
            <Box display="flex" justifyContent="space-between" alignItems="flex-start">
              <Box display="flex" gap="0.5rem">
                <Typography><b>Prompt: </b></Typography>
                <Box>
                  {
                    text.map((part, index) => {
                      return <Typography key={index}>{part}</Typography>
                    })
                  }
                </Box>
              </Box>
              <Box display="flex" flexDirection="column" gap="0.5rem">
                <Button className='btn-default' onClick={() => handleSendToJira(row)}>Jira</Button>
                <Button className='btn-reprove' onClick={() => handleDelete(row)}>{i18n.t('aiContent.library.delete')}</Button>
              </Box>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}

const Library = (props) => {
  const [selected, setSelected] = useState({});
  const [openJira, setOpenJira] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [deleteModal, setDeleteModal] = useState(false);

  const handleClick = (fileName) => {
    const url = `${api}/files/aicontent/${fileName}`;
    window.open(url, '_blank');
  };

  const handleSendToJira = (item) => {
    if (item.jiraCode || item.action) {
      window.open(`https://checkstore.atlassian.net/browse/${item.jiraCode}`, '_blank')
    } else {
      setOpenJira(true);
      setSelected(item);
    }
  };

  const handleDelete = (item) => {
    setSelected(item);
    setDeleteModal(true);
  };

  const filterActions = (title = "", action = "", type = "", associated = "", page = 1) => {
    const params = {
      title,
      action,
      type,
      associated,
      page
    }
    const query = queryString(params);
    props.getAiContent(query);
  };

  const deleteItem = async () => {
    await props.deleteAiContent(selected?._id);
    setCurrentPage(1);
    filterActions();
    setDeleteModal(false);
  };

  useEffect(()=>{
    props.getActions();
    setCurrentPage(1);
  },[props.shop]);

  return (
    <Box className="dashboard">
      <DetailsModal
        open={openJira}
        setOpen={setOpenJira}
        selected={selected}
      />
      <Typography variant="h4">{i18n.t('aiContent.library.library')}</Typography>
      <Filter 
        actions={props.actions}
        getAiContent={props.getAiContent}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
        shop={props.shop}
        filterActions={filterActions}
      />
      <Box>
        <FCTableContainer>
          <Table sx={{ minWidth: 650 }}>
            <FCTableHeader>
              <TableRow sx={{background: "#F0F0F0"}}>
                <TableCell width={"15%"}>{i18n.t('aiContent.library.title')}</TableCell>
                <TableCell width={"20%"}>{i18n.t('aiContent.library.action')}</TableCell>
                <TableCell width={"10%"}>{i18n.t('aiContent.library.link')}</TableCell>
                <TableCell width={"20%"}>{i18n.t('aiContent.library.type')}</TableCell>
                <TableCell width={"10%"}>{i18n.t('aiContent.library.samples')}</TableCell>
                <TableCell width={"10%"}>{i18n.t('aiContent.library.date')}</TableCell>
                <TableCell width={"5%"}></TableCell>
              </TableRow>
            </FCTableHeader>
            <TableBody>
              {props.aiContent?.docs?.map((row) => 
                <Row 
                  key={row._id} 
                  row={row} 
                  handleClick={handleClick} 
                  handleSendToJira={handleSendToJira} 
                  handleDelete={handleDelete}
                />
              )}
            </TableBody>
          </Table>
        </FCTableContainer>
          <FCPagination
            sx={{ padding: '0.5rem'}}
            count={props.aiContent?.totalPages}
            page={currentPage}
            onChange={(e, page) => setCurrentPage(page)}
          />
      </Box>
      <Modal centered toggle={() => setDeleteModal(false)} isOpen={deleteModal}>
        <Box 
          sx={{
            display: "flex", 
            justifyContent: "space-between", 
            padding: 2, 
            alignItems: "center"
          }}
          >
          <Typography variant="h3">{i18n.t('aiContent.library.deleteAction')}</Typography>
          <Icon
            sx={{
              color: "#86888c",
              cursor: "pointer",
            }}
            onClick={() => setDeleteModal(false)}
            className="material-icons-outlined"
            fontSize="large"
          >
            cancel
          </Icon>
        </Box>
        <Divider />
        <ModalBody>
          <Typography height={"5vh"}>{i18n.t('aiContent.library.confirmDelete')}</Typography>
        </ModalBody>
        <ModalFooter>
          <Box display={"flex"} gap={1}>
            <Button variant="contained" className="btn-reprove" onClick={deleteItem}>
              Deletar
            </Button>
            <Button variant="contained" className="btn-gray" onClick={() => setDeleteModal(false)}>
              Cancelar
            </Button>
          </Box>
        </ModalFooter>
      </Modal>
    </Box>
  )
}

const mapStateToProps = (state) => ({
  aiContent: state.aiContent.aiContent,
  actions: state.actions.actions,
  shop: state.auth.shop,
});

export default connect(mapStateToProps, {...actions, ...actions2})(Library);