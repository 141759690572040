import { Slider, Typography } from '@mui/material'
import React from 'react'
import { i18n } from '../../../../translate/i18n';

const Score = ({score, setScore}) => {
  return (
    <>
      <Typography>{i18n.t('sprint.utils.filter.score.score')}</Typography>
      <Typography>{score[0]}</Typography>
      <Slider
        color='backlog'
        sx={{ marginLeft: '1rem', marginRight: '1rem' }}
        value={score}
        onChange={(e, value) => setScore(value)}
        min={0}
        max={1000}
        valueLabelDisplay="auto"
      />
      <Typography>{score[1]}</Typography>
    </>
  )
}

export default Score