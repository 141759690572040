export const categories = [
  {
    name: "Ativação",
    icon: "campaign",
    quadrants: [
      "Descoberta",
      "Consideração",
      "Prospecção"
    ],
  },
  {
    name: "Aquisição",
    icon: "ads_click",
    quadrants: [
      "Conteúdo",
      "Rastreamento",
      "Benefício",
    ],
  },
  {
    name: "Engajamento",
    icon: "thumbs_up_down",
    quadrants: [
      "Próxima Compra",
      "Contexto",
      "Recuperação",
    ],
  },
  {
    name: "Conversão",
    icon: "currency_exchange",
    quadrants: [
      "Barreiras de Compra",
      "Ofertas",
      "Autoridade",
    ],
  },
  {
    name: "Retenção",
    icon: "volunteer_activism",
    quadrants: [
      "Análise de experiência",
      "Transmissão",
      "Segmentos",
    ],
  }
];

export const currencySymbols = [
  'R$',
  '$',
  '€',
  '¥',
  '£',
  '₩',
  '₹',
  '₿',
];