import { Box, Chip, Grid, Paper, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { api } from '../../../../config';
import FileSelect from '../utils/FileSelect';
import EditCampaignModal from '../utils/EditCampaignModal';
import FreeSoloCreateOption from '../utils/FreeSoloCreateOption';
import CategorySelect from '../utils/CategorySelect';
import { useField } from 'formik';
import { isMobile } from "react-device-detect";
import "dayjs/locale/pt-br";
import 'dayjs/locale/es';
import { toast } from 'react-toastify';
import { i18n } from '../../../../translate/i18n';
import ReactQuillAction from '../utils/ReactQuillAction';
import { BaseDatePicker } from '../utils/BaseDatePicker';
import { FCCheckbox, FCSelect, FCTextField } from 'components-fullcomm';


const Details = ({ getFieldProps, campaigns, type, errors, touched, queryFiles, id, deleteActionFile, values }) => {
  const [end, , endHelpers] = useField("end");
  const [campaign] = useField("campaign");
  const [perpetual, , perpetualHelpers] = useField("perpetual");
  const [files, setFiles] = useState(queryFiles || []);
  const [userLanguage, , userLanguageHelpers] = useField("userLanguage")
  const [name, , nameHelper] = useField("name")
  const [description, , descriptionHelper] = useField("description")
  const [language, , languageHelpers] = useField("language")

  const handleClick = (fileName) => {
    const url = fileName?.location ? fileName?.location : `${api}/files/actions/${id}/${fileName.id}`;
    window.open(url, '_blank');
  };

  const handleDelete = async (fileName) => {
    const response = await deleteActionFile(id, fileName);
    if (response.error) toast.error(response.error.message);
    else {
      toast.success(response.message);
      const index = files.findIndex((file) => file.name === fileName);
      if (index !== -1) {
        const newFiles = [...files];
        newFiles.splice(index, 1);
        setFiles(newFiles);
      }
    }

  };

  useEffect(() => {
    setFiles(queryFiles || []);
  }, [queryFiles]);

  const languageOptions = [
    { value: 'pt-BR', label: i18n.t("sprint.newAction.pages.details.portuguese") },
    { value: 'en-US', label: i18n.t("sprint.newAction.pages.details.english") },
    { value: 'es', label: i18n.t("sprint.newAction.pages.details.spanish") }
  ];

  return (
    <Box padding={isMobile ? 0 : "1rem"}>
      <Paper sx={{ padding: "1rem", borderRadius: "5px" }}>
        <Typography mb="1rem" variant="h4">{i18n.t("sprint.newAction.pages.details.detailsOf")} {type}</Typography>
        <Grid container columnSpacing={2} rowSpacing={isMobile ? 2 : 4}>
          <Grid item xs={isMobile ? 12 : ((localStorage.getItem('shop') === '64599fe285284405feff3961') || (localStorage.getItem('shop') === '6455addbdbf2797467494658')) ? 10 : 12} >
            <FCTextField
              size={isMobile ? 'small' : 'medium'}
              fullWidth
              label={i18n.t('sprint.newAction.pages.details.name')}
              placeholder={i18n.t('sprint.newAction.pages.details.name')}
              {...getFieldProps('name')}
              error={Boolean(touched.name && errors.name)}
              helperText={touched.name && errors.name}
            />
          </Grid>
          {localStorage.getItem('shop') === '64599fe285284405feff3961' ?
            <Grid item xs={isMobile ? 12 : 2}>
              <FCSelect
                fullWidth
                select
                size={isMobile ? 'small' : 'medium'}
                label={i18n.t("sprint.newAction.pages.details.language")}
                onChange={(e) => {
                  const prevUserLanguage = userLanguage.value;
                  languageHelpers.setValue({
                    ...language.value,
                    [prevUserLanguage]: { name: values.name, description: values.description }
                  });
                  userLanguageHelpers.setValue(e.target.value);
                  nameHelper.setValue(values.language[e.target.value]?.name || '');
                  descriptionHelper.setValue(values.language[e.target.value]?.description || '');
                }}
                value={userLanguage.value}
                options={languageOptions.map((option) => (
                  { value: option.value, label: option.label }
                ))}
              />
            </Grid> : null}
          <Grid item xs={12} paddingBottom={"2.5rem"}>
            <ReactQuillAction />
          </Grid>
          {
            type === "Campanha" && (
              <>
                <Grid item xs={6}>
                  <CategorySelect
                    errors={errors.category}
                    touched={touched.category}
                    getFieldProps={getFieldProps}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FCSelect
                    select
                    fullWidth
                    type="number"
                    size={isMobile ? "small" : "medium"}
                    label={i18n.t("sprint.newAction.pages.details.deadlineChecklist")}
                    {...getFieldProps("executionTime")}
                    options={[
                      { value: 0, label: 0 + ' '  + i18n.t("sprint.newAction.pages.details.weeks") },
                      { value: 1, label: 1 + ' '  + i18n.t("sprint.newAction.pages.details.week") },
                      { value: 2, label: 2 + ' '  + i18n.t("sprint.newAction.pages.details.weeks") },
                      { value: 3, label: 3 + ' '  + i18n.t("sprint.newAction.pages.details.weeks") },
                      { value: 4, label: 4 + ' '  + i18n.t("sprint.newAction.pages.details.weeks") }
                    ]}
                  />
                </Grid>
              </>
            )
          }
          <Grid item xs={6}>
            <Box display='flex' gap="1rem" alignItems="end">
              <FreeSoloCreateOption
                isMobile={isMobile}
                campaigns={campaigns}
              />
              {
                campaign.value && (
                  <EditCampaignModal
                    isMobile={isMobile}
                    campaign={campaign.value}
                  />
                )
              }
            </Box>
          </Grid>
          {type === "Campanha" && (
            <Grid item xs={6}></Grid>
          )}
          <Grid item xs={6}>
            <BaseDatePicker
              fieldName="start"
              disabled={false}
              getFieldProps={getFieldProps}
              errors={errors}
              touched={touched}
            />
          </Grid>
          {
            type === "Campanha" && (
              <Grid item xs={6}>
                <BaseDatePicker
                  fieldName="end"
                  disabled={perpetual.value}
                  getFieldProps={getFieldProps}
                  errors={errors}
                  touched={touched}
                />
                <FCCheckbox
                  onChange={() => {
                    perpetualHelpers.setValue(!perpetual.value);
                    if (perpetual.value) {
                      endHelpers.setValue('');
                    }
                  }}
                  value={perpetual.value}
                  label={i18n.t("sprint.newAction.pages.details.perpetualAction")}
                />
              </Grid>
            )
          }
          <Grid item xs={12}>
            <FileSelect />
            {
              files.map((file, index) => {
                return (
                  <Chip
                    sx={{ marginLeft: '0.5rem', marginY: '0.5rem' }}
                    key={index}
                    label={file.name}
                    onClick={() => handleClick(file)}
                    onDelete={() => handleDelete(file.name)}
                  />
                )
              }
              )}
          </Grid>
        </Grid>
      </Paper>
    </Box>
  )
}

export default Details