import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Modal, ModalBody } from 'reactstrap';

import ChipProject from '../../../components/ChipProject'
import { connect } from 'react-redux'
import { getProjects, deleteProject } from '../../../store/actions/projects'
import { getCustomers } from '../../../store/actions/customers'
import { Stack, Typography, CardActions, CardContent, Button, Grid, Card } from '@mui/material';
import moment from "moment";
import { Icon } from '@iconify/react';
import { toast } from 'react-toastify';
import { isMobile } from 'react-device-detect';
import { i18n } from '../../../translate/i18n';
import { FCModalFooter, FCModalHeader, FCPagination, FCSelect } from 'components-fullcomm';

const Project = (props) => {
    const [pageCurrent, setPageCurrent] = useState(1);
    const limit = 12;
    const [loading, setLoading] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [idDelete, setIdDelete] = useState("");
    const [status, setStatus] = useState('all');
    const [customer, setCustomer] = useState('all');
    const [customers, setCustomers] = useState([])

    useEffect(() => {
        props.getCustomers(1, 1000)
    }, [])

    useEffect(() => {
        props.getProjects(pageCurrent, limit, customer === 'all' ? '' : customer, status === 'all' ? '' : status)
    }, [pageCurrent, status, customer]);


    useEffect(() => {
        setCustomers(props.customers?.docs)
    }, [props.customers]);

    const deleteProject = async () => {
        setLoading(true)
        try {
            const result = await props.deleteProject(idDelete)
            await props.getProjects(pageCurrent, limit)

            if (result.error) {
                switch (result.error.message) {
                    case "Projeto não existe!": return  toast.error(i18n.t('start.project.projectNotExist'));
                    case "Erro ao excluir!": return  toast.error(i18n.t('start.project.deleteError'));
                    case "Erro interno do sistema.": return  toast.error(i18n.t('start.project.internalError'));
                    default: return toast.error(result.error.message)
                }
            } else {
                if (result.message === "Projeto apagado com sucesso!") {
                    toast.success(i18n.t('start.project.deleteSuccess'))
                } else {
                    toast.success(result.message)
                }
            }

            toggleModal();
        } catch (error) {
            if (error.error.message === "Erro interno do servidor.") {
                toast.error(i18n.t('start.project.internalError'))
            } else {
                toast.error(error.error.message)
            }
        }
        setLoading(false)
    }

    const toggleModal = (_id = "") => {
        if (_id) {
            setIdDelete(_id)
        } else {
            setIdDelete("")
        }
        setOpenModal(!openModal);
    }

    let projects = [];

    if (props.projects) {
        projects = props.projects.docs
    }

    return (
        <div style={{ padding: isMobile ? '16px 16px 66px 16px' : '16px' }} className={!isMobile & "p-4"} >
            <div className="d-flex">
                <div className="mr-auto p-2">
                    <Typography variant="h4">{i18n.t('start.project.projects')}</Typography>
                </div>
            </div>
            <Stack direction={{ xs: 'row', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                <FCSelect
                    sx={{ minWidth: '100px' }}
                    label={i18n.t('start.project.store')}
                    value={customer}
                    onChange={e => setCustomer(e.target.value)}
                    options={[
                        { value: 'all', label: i18n.t('start.project.all') },
                        ...(customers?.map(elem => ({ value: elem._id, label: elem.name })) || [])
                    ]}
                />
                <FCSelect
                    sx={{ minWidth: '100px' }}
                    label={i18n.t('start.project.status')}
                    value={status}
                    onChange={e => setStatus(e.target.value)}
                    options={[
                        { value: 'all', label: i18n.t('start.project.all2') },
                        { value: 'form', label: i18n.t('start.project.incomplete') },
                        { value: 'development', label: i18n.t('start.project.development') },
                        { value: 'waiting_price', label: i18n.t('start.project.waitingBudget') },
                        { value: 'waiting_dev', label: i18n.t('start.project.waitingDevelopment') },
                        { value: 'waiting_customer', label: i18n.t('start.project.waitingCustomer') },
                        { value: 'concluded', label: i18n.t('start.project.done') },
                    ]}
                />
            </Stack>
            <br />
            <Grid container spacing={2}>
                {projects.map(row => (
                    <Grid item md={4} xs={12} key={row._id}>
                        <Card>
                            <CardContent style={{ position: 'relative' }}>
                                <ChipProject status={row.status} style={{ marginBottom: '10px' }} />
                                <Icon onClick={() => toggleModal(row._id)} style={{ position: "absolute", right: '10px', cursor: "pointer" }} width="25" icon="material-symbols:delete" />
                                <Typography variant="h5" component="div">
                                    {row.customer?.name}
                                </Typography>
                                <Typography color="text.secondary" variant="body2">
                                    {i18n.t('start.project.createIn')} {moment(row.createdAt).format("DD/MM/YYYY - HH:mm:ss")}
                                    <br />
                                    {i18n.t('start.project.updatedIn')} {moment(row.updatedAt).format("DD/MM/YYYY - HH:mm:ss")}
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <Link to={`/project/${row._id}`}><Button size="small">{i18n.t('start.project.details')}</Button></Link>
                            </CardActions>
                        </Card>
                    </Grid>
                ))}
            </Grid>
            <br />
            {!props.projects ? "" :
                <FCPagination count={props.projects.totalPages} page={pageCurrent} onChange={(e, page) => setPageCurrent(page)} />
            }

            <Modal isOpen={openModal} >
                <FCModalHeader title={i18n.t('start.project.remove')}  onClick={() => toggleModal()}/>
                <ModalBody>
                    {i18n.t('start.project.confirmRemove')}
                </ModalBody>
                <FCModalFooter
                    handleAction={() => deleteProject()}
                    loading={loading}
                    actionLabel={i18n.t('start.project.remove')}
                    handleCancel={() => toggleModal()}
                    cancelLabel={i18n.t('start.project.cancel')}
                />
            </Modal>
        </div>
    )
}


const mapStateToProps = state => ({
    projects: state.project.projects,
    customers: state.customer.customers
})


const mapDispatchToProps = (dispatch) => {
    return {
        deleteProject: (id) => dispatch(deleteProject(id)),
        getProjects: (p, l, c, s) => dispatch(getProjects(p, l, c, s)),
        getCustomers: () => dispatch(getCustomers(1, 1000))
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(Project);

