import { Box, Tab, Typography } from '@mui/material'
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import * as actions from '../../../store/actions/customers'
import WebVitals from './WebVitals';
import { api } from '../../../config';
import { getHeaders } from '../../../store/actions/localStorage';
import Graphs from './Graphs';
import Status from './Status';
import { i18n } from '../../../translate/i18n';
import { FCSelect, FCTabs } from 'components-fullcomm';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      style={{ width: "100%", paddingTop: "1rem" }}
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box padding={"1rem"}>
          {children}
        </Box>
      )}
    </div>
  );
}

const Performance = (props) => {
  const [value, setValue] = useState(0);
  const [performanceData, setPerformanceData] = useState({});
  const [lightHouse, setLightHouse] = useState({});
  const [loading, setLoading] = useState(false);
  const [audits, setAudits] = useState({});
  const [strategy, setStrategy] = useState("desktop");
  const [data, setData] = useState({});

  const getPerformanceData = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${api}/performance/`, getHeaders());
      const performanceData = response.data?.performance || {};
      setData(performanceData);

      setLoading(false);
    return null;
    } catch (error) {
      setLoading(false);
    }
  };

  const updatePerformanceData = () => {
    setPerformanceData(data[strategy]?.loadingExperience || {});
    setLightHouse(data[strategy]?.lighthouseResult?.categories || {});
    setAudits(data[strategy]?.lighthouseResult?.audits || {});
  }

  useEffect(() => {
    getPerformanceData();
    props.getViewCustomer(props.shop);
  }, [props.shop])

  useEffect(() => {
    updatePerformanceData();
  }, [props.shop, strategy, data])

  return (
    <Box className="dashboard-body">
      <Box display={"flex"} justifyContent={"space-between"}>
        <Typography variant='h4'>{i18n.t('support.performance.performance')}</Typography>
        <FCSelect
          label={i18n.t('support.performance.strategy')}
          value={strategy}
          onChange={(e) => setStrategy(e.target.value)}
          sx={{ width: "10rem" }}
          options={[
            { label: i18n.t('support.performance.desktop'), value: "desktop" },
            { label: i18n.t('support.performance.mobile'), value: "mobile" },
          
          ]}
        />
      </Box>
      <FCTabs
          onChange={(e, newValue) => setValue(newValue)}
          value={value} 
          sx={{
            "& .MuiButtonBase-root": {
              textTransform: "none",
            },
          }}
        >
          <Tab label={i18n.t('support.performance.details')}/>
          <Tab label={i18n.t('support.performance.graphics')}/>
          <Tab label={i18n.t('support.performance.statusLabel')}/>
        </FCTabs>
        <TabPanel value={value} index={0}>
          <WebVitals performanceData={performanceData} lightHouse={lightHouse} loading={loading} audits={audits} user={props.user} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Graphs history={data?.history} strategy={strategy}/>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Status url={props?.customer?.uptimeStatusId} />
        </TabPanel>
    </Box>
  )
}

const mapStateToProps = (state) => ({
  customer: state.customer.customerDetails,
  shop: state.auth.shop,
  user: state.auth.user,
});

export default connect(mapStateToProps, actions)(Performance)