import React from 'react';
import { Typography } from '@mui/material';
import { i18n } from '../../../translate/i18n';
import { FCAutocomplete } from 'components-fullcomm';
const SearchActions = ({action, actions, setAction, label = true}) => {
  return (
    <FCAutocomplete
      disablePortal
      options={actions?.docs?.filter(el => el.status !== "Planejamento") || []}
      filterOptions={(options, state) =>{
        const arr = options.filter((item) =>
          item.name.toLowerCase().includes(state.inputValue.toLowerCase())
        )
        return arr;
      }}
      fullWidth
      size='small'
      getOptionLabel={(option) => {
        return option?.name || '';
      }}
      value={action}
      label={label && i18n.t('aiContent.utils.searchAction.action')}
      onChange={(event, newValue) => setAction(newValue)} 
      renderOption={(props, option) => (
        <li {...props} key={option._id}>
          <Typography variant="body1">{option.name}</Typography>
        </li>
      )}
      // renderInput={(params) => <TextField {...params} label={i18n.t('aiContent.utils.searchAction.action')} />} 
    />
  )
}

export default SearchActions