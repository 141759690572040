import axios from 'axios';
import {api} from '../../config'
import {getHeaders} from './localStorage'
import errorHandling from './errorHandling'
import {parseQueryString, queryString} from '../../utils/String'

export const getContracts = (params) => async (dispatch) => {
    try {
        const response = await axios.get(api + `/contracts?${queryString(params)}`, getHeaders());
        dispatch({ type: "GET_CONTRACTS", payload: response.data });
    } catch (err) {
        return errorHandling(err);
    }
}

export const getViewContract = (id) => async (dispatch) => {
    try {
        const response = await axios.get(`${api}/contracts/${id}`, getHeaders());
        dispatch({ type: 'GET_CONTRACT_VIEW', payload: response.data })
    } catch (error) {
        return errorHandling(error);
    }
}

export const getViewContractSpecific = () => async (dispatch) => {
    try {
        const response = await axios.get(`${api}/contract`, getHeaders());
        dispatch({ type: 'GET_CONTRACT', payload: response.data })
    } catch (error) {
        return errorHandling(error);
    }
}

export const handleAddContract = (props) => async () => {
    try {
        const response = await axios.post(api+"/contracts", props, getHeaders());
        return response.data;
    } catch (err) {
        return errorHandling(err);
    }
}

export const handleUpdateContract = (props) => async () => {
    try {
        const response = await axios.put(api+"/contracts", props, getHeaders());
        return response.data;
    } catch (err) {
        return errorHandling(err);
    }
}

export const clearContract = () => {
    return function(dispatch){
        dispatch({type: "CLEAR_CONTRACT"})
    } 
}

export const clearContracts = () => {
    return function(dispatch){
        dispatch({type: "CLEAR_CONTRACTS"})
    } 
}

export const addBilling = (props) => async () => {
    try {
        const response = await axios.post(api + `/contract/billing`, props, getHeaders());
        return response.data;
    } catch (err) {
        return errorHandling(err);
    }
}

export const getBillingLogs = (id, params) => async () => {
    try {
        const { action } = params;
        const response = await axios.get(api + `/contract/billing/${id}?action=${action}`, getHeaders());
        return response.data.billingLogs;
    } catch (err) {
        return errorHandling(err);
    }
}

export const addContractFiles = async (formdata, contractId) => {
    try {
        console.log('formdata', formdata)
        const response = await axios.put(api + `/contract-file/${contractId}`, formdata, getHeaders());
        return response.data;
    } catch (err) {
        return errorHandling(err);
    }
}

export const RemoveContractFile = async (contractId, fileId) => {
    try {
        const response = await axios.delete(api + `/contract-file/${contractId}/${fileId.split("/").pop()}`, getHeaders());
        return response.data;
    } catch (err) {
        return errorHandling(err);
    }
}