import React, { useEffect, useState } from 'react';
import { Typography, Box, Paper, CircularProgress, CardActionArea } from '@mui/material';
import Roadmap from './Roadmap';
import { handleHealthStatus } from '../../Sprint/utils/statusColor';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions/action';
import CycleSelector from '../../Sprint/utils/HeaderButtons/CycleSelector';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { isMobile } from "react-device-detect";
import { i18n } from '../../../translate/i18n';
import healthScoreBlurry from '../../../assets/healthscore_blurry.png';


const borderRadius = "1.5rem";

const styles = {
  box : {
    padding: "1rem",
    display: "flex", 
    alignItems: "center", 
    gap: "2rem", 
    borderRadius: "12px", 
    height: isMobile ? "100px" : "150px", 
    border: "1px solid #E3E3E3"
  }
}

const Strategy = (props) => {
  const history = useHistory();
  const [healthStatus, setHealthStatus] = useState(handleHealthStatus(""));
  const [activitiesNotifications, setActivitiesNotifications] = useState(0);
  const [checklistNotifications, setChecklistNotifications] = useState(0);

  useEffect(() => {
    props.getHealthScore(props.cycle)
  }, [props.cycle])

  useEffect(() => {
    setHealthStatus(handleHealthStatus(props.health));
  }, [props.health]);

  useEffect(() => {
    setActivitiesNotifications(props.notifications?.activitiesNotifications || 0)
    setChecklistNotifications(props.notifications?.checklistNotifications?.customer || 0)
}, [props.notifications]);

  return (
    <Box mt="2.5rem">
      <Box display="none">
        <CycleSelector />
      </Box>
      <Typography variant="h4" mb="1.5rem">{i18n.t('dashboard.strategy')}</Typography>
      <Box display="flex" gap="1.5rem" flexDirection={isMobile ? "column" : "row"}>
        <Paper sx={{ borderRadius, backgroundColor: "#F3F3F3" }}>
          {
            props.customer?.platform?.includes('sprint') ? (
              <Box
                sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center',  padding: 2}}
              >
                <Typography fontSize={"23px"} fontWeight={"bold"}>{i18n.t('dashboard.healthScore')}</Typography>
                <Box
                  position={"relative"}
                  display={"grid"}
                  alignItems={"center"}
                >
                  <CircularProgress
                    variant="determinate"
                    size={"150px"}
                    value={100}
                    sx={{ color: "#e0e0e0", position: "absolute" }}
                    thickness={3}
                  />
                  <CircularProgress
                    variant="determinate"
                    size={"150px"}
                    value={props.health}
                    sx={{ color: healthStatus }}
                    thickness={3}
                  />
                  <Typography
                    sx={{
                      fontSize: "40px",
                      color: healthStatus,
                      fontWeight: "600",
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  >
                    {props.health}
                  </Typography>
                </Box>
              </Box>
            ) : (
                <Box 
                  sx={{
                    backgroundImage: `url(${healthScoreBlurry})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'right bottom',
                    backgroundSize: 'contain',
                    width: "200px",
                    height:"100%"
                  }}
                />

              )
          }
         
        </Paper>
        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', borderRadius, width: "100%", backgroundColor: "#F3F3F3" }}>
          <Typography fontSize={"23px"} fontWeight={"bold"}>{i18n.t('dashboard.immediateAction')}</Typography>
          <Box display="flex" gap="1.5rem" flexDirection={isMobile ? "column" : "row"}>
            <CardActionArea sx={styles.box} onClick={()=> history.push('/kanban')} disabled={!props.customer?.platform?.includes('sprint')}>
              <Typography fontSize={isMobile ? 50 : 60}>{activitiesNotifications}</Typography>
              <Typography variant={isMobile ? "h7" : "h5"}>{i18n.t('dashboard.activitiesWaitingApproval')}</Typography>
            </CardActionArea>
            <CardActionArea sx={styles.box} onClick={()=> history.push('/action/checklists')} disabled={!props.customer?.platform?.includes('sprint')}>
              <Typography fontSize={isMobile ? 50 : 60}>{checklistNotifications}</Typography>
              <Typography variant={isMobile ? "h7" : "h5"}>{i18n.t('dashboard.checklistItens')}</Typography>
            </CardActionArea>
          </Box>
        </Paper>
      </Box>
      <Paper sx={{borderRadius, mt: "1.5rem"}}>
        <Roadmap />
      </Paper>
    </Box>
  )
}

const mapStateToProps = (state) => ({
  health: state.actions.health,
  user: state.auth.user,
  notifications: state.notifications.notifications,
  cycle: state.actions.cycle,
  customer: state.customer.customerDetails,
});

export default connect(mapStateToProps, actions)(Strategy)