import { Typography, Grid, Box, Icon, Button, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import ActionCard from "./ActionCard";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { queryString } from "../../../utils/String";
import ShowMoreLessButton from "../utils/ShowMoreLessButton";
import { i18n } from "../../../translate/i18n";

const Sections = ({ quadrant, actions, category }) => {
    const history = useHistory();
    const [sectionActions, setSectionActions] = useState(
        actions?.filter((action) => action.quadrant === quadrant)
    );
    const [filteredActions, setFilteredActions] = useState([]);

    const pageRedirect = (q) => {
        const query = queryString({ category, quadrant: q });
        history.push(`/new-action?${query}`);
    };

    useEffect(() => {
        const actionsForQuadrant = actions?.filter(
            (action) => action.quadrant === quadrant
        );
        setSectionActions(actionsForQuadrant);
        setFilteredActions(actionsForQuadrant?.slice(0, 4));
    }, [actions]);

    const handleQuadrant = (quadrant) => {
        switch (quadrant) {
            case "Descoberta": return i18n.t('sprint.strategy.actionCardArea.discovery');
            case "Conteúdo": return i18n.t('sprint.strategy.actionCardArea.content');
            case "Próxima Compra": return i18n.t('sprint.strategy.actionCardArea.nextPurchase');
            case "Barreiras de Compra": return i18n.t('sprint.strategy.actionCardArea.buyingBarriers');
            case "Análise de experiência": return i18n.t('sprint.strategy.actionCardArea.expAnalysis');
            case "Consideração": return i18n.t('sprint.strategy.actionCardArea.consideration');
            case "Rastreamento": return i18n.t('sprint.strategy.actionCardArea.tracking');
            case "Contexto": return i18n.t('sprint.strategy.actionCardArea.context');
            case "Ofertas": return i18n.t('sprint.strategy.actionCardArea.offers');
            case "Transmissão": return i18n.t('sprint.strategy.actionCardArea.transmission');
            case "Prospecção": return i18n.t('sprint.strategy.actionCardArea.prospecting');
            case "Benefício": return i18n.t('sprint.strategy.actionCardArea.benefit');
            case "Recuperação": return i18n.t('sprint.strategy.actionCardArea.recovery');
            case "Autoridade": return i18n.t('sprint.strategy.actionCardArea.authority');
            case "Segmentos": return i18n.t('sprint.strategy.actionCardArea.segments');
            default: return "";
        }
    }

    return (

        <Box key={quadrant} sx={{ bgcolor: "#F0F0F0", padding: 1 }}>
            <Box
                sx={{
                    bgcolor: "#E7E7E7",
                    borderRadius: "5px",
                    paddingBottom: "5px",
                }}
            >
                <Box sx={{ justifyContent: "space-between", display: "flex" }}>
                    <Typography
                        alignSelf={"center"}
                        sx={{ padding: "0.5rem" }}
                        variant="h7"
                    >
                        {handleQuadrant(quadrant)}
                    </Typography>
                </Box>
                {filteredActions?.length ? (
                    filteredActions.map((action, i) => (
                        <ActionCard key={action._id} action={action} />
                    )).concat(
                        <Box padding={"5px"} paddingTop={"0"}>
                            <ShowMoreLessButton
                                originalArray={sectionActions}
                                setDisplayArray={setFilteredActions}
                                displayArray={filteredActions}
                            />
                        </Box>
                    )
                        .concat(
                            <Button
                                onClick={pageRedirect}
                                className="btn-default"
                                size="small"
                                fullWidth
                            >
                                {i18n.t('sprint.strategy.actionCardAreaMobile.newAction')}
                            </Button>
                        )
                ) : (
                    <Box display={"grid"} justifyItems={"center"} padding={2}>
                        <Icon
                            className="material-icons-outlined"
                            sx={{
                                color: "#86888c",
                            }}
                            fontSize="large"
                        >
                            warning
                        </Icon>
                        <Typography textAlign={"center"} variant="body2" marginBottom={1}>
                            {i18n.t('sprint.strategy.actionCardArea.dontCreate')}
                        </Typography>
                        <Button
                            onClick={() => pageRedirect(quadrant)}
                            className="btn-default"
                        >
                            {i18n.t('sprint.strategy.actionCardArea.createNow')}
                        </Button>
                    </Box>
                )}
            </Box>
        </Box>
    )
}

const ActionCardAreaMobile = ({ quadrant, category, actions }) => {

    return (

        quadrant.map((elem) => {
            return (
                <Sections
                    key={elem}
                    quadrant={elem}
                    category={category}
                    actions={actions}
                />
            );

        })

    );
};

export default ActionCardAreaMobile;
