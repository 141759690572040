import React, { useEffect, useState } from 'react'
import AppTabs from '../utils/AppTabs'
import { Box, Typography } from '@mui/material'
import { connect } from 'react-redux';
import * as actions from '../../../store/actions/apps'
import { i18n } from '../../../translate/i18n';

const ExtendedCheckout = (props) => {

  useEffect(()=>{
    props.getApp("category=Checkout Ampliado")
  }, [])


  return (
    <Box className="dashboard">
      <Typography variant={"h4"}>{i18n.t('apps.extendedCheckout.extendedCheckout')}</Typography>
      <AppTabs category={i18n.t('apps.extendedCheckout.extendedCheckout')} notifications={{}}/>
    </Box>
  )
}

const mapStateToProps = (state) => ({
  app: state.app
});

export default connect(mapStateToProps, actions)(ExtendedCheckout)