import React from 'react'
import { styled } from '@mui/material/styles';
import { Card, Typography, Grid, Avatar } from '@mui/material';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import PollOutlinedIcon from '@mui/icons-material/PollOutlined';

const BorderLinearProgress = styled(LinearProgress)(({ theme, colorBar }) => ({
    height: 5,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: "#5048E5"
    },
  }));

const CardProcess = (props) => {
    return (
        <Card className="card_progress" {...props}>
            <Grid container spacing={2}>
            <Grid item xs={8}>
            <Typography sx={{fontSize: '12px'}} variant="p">{props.name}</Typography>
            <Typography variant="h4">90%</Typography>
            </Grid>
            <Grid align="right" item xs={4}>
              <Avatar sx={{ bgcolor: props.color }}>
                <PollOutlinedIcon />
              </Avatar>
            </Grid>
            </Grid>
            <br />
            <BorderLinearProgress colorBar={props.color} variant="determinate" value={90} />
            <br />
        </Card>     
    )
}

export default CardProcess;