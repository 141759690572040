import React, { useState, useEffect } from "react";

import { TextField, TableCell, TableRow, TableBody, Table, Button, Typography } from '@mui/material';
import { connect } from 'react-redux'
import { getTasks, handleUpdateTask, handleAddTask, deleteTask } from '../../../store/actions/tasks'
import { toast } from 'react-toastify';
import { Icon } from '@iconify/react';
import { i18n } from '../../../translate/i18n';

function Task(props) {
    const [inputValue, setInputValue] = useState('');

    const [rows, setRows] = useState([]);

    useEffect(() => {
        props.getTasks()
    }, []);

    useEffect(() => {
        setRows(props.tasks || [])
    }, [props.tasks]);

    const handleSave = (id, name) => {
        try {
            const resp = props.updateTask({ _id: id, name });
            if (resp.error) {
                switch (resp.error.response) {
                    case "Ocorreu um erro ao atualizar o tarefa.": return  toast.error(i18n.t('start.task.updateError'))
                    case "Erro interno do sistema": return toast.error(i18n.t('start.task.internalError'));
                    default: return toast.error(resp.error.response)
                }
            } else {
                if (resp.message === "Tarefa atualizada com sucesso!") {
                    toast.success(i18n.t('start.task.updateSuccess'));
                } else {
                    toast.success(resp.message)
                }
            }
        } catch (error) {
            if (error.error.response === "Erro interno do servidor.") {
                toast.error(i18n.t('start.task.internalError'));
            } else {
                toast.error(error.error.message)
            }
        }
    };

    const handleTextFieldChange = (event) => {
        setInputValue(event.target.value);
    };

    const handleDelete = (index, id) => {
        props.deleteTask(id);
        setRows(rows.filter((row, i) => i !== index));
    };

    const handleAdd = async () => {
        const task = await props.addTask({ name: "Nome da tarefa" });
        setRows([...rows, { name: "Nome da tarefa", _id: task._id }]);
    };

    return (
        <>

            <div className="d-flex">
                <div className="mr-auto p-2">
                    <Typography variant="h1">{i18n.t('start.task.tasks')}</Typography>
                </div>
            </div>
            <Table>
                <TableBody>
                    {rows?.map((row, index) => (
                        <TableRow key={row.id}>
                            <TableCell style={{ width: "90%" }}>
                                <TextField
                                    defaultValue={row.name}
                                    fullWidth
                                    placeholder={i18n.t('start.task.taskName')}
                                    onChange={handleTextFieldChange}
                                    id={`textField-${index}`}
                                    onClick={handleTextFieldChange}
                                    onKeyDown={(event) => {
                                        if (event.key === 'Enter') {
                                            handleSave(row._id, inputValue);
                                            const nextTextField = document.getElementById(`textField-${index + 1}`);
                                            if (nextTextField) {
                                                nextTextField.focus();
                                            }
                                            event.preventDefault();
                                        }
                                    }}
                                />
                            </TableCell>
                            <TableCell align="right">
                                <Button onClick={() => handleSave(row._id, inputValue)}><Icon width="25" icon="material-symbols:save" /></Button>
                                <Button onClick={() => handleDelete(index, row._id)}><Icon width="25" icon="material-symbols:delete" /></Button>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <Button onClick={handleAdd}>{i18n.t('start.task.add')}</Button></>
    );
}


const mapStateToProps = state => ({
    tasks: state.task.tasks
})


const mapDispatchToProps = (dispatch) => {
    return {
        deleteTask: (id) => dispatch(deleteTask(id)),
        getTasks: () => dispatch(getTasks()),
        updateTask: (data) => dispatch(handleUpdateTask(data)),
        addTask: (data) => dispatch(handleAddTask(data))
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(Task);