import React, { useCallback, useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

import { Container, Button, Grid, Typography, Accordion, TextField, InputLabel, FormControl, Select, MenuItem, FormControlLabel, Checkbox, AccordionSummary, AccordionDetails, Divider, useMediaQuery } from '@mui/material';

import { getActionChecklists, handleAddActionChecklist, getActionChecklist, deleteActionChecklist, handleUpdateActionChecklist, handleUpdateActionChecklistOrder } from '../../../store/actions/checklist'
import '../../../styles/dashboard/start/checklist.css'
import { Icon } from '@iconify/react';
import { getAction, putAction } from '../../../store/actions/action';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { i18n } from '../../../translate/i18n';
import Loading from '../utils/Loading';

const Create = (props) => {
    const history = useHistory();
    const [form, setForm] = useState(null);
    const [add, setAdd] = useState(null);
    const [data, setData] = useState({});
    const [items, setItems] = useState([]);
    const [itensCheck, setItensCheck] = useState([]);
    const isMobileDevice = useMediaQuery('(max-width:600px)');
    const [loading, setLoading] = useState(false);

    const handleItensCheck = (e, id) => {
        const aux = [...itensCheck];
        const find = aux.find(e => e.field === id);
        if (find) {
            if (find.status === 'd') {
                aux[aux.indexOf(find)].status = 'a';
            } else {
                aux[aux.indexOf(find)].status = 'd';
            }
        } else {
            aux.push({ field: id, checklist: form });
        }
        setItensCheck(aux);
    }

    useEffect(() => {
        props.getAction(props.match.params.id);
        props.getChecklists()
    }, []);

    useEffect(() => {
        if (props.action?.checklist?.length > 0) {
            setItensCheck(props.action?.checklist);
        }
    }, [props.action]);

    useEffect(() => {
        if (props.checklists?.length > 0) {
            setItems(props.checklists?.filter(e => e.parent === "action"));
        }
    }, [props.checklists]);

    const handleForm = async (e) => {
        setForm(e)
        const response = await props.getChecklist(e);
        setData(response.data)
        setAdd(null)
    };

    const save = async () => {
        setLoading(true);
        try {
            const response = await props.updateAction(props.match.params.id, { ...props.action, checklist: itensCheck });
            if (response.error) {
                switch (response.error.message) {
                    case "Ação não existe!": return toast.error(i18n.t('sprint.actionChecklist.create.actionNotExist'));
                    case "Erro na integração com Jira, tente salvar a ação novamente!": return toast.error(i18n.t('sprint.actionChecklist.create.integrationJiraError'));
                    case "Ação não encontrada ou não modificada!": return toast.error(i18n.t('sprint.actionChecklist.create.updateError'));
                    case "Erro interno do servidor.": return toast.error(i18n.t('sprint.actionChecklist.create.internalError'));
                    default: return toast.error(response.message);
                }
            }
            else {
                if (response.message === "Ação atualizada com sucesso!") {
                    toast.success(i18n.t('sprint.actionChecklist.create.updateSuccess'));
                } else {
                    toast.success(response.message);
                }
                const previousPath = localStorage.getItem('previousPath');
                localStorage.removeItem('previousPath');
                history.push(previousPath || '/action/checklists');
            }
        } catch (error) {
            if (error.error.message === "Erro interno do servidor.") {
                toast.error(i18n.t('sprint.actionChecklist.create.internalError'));
            } else {
                toast.error(error.error.message);
            }
        }

        setLoading(false);
    }

    return (
        <div class='body'>
            <Loading loading={loading} />
            <Grid style={{ borderTop: '1px solid silver', height: '100%' }} container>
                <Grid item xs={3} className="menu">
                    <Grid container className='header'>
                        {
                            isMobileDevice ? (
                                <>
                                    <small style={{ padding: 0 }}>{i18n.t('sprint.actionChecklist.create.checklist')}</small>
                                    {
                                        !isMobileDevice && (
                                            <h3 >{props.action?.name}</h3>
                                        )
                                    }
                                    <Button className='saveButton' onClick={() => save()}>{i18n.t('sprint.actionChecklist.create.save')}</Button>

                                </>
                            ) : (
                                <>
                                    <Grid item xs={8}>
                                        <small style={{ padding: 0 }}>{i18n.t('sprint.actionChecklist.create.checklist')}</small>
                                        <h3>{props.action?.name}</h3>
                                    </Grid>

                                    <Grid className='alignCenterVertical' item xs={4} justifyContent='right'>
                                        <Button className='saveButton' onClick={() => save()}>{i18n.t('sprint.actionChecklist.create.save')}</Button>
                                    </Grid>
                                </>
                            )
                        }
                    </Grid>
                    <div className={isMobileDevice ? 'item-mobile' : 'item'}>
                        <h3>{i18n.t('sprint.actionChecklist.create.checklistAction')}</h3>
                    </div>
                    <div className='subitens'>
                        {items.map((item, index) => {
                            const selects = itensCheck.filter(e => e.checklist === item._id && e.status !== 'd').length;
                            return (
                                <div className={`${isMobileDevice ? 'subitem-mobile' : 'subitem'} ${form === item._id ? 'selected' : ''}`} onClick={() => handleForm(item._id)}>
                                    <Grid container>
                                        <Grid item xs={isMobileDevice ? 6 : 2} className='alignCenterVertical'>
                                            <Icon icon={item.icon} width='30px' />
                                        </Grid>
                                        {
                                            !isMobileDevice && (
                                                <Grid item xs={8} className='alignCenterVertical'>
                                                    <h4 className='break-word'>{item.title}</h4>
                                                </Grid>
                                            )
                                        }
                                        <Grid item xs={isMobileDevice ? 6 : 2} className='alignCenterVertical' justifyContent={isMobileDevice ? 'center' : 'right'}>
                                            <small>{selects}/{item.total}</small>
                                        </Grid>
                                    </Grid>
                                </div>
                            )
                        })}
                    </div>
                </Grid>
                <Grid item xs={9}>
                    <Container className={isMobileDevice ? 'p-3' : 'p-5'} style={{ heigth: '100%' }}>
                        {data?._id || add ?
                            <>
                                <Grid container columns={20}>
                                    <Grid textAlign='center' xs={1}>
                                        {
                                            !isMobileDevice && (
                                                <Icon style={{ cursor: 'pointer' }} icon={data.icon} width='30px' />
                                            )
                                        }                                      </Grid>
                                    <Grid xs={19}><h3>{data.title}</h3><p>{data.subtitle || <small>{i18n.t('sprint.actionChecklist.create.subtitle')}</small>}</p></Grid>
                                </Grid>
                                {data.fields && data.fields.map((field, index) => (
                                    <Accordion
                                    >
                                        <AccordionSummary expandIcon={<Icon onClick={(e) => {
                                            e.stopPropagation();
                                            handleItensCheck(e, field._id)
                                        }} icon={itensCheck.find(e => e.field === field._id && e.status !== 'd') ? 'material-symbols:radio-button-checked-outline' : 'ic:baseline-radio-button-unchecked'} color={itensCheck.find(e => e.field === field._id && e.status !== 'd') ? 'green' : 'grey'} width='30px' />}>
                                            <Typography>{field.title}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography>
                                                <Grid container spacing={2} rowSpacing={2}>
                                                    <Grid item xs={12} md={4}>
                                                        <TextField disabled
                                                            size='small' fullWidth value={field.title} label={i18n.t('sprint.actionChecklist.create.title')} />
                                                    </Grid>
                                                    <Grid item xs={12} md={4}>
                                                        <TextField disabled
                                                            size='small' fullWidth value={field.helperText} label={i18n.t('sprint.actionChecklist.create.helperText')} />
                                                    </Grid>
                                                    <Grid item xs={12} md={4}>
                                                        <TextField disabled
                                                            size='small' value={field.placeholder} fullWidth label={i18n.t('sprint.actionChecklist.create.placeHolder')} />
                                                    </Grid>
                                                    <Grid item xs={12} md={4}>
                                                        <FormControl fullWidth>
                                                            <InputLabel>{i18n.t('sprint.actionChecklist.create.type')}</InputLabel>
                                                            <Select
                                                                disabled
                                                                size='small'
                                                                value={field.type}
                                                                label={i18n.t('sprint.actionChecklist.create.type')}
                                                            >
                                                                <MenuItem value='text'>{i18n.t('sprint.actionChecklist.create.text')}</MenuItem>
                                                                <MenuItem value={'textarea'}>{i18n.t('sprint.actionChecklist.create.textArea')}</MenuItem>
                                                                <MenuItem value={'number'}>{i18n.t('sprint.actionChecklist.create.count')}</MenuItem>
                                                                <MenuItem value={'money'}>{i18n.t('sprint.actionChecklist.create.money')} (R$)</MenuItem>
                                                                <MenuItem value={'select'}>{i18n.t('sprint.actionChecklist.create.uniqueList')}</MenuItem>
                                                                <MenuItem value={'multiselect'}>{i18n.t('sprint.actionChecklist.create.multipleList')}</MenuItem>
                                                                <MenuItem value={'url'}>{i18n.t('sprint.actionChecklist.create.url')}</MenuItem>
                                                                <MenuItem value={'date'}>{i18n.t('sprint.actionChecklist.create.date')}</MenuItem>
                                                                <MenuItem value={'email'}>{i18n.t('sprint.actionChecklist.create.email')}</MenuItem>
                                                                <MenuItem value={'radio'}>{i18n.t('sprint.actionChecklist.create.radio')}</MenuItem>
                                                                <MenuItem value={'checkbox'}>{i18n.t('sprint.actionChecklist.create.checkbox')}</MenuItem>
                                                                <MenuItem value={'attachment'}>{i18n.t('sprint.actionChecklist.create.attachment')}</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item xs={12} md={4}>
                                                    </Grid>
                                                    <Grid item xs={12} md={4}>
                                                        <FormControlLabel disabled control={<Checkbox checked={field.required} />} label={i18n.t('sprint.actionChecklist.create.mandatory')} />
                                                    </Grid>

                                                    {field.type === 'select' || field.type === 'multiselect' || field.type === 'radio' ? (
                                                        <Grid item xs={12}>
                                                            <p><b>{i18n.t('sprint.actionChecklist.create.options')}</b></p>
                                                            <Grid spacing={2} container rowSpacing={2}>
                                                                {field.options && field.options.map((option, indexOpt) =>
                                                                    <Grid item xs={12} md={4}>
                                                                        <TextField
                                                                            size='small' InputProps={{
                                                                            }} value={option} disabled fullWidth />
                                                                    </Grid>
                                                                )}
                                                            </Grid>
                                                        </Grid>
                                                    ) : <></>}
                                                </Grid>
                                            </Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                ))}
                            </>
                            : <>{i18n.t('sprint.actionChecklist.create.selectItem')}</>}
                    </Container>
                </Grid>
            </Grid>
        </div >
    );
};

const mapStateToProps = state => ({
    action: state.actions.action,
    checklists: state.checklist.checklists,
    customer: state.customer.customerDetails
})


const mapDispatchToProps = (dispatch) => {
    return {
        getAction: (id) => dispatch(getAction(id)),
        updateAction: (params, props) => dispatch(putAction(params, props)),
        getChecklists: () => dispatch(getActionChecklists()),
        handleAddChecklist: (data) => dispatch(handleAddActionChecklist(data)),
        getChecklist: (id) => dispatch(getActionChecklist(id)),
        deleteChecklist: (id) => dispatch(deleteActionChecklist(id)),
        handleUpdateChecklist: (data) => dispatch(handleUpdateActionChecklist(data)),
        handleUpdateChecklistOrder: (data) => dispatch(handleUpdateActionChecklistOrder(data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Create);
