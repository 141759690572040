import React from 'react';
import { Stack, Grid } from '@mui/material';
import DataRange from '../Date/DateRange';
import { i18n } from '../../translate/i18n';
import { getLabelFilterStatus } from '../../utils/statusPlatinum';
import { FCSelect, FCTextField } from 'components-fullcomm';

const options = [
    { key: 3, value: 'Aguardando WMS' },
    { key: 8, value: 'Aguardando Nota Fiscal' },
    { key: 5, value: 'Aguardando Picking' },
    { key: 30, value: 'Aguardando Checkout' },
    { key: 40, value: 'Pronto para Coleta' },
    { key: 50, value: 'Despachado' },
    { key: 70, value: 'Em trânsito' },
    { key: 75, value: 'Saiu para entrega' },
    { key: 90, value: 'Entregue' },
    { key: 13, value: 'Cancelado' },
    { key: 9001, value: 'Atrasado na Integração' },
    { key: 9002, value: 'Atrasado na Entrega' },
    { key: 9003, value: 'Entrega hoje' },
    { key: 80, value: 'Com ocorrência' }
]

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const FilterBar = ({ begin, setBegin, end, setEnd, search, setSearch, selectedStatus, setSelectedStatus }) =>
(
    <div className='padding'>
        <Grid container columnSpacing={2} rowSpacing={{ xs: 1, sm: 0 }}>
            <Grid item xs={12} sm={4} >
                <Stack spacing={2} direction="row">
                    <DataRange begin={begin} setBegin={setBegin} end={end} setEnd={setEnd} />
                </Stack>
            </Grid>
            <Grid item xs={6} sm={4} display='flex' alignItems='end'>
                <FCTextField placeholder={i18n.t('components.platinumlog.filterBar.search')} value={search} onChange={(e) => setSearch(e.target.value)} fullWidth
                    searchIcon />
            </Grid>
            <Grid item xs={6} sm={4}>
                <FCSelect
                    fullWidth
                    multiple
                    value={selectedStatus}
                    onChange={(e) => setSelectedStatus(e.target.value)}
                    label={i18n.t('components.platinumlog.filterBar.status')}
                    renderValue={(selected) => {
                        const selectedItems = options.filter(item => selected.indexOf(item.key) > -1);
                        return selectedItems.map(item => getLabelFilterStatus(item.value)).join(', ');
                    }}
                    options={
                        options.map((item) => (
                            { value: item.key, label: getLabelFilterStatus(item.value) }
                        ))
                    }
                />
            </Grid>
        </Grid>
    </div>
);

export default FilterBar;