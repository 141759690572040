import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Divider, Grid, Paper, Typography, Tooltip, Chip, IconButton } from '@mui/material';
import { Box } from '@mui/system';
import HelpIcon from '@mui/icons-material/Help';
import * as actions from '../../../../../store/actions/activities';
import { debounce } from 'lodash';
import { isMobile } from 'react-device-detect';
import ActivityAccordion from './ActivityAccordion';
import { statusColor } from '../../../utils/statusColor';
import CloseIcon from '@mui/icons-material/Close';
import { useField } from 'formik';
import { i18n } from '../../../../../translate/i18n';
import { handleChangeName, handleChangeCategory, handleChangeMeasurement, handleChangeDescription, handleOptions } from '../../../../../translate/translates/Translates';
import { FCCheckbox, FCPagination, FCSelect, FCTextField } from 'components-fullcomm';

const categoryOptions = [
  "CRO",
  "Desenv. de Interface",
  "Design de Interface",
  "Implantação",
  "Relatórios",
  "SEO",
  "Setup de Marketing",
  "Tradução",
  "UI & UX",
];


const ActivityRow = ({ activity, addAmount, selectedActivities, disabled, shrink }) => {
  const [amount, setAmount] = useState(selectedActivities?.find((obj) => obj?.activity === activity._id)?.repeat || 0);

  useEffect(() => {
    setAmount(selectedActivities?.find((obj) => obj?.activity === activity._id)?.repeat || 0);
  }, [selectedActivities]);

  return (
    <Grid container key={activity.name} alignItems={'center'} padding={2}>
      <Grid item md={8} xs={12} style={(isMobile || shrink) ? {marginBottom: '10px'} : {}}>
        <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center' }}>
          {handleChangeCategory(activity.category)}
          <Tooltip title={handleChangeDescription(activity.description)}>
            <HelpIcon sx={{ ml: '5px', width: '15px' }} />
          </Tooltip>
        </Typography>
        <Typography variant={'body1'}>{handleChangeName(activity.name)}</Typography>
      </Grid>
      <Grid item md={2} xs={6} textAlign={'center'}>
        <Typography>{activity?.creditsCost}</Typography>
        <Typography variant={'caption'}>{handleChangeMeasurement(activity?.measurement)}</Typography>
      </Grid>
      <Grid item md={2} xs={6} textAlign={'center'}>
        <FCTextField
          disabled={disabled}
          size="small"
          type='number'
          sx={{ width: "4rem" }}
          InputProps={{
            sx: {
              height: '100%',
              '> *:first-of-type': {
                padding: '0.5rem',
              },
            },
          }}
          value={amount}
          onChange={(e) => {
            addAmount(e.target.value, activity._id)
            if(e.target.value > 500) setAmount(500);
            else if(e.target.value < 0) setAmount(0);
            else setAmount(e.target.value);
          }}
        />
      </Grid>
    </Grid>
  );
};

const getAvailableCredits = (contract, cycle, platform) => {
  if (platform === 'sprint') {
    const credits = contract?.credits?.find((credit) => credit.cycle === cycle)?.amount || contract?.monthlyCredits * 3;
    return credits;
  }
  const { creditsUsedMonthly = [] } =  contract
  return contract?.monthlyCreditsSupport - creditsUsedMonthly[creditsUsedMonthly.length -1]?.amount || 0;
};

const activitiesCost = (selectedActivities, localActivities) => {
  const total = localActivities.reduce((acc, activity) => {
    const index = selectedActivities.findIndex((obj) => obj.activity === activity._id);
    const amount = selectedActivities[index]?.repeat;
    return acc + activity.creditsCost * amount;
  }, 0);
  return total;
}

const Activities = (props) => {
  const { shrink = false } = props;
  const [requireEstimate, , requireEstimateHelpers] = useField('requireEstimate');
  const [search, setSearch] = useState('');
  const [status, setStatus] = useState('mostPopular');
  const [options, setOptions] = useState([]);
  const limit = shrink ? 5 : 10;
  const [currentPage, setCurrentPage] = useState(0);
  const [activities, setActivities] = useState([]);
  const [availableCredites, setAvailableCredites] = useState(getAvailableCredits(props.contract, props.cycle, props.platform));

  const clearActivity = (id) => {
    props.setSelectedActivities(prevActivities => {
      const arr = prevActivities.filter((obj) => obj.activity != id);
      return arr;
    });
  };

  useEffect(() => {
    let isSorted = "";
    let category = status
    if (status === 'mostPopular') {
      isSorted = true;
      category = props.values?.type === "Implementação" ? categoryOptions?.join(', ') : "all";
    }
    props.getActivitiesByVersion(-1, {platform: props.customer?.platformEcommerce || '', querySearch: search, category, page: currentPage, limit, sort: props.values?.type, isSorted });
  }, [currentPage]);

  useEffect(() => {
    setAvailableCredites(getAvailableCredits(props.contract, props.cycle, props.platform));
  }, [props.contract, props.cycle, props.platform]);

  useEffect(() => {
    setActivities(props.activities?.docs);
    setOptions(props.values?.type === "Implementação" ? categoryOptions : props.categories);
  }, [props.activities, props.categories]);

  const handleSearch = useCallback(
    debounce(async (search, status, platform = '', sort) => {
      let isSorted = "";
      if (status === 'mostPopular') {
        isSorted = true;
        status = props.values?.type === "Implementação" ? categoryOptions?.join(', ') : "all";
      }
      await props.getActivitiesByVersion(-1, {platform, querySearch: search, category: status, page: currentPage, limit, sort, isSorted });
      setCurrentPage(0);
    }, 1000),
    [props.customer]
  );

  const updateSelectedActivities = (prevActivities, id, length) => {
    const index = prevActivities.findIndex((obj) => obj.activity === id);
    const updatedActivities = [...prevActivities];
  
    if (index === -1) {
      if (length > 0) updatedActivities.push({ activity: id, repeat: length, responsible: "FullPRO" });
    } else {
      if (length > 0) {
        updatedActivities[index].repeat = length;
      } else {
        updatedActivities.splice(index, 1);
      }
    }
    return updatedActivities;
  };
  
  const addAmount = (value, id) => {
    let length = Math.min(Math.max(Number(value), 0), 500);
    const updatedActivities = updateSelectedActivities(props.selectedActivities, id, length);
    props.setSelectedActivities(updatedActivities);
  };
  
  useEffect(() => {
    handleSearch(search, status, props.customer?.platformEcommerce, props.values?.type);
  }, [handleSearch, search, status]);

  useEffect(() => {
    if (requireEstimate.value) {
      props.setSelectedActivities([]);
      props.setLocalActivities([]);
    }
  }, [requireEstimate.value]);

  useEffect(() => {
    const total = props.localActivities.reduce((acc, activity) => {
      const index = props.selectedActivities.findIndex((obj) => obj.activity === activity._id);
      const amount = props.selectedActivities[index]?.repeat;
      return acc + activity.creditsCost * amount;
    }, 0);
    props.setFieldValue('credits', total || 0);
  }, [props.selectedActivities, props.localActivities]);

  return (
    <Box display="flex" flexDirection={(isMobile || shrink) ? "column" : "row"} gap="2rem">
      <Box width={(isMobile || shrink) ? "100%" : "50%"} mt="1rem">
        <Paper 
          elevation={shrink ? 0 : 3}
          sx={{display: "flex", flexDirection: "column", gap: "1rem", padding: "1rem", borderRadius: "5px"}}
        >
        <Typography variant="h4" sx={{ marginBottom: '0.5rem' }}>{i18n.t('sprint.newAction.pages.activities.availableActivities')}</Typography>
          <Grid container spacing={2}>
            <Grid item xs={6} display='flex' alignItems='end'>
              <FCTextField
                size={(isMobile || shrink) ? 'small' : 'medium'}
                fullWidth
                value={search}
                placeholder={i18n.t('sprint.newAction.pages.activities.search')}
                onChange={(e) => setSearch(e.target.value)}
                searchIcon
              />
            </Grid>
            <Grid item xs={6}>
              <FCSelect
                size={(isMobile || shrink) ? 'small' : 'medium'}
                fullWidth
                label={i18n.t('sprint.newAction.pages.activities.categories')}
                options={options?.map((option) => ({ value: option, label: handleOptions(option) }))}
                value={status}
                onChange={(e) => setStatus(e.target.value)}
              />
            </Grid>
          </Grid>
          <Paper sx={{ background: "#FAFAFA" }}>
            {!(isMobile || shrink) &&
              <Grid container paddingX={2} paddingTop={2}>
                <Grid item xs={6} lg={8}></Grid>
                <Grid item xs={3} lg={2}>
                  <Typography variant="h5" textAlign={'center'}>
                  {i18n.t('sprint.newAction.pages.activities.credits')}
                  </Typography>
                </Grid>
                <Grid item xs={3} lg={2}>
                  <Typography variant="h5" textAlign={'center'}>
                  {i18n.t('sprint.newAction.pages.activities.quantity')}
                  </Typography>
                </Grid>
              </Grid>
            }
            {activities?.map((activity, i) => {
              return (
                <div key={activity._id}>
                  <ActivityRow
                    activity={activity}
                    addAmount={addAmount}
                    selectedActivities={props.selectedActivities}
                    disabled={requireEstimate.value}
                    shrink={shrink}
                  />
                  {activities.length > i + 1 && <Divider />}
                </div>
              );
            })}
            <Box display={'grid'} alignItems={'center'} justifyContent={'center'} padding={1}>
              <FCPagination
                count={props.activities?.totalPages || 1}
                page={currentPage + 1} 
                onChange={(e, page) => {
                  setCurrentPage(page - 1);
                }}
              />
            </Box>
        </Paper>
      </Paper>
      </Box>
      <Box width={(isMobile || shrink) ? "100%" : "50%"} mt="1rem">
        <Paper
          sx={{display: "flex", flexDirection: "column", gap: "1rem", padding: "1rem", borderRadius: "5px"}}
        >
          <Box display="flex" justifyContent="space-between" alignItems="center" mb={'0.5rem'}>
            <Typography variant="h4">{i18n.t('sprint.newAction.pages.activities.activitiesAdded')}</Typography>
            {
              activitiesCost(props.selectedActivities, props.localActivities) > availableCredites && (
                <Typography variant="caption" color="error.main">{i18n.t('sprint.newAction.pages.activities.creditsLimitReached')}</Typography>
              )
            }
          </Box>
          {
            props.values.type !== "Melhoria" && props.selectedActivities?.length === 0 && (
              <FCCheckbox
                label={i18n.t('sprint.newAction.pages.activities.requestEstimate')}
                checked={requireEstimate.value}
                onChange={() => requireEstimateHelpers.setValue(!requireEstimate.value)}
              />
            )
          }
          {props.localActivities?.map((activity, i) => {
            const index = props.selectedActivities.findIndex((obj) => obj.activity === activity._id);
            const { repeat, status } = props.selectedActivities[index] || { repeat: 0, status: ''};
            return (
              <div key={activity._id}>
                <Box padding={"1rem"} display={"flex"} flexDirection="column" gap={"0.25rem"}
                  position="relative">
                  {!props.disabled &&
                    <IconButton
                      onClick={() => clearActivity(activity._id)}
                      sx={{
                        position: "absolute",
                        top: "-1rem",
                        right: 0,
                        color: "gray",
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  }
                  <Box>
                    <Box display="flex" flexDirection={(isMobile) ? "column" : "row"} justifyContent="space-between" alignItems={(isMobile || shrink) ? "start" : "center"}>
                      <Box>
                        <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center' }}>
                          {handleChangeCategory(activity.category)}
                        </Typography>
                        <Typography variant={'body1'}>{handleChangeName(activity.name)}</Typography>
                      </Box>
                      <Box display="flex" gap="0.5rem">
                        <Box display="grid" justifyItems="center">
                          <Typography variant='h6'>{i18n.t('sprint.newAction.pages.activities.credits2')}</Typography>
                          <Typography variant='h6'>{activity?.creditsCost * repeat}</Typography>
                        </Box>
                        <Box display="grid" justifyItems="center">
                        <Typography variant='h6'>{i18n.t('sprint.newAction.pages.activities.quantity2')}</Typography>
                          <FCTextField
                            size="small"
                            type='number'
                            sx={{ width: "4rem" }}
                            InputProps={{
                              sx: {
                                height: '100%',
                                '> *:first-of-type': {
                                  padding: '0.5rem',
                                },
                              },
                            }}
                            value={repeat}
                            onChange={(e) => {
                              addAmount(e.target.value, activity._id)
                            }}
                          />
                        </Box>
                      </Box>
                    </Box>
                    {
                      status && (
                        <Chip
                        size="small"
                        color={statusColor(status).type}
                        label={status}
                        />
                      )
                    }
                  </Box>
                  <ActivityAccordion
                    amount={repeat}
                    activity={activity}
                    selectedActivities={props.selectedActivities}
                    setSelectedActivities={props.setSelectedActivities}
                  />
                </Box>
                {props.localActivities.length > i + 1 && <Divider />}
              </div>
            )
          })}
        </Paper>
      </Box>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  customer: state.customer.customerDetails,
  activities: state.activities?.activities,
  categories: state.activities?.categories,
  user: state.auth.user,
  contract: state.contract.contractDetails,
  cycle: state.actions.cycle,
});

export default connect(mapStateToProps, actions)(Activities);
