import React, { useEffect, useState } from 'react'
import { Box, Paper, Tab, Typography } from '@mui/material'
import Start from './Start';
import { connect } from 'react-redux';
import * as scaleActions from '../../../store/actions/scale';
import * as customerActions from '../../../store/actions/customers';
import Orders from './Orders';
import { i18n } from '../../../translate/i18n';
import { FCSelect, FCTabs } from 'components-fullcomm';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      style={{ width: "100%", paddingTop: "1rem" }}
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Paper>
          {children}
        </Paper>
      )}
    </div>
  );
}

const Logistics = (props) => {
  const [value, setValue] = useState(0);
  const [view, setView] = useState('cliente');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    props.fetchToken();
    props.getViewCustomer(props.shop);
  }, [props.shop])

  return (
    <Box padding="1rem 1.5rem">
      <Box display='flex' justifyContent='space-between'>
        <Typography variant='h4' mb="1rem">{i18n.t('scale.logistics.logistic')}</Typography>
        {
          props.user?.role === 'admin' && (
            <FCSelect
              value={view}
              onChange={(e) => setView(e.target.value)}
              options={[
                { value: 'cliente', label: 'Cliente Atual'},
                { value: 'admin', label: 'Visão Geral (admin)'}
              ]}
            />
          )
        }
      </Box>
        <FCTabs
          value={value} 
          onChange={handleChange}               
        >
          <Tab label={i18n.t('scale.logistics.tabInit')}/>
          <Tab label={i18n.t('scale.logistics.tabOrders')}/>
        </FCTabs>
        <TabPanel value={value} index={0}>
          <Start view={view} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Orders view={view} />
        </TabPanel>
    </Box>
  )
}

const mapStateToProps = (state) => ({
  shop: state.auth.shop,
  user: state.auth.user,
})

export default connect(mapStateToProps, {...scaleActions, ...customerActions})(Logistics)