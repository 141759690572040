import React, { useEffect, useState, useCallback } from 'react';
import Dropzone, { useDropzone } from "react-dropzone";
import { styled } from '@mui/material/styles';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { FormControl, Button, Container, Grid, Typography, CircularProgress, InputAdornment, Box, TextField, InputLabel, FormGroup, Select, MenuItem, FormControlLabel, Checkbox, RadioGroup, Radio, useMediaQuery, } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { api } from '../../../config';
import '../../../styles/dashboard/start/checklist.css'
import { Icon } from '@iconify/react';
import { FlareSharp } from '@mui/icons-material';
import { getViewActionChecklist, handleUpdateActionChecklist } from '../../../store/actions/action';
import { i18n } from '../../../translate/i18n';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 8,
    borderRadius: 4,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: "#008060"
    },
}));

const useStyles = makeStyles({
    dropzone: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        padding: '20px 10px',
        border: "2px dashed gray",
        borderRadius: "5px",
        backgroundColor: "#fafafa",
        outline: "none",
        transition: "border .24s ease-in-out",
        cursor: "pointer",
        "&:hover": {
            borderColor: "#008060"
        }
    }
});

const Inicial = (props) => {
    const [uploads, setUploads] = useState([]);
    const [form, setForm] = useState(null);
    const [data, setData] = useState(null);
    const [values, setValues] = useState({})
    const [checklist, setChecklist] = useState([]);
    const [errors, setErrors] = useState([]);
    const classes = useStyles();
    const isMobileDevice = useMediaQuery('(max-width:600px)');

    const requireds = [];
    const fieldsIncludes = [];

    useEffect(() => {
        props.getViewActionChecklist(props.match.params.id)
    }, []);

    useEffect(() => {
        if (props.checklists) {
            const uniqueChecklists = props.checklists.reduce((acc, item) => {
                if (!acc.some(checklist => checklist._id === item.checklist._id)) {
                    acc.push(item.checklist);
                }
                return acc;
            }, []);
            setChecklist(uniqueChecklists)
        }
    }, [props.checklists]);

    const handleForm = (id) => {
        setForm(id);
        let aux = props.checklists.filter(e => e.checklist._id === id)
        aux = aux.sort((a, b) => a.field.order - b.field.order);
        setData(aux)
        let result = {};
        aux.forEach(element => {
            result = {
                ...result,
                [element._id]: element.value || ''
            }
        });
        setValues(result)
    }

    const onDrop = (id, files) => {
        setUploads({ ...uploads, [id]: files })
    }

    const save = async () => {
        try {

            setErrors([])
            let auxErrors = [];
            for (let i = 0; i < requireds.length; i++) {
                if (!values[requireds[i]]) {
                    auxErrors.push({ _id: requireds[i], message: i18n.t('sprint.actionChecklist.inicial.fieldRequired') })
                    toast.error(i18n.t('sprint.actionChecklist.inicial.fillFields'));
                }
            }
            if (auxErrors.length > 0) {
                setErrors(auxErrors)
                return false
            }
            let checklist = []
            for (let [key, value] of Object.entries(values)) {
                checklist.push({
                    _id: key,
                    value: value,
                    statusInclude: fieldsIncludes.includes(key)
                })
            }
            const formData = new FormData();
            for (let key in uploads) {
                uploads[key].forEach(file => {
                    formData.append(key, file)
                })
            }

            formData.append('checklist', JSON.stringify(checklist))
            const update = await props.handleUpdateActionChecklist(props.match.params.id, formData);

            if (update.error) {
                switch (update.error.message) {
                    case "Ação não encontrada!": return toast.error(i18n.t('sprint.actionChecklist.inicial.actionNotFound'));
                    case "Ocorreu um erro ao atualizar a ação.": return toast.error(i18n.t('sprint.actionChecklist.inicial.errorUpdate'));
                    case "Erro interno do servidor.": return toast.error(i18n.t('sprint.actionChecklist.inicial.internalError'));
                    default: return toast.error(update.error.message);
                }
            } else {
                if (update.message === "Ação atualizada com sucesso!") {
                    toast.success(i18n.t('sprint.actionChecklist.inicial.updateSuccess'));
                } else {
                    toast.success(update.message)
                }
            }

            props.getViewActionChecklist(props.match.params.id,);
            setData(null);
        } catch (error) {
            if (error.error.messag === "Erro interno do servidor.") {
                toast.error(i18n.t('sprint.actionChecklist.inicial.internalError'));
            } else {
                toast.error(error.error.message);
            }
        }
    }

    const handleInputChange = (event) => {
        let { name, value } = event.target;
        if (event.target.type === 'checkbox') {
            if (event.target.checked) {
                value = event.target.checked
            } else {
                value = ''
            }
        }
        let aux = { ...values };
        aux[name] = value;
        setValues(aux)
    };

    const { user } = props;
    const Field = (d) => {
        d.id = d._id
        const props = data.find(e => e._id === d.id)
        const dataProps = props.field;
        if (d.status === 'd') return false;
        if (dataProps.parent && !dataProps.parentValue?.some(el =>{
            const res = values[data.find(e => e.field._id === dataProps.parent)._id]
            return Array.isArray(res) ? res.includes(el) : res === el
        })) {
            return false;
        }
        if (dataProps.required) requireds.push(d.id)
        fieldsIncludes.push(d.id)
        const styleLabel = { color: '#202223', fontSize: '0.85rem' };
        const shop = localStorage.getItem('shop')
        const isEditable = user.permissionCustomer?.some(p => p.customer === shop && p.actions.includes("edit_checklist")) && props.status !== 'success';
        let comum = {
            required: dataProps.required,
            placeholder: dataProps.placeholder,
            disabled: !isEditable,
            value: values[d.id],
            name: d.id,
            onChange: handleInputChange
        }

        const input = <InputLabel style={styleLabel}>
            <Grid container>
                <Grid xs={isMobileDevice ? 12 : 10}>
                    {dataProps.title} {dataProps.required && <span style={{ color: 'red' }}>*</span>}
                </Grid>

                <Grid xs={isMobileDevice ? 12 : 2} textAlign='right'>
                    {props.status === 'error' && <Icon width='20px' color='#d82c0d' icon="material-symbols:error" />}
                    {props.status === 'in_analysis' && <Icon color="#6B7280" icon="material-symbols:hourglass-bottom" width="20" />}
                    {props.status === 'success' && <Icon color="green" width='20px' icon="material-symbols:check-circle" />}
                </Grid>
            </Grid>
        </InputLabel>
        const infosSmall = <><small style={{ color: "rgba(0, 0, 0, 0.6)" }}>{dataProps.helperText}</small>
            <small style={{ color: "#d32f2f" }}>{props.status === 'error' && (props.comment || "Erro.")}</small>
            {errors?.find(e => e._id === d.id) && (<small style={{ color: "#d32f2f" }}>{errors?.find(e => e._id === d.id).message}</small>)}
        </>
        switch (dataProps.type) {
            case 'textarea':
            case 'money':
            case 'url':
            case 'number':
            case 'date':
            case 'email':
            case 'text':
                return <FormGroup id={d.id} className={`formGroup`}>
                    {input}
                    <TextField
                        multiline={dataProps.type === 'textarea'}
                        rows={dataProps.type === 'textarea' ? 4 : 1}
                        fullWidth
                        type={dataProps.type}
                        size="small"
                        {...comum}
                        InputProps={{
                            style: { fontSize: '12px' },
                            startAdornment: dataProps.type === 'money' && <InputAdornment position="start">R$</InputAdornment>
                        }}
                    />
                    {infosSmall}
                </FormGroup>
            case 'multiselect':
            case 'select':
                const { value } = comum;
                if (typeof value === 'string' && dataProps.type === 'multiselect') {
                    comum.value = [value];
                }
                return <FormGroup id={d.id} className={`formGroup`}>
                    {input}
                    <Select
                        fullWidth
                        multiple={dataProps.type === 'multiselect'}
                        size="small"
                        {...comum}
                        InputProps={{
                            style: { fontSize: '12px' },
                        }}
                    >
                        {
                            dataProps.options.map((e, i) => {
                                return <MenuItem key={i} value={e}>{e}</MenuItem>
                            })
                        }
                    </Select>

                    {infosSmall}</FormGroup>
            case 'checkbox':

                return <FormGroup id={d.id} className={`formGroup`}>
                    <FormControlLabel
                        {...comum}
                        control={<Checkbox checked={d.value}  {...comum} />}
                        label={<>{dataProps.title} {dataProps.required && <span style={{ color: "red" }}>*</span>}</>}
                    />

                    {infosSmall}</FormGroup>
            case 'radio':
                return <FormGroup id={d.id} className='formGroup'>
                    {input}
                    <RadioGroup
                        {...comum}>
                        {
                            dataProps.options.map((e, i) => {
                                return <FormControlLabel {...comum} key={i} value={e} control={<Radio />} label={e} />
                            })
                        }
                    </RadioGroup>

                    {infosSmall}
                </FormGroup>
            case 'attachment':
                let files = uploads[d.id]?.map(file => (
                    <li key={file.location ? file.location :file.path}>
                        <small>{file.location ? file.location :file.path}</small>
                    </li>
                ));

                if (!files && values[d.id]) {
                    files = values[d.id]?.map(file => (
                        <li key={file.newName}>
                            <a href={file.location ? file.location :`${api}${file.path}`} target='_blank'><small>{file.name}</small></a>
                        </li>
                    ));
                }

                return (
                    <FormGroup id={d.id} className='formGroup'>
                        {input}
                        <Dropzone onDrop={(files) => onDrop(d.id, files)}>
                            {({ getRootProps, getInputProps, isDragActive }) => (
                                <>
                                    <div {...getRootProps({ className: classes.dropzone })} >
                                        <input name={`${d.id}[]`} {...getInputProps()} />
                                        {isDragActive ? (
                                            <small>{i18n.t('sprint.actionChecklist.inicial.archiveHere')}</small>
                                        ) : (
                                            <small>{i18n.t('sprint.actionChecklist.inicial.dragOrClick')}</small>
                                        )}
                                    </div>
                                    {infosSmall}
                                    <br />
                                    <ul>{files}</ul>
                                </>
                            )}
                        </Dropzone>
                    </FormGroup>
                );
            default:
                return <></>

        }
    }

    const fieldsInput = data ? data.map(item => Field(item)) : null;
    return (
        <div class='body'>
            <Container style={{ paddingTop: "20px", paddingBottom: "20px" }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant="h1">
                        {i18n.t('sprint.actionChecklist.inicial.checklist')}
                    </Typography>

                    <Typography style={{ textAlign: 'right', alignItems: "center" }}>
                        {`${Math.round(props.infos?.action)}%`}
                    </Typography>
                </div>
                <BorderLinearProgress colorBar={props.color} variant="determinate" value={props.infos?.action} />
            </Container>
            <Grid style={{ borderTop: '1px solid silver', height: '91%' }} container>
                <Grid item xs={isMobileDevice ? 1.5 : 3} className="menu">
                    <div className={isMobileDevice ? 'item-mobile' : 'item'}>
                        {
                            !isMobileDevice &&
                            <Grid container>
                                <Grid className='alignCenterVertical' item xs={8}>
                                    <h3>{i18n.t('sprint.actionChecklist.inicial.checklistAction')}</h3>
                                </Grid>
                                <Grid item xs={4}>
                                    <Box sx={{ position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'flex-end', height: '100%' }}>
                                        <CircularProgress variant="determinate" value={props.infos?.action} style={{ color: 'white' }} />
                                        <Box className="circularProgress">
                                            {`${Math.round(props.infos?.action)}%`}
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                        }
                    </div>
                    <div className='subitens'>
                        {checklist?.length > 0 && checklist?.filter(e => e.parent === "action").map((item, index) => {
                            return (
                                <div className={`${isMobileDevice ? 'subitem-mobile' : 'subitem'} ${form === item._id ? 'selected' : ''}`} onClick={() => handleForm(item._id)}>
                                    <Grid container>
                                        <Grid item xs={isMobileDevice ? 10 : 2} className='alignCenterVertical'>
                                            <Icon icon={item.icon} width='30px' />
                                        </Grid>
                                        {
                                            !isMobileDevice && (
                                                <Grid item xs={8} className='alignCenterVertical'>
                                                    <h4 className='break-word'>{item.title}</h4>
                                                </Grid>
                                            )
                                        }
                                        <Grid item xs={2} style={{ display: 'flex', alignItems: 'center', justifyContent: 'right' }}>
                                            {props.infos?.errors?.includes(item._id) ? <Icon width='20px' icon="material-symbols:error" color="#d82c0d" />
                                                : (props.infos?.waitings?.includes(item._id) ? <Icon icon="ic:baseline-keyboard-arrow-right" color="#6B7280" /> : (props.infos?.in_analysis?.includes(item._id) ? <Icon icon="material-symbols:hourglass-bottom" color="#6B7280" width="20" /> : <Icon icon="material-symbols:check-circle" color="#008060" />))
                                            }
                                        </Grid>
                                    </Grid>
                                </div>
                            )
                        })}
                    </div>
                </Grid>
                <Grid item xs={isMobileDevice ? 10.5 : 9}>
                    <Container className={isMobileDevice ? 'p-3' : 'p-5'} style={{ heigth: '100%' }}>

                        {data ? <>
                            <h3>{data[0].checklist.title}</h3>
                            <p>{data[0].checklist.subtitle}</p>
                            {fieldsInput}
                            <Grid container spacing={2} style={{ marginTop: '30px' }}>
                                <Grid item xs={12}>
                                    <Button className='saveButton' onClick={() => save()} fullWidth>{i18n.t('sprint.actionChecklist.inicial.save')}</Button>
                                </Grid>
                            </Grid>
                        </> : <>{i18n.t('sprint.actionChecklist.inicial.selectItem')}</>}
                    </Container>
                </Grid>
            </Grid>
        </div>
    );
};

const mapStateToProps = state => ({
    checklists: state.actions.actionChecklist || [],
    infos: state.actions.actionChecklistInfos,
})


const mapDispatchToProps = (dispatch) => {
    return {
        getViewActionChecklist: (id) => dispatch(getViewActionChecklist(id)),
        handleUpdateActionChecklist: (id, data) => dispatch(handleUpdateActionChecklist(id, data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Inicial);
