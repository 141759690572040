import React, { useCallback, useEffect, useState } from 'react';
import { Box, Button, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Typography } from '@mui/material';
import { LocalizationProvider, DatePicker, DateTimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { currencySymbols } from '../../../utils/objects';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import dayjs from 'dayjs';
import 'dayjs/locale/es';
import { debounce } from 'lodash';
import { i18n } from '../../../../../translate/i18n';
import { FCDatePicker, FCSelect, FCTextField } from 'components-fullcomm';

const ActivityOptions = ({ options, setArr, i, defaultValue }) => {
  const [value, setValue] = useState(dayjs(new Date()));
  const [range, setRange] = useState([dayjs(), dayjs()]);

  const handleChangeTitle = (title) => {
    switch (title) {
      case "Código": return i18n.t('sprint.newAction.pages.activities.activityOptions.code');
      case "Desconto": return i18n.t('sprint.newAction.pages.activities.activityOptions.discount');
      case "Tema": return i18n.t('sprint.newAction.pages.activities.activityOptions.theme');
      case "Data": return i18n.t('sprint.newAction.pages.activities.activityOptions.date');
      case "Métrica": return i18n.t('sprint.newAction.pages.activities.activityOptions.metric');
      default: return title;
    }
  }

  const handleChangePlaceHolder = (placeholder) => {
    switch (placeholder) {
      case "Escreva o código do cupom": return i18n.t('sprint.newAction.pages.activities.activityOptions.writeCouponCode');
      case "Descreva o desconto": return i18n.t('sprint.newAction.pages.activities.activityOptions.describeDiscount');
      case "Tema do e-mail": return i18n.t('sprint.newAction.pages.activities.activityOptions.themeEmail');
      case "Data de envio": return i18n.t('sprint.newAction.pages.activities.activityOptions.sendDate');
      case "Nome da métrica": return i18n.t('sprint.newAction.pages.activities.activityOptions.metricName');
      default: return placeholder;
    }
  }

  useEffect(() => {
    if (!defaultValue) {
      setValue('');
      return;
    };
    const { value, name } = defaultValue;
    if (value) {
      if (name === 'date' || name === 'time') {
        setValue(dayjs(value));
      } else if (name === 'range') {
        const [start, end] = value.split(',');
        setRange([dayjs(start), dayjs(end)]);
      } else {
        setValue(value);
      }
      setArr(prev => {
        const newArr = [...prev];
        newArr[i] = defaultValue;
        return newArr;
      });
    }
  }, []);

  useEffect(() => {
    debouncedHandleChange(value);
  }, [value]);

  const handleChange = useCallback(
    debounce((e) => {
      const newValue = {
        name: options.type,
        title: options.title,
      };
      if (options.type === 'date' || options.type === 'time') {
        newValue.value = dayjs(e.$d).format('YYYY-MM-DD HH:mm:ss (Z)');
      } else {
        newValue.value = e;
      }

      setArr(prev => {
        const newArr = [...prev];
        newArr[i] = newValue;
        return newArr;
      });
    }, 300), // Adjust the debounce delay (in milliseconds) as needed
    [options.type, setArr, i]
  );

  // Attach the debounced function to the event listener
  const debouncedHandleChange = (e) => handleChange(e);

  const handleRangeChange = (start, end) => {
    let startDate = dayjs(start.$d);
    let endDate = dayjs(end.$d);

    if (startDate.isAfter(endDate)) endDate = startDate;

    const newValue = {
      name: options.type,
      value: `${startDate.format('YYYY-MM-DD HH:mm:ss (Z)')}, ${endDate.format('YYYY-MM-DD HH:mm:ss (Z)')}`
    };
    setRange([startDate, endDate]);
    setArr(prev => {
      const newArr = [...prev];
      newArr[i] = newValue;
      return newArr;
    });
  };

  const handleFileChange = (e) => {
    const newValue = {
      name: options.type,
      value: e.target.files[0]
    };
    setArr(prev => {
      const newArr = [...prev];
      newArr[i] = newValue;
      return newArr;
    });
  };

  switch (options.type) {
    case "range":
      return (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={localStorage.getItem('i18nextLng')}>
          {/* <Typography>{handleChangeTitle(options.title)}</Typography> */}
          <Box display="flex" gap={1} alignItems="center">
            <DatePicker value={range[0]} onChange={(e) => handleRangeChange(e, range[1])} />
            <Typography>{i18n.t('sprint.newAction.pages.activities.activityOptions.to')}</Typography>
            <DatePicker minDate={range[0]} value={range[1]} onChange={(e) => handleRangeChange(range[0], e)} />
          </Box>
        </LocalizationProvider>
      );
    case "monetary value":
      return (
        <FCSelect           
        options={[
          currencySymbols.map((currency, i) => (
            { value: currency, label: currency }
          ))
        ]}
        label={handleChangeTitle(options.title)}
        size='small' fullWidth select onChange={e => setValue(e.target.value)} value={value}
        />
      );
    case "date":
      return (
        <FCDatePicker
          label={handleChangeTitle(options.title)}
          // value={value}
          // onChange={e => setValue(e)}
        />
      );
    case "time":
      return (
        <>
        {/* <Typography>{handleChangeTitle(options.title)}</Typography> */}
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={localStorage.getItem('i18nextLng')}>
          <DateTimePicker value={value} onChange={e => setValue(e)} />
        </LocalizationProvider>
        </>
      );
    case "radio":
      return (
        <FormControl>
          <FormLabel>{handleChangeTitle(options.title)}</FormLabel>
          <RadioGroup
            row size='small'
            name="row-radio-buttons-group"
            onChange={e => setValue(e.target.value)}
            value={value}
          >
            {
              options.options?.map((option, i) => (
                <FormControlLabel key={i} value={option} control={<Radio />} label={option} />
              ))
            }
          </RadioGroup>
        </FormControl>
      );
    case "paragraph":
      return (
        <FCTextField
          label={handleChangeTitle(options.title)}
          rows={4} 
          size='small'
          fullWidth
          multiline
          onChange={e => setValue(e.target.value)}
          value={value}
        />
      );
    case "select":
    case "responsible":
      return (
        <FCSelect
          select
          label={handleChangeTitle(options.title)}
          fullWidth size='small'
          multiline
          onChange={e => setValue(e.target.value)}
          value={value}
          options={[
            options.options?.map((option, i) => (
              { value: option, label: option }
            ))
          ]}
        />
      );
    case "file":
      return (
        <Button size='small' onChange={handleFileChange} component="label" variant="contained" >
          <FileUploadIcon />
          {i18n.t('sprint.newAction.pages.activities.activityOptions.upload')}
          <input hidden multiple type="file" />
        </Button>
      );
    default:
      return (
        <FCTextField label={handleChangeTitle(options.title)} size='small' fullWidth type={options} onChange={e => setValue(e.target.value)} value={value} />
      );
  }
};

export default ActivityOptions;
