import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import SpinnerDelete from '../../components/SpinnerDelete'
import { connect } from 'react-redux'
import * as actions from '../../store/actions/agencies'
import { Pagination, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Box, Typography, Avatar } from '@mui/material';

import { toast } from 'react-toastify';
import { i18n } from '../../translate/i18n';
import { userPermissionCheck } from '../Sprint/utils/userPermissionCheck';
import { api } from '../../config';
import { FCDropdownButton } from 'components-fullcomm';

const Specific = (props) => {
    const history = useHistory();
    const [pageCurrent, setPageCurrent] = useState(1);
    const limit = 10;
    const [loading, setLoading] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [idDelete, setIdDelete] = useState("");
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    useEffect(() => {
        props.getViewAgency(props.match.params.id)
    }, []);

    const deleteUser = async () => {
        setLoading(true)

        try {

            const result = await props.deleteUser(idDelete)
            await props.getUsers(pageCurrent, limit)
            if (result.error) {
                switch (result.error.message) {
                    case 'Usuário não existe!': return toast.error(i18n.t('agency.specific.userNotExist'));
                    case "Erro ao excluir!": return toast.error(i18n.t('agency.specific.deleteError'));
                    case "Erro interno do servidor.": return toast.error(i18n.t('agency.specific.internalError'));
                    default: return toast.error(result.error.message);
                }
            } else {
                if (result.message === 'Usuário apagado com sucesso!') {
                    toast.success(i18n.t('agency.specific.deleteSuccess'))
                } else {
                    toast.success(result.message);
                }
            }

        } catch (error) {
            if (error.error.message === 'Erro interno do servidor.') {
                toast.error(i18n.t('agency.specific.internalError'))
            } else {
                toast.error(error.error.message)
            }
        }

        setLoading(false)
        toggleModal();
    }


    const toggleModal = (_id = "") => {
        if (_id) {
            setIdDelete(_id)
        } else {
            setIdDelete("")
        }
        setOpenModal(!openModal);
    }

    return (
        <div class="p-4">
            <Box display="flex" justifyContent="space-between" alignItems="start" mb="2rem">
                <Box display="flex" gap="1rem" alignItems="center">
                    <Avatar sx={{ width: 60, height: 60 }} src={props.agency?.fileLocation ? props.agency?.fileLocation : `${api}/files/agencies/${props.agency?.fileName}`} />
                    <Typography variant="h1">{props.agency?.name}</Typography>
                </Box>
                {
                    userPermissionCheck(props.user, ["edit_agency"]) && (
                        <Button variant="contained" className='btn-action' onClick={() => history.push(`/edit-agency/${props.agency?._id}`)}>{i18n.t('agency.specific.edit')}</Button>
                    )
                }
            </Box>
            {/* <div className="d-flex">
                <div className="mr-auto p-2">
                    <Typography variant="h1">{i18n.t('agency.specific.stores')}</Typography>
                </div>
            </div>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>{i18n.t('agency.specific.id')}</TableCell>
                            <TableCell>{i18n.t('agency.specific.name')}</TableCell>
                            <TableCell align='right' padding='none' sx={{paddingRight: "1rem"}}>
                                <Link to={`/add-customer/${props.match.params.id}`}>
                                    <Button className="btn-action">{i18n.t('agency.specific.register')}</Button>
                                </Link>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.agency?.customers?.map(row => (
                            <TableRow
                                key={row._id}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell>
                                    {row._id}
                                </TableCell>
                                <TableCell>{row.name}</TableCell>
                                <TableCell align="right">
                                    <DropdownButton
                                        items={[
                                            i18n.t('agency.specific.edit'),
                                            i18n.t('agency.specific.delete'),
                                        ]}
                                        itemClickHandlers={[
                                            () => history.push("/update-user/customer/" + props.match.params.store + "/" + row._id),
                                            () => toggleModal(row._id),
                                        ]}
                                    />

                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <br />
            {!props.users ? "" :
                <Box display="flex" justifyContent="center" alignItems="center">
                    <Pagination showFirstButton showLastButton variant="outlined" shape="rounded" count={props.users.totalPages} page={pageCurrent} onChange={(e, page) => setPageCurrent(page)} />
                </Box>
            } */}
            <div className="d-flex">
                <div className="mr-auto p-2">
                    <Typography variant="h5">Usuários</Typography>
                </div>
            </div>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>{i18n.t('agency.specific.name')}</TableCell>
                            <TableCell>{i18n.t('agency.specific.email')}</TableCell>
                            <TableCell>{i18n.t('sprint.contract.list.stores')}</TableCell>
                            <TableCell align='right' padding='none' sx={{paddingRight: "1rem"}}>
                                {
                                    userPermissionCheck(props.user, ["create_user"]) && (
                                        <Link to={`/add-user/agency/${props.agency?._id}`}>
                                            <Button variant='contained' className="btn-action">{i18n.t('agency.specific.register')}</Button>
                                        </Link>
                                    )
                                }
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.agency?.users?.filter(el => el.role === 'agency')?.map(row => (
                            <TableRow
                                key={row._id}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell>{row.name}</TableCell>
                                <TableCell>{row.email}</TableCell>
                                <TableCell>{row.customer?.map(el => el.name).join(', ')}</TableCell>
                                <TableCell align="right">
                                    {
                                        userPermissionCheck(props.user, ["edit_user", "delete_user"]) && (
                                            <FCDropdownButton
                                                items={[
                                                    userPermissionCheck(props.user, ["edit_user"]) ? i18n.t('agency.specific.edit') : null,
                                                    userPermissionCheck(props.user, ["delete_user"]) ? i18n.t('agency.specific.delete') : null,
                                                ]}
                                                itemClickHandlers={[
                                                    () => history.push("/update-user/customer/" + props.match.params.store + "/" + row._id),
                                                    () => toggleModal(row._id),
                                                ]}
                                            />
                                        )
                                    }

                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <br />
            {!props.users ? "" :
                <Box display="flex" justifyContent="center" alignItems="center">
                    <Pagination showFirstButton showLastButton variant="outlined" shape="rounded" count={props.users.totalPages} page={pageCurrent} onChange={(e, page) => setPageCurrent(page)} />
                </Box>
            }
            <Modal isOpen={openModal} >
                <ModalHeader toggle={() => toggleModal()}>{i18n.t('agency.specific.deleteUser')}</ModalHeader>
                <ModalBody>
                    {i18n.t('agency.specific.confirmDelete')}
                </ModalBody>
                <ModalFooter>
                    <span onClick={() => deleteUser()}>
                        <SpinnerDelete text={true} loading={loading} />
                    </span>
                    <Button size="sm" color="secondary" onClick={() => toggleModal()}>{i18n.t('agency.specific.cancel')}</Button>
                </ModalFooter>
            </Modal>
        </div>
    )
}


const mapStateToProps = state => ({
    agency: state.agencies.agencyDetailsView,
    user: state.auth.user
})

export default connect(mapStateToProps, actions)(Specific);

