import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import moment from "moment";
import { connect } from 'react-redux'
import * as actions from '../../../store/actions/activities'
import { Stack, Button, Typography } from '@mui/material';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { userPermissionCheck } from '../utils/userPermissionCheck';
import { i18n } from '../../../translate/i18n';
import { isMobile } from 'react-device-detect';
import { FCCard, FCChip } from 'components-fullcomm';

const StyleItem = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 15px;
  border-bottom: 1px solid #ccc;
  small {
    color: #606669;
  }

  &:last-of-type {
    border-bottom: none;
  }
`;

const Tables = (props) => {
    const fileInput = useRef(null);

    useEffect(() => {
        props.getVersionsActivities()
    }, []);

    const handleFileChange = async (event) => {
        const formData = new FormData();
        formData.append('file', event.target.files[0]);
        try {
            const response = await props.uploadTable(formData);
            props.getVersionsActivities()

            if (response.error) {
                if (response.error.message === "Erro interno do servidor.") {
                    toast.error(i18n.t('sprint.tableActivity.tables.internalError'));
                } else {
                    toast.error(response.error.message)
                }
            } else {
                toast.success(i18n.t('sprint.tableActivity.tables.updateSuccess'));
            }
        } catch (error) {
            if (error.error.message === "Erro interno do servidor.") {
                toast.error(i18n.t('sprint.tableActivity.tables.internalError'));
            } else {
                toast.error(error.error.message)
            }
        }

    };
    const handleClick = () => {
        fileInput.current.click();
    };

    return (
        <div className="p-4">
            <div className="d-flex align-items-center justify-content-between mb-3">
                <div className="mr-auto">
                    <Typography variant='h4'>{i18n.t('sprint.tableActivity.tables.activitiesTable')}</Typography>
                </div>
                <div className="d-flex align-items-center">
                    {
                        userPermissionCheck(props.user, ['edit_activity_version']) &&
                        <Button
                            variant='contained'
                            onClick={handleClick}
                            className='btn-action'
                            sx={{ height: '36px' }}
                        >
                            <FileUploadIcon style={{ marginRight: '10px' }} /> {i18n.t('sprint.tableActivity.tables.upload')}
                        </Button>
                    }

                    <input ref={fileInput} type="file" onChange={handleFileChange} accept=".csv" style={{ display: 'none' }} />
                </div>
            </div>
            <FCCard className="p-3">
                <Stack>
                    {
                        props.versions?.map((version, index) => {
                            return (
                                <Link to={`/table/${version.version}`} style={{ color: '#000'}}>
                                    <StyleItem key={index}>
                                        <Typography variant='subtitle2' sx={{ color: '#000'}}>{i18n.t('sprint.tableActivity.tables.version')} {version.version} {props.version == version.version && <FCChip size="small" color='green-success' sx={{ 'marginLeft': '10px', height: '18px', paddingX: isMobile ? 0 : '0.25rem' }} label={i18n.t('sprint.tableActivity.tables.activeTable')} />}</Typography>
                                        <Typography variant='body2'>{moment(version.date).format("DD/MM/YYYY")}</Typography>
                                    </StyleItem>
                                </Link>
                            )
                        })
                    }
                </Stack>
            </FCCard>
        </div>
    )
}


const mapStateToProps = state => {
    return {
        versions: state.activities?.versions,
        version: state.activities?.versionTableActivities,
    }
}

export default connect(mapStateToProps, actions)(Tables);
