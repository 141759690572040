import React, { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { LoadingButton } from '@mui/lab';
import { getForm, updateForm } from '../../../store/actions/form'
import { toast } from 'react-toastify';
import ConfigForm from './ConfigForm';
import { i18n } from '../../../translate/i18n';

import FlowBuilder, {
  NodeContext,
  useDrawer
} from 'react-flow-builder';

const handleSubmit = async (save, node, e, field) => {
  try {
    node[field] = e.target.value
    save(node);
  } catch (error) {
    node[field] = e.target.value
    save(node, !!error);
  }
};

const StartNodeDisplay = () => {
  const node = useContext(NodeContext);
  return <div className="start-node">{node.name}</div>;
};

const EndNodeDisplay = () => {
  const node = useContext(NodeContext);
  return <div className="end-node">{node.name}</div>;
};

const NodeDisplay = () => {
  const node = useContext(NodeContext);
  return (
    <div
      className={`other-node ${node.configuring ? 'node-configuring' : ''} ${node.validateStatusError ? 'node-status-error' : ''
        }`}
    >
      <small>{i18n.t('start.form.question')}</small>
      <h5>{node?.data?.name || ""}</h5>
      <small>{i18n.t('start.form.type')} {node.data?.fieldType}</small>
      <br />
      {['single', 'multiple', 'multiple_option', 'single_option'].includes(node.data?.fieldType) &&
        <ul>{node.data?.options.map(elem => {
          return <li>{elem.value}</li>
        })}</ul>}
    </div>
  );
};

const ConditionNodeDisplay = () => {
  const { saveDrawer: save } = useDrawer();
  const node = useContext(NodeContext);
  return (
    <div
      className={`condition-node ${node.configuring ? 'node-configuring' : ''
        } ${node.validateStatusError ? 'node-status-error' : ''}`}
    >
      <small>{i18n.t('start.form.condition')}</small>
      <input onChange={(e) => handleSubmit(save, node, e, 'name')} value={node.name} placeholder={i18n.t('start.form.text')} />
    </div>
  );
};

const registerNodes = [
  {
    type: 'start',
    name: 'Início',
    displayComponent: StartNodeDisplay,
    isStart: true,
  },
  {
    type: 'end',
    name: 'Fim',
    displayComponent: EndNodeDisplay,
    isEnd: true,
  },
  {
    type: 'node',
    name: 'Pergunta',
    displayComponent: NodeDisplay,
    configComponent: ConfigForm,
    configTitle: "Configuração"
  },
  {
    type: 'condition',
    name: 'Condição',
    displayComponent: ConditionNodeDisplay,
  },
  {
    type: 'branch',
    name: 'Condições',
    conditionNodeType: 'condition',
  }
];

const defaultNodes = [
  {
    "type": "start",
    "name": "Carregando...",
  }];

function addIdsToNodes(nodeObj, idMap = {}, parentId = null) {
  if (!Array.isArray(nodeObj)) {
    let nodeId = parentId ? `${parentId}-${nodeObj.type}` : `node-${nodeObj.type}`;
    let counter = 1;
    while (idMap[nodeId]) {
      nodeId = parentId ? `${parentId}-${nodeObj.type}-${counter}` : `node-${nodeObj.type}-${counter}`;
      counter++;
    }

    nodeObj.id = nodeId;
    idMap[nodeId] = true;

    if (nodeObj.children) {
      for (let i = 0; i < nodeObj.children.length; i++) {
        const childId = `${nodeId}-${i + 1}`;
        addIdsToNodes(nodeObj.children[i], idMap, childId);
      }
    }
  } else {
    for (let i = 0; i < nodeObj.length; i++) {
      addIdsToNodes(nodeObj[i], idMap, parentId);
    }
  }
}


const NodeForm = (props) => {
  const [nodes, setNodes] = useState(defaultNodes);
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    props.getForm();
  }, []);

  useEffect(() => {
    const localNodes = props.nodes;
    addIdsToNodes(localNodes);
    if (props.nodes) setNodes(localNodes);
  }, [props.nodes]);

  const handleUpdate = async () => {
    setLoading(true);
    try {

      const rep = await props.updateForm({ form: nodes });
      if (rep.error) {
        if (rep.error.message === "Erro interno do servidor.") {
          toast.error(i18n.t('start.form.internalError'))
        } else {
          toast.error(rep.error.message)
        }
      } else {
        if (rep.message === "Formulário atualizado com sucesso!") {
          toast.success(i18n.t('start.form.updateSuccess'));
        } else {
          toast.success(rep.message);
        }
      }
    } catch (error) {
      if (error.error.message === "Erro interno do servidor.") {
        toast.error(i18n.t('start.form.internalError'))
      } else {
        toast.error(error.error.message)
      }
    }

    setLoading(false);
  };

  const handleChange = (nodes) => {
    setNodes(nodes);
  };

  return (
    <div id="workflow">

      <Link onClick={handleUpdate} to="#">
        <LoadingButton variant="contained" loading={loading}>{i18n.t('start.form.refresh')}</LoadingButton>
      </Link>
      <NodeContext.Provider value={nodes}>
        <FlowBuilder
          nodes={nodes}
          onChange={handleChange}
          registerNodes={registerNodes}
          historyTool
          zoomTool
        />
      </NodeContext.Provider>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    nodes: state.form.form || defaultNodes
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getForm: () => dispatch(getForm()),
    updateForm: (form) => dispatch(updateForm(form)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NodeForm);
