import { Box, Button, Collapse, Grid, Typography } from '@mui/material'
import { FCDatePicker, FCModalHeader, FCSelect, FCTextField } from 'components-fullcomm'
import React, { useCallback, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Modal } from 'reactstrap'
import * as actions from '../../../../store/actions/goal'
import { LoadingButton } from '@mui/lab'
import { toast } from 'react-toastify'
import { debounce, isArray } from 'lodash'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import dayjs from 'dayjs'

const GoalsModal = ({ open, setOpen, goals, addGoal, shop, updateGoal, editGoal, setEditGoal, debounceGetGoals }) => {
  const [addRow, setAddRow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [updatedGoals, setUpdatedGoals] = useState(isArray(goals) ? goals : []);
  const [values, setValues] = useState({
    name: '',
    date: dayjs().format('YYYY-MM'),
    type: 'daily',
    daily: 0,
    weekly: 0,
    monthly: 0,
    quarterly: 0,
    yearly: 0,
    notify: false,
    title: '',
  });

  const handleSubmit = async () => {
    try {

      setLoading(true);
      const res = await (editGoal ? updateGoal({ ...values, id: editGoal._id }) : addGoal(values));
      if (res.error) {
        return toast.error(res.error.message);
      }
      toast.success(`Meta ${editGoal ? 'atualizada' : 'adicionada'} com sucesso`);
      setEditGoal(null);
      setAddRow(false);
      setValues({
        name: '',
        date: dayjs().format('YYYY-MM'),
        type: 'monthly',
        daily: 0,
        weekly: 0,
        monthly: 0,
        quarterly: 0,
        yearly: 0,
        notify: false,
        title: '',
      });
      debounceGetGoals();
      setLoading(false);
    } catch (error) {
      toast.error('Erro ao adicionar meta');
      setLoading(false);
    }
  };

  const handleRowUpdate = (index, updatedValues) => {
    const updated = [...updatedGoals];
    updated[index] = updatedValues;
    setUpdatedGoals(updated);
  };

  const handleTypeChange = (type, value, name) => {
    if (name === 'conversionRate' || name === 'averageTicket') {
      return setValues({
        ...values,
        daily: value,
          weekly: value,
          monthly: value,
          quarterly: value,
          yearly: value,
      })
    }
    switch (type) {
      case 'daily':
        setValues({
          ...values, 
          type,
          daily: value,
          weekly: value * 7,
          monthly: value * 30,
          quarterly: value * 90,
          yearly: value * 365,
        })
        break;
      case 'weekly':
        setValues({
          ...values, 
          type,
          daily: value / 7,
          weekly: value,
          monthly: value * 4,
          quarterly: value * 12,
          yearly: value * 52,
        })
        break;
      case 'monthly':
        setValues({
          ...values, 
          type,
          daily: value / 30,
          weekly: value / 4,
          monthly: value,
          quarterly: value * 3,
          yearly: value * 12,
        })
        break;
      case 'quarterly':
        setValues({
          ...values, 
          type,
          daily: value / 90,
          weekly: value / 12,
          monthly: value / 3,
          quarterly: value,
          yearly: value * 4,
        })
        break;
      case 'yearly':
        setValues({
          ...values, 
          type,
          daily: value / 365,
          weekly: value / 52,
          monthly: value / 12,
          quarterly: value / 4,
          yearly: value,
        })
        break;
      default:
        break;
    }
  }

  useEffect(() => {
    debounceGetGoals();
  }, [shop, open]);

  useEffect(() => {
    if (editGoal) {
      setValues({
        name: editGoal.name,
        date: editGoal.date,
        type: 'daily',
        daily: editGoal.daily,
        weekly: editGoal.weekly,
        monthly: editGoal.monthly,
        quarterly: editGoal.quarterly,
        yearly: editGoal.yearly,
        notify: editGoal.notify,
        title: editGoal.title,
      })
    } else {
      setValues({
        name: '',
        date: dayjs().format('YYYY-MM'),
        type: 'daily',
        daily: 0,
        weekly: 0,
        monthly: 0,
        quarterly: 0,
        yearly: 0,
        notify: false,
        title: '',
      })
    }
  }, [editGoal]);

  return (
    <Modal isOpen={open} toggle={() => {setOpen(false); setEditGoal(null)}} size='xl' centered>
      <FCModalHeader title={`${editGoal ? 'Editar' : 'Criar'} meta`} onClick={() => {setOpen(false); setEditGoal(null)}} />
      <Box padding='2rem'>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <FCSelect
              label="Métrica"
              fullWidth={true}
              value={values.name}
              onChange={e => {
                const value = e.target.value;
                const data = {
                  ...values,
                  name: value,
                }
                // if (value === 'conversionRate' || value === 'averageTicket') {
                //     data.daily = 0;
                //     data.type = 'daily'
                //   };
                setValues(data);
                }
              }
              options={[
                { label: 'Ticket Médio', value: 'averageTicket' },
                { label: 'Sessões', value: 'sessions' },
                { label: 'Taxa de Conversão', value: 'conversionRate' },
                { label: 'Quantidade de Pedidos', value: 'ordersAmount' },
                { label: 'Receita', value: 'revenue' },
              ]}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FCDatePicker
              label="Data"
              views={['year', 'month']}
              fullWidth={true}
              value={dayjs(values.date)}
              onChange={e => setValues({ ...values, date: e.format('YYYY-MM') })}
            />
          </Grid>
          <Grid item xs={6}>
            <FCSelect
              disabled={addRow}
              label="Valor"
              fullWidth={true}
              value={values.type}
              onChange={e => {
                handleTypeChange(e.target.value, values[e.target.value], values.name);
              }}
              options={[
                { label: 'Diário', value: 'daily' },
                { label: 'Semanal', value: 'weekly' },
                { label: 'Mensal', value: 'monthly' },
                { label: 'Trimestral', value: 'quarterly' },
                { label: 'Anual', value: 'yearly' },
              ]}
            />
        </Grid>
        <Grid item xs={6} display='flex' gap='1rem' alignItems='end'>
          <FCTextField
            disabled={addRow}
            fullWidth={true}
            type="number"
            value={values[values.type]}
            onChange={e => {
              handleTypeChange(values.type, e.target.value, values.name);
            }}
          />
          <Button 
            sx={{ height: '42px'}}
            className='btn-gray' 
            onClick={() => setAddRow((prev) => !prev)}
          >
            <KeyboardArrowDownIcon sx={{ rotate: addRow && '180deg'}} />
          </Button>
        </Grid>
          <Grid item xs={12}>
          <Collapse in={addRow}>
            <Grid container spacing={2}>
              <Grid item xs={6}
                display='flex'
                alignItems='center'
                justifyContent='right'
              >
                <Typography>Diário</Typography>
              </Grid>
              <Grid item xs={6}>
                <FCTextField 
                  type="number"
                  fullWidth={true}
                  value={values.daily}
                  onChange={e => setValues({ ...values, daily: e.target.value })}
                />
              </Grid>
              <Grid item xs={6}
                display='flex'
                alignItems='center'
                justifyContent='right'
              >
                <Typography>Semanal</Typography>
              </Grid>
              <Grid item xs={6}>
                <FCTextField 
                  type="number"
                  fullWidth={true}
                  value={values.weekly}
                  onChange={e => setValues({ ...values, weekly: e.target.value })}
                />
              </Grid>
              <Grid item xs={6}
                display='flex'
                alignItems='center'
                justifyContent='right'
              >
                <Typography>Mensal</Typography>
              </Grid>
              <Grid item xs={6}>
                <FCTextField 
                  type="number"
                  fullWidth={true}
                  value={values.monthly}
                  onChange={e => setValues({ ...values, monthly: e.target.value })}
                />
              </Grid>
              <Grid item xs={6}
                display='flex'
                alignItems='center'
                justifyContent='right'
              >
                <Typography>Trimestral</Typography>
              </Grid>
              <Grid item xs={6}>
                <FCTextField 
                  type="number"
                  fullWidth={true}
                  value={values.quarterly}
                  onChange={e => setValues({ ...values, quarterly: e.target.value })}
                />
              </Grid>
              <Grid item xs={6}
                display='flex'
                alignItems='center'
                justifyContent='right'
              >
                <Typography>Anual</Typography>
              </Grid>
              <Grid item xs={6}>
                <FCTextField 
                  type="number"
                  fullWidth={true}
                  value={values.yearly}
                  onChange={e => setValues({ ...values, yearly: e.target.value })}
                />
              </Grid>
            </Grid>
          </Collapse>
          </Grid>
        <Grid item xs={12}>
          <FCTextField 
            fullWidth={true}
            label='Título'
            value={values.title}
            onChange={e => setValues({ ...values, title: e.target.value })}
          />
        </Grid>
      </Grid>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          gap: '1rem',
          padding: '1rem',
        }}
      >
        <Button variant="contained" className='btn-gray large' onClick={() => {setOpen(false); setEditGoal(null)}}>Cancelar</Button>
        <LoadingButton variant="contained" className='btn-action large' loading={loading} onClick={handleSubmit}>Salvar</LoadingButton>
      </Box>
    </Modal>
  )
}

const mapStateToProps = (state) => ({
  goals: state.goals.goals,
  shop: state.auth.shop,
})

export default connect(mapStateToProps, actions)(GoalsModal);