import { Box, Typography, Grid, Button, Grow, useMediaQuery } from "@mui/material";
import React, { useState } from "react";
import { connect } from "react-redux";
import ActionsTable from "./ActionsTable";
import { toast } from "react-toastify";
import * as actions from "../../store/actions/action";
import { i18n } from '../../translate/i18n';
import ExportButton from "../../pages/Sprint/utils/ExportButton";
import TrimesterUpdateModal from "../../pages/Sprint/utils/TrimesterUpdateModal";
import { removeActionFromTrimester, updateActionsTrimester } from "../../pages/Sprint/utils/updateActionsTrimester";
import Filter from "../../pages/Sprint/utils/Filter";
import ActionModal from "../../pages/Sprint/V2NewAction/ActionFloatingButton";
import { FCPagination } from "components-fullcomm";

const List = (props) => {
  const filterParams = JSON.parse(localStorage.getItem('filterParams'));
  const [currentPage, setCurrentPage] = useState(filterParams?.page || 1);
  const [selected, setSelected] = useState([]);
  const [sortDirection, setSortDirection] = useState(1);
  const [open, setOpen] = useState(false);
  const isMobileDevice = useMediaQuery('(max-width:600px)');
  const updateActions = async (add = true) => {
    try {

      const result = await (add ?  props.handleUpdateActionsTrimester(updateActionsTrimester(selected)) : props.handleUpdateActionsTrimester(removeActionFromTrimester(selected)));
      if (result.error) {
        if (result.error.message === "Erro interno do servidor.") {
          toast.error(i18n.t('sprint.list.internalError'));
        } else {
          toast.error(result.error.message);
        }
      } else {
        toast.success(i18n.t('sprint.list.updatedSuccess'));
      }
      setSelected([]);
      setCurrentPage(0);
      setCurrentPage(1);
    } catch (error) {
      if (error.error.message === "Erro interno do servidor.") {
        toast.error(i18n.t('sprint.list.internalError'));
      } else {
        toast.error(error.error.message);
      }
    }
  };

  return (
    <Box height={"calc(100vh - 4rem)"} overflow={"auto"} className="dashboard">
      {
        props?.platform[0] === "sprint" && (
          <ActionModal />
        )
      }
      <Box>
        <Grid container marginBottom={"1rem"}>
          <Grid
            item
            xs={9.5}
            display={"flex"}
            justifyContent={"space-between"}
          >
            <Typography variant={"h4"}>{props.platform?.includes('suporte') ? i18n.t('sprint.list.improvementsList') : i18n.t('sprint.list.actionsList')}</Typography>
          </Grid>
          {
            !isMobileDevice && (
              <Grid item xs={2.5} sx={{ display: "flex", gap: "1rem", alignSelf: "center", justifyContent: "end" }}>
                <ExportButton data={selected.map(el => {return {...el, cycle: Array.isArray(el.cycle) ? el.cycle.join(", ") : el.cycle}})} />
                {
                  props.user?.role === "admin" && (
                    <Grow in={selected.length > 0}>
                      <Button
                        sx={{ alignSelf: "center" }}
                        fullWidth
                        variant="contained"
                        className="btn-gray"
                        onClick={() => setOpen(true)}>
                        {i18n.t('sprint.list.updateActions')}
                      </Button>
                    </Grow>
                  )
                }
              </Grid>
            )
          }
        </Grid>
        <Filter
          platform={props.platform}
          type={"List"}
          currentPage={currentPage}
          sortDirection={sortDirection}
        />
        <TrimesterUpdateModal open={open} setOpen={setOpen} handleUpdate={updateActions} all={true} />
        <ActionsTable
          selected={selected}
          setSelected={setSelected}
          setSortDirection={setSortDirection}
          platform={props.platform}
        />
        <FCPagination
          sx={{ marginBottom: '3rem', mt: '1rem' }}
          count={props.actions?.totalPages}
          page={currentPage}
          onChange={(e, page) => setCurrentPage(page)}
        />
      </Box>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  actions: state.actions.actions,
});

export default connect(mapStateToProps, actions)(List);
