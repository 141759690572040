import { Box, Grid, Paper, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import * as customerActions from '../../../store/actions/customers';
import * as scaleActions from '../../../store/actions/scale';
import { i18n } from '../../../translate/i18n';
import { FCSelect } from 'components-fullcomm';

function SmartShipping(props) {
  const [data, setData] = useState({});
  const [period, setPeriod] = useState('7');

  useEffect(() => {
    props.getSmartShipping(period);
  }, [props.tokenApi, period])

  useEffect(() => {
    setData(props.smartShipping);
  }, [props.smartShipping])

  useEffect(() => {
    props.fetchToken();
  }, [props.shop])

  return (
    <Box padding="1rem 1.5rem">
      <Typography variant='h4' mb="1rem">{i18n.t('scale.smartShipping.smartShipping')}</Typography>
      <Paper sx={{padding: "1.5rem 2rem"}}>
        <Typography variant='h6' mb="0.5rem">{i18n.t('scale.smartShipping.filterPeriod')}</Typography>
        <FCSelect
          sx={{marginBottom: "1rem"}}
          select
          fullWidth
          size='small'
          value={period}
          onChange={(e) => setPeriod(e.target.value)}
          options={[
            { value: '7', label: i18n.t('scale.smartShipping.last7')},
            { value: '15', label: i18n.t('scale.smartShipping.last15')},
            { value: '30', label: i18n.t('scale.smartShipping.last30')},
            { value: '90', label: i18n.t('scale.smartShipping.last90')},
            { value: 'all', label: i18n.t('scale.smartShipping.all')}
          ]}
        />  
        <Grid container rowGap="1.5rem">
          <Grid item xs={6} md={3} borderLeft={"3px solid #2E7D32"} paddingLeft={"1rem"}>
            <Typography variant='subtitle2'>{i18n.t('scale.smartShipping.quotes')}</Typography>
            <Typography variant='h1'>{data?.total_rates || 0}</Typography>
          </Grid>
          <Grid item xs={6} md={3} borderLeft={"3px solid #2E7D32"} paddingLeft={"1rem"}>
            <Typography variant='subtitle2'>{i18n.t('scale.smartShipping.ZIPCodeDestination')}</Typography>
            <Typography variant='h1'>{data?.uniquePostalCodes || 0}</Typography>
          </Grid>
          <Grid item xs={6} md={3} borderLeft={"3px solid #2E7D32"} paddingLeft={"1rem"}>
            <Typography variant='subtitle2'>{i18n.t('scale.smartShipping.generalMedia')}</Typography>
            <Typography variant='h1'>R${data?.average_price_all || 0}</Typography>
          </Grid>
          <Grid item xs={6} md={3} borderLeft={"3px solid #2E7D32"} paddingLeft={"1rem"}>
            <Typography variant='subtitle2'>{i18n.t('scale.smartShipping.mediaV1')}</Typography>
            <Typography variant='h1'>R${data?.average_price_v1 || 0}</Typography>
          </Grid>
        </Grid>   
      </Paper>
    </Box>
  )
}

const mapStateToProps = (state) => ({
  tokenApi: state.scale.tokenApi,
  smartShipping: state.scale.smartShipping,
  shop: state.auth.shop,
})

export default connect(mapStateToProps, {...customerActions, ...scaleActions})(SmartShipping)