import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import {Form, FormGroup, Label, Input } from 'reactstrap';
import { Typography } from '@mui/material';
import {connect} from 'react-redux'
import AlertDanger from '../../components/AlertDanger'
import AlertSuccess from '../../components/AlertSuccess'
import SpinnerUp from '../../components/SpinnerUp'
import * as actions from '../../store/actions/users'
import { i18n } from '../../translate/i18n';

class UpdatePerfilPass extends Component{

    state = {
        password: "",
        error: "",
        success: "",
        loading: false,
    }

    onChangeInput = (ev) => {
        this.setState({[ev.target.name]: ev.target.value})
    }

    validate(){
        const {password} = this.state;
        if(!password){
            return this.setState({error: {message:  i18n.t('updatePerfilPass.informPassword')}})
        }else if(password.length < 6){
            return this.setState({error: {message:  i18n.t('updatePerfilPass.minChars')}})
        }

        return true;
    }

    

    handleUpdate(){
        this.setState({success: ""});
        const {password} = this.state;
        if(!this.validate()) return
        
        this.setState({loading: true});

        this.props.handleUpdatePerfil({password}, (msg) => {
            if(msg.error.error){
                this.setState({error: {message: msg.error.message}})                
                this.setState({success: ""});
            }else{
                this.setState({error: ""});
                this.setState({success: {message: msg.error.message}});
            }
            
            this.setState({loading: false});
        });

    }

    render(){
        const {password, loading, error, success} = this.state
        return(
            <div className='p-4'>
                <div className="d-flex">
                <div className="mr-auto p-2">
                    <Typography variant="h1">{i18n.t('updatePerfilPass.editPassword')}</Typography>
                </div>
                <Link to={"/perfil"}>
                    <button className="ml-1 btn btn-primary btn-sm">{i18n.t('updatePerfilPass.perfil')}</button>
                </Link>
            </div>
            <AlertDanger error={error}/>
                <AlertSuccess error={success}/>
            <Form>
                    <FormGroup>
                        <Label for="password">{i18n.t('updatePerfilPass.newPassword')}</Label>
                        <Input type="password" value={password} name="password" id="password" placeholder={i18n.t('updatePerfilPass.enterNewPassword')} autoComplete="password" onChange={(ev) => this.onChangeInput(ev)}/>  
                    </FormGroup>
                    <Link onClick={() => this.handleUpdate()} to="#">
                        <SpinnerUp loading={loading}></SpinnerUp>
                    </Link>
                </Form>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    userDetails: state.user.userDetails
})

export default connect(mapStateToProps, actions)(UpdatePerfilPass);