import React, { useState, useEffect, useRef, useContext } from "react";
import { AppBar, Badge, Box, CircularProgress, IconButton, List, ListItem, Toolbar, Typography } from "@mui/material";
import { connect } from "react-redux";
import { sendMessage, getMessages, readMessages, getNoReadMessagesCustomer, getMessagesAdd, getFiles, getMarks } from "../../store/actions/messages";
import ChatHeader from "../../components/Chat/ChatHeader";
import ChatBody from "../../components/Chat/ChatBody";
import ChatInput from "../../components/Chat/ChatInput";
import ChatConversation from "../../components/Chat/ChatConversation";
import '../../styles/dashboard/Chat.css';
import { SocketContext } from "../../contexts/SocketContext";
import ChatHeaderDrawer from "../../components/Chat/ChatHeaderDrawer";
import { isMobile } from "react-device-detect";
import ChatThread from "../../components/Chat/ChatThread";
import { muteChatChannel } from "../../store/actions/users";
import { userPermissionCheck } from "../Sprint/utils/userPermissionCheck";
import { i18n } from "../../translate/i18n";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { getTaggedUsers } from "./utils/getTaggedUsers";
import { containsOnlyEmptyTags } from "./utils/containsOnlyEmptyTags";

const deparaPlataforma = {
  start: {
    text: i18n.t('components.chat.chatSideBarAdm.implantation'),
  },
  sprint: {
    text: i18n.t('components.chat.chatSideBarAdm.performance'),
  },
  scale: {
    text: i18n.t('components.chat.chatSideBarAdm.operation'),
  },
  suporte: {
    text: i18n.t('components.chat.chatSideBarAdm.support'),
  },
  administrative: {
    text: i18n.t('components.chat.chatSideBarAdm.administrative'),
  },
}

const Chat = (props) => {
  const socket = useContext(SocketContext);
  const divRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [loadingMessages, setLoadingMessages] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [activeConversation, setActiveConversation] = useState(props.match.params.platform);
  const [platforms, setPlatforms] = useState([]);
  const shop = localStorage.getItem('shop');
  const [messages, setMessages] = useState([]);
  const [layout, setLayout] = useState('geral');

  const [thread, setThread] = useState({});
  const [threadMessages, setThreadMessages] = useState([]);
  const [unreadThreads, setUnreadThreads] = useState([]);

  const [oldMessages, setOldMessages] = useState(true);
  const [newMessages, setNewMessages] = useState(false);

  const [openDrawer, setOpenDrawer] = useState(false);
  const [isSidebarOpen, setSidebarOpen] = useState(true);

  useEffect(() => {
    if (props.match.params.store) 
      localStorage.setItem('shop', props.match.params.store);
  }, [props.match.params.store]);

  useEffect(() => {
    setOldMessages(true);
  }, [activeConversation, props.position]);

  useEffect(() => {
    if (!socket) return;
    let activeConversationValue = activeConversation;

    const receiveMessageHandler = async (data) => {
      if (data.message.author._id !== props.user._id) {
        if (data.message?.platform === activeConversationValue && data.message?.customer === shop) {
          props.readMessages({ customer: shop, platform: activeConversationValue })
          if (!newMessages) {
            if (data.message.parent && thread?._id === data.message.parent && thread?._id) {
              setThreadMessages(prev => ([...prev, data.message]));
              setMessages((prevMessages) => {
                return prevMessages.map((message) => {
                  if (message._id === data.message.parent) {
                    message.children = [...message.children, data.message];
                    return message;
                  }
                  return message;
                });
              });
            } else if (!data.message.parent) {
              setMessages((prevMessages) => [...prevMessages, data.message]);
            } else {
              setMessages((prevMessages) => {
                return prevMessages.map((message) => {
                  if (message._id === data.message.parent) {
                    message.children = [...message.children, data.message];
                    return message;
                  }
                  return message;
                });
              });
            }
          }
        }
      }
    };

    const messageEditedHandler = async (data) => {
      if (data.message?.platform === activeConversationValue && data.message?.customer === shop && !newMessages) {
        setMessages((prevMessages) => {
          return prevMessages.map((message) => {
            if (message._id === data.message._id) {
              return data.message;
            }
            return message;
          });
        });
      }
    };

    const connectHandler = () => {
      if (activeConversation !== 'platform') {
        props.getMessages({ customer: shop, platform: activeConversation });
        props.getFiles({ customer: shop, platform: activeConversation, limit: 4 });
        props.getMarks({ customer: shop, platform: activeConversation, limit: 4 });
        props.readMessages({ customer: shop, platform: activeConversation });
      }
    };

    socket.on("connect", connectHandler);
    socket.on('receiveMessage', receiveMessageHandler);
    socket.on('messageEdited', messageEditedHandler);

    return () => {
      socket.off('receiveMessage', receiveMessageHandler);
      socket.off('messageEdited', messageEditedHandler);
      socket.off("connect", connectHandler);
    };
  }, [props.user, shop, activeConversation, newMessages, thread]);


  const handleSendMessage = async (event, files, action, taggedData) => {
    event.preventDefault();
    if (containsOnlyEmptyTags(inputValue) && files.length === 0 && !action) return;
    
    const taggedUsersData = getTaggedUsers(inputValue);
    const taggedUsers = taggedUsersData.filter((tagged) => taggedData?.includes(tagged));
    const formData = new FormData();
    files.forEach((file) => {
      formData.append("files", file);
    });
    formData.append("text", inputValue);
    formData.append("platform", activeConversation);
    if (action) formData.append("action", action);
    if (taggedUsers) formData.append("taggedUsers", taggedUsers);
    const newMessage = await props.sendMessage(formData, shop);
    setMessages([...messages, newMessage]);

    setInputValue("");
    scrollToBottom();
  };


  useEffect(() => {
    if (props.messages) {
      setMessages(props.messages)
    }
  }, [props.messages]);

  const loadMoreMessages = async () => {
    if (oldMessages === false) return;
    const newMessages = await props.getMessagesAdd({ customer: shop, platform: activeConversation, beforeId: messages[0]?._id });
    if (newMessages.length === 0) {
      setOldMessages(false);
      setLoading(false);
      return;
    }
    setMessages((prevMessages) => {
      return [...newMessages, ...prevMessages];
    });
  };

  const loadMoreMessagesNew = async () => {
    if (newMessages === false) return;
    const newMessagesAux = await props.getMessagesAdd({ customer: shop, platform: activeConversation, afterId: messages[messages.length - 1]?._id });
    if (newMessagesAux.length === 0) {
      setNewMessages(false);
      setLoading(false);
      return;
    }
    setMessages((prevMessages) => {
      return [...prevMessages, ...newMessagesAux];
    });
  };
  
  const getConversastionData = async () => {
    setLoadingMessages(true);
    props.getFiles({ customer: shop, platform: activeConversation, limit: 4 })
    props.getMarks({ customer: shop, platform: activeConversation, limit: 4 })
    props.readMessages({ customer: shop, platform: activeConversation })
    await props.getMessages({ customer: shop, platform: activeConversation })
    setLoadingMessages(false);
  }

  useEffect(() => {
    if (activeConversation === 'platform') setUnreadThreads([]);
    getConversastionData();
    setThread({});
  }, [activeConversation, shop]);

  useEffect(() => {
    props.getNoReadMessages({ shop: shop })
  }, [activeConversation, shop, props.messages, messages]);

  useEffect(() => {
    setUnreadThreads(messages.filter(message => message.children.some(child => !child.read?.includes(props.user?._id))))
  }, [messages]);

  const scrollToBottom = () => {
    const chatContainer = document.getElementById("chat-container");
    if (chatContainer) {
      chatContainer.scrollTop = chatContainer.scrollHeight + 90;
    }
  };

  const handleInputChange = (text) => {
    setInputValue(text);
  };

  useEffect(() => {
    if(thread?._id) setOpenDrawer(false);
  }, [thread]);

  useEffect(() => {
    if (openDrawer) setThread({});
  }, [openDrawer]);

  useEffect(() => {
    setActiveConversation(props.match.params.platform);
  }, [props.match.params]);

  useEffect(() => {
    const platformArr = props.user?.customer?.find(customer => customer._id === localStorage.getItem('shop'))?.platform?.map(el => ({
      taggedAmount: props.noReadMessages?.['tagged_' + el] || 0,
      unreadMessages: props.noReadMessages?.[el] || 0,
      text: deparaPlataforma[el].text,
      conversation: el
    })).sort((a, b) => b.taggedAmount - a.taggedAmount || b.unreadMessages - a.unreadMessages);

    setPlatforms(platformArr || []);
  }, [props.noReadMessages]);

  return (
    <Box sx={{
      height: isMobile ? `calc(100vh - ${(activeConversation === 'platform') ? '210px' : '255px'})` : "calc(100vh - 4rem)",
      display: "flex", 
      position: 'relative',
      overflow: 'hidden'
    }}>

      {
        (activeConversation === 'platform' || !isMobile) && (
          <Box 
            className={`chat-sidebar ${isSidebarOpen ? '' : 'minimized'}`}
            sx={{
              opacity: isSidebarOpen ? 1 : 0,
              visibility: isSidebarOpen ? 'visible' : 'hidden',
              transition: 'all 0.5s ease',
            }}
          >
            {
              isMobile ? (
                <AppBar position="relative" 
                sx={{
                  backgroundColor: '#F3F3F3',
              }}>
                  <Toolbar>
                      <Typography variant="h6" style={{ flexGrow: 1, color: 'black' }} align="center">
                          {i18n.t('components.chat.chatSideBar.channels')}
                      </Typography>
                  </Toolbar>
              </AppBar>
              ) : (
                <Box display='flex' width='100%' padding='1rem' alignItems='center'>
                  <IconButton
                      sx={{ color: '#888' }}
                      onClick={() => {
                        setSidebarOpen(!isSidebarOpen)
                      }}
                  >
                      <ArrowBackIosNewIcon  sx={{ width: '20px', height: '20px', rotate: !isSidebarOpen && '180deg' }}/>
                  </IconButton>
                  <Typography variant="h6" style={{ flexGrow: 1 }} align="center">
                    {i18n.t('components.chat.chatSideBar.channels')}
                  </Typography>
                </Box>
              )
            }
            <List>
              {platforms.map(({ conversation, unreadMessages, taggedAmount, text }, index) => {
                return userPermissionCheck(props.user, [`chat_${conversation}`]) && (
                  <ListItem
                    key={conversation}
                    onClick={() => setActiveConversation(conversation)}
                    sx={{
                      cursor: "pointer",
                      display: "flex",
                      justifyContent: "space-between",
                      backgroundColor: activeConversation === conversation ? "#e3e3e3" : "inherit",
                      padding: '16px 15px 16px 15px',
                      borderTop: index === 0 ?  'none' : '1px solid #E0E0E0',
                      borderBottom: '1px solid #E0E0E0',
                      '&:hover': {
                        backgroundColor: "#e3e3e3 !important",
                      }
                    }}
                  >
                    <Typography fontWeight={unreadMessages > 0 ? 700 : 400}>{text}</Typography>
                    <Badge badgeContent={unreadMessages} color="greenNotification" sx={{marginRight: "0.5rem"}}/>
                  </ListItem>
                );
              })}
            </List>
          </Box>
        )
      }
      <Box 
        sx={{ 
          display: ((openDrawer || thread?._id) && isMobile) ? 'none' : 'flex',
          flexGrow: 1, 
          flexDirection: "column", 
          overflowX: 'hidden',
          marginRight: ((openDrawer || thread?._id) && !isMobile) ? '375px' : '0px',
          marginLeft: (isSidebarOpen && !isMobile) ? '350px' : '0px',
          transition: "all 0.5s ease", 
        }}
      >
        {
          (!isMobile || ['start', 'sprint', 'scale', 'suporte', 'administrative'].includes(activeConversation) && !thread?._id && !openDrawer) && (
            <ChatHeader 
              setThread={setThread}
              unreadThreads={unreadThreads} 
              setUnreadThreads={setUnreadThreads}
              activeConversation={activeConversation} 
              setActiveConversation={setActiveConversation}
              setOpenDrawer={setOpenDrawer} 
              setSidebarOpen={setSidebarOpen}
              openDrawer={openDrawer} 
              isSidebarOpen={isSidebarOpen}
            />
          )
        }

          {
            ((!thread?._id && !openDrawer && activeConversation !== 'platform' && isMobile) || !isMobile) &&
          (
            loadingMessages ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
              <CircularProgress />
            </Box>
          ) : 
          (
            <>
              <ChatBody
                loadMoreMessages={loadMoreMessages}
                messages={messages}
                user={props.user}
                divRef={divRef}
                loading={loading}
                setLoading={setLoading}
                activeConversation={activeConversation}
                oldMessages={oldMessages}
                newMessages={newMessages}
                loadMoreMessagesNew={loadMoreMessagesNew}
                setThread={setThread}
              >
                <ChatConversation
                  conversation={messages}
                  user={props.user}
                />
              </ChatBody>

              {activeConversation !== 'platform' && (
                <ChatInput
                  inputValue={inputValue}
                  customer={shop}
                  handleInputChange={handleInputChange}
                  handleSendMessage={handleSendMessage}
                  platform={activeConversation}
                />
              )}
            </>
          )
        )}
      </Box>
      <ChatHeaderDrawer 
        muteChatChannel={props.muteChatChannel} 
        user={props.user} 
        open={openDrawer}
        openDrawer={setOpenDrawer} 
        getMessages={props.getMessages} 
        files={props.files} 
        getFiles={props.getFiles} 
        marks={props.marks} 
        getMarks={props.getMarks} 
        shop={shop} 
        activeConversation={activeConversation} 
        newMessages={newMessages} 
        setNewMessages={setNewMessages} 
        layout={layout}
        setLayout={setLayout}
      />
      <ChatThread 
        thread={thread} 
        setThread={setThread}
        threadMessages={threadMessages}
        setThreadMessages={setThreadMessages} 
        setMessages={setMessages}        
      />
    </Box>
  );
};

const mapStateToProps = state =>
({
  noReadMessages: state.message.noReadMessages || [],
  user: state.auth.user,
  messages: state.message.messages || [],
  noReadMessages: state.message.noReadMessages || [],
  files: state.message.files || [],
  marks: state.message.marks || [],
  position: state.message.position || null
})


const mapDispatchToProps = (dispatch) => {
  return {
    sendMessage: (props, shop) => dispatch(sendMessage(props, shop)),
    getMessages: (props) => dispatch(getMessages(props)),
    getFiles: (props) => dispatch(getFiles(props)),
    getMarks: (props) => dispatch(getMarks(props)),
    getMessagesAdd: (props) => dispatch(getMessagesAdd(props)),
    readMessages: (props) => dispatch(readMessages(props)),
    getNoReadMessages: (props) => dispatch(getNoReadMessagesCustomer(props)),
    muteChatChannel: (props) => dispatch(muteChatChannel(props)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Chat);