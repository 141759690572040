import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';

import * as insightsActions from '../../../store/actions/insights';
import * as scaleActions from '../../../store/actions/scale';
import * as customerActions from '../../../store/actions/customers';
import * as goalsActions from '../../../store/actions/goal';
import { apiMetrics, keyCrypto } from "../../../config"
import { i18n } from '../../../translate/i18n';
import { debounce } from 'lodash';
import { Box, Button, CircularProgress, Grid, Stack, Tab, Typography } from '@mui/material';
import ApexDashedChart from '../../../components/Insights/ApexDashedChart';
import { LoadingButton } from '@mui/lab';
import { FCCard, FCDatePicker, FCSelect, FCTabs } from 'components-fullcomm';
import GoalsModal from './Goals/GoalsModal';
import dayjs from 'dayjs';
import { abbreviateNumber } from '../utils/abbreviateNumber';
import GoalsTable from './Goals';

const Loading = () => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '350px' }}>
      <CircularProgress />
    </Box>
  )
}

const Results = (props) => {
  const [startDate, setStartDate] = useState(dayjs().subtract(1, 'month'));
  const [endDate, setEndDate] = useState(dayjs());
  const [loading, setLoading] = useState(true);
  const [loadingOrders, setLoadingOrders] = useState(true);
  const [sessionData, setSessionData] = useState([]);
  const [datesData, setDatesData] = useState([]);
  const [ordersData, setOrdersData] = useState([]);
  const [orderTypes, setOrderTypes] = useState([]);
  const [orderValues, setOrderValues] = useState([]);
  const [ordersDates, setOrdersDates] = useState([]);
  const [orderPaymentStatus, setOrderPaymentStatus] = useState([]);
  const [accRevenueData, setAccRevenueData] = useState([]);
  const [accOrdersAmount, setAccOrdersAmount] = useState([]);
  const [shippingOrdersData, setShippingOrdersData] = useState([]);
  const [conversionData, setConversionData] = useState([]);
  const [customerData, setCustomerData] = useState([]);
  const [modal, setModal] = useState(false);
  const [tab, setTab] = useState(0);
  const [editGoal, setEditGoal] = useState(null);
  const [aggregate, setAggregate] = useState('daily');

  const debounceGetGoals = useCallback(debounce(async () => {await props.getGoals(dayjs(startDate).format('YYYY-MM'))}, 500), [startDate]);

  const debouncedSearch = useCallback(
    debounce(async (platformDomain) => {
      // setLoading(true);
      props.fetchTokenInsights(platformDomain)
      try {
        const config_request = {
          method: "get",
          url: `${apiMetrics}/session/offline_${platformDomain}`,
          headers: {
            'fullcomm-token': keyCrypto
          }
        };
        const result = (await axios(config_request)).data;

      } catch (error) {
        // setLoading(false);
        return undefined;
      }
    }, 500),
    []
  );

  const debouncedGetCurvesAndOrders = useCallback(
    debounce(async (token, start, end, aggregate = 'daily') => {
      setLoadingOrders(true);
      setLoading(true);
      const startMonth = dayjs(start).format('YYYY-MM');
      start = dayjs(start).format('YYYY-MM-DD');
      end = dayjs(end).format('YYYY-MM-DD');
      const orderData = props.getOrdersInfo(token, start, end, aggregate);
      const productsData = props.getProductsInfo(token, start, end, aggregate);
      props.getPreviousPeriodProductsInfo(token, start, end, aggregate);
      props.getOrderShippingValue(start, end);
      props.getGoals(dayjs(startMonth).format('YYYY-MM'));
      await Promise.all([orderData]);
      setLoadingOrders(false);
      await Promise.all([productsData]);
      setLoading(false);
    }, 500),
    []
  );

  useEffect(() => {
    props.getViewCustomer(props.shop);
    props.fetchTokenShipping();
  }, [props.shop])

  useEffect(() => {
    if (props.token) {
      debouncedGetCurvesAndOrders(props.token, startDate, endDate, aggregate)
    }
  }, [props.token])

  useEffect(() => {
    if (props.customer) {
      debouncedSearch(props.customer.platformDomain);
    }
  }, [props.customer])

  useEffect(() => {
    if (props.productsInfo) {
      const dates = [];
      const sessionData = {
        name: 'Sessões',
        data: []
      }
      const previousData = {
        name: 'Período anterior',
        data: []
      }

      const conversionData = {
        name: 'Conversão',
        data: []
      }

      props.productsInfo?.data_over_time?.forEach((session) => {
        dates.push(session.date);
        sessionData.data.push(session.accumulated_sessions);
        conversionData.data.push(session.conversions);
      });

      props.previousPeriodProductsInfo?.previous_period?.forEach((session) => {
        previousData.data.push(session.accumulated_sessions);
      });

      setDatesData(dates);
      setSessionData([
        sessionData,
        previousData,
        {
          name: 'Meta',
          data: Array(dates?.length || 0)?.fill(0).map((val, index) => 
          props.goals?.find(goal => goal.name === 'sessions')?.[aggregate]*(index+1) || 0
          )
        }
      ]);
      setConversionData([
        conversionData,
        {
          name: 'Meta',
          data: Array(dates?.length || 0)?.fill(props.goals?.find(goal => goal.name === 'conversionRate')?.[aggregate] || 0)
        }
      ]);
    }
  }, [props.productsInfo, props.previousPeriodProductsInfo, props.goals])

  useEffect(() => {
    const processOrderType = (name, key) => ({
      name,
      data: props.ordersInfo?.orders_group?.map(order => order[key]?.amount || 0),
      type: 'column'
    });
  
    const processOrderValue = (name, key) => ({
      name,
      data: props.ordersInfo?.orders_group?.map(order => ((order[key]?.total_price || 0).toFixed(0))),
      type: 'column'
    });
  
    const processCustomerData = (name, key) => ({
      name,
      data: props.ordersInfo?.orders_group?.map(order => order.customers?.[key] || 0),
    });

    const processOrderPaymentStatus = (name, key) => ({
      name,
      data: props.ordersInfo?.orders_group?.map(order => order.payment_method?.[key] || 0),
    });

    const processRevenueData = (name, key) => ({
      name,
      data: props.ordersInfo?.orders_group?.map(order => order.accumulated.total_value || 0)
    });

    const processAcumulatedOrders = (name, key) => ({
      name,
      data: props.ordersInfo?.orders_group?.map(order => order.accumulated.orders_amount || 0)
    });

    if (props.ordersInfo) {
      const dates = props.ordersInfo.orders_group.map(order => order.date);
      const orderTypes = [{name: "Pagos", key: 'paid'}, {name: "Pendentes", key: "pending"}, { name: "Reembolsados", key:"refunded"}, { name: "Cancelados", key: "voided"}];

      setOrdersDates(dates);
      setOrdersData([
        { name: 'Ticket Médio', data: props.ordersInfo.orders_group.map(order => ((order.paid?.total_price || 0) / (order.paid?.amount || 1)).toFixed(2)) },
        { name: 'Meta', data: Array(dates.length).fill(props.goals?.find(goal => goal.name === 'averageTicket')?.[aggregate] || 0), type: 'line'}
      ]);
      setOrderTypes([
        ...orderTypes.map(item => processOrderType(item.name, item.key)),
        { name: 'Meta', data: Array(dates.length).fill(props.goals?.find(goal => goal.name === 'ordersAmount')?.[aggregate]), type: 'line'}]);
      setOrderValues([
        ...orderTypes.map(item => processOrderValue(item.name, item.key)),
        { name: 'Meta', data: Array(dates.length).fill(props.goals?.find(goal => goal.name === 'revenue')?.[aggregate]), type: 'line'}
      ]);
      setCustomerData([{name: 'Novos clientes', key: 'new'}, {name: 'Clientes recorrentes', key: 'recurring'}].map(item => processCustomerData(item.name, item.key)));
      setOrderPaymentStatus([{name: 'Sucesso', key: 'success'}, {name: 'Falha', key: 'failure'}, {name: 'Erro', key: 'error'}].map(item => processOrderPaymentStatus(item.name, item.key)));
      setAccRevenueData([
        processRevenueData('Faturamento'),
        {
          name: 'Meta',
          data: Array(dates?.length || 0)?.fill(0).map((val, index) => 
            props.goals?.find(goal => goal.name === 'revenue')?.[aggregate]*(index+1) || 0
          )
        }
      ]);
      setAccOrdersAmount([
        processAcumulatedOrders('Pedidos'),
        { 
          name: 'Meta', 
          data: Array(dates?.length || 0)?.fill(0).map((val, index) => 
          props.goals?.find(goal => goal.name === 'ordersAmount')?.[aggregate]*(index+1) || 0)
        },
      ]);
    }
  }, [props.ordersInfo, props.goals]);

  useEffect(() => {
    if (props.shippingOrders?.docs) {
      const shippingOrdersMap = new Map();
      props.shippingOrders?.docs?.forEach((order) => {
        const date = new Date(order.createdAt)
        const index = date.toISOString().split('T')[0];
        const orderGroup = shippingOrdersMap.get(index) || {
          orderPrice: order.shipping_line_order?.price || 0,
          orderQuote: order.shipping_line_quote?.price || 0,
        }
        if (orderGroup) {
          orderGroup.orderPrice += order.shipping_line_order?.price || 0;
          orderGroup.orderQuote += order.shipping_line_quote?.price || 0;
        }
        shippingOrdersMap.set(index, orderGroup);
      });
      const orderedShipping = Array.from(shippingOrdersMap.keys()).sort((a, b) => new Date(a) - new Date(b));

      const shippingOrders = [{
        name: 'Frete pago',
        data: []
      }, {
        name: 'Frete cotado',
        data: []
      }];

      for (const key of orderedShipping) {
        const orderGroup = shippingOrdersMap.get(key);
        shippingOrders[0].data.push(orderGroup?.orderPrice.toFixed(2) || 0);
        shippingOrders[1].data.push(orderGroup?.orderQuote.toFixed(2) || 0);
      }
      setShippingOrdersData([shippingOrders, orderedShipping.map(item => item)]);
    }
  }, [props.shippingOrders]);


  return (
    <Box className="dashboard">
      <Typography variant="h4" mb="1.5rem">{i18n.t('sprint.results.results')}</Typography>
      <Stack direction="row" justifyContent="flex-start" alignItems="end" spacing={2} mt={2} >
        <FCDatePicker
          value={startDate} 
          onChange={e => setStartDate(e)} 
          // views={['year', 'month']}
          label={i18n.t('insight.startDate')} 
          id="date"
          type="date" 
          InputLabelProps={{ shrink: true }} 
        />
        <FCDatePicker
          value={endDate} 
          onChange={e => setEndDate(e)} 
          id="date" 
          label={i18n.t('insight.endDate')} 
          type="date" 
          InputLabelProps={{ shrink: true }} 
        />
        <FCSelect
          label='Agrupar'
          value={aggregate}
          onChange={(e) => setAggregate(e.target.value)}
          options={[
            {label: 'Dia', value: 'daily'},
            {label: 'Semana', value: 'weekly'},
            {label: 'Mês', value: 'monthly'},
            {label: 'Trimestre', value: 'quarterly'},
            {label: 'Ano', value: 'yearly'},
          ]}
        />
        <LoadingButton onClick={() => {
          debouncedGetCurvesAndOrders(props.token, startDate, endDate, aggregate);
        }} 
        loading={loading} 
        className='btn-white medium mb-1'
        variant="contained" 
      > 
        {i18n.t('insight.search')}
      </LoadingButton>
      <Button 
        sx={{
          marginLeft: 'auto !important',
          marginRight: '1rem'
        }}
        onClick={() => {
          setModal(true);
        }} 
        variant="contained" 
        className='btn-white large mb-1 float-right'
      >
        Adicionar Meta
      </Button>

      </Stack>
      <GoalsModal 
        open={modal} 
        setOpen={setModal}
        editGoal={editGoal} 
        setEditGoal={setEditGoal} 
        debounceGetGoals={debounceGetGoals} 
      />
          <FCTabs value={tab} onChange={(e, newValue)=> setTab(newValue)}>
            <Tab label="Visão Geral" value={0} />
            <Tab label="Metas" value={1}/>
          </FCTabs>
            {
              tab === 0 ? (
                <Grid container spacing={2} mt='1rem'>
                  <Grid item xs={12} md={6} lg={4}>
                    <FCCard>
                      {
                        loading ? <Loading />
                         : (
                          <ApexDashedChart 
                            seriesData={sessionData} 
                            categories={datesData} 
                            title={"Sessões - " + abbreviateNumber(props.productsInfo?.data_over_time[props.productsInfo?.data_over_time?.length -1]?.accumulated_sessions || 0)} 
                            subtitle={"Meta: " + abbreviateNumber((props.goals?.find(goal => goal.name === 'sessions')?.[aggregate] || 0)* datesData?.length || 0)}
                            colors={['#26a0fc', '#febc3b', '#888888']}
                            dashArray={[0, 0, 5]}
                          />
                        )
                      }
                    </FCCard>
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    <FCCard>
                      {
                        loading ? <Loading />
                        : (
                          <ApexDashedChart 
                            seriesData={conversionData} 
                            categories={datesData} 
                            title={"Conversão - " + ((props.ordersInfo?.orders_group?.reduce((acc, curr) => acc += curr?.paid?.amount || 0, 0) || 0)*100/props.productsInfo?.data_over_time[props.productsInfo?.data_over_time?.length -1]?.accumulated_sessions || 1).toFixed(2) + '%'} 
                            subtitle={"Meta: " + abbreviateNumber(props.goals?.find(goal => goal.name === 'conversionRate')?.[aggregate]) + '%'}
                            colors={['#26a0fc', '#888888']}
                            dashArray={[0, 5]}
                          />
                        )
                      }
                    </FCCard>
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    <FCCard>
                      {
                        loadingOrders ? <Loading />
                        : (
                          <ApexDashedChart 
                            seriesData={ordersData} 
                            categories={ordersDates} 
                            title={"Ticket Médio - " + abbreviateNumber(props.ordersInfo?.average_ticket || 0, true)} 
                            subtitle={"Meta: " + abbreviateNumber(props.goals?.find(goal => goal.name === 'averageTicket')?.[aggregate], true)}
                            type={'area'} 
                            currency={true}
                            colors={['#26a0fc', '#888888']}
                            dashArray={[0, 5]}
                          />
                        )
                      }
                    </FCCard>
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    <FCCard>
                      {
                        loadingOrders ? <Loading />
                        : (
                          <ApexDashedChart 
                            seriesData={orderValues} 
                            categories={ordersDates} 
                            title={"Valores de pedidos - " + abbreviateNumber((props.ordersInfo?.total_price || 0) / (ordersDates?.length || 1), true)} 
                            subtitle={"Meta: " + (abbreviateNumber(props.goals?.find(goal => goal.name === 'revenue')?.[aggregate] || 0, true))}
                            stacked={true} 
                            currency={true} 
                            colors={['#26a0fc', '#4eca80', '#febc3b', '#e74c3c', '#888888']}
                            dashArray={[0, 0, 0, 0, 5]}
                          />
                        )
                      }
                    </FCCard>
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    <FCCard>
                      {
                        loadingOrders ? <Loading />
                        : (
                          <ApexDashedChart 
                            seriesData={accRevenueData} 
                            categories={ordersDates} 
                            title={"Faturamento - " + abbreviateNumber(props.ordersInfo?.total_price || 0, true)} 
                            subtitle={"Meta: " + abbreviateNumber((props.goals?.find(goal => goal.name === 'revenue')?.[aggregate] || 0)* ordersDates?.length || 0, true)}
                            currency={true} 
                            colors={['#26a0fc', '#888888']}
                            dashArray={[0, 5]}
                          />
                      )
                    }
                    </FCCard>
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    <FCCard>
                    {
                        loadingOrders ? <Loading />
                        : (
                          <ApexDashedChart 
                            seriesData={accOrdersAmount} 
                            categories={ordersDates} 
                            title={"Pedidos - " + abbreviateNumber(props.ordersInfo?.number || 0)} 
                            subtitle={"Meta: " + abbreviateNumber((props.goals?.find(goal => goal.name === 'ordersAmount')?.[aggregate] || 0)* ordersDates?.length || 0)}
                            colors={['#26a0fc', '#888888']}
                            dashArray={[0, 5]}
                          />
                      )
                    }
                    </FCCard>
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    <FCCard>
                      {
                        loadingOrders ? <Loading />
                        : (
                          <ApexDashedChart 
                            seriesData={orderTypes} 
                            categories={ordersDates} 
                            title={"Tipos de pedidos - " + abbreviateNumber((props.ordersInfo?.number || 0) / (ordersDates?.length || 1))} 
                            subtitle={"Meta: " + abbreviateNumber(props.goals?.find(goal => goal.name === 'ordersAmount')?.[aggregate])}
                            stacked={true} 
                            colors={['#26a0fc', '#4eca80', '#febc3b', '#e74c3c', '#888888']}
                            dashArray={[0, 0, 0, 0, 5]}
                          />
                        )
                      }
                    </FCCard>
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    <FCCard>
                      {
                        loadingOrders ? <Loading />
                        : (
                          <ApexDashedChart seriesData={orderPaymentStatus} categories={ordersDates} title={"Aprovação método de pagamento"} type={'area'} />
                        )
                      }
                    </FCCard>
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    <FCCard>
                      {
                        loadingOrders ? <Loading />
                        : (
                          <ApexDashedChart seriesData={customerData} categories={ordersDates} title={"Clientes"} type={'area'} />
                        )
                      }
                    </FCCard>
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    <FCCard>
                      {
                        loadingOrders ? <Loading />
                        : (
                          <ApexDashedChart seriesData={shippingOrdersData[0]} categories={shippingOrdersData[1]} title={"Frete pago vs frete cotado (%)"} />
                        )
                      }
                    </FCCard>
                  </Grid>
                </Grid>
              ) : (
                <>
                  <br/>
                  <GoalsTable
                    aggregate={aggregate}
                    goals={props.goals}
                    ordersInfo={props.ordersInfo}
                    productsInfo={props.productsInfo}
                    setEditGoal={setEditGoal}
                    setModal={setModal}
                    deleteGoal={props.deleteGoal}
                    debounceGetGoals={debounceGetGoals}
                  />
                </>
              )
            }
    </Box>
  )
}

const mapStateToProps = (state) => ({
  shop: state.auth.shop,
  goals: state.goals.goals,
  customer: state.customer.customerDetails,
  token: state.insight.tokenApi,
  productsInfo: state.insight.productsInfo,
  previousPeriodProductsInfo: state.insight.previousPeriodProductsInfo,
  ordersInfo: state.insight.ordersInfo,
  shippingOrders: state.scale.smartShippingOrders,
})

const mapDispatchToProps = (dispatch) => ({
  fetchTokenInsights: (platformDomain) => dispatch(insightsActions.fetchToken(platformDomain)),
  fetchTokenShipping: () => dispatch(scaleActions.fetchToken()),
  getViewCustomer: (shop) => dispatch(customerActions.getViewCustomer(shop)),
  getProductsInfo: (token, start, end, aggregate) => dispatch(insightsActions.getProductsInfo(token, start, end, aggregate)),
  getOrdersInfo: (token, start, end, aggregate) => dispatch(insightsActions.getOrdersInfo(token, start, end, aggregate)),
  getOrderShippingValue: (start, end) => dispatch(scaleActions.getOrderShippingValue(start, end)),
  getPreviousPeriodProductsInfo: (token, start, end, aggregate) => dispatch(insightsActions.getPreviousPeriodProductsInfo(token, start, end, aggregate)),
  deleteGoal: (id) => dispatch(goalsActions.deleteGoal(id)),
  getGoals: (query) => dispatch(goalsActions.getGoals(query)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Results);