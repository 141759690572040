import { Typography } from '@mui/material'
import React from 'react'
import { i18n } from '../../../translate/i18n';

const Status = ({url}) => {
  return url ? (
    <div style={{ height: '150%'}} className='embed-responsive embed-responsive-1by1'>
      <iframe
        src={`https://status.checkstore.com.br/${url}`}
        className='embed-responsive-item'
        allowFullScreen
      />
    </div>
  ) : (
    <Typography variant='h4'>
      {i18n.t('support.performance.status.statusNotAvailable')}
    </Typography>
  )
}

export default Status