import React, { useEffect, useState } from 'react';

import { Link, useHistory } from 'react-router-dom';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import SpinnerDelete from '../../components/SpinnerDelete'
import { connect } from 'react-redux'
import * as actions from '../../store/actions/users'
import { Pagination, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Box, Typography } from '@mui/material';
import { i18n } from '../../translate/i18n';

import { toast } from 'react-toastify';
import { userPermissionCheck } from '../Sprint/utils/userPermissionCheck';
import { FCDropdownButton, FCReturnButton } from 'components-fullcomm';

const Specific = (props) => {
    const history = useHistory();
    const [pageCurrent, setPageCurrent] = useState(1);
    const limit = 10;
    const [loading, setLoading] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [idDelete, setIdDelete] = useState("");
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    useEffect(() => {
        props.getUsersSpecific(props.match.params.role, props.match.params.store, pageCurrent, limit)
    }, [pageCurrent]);

    const deleteUser = async () => {
        setLoading(true)
        try {
            const result = await props.deleteUser(idDelete)
            await props.getUsers(pageCurrent, limit)
            if (result.error) {
                switch (result.error.message) {
                    case "Usuário não existe!": return toast.error(i18n.t('user.specific.userNotExist'));
                    case "Erro ao excluir!": return toast.error(i18n.t('user.specific.deleteError'));
                    default: return toast.error(i18n.t('user.specific.internalError'));
                }
            } else {
                if (result.message === "Usuário apagado com sucesso!") {
                    toast.success(i18n.t('user.specific.deleteSuccess'));
                } else {
                    toast.success(result.message);
                }
            }
        } catch (error) {
            if (error.error.message === "Erro interno do servidor.") {
                toast.error(i18n.t('user.specific.internalError'));
            } else {
                toast.error(error.error.message);
            }
        }
        setLoading(false)
        toggleModal();
    }

    const toggleModal = (_id = "") => {
        if (_id) {
            setIdDelete(_id)
        } else {
            setIdDelete("")
        }
        setOpenModal(!openModal);
    }

    let users = [];

    if (props.users) {
        users = props.users.docs
    }

    return (
        <div class="p-4">
            <FCReturnButton margin={"0rem"}/>
            <div className="d-flex">
                <div className="mr-auto p-2">
                    <Typography variant="h1">{i18n.t('user.specific.users')}</Typography>
                </div>
                {
                    userPermissionCheck(props.user, ["create_user"]) && (
                        <Link to={`/add-user/${props.match.params.role}/${props.match.params.store}`}>
                            <Button className="btn btn-custom-1 btn-sm">{i18n.t('user.specific.register')}</Button>
                        </Link>
                    )
                }
            </div>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>{i18n.t('user.specific.id')}</TableCell>
                            <TableCell>{i18n.t('user.specific.name')}</TableCell>
                            <TableCell>{i18n.t('user.specific.email')}</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {users.map(row => (
                            <TableRow
                                key={row._id}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell>
                                    {row._id}
                                </TableCell>
                                <TableCell>{row.name}</TableCell>
                                <TableCell>{row.email}</TableCell>
                                <TableCell align="right">
                                    {userPermissionCheck(props.user, ["edit_user", "delete_user", "edit_user_permissions"]) && (
                                        <FCDropdownButton
                                            items={[
                                                userPermissionCheck(props.user, ["edit_user"]) ? i18n.t('user.specific.edit') : '',
                                                userPermissionCheck(props.user, ["delete_user"]) ? i18n.t('user.specific.delete') : '',
                                                userPermissionCheck(props.user, ["edit_user_permissions"]) ? i18n.t('user.specific.permissions') : ''
                                            ]}
                                            itemClickHandlers={[
                                                () => history.push("/update-user/customer/" + props.match.params.store + "/" + row._id),
                                                () => toggleModal(row._id),
                                                () => history.push("/permissions/customer/" + row._id)
                                            ]}
                                        />
                                    )}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <br />
            {!props.users ? "" :
                <Box display="flex" justifyContent="center" alignItems="center">
                    <Pagination showFirstButton showLastButton variant="outlined" shape="rounded" count={props.users.totalPages} page={pageCurrent} onChange={(e, page) => setPageCurrent(page)} />
                </Box>
            }

            <Modal isOpen={openModal} >
                <ModalHeader toggle={() => toggleModal()}>{i18n.t('user.specific.deleteUser')}</ModalHeader>
                <ModalBody>
                    {i18n.t('user.specific.confirmDelete')}
                </ModalBody>
                <ModalFooter>
                    <span onClick={() => deleteUser()}>
                        <SpinnerDelete text={true} loading={loading} />
                    </span>
                    <Button size="sm" color="secondary" onClick={() => toggleModal()}>{i18n.t('user.specific.cancel')}</Button>
                </ModalFooter>
            </Modal>
        </div>
    )
}


const mapStateToProps = state => ({
    users: state.user.users,
    user: state.auth.user
})

export default connect(mapStateToProps, actions)(Specific);

