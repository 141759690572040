const form_reducers = (state = {}, actions) => {
    switch(actions.type){
        case 'GET_FORM':
            return{
                ...state,
                form: actions.payload.form
            }
        default: 
            return state;
    }
}

export default form_reducers;