import React, { useState, useEffect, useContext } from "react";
import { useHistory } from 'react-router-dom';
import { SocketContext } from "../../contexts/SocketContext";

import { List, ListItemText, ListItemIcon, Badge, MenuItem, ListItemAvatar, Avatar, Box, Typography, ListItem } from "@mui/material";
import { connect } from "react-redux";
import { getNoReadMessagesCustomer, unreadMessages } from "../../store/actions/messages";
import SpeedIcon from '@mui/icons-material/Speed';
import ArticleIcon from '@mui/icons-material/Article';
import InventoryIcon from '@mui/icons-material/Inventory';
import DisplaySettingsIcon from '@mui/icons-material/DisplaySettings';
import startSvg from '../../assets/icon-implantacao.svg';
import sprintSvg from '../../assets/icon-performance.svg';
import scaleSvg from '../../assets/icon-operacao.svg';
import supportSvg from '../../assets/icon-suporte.svg';
import administrativeSvg from '../../assets/icon-administracao.svg';
import { i18n } from "../../translate/i18n";
import { Link, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import { userPermissionCheck } from "../../pages/Sprint/utils/userPermissionCheck";

const deparaPlataforma = {
  start: {
    text: i18n.t('components.chat.chatSideBarAdm.implantation'),
    svg: startSvg,
    img: <ArticleIcon />,
    route: '/chat/start',
    routeAdm: '/chat-adm?platform=start'
  },
  sprint: {
    text: i18n.t('components.chat.chatSideBarAdm.performance'),
    svg: sprintSvg,
    img: <SpeedIcon />,
    route: '/chat/sprint',
    routeAdm: '/chat-adm?platform=sprint'
  },
  scale: {
    text: i18n.t('components.chat.chatSideBarAdm.operation'),
    svg: scaleSvg,
    img: <InventoryIcon />,
    route: '/chat/scale',
    routeAdm: '/chat-adm?platform=scale'
  },
  suporte: {
    text: i18n.t('components.chat.chatSideBarAdm.support'),
    svg: supportSvg,
    img: <DisplaySettingsIcon />,
    route: '/chat/suporte',
    routeAdm: '/chat-adm?platform=suporte'
  },
  administrative: {
    text: i18n.t('components.chat.chatSideBarAdm.administrative'),
    svg: administrativeSvg,
    img: <SupervisorAccountIcon />,
    route: '/chat/administrative',
    routeAdm: '/chat-adm?platform=administrative'
  },
}
const ChatSideBar = (props) => {
  const socket = useContext(SocketContext);
  const location = useLocation();
  const history = useHistory();
  const [noReadMessages, setNoReadMessages] = useState(props.noReadMessages);
  const [conversations, setConversations] = useState(null);
  const [shop, setShop] = useState();
  const [hoveredIndex, setHoveredIndex] = useState(null);

  const handleNumberUnreadAdmin = (unreadMessages) => {
    return Object.values(unreadMessages).reduce((acc, msg) => {
      acc.suporte += msg.suporte || 0;
      acc.scale += msg.scale || 0;
      acc.start += msg.start || 0;
      acc.sprint += msg.sprint || 0;
      acc.administrative += msg.administrative || 0;
      return acc;
    }, { sprint: 0, scale: 0, start: 0, suporte: 0, administrative: 0 });
  };

  useEffect(() => {
    if (!socket) return;

    const receiveMessageHandler = async (data) => {
      props.getNoReadMessages({ shop: shop?._id })
    };

    if (props.user?.role !== "admin") socket.on('receiveMessage', receiveMessageHandler);

    return () => {
      socket.off('receiveMessage', receiveMessageHandler);
    };
  }, [props.user, shop]);

  useEffect(() => {
    const aux = props.user?.customer.find(customer => customer._id === localStorage.getItem('shop'));
    setShop(aux)
    setConversations(props.user?.role === "admin" ? ["start", "sprint", "scale", "suporte", "administrative"] : aux?.platform)
  }, [props.user, props.shop]);

  useEffect(() => {
    if (shop && props.user?.role !== "admin") props.getNoReadMessages({ shop: shop?._id });
  }, [shop]);

  useEffect(() => {
    if (props.noReadMessages) setNoReadMessages(props.user?.role === 'admin' ? handleNumberUnreadAdmin(props.noReadMessages) : props.noReadMessages);
  }, [props.noReadMessages]);

  const handleConversationChange = (index) => {
    if (props.user?.role === 'admin') return;
    let aux = noReadMessages;
    aux = {
      ...aux,
      [index]: 0,
    }
    setNoReadMessages(aux);
  };

  const handleMouseEnter = (index) => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };
  



  return props.isMobile ? (
    <div className="chat-sidebar-user" style={props.style}>

      <Typography variant="h4" style={{ padding: '8px 16px' }}>{i18n.t('components.chat.chatSideBar.channels')}</Typography>
      <List>
        {conversations?.map((conversation, index) => {
          const to = deparaPlataforma[conversation]?.[props.user?.role === 'admin' ? 'routeAdm' : 'route'];
          const isHovered = index === hoveredIndex;
          const unreadMessages = noReadMessages?.[conversation] || 0;
          return userPermissionCheck(props.user, [`chat_${conversation}`]) && (
            <ListItem
              component={Link} 
              to={to}
              button
              key={index}
              onClick={() => handleConversationChange(conversation)}
              onMouseEnter={() => handleMouseEnter(index)}
              onMouseLeave={handleMouseLeave}
              style={{
                backgroundColor: isHovered ? "#F5F5F5" : "inherit",
                borderRight: isHovered ? '5px solid #008060' : 'none'
              }}
            >
              <ListItemAvatar>
                <Avatar src={deparaPlataforma[conversation]?.svg}>{deparaPlataforma[conversation].text[0].toUpperCase()}</Avatar>
              </ListItemAvatar>
              <ListItemText primary={deparaPlataforma[conversation].text} />
              {unreadMessages > 0 && (
                <Box
                  sx={{
                    backgroundColor: "#686B6C",
                    borderRadius: "50%",
                    color: "white",
                    width: "20px",
                    height: "20px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "absolute",
                    right: isHovered ? "30px" : "35px",
                  }}
                >
                  <Typography variant="caption">{unreadMessages}</Typography>
                </Box>
              )}
            </ListItem>
          );
        })}
      </List>
    </div>
  ) : (
    <div >

      <List>
        {conversations?.map((conversation) => {
          const to = deparaPlataforma[conversation]?.[props.user?.role === 'admin' ? 'routeAdm' : 'route'];
          const unreadMessages = noReadMessages?.[conversation] || 0;
          
          return userPermissionCheck(props.user, [`chat_${conversation}`]) && (
            <MenuItem  key={to} onClick={() => handleConversationChange(conversation)} sx={{background: "#2b2b2b !important"}} className="li" component={Link} to={to}>
              <ListItemIcon>
                {deparaPlataforma[conversation]?.img}
              </ListItemIcon>
              <ListItemText style={location.pathname.startsWith(to) ? { color: '#00D6CF', fontWeight: '600' } : {}} disableTypography={true} sx={{ fontSize: '14px' }} primary={deparaPlataforma[conversation]?.text} />
              <Badge badgeContent={unreadMessages} color="error" />
            </MenuItem>
          );
        })}
      </List>
    </div>
  );
};

const mapStateToProps = state =>
({
  user: state.auth.user,
  noReadMessages: state.message.noReadMessages || [],
  shop: state.auth.shop,
})

const mapDispatchToProps = (dispatch) => {
  return {
    getNoReadMessages: (props) => dispatch(getNoReadMessagesCustomer(props)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChatSideBar);
