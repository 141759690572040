const activities_reducer = (state = {}, actions) => {
    switch(actions.type){
        case "GET_ACTIVITIES_VERSIONS":
            return{
                ...state,
                versions: actions.payload,
                versionTableActivities: actions.version
            }
        case "GET_ACTIVITIES":
            return{
                ...state,
                activities: actions.payload,
                categories: actions.categories,
            }
        default: 
            return state;
    }
}

export default activities_reducer;