import React from "react";
import ReactApexChart from "react-apexcharts";
import { chartLocale } from './locale'

function MultipleYAxis({ inventory, total }) {
    // get max value in created, inc, out and cancelled
    const max = Math.max(...total?.created, ...total?.inc, ...total?.out, ...total?.cancelled) + 10
    const data = {
        series: [{
            name: 'Estoque',
            type: 'line',
            data: inventory?.map(elem => elem.available),
        }, {
            name: 'Vendas',
            type: 'column',
            data: total?.created,
        }, {
            name: 'Entrada',
            type: 'column',
            data: total?.inc,
        }, {
            name: 'Saída',
            type: 'column',
            data: total?.out,
        }, {
            name: 'Cancelados',
            type: 'column',
            data: total?.cancelled,
        }]
        ,
        options: {
            chart: {
                locales: [chartLocale],
                defaultLocale: 'global',

                height: 500,
            },
            stroke: {
                width: [1, 4, 2, 2, 2]
            },
            xaxis: {
                type: 'datetime',
                categories: inventory?.map(elem => elem.date),
            },
            yaxis: [
                {
                    min: 0,
                    axisTicks: {
                        show: true,
                    },
                    axisBorder: {
                        show: true,
                        color: '#008FFB'
                    },
                    labels: {
                        style: {
                            colors: '#008FFB',
                        }
                    },
                    title: {
                        text: "Estoque",
                        style: {
                            color: '#008FFB',
                        }
                    },
                    tooltip: {
                        enabled: true
                    }
                },
                {
                    min: 0,
                    max,
                    opposite: true,
                    axisTicks: {
                        show: true,
                    },
                    axisBorder: {
                        show: true,
                    },
                    title: {
                        text: "Quantidade",
                    },
                    tooltip: {
                        enabled: true
                    }
                },
                {
                    min: 0,
                    max,
                    show: false,
                },
                {
                    min: 0,
                    max,
                    show: false,
                },
                {
                    min: 0,
                    max,
                    show: false
                },
            ],

            tooltip: {
                fixed: {
                    enabled: true,
                    position: 'topLeft',
                    offsetY: 30,
                    offsetX: 60
                },
            },
            legend: {
                horizontalAlign: 'left',
                offsetX: 40,
                offsetY: 10
            }
        },
    };

    return (
        <div id="chart">
            <ReactApexChart options={data.options} series={data.series} type="line" height={500} />
        </div>
    );
}

export default MultipleYAxis;
