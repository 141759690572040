import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import {Form, FormGroup, Label, Input } from 'reactstrap';
import {connect} from 'react-redux'
import { Typography } from '@mui/material';
import AlertDanger from '../../components/AlertDanger'
import AlertSuccess from '../../components/AlertSuccess'
import * as actions from '../../store/actions/users'
import { i18n } from '../../translate/i18n';
import { LoadingButton } from '@mui/lab';
import { FCReturnButton } from 'components-fullcomm';

class UpdatePerfilPhoto extends Component{

    state = {
        _id: "",
        file: null,
        error: "",
        success: "",
        loading: false
    }    

    onChangeInput = (ev) => {
        this.setState({[ev.target.name]: ev.target.files[0]})
    }

    validate(){
        const {file} = this.state;
        if(!file){
            return this.setState({error: {message:  i18n.t("updatePerfilPhoto.photoRequired")}})
        }

        return true;
    }

    async handleUpdate(){
        this.setState({error: ""});
        this.setState({success: ""});

        if(!this.validate()) return;

        
        this.setState({loading: true});

        const formData = new FormData();
        formData.append('file', this.state.file)

        await this.props.handleUpdatePerfilPhoto(formData, (msg) => {
            if(msg.error.error){
                this.setState({error: {message: msg.error.message}})
            }else{
                this.setState({success: {message: msg.error.message}});
                this.updateDataUser()
            }
            
            this.setState({loading: false});
        });

    }

    async updateDataUser(){
        await this.props.viewPerfil(() => {
            this.setState({loading: false})
        })
    }

    render(){
        const {file, loading, error, success} = this.state
        return(
            <div className="p-4">
                <div className="mr-auto p-2 d-flex align-items-center">
                    <FCReturnButton />
                    <Typography variant="h4">{i18n.t("updatePerfilPhoto.editPhoto")}</Typography>
                </div>
            <AlertDanger error={error}/>
            <AlertSuccess error={success}/>
            <Form>
                    <FormGroup>
                        <Label for="file">`{i18n.t("updatePerfilPhoto.photo")}`</Label>
                        <Input type="file" name="file" id="file" autoComplete="file" onChange={(ev) => this.onChangeInput(ev)}/>  
                    </FormGroup>
                    <FormGroup>
                        { file ? <img src={URL.createObjectURL(file)} alt={i18n.t("updatePerfilPhoto.perfilPhoto")} width="150" /> : this.props.user ? <img src={this.props.user.fileLocation ? this.props.user.fileLocation : this.props.user.url} width="150" alt={i18n.t("updatePerfilPhoto.perfilPhoto")}  /> : ""}
                    </FormGroup>
                    <Link onClick={() => this.handleUpdate()} to="#">
                        <LoadingButton loading={loading} variant="contained" className='btn-action medium'>
                            {i18n.t("updatePerfilPhoto.update")}
                        </LoadingButton>
                    </Link>
                </Form>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    user: state.auth.user
})

export default connect(mapStateToProps, actions)(UpdatePerfilPhoto);