import { Box, Grid, Pagination, Paper, Typography, useMediaQuery } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import ExportButton from '../../Sprint/utils/ExportButton'
import ProblemIncidentsTable from './Table';
import ModalForm from './ModalForm';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions/problems'
import { debounce } from 'lodash';
import { queryString } from '../../../utils/String';
import "dayjs/locale/pt-br";
import 'dayjs/locale/es';
import dayjs from 'dayjs';
import { userPermissionCheck } from '../../Sprint/utils/userPermissionCheck';
import { i18n } from '../../../translate/i18n';
import { FCDatePicker, FCPagination, FCSelect, FCTextField } from 'components-fullcomm';

function ProblemIncidents(props) {
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(1);
  const [status, setStatus] = useState('Todos');
  const [search, setSearch] = useState('');
  const [dateFilter, setDateFilter] = useState(dayjs(new Date()));
  const [isEdit, setIsEdit] = useState(null);
  const [sortDirection, setSortDirection] = useState(1);
  const isMobileDevice = useMediaQuery('(max-width:600px)');

  const handleSearch = useCallback(
    debounce((title, status, page, sortDirection, date, customer) => {
      const query = queryString({title, status, page, sortDirection, date, customer });
      props.getProblems(query);
      return null;
    }, 1000),
    []
  );

  useEffect(() => {
    handleSearch(search, status, page, sortDirection, dateFilter, props.shop );
  }, [search, status, page, sortDirection, dateFilter, props.shop]);

  const handleChangeStatus = (item) => {
    switch (item) {
      case "Todos": return i18n.t('support.problemsIncidents.all');
      case "Backlog": return i18n.t('support.problemsIncidents.backlog');
      case "Para fazer": return i18n.t('support.problemsIncidents.toDo');
      case "Em andamento": return i18n.t('support.problemsIncidents.inProgress');
      case "Q/A": return i18n.t('support.problemsIncidents.qa');
      case "Homologação": return i18n.t('support.problemsIncidents.clientApproval');
      case "Concluído": return i18n.t('support.problemsIncidents.done');
      default: return item;
    }
  }

  return (
    <Box className="dashboard">
      <Grid container spacing={"0.75rem"}>
        <Grid item xs={12} md={9}>
          <Typography variant='h4'>{i18n.t('support.problemsIncidents.problemsIncidents')}</Typography>
        </Grid> 
        <Grid item xs={12} md={1}>
          <ExportButton data={selected} />
        </Grid>
        <Grid item xs={12} md={2}>
          <FCDatePicker
            label={i18n.t('support.problemsIncidents.filter')}
            value={dateFilter}
            onChange={(date) => setDateFilter(date || '')}
            views={['month', 'year']}
            minDate={dayjs("01/01/2020")}
            maxDate={dayjs("01/01/2040")}
          />
        </Grid>
      </Grid>
      <Paper
        sx={{
          marginY: '1.75rem',
        }}
      >
        <Grid
          padding="1rem"
          container
          alignItems="end"
          rowGap={"1rem"}
        >
          <Grid xs={12} md={3}>
            <FCTextField
              fullWidth
              searchIcon
              placeholder={i18n.t('support.problemsIncidents.research')}
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </Grid>
          <Grid xs={8} md={3} paddingLeft={isMobileDevice ? "0" : "1rem"}>
            <FCSelect
              fullWidth
              select 
              label={i18n.t('support.problemsIncidents.status')}
              sx={{maxWidth: isMobileDevice ? '100%' : '12rem'}}
              value={status}
              onChange={(e) => setStatus(e.target.value)}
              options={[
                {label: i18n.t('support.problemsIncidents.all'), value: 'Todos'},
                {label: i18n.t('support.problemsIncidents.backlog'), value: 'Backlog'},
                {label: i18n.t('support.problemsIncidents.toDo'), value: 'Para fazer'},
                {label: i18n.t('support.problemsIncidents.inProgress'), value: 'Em andamento'},
                {label: i18n.t('support.problemsIncidents.qa'), value: 'Q/A'},
                {label: i18n.t('support.problemsIncidents.clientApproval'), value: 'Homologação'},
                {label: i18n.t('support.problemsIncidents.done'), value: 'Concluído'},
              ]}
              />
          </Grid>
          <Grid xs={4} md={6} display="flex" justifyContent="end">
            {
              userPermissionCheck(props.user, ["create_problem"]) && (
                <ModalForm isEdit={isEdit} setIsEdit={setIsEdit}/>
              )
            }
          </Grid>
        </Grid>
        <ProblemIncidentsTable 
          dateFilter={dateFilter}
          handleSearch={handleSearch}
          setIsEdit={setIsEdit} 
          selected={selected} 
          setSelected={setSelected} 
          data={props.problems?.docs || []}
          setSortDirection={setSortDirection}
        />
      </Paper>
      <Box
          display={"grid"}
          alignItems={"center"}
          justifyContent={"center"}
          padding={1}
        >
          <FCPagination
            sx={{marginBottom: '3rem'}}
            count={props.problems?.totalPages || 1}
            page={page}
            onChange={(e, page) => setPage(page)}
          />
        </Box>
    </Box>
  )
}

const mapStateToProps = (state) => ({
  problems: state.problems.problems,
  shop: state.auth.shop,
  user: state.auth.user,
});

export default connect(mapStateToProps, actions)(ProblemIncidents)