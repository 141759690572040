import React from "react";
import ReactApexChart from "react-apexcharts";
import { chartLocale } from './locale'

function LevelTypes({ inventory, dates }) {
    const data = {
        series: Object.keys(inventory).map(key => ({
            name: key,
            data: inventory[key]
        })),
        options: {
            theme: {
                palette: 'palette10',
            }, 
            yaxis: {
                min: 0,
            },
            chart: {
                locales: [chartLocale],
                defaultLocale: 'global',
                type: 'bar',
                height: 700,
                stacked: true,
                toolbar: {
                    show: true
                },
                zoom: {
                    enabled: true
                }
            },
            responsive: [{
                breakpoint: 480,
                options: {
                    legend: {
                        position: 'bottom',
                        offsetX: -10,
                        offsetY: 0
                    }
                }
            }],
            plotOptions: {
                bar: {
                    horizontal: false,
                    borderRadius: 10,
                    dataLabels: {
                        total: {
                            enabled: true,
                            style: {
                                fontSize: '13px',
                                fontWeight: 900
                            }
                        }
                    }
                },
            },
            xaxis: {
                type: 'datetime',

                categories: dates,
            },
            legend: {
                horizontalAlign: 'left',
                offsetX: 40,
                position: 'bottom',  showForNullSeries: false,
                showForZeroSeries: false,
            },
            fill: {
                opacity: 1
            }
        },


    };

    return ( 
        <div id="chart">
            <ReactApexChart options={data.options} series={data.series} type="bar" height={700} />
        </div>
    );
}

export default LevelTypes;
