import { Box, Chip, Divider, Icon, IconButton, Menu, MenuItem, Paper, Tooltip, Typography } from '@mui/material'
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react'
import { statusColor } from '../../utils/statusColor';
import { isMobile } from "react-device-detect";
import IntegrationsModal from '../../List/Modal/IntegrationsModal';
import { userPermissionCheck } from '../../utils/userPermissionCheck';
import { handleMetric, handleChannel } from './Analysis';
import { handleCategory } from '../utils/CategorySelect';
import { i18n } from '../../../../translate/i18n';
import { handleChangeName, handleChangeCategory, handleChangeDescription } from '../../../../translate/translates/Translates';
import HelpIcon from '@mui/icons-material/Help';
import { formatText } from '../../utils/formatText';
import { FCCheckbox, FCChip, FCSelect } from 'components-fullcomm';

export const handleType = (type) => {
  switch (type) {
    case "Campanha": return i18n.t("sprint.newAction.pages.revision.typeCampaign")
    case "Implementação": return i18n.t("sprint.newAction.pages.revision.typeImplementation")
    case "Melhoria": return i18n.t("sprint.newAction.pages.revision.typeImprovement")
    default: return type;
  }
}

const Revision = ({ values, localActivities, selectedActivities, getFieldProps, user, checklist, setChecklist, isEdit, isEditing, initialValues, action }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [openModal, setOpenModal] = useState(false);
  const [options, setOptions] = useState(["Planejamento", "Backlog"]);
  const optionsMapCustomer = {
    'Planejamento': ['Backlog', 'Cancelado'],
    Backlog: ['Para fazer', 'Cancelado'],
    'Para fazer': ['Backlog', 'Cancelado'],
    'Em andamento': ['Concluído', 'Ativo', 'Cancelado'],
    'Ativo': ['Concluído', 'Cancelado'],
  };
  const score = values.ease * values.impact * values.trust;
  const { category = '', quadrant = '' } = JSON.parse(values.category || '{}');

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  function getColorFromScore(score) {
    if (score <= 250) {
      return "#db4e2f";
    } else if (score >= 750) {
      return "#007f60";
    }
    return "#e0a409";
  }

  useEffect(() => {
    if (isEdit) {
      const currentOption = initialValues.status;
      const availableOptions = optionsMapCustomer[currentOption] || [];
      if (currentOption === 'Para fazer' && !action?.keyJira) {
        availableOptions.push('Em andamento');
      }
      setOptions([currentOption, ...availableOptions || []]);
    }
  }, [isEdit, initialValues]);

  const handleChangeLabel = (label) => {
    switch (label) {
      case "Ativo": return i18n.t("sprint.newAction.pages.revision.active")
      case "Backlog": return i18n.t("sprint.newAction.pages.revision.backlog")
      case "Em andamento": return i18n.t("sprint.newAction.pages.revision.inProgress")
      case "Concluído": return i18n.t("sprint.newAction.pages.revision.done")
      case "Para fazer": return i18n.t("sprint.newAction.pages.revision.toDo")
      case "Planejamento": return i18n.t("sprint.newAction.pages.revision.planning")
      case "Cancelado": return i18n.t("sprint.newAction.pages.revision.cancelled")
      default: return label
    }
  }

  const formatLinks = (text) => {
    const patterns = [
      { regex: /(https?:\/\/[^\s<]+)/g, tag: 'a' },
    ];
  
    const lines = text?.split('\n');
  
    const formattedLines = lines?.map((line, index) => {
      let formattedLine = line;
  
      patterns.forEach(({ regex, tag }) => {
        formattedLine = formattedLine.replace(regex, `<${tag} target="_blank" href="$1"}>$1</${tag}>`);
      });
  
      if (index !== lines.length - 1) {
        formattedLine += '<br>';
      }
  
      return formattedLine;
    });
  
    return formattedLines?.join('');
  };
  


  return (
    <Box>
      <IntegrationsModal
        actionId={action?._id}
        open={openModal}
        setOpen={setOpenModal}
      />
      <Typography my="1rem" variant="h4" align="center">{!(isEditing || !isEdit) ? `${i18n.t("sprint.newAction.pages.revision.detailsOf")} ${handleType(values.type) || ""}` : i18n.t("sprint.newAction.pages.revision.revision")}</Typography>
      <Box display="flex" flexDirection={isMobile ? "column-reverse" : "row"} justifyContent="center" gap="2rem">
        <Paper sx={{ width: isMobile ? "100%" : "33%", padding: "1rem", borderRadius: "5px" }}>
          <Typography variant="h5" mb="1rem">{i18n.t("sprint.newAction.pages.revision.details")}</Typography>
          <Box display="flex" flexDirection="column" gap="0.25rem" padding="0.5rem" borderRadius="5px" border="1px solid #ccc">
            <Typography><b>{i18n.t("sprint.newAction.pages.revision.name")}</b> {values.name || i18n.t("sprint.newAction.pages.revision.notSpecified")}</Typography>
            <div style={{
                overflow: 'hidden',
              }} 
              dangerouslySetInnerHTML={{ __html: `<b>${i18n.t("sprint.newAction.pages.revision.description")}</b> ${(new Date(action?.createdAt) > new Date("2023-11-20") ?  formatLinks(values.description) : formatText(values.description)) || i18n.t("sprint.newAction.pages.revision.notSpecified")}` }} 
            />
            <Typography><b>{i18n.t("sprint.newAction.pages.revision.type")}</b> {handleType(values.type) || i18n.t("sprint.newAction.pages.revision.notSpecified")}</Typography>
            <Typography><b>{i18n.t("sprint.newAction.pages.revision.category")}</b> {handleCategory(category) || i18n.t("sprint.newAction.pages.revision.notSpecified")}</Typography>
            <Typography><b>{i18n.t("sprint.newAction.pages.revision.quadrant")}</b> {handleCategory(quadrant) || i18n.t("sprint.newAction.pages.revision.notSpecified")}</Typography>
            <Typography><b>{i18n.t("sprint.newAction.pages.revision.executionTime")}</b> {values.executionTime ? `${values.executionTime} ${i18n.t("sprint.newAction.pages.revision.weeks")}` : i18n.t("sprint.newAction.pages.revision.notSpecified")}</Typography>
            <Typography><b>{i18n.t("sprint.newAction.pages.revision.start")}</b> {(localStorage.getItem('i18nextLng').includes('en-US') ? dayjs(values.start).format("MM/DD/YYYY") : dayjs(values.start).format("DD/MM/YYYY")) || i18n.t("sprint.newAction.pages.revision.notSpecified")}</Typography>
            <Typography><b>{i18n.t("sprint.newAction.pages.revision.end")}</b> {values.perpetual ? i18n.t("sprint.newAction.pages.revision.perpetual") : dayjs(values.end).format("DD/MM/YYYY") === "Invalid Date" ? i18n.t("sprint.newAction.pages.revision.notSpecified") : (localStorage.getItem('i18nextLng').includes('en-US') ? dayjs(values.end).format("MM/DD/YYYY") : dayjs(values.end).format("DD/MM/YYYY"))}</Typography>
            <Typography><b>{i18n.t("sprint.newAction.pages.revision.channel")}</b> {handleChannel(values.channel) || i18n.t("sprint.newAction.pages.revision.notSpecified")}</Typography>
            <Typography><b>{i18n.t("sprint.newAction.pages.revision.metric")}</b> {handleMetric(values.metric) || i18n.t("sprint.newAction.pages.revision.notSpecified")}</Typography>
          </Box>
        </Paper>
        {
          selectedActivities.length > 0 && (
            <Paper sx={{ width: isMobile ? "100%" : "33%", borderRadius: "5px" }}>
              <Typography variant="h5" padding="1rem">{i18n.t("sprint.newAction.pages.revision.activities")}</Typography>
              <Box margin="1rem" mt={0} borderRadius="5px" border="1px solid #ccc">
                {localActivities?.map((activity, i) => {
                  const index = selectedActivities.findIndex((obj) => obj.activity === activity._id);
                  const { repeat, status } = selectedActivities[index] || { repeat: 0, status: '' };
                  return (
                    <div key={activity._id}>
                      <Box padding={"1rem"} display={"grid"} gap={"0.5rem"}
                        position="relative">
                        <Box display="flex" justifyContent="space-between" alignItems="center">
                          <Box>
                            <Typography variant="caption">{handleChangeCategory(activity.category)}</Typography>
                            <Tooltip title={handleChangeDescription(activity.description)}>
                              <HelpIcon sx={{ ml: '5px', width: '15px' }} />
                            </Tooltip>
                          </Box>
                          {
                            status && (
                              <FCChip
                                size="small"
                                color={statusColor(status).type}
                                label={handleChangeLabel(status)}
                              />
                            )
                          }
                        </Box>
                        <Typography>{handleChangeName(activity.name)}</Typography>
                        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                          <Typography variant="h6">
                            {activity.creditsCost} {i18n.t("sprint.newAction.pages.revision.creditsQuantity")} {repeat}
                          </Typography>
                        </Box>
                      </Box>
                      {localActivities.length > i + 1 && <Divider sx={{ marginBottom: "1rem" }} />}
                    </div>
                  )
                })}
              </Box>
            </Paper>
          )
        }
        <Paper sx={{ width: isMobile ? "100%" : "33%", padding: "1rem", borderRadius: "5px" }}>
          <Typography variant="h5" mb="1rem">{i18n.t("sprint.newAction.pages.revision.resume")}</Typography>
          <Box display="flex" flexDirection="column" gap="0.5rem" borderRadius="5px" border="1px solid #ccc">
            <Typography paddingX="0.5rem" paddingTop="0.5rem" variant="h6">{i18n.t("sprint.newAction.pages.revision.credits")} {values.credits}</Typography>
            <Divider />
            <Box display={"flex"} gap={1} paddingX="0.5rem">
              <Typography>{i18n.t("sprint.newAction.pages.revision.score")}</Typography>
              <Chip
                size="small"
                sx={{
                  fontWeight: "500",
                  background: getColorFromScore(score),
                  color: "white",
                  fontSize: "1rem",
                }}
                label={score}
              />
            </Box>
            {
              values.campaign?.name && (
                <>
                  <Divider />
                  <Box display="flex" alignItems="center" gap="0.5rem" mb="0.5rem" paddingX="0.5rem">
                    <Typography variant="h6">{i18n.t("sprint.newAction.pages.revision.campaign")} </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        fontWeight: "bold",
                        color: "#FFFFFF",
                        width: "fit-content",
                        background: "#352c6342",
                        borderRadius: "5px",
                        padding: "0.25rem 0.5rem",
                      }}
                    >
                      {values.campaign?.name}
                    </Typography>
                  </Box>
                </>
              )
            }
            <Divider />
              <Box sx={{ padding: "0.5rem", display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
                <Typography variant="body2">{i18n.t("sprint.newAction.pages.revision.status")}:</Typography>
                <FCSelect
                  fullWidth
                  disabled={!(isEditing || !isEdit)}
                  // label={i18n.t("sprint.newAction.pages.revision.status")}
                  {...getFieldProps("status")}
                  renderValue={() => {
                    const { type } = statusColor(values.status);
                    return (
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {values.status && (
                          <FCChip
                            size="small"
                            color={type}
                            label={handleChangeLabel(values.status)}
                          />
                        )}
                      </Box>
                    );
                  }}
                  options={options.map((option) => ({ value: option, label: handleChangeLabel(option) }))}
                />
              </Box>
            <Divider />
            {
              user?.role?.includes("admin") && (
                <>
                  <Box padding={"0.5rem"}>
                    <FCCheckbox
                      label={i18n.t("sprint.newAction.pages.revision.includeChecklist")}
                      onChange={(e) => setChecklist(e.target.checked)}
                      checked={checklist}
                      disabled={!(isEditing || !isEdit) || values?.checklist?.length > 0}
                    />
                  </Box>
                  <Divider />
                </>
              )
            }
            <Box display="flex" justifyContent="space-between" alignItems="center" paddingX="0.5rem" paddingBottom="0.5rem">
              <Typography variant="h6">{i18n.t("sprint.newAction.pages.revision.jira")} {action?.keyJira || ""}</Typography>
              {
                userPermissionCheck(user, ["edit_action"], "admin") && (
                  <Box
                    border={"1px solid #babfc3"}
                    borderRadius={"5px"}
                    height={"2rem"}
                    width={"2rem"}
                  >
                    <Box position={"relative"}>
                      <IconButton
                        onClick={handleClick}
                        sx={{
                          borderRadius: "5px",
                          width: "100%",
                          height: "2rem",
                        }}
                      >
                        <Icon className="material-icons-outlined">more_vert</Icon>
                      </IconButton>
                      <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                          'aria-labelledby': 'basic-button',
                        }}
                      >
                        {
                          action.keyJira && (
                            <MenuItem onClick={(e) => window.open(`https://checkstore.atlassian.net/browse/${action.keyJira}`, '_blank')}>
                              {i18n.t("sprint.newAction.pages.revision.jira2")}
                            </MenuItem>
                          )
                        }
                        <MenuItem onClick={(e) => setOpenModal(true)}>
                          {i18n.t("sprint.newAction.pages.revision.integration")}
                        </MenuItem>
                      </Menu>
                    </Box>
                  </Box>
                )
              }
            </Box>
          </Box>
        </Paper>
      </Box>
    </Box>
  )
}

export default Revision