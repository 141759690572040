import axios from 'axios';
import {api} from '../../config'
import {getHeaders} from './localStorage'
import errorHandling from './errorHandling'

export const getChecklists = () => async (dispatch) => {
    try {
        const response = await axios.get(api + `/checklists`, getHeaders());
        dispatch({ type: "GET_CHECKLISTS", payload: response.data });
    } catch (err) {
        return errorHandling(err);
    }
}

export const getActionChecklists = () => async (dispatch) => {
    try {
        const response = await axios.get(api + `/checklists/action`, getHeaders());
        dispatch({ type: "GET_CHECKLISTS", payload: response.data });
    } catch (err) {
        return errorHandling(err);
    }
}

export const getChecklist = (_id) => async (dispatch) => {
    try {
        return await axios.get(api + `/checklists/${_id}`, getHeaders());
    } catch (err) {
        return errorHandling(err);
    }
}

export const getActionChecklist = (_id) => async (dispatch) => {
    try {
        return await axios.get(api + `/checklists/action/${_id}`, getHeaders());
    } catch (err) {
        return errorHandling(err);
    }
}

export const deleteChecklist = (_id) => async (dispatch) => {
    try {
        const response = await axios.delete(api+"/checklists/" + _id, getHeaders());
        return response.data;
    } catch (err) {
        return errorHandling(err);
    }
}

export const deleteActionChecklist = (_id) => async (dispatch) => {
    try {
        const response = await axios.delete(api+"/checklists/action/" + _id, getHeaders());
        return response.data;
    } catch (err) {
        return errorHandling(err);
    }
}

export const handleAddChecklist = (props) => async () => {
    try {
        const response = await axios.post(api+"/checklists", props, getHeaders());
        return response.data;
    } catch (err) {
        return errorHandling(err);
    }
}

export const handleAddActionChecklist = (props) => async () => {
    try {
        const response = await axios.post(api+"/checklists/action", props, getHeaders());
        return response.data;
    } catch (err) {
        return errorHandling(err);
    }
}

export const handleUpdateChecklist = (props) => async () => {
    try {
        const response = await axios.put(api+"/checklists", props, getHeaders());
        return response.data;
    } catch (err) {
        return errorHandling(err);
    }
}

export const handleUpdateActionChecklist = (props) => async () => {
    try {
        const response = await axios.put(api+"/checklists/action", props, getHeaders());
        return response.data;
    } catch (err) {
        return errorHandling(err);
    }
}

export const handleUpdateChecklistOrder = (props) => async () => {
    try {
        const response = await axios.put(api+"/checklists-order", props, getHeaders());
    } catch (err) {
        return errorHandling(err);
    }
}

export const handleUpdateActionChecklistOrder = (props) => async () => {
    try {
        const response = await axios.put(api+"/checklists-order/action", props, getHeaders());
    } catch (err) {
        return errorHandling(err);
    }
}

export const clearChecklist = () => {
    return function(dispatch){
        dispatch({type: "CLEAR_CHECKLIST"})
    } 
}

export const clearChecklists = () => {
    return function(dispatch){
        dispatch({type: "CLEAR_CHECKLISTS"})
    } 
}