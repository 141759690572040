import React, { useEffect, useState } from 'react'
import { Card, Grid, Tooltip, Table, TableCell, TableRow, TableBody, TableSortLabel } from '@mui/material'
import { connect } from 'react-redux';
import * as scaleActions from '../../../store/actions/scale';
import InfoIcon from '@mui/icons-material/Info';

import { i18n } from '../../../translate/i18n';
import { FCTableContainer, FCTableHeader } from 'components-fullcomm';

const CostsOMS = (props) => {
    const { month, year } = props;
    const [order, setOrder] = useState('desc');
    const [orderBy, setOrderBy] = useState('resources');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            if (month && year) {
                await props.getCostsOms({ month, year });
                setLoading(false);
            }
        };

        fetchData();
    }, [month, year]);


    const values = [
        {
            value: `$${props.costs?.value?.toFixed(2)}`,
            text: i18n.t('costsApp.oms.spending'),
            subtitle: i18n.t('costsApp.oms.spendingText'),
        },
        {
            value: `$${props.costs?.totalPrice?.toFixed(2)}`,
            text: i18n.t('costsApp.oms.earnings'),
            subtitle: i18n.t('costsApp.oms.earningsText'),
        },
        {
            value: `${props.costs?.result?.length}`,
            text: i18n.t('costsApp.oms.stores'),
            subtitle: i18n.t('costsApp.oms.storesText'),
        },
        {
            value: `${props.costs?.totalFullfillments}`,
            text: i18n.t('costsApp.oms.fulfillments'),
            subtitle: i18n.t('costsApp.oms.fulfillmentsText'),
        },
        {
            value: `$${props.costs?.pricePerResource?.toFixed(6)}`,
            text: i18n.t('costsApp.oms.costResource'),
            subtitle: i18n.t('costsApp.oms.costResourceText'),
        },
    ]

    const columns = [
        { field: 'shop', headerName: i18n.t('costsApp.oms.store') },
        { field: 'fulfillments', headerName: i18n.t('costsApp.oms.fulfillments'), type: 'number' },
        { field: 'resources', headerName: i18n.t('costsApp.oms.resources'), type: 'number' },
        { field: 'cost', headerName: i18n.t('costsApp.oms.cost'), type: 'number' },
        { field: 'pricePerUse', headerName: i18n.t('costsApp.oms.pricePerUse'), type: 'number' },
        { field: 'pricePlan', headerName: i18n.t('costsApp.oms.pricePlan'), type: 'number' },
        { field: 'totalPrice', headerName: i18n.t('costsApp.oms.totalPrice'), type: 'number' },
        { field: 'profit', headerName: i18n.t('costsApp.oms.profit'), type: 'number' }
    ];

    const handleSort = (property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const sortedData = [...props?.costs?.result || []].sort((a, b) => {
        const valA = a[orderBy];
        const valB = b[orderBy];

        if (typeof valA === 'string' && typeof valB === 'string') {
            const comparison = valA.localeCompare(valB, undefined, { sensitivity: 'accent' });
            return order === 'asc' ? comparison : -comparison;
        }

        if (valA < valB) {
            return order === 'asc' ? -1 : 1;
        }
        if (valA > valB) {
            return order === 'asc' ? 1 : -1;
        }
        return 0;
    });

    if (props.costs?.result?.length === 0) return (
        <p>{i18n.t('costsApp.oms.noData')}</p>
    );

    if (loading) return (
        <p>{i18n.t('costsApp.oms.loading')}</p>
    );

    return (
        <>
            <Grid container spacing={{ xs: 2, md: 3 }} columns={{ sm: 10, xs: 12 }}>
                {values.map((value, index) => (
                    <Grid item xs={4} sm={2} key={index}>
                        <Card style={{ padding: '10px' }} className='card'>
                            <h4>{value.text}</h4>
                            <p >{value.value}</p>
                            <Tooltip title={value.subtitle}>
                                <InfoIcon sx={{ width: '15px', position: 'absolute', top: 0, right: '10px', color: '#9e9e9e' }} />
                            </Tooltip>
                        </Card>
                    </Grid>
                ))}
            </Grid>
            <FCTableContainer sx={{ mt: '10px' }}>
                <Table>
                    <FCTableHeader>
                        <TableRow>
                            {columns.map((column, index) => (
                                <TableCell key={index}>
                                    <TableSortLabel
                                        active={orderBy === column.field}
                                        direction={order}
                                        onClick={() => handleSort(column.field)}
                                    >
                                        {column.headerName}
                                    </TableSortLabel>
                                </TableCell>
                            ))}
                        </TableRow>
                    </FCTableHeader>
                    <TableBody>
                        {sortedData.map((result, index) => (
                            <TableRow key={index}>
                                <TableCell>{result.store?.replace(".myshopify.com", "")}</TableCell>
                                <TableCell>{result.fulfillments}</TableCell>
                                <TableCell>{result.resources}</TableCell>
                                <TableCell>${result.cost?.toFixed(2)}</TableCell>
                                <TableCell>${result.pricePerUse?.toFixed(2)}</TableCell>
                                <TableCell>${result.pricePlan?.toFixed(2)}</TableCell>
                                <TableCell>${result.totalPrice?.toFixed(2)}</TableCell>
                                <TableCell>${result.profit?.toFixed(2)}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </FCTableContainer>
        </>

    )
}

const mapStateToProps = (state) => ({
    costs: state.scale.costsOms,
})

export default connect(mapStateToProps, scaleActions)(CostsOMS)