import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/customers'
import Operation from './Operation';
import Tecnology from './Tecnology';
import Strategy from './Strategy';
import Sales from './Sales';
import { Box } from '@mui/material';
import DataEnrichmentModal from '../DataEnrichmentModal';
import { i18n } from '../../translate/i18n';
import { FCSelect } from 'components-fullcomm';

const Dashboard = (props) => {
  const [clientInfo, setClientInfo] = useState({});
  const [dashboardId, setDashboardId] = useState(0);

  useEffect(() => {
    if (props.user?.trial && props.user?.customer?.length === 0) {
      props.history.push(`/dashboard?onboarding=true`);
    }
  }, [props.user]);

  useEffect(() => {
    const shop = localStorage.getItem('shop');
    props.getViewCustomer(shop);
  }, [props.shop]);

  useEffect(() => {
    if (props.customer) {
      const { resumoDeVendas, trafegoPago, CRM } = props.customer;
      setClientInfo([resumoDeVendas, trafegoPago, CRM])
      setDashboardId(0)
    }
  }, [props.customer])

  const handleChange = (event) => {
    setDashboardId(event.target.value)
  }

  return (
    <div className='dashboard'>
      <DataEnrichmentModal />
        {clientInfo &&
          <Box
            display='flex'
            justifyContent='flex-end'
          >
            <FCSelect
              value={dashboardId}
              onChange={handleChange}
              options={[
                { label: 'FullComm', value: 0 },
                { label: i18n.t('dashboard.resumeSales'), value: 1 },
                { label: i18n.t('dashboard.paidTraffic'), value: 2 },
                { label: i18n.t('dashboard.crm'), value: 3 },
              ]}
            />
          </Box>
        }

        {
          dashboardId !== 0 && (
            <div
              style={{height: "calc(100vh - 10rem)", width: "100%"}}
            >
              <iframe
                style={{border: "none", height: "100%", width: "100%"}}
                src={clientInfo[dashboardId]}
              />
            </div>
          )
        }

        {
          dashboardId === 0 && (
            <>
              <Sales />
              <Strategy />
              <Operation />
              <Tecnology />
            </>
          )
        }
    </div>
  );

};


const mapStateToProps = state =>
({
  user: state.auth.user,
  shop: state.auth.shop,
  customer: state.customer.customerDetails,
});

export default connect(mapStateToProps, actions)(Dashboard);
