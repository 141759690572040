const actions_reducer = (state = {}, actions) => {
    switch (actions.type) {
        case "GET_ACTIONS":
            return {
                ...state,
                actions: actions.payload
            }
        case "GET_ACTIONS_CONTRACT":
            return {
                ...state,
                actions_contract: actions.payload
            }
        case "GET_ACTIONS_MODEL":
            return {
                ...state,
                models: actions.payload
            }
        case "GET_ACTIONS_CHECKLIST":
            return {
                ...state,
                actionsChecklist: actions.payload.actions
            }
        case "GET_ACTION_CHECKLIST":
            return {
                ...state,
                actionChecklist: actions.payload.checklist
            }
        case "GET_ACTIONS_CHECKLIST_INFOS":
            return {
                ...state,
                actionChecklistInfos: {
                    action: actions.payload.action,
                    total: actions.payload.total,
                    errors: actions.payload.errors,
                    waitings: actions.payload.waitings,
                    in_analysis: actions.payload.in_analysis,
                }
            }
        case "GET_ACTION_MODEL":
            return {
                ...state,
                model: actions.payload
            }
        case "GET_HEALTH_SCORE":
            return {
                ...state,
                health: actions.payload.score,
                healthAux: actions.payload.aux
            }
        case "GET_ACTION":
            return {
                ...state,
                action: actions.payload
            }
        case "GET_CYCLE":
            return {
                ...state,
                cycle: actions.payload
            }
        case "GET_FILTER_PARAMS":
            return {
                ...state,
                filterParams: actions.payload
            }
        case "GET_CAMPAIGNS":
            return {
                ...state,
                campaigns: actions.payload
            }
        default:
            return state;
    }
}

export default actions_reducer;