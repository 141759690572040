import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react'
import { Avatar, Box, Chip, Divider, Grid, Paper, Typography } from "@mui/material";
import { i18n } from '../../../../../translate/i18n'
import ShowMoreLessButton from '../../ShowMoreLessButton';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';

const TransactionsArea = ({ transactions }) => {
  const [sectionTransactions, setSectionTransactions] = useState(transactions?.slice(0, 4));

  useEffect(() => {
    setSectionTransactions(transactions?.slice(0, 4))
  }, [transactions])
  return (
    <Paper
    sx={{
      minHeight: "250px",
      maxHeight: "calc(100vh - 35rem)",
      overflow: "auto",
      background: "#fafafa",
      borderRadius: "10px",
    }}
  >
    {sectionTransactions?.map((transaction, i) => (
          <div key={transaction._id}>
          <Grid
            container
            alignItems={"center"}
            width={"100%"}
            padding={2}
          >
            <Grid item xs={2} sm={1}>
              <Avatar sx={{ width: "44px", height: "44px" }} alt={"user image"} src={``} >
                <CompareArrowsIcon sx={{width: "44px", height: "44px"}}/>

              </Avatar>
            </Grid>
            <Grid item xs={3} sm={2} paddingLeft={"1rem"}>
              <Typography fontWeight={"700"}>
                {i18n.t('sprint.utils.headerButtons.creditBalanceModal.transaction')}
              </Typography>
              <Typography variant="caption">
                {dayjs(transaction.createdAt).format('DD/MM/YYYY')}
              </Typography>
            </Grid>
            <Grid item xs={4} sm={6} display={"grid"}>
              <Typography variant='caption'>{transaction.comment}</Typography>
            </Grid>
            <Grid item xs={3} sm={3} display={"grid"}>
              <Typography textAlign={"end"}><small>{transaction.credits} {i18n.t('sprint.utils.headerButtons.creditBalanceModal.actionCard.credits')}</small></Typography>
              <Chip
                sx={{
                  width: "fit-content",
                  justifySelf: "end",
                }}
                color={transaction.type === 'charge' ? 'to_do' : 'active'}
                label={transaction.type === 'charge' ? i18n.t('sprint.utils.headerButtons.creditBalanceModal.debited') : i18n.t('sprint.utils.headerButtons.creditBalanceModal.refunded')}
                size="small"
              />
            </Grid>
          </Grid>
          {(sectionTransactions?.length > i + 1 || sectionTransactions?.length < 4) && <Divider />}
        </div>
    ))}
    <Box paddingX={4} paddingBottom={2}>
    <ShowMoreLessButton
      originalArray={transactions} 
      setDisplayArray={setSectionTransactions}
      displayArray={sectionTransactions}
    />
    </Box>
  </Paper>
  )
}

export default TransactionsArea