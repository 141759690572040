import axios from 'axios';
import { api } from '../../config'
import { getHeaders } from './localStorage'
import errorHandling from './errorHandling'

export const addGoal = (props) => async () => {
  try {
    const response = await axios.post(api + `/goal`, props, getHeaders());
    return response.data
  } catch (err) {
    return errorHandling(err);
  }
}

export const updateGoal = (props) => async () => {
  try {
    const response = await axios.put(api + `/goal/${props.id}`, props, getHeaders());
    return response.data
  } catch (err) {
    return errorHandling(err);
  }
}

export const getGoals = (date) => async (dispatch) => {
  try {
    const response = await axios.get(api + `/goals?date=${date}`, getHeaders());
    dispatch({ type: "GET_GOALS", payload: response.data.goals });
  } catch (err) {
    return errorHandling(err);
  }
}

export const deleteGoal = (id) => async () => {
  try {
    const response = await axios.delete(api + `/goal/${id}`, getHeaders());
    return response.data
  } catch (err) {
    return errorHandling(err);
  }
}
