import React, { useState } from 'react'
import { Tab, Table, TableCell, TableRow, Typography } from '@mui/material';
import { FCDropdownButton, FCModalFooter, FCModalHeader, FCTableContainer, FCTableHeader } from 'components-fullcomm';
import { abbreviateNumber } from '../../utils/abbreviateNumber';
import { Modal } from 'reactstrap'
import { i18n } from '../../../../translate/i18n';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';

const GoalsTable = ({
  goals,
  ordersInfo,
  productsInfo,
  setEditGoal,
  setModal,
  deleteGoal,
  debounceGetGoals,
  aggregate,
}) => {
  const [deleteId, setDeleteId] = useState(null);
  
  return (
    <>
      <Modal isOpen={deleteId} toggle={() => setDeleteId(null)} centered>
        <FCModalHeader title='Deletar meta' />
        <Typography sx={{ padding: '1rem' }}>Você tem certeza que deseja deletar essa meta?</Typography>
        <FCModalFooter
          actionLabel={i18n.t('documents.index.delete')}
          handleAction={async () => {
            const res = await deleteGoal(deleteId);
            if (res.error) return toast.error(res.error);
            toast.success('Meta deletada com sucesso');
            setDeleteId(null);
            debounceGetGoals();
          }}
          cancelLabel={i18n.t('documents.index.cancel')}
          handleCancel={() => setDeleteId(null)}
        />
      </Modal>
      <FCTableContainer>
        <Table>
          <FCTableHeader>
            <TableRow>
              <TableCell sx={{ width: '300px' }}>Título</TableCell>
              <TableCell sx={{ minWidth: '100px'}}>Data</TableCell>
              <TableCell sx={{ minWidth: '100px'}}>Performance</TableCell>
              <TableCell sx={{ minWidth: '100px'}}>Valor atual</TableCell>
              <TableCell sx={{ minWidth: '100px'}}>Alvo</TableCell>
              <TableCell sx={{ width: '50px'}}></TableCell>
            </TableRow>
          </FCTableHeader>
          {
            goals?.map(goal => {
              switch (goal.name) {
                case 'sessions':
                  goal.performance = (productsInfo?.data_over_time[productsInfo?.data_over_time?.length -1]?.accumulated_sessions || 0)*100/((goal?.[aggregate] * productsInfo?.data_over_time?.length) || 1);
                  goal.value = abbreviateNumber(productsInfo?.data_over_time[productsInfo?.data_over_time?.length -1]?.accumulated_sessions || 0);
                  goal.target =  abbreviateNumber((goal?.[aggregate] * productsInfo?.data_over_time?.length) || 0);
                  break;
                case 'conversionRate':
                  goal.performance = ((ordersInfo?.orders_group?.reduce((acc, curr) => acc += curr?.paid?.amount || 0, 0) || 0)*100/productsInfo?.data_over_time[productsInfo?.data_over_time?.length -1]?.accumulated_sessions || 0)*100/goal?.[aggregate];
                  goal.value = abbreviateNumber((ordersInfo?.orders_group?.reduce((acc, curr) => acc += curr?.paid?.amount || 0, 0) || 0)*100/productsInfo?.data_over_time[productsInfo?.data_over_time?.length -1]?.accumulated_sessions) + '%';
                  goal.target = goal?.[aggregate] + '%';
                  break;
                case 'averageTicket':
                  goal.performance = ordersInfo?.average_ticket*100/goal?.[aggregate];
                  goal.value = abbreviateNumber(ordersInfo?.average_ticket || 0, true);
                  goal.target = abbreviateNumber(goal?.[aggregate], true);
                  break;
                case 'revenue':
                  goal.performance = (ordersInfo?.total_price || 0)*100/((goal?.[aggregate] || 1)*(ordersInfo.orders_group?.length || 1));
                  goal.value = abbreviateNumber(ordersInfo?.total_price || 0, true);
                  goal.target = abbreviateNumber(goal?.[aggregate]*(ordersInfo.orders_group?.length || 0), true);
                  break;
                case 'ordersAmount':
                  goal.performance = (ordersInfo?.number || 0)*100/((goal?.[aggregate] || 1)*(ordersInfo.orders_group?.length || 1));
                  goal.value = abbreviateNumber(ordersInfo?.number || 0);
                  goal.target = abbreviateNumber((goal?.[aggregate] || 0)*(ordersInfo.orders_group?.length || 0));
                  break;
                default:
                  break;
              }
              return (
                <TableRow key={goal.id}>
                  <TableCell>{goal.title}</TableCell>
                  <TableCell>{dayjs(goal.date).format('MM/YYYY')}</TableCell>
                  <TableCell sx={{ color: goal.performance > 100 ? '#00D6CF' : '#DB4E2F'}}>{goal?.performance?.toFixed(2)}%</TableCell>
                  <TableCell>{goal.value}</TableCell>
                  <TableCell>{goal.target}</TableCell>
                  <TableCell align='right'>
                    <FCDropdownButton
                      items={[
                        'Editar',
                        'Deletar'
                      ]}
                      itemClickHandlers={[
                        () => {
                          setEditGoal(goal);
                          setModal(true);
                        },
                        () => {setDeleteId(goal._id)}
                      ]}
                    />
                  </TableCell>
                </TableRow>
              )
            })
          }
        </Table>
      </FCTableContainer>
    </>
  )
}

export default GoalsTable