import { Box, Button, CircularProgress, Divider, Grid, Typography, useMediaQuery } from '@mui/material'
import React from 'react'
import { Modal } from "reactstrap";
import { priorities } from '../utils';
import { i18n } from '../../../../translate/i18n';
import { FCModalHeader } from 'components-fullcomm';

const ModalSummary = ({ problem, openModal, formik, customer, setOpenModal, setPage, buttons, setIsEdit}) => {
  const isMobileDevice = useMediaQuery('(max-width:600px)');

  return (
    <Modal
    toggle={() => {
      setOpenModal(false);
      if (setIsEdit) setIsEdit(null);
    }} 
    isOpen={openModal}
    centered
    size='lg'
  >
    <Box
      display="flex"
      padding={"24px"}
      paddingTop={0}
      flexDirection="column"
      gap={"24px"}
    >
      <FCModalHeader
        title={i18n.t('support.problemsIncidents.modalForm.modalSummary.resume')}
        onClick={() => {
          setOpenModal(false);
          if (setIsEdit) setIsEdit(null);
        }}
      />
      <Box
        padding={"1.5rem"}
        flexShrink={0}
        borderRadius={"8px"}
        boxShadow="0px 1px 2px 0px rgba(0, 0, 0, 0.15), 0px 0px 5px 0px rgba(0, 0, 0, 0.05)"
      >
        <Typography variant='h6' lineHeight={"160%"}>{problem.title}</Typography>
        <Typography fontSize={"12px"}><div dangerouslySetInnerHTML={{__html: problem.description}}/></Typography>
        <Grid container mt={"10px"} paddingY="0.5rem" paddingX={"1rem"} spacing={"5px"}>
          <Grid item xs={12} md={4}>
            <Typography variant='caption'><b>{i18n.t('support.problemsIncidents.modalForm.modalSummary.rootCause')} </b>{problem.rootCause}</Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography variant='caption'><b>{i18n.t('support.problemsIncidents.modalForm.modalSummary.dispositive')} </b>{problem.device}</Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography variant='caption'><b>{i18n.t('support.problemsIncidents.modalForm.modalSummary.reproducedError')} </b>{problem.repeated}</Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography variant='caption'><b>{i18n.t('support.problemsIncidents.modalForm.modalSummary.problemLocation')} </b>{problem.problemLocation}</Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography variant='caption'><b>{i18n.t('support.problemsIncidents.modalForm.modalSummary.navigator')} </b>{problem.browser}</Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography variant='caption'><b>{i18n.t('support.problemsIncidents.modalForm.modalSummary.store')} </b>{customer}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider />
            <img style={{height: "16px", width: "16px"}} src={priorities.find((priority) => priority.title === problem.priority)?.image} alt={problem.priority} />
            <Typography variant='caption' mt={"5px"}><b>{i18n.t('support.problemsIncidents.modalForm.modalSummary.priority')} </b>{problem.priority}</Typography>
          </Grid>
        </Grid>
      </Box>
      {
        buttons && (
          <Box
            display="flex"
            alignItems="flex-start"
            gap={"8px"}
            alignSelf="stretch"
          >
            <Button 
              fullWidth 
              variant="contained" 
              className='btn-white fullwidth' 
              onClick={() => {
                setPage("fill");
              }}
            >
              {i18n.t('support.problemsIncidents.modalForm.modalSummary.cancel')}
            </Button>
            <Button fullWidth variant="contained" className='btn-action fullwidth' onClick={()=> (formik.isSubmitting ? null : formik.submitForm())}>
              {
                formik.isSubmitting ? (
                  <CircularProgress size={25} sx={{ color: "white" }} thickness={4} />
                ) : (
                  <>{i18n.t('support.problemsIncidents.modalForm.modalSummary.end')}</>
                
              )}
            </Button>
          </Box>
        )
      }
    </Box>
  </Modal>
  )
}

export default ModalSummary