import { Box, Button, CardActionArea, CircularProgress, Divider, Grow, Paper, Typography, useMediaQuery } from '@mui/material';
import { FormikProvider, useFormik } from 'formik';
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { Form } from 'reactstrap';
import * as actions from '../../../store/actions/aiContent';
import * as actions2 from '../../../store/actions/action';
import { toast } from 'react-toastify';
import Options from './Options';
import SendToJiraModal from '../utils/SendToJiraModal';
import { formattedContent, options } from '../utils/config';
import SearchActions from '../utils/SearchActions';
import { i18n } from '../../../translate/i18n';
import { FCSelect, FCTextField } from 'components-fullcomm';

const Redacting = (props) => {
  const isMobileDevice = useMediaQuery('(max-width:600px)');
  const [value, setValue] = useState(0);
  const [data, setData] = useState({});
  const [bookmarked, setBookmarked] = useState([]);
  const [open, setOpen] = useState(false);
  const [engine, setEngine] = useState('good');
  const [language, setLanguage] = useState('pt-br');
  const [num_copies, setNumCopies] = useState(3);
  const [action, setAction] = useState({});

  const formik = useFormik({
    initialValues: {},
    onSubmit: async (values, { setSubmitting }) => {
      try {
        const selectedValue = value;

        const requiredFields = {
          0: ['description'],
          1: ['product', 'description'],
          2: ['recipient', 'recipient_position', 'description'],
          3: ['product_name', 'product_description', 'occasion', 'promotion'],
          4: ['product_name', 'product_description', 'search_term'],
          5: ['name', 'details'],
          6: ['company_name', 'description', 'keyword'],
          7: ['product_name', 'product_description', 'target_keywords', 'target_audience'],
          8: ['description', 'tone_of_voice'],
          9: ['content', 'tone'],
          10: ['product_description', 'feature'],
        };

        const missingFields = requiredFields[selectedValue].filter(field => {
          const value = values[field];
          return !value || value.length < 3;
        });
        if (missingFields.length > 0) {
          const errorMessage = missingFields.map(field => {
            const value = values[field];
            if (!value) {
              return i18n.t('aiContent.redacting.fillFields');
            } else if (value.length < 3) {
              return i18n.t('aiContent.redacting.minChar');
            }
          });
        
          toast.error(`${errorMessage.join(", ")}`);
          return;
        }
        const aux = {
          options: {
            engine,
            language,
            num_copies
          },
          type: value,
          prompt: {
            name: values.name,
            details: values.details,
            description: values.description,
            product: values.product,
            recipient: values.recipient,
            recipient_position: values.recipient_position,
            product_name: values.product_name,
            product_description: values.product_description,
            occasion: values.occasion,
            promotion: values.promotion,
            search_term: values.search_term,
            company_name: values.company_name,
            keyword: values.keyword,
            target_keywords: values.target_keywords,
            target_audience: values.target_audience,
            tone_of_voice: values.tone_of_voice,
            content: values.content,
            tone: values.tone,
            feature: values.feature,
          }
        }
  
        const result = await props.createWriteSonic(aux);
        if (result.error) {
          toast.error(result.error.message);
        } else {
        setData(result?.data || {});
        }
        setSubmitting(false);
      } catch (error) {
        toast.error(i18n.t('aiContent.redacting.errorContent'));
      }
    }
  });

  const { handleSubmit, getFieldProps, isSubmitting, resetForm } = formik;

  const handleChange = (event) => {
    setValue(event.target.value);
    resetForm();
  };

  const handleClick = (output) => {
    if (bookmarked.includes(output)) {
      setBookmarked(bookmarked.filter(item => item !== output));
    } else {
      setBookmarked([...bookmarked, output]);
    }
  }

  const store = async (jiraCode, jiraComment) => {
    try {
      const aux = {
        ...data,
        type: options.find(item => item.value === value).label,
        output: bookmarked,
        ...jiraCode,
        jiraComment,
      }

      const result = await props.storeAiContent(aux);
      return result;
    } catch (error) {
      toast.error(i18n.t('aiContent.redacting.errorSave'));
    }
  }

  useEffect(() => {
    props.getActions();
  }, [props.shop])

  return (
    <Box className="dashboard">
      <SendToJiraModal setOpen={setOpen} open={open} action={action} store={store}/>
      <Typography variant='h4'>{i18n.t('aiContent.redacting.redaction')}</Typography>
      <Paper sx={{mt: "1rem", mb: isMobileDevice ? "5rem" : 0, padding: "1rem", borderRadius: "10px"}}>
        <Box display={"flex"} flexDirection={isMobileDevice ? "column" : "row"} gap={"1rem"}>
          <Box width={isMobileDevice ? "auto" : "25vw"} minWidth={isMobileDevice ? 0 : "15rem"} mt={'1rem'}>
            <FormikProvider value={formik}>
              <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
                <SearchActions action={action} actions={props.actions} setAction={setAction}/>
                <Box sx={{ display: "flex", gap:"1rem", alignItems:"end", my: "1rem" }}>
                  <FCSelect
                    fullWidth
                    label={i18n.t('aiContent.redacting.method')}
                    select
                    value={value}
                    onChange={handleChange}
                    options={options.map(item => ({value: item.value, label: item.label}))}
                  />
                  <Button className='btn-action medium' type="submit" variant="contained">
                    {isSubmitting ? (
                      <CircularProgress size={25} sx={{ color: "white" }} thickness={4} />
                    ) : (
                      <>{i18n.t('aiContent.redacting.send')}</>
                    )}
                  </Button>
                </Box>
                <Box display={"flex"} gap={"1rem"} mb="1rem">
                  <FCSelect
                    fullWidth
                    label={i18n.t('aiContent.redacting.quality')}
                    select
                    value={engine}
                    onChange={(e) => setEngine(e.target.value)}
                    options={[
                      {value: 'economy', label: i18n.t('aiContent.redacting.economy')},
                      {value: 'average', label: i18n.t('aiContent.redacting.medium')},
                      {value: 'good', label: i18n.t('aiContent.redacting.high')},
                      {value: 'premium', label: i18n.t('aiContent.redacting.premium')}
                    
                    ]}
                  />
                  <FCSelect
                    fullWidth
                    label={i18n.t('aiContent.redacting.language')}
                    select
                    value={language}
                    onChange={(e) => setLanguage(e.target.value)}
                    options={[
                      {value: 'pt-br', label: i18n.t('aiContent.redacting.portuguese')},
                      {value: 'en', label: i18n.t('aiContent.redacting.english')},
                      {value: 'es', label: i18n.t('aiContent.redacting.spanish')}
                    ]}
                  />
                  <FCTextField
                    fullWidth
                    label={i18n.t('aiContent.redacting.numberCopies')}
                    type="number"
                    inputProps={{ min: 1, max: 5 }}
                    value={num_copies}
                    onChange={(e) => setNumCopies(e.target.value)}
                  />
                </Box>
                <Options value={value} getFieldProps={getFieldProps}/>
                <Typography variant='body2' color={"#757575"} padding="1rem" border= "1px solid #ccc" borderRadius="5px" sx={{ whiteSpace: 'pre-line' }}>
                  {formattedContent(options.find(item => item.value === value)?.description)}
                </Typography>
              </Form>
            </FormikProvider>
          </Box>
          <Divider orientation={isMobileDevice ? "horizontal" : "vertical"} flexItem />
          <Box width={isMobileDevice ? "auto" : "75vw"} overflow={isMobileDevice ? "visible" : "auto"}>
            {
              data?.output?.map((item, index) => (
                <CardActionArea 
                  key={index}
                  sx={{border: bookmarked.includes(item) ? "2px solid rgb(255 184 0)" : "1px solid #ccc", borderRadius: '5px', padding: '1rem', marginBottom: '1rem', position: 'relative'}}
                  onClick={() => handleClick(item)}
                >
                  {
                    item.ad_title && (
                      <Typography variant='h5' mb={"1rem"}>{item.ad_title}</Typography>
                    )
                  }
                  {
                    item.ad_description && (
                      <Typography>{item.ad_description}</Typography>
                    )
                  }
                  {
                    item.text && (
                      <Typography>{item.text}</Typography>
                    )
                  }
                </CardActionArea>
              ))
            }
            <Grow in={bookmarked?.length > 0} style={{float: "right"}}>
              <Button className='btn-action' variant="contained" onClick={() => setOpen(true)}>
                {isSubmitting ? (
                  <CircularProgress size={25} sx={{ color: "white" }} thickness={4} />
                ) : (
                  <>{i18n.t('aiContent.redacting.save')}</>
                )}
              </Button>
            </Grow>
          </Box>
        </Box>
      </Paper>
    </Box>
    )
}

const mapStateToProps = (state) => ({
  actions: state.actions.actions,
  shop: state.auth.shop,
});

export default connect(mapStateToProps, {...actions, ...actions2})(Redacting);