import React, { useEffect, useState, useCallback } from 'react';

import { Link, useHistory } from 'react-router-dom';
import { Modal, ModalBody } from 'reactstrap';
import { connect } from 'react-redux'
import * as actions from '../../store/actions/customers'
import * as actions2 from '../../store/actions/action'
import { Typography, Tooltip, Grid, CircularProgress, Paper, Button, Box } from '@mui/material';
import { toast } from 'react-toastify';
import { Icon } from '@iconify/react';
import debounce from 'lodash.debounce';
import { userPermissionCheck } from '../Sprint/utils/userPermissionCheck';
import { isMobile } from 'react-device-detect';
import { i18n } from '../../translate/i18n';
import '../../styles/customer/index.css'
import { api } from '../../config';
import { giveSelectTrimesters } from '../Sprint/utils/HeaderButtons/CycleSelector';
import { minCurrency } from '../../utils/String';
import { FCDropdownButton, FCModalFooter, FCModalHeader, FCPagination, FCSelect, FCTextField } from 'components-fullcomm';

const Customer = (props) => {
    const history = useHistory();
    const cycles = giveSelectTrimesters(new Date())
    const [pageCurrent, setPageCurrent] = useState(1);
    const limit = 10;
    const [loading, setLoading] = useState(false);
    const [loadingMetrics, setLoadingMetrics] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [shopFilter, setShopFilter] = useState('all');
    const [platform, setPlatform] = useState('all');
    const [query, setQuery] = useState('');
    const [cycle, setCycle] = useState(cycles[2].label)
    const [idDelete, setIdDelete] = useState("");
    const debouncedSearch = useCallback(
        debounce(async (query) => {
            setLoading(true)
            const data = await props.getCustomers(pageCurrent, limit, shopFilter, query, ['a', 'd'], cycle, platform)
            setLoading(false)
            if (!data.error) {
                const { docs } = data.customers
                const platformDomains = docs.reduce((acc, curr) => {
                    if (!acc.includes(curr.platformDomain) && curr.platformDomain !== undefined) {
                        acc += curr.platformDomain + ','
                    }
                    return acc
                }, '');

                setLoadingMetrics(true);
                await props.getMetrics(platformDomains, cycle);
                setLoadingMetrics(false);

            }
        }, 1000),
        [pageCurrent, limit, shopFilter, cycle, platform]
    );
    useEffect(() => {
        debouncedSearch(query)
    }, [pageCurrent, shopFilter, cycle, platform]);

    useEffect(() => {
        const metrics = props.metrics;
        for (let i = 0; i < props.customers?.docs?.length; i++) {
            const cus = props.customers.docs[i];
            const pixel = metrics?.pixel?.find(p => p.url === cus.platformDomain);
            const orders = metrics?.orders?.find(o => o.url === cus.platformDomain);
            cus.pixel = pixel;
            cus.orders = orders;
            props.customers.docs[i] = cus;
        }
    }, [props.metrics]);

    const deleteCustomer = async () => {
        setLoading(true)

        try {

            const result = await props.deleteCustomer(idDelete)
            await props.getCustomers(pageCurrent, limit)
            setLoading(false)
            toggleModal();
            if (result.error) {
                switch (result.error.message) {
                    case "Cliente não existe!": return toast.error(i18n.t('customer.customerNotExist'));
                    case "Erro ao excluir!": return toast.error(i18n.t('customer.deleteError'));
                    case "Erro interno do servidor.": return toast.error(i18n.t('customer.internalError'));
                    default: return toast.error(result.error.message);
                }
            } else {
                if (result.message === "Cliente deletado com sucesso!") {
                    toast.success(i18n.t('customer.deleteSuccess'));
                } else {
                    toast.success(result.message);
                }
            }
        } catch (error) {
            if (error.error.message === 'Erro interno do servidor.') {
                toast.error(i18n.t('customer.internalError'));
            } else {
                toast.error(error.error.message);
            }

            setLoading(false)
            toggleModal();
        }
    }

    const toggleModal = (_id = "") => {
        if (_id) {
            setIdDelete(_id)
        } else {
            setIdDelete("")
        }
        setOpenModal(!openModal);
    }

    const getTitle = (key) => {
        switch (key) {
            case "performance": return i18n.t('customer.performance');
            case "accessibility": return i18n.t('customer.acessibility');
            case "bestPractices": return i18n.t('customer.bestPractices');
            case "seo": return i18n.t('customer.seo');
            default: return key
        }
    }

    const getColour = (data) => {
        if (data >= 90) {
          return '#00D6CF';
        } else if (data >= 50) {
          return '#FFAA33';
        } else {
          return '#E74C3C';
        }
      }
    const getColourBalance = (data) => {
        if (data > 0) {
          return '#00D6CF';
        } else if (data < 0)  {
          return '#E74C3C';
        } else {
          return '#000000';
        }
      }

    return (
            <div style={{ padding: isMobile ? '16px 16px 66px 16px' : '16px' }} >
                <div className="d-flex">
                    <div className="mr-auto">
                        <Typography variant="h4">{i18n.t('customer.stores')}</Typography>
                    </div>
                    {
                        userPermissionCheck(props.user, ["create_customer"]) &&
                        <Link to={"/add-customer"}>
                            <Button className="btn-action medium">{i18n.t('customer.register')}</Button>
                        </Link>
                    }
                </div>
                <br />
                <Box display='flex' gap='1rem' alignItems='end' mb='1rem'>
                    <FCTextField 
                        value={query} 
                        sx={{ width: isMobile ? '50vw' : '290px' }} 
                        placeholder={i18n.t('customer.search')} 
                        searchIcon={true}
                        onChange={e => {
                            setQuery(e.target.value); debouncedSearch(e.target.value)
                        }} 
                    />
                    <FCSelect
                        sx={{ width: isMobile ? '38vw' : '145px' }}
                        value={cycle}
                        onChange={(e) => setCycle(e.target.value)}
                        options={cycles.map((cycle) => ({ label: cycle.label, value: cycle.label }))}
                    />
                    {
                        !isMobile && (
                            <FCSelect
                                sx={{ width: '145px' }}
                                value={platform}
                                onChange={(e) => setPlatform(e.target.value)}
                                options={[
                                    { value: 'all', label: i18n.t('customer.all') },
                                    { value: 'start', label: 'Start' },
                                    { value: 'sprint', label: 'Sprint' },
                                    { value: 'scale', label: 'Scale' },
                                    { value: 'suporte', label: 'Suporte' },
                                ]}
                            />
                        )
                    }
                </Box>
                {loading ? 
                <Box width={"100%"} display={"flex"} justifyContent={"center"}>
                    <Box display={"flex"} flexDirection={"column"} alignItems={"center"} mt={"10rem"}>
                        <Typography variant='h3' mb={"1rem"}>{i18n.t('support.performance.webVitals.searchInfos')}</Typography>
                        <CircularProgress />
                    </Box>
                </Box> 
                :
                <>
                    <Grid container spacing={2} marginBottom={'16px'}>
                    {props.customers?.docs?.map((elem => {
                        const color = getColour(elem.healthScore);
                        const colorBalancePerformance = getColourBalance(elem.creditsContract?.balancePerformance);
                        const colorBalanceSupoort = getColourBalance(elem.creditsContract?.balanceSupport);
                        return (
                            <Grid item  /* xs={12} sm={12} md={6} */ mobile={12} desktop={6}>
                                <Paper elevation={3} style={{borderRadius: '4px', padding: '24px', gap: '24px', height: '100%'}}>
                                    <Grid container spacing={1} marginBottom={'24px'}>
                                        <Grid item xs={8} tablet={5} display={'flex'} alignItems={'center'}>
                                            <img src={`${api}/files/customers/${elem.fileName}`} alt={elem.name} style={{width: '48px', height: '48px', marginRight: '9.5px'}} />
                                            <Typography variant="h5">{elem.name}</Typography>

                                        </Grid>
                                        <Grid item xs={4} tablet={3} sx={{display: 'flex', justifyContent: 'space-between'}}>
                                        <Tooltip placement="right" title="O Health Score é uma nota de 0 a 100 que representa a maturidade da sua estratégia de e-commerce com base nas ações ativas em cada quadrade">
                                            <Box display={"flex"} flexDirection={"column"} justifyContent={"center"} alignItems={"center"}>
                                                <Box position={"relative"} mb={"0.5rem"}>
                                                    <CircularProgress
                                                        variant="determinate"
                                                        value={100}
                                                        sx={{ color: "#e0e0e0", position: "absolute" }}
                                                        size={42}
                                                    />
                                                    <CircularProgress sx={{color}} variant="determinate" size={42} value={elem.healthScore} />
                                                    <Box position={"absolute"} display={"flex"} alignItems={"center"} justifyContent={"center"} height={"100%"} width={"100%"} top={0}>
                                                        <Typography variant='body2' fontWeight={'500'} sx={{color}} >{elem.healthScore ? elem.healthScore : 0}%</Typography>
                                                    </Box>
                                                </Box>
                                                </Box>
                                        </Tooltip>
                                        {
                                                userPermissionCheck(props.user, ["view_checklist", "create_checklist", "view_user", "edit_customer", "delete_customer"]) && (
                                                    <FCDropdownButton
                                                        sx={{ display: {tablet: 'none'} }}
                                                        items={[
                                                            elem.checklist?.length > 0 && userPermissionCheck(props.user, ["view_checklist"]) ? i18n.t('customer.viewChecklist') : '',
                                                            userPermissionCheck(props.user, ["create_checklist"]) ? i18n.t('customer.editChecklist') : '',
                                                            userPermissionCheck(props.user, ["view_user"]) ? i18n.t('customer.users') : '',
                                                            userPermissionCheck(props.user, ["edit_customer"]) ? i18n.t('customer.edit') : '',
                                                            userPermissionCheck(props.user, ["delete_customer"]) ? i18n.t('customer.delete') : '',

                                                        ]}
                                                        itemClickHandlers={[
                                                            () => history.push("/view-checklist/" + elem._id),
                                                            () => history.push("/create-checklist/" + elem._id),
                                                            () => history.push("/user/customer/" + elem._id),
                                                            () => history.push("/update-customer/" + elem._id),
                                                            () => toggleModal(elem._id),
                                                        ]}
                                                    />
                                                )
                                            }    
                                        </Grid>
                                        <Grid item tablet={4} sx={{display: {xs: 'none', tablet: 'flex' }}} justifyContent={'space-between'}>
                                            <div>
                                                <Typography variant="body2" color="888888">Taxa de conversão</Typography>
                                                {
                                                    (!elem?.pixel?.conversion && loadingMetrics) ? 
                                                    <CircularProgress size={16} /> 
                                                    :
                                                    <Typography variant="body1" fontSize={'16px'} fontWeight="500">{elem?.pixel?.conversion ? elem?.pixel?.conversion : "-"}</Typography>
                                                }
                                            </div>
                                            {
                                                userPermissionCheck(props.user, ["view_checklist", "create_checklist", "view_user", "edit_customer", "delete_customer"]) && (
                                                    <FCDropdownButton
                                                        items={[
                                                            elem.checklist?.length > 0 && userPermissionCheck(props.user, ["view_checklist"]) ? i18n.t('customer.viewChecklist') : '',
                                                            userPermissionCheck(props.user, ["create_checklist"]) ? i18n.t('customer.editChecklist') : '',
                                                            userPermissionCheck(props.user, ["view_user"]) ? i18n.t('customer.users') : '',
                                                            userPermissionCheck(props.user, ["edit_customer"]) ? i18n.t('customer.edit') : '',
                                                            userPermissionCheck(props.user, ["delete_customer"]) ? i18n.t('customer.delete') : '',

                                                        ]}
                                                        itemClickHandlers={[
                                                            () => history.push("/view-checklist/" + elem._id),
                                                            () => history.push("/create-checklist/" + elem._id),
                                                            () => history.push("/user/customer/" + elem._id),
                                                            () => history.push("/update-customer/" + elem._id),
                                                            () => toggleModal(elem._id),
                                                        ]}
                                                    />
                                                )
                                            }
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={1} marginBottom={'24px'}>
                                        <Grid item xs={6} tablet={4} sx={{display: {xs: 'flex', tablet: 'block'}, justifyContent: 'space-between'}}>
                                            <div style={{display: 'flex', alignItems: 'center'}} className='metrics'>
                                                <Icon className='icons' icon="dashicons:money-alt" style={{width: '32px', height: '32px', padding: '8px', borderRadius: '50%', backgroundColor: '#F3F3F3' }} />
                                                <div style={{marginLeft: '8px'}}>
                                                    <Typography variant="body2" color="888888">Alíquota de Comissão</Typography>
                                                    <Typography variant="body1" fontSize={'16px'} fontWeight="500">{elem?.commissionRate?.percentComission ? elem?.commissionRate?.percentComission + "%" : "-"}</Typography>
                                                </div>
                                            </div>
                                            <div style={{display: 'flex', alignItems: 'center'}}>
                                                <Icon className='icons' icon="mdi:invoice-text-arrow-right-outline" style={{width: '32px', height: '32px', padding: '8px', borderRadius: '50%', backgroundColor: '#F3F3F3' }} />
                                                <div style={{marginLeft: '8px'}}>
                                                    <Typography variant="body2" color="888888" className='ticket-name'>Ticket Médio</Typography>
                                                    {
                                                        (!elem?.orders?.average_ticket && loadingMetrics) ? 
                                                        <CircularProgress size={16} /> 
                                                        :
                                                        <Typography variant="body1" fontSize={'16px'} fontWeight="500">R$ {elem?.orders?.average_ticket ? elem?.orders?.average_ticket?.replace('.', ",") : "-"}</Typography>                                                }
                                                </div>
                                            </div>
                                        </Grid>
                                        <Grid item xs={6} tablet={3} sx={{display: {xs: 'flex', tablet: 'block'}, justifyContent: 'space-around'}}>
                                            <div style={{display: 'flex', alignItems: 'center'}} className='metrics'>
                                                <Icon className='icons' icon="mdi:performance" style={{width: '32px', height: '32px', padding: '8px', borderRadius: '50%', backgroundColor: '#F3F3F3' }} />
                                                <div style={{marginLeft: '8px'}}>
                                                    <Typography variant="body2" color="888888">Faturamento</Typography>
                                                    {
                                                        (!elem?.orders?.total_value && loadingMetrics) ? 
                                                        <CircularProgress size={16} /> 
                                                        :
                                                        <Typography variant="body1" fontSize={'16px'} fontWeight="500">{elem?.orders?.total_value ? minCurrency(elem?.orders?.total_value) : "-"}</Typography>
                                                    }
                                                </div>
                                            </div>
                                            <div style={{display: 'flex', alignItems: 'center'}}>
                                                <Icon className='icons' icon="solar:box-outline" style={{width: '32px', height: '32px', padding: '8px', borderRadius: '50%', backgroundColor: '#F3F3F3' }} />
                                                <div style={{marginLeft: '8px'}}>
                                                    <Typography variant="body2" color="888888">Pedidos</Typography>
                                                    {
                                                        (!elem?.orders?.total_value && loadingMetrics) ? 
                                                        <CircularProgress size={16} /> 
                                                        :
                                                    <Typography variant="body1" fontSize={'16px'} fontWeight="500">{elem?.orders?.total_value ? minCurrency(elem?.orders?.total_value) : "-"}</Typography>
                                                    }
                                                </div>
                                            </div>
                                        </Grid >
                                        <Grid item xs={12} tablet={5} >
                                            <div style={{display: 'flex', justifyContent: 'space-around'}} className='creditsContract'>
                                                <div /* className='performanceCredits' */>
                                                    <Typography variant="body2" color="888888" marginBottom={'6px'} >Performance</Typography>
                                                    <Typography variant="body2" marginBottom={'6px'}>Créditos: {elem?.creditsContract?.creditsPerformance ? elem?.creditsContract?.creditsPerformance : "-"}</Typography>
                                                    <div style={{display: 'flex'}}>
                                                    <Typography variant="body2" marginRight="2px">Saldo: </Typography>
                                                    <Typography variant="body2" color={colorBalancePerformance}>{elem?.creditsContract?.balancePerformance ? elem?.creditsContract?.balancePerformance : "-"}</Typography>
                                                    </div>
                                                </div>
                                                <div>
                                                    <Typography variant="body2" color="888888" marginBottom={'6px'}>Suporte</Typography>
                                                    <Typography variant="body2" marginBottom={'6px'}>Créditos: {elem?.creditsContract?.creditsSupport ? elem?.creditsContract?.creditsSupport : "-"}</Typography>
                                                    <div style={{display: 'flex'}}>
                                                    <Typography variant="body2" marginRight="2px">Saldo:</Typography>
                                                    <Typography variant="body2" color={colorBalanceSupoort}>{elem?.creditsContract?.balanceSupport ? elem?.creditsContract?.balanceSupport : "-"}</Typography>
                                                    </div>
                                                </div>
                                                <div class="taxa-conversao">
                                                    <Typography variant="body2" color="888888">Taxa de conversão</Typography>
                                                    {
                                                        (!elem?.pixel?.conversion && loadingMetrics) ? 
                                                        <CircularProgress size={16} /> 
                                                        :
                                                        <Typography variant="body1" fontSize={'16px'} fontWeight="500">{elem?.pixel?.conversion ? elem?.pixel?.conversion : "-"}</Typography>
                                                    }
                                                </div>
                                            </div>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={1}>
                                            {elem?.performance?.desktop ? (
                                                    Object.keys(elem?.performance?.desktop).map((key) => {
                                                        const score = elem?.performance?.desktop[key]
                                                        const color = getColour(score);
                                                        return (
                                                        (
                                                        <Grid item xs={6} tablet={3} desktop={3} >
                                                            <Box key={key} display={"flex"} alignItems={"center"} /* className='circular-performance' */ >
                                                            <Box position={"relative"} mb={"0.5rem"}>
                                                                <CircularProgress
                                                                variant="determinate"
                                                                value={100}
                                                                sx={{ color: "#e0e0e0", position: "absolute" }}
                                                                size={35}
                                                                />
                                                                <CircularProgress sx={{color}} variant="determinate" size={35} value={score} />
                                                                <Box position={"absolute"} display={"flex"} alignItems={"center"} justifyContent={"center"} height={"100%"} width={"100%"} top={0}>
                                                                <Typography variant='caption' fontWeight='500'  sx={{color}} >{score}%</Typography>
                                                                </Box>
                                                            </Box>
                                                            <Typography variant="caption" fontSize='12px' marginLeft='12px'>{getTitle(key)}</Typography>
                                                            </Box>
                                                        </Grid>
                                                        )
                                                        )
                                                    })
                                                    ) : '-' }          
                                    </Grid>
                                </Paper>
                            </Grid>
                        )
                    }))}
                    </Grid> 
                    <FCPagination showFirstButton showLastButton variant="outlined" shape="rounded" count={props.customers.totalPages} page={pageCurrent} onChange={(e, page) => setPageCurrent(page)} />
                </>    
                }
                <Modal isOpen={openModal} >
                    <FCModalHeader title={i18n.t('customer.deleteCustomer')} onClick={() => toggleModal()}/>
                    <ModalBody>
                        {i18n.t('customer.confirmDelete')}
                    </ModalBody>
                    <FCModalFooter
                        actionLabel={i18n.t('customer.delete')}
                        handleAction={deleteCustomer}
                        cancelLabel={i18n.t('customer.cancel')}
                        handleCancel={toggleModal}
                    />
                </Modal>
            </div>
    )
}


const mapStateToProps = state => ({
    customers: state.customer?.customers || [],
    metrics: state.customer?.metrics || []
})

export default connect(mapStateToProps, {...actions, ...actions2})(Customer);

