import React from 'react';
import { Card, Grid, Tooltip } from '@mui/material';
import { getIcon, getStatusDescription } from '../../utils/statusPlatinum';
import { formatTime } from '../../utils/String';

const statusShow = [0, 3, 8, 5, 30, 40, 50, 70, 75, 90];


const StatusView = ({ ordersStatusPlatinum, setSelectedStatus }) =>
    <Card className="card-general">
        <Grid container spacing={2} className="main-grid-stack" columns={{ md: statusShow?.length, sm: 5, mobile: 4, xs: 2 }} justifyContent="space-around">
            {statusShow.map((status, index) => (
                <Grid onClick={() => setSelectedStatus([status])} className='item-grid-stack' item xs={1} sm={1} md={1} key={index}>
                    <div className="box-status">
                        <div className="icon">{getIcon(status)}</div>
                        <div className="number">{ordersStatusPlatinum?.[status] || 0} {ordersStatusPlatinum?.stepsLated?.[status] && <Tooltip title="Pedidos com atraso na entrega."><small>({ordersStatusPlatinum?.stepsLated?.[status]})</small></Tooltip>}</div>
                        <div className="title">{getStatusDescription(status)}</div>
                        {!!ordersStatusPlatinum?.media?.[status] &&
                            <div className="mediaHours">{formatTime(ordersStatusPlatinum?.media?.[status]) || "Sem registros"}</div>
                        }
                    </div>
                </Grid>
            ))}
        </Grid>
    </Card>

export default StatusView;