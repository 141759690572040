import React, { useEffect, useState } from 'react'
import Intro from './Intro'
import { connect } from 'react-redux';
import Integrations from './Integrations';


const DataEnrichmentModal = (props) => {
  const [currentPage, setCurrentPage] = useState("");
  const [integrations, setIntegrations] = useState({});
  const [name, setName] = useState('');
  const [storeUrl, setStoreUrl] = useState('');
  const [userInfo, setUserInfo] = useState({});
  
  useEffect(() => {
    if (props.user?.trial && !props.user?.customer[0] && props.user?.role === 'customer') setCurrentPage("intro");
  }, [props.user]);

  switch (currentPage) {
    case "intro":
      return (
        <Intro 
          user={props.user}
          setUserInfo={setUserInfo}
          setCurrentPage={setCurrentPage} 
          setIntegrations={setIntegrations}
          setName={setName}
          setStoreUrl={setStoreUrl}
        />
      )
    case "integrations":
      return (
        <Integrations
          userInfo={userInfo}
          setCurrentPage={setCurrentPage}
          integrations={integrations}
          name={name}
          storeUrl={storeUrl}
        />
      )
    default:
      return <></>
  }
}
const mapStateToProps = state =>
({
  user: state.auth.user,
});

export default connect(mapStateToProps)(DataEnrichmentModal);