import { Box, Button, Grid, Paper, Tooltip, Typography } from '@mui/material'
import { Form, FormikProvider, useFormik } from 'formik';
import React, { useEffect } from 'react'
import { i18n } from '../../../translate/i18n';
import "dayjs/locale/pt-br";
import 'dayjs/locale/es';
import dayjs from 'dayjs';
import * as actions from '../../../store/actions/apps'
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { FCCheckbox, FCDatePicker, FCTextField } from 'components-fullcomm';

const Alerts = (props) => {

  useEffect(()=>{
    props.getApp("category=Alertas");
  }, [])

  var formik = useFormik({
    enableReinitialize: true,
    validateOnMount: true,
    initialValues: {
      start: props.app?.description?.start ? dayjs(props.app?.description?.start) : dayjs(new Date().toISOString().split("T")[0]),
      end: props.app?.description?.end ? dayjs(props.app?.description?.end) : "",
      message: props.app?.description?.message ? props.app?.description?.message : "",
      telephone: props.app?.description?.telephone ? props.app?.description?.telephone : "",
      recurring: props.app?.description?.recurring ? props.app?.description?.recurring : false,
      active: props.app?.description?.active ? props.app?.description?.active : true,
      btnText: props.app?.description?.btnText ? props.app?.description?.btnText : "",
    },
    // validationSchema: NewActionSchema,

    onSubmit: async (values) => {
      try {
        const response = await props.updateApp({ category: "Alertas", description: values });

        if (response.error) {
            toast.error(i18n.t('apps.utils.appFormCms.updateAppError'));
        } else {
          toast.success(i18n.t('apps.utils.appFormCms.updateAppSuccess'))
          props.getApp("category=Alertas");
        }
      } catch (error) {
        toast.error(i18n.t('apps.utils.appFormCms.error'));
      }
    },
  });

  const { handleSubmit, getFieldProps, getFieldHelpers, values } = formik;

  return (
    <Box className="dashboard">
      <Typography variant="h4">{i18n.t('apps.alerts.alerts')}</Typography>
      <FormikProvider value={formik}>
        <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
          <Box display={"flex"} justifyContent={"end"} mb={"1rem"}>
            <Button variant="contained" className='btn-action' sx={{ height: '36px' }} type='submit' onClick={() => { }}>
              {i18n.t('apps.utils.appFormCms.sincAlt')}
            </Button>
          </Box>
          <Paper sx={{ padding: "1rem", my: "1rem", borderRadius: '5px' }}>
            <Typography variant="h3" mb="1rem">{i18n.t('apps.alerts.chatSupportMessage')}</Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                  <FCTextField
                    multiline
                    rows={2}
                    fullWidth
                    label={i18n.t('apps.alerts.message')}
                    placeholder={i18n.t('apps.alerts.message')}
                    {...getFieldProps("message")}
                  />
              </Grid>
              <Grid item xs={12} md={6}>
                <FCDatePicker
                  disabled={values.recurring}
                  {...getFieldProps("start")}
                  label={i18n.t('apps.alerts.start')}
                  value={values.start}
                  onChange={(date) => {
                    getFieldHelpers('start').setValue(date || '');
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Tooltip title={"É importante adicionar DDD e código do país. Ex:  5548123123123"}>
                  <FCTextField
                    fullWidth
                    label={i18n.t('apps.alerts.telephone')}
                    placeholder={i18n.t('apps.alerts.telephone')}
                    {...getFieldProps("telephone")}
                  />
                </Tooltip>
              </Grid>
              <Grid item xs={12} md={6}>
                <FCDatePicker
                  disabled={values.recurring}
                  {...getFieldProps("end")}
                  minDate={values.start ? values.start : ''}
                  label={i18n.t('apps.alerts.end')}
                  value={values.end}
                  onChange={(date) => {
                    getFieldHelpers('end').setValue(date || '');
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FCTextField
                  fullWidth
                  label={i18n.t('apps.alerts.btnText')}
                  placeholder={i18n.t('apps.alerts.btnText')}
                  {...getFieldProps("btnText")}
                />
              </Grid>
              <Grid item xs={12} md={6} display="flex" gap="1rem" alignItems="end">
                <FCCheckbox
                  label={i18n.t('apps.alerts.weekends')}
                  onChange={() => {
                    getFieldHelpers('recurring').setValue(!values.recurring);
                  }}
                  checked={values.recurring}
                />
                <FCCheckbox
                  label={i18n.t('apps.alerts.active')}
                  onChange={() => {
                    getFieldHelpers('active').setValue(!values.active);
                  }}
                  checked={values.active}
                />
              </Grid>
            </Grid>
          </Paper>
        </Form>
      </FormikProvider>
    </Box>
  )
}

const mapStateToProps = (state) => ({
  app: state.app.app
});

export default connect(mapStateToProps, actions)(Alerts)