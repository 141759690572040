const agencies_reducer = (state = {}, actions) => {
  switch (actions.type) {
    case "GET_AGENCIES":
          return {
              ...state,
              agencies: actions.payload.agencies
          }
      case "GET_AGENCY":
          return {
              ...state,
              agencyDetails: actions.payload.agency
          }
      case "GET_AGENCY_VIEW":
          return {
              ...state,
              agencyDetailsView: actions.payload.agency
          }
      case "CLEAR_AGENCY":
          return {
              ...state,
              agencyDetails: null
          }
      case "CLEAR_AGENCIES":
          return {
              ...state,
              agencies: null
          }
      default:
          return state;
  }
}

export default agencies_reducer;