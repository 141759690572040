import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ActivityDetails from './ActivityDetails';
import { connect } from "react-redux";
import { i18n } from "../../../../../translate/i18n";
import { FCSelect } from 'components-fullcomm';

const ActivityAccordion = (props) => {
  const [description, setDescription] = useState([]);
  const position = props.selectedActivities.findIndex((obj) => obj.activity === props.activity._id);
  const [index, setIndex] = useState(0);
  const [responsible, setResponsible] = useState(props.selectedActivities[position]?.responsible || "FullPRO");
  const [squad, setSquad] = useState(props.selectedActivities[position]?.squad || "-1");
  const [status, setStatus] = useState(props.selectedActivities[position]?.status || "Backlog");
  const agenciesWithActivity = props.customer?.agency.reduce((acc, agency) => {
    if (agency.activities.find((activity) => activity === props.activity._id)) {
      acc.push(agency.name);
    }
    return acc;
  }, []);

  const responsibleOptions = ["FullPRO", props.customer?.name || "Cliente", ...agenciesWithActivity].filter(Boolean);

  useEffect(() => {
    if (!props.selectedActivities[position]) return;
    const {responsible} = props.selectedActivities[position];
    if (responsible) setResponsible(responsible);
  }, [position]);
  
  useEffect(() => {
    props.setSelectedActivities(prev => {
      const newArr = [...prev];
      const descriptionArray = newArr[position]?.descriptions || [];
      descriptionArray[index] = description;
      if (newArr[position]) newArr[position].descriptions = descriptionArray
      return newArr;
    });
  }, [description]);

  const handleChange = (property, value) => {
    if (property === 'responsible') {
      setResponsible(value);
    } else if (property === 'squad') {
      setSquad(value);
    } else if (property === 'status') {
      setStatus(value);
    }
    props.setSelectedActivities(prev => {
      const newArr = [...prev];
      if (newArr[position]) {
        newArr[position][property] = value;
      }
      return newArr;
    });
  };

  return (
    <>
      <FCSelect
        label={i18n.t('sprint.newAction.pages.activities.activityAccordion.responsible')}
        sx={{marginY: "0.5rem"}}
        select 
        fullWidth 
        multiline 
        onChange={(e) => handleChange('responsible', e.target.value)}
        value={responsible}
        options={responsibleOptions.map((option) => ({ value: option, label: option }))}
      />
      {
        responsible === "FullPRO" ? (
          <FCSelect
            label={i18n.t('sprint.newAction.pages.activities.activityAccordion.squad')}
            fullWidth
            select
            onChange={(e) => handleChange('squad', e.target.value)}
            value={squad}
            sx={{marginY: "0.5rem"}}
            options={[
              { value: "-1", label: i18n.t('sprint.newAction.pages.activities.activityAccordion.none') },
              { value: "10353", label: "Tech" },
              { value: "10356", label: "Scale" },
              { value: "10427", label: "Products" },
              { value: "10357", label: "Tech Suporte" },
              { value: "10354", label: "Tech Strike" },
              { value: "10351", label: "Backoffice" }
            ]}
          />
        ) : (
          <FCSelect
          label={i18n.t('sprint.newAction.pages.activities.activityAccordion.status')}
          fullWidth
          select
          onChange={(e) => handleChange('status', e.target.value)}
          value={status}
          sx={{marginY: "0.5rem"}}
          options={[
            { value: "Backlog", label: i18n.t('sprint.newAction.pages.activities.activityAccordion.backlog') },
            { value: "Concluído/Aplicado", label: i18n.t('sprint.newAction.pages.activities.activityAccordion.doneApplied') },
            { value: "Ativo", label: i18n.t('sprint.newAction.pages.activities.activityAccordion.active') }
          ]}
        />
        )
      }
      {
        props.activity?.fields?.length > 0 && (
          <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">{i18n.t('sprint.newAction.pages.activities.activityAccordion.activityDetails')}</Typography>
        </AccordionSummary>
        <AccordionDetails sx={{gap: "1rem", display: "grid"}}>
          {Array(props.amount).fill(0).map((_, index) => {
            return props.selectedActivities[position]?.descriptions &&
            <ActivityDetails 
            key={index}
            defaultValue={props.selectedActivities[position]?.descriptions[index]}
            setDescription={setDescription} 
            amount={props.amount}
            index={index} 
            setIndex={setIndex}
            activity={props.activity}
            />
          })}
        </AccordionDetails>
      </Accordion>
        )
      }
    </>
  )
}

const mapStateToProps = (state) => ({
  customer: state.customer?.customerDetails,
});

export default connect(mapStateToProps)(ActivityAccordion);