import React, { useEffect } from "react";
import Routes from "./routes/routes";
import { ToastContainer } from "react-toastify";

import { createTheme, ThemeProvider } from "@mui/material/styles";
import "react-toastify/dist/ReactToastify.css";
import { hotjar } from 'react-hotjar';

const theme = createTheme({
  typography: {
    fontFamily: ["Roboto"].join(","),
    caption: {
      fontSize: '12px',
      fontWeight: 500,
      lineHeight: '18px',
    },
    subtitle1: {
      fontSize: '16px',
      fontWeight: 500,
      lineHeight: '24px'
    },
    subtitle2: {
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '21px'
    },
    body1: {
      fontSize: '16px',
      fontWeight: 400,
      lineHeight: '24px',
    },
    body2: {
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '21px',
    },
    h1: {
      fontSize: '40px',
      fontWeight: 400,
      lineHeight: '60px',
    },
    h2: {
      fontSize: '34px',
      fontWeight: 300,
      lineHeight: '51px',
    },
    h3: {
      fontSize: '28px',
      fontWeight: 400,
      lineHeight: '42px',
    },
    h4: {
      fontSize: '24px',
      fontWeight: 500,
      lineHeight: '36px',
    },
    h5: {
      fontSize: '22px',
      fontWeight: 500,
      lineHeight: '33px',
    },
    h6: {
      fontSize: '20px',
      fontWeight: 500,
      lineHeight: '30px',
    },
    h7: {
      fontSize: '18px',
      fontWeight: 500,
      lineHeight: '21.09px',
    },
  },
  breakpoints:{
    values: {
      mobile: 380,
      tablet: 640,
      desktop: 1024,
      large: 1280,
      extraLarge: 1536,
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    }
  },
  palette: {
    primary: {
      main: "#333",
      dark: "#351414",
      darker: "#ba7272",
      contrastText: "#fff",
      lighter: "#edc636",
      light: "#eabf1d",
    },
    secondary: {
      lighter: "#ba7272",
      light: "#84A9FF",
      main: "#ba7272",
      dark: "#ba7272",
      darker: "#091A7A",
      contrastText: "#fff",
    },
    terciary: {
      main: "#c1c1c1",
    },
    default: {
      main: "#FFF",
    },
    greenNotification: {
      main: "#4eca80",
      contrastText: "#fff",
    },
    neutral: {
      main: "#888888",
      contrastText: "#000",
    },
    active: {
      dark: "#e0e0e0",
      main: "#85bc3a33",
      contrastText: "#85bc3a",
    },
    backlog: {
      dark: "#e0e0e0",
      main: "#86888c33",
      contrastText: "#86888c",
    },
    ongoing: {
      dark: "#e0e0e0",
      main: "#E0A40933",
      contrastText: "#E0A409",
    },
    pending_approval: {
      dark: "#e0e0e0",
      main: "#ff6f0033",
      contrastText: "#ff6f00",
    },
    concluded: {
      dark: "#e0e0e0",
      main: "#2c82d233",
      contrastText: "#2c82d2",
    },
    campaign: {
      dark: "#e0e0e0",
      main: "#a8a8a8",
      contrastText: "#fff",
    },
    to_do: {
      dark: "#e0e0e0",
      main: "#DB4E2F33",
      contrastText: "#DB4E2F",
    },
    planned: {
      dark: "#e0e0e0",
      main: "#B19CD933",
      contrastText: "#B19CD9",
    },
    cancelled: {
      dark: "#DBD9D9",
      main: "#F0F0F033",
      contrastText: "#F0F0F0",
    },
    yellow: {
      dark: "#FFDE49",
      main: "#FFDE49",
      contrastText: "#000",
    },
    action: {
      hover: "#ba7272",
      disabled: "#333",
      disabledBackground: "#333",
      focus: "#333",
      hoverOpacity: 0.08,
      disabledOpacity: 0.48,
    },
    'green-success': {
      dark: "#00D6CF33",
      main: "#00D6CF33",
      contrastText: "#00D6CF",
    },
    'danger-red': {
      dark: '#E74C3C33',
      main: '#E74C3C33',
      contrastText: '#E74C3C',
    },
    'primary-black':{
      dark: "#000000",
      main: "#000000",
      contrastText: "#fff",
    },
    'primary-green':{
      hover: "#198038",
      focus: "#0E6027",
      dark: "#00D6CF",
      main: "#00D6CF",
      contrastText: "#fff",
    },
    'primary-grey':{
      dark: "#D1D5DB",
      main: "#D1D5DB",
      contrastText: "#fff",
    },
    // white:{
    //   dark: "#FFFFF",
    //   main: "#FFFFF",
    //   contrastText: "#000",
    // },
    'success-purple':{
      dark: "#B196D9",
      main: "#B196D9",
      contrastText: "#fff",
    },
    background: {
      dark: '#FAFAFA',
      main: '#FAFAFA',
    },
    'background-2':{
      dark: "#F3F3F3",
      main: "#F3F3F3",
    },
    'light-grey-2': {
      dark: "#ECEDEF",
      main: "#ECEDEF",
    },
    'light-grey-3': {
      dark: "#F6F6F7",
      main: "#F6F6F7",
    },
    // neutral: {
    //   dark: "#2F2F2F",
    //   main: "#2F2F2F",
    // },
    'dark-grey': {
      dark: "#888888",
      main: "#888888",
    }
  },
});
function App() {
  hotjar.initialize(3483974, 6);

  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
        
    window.dataLayer.push (
      {
        event: "cs_begin_dataLayer",
        gtm: {uniqueEventId: 2, start: 1701082785959},
        timeStamp: new Date().getTime(),
      });

  }, []);
    
  return (
      <ThemeProvider theme={theme}>
        <Routes />
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
        />
      </ThemeProvider>
  );
}

export default App;
