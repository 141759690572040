export const getTaggedUsers = (inputValue) => {
  const regex = /data-id="([^"]+)"/g;
  const dataIds = [];
  let match;
  
  while ((match = regex.exec(inputValue)) !== null) {
    dataIds.push(match[1]);
  }
  
  return dataIds;
};