import React, { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux'
import { InputAdornment, Collapse, Typography, TableBody, TableCell, Table, TableRow, IconButton, Button, Box, Checkbox } from '@mui/material';
import { toast } from 'react-toastify';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import debounce from 'lodash.debounce';
import { isMobile } from 'react-device-detect';
import { userPermissionCheck } from '../utils/userPermissionCheck';
import { getActivitiesByVersion, getVersionsActivities, putAgencyActivities, uploadVersion, } from '../../../store/actions/activities';
import { getViewAgency } from '../../../store/actions/agencies';
import { i18n } from '../../../translate/i18n';
import { FCChip, FCPagination, FCReturnButton, FCSelect, FCTableContainer, FCTableHeader, FCTextField } from 'components-fullcomm';

function Row(props) {
    const { row, selected, handleClick, checkbox } = props;
    const [open, setOpen] = useState(false);

    return (
        <React.Fragment>
            <TableRow>
                <TableCell sx={{ border: 'none' }}>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell sx={{ border: 'none' }} component="th" scope="row">
                    {row.category}
                </TableCell>
                <TableCell sx={{ border: 'none' }}>{row.name}</TableCell>
                <TableCell sx={{ border: 'none' }}>{row.creditsCost}</TableCell>
                <TableCell sx={{ border: 'none' }}>{row.measurement}</TableCell>
                {
                    checkbox && (
                        <TableCell
                            padding='checkbox'
                            onClick={(event) => handleClick(event, row._id)}
                        >
                            <Checkbox
                                color="backlog"
                                checked={selected.indexOf(row._id) !== -1}
                            />
                        </TableCell>
                    )
                }
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <Typography variant="h6" gutterBottom component="div">
                                {i18n.t('sprint.tableActivity.table.description')}
                            </Typography>
                            <p>{row.description}</p>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

const Tables = (props) => {
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(50);
    const [category, setCategory] = useState('all');
    const [querySearch, setQuerySearch] = useState('');
    const [version, setVersion] = useState(props.version);
    const [selected, setSelected] = useState([]);
    const [checked, setChecked] = useState(false);
    const agency = props.match.params.agency || '';

    const handleSelectAllClick = (event) => {
        if (!checked) {
            const newSelected = props.activities?.docs?.reduce((acc, n) => {
                if (!selected.includes(n._id)) {
                    acc.push(n._id);
                }
                return acc;
            }, []);
            setSelected([...selected, ...newSelected]);
        } else setSelected([]);
    };

    const handleClick = (event, _id) => {
        const selectedIndex = selected.indexOf(_id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, _id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    };

    useEffect(() => {
        if ((selected.length > 0 && selected.length < props.activities?.docs?.length) || props.activities?.docs.every(obj => selected.includes(obj._id))) setChecked(true)
        else setChecked(false)
    }, [selected, props.activities]);

    useEffect(() => {
        props.getActivitiesByVersion(props.match.params.version || -1, { page, limit, querySearch, category });
    }, [page, limit, category]);

    useEffect(() => {
        if (props.user?.role === 'admin') props.getVersionsActivities()
    }, [props.user]);

    useEffect(() => {
        if (props.match.params.agency) props.getViewAgency(props.match.params.agency)
    }, []);

    useEffect(() => {
        if (props.agency?.activities) setSelected(props.agency.activities)
    }, [props.agency]);

    useEffect(() => {
        setVersion(props.version)
    }, [props.version]);

    const debouncedSearch = useCallback(
        debounce(async (query) => {
            setPage(0)
            props.getActivitiesByVersion(props.match.params.version || -1, { page: 0, limit, querySearch: query, category })
        }, 1000),
        []
    );

    const handleChangeVersion = () => {
        props.uploadVersion({ version: props.match.params.version })
        setVersion(props.match.params.version)
    }

    const putAgencyActivities = async () => {
        try {
            if (selected.length === 0) toast.error(i18n.t('sprint.tableActivity.table.noneSelected'))
            else {
                const response = await props.putAgencyActivities({ activities: selected, agency })

                if (response.error) {
                    switch (response.error.message) {
                        case "Não foi possível encontrar a Agência!": return toast.error(i18n.t('sprint.tableActivity.table.agencyNotFound'))
                        case "Erro interno do servidor.": return toast.error(i18n.t('sprint.tableActivity.table.internalError'));
                        default: return toast.error(response.error.message)
                    }
                } else {
                    if (response.message === "Atividades atualizadas com sucesso!") {
                        toast.success(i18n.t('sprint.tableActivity.table.updateSuccess'))
                    } else {
                        toast.success(response.message)
                    }
                }
            }
        } catch (error) {
            if (error.error.message === "Erro interno do servidor.") {
                toast.error(i18n.t('sprint.tableActivity.table.internalError'))
            } else {
                toast.error(error.error.message)
            }
        }
    }
    return (
        <div style={{ padding: isMobile ? '16px 16px 66px 16px' : '16px' }} className={!isMobile & "p-4"} >
            <div className="d-flex align-items-center justify-content-between mb-3">
                <div className="mr-auto d-flex align-items-center">
                    <FCReturnButton />
                    <Typography variant="h4">{i18n.t('sprint.tableActivity.table.activitiesTable')}</Typography>
                </div>
                {parseInt(props.match.params.version) === parseInt(version) && 
                    <FCChip 
                        color='green-success' 
                        size="small" 
                        sx={{ 'marginRight': '10px', height: isMobile ? '18px' : '28px', paddingX: isMobile && 0 }} 
                        label={i18n.t('sprint.tableActivity.table.activeTable')} 
                    />
                }
                {
                    agency.length === 0 && userPermissionCheck(props.user, ['edit_activity_version']) && parseInt(props.match.params.version) !== parseInt(version) && (
                        <Button onClick={() => handleChangeVersion()} className='btn-gray large' sx={{ width: '120px !important'}} disabled={parseInt(props.match.params.version) === parseInt(version)}>{i18n.t('sprint.tableActivity.table.activateTable')}</Button>
                    )
                }
                {
                    agency.length > 0 &&
                    <Button onClick={putAgencyActivities} className='btn-gray' variant="contained">{i18n.t('sprint.tableActivity.table.addActivities')}</Button>
                }
            </div>
                <Box display='flex' alignItems='end' gap='0.5rem' mb='1rem'>
                    <FCSelect
                        sx={{ width: isMobile ? '11rem' : '290px' }}
                        label={i18n.t('sprint.tableActivity.table.categoryLabel')}
                        value={category}
                        onChange={(e) => setCategory(e.target.value)}
                        options={[{ value: 'all', label: i18n.t('sprint.tableActivity.table.all') }, ...(props.categories?.map(category => ({ label: category, value: category })) || [])]}
                    />
                    <FCTextField
                        placeholder={i18n.t('user.search')}
                        sx={{ width: isMobile ? '11rem' : '290px' }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M8.57633 7.54717H8.03431L7.8422 7.36192C8.51458 6.57976 8.91938 5.56432 8.91938 4.45969C8.91938 1.99657 6.92281 0 4.45969 0C1.99657 0 0 1.99657 0 4.45969C0 6.92281 1.99657 8.91938 4.45969 8.91938C5.56432 8.91938 6.57976 8.51458 7.36192 7.8422L7.54717 8.03431V8.57633L10.9777 12L12 10.9777L8.57633 7.54717ZM4.45969 7.54717C2.75129 7.54717 1.37221 6.1681 1.37221 4.45969C1.37221 2.75129 2.75129 1.37221 4.45969 1.37221C6.1681 1.37221 7.54717 2.75129 7.54717 4.45969C7.54717 6.1681 6.1681 7.54717 4.45969 7.54717Z" fill="#888888"/>
                                    </svg>
                                </InputAdornment>
                            ),
                        }}
                        value={querySearch}
                        onChange={(e) => { setQuerySearch(e.target.value); debouncedSearch(e.target.value) }}
                    />
                </Box>
                {isMobile ? (
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            borderRadius: '4px',
                            border: '1px solid #D1D5DB',
                        }}
                    >
                        {props.activities?.docs?.map((row, i) => (
                            <Box 
                                key={row._id}
                                borderTop={i !== 0 ? '1px solid #D1D5DB' : ''}
                                paddingY='1rem'
                                paddingX='0.5rem'
                                display={'flex'}
                                justifyContent={'space-between'}
                            >
                                <Box>
                                    <Typography variant="subtitle2">{i18n.t('sprint.tableActivity.table.category')}</Typography>
                                    <Typography variant="body2">{row.category}</Typography>
                                    <Typography variant="subtitle2">{i18n.t('sprint.tableActivity.table.activities')}</Typography>
                                    <Typography variant="body2">{row.name}</Typography>
                                </Box>
                                <Box display='flex'>
                                    <Box>
                                        <Typography variant="subtitle2">{i18n.t('sprint.tableActivity.table.credits')}</Typography>
                                        <Typography variant='body2'>{row.creditsCost}</Typography>
                                        <Typography variant="subtitle2">{i18n.t('sprint.tableActivity.table.unitMensurement')}</Typography>
                                        <Typography variant='body2'>{row.measurement}</Typography>
                                    </Box>
                                </Box>
                            </Box>
                        ))}
                    </Box>
                ) : (
                    <FCTableContainer>
                        <Table sx={{ minWidth: 650 }}>
                            <FCTableHeader>
                                <TableRow>
                                    <TableCell></TableCell>
                                    <TableCell>{i18n.t('sprint.tableActivity.table.category')}</TableCell>
                                    <TableCell>{i18n.t('sprint.tableActivity.table.activities')}</TableCell>
                                    <TableCell>{i18n.t('sprint.tableActivity.table.credits')}</TableCell>
                                    <TableCell>{i18n.t('sprint.tableActivity.table.unitMensurement')}</TableCell>
                                    {
                                        agency.length > 0 &&
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                color="backlog"
                                                indeterminate={selected.length > 0 && !props.activities?.docs.every(obj => selected.includes(obj._id))}
                                                checked={checked}
                                                onChange={handleSelectAllClick}
                                            />
                                        </TableCell>
                                    }
                                </TableRow>
                            </FCTableHeader>
                            <TableBody>
                                {props.activities?.docs?.map((row) => (
                                    <Row key={row._id} row={row} handleClick={handleClick} selected={selected} checkbox={agency.length > 0} />
                                ))}
                            </TableBody>
                        </Table>
                    </FCTableContainer>
                )}
                <FCPagination
                    sx={{
                        marginTop: '1.5rem',
                        marginBottom: isMobile && '1rem',
                    }}
                    count={props.activities?.totalDocs}
                    rowsPerPage={limit}
                    page={props.activities?.page - 1}
                    onChange={(e, page) => setPage(page)}
                />
        </div >
    )
}


const mapStateToProps = state => {
    return {
        activities: state.activities?.activities,
        categories: state.activities?.categories,
        user: state.auth?.user,
        version: state.activities?.versionTableActivities,
        agency: state.agencies?.agencyDetailsView
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getActivitiesByVersion: (version, params) => dispatch(getActivitiesByVersion(version, params)),
        getVersionsActivities: () => dispatch(getVersionsActivities()),
        uploadVersion: (version) => dispatch(uploadVersion(version)),
        getViewAgency: (agency) => dispatch(getViewAgency(agency)),
        putAgencyActivities: (data) => dispatch(putAgencyActivities(data))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Tables);
