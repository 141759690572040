import { i18n } from '../i18n';

export const handleLabelActivityStatus = (status) => {
  switch (status) {
    case "Para fazer": return i18n.t('sprint.kanban.activityCard.toDo');
    case "Em andamento": return i18n.t('sprint.kanban.activityCard.inProgress');
    case "Homologação": return i18n.t('sprint.kanban.activityCard.customerApproval');
    case "Concluído/Aplicado": return i18n.t('sprint.kanban.activityCard.doneApplied');
    default: return status;
  }
}

export const translatePerformanceMetrics = (metric) => {
  switch (metric) {
    case "Desempenho": return i18n.t('dashboard.performance');
    case "Acessibilidade": return i18n.t('dashboard.accessibility');
    case "Práticas recomendadas": return i18n.t('dashboard.recommendedPractices');
    case "SEO": return i18n.t('dashboard.seo');
    case "PWA": return i18n.t('dashboard.pwa');
    default: return metric;
  }
}

export   const traslateLogisticStatus = (status) => {
  switch (status) {
    case 'created':
      return { status: i18n.t('scale.logistics.orders.created'), color: 'secondary' };
    case 'error':
      return { status: i18n.t('scale.logistics.orders.error'), color: 'error' };
    case 'processed':
      return { status: i18n.t('scale.logistics.orders.processed'), color: 'yellow' };
    case 'shipment':
      return { status: i18n.t('scale.logistics.orders.shipment'), color: 'warning' };
    case 'shipping':
      return { status: i18n.t('scale.logistics.orders.shipping'), color: 'success' };
    case 'customer':
      return { status: i18n.t('scale.logistics.orders.customer'), color: 'info' };
    case 'invoiced':
      return { status: i18n.t('scale.logistics.orders.invoiced'), color: 'primary' };
    default:
      return { status: '', color: 'error' };
  }
};

export const handleDocumentType = (type) => {
  switch (type) {
    case "Nota de reunião": return i18n.t('documents.form.meetingNote');
    case "Requisitos de projeto": return i18n.t('documents.form.projectRequirements');
    case "Requisitos operacionais": return i18n.t('documents.form.operationalRequirements');
    case "Relatório": return i18n.t('documents.form.report');
    case "Outros": return i18n.t('documents.form.other');
    case "Todos": return i18n.t('documents.form.all');
    default: return type;
  }
}

export const handleChangeName = (name) => {
    switch (name) {
      case "Página Inicial": return i18n.t('sprint.newAction.pages.activities.initialPage');
      case "Página de Categoria": return i18n.t('sprint.newAction.pages.activities.categoryPage');
      case "Página de Produto": return i18n.t('sprint.newAction.pages.activities.productPage');
      case "Atualização de Preços via planilha": return i18n.t('sprint.newAction.pages.activities.priceUpdate');
      case "Atualização de Produtos": return i18n.t('sprint.newAction.pages.activities.productUpdate');
      case "Página Diversas: carrinho, minha conta, sobre, etc": return i18n.t('sprint.newAction.pages.activities.diversePage');
      case "Landing Page promocional, upsell, carta de vendas, etc" || "Landing Page: promocional, upsell, carta de vendas, etc": return i18n.t('sprint.newAction.pages.activities.landingPage');
      case "Página Home promocional": return i18n.t('sprint.newAction.pages.activities.promotionalHomePage');
      case "Elementos Principais: header, footer e folha de estilos": return i18n.t('sprint.newAction.pages.activities.mainElements');
      case "Criação/Correção de Planilha": return i18n.t('sprint.newAction.pages.activities.spreadsheetCreation');
      case "UI Kit": return i18n.t('sprint.newAction.pages.activities.uiKit');
      case "Designer UX (por hora)": return i18n.t('sprint.newAction.pages.activities.uxDesignerHour');
      case "Design de E-mail (Autoresponder, Broadcast e Transacional)": return i18n.t('sprint.newAction.pages.activities.emailDesign');
      case "E-book Design e Diagramação": return i18n.t('sprint.newAction.pages.activities.ebookDesign');
      case "Tratamento de imagem (para web)": return i18n.t('sprint.newAction.pages.activities.imageTreatment');
      case "Recortar fundo de imagem (para web)": return i18n.t('sprint.newAction.pages.activities.imageBackgroundCutting');
      case "Design de Ads em Vídeo (15seg)": return i18n.t('sprint.newAction.pages.activities.videoAdsDesign');
      case "Motion Graphics": return i18n.t('sprint.newAction.pages.activities.motionGraphics');
      case "Design de Ads em Vídeo (30seg)": return i18n.t('sprint.newAction.pages.activities.videoAdsDesign30');
      case "Edição de vídeo (pontuação por minutagem)": return i18n.t('sprint.newAction.pages.activities.videoEditingPerMin');
      case "Legendação de vídeo (pontuação por minutagem)": return i18n.t('sprint.newAction.pages.activities.videoCaptioningPerMin');
      case "Criação de Cupons": return i18n.t('sprint.newAction.pages.activities.couponCreation');
      case "Formulário de Captação": return i18n.t('sprint.newAction.pages.activities.captationForm');
      case "Gestão de Scripts e Rastreadores (via GTM)": return i18n.t('sprint.newAction.pages.activities.scriptManagement');
      case "Conciliação Manual de Pagamentos": return i18n.t('sprint.newAction.pages.activities.manualPaymentReconciliation');
      case "Configurar automação de merchandising (Launchpad)": return i18n.t('sprint.newAction.pages.activities.merchandisingAutomation');
      case "Configurar automação de tarefas (Flow)": return i18n.t('sprint.newAction.pages.activities.taskAutomation');
      case "Moderação de avaliações": return i18n.t('sprint.newAction.pages.activities.reviewsModeration');
      case "Configuração de Anúncio": return i18n.t('sprint.newAction.pages.activities.adConfiguration');
      case "Configuração de Kit para Anúncio": return i18n.t('sprint.newAction.pages.activities.adKitConfiguration');
      case "Gestão de Preços": return i18n.t('sprint.newAction.pages.activities.priceManagement');
      case "Configuração de Regras": return i18n.t('sprint.newAction.pages.activities.rulesConfiguration');
      case "Gestão de Fretes": return i18n.t('sprint.newAction.pages.activities.freightManagement');
      case "Edição entradas DNS": return i18n.t('sprint.newAction.pages.activities.dnsEntriesEditing');
      case "Integração (via middleware)": return i18n.t('sprint.newAction.pages.activities.integrationMiddleware');
      case "Instalação de Scripts e Rastreadores (via código)": return i18n.t('sprint.newAction.pages.activities.scriptInstallationCode');
      case "Instalação de Scripts e Rastreadores (via GTM)": return i18n.t('sprint.newAction.pages.activities.scriptInstallationGtm');
      case "Desenvolvimento Front-end (por hora)": return i18n.t('sprint.newAction.pages.activities.frontEndDevHour');
      case "Desenvolvimento Full-stack (por hora)": return i18n.t('sprint.newAction.pages.activities.fullStackDevHour');
      case "Agendamento de E-mail": return i18n.t('sprint.newAction.pages.activities.emailScheduling');
      case "Arquiteto de Software (por hora)": return i18n.t('sprint.newAction.pages.activities.softwareArchitectHour');
      case "Notificação Push/SMS": return i18n.t('sprint.newAction.pages.activities.pushNotificationSms');
      case "Configuração de segmentos de clientes": return i18n.t('sprint.newAction.pages.activities.customerSegmentsConfig');
      case "Setup do Funil de Automação de Marketing": return i18n.t('sprint.newAction.pages.activities.marketingAutoFunnelSetup');
      case "Setup de Eventos Personalizados (via GTM)": return i18n.t('sprint.newAction.pages.activities.customEventsSetup');
      case "Assessoria de Imprensa (mídia local SC)": return i18n.t('sprint.newAction.pages.activities.scPressAdvisory');
      case "Assessoria de Imprensa (mídia nacional)": return i18n.t('sprint.newAction.pages.activities.nationalPressAdvisory');
      case "E-book (apenas texto)": return i18n.t('sprint.newAction.pages.activities.ebookTextOnly');
      case "Assessoria de Imprensa (clipping)": return i18n.t('sprint.newAction.pages.activities.pressAdvisoryClipping');
      case "Redação publicitária de produto": return i18n.t('sprint.newAction.pages.activities.productAdvertisingWriting');
      case "Redação de Conteúdo de Páginas": return i18n.t('sprint.newAction.pages.activities.pagesContentWriting');
      case "Relatório Personalizado de Marketing": return i18n.t('sprint.newAction.pages.activities.customMktReport');
      case "Relatório Personalizado de Vendas": return i18n.t('sprint.newAction.pages.activities.customSalesReport');
      case "Conteúdo de SEO": return i18n.t('sprint.newAction.pages.activities.seoContent');
      case "Ajuste técnico para SEO": return i18n.t('sprint.newAction.pages.activities.seoAdjustment');
      case "Facebook Business": return i18n.t('sprint.newAction.pages.activities.facebookBusiness');
      case "Facebook Página": return i18n.t('sprint.newAction.pages.activities.facebookPage');
      case "Facebook Conta Instagram": return i18n.t('sprint.newAction.pages.activities.fbInstagramAccount');
      case "Facebook Conta de Anúncios": return i18n.t('sprint.newAction.pages.activities.fbAdsAccount');
      case "Facebook Pixel": return i18n.t('sprint.newAction.pages.activities.fbPixel');
      case "Facebook Catálogo de Produtos": return i18n.t('sprint.newAction.pages.activities.fbProductCatalog');
      case "Facebook Permissões de Tráfego do Pixel": return i18n.t('sprint.newAction.pages.activities.fbPixelTrafficPermissions');
      case "DataLayer": return i18n.t('sprint.newAction.pages.activities.dataLayer');
      case "Google Tag Manager": return i18n.t('sprint.newAction.pages.activities.googleTagManager');
      case "Google Analytics": return i18n.t('sprint.newAction.pages.activities.googleAnalytics');
      case "Google Analytics Comércio Eletrônico Avançado": return i18n.t('sprint.newAction.pages.activities.advancedEcommerceGA');
      case "Google Search Console": return i18n.t('sprint.newAction.pages.activities.googleSearchConsole');
      case "Google Merchant Center": return i18n.t('sprint.newAction.pages.activities.googleMerchantCenter');
      case "Google Ads": return i18n.t('sprint.newAction.pages.activities.googleAds');
      case "Google Ads Conv. Compra": return i18n.t('sprint.newAction.pages.activities.googleAdsConvBuy');
      case "Google Ads Remarketing": return i18n.t('sprint.newAction.pages.activities.googleAdsRemarketing');
      case "Vinculação do Google Ads com Google Analytics": return i18n.t('sprint.newAction.pages.activities.googleAdsAnalyticsLink');
      case "Google Ads Vinculador de conversões": return i18n.t('sprint.newAction.pages.activities.googleAdsConvLink');
      case "Vinculação do Google Ads com Merchant Center": return i18n.t('sprint.newAction.pages.activities.googleAdsMerchantLink');
      case "Implementação dos eventos do GA4 via Google Tag Manager": return i18n.t('sprint.newAction.pages.activities.ga4EventsImplementation');
      case "Implementação dos relatórios do GA4": return i18n.t('sprint.newAction.pages.activities.ga4ReportsImplementation');
      case "Tag da Plataforma de Marketing Cloud": return i18n.t('sprint.newAction.pages.activities.marketingCloudTag');
      case "Tradução multilíngua por página/template": return i18n.t('sprint.newAction.pages.activities.multilingualTranslation');
      case "Setup Campanha de Search Ads": return i18n.t('sprint.newAction.pages.activities.searchAdsCampaignSetup');
      case "Setup Campanha de Product Listing Ads (Shopping)": return i18n.t('sprint.newAction.pages.activities.productListingAdsCampaignSetup');
      case "Setup Campanha de Lead Ads": return i18n.t('sprint.newAction.pages.activities.leadAdsCampaignSetup');
      case "Setup Campanha de Display Ads": return i18n.t('sprint.newAction.pages.activities.displayAdsCampaignSetup');
      case "Setup Campanha de Vídeo Ads": return i18n.t('sprint.newAction.pages.activities.videoAdsCampaignSetup');
      case "Configuração de públicos de interesse": return i18n.t('sprint.newAction.pages.activities.interestAudiencesConfig');
      case "Configuração de públicos personalizados": return i18n.t('sprint.newAction.pages.activities.customAudiencesConfig');
      case "Otimização de campanhas": return i18n.t('sprint.newAction.pages.activities.campaignOptimization');
      case "Análise de Usabilidade de Navegação": return i18n.t('sprint.newAction.pages.activities.navigationUsabilityAnalysis');
      case "Recortes e Redimensionamento de Imagens": return i18n.t('sprint.newAction.pages.activities.imageCuttingResizing');
      case "Ajustes de criativos": return i18n.t('sprint.newAction.pages.activities.creativeAdjustments');
      case "Relatório de SEO (off-page)": return i18n.t('sprint.newAction.pages.activities.seoReport');
      case "Cadastro de produtos ERP": return i18n.t('sprint.newAction.pages.activities.erpProductRegistration');
      case "Relatório de SEO (in-page)": return i18n.t('sprint.newAction.pages.activities.seoReportInPage');
      case "Otimização de Vitrine": return i18n.t('sprint.newAction.pages.activities.showcaseOptimization');
      case "Configuração de App (por hora)": return i18n.t('sprint.newAction.pages.activities.appConfigurationHour');
      case "Cadastro de conteúdo (páginas institucionais, blog post, FAQ..)": return i18n.t('sprint.newAction.pages.activities.contentRegistration');
      case "Gestão de Scripts e rastreadores (por hora)": return i18n.t('sprint.newAction.pages.activities.scriptManagementHour');
      case "Criação conteúdo SMS": return i18n.t('sprint.newAction.pages.activities.smsContentCreation');
      case "Análise da estratégia de tráfego": return i18n.t('sprint.newAction.pages.activities.trafficStrategyAnalysis');
      case "Análise do fluxo de navegação": return i18n.t('sprint.newAction.pages.activities.navigationFlowAnalysis');
      case "Análise da taxa de conversão": return i18n.t('sprint.newAction.pages.activities.conversionRateAnalysis');
      case "Notificação on-site": return i18n.t('sprint.newAction.pages.activities.onsiteNotification');
      case "Relatórios de Metas e Projeções": return i18n.t('sprint.newAction.pages.activities.goalsProjectionsReports');
      case "Relatório de Benchmarking - SEO e PPC": return i18n.t('sprint.newAction.pages.activities.seoPpcBenchmarkingReport');
      case "Desenho de Funil de Marketing para campanhas de Tráfego Orgânico": return i18n.t('sprint.newAction.pages.activities.organicTrafficMktFunnelDesign');
      case "Desenho de Funil de Marketing para campanhas de Lifecycle Marketing": return i18n.t('sprint.newAction.pages.activities.lifecycleMktFunnelDesign');
      case "Revisão de Tags implementadas no Google Tag Manager de diversas plataformas": return i18n.t('sprint.newAction.pages.activities.gtmTagsReview');
      case "Relatório de Cobranças das plataformas de Tráfego Pago": return i18n.t('sprint.newAction.pages.activities.paidTrafficBillingReport');
      case "Relatório de Performance (Velocidade, Usabilidade, Taxa de Conversão e Canais de Aquisição)": return i18n.t('sprint.newAction.pages.activities.performanceReport');
      case "Relatório e Análise de Performance de Lifecycle Marketing": return i18n.t('sprint.newAction.pages.activities.lifecycleMktPerformanceReport');
      case "Desenvolvimento de template HTML": return i18n.t('sprint.newAction.pages.activities.htmlTemplateDev');
      case "Design de Banner E-commerce (desktop)": return i18n.t('sprint.newAction.pages.activities.ecommerceBannerDesignDesktop');
      case "Design de Banner E-commerce (mobile)": return i18n.t('sprint.newAction.pages.activities.ecommerceBannerDesignMobile');
      case "Design de Anúncio Estático": return i18n.t('sprint.newAction.pages.activities.staticAdDesign');
      case "Design de Anúncio Carrossel": return i18n.t('sprint.newAction.pages.activities.carouselAdDesign');
      case "Design de Anúncio Stories": return i18n.t('sprint.newAction.pages.activities.storiesAdDesign');
      case "Conjunto de Criativos para Mídia Programática": return i18n.t('sprint.newAction.pages.activities.programmaticMediaCreativeSet');
      case "Conjunto de Criativos para Google Performance Max": return i18n.t('sprint.newAction.pages.activities.googlePrfmMaxCreativeSet');
      case "Merchandising (página inicial)": return i18n.t('sprint.newAction.pages.activities.merchandisingInitialPage');
      case "Cadastro de banners simples": return i18n.t('sprint.newAction.pages.activities.simpleBannerRegistration');
      case "Cadastro de banners completo": return i18n.t('sprint.newAction.pages.activities.completeBannerRegistration');
      case "Migração de Segmentos e Contatos de E-mail Marketing": return i18n.t('sprint.newAction.pages.activities.emailMktContactsMigration');
      case "Migração de Fluxos de E-mail Marketing": return i18n.t('sprint.newAction.pages.activities.emailMktFlowsMigration');
      case "Ordenamento e Otimização de Coleções (automática)": return i18n.t('sprint.newAction.pages.activities.collOptimizationAutomatic');
      case "Ordenamento e Otimização de Coleções (manual)": return i18n.t('sprint.newAction.pages.activities.collOptimizationManual');
      case "Instalar Shopify App": return i18n.t('sprint.newAction.pages.activities.shopifyAppInstallation');
      case "Instalar App Vtex Marketplace": return i18n.t('sprint.newAction.pages.activities.vtexAppInstallation');
      case "Instalar Plugin Wordpress": return i18n.t('sprint.newAction.pages.activities.wordpressPluginInstallation');
      case "Configuração de automação de dados via plataforma externa": return i18n.t('sprint.newAction.pages.activities.externalPlatformDataAutomationConfig');
      case "Configuração de webhooks": return i18n.t('sprint.newAction.pages.activities.webhooksConfig');
      case "Conteúdo blog post (curto)": return i18n.t('sprint.newAction.pages.activities.blogPostContentShort');
      case "Conteúdo blog post (longo)": return i18n.t('sprint.newAction.pages.activities.blogPostContentLong');
      case "Criação de Landing Page (ferramenta automação de marketing)": return i18n.t('sprint.newAction.pages.activities.landingPageCreation');
      case "Redação de Anúncios de Search Ads": return i18n.t('sprint.newAction.pages.activities.searchAdsAdWriting');
      case "Redação de Chamadas para Ads": return i18n.t('sprint.newAction.pages.activities.adsCallWriting');
      case "Redação de Chamadas para banner e-commerce": return i18n.t('sprint.newAction.pages.activities.ecommerceBannerCallWriting');
      case "Redação de E-mails (formato longo)": return i18n.t('sprint.newAction.pages.activities.emailWritingLong');
      case "Redação de Chamadas para E-mails": return i18n.t('sprint.newAction.pages.activities.emailCallWriting');
      case "Relatório Personalizado de Mídia Paga": return i18n.t('sprint.newAction.pages.activities.customPaidMediaReport');
      case "Tag da Plataforma de Testes e Experimentos": return i18n.t('sprint.newAction.pages.activities.testingPlatformTag');
      case "Tag da Plataforma de Análise de Usabilidade": return i18n.t('sprint.newAction.pages.activities.usabilityAnalysisPlatformTag');
      case "Configuração de Pixels, Tags e Eventos de plataformas de Ads": return i18n.t('sprint.newAction.pages.activities.adsPlatformsPixelsTagsEventsConfig');
      case "Setup Campanha de Performance Max": return i18n.t('sprint.newAction.pages.activities.performanceMaxCampaignSetup');
      case "Setup Campanha de Social Ads": return i18n.t('sprint.newAction.pages.activities.socialAdsCampaignSetup');
      case "Desenho de Funil de Marketing para campanhas da Tráfego Pago": return i18n.t('sprint.newAction.pages.activities.paidTrafficMktFunnelDesign');
      case "Teste A/B (texto ou imagem)": return i18n.t('sprint.newAction.pages.activities.abTestTextImage');
      case "Teste A/B (snippet de código)": return i18n.t('sprint.newAction.pages.activities.abTestCodeSnippet');
      case "Criação de Promoções": return i18n.t('sprint.newAction.pages.activities.promotionCreation');
      case "Criação de Coleções": return i18n.t('sprint.newAction.pages.activities.collectionCreation');
      case "Gestão da Navegação (menus e links)": return i18n.t('sprint.newAction.pages.activities.navigationManagement');
      case "Configuração de regras de frete": return i18n.t('sprint.newAction.pages.activities.freightRulesConfig');
      case "Desenvolvimento de script via Scripts Editor": return i18n.t('sprint.newAction.pages.activities.scriptDevScriptsEditor');
      case "Cadastro de Produtos": return i18n.t('sprint.newAction.pages.activities.productRegistration');
      case "Importação de Produtos via planilha padronizada": return i18n.t('sprint.newAction.pages.activities.productImport');
      case "Adicionar filtros no catálogo (via tags ou especificação de produto)": return i18n.t('sprint.newAction.pages.activities.catalogFiltersAdd');
      case "Configurar variações (via opção ou metacampo)": return i18n.t('sprint.newAction.pages.activities.variationsConfig');
      case "Adicionar filtros no catálogo (via especificação de produto)": return i18n.t('sprint.newAction.pages.activities.catalogFiltersAddProductSpec');
      case "Configurar variações (via especificação de produto)": return i18n.t('sprint.newAction.pages.activities.variationsConfigProductSpec');
      case "Configurar variações (via opção)": return i18n.t('sprint.newAction.pages.activities.variationsConfigOption');
      case "Adicionar filtros no catálogo (via tags, opção ou metacampos)": return i18n.t('sprint.newAction.pages.activities.catalogFiltersAddTagsOptionMetacamp');
      default: return name;
    }
  }

  export const handleChangeCategory = (category) => {
    switch (category) {
      case "Desenv. de Interface": return i18n.t('sprint.newAction.pages.activities.interfaceDev')
      case "Inventário": return i18n.t('sprint.newAction.pages.activities.inventory');
      case "Design de Interface": return i18n.t('sprint.newAction.pages.activities.interfaceDesign');
      case "Design Gráfico": return i18n.t('sprint.newAction.pages.activities.graphicDesign');
      case "Edição de Imagem": return i18n.t('sprint.newAction.pages.activities.imageEditing');
      case "Edição de Vídeo": return i18n.t('sprint.newAction.pages.activities.videoEditing');
      case "Gestão de E-commerce": return i18n.t('sprint.newAction.pages.activities.ecommerceManagement');
      case "Gestão de Marketplace": return i18n.t('sprint.newAction.pages.activities.marketplaceManagement');
      case "Implantação": return i18n.t('sprint.newAction.pages.activities.deployment');
      case "Marketing Digital": return i18n.t('sprint.newAction.pages.activities.digitalMarketing');
      case "Redação Publicitária": return i18n.t('sprint.newAction.pages.activities.advertisingWriting');
      case "Relatórios": return i18n.t('sprint.newAction.pages.activities.reports');
      case "SEO": return i18n.t('sprint.newAction.pages.activities.seo');
      case "Setup de Marketing": return i18n.t('sprint.newAction.pages.activities.marketingSetup');
      case "Tradução": return i18n.t('sprint.newAction.pages.activities.translation');
      case "Tráfego Pago": return i18n.t('sprint.newAction.pages.activities.paidTraffic');
      case "UI & UX": return i18n.t('sprint.newAction.pages.activities.uiUx');
      case "CRO": return i18n.t('sprint.newAction.pages.activities.cro');
      default: return category;
    }
  }

  export const handleChangeMeasurement = (measurement) => {
    switch (measurement) {
      case "por template": return i18n.t('sprint.newAction.pages.activities.perTemplate');
      case "por hora": return i18n.t('sprint.newAction.pages.activities.perHour');
      case "unidade" || "Por unidade": return i18n.t('sprint.newAction.pages.activities.unit');
      case "por notificação": return i18n.t('sprint.newAction.pages.activities.perNotification');
      case "a cada 10 páginas": return i18n.t('sprint.newAction.pages.activities.every10Pages');
      case "por foto": return i18n.t('sprint.newAction.pages.activities.perPhoto');
      case "por anúncio": return i18n.t('sprint.newAction.pages.activities.perAd');
      case "por minuto": return i18n.t('sprint.newAction.pages.activities.perMinute');
      case "por regra": return i18n.t('sprint.newAction.pages.activities.perRule');
      case "por script": return i18n.t('sprint.newAction.pages.activities.perScript');
      case "por transação": return i18n.t('sprint.newAction.pages.activities.perTransaction');
      case "por ação": return i18n.t('sprint.newAction.pages.activities.perAction');
      case "por automação": return i18n.t('sprint.newAction.pages.activities.perAutomation');
      case "por avaliação": return i18n.t('sprint.newAction.pages.activities.perReview');
      case "por marketplace": return i18n.t('sprint.newAction.pages.activities.perMarketplace');
      case "por produto": return i18n.t('sprint.newAction.pages.activities.perProduct');
      case "por apontamento": return i18n.t('sprint.newAction.pages.activities.perPointing');
      case "por conexão": return i18n.t('sprint.newAction.pages.activities.perConnection');
      case "por segmento": return i18n.t('sprint.newAction.pages.activities.perSegment');
      case "por funil": return i18n.t('sprint.newAction.pages.activities.perFunnel');
      case "por evento": return i18n.t('sprint.newAction.pages.activities.perEvent');
      case "por ciclo": return i18n.t('sprint.newAction.pages.activities.perCycle');
      case "por métrica": return i18n.t('sprint.newAction.pages.activities.perMetric');
      case "por URL": return i18n.t('sprint.newAction.pages.activities.perUrl');
      case "por serviço": return i18n.t('sprint.newAction.pages.activities.perService');
      case "conjunto de anúncios": return i18n.t('sprint.newAction.pages.activities.adSet');
      case "por dia": return i18n.t('sprint.newAction.pages.activities.perDay');
      case "por loja": return i18n.t('sprint.newAction.pages.activities.perStore');
      case "por página": return i18n.t('sprint.newAction.pages.activities.perPage');
      case "por vitrine": return i18n.t('sprint.newAction.pages.activities.perShowcase');
      case "por mensagem": return i18n.t('sprint.newAction.pages.activities.perMessage');
      case "por relatório": return i18n.t('sprint.newAction.pages.activities.perReport');
      case "por marca": return i18n.t('sprint.newAction.pages.activities.perBrand');
      case "para 12 formatos": return i18n.t('sprint.newAction.pages.activities.for12Formats');
      case "para 6 formatos": return i18n.t('sprint.newAction.pages.activities.for6Formats');
      case "por e-mail (layout e regras)": return i18n.t('sprint.newAction.pages.activities.perEmail');
      case "por coleção": return i18n.t('sprint.newAction.pages.activities.perCollection'); 
      case "por app": return i18n.t('sprint.newAction.pages.activities.perApp');
      case "por plugin": return i18n.t('sprint.newAction.pages.activities.perPlugin');
      case "até 700 palavras": return i18n.t('sprint.newAction.pages.activities.upTo700Words');
      case "até 1.500 palavras": return i18n.t('sprint.newAction.pages.activities.upTo1500Words');
      case "por campanha": return i18n.t('sprint.newAction.pages.activities.perCampaign');
      case "por e-mail": return i18n.t('sprint.newAction.pages.activities.perEmailOriginal');
      case "por tag": return i18n.t('sprint.newAction.pages.activities.perTag');
      case "por experimento": return i18n.t('sprint.newAction.pages.activities.perExperiment');
      case "por planilha": return i18n.t('sprint.newAction.pages.activities.perSpreadsheet');
      case "por filtro": return i18n.t('sprint.newAction.pages.activities.perFilter');
      case "por variação": return i18n.t('sprint.newAction.pages.activities.perVariation');
      default: return measurement;
    }
  }

  export const handleChangeDescription = (description) => {
    switch (description) {
      case "Desenv. de Interface - Página Inicial: Aqui, é desenvolvida a página inicial do site ou plataforma, que geralmente apresenta informações relevantes, promoções ou destaques dos produtos, com o objetivo de atrair e engajar os visitantes.": return i18n.t('sprint.newAction.pages.activities.descriptionDevInterface');
      case "Desenv. de Interface - Página de Categoria: Essa atividade envolve o desenvolvimento da página de categoria, onde os produtos são agrupados de acordo com suas categorias ou tipos, facilitando a navegação e busca dos clientes.": return i18n.t('sprint.newAction.pages.activities.descriptionDevCategory');
      case "Desenv. de Interface - Página de Produto: É realizada a criação da página de produto, que exibe informações detalhadas sobre um item específico, como descrição, imagens, preço, opções de variação e botões de compra, proporcionando uma experiência completa ao cliente.": return i18n.t('sprint.newAction.pages.activities.descriptionDevProduct');
      case "Inventário - Atualização de Preços via planilha: É realizada a atualização dos preços dos produtos por meio de uma planilha, permitindo alterações em massa e facilitando a manutenção dos valores atualizados.": return i18n.t('sprint.newAction.pages.activities.descriptionPriceUpdate');
      case "Inventário - Atualização de Produtos: Aqui, são realizadas as atualizações necessárias nos dados dos produtos cadastrados, como alterações de preço, descrição, imagens, atributos, entre outros.": return i18n.t('sprint.newAction.pages.activities.descriptionProductUpdate');
      case 'Desenv. de Interface - Página Diversas: carrinho, minha conta, sobre, etc: Aqui, são desenvolvidas diferentes páginas secundárias ou complementares, como carrinho de compras, área de conta do usuário, página "Sobre nós" ou outras páginas institucionais.': return i18n.t('sprint.newAction.pages.activities.descriptionDiversePage');
      case "Desenv. de Interface - Landing Page promocional, upsell, carta de vendas, etc: Essa atividade envolve o desenvolvimento de landing pages, que são páginas específicas criadas para campanhas promocionais, upsell (venda adicional) ou carta de vendas, com o objetivo de direcionar os visitantes para ações específicas e aumentar as conversões.": return i18n.t('sprint.newAction.pages.activities.descriptionLandingPage');
      case "Design de Interface - Página Inicial: Nessa atividade, é realizado o design da página inicial do site ou plataforma, considerando aspectos visuais, como layout, cores, tipografia e elementos gráficos, buscando transmitir a identidade visual da marca e proporcionar uma experiência estética agradável aos usuários.": return i18n.t('sprint.newAction.pages.activities.descriptionDesignInterface');
      case "Desenv. de Interface - Página Home promocional: Aqui, é desenvolvida uma versão promocional da página inicial, destacando ofertas, descontos ou produtos em destaque, com o objetivo de impulsionar as vendas e atrair a atenção dos visitantes.": return i18n.t('sprint.newAction.pages.activities.descriptionPromotionalHomePage');
      case "Design de Interface - Página de Categoria: É desenvolvido o design da página de categoria, levando em consideração a organização e disposição dos produtos, a aplicação de filtros de pesquisa, a apresentação de imagens e informações relevantes, com o objetivo de facilitar a navegação e tornar a experiência do usuário mais intuitiva.": return i18n.t('sprint.newAction.pages.activities.descriptionDesignCategory');
      case "Desenv. de Interface - Elementos Principais: header, footer e folha de estilos: Nessa atividade, são desenvolvidos os elementos principais de uma interface, como o cabeçalho (header), rodapé (footer) e a estilização geral da página (folha de estilos), garantindo uma aparência consistente e profissional em todo o site.": return i18n.t('sprint.newAction.pages.activities.descriptionMainElements');
      case "Inventário - Criação/Correção de Planilha: Essa atividade envolve a criação ou correção de uma planilha utilizada para o controle e organização dos produtos, garantindo a consistência e precisão das informações.": return i18n.t('sprint.newAction.pages.activities.descriptionSpreadsheetCreation');
      case "Design de Interface - Página de Produto: Aqui, é realizado o design da página de produto, levando em conta a apresentação das imagens, descrições, opções de variação, botões de compra e demais elementos visuais, visando destacar as características e atrativos do produto, bem como fornecer uma interface clara e atraente aos clientes.": return i18n.t('sprint.newAction.pages.activities.descriptionDesignProduct');
      case 'Design de Interface - Página Diversas: carrinho, minha conta, sobre, etc: São criados os designs das diferentes páginas secundárias ou complementares, como carrinho de compras, área de conta do usuário, página "Sobre nós" ou outras páginas institucionais, seguindo a identidade visual da marca e garantindo a consistência estética em todo o site.': return i18n.t('sprint.newAction.pages.activities.descriptionDesignDiversePage');
      case "Design de Interface - Landing Page: promocional, upsell, carta de vendas, etc: É realizado o design de landing pages, que são projetadas de forma atrativa e persuasiva, com elementos visuais impactantes, conteúdo persuasivo e um call-to-action claro, com o objetivo de direcionar os visitantes para uma ação específica, como fazer uma compra ou fornecer informações de contato.": return i18n.t('sprint.newAction.pages.activities.descriptionDesignLandingPage');
      case "Design de Interface - Página Home promocional: Essa atividade envolve o design de uma versão promocional da página inicial, destacando ofertas, descontos ou produtos em destaque por meio de imagens, banners ou outros elementos visuais, com o objetivo de chamar a atenção dos visitantes e incentivar a conversão.": return i18n.t('sprint.newAction.pages.activities.descriptionDesignPromotionalHomePage');
      case "Design de Interface - UI Kit: Nessa atividade, é criado um conjunto de elementos de interface (UI Kit), que inclui botões, ícones, caixas de seleção, barras de rolagem, entre outros, seguindo uma identidade visual consistente e facilitando o desenvolvimento e design de novas páginas e elementos da interface.": return i18n.t('sprint.newAction.pages.activities.descriptionUiKit');
      case "Design de Interface - Designer UX (por hora): Um designer UX (User Experience) é responsável por criar uma experiência de usuário positiva e intuitiva, analisando fluxos de navegação, interações, organização de informações e usabilidade do site ou plataforma. Nessa atividade, o designer UX trabalha em projetos específicos por hora, visando melhorar a experiência do usuário.": return i18n.t('sprint.newAction.pages.activities.descriptionUxDesignerHour');
      case "Design Gráfico - Design de E-mail (Autoresponder, Broadcast e Transacional): É realizado o design de e-mails para diferentes finalidades, como autoresponder (respostas automáticas), broadcast (envio em massa) e transacional (relacionado a ações específicas do usuário). Os e-mails podem incluir elementos gráficos, imagens, texto formatado e chamadas à ação.": return i18n.t('sprint.newAction.pages.activities.descriptionEmailDesign');
      case "Design Gráfico - E-book Design e Diagramação: Nessa atividade, é realizado o design e diagramação de e-books, que são materiais digitais mais longos, como guias, tutoriais, catálogos ou livros eletrônicos. O design e diagramação visam tornar o conteúdo visualmente atrativo e de fácil leitura, com elementos gráficos, ilustrações e formatação adequada.": return i18n.t('sprint.newAction.pages.activities.descriptionEbookDesign');
      case "Edição de Imagem - Tratamento de imagem (para web): Aqui, as imagens são editadas e tratadas de forma adequada para a web, incluindo ajustes de tamanho, otimização de qualidade, recorte, correção de cores, remoção de imperfeições, entre outros. O objetivo é garantir que as imagens sejam visualmente atraentes e carreguem rapidamente nas páginas do site ou plataforma.": return i18n.t('sprint.newAction.pages.activities.descriptionImageTreatment');
      case "Edição de Imagem - Recortar fundo de imagem (para web): Nessa atividade, é realizado o recorte do fundo de uma imagem, removendo elementos indesejados ou isolando o objeto principal. Esse recorte é feito para uso específico na web, permitindo a inserção do objeto recortado em diferentes contextos sem o fundo original.": return i18n.t('sprint.newAction.pages.activities.descriptionImageBackgroundCutting');
      case "Edição de Vídeo - Design de Ads em Vídeo (15seg): Essa atividade envolve a criação de anúncios em vídeo com duração de 15 segundos. É realizado o design visual do anúncio, incluindo a seleção de imagens, texto, elementos gráficos e transições, buscando capturar a atenção do público-alvo e transmitir a mensagem de forma eficiente no curto tempo disponível.": return i18n.t('sprint.newAction.pages.activities.descriptionVideoAdsDesign');
      case "Edição de Vídeo - Motion Graphics: Nessa atividade, são criados elementos gráficos em movimento para vídeos, utilizando técnicas de motion graphics. Esses elementos podem incluir animações, transições, infográficos animados, textos em movimento, entre outros, tornando o vídeo mais dinâmico e visualmente interessante.": return i18n.t('sprint.newAction.pages.activities.descriptionMotionGraphics');
      case "Edição de Vídeo - Design de Ads em Vídeo (30seg): Similar à atividade anterior, porém, nesse caso, os anúncios em vídeo têm duração de 30 segundos. O objetivo é criar conteúdo visualmente atraente e envolvente dentro desse período de tempo para atrair e engajar os espectadores.": return i18n.t('sprint.newAction.pages.activities.descriptionVideoAdsDesign30');
      case "Edição de Vídeo - Edição de vídeo (pontuação por minutagem): Aqui, é realizada a edição de vídeos já gravados, incluindo corte, junção de cenas, adição de transições, correção de cor, ajuste de áudio e demais ajustes necessários. A atividade é remunerada com base na duração do vídeo, medida em minutos.": return i18n.t('sprint.newAction.pages.activities.descriptionVideoEditingPerMin');
      case "Edição de Vídeo - Legendação de vídeo (pontuação por minutagem): É adicionada a legenda aos vídeos, transcrevendo o conteúdo falado e sincronizando-o com o vídeo. Essa atividade é remunerada com base na duração do vídeo, medida em minutos.": return i18n.t('sprint.newAction.pages.activities.descriptionVideoCaptioningPerMin');
      case "Gestão de E-commerce - Criação de Cupons: Aqui, são criados cupons de desconto que os clientes podem utilizar em suas compras. Os cupons podem ser personalizados, com códigos exclusivos e regras específicas de utilização, contribuindo para aumentar as vendas e fidelizar os clientes.": return i18n.t('sprint.newAction.pages.activities.descriptionCouponCreation');
      case "Gestão de E-commerce - Formulário de Captação: É criado um formulário de captação de dados, que pode ser utilizado para capturar informações de contato dos clientes, como nome, e-mail, telefone, entre outros. Esses formulários são integrados ao site ou plataforma e podem ser utilizados para construir uma lista de contatos ou segmentar o público-alvo.": return i18n.t('sprint.newAction.pages.activities.descriptionCaptationForm');
      case "Gestão de E-commerce - Gestão de Scripts e Rastreadores (via GTM): Nessa atividade, são gerenciados os scripts e rastreadores (tags) do site ou plataforma por meio do Google Tag Manager (GTM). Isso envolve a criação, edição e exclusão de tags para rastreamento de eventos, conversões, análise de comportamento do usuário, entre outros.": return i18n.t('sprint.newAction.pages.activities.descriptionScriptManagement');
      case "Gestão de E-commerce - Conciliação Manual de Pagamentos: Aqui, é realizada a conciliação manual dos pagamentos recebidos pela empresa, verificando se os valores registrados no sistema estão corretos e correspondem aos pagamentos efetivamente recebidos.": return i18n.t('sprint.newAction.pages.activities.descriptionManualPaymentReconciliation');
      case "Gestão de E-commerce - Configurar automação de merchandising (Launchpad): Nessa atividade, é configurada a automação de merchandising por meio da plataforma Launchpad. Isso envolve a definição de regras e condições para a exibição de banners, vitrines ou promoções específicas com base em critérios como perfil do cliente, período de tempo, categorias de produtos, entre outros.": return i18n.t('sprint.newAction.pages.activities.descriptionMerchandisingAutomation');
      case "Gestão de E-commerce - Configurar automação de tarefas (Flow): São configuradas automações de tarefas por meio da plataforma Flow, visando automatizar processos internos do e-commerce. Isso pode incluir o envio automático de e-mails de acompanhamento de pedidos, atualizações de status, solicitações de feedback, entre outras ações relacionadas ao relacionamento com o cliente.": return i18n.t('sprint.newAction.pages.activities.descriptionTaskAutomation');
      case "Gestão de E-commerce - Moderação de avaliações: Nessa atividade, é realizada a moderação das avaliações e comentários deixados pelos clientes nos produtos ou serviços da empresa. A moderação pode envolver a aprovação, edição ou exclusão de avaliações, garantindo a qualidade e apropriada representação das opiniões dos clientes.": return i18n.t('sprint.newAction.pages.activities.descriptionReviewsModeration');
      case "Gestão de Marketplace - Configuração de Anúncio: Nessa atividade, são realizadas as configurações necessárias para a publicação de anúncios em marketplaces, como Amazon, eBay ou Mercado Livre. Isso pode incluir a criação de títulos, descrições, imagens, categorias e informações adicionais para cada anúncio.": return i18n.t('sprint.newAction.pages.activities.descriptionAdConfiguration');
      case "Gestão de Marketplace - Configuração de Kit para Anúncio: É realizada a configuração de kits de produtos para anúncios em marketplaces. Os kits são conjuntos de produtos vendidos em conjunto, com um único preço, permitindo oferecer pacotes ou combinações especiais aos clientes.": return i18n.t('sprint.newAction.pages.activities.descriptionAdKitConfiguration');
      case "Gestão de Marketplace - Gestão de Preços: Nessa atividade, é realizada a gestão de preços dos produtos nos marketplaces, considerando estratégias de precificação, análise da concorrência, margem de lucro e metas de vendas. Os preços são ajustados de acordo com as demandas e condições do mercado.": return i18n.t('sprint.newAction.pages.activities.descriptionPriceManagement');
      case "Gestão de Marketplace - Configuração de Regras: São configuradas regras específicas nos marketplaces, considerando políticas de vendas, restrições de produtos, políticas de envio e demais diretrizes estabelecidas pela plataforma. Isso garante que a empresa esteja em conformidade com as regras e regulamentos de cada marketplace.": return i18n.t('sprint.newAction.pages.activities.descriptionRulesConfiguration');
      case "Gestão de Marketplace - Gestão de Fretes: Aqui, é realizada a gestão dos fretes para os produtos vendidos nos marketplaces. Isso inclui a configuração das opções de entrega, cálculo dos custos de envio, integração com serviços de transporte e atualização das informações de rastreamento para os clientes.": return i18n.t('sprint.newAction.pages.activities.descriptionFreightManagement');
      case "Implantação - Edição entradas DNS: Nessa atividade, são realizadas edições nas entradas de DNS (Domain Name System) do site ou plataforma, configurando a resolução de domínio, redirecionamento de URLs ou definição de registros específicos, como SPF (Sender Policy Framework) ou DKIM (DomainKeys Identified Mail), para garantir a segurança e o correto funcionamento do domínio.": return i18n.t('sprint.newAction.pages.activities.descriptionDnsEntriesEditing');
      case "Implantação - Integração (via middleware): É realizada a integração entre diferentes sistemas ou plataformas de e-commerce por meio de um middleware da CheckStore (n8n), que atua como um intermediário para facilitar a troca de informações e sincronização de dados. Isso permite a comunicação eficiente entre diferentes sistemas e a integração de processos comerciais. ": return i18n.t('sprint.newAction.pages.activities.descriptionIntegrationMiddleware');
      case "Implantação - Instalação de Scripts e Rastreadores (via código): Nessa atividade, são instalados scripts e rastreadores no site ou plataforma por meio da inserção de código personalizado. Esses scripts e rastreadores podem ser fornecidos por terceiros e são utilizados para coletar dados, como análises de tráfego, monitoramento de conversões, integração com ferramentas de marketing, entre outros.": return i18n.t('sprint.newAction.pages.activities.descriptionScriptInstallationCode');
      case "Implantação - Instalação de Scripts e Rastreadores (via GTM): Aqui, os scripts e rastreadores são instalados no site ou plataforma por meio do Google Tag Manager (GTM). O GTM facilita a implementação e gerenciamento de tags de rastreamento, permitindo a adição, edição e exclusão dessas tags sem a necessidade de modificar o código do site diretamente.": return i18n.t('sprint.newAction.pages.activities.descriptionScriptInstallationGtm');
      case "Implantação - Desenvolvimento Front-end (por hora): Nessa atividade, é realizado o desenvolvimento da camada front-end do site ou plataforma. Isso envolve a criação e implementação de elementos visuais, como layouts, estilos, interações e animações, utilizando tecnologias como HTML, CSS e JavaScript para garantir uma experiência de usuário atraente e responsiva.": return i18n.t('sprint.newAction.pages.activities.descriptionFrontEndDevHour');
      case "Implantação - Desenvolvimento Full-stack (por hora): Aqui, é realizado o desenvolvimento full-stack do site ou plataforma, abrangendo tanto a camada front-end quanto a camada back-end. Isso inclui a implementação de funcionalidades, integrações com bancos de dados, lógica de negócios, autenticação de usuários, entre outras tarefas, utilizando linguagens de programação como JavaScript, Python, Ruby ou PHP, de acordo com as necessidades do projeto.": return i18n.t('sprint.newAction.pages.activities.descriptionFullStackDevHour');
      case "Marketing Digital - Agendamento de E-mail: Nessa atividade, são agendados o envio de e-mails promocionais, informativos ou transacionais para os clientes. Isso envolve a criação do conteúdo do e-mail, seleção dos destinatários, definição de datas e horários de envio, além da configuração de automações de e-mail marketing para fornecer mensagens personalizadas e relevantes aos destinatários.": return i18n.t('sprint.newAction.pages.activities.descriptionEmailScheduling');
      case "Implantação - Arquiteto de Software (por hora): Nessa atividade, um arquiteto de software é contratado por hora para fornecer orientações estratégicas e técnicas relacionadas à arquitetura do site ou plataforma. O arquiteto de software analisa os requisitos do projeto, define a estrutura do sistema, seleciona as tecnologias adequadas e estabelece as diretrizes de desenvolvimento para garantir um projeto robusto, escalável e de alta qualidade.": return i18n.t('sprint.newAction.pages.activities.descriptionSoftwareArchitectHour');
      case "Marketing Digital - Notificação Push/SMS: Aqui, são configuradas notificações push ou mensagens SMS que são enviadas aos usuários por meio de dispositivos móveis ou web. Essas notificações podem ser utilizadas para enviar atualizações, alertas, ofertas exclusivas ou lembretes aos clientes, visando manter o engajamento e promover a interação com o e-commerce.": return i18n.t('sprint.newAction.pages.activities.descriptionPushNotificationSms');
      case "Marketing Digital - Configuração de segmentos de clientes: Nessa atividade, são configurados segmentos de clientes com base em critérios específicos, como comportamento de compra, preferências, histórico de interações ou informações demográficas. A segmentação permite direcionar mensagens personalizadas para grupos específicos de clientes, aumentando a relevância e eficácia das campanhas de marketing.": return i18n.t('sprint.newAction.pages.activities.descriptionCustomerSegmentsConfig');
      case "Marketing Digital - Setup do Funil de Automação de Marketing: Aqui, é realizado o setup do funil de automação de marketing, que consiste em uma sequência de etapas automatizadas para nutrir leads, converter clientes em potencial e promover a fidelização. Isso envolve a criação de fluxos de automação, definição de gatilhos, configuração de e-mails automatizados e acompanhamento do desempenho do funil.": return i18n.t('sprint.newAction.pages.activities.descriptionMarketingAutoFunnelSetup');
      case "Marketing Digital - Setup de Eventos Personalizados (via GTM): Nessa atividade, são configurados eventos personalizados no Google Tag Manager (GTM) para rastrear ações específicas dos usuários no site ou plataforma. Esses eventos podem incluir cliques em botões, envio de formulários, visualização de páginas, reprodução de vídeos, entre outros. O rastreamento dos eventos personalizados permite obter insights sobre o comportamento do usuário e otimizar as estratégias de marketing.": return i18n.t('sprint.newAction.pages.activities.descriptionCustomEventsSetup');
      case "Marketing Digital - Assessoria de Imprensa (mídia local SC): Essa atividade envolve a assessoria de imprensa focada na mídia local de Santa Catarina (SC). É realizado o contato com veículos de comunicação locais, elaboração de releases, agendamento de entrevistas e acompanhamento de notícias e menções à empresa na mídia local.": return i18n.t('sprint.newAction.pages.activities.descriptionScPressAdvisory');
      case "Marketing Digital - Assessoria de Imprensa (mídia nacional): Aqui, é realizada a assessoria de imprensa voltada para a mídia nacional. São estabelecidos contatos com jornalistas, elaboração de comunicados de imprensa, divulgação de notícias e conquista de espaços em veículos de comunicação de abrangência nacional.": return i18n.t('sprint.newAction.pages.activities.descriptionNationalPressAdvisory');
      case "Marketing Digital - E-book (apenas texto): Nessa atividade, é desenvolvido um e-book contendo apenas texto, que aborda um tópico relevante e interessante para o público-alvo do e-commerce. O e-book pode servir como material educacional, guia prático, compilação de dicas ou qualquer outro formato que forneça informações valiosas aos leitores.": return i18n.t('sprint.newAction.pages.activities.descriptionEbookTextOnly');
      case "Marketing Digital - Assessoria de Imprensa (clipping): Nessa atividade, é realizado o clipping de notícias relacionadas à empresa em veículos de comunicação. São monitoradas as menções, reportagens e artigos que mencionam a empresa, sendo compiladas e organizadas para análise e acompanhamento.": return i18n.t('sprint.newAction.pages.activities.descriptionPressAdvisoryClipping');
      case "Redação Publicitária - Redação publicitária de produto: Aqui, são elaborados textos publicitários específicos para a descrição e promoção de produtos do e-commerce. Os textos são escritos de forma persuasiva, destacando os benefícios, características e diferenciais dos produtos, com o objetivo de incentivar a compra e despertar o interesse do público-alvo.": return i18n.t('sprint.newAction.pages.activities.descriptionProductAdvertisingWriting');
      case "Redação Publicitária - Redação de Conteúdo de Páginas: Aqui, são elaborados textos publicitários para o conteúdo das páginas do e-commerce, incluindo a página inicial, páginas institucionais, páginas especiais de produto, páginas especiais de categoria, entre outras. Os textos são escritos de forma atraente, informativa e persuasiva, visando cativar os visitantes e levá-los a realizar ações desejadas, como efetuar uma compra ou entrar em contato.": return i18n.t('sprint.newAction.pages.activities.descriptionPageContentWriting');
      case "Relatórios - Relatório Personalizado de Marketing: Nessa atividade, são elaborados relatórios personalizados que apresentam uma análise abrangente das estratégias de marketing adotadas pelo e-commerce. Os relatórios podem incluir métricas como tráfego do site, origem do tráfego, taxas de conversão, engajamento nas redes sociais, análise de campanhas de e-mail marketing, análise de conteúdo, entre outras informações que ajudam a avaliar a eficácia das estratégias de marketing e orientar decisões futuras.": return i18n.t('sprint.newAction.pages.activities.descriptionCustomMktReport');
      case "Relatórios - Relatório Personalizado de Vendas: Aqui, são desenvolvidos relatórios personalizados que fornecem uma visão abrangente das vendas realizadas no e-commerce. Os relatórios podem incluir métricas como volume de vendas, receita, ticket médio, margem de lucro, análise de produtos mais vendidos, análise de clientes, entre outras informações relevantes para monitorar o desempenho e o sucesso das vendas.": return i18n.t('sprint.newAction.pages.activities.descriptionCustomSalesReport');
      case "SEO - Conteúdo de SEO: Nessa atividade, é criado e otimizado o conteúdo do e-commerce para melhorar sua visibilidade nos mecanismos de busca. Isso envolve a pesquisa de palavras-chave relevantes, a criação de textos e descrições otimizadas, a implementação de tags HTML adequadas e outras práticas recomendadas para melhorar o posicionamento do site nos resultados de busca.": return i18n.t('sprint.newAction.pages.activities.descriptionSeoContent');
      case "SEO - Ajuste técnico para SEO: Aqui, são realizados ajustes técnicos no site ou plataforma para otimização de mecanismos de busca. Isso pode incluir a otimização da estrutura do site, a melhoria da velocidade de carregamento das páginas, a configuração adequada de metadados, a otimização de URLs, a criação de sitemaps e a implementação de marcações estruturadas, entre outros.": return i18n.t('sprint.newAction.pages.activities.descriptionSeoAdjustment');
      case "Setup de Marketing - Facebook Business: Nessa atividade, é realizado o setup da conta do Facebook Business para o e-commerce. Isso envolve a criação da conta, a configuração das informações da empresa, a associação de páginas do Facebook, a definição de permissões de acesso e a realização de integrações com outras ferramentas de marketing do Facebook.": return i18n.t('sprint.newAction.pages.activities.descriptionFacebookBusiness');
      case "Setup de Marketing - Facebook Página: Aqui, é realizado o setup da página do Facebook para o e-commerce. Isso inclui a criação da página, a personalização do layout e das informações, a definição de categorias, a adição de elementos visuais e a configuração das configurações de privacidade e segurança.": return i18n.t('sprint.newAction.pages.activities.descriptionFacebookPage');
      case "Setup de Marketing - Facebook Conta Instagram: Nessa atividade, é realizado o setup da conta do Instagram vinculada ao e-commerce. Isso envolve a criação da conta, a configuração das informações do perfil, a associação com a página do Facebook e a realização de integrações com outras ferramentas de marketing do Instagram.": return i18n.t('sprint.newAction.pages.activities.descriptionFbInstagramAccount');
      case "Setup de Marketing - Facebook Conta de Anúncios: Aqui, é realizado o setup da conta de anúncios do Facebook para o e-commerce. Isso inclui a criação da conta, a configuração de informações de faturamento, a definição de configurações de privacidade e segurança, e a associação com a página do Facebook e a conta do Instagram.": return i18n.t('sprint.newAction.pages.activities.descriptionFbAdsAccount');
      case "Setup de Marketing - Facebook Pixel: Nessa atividade, é realizado o setup do Facebook Pixel, uma ferramenta que permite rastrear e medir as ações dos usuários no site ou plataforma. Isso inclui a geração do código do pixel, a inserção adequada no site, a configuração de eventos e conversões a serem rastreados, e a verificação do funcionamento correto do pixel.": return i18n.t('sprint.newAction.pages.activities.descriptionFbPixel');
      case "Setup de Marketing - Facebook Catálogo de Produtos: Aqui, é realizado o setup do catálogo de produtos do Facebook, que permite exibir e promover os produtos do e-commerce nas plataformas do Facebook, como o Instagram e o Facebook Marketplace. Isso inclui a criação e configuração do catálogo, a sincronização com os produtos do e-commerce, a adição de informações e imagens dos produtos e a configuração das configurações de exibição e segmentação.": return i18n.t('sprint.newAction.pages.activities.descriptionFbProductCatalog');
      case "Setup de Marketing - Facebook Permissões de Tráfego do Pixel: Nessa atividade, são configuradas as permissões de tráfego do Facebook Pixel, determinando quais domínios ou URLs estão autorizados a enviar dados para o pixel e quais domínios ou URLs têm permissão para receber dados do pixel. Isso ajuda a garantir a segurança e a integridade das informações rastreadas e compartilhadas pelo pixel.": return i18n.t('sprint.newAction.pages.activities.descriptionFbPixelTrafficPermissions');
      case "Setup de Marketing - DataLayer: Aqui, é realizado o setup do DataLayer, uma estrutura de dados que permite a coleta e o compartilhamento de informações específicas sobre eventos e interações do usuário no site ou plataforma. Isso envolve a implementação adequada do DataLayer no código do site, a definição de variáveis e valores a serem capturados e a configuração de integrações com outras ferramentas de marketing.": return i18n.t('sprint.newAction.pages.activities.descriptionDataLayer');
      case "Setup de Marketing - Google Tag Manager: Nessa atividade, é realizado o setup do Google Tag Manager (GTM), uma ferramenta que permite gerenciar de forma centralizada a implementação de tags e rastreadores no site ou plataforma. Isso inclui a criação da conta do GTM, a configuração da estrutura de contêiner, a implementação do código do GTM no site e a configuração das tags e acionadores necessários para rastrear eventos e interações específicas.": return i18n.t('sprint.newAction.pages.activities.descriptionGtm');
      case "Setup de Marketing - Google Analytics: Aqui, é realizado o setup do Google Analytics, uma ferramenta de análise de dados que fornece informações detalhadas sobre o tráfego, comportamento do usuário, conversões e outras métricas importantes do site ou plataforma. Isso envolve a criação da conta do Google Analytics, a configuração do código de acompanhamento, a definição de metas e conversões, e a personalização de relatórios e painéis de controle.": return i18n.t('sprint.newAction.pages.activities.descriptionGoogleAnalytics');
      case "Setup de Marketing - Google Analytics Comércio Eletrônico Avançado: Nessa atividade, é realizado o setup de recursos avançados do Google Analytics para análise de comércio eletrônico. Isso inclui a configuração de acompanhamento de transações, análise de funil de conversão, análise de produtos e categorias, análise de desempenho de vendas, entre outras métricas específicas para o comércio eletrônico.": return i18n.t('sprint.newAction.pages.activities.descriptionAdvancedGA');
      case "Setup de Marketing - Google Search Console: Nessa atividade, é realizado o setup do Google Search Console, uma ferramenta que fornece informações detalhadas sobre a presença do site nos resultados de pesquisa do Google. Isso inclui a criação da conta do Search Console, a verificação da propriedade do site, o envio do sitemap, o acompanhamento do desempenho de pesquisa, a identificação de erros e problemas de indexação, e o monitoramento das palavras-chave e posições nos resultados de pesquisa.": return i18n.t('sprint.newAction.pages.activities.descriptionGoogleSearchConsole');
      case "Setup de Marketing - Google Merchant Center: Aqui, é realizado o setup do Google Merchant Center, uma plataforma que permite que os comerciantes façam upload e gerenciem informações de produtos para serem exibidas nos resultados de pesquisa do Google e em outros serviços do Google, como o Google Shopping. Isso envolve a criação da conta do Merchant Center, a configuração das informações do negócio, a importação de dados do catálogo de produtos, a configuração de configurações de exibição e segmentação, e a verificação da conformidade com as políticas do Google.": return i18n.t('sprint.newAction.pages.activities.descriptionGoogleMerchantCenter');
      case "Setup de Marketing - Google Ads: Nessa atividade, é realizado o setup do Google Ads (anteriormente conhecido como Google AdWords), uma plataforma de publicidade online que permite a criação e gerenciamento de campanhas de anúncios pagos nos mecanismos de busca do Google e em outros sites parceiros. Isso inclui a criação da conta do Google Ads, a configuração de informações de faturamento, a definição de configurações de campanha, a criação de grupos de anúncios, a seleção de palavras-chave relevantes e a criação de anúncios persuasivos.": return i18n.t('sprint.newAction.pages.activities.descriptionGoogleAds');
      case "Setup de Marketing - Google Ads Conv. Compra: Aqui, é realizado o setup da configuração de conversões de compra no Google Ads. Isso envolve a configuração de tags e eventos para rastrear conversões específicas relacionadas a transações e compras no e-commerce, permitindo a medição do retorno sobre o investimento (ROI) das campanhas de anúncios pagos.": return i18n.t('sprint.newAction.pages.activities.descriptionGoogleAdsConvBuy');
      case "Setup de Marketing - Google Ads Remarketing: Nessa atividade, é realizado o setup de campanhas de remarketing no Google Ads. Isso envolve a criação de listas de remarketing com base no comportamento do usuário no site ou plataforma, a configuração de anúncios personalizados direcionados a essas listas e a definição de estratégias de lances e segmentação para alcançar os usuários que já interagiram com o e-commerce.": return i18n.t('sprint.newAction.pages.activities.descriptionGoogleAdsRemarketing');
      case "Setup de Marketing - Vinculação do Google Ads com Google Analytics: Nessa atividade, é realizada a vinculação da conta do Google Ads com a conta do Google Analytics. Isso permite a sincronização de dados entre as duas plataformas, fornecendo uma visão integrada das métricas e do desempenho das campanhas de anúncios pagos e seu impacto nas interações dos usuários no site ou plataforma.": return i18n.t('sprint.newAction.pages.activities.descriptionGoogleAdsLinking');
      case "Setup de Marketing - Google Ads Vinculador de conversões: Aqui, é realizado o setup do vinculador de conversões do Google Ads, que permite associar conversões rastreadas no Google Analytics às campanhas e anúncios específicos no Google Ads. Isso facilita a análise do desempenho das campanhas e a atribuição correta das conversões geradas pelos anúncios pagos.": return i18n.t('sprint.newAction.pages.activities.descriptionGoogleAdsConvLinker');
      case "Setup de Marketing - Vinculação do Google Ads com Merchant Center: Aqui, é realizada a vinculação da conta do Google Ads com a conta do Google Merchant Center. Isso permite a sincronização de dados sobre produtos e campanhas de anúncios pagos, possibilitando a exibição de anúncios de produtos relevantes nos resultados de pesquisa e no Google Shopping.": return i18n.t('sprint.newAction.pages.activities.descriptionGoogleAdsMerchantLinking');
      case "Setup de Marketing - Implementação dos eventos do GA4 via Google Tag Manager: Aqui, é realizada a implementação dos eventos do Google Analytics 4 (GA4) por meio do Google Tag Manager. Isso inclui a configuração de eventos personalizados no GTM, a criação de variáveis e acionadores para capturar interações específicas do usuário no site ou plataforma e o envio dos dados correspondentes para o GA4 para análise.": return i18n.t('sprint.newAction.pages.activities.descriptionGA4EventsImplementation');
      case "Setup de Marketing - Implementação dos relatórios do GA4: Nessa atividade, é realizada a implementação dos relatórios personalizados no Google Analytics 4 (GA4). Isso envolve a configuração de relatórios específicos para fornecer insights e métricas relevantes para o e-commerce, como análise de funil de conversão, análise de eventos, análise de audiência e outras informações que auxiliam na compreensão do comportamento e desempenho dos usuários.": return i18n.t('sprint.newAction.pages.activities.descriptionGA4ReportsImplementation');
      case "Setup de Marketing - Tag da Plataforma de Marketing Cloud: Nessa atividade, é realizado o setup da tag da plataforma de marketing cloud no site ou plataforma. Isso permite a coleta de dados sobre o comportamento do usuário, interações, conversões e outras informações relevantes para análise e personalização de marketing baseado em dados.": return i18n.t('sprint.newAction.pages.activities.descriptionMarketingCloudTag');
      case "Tradução - Tradução multilíngua por página/template: Nessa atividade, é realizada a tradução de páginas ou templates do e-commerce para outros idiomas. Isso inclui a tradução de textos, descrições de produtos, botões, menus e outros elementos visuais e de comunicação para fornecer uma experiência localizada aos usuários que falam diferentes idiomas.": return i18n.t('sprint.newAction.pages.activities.descriptionMultilanguageTranslation');
      case "Tráfego Pago - Setup Campanha de Search Ads: Aqui, é realizado o setup de campanhas de anúncios de Search Ads, que são exibidos nos resultados de pesquisa dos mecanismos de busca. Isso envolve a configuração das palavras-chave, criação de anúncios relevantes e a definição de lances e orçamentos para alcançar os usuários interessados nos produtos ou serviços do e-commerce.": return i18n.t('sprint.newAction.pages.activities.descriptionSearchAdsCampaignSetup');
      case "Tráfego Pago - Setup Campanha de Product Listing Ads (Shopping): Aqui, é realizado o setup de campanhas de anúncios de Product Listing Ads (PLAs), que são exibidos nos resultados de pesquisa do Google Shopping. Isso inclui a criação do catálogo de produtos no Merchant Center, a configuração das informações de produtos, a definição de lances e orçamentos para exibir os anúncios de produtos relevantes aos usuários.": return i18n.t('sprint.newAction.pages.activities.descriptionProductListingAdsCampaignSetup');
      case "Tráfego Pago - Setup Campanha de Lead Ads: Aqui, é realizado o setup de campanhas de anúncios de Lead Ads, que são projetados para capturar informações de contato e gerar leads qualificados. Isso envolve a criação de formulários de geração de leads, a definição de critérios e segmentação para exibir os anúncios aos usuários relevantes, e a configuração dos fluxos de acompanhamento e integrações com ferramentas de automação de marketing.": return i18n.t('sprint.newAction.pages.activities.descriptionLeadAdsCampaignSetup');
      case "Tráfego Pago - Setup Campanha de Display Ads: Nessa atividade, é realizado o setup de campanhas de anúncios de Display Ads, que são exibidos em sites parceiros por meio de banners, imagens ou vídeos. Isso inclui a configuração dos critérios de segmentação, a criação de anúncios persuasivos e a definição de estratégias de lances e orçamentos para alcançar o público-alvo do e-commerce.": return i18n.t('sprint.newAction.pages.activities.descriptionDisplayAdsCampaignSetup');
      case "Tráfego Pago - Setup Campanha de Vídeo Ads: Aqui, é realizado o setup de campanhas de anúncios de Vídeo Ads, que são vídeos promocionais exibidos em plataformas de vídeo, como YouTube. Isso envolve a criação de vídeos relevantes e impactantes, a configuração dos critérios de segmentação, e a definição de estratégias de lances e orçamentos para alcançar o público-alvo do e-commerce.": return i18n.t('sprint.newAction.pages.activities.descriptionVideoAdsCampaignSetup');
      case "Tráfego Pago - Configuração de públicos de interesse: Nessa atividade, são configurados públicos de interesse segmentados para as campanhas de tráfego pago. Isso envolve a definição de critérios demográficos, comportamentais e de interesse para segmentar os anúncios aos usuários mais propensos a se interessarem pelos produtos ou serviços do e-commerce.": return i18n.t('sprint.newAction.pages.activities.descriptionInterestAudiencesConfig');
      case "Tráfego Pago - Configuração de públicos personalizados: Aqui, são configurados públicos personalizados para as campanhas de tráfego pago. Isso pode incluir a criação de públicos com base em dados de clientes existentes, listas de e-mails, visitantes do site, interações em redes sociais e outras fontes de dados relevantes. Esses públicos personalizados permitem direcionar os anúncios aos usuários mais qualificados e propensos a se converterem em clientes.": return i18n.t('sprint.newAction.pages.activities.descriptionCustomAudiencesConfig');
      case "Tráfego Pago - Otimização de campanhas: Nessa atividade, são realizadas otimizações contínuas nas campanhas de tráfego pago para maximizar o desempenho e os resultados. Isso envolve análise de métricas, ajustes de lances e orçamentos, refinamento de segmentação, testes de criativos e mensagens, e implementação de estratégias de otimização para alcançar os objetivos definidos.": return i18n.t('sprint.newAction.pages.activities.descriptionCampaignOptimization');
      case "UI & UX - Análise de Usabilidade de Navegação: Nessa atividade, é realizada uma análise detalhada da usabilidade da navegação do site ou plataforma. Isso envolve a revisão da arquitetura de informação, organização de menus, fluxo de navegação, categorização de produtos e outras interações do usuário. O objetivo é identificar pontos de melhoria, simplificar a navegação e proporcionar uma experiência mais intuitiva e agradável para os usuários.": return i18n.t('sprint.newAction.pages.activities.descriptionNavigationUsabilityAnalysis');
      case "Design Gráfico - Recortes e Redimensionamento de Imagens: Nessa atividade, são realizados recortes precisos e redimensionamentos de imagens de acordo com as necessidades do projeto. Isso inclui a remoção de fundos indesejados, ajustes de proporção, resolução e tamanho, garantindo que as imagens estejam prontas para uso em diferentes contextos, como sites, redes sociais, impressão, entre outros.": return i18n.t('sprint.newAction.pages.activities.descriptionImageCroppingResizing');
      case "Design Gráfico - Ajustes de Criativos: Aqui, são feitos ajustes nos elementos visuais existentes, como banners, anúncios ou outros recursos gráficos. Isso envolve aprimorar a estética, fazer alterações no layout, ajustar cores, fontes, imagens e outros elementos, visando melhorar a comunicação visual e garantir a consistência da identidade da marca.": return i18n.t('sprint.newAction.pages.activities.descriptionCreativeAdjustments');
      case "SEO - Relatório de SEO (off-page): Nessa atividade, é elaborado um relatório abrangente que analisa as estratégias e ações de otimização de SEO realizadas fora do site. Isso inclui a análise de fatores externos que afetam a classificação nos motores de busca, como a qualidade e quantidade de backlinks, menções em mídias sociais, presença em diretórios e outras ações de marketing off-page.": return i18n.t('sprint.newAction.pages.activities.descriptionSeoOffPageReport');
      case "Inventário - Cadastro de Produtos ERP: Nessa atividade, são cadastrados os produtos no sistema de gestão empresarial (ERP) do e-commerce. Isso envolve a inserção de informações detalhadas sobre os produtos, como descrições, imagens, preços, estoque, categorias, atributos específicos e outras informações relevantes para o controle do inventário e a exposição adequada dos produtos.": return i18n.t('sprint.newAction.pages.activities.descriptionErpProductRegistration');
      case "SEO - Relatório de SEO (in-page): Aqui, é elaborado um relatório que se concentra nas otimizações realizadas diretamente nas páginas do site. Isso envolve a análise de fatores como palavras-chave, estrutura de URL, meta tags, uso adequado de heading tags, qualidade e relevância do conteúdo, velocidade de carregamento, entre outros aspectos que impactam a visibilidade e classificação nos mecanismos de busca.": return i18n.t('sprint.newAction.pages.activities.descriptionSeoInPageReport');
      case "Gestão de E-commerce - Otimização de Vitrine: Nessa atividade, é feita a otimização da vitrine do e-commerce, a área onde os produtos são exibidos na página inicial ou em destaque. Isso envolve a seleção cuidadosa dos produtos a serem exibidos, a ordenação estratégica, a personalização visual e a atualização periódica para promover os itens mais relevantes e atrair a atenção dos visitantes, aumentando as chances de conversão.": return i18n.t('sprint.newAction.pages.activities.descriptionShowcaseOptimization');
      case "Gestão de E-commerce - Configuração de App (por hora): Aqui, é realizada a configuração de um aplicativo móvel dedicado ao e-commerce. Isso pode incluir a integração com o sistema de gerenciamento existente, a configuração de funcionalidades específicas, como notificações push, personalização da interface de usuário, configurações de segurança e outras customizações para oferecer uma experiência aprimorada aos usuários do aplicativo.": return i18n.t('sprint.newAction.pages.activities.descriptionAppConfiguration');
      case "Gestão de E-commerce - Cadastro de Conteúdo (páginas institucionais, postagens de blog, FAQ etc.): Nessa atividade, é realizado o cadastro e atualização do conteúdo em diversas áreas do e-commerce. Isso inclui a criação e edição de páginas institucionais, postagens de blog, seções de perguntas frequentes (FAQ) e outros conteúdos relevantes para fornecer informações aos clientes, melhorar a experiência do usuário e impulsionar o engajamento.": return i18n.t('sprint.newAction.pages.activities.descriptionContentRegistration');
      case "Implantação - Gestão de Scripts e Rastreadores (por hora): Nessa atividade, é realizada a gestão de scripts e rastreadores no site ou plataforma do e-commerce. Isso envolve a implementação e configuração de scripts personalizados, como rastreamento de conversões, análise de dados, chatbots, personalização de conteúdo e outras funcionalidades que requerem conhecimento técnico para garantir o funcionamento adequado e o acompanhamento correto das ações realizadas pelos usuários.": return i18n.t('sprint.newAction.pages.activities.descriptionScriptsTrackersManagement');
      case "Redação Publicitária - Criação de Conteúdo SMS: Aqui, é realizada a criação de conteúdo publicitário para ser enviado por meio de mensagens de texto (SMS). Isso inclui a redação de mensagens curtas e persuasivas que comuniquem de forma eficaz promoções, ofertas exclusivas, atualizações relevantes ou chamadas para ação específicas, visando alcançar e engajar o público-alvo por meio dessa plataforma de comunicação direta.": return i18n.t('sprint.newAction.pages.activities.descriptionSmsContentCreation');
      case "CRO - Análise da Estratégia de Tráfego: Nessa atividade, é feita uma análise detalhada da estratégia de tráfego do e-commerce. Isso envolve a avaliação das fontes de tráfego, como orgânico, pago, social, referência, entre outros, para identificar quais canais estão gerando mais visitantes e conversões. Com base nessa análise, são propostas estratégias de otimização e realocação de recursos para maximizar o retorno sobre o investimento em marketing.": return i18n.t('sprint.newAction.pages.activities.descriptionTrafficStrategyAnalysis');
      case "CRO - Análise do Fluxo de Navegação: Aqui, é realizada uma análise do fluxo de navegação dos usuários no site ou plataforma do e-commerce. Isso inclui o estudo do comportamento dos visitantes, as páginas mais visitadas, os pontos de entrada e saída, os caminhos percorridos e os possíveis obstáculos que podem prejudicar a experiência do usuário. Com base nessa análise, são propostas melhorias no layout, navegação e usabilidade para aumentar a taxa de conversão.": return i18n.t('sprint.newAction.pages.activities.descriptionNavigationFlowAnalysis');
      case "CRO - Análise da Taxa de Conversão: Nessa atividade, é realizada uma análise aprofundada da taxa de conversão do e-commerce. Isso envolve a identificação de métricas-chave, como taxa de conversão por canal, por página de destino, por segmento de público, entre outros. Com base nessa análise, são propostas estratégias de otimização, como testes A/B, personalização de conteúdo e melhorias na experiência do usuário, para aumentar a taxa de conversão global do e-commerce.": return i18n.t('sprint.newAction.pages.activities.descriptionConversionRateAnalysis');
      case "Gestão de E-commerce - Notificação On-site: Aqui, é realizada a configuração e implementação de notificações on-site no e-commerce. Isso inclui a criação de mensagens personalizadas, como pop-ups, barras de notificação ou alertas, exibidos no próprio site para fornecer informações importantes, incentivar ações específicas (como descontos por tempo limitado) ou capturar leads. Essas notificações podem ser segmentadas com base no comportamento do usuário ou outros critérios relevantes.": return i18n.t('sprint.newAction.pages.activities.descriptionOnSiteNotification');
      case "Relatórios - Relatórios de Metas e Projeções: Nessa atividade, são criados relatórios que acompanham o progresso em relação às metas e fazem projeções para o desempenho futuro do e-commerce. Isso inclui análises de métricas relevantes, como vendas, tráfego, conversões, ROI, entre outras, para fornecer uma visão clara do desempenho atual e ajudar na tomada de decisões estratégicas para atingir as metas estabelecidas.": return i18n.t('sprint.newAction.pages.activities.descriptionGoalsProjectionsReports');
      case "Relatórios - Relatório de Benchmarking - SEO e PPC: Aqui, é elaborado um relatório de benchmarking que compara o desempenho do e-commerce em termos de SEO (otimização para mecanismos de busca) e PPC (pagamento por clique). Isso envolve a análise da posição nos resultados de busca, análise da concorrência, avaliação do desempenho das campanhas de tráfego pago, entre outros fatores importantes para identificar oportunidades de melhoria e se manter competitivo no mercado.": return i18n.t('sprint.newAction.pages.activities.descriptionSeoPpcBenchmarkingReport');
      case "Marketing Digital - Desenho de Funil de Marketing para Campanhas de Tráfego Orgânico: Aqui, é realizado o desenho de um funil de marketing estratégico para campanhas de tráfego orgânico. Isso envolve a criação de conteúdo relevante para atrair visitantes, a segmentação do público-alvo com base em interesses e comportamentos, a nutrição dos leads por meio de e-mails ou outros canais e a otimização do funil para aumentar a conversão de visitantes em clientes.": return i18n.t('sprint.newAction.pages.activities.descriptionOrganicTrafficMktFunnelDesign');
      case "Gestão de E-commerce - Desenho de Funil de Marketing para Campanhas de Lifecycle Marketing: Nessa atividade, é realizado o desenho de um funil de marketing estratégico para campanhas de lifecycle marketing, que acompanham o ciclo de vida dos clientes. Isso envolve a definição das diferentes fases do ciclo de vida, como aquisição, ativação, retenção e fidelização, e a criação de estratégias de marketing personalizadas para cada fase, visando maximizar o valor do cliente e promover a lealdade.": return i18n.t('sprint.newAction.pages.activities.descriptionLifecycleMktFunnelDesign');
      case "Relatórios - Revisão de Tags implementadas no Google Tag Manager de diversas plataformas: Aqui, é feita uma revisão completa das tags implementadas no Google Tag Manager do e-commerce para diversas plataformas. Isso inclui a verificação da correta implementação das tags de análise, remarketing, chatbots, otimização de conversão e outras ferramentas em diferentes páginas e eventos, garantindo que os dados sejam coletados de forma precisa e confiável para análise posterior.": return i18n.t('sprint.newAction.pages.activities.descriptionGtmTagsReview');
      case "Tráfego Pago - Relatório de Cobranças das Plataformas de Tráfego Pago: Aqui, é elaborado um relatório que apresenta um panorama detalhado das cobranças e gastos nas plataformas de tráfego pago, como Google Ads, Facebook Ads, entre outros. Isso inclui informações sobre os custos das campanhas, os gastos por canal, as métricas de desempenho associadas aos investimentos e outras análises financeiras relevantes para monitorar o orçamento e maximizar o retorno sobre o investimento.": return i18n.t('sprint.newAction.pages.activities.descriptionPaidTrafficBillingReport');
      case "Relatórios - Relatório de Performance (Velocidade, Usabilidade, Taxa de Conversão e Canais de Aquisição): Nessa atividade, é elaborado um relatório abrangente que avalia a performance do e-commerce em relação à velocidade de carregamento, usabilidade, taxa de conversão e canais de aquisição de tráfego. Isso envolve análises detalhadas desses aspectos, identificando áreas de melhoria, oportunidades de otimização e oferecendo insights para direcionar a estratégia de marketing digital.": return i18n.t('sprint.newAction.pages.activities.descriptionPerformanceReport');
      case "Relatórios - Relatório e Análise de Performance de Lifecycle Marketing: Nessa atividade, são elaborados relatórios que analisam o desempenho das campanhas de lifecycle marketing do e-commerce. Isso envolve a análise de métricas-chave em cada fase do ciclo de vida do cliente, como taxa de conversão, retenção, valor médio do pedido, entre outros, para identificar oportunidades de melhoria e otimização das estratégias de marketing direcionadas ao longo do ciclo de vida.": return i18n.t('sprint.newAction.pages.activities.descriptionLifecycleMktPerformanceReport');
      case "Desenv. de Interface - Desenvolvimento de Template HTML: Nessa atividade, é realizado o desenvolvimento de um template HTML personalizado para o e-commerce. Isso envolve a criação de um design visualmente atraente e funcional, a codificação do template em HTML e CSS, a implementação de recursos interativos e a garantia de que o template seja responsivo, ou seja, se adapte a diferentes dispositivos e tamanhos de tela, proporcionando uma experiência consistente aos usuários.": return i18n.t('sprint.newAction.pages.activities.descriptionHtmlTemplateDevelopment');
      case "Design Gráfico - Design de Banner E-commerce (desktop e mobile): Aqui, é realizado o design de banners específicos para o e-commerce, considerando diferentes formatos e tamanhos adequados para visualização em desktop e dispositivos móveis. Os banners podem ser utilizados para promoções, anúncios ou destacar produtos específicos.": return i18n.t('sprint.newAction.pages.activities.descriptionEcommerceBannerDesign');
      case "Design Gráfico - Design de Anúncio de Display Ads: É realizado o design de anúncios de display, que são elementos visuais utilizados em campanhas de publicidade online, exibidos em sites ou aplicativos de terceiros. Esses anúncios podem incluir imagens, texto e chamadas à ação, buscando atrair a atenção dos usuários e direcioná-los ao site ou plataforma da empresa.": return i18n.t('sprint.newAction.pages.activities.descriptionDisplayAdsDesign');
      case "Design Gráfico - Design de Anúncio de Display Social Ads: Essa atividade envolve o design de anúncios de display específicos para redes sociais, como Facebook, Instagram ou Twitter. Os anúncios podem ser projetados em diferentes formatos e tamanhos, com o objetivo de gerar engajamento e conversões por meio das plataformas de mídia social.": return i18n.t('sprint.newAction.pages.activities.descriptionSocialAdsDesign');
      case "Design Gráfico - Design de Anúncio de Carrossel Social Ads: É realizado o design de anúncios de carrossel para redes sociais, onde várias imagens ou cards são exibidos em sequência, permitindo contar uma história ou exibir vários produtos em um único anúncio.": return i18n.t('sprint.newAction.pages.activities.descriptionSocialAdsCarouselDesign');
      case "Design Gráfico - Conjunto de Criativos para Google Display: É criado um conjunto de criativos visuais para campanhas de Google Display, incluindo diferentes formatos e tamanhos de anúncios visuais, como banners estáticos ou animados, buscando atrair a atenção do público-alvo e gerar cliques e conversões.": return i18n.t('sprint.newAction.pages.activities.descriptionGoogleDisplayCreativeSet');
      case "Design Gráfico - Conjunto de Criativos para Google Max Performance: Nessa atividade, são criados conjuntos de criativos visuais para campanhas de Google Max Performance. Esses criativos podem incluir anúncios em vídeo, banners, anúncios de carrossel ou outros formatos adequados para a plataforma, visando otimizar o desempenho da campanha e gerar resultados eficientes.": return i18n.t('sprint.newAction.pages.activities.descriptionGoogleMaxPrfmCreativeSet');
      case "Gestão de E-commerce - Merchandising (página inicial): Essa atividade envolve a gestão de banners e vitrines do e-commerce, onde são configurados e atualizados os elementos visuais de destaque, como banners promocionais, vitrines temáticas ou destaques de produtos, buscando direcionar a atenção dos clientes para ofertas ou produtos específicos.": return i18n.t('sprint.newAction.pages.activities.descriptionMerchandising');
      case "Gestão de E-commerce - Cadastro de banners simples: Essa atividade envolve a substituição dos banners atuais por novos sem alterar a estrutura base do layout da página.": return i18n.t('sprint.newAction.pages.activities.descriptionSimpleBannerRegistration');
      case "Gestão de E-commerce - Cadastro de banners completo: Essa atividade envolve a substituição dos banners atuais por novos, bem como a alteração das coleções e textos das seções sem alterar a estrutura base do layout da página.": return i18n.t('sprint.newAction.pages.activities.descriptionFullBannerRegistration');
      case "Gestão de E-commerce - Migração de Segmentos e Contatos de E-mail Marketing: Nessa atividade, são realizadas a migração e a organização dos segmentos e contatos da lista de e-mail marketing do e-commerce. Isso inclui a revisão dos segmentos existentes, a criação de novos segmentos com base em critérios específicos, a importação e exportação de contatos, garantindo a atualização e a qualidade da lista para a implementação eficaz de campanhas de e-mail marketing.": return i18n.t('sprint.newAction.pages.activities.descriptionEmailMktMigration');
      case "Gestão de E-commerce - Migração de Fluxos de E-mail Marketing: Aqui, é realizada a migração dos fluxos de automação de e-mail marketing do e-commerce para uma nova plataforma ou ferramenta. Isso envolve a revisão dos fluxos existentes, a criação de fluxos automatizados personalizados, a configuração de gatilhos e segmentações, garantindo uma transição suave e eficiente para a nova plataforma de automação.": return i18n.t('sprint.newAction.pages.activities.descriptionEmailMktFlowsMigration');
      case "Gestão de E-commerce - Ordenamento e Otimização de Coleções (automática): Nessa atividade, é realizado o ordenamento e a otimização automática das coleções de produtos do e-commerce. Isso envolve a configuração de regras de exibição, como relevância, popularidade, preço, disponibilidade, entre outros critérios, para garantir que os produtos sejam apresentados aos clientes de forma mais adequada e atrativa, melhorando a experiência de compra.": return i18n.t('sprint.newAction.pages.activities.descriptionCollOptimizationAutomatic');
      case "Gestão de E-commerce - Ordenamento e Otimização de Coleções (manual): Aqui, é realizado o ordenamento e a otimização manual das coleções de produtos do e-commerce. Isso envolve a revisão e a classificação manual dos produtos com base em critérios específicos, como tendências de mercado, preferências do público-alvo, margem de lucro, estoque disponível, para garantir uma exibição eficiente e atraente dos produtos no site ou plataforma.": return i18n.t('sprint.newAction.pages.activities.descriptionCollOptimizationManual');
      case "Implantação - Shopify App: Nessa atividade, é realizada a implantação de um aplicativo específico para a plataforma Shopify, adicionando novas funcionalidades, recursos ou integrações ao e-commerce. Isso permite expandir as capacidades da loja virtual e atender às necessidades específicas do negócio.": return i18n.t('sprint.newAction.pages.activities.descriptionShopifyAppImplementation');
      case "Implantação - Vtex Marketplace: Aqui, é realizada a implantação do e-commerce na plataforma Vtex Marketplace, configurando as integrações necessárias, definindo as configurações de catálogo, preços, estoque, frete e demais funcionalidades relacionadas à operação no marketplace.": return i18n.t('sprint.newAction.pages.activities.descriptionVtexAppInstallation');
      case "Implantação - WordPress: Nessa atividade, é realizada a implantação do e-commerce na plataforma WordPress, que é uma solução de CMS. Isso envolve a configuração da plataforma, escolha de temas, instalação de plugins e personalização do ambiente.": return i18n.t('sprint.newAction.pages.activities.descriptionWordpressImplementation');
      case "Implantação - Configuração de Automação de Dados via Plataforma Externa: Nessa atividade, é realizada a configuração de automação de dados por meio de uma plataforma externa para o e-commerce. Isso pode incluir a integração e configuração de fluxos de dados automatizados entre diferentes sistemas e plataformas, como CRM, ERP, ferramentas de marketing, para garantir a sincronização eficiente e a troca de informações relevantes em tempo real.": return i18n.t('sprint.newAction.pages.activities.descriptionDataAutomationConfiguration');
      case "Implantação - Configuração de Webhooks: Aqui, é realizada a configuração de webhooks no e-commerce. Os webhooks são URLs que permitem que aplicativos externos sejam notificados sobre eventos ou ações específicas que ocorrem no site ou plataforma. Isso envolve a definição dos eventos a serem monitorados, a configuração dos URLs de destino e a integração com os sistemas ou ferramentas necessárias para automatizar processos e melhorar a eficiência operacional.": return i18n.t('sprint.newAction.pages.activities.descriptionWebhooksConfiguration');
      case "Marketing Digital - Conteúdo blog post: Nessa atividade, é criado conteúdo curto para postagens no blog do e-commerce. O conteúdo do blog pode abordar tópicos relacionados aos produtos, dicas, tendências, notícias do setor, entre outros. A criação de conteúdo relevante e informativo para o blog ajuda a atrair tráfego orgânico, fortalecer a autoridade da marca e fornecer valor aos clientes.": return i18n.t('sprint.newAction.pages.activities.descriptionBlogPostContentShort');
      case "Marketing Digital - Conteúdo blog post: Nessa atividade, é criado conteúdo longo para postagens no blog do e-commerce. O conteúdo do blog pode abordar tópicos relacionados aos produtos, dicas, tendências, notícias do setor, entre outros. A criação de conteúdo relevante e informativo para o blog ajuda a atrair tráfego orgânico, fortalecer a autoridade da marca e fornecer valor aos clientes.": return i18n.t('sprint.newAction.pages.activities.descriptionBlogPostContentLong');
      case "Marketing Digital - Criação de Landing Page (ferramenta de automação de marketing): Aqui, é realizada a criação de landing pages utilizando uma ferramenta de automação de marketing. Isso inclui a concepção do design, a seleção e customização de modelos predefinidos, a configuração de formulários de captura de leads e a implementação de elementos interativos, como pop-ups ou chatbots, para aumentar a taxa de conversão e direcionar o público para ação específica.": return i18n.t('sprint.newAction.pages.activities.descriptionLandingPageCreation');
      case "Redação Publicitária - Redação para Anúncios de Search Ads: Nessa atividade, são criados textos publicitários para anúncios de Search Ads, que são exibidos nos resultados de pesquisa dos mecanismos de busca. Os textos são otimizados para chamar a atenção do usuário, transmitir a proposta de valor do produto ou serviço e incentivar o clique no anúncio.": return i18n.t('sprint.newAction.pages.activities.descriptionSearchAdsCopywriting');
      case "Redação Publicitária - Redação de Chamadas para Ads: Aqui, são desenvolvidos textos publicitários para anúncios que são exibidos em sites parceiros por meio de banners, imagens ou vídeos. Os textos são elaborados para atrair a atenção do público-alvo, gerar interesse e incentivar a interação com o anúncio.": return i18n.t('sprint.newAction.pages.activities.descriptionAdsCopywriting');
      case "Redação Publicitária - Redação banner e-commerce: Nessa atividade, são criados textos publicitários para banners utilizados no e-commerce. Os textos são elaborados para chamar a atenção dos usuários, transmitir mensagens persuasivas e incentivar a interação com os produtos, promoções ou chamadas de ação presentes nos banners.": return i18n.t('sprint.newAction.pages.activities.descriptionEcommerceBannerCopywriting');
      case "Redação Publicitária - Redação de e-mails: Nessa atividade, são desenvolvidos textos persuasivos para e-mails de marketing, incluindo e-mails de campanhas promocionais, boletins informativos e e-mails de relacionamento com os clientes. Os textos são elaborados para atrair a atenção, gerar engajamento e incentivar a ação dos destinatários.": return i18n.t('sprint.newAction.pages.activities.descriptionEmailCopywriting');
      case "Redação Publicitária - Redação de e-mails: Nessa atividade, são desenvolvidos chamadas persuasivas e CTA (chamadas para a ação) para e-mails de marketing, incluindo e-mails de campanhas promocionais, boletins informativos, e-mails transacionais e e-mails de relacionamento com os clientes. Os textos são elaborados para atrair a atenção, gerar engajamento e incentivar a ação dos destinatários.": return i18n.t('sprint.newAction.pages.activities.descriptionCallEmailCopywriting');
      case "Relatórios - Relatório Personalizado de Mídia Paga (Google Ads): Nessa atividade, são elaborados relatórios personalizados que fornecem análises detalhadas dos resultados das campanhas de mídia paga realizadas por meio do Google Ads. Os relatórios podem incluir métricas como cliques, impressões, taxa de conversão, retorno sobre investimento (ROI) e outras informações relevantes para avaliar o desempenho das campanhas e direcionar ajustes estratégicos.": return i18n.t('sprint.newAction.pages.activities.descriptionGoogleAdsCustomReport');
      case "Setup de Marketing - Google Optimize: Nessa atividade, é realizado o setup do Google Optimize, uma ferramenta de teste e personalização de sites que permite a criação de experimentos para otimizar a experiência do usuário. Isso envolve a criação da conta do Optimize, a configuração dos experimentos, a definição de variantes e objetivos, e a realização de testes A/B e testes multivariados para avaliar e melhorar a eficácia do site ou plataforma.": return i18n.t('sprint.newAction.pages.activities.descriptionGoogleOptimizeSetup');
      case "Setup de Marketing - Hotjar Heatmaps & Behavior Analytics: Aqui, é realizado o setup do Hotjar, uma ferramenta de análise de comportamento do usuário que fornece mapas de calor, gravações de sessões e outros recursos para entender como os visitantes interagem com o site ou plataforma. Isso envolve a criação da conta do Hotjar, a configuração do código de rastreamento, a definição de configurações de acompanhamento e a análise dos dados coletados para obter insights valiosos sobre o comportamento do usuário e a usabilidade do site.": return i18n.t('sprint.newAction.pages.activities.descriptionHotjarSetup');
      case "Setup de Marketing - Configuração de Pixels, Tags e Eventos de plataformas Ads: Nessa atividade, é realizada a configuração dos pixels de rastreamento, tags e eventos do TikTok Ads, Pinterest Ads, Twitter Ads, Linkedin Ads e outros no site ou plataforma do e-commerce. Isso permite o monitoramento e rastreamento adequado das ações dos usuários, como visualizações de vídeos, cliques em anúncios e conversões, para fins de análise e otimização das campanhas de publicidade.": return i18n.t('sprint.newAction.pages.activities.descriptionAdsPlatformsSetup');
      case "Tráfego Pago - Setup Campanha de Google Max Performance: Aqui, é realizado o setup de campanhas de Google Max Performance, uma modalidade de campanha automatizada que otimiza lances, segmentação e criativos para alcançar os melhores resultados de desempenho. Isso envolve a configuração das configurações da campanha, a definição de objetivos e restrições, e a análise contínua dos resultados para ajustes e otimizações.": return i18n.t('sprint.newAction.pages.activities.descriptionGoogleMaxPrfmCampaignSetup');
      case "Tráfego Pago - Setup Campanha de Social Ads: Aqui, é realizado o setup de campanhas de anúncios de Display Social Ads, que são exibidos nas plataformas de redes sociais, como Facebook, Instagram, TikTok, Pinterest, Twitch, Twitter ou LinkedIn. Isso envolve a configuração dos critérios de segmentação, a criação de anúncios visualmente atraentes e persuasivos, e a definição de estratégias de lances e orçamentos para alcançar o público-alvo do e-commerce.": return i18n.t('sprint.newAction.pages.activities.descriptionSocialAdsCampaignSetup');
      case "Tráfego Pago - Desenho de Funil de Marketing para Campanhas de Tráfego Pago: Nessa atividade, é realizado o desenho de um funil de marketing estratégico para campanhas de tráfego pago. Isso envolve a definição das etapas do funil, desde a conscientização até a conversão, a criação de segmentações e ofertas específicas para cada etapa, e a configuração das campanhas de anúncios para direcionar o tráfego qualificado ao longo do funil, visando maximizar as conversões e o retorno sobre o investimento.": return i18n.t('sprint.newAction.pages.activities.descriptionPaidTrafficMktFunnelDesign');
      case "CRO - Teste A/B (texto ou imagem): São realizados testes A/B, nos quais diferentes versões de textos ou imagens são apresentadas aleatoriamente aos visitantes do site. Isso permite avaliar qual versão gera melhores resultados, como taxas de conversão ou engajamento, auxiliando na otimização das páginas e no aumento das conversões.": return i18n.t('sprint.newAction.pages.activities.descriptionAbTestTextImage');
      case "CRO - Teste A/B (snippet de código): Nessa atividade, são realizados testes A/B utilizando snippets de código, nos quais diferentes variantes de código são testadas em partes específicas do site. Isso permite avaliar qual versão gera melhores resultados em termos de desempenho, funcionalidade ou experiência do usuário.": return i18n.t('sprint.newAction.pages.activities.descriptionAbTestCodeSnippet');
      case "Gestão de E-commerce - Criação de Promoções: Nessa atividade, são criadas promoções ou descontos especiais para os produtos ou serviços da empresa. Isso pode incluir a definição das regras de desconto, período de validade e comunicação da promoção aos clientes.": return i18n.t('sprint.newAction.pages.activities.descriptionPromotionsCreation');
      case "Gestão de E-commerce - Criação de Coleções: Essa atividade envolve a criação de coleções de produtos agrupados com base em um tema ou critério específico. As coleções podem ser utilizadas para destacar produtos relacionados, promover tendências ou facilitar a navegação dos clientes dentro do catálogo de produtos.": return i18n.t('sprint.newAction.pages.activities.descriptionCollectionsCreation');
      case "Gestão de E-commerce - Gestão da Navegação (menus e links): Nessa atividade, são gerenciados os menus e links de navegação do site ou plataforma, garantindo a organização e usabilidade adequadas. Isso inclui a criação, edição e exclusão de categorias, subcategorias, páginas e links de navegação, buscando facilitar a experiência do usuário ao encontrar os produtos desejados.": return i18n.t('sprint.newAction.pages.activities.descriptionNavigationManagement');
      case "Gestão de E-commerce - Configuração de regras de frete: Aqui, são configuradas as regras relacionadas ao cálculo e exibição das opções de frete para os clientes durante o processo de compra. Isso inclui definição de faixas de peso, regiões de entrega, valores mínimos de pedido, entre outros critérios para o cálculo do frete.": return i18n.t('sprint.newAction.pages.activities.descriptionShippingRulesConfiguration');
      case "Gestão de E-commerce - Desenvolvimento de script de checkout (Scripts Editor): Essa atividade envolve o desenvolvimento de scripts personalizados para o processo de checkout do e-commerce. Esses scripts podem automatizar tarefas, adicionar funcionalidades específicas ou personalizar o fluxo do checkout, visando melhorar a experiência do usuário e facilitar o processo de compra.": return i18n.t('sprint.newAction.pages.activities.descriptionCheckoutScriptDevelopment');
      case "Inventário - Cadastro de Produtos: Essa atividade consiste em cadastrar os produtos da empresa em um sistema ou plataforma específica, inserindo informações como nome, descrição, código, categoria, preço, entre outros dados relevantes.": return i18n.t('sprint.newAction.pages.activities.descriptionProductsRegistration');
      case "Inventário - Importação de Produtos via planilha padronizada: Nessa atividade, os produtos são importados para o sistema por meio de uma planilha padronizada, agilizando o processo de atualização do inventário.": return i18n.t('sprint.newAction.pages.activities.descriptionProductsImport');
      case "Inventário - Adicionar filtros no catálogo (via tags): São adicionados filtros ao catálogo de produtos, utilizando tags ou metacampos, facilitando a navegação e pesquisa dos clientes dentro da plataforma.": return i18n.t('sprint.newAction.pages.activities.descriptionCatalogFiltersAddition');
      case "Inventário - Configurar swatches de variações: É realizada a configuração de swatches, que são pequenas amostras de cores, tamanhos ou outras variações de um produto, permitindo aos clientes visualizar e selecionar diferentes opções.": return i18n.t('sprint.newAction.pages.activities.descriptionVariationsSwatchesConfiguration');
      default: return description;
    }
  }

  export const handleOptions = (option) => {
    switch (option) {
      case "CRO": return i18n.t('sprint.newAction.pages.activities.categoryCRO');
      case "Desenv. de Interface": return i18n.t('sprint.newAction.pages.activities.categoryInterfaceDevelopment');
      case "Design Gráfico": return i18n.t('sprint.newAction.pages.activities.categoryGraphicDesign');
      case "Design de Interface": return i18n.t('sprint.newAction.pages.activities.categoryInterfaceDesign');
      case "Gestão de E-commerce": return i18n.t('sprint.newAction.pages.activities.categoryEcommerceManagement');
      case "Implantação": return i18n.t('sprint.newAction.pages.activities.categoryImplementation');
      case "Inventário": return i18n.t('sprint.newAction.pages.activities.categoryInventory');
      case "Marketing Digital": return i18n.t('sprint.newAction.pages.activities.categoryDigitalMarketing');
      case "Redação Publicitária": return i18n.t('sprint.newAction.pages.activities.categoryCopywriting');
      case "Relatórios": return i18n.t('sprint.newAction.pages.activities.categoryReports');
      case "SEO": return i18n.t('sprint.newAction.pages.activities.categorySEO');
      case "Setup de Marketing": return i18n.t('sprint.newAction.pages.activities.categoryMarketingSetup');
      case "Tráfego Pago": return i18n.t('sprint.newAction.pages.activities.categoryPaidTraffic');
      case "UI & UX": return i18n.t('sprint.newAction.pages.activities.categoryUiUx');
      case "Edição de Imagem": return i18n.t('sprint.newAction.pages.activities.categoryImageEditing');
      case "Edição de Vídeo": return i18n.t('sprint.newAction.pages.activities.categoryVideoEditing');
      case "Gestão de Marketplace": return i18n.t('sprint.newAction.pages.activities.categoryMarketplaceManagement');
      case "Tradução": return i18n.t('sprint.newAction.pages.activities.categoryTranslation');
  
      default: return option;
    }
  }