import { Box, Button, Grid, Paper, Typography } from '@mui/material'
import { FormikProvider, useFormik } from 'formik';
import React, { useEffect, useState } from 'react'
import { Form } from 'reactstrap'
import { i18n } from '../../translate/i18n';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/documents';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import "dayjs/locale/pt-br";
import 'dayjs/locale/es';
import * as Yup from 'yup';
import { FCDatePicker, FCReturnButton, FCSelect, FCTextField } from 'components-fullcomm';

const DocumentForm = (props) => {
  const location = window.location.pathname?.split('/')?.[2] || 'start';
  const [query, setQuery] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const newDocumentSchema = Yup.object().shape({
    name: Yup.string().required(i18n.t('documents.form.errorName')),
    url: Yup.string().required(i18n.t('documents.form.errorUrl')),
    date: Yup.date().required(i18n.t('documents.form.errorDate')),
    type: Yup.string().required(i18n.t('documents.form.errorType')),
  });

  var formik = useFormik({
    enableReinitialize: true,
    validateOnMount: true,
    initialValues: {
      name: query.name || '',
      url: query.url || '',
      platform: query.platform || location,
      date: query.date ? dayjs(query.date) : dayjs(new Date().toISOString().split("T")[0]),
      type: query.type || '',
    },
    validationSchema: newDocumentSchema,
    onSubmit: async (values) => {
      const formatedDate = values.date && dayjs(values.date).format("YYYY-MM-DD");

      const response = await (isEdit ? props.editDocument(props.match.params.id, { ...values, date: formatedDate }) : props.addDocument({ ...values, date: formatedDate }));
      
      if (response.error) {
        switch (response.error.message) {
          case "Dados inválidos.": return toast.error(i18n.t('document.form.invalidData'));
          case "Documento não encontrado.": return toast.error(i18n.t('document.form.documentNotExist'));
          case "Erro interno do servidor.": return toast.error(i18n.t('document.form.internalError'));
          default: return toast.error(response.error.message);
        }
      }
      else {
        if (isEdit) {
          toast.success(i18n.t('documents.form.editSuccess'));
          props.getDocument(props.match.params.id)
        } else {
          toast.success(i18n.t('documents.form.saveSuccess'));
          resetForm();
        }
      }
    },
  });

  var { getFieldProps, handleSubmit, touched, errors, values, resetForm} = formik;

  useEffect(() => {
    if (props.match.params.id) {
      props.getDocument(props.match.params.id);
      setIsEdit(true);
    }
  }, []);

  useEffect(() => {
    if (isEdit) setQuery(props.documentDetails || {});
  }, [props.documentDetails]);

  return (
    <Box className="dashboard">
      <Box display='flex' alignItems='center' mt="1rem">
        <FCReturnButton margin="0rem" />
        <Typography variant="h4">{isEdit ? i18n.t('documents.form.title2') : i18n.t('documents.form.title1')}</Typography>
      </Box>
      <FormikProvider value={formik}>
        <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
          <Paper sx={{ padding: "1rem", my: "1rem", borderRadius: '5px' }}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <FCTextField
                  size={"small"}
                  fullWidth
                  label={i18n.t('documents.form.name')}
                  {...getFieldProps("name")}
                  error={Boolean(touched.name && errors.name)}
                  helperText={touched.name && errors.name}
                />
              </Grid>
              <Grid item xs={6}>
                <FCTextField
                  size={"small"}
                  fullWidth
                  type='url'
                  label={"Url"}
                  {...getFieldProps("url")}
                  error={Boolean(touched.url && errors.url)}
                  helperText={touched.url && errors.url}
                />
              </Grid>
              <Grid item xs={6}>
                <FCDatePicker
                  value={values.date}
                  onChange={(newValue) => {
                    formik.setFieldValue("date", newValue);
                    }
                  }
                  fullWidth
                  label={i18n.t('documents.form.date')}
                  error={Boolean(touched.date && errors.date)}
                  helperText={touched.date && errors.date}
                />
              </Grid>
              <Grid item xs={6}>
                <FCSelect
                  size={"small"}
                  fullWidth
                  label={i18n.t('documents.form.type')}
                  {...getFieldProps("type")}
                  error={Boolean(touched.type && errors.type)}
                  helperText={touched.type && errors.type}
                  options={[
                    { value: "Nota de reunião", label: i18n.t('documents.form.meetingNote') },
                    { value: "Requisitos de projeto", label: i18n.t('documents.form.projectRequirements') },
                    { value: "Requisitos operacionais", label: i18n.t('documents.form.operationalRequirements') },
                    { value: "Relatório", label: i18n.t('documents.form.report') },
                    { value: "Outros", label: i18n.t('documents.form.other') },
                  ]}
                />
              </Grid>
              <Grid item xs={12} display="flex" justifyContent="right">
                <Button type="submit" variant="contained" className='btn-action large'>{i18n.t('documents.form.save')}</Button>
              </Grid>
            </Grid>
          </Paper>
        </Form>
      </FormikProvider>
    </Box>
  )
}

const mapStateToProps = (state) => ({
  documentDetails: state.documents.documentDetails,
});

export default connect(mapStateToProps, actions)(DocumentForm);