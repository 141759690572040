import { Box, Button, Grid, Link, Paper, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ImageEditor from './ImageEditor';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from "../../../store/actions/action";
import * as actions2 from "../../../store/actions/problems";
import { api } from '../../../config';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { makeUrlsClickable } from './ActivityApproval';
import { i18n } from '../../../translate/i18n';

const ReprovedActivity = (props) => {
  const history = useHistory();
  const location = useLocation();
  const isPerformance = location.pathname.split('/')[1] === "support-performance";
  const [data, setData] = useState({});

  useEffect(() => {
    isPerformance ? props.getProblem(props.match.params.id) : props.getAction(props.match.params.actionId);
  }, []);

  useEffect(() => {
    if (props.action) {
      const activity = props.action.activities.find(activity => activity._id === props.match.params.activityId);
      setData(activity);
    }
  }, [props.action]);

  useEffect(() => {
    if (props.problem) {
      setData(props.problem);
    }
  }, [props.problem]);

  return (
    <Box
      overflow={'auto'} 
      maxHeight={'calc(100vh - 5rem)'} 
      padding={'2rem'}
    >
      <Button
        className="btn-default"
        onClick={() => history.goBack()}
      >
        <ArrowBackIcon fontSize="small" />
        {i18n.t('sprint.kanban.reprovedActivity.back')}
      </Button>
      <Grid container columnSpacing={5} marginTop={"1rem"}>
        <Grid item xs={8}>
          <Paper
            sx={{
              paddingY: "1.5rem",
              paddingX: "2.5rem",
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
            }}
          >
            <Typography variant="h4">
              {(data?.title || data?.description) || ''}
            </Typography>
            <div style={{ font: 'inherit', whiteSpace: "pre-wrap", wordWrap: "break-word" }} dangerouslySetInnerHTML={{ __html: makeUrlsClickable(data?.commentJira || '') }}></div>
            {data?.attachmentsJira && <b>{i18n.t('sprint.kanban.reprovedActivity.attachments')}</b>}
            {data?.attachmentsJira &&
              data?.attachmentsJira?.map((attachment, i) => {

                return (
                  <div key={`${attachment.id}-${i}`}>
                    {attachment.mimeType?.startsWith('image/') ? (
                      <>                      
                        <ImageEditor 
                          disableEdit={true}
                          alt={attachment.filename}
                          annotations={attachment.annotations}
                          imageUrl={attachment.location ? attachment.location : `${api}/files/jira/${attachment.id}.${attachment.mimeType.split('/')[1]}`} 
                        />
                        <br />
                        <small>{attachment.filename}</small>
                      </>
                    ) : (
                      <Link href={attachment.location ? attachment.location : `${api}/files/jira/${attachment.id}.${attachment.mimeType?.split('/')[1]}`} target="_blank" rel="noopener noreferrer">
                        {attachment.location ? attachment.location : `${api}/files/jira/${attachment.id}.${attachment.mimeType?.split('/')[1]}`}
                        <InsertDriveFileIcon fontSize="small" />
                      </Link>
                    )}
                  </div>
                )
              })}
          </Paper>
        </Grid>
        <Grid item xs={4}>
          <Paper
            sx={{
              padding: "1.5rem",
            }}
          >
            <Typography variant="h5" mb={"1rem"}>{i18n.t('sprint.kanban.reprovedActivity.customerComment')}</Typography>
            <Typography>{data?.comment}</Typography>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  )
}

const mapStateToProps = (state) => ({
  problem: state.problems.problemDetails,
  action: state.actions.action,
});

export default connect(mapStateToProps, {...actions, ...actions2})(ReprovedActivity);