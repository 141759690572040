import { Box, Chip, CircularProgress, FormControl, Grid, InputLabel, MenuItem, Pagination, Paper, Select, Typography } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import ExportButton from '../../Sprint/utils/ExportButton'
import HoursTable from './HoursTable'
import * as actions from '../../../store/actions/problems'
import * as actions2 from "../../../store/actions/contracts";
import { debounce } from 'lodash';
import { queryString } from '../../../utils/String';
import { connect } from 'react-redux'
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/pt-br";
import dayjs from 'dayjs';
import 'dayjs/locale/es';
import { i18n } from '../../../translate/i18n';

function HourTracking(props) {
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(1);
  const [dateFilter, setDateFilter] = useState(dayjs(new Date()));
  const [sortDirection, setSortDirection] = useState(1);
  const [remainingHours, setRemainingHours] = useState(0);
  const [hoursSpent, setHoursSpent] = useState(0);
  const [selectedCustomer, setSelectedCustomer] = useState("")
  const [date, setDate] = useState("");

  const handleSearch = useCallback(
    debounce((page, sortDirection, date, customer = "") => {
      const query = queryString({page, sortDirection, date, customer });
      props.getProblems(query);
      return null;
    }, 1000),
    []
  );

  const changeShop = (shop) => {
    const isSelected = selectedCustomer === shop ? "" : shop;
    setSelectedCustomer(isSelected);
    handleSearch(page, sortDirection, dateFilter, isSelected);
  }

  useEffect(() => {
    handleSearch(page, sortDirection, dateFilter );
  }, [page, sortDirection, props.shop, dateFilter]);

  useEffect(() => {
    props.getViewContractSpecific();
  }, [props.shop]);

  useEffect(() => {
    const currentDate = new Date(dateFilter);
    const month = currentDate.getMonth();
    const year = currentDate.getFullYear();
    const date = `${year}-${month}`;
    setDate(date);
    const totalHours = props.problems?.docs?.reduce((acc, obj) => {
      let timeSpentInHistory = 0;
      obj.history?.forEach((historyObj, index) => {
        if (historyObj.date === date && typeof historyObj.timeSpentSeconds === 'number') {
          timeSpentInHistory += historyObj.timeSpentSeconds;
        } else {
          obj.history.splice(index, 1);
        }
      });
    
      return acc + timeSpentInHistory;
    }, 0);

    const hours = Math.floor((props.contract?.monthlyHours || 0) - (totalHours/3600 || 0));
    setRemainingHours(hours);
    setHoursSpent(Math.ceil(totalHours/3600 || 0));
  }, [props.contract, dateFilter, selectedCustomer, props.problems])

  return (
    <Box className="dashboard">
      <Grid container spacing={"0.75rem"}>
        <Grid item xs={12} md={9}>
          <Typography variant='h1'>{i18n.t("support.hourTracking.timeTracking")}</Typography>
        </Grid> 
        <Grid item xs={12} md={1}>
          <ExportButton data={selected} />
        </Grid>
        <Grid item xs={12} md={2}>
          <FormControl
            fullWidth
          >
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={localStorage.getItem('i18nextLng')}>
              <DatePicker
                minDate={dayjs("01/01/2020")}
                maxDate={dayjs("01/01/2040")}
                views={['month', 'year']}
                slotProps={{
                  textField: {
                    size: 'small'
                  },
                }}
                value={dateFilter}
                label={i18n.t("support.hourTracking.filter")}
                onChange={(date) => {
                  setDateFilter(date || '');
                }}
              />
            </LocalizationProvider>
          </FormControl>
        </Grid>
      </Grid>
      <Paper
        sx={{
          padding: '1.5rem',
          marginY: '1.75rem',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Box sx={{ display: 'flex', gap: '1.5rem' }}>
          <Box
            position={"relative"}
            display={"grid"}
            alignItems={"center"}
          >
            <CircularProgress
              variant="determinate"
              value={100}
              sx={{ color: "#e0e0e0", position: "absolute" }}
              size={"6rem"}
            />
            <CircularProgress
              variant="determinate"
              value={(remainingHours/(props.contract?.monthlyHours || 0))*100 || 100}
              sx={{ color: "#008060" }}
              size={"6rem"}
            />
            <Typography
              sx={{
                color: "#008060",
                fontWeight: "700",
                fontSize: '24px',
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              {remainingHours}
            </Typography>
          </Box>
          <Box
            display='flex'
            flexDirection='column'
            justifyContent='space-between'
          >
            <Typography fontSize="14px">{i18n.t("support.hourTracking.contractedHours")} <b>{props.contract?.monthlyHours || 0}h </b> <Typography variant='caption'>({props.contract?.monthlyHours * 25} {i18n.t("support.hourTracking.credits")})</Typography></Typography>
            <Typography fontSize="14px">{i18n.t("support.hourTracking.usedHours")} <b>{hoursSpent}h </b> <Typography variant='caption'>({hoursSpent * 25} {i18n.t("support.hourTracking.credits")})</Typography></Typography>
            <Typography fontSize="14px">{i18n.t("support.hourTracking.remainingHours")} <b>{remainingHours}h</b> <Typography variant='caption'>({remainingHours * 25} {i18n.t("support.hourTracking.credits")})</Typography></Typography>
          </Box>
        </Box>
        <Box>
          {
            props.contract?.customers?.map((customer) => {
              return (
                <Chip
                  key={customer?._id}
                  label={customer?.name || ""}
                  color={selectedCustomer === customer?._id ? "primary" : "default"}
                  sx={{ marginRight: "0.5rem" }}
                  onClick={() => { changeShop(customer?._id) }}
                />
              )
            }
            )
          }
        </Box>
      </Paper>

      <HoursTable 
        selected={selected} 
        setSelected={setSelected}           
        data={props.problems?.docs || []}
        date={date}
        setSortDirection={setSortDirection}
      />

        <Box
          display={"grid"}
          alignItems={"center"}
          justifyContent={"center"}
          padding={1}
        >
          <Pagination
            sx={{marginBottom: '3rem'}}
            variant="outlined"
            shape="rounded"
            count={props.problems?.totalPages || 1}
            page={page}
            onChange={(e, page) => setPage(page)}
          />
        </Box>
    </Box>
  )
}

const mapStateToProps = (state) => ({
  shop: state.auth.shop,
  problems: state.problems.problems,
  contract: state.contract.contractDetails,
});


export default connect(mapStateToProps, {...actions, ...actions2})(HourTracking)