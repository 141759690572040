import { Box, Button, Checkbox, Divider, Grid, Icon, Typography } from '@mui/material'
import React, { useState } from 'react'
import { Modal, ModalBody, ModalFooter } from 'reactstrap'
import { i18n } from '../../../translate/i18n';

const TrimesterUpdateModal = ({open, setOpen, handleUpdate, actions, all = false}) => {
  const [actionsTrimester, setActionsTrimester] = useState([]);

  const updateActions = () => {
    const defaultActions = actions?.filter(el=> el.status !== "Planejamento" && el.status !== "Cancelado" && el.status !== "Concluído") || [];
    const mergedActions = [...defaultActions, ...actionsTrimester];
    all ? handleUpdate() : handleUpdate(mergedActions);
  }

  const handleChange = (event, action) => {
    if (event.target.checked) {
      setActionsTrimester(prevActions => [...prevActions, action]);
    } else {
      setActionsTrimester(prevActions =>
        prevActions.filter(prevAction => prevAction._id !== action._id)
      );
    }
  };

  
  return (
    <Modal toggle={() => setOpen(false)} isOpen={open} centered>
        <Box 
          sx={{
            display: "flex", 
            justifyContent: "space-between", 
            padding: 2, 
            alignItems: "center"
          }}
          >
          <Typography variant="h3">{i18n.t('sprint.utils.headerButtons.trimesterUpdateModal.updateTrimester')}</Typography>
          <Icon
            sx={{
              color: "#86888c",
              cursor: "pointer",
            }}
            onClick={() => setOpen(false)}
            className="material-icons-outlined"
            fontSize="large"
          >
            cancel
          </Icon>
        </Box>
        <Divider />
        <ModalBody>
          <Typography height={"5vh"}>{all ? i18n.t('sprint.utils.headerButtons.trimesterUpdateModal.transferActions'): i18n.t('sprint.utils.headerButtons.trimesterUpdateModal.selectActions')}</Typography>
          <Grid container>
            {
              actions?.filter(el=> el.status === "Planejamento")?.map((action) => (
                <Grid key={action._id} item xs={12} md={6}  display={"flex"} gap={1} alignItems={"center"} marginTop={2}>
                  <Checkbox
                    color='backlog'
                    checked={actionsTrimester.some(selectedAction => selectedAction._id === action._id)}
                    onChange={event => handleChange(event, action)}
                    />
                  <Typography variant='h6'>{action.name}</Typography>
                </Grid>
              ))
            }
          </Grid>
        </ModalBody>
        <Divider sx={{paddingY: "0.75rem"}}/>
        <Box display={"flex"} justifyContent={"space-between"} padding={"0.75rem"}>
          {all && (
              <Button variant="contained" className="btn-reprove" onClick={() => handleUpdate(false)}>
                {i18n.t('sprint.utils.headerButtons.trimesterUpdateModal.remove')}
              </Button>
            )
          }
          <Box display={"flex"} gap={1} marginRight={0} marginLeft={"auto"}>
            <Button variant="contained" className="btn-action" onClick={() => {updateActions();setOpen(false)}}>
              {i18n.t('sprint.utils.headerButtons.trimesterUpdateModal.continue')}
            </Button>
            <Button variant="contained" className="btn-gray" onClick={() => setOpen(false)}>
              {i18n.t('sprint.utils.headerButtons.trimesterUpdateModal.cancel')}
            </Button>
          </Box>
        </Box>
      </Modal>
  )
}

export default TrimesterUpdateModal