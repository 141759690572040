import {
  Box,
  Button,
  Divider,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import * as actions from "../../../store/actions/action";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import dayjs from "dayjs";
import { useStyles } from "../../Sprint/ActionPlan/styles";
import { calculateTimeArea } from "../../Sprint/utils/calculateTimeArea";
import { isDateToday } from "../../Sprint/utils/isDateToday";
import GenttRow from "../../Sprint/ActionPlan/Roadmap/GenttRow";
import { i18n } from "../../../translate/i18n";
import LockIcon from '@mui/icons-material/Lock';
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const cycleFormatter = (cycle) => {
  let month;
  if (cycle) month = Number(cycle.charAt(0));
  if (month === 4) month = 9;
  if (month === 3) month = 6;
  if (month === 2) month = 3;
  if (month === 1) month = 0;
  const year = Number(cycle?.split(" ")[2]);
  return { month, year };
};

const getCurrentTrimesterStartMonth = () => {
  const currentMonth = new Date().getMonth();
  return [0, 3, 6, 9][Math.floor(currentMonth / 3)];
};

const dateRange = (date) => {
  const today = new Date(date);
  const start = new Date(today.getFullYear(), getCurrentTrimesterStartMonth() + 1, 1);
  const end = new Date(today.getFullYear(), getCurrentTrimesterStartMonth() + 3, 0);
  return [dayjs(start), dayjs(end)];
};

const Roadmap = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const [dates, setDates] = useState([]);
  const [defaultRows, setDefaultRows] = useState([]);
  const [activeRows, setActiveRows] = useState([]);
  const [months, setMonths] = useState([]);
  const [range, setRange] = useState(dateRange(new Date()));
  const [collapse, setCollapse] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const getActions = () => {
    const trimester = range?.map((date) => date.toISOString().slice(0, 10));
    const params = new URLSearchParams({
      trimester,
    });
    setSubmitting(true);
    props.getActions(params);
  };

  const handleClick = () => {
    if (props.customer?.platform?.includes("sprint")) {
      history.push("/estrategia");
    } else {
      window.location.href = `mailto:help@fullcomm.io`
    }
  }

  useEffect(() => {
    getActions();
  }, [props.shop]);

  useEffect(() => {
    setSubmitting(false);
    const filteredArray =
      props.actions?.docs.filter((obj) => {
        if (obj.status === "Concluído") {
          if (obj?.cycle?.includes(props.cycle)) {
            return true;
          } else {
            return false;
          }
        } else {
          return true;
        }
      }) || [];
    const trimester = cycleFormatter(props.cycle);
    if (trimester) {
      const { dates, defaultRows, activeRows, months } = calculateTimeArea(
        filteredArray,
        range
      );
      setDates(dates);
      setDefaultRows(defaultRows);
      setActiveRows(activeRows);
      setMonths(months);
    }
  }, [props.actions, props.cycle]);

  return (activeRows.length > 0 || defaultRows.length > 0) && props.customer?.platform?.includes("sprint") ? (
      <div style={{ overflow: "auto", maxHeight: "300px", borderRadius: "1.5rem" }}>
        <TableContainer
          sx={{
            paddingBottom: "1rem",
          }}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{ background: "#FAFAFA", border: "none", width: "20vw"  }}
                >
                  <Typography fontSize={"23px"} fontWeight={"bold"}>
                  {i18n.t('dashboard.roadmap')}
                  </Typography>
                </TableCell>
                {months.map((month, i) => {
                  let monthCell = [classes.monthCell];
                  if (i === 0) monthCell = [classes.startMonth];
                  if (i === months.length - 1) monthCell = [classes.endMonth];
                  return (
                    <TableCell
                      key={month.name}
                      className={monthCell.join(" ")}
                      colSpan={month.days}
                    >
                      <Typography>{month.name}</Typography>
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
            {
              activeRows.length > 0 && (
                <>
                  <TableRow className={classes.visibleRow}>
                    <TableCell className={classes.dropdownCell}>
                      <Paper className={classes.paper}>
                        <Typography>{i18n.t('sprint.actionPlan.roadMap.activeActions')}</Typography>
                        <IconButton
                          aria-label="expand row"
                          size="small"
                          onClick={() => setCollapse(!collapse)}
                        >
                          {collapse ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                      </Paper>
                    </TableCell>
                    {dates.map((date, i) => {
                      const cellClasses = [classes.dateCell];
                      const isToday = isDateToday(date);
                      return (
                        <TableCell
                          key={`${date}-${i}`}
                          className={cellClasses.join(" ")}
                        >
                          {isToday && <Box className={classes.todayCell} />}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                  {activeRows.map((row) => (
                    <GenttRow
                      key={row._id}
                      row={row}
                      dates={dates}
                      classes={classes}
                      hidden={collapse}
                    />
                  ))}
                  {!collapse && <Divider />}
                </>
              )
                }

              {defaultRows?.map((row) => (
                <GenttRow key={row._id} row={row} dates={dates} classes={classes} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    ) : (
      <Box   
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          minHeight: "250px",
          padding: "1rem",
          background: "#F3F3F3",
          borderRadius: "1.5rem",
        }}
      >
        <Paper sx={{ padding: '1rem', width: "50%", minWidth: "300px", maxWidth: "450px" }}>
          <Box fullWidth style={{ textAlign: 'center' }}>
            <Typography variant="h6" style={{ marginTop: '5px', display: "flex", gap: "5px", alignItems: "center", justifyContent: "center" }}>
              <LockIcon />
              {i18n.t('dashboard.startWithPerformance')}
            </Typography>
            <Typography variant="body2" style={{ marginTop: '5px' }}>
              {i18n.t('dashboard.usePerformanceModule')}
            </Typography>
            <br />
              <Button className='btn-action' variant='contained' onClick={handleClick}>
                {
                  props.customer?.platform?.includes("sprint") ? (
                    i18n.t('dashboard.goToPerformance')
                  ) : (
                    i18n.t('dashboard.requestModule')
                  )
                }
              </Button>
          </Box>
        </Paper>
      </Box>
    )
};

const mapStateToProps = (state) => ({
  shop: state.auth.shop,
  cycle: state.actions.cycle,
  actions: state.actions.actions,
  customer: state.customer.customerDetails,
});

export default connect(mapStateToProps, actions)(Roadmap);
