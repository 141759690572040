import React, { useEffect, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux'
import * as actions from '../../../store/actions/customers'
import { Typography, Tooltip, Table, TableBody, TableCell, TableRow, Button, Box } from '@mui/material';
import { Icon } from '@iconify/react';
import debounce from 'lodash.debounce';
import { userPermissionCheck } from '../../Sprint/utils/userPermissionCheck';
import { isMobile } from 'react-device-detect';
import { i18n } from '../../../translate/i18n';
import { FCPagination, FCSelect, FCTableContainer, FCTableHeader, FCTextField } from 'components-fullcomm';

const ViewAll = (props) => {
    const [pageCurrent, setPageCurrent] = useState(1);
    const limit = 10;
    const [shopFilter, setShopFilter] = useState('all');
    const [query, setQuery] = useState('');
    const debouncedSearch = useCallback(
        debounce(async (pageCurrent, limit, shopFilter, query) => {
            try {
                props.getCustomersChecklist(pageCurrent, limit, shopFilter, query)
            } catch (error) {
                console.error("Erro ao buscar ícones:", error);
            }
        }, 1000),
        []
    );
    useEffect(() => {
        debouncedSearch(pageCurrent, limit, shopFilter, query);
    }, [pageCurrent, shopFilter, query]);

    let customers = [];

    if (props.customers) {
        customers = props.customers.docs
    }

    return (
        <div style={{ padding: isMobile ? '16px 16px 66px 16px' : '16px' }} className={!isMobile & "p-4"} >
            <div className="d-flex">
                <div className="mr-auto">
                <Typography variant="h4">{i18n.t('start.checklist.viewAll.checklists')}</Typography>
                </div>
                {
                    userPermissionCheck(props.user, ["create_checklist"], "admin") &&                 
                    <Link to={"/edit-checklist"}>
                        <Button className="btn-action medium">{i18n.t('start.checklist.viewAll.edit')}</Button>
                    </Link>
                }

            </div>
            <br />
            <Box
                display='flex'
                gap='1rem'
                mb='1rem'
            >
                <FCTextField value={query} label={i18n.t('sprint.actionChecklist.viewAll.search')} onChange={e => setQuery(e.target.value)} />
                <FCSelect
                    value={shopFilter}
                    label={i18n.t('start.checklist.viewAll.store')}
                    onChange={e => setShopFilter(e.target.value)}
                    options={[
                        { value: 'all', label: i18n.t('start.checklist.viewAll.all') },
                        { value: 'success', label: i18n.t('start.checklist.viewAll.finisheds') },
                        { value: 'in_analysis', label: i18n.t('start.checklist.viewAll.inAnalysis') },
                        { value: 'waiting', label: i18n.t('start.checklist.viewAll.waiting') },
                        { value: 'error', label: i18n.t('start.checklist.viewAll.error') },
                        { value: 'without', label: i18n.t('start.checklist.viewAll.withoutChecklist') },
                    ]}
                />
            </Box>
            <FCTableContainer>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <FCTableHeader>
                        <TableRow>
                            <TableCell>{i18n.t('start.checklist.viewAll.name')}</TableCell>
                            <TableCell>{i18n.t('start.checklist.viewAll.waiting2')}</TableCell>
                            <TableCell>{i18n.t('start.checklist.viewAll.inAnalysis2')}</TableCell>
                            <TableCell>{i18n.t('start.checklist.viewAll.error')}</TableCell>
                            <TableCell>{i18n.t('start.checklist.viewAll.finisheds2')}</TableCell>
                            <TableCell>{i18n.t('start.checklist.viewAll.total')}</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </FCTableHeader>
                    <TableBody>
                        {customers?.map(row => (
                            <TableRow
                                key={row._id}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell>{row.name}</TableCell>
                                <TableCell>{row.checklist.filter(e => e.status === 'waiting').length}</TableCell>
                                <TableCell>{row.checklist.filter(e => e.status === 'in_analysis').length}</TableCell>
                                <TableCell>{row.checklist.filter(e => e.status === 'error').length}</TableCell>
                                <TableCell>{row.checklist.filter(e => e.status === 'success').length}</TableCell>
                                <TableCell>{row.checklist.length}</TableCell>
                                <TableCell align="right">
                                    {
                                         userPermissionCheck(props.user, ["view_checklist"]) && row.checklist.length > 0 && <Tooltip title={i18n.t('start.checklist.viewAll.viewChecklist')}><Link to={"/view-checklist/" + row._id}><Icon width="25" style={{marginRight: '5px'}} icon="tabler:checklist" /></Link></Tooltip>
                                    }
                                    {
                                        userPermissionCheck(props.user, ["create_checklist"]) && <Tooltip title={i18n.t('start.checklist.viewAll.editChecklist')}><Link to={"/create-checklist/" + row._id}><Icon width="25" style={{marginRight: '5px'}} icon="material-symbols:edit-attributes" /></Link></Tooltip>
                                    }
                            </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </FCTableContainer>
            <br />
            {!props.customers ? "" :
                <FCPagination count={props.customers.totalPages} page={pageCurrent} onChange={(e, page) => setPageCurrent(page)} />
            }
        </div>
    )
}


const mapStateToProps = state => ({
    customers: state.customer?.customersChecklist || []
})

export default connect(mapStateToProps, actions)(ViewAll);

