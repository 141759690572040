import { AppBar, Box, IconButton, Toolbar, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import ChatInput from './ChatInput'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/messages';
import { i18n } from '../../translate/i18n';
import Message from './Message';
import { isMobile } from 'react-device-detect';
import { containsOnlyEmptyTags } from '../../pages/Chat/utils/containsOnlyEmptyTags';
import { getTaggedUsers } from '../../pages/Chat/utils/getTaggedUsers';
import MessageDeleteModal from "./utils/MessageDeleteModal";

const ChatThread = (props) => {
  const [inputValue, setInputValue] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [deleteMessageId, setDeleteMessageId] = useState(null);

  const deleteMessage = async (id) => {
    try {
      const response = await props.deleteMessage(id);
      if (!response.error) {
        id === props.thread._id ? props.setThread(response.messageData)
        : props.setThreadMessages((prev) => prev.map((message) => message._id === id ? response.messageData : message));
      }
      return response;
    } catch (error) {
      console.log(error);
    }
  }

  const handleInputChange = (text) => {
    setInputValue(text);
  };

  const handleSendMessage = async (event, files, action, taggedData) => {
    event.preventDefault();
    if (containsOnlyEmptyTags(inputValue) && files.length === 0 && !action) return;

    const taggedUsersData = getTaggedUsers(inputValue);
    const taggedUsers = taggedUsersData.filter((tagged) => taggedData?.includes(tagged));
    const formData = new FormData();
    files.forEach((file) => {
      formData.append("files", file);
    });
    formData.append("text", inputValue);
    formData.append("platform", props.thread.platform);
    formData.append("parent", props.thread._id);
    if (taggedUsers) formData.append("taggedUsers", taggedUsers);
    if (action) formData.append("action", action);

    const newMessage = await props.sendMessage(formData, props.thread.customer);
    props.setThreadMessages(prev => ([...prev, newMessage]))
    props.setMessages((prevMessages) => {
      return prevMessages.map((message) => {
        if (message._id === props.thread._id) {
          message.read?.findIndex((read) => read === props.user._id) === -1 && message.read?.push(props.user._id);
          const children = message.children.map((child) => {
            child.read?.findIndex((read) => read === props.user._id) === -1 && child.read?.push(props.user._id);
            return child;
          });
          message.children = [...children, newMessage];
          return message;
        }
        return message;
      });
    });
    setInputValue("");
    // scrollToBottom();
  };

  const handleThreads = async () => {
    if (props?.thread?._id) {
      await props.getThread(props.thread._id, props.thread.customer, props.thread.platform);
      props.user?.role === 'admin' ? await props.getNoReadMessages() : props.getNoReadMessagesCustomer({shop: props.thread.customer})
    } else {
      props.setThreadMessages([]);
    }
  };

  useEffect(() => {
    handleThreads();
  }, [props.thread, props.messageToEdit]);

  useEffect(() => {
    props.setThreadMessages(props.threads);
  }, [props.threads]);

  return (
    <Box 
      display="flex" 
      flexDirection="column" 
      height="100%"
      sx={{
        height: "100%",
        width: props.thread?._id ? isMobile ? '100%' : '375px' : '0px',
        transition: `${!isMobile && 'width 0.5s'}, visibility 0.5s ease, opacity 0.5s ease`,
        opacity: props.thread?._id ? 1 : 0,
        visibility: props.thread?._id ? 'visible' : 'hidden',
        position: 'absolute',
        top: 0,
        right: 0,
        borderLeft: isMobile ? 'none' : '1px solid #D1D5DB',
      }}
    >
      <MessageDeleteModal 
        id={deleteMessageId}
        setDeleteId={setDeleteMessageId}
        open={openModal}
        setOpen={setOpenModal}
        deleteMessage={deleteMessage}
      />
      <AppBar 
        position="relative" 
        elevation={isMobile ? 3 : 0}
        sx={{
          backgroundColor: isMobile ? '#F3F3F3' : 'white',
          zIndex: 10,
          borderBottom: !isMobile && '1px solid #D1D5DB',
        }}
      >
        <Toolbar>
            <IconButton
                sx={{ color: '#888' }}
                onClick={() => {
                   props.setThread({});
                }}
            >
                <ArrowBackIosNewIcon sx={{ height: '15px', width: '15px', rotate: !isMobile && '180deg' }} />
            </IconButton>
            <Typography variant="h5" color={"#000000DE"} style={{ flexGrow: 1 }} align="center">
                {i18n.t('components.chat.chatHeader.chat')}
            </Typography>
        </Toolbar>
      </AppBar>
      <Box overflow='auto'>
        <Message message={props.thread} user={props.user} updateMessage={props.localEditMessage} setDeleteMessageId={setDeleteMessageId} setOpenModal={setOpenModal}/>
        <Box 
          sx={{
            my: "0.25rem", 
            borderWidth: '1px 0px 1px 0px', 
            borderStyle: 'solid', 
            borderColor: '#D1D5DB',
            padding: '8px 15px 8px 15px',
          }}>
          <Typography variant="body1" sx={{ color: "#888888" }}>
          {props.threadMessages?.length} {props.threadMessages?.length === 1 ? i18n.t('components.chat.chatConversation.reply') : i18n.t('components.chat.chatConversation.replies')}
          </Typography>
        </Box>
        {
          props.threadMessages?.map((message, index) => (
            <Message 
              key={message?._id} 
              message={message} 
              user={props.user}
              handleMarkMessage={props.markMessage}
              updateMessage={props.localEditMessage}
              setDeleteMessageId={setDeleteMessageId}
              setOpenModal={setOpenModal}
            />
            ))
          }
      </Box>
      <ChatInput
        inputValue={inputValue}
        customer={props.thread.customer}
        handleInputChange={handleInputChange}
        handleSendMessage={handleSendMessage}
        tagging={false}
        thread={props.thread}
        />
    </Box>
  )
}

const mapStateToProps = (state) => ({
  threads: state.message.thread,
  user: state.auth.user,
  messageToEdit: state.message.messageToEdit,
})

export default connect(mapStateToProps, actions)(ChatThread)