import React, { useState, useEffect, useRef, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import Header from '../../components/Header'
import Sidebar from '../../components/Sidebar'
import { connect } from 'react-redux'
import * as actions from '../../store/actions'
import * as actions2 from '../../store/actions/action'
import * as actionsMessages from '../../store/actions/messages';
import * as actionsContract from '../../store/actions/contracts'
import * as actionsCustomer from '../../store/actions/customers'
import { SocketContext } from "../../contexts/SocketContext";
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import '../../styles/dashboard/index.css'
import notificationSoundFile from "../../assets/notification.mp3";
import taggedUserSoundFile from "../../assets/user_tagged.mp3";
import OneSignal from 'react-onesignal';
import { appKeyOneSignal } from '../../config'
import NotificationsIcon from '@mui/icons-material/Notifications';
import { isMobile } from 'react-device-detect';
import { BottomNavigation, BottomNavigationAction, Paper, Badge } from '@mui/material';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import GridViewOutlinedIcon from '@mui/icons-material/GridViewOutlined';
import ChatSidebar from '../../components/Chat/ChatSideBar'
import { i18n } from '../../translate/i18n';
import { userPermissionCheck } from '../../pages/Sprint/utils/userPermissionCheck';

const BaseDashboard = (props) => {
  const history = useHistory();
  const socket = useContext(SocketContext);
  const [sitMenu, setSitMenu] = useState(isMobile ? false : true);
  const [shop, setShop] = useState(localStorage.getItem('shop'))
  const [chatOpen, setChatOpen] = useState(false);
  const [messages, setMessages] = useState(0);
  const [location, setLocation] = useState(history.location.pathname?.split('/')[1]);

  function calculateTotalSum(data) {
    let sum = 0;
    for (let key in data) {
      if (data.hasOwnProperty(key)) {
        sum += data[key].total || 0;
      }
    }
    return sum;
  }

  useEffect(() => {
    setLocation(history.location.pathname?.split('/')[1])
  }, [history.location.pathname])

useEffect(() => {
    if (props.user?.role === 'admin') setMessages(calculateTotalSum(props.noReadMessages));
}, [props.noReadMessages])

  const changeShop = (value) => {
    setShop(value)
    props.handleShop(value)
    props.getHealthScore(props.cycle)
    props.getViewContractSpecific()
    props.getViewCustomer(value)
  }

  useEffect(() => {
    const shop = localStorage.getItem('shop')
    if (shop) {
      changeShop(shop)
    }
  }, [])

  useEffect(async () => {
    if (props.user?._id) {
      const shop  = localStorage.getItem('shop')
      if (!shop && props.user.customer[0]) changeShop(props.user.customer[0]._id)

      await OneSignal.init({ appId: appKeyOneSignal, allowLocalhostAsSecureOrigin: true });
      const externalUserId = await OneSignal.getExternalUserId();
      if (externalUserId === null) {
        OneSignal.setExternalUserId(props.user._id)
        OneSignal.setEmail(props.user.email)
      }
      const isEmailSubscribed = await OneSignal.getEmailId();
      if (!isEmailSubscribed && externalUserId !== null) {
        OneSignal.setEmail(props.user.email)
      }
      if (!OneSignal.isPushNotificationsEnabled) {
        OneSignal.showSlidedownPrompt();
        OneSignal.registerForPushNotifications();
      }

    }
  }, [props.user]);

  useEffect(() => {
    const { role } = props.user || {};
    if (role === 'customer' || role === 'agency') props.getNoReadMessagesCustomer(props);
  }, [props.shop]);

  useEffect(() => {
    if (props.cycle) props.getHealthScore(props.cycle);
  }, [props.cycle]);
  const notificationSound = useRef(new Audio(notificationSoundFile));
  const userTaggedSound = useRef(new Audio(taggedUserSoundFile));
  useEffect(() => {
    let isMounted = true;

    if (!socket) return;

    socket.on('receiveMessage', (data) => {
      if (isMounted && data.message.author._id !== props.user._id) {
        if (!props.user.isBusy) data.message.taggedUsers?.includes(props.user._id) ? userTaggedSound.current.play() : notificationSound.current.play();
        if (history.location.pathname === '/chat-adm' || history.location.pathname?.split('/')[1] === 'chat') return;
        if (props.user?.role === 'admin') {
          props.getNoReadMessages()
        } else {
          props.getNoReadMessagesCustomer(props);
        }
      }
    });

    if (props.user?.role === 'customer' || props.user?.role === 'agency') {
      let platforms = props.user?.customer?.find(e => e._id === shop)?.platform;
      platforms?.forEach(e => {
        if (isMounted && userPermissionCheck(props.user, [`chat_${e}`])) {
          socket.emit('joinChatRoom', { _id: shop + "-" + e });
        }
      });
    } else if (props.user?.role === 'admin') {
      for (const e of props.customers) {
        let platforms = e?.platform;
        if (platforms) {
          for (const plat of platforms) {
            if (isMounted && !props.user.mutedChatChannels?.[plat]?.includes(e._id) && userPermissionCheck(props.user, [`chat_${plat}`])) {
              socket.emit('joinChatRoom', { _id: e._id + "-" + plat });
            }
          }
        }
      }
    }

    return () => {
      isMounted = false;
    };
  }, [props.user, props.customers, socket]);

  const altSitMenu = () => {
    setSitMenu(!sitMenu)
  }
  return (
    <div className="d-flex" onClick={() => {
        if (chatOpen === true) setChatOpen(false)
      }
      }>
        <Sidebar setShop={changeShop} shop={shop} active={sitMenu} handleLogout={props.handleLogout} />
        <div shop style={sitMenu ? { marginLeft: '250px', width: isMobile ? '100%' : 'calc(100% - 250px)' } : { marginLeft: '0', width: '100%' }} className='container-dash'>

          <Header setChatOpen={setChatOpen} chatOpen={chatOpen} handleLogout={props?.handleLogout} altSitMenu={altSitMenu} dataUser={props} sitMenu={sitMenu} />
          <div onClick={() => isMobile && setSitMenu(false)} >
            {{ ...props.children, shop }}
          </div>
          {isMobile &&
            <>
              <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, zIndex: 997, paddingBottom: "15px" }} elevation={5}>
                <BottomNavigation
                  showLabels
                >
                  <BottomNavigationAction 
                    onClick={() => {
                      history.push('/')
                    }} 
                    sx={{color: location === 'dashboard' ? "#00D6CF" : 'rgba(0, 0, 0, 0.6)'}}
                    label={i18n.t('containers.dashboard.baseDashboard.dashboard')} 
                    icon={<GridViewOutlinedIcon sx={{color: location === 'dashboard' ? "#00D6CF" : 'rgba(0, 0, 0, 0.6)'}} />} 
                  />
                  <BottomNavigationAction 
                    onClick={() => {
                      history.push('/action-list')
                    }} 
                    label={i18n.t('containers.dashboard.baseDashboard.actions')} 
                    sx={{color: location === 'action-list' ? "#00D6CF" : 'rgba(0, 0, 0, 0.6)'}}
                    icon={<ReceiptLongIcon sx={{color: location === 'action-list' ? "#00D6CF" : 'rgba(0, 0, 0, 0.6)'}}/>} 
                  />
                  <BottomNavigationAction 
                    onClick={() => {
                      history.push('/notifications')
                    }} 
                    label={i18n.t('containers.dashboard.baseDashboard.notifications')} 
                    sx={{color: location === 'notifications' ? "#00D6CF" : 'rgba(0, 0, 0, 0.6)'}}
                    icon={<NotificationsIcon sx={{color: location === 'notifications' ? "#00D6CF" : 'rgba(0, 0, 0, 0.6)'}} />} 
                  />
                  <BottomNavigationAction 
                    onClick={() => {
                      props.user?.role === 'admin' ? history.push('/chat-adm?platform=platform') :
                      history.push('/chat/platform')
                    }} 
                    label={i18n.t('containers.dashboard.baseDashboard.chat')} 
                    sx={{color: (location === 'chat' || location === 'chat-adm') ? "#00D6CF" : 'rgba(0, 0, 0, 0.6)'}}
                    icon={
                      <Badge badgeContent={props.user?.role === 'customer' ? props.noReadMessages?.total: messages} color="greenNotification">
                        <svg width="23" height="20" viewBox="0 0 23 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M2.964 14.828C1.5 13.657 1.5 12.771 1.5 9C1.5 5.229 1.5 3.343 2.964 2.172C4.43 1 6.786 1 11.5 1C16.214 1 18.571 1 20.035 2.172C21.5 3.343 21.5 5.229 21.5 9C21.5 12.771 21.5 13.657 20.035 14.828C18.572 16 16.214 16 11.5 16C8.99 16 7.7 17.738 5.5 19V15.788C4.406 15.625 3.601 15.338 2.964 14.828Z" stroke={(location === 'chat' || location === 'chat-adm') ? "#00D6CF" : 'rgba(0, 0, 0, 0.6)'} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                      </Badge>} />
                </BottomNavigation>

              </Paper>
              {
                props.user?.role === 'customer' && (
                  <ChatSidebar isMobile={true} style={{ display: chatOpen ? 'block' : 'none', position: 'absolute', bottom: '75px', right: '0', zIndex: '9999', width: isMobile ? '100%' : 'inherit' }} />
                )
              }
            </>
          }
        </div>
      </div>
  )

}

const mapStateToProps = state => {
  return ({
    shop: state.auth.shop,
    user: state.auth.user,
    cycle: state.actions.cycle,
    customers: state.customer?.customersSidebar?.docs || [],
    noReadMessages: state.message.noReadMessages || [],
  })
}

export default connect(mapStateToProps, { ...actions, ...actions2, ...actionsContract, ...actionsCustomer, ...actionsMessages })(BaseDashboard);