import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { getPermissionModels, getPermissions, updateUserPermissions } from '../../store/actions/permissions';
import { getViewUser } from '../../store/actions/users';
import { toast } from 'react-toastify';
import { userPermissionCheck } from '../Sprint/utils/userPermissionCheck';
import {
    Button,
    Tab,
    Box,
    FormGroup,
    Typography,
    CardContent,
} from '@mui/material';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { i18n } from '../../translate/i18n';
import { FCAutocomplete, FCCard, FCCheckbox, FCReturnButton, FCSelect, FCTabs } from 'components-fullcomm';

const UserPermissions = (props) => {
    const { id } = useParams();
    const [userPermissions, setUserPermissions] = useState([]);
    const [tabValue, setTabValue] = useState(0);
    const [selectedModel, setSelectedModel] = useState('');
    const [selectedCustomer, setSelectedCustomer] = useState('');
    const history = useHistory();

    const handleRedirect = () => {
        history.push('/permissions');
    };

    useEffect(() => {
        props.getPermissions();
        props.getPermissionsModels();
        props.getViewUser(id);
    }, []);
    useEffect(() => {
        if (props.user) {
            setUserPermissions(props.user?.permissionCustomer?.find(e => e.customer === selectedCustomer)?.actions || []);
            setSelectedModel(props.permissionModels.find(e => e._id === props.user?.permissionCustomer?.find(e => e.customer === selectedCustomer)?.permissionModel));
        }
    }, [props.user, selectedCustomer]);

    const handleCheckbox = (actionId, event) => {
        if (event.target.checked) {
            setUserPermissions([...userPermissions, actionId]);
        } else {
            setUserPermissions(userPermissions.filter(id => id !== actionId));
        }
    };

    const savePermissions = async () => {
        if (!selectedCustomer) {
            toast.error(i18n.t('permission.selectClient'));
            return;
        }
        const permissionsData = {
            permissionModel: selectedModel?._id || null,
            customer: selectedCustomer,
            user: id,
            actions: userPermissions,
        };
        try {
            const response = await props.updateUserPermissions(permissionsData);
            props.getViewUser(id);
            if (response.error) {
                switch (response.error.message) {
                    case "Dados inválidos.": return toast.error(i18n.t('permission.invalidData'));
                    case "Usuário não encontrado.": return toast.error(i18n.t('permission.userNotFound'));
                    case "Erro interno do servidor.": return toast.error(i18n.t('permission.internalError'));
                    default: toast.error(response.error.message)
                }
            } else {
                if (response.message === "Permissões atualizadas com sucesso!") {
                    toast.success(i18n.t('permission.updateSuccess'));
                } else {
                    toast.success(response.message);
                }
            }
        } catch (error) {
            if (error.error.message === "Erro interno do servidor.") {
                toast.error(i18n.t('permission.internalError'));
            } else {
                toast.error(error.error.message);
            }
        }
    };

    const handleChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const renderPermissions = (parent) => {
        const parentPermissions = props.permissions.filter((permission) => permission.parent === parent);

        return (
            <Box mt={'1rem'}>
                {parentPermissions.map((permission) => (
                    <FCCard key={permission._id} sx={{ marginBottom: '16px' }}>
                        <CardContent>
                            <Typography variant="subtitle2" component="div">
                                {permission.name}
                            </Typography>
                            <FormGroup row>
                                {permission.actions.map((action) => (
                                    <FCCheckbox 
                                        key={action.id} 
                                        disabled={selectedModel?.actions?.length > 0}
                                        checked={userPermissions?.includes(action.id) || false}
                                        onChange={(event) => handleCheckbox(action.id, event)}
                                        label={action.name}
                                    />
                                ))}
                            </FormGroup>
                        </CardContent>
                    </FCCard>
                ))}
            </Box>
        );
    };
    const handleModelChange = (model) => {
        setSelectedModel(model);
        if (model) {
            setUserPermissions(model.actions);
        } else {
            setUserPermissions([]);
        }
    };
    return (
        <div className="p-4">
            <Box display="flex" justifyContent={"space-between"} mb='1rem'>
                <div style={{ display: 'flex', alignItems: 'center'}}>
                    <FCReturnButton margin={"0rem"}/>
                    <Typography variant="h5">{i18n.t('permission.permissions')}</Typography>
                </div>
                {
                    userPermissionCheck(props.auth, ["create_permission", "admin"]) && (
                        <Button className='btn-action medium' onClick={handleRedirect}>{i18n.t('permission.edit')}</Button>
                    )
                }
            </Box>
            <Box display="flex" gap="1rem">
                <FCAutocomplete
                    disablePortal
                    label='Cliente'
                    options={
                        props.auth?.role === 'admin'
                            ? (props.user?.customer || []).map(el => el.name)
                            : (props.user?.customer || []).filter(el => (props.auth?.permissionCustomer || []).filter(el => el?.actions?.includes("edit_user_permissions")).map(el => el?.customer).includes(el?._id)).map(el => el?.name)
                    }
                    fullWidth
                    sx={{ marginBottom: '10px' }}
                    onChange={(event, newValue) => {
                        setSelectedCustomer(props.user?.customer.find(el => el.name === newValue)?._id);
                    }
                    }
                />
                <FCSelect
                    label={i18n.t('permission.permissionsModel')}
                    fullWidth
                    options={[...(props.permissionModels?.map(model => ({ value: model, label: model.name })) || []), { value: '', label: i18n.t('permission.none') }]}
                    value={selectedModel}
                    onChange={(e) => handleModelChange(e.target.value)}
                />
            </Box>

            <FCTabs value={tabValue} onChange={handleChange}>
                <Tab label={i18n.t('permission.general')} />
                <Tab label={i18n.t('permission.implantation')} />
                <Tab label={i18n.t('permission.performance')} />
                <Tab label={i18n.t('permission.support')} />
                <Tab label={i18n.t('permission.operation')} />
            </FCTabs>
            {tabValue === 0 && renderPermissions('geral')}
            {tabValue === 1 && renderPermissions('start')}
            {tabValue === 2 && renderPermissions('sprint')}
            {tabValue === 3 && renderPermissions('suporte')}
            {tabValue === 4 && renderPermissions('scale')}
                <Button className='btn-action large' variant='contained' onClick={savePermissions} sx={{ float: 'right', mb: '5rem' }}>{i18n.t('permission.savePermissions')}</Button>
        </div>
    );
};

const mapStateToProps = (state) => ({
    permissions: state.permission?.permissions?.filter(elem => elem.typeUser !== 'admin') || [],
    user: state.user?.userDetails || {},
    auth: state.auth.user,
    permissionModels: state.permission?.permissionModels || [],
});

const mapDispatchToProps = (dispatch) => {
    return {
        getPermissionsModels: () => dispatch(getPermissionModels()),
        getPermissions: () => dispatch(getPermissions()),
        getViewUser: (props) => dispatch(getViewUser(props)),

        updateUserPermissions: (props) => dispatch(updateUserPermissions(props)),
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(UserPermissions);
