/* eslint no-restricted-globals: ["error", "never"] */


self.addEventListener('install', (event) => {
  // O promise event.waitUntil é usado para saber quanto tempo leva a instalação e se foi bem sucedida.
  event.waitUntil(
    caches.open('my-cache').then((cache) => {
      // Os arquivos são adicionados ao cache aqui.
      return cache.addAll([
        '/',
        '/index.html',
        // Aqui você pode adicionar mais arquivos se desejar.
      ]);
    })
  );
});

self.addEventListener('fetch', (event) => {
  // A API Fetch é usada para capturar todas as solicitações HTTP feitas por nosso aplicativo.
  event.respondWith(
    caches.match(event.request).then((response) => {
      // O método caches.match() procura no CacheStorage uma correspondência para a solicitação. Se for encontrada, a resposta é retornada.
      return response || fetch(event.request);
    })
  );
});

export function register() {
  if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
      navigator.serviceWorker.register('/serviceWorker.js').then(
        (registration) => {
          // Registration was successful
          console.log(
            'ServiceWorker registration successful with scope: ',
            registration.scope
          );
        },
        (err) => {
          // registration failed :(
          console.log('ServiceWorker registration failed: ', err);
        }
      );
    });
  }
}
