import React from 'react';
import Start from './Start';
import Sprint from './Sprint';
import Scale from './Scale';
import Support from './Support';
import Apps from './Apps';
import AiContent from './AiContent';

const CombinedRoutes = ({ baseDashboard, wideDashboard }) => {
    return (
        <>
            <Start baseDashboard={baseDashboard} />
            <Sprint baseDashboard={baseDashboard} wideDashboard={wideDashboard} />
            <Scale baseDashboard={baseDashboard} />
            <Support baseDashboard={baseDashboard} />
            <Apps baseDashboard={baseDashboard} />
            <AiContent baseDashboard={baseDashboard} />
        </>
    );
};

export default CombinedRoutes;
