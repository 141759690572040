import React, { useEffect, useMemo, useRef, useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "moment/locale/pt";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { statusColor } from "../utils/statusColor";
import { Box, IconButton, MenuItem, Select, Typography, useMediaQuery } from "@mui/material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { connect } from "react-redux";
import * as actions from "../../../store/actions/action";
import "../../../styles/dashboard/sprint/index.css";
import { i18n } from '../../../translate/i18n';

const localizer = momentLocalizer(moment);

const CalendarView = (props) => {
  const calendarRef = useRef(null);
  const isMobileDevice = useMediaQuery('(max-width:600px)');
  const [events, setEvents] = useState([]);
  const [view, setView] = useState("month");

  const eventStyleGetter = (event) => {
    const { bgcolor } = statusColor(event.status);
    const style = {
      backgroundColor: bgcolor,
      borderRadius: "0px",
      color: "white",
      border: "0px",
      display: "block",
      borderRadius: "5px",
      fontSize: "16px",
    };
    return { style };
  };

  const capitalizeFirstLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  useEffect(() => {
    setEvents(
      props.actions?.docs.map((action) => {
        return {
          title: action.name,
          start: new Date(action.start),
          end: new Date(action.end),
          status: action.status,
          allDay: true,
        };
      })
    );
  }, [props.actions]);

  useEffect(() => {
    const params = new URLSearchParams({
      cycle: props.cycle,
    });

    props.getActions(params);
  }, [props.cycle, props.shop]);

  useEffect(() => {
    const time = document.querySelectorAll('.rbc-time-content');
    time.forEach((element) => {
      element.style.display = 'none';
    });
    const rows = document.querySelectorAll('.rbc-row-content');
    rows.forEach((element) => {
      element.style.height = '67vh';
    });
    const gutter = document.querySelectorAll('.rbc-time-header-gutter');
    gutter.forEach((element) => {
      element.style.padding = '0px';
    });
    const content = document.querySelectorAll('.rbc-time-header');
    content.forEach((element) => {
      element.style.height = '100%';
    });
  }, [view]);

  const Header = ({ date }) => {
    const monthName = capitalizeFirstLetter(moment(date).format("MMMM"));
    const year = moment(date).format("YYYY");
    return (
      <Box display={"flex"} justifyContent={"space-between"} marginBottom={1}>
        <Box display={"flex"} alignItems={"center"}>
          <IconButton
            onClick={() => calendarRef.current?.handleNavigate("PREV")}
          >
            <KeyboardArrowLeftIcon />
          </IconButton>
          <Typography
            fontSize={"18px"}
            fontWeight={600}
            width={"8rem"}
            display={"flex"}
            justifyContent={"center"}
          >
            {monthName} {year}
          </Typography>{" "}
          <IconButton
            onClick={() => calendarRef.current?.handleNavigate("NEXT")}
          >
            <KeyboardArrowRightIcon />
          </IconButton>
        </Box>
        <Box display={"flex"} alignItems={"center"}>
          {!isMobileDevice && <Typography marginRight={1}>{i18n.t('sprint.actionPlan.calendarView.viewBy')}</Typography>}
          <Select
            sx={{ width: isMobileDevice ? "100%" : "8rem"}}
            size="small"
            defaultValue={"month"}
            onChange={(e) =>{
              setView(e.target.value)
              calendarRef.current?.handleViewChange(e.target.value)
            }}
          >
            <MenuItem value={"month"}>{i18n.t('sprint.actionPlan.calendarView.month')}</MenuItem>
            <MenuItem value={"week"}>{i18n.t('sprint.actionPlan.calendarView.week')}</MenuItem>
          </Select>
        </Box>
      </Box>
    );
  };

  const components = useMemo(
    () => ({
      toolbar: Header,
    }),
    []
  );

  return (
    <Box  
      overflow={"auto"}
      maxHeight={"calc(100vh - 14.5rem)"}
      marginTop={2}
    >
      <Calendar
        ref={calendarRef}
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        style={{ height: view ==="month" ? "100vh" : "calc(100vh - 15rem)" }}
        views={["month", "week"]}
        eventPropGetter={eventStyleGetter}
        showAllEvents={true}
        components={components}
      />
    </Box>
  );
};

const mapStateToProps = (state) => ({
  shop: state.auth.shop,
  cycle: state.actions.cycle,
  actions: state.actions.actions,
});

export default connect(mapStateToProps, actions)(CalendarView);
