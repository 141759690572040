import { useCallback, useEffect, useState } from "react";
import { connect } from 'react-redux'
import * as Yup from 'yup';
import { Form, FormikProvider, useFormik } from 'formik';
import { toast } from 'react-toastify';

import { getViewAgency, handleAddAgency, handleUpdateAgency } from '../../store/actions/agencies'
import { getCustomers } from '../../store/actions/customers'
import { LoadingButton } from '@mui/lab';
import { Grid, TextField, Typography } from '@mui/material';
import { i18n } from '../../translate/i18n';
import UploadAvatar from "../../components/Upload/Avatar";
import { jsonToFormData } from "../../utils/String";
import { api } from "../../config";
import { FCAutocomplete, FCReturnButton, FCTextField } from "components-fullcomm";

const FormAgency = (props) => {
    const [isEdit, setIsEdit] = useState(false);
    const [agency, setAgency] = useState({});
    const [image, setImage] = useState('');
    const [spec, setSpec] = useState([]);
    const NewAgencySchema = Yup.object().shape({
        name: Yup.string().required(i18n.t('agency.form.socialRequired')),
        cnpj: Yup.string().required(i18n.t('agency.form.cnpjRequired')),
        customers: Yup.array()
            .of(Yup.object().shape({
                id: Yup.string().required(i18n.t('agency.form.clientIdRequired')),
                label: Yup.string().required('Nome do cliente é obrigatório.')
            }))
            .required(i18n.t('agency.form.storeRequired')),
        // monthlyCredits: Yup.number().required(i18n.t('agency.form.monthlyCreditRequired')),
        // startDate: Yup.date().required(i18n.t('agency.form.initDateRequired')),
        contactName: Yup.string(),
        contactEmail: Yup.string().email(i18n.t('agency.form.invalidEmail')),
        contactPhone: Yup.string(),
        contactFinancialName: Yup.string(),
        contactFinancialEmail: Yup.string().email(i18n.t('agency.form.invalidEmail')),
        contactFinancialPhone: Yup.string()
    });
    useEffect(() => {
        if (props.match.params.id) {
            setIsEdit(true);
            props.getViewAgency(props.match.params.id);
        }
        props.getCustomers(1, 1000)
    }, []);

    useEffect(() => {
        if (props.customers?.docs) setSpec(props.customers?.docs)
    }, [props.customers]);

    useEffect(() => {
        if (props.match.params.id) {
            setAgency(props.agency);
        }
    }, [props.agency]);
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: agency?.name || '',
            cnpj: agency?.cnpj || '',
            startDate: agency?.startDate?.slice(0, 10) || new Date().toDateString().slice(0, 10),
            monthlyCredits: agency?.monthlyCredits || '',
            contactName: agency?.contact?.name || '',
            contactEmail: agency?.contact?.email || '',
            contactPhone: agency?.contact?.phone || '',
            contactFinancialName: agency?.contactFinancial?.name || '',
            contactFinancialEmail: agency?.contactFinancial?.email || '',
            contactFinancialPhone: agency?.contactFinancial?.phone || '',
            img: agency?.fileLocation !== undefined ? agency.fileLocation : agency?.fileName !== undefined ? `${api}/files/agencies/${agency.fileName}` : null,
            customers: isEdit ? (agency?.customers?.map(elem => ({
                id: elem._id,
                label: elem.name
            })) || []) : []
        },
        validationSchema: NewAgencySchema,
        onSubmit: async (values, { setSubmitting, resetForm, setErrors }) => {
            try {
                if (props.user?.role !== 'admin' && props.match.params.id !== props.user?.agency?._id) {
                    toast.error(i18n.t('agency.form.registerError'));
                    return;
                }
                let auxValue = { ...values };
                if (isEdit) {
                    auxValue._id = props.match.params.id;
                }
                auxValue.customers = values.customers.map(elem => elem.id)
                auxValue.contact = {
                    name: values.contactName,
                    email: values.contactEmail,
                    phone: values.contactPhone
                }
                auxValue.contactFinancial = {
                    name: values.contactFinancialName,
                    email: values.contactFinancialEmail,
                    phone: values.contactFinancialPhone
                }
                delete auxValue.contactName;
                delete auxValue.contactEmail;
                delete auxValue.contactPhone;
                delete auxValue.contactFinancialName;
                delete auxValue.contactFinancialEmail;
                delete auxValue.contactFinancialPhone;

                const formData = jsonToFormData(auxValue);
                if (image) formData.append('image', image);

                let result = isEdit ? await props.updateAgency(formData) : await props.addAgency(formData);
                if (result.error) {
                    setSubmitting(false);
                    if (result.error.message.includes("As lojas")) {
                        result.error.message = result.error.message.replace("As lojas", i18n.t('agency.form.theStores'));
                        result.error.message = result.error.message.replace("já possuem agência!", i18n.t('agency.form.haveAgency'));
                    }

                    if (result.error.message.includes("A loja")) {
                        result.error.message = result.error.message.replace("A loja", i18n.t('agency.form.theStore'));
                        result.error.message = result.error.message.replace("já possui agência!", i18n.t('agency.form.haveAgency2'));
                    }
                    switch (result.error.message) {
                        case 'Erro ao cadastrar agência!': return i18n.t('agency.form.registerError');
                        case 'Agência não encontrada.': return i18n.t('agency.form.agencyNotFound');
                        case 'Ocorreu um erro ao atualizar os clientes associados à agência.': return i18n.t('agency.form.errorUpdateCustomer');
                        case 'Erro interno do servidor.': return i18n.t('agency.form.internalError');
                        default: return toast.error(result.error.message);;
                    }
                } else {
                    if (!isEdit) resetForm()
                    if (result.message === 'Agência cadastrada com sucesso!') {
                        toast.success(i18n.t('agency.form.registerSuccess'));
                    } else if (result.message === 'Agência atualizada com sucesso!') {
                        toast.success(i18n.t('agency.form.updateSuccess'));
                    } else {
                        toast.success(result.message);
                    }
                    setSubmitting(false);
                }
            } catch (error) {
                setSubmitting(false);
                if (error.error.message === 'Erro interno do servidor.') {
                    toast.error(i18n.t('agency.form.internalError'));
                } else {
                    toast.error(error.error.message);
                }
            }
        }
    });

    const { errors, touched, handleSubmit, isSubmitting, getFieldProps, values, setFieldValue } = formik;

    const handleDrop = useCallback(
        (acceptedFiles) => {
          const file = acceptedFiles[0];
          if (file) {
            setFieldValue('img', {
              ...file,
              preview: URL.createObjectURL(file)
            });
            setImage(file);
          }
        },
        [setFieldValue]
      );

    return (
        <div className="p-4">
            <div className="d-flex align-items-center mt-2 mr-auto p-2">
                <FCReturnButton margin={"0rem"}/>
                <Typography variant="h4">{isEdit ? i18n.t('agency.form.edit') : i18n.t('agency.form.register')} {i18n.t('agency.form.agency')}</Typography>
            </div>

            <FormikProvider value={formik}>
                <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                <UploadAvatar
                                    accept="image/*"
                                    file={values.img}
                                    maxSize={1048576}
                                    onDrop={handleDrop}
                                    error={Boolean(touched.img && errors.img)}
                                    caption={
                                      <Typography
                                        variant="caption"
                                        sx={{
                                          display: 'block',
                                          textAlign: 'center',
                                          color: 'text.secondary'
                                        }}
                                        style={{ marginTop: '5px' }}
                                      >
                                        {i18n.t('addCustomer.imageAllowed')} *.jpeg, *.jpg, *.png
                                      </Typography>
                                    }
                                />
                                </Grid>
                                <Grid item xs={12}>
                                    <FCTextField
                                        fullWidth
                                        required={true}
                                        label={i18n.t('agency.form.name')}
                                        {...getFieldProps('name')}
                                        error={Boolean(touched.name && errors.name)}
                                        helperText={touched.name && errors.name}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <FCTextField
                                        fullWidth
                                        required={true}
                                        label={i18n.t('agency.form.cnpj')}
                                        {...getFieldProps('cnpj')}
                                        error={Boolean(touched.cnpj && errors.cnpj)}
                                        helperText={touched.cnpj && errors.cnpj}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <FCAutocomplete
                                        label={i18n.t('agency.form.assocStores')}
                                        disabled={props.user?.role !== 'admin'}
                                        fullWidth
                                        {...getFieldProps('customers')}
                                        multiple
                                        required={true}
                                        value={values.customers}
                                        onChange={(event, newValue) => {
                                            setFieldValue('customers', newValue);
                                        }}
                                        options={spec.map(elem =>
                                        ({
                                            id: elem._id,
                                            label: elem.name
                                        }))}
                                        isOptionEqualToValue={(option, value) => option.id === value.id}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label={i18n.t('agency.form.assocStores')}
                                                placeholder={i18n.t('agency.form.assocStores')}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="h7">{i18n.t('agency.form.financContacts')}</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <FCTextField
                                        fullWidth
                                        label={i18n.t('agency.form.name')}
                                        {...getFieldProps('contactFinancialName')}
                                        error={Boolean(touched.contactFinancialName && errors.contactFinancialName)}
                                        helperText={touched.contactFinancialName && errors.contactFinancialName}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <FCTextField
                                        fullWidth
                                        label={i18n.t('agency.form.phone')}
                                        {...getFieldProps('contactFinancialPhone')}
                                        error={Boolean(touched.contactFinancialPhone && errors.contactFinancialPhone)}
                                        helperText={touched.contactFinancialPhone && errors.contactFinancialPhone}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <FCTextField
                                        fullWidth
                                        type='email'
                                        label={i18n.t('agency.form.email')}
                                        {...getFieldProps('contactFinancialEmail')}
                                        error={Boolean(touched.contactFinancialEmail && errors.contactFinancialEmail)}
                                        helperText={touched.contactFinancialEmail && errors.contactFinancialEmail}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="h7">{i18n.t('agency.form.respContact')}</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <FCTextField
                                        fullWidth
                                        label={i18n.t('agency.form.name')}
                                        {...getFieldProps('contactName')}
                                        error={Boolean(touched.contactName && errors.contactName)}
                                        helperText={touched.contactName && errors.contactName}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <FCTextField
                                        fullWidth
                                        label={i18n.t('agency.form.phone')}
                                        {...getFieldProps('contactPhone')}
                                        error={Boolean(touched.contactPhone && errors.contactPhone)}
                                        helperText={touched.contactPhone && errors.contactPhone}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <FCTextField
                                        fullWidth
                                        type='email'
                                        label={i18n.t('agency.form.email')}
                                        {...getFieldProps('contactEmail')}
                                        error={Boolean(touched.contactEmail && errors.contactEmail)}
                                        helperText={touched.contactEmail && errors.contactEmail}
                                    />
                                </Grid>
                                <Grid item xs={12} display='flex' gap='1rem' justifyContent='end'>
                                    <LoadingButton type="submit"
                                        onClick={() => window.history.back()}
                                        fullWidth
                                        // variant="contained"
                                        className="btn-white large" loading={isSubmitting}>{i18n.t('agency.form.cancel')}
                                    </LoadingButton>
                                    <LoadingButton type="submit"
                                        fullWidth
                                        className="btn-action large"
                                        variant="contained" loading={isSubmitting}>{i18n.t('agency.form.save')}
                                    </LoadingButton>
                                </Grid>
                            </Grid>
                </Form>
            </FormikProvider >
        </div>
    )
}



const mapStateToProps = state => {
    return ({
        user: state.auth.user,
        agency: state.agencies?.agencyDetailsView,
        customers: state.customer?.customers
    })
}

const mapDispatchToProps = (dispatch) => {
    return {
        addAgency: (data) => dispatch(handleAddAgency(data)),
        getViewAgency: (id) => dispatch(getViewAgency(id)),
        updateAgency: (data) => dispatch(handleUpdateAgency(data)),
        getCustomers: (page, limit) => dispatch(getCustomers(page, limit))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(FormAgency);