import { FormControl, FormHelperText, InputLabel, ListSubheader, MenuItem, Select, Typography } from '@mui/material';
import React from 'react'
import { isMobile } from 'react-device-detect';
import { i18n } from '../../../../translate/i18n';

const categories = [
  "Ativação",
  "Descoberta",
  "Consideração",
  "Prospecção",
  "Aquisição",
  "Conteúdo",
  "Rastreamento",
  "Benefício",
  "Engajamento",
  "Próxima Compra",
  "Contexto",
  "Recuperação",
  "Conversão",
  "Barreiras de Compra",
  "Ofertas",
  "Autoridade",
  "Retenção",
  "Análise de experiência",
  "Transmissão",
  "Segmentos",
];

export const handleCategory = (category) => {
  switch (category) {
    case "Ativação": return i18n.t('sprint.newAction.utils.categorySelect.activation'); 
    case "Descoberta": return i18n.t('sprint.newAction.utils.categorySelect.discovery');
    case "Consideração": return i18n.t('sprint.newAction.utils.categorySelect.consideration');
    case "Prospecção": return i18n.t('sprint.newAction.utils.categorySelect.prospection');
    case "Aquisição": return i18n.t('sprint.newAction.utils.categorySelect.acquisition');
    case "Conteúdo": return i18n.t('sprint.newAction.utils.categorySelect.content');
    case "Rastreamento": return i18n.t('sprint.newAction.utils.categorySelect.tracking');
    case "Benefício": return i18n.t('sprint.newAction.utils.categorySelect.benefit');
    case "Engajamento": return i18n.t('sprint.newAction.utils.categorySelect.engagement');
    case "Próxima Compra": return i18n.t('sprint.newAction.utils.categorySelect.nextPurchase');
    case "Contexto": return i18n.t('sprint.newAction.utils.categorySelect.context');
    case "Recuperação": return i18n.t('sprint.newAction.utils.categorySelect.recovery');
    case "Conversão": return i18n.t('sprint.newAction.utils.categorySelect.conversion');
    case "Barreiras de Compra": return i18n.t('sprint.newAction.utils.categorySelect.purchaseBarriers');
    case "Ofertas": return i18n.t('sprint.newAction.utils.categorySelect.offers');
    case "Autoridade": return i18n.t('sprint.newAction.utils.categorySelect.authority');
    case "Retenção": return i18n.t('sprint.newAction.utils.categorySelect.retention');
    case "Análise de experiência": return i18n.t('sprint.newAction.utils.categorySelect.experienceAnalysis');
    case "Transmissão": return i18n.t('sprint.newAction.utils.categorySelect.transmission');
    case "Segmentos": return i18n.t('sprint.newAction.utils.categorySelect.segments');
  default: return category
  }
}

const CategorySelect = ({ getFieldProps, touched, errors, disabled }) => {
  const dropDown = () => {
    let category;
    return categories.map((quadrant, i) => {
      if (i % 4 === 0) category = quadrant;
      return i % 4 === 0 ? (
        <ListSubheader key={quadrant}>{handleCategory(quadrant)}</ListSubheader>
      ) : (
        <MenuItem
          key={quadrant}
          sx={{ marginLeft: "0.5rem" }}
          value={JSON.stringify({ category, quadrant })}
        >
          {handleCategory(quadrant)}
        </MenuItem>
      );
    });
  };
  return (
    <FormControl
      error={Boolean(touched && errors)}
      fullWidth
      size={isMobile ? "small" : "medium"}
    >
      <Typography variant='body2' mb='0.5rem' color={Boolean(touched && errors) ? '#d32f2f' : '#000'}>{i18n.t('sprint.newAction.utils.categorySelect.category')}</Typography>
      <Select
        size={isMobile ? "small" : "medium"}
        disabled={disabled}
        {...getFieldProps("category")}
        renderValue={(selected) => {
          const { category, quadrant } = JSON.parse(selected);
          return `${handleCategory(category)} / ${handleCategory(quadrant)}`;
        }}
        sx={{
          '& fieldset': {
            border: `0.5px solid ${Boolean(touched && errors) ? '#d32f2f' : '#888888'} !important`,
            boxShadow: '0px 1px 0px 0px #00000040 inset !important',
            borderRadius: '8px !important',
          },
        }}
      >
        <option value="" />
        {dropDown()}
      </Select>
      {
        touched && errors &&
        <FormHelperText>{errors}</FormHelperText>
      }
    </FormControl>

  );
}

export default CategorySelect