import { useEffect, useState, useCallback } from "react";
import { connect } from 'react-redux'
import * as Yup from 'yup';
import { Form, FormikProvider, useFormik } from 'formik';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getViewCustomer, handleAddCustomer, handleUpdateCustomer } from '../../store/actions/customers'
import { LoadingButton } from '@mui/lab';
import { Box, Grid, Stack, Typography } from '@mui/material';
import UploadAvatar from '../../components/Upload/Avatar';
import { jsonToFormData } from '../../utils/String'
import { api } from '../../config';
import { UpdateAgencyCustomers, getAgencies } from "../../store/actions/agencies";
import axios from "axios";
import { getHeaders } from "../../store/actions/localStorage";
import { i18n } from '../../translate/i18n';
import { FCAutocomplete, FCReturnButton, FCSelect, FCTextField } from "components-fullcomm";

const AddCustomer = (props) => {
  const [isEdit, setIsEdit] = useState(false);
  const [customer, setCustomer] = useState({});
  const [specAgency, setSpecAgency] = useState([]);
  const [image, setImage] = useState('');
  const NewCustomerSchema = Yup.object().shape({
    name: Yup.string().required(i18n.t('addCustomer.nameRequired')),
    storeUrl: Yup.string().url(i18n.t('addCustomer.urlValid')).required(i18n.t('addCustomer.urlRequired')),
    jiraCode: Yup.string(),
    idJira: Yup.string(),
    idGA: Yup.string(),
    idFbPixel: Yup.string(),
    keyApiActiveCampaign: Yup.string(),
    resumoDeVendas: Yup.string().url(i18n.t('addCustomer.urlValid')),
    trafegoPago: Yup.string().url(i18n.t('addCustomer.urlValid')),
    CRM: Yup.string().url(i18n.t('addCustomer.urlValid')),
  });

  useEffect(() => {
    setSpecAgency(props.agencies?.docs)
  }, [props.agencies]);

  useEffect(() => {
    props.getAgencies(1, 1000)
    if (props.match.params.id) {
      setIsEdit(true);
      props.getViewCustomer(props.match.params.id);
    }
  }, []);

  useEffect(() => {
    if (props.match.params.id === props.customer?._id) {
      setCustomer(props.customer);
    }
  }, [props.customer]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: customer?.name || '',
      platform: customer?.platform || ['administrative'],
      resumoDeVendas: customer?.resumoDeVendas || '',
      trafegoPago: customer?.trafegoPago || '',
      CRM: customer?.CRM || '',
      storeUrl: customer?.storeUrl || '',
      jiraCode: customer?.jiraCode || '',
      agency: isEdit && customer?.agency ? customer.agency.map(el => { return { id: el._id, label: el.name } }) : [],
      idJira: customer?.idJira || '',
      idGA: customer?.idGA || '',
      platformEcommerce: customer?.platformEcommerce || 'shopify',
      platformDomain: customer?.platformDomain || '',
      idFbPixel: customer?.idFbPixel || '',
      keyApiActiveCampaign: customer?.keyApiActiveCampaign || '',
      img: customer?.fileLocation !== undefined ? customer.fileLocation : customer?.fileName !== undefined ? `${api}/files/customers/${customer.fileName}` : null,
      uptimeStatusId: customer?.uptimeStatusId || '',
      status: customer?.status || 'a',
      tokenPlatinum: customer?.tokenPlatinum || '',
      boteriaId: customer?.boteriaId || '',
    },
    validationSchema: NewCustomerSchema,
    onSubmit: async (values, { setSubmitting, resetForm, setErrors }) => {
      try {
        const auxValues = { ...values, agency: values.agency?.map(el => el.id) || [] };
        if (auxValues?.agency?.length === 0) delete auxValues.agency;
        if (isEdit) {
          auxValues._id = props.match.params.id;
        }
        if (auxValues.platformEcommerce === 'shopify' && (!auxValues.platformDomain || auxValues.platformDomain === "undefined")) {
          const storeUrl = auxValues.storeUrl.replace(/^https?:\/\//, "");
          try {
            const response = await axios.get(api + '/data-enrichment/' + storeUrl, getHeaders());
            auxValues.platformDomain = response.data?.platformDomain;
          } catch (error) {
          }
        }
        const formData = jsonToFormData(auxValues);
        if (image) formData.append('image', image);
        const result = isEdit ? await props.updateCustomer(formData) : await props.addCustomer(formData);
        props.UpdateAgencyCustomers({ id: auxValues.agency, customers: result.customer })
        if (result.error) {
          setSubmitting(false);
          switch (result.error.message) {
            case "Dados inválidos.": return toast.error(i18n.t('addCustomer.invalidData'));
            case "Cliente não foi cadastrado com sucesso!": return toast.error(i18n.t('addCustomer.registerError'));
            case "Erro interno do servidor!": return toast.error(i18n.t('addCustomer.internalError'));
            case "Cliente não encontrado.": return toast.error(i18n.t('addCustomer.customerNotFound'));
            case "Ocorreu um erro ao atualizar cliente.": return toast.error(i18n.t('addCustomer.updateError'));
            default: return toast.error(result.error.message);
          }

        } else {
          if (!isEdit) resetForm();
          if (result.message === "Cliente cadastrado com sucesso!") {
            toast.success(i18n.t('addCustomer.registerSuccess'))
          } else if (result.message === "Cliente atualizado com sucesso!") {
            toast.success(i18n.t('addCustomer.updateSuccess'))
          } else {
            toast.success(result.message);
          }
          setSubmitting(false);
        }
      } catch (error) {
        setSubmitting(false);
        if (error.error.message === 'Erro interno do servidor!') {
          toast.error(i18n.t('addCustomer.error'));
        } else {
          toast.error(error.error.message);
        }
      }
    }
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps, values, setFieldValue } = formik;


  const handleDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];
      if (file) {
        setFieldValue('img', {
          ...file,
          preview: URL.createObjectURL(file)
        });
        setImage(file);
      }
    },
    [setFieldValue]
  );

  return (
    <div className="p-4">
      <div className="d-flex">
        <div className="mr-auto p-2 d-flex align-items-center">
          <FCReturnButton />
          <Typography variant="h4">{isEdit ? i18n.t('addCustomer.edit') : i18n.t('addCustomer.register')} {i18n.t('addCustomer.store')}</Typography>
        </div>
      </div>

        <FormikProvider value={formik}>
          <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={6}>
                <Stack spacing={2.5}>
                  <UploadAvatar
                    accept="image/*"
                    file={values.img}
                    maxSize={1048576}
                    onDrop={handleDrop}
                    error={Boolean(touched.img && errors.img)}
                    caption={
                      <Typography
                        variant="caption"
                        sx={{
                          display: 'block',
                          textAlign: 'center',
                          color: 'text.secondary'
                        }}
                        style={{ marginTop: '5px' }}
                      >
                        {i18n.t('addCustomer.imageAllowed')} *.jpeg, *.jpg, *.png
                      </Typography>
                    }
                  />
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                    <FCTextField
                      fullWidth
                      label={i18n.t('addCustomer.name')}
                      required
                      {...getFieldProps('name')}
                      error={Boolean(touched.name && errors.name)}
                      helperText={touched.name && errors.name}
                    />
                  </Stack>

                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                    <FCTextField
                      fullWidth
                      required
                      label={i18n.t('addCustomer.urlStore')}
                      {...getFieldProps('storeUrl')}
                      error={Boolean(touched.storeUrl && errors.storeUrl)}
                      helperText={touched.storeUrl && errors.storeUrl}
                    />
                  </Stack>
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                    <FCSelect
                      fullWidth
                      label={i18n.t('addCustomer.platformFullcomm')}
                      requireds
                      multiple
                      {...getFieldProps('platform')}
                      error={Boolean(touched.platform && errors.platform)}
                      helperText={touched.platform && errors.platform}
                      options={[
                        { value: 'start', label: i18n.t('addCustomer.implantation') },
                        { value: 'sprint', label: i18n.t('addCustomer.performance') },
                        { value: 'scale', label: i18n.t('addCustomer.operation') },
                        { value: 'suporte', label: i18n.t('addCustomer.support') }
                      ]}
                    />
                  </Stack>
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                    <FCSelect
                      fullWidth
                      label={i18n.t('addCustomer.platformEcommerce')}
                      {...getFieldProps('platformEcommerce')}
                      options={[
                        { value: 'bigcommerce', label: 'BigCommerce' },
                        { value: 'ecwid', label: 'Ecwid' },
                        { value: 'magento', label: 'Magento' },
                        { value: 'miva', label: 'Miva' },
                        { value: 'opencart', label: 'OpenCart' },
                        { value: 'oscommerce', label: 'osCommerce' },
                        { value: 'prestashop', label: 'PrestaShop' },
                        { value: 'shopify', label: 'Shopify' },
                        { value: 'square', label: 'Square Online Store' },
                        { value: 'squarespace', label: 'Squarespace' },
                        { value: 'tray', label: 'Tray' },
                        { value: 'vtex', label: 'VTEX' },
                        { value: 'volusion', label: 'Volusion' },
                        { value: 'weebly', label: 'Weebly' },
                        { value: 'wix', label: 'Wix' },
                        { value: 'woocommerce', label: 'WooCommerce' },
                        { value: 'xcart', label: 'X-Cart' },
                        { value: 'yahoo', label: 'Yahoo! Merchant Solutions' },
                        { value: 'zencart', label: 'Zen Cart' },
                        { value: '3dcart', label: '3dcart' },
                        { value: 'outro', label: i18n.t('addCustomer.other') }
                      ]}
                    />
                  </Stack>
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                    <FCAutocomplete
                      fullWidth
                      {...getFieldProps('agency')}
                      multiple
                      value={values.agency}
                      onChange={(event, newValue) => {
                        setFieldValue('agency', newValue);
                      }}
                      options={specAgency?.map(elem =>
                      ({
                        id: elem._id,
                        label: elem.name
                      })) || []}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      label={i18n.t('addCustomer.agency')}
                      placeholder={i18n.t('addCustomer.agency')}
                    />
                  </Stack>
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                    <FCTextField
                      fullWidth
                      size="small"
                      label={i18n.t('addCustomer.urlSales')}
                      {...getFieldProps('resumoDeVendas')}
                      error={Boolean(touched.resumoDeVendas && errors.resumoDeVendas)}
                      helperText={touched.resumoDeVendas && errors.resumoDeVendas}
                    />
                  </Stack>
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                    <FCTextField
                      fullWidth
                      size="small"
                      label={i18n.t('addCustomer.urlTraffic')}
                      {...getFieldProps('trafegoPago')}
                      error={Boolean(touched.trafegoPago && errors.trafegoPago)}
                      helperText={touched.trafegoPago && errors.trafegoPago}
                    />
                  </Stack>
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                    <FCTextField
                      fullWidth
                      size="small"
                      label={i18n.t('addCustomer.urlCRM')}
                      {...getFieldProps('CRM')}
                      error={Boolean(touched.CRM && errors.CRM)}
                      helperText={touched.CRM && errors.CRM}
                    />
                  </Stack>
                </Stack>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Stack spacing={2.5}>
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                    <FCTextField
                      fullWidth
                      size="small"
                      label={i18n.t('addCustomer.keyJira')}
                      {...getFieldProps('jiraCode')}
                      error={Boolean(touched.jiraCode && errors.jiraCode)}
                      helperText={touched.jiraCode && errors.jiraCode}
                    />
                  </Stack>
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                    <FCTextField
                      fullWidth
                      size="small"
                      label={i18n.t('addCustomer.idGa')}
                      {...getFieldProps('idGA')}
                      error={Boolean(touched.idGA && errors.idGA)}
                      helperText={touched.idGA && errors.idGA}
                    />
                  </Stack>
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                    <FCTextField
                      fullWidth
                      size="small"
                      label={i18n.t('addCustomer.idFacebook')}
                      {...getFieldProps('idFbPixel')}
                      error={Boolean(touched.idFbPixel && errors.idFbPixel)}
                      helperText={touched.idFbPixel && errors.idFbPixel}
                    />
                  </Stack>
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                    <FCTextField
                      fullWidth
                      size="small"
                      label={i18n.t('addCustomer.keyActive')}
                      {...getFieldProps('keyApiActiveCampaign')}
                      error={Boolean(touched.keyApiActiveCampaign && errors.keyApiActiveCampaign)}
                      helperText={touched.keyApiActiveCampaign && errors.keyApiActiveCampaign}
                    />
                  </Stack>
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                    <FCTextField
                      fullWidth
                      size="small"
                      label={i18n.t('addCustomer.idUptime')}
                      {...getFieldProps('uptimeStatusId')}
                      error={Boolean(touched.uptimeStatusId && errors.uptimeStatusId)}
                      helperText={touched.uptimeStatusId && errors.uptimeStatusId}
                    />
                  </Stack>
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                    <FCTextField
                      fullWidth
                      size="small"
                      label={i18n.t('addCustomer.platformDomain')}
                      {...getFieldProps('platformDomain')}
                      error={Boolean(touched.platformDomain && errors.platformDomain)}
                      helperText={touched.platformDomain && errors.platformDomain}
                    />
                  </Stack>
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                    <FCTextField
                      fullWidth
                      size="small"
                      label='Bot ID Boteria'
                      {...getFieldProps('boteriaId')}
                    />
                  </Stack>
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                    <FCSelect
                      fullWidth
                      size="small"
                      label={"Status"}
                      {...getFieldProps('status')}
                      error={Boolean(touched.status && errors.status)}
                      helperText={touched.status && errors.status}
                      options={[
                        { value: 'a', label: 'Ativo' },
                        { value: 'd', label: 'Inativo' }
                      ]}
                    />
                  </Stack>
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                    <FCTextField
                      fullWidth
                      size="small"
                      label='Token Platinum'
                      {...getFieldProps('tokenPlatinum')}
                      error={Boolean(touched.tokenPlatinum && errors.tokenPlatinum)}
                      helperText={touched.tokenPlatinum && errors.tokenPlatinum}
                    />
                  </Stack>
                </Stack>
              </Grid>
            </Grid>
            <Box
              display='flex'
              justifyContent='end'
              gap='1rem'
              mt='2rem'
            >
              <Link to='/customer'>
                <LoadingButton
                  fullWidth
                  variant="contained"
                  className="btn-white large" loading={isSubmitting}>{i18n.t('addCustomer.cancel')}
                </LoadingButton>
              </Link>
              <LoadingButton type="submit"
                fullWidth
                className="btn-action large"
                variant="contained" loading={isSubmitting}>{i18n.t('addCustomer.save')
              }</LoadingButton>
            </Box>
            
          </Form>
        </FormikProvider>

    </div>


  )

};

const mapStateToProps = state => {
  return ({
    customer: state.customer.customerDetails,
    agencies: state.agencies.agencies,
  })
};

const mapDispatchToProps = (dispatch) => {
  return {
    addCustomer: (data) => dispatch(handleAddCustomer(data)),
    getViewCustomer: (id) => dispatch(getViewCustomer(id)),
    updateCustomer: (data) => dispatch(handleUpdateCustomer(data)),
    getAgencies: (page, limit) => dispatch(getAgencies(page, limit)),
    UpdateAgencyCustomers: (data) => dispatch(UpdateAgencyCustomers(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddCustomer);
