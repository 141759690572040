import React from 'react';
import {Button, Spinner} from 'reactstrap';
import { i18n } from '../../translate/i18n';

const SpinnerLogin = (props) => {
    if(props.loading) return(
        <Button type="button" className="btn btn-lg btn-block" disabled >{i18n.t('components.sprinnerLogin.wait')}<Spinner className="ml-1" size="sm" color="light"/></Button>
    )

    return (
        <Button type="button" className="btn btn-lg btn-block">{i18n.t('components.sprinnerLogin.enter')}</Button>
    )
}

export default SpinnerLogin;