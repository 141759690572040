import React from 'react'
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, CircularProgress, Divider, Grid, Paper, Typography } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CircleIcon from '@mui/icons-material/Circle';
import { i18n } from '../../../translate/i18n';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { queryString } from '../../../utils/String';
import { userPermissionCheck } from '../../Sprint/utils/userPermissionCheck';

const translate = (data) => {
  switch (data) {
    case 'SLOW':
      return {
        text: i18n.t('support.performance.webVitals.slow'),
        color: '#eb0f00'
      };
    case 'AVERAGE':
      return {
        text: i18n.t('support.performance.webVitals.average'),
        color: '#fa3'
      }
    case 'FAST':
      return {
        text: i18n.t('support.performance.webVitals.fast'),
        color: '#018642'
      }
    default:
      return {
        text: i18n.t('support.performance.webVitals.notRated'),
        color: '#9CA3AF'
      };
  }
}

const getColour = (data) => {
  if (data >= 90) {
    return '#018642';
  } else if (data >= 50) {
    return '#fa3';
  } else {
    return '#eb0f00';
  }
}

const info ={
  performance: i18n.t('support.performance.webVitals.valuesCanVariate'),
  accessibility:i18n.t('support.performance.webVitals.acessibility'),
  "best-practices": i18n.t('support.performance.webVitals.bestPractices'),
  seo: i18n.t('support.performance.webVitals.seo'),
}

const WebVitals = ({ loading, lightHouse, performanceData, audits, user }) => {
  const { metrics, overall_category } = performanceData;
  const history = useHistory();

  const pageRedirect = (name) => {
    const query = queryString({ name, type: "Melhoria" });
    history.push(`/support-new-action?${query}`);
  };

  return (
    <>
      {loading ? (
      <Box width={"100%"} display={"flex"} justifyContent={"center"}>
        <Box display={"flex"} flexDirection={"column"} alignItems={"center"} mt={"10rem"}>
          <Typography variant='h4' mb={"1rem"}>{i18n.t('support.performance.webVitals.searchInfos')}</Typography>
          <CircularProgress />
        </Box>
      </Box>
    ) : (
      <>
        <Typography variant='h5' mb={"1rem"}>{i18n.t('support.performance.webVitals.usersExperiencing')}</Typography>
        <Paper sx={{padding: "1rem"}}>
          <Typography variant='h6' align='center' mb={"1rem"}
          >
            {i18n.t('support.performance.webVitals.assessmentPoints')} <Typography variant='h6' component='span' fontWeight='bold' color={translate(overall_category)?.color}>{translate(overall_category)?.text}</Typography>
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <Typography variant='h7'
              >
                {i18n.t('support.performance.webVitals.firstContent')} <Typography variant='h7' component='span' color={translate(metrics?.FIRST_CONTENTFUL_PAINT_MS?.category)?.color}>{metrics?.FIRST_CONTENTFUL_PAINT_MS?.percentile ? `${metrics?.FIRST_CONTENTFUL_PAINT_MS?.percentile} ms` : ""} {translate(metrics?.FIRST_CONTENTFUL_PAINT_MS?.category)?.text}</Typography></Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography variant='h7'
              >
                {i18n.t('support.performance.webVitals.firstDelay')} <Typography variant='h7' component='span' color={translate(metrics?.FIRST_INPUT_DELAY_MS?.category)?.color}>{metrics?.FIRST_INPUT_DELAY_MS?.percentile ? `${metrics?.FIRST_INPUT_DELAY_MS?.percentile} ms` : ""} {translate(metrics?.FIRST_INPUT_DELAY_MS?.category)?.text}</Typography></Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography variant='h7'
              >
                {i18n.t('support.performance.webVitals.greaterContent')} <Typography variant='h7' component='span' color={translate(metrics?.LARGEST_CONTENTFUL_PAINT_MS?.category)?.color}>{metrics?.LARGEST_CONTENTFUL_PAINT_MS?.percentile ? `${metrics?.LARGEST_CONTENTFUL_PAINT_MS?.percentile} ms` : ""} {translate(metrics?.LARGEST_CONTENTFUL_PAINT_MS?.category)?.text}</Typography></Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography variant='h7'
              >
                {i18n.t('support.performance.webVitals.changeLayout')}  <Typography variant='h7' component='span' color={translate(metrics?.CUMULATIVE_LAYOUT_SHIFT_SCORE?.category)?.color}>{metrics?.CUMULATIVE_LAYOUT_SHIFT_SCORE?.percentile} {translate(metrics?.CUMULATIVE_LAYOUT_SHIFT_SCORE?.category)?.text}</Typography></Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography variant='h7'
              >
                {i18n.t('support.performance.webVitals.interactivityRendering')} <Typography variant='h7' component='span' color={translate(metrics?.INTERACTION_TO_NEXT_PAINT?.category)?.color}>{metrics?.INTERACTION_TO_NEXT_PAINT?.percentile ? `${metrics?.INTERACTION_TO_NEXT_PAINT?.percentile} ms` : ""} {translate(metrics?.INTERACTION_TO_NEXT_PAINT?.category)?.text}</Typography></Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography variant='h7'
              >
                {i18n.t('support.performance.webVitals.firstByte')} <Typography variant='h7' component='span' color={translate(metrics?.EXPERIMENTAL_TIME_TO_FIRST_BYTE?.category)?.color}>{metrics?.EXPERIMENTAL_TIME_TO_FIRST_BYTE?.percentile ? `${metrics?.EXPERIMENTAL_TIME_TO_FIRST_BYTE?.percentile} ms` : ""} {translate(metrics?.EXPERIMENTAL_TIME_TO_FIRST_BYTE?.category)?.text}</Typography></Typography>
            </Grid>
          </Grid>
        </Paper>
        {
          lightHouse && (
            <>
              <Typography variant='h5' mb={"1rem"} mt={"2rem"}>{i18n.t('support.performance.webVitals.generalObservations')}</Typography>
              <Paper sx={{padding: "1rem"}}>
                <Box display={"flex"} gap={"5%"} justifyContent={"center"} mb={"2rem"}>
                  {Object.keys(lightHouse).map((key) => {
                    const score = lightHouse[key].score * 100;
                    const title = lightHouse[key].title;
                    const color = getColour(score);
                    return (
                      (
                        <Box key={key} display={"flex"} flexDirection={"column"} justifyContent={"center"} alignItems={"center"}>
                          <Box position={"relative"} mb={"0.5rem"}>
                            <CircularProgress
                              variant="determinate"
                              value={100}
                              sx={{ color: "#e0e0e0", position: "absolute" }}
                              size={50}
                            />
                            <CircularProgress sx={{color}} variant="determinate" size={50} value={score} />
                            <Box position={"absolute"} display={"flex"} alignItems={"center"} justifyContent={"center"} height={"100%"} width={"100%"} top={0}>
                              <Typography variant='body2'  sx={{color}} >{score?.toString()?.match(/[^.]+/)}%</Typography>
                            </Box>
                          </Box>
                          <Typography variant='caption' align='center' mb={"1rem"}>{title}</Typography>
                        </Box>
                    )
                    )
                  })}
                </Box>
                <Divider />
                <Box display={"flex"} flexDirection={"column"} alignItems={"center"} paddingTop={"2rem"}>
                  {
                    Object.keys(lightHouse).map((key) => {
                      const score = lightHouse[key].score * 100;
                      const color = getColour(score);
                      return (
                        <Box mb={"2rem"}>
                          <Typography variant='h6'>{i18n.t('support.performance.webVitals.diagnosisOf')} {lightHouse[key].title}:</Typography>
                          <Box display="flex" alignItems={"center"} justifyContent={"space-between"} padding={"2rem"}>
                           <Box position={"relative"} mb={"0.5rem"} display={"flex"} justifyContent={"center"} width={"50%"}>
                            <CircularProgress
                              variant="determinate"
                              value={100}
                              sx={{ color: "#e0e0e0", position: "absolute" }}
                              size={100}
                              thickness={3}
                            />
                            <CircularProgress sx={{color}} variant="determinate" size={100} thickness={3} value={score} />
                            <Box position={"absolute"} display={"flex"} alignItems={"center"} justifyContent={"center"} height={"100%"} width={"100%"} top={0}>
                              <Typography variant='h3'  sx={{color}}>{score}%</Typography>
                            </Box>
                            </Box>
                            <Divider orientation='vertical' flexItem />
                            <Typography textAlign={"center"}  mb={"1rem"} width={"75%"} minWidth={"200px"}>{info[key]}</Typography>
                          </Box>
                          {
                            Object.keys(audits).sort((a, b) => audits[a].score - audits[b].score).map((auditKey) => {
                              const data = Object.keys(lightHouse[key].auditRefs).map((el) => {
                                return lightHouse[key].auditRefs[el].id;
                              })
                              const id = audits[auditKey].id;
                              if (data.includes(id) && !["main-thread-tasks", "diagnostics", "network-requests", "metrics", "screenshot-thumbnails", "final-screenshot", "mainthread-work-breakdown", "bootup-time", "script-treemap-data"].includes(id)) {
                                const score = audits[auditKey].score * 100;
                                const description = audits[auditKey].description?.replace(/\[(.*?)\]\((.*?)\)/g, '<a href="$2">$1</a>');
                                const displayValue = audits[auditKey].displayValue;
                                const title = audits[auditKey].title;
                                const color = getColour(score);
                                return (
                                  <>
                                    <Box 
                                      key={auditKey} 
                                      display={"flex"} 
                                      flexDirection={"column"} 
                                      justifyContent={"center"} 
                                      alignItems={"center"} 
                                      width={"100%"}
                                      borderBottom={"1px solid #E5E7EB"}
                                    >
                                      <Accordion sx={{width: "100%"}} elevation={0}>
                                        <AccordionSummary
                                          expandIcon={<ExpandMoreIcon />}
                                          aria-controls="panel1a-content"
                                          id="panel1a-header"
                                        >
                                          <Box display={"flex"} justifyContent={"space-between"} width={"100%"}>
                                            <Typography variant='h7' fontWeight={400}><CircleIcon sx={{color, height: "16px", opacity: 0.70}}/>{title}</Typography>
                                            <Typography variant='body2'sx={{color}} >{displayValue}</Typography>
                                          </Box>
                                        </AccordionSummary>
                                        <AccordionDetails sx={{paddingLeft: "3rem"}}>
                                          <div dangerouslySetInnerHTML={{__html: description}} />
                                          {
                                            userPermissionCheck(user, ["create_action"]) && (
                                              <Button variant='contained' className='btn-action' sx={{mt: "1rem"}} onClick={()=> pageRedirect(title)}>{i18n.t('support.performance.webVitals.fix')}</Button>
                                            )
                                          }
                                          {/* {
                                            audits[auditKey].details?.items && Object.keys(audits[auditKey].details?.items)?.map((item) => {
                                              console.log(title, audits[auditKey].details.items);
                                              return (
                                                <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
                                                  <Typography variant='body2' fontWeight={400}>{audits[auditKey].details[item]?.node.nodeLabel}</Typography>
                                                  // <Typography variant='body2' fontWeight={400}>{audits[auditKey].details[item].description}</Typography>
                                                </Box>
                                              )
                                            }
                                            )
                                          } */}
                                        </AccordionDetails>
                                      </Accordion>
                                    </Box>
                                  </>
                                )
                              }
                              return null;
                            })
                          }
                          <Divider />
                        </Box>
                      )})
                  }
                </Box>
              </Paper>
            </>
          )
        }
      </>
    )}
    </>
  )
  
}

export default WebVitals