import React from 'react'
import { i18n } from '../../translate/i18n';
import { FCChip } from 'components-fullcomm';

const ChipProject = ({status, style}) => {
    let label;
    let color;

    switch (status) {
        case "form":
            label = i18n.t('components.chipProject.quiz');
            color = "warning";
            break;
        case "waiting_dev":
            label = i18n.t('components.chipProject.waitingDevelopment');
            color = "primary";
            break;
        case "waiting_price":
            label = i18n.t('components.chipProject.waitingBudget');
            color = "secondary";
            break;
        case "development":
            label = i18n.t('components.chipProject.development');
            color = "error";
            break;
        case "waiting_customer":
            label = i18n.t('components.chipProject.waitingCustomer');
            color = "info";
            break;
        case "concluded":
            label = i18n.t('components.chipProject.done');
            color = "success";
            break;
        default:
            label = i18n.t('components.chipProject.unknown');
            color = "warning";
            break;
    }

    return (
        <FCChip style={style} label={label} color={color} />
    );
}

export default ChipProject;