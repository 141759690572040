import React, { useCallback, useEffect, useState } from 'react';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { Link, useHistory } from 'react-router-dom';
import { Modal, ModalBody } from 'reactstrap';
import { connect } from 'react-redux'
import * as actions from '../../store/actions/users'
import { Table, TableBody, TableCell, TableRow, Button, Box, Typography, Grid } from '@mui/material';
import { roles } from "../../config"
import { toast } from 'react-toastify';
import { userPermissionCheck } from '../Sprint/utils/userPermissionCheck';
import { isMobile } from 'react-device-detect';
import { debounce } from 'lodash';
import { i18n } from '../../translate/i18n';
import { LoadingButton } from '@mui/lab';
import { FCDropdownButton, FCPagination, FCSelect, FCTableContainer, FCTableHeader, FCTextField } from 'components-fullcomm';

const User = (props) => {
    const history = useHistory();
    const [pageCurrent, setPageCurrent] = useState(1);
    const limit = 10;
    const [loading, setLoading] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [idDelete, setIdDelete] = useState("");
    const [role, setRole] = useState("all");
    const [search, setSearch] = useState("");

    const debouncedGetUsers = useCallback(
        debounce(async (data) => await props.getUsers({ ...data }), 500),
        []
    );

    useEffect(() => {
        debouncedGetUsers({ page: pageCurrent, limit, role, name: search });
    }, [role, search, pageCurrent]);

    const deleteUser = async () => {
        setLoading(true)
        try {
            const result = await props.deleteUser(idDelete)
            await props.getUsers(pageCurrent, limit)
            if (result.error) {
                switch (result.error.message) {
                    case "Usuário não existe!": return toast.error(i18n.t('user.userNotExist'));
                    case "Erro ao excluir!": return toast.error(i18n.t('user.deleteError'));
                    default: return toast.error(i18n.t('user.internalError'));
                }
            } else {
                if (result.message === "Usuário apagado com sucesso!") {
                    toast.success(i18n.t('user.deleteSuccess'));
                } else {
                    toast.success(result.message);
                }
            }
        } catch (error) {
            if (error.error.message === "Erro interno do servidor.") {
                toast.error(i18n.t('user.internalError'));
            } else {
                toast.error(error.error.message);
            }
        }
        setLoading(false)
        toggleModal();
    }

    const toggleModal = (_id = "") => {
        if (_id) {
            setIdDelete(_id)
        } else {
            setIdDelete("")
        }
        setOpenModal(!openModal);
    }

    let users = [];

    if (props.users) {
        users = props.users.docs
    }

    const handleFunction = (role) => {
        switch (role) {
            case "Administrador": return i18n.t('user.admin');
            case "Cliente": return i18n.t('user.customer');
            case "Agência": return i18n.t('user.agency');
            default: return role;
        }
    }


    return (
        <div style={{ padding: isMobile ? '16px 16px 66px 16px' : '16px' }} className={!isMobile & "p-4"} >
            <div className="d-flex justify-content-between align-items-center">
                <div className="mr-auto p-2 ">
                    <Typography variant="h4">{i18n.t('user.users')}</Typography>
                </div>
                {
                    userPermissionCheck(props.user, ["create_user"]) &&
                    <Link to={"/add-user"}>
                        <Button className="btn-action medium btn-sm">{i18n.t('user.register')}</Button>
                    </Link>
                }
            </div>
            <br />
            <Box display="flex" gap="1rem" alignItems="end" mb="1rem">
                <FCSelect
                    label={i18n.t('user.function')}
                    value={role}
                    sx={{ width: isMobile ? '44vw' : '290px'}}
                    onChange={(e) => setRole(e.target.value)}
                    options={[
                        { value: "all", label: i18n.t('user.all') },
                        { value: "admin", label: i18n.t('user.admin') },
                        { value: "customer", label: i18n.t('user.customer') },
                        { value: "agency", label: i18n.t('user.agency') }
                    ]}
                />
                <FCTextField
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    placeholder={i18n.t('user.search')}
                    sx={{ width: isMobile ? '44vw' : '290px'}}
                    searchIcon={true}
                />
            </Box>
            {
                isMobile ? <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    borderRadius: '4px',
                    border: '1px solid #D1D5DB',
                }}
            >
                {users?.map((row, i) => (
                    <Grid
                        container
                        key={row._id}
                        width={'100%'}
                        borderTop={i !== 0 ? '1px solid #D1D5DB' : ''}
                        paddingY='1rem'
                        paddingX='1rem'
                        display={'flex'}
                        justifyContent={'space-between'}
                    >
                        <Grid item xs={8}>
                            <Typography variant="subtitle2">{i18n.t('user.id')}</Typography>
                            <Typography variant="body2">{row._id}</Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography variant="subtitle2">{i18n.t('user.function2')}</Typography>
                            <Typography variant="body2">{handleFunction(roles.get(row.role)) || "-"}</Typography>
                        </Grid>
                        <Grid item xs={1}>
                            <FCDropdownButton
                                sx={{
                                    color: '#888888'
                                }}
                                items={[
                                    userPermissionCheck(props.user, ["edit_user"]) ? i18n.t('user.edit') : '',
                                    userPermissionCheck(props.user, ["delete_user"]) ? i18n.t('user.delete') : '',
                                    userPermissionCheck(props.user, ["edit_user_permissions"]) ? i18n.t('user.permissions') : ''
                                ]}
                                itemClickHandlers={[
                                    () => history.push("/update-user/" + row._id),
                                    () => toggleModal(row._id),
                                    row.role === 'admin' ? () => history.push("/permissions/" + row._id) : () => history.push("/permissions/customer/" + row._id)
                                ]}
                            />
                        </Grid>
                        <Grid item xs={6} paddingTop='0.5rem'>
                            <Typography variant="subtitle2">{i18n.t('user.name')}</Typography>
                            <Typography variant="body2">{row.name}</Typography>
                        </Grid>
                        <Grid item xs={6} paddingTop='0.5rem'>
                            <Typography variant="subtitle2">{i18n.t('user.email')}</Typography>
                            <Typography 
                                variant="body2"
                                sx={{
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis'
                                }}
                            >{row.email}</Typography>
                        </Grid>

                    </Grid>
                ))}
            </Box> 
            : 
            (
                <FCTableContainer>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <FCTableHeader>
                            <TableRow>
                                <TableCell width='10%' sx={{ minWidth: '215px'}}>{i18n.t('user.id')}</TableCell>
                                <TableCell width='40%'>{i18n.t('user.name')}</TableCell>
                                <TableCell width='35%'>{i18n.t('user.email')}</TableCell>
                                <TableCell width='10%'>{i18n.t('user.function2')}</TableCell>
                                <TableCell width='5%'></TableCell>
                            </TableRow>
                        </FCTableHeader>
                        <TableBody>
                            {users.map(row => (
                                <TableRow
                                    key={row._id}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell>
                                        {row._id}
                                    </TableCell>
                                    <TableCell>{row.name}</TableCell>
                                    <TableCell>{row.email}</TableCell>
                                    <TableCell>{handleFunction(roles.get(row.role)) || "-"}</TableCell>
                                    <TableCell align="right">
                                        {
                                            userPermissionCheck(props.user, ["edit_user", "delete_user", "edit_user_permissions"]) && (
                                                <FCDropdownButton
                                                    sx={{
                                                        color: '#888888'
                                                    }}
                                                    items={[
                                                        userPermissionCheck(props.user, ["edit_user"]) ? i18n.t('user.edit') : '',
                                                        userPermissionCheck(props.user, ["delete_user"]) ? i18n.t('user.delete') : '',
                                                        userPermissionCheck(props.user, ["edit_user_permissions"]) ? i18n.t('user.permissions') : ''
                                                    ]}
                                                    itemClickHandlers={[
                                                        () => history.push("/update-user/" + row._id),
                                                        () => toggleModal(row._id),
                                                        row.role === 'admin' ? () => history.push("/permissions/" + row._id) : () => history.push("/permissions/customer/" + row._id)
                                                    ]}
                                                />
                                            )
                                        }
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </FCTableContainer>
            )}

            
            <br />
            {!props.users ? "" :
                <FCPagination
                    sx={{
                        mb: '1rem',
                    }}
                    count={props.users.totalPages} page={pageCurrent} onChange={(e, page) => setPageCurrent(page)}
                />
            }

            <Modal isOpen={openModal} >
                <Box display='flex' justifyContent='space-between' padding='1rem' toggle={() => toggleModal()}>
                    <Typography variant='h7'>{i18n.t('user.deleteUser')}</Typography>
                    <CloseOutlinedIcon
                      sx={{
                        color: "#86888c",
                        cursor: "pointer",
                        float: 'top'
                      }}
                      onClick={() => toggleModal()}
                    />
                </Box>
                <ModalBody>
                    {i18n.t('user.confirmDelete')}
                </ModalBody>
                <Box display='flex' gap='1rem' justifyContent='end' padding='1rem' alignItems='center'>
                    <LoadingButton
                        className='btn-reprove medium'
                        loading={loading}
                        onClick={() => deleteUser()}
                        variant="contained"
                    >
                        {i18n.t('user.delete')}
                    </LoadingButton>
                    <Button className="btn-white medium" variant='contained' onClick={() => toggleModal()}>{i18n.t('user.cancel')}</Button>
                </Box>
            </Modal>
        </div>
    )
}


const mapStateToProps = state => ({
    users: state.user.users,
    user: state.auth.user
})

export default connect(mapStateToProps, actions)(User);

