const checklists_reduce = (state = {}, actions) => {
    switch(actions.type){
        case "GET_CHECKLISTS":
            return{
                ...state,
                checklists: actions.payload.checklists
            }
        case "GET_CHECKLIST":
            return{
                ...state,
                checklistDetails: actions.payload.checklist
            }
        case "CLEAR_CHECKLIST":
            return{
                ...state,
                checklistDetails: null
            }
        case "CLEAR_CHECKLISTS":
            return{
                ...state,
                checklists: null
            }
        default: 
            return state;
    }
}

export default checklists_reduce;