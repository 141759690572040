export const channels = [
  "Google Pesquisa",
  "Bing Pesquisa",
  "Youtube",
  "Max Performance",
  "Facebook",
  "Twitter",
  "Instagram",
  "Tik Tok",
  "Busca Orgânica",
  "Taboola",
  "Referral",
  "Display",
  "Pinterest",
  "Email",
  "Social",
  "Direct",
  "SMS",
];

export const mainMetrics = [
  "Sessões",
  "Taxa de Conversão",
  "Ticket Médio",
  "Receita",
  "Receita de Novos Clientes",
  "Receita de Clientes Antigos",
  "Receita Recuperada",
  "Customer Lifetime Value",
  "NPS",
  "Uso do Cupom",
];

export const metrics = {
  "Google Pesquisa": [
    "CTR",
    "CPC",
    "CPA",
    "Taxa de Conversão",
    "ROAS",
    "CPL",
    "Sessões",
    "Tráfego Busca Paga",
    "Ticket Médio",
    "Receita Canal Busca Paga",
    "Lista de Público Google Ads",
    "ROAS Google Ads",
  ],
  "Bing Pesquisa": [
    "CTR",
    "CPC",
    "CPA",
    "Taxa de Conversão",
    "ROAS",
    "CPL",
    "Sessões",
    "Tráfego Busca Paga",
    "Ticket Médio",
    "Receita Canal Busca Paga",
    "Taxa de Conversão Busca Paga",
  ],
  Youtube: [
    "N° Total de Assinantes",
    "Vídeo View",
    "Engajamento",
    "Taxa de Visualização",
    "CPC",
    "Sessões",
    "Taxa de Conversão",
    "Ticket Médio",
    "Lista de Público Google Ads",
    "ROAS Google Ads",
    "Receita de Novos Clientes",
  ],
  "Max Performance": [
    "CTR",
    "CPC",
    "CPA",
    "Taxa de Conversão",
    "ROAS",
    "Sessões",
    "Ticket Médio",
    "ROAS Google Ads",
    "Receita de Novos Clientes",
  ],
  Facebook: [
    "CTR",
    "CPC",
    "CPA",
    "Taxa de Conversão",
    "ROAS",
    "CPL",
    "Sessões",
    "Taxa de Engajamento",
    "Táfego Redes Sociais",
    "Novos Seguidores",
    "Leads Adquiridos",
    "Ticket Médio",
    "Taxa de Abandono de Carrinho",
    "Receita Canal Redes Sociais",
    "Taxa de Conversão Canal Redes Sociais",
    "Lista de Público Facebook Ads",
    "ROAS Facebook Ads",
    "Receita de Novos Clientes",
  ],
  Twitter: [
    "CTR",
    "CPC",
    "CPA",
    "Taxa de Conversão",
    "ROAS",
    "CPL",
    "Sessões",
    "Taxa de Engajamento",
    "Táfego Redes Sociais",
    "Novos Seguidores",
    "Ticket Médio",
    "Taxa de Abandono de Carrinho",
    "Receita Canal Redes Sociais",
    "Taxa de Conversão Canal Redes Sociais",
    "Receita Novos Clientes",
  ],
  Instagram: [
    "CTR",
    "CPC",
    "CPA",
    "Taxa de Conversão",
    "ROAS",
    "CPL",
    "Sessões",
    "Taxa de Engajamento",
    "Táfego Redes Sociais",
    "Novos Seguidores",
    "Ticket Médio",
    "Taxa de Abandono de Carrinho",
    "Receita Canal Redes Sociais",
    "Lista de Público Facebook Ads",
    "ROAS Facebook Ads",
    "Receita de Novos Clientes",
  ],
  "Tik Tok": [
    "CTR",
    "CPC",
    "CPA",
    "Taxa de Conversão",
    "ROAS",
    "CPL",
    "Sessões",
    "Taxa de Engajamento",
    "Táfego Redes Sociais",
    "Novos Seguidores",
    "Ticket Médio",
    "Taxa de Abandono de Carrinho",
    "Receita Canal Redes Sociais",
    "Taxa de Conversão Canal Redes Sociais",
    "Lista de Público Tik Tok Ads",
    "Receita Novos Clientes",
  ],
  "Busca Orgânica": [
    "Sessões",
    "Duração Média da Sessão",
    "Taxa de Conversão",
    "Páginas/Sessão",
    "Valor da Página",
    "Tráfego Orgânico",
    "Ticket Médio",
    "Receita Canal Orgânico",
    "Taxa de Conversão Canal Orgânico",
  ],
  Taboola: [
    "CTR",
    "CPC",
    "CPA",
    "Taxa de Conversão",
    "ROAS",
    "CPL",
    "Sessões",
    "Taxa de Engajamento",
    "Ticket Médio",
    "Receita Novos Clientes",
  ],
  Referral: [
    "CTR",
    "CPC",
    "CPA",
    "Taxa de Conversão",
    "Sessões",
    "Tráfego Referência",
    "Tempo de Sessão",
    "Páginas por Sessão",
    "Taxa de Rejeição",
    "Ticket Médio",
    "Receita Canal Referência",
    "Taxa de Conversão Canal Referência",
  ],
  Display: [
    "Sessões",
    "Impressões",
    "Cliques",
    "CTR",
    "CPC",
    "Tráfego Display",
    "Tempo de Sessão",
    "Páginas por Sessão",
    "Taxa de Rejeição",
    "Ticket Médio",
    "Receita Canal Display",
    "Taxa de Conversão Canal Display",
    "ROAS Google Ads",
  ],
  Pinterest: [
    "CTR",
    "CPC",
    "CPA",
    "Taxa de Conversão",
    "ROAS",
    "CPL",
    "Sessões",
    "Taxa de Engajamento",
    "Táfego Redes Sociais",
    "Novos Seguidores",
    "Ticket Médio",
    "Taxa de Abandono de Carrinho",
    "Receita Canal Redes Sociais",
    "Taxa de Conversão Canal Redes Sociais",
    "Receita de Novos Clientes",
  ],
  Email: [
    "Taxa de Abertura",
    "CTR",
    "CTOR",
    "Taxa de Conversão",
    "Tráfego de E-mail",
    "Leads Adquiridos",
    "Ticket Médio",
    "Taxa de Abandono de Carrinho",
    "Receita Canal E-mail",
    "Taxa de Conversão Canal E-mail",
    "Receita Recuperada",
    "Customer Lifetime Value",
    "NPS",
    "Uso do Cupom",
  ],
  Social: [
    "Alcance",
    "Engajamento",
    "Seguidores",
    "Taxa de Conversão",
    "Ticket Médio",
    "Receita Novos Clientes",
    "Receita Clientes Antigos",
  ],
  SMS: [
    "Taxa de Abertura",
    "Taxa de Conversão",
    "Recuperação de Carrinho",
    "NPS",
  ],
  Direct: [
    "Sessões",
    "Taxa de Conversão",
    "Tráfego Direto",
    "Tempo de Sessão",
    "Páginas por Sessão",
    "Taxa de Rejeição",
    "Ticket Médio",
    "Receita Canal Direto",
    "Taxa de Conversão Canal Direto",
    "Receita de Clientes Antigos",
  ],
};
