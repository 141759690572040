const goals_reducer = (state = {}, actions) => {
  switch(actions.type){
      case 'GET_GOALS':
          return{
              ...state,
              goals: actions.payload
          }
      default: 
          return state;
  }
}

export default goals_reducer;