import React, { useState, useEffect } from 'react';
import { Container, Card, Button, Box, Grid, AppBar, Toolbar, IconButton, Typography } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import LogoCheck from '../../assets/check.png'
import LogoTotvs from '../../assets/logo-totvs.png'

const TotvsEcommerceBrasil = () => {
    const [links, setLinks] = useState([]);
    const [selectedUrl, setSelectedUrl] = useState(null);
    const [content, setContent] = useState('');

    useEffect(() => {
        const fetchContent = async () => {
            const response = await fetch(`http://localhost:8000/proxy?url=${encodeURIComponent(selectedUrl)}`);
            const html = await response.text();
            setContent(html);
        };

        fetchContent();
    }, [selectedUrl]);

    useEffect(() => {
        const fetchData = async () => {
            const response = await fetch('https://api.sheety.co/5ceb49c1727fbba794fa18e581b5d36e/demoTotvsForumEcbr/list');
            const data = await response.json();
            setLinks(data.list);
        };
        fetchData();
    }, []);

    return (
        <>
            <link rel="preconnect" href="https://fonts.googleapis.com" />
            <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
            <link href="https://fonts.googleapis.com/css2?family=Lexend+Deca:wght@100..900&display=swap" rel="stylesheet"></link>
            <AppBar position="sticky">
                <Toolbar sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    {selectedUrl && (
                        <IconButton
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            sx={{ position: 'absolute', left: 10 }}
                            onClick={() => setSelectedUrl(null)}
                        >
                            <ArrowBackIosIcon />
                        </IconButton>
                    )}
                    <Typography variant="h6" component="div">
                        <img src={LogoTotvs} width={'130px'} style={{ marginRight: '5px' }} /> <small style={{ fontFamily: "Lexend Deca" }}>by</small> <img src={LogoCheck} width={'230px'} style={{ marginLeft: '5px' }} />
                    </Typography>
                </Toolbar>
            </AppBar>
            <Container maxWidth={'xl'}>
                {selectedUrl ? (
                    <Box>
                        <div dangerouslySetInnerHTML={{ __html: content }} />
                    </Box>
                ) : (
                    <>
                        <br />
                        <Grid container spacing={2}>
                            {links.map((link) => (
                                <Grid item xs={4} key={link.id}>
                                    <Card style={{ display: 'flex', flexDirection: 'column', height: '100%', borderRadius: '10px', border: '1px solid #00E029', fontFamily: "Lexend Deca" }} onClick={() => {
                                        // setSelectedUrl(link.url);
                                        const width = window.innerWidth * 0.9;
                                        const height = window.innerHeight * 0.9;
                                        const left = (window.innerWidth - width) / 2;
                                        const top = (window.innerHeight - height) / 2;
                                        window.open(link.url, '_blank', `width=${width},height=${height},left=${left},top=${top}`);
                                    }}>
                                        <Box style={{ flexGrow: 1, padding: '10px' }}>
                                            <h4>{link.nomeDoRecurso}</h4>
                                            <small>{link.descrição}</small>
                                        </Box>
                                    </Card>
                                </Grid>
                            ))}
                        </Grid>
                    </>
                )}

                <br />
            </Container>
        </>
    );
};

export default TotvsEcommerceBrasil;
