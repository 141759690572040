import { Typography, Paper, Grid, Button, Collapse, Box } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import * as actions from "../../../../store/actions/action";
import { getViewCustomer } from "../../../../store/actions/customers";
import Status from "./Status";
import Score from "./Score";
import Name from "./Name";
import Responsible from "./Responsible";
import { debounce } from 'lodash';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import "../../../../styles/dashboard/sprint/index.css";
import Campaign from "./Campaign";
import { i18n } from "../../../../translate/i18n";

const options = [
  "Ativo",
  "Backlog",
  "Em andamento",
  "Concluído",
  "Para fazer",
  "Cancelado",
  "Planejamento",
];

const Filter = (props) => {
  const { platform = ["sprint"] } = props;
  const filterParams = JSON.parse(localStorage.getItem('filterParams'));
  const [showFilters, setShowFilters] = useState(false);
  const [name, setName] = useState(props.type !== "List" ? "" :  filterParams?.name ? filterParams?.name : "");
  const [status, setStatus] = useState(filterParams?.status?.split(",") ? filterParams?.status?.split(",") : props.type !== "List" ? ["Ativo", "Em andamento"] : options);
  const [category, setCategory] = useState([]);
  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");
  const [score, setScore] = useState(props.type !== "List" ? [0, 1000] : filterParams?.score ? filterParams?.score : [0, 1000]);
  const [responsible, setResponsible] = useState(props.type !== "List" ? getResponsible() : filterParams?.responsible?.split(",") ? filterParams?.responsible?.split(",") : getResponsible());
  const [filterReset, setFilterReset] = useState(false);
  const [campaign, setCampaign] = useState("");
  const [isFirstRender, setIsFirstRender] = useState(true);

  function getResponsible() {
    return ['FullPRO', props.customer?.name, ...(props.customer?.agency || []).map(agency => agency.name)]
  }

  const resetFilters = () => {
    setCampaign("");
    setResponsible(['FullPRO', props.customer?.name, ...(props.customer?.agency || []).map(agency => agency.name)]);
    setName("");
    setStatus(options);
    setCategory([]);
    setScore([0, 1000]);
    setStart("");
    setEnd("");
    setFilterReset(!filterReset);
  };

  useEffect(() => {
    const shop = localStorage.getItem('shop');
    props.getCampaigns();
    props.getViewCustomer(shop);
  }, [props.shop]);

  const filterActions = useCallback(debounce((page = 1) => {
    if (props.cycle === "") return;
    const filter = {
      name,
      type: props.typeFilter || '',
      score: score,
      status: status.join(",") || ' ',
      responsible: responsible.length ? responsible.join(",") : '',
      campaign: props.campaigns?.find(elem => elem.name === campaign)?._id || '',
      cycle: props.cycle,
      start: start ? start.toISOString().slice(0, 10) : "",
      end: end ? end.toISOString().slice(0, 10) : "",
      page: page,
      limit: props.type === "Strategy" ? 9999 : 10,
      sortDirection: props.sortDirection || 1,
    };

    const params = new URLSearchParams((isFirstRender && filterParams && props.type === "List") ? filterParams : filter);

    params.set("platform", platform[0]);
    if (platform[0] === "suporte") {
      params.delete("cycle");
    }
    
    if (props.type === "List") {
      params.delete("type");
      params.set("limit", 10);
      setIsFirstRender(false);
    }
    localStorage.setItem('filterParams', JSON.stringify(filter));
    props.getActions(params);
  }, 500), [
    props.sortDirection,
    props.currentPage,
    score,
    props.type,
    campaign,
    status,
    props.cycle,
    category,
    name,
    start,
    end,
    responsible,
  ]);

  const filterActionsModel = useCallback(debounce((page = 1) => {
    if (props.cycle === "") return;
    const params = new URLSearchParams({
      page: page,
      limit: props.type === "Strategy" ? 9999 : 10,
      sortDirection: props.sortDirection || 1,
    });
    props.getActions(params);
  }, 500), [
    props.sortDirection,
    props.currentPage,
  ]);

  useEffect(() => {
    const handleKeypress = (event) => {
      if (event.key === "Enter") {
        filterActions();
      }
    }
    window.addEventListener('keypress', handleKeypress);

    return () => {
        window.removeEventListener('keypress', handleKeypress);
    };
  }, [    
    props.sortDirection,
    props.currentPage,
    score,
    props.type,
    campaign,
    status,
    props.cycle,
    category,
    name,
    start,
    end,
    responsible,
  ]); 

  useEffect(() => {
    if (props.customer?.model) {
      filterActionsModel(props.currentPage);
    } else {
      filterActions(props.currentPage);
    }

  }, [props.cycle, filterReset, props.currentPage, props.shop, props.sortDirection, props.customer]);


  const filterType = (type) => {
    switch (type) {
      case "List":
        return (
          <>
            <Grid
              item
              xs={12}
              sm={6}
              display="flex"
              alignItems="center"
              flexWrap="wrap"
              gap={1}
            >
              <Status status={status} setStatus={setStatus} />
            </Grid>
            <Grid item xs={12} sm={6} display={"flex"} gap={1}>
              <Name name={name} setName={setName} />
            </Grid>
            <Grid item xs={12} sm={6} display={"flex"} alignItems={"center"} gap={1}>
              <Responsible
                customer={props.customer}
                responsible={responsible}
                setResponsible={setResponsible}
              />
            </Grid>
            <Grid item xs={12} sm={6} display={"flex"} alignItems={"center"} gap={1}>
              <Score score={score} setScore={setScore} />
            </Grid>
          </>
        );
      default:
        return (
          <>
            <Grid
              item
              xs={12}
              sm={6}
              display="flex"
              alignItems="center"
              flexWrap="wrap"
              gap={1}
            >
              <Status status={status} setStatus={setStatus} />
            </Grid>
            <Grid item xs={12} sm={6} display={"flex"} gap={1}>
              <Campaign campaigns={props.campaigns} campaign={campaign} setCampaign={setCampaign}/>
            </Grid>
            <Grid item xs={12} sm={6} display={"flex"} alignItems={"center"} gap={1}>
              <Responsible
                customer={props.customer}
                responsible={responsible}
                setResponsible={setResponsible}
              />
            </Grid>
            <Grid item xs={12} sm={6} display={"flex"} alignItems={"center"} gap={1}>
              <Score score={score} setScore={setScore} />
            </Grid>
          </>
        );
    }
  };

  return (
    <Box marginBottom={1} width="100%">
      <Button
        color="default"
        variant="contained"
        onClick={() => setShowFilters(!showFilters)}
        sx={{
          color: "#202223",
          background: "#FFFFFF",
          fontWeight: "400",
          border: "1px solid #babfc3",
          textTransform: "none",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        {i18n.t('sprint.utils.filter.filter')} {showFilters ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </Button>
      <Grid item xs={12}>
        <Collapse in={showFilters}>
          <Paper
            className="filter-container"
            sx={{
              marginTop: "0.5rem",
              paddingX: "2rem",
              paddingY: "1rem",
              borderRadius: "10px",
            }}
          >
            <Grid container columnSpacing={4} rowSpacing={2}>
              <Grid item xs={12}>
                <Typography variant="h6">{i18n.t('sprint.utils.filter.filterBy')}</Typography>
              </Grid>
              {filterType(props.type)}
              <Grid item xs={12} display={"flex"} alignItems={"center"} gap={1}>
                <Button
                  variant="contained"
                  className="btn-action"
                  sx={{
                    height: '36px'
                  }}
                  onClick={()=>filterActions()}
                >
                  {i18n.t('sprint.utils.filter.apply')}
                </Button>
                <Button 
                  className="btn-white" 
                  onClick={resetFilters}
                  sx={{
                    height: '36px'
                  }}
                >
                {i18n.t('sprint.utils.filter.resetFilters')}
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Collapse>
      </Grid>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  shop: state.auth.shop,
  actions: state.actions.actions,
  cycle: state.actions.cycle,
  customer: state.customer.customerDetails,
  campaigns: state.actions.campaigns,
});

export default connect(mapStateToProps, { ...actions, getViewCustomer })(Filter);
