const ai_contents_reducer = (state = {}, aiContent) => {
  switch(aiContent.type){
      case "GET_AICONTENT":
          return{
              ...state,
              aiContent: aiContent.payload.aiContent
          }
      default: 
          return state;
  }
}

export default ai_contents_reducer;