import React from 'react'
import { queryString } from '../../../../utils/String';
import { Box, CardActionArea, Divider, Grid, Icon, IconButton, Paper, Typography } from '@mui/material';
import HandshakeOutlinedIcon from '@mui/icons-material/HandshakeOutlined';
import TrendingUpOutlinedIcon from '@mui/icons-material/TrendingUpOutlined';
import ApprovalOutlinedIcon from '@mui/icons-material/ApprovalOutlined';
import { categories } from '../../utils/objects';
import { handleChangeCategory } from '../../../../translate/translates/Translates';
import { handleChannel } from '../pages/Analysis';
import { handleType } from '../pages/Revision';
import { i18n } from '../../../../translate/i18n';
import { formatText } from '../../utils/formatText';

const ModelCard = ({ model, history }) => {
  const icon = categories.find((obj) => obj.name === model.category)?.icon;

  const activityCategories = model.activities?.reduce((acc, activity) => {
    return acc.split(", ").includes(activity.activity?.category) ? acc : `${acc}, ${activity.activity?.category}`;
  }, "").substring(1);


  const handleCategories = (categories) => {
    const arrayTrim = categories.trim()
    const arrayCategories = arrayTrim.split(", ")

    const newArrayCategory = arrayCategories.map((category) => {
      const stringCategory = `${category}`
      const result = handleChangeCategory(stringCategory)
      return result
    })

    const joinCategories = newArrayCategory.join(", ")

    return joinCategories
  }

  return (
    <Paper sx={{ borderRadius: "8px", marginBottom: "2px" }}>
      <CardActionArea
        key={model._id}
        sx={{ borderRadius: "8px", padding: "1rem" }}
        onClick={() => {
          const query = queryString(model);
          history.push(`/new-action/${model._id}`);
        }}
      >
        <Grid container>
          <Grid item xs={12}>
            <Box display={"flex"} gap={1} alignItems={"center"}>
              <Icon fontSize="small">
                {icon}
              </Icon>
              <Typography variant="h6">{model.language?.[localStorage.getItem('i18nextLng')]?.name}</Typography>
            </Box>
          </Grid>
          <Grid item xs={12} paddingX={"1rem"} marginBottom={"1rem"}>

            <Typography variant="caption">          
              <div dangerouslySetInnerHTML={{ __html: formatText(model.language?.[localStorage.getItem('i18nextLng')]?.description) }} />
            </Typography>
          </Grid>
          <Grid item xs={4} paddingX={"1rem"}>
            <Typography variant="caption"><b>{i18n.t('sprint.newAction.actionFloatingButton.type')}</b> {handleType(model.type)}</Typography>
          </Grid>
          <Grid item xs={8} paddingX={"1rem"}>
            <Typography variant="caption"><b>{i18n.t('sprint.newAction.actionFloatingButton.principalChannel')}</b> {handleChannel(model.channel)}</Typography>
          </Grid>
          <Grid item xs={4} paddingX={"1rem"}>
            <Typography variant="caption"><b>{i18n.t('sprint.newAction.actionFloatingButton.credits')}</b> {model.credits}</Typography>
          </Grid>
          <Grid item xs={8} paddingX={"1rem"}>
            <Typography variant="caption"><b>{i18n.t('sprint.newAction.actionFloatingButton.activitiesChannel')}</b> {handleCategories(activityCategories)}</Typography>
          </Grid>
          <Grid item xs={12} paddingX={"1rem"}>
            <Divider sx={{ marginY: "5px" }} />
            <Box display={"flex"} gap={2}>
              <Typography variant="caption"><HandshakeOutlinedIcon fontSize='12px' /> <b>{i18n.t('sprint.newAction.actionFloatingButton.impact')}</b> {model.impact}</Typography>
              <Typography variant="caption"><TrendingUpOutlinedIcon fontSize='12px' /> <b>{i18n.t('sprint.newAction.actionFloatingButton.trust')}</b> {model.trust}</Typography>
              <Typography variant="caption"><ApprovalOutlinedIcon fontSize='12px' /> <b>{i18n.t('sprint.newAction.actionFloatingButton.facility')}</b> {model.ease}</Typography>
            </Box>
          </Grid>
        </Grid>
      </CardActionArea>
    </Paper>
  )
}

export default ModelCard