import { Box, Button, Paper, Table, TableBody, TableCell, TableRow, Typography } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import { i18n } from '../../translate/i18n'
import { userPermissionCheck } from '../../pages/Sprint/utils/userPermissionCheck';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import * as actions from '../../store/actions/documents';
import dayjs from 'dayjs';
import { queryString } from '../../utils/String';
import debounce from 'lodash.debounce';
import { Modal, ModalBody } from 'reactstrap';
import { toast } from 'react-toastify';
import { handleDocumentType } from '../../translate/translates/Translates';
import { FCDropdownButton, FCModalFooter, FCModalHeader, FCPagination, FCSelect, FCTableContainer, FCTableHeader, FCTextField } from 'components-fullcomm';

const Documents = (props) => {
  const platform = window.location.pathname?.split('/')?.[2] || 'start';
  const history = useHistory();
  const [type, setType] = useState('Todos');
  const [search, setSearch] = useState('');
  const [pageCurrent, setPageCurrent] = useState(1);
  const [openModal, setOpenModal] = useState(null);
  const [loading, setLoading] = useState(false);

  const deleteDocument = async (id) => {
    setLoading(true)
    const result = await props.deleteDocument(id);
    await debouncedSearch({page: pageCurrent, search, type, platform});
    if (result.error) {
      switch (result.error.message) {
        case "Dados inválidos.": return toast.error(i18n.t('document.form.invalidData'));
        case "Documento não encontrado.": return toast.error(i18n.t('document.form.documentNotExist'));
        case "Erro interno do servidor.": return toast.error(i18n.t('document.form.internalError'));
        default: return toast.error(result.error.message);
      }
    } else {
      toast.success(i18n.t('document.form.deleteSuccess'));
      setOpenModal(null);
    }
    setLoading(false);
  };

  const debouncedSearch = useCallback(
    debounce(async (query) => {
      const parsed = queryString(query)
      props.getDocuments(parsed)
    }, 500),
    []
);
  useEffect(() => {
    debouncedSearch({page: pageCurrent, search, type, platform});
  }, [pageCurrent, type, search, props.shop, platform]);

  return (
    <Box className="dashboard">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h4">{i18n.t('documents.index.title')}</Typography>
        {
          userPermissionCheck(props.user, ["create_document"]) && 
            <Button variant="contained" className='btn-action medium' onClick={()=> history.push(`/new-document/${platform}`)}>{i18n.t('documents.index.new')}</Button>
        }
      </Box>
      <br />
      <Box display="flex" gap='1rem' alignItems="center" mb='1rem'>
        <FCTextField value={search} label={i18n.t('documents.index.search')} onChange={e => setSearch(e.target.value)} />
        <FCSelect
          value={type}
          label={i18n.t('documents.index.type')}
          onChange={e => setType(e.target.value)}
          options={[
            { value: "Todos", label: i18n.t('documents.form.all') },
            { value: "Nota de reunião", label: i18n.t('documents.form.meetingNote') },
            { value: "Requisitos de projeto", label: i18n.t('documents.form.projectRequirements') },
            { value: "Requisitos operacionais", label: i18n.t('documents.form.operationalRequirements') },
            { value: "Relatório", label: i18n.t('documents.form.report') },
            { value: "Outros", label: i18n.t('documents.form.other') },
          ]}
        />
      </Box>
      <FCTableContainer component={Paper}>
    <Table sx={{ minWidth: 650 }}>
        <FCTableHeader>
            <TableRow>
                <TableCell>{i18n.t('documents.index.name')}</TableCell>
                <TableCell>{i18n.t('documents.index.date')}</TableCell>
                <TableCell>{i18n.t('documents.index.type')}</TableCell>
                <TableCell>Url</TableCell>
                <TableCell></TableCell>
            </TableRow>
        </FCTableHeader>
        <TableBody>
            {props.documents?.docs?.map((row) => (
                <TableRow
                    key={row._id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                    <TableCell sx={{ maxWidth: 150, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                        {row.name}
                    </TableCell>
                    <TableCell sx={{ maxWidth: 50}}>{dayjs(row.date).format('DD/MM/YYYY')}</TableCell>
                    <TableCell sx={{maxWidth: 75}}>{handleDocumentType(row.type)}</TableCell>
                    <TableCell sx={{ maxWidth: 200, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                        <a href={row.url} target="_blank">
                            {row.url}
                        </a>
                    </TableCell>
                    <TableCell align="right">
                        {userPermissionCheck(props.user, ['delete_document', 'edit_document']) && (
                            <FCDropdownButton
                                items={[
                                    userPermissionCheck(props.user, ['edit_document'])
                                        ? i18n.t('documents.index.edit')
                                        : null,
                                    userPermissionCheck(props.user, ['delete_document'])
                                        ? i18n.t('documents.index.delete')
                                        : null,
                                ]}
                                itemClickHandlers={[
                                    () => history.push(`/edit-document/${row._id}`),
                                    () => setOpenModal(row._id),
                                ]}
                            />
                        )}
                    </TableCell>
                </TableRow>
            ))}
        </TableBody>
    </Table>
</FCTableContainer>

      <br />
      <Box display="flex" justifyContent="center" alignItems="center">
        <FCPagination count={props.documents?.totalPages} page={pageCurrent} onChange={(e, page) => setPageCurrent(page)} />
      </Box>
      <Modal centered isOpen={openModal} >
        <FCModalHeader onClick={() => setOpenModal(null)} title={i18n.t('documents.index.deleteDocument')}/>
        <ModalBody>
            {i18n.t('documents.index.confirmDelete')}
        </ModalBody>
        <FCModalFooter
          actionLabel={i18n.t('documents.index.delete')}
          handleAction={() => deleteDocument(openModal)}
          cancelLabel={i18n.t('documents.index.cancel')}
          handleCancel={() => setOpenModal(null)}
        />
      </Modal>
    </Box>
  )
}

const mapStateToProps = state => ({ 
  user: state.auth.user,
  documents: state.documents.documents,
  shop: state.auth.shop,
 })

export default connect(mapStateToProps, actions)(Documents)