import React from 'react'
import { Route } from 'react-router-dom';

import HourTracking from '../pages/Support/HourTracking';
import ProblemsIncidents from '../pages/Support/ProblemsIncidents';
import Performance from '../pages/Support/Performance';
import Kanban from '../pages/Sprint/Kanban';
import ReprovedActivity from '../pages/Sprint/Kanban/ReprovedActivity';
import ActionList from '../components/ActionList';
import V2NewAction from '../pages/Sprint/V2NewAction';

const Support = ({ baseDashboard }) => {
  return (
    <>
      <Route path="/hour-tracking" exact component={baseDashboard({ Component: HourTracking, permissions: ['admin', 'customer', 'agency'], platform: ['suporte'], action: 'view_problem' })} />
      <Route path="/problems-incidents" exact component={baseDashboard({ Component: ProblemsIncidents, permissions: ['admin', 'customer', 'agency'], platform: ['suporte'], action: 'view_problem' })} />
      <Route path="/performance" exact component={baseDashboard({ Component: Performance, permissions: ['admin', 'customer', 'agency'], platform: ['suporte'] })} />
      <Route path="/support-performance" exact component={baseDashboard({ Component: Kanban, permissions: ['admin', 'customer', 'agency'], platform: ['suporte'], action: 'view_problem' })} />
      <Route path="/support-performance/:id" exact component={baseDashboard({ Component: Kanban, permissions: ['admin', 'customer', 'agency'], platform: ['suporte'], action: 'view_problem' })} />
      <Route path="/support-performance/reproved-problem/:id" exact component={baseDashboard({ Component: ReprovedActivity, permissions: ['admin'], platform: ['suporte'], action: 'view_problem'})} />
      <Route path="/support-action-list" exact component={baseDashboard({ Component: ActionList, permissions: ['admin', 'customer', 'agency'], platform: ['suporte'], action: 'view_action'})} />
      <Route path="/support-new-action" exact component={baseDashboard({ Component: V2NewAction, permissions: ['admin', 'customer', 'agency'], platform: ['suporte'], action: 'create_action'})} />
      <Route path="/support-edit-action/:id" exact component={baseDashboard({ Component: V2NewAction, permissions: ['admin', 'customer', 'agency'], platform: ['suporte'], action: 'view_action'})} />
    </>
    )
}

export default Support