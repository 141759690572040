const insight_reducer = (state = {}, action) => {
    switch (action.type) {
        case "FETCH_TOKEN":
            return {
                ...state,
                tokenApi: action.payload,
            }
        case "FETCH_PRODUCTS":
            return {
                ...state,
                products: action.payload,
            }
        case "FETCH_LOCATIONS":
            return {
                ...state,
                locations: action.payload,
            }
        case "FETCH_INVENTORY_LEVEL":
            return {
                ...state,
                inventoryLevel: action.payload,
            }
        case "FETCH_INVENTORY_LEVEL_TYPES":
            return {
                ...state,
                inventoryLevelTypes: action.payload,
            }
        case "FETCH_CURVE_ABC":
            return {
                ...state,
                curveABC: action.payload,
            }
        case "FETCH_ORDERS":
            return {
                ...state,
                orders: action.payload,
            }
        case "FETCH_PRODUCTS_INFO":
            return {
                ...state,
                productsInfo: action.payload,
            }
        case "FETCH_PREVIOUS_PERIOD_PRODUCTS_INFO":
            return {
                ...state,
                previousPeriodProductsInfo: action.payload,
            }
        case "FETCH_ORDERS_INFO":
            return {
                ...state,
                ordersInfo: action.payload,
        }
        default:
            return state;
    }
}

export default insight_reducer;