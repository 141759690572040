import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux'

import { getCustomers, getViewCustomer } from '../../store/actions/customers'
import { Link, useLocation } from 'react-router-dom'
import logo from '../../assets/logo-fullcomm-horizontal.svg'
import GridViewOutlinedIcon from '@mui/icons-material/GridViewOutlined';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import { MenuItem, ListItemIcon, ListItemText, MenuList, FormControl, InputLabel, Select, Divider, Avatar, Badge } from '@mui/material';
import Sprint from './Sprint';
import Start from './Start';
import Admin from './Admin';
import { repository } from '../../config';
import { handleLogout } from '../../store/actions';
import { getNoReadMessages, getNoReadMessagesCustomer } from '../../store/actions/messages';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import Scale from './Scale';
import Support from './Support';
import Apps from './Apps';
import AiContent from './AiContent';
import { i18n } from '../../translate/i18n';
import { userPermissionCheck } from '../../pages/Sprint/utils/userPermissionCheck';


const MenuLink = ({ to, children, Icon, badge = 0, onClick }) => {
    const location = useLocation();
    const invisible = badge ? false : true;
    return (
        <MenuItem onClick={onClick} className="li" component={Link} to={to}>
            <ListItemIcon>
                <Icon />
            </ListItemIcon>
            <ListItemText style={location.pathname.startsWith(to) ? { color: '#00D6CF', fontWeight: '600' } : {}} disableTypography={true} sx={{ fontSize: '14px' }} primary={children} />
            <Badge badgeContent={badge} invisible={invisible} color="error" sx={{zIndex: -1}}/>
        </MenuItem>
    );
};
const Sidebar = (props) => {
    const history = useHistory();
    const [shopDetails, setShopDetails] = useState(props.user?.customer?.find(e => e._id === props.shop) || {});
    const [activitiesNotifications, setActivitiesNotifications] = useState(props.notifications?.activitiesNotifications || 0);
    const [checklistNotifications, setChecklistNotifications] = useState(props.user?.role === "customer" ? props.notifications?.checklistNotifications?.customer || 0 : 0);
    const [problemNotifications, setProblemNotifications] = useState(props.notifications?.problemNotifications || 0);
    useEffect(() => {
        props.user?.role === "admin" ? props.getNoReadMessages() : props.getNoReadMessagesCustomer();
    }, [history.location.pathname, props.shop, props.user]);

    const handleScroll = () => {
      localStorage.setItem('scrollPosition', document.getElementById('sidebar')?.scrollTop);
    };
  
    useEffect(() => {
        const sidebar = document.getElementById('sidebar');
        if (sidebar) sidebar.scrollTop = localStorage.getItem('scrollPosition') || 0;
    }, [history]);


    const [messages, setMessages] = useState(0)
    function calculateTotalSum(data) {
        let sum = 0;
        for (let key in data) {
            if (props.user?.customer?.some(e => e._id === key) || props.user?.allCustomer) {
                if (data.hasOwnProperty(key)) {
                    sum += data[key].total || 0;
                  }
            }
        }
        return sum;
      }

    useEffect(() => {
        if (props.user?.role === "admin") setMessages(calculateTotalSum(props.noReadMessages));
        else setMessages(props.noReadMessages?.total || 0);
    }, [props.noReadMessages]);

    useEffect(() => {
        if (props.user?.role === "admin") {
            props.getCustomers(1, 5555);
        }
    }, [props.user]);

    useEffect(() => {
        setProblemNotifications(props.notifications?.problemNotifications || 0)
        setActivitiesNotifications(props.notifications?.activitiesNotifications || 0)
        if (props.user?.role === "admin") {
            setChecklistNotifications(props.notifications?.checklistNotifications?.admin || 0)
        } else {
            setChecklistNotifications(props.notifications?.checklistNotifications?.customer || 0)
        }
    }, [props.notifications]);


    useEffect(() => {
        setShopDetails(props.customers.find(e => e._id === props.shop) || props.user?.customer?.find(e => e._id === props.shop) || {});
    }, [props.shop, props.customers]);

    if (!props.user) return <></>;
    return (
        <>
        <nav id='sidebar' className={props.active ? "sidebar" : "sidebar toggled"} onScroll={handleScroll}>
            <div className='sidebar-header'>
                <div className='logo-container'>
                    <img src={logo} className="logo" />
                    <a href="https://checkstore.atlassian.net/wiki/spaces/CHANGELOG/pages/2616623132/v1+Changelog+Plataforma+Home" target="_blank" rel="noreferrer" className="chip-status">
                    v 1.20.0
                    </a>
                </div>
                <FormControl fullWidth>
                    <InputLabel sx={{ color: 'white' }}>{i18n.t('components.sideBar.store')}</InputLabel>
                    <Select className='sidebar-select' onChange={(e) => props.setShop(e.target.value)} value={props.shop} sx={{ backgroundColor: 'rgba(255, 255, 255, 0.04)', color: 'white' }} label={i18n.t('components.sideBar.store')}>
                        {(props.user?.role === "customer" || props.user?.role === "agency") && props.user?.customer.map(item => <MenuItem key={item._id} value={item._id} sx={{ gap: "5px" }}><Avatar className='sidebar-avatar' src={item.fileLocation ? item.fileLocation : `${repository}/files/customers/${item.fileName}`} /> {item.name}</MenuItem>)}
                        {props.user?.role === "admin" && props.customers.map(item => <MenuItem key={item._id} value={item._id} sx={{ gap: "5px" }}><Avatar className='sidebar-avatar' src={item.fileLocation ? item.fileLocation : `${repository}/files/customers/${item.fileName}`} />{item.name}</MenuItem>)}
                    </Select >
                </FormControl >
            </div >
            <MenuList style={{ paddingBottom: '50px' }} className="ul">
                <div style={{marginBottom: "10px"}}>
                    <MenuLink to="/dashboard" Icon={GridViewOutlinedIcon}>{i18n.t('components.sideBar.dashboard')}</MenuLink>
                    <MenuLink badge={messages} to={props.user?.role === 'admin' ? "/chat-adm?platform=platform" : "/chat/platform"} Icon={ChatBubbleIcon}>{i18n.t('components.sideBar.messages')}</MenuLink> 
                </div>
                {/* START */}
                {
                    (((props.user?.role === 'customer' || props.user?.role === 'agency') && shopDetails?.platform?.includes('start') || ["admin", "manager"].includes(props.user?.role))) &&
                    <div>
                        <Divider />
                        <Start MenuLink={MenuLink} shopDetails={shopDetails} user={props.user} />

                    </div>
                }
                {/* SPRINT */}
                {
                    (((props.user?.role === 'customer' || props.user?.role === 'agency') && shopDetails?.platform?.includes('sprint') || ["admin", "manager"].includes(props.user?.role))) &&
                    <div>
                        <Divider />
                        <Sprint 
                            checklistNotifications={checklistNotifications} 
                            activitiesNotifications={activitiesNotifications} 
                            MenuLink={MenuLink} 
                            shop={props.shop}
                            getViewCustomer={props.getViewCustomer}
                            customer={props.customer}
                            user={props.user} />
                    </div>
                }
                {/* SCALE */}
                {
                    shopDetails?.platform?.includes('scale') &&
                    <div>
                        <Divider />
                        <Scale MenuLink={MenuLink} user={props.user} shopDetails={props.customers.find(e => e._id === props.shop) || shopDetails} />
                    </div>
                }
                {/* SUPORTE */}
                {
                    (shopDetails?.platform?.includes('suporte') || ["admin", "manager"].includes(props.user?.role)) &&
                    <div>
                        <Divider />
                        <Support MenuLink={MenuLink} user={props.user} problemNotifications={problemNotifications}/>
                    </div>
                }
                {
                    (props.user?.role === 'admin' && props.user?.permissionAdmin?.includes("view_app")) &&
                    <div>
                        <Divider />
                        <Apps MenuLink={MenuLink} user={props.user} />
                    </div>
                }
                {/* Criativos */}
                {
                    (userPermissionCheck(props.user, ["view_aicontent"])) &&
                    <div>
                        <Divider />
                        <AiContent MenuLink={MenuLink} user={props.user} />
                    </div>
                }
                {/* Settings */}
                <div>
                    <Divider />
                    <Admin MenuLink={MenuLink} user={props.user} handleLogout={handleLogout} />
                </div>
            </MenuList >

        </nav >
            {/* <MenuList className={props.active ? "bottom-menu" : "bottom-menu toggled"} sx={{bottom: 0}}> */}
            {/* </MenuList> */}
        </>
    )
}


const mapStateToProps = state => {
    return ({
        user: state.auth.user,
        customers: state.customer?.customersSidebar?.docs || [],
        customer: state.customer.customerDetails,
        noReadMessages: state.message.noReadMessages || [],
        messages: state.message.messages || [],
        notifications: state.notifications.notifications,
        shop: state.auth.shop,
    })
}

const mapDispatchToProps = (dispatch) => {
    return {
        getNoReadMessages: () => dispatch(getNoReadMessages()),
        getNoReadMessagesCustomer: () => dispatch(getNoReadMessagesCustomer()),
        getCustomers: (page, limit, type) => dispatch(getCustomers(page, limit, type)),
        getViewCustomer: (id) => dispatch(getViewCustomer(id)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);