import React, { useState, useEffect } from "react";
import { makeStyles } from '@mui/styles';

import { Grid, Divider, TextField, Accordion, FormControl, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, AccordionSummary, AccordionDetails, Stack, Button, Link, Typography, Autocomplete, List, ListItem, ListItemText } from '@mui/material';
import { connect } from 'react-redux'
import ChipProject from '../../../components/ChipProject'
import { getViewProject, handleUpdateProject, handleUpdateStatusTask, updateProp } from '../../../store/actions/projects'
import { getTasks } from '../../../store/actions/tasks'
import { toast } from 'react-toastify';
import KanbanBoard from '../../../components/Kanban/KanbanBoard';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import moment from 'moment';
import { api } from '../../../config';
import { isMobile } from "react-device-detect";
import { LoadingButton } from "@mui/lab";
import { i18n } from '../../../translate/i18n';

const  checkForUrl = (url) => {
    const urlParts = url.split('/');
    return urlParts[0] === 'https:';
}

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    paper: {
        padding: theme.spacing(2),
        margin: 'auto',
    },
    title: {
        margin: theme.spacing(2),
        textAlign: 'center',
    },
}));

function ViewProject(props) {
    const [loading, setLoading] = useState(false);
    const [tasks, setTasks] = useState([]);
    const [tasksProject, setTasksProject] = useState([]);
    const [project, setProject] = useState({});
    const [file, setFile] = useState(null);
    const [file2, setFile2] = useState(null);
    const classes = useStyles();

    useEffect(() => {
        const { id } = props.match.params
        props.getTasks();
        props.getViewProject(id);
    }, []);

    const dataForm = project && project?.data?.length > 0 ?
        project?.data : project?.form?.map(elem => elem.data).slice(1, project.stepForm + 1)
    useEffect(() => {
        setTasks(props.tasks || [])
    }, [props.tasks]);
    useEffect(() => {
        setProject(props.project || [])
        if (props.project) {
            setTasksProject(props.project.tasks.map(elem => ({
                id: elem.task._id,
                label: elem.task.name
            })))
        }
    }, [props.project]);

    const handleStatus = (status, id) => {
        props.updateStatusTask({ _id: project._id, status, idTask: id })
    }

    const timeouts = new Map();
    const handleDate = (id, date) => {
        clearTimeout(timeouts.get(id));
        const timeoutId = setTimeout(() => {
            props.updateStatusTask({ _id: project._id, deliveryDate: date, idTask: id });
        }, 1000);
        timeouts.set(id, timeoutId);
    };

    const handleSaveValues = async () => {
        setLoading(true);
        try {
            if (!file) toast.error(i18n.t('start.viewProject.selectBreakDown'))
            // if (!file2) toast.error("Selecione a apresentação.")
            if (!file) return false;

            const formData = new FormData();
            formData.append('details', file);
            formData.append('apresentation', file2)
            const update = await props.updateProp(formData, props.match.params.id);
            if (update.error) {
                switch (update.error.message) {
                    case "Projeto não encontrado!": return toast.error(i18n.t('start.viewProject.projectNotFound'));
                    case "Erro interno do servidor.": return toast.error(i18n.t('start.viewProject.internalError'));
                    default: return toast.error(update.error.message);
                }
            } else {
                if (update.message === "Projeto atualizado com sucesso!") {
                    toast.success(i18n.t('start.viewProject.updateSuccess'))
                } else {
                    toast.success(update.message)
                }
                await props.getViewProject(props.match.params.id);
                // toast.success(i18n.t('start.viewProject.proposalSent'))
                setFile(null);
                setFile2(null);
            }
        } catch (error) {
            if (error.error.message === "Erro interno do servidor.") {
                toast.error(i18n.t('start.viewProject.internalError'))
            } else {
                toast.error(error.error.message)
            }
        }
        setLoading(false);
    };

    const handleChange = (event) => {
        setFile(event.target.files[0]);
    };

    const handleChange2 = (event) => {
        setFile2(event.target.files[0]);
    };

    const handleSaveTasks = async () => {
        try {
            const { id } = props.match.params
            const result = await props.updateProject({ _id: project._id, tasks: tasksProject });
            props.getViewProject(id);
            if (result.error) {
                switch (result.error.message) {
                    case "Ocorreu um erro ao atualizar o projeto.": return toast.error(i18n.t('start.viewProject.updateError'));
                    case "Erro interno do servidor.": return toast.error(i18n.t('start.viewProject.internalError'))
                    default: return toast.error(result.error.message);
                }
            } else {
                if (result.message === "Projeto atualizado com sucesso!") {
                    toast.success(i18n.t('start.viewProject.updateSuccess2'))
                } else {
                    toast.success(result.message)
                }
            }
            // toast.success(i18n.t('start.viewProject.tasksSet'));
        } catch (error) {
            if (error.error.message === "Erro interno do servidor.") {
                toast.error(i18n.t('start.viewProject.internalError'))
            } else {
                toast.error(error.error.message)
            }
        }
    };

    return (
        <div style={{ padding: isMobile ? '16px 16px 66px 16px' : '16px' }} className={!isMobile & "p-4"} >

            <div className="d-flex">
                <div className="mr-auto p-2">
                    <Typography variant="h1">{project?.customer?.name} <ChipProject status={project.status} style={{ marginBottom: '10px' }} /></Typography>

                </div>
                <Link to={"/project"}>
                    <div className="p-2">

                        <button className="btn btn-outline-info btn-sm">
                            {i18n.t('start.viewProject.list')}
                        </button>
                    </div>
                </Link>
            </div>
            <div>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel3a-content"
                        id="panel3a-header"
                    >
                        <Typography>{i18n.t('start.viewProject.storeInformation')}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <p><b>{i18n.t('start.viewProject.name')}</b>: {project.customer?.name}</p>
                        <hr />
                        <p><b>{i18n.t('start.viewProject.email')}</b>: {project.customer?.email}</p>
                        <hr />
                        <p><b>{i18n.t('start.viewProject.phone')}</b>: {project.customer?.phone}</p>
                        <Paper className={classes.paper}>
                            <Grid container className={classes.root}>
                                {dataForm?.map((item, index) => (
                                    <React.Fragment key={item.name}>
                                        <Grid style={index % 2 !== 1 ? { borderRight: "1px solid #0000002b" } : {}} item xs={12} sm={6}>
                                            <ListItem>
                                                <ListItemText
                                                    primary={`${index + 1} - ${item.name}`}
                                                    secondary={
                                                        Array.isArray(item.value)
                                                            ? item.value.join(', ')
                                                            : item.value
                                                    }
                                                />
                                            </ListItem>
                                        </Grid>
                                        {index % 2 === 1 && (
                                            <Grid item xs={12} sm={12}>
                                                <Divider />
                                            </Grid>
                                        )}
                                    </React.Fragment>
                                ))}
                            </Grid>
                        </Paper>
                    </AccordionDetails>
                </Accordion>

                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography>{i18n.t('start.viewProject.proposals')}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {project.proposal?.length > 0 ?
                            <TableContainer component={Paper}>
                                <Table aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>{i18n.t('start.viewProject.date')}</TableCell>
                                            <TableCell align="right">{i18n.t('start.viewProject.apresentation')}</TableCell>
                                            <TableCell align="right">{i18n.t('start.viewProject.detailing')}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {project.proposal?.map((row) => (
                                            <TableRow key={row.data}>
                                                <TableCell component="th" scope="row">
                                                    {moment(row.date).format("DD/MM/YYYY")}
                                                </TableCell>
                                                <TableCell align="right"><a target="_blank" href={checkForUrl(row.apresentation) ? row.apresentation : `${api}/${row.apresentation}`}>{i18n.t('start.viewProject.apresentation2')}</a></TableCell>
                                                <TableCell align="right"><a target="_blank" href={checkForUrl(row.details) ? row.details : `${api}/${row.details}`}>{i18n.t('start.viewProject.detailing2')}</a></TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            : <div>{i18n.t('start.viewProject.noProposals')}</div>}
                        <FormControl sx={{ m: 1, ml: 0 }}>
                            <Button variant="contained" component="label">
                                {i18n.t('start.viewProject.uploadDetailing')}
                                <input
                                    type="file"
                                    style={{ display: "none" }}
                                    onChange={handleChange}
                                    accept="application/pdf"
                                    multiple={false}
                                />
                            </Button>
                            <small>{file ? file.name : i18n.t('start.viewProject.noFileSelect')}</small>
                        </FormControl>
                        <FormControl sx={{ m: 1, ml: 0 }}>
                            <Button variant="contained" component="label">
                                {i18n.t('start.viewProject.uploadApresentation')}
                                <input
                                    type="file"
                                    style={{ display: "none" }}
                                    onChange={handleChange2}
                                    accept="application/pdf"
                                    multiple={false}
                                />
                            </Button>
                            <small>{file2 ? file2.name : i18n.t('start.viewProject.noFileSelect2')}</small>
                        </FormControl>
                        <br />
                        <FormControl sx={{ m: 1, ml: 0 }}>
                            <LoadingButton loading={loading} onClick={handleSaveValues} variant="contained">{i18n.t('start.viewProject.send')}</LoadingButton>
                        </FormControl>
                    </AccordionDetails>
                </Accordion>
                {/* <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography>Tarefas</Typography>
                    </AccordionSummary>
                    <AccordionDetails>

                        <Stack>
                            <Autocomplete
                                multiple
                                options={tasks.map(elem =>
                                ({
                                    id: elem._id,
                                    label: elem.name
                                }))}
                                onChange={(e, value) => setTasksProject(value)}
                                value={tasksProject}
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Tarefas"
                                        placeholder="Tarefas"
                                    />
                                )}
                            />
                        </Stack>
                        <br />
                        <Button onClick={handleSaveTasks} variant="contained">Atualizar tarefas</Button>
                        <Stack>
                            {props.project?.tasks &&
                                <KanbanBoard onChange={handleStatus} onChangeDate={handleDate} itemsFromBackend={props.project.tasks || []} />}
                        </Stack>
                    </AccordionDetails>
                </Accordion> */}
            </div>
        </div>
    );
}


const mapStateToProps = state => ({
    project: state.project.projectDetails,
    tasks: state.task.tasks
})


const mapDispatchToProps = (dispatch) => {
    return {
        getViewProject: (id) => dispatch(getViewProject(id)),
        updateProject: (data) => dispatch(handleUpdateProject(data)),
        getTasks: () => dispatch(getTasks()),
        updateStatusTask: (data) => dispatch(handleUpdateStatusTask(data)),
        updateProp: (data, id) => dispatch(updateProp(data, id))
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(ViewProject);