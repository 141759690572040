import React, { Component } from 'react';
import {Link, Redirect} from 'react-router-dom';
import {Form, FormGroup, Label, Input } from 'reactstrap';
import logo from '../../assets/logo-fullcomm-vertical.png';
import {connect} from 'react-redux'
import AlertDanger from '../../components/AlertDanger'
import SpinnerUpdateRecovery from '../../components/SpinnerUpdateRecovery'
import * as actions from '../../store/actions/users'
import { i18n } from '../../translate/i18n';

class UpdatePassRecovery extends Component{
    state = {
        _id: "",
        recoveryPass: "",
        password: "",
        key: "",
        error: "",
        success: "",
        loading: false,
        formSuccess: false,
        loadingForm: false,
        keyInvalid: false,
    }

    onChangeInput = (ev) => {
        this.setState({[ev.target.name]: ev.target.value})
    }

    componentDidMount(){      
        this.setState({loadingForm: true})
        const {key} = this.props.match.params;
        this.setState({key})
        this.props.validKeyRecovery(key, (msg) => {
            if(msg.error.error){
                this.setState({keyInvalid: true})
            }else{
                this.setState({_id: msg.error.user._id})
                this.setState({recoveryPass: key})
                
                this.setState({loadingForm: false})
            }
        });
    }

    validate(){
        const {password} = this.state;
        if(!password){
            return this.setState({error: {message:  i18n.t('updatePassRecovery.informPassword')}})
        }else if(password.length < 6){
            return this.setState({error: {message:  i18n.t('updatePassRecovery.minChars')}})
        }

        return true;
    }

    

    updateUserPass(){
        const {password, _id, recoveryPass} = this.state;

        if(!this.validate()) return
        
        this.setState({loading: true});

        this.props.updateUserPassRecovery({password, _id, recoveryPass}, (msg) => {
            if(msg.error.error){
                this.setState({error: {message: msg.error.message}})                
                this.setState({success: ""});
            }else{
                this.setState({error: ""});
                this.setState({success: {message: msg.error.message}});
                this.setState({formSuccess: true});
            }
            
            this.setState({loading: false});
        });

    }

    render(){    
        const { loading, password, error,  keyInvalid, formSuccess, success, loadingForm} = this.state;
        if(keyInvalid){
            return <Redirect to ={{
                pathname: '/recovery-pass',
                state: { msg: i18n.t('updatePassRecovery.invalidKey')}
            }} />
        }

        if(formSuccess){
            return <Redirect to ={{
                pathname: '/',
                state: { msg: success.message}
            }} />
        }
        
        return (
            
            <>     
            <div className="container-login">
                <div className="login card shadow">
                    <Form className="form-signin text-center">
                        <img src={logo} alt="Logo" className="center imgLogo"/>
                        <h1 className="h3 mb-3 font-weight-normal">{i18n.t('updatePassRecovery.updatePassword')}</h1>
                        <AlertDanger error={error}/>
                        <FormGroup>
                            <Label for="password">{i18n.t('updatePassRecovery.password')}</Label>
                            <Input type="password" value={password} name="password" id="password" placeholder={loadingForm ? i18n.t('updatePassRecovery.loading') : i18n.t('updatePassRecovery.newPassword')} disabled={!loadingForm ? false : true} onChange={(ev) => this.onChangeInput(ev)}/>
                        </FormGroup>
                        
                        <span onClick={() => this.updateUserPass()}><SpinnerUpdateRecovery loading={loading}/></span>
                        <p className="text-center mt-2"><Link to="/">{i18n.t('updatePassRecovery.clickHere')}</Link>
                        {i18n.t('updatePassRecovery.toAccess')}</p>
                        
                    </Form>
                </div>
            </div>
            </>
        )
    }
    
}

export default connect(null, actions)(UpdatePassRecovery);