import { Collapse, List, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ErrorIcon from '@mui/icons-material/Error';
import SpeedIcon from '@mui/icons-material/Speed';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import { userPermissionCheck } from '../../pages/Sprint/utils/userPermissionCheck';
import LibraryAddIcon from '@mui/icons-material/LibraryAdd';
import ViewKanbanIcon from '@mui/icons-material/ViewKanban';
import { i18n } from '../../translate/i18n';
import TopicIcon from '@mui/icons-material/Topic';

const Support = ({ user, MenuLink, problemNotifications }) => {
  const [isOpen, setIsOpen] = useState(localStorage.getItem('supportSidebar') === 'false' ? false : true);
  
  const handleToggleCollapse = () => {
      setIsOpen(!isOpen);
  };
  
  useEffect(() => {
      localStorage.setItem('supportSidebar', isOpen);
  }, [isOpen]);
  return (
      <div>
          <Typography onClick={handleToggleCollapse} variant="h6" sx={{ cursor: 'pointer', color: 'white', fontSize: '14px', fontWeight: 'bold', marginTop: '10px', marginBottom: '10px' }}>
              {i18n.t('components.sideBar.support.support')}
          </Typography>
          <Collapse in={isOpen}>
              <List component="nav">
              {
                userPermissionCheck(user, ["create_action"]) && (
                    <MenuLink to="/support-new-action" Icon={LibraryAddIcon}>{i18n.t('components.sideBar.support.improvements')}</MenuLink>
                )
                }
                {
                userPermissionCheck(user, ["view_action"]) && (
                    <MenuLink to="/support-action-list" Icon={ReceiptLongIcon}>{i18n.t('components.sideBar.support.improvementsList')}</MenuLink>
                )
                }
              {/* {
                    userPermissionCheck(user, ["view_problem"]) && (
                        <MenuLink to="/hour-tracking" Icon={AccessTimeIcon}>{i18n.t('components.sideBar.support.hoursControl')}</MenuLink>
                    )
              } */}
              {
                userPermissionCheck(user, ["view_problem"]) && (
                    <MenuLink to="/problems-incidents" Icon={ErrorIcon}>{i18n.t('components.sideBar.support.problems')}</MenuLink>
                )
              }
              {
                  userPermissionCheck(user, ["view_problem"]) && (
                      <MenuLink to="/support-performance" Icon={ViewKanbanIcon} badge={problemNotifications}>{i18n.t('components.sideBar.support.kanban')}</MenuLink>
                      )   
                }
                {
                    userPermissionCheck(user, ["view_document"]) && (
                        <MenuLink to="/documents/suporte" Icon={TopicIcon}>{i18n.t('documents.index.title')}</MenuLink>
                    )
                    
                }
                <MenuLink to="/performance" Icon={SpeedIcon}>{i18n.t('components.sideBar.support.performance')}</MenuLink>
              </List>
          </Collapse>
      </div>
  );
}

export default Support