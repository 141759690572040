import React, { Component } from 'react';
import { Link} from 'react-router-dom';
import validator from 'validator';
import logo from '../../assets/logo-fullcomm-vertical.png';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/users';
import { Form, FormGroup, Label, Input } from 'reactstrap';
import AlertDanger from "../../components/AlertDanger";
import AlertSuccess from "../../components/AlertSuccess";
import { i18n } from '../../translate/i18n';


import SpinnerRecovery from '../../components/SpinnerRecovery'


class RecoveryPassLogin extends Component{
    state = {
        email: "",
        error: "",
        loading: false,
        formSuccess: false,
        success: "",
        msgErr: ""
    }

    componentDidMount() {
        if (this.props.location.state) {
            this.setState({ msgErr: this.props.location.state.msg });
            this.props.location.state.msgErr = "";
        }
    }

    recoveryPass(){
        const { email} = this.state;

        if(!this.validate()) return
        
        this.setState({loading: true});

        this.props.recoveryPass({email}, (msg) => {
            if(msg.error.error){
                this.setState({error: {message: msg.error.message}})                
                this.setState({success: ""});
            }else{
                this.setState({error: ""});
                this.setState({success: {message: msg.error.message}});
                this.setState({formSuccess: true});
            }
            
            this.setState({loading: false});
        });

    }

    validate(){
        const {email} = this.state;
        if(!email){
            return this.setState({error: {message: i18n.t('recoveryPassLogin.emailRequired')}})            
        }else if(!validator.isEmail(email)){
            return this.setState({error: {message:  i18n.t('recoveryPassLogin.emailError')}})            
        }

        return true;
    }

    

    onChangeInput = (ev) => {
        this.setState({[ev.target.name]: ev.target.value})
    }

    render(){
        const { loading, email, error, success, msgErr } = this.state;
        return (
            <>
            <div className="container-login">
                <div className="login card shadow">
                    <Form className="form-signin text-center">
                        <img src={logo} alt="Logo" className="center imgLogo"/>
                        <h1 className="h3 mb-3 font-weight-normal">{i18n.t('recoveryPassLogin.rescuePass')}</h1>
                        {msgErr ? <AlertDanger error={{ message: msgErr }} /> : ""}
                        <AlertDanger error={error}/>                        
                        <AlertSuccess error={success}/>
                        <FormGroup> 
                            <Label for="email">{i18n.t('recoveryPassLogin.email')}</Label>
                            <Input type="email" value={email} name="email" id="email" placeholder={i18n.t('recoveryPassLogin.writeEmail')} onChange={(ev) => this.onChangeInput(ev)}/>
                        </FormGroup>
                        
                        <span onClick={() => this.recoveryPass()}><SpinnerRecovery loading={loading}/></span>
                        <p className="text-center mt-2"><Link to="/">{i18n.t('recoveryPassLogin.clickHere')}</Link>
                        {i18n.t('recoveryPassLogin.toAccess')}</p>
                        
                    </Form>
                </div>
            </div>
            </>
        )
    }
}

export default connect(null, actions)(RecoveryPassLogin)