import { Button, Tab, Typography, Avatar } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { Modal } from "reactstrap";
import { connect } from "react-redux";
import { Box } from "@mui/system";
import CycleSelector from "../CycleSelector";
import "dayjs/locale/pt-br";
import 'dayjs/locale/es';
import dayjs from 'dayjs';
import { getViewContractSpecific } from "../../../../../store/actions/contracts";
import { getActionsContract, getDebitedActions, getHealthScore } from "../../../../../store/actions/action";
import { getProblems } from "../../../../../store/actions/problems";
import PropTypes from "prop-types";
import ActionCardArea from "./ActionCardArea";
import { api } from "../../../../../config";
import EditCreditsModal from "./EditCreditsModal";
import { isMobile } from "react-device-detect";
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import { handleShop } from "../../../../../store/actions";
import { getViewCustomer } from "../../../../../store/actions/customers";
import { i18n } from "../../../../../translate/i18n";
import TransactionsArea from "./TransactionsArea";
import { queryString } from "../../../../../utils/String";
import { FCChip, FCDatePicker, FCModalHeader, FCTabs } from "components-fullcomm";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      style={{ width: "100%" }}
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const CreditBalanceModal = (props) => {
  const { platform } = props;
  const [open, setOpen] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const [date, setDate] = useState(dayjs(new Date()));
  const [plannedActions, setPlannedActions] = useState(props.actions?.filter(elem => elem.status === 'Backlog' || elem.status === 'Planejamento') || []);
  const [provisionedActions, setProvisionedActions] = useState(props.actions?.filter(elem => elem.status === 'Para fazer') || []);
  const [totalDebitedCredits, setTotalDebitedCredits] = useState(0);
  const [debitedActions, setDebitedActions] = useState([]);
  const [totalTransactions, setTotalTransactions] = useState(0);
  const [transactions, setTransactions] = useState([]);
  const [problemCredits, setProblemCredits] = useState(0);
  const [problems, setProblems] = useState([]);
  const [creditsSpent, setCreditsSpent] = useState(0);

  const changeShop = (value) => {
    props.handleShop(value)
    props.getHealthScore(props.cycle)
    props.getViewContractSpecific()
    props.getViewCustomer(value)
    getActions(value, props.cycle, date)
  }
const creditsCost = (actions) => {
  return actions?.reduce((acc, action) => {
    if (action.status === 'Backlog' || action.status === 'Planejamento' || action.status === 'Para fazer') {
      action.activities?.forEach((activity) => {
        if (!activity.repeat || (Array.isArray(action.cycle) && action.cycle[action.cycle?.length - 1] !== props.cycle && action.platform === "sprint")) return;
        const activityCost = Number(activity.activity.creditsCost) * activity.repeat;
        acc += activityCost || 0;
      });
    } else {
      action.activities?.forEach((activity) => {
        if (!activity.charged || (Array.isArray(action.cycle) && action.cycle[action.cycle?.length - 1] !== props.cycle && action.platform === "sprint")) return;
        const activityCost = Number(activity.activity.creditsCost) * activity.charged;
        acc += activityCost || 0;
      });
    }
    return acc;
  }, 0);
};

const handleCreditsDisplay = (date) => {
  const today = new Date(date);
  const currentMonth = today.getMonth();
  const currentYear = today.getFullYear();
  const currentDate = `${currentYear}-${currentMonth}`;
  const currentAmountCredits = props.contract?.creditsUsedMonthly?.find(el => el.date === currentDate)?.amount || 0;

  // const currentAmountHours = props.contract?.hours?.find(el => el.date === currentDate)?.amount || 0;
  // const currentAmountHoursRounded = Math.ceil((currentAmountHours / 3600)) * 25;

  const total = props.contract?.monthlyCreditsSupport - (currentAmountCredits);

  return platform === "suporte" ?
    total :
    props.contract?.monthlyCredits * 3 - totalDebitedCredits - transactions?.reduce((acc, cur) => {
      if (cur.type === 'charge') acc += cur.credits;
      else acc -= cur.credits;
      return acc;
    }, 0);
};

  const getActions = useCallback(async (customerId = '', cycle, date) => {
    const params = new URLSearchParams({
      customerId: customerId,
    });
    const today = new Date(date);
    const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0, 20, 59, 59, 999);

    if (platform?.includes("suporte")) {
      if (!date) return;
      params.set("createdAt", lastDayOfMonth);
      params.set("platform", "suporte");
      const query = queryString({ history: today, limit: 9999, customer: customerId });
      const res = await props.getProblems(query, false);
      setProblems(res);
    } else {
      if (!cycle) return;
      params.set("cycle", cycle);
      params.set("platform", "sprint");
    }

    const response = await props.getDebitedActions(cycle, customerId, platform?.includes("suporte") ? "suporte" : "sprint", today);
    setDebitedActions(response?.actions || []);
    setTotalDebitedCredits(response?.totalCharge || 0);
    setTotalTransactions(response?.totalTransactions || 0);
    setTransactions(response?.transactions || []);
    props.getActions(params);
  }, []);

  useEffect(() => {
    getActions('', props.cycle, date);
  }, [props.cycle, open, date]);

  useEffect(() => {
    setPlannedActions(props.actions?.filter(elem => elem.status === 'Backlog' || elem.status === 'Planejamento') || []);
    setProvisionedActions(props.actions?.filter(elem => elem.status === 'Para fazer') || []);
  }, [props.actions]);

  useEffect(() => {
    setCreditsSpent(handleCreditsDisplay(date) - problemCredits);
  }, [props.contract, date, problemCredits, props.cycle, debitedActions, transactions, totalDebitedCredits]);

  useEffect(() => {
    let credits = 0;
    if (problems?.docs?.length > 0) {
      const today = new Date(date);
      const month = today.getMonth();
      const year = today.getFullYear();
      credits = problems?.docs?.reduce((acc, cur) => {
        if (cur.history) {
          const seconds = cur.history.reduce((acc, cur) => {
            if (cur.date === `${year}-${month}`) {
              acc += cur.timeSpentSeconds;
            }
            return acc;
          }, 0);
          acc += Math.ceil(Math.ceil(seconds / 1800) * 12.5);
        }
        return acc;
      }, 0);
    }

    setProblemCredits(credits >= 0 ? credits : 0);
  }, [problems]);

  useEffect(() => {
    props.getViewContractSpecific();
  }, []);

  return (
    <>
      <Button
        onClick={() => {
          setOpen(true);
          getActions();
        }}
        variant="contained"
        color="default"
        sx={{
          textTransform: "none",
          background: "#333333",
          color: "white",
          '&:hover': {
            background: "#1e88e5",
          },
        }}
      >
        <small>{isMobile ? <AccountBalanceWalletIcon style={{ width: '12px' }} /> : i18n.t('sprint.utils.headerButtons.creditBalanceModal.freeBalance')} {creditsSpent}</small>
      </Button>
      <Modal
        isOpen={open}
        size="lg"
        centered={true}
        toggle={() => setOpen(false)}
      >
        <FCModalHeader
          title={i18n.t('sprint.utils.headerButtons.creditBalanceModal.balance')}
          onClick={() => setOpen(false)}
        />
        <Box padding={4} paddingTop={0} display={"grid"} gap={4}>
          <Box gap={2} alignItems={"center"}>
            <Box display={"flex"} gap={"0.75rem"} alignItems={"center"}>
              <Avatar alt={"user image"} src={props.customer?.fileLocation ? props.customer.fileLocation : `${api}/files/customers/${props.customer?.fileName}`} />
              <Typography variant="h6">{props.customer?.name || ""}</Typography>
            </Box>
            <Typography variant="h7">{props.contract?.name || ""}</Typography>
          </Box>
          <div>
            <Box marginBottom={"0.5rem"}>
              {
                props.contract?.customers?.map((customer) => {
                  return (
                    <FCChip
                      key={customer?._id}
                      label={customer?.name || ""}
                      sx={{ marginRight: "0.5rem" }}
                      onClick={() => { changeShop(customer?._id) }}
                    />
                  )
                }
                )
              }
            </Box>
            <Box display="flex" flexDirection={{ xs: "column", md: "row" }} gap={{xs: "1rem", md: 0}} mt="0.75rem" justifyContent="space-between" alignItems="center">
              <Box display="flex" flexDirection={{ xs: "column", md: "row" }} alignItems="center" gap={{xs: "0.5rem", md: 2}}>
                <Typography variant="h6">{i18n.t('sprint.utils.headerButtons.creditBalanceModal.cycleBalance')}</Typography>
                {
                  platform?.includes("suporte") ? (
                    <FCDatePicker
                      // label={i18n.t("support.hourTracking.filter")}
                      value={date}
                      onChange={(date) => setDate(date)}
                      minDate={dayjs("01/01/2020")}
                      maxDate={dayjs("01/01/2040")}
                      views={['month', 'year']}
                    />
                  ) : (
                    <CycleSelector />
                  )
                }
              </Box>
              <Box display="flex" alignItems="center" gap="1rem">
                <Typography variant="h6">{i18n.t('sprint.utils.headerButtons.creditBalanceModal.avaiable')} {creditsSpent}</Typography>
                {props.user?.role === 'admin' && <EditCreditsModal contract={props.contract} customer={props.customer} cycle={props.cycle} platform={platform} handleCreditsDisplay={creditsSpent} date={date}/>}
              </Box>
            </Box>
          </div>
          <Box>
            <FCTabs
              variant={isMobile ? "scrollable" : "standard"}
              value={tabIndex}
              onChange={(e, newValue) => setTabIndex(newValue)}
            >
              <Tab
                label={`${i18n.t('sprint.utils.headerButtons.creditBalanceModal.planned')} ${creditsCost(props.actions?.filter(elem => elem.status === 'Backlog' || elem.status === 'Planejamento'))}`}
                {...a11yProps(0)}
              />
              <Tab
                label={`${i18n.t('sprint.utils.headerButtons.creditBalanceModal.provisioned')} ${creditsCost(props.actions?.filter(elem => elem.status === 'Para fazer'))}`}
                {...a11yProps(1)}
              />
              <Tab
                label={`${i18n.t('sprint.utils.headerButtons.creditBalanceModal.debited')} ${totalDebitedCredits + problemCredits}`}
                {...a11yProps(2)}
              />
              <Tab
                label={`${i18n.t('sprint.utils.headerButtons.creditBalanceModal.transactions')}  ${totalTransactions}`}
                {...a11yProps(3)}
              />
            </FCTabs>
            <TabPanel value={tabIndex} index={0}>
              <ActionCardArea actions={plannedActions} />
            </TabPanel>
            <TabPanel value={tabIndex} index={1}>
              <ActionCardArea
                actions={provisionedActions}
              />
            </TabPanel>
            <TabPanel value={tabIndex} index={2}>
              <ActionCardArea actions={[...debitedActions || [], ...problems?.docs || []]} dateFilter={date}/>
            </TabPanel>
            <TabPanel value={tabIndex} index={3}>
              <TransactionsArea transactions={transactions} />
            </TabPanel>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => {
  return ({
    user: state.auth.user,
    customer: state.customer.customerDetails,
    actions: state.actions.actions_contract,
    cycle: state.actions.cycle,
    contract: state.contract.contractDetails,
    problems: state.problems.problems,
  })
};

const mapDispatchToProps = (dispatch) => {
  return {
    getProblems: (query, useDispatch) => dispatch(getProblems(query, useDispatch)),
    getDebitedActions: (cycle, customer, platform, createdAt) => dispatch(getDebitedActions(cycle, customer, platform, createdAt)),
    getActions: (props) => dispatch(getActionsContract(props)),
    getViewContractSpecific: (props) => dispatch(getViewContractSpecific(props)),
    handleShop: (props) => dispatch(handleShop(props)),
    getHealthScore: (props) => dispatch(getHealthScore(props)),
    getViewCustomer: (props) => dispatch(getViewCustomer(props)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreditBalanceModal);
