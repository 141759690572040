import React, { useState } from "react";
import { Box } from "@mui/system";
import Roadmap from "./Roadmap";
import { Tab, Tabs, Typography } from "@mui/material";
import PropTypes from "prop-types";
import CalendarView from "./CalendarView";
import { i18n } from '../../../translate/i18n';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
const ActionPlan = () => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box className="dashboard">
      <Typography variant="h4">{i18n.t('sprint.actionPlan.actionPlan')}</Typography>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          sx={{
            "& .MuiTabs-indicator": { backgroundColor: "#008060" },
            "& .MuiButtonBase-root": {
              color: "#a2a4a7",
              textTransform: "none",
            },
            "& .Mui-selected": {
              color: "#202223 !important",
            },
          }}
        >
          <Tab label={i18n.t('sprint.actionPlan.roadMapLabel')} />
          <Tab label={i18n.t('sprint.actionPlan.calendary')} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <Roadmap />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <CalendarView />
      </TabPanel>
    </Box>
  );
};

export default ActionPlan;
