import React, { useEffect, useState } from 'react'
import ActivityOptions from './ActivityOptions';
import { connect } from "react-redux";
import { Box, Divider } from '@mui/material';

const ActivityDetails = (props) => {
  const [arr, setArr] = useState([]);

  useEffect(() => {
    props.setIndex(props.index);
    props.setDescription(arr);
  }, [arr]);

  return (
    <>
    {
      props.activity.fields?.map((options, i) => 
      <ActivityOptions 
        key={i}
        defaultValue={props.defaultValue?.[i] ?? null}
        setArr={setArr}
        options={options}
        i={i} 
      />
      )
    }
    {
      props.index < props.amount - 1 && <Divider sx={{ my: 2 }} />
    }
    </>
  )
}

const mapStateToProps = (state) => ({
  customer: state.customer?.customerDetails
});

export default connect(mapStateToProps)(ActivityDetails);
