import { Box, Button, Checkbox, CircularProgress, FormControlLabel, FormGroup, FormHelperText, Grid, Menu, MenuItem, TextField, Typography } from '@mui/material'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Modal } from 'reactstrap'
import * as Yup from 'yup';
import { api } from '../../config'
import { Form, FormikProvider, useFormik } from 'formik';
import { toast } from 'react-toastify';
import { getHeaders } from '../../store/actions/localStorage';
import { LoadingButton } from '@mui/lab';
import { useAuth0 } from '@auth0/auth0-react';
import { i18n } from '../../translate/i18n';
import { getViewCustomer, handleAddTrialCustomer } from '../../store/actions/customers';
import { updateTrialUser, viewPerfil  } from '../../store/actions/users';
import { handleLogout, handleShop } from '../../store/actions';
import { getHealthScore } from '../../store/actions/action';
import { getViewContractSpecific } from '../../store/actions/contracts';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { MuiTelInput } from 'mui-tel-input';

const Intro = (props) => {
  const history = useHistory();
  const [open, setOpen] = useState(true);
  const { logout } = useAuth0();

  const NewCustomerSchema = Yup.object().shape({
    username: Yup.string().required(i18n.t('dataEnrichmentModal.intro.userRequired')),
    // telephone: Yup.string().required(i18n.t('dataEnrichmentModal.intro.phoneRequired')),
    name: Yup.string().required(i18n.t('dataEnrichmentModal.intro.nameRequired')),
    termsOfUse: Yup.boolean().oneOf([true], i18n.t('dataEnrichmentModal.intro.termsOfUseRequired')),
    storeUrl: Yup.string().url(i18n.t('dataEnrichmentModal.intro.urlValid')).required(i18n.t('dataEnrichmentModal.intro.urlRequired')),
    segment: Yup.string().required(i18n.t('dataEnrichmentModal.intro.segmentRequired')),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      username: '', 
      name: '',
      termsOfUse: false,
      storeUrl: '',
      segment: '',
      telephone: '+55',
    },
    validationSchema: NewCustomerSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        // const response = await axios.get(api + '/data-enrichment/' + formik.values.storeUrl.replace(/^https?:\/\//, ""), getHeaders());
        // props.setName(values.name);
        // props.setStoreUrl(values.storeUrl);
        // props.setUserInfo({name: values.username, telephone: values.telephone});
        // props.setIntegrations(response.data.categoryObject);

        // setSubmitting(false);
        // setOpen(false);
        // props.setCurrentPage("integrations");

        const data = {
          name: values.name,
          storeUrl: values.storeUrl,
          segment: values.segment,
        }
        const customer = await props.addCustomer(data);
        const { _id } = customer.data;
        const user = await props.updateUser({
          _id: props.user?._id, 
          customer: _id,
          name: values.username,
          telephone: values.telephone,
        });
        if (user.error) {
          toast.error(user.error);
          return;
        }
        await props.getUser();
        setOpen(false);
        props.setCurrentPage("");
        toast.success(i18n.t('dataEnrichmentModal.integrations.createdStore'));
        setSubmitting(false);
        props.handleShop(_id)
        props.getHealthScore(props.cycle)
        props.getViewContractSpecific()
        props.getViewCustomer(_id)
        history.push(`/dashboard`);
      } catch (error) {
        setSubmitting(false);
        toast.error(error.message);
      }
      // } catch (error) {

      //   props.setName(values.name);
      //   props.setStoreUrl(values.storeUrl);
      //   props.setUserInfo({name: values.username, telephone: values.telephone});
      //   setOpen(false);
      //   props.setCurrentPage("integrations");
      //   setSubmitting(false);
      //   toast.error(error.response.data.error || error.message);
      // }
    }
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps, setFieldValue, values } = formik;

  useEffect(() => {
    // Add or remove the blur-background class from the body element
    document.body.classList.toggle('blur-background', open);

    // Cleanup: remove the class when the component is unmounted
    return () => {
      document.body.classList.remove('blur-background');
    };
  }, [open]);

  return (
    <div className={open ? 'blur-background' : ''}>
    <Modal
      style={{zIndex: 99999}}
      isOpen={open}
      centered={true}
      size="lg"
    >
      <Box padding={"3.25rem"} paddingBottom={"2.75rem"}>
        <Typography 
          mb={"2rem"} 
          variant="h1" 
          fontSize={"48px"} 
          align="center"
        >
          {i18n.t('dataEnrichmentModal.intro.helpUs')}
        </Typography>
        <FormikProvider value={formik}>
          <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <Box display={"grid"} gap={"1rem"}>
              <Grid container spacing={"1rem"}>
                <Grid item xs={8}>
                  <TextField
                    {...getFieldProps('username')}
                    error={Boolean(touched.username && errors.username)}
                    helperText={touched.username && errors.username}
                    type='text'
                    label={i18n.t('dataEnrichmentModal.intro.name')}
                    fullWidth
                    />
                </Grid>
                <Grid item xs={4}>
                  <MuiTelInput
                    fullWidth
                    value={values.telephone}
                    onChange={(e) => setFieldValue('telephone', e)}
                    label={i18n.t('dataEnrichmentModal.intro.phone')}
                    lang={localStorage.getItem('i18nextLng') || this.props?.user?.language}
                  />
                </Grid>
                <Grid item xs={8}>
                <TextField
                  {...getFieldProps('name')}
                  error={Boolean(touched.name && errors.name)}
                  helperText={touched.name && errors.name}
                  type='text'
                  label={i18n.t('dataEnrichmentModal.intro.storeName')}
                  fullWidth
                />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    select
                    {...getFieldProps('segment')}
                    error={Boolean(touched.segment && errors.segment)}
                    helperText={touched.segment && errors.segment}
                    label={i18n.t('dataEnrichmentModal.intro.segment')}
                    fullWidth
                  >
                    <MenuItem value={"Moda"}>{i18n.t('dataEnrichmentModal.intro.fashion')}</MenuItem>
                    <MenuItem value={"Eletrônicos"}>{i18n.t('dataEnrichmentModal.intro.eletronics')}</MenuItem>
                    <MenuItem value={"Beleza"}>{i18n.t('dataEnrichmentModal.intro.beauty')}</MenuItem>
                    <MenuItem value={"Casa"}>{i18n.t('dataEnrichmentModal.intro.home')}</MenuItem>
                    <MenuItem value={"Outro"}>{i18n.t('dataEnrichmentModal.intro.other')}</MenuItem>
                  </TextField>
                </Grid>
              </Grid>
              <TextField 
                required
                {...getFieldProps('storeUrl')}
                error={Boolean(touched.storeUrl && errors.storeUrl)}
                helperText={touched.storeUrl && errors.storeUrl}
                type='url'
                label={i18n.t('dataEnrichmentModal.intro.urlStore')}
                fullWidth
              />
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox color='concluded' onChange={e =>setFieldValue('termsOfUse', e.target.checked)}/>}
                  label={i18n.t('dataEnrichmentModal.intro.termsOfUse')}
                />
                {touched.termsOfUse && errors.termsOfUse && (
                  <FormHelperText error>{errors.termsOfUse}</FormHelperText>
                )}
              </FormGroup>
              <LoadingButton
                sx={{textTransform: 'none'}}
                fullWidth 
                variant="contained"
                type="submit"
                loading={isSubmitting}
                loadingIndicator={<CircularProgress size={20} sx={{color: '#fff'}}/>}
              >
                {i18n.t('dataEnrichmentModal.intro.initConfig')}
              </LoadingButton>
            </Box>
          </Form>
        </FormikProvider>
        <Typography 
          align="center"
          mt={"0.5rem"}
          sx={{
            color: '#919191',
            cursor: 'pointer', 
            '&:hover': {
              textDecoration: 'underline'
            }
          }}
          onClick={()=>{
            handleLogout();
            logout();
          }}
          >
          {i18n.t('dataEnrichmentModal.intro.continueAfter')}
        </Typography>
      </Box>
    </Modal>
    </div>
  )
}

const mapStateToProps = state =>
({
  user: state.auth.user,
  cycle: state.actions.cycle,
});

const mapDispatchToProps = (dispatch) => {
  return {
    addCustomer: (data) => dispatch(handleAddTrialCustomer(data)),
    updateUser: (data) => dispatch(updateTrialUser(data)),
    getUser: () => dispatch(viewPerfil()),
    handleShop: (shop) => dispatch(handleShop(shop)),
    getHealthScore: (cycle) => dispatch(getHealthScore(cycle)),
    getViewContractSpecific: () => dispatch(getViewContractSpecific()),
    getViewCustomer: (id) => dispatch(getViewCustomer(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Intro);
