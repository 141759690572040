import no_priority from '../../../assets/priorities/no_priority.png';
import low from '../../../assets/priorities/low.png';
import normal from '../../../assets/priorities/normal.png';
import high from '../../../assets/priorities/high.png';
import critical from '../../../assets/priorities/critical.png';
import emergency from '../../../assets/priorities/emergency.png';

export const priorities = [
  {
    title: "Emergência",
    description: "As vendas estão comprometidas até a solução do item",
    image: emergency,
  },
  {
    title: "Crítica",
    description: "As vendas poderão ser altamente reprimidas até a solução deste item",
    image: critical,
  },
  {
    title: "Alta",
    description: "As vendas poderão ser levemente reprimidas até a solução deste item",
    image: high,
  },
  {
    title: "Normal",
    description: "As vendas poderão melhorar com a resolução deste item",
    image: normal,
  },
  {
    title: "Baixa",
    description: "As vendas não serão impactadas com a solução deste item",
    image: low,
  },
  {
    title: "Sem Prioridade",
    description: "A criticidade deste item não pode ser definida",
    image: no_priority,
  },
];

export const rootCause = [
  "Causa raiz não identificada",
  "Instalação do app",
  "Atualização de tema",
  "Integração de sistema",
  "Fornecedor externo",
  "Instabilidade da plataforma",
  "Outro..."
]

export const problemLocation = [
  "Página inicial",
  "Página de produto",
  "Página de coleção",
  "Carrinho",
  "Login ou Cadastro",
  "Formulário",
  "Promoção",
  "Funcionalidade de App",
  "Integração de ERP",
  "Cálculo de frete",
  "Erro de Analytics",
  "Ferramenta de Marketing",
  "Integração de Catálogo Facebook",
  "Integração de Catálogo Google",
  "Outro..."
]

export const devices = [
  "Desktop Windows",
  "Desktop Mac",
  "Desktop Linux",
  "Tablet Android",
  "Tablet iOS",
  "Mobile Android",
  "Mobile iOS",
]

export const browsers = [
  "Chrome",
  "Safari",
  "Firefox",
  "Edge",
  "Internet Explorer",
  "Opera",
]