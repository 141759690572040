import axios from 'axios';
import { api } from '../../config'
import { getHeaders } from './localStorage'
import errorHandling from './errorHandling'
import { useThemeProps } from '@mui/material';
import { queryString } from '../../utils/String';

export const readMessages = (props) => async (dispatch) => {
    try {
        const query = props.parent ? "?parent=true" : ""
        const response = await axios.get(api + "/read-message/" + props.platform + "/" + props.customer + query, getHeaders());
    } catch (err) {
        return errorHandling(err);
    }
}

export const getMessages = (props) => async (dispatch) => {
    try {
        const response = await axios.get(api + "/message/" + props.platform + "/" + props.customer + "?keep=1&afterId=" + (props.afterId || '') + "&activity=" + (props.activity || ''), getHeaders());
        dispatch({ type: "GET_MESSAGES", payload: {messages: response.data } });

        dispatch({ type: "GET_POSITION", payload: {position: props.afterId ? props.afterId  : null} });
    } catch (err) {
        return errorHandling(err);
    }
}

export const getMessagesAdd = (props) => async (dispatch) => {
    try {
        const response = await axios.get(api + "/message/" + props.platform + "/" + props.customer + "?beforeId=" + (props.beforeId || '') + "&afterId=" + (props.afterId || '')  + "&search=" + (props.search || ''), getHeaders());

        dispatch({ type: "GET_POSITION", payload: {position: null} });
        return response.data;
    } catch (err) {
        return errorHandling(err);
    }
}

export const getNoReadMessages = () => async (dispatch) => {
    try {
        const response = await axios.get(api + "/message-no-read", getHeaders());
        dispatch({ type: "GET_NOREAD_MESSAGES", payload: response.data });
    } catch (err) {
        return errorHandling(err);
    }
}

export const getNoReadMessagesCustomer = (props) => async (dispatch) => {
    try {
        const response = await axios.get(api + "/message-no-read/" + props.shop, getHeaders());
        dispatch({ type: "GET_NOREAD_MESSAGES", payload: response.data });
    } catch (err) {
        return errorHandling(err);
    }
}

export const sendMessage = (props, customer) => async () => {
    try {
        const response = await axios.post(api + "/message/" + customer, props, getHeaders());
        return response.data;
    } catch (err) {
        return errorHandling(err);
    }
}

export const markMessage = (props) => async (dispatch) => {
    try {
        await axios.get(api + "/mark-message/" + props, getHeaders());
    } catch (err) {
        return errorHandling(err);
    }
}

export const unmarkMessage = (props) => async (dispatch) => {
    try {
        await axios.get(api + "/unmark-message/" + props, getHeaders());
    } catch (err) {
        return errorHandling(err);
    }
}

export const getFiles = (props) => async (dispatch) => {
    try {
        const response = await axios.get(api + "/message-files/" + props.platform + "/" + props.customer + "?limit=" + props.limit || 100, getHeaders());
        dispatch({ type: "GET_FILES", payload: response.data });
    } catch (err) {
        return errorHandling(err);
    }
}

export const getMarks = (props) => async (dispatch) => {
    try {
        const response = await axios.get(api + "/message-marks/" + props.platform + "/" + props.customer + "?limit=" + props.limit || 100, getHeaders());
        dispatch({ type: "GET_MARKS", payload: response.data });
    } catch (err) {
        return errorHandling(err);
    }
}

export const updateMessage = (id, text) => async (dispatch) => {
    try {
        const response = await axios.put(api + "/message/" + id, {text}, getHeaders());
        dispatch({ type: "EDIT_MESSAGE", payload: null });
        return response.data;
    } catch (err) {
        return errorHandling(err);
    }
}

export const localEditMessage = (message) => (dispatch) => {
    try {
        dispatch({ type: "EDIT_MESSAGE", payload: message });
    } catch (err) {
        return errorHandling(err);
    }
}

export const deleteMessage = (id) => async (dispatch) => {
    try {
        const response = await axios.delete(api + "/message/" + id, getHeaders());
        dispatch({ type: "EDIT_MESSAGE", payload: null });
        return response.data;
    } catch (err) {
        return errorHandling(err);
    }
}

export const getThread = (id, shop, platform) => async (dispatch) => {
    try {
        const response = await axios.get(`${api}/message-thread/${id}?${queryString({shop, platform})}`, getHeaders());
        dispatch({ type: "GET_THREADS", payload: response.data });
    } catch (err) {
        return errorHandling(err);
    }
}

export const unreadMessages = (id, shop, platform) => async () => {
    try {
        const response = await axios.put(`${api}/unread-messages/${id}`, {shop, platform}, getHeaders());
        return response.data;
    } catch (err) {
        return errorHandling(err);
    }
}

export const putActionActivity = (action, activity, props) => async () => {
    try {
        const response = await axios.put(api + `/actions/activities/${action}/${activity}`, props, getHeaders());
        return response.data
    } catch (err) {
        return errorHandling(err);
    }
}