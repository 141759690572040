import React, { useState, useEffect } from 'react';

import { Typography, Collapse, List, Chip, Box} from '@mui/material';
import ArticleIcon from '@mui/icons-material/Article';
import FilterBAndWIcon from '@mui/icons-material/FilterBAndW';
import GridViewIcon from '@mui/icons-material/GridView';
import { i18n } from '../../translate/i18n';

const AiContent = ({ MenuLink }) => {
    const [isOpen, setIsOpen] = useState(localStorage.getItem('AiContentSidebar') === 'false' ? false : true);

    const handleToggleCollapse = () => {
        setIsOpen(!isOpen);
    };

    useEffect(() => {
        localStorage.setItem('AiContentSidebar', isOpen);
    }, [isOpen]);

    return (
        <div>
            <Box display="flex" justifyContent="space-between" alignItems="center">

                <Typography onClick={handleToggleCollapse} variant="h6" sx={{ cursor: 'pointer', color: 'white', fontSize: '14px', fontWeight: 'bold', marginTop: '10px', marginBottom: '10px' }}>
                    {i18n.t('components.sideBar.aiContent.creation')}
                </Typography>
                <Chip label="Beta" size="small" sx={{ marginLeft: '5px', height: "1rem", backgroundColor: '#00D6CF', color: 'white' }} />
            </Box>
            <Collapse in={isOpen}>
                <List component="nav">
                    <MenuLink to="/aicontent/redacting" Icon={ArticleIcon}>{i18n.t('components.sideBar.aiContent.redaction')}</MenuLink>
                    <MenuLink to="/aicontent/images" Icon={FilterBAndWIcon}>{i18n.t('components.sideBar.aiContent.images')}</MenuLink>
                    <MenuLink to="/aicontent/library" Icon={GridViewIcon}>{i18n.t('components.sideBar.aiContent.library')}</MenuLink>
                </List>
            </Collapse>
        </div>
    );
};

export default AiContent;
