import axios from 'axios';
import {api} from '../../config'
import {getHeaders} from './localStorage'
import errorHandling from './errorHandling'
import {queryString} from '../../utils/String'

export const addActivity = (props) => async () => {
    try {
        const response = await axios.post(api + `/activities`,props , getHeaders());
        return response.data
    } catch (err) {
        return errorHandling(err);
    }
}

export const getActivities = (params) => async () => {
    try {
        const response = await axios.get(api + `/activities/?${params}`, getHeaders());
        return response.data.activities
    } catch (err) {
        return errorHandling(err);
    }
}

export const getVersionsActivities = () => async (dispatch) => {
    try {
        const response = await axios.get(api + `/versions-activities`, getHeaders());
        dispatch({ type: "GET_ACTIVITIES_VERSIONS", payload: response.data.versions, version: response.data.version });
    } catch (err) {
        return errorHandling(err);
    }
}

export const uploadTable = (props) => async () => {
    try {
        const response = await axios.post(api + `/upload-table-activities`,props , getHeaders());
        return response.data
    } catch (err) {
        return errorHandling(err);
    }
}

export const getActivitiesByVersion = (version, props) => async (dispatch) => {
    try {
        const response = await axios.get(api + `/activities-table/${version}?${queryString(props)}`, getHeaders());
        dispatch({ type: "GET_ACTIVITIES", payload: response.data.activities, categories: response.data.categories });
    } catch (err) {
        return errorHandling(err);
    }
}

export const uploadVersion = (props) => async () => {
    try {
        const response = await axios.put(api + `/version-activities/${props.version}`, {} , getHeaders());
        return response.data
    } catch (err) {
        return errorHandling(err);
    }
}

export const putAgencyActivities = (props) => async () => {
    try {
        const response = await axios.put(api + `/agency-activities`, props , getHeaders());
        return response.data
    } catch (err) {
        return errorHandling(err);
    }
}