import { giveSelectTrimesters } from "./HeaderButtons/CycleSelector";
import store from '../../../store/store';


export const updateActionsTrimester = (actions, addAll = false) => {
  const filteredActions = actions.filter((action) =>
  !["Cancelado", "Concluído"].includes(action.status)
  );
  
  const cycleData = store.getState().actions.cycle;
  const cycles = giveSelectTrimesters(new Date());
  const index = cycles.findIndex((cycle) => cycle.label === cycleData);
  const cycle = giveSelectTrimesters(new Date())[index + 1].label;
  
  const updatedActions = filteredActions.map((action) => {
    if(action.cycle.includes(cycle)) return action;
    if (typeof action.cycle === 'string') action.cycle = [action.cycle, cycle];
    else action.cycle.push(cycle);
    return {_id: action._id, cycle: action.cycle};
  });

  return updatedActions;
};

export const removeActionFromTrimester = (actions) => {
  const filteredActions = actions.filter((action) =>
  !["Cancelado"].includes(action.status)
  );
  
  const cycleData = store.getState().actions.cycle;
  
  const updatedActions = filteredActions.map((action) => {
    if(!action.cycle.includes(cycleData) || action.cycle.length === 1) return action;
    const index = action.cycle.findIndex((cycle) => cycle === cycleData);
    action.cycle.splice(index, 1);
    return {_id: action._id, cycle: action.cycle};
  });

  return updatedActions;
}