import React, { useCallback, useEffect, useState } from "react";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { connect } from "react-redux";
import ActivityCardArea from "./ActivityCardArea";
import { isMobile } from "react-device-detect";
import ProblemCardArea from "./ProblemCardArea";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { i18n } from "../../../translate/i18n";

const ActionCardArea = (props) => {
  const location = useLocation();
  const isPerformance = location.pathname.split('/')[1] === "support-performance";

  const handleLabel = (label) => {
    switch (label) {
      case "Para fazer": return i18n.t('sprint.kanban.actionCardArea.toDo');
      case "Em andamento": return i18n.t('sprint.kanban.actionCardArea.inProgress');
      case "Homologação": return i18n.t('sprint.kanban.actionCardArea.customerApproval');
      case "Concluído/Aplicado": return i18n.t('sprint.kanban.actionCardArea.doneApplied');
      default: return label;
    }
  }

  return (
    <Box sx={{ minHeight: isMobile ? '500px' : 'initial' }} >
      <Box
        sx={{
          minHeight: "100%",
          bgcolor: "#F0F0F0",
          padding: 1,
          borderRadius: "5px",
        }}
      >
        <Typography
          variant="h5"
          alignSelf={"center"}
          sx={{ padding: "0.5rem" }}
        >
          {handleLabel(props.label)}
        </Typography>
        {
          isPerformance &&
            <ProblemCardArea
              user={props.user}
              label={props.label}
              problems={props.problems?.docs}
              setCurrentPageInfo={props.setCurrentPageInfo}
            />
        }
        {
          props.actions?.docs.map((action) =>
            <ActivityCardArea
              key={action._id}
              user={props.user}
              label={props.label}
              action={action}
              setCurrentPageInfo={props.setCurrentPageInfo}
              updateActivityStatus={props.updateActivityStatus}
              customer={props.customer}
            />
          )
        }
      </Box>
    </Box >
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  shop: state.auth.shop,
  actions: state.actions.actions,
  cycle: state.actions.cycle,
  problems: state.problems.problems,
  customer: state.customer.customerDetails,
});

export default connect(mapStateToProps)(ActionCardArea);
