import { connect } from 'react-redux'
import * as Yup from 'yup';
import { Form, FormikProvider, useFormik } from 'formik';
import { toast } from 'react-toastify';
import { useHistory } from "react-router-dom";
import { getViewUser, handleTrialAddUser, handleUpdateUser } from '../../store/actions/users'
import { getCustomers } from '../../store/actions/customers'
import { LoadingButton } from '@mui/lab';
import { Box, Button, Card, CircularProgress, Grid, TextField, Typography } from '@mui/material';
import { mdiGoogle, mdiMicrosoft, mdiSlack } from '@mdi/js';
import Icon from '@mdi/react';
import logo from '../../assets/logo-fullcomm-horizontalnew.svg'
import backgroundImg from '../../assets/background_1.png'
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { handleLogin } from '../../store/actions';
import { i18n } from '../../translate/i18n';
import "../../styles/login/index.css"
import { useAuth0 } from '@auth0/auth0-react';

const AddTrialUser = (props) => {
  const { loginWithRedirect } = useAuth0();
  const NewUserSchema = Yup.object().shape({
    // name: Yup.string().required(i18n.t('addTrialUser.nameRequired')),
    email: Yup.string().email(i18n.t('addTrialUser.emailValid')).required(i18n.t('addTrialUser.emailRequired')),
    // phone: Yup.string().required(i18n.t('addTrialUser.phoneRequired')),
    password: Yup.string()
    .matches(/^(?=.*[0-9])(?=.*[A-Z]).{8,}$/, i18n.t('addTrialUser.passwordRule'))
    .required(i18n.t('addTrialUser.passwordRequired')),    passwordConfirm: Yup.string()
      .oneOf([Yup.ref('password')], i18n.t('addTrialUser.passwordEqual'))
      .required(i18n.t('addTrialUser.passwordConfirm')),
  });


  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: '',
      email: '',
      password: '',
      passwordConfirm: '',
    },
    validationSchema: NewUserSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {

        const result = await props.addTrialUser(values);
        if (result.error) {
          setSubmitting(false);
          switch (result.error.message) {
            case "Dados inválidos.": return toast.error(i18n.t('addTrialUser.invalidData'));
            case "Este e-mail já esta cadastrado.": return toast.error(i18n.t('addTrialUser.alreadyRegistered'));
            case "Usuário não foi cadastrado com sucesso!": return toast.error(i18n.t('addTrialUser.registerError'));
            default: return toast.error(result.error.message);
          }
        } else {
          if (result.message === "Usuário cadastrado com sucesso!") {
            toast.success(i18n.t('addTrialUser.registerSuccess'))
          } else {
            toast.success(result.message);
          }
          setSubmitting(false);
          // await props.loginUser({ email: values.email, password: values.password });
          // history.push('/');
          loginWithRedirect();
        }
      } catch (error) {
        setSubmitting(false);
        toast.error(error.message);
      }
    }
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  return (
    <Box
      sx={{
        backgroundImage: `url(${backgroundImg})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundColor: "#333",
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <FormikProvider value={formik}>
        <Form noValidate autoComplete="off" onSubmit={handleSubmit} style={{display: 'flex', justifyContent: 'center', width: "100%"}}>
          <Card sx={{ borderRadius: "16px", minWidth: "350px", width: "30%", maxWidth: "500px" }}>
            <Grid container spacing={2} padding={"3rem"}>
              <Box display="flex" justifyContent="center" width="100%" mb="1rem">
                <img className='logo-img ' src={logo} alt="Company Logo" />
              </Box>
              {/* <Box display={"grid"} width={"100%"} justifyContent={"center"}>
                <Typography variant="h1" align={"center"} marginBottom={"1.5rem"}>Experimente a FullComm</Typography>
              </Box> */}
              {/* <Grid item xs={12}>
                <TextField
                  fullWidth
                  label={i18n.t('addTrialUser.completeName')}
                  {...getFieldProps('name')}
                  error={Boolean(touched.name && errors.name)}
                  helperText={touched.name && errors.name}
                />
              </Grid> */}
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  type="email"
                  label={i18n.t('addTrialUser.email')}
                  {...getFieldProps('email')}
                  error={Boolean(touched.email && errors.email)}
                  helperText={touched.email && errors.email}
                />
              </Grid>
              {/* <Grid item xs={12}>
                <TextField
                  fullWidth
                  type="tel"
                  label={i18n.t('addTrialUser.phone')}
                  {...getFieldProps('phone')}
                  error={Boolean(touched.phone && errors.phone)}
                  helperText={touched.phone && errors.phone}
                />
              </Grid> */}
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  type="password"
                  label={i18n.t('addTrialUser.password')}
                  {...getFieldProps('password')}
                  error={Boolean(touched.password && errors.password)}
                  helperText={touched.password && errors.password}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  type="password"
                  label={i18n.t('addTrialUser.passwordConfirmShort')}
                  {...getFieldProps('passwordConfirm')}
                  error={Boolean(touched.passwordConfirm && errors.passwordConfirm)}
                  helperText={touched.passwordConfirm && errors.passwordConfirm}
                />
              </Grid>
              <Grid item xs={12} display="flex" flexDirection="column" gap="0.25rem" alignItems="center">
                <LoadingButton
                  sx={{ textTransform: 'none' }}
                  fullWidth
                  type="submit"
                  variant="contained"
                  loadingIndicator={<CircularProgress size={20} sx={{ color: '#fff' }} />}
                  loading={isSubmitting}
                >{i18n.t('addTrialUser.createAccount')}
                </LoadingButton>
                <Typography
                  onClick={() => loginWithRedirect()}
                  sx={{
                    cursor: 'pointer',
                    '&:hover': {
                      textDecoration: 'underline'
                    }
                  }}
                  variant='body'
                >
                  {i18n.t('addTrialUser.hasAccount')}
                </Typography>
              </Grid>
              {/* <Grid item xs={12}>
                <Typography align="center">{i18n.t('addTrialUser.createVia')}</Typography>
              </Grid> */}
              {/* <Grid item xs={4}>
                <Button
                  fullWidth
                  variant='contained'
                  className='btn-gray'
                  sx={{ gap: '7px' }}
                >
                  <Icon path={mdiGoogle} size={0.75} />
                  Google
                </Button>
              </Grid>
              <Grid item xs={4}>
                <Button
                  fullWidth
                  variant='contained'
                  className='btn-gray'
                  sx={{ gap: '7px' }}
                >
                  <Icon path={mdiMicrosoft} size={0.75} />
                  Microsoft
                </Button>
              </Grid>
              <Grid item xs={4}>
                <Button
                  fullWidth
                  variant='contained'
                  className='btn-gray'
                  sx={{ gap: '7px' }}

                >
                  <Icon path={mdiSlack} size={0.75} />
                  Slack
                </Button>
              </Grid> */}
            </Grid>
          </Card>
        </Form>
      </FormikProvider >
    </Box>
  )
}



const mapStateToProps = state => {
  return ({
    user: state.user.userDetails,
    auth: state.auth.user,
    customers: state.customer.customers
  })
}

const mapDispatchToProps = (dispatch) => {
  return {
    addTrialUser: (data) => dispatch(handleTrialAddUser(data)),
    getViewUser: (id) => dispatch(getViewUser(id)),
    updateUser: (data) => dispatch(handleUpdateUser(data)),
    getCustomers: (page, limit) => dispatch(getCustomers(page, limit)),
    loginUser: (data) => dispatch(handleLogin(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddTrialUser);