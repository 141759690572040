export const statusColor = (status) => {
  switch (status) {
    case 'Ativo':
      return { bgcolor: '#85bc3a', color: 'white', type: 'active' };
    case 'Cancelado':
      return { bgcolor: '#F0F0F0', color: '#878c8e', type: 'cancelled' };
    case 'Q/A':
    case 'Homologação':
      return { bgcolor: '#ff6f00', color: 'white', type: 'pending_approval', icon: 'trip_origin' };
    case 'Em andamento':
      return { bgcolor: '#E0A40A', color: 'white', type: 'ongoing' };
    case 'Concluído/Aplicado':
    case 'Concluído':
      return { bgcolor: '#2c82d2', color: 'white', type: 'concluded', icon: 'done' };
    case 'Para fazer':
      return { bgcolor: '#DB4E2F', color: 'white', type: 'to_do', icon: 'trip_origin' };
    case 'Planejamento':
      return { bgcolor: '#B19CD9', color: 'white', type: 'planned', icon: 'trip_origin' };
    case 'Aprovado':
      return { type: 'active', icon: 'done' };
    case 'Reprovado':
      return { type: 'to_do', icon: 'close' };
    default:
      return { bgcolor: '#86888c', color: 'black', type: 'backlog', icon: 'trip_origin' };
  }
};

export function handleHealthStatus(score) {
  if (score <= 25) {
    return "#db4e2f";
  } else if (score >= 75) {
    return "#007f60";
  }
  return "#e0a409";
}