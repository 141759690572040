import React from 'react';
import WideDashboard from './WideDashboard';

import {connect} from 'react-redux';
import * as actions from '../../../store/actions'

//this component was made to be used as a wider version of the dashboard, but ended up just changing the original baseboard. So right now it's not in use
const wideDashboard = (Component, permissions = null) => {
    class ComponentWideDashboard extends React.Component{
        componentDidMount(){
            const {getUser} = this.props;
        getUser();
    }

    componentDidUpdate() {
        const { history, authorized, user } = this.props;
        if (!authorized) return history.replace("/")
        if (!permissions?.includes(user?.role) && permissions !== null) return history.replace("/dashboard")
    }

    render() {
        return (
            <WideDashboard>
                <Component {...this.props} />
            </WideDashboard>
        )
    }
}

const mapStateToProps = state => ({
    authorized: state.auth.authorized,
    user: state.auth.user
})

return connect(mapStateToProps, actions)(ComponentWideDashboard)
}

export default wideDashboard;