import React from 'react';
import { Box, Card, Typography, Tooltip, IconButton, Grid } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { formatTime } from '../../utils/String';
import { i18n } from '../../translate/i18n';

const MediaTime = ({ ordersStatusPlatinum }) =>
    <Grid container spacing={2}>
        <Grid item xs={6} tablet={3}>
            <Card className='card'>
                <Box>
                    <Typography className="title">
                        { i18n.t('components.platinumlog.mediaTime.invoicing') }
                    </Typography>
                </Box>
                <Typography className='number'>
                    {formatTime(ordersStatusPlatinum?.mediaProccess?.['createdUntilInvoiced']) || "-"}
                </Typography>
                <Tooltip title={<>{i18n.t('components.platinumlog.mediaTime.timeCreateToSendNf')}</>}>
                    <InfoIcon sx={{ width: '15px', position: 'absolute', top: 0, right: '10px', color: '#9e9e9e' }} />
                </Tooltip>
            </Card>
        </Grid>
        <Grid item xs={6} tablet={3}>
            <Card className='card'>
                <Box>
                    <Typography className="title">
                        {i18n.t('components.platinumlog.mediaTime.expedition')}
                    </Typography>
                </Box>
                <Typography className='number'>
                    {formatTime(ordersStatusPlatinum?.mediaProccess?.['invoicedUntilEndCheckout']) || "-"}
                </Typography>
                <Tooltip title={<>{i18n.t('components.platinumlog.mediaTime.timeSendToDispatching')}</>}>
                    <InfoIcon sx={{ width: '15px', position: 'absolute', top: 0, right: '10px', color: '#9e9e9e' }} />
                </Tooltip>
            </Card>
        </Grid>
        <Grid item xs={6} tablet={3}>
            <Card className='card'>
                <Box>
                    <Typography className="title">
                        {i18n.t('components.platinumlog.mediaTime.delivery')}
                    </Typography>
                </Box>
                <Typography className='number'>
                    {formatTime(ordersStatusPlatinum?.mediaProccess?.['endCheckoutUntilDelivered']) || "-"}
                </Typography>
                <Tooltip title={<>{i18n.t('components.platinumlog.mediaTime.timeCollectToDelivered')}</>}>
                    <InfoIcon sx={{ width: '15px', position: 'absolute', top: 0, right: '10px', color: '#9e9e9e' }} />
                </Tooltip>
            </Card>
         </Grid>
         <Grid item xs={6} tablet={3}>
            <Card className='card'>
                <Box>
                    <Typography className="title">
                        {i18n.t('components.platinumlog.mediaTime.total')}
                    </Typography>
                </Box>
                <Typography className='number'>
                    {formatTime(ordersStatusPlatinum?.mediaProccess?.['allProccess']) || "-"}
                </Typography>
                <Tooltip title={<>{i18n.t('components.platinumlog.mediaTime.totalAverage')}</>}>
                    <InfoIcon sx={{ width: '15px', position: 'absolute', top: 0, right: '10px', color: '#9e9e9e' }} />
                </Tooltip>
            </Card>
         </Grid>
    </Grid>

export default MediaTime;