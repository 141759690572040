import React, { Component } from 'react';
import { Form } from 'reactstrap';
import { Box, Typography } from '@mui/material';
import {connect} from 'react-redux'
import AlertDanger from '../../components/AlertDanger'
import AlertSuccess from '../../components/AlertSuccess'
import * as actions from '../../store/actions/users'
import { i18n } from '../../translate/i18n';
import dayjs from 'dayjs';
import { LoadingButton } from '@mui/lab';
import { FCReturnButton, FCSelect, FCTelInput, FCTextField, FCTimePicker } from 'components-fullcomm';

class UpdatePerfil extends Component{

    state = {
        _id: "",
        name: "",
        error: "",
        success: "",
        loading: false,
        telephone: "+55",
        legacyChatStyle: false,
        start: dayjs(new Date()),
        end: dayjs(new Date()),
    }

    componentDidMount(){
        const {name, _id, telephone, legacyChatStyle, workingHours} = this.props.user || {};
        this.setState({name})
        this.setState({_id})
        this.setState({telephone})
        this.setState({legacyChatStyle})
        this.setState({start: dayjs(workingHours?.start)})
        this.setState({end: dayjs(workingHours?.end)})
    }

    onChangeInput = (ev) => {
        this.setState({[ev.target.name]: ev.target.value})
    }

    validate(){
        this.setState({success: ""});
        const {name, email} = this.state;
        if(!name){
            return this.setState({error: {message: i18n.t('updatePerfil.informName')}})
        }

        return true;
    }

    handleUpdate(){
        const {name, email, _id, telephone, legacyChatStyle, start, end} = this.state;

        if(!this.validate()) return
        
        this.setState({loading: true});

        if (dayjs(start).isAfter(dayjs(end)) || dayjs(start).isSame(dayjs(end))) {
            this.setState({error: {message: i18n.t('perfil.invalidHours')}, loading: false});
            return;
        }

        this.props.handleUpdatePerfil({name, _id, telephone, legacyChatStyle, workingHours: { start: start["$d"], end: end["$d"] }}, (msg) => {
            if(msg.error.error){
                if (msg.error.message === "Ocorreu um erro ao atualizar o usuário.") {
                    this.setState({success: {message: i18n.t('updatePerfil.errorUpdate')}});
                } else {
                    this.setState({error: {message: msg.error.message}}) 
                }
                this.setState({success: ""});
            }else{
                this.setState({error: ""});
                if (msg.error.message === "Perfil atualizado com sucesso!") {
                    this.setState({success: {message: i18n.t('updatePerfil.successUpdate')}});
                } else {
                    this.setState({success: {message: msg.error.message}});
                }
                this.updateDataUser()
            }
            
            this.setState({loading: false});
        });

    }

    async updateDataUser(){
        await this.props.viewPerfil(() => {
            this.setState({loading: false})
        })
    }

    render(){
        const {name, email, loading, error, success, legacyChatStyle, telephone, start, end} = this.state
        return(
            <div className='p-4'>
                <div className="mr-auto p-2 d-flex align-items-center">
                    <FCReturnButton />
                    <Typography variant="h4">{i18n.t('updatePerfil.editPerfil')}</Typography>
                </div>
            <AlertDanger error={error}/>
                <AlertSuccess error={success}/>
            <Form>
                <Box display='flex' flexDirection='column' gap='0.5rem'>
                    <FCTextField
                        fullWidth
                        label={i18n.t('updatePerfil.name')}
                        value={name}
                        onChange={(ev) => this.onChangeInput(ev)}
                        autoComplete="name"
                    />

                    <FCTelInput
                        fullWidth
                        sx={{mb: "1rem"}}
                        label={i18n.t('dataEnrichmentModal.intro.phone')}
                        size='small'
                        lang={localStorage.getItem('i18nextLng') || this.props?.user?.language}
                        value={telephone}
                        onChange={(e) => this.setState({telephone: e})}
                    />
                    {
                        this.props?.user?.role === "admin" && (
                            <>
                                <FCSelect
                                    fullWidth
                                    size='small'
                                    label={i18n.t('updatePerfil.chatStyle')}
                                    name='legacyChatStyle'
                                    value={legacyChatStyle}
                                    onChange={(ev) => this.onChangeInput(ev)}
                                    options={[
                                        {label: i18n.t('updatePerfil.new'), value: false},
                                        {label: i18n.t('updatePerfil.legacy'), value: true}
                                    ]}
                                />

                                <Box display='flex' gap='0.5rem' mb='0.5rem'>
                                    <FCTimePicker
                                        value={start}
                                        onChange={(date) => this.setState({start: date})}
                                        label={i18n.t('updatePerfil.startWorkingHours')}
                                        fullWidth
                                    />
                                    <FCTimePicker
                                        fullWidth
                                        value={end}
                                        onChange={(date) => this.setState({end: date})}
                                        label={i18n.t('updatePerfil.endWorkingHours')}
                                    />
                                </Box>
                            </>
                        )
                    }
                    </Box>
                    <LoadingButton
                        className='btn-action large'
                        loading={loading}
                        onClick={() => this.handleUpdate()}
                        variant="contained"
                        sx={{
                            float: 'right',
                            mt: '0.5rem',
                        }}
                    >
                        {i18n.t('updatePerfil.save')}
                    </LoadingButton>
                </Form>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    userDetails: state.user.userDetails
})

export default connect(mapStateToProps, actions)(UpdatePerfil);