import axios from 'axios';
import {api} from '../../config'
import {getToken, saveToken, getHeaders, clearToken} from './localStorage'
import errorHandling from './errorHandling'

export const handleLogin = (props, callback) => {
    return function(dispatch){
        axios.post(api+"/login", props).then((response) => {
            saveToken(response.data);
            dispatch({type: "LOGIN_USER", payload: response.data})
            if(response.data?.user.customer.length > 0) {
                localStorage.setItem('shop', response.data.user.customer[0]._id)
                dispatch({type: "SET_SHOP", payload: response.data.user.customer[0]._id})
            };
        }).catch((err) => callback(errorHandling(err)))
    }
}

export const getAuth0User = (props, callback) => {
    return function(dispatch){
        axios.post(api+"/login/auth0", props).then((response) => {
            saveToken(response.data);
            dispatch({type: "LOGIN_USER", payload: response.data})
            if(response.data?.user.customer.length > 0) {
                localStorage.setItem('shop', response.data.user.customer[0]._id)
                dispatch({type: "SET_SHOP", payload: response.data.user.customer[0]._id})
            };
        }).catch((err) => callback(errorHandling(err)))
    }
}

export const handleShop = (shop) => {
    return function(dispatch){
        localStorage.setItem('shop', shop);
        dispatch({type: "SET_SHOP", payload: shop})
    }
}

export const getUser = () => {
    return function(dispatch){
        if(getToken()){
            axios.get(api+"/perfil", getHeaders()).then((response) => {
                saveToken(response.data);
                dispatch({type: "LOGIN_USER", payload: response.data, shop: localStorage.getItem('shop')})
            }).catch((err) => {
                clearToken()
                dispatch({type: "LOGOUT_USER"})
            })
        }else{
            clearToken()
            dispatch({type: "LOGOUT_USER"})
        }
    }
}

export const getInfoDashboard = (callback) => {
    return function(dispatch){
        axios.get(api+"/info-dashboard", getHeaders()).then((response) => {
            callback({data: response.data});
        }).catch(() => {
            
        })
    }
}

export const handleLogout = () => {
    window.productFruits?.services?.destroy();
    clearToken()
    return {type: "LOGOUT_USER"}
}