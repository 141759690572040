export const formatText = (text) => {
  const patterns = [
    { regex: /(https?:\/\/[^\s<]+)/g, tag: 'a' },
    { regex: /\*(.*?)\*/g, tag: 'strong' },
    { regex: /_(.*?)_/g, tag: 'em' },
    { regex: /-(.*?)-/g, tag: 'span' },
  ];

  const lines = text?.split('\n');

  const formattedLines = lines?.map((line, index) => {
    let formattedLine = line;

    patterns.forEach(({ regex, tag }) => {
      if (tag === 'a') {
        formattedLine = formattedLine.replace(regex, `<${tag} target="_blank" href="$1"}>$1</${tag}>`);
      } else {
        formattedLine = formattedLine.replace(regex, `<${tag}>$1</${tag}>`);
      }
    });

    if (index !== lines.length - 1) {
      formattedLine += '<br>';
    }

    return formattedLine;
  });

  return formattedLines?.join('');
};