import React, { Component } from 'react';
import {connect} from 'react-redux'
import * as actions from '../../store/actions/users'
import {format} from 'date-fns'
import pt from 'date-fns/locale/pt'
import {Spinner} from 'reactstrap';
import { i18n } from '../../translate/i18n';

class DetailsUser extends Component{

    renderInfoUser(){
        if(!this.props.userDetails) return null;

        return(
            <dl className="row">
                <dt className="col-sm-3">{i18n.t('viewUser.detailsUser.id')}</dt>
                <dd className="col-sm-9">{this.props.userDetails ? this.props.userDetails._id : ""}</dd>

                <dt className="col-sm-3">{i18n.t('viewUser.detailsUser.name')}</dt>
                <dd className="col-sm-9">{this.props.userDetails ? this.props.userDetails.name : ""}</dd>

                <dt className="col-sm-3">{i18n.t('viewUser.detailsUser.email')}</dt>
                <dd className="col-sm-9">{this.props.userDetails ? this.props.userDetails.email : ""}</dd>

                <dt className="col-sm-3">{i18n.t('viewUser.detailsUser.function')}</dt>
                <dd className="col-sm-9">{this.props.userDetails ? this.props.userDetails.role : ""}</dd>

                <dt className="col-sm-3">{i18n.t('viewUser.detailsUser.dateRegister')}</dt>
                <dd className="col-sm-9">{this.props.userDetails ? format(new Date(this.props.userDetails.createdAt), "dd/MM/yyyy hh:mm:ss", {locale: pt}) : ""}</dd>

                <dt className="col-sm-3">{i18n.t('viewUser.detailsUser.editIn')}</dt>
                <dd className="col-sm-9">{this.props.userDetails.updatedAt ? format(new Date(this.props.userDetails.updatedAt), "dd/MM/yyyy hh:mm:ss", {locale: pt}) : ""}</dd>
                
            </dl>
        )
    }

    render(){
        return (
            <>
            {this.props.userDetails ? "" : <div className="d-flex justify-content-center"><Spinner color="primary" /></div>}
            {this.renderInfoUser()}
            </>
        )  
    }
}

const mapStateToProps = state => ({
    userDetails: state.user.userDetails,
    user: state.auth.usuario
})

export default connect(mapStateToProps, actions)(DetailsUser);