import { combineReducers } from 'redux';
import authReducer from './auth_reducer';
import userReducer from './users_reducer';
import formReducer from './form_reducers';
import customerReducer from './customers_reducer';
import projectReducer from './projects_reducer';
import taskReducer from './tasks_reducer';
import checklistReducer from './checklists_reduce';
import messageReducer from './messages_reducer';
import permissionsReducer from './permissions_reducer';
import actionsReducer from './actions_reducer';
import activitiesReducer from './activities_reducer';
import contractsReducer from './contracts_reducer';
import notificationsReducer from './notifications_reducer';
import agenciesReducer from './agencies_reducer';
import scaleReducer from './scale_reducers';
import problemsReducer from './problems_reducer';
import appsReducer from './apps_reducer';
import aiContentsReducer from './ai_contents_reducer';
import insightReducer from './insights_reducer';
import documentsReducer from './documents_reducer';
import goalsReducer from './goals_reducer';

const reducers = combineReducers({
  auth: authReducer,
  user: userReducer,
  form: formReducer,
  customer: customerReducer,
  project: projectReducer,
  task: taskReducer,
  checklist: checklistReducer,
  message: messageReducer,
  permission: permissionsReducer, 
  notifications: notificationsReducer,
  actions: actionsReducer,
  activities: activitiesReducer,
  contract: contractsReducer,
  agencies: agenciesReducer,
  scale: scaleReducer,
  problems: problemsReducer,
  app: appsReducer,
  aiContent: aiContentsReducer,
  insight: insightReducer,
  documents: documentsReducer,
  goals: goalsReducer,
});

export default reducers;
