import { Box, Button, Grid, Paper } from '@mui/material'
import { FormikProvider, useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { Form } from 'reactstrap'
import { api } from '../../../config'
import * as actions from '../../../store/actions/apps'
import { connect } from 'react-redux'
import { i18n } from '../../../translate/i18n';
import { FCTextField } from 'components-fullcomm'

const AppFormCms = ({ category, updateApp, updateAppFiles, app, deleteAppFiles, getApp }) => {
  const [files, setFiles] = useState(app?.files || []);

  useEffect(() => {
    if (app?.description && category === app?.category) {

      Object.keys(app.description).forEach((keyName, index) => {
        if (keyName === "category") return;
        Object.keys(app.description[keyName]).forEach((key) => {
          formik.setFieldValue(`${key}${index + 1}`, app.description[keyName][key]);
        });
      });
      setFiles(app?.files || []);
    } else {
      setFiles([]);
    }
  }, [app]);

  const handleFile = (event) => {
    const newFiles = file.value ? [...event.target.files, ...file.value] : [...event.target.files];
    formik.setFieldValue('file', newFiles);
  };

  const handleDelete = (fileName) => {
    const updatedFiles = file?.value.filter((file) => file.name !== fileName);
    formik.setFieldValue('file', updatedFiles);
  };

  var formik = useFormik({
    enableReinitialize: true,
    validateOnMount: true,
    initialValues: {
      title1: "",
      link1: "",
      description1: "",
      title2: "",
      buttonText2: "",
      description2: "",
      file: [],
    },
    // validationSchema: NewActionSchema,

    onSubmit: async (values, { setSubmitting, resetForm }) => {
      try {

        const formData = new FormData();
        if (values.file) {
          for (let i = 0; i < values.file.length; i++) {
            formData.append("file", values.file[i]);
          }
        }

        delete values.file;

        const aux = {};
        Object.keys(values).forEach(key => {
          const lastChar = key.slice(-1);
          const keyName = key.slice(0, -1);
          aux[`group${lastChar}`] = { ...aux[`group${lastChar}`], [keyName]: values[key] }
        });
        const response = await updateApp({ category, description: aux });
        const upload = await updateAppFiles(category, formData);
        if (upload.error) {
          switch (upload.error.message) {
            case "Erro ao enviar arquivos!": return toast.error(i18n.t('apps.utils.appFormCms.sendFilesError'));
            case "Erro ao atualizar arquivos!": return toast.error(i18n.t('apps.utils.appFormCms.updateFilesError'));
            case "Erro interno do servidor.": return toast.error(i18n.t('apps.utils.appFormCms.internalError'));
            default: return toast.error(upload.error.message);
          }
        }
        getApp(`category=${category}`);

        if (response.error) {
          switch (response.error.message) {
            case "Erro ao atualizar app!": return toast.error(i18n.t('apps.utils.appFormCms.updateAppError'));
            case "Erro interno do servidor.": return toast.error(i18n.t('apps.utils.appFormCms.internalError'));
            default: return toast.error(response.error.message);
          }
        } else {
          if (response.message === 'App criado com sucesso!') {
            toast.success(i18n.t('apps.utils.appFormCms.createAppSuccess'))
          } else if (response.message === 'App atualizado com sucesso!') {
            toast.success(i18n.t('apps.utils.appFormCms.updateAppSuccess'))
          } else {
            toast.success(response.message)
          }
        }
      } catch (error) {
        if (error.error.message === 'Erro interno do servidor.') {
          toast.error(i18n.t('apps.utils.appFormCms.internalError'))
        } else {
          toast.error(error.error.message)
        }
      }
    },
  });

  var file = formik.getFieldProps("file");
  const { isSubmitting, handleSubmit, getFieldProps, touched, errors } = formik;

  const handleClickFile = (fileName) => {
    const url = `${api}/files/app/${category}/${fileName}`;
    window.open(url, '_blank');
  };

  const handleDeleteFile = async (fileName) => {
    const response = await deleteAppFiles(category, fileName);
    if (response.error) toast.error(response.error.message);
    else {
      toast.success(response.message);
      const index = app.files.findIndex((file) => file.id === fileName);
      if (index !== -1) {
        const newFiles = [...app.files];
        newFiles.splice(index, 1);
        setFiles(newFiles);
      }
    }

  };

  return (
    <Box>
      <FormikProvider value={formik}>
        <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
          <Box display={"flex"} justifyContent={"end"} mb={"1rem"}>
            <Button variant="contained" className='btn-action' sx={{ height: '36px' }} type='submit' onClick={() => { }}>
              {i18n.t('apps.utils.appFormCms.sincAlt')}
            </Button>
          </Box>
          <Paper sx={{ padding: "1rem", my: "1rem", borderRadius: '5px' }}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                  <FCTextField
                    fullWidth
                    label={i18n.t('apps.utils.appFormCms.title1')}
                    placeholder={i18n.t('apps.utils.appFormCms.title1')}
                    {...getFieldProps("title1")}
                    error={Boolean(touched.title1 && errors.title1)}
                    helperText={touched.title1 && errors.title1}
                  />
              </Grid>
              <Grid item xs={6}>
                <FCTextField
                  fullWidth
                  label={i18n.t('apps.utils.appFormCms.link1')}
                  placeholder={i18n.t('apps.utils.appFormCms.link1')}
                  {...getFieldProps("link11")}
                  error={Boolean(touched.link11 && errors.link11)}
                  helperText={touched.link11 && errors.link11}
                />
              </Grid>
              <Grid item xs={6}>
                <FCTextField
                  fullWidth
                  multiline
                  rows={6}
                  label={i18n.t('apps.utils.appFormCms.description1')}
                  placeholder={i18n.t('apps.utils.appFormCms.description1')}
                  {...getFieldProps("description1")}
                  error={Boolean(touched.description1 && errors.description1)}
                  helperText={touched.description1 && errors.description1}
                />
              </Grid>
              <Grid item xs={6} display={"flex"} flexDirection={"column"} gap={"1rem"}>
                <FCTextField
                  fullWidth
                  label={i18n.t('apps.utils.appFormCms.link2')}
                  placeholder={i18n.t('apps.utils.appFormCms.link2')}
                  {...getFieldProps("link21")}
                  error={Boolean(touched.link21 && errors.link21)}
                  helperText={touched.link21 && errors.link21}
                />
                <FCTextField
                  fullWidth
                  label={i18n.t('apps.utils.appFormCms.link3')}
                  placeholder={i18n.t('apps.utils.appFormCms.link3')}
                  {...getFieldProps("link31")}
                  error={Boolean(touched.link31 && errors.link31)}
                  helperText={touched.link31 && errors.link31}
                />
                <FCTextField
                  fullWidth
                  label={i18n.t('apps.utils.appFormCms.link4')}
                  placeholder={i18n.t('apps.utils.appFormCms.link4')}
                  {...getFieldProps("link41")}
                  error={Boolean(touched.link41 && errors.link41)}
                  helperText={touched.link41 && errors.link41}
                />
              </Grid>
              {/* <Grid item xs={12}>
                <FileSelectArea
                  handleDrop={(e) => {}}
                  file={file}
                  handleFile={handleFile}
                  handleDelete={handleDelete}
                />
                {
                  files?.map((file, index) => {
                    return (
                      <Chip
                      sx={{marginLeft: '0.5rem', marginY: '0.5rem'}}
                      key={index}
                      label={file.name}
                      onClick={()=>handleClickFile(file.id)}
                      onDelete={()=>handleDeleteFile(file.id)}
                    />
                    )
                  }
                )}
              </Grid> */}
            </Grid>
          </Paper>
          <Paper sx={{ padding: "1rem", my: "1rem", borderRadius: '5px' }}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <FCTextField
                  fullWidth
                  label={i18n.t('apps.utils.appFormCms.title2')}
                  placeholder={i18n.t('apps.utils.appFormCms.title2')}
                  {...getFieldProps("title2")}
                  error={Boolean(touched.title2 && errors.title2)}
                  helperText={touched.title2 && errors.title2}
                />
              </Grid>
              <Grid item xs={6}>
                <FCTextField
                  fullWidth
                  label={i18n.t('apps.utils.appFormCms.textButton')}
                  placeholder={i18n.t('apps.utils.appFormCms.textButton')}
                  {...getFieldProps("buttonText2")}
                  error={Boolean(touched.buttonText2 && errors.buttonText2)}
                  helperText={touched.buttonText2 && errors.buttonText2}
                />
              </Grid>
              <Grid item xs={6}>
                <FCTextField
                  fullWidth
                  multiline
                  rows={5}
                  label={i18n.t('apps.utils.appFormCms.description2')}
                  placeholder={i18n.t('apps.utils.appFormCms.description2')}
                  {...getFieldProps("description2")}
                  error={Boolean(touched.description2 && errors.description2)}
                  helperText={touched.description2 && errors.description2}
                />
              </Grid>
            </Grid>
          </Paper>
          <Paper sx={{ padding: "1rem", my: "1rem", borderRadius: '5px' }}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <FCTextField
                  fullWidth
                  label={i18n.t('apps.utils.appFormCms.subtitle1')}
                  placeholder={i18n.t('apps.utils.appFormCms.subtitle1')}
                  {...getFieldProps(`title3`)}
                  error={Boolean(touched[`title3`] && errors[`title3`])}
                  helperText={touched[`title3`] && errors[`title3`]}
                />
              </Grid>
              <Grid item xs={6}>
                <FCTextField
                  fullWidth
                  multiline
                  rows={5}
                  label={i18n.t('apps.utils.appFormCms.description3')}
                  placeholder={i18n.t('apps.utils.appFormCms.description3')}
                  {...getFieldProps(`description3`)}
                  error={Boolean(touched[`description3`] && errors[`description3`])}
                  helperText={touched[`description3`] && errors[`description3`]}
                />
              </Grid>
              <Grid item xs={6}>
                <FCTextField
                  fullWidth
                  label={i18n.t('apps.utils.appFormCms.subtitle2')}
                  placeholder={i18n.t('apps.utils.appFormCms.subtitle2')}
                  {...getFieldProps(`title4`)}
                  error={Boolean(touched[`title4`] && errors[`title4`])}
                  helperText={touched[`title4`] && errors[`title4`]}
                />
              </Grid>
              <Grid item xs={6}>
                <FCTextField
                  fullWidth
                  multiline
                  rows={5}
                  label={i18n.t('apps.utils.appFormCms.description4')}
                  placeholder={i18n.t('apps.utils.appFormCms.description4')}
                  {...getFieldProps(`description4`)}
                  error={Boolean(touched[`description4`] && errors[`description4`])}
                  helperText={touched[`description4`] && errors[`description4`]}
                />
              </Grid>
              <Grid item xs={6}>
                <FCTextField
                  fullWidth
                  label={i18n.t('apps.utils.appFormCms.subtitle3')}
                  placeholder={i18n.t('apps.utils.appFormCms.subtitle3')}
                  {...getFieldProps(`title5`)}
                  error={Boolean(touched[`title5`] && errors[`title5`])}
                  helperText={touched[`title5`] && errors[`title5`]}
                />
              </Grid>
              <Grid item xs={6}>
                <FCTextField
                  fullWidth
                  multiline
                  rows={5}
                  label={i18n.t('apps.utils.appFormCms.description5')}
                  placeholder={i18n.t('apps.utils.appFormCms.description5')}
                  {...getFieldProps(`description5`)}
                  error={Boolean(touched[`description5`] && errors[`description5`])}
                  helperText={touched[`description5`] && errors[`description5`]}
                />
              </Grid>
            </Grid>
            {/* <Button variant="contained" sx={{mt: "1rem"}} className='btn-action' onClick={() => setDescriptionComponents([...descriptionComponents, <DescriptionComponent index={descriptionComponents.length} formik={formik}/>])}>
              {{i18n.t('apps.utils.appFormCms.addDescription')}}
            </Button> */}
          </Paper>
        </Form>
      </FormikProvider>
    </Box>
  )
}

const mapStateToProps = (state) => ({
  app: state.app.app
});

export default connect(mapStateToProps, actions)(AppFormCms);