import { Box, CircularProgress, Drawer, InputAdornment, TextField, Typography } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'
import CloseIcon from '@mui/icons-material/Close';
import { i18n } from '../../../translate/i18n';
import SearchIcon from '@mui/icons-material/Search';
import * as actions from "../../../store/actions/action";
import { debounce } from "lodash";
import { connect } from 'react-redux';
import ActionCard from '../../../pages/Sprint/Strategy/ActionCard';
import { FCTextField } from 'components-fullcomm';

const ActionsDrawer = ({ open, setOpen, customer , getActions, selectedAction, setSelectedAction}) => {
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);
  const [actions, setActions] = useState([])

  const debouncedGetActions = useCallback(
      debounce(async (customerId, name) => {
        setLoading(true);
        const res = await getActions(`customerId=${customerId}&name=${name}&limit=10`, false);
        setActions(res?.docs || []);
        setLoading(false);
    }, 500),
    []
  );

  const handleClick = (action) => {
    if (selectedAction?._id !== action._id) {
      setSelectedAction(actions?.find((a) => a._id === action._id));
      setOpen(false);
    }
    else setSelectedAction(null);
  };

  useEffect(() => {
    debouncedGetActions(customer, search);
  }, [search, customer]);

  return (
    <Drawer
      anchor='left'
      open={open}
      onClose={() => setOpen(false)}
    >
      <Box sx={{ width: isMobile ? '90vw' : "350px" }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '10px' }}>
          <span/>
          <Typography variant='h5'>{i18n.t('components.chat.chatInput.addActions')}</Typography>
          <CloseIcon sx={{ cursor: 'pointer' }} color='backlog' onClick={() => setOpen(false)} />
        </Box>
        <hr/>
        <Box sx={{ padding: '10px' }}>
          <FCTextField
            sx={{
              marginBottom: '1rem',
            }}
            fullWidth 
            placeholder={i18n.t('components.chat.chatHeaderDrawer.search')}
            onChange={(e) => setSearch(e.target.value)} 
            value={search} 
            searchIcon
            />
          { loading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
              <CircularProgress />
            </Box>
          ) : 
            actions?.slice(0, 10)?.map((action, index) => (
              <Box 
                key={action?._id}
                sx={{
                  marginBottom: '1.25rem',
                  '& > *:first-child': {
                    cursor: 'pointer !important',
                    border:selectedAction?._id === action._id ? "1px solid #00D6CF" : '0px 0px 2px 0px', 
                    boxShadow:'0px 4px 4px 0px #00000026',
                    '&:hover': {
                      background: '#F3F3F3',
                    }
                  }
                }}
                >
                <ActionCard 
                  key={index} 
                  action={action}
                  handleClick={handleClick}
                />
              </Box>
            ))
          }
        </Box>
      </Box>
    </Drawer>
  )
}

export default connect(null, actions)(ActionsDrawer);