import { Box, Button, Grid, Paper, Slider, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";
import shopify from '../../../../assets/shopify_glyph 1.png'
import google from '../../../../assets/Logo_Google_Analytics 1.png'
import meta from '../../../../assets/Meta_Platforms_Inc._logo 1.png'
import active from '../../../../assets/AC_Glyph_Blue 1.png'
import { mainMetrics } from '../../NewAction/ActionAccordions/ControlMetrics/utils';
import { useField } from 'formik';
import { i18n } from '../../../../translate/i18n';
import { FCSelect, FCTextField } from 'components-fullcomm';

const mainChannels = [
  {
    name: "Shopify",
    img: shopify,
  },
  {
    name: "Google Analytics 4",
    img: google,
  },
  {
    name: "Pixel da Meta",
    img: meta,
  },
  {
    name: "Active Campaign",
    img: active,
  },
];

const marks = [
  { value: 0 },
  { value: 1 },
  { value: 2 },
  { value: 3 },
  { value: 4 },
  { value: 5 },
  { value: 6 },
  { value: 7 },
  { value: 8 },
  { value: 9 },
  { value: 10 },
];

export const handleChannel = (channel) => {
  if (channel?.includes("da")) {
    channel = channel.replace("da", i18n.t("sprint.newAction.pages.analysis.of"))
  }

  return channel
}

export const handleMetric = (metric) => {
  switch (metric) {
    case "Sessões": return i18n.t("sprint.newAction.pages.analysis.sessions");
    case "Taxa de Conversão": return i18n.t("sprint.newAction.pages.analysis.conversionRate");
    case "Ticket Médio": return i18n.t("sprint.newAction.pages.analysis.averageTicket");
    case "Receita": return i18n.t("sprint.newAction.pages.analysis.revenue");
    case "Receita de Novos Clientes": return i18n.t("sprint.newAction.pages.analysis.newCustomersRevenue");
    case "Receita de Clientes Antigos": return i18n.t("sprint.newAction.pages.analysis.oldCustomersRevenue");
    case "Receita Recuperada": return i18n.t("sprint.newAction.pages.analysis.recoveredRevenue");
    case "Customer Lifetime Value": return i18n.t("sprint.newAction.pages.analysis.customerLifetimeValue");
    case "NPS": return i18n.t("sprint.newAction.pages.analysis.nps");
    case "Uso do Cupom": return i18n.t("sprint.newAction.pages.analysis.couponUsage");
    default: return metric
  }
}

const Analisis = ({ getFieldProps, values }) => {
  const [channel, , channelHelper] = useField("channel");
  const [value, setValue] = useState(null)

  const baseSlider = (field) => {
    return (
      <Box display={"flex"} alignItems={"center"} gap={2}>
        <Typography>0</Typography>
        <Slider
          color="terciary"
          {...getFieldProps(field)}
          marks={marks}
          step={1}
          min={0}
          max={10}
          valueLabelDisplay="auto"
        />
        <Typography>10</Typography>
      </Box>
    );
  };

  const copyToClipboard = (text) => {
    navigator.clipboard
      .writeText(text)
  };

  useEffect(() => {
    setValue(mainChannels.find((ch) => ch.name === channel.value));
  }, [channel.value]);

  return (
    <>
      <Typography my="1rem" align="center" variant="h4">{i18n.t("sprint.newAction.pages.analysis.analysis")}</Typography>
      <Box display="flex" flexDirection={isMobile ? "column" : "row"} gap="2rem">
        {
          values.type === "Campanha" && (
            <Box width={isMobile ? "100%" : "50%"} mt="1rem">
              <Paper
                sx={{ display: "flex", flexDirection: "column", gap: "1rem", padding: "1rem", borderRadius: "5px" }}
              >
                <Typography variant="h5" sx={{ marginBottom: '0.5rem' }}>{i18n.t("sprint.newAction.pages.analysis.metricsTitle")}</Typography>
                <Grid container columnSpacing={2} rowSpacing={3}>
                  <Grid item xs={6}>
                    <FCSelect
                      fullWidth
                      size={isMobile ? "small" : "medium"}
                      label={i18n.t("sprint.newAction.pages.analysis.channels")}
                      options={mainChannels.map((channel) => ({ value: channel, label: handleChannel(channel.name), img:channel.img }))}
                      {...getFieldProps("channel")}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FCSelect
                      fullWidth
                      size={isMobile ? "small" : "medium"}
                      label={i18n.t("sprint.newAction.pages.analysis.metrics")}
                      options={mainMetrics.map((option) => ({ value: option, label: handleMetric(option) }))}
                      {...getFieldProps("metric")}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FCTextField
                      size={isMobile ? "small" : "medium"}
                      fullWidth
                      label={i18n.t("sprint.newAction.pages.analysis.utm")}
                      placeholder={i18n.t("sprint.newAction.pages.analysis.utm")}
                      {...getFieldProps("utm")}
                    />
                  </Grid>
                  <Grid item xs={7} md={9} lg={10} xl={10.5} display={"flex"} alignItems='end'>
                    <FCTextField
                      size={isMobile ? "small" : "medium"}
                      fullWidth
                      placeholder={i18n.t("sprint.newAction.pages.analysis.utm")}
                      label={i18n.t("sprint.newAction.pages.analysis.completeUtm")}
                      disabled
                      {...getFieldProps("urlUtm")}
                    />
                  </Grid>
                  <Grid item xs={5} md={3} lg={2} xl={1.5} display={"flex"} alignItems={"end"}>
                    <Button
                      sx={{ textTransform: "none", mb: isMobile ? "0.25rem" : "0.75rem"}}
                      className='btn-gray medium'
                      variant="contained"
                      onClick={() => copyToClipboard(getFieldProps("urlUtm").value)}
                    >
                      <ContentCopyRoundedIcon /> {i18n.t("sprint.newAction.pages.analysis.copy")}
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
            </Box>
          )
        }
        <Box width={(isMobile || values.type === "Melhoria") ? "100%" : "50%"} mt="1rem">
          <Paper
            sx={{ display: "flex", flexDirection: "column", gap: "1rem", padding: "1rem", borderRadius: "5px" }}
          >
            <Typography variant="h5" sx={{ marginBottom: '0.5rem' }}>{i18n.t("sprint.newAction.pages.analysis.prioritization")}</Typography>
            <Grid container columnSpacing={2} rowSpacing={3}>
              <Grid item xs={12}>
                <Typography>{i18n.t("sprint.newAction.pages.analysis.trust")}</Typography>
                {baseSlider("trust")}
              </Grid>
              <Grid item xs={12}>
                <Typography>{i18n.t("sprint.newAction.pages.analysis.impact")}</Typography>
                {baseSlider("impact")}
              </Grid>
              <Grid item xs={12}>
                <Typography>{i18n.t("sprint.newAction.pages.analysis.facility")}</Typography>
                {baseSlider("ease")}
              </Grid>
            </Grid>
          </Paper>
        </Box>
      </Box>
    </>
  )
}

export default Analisis