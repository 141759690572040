import React, { useEffect, useState } from 'react'
import { getViewCustomer, handleAddTrialCustomer } from '../../store/actions/customers';
import { updateTrialUser, viewPerfil  } from '../../store/actions/users';
import { handleLogout, handleShop } from '../../store/actions';
import { connect } from 'react-redux';
import { Modal } from 'reactstrap';
import { Avatar, Box, Button, CircularProgress, Grid, Typography, useMediaQuery } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { LoadingButton } from '@mui/lab';
import { Form, FormikProvider, useFormik } from 'formik';
import { toast } from 'react-toastify';
import { useAuth0 } from '@auth0/auth0-react';
import { i18n } from '../../translate/i18n';
import { getHealthScore } from '../../store/actions/action';
import { getViewContractSpecific } from '../../store/actions/contracts';

const Integrations = (props) => {
  const { logout } = useAuth0();
  const [open, setOpen] = useState(true);
  const [integrations, setIntegrations] = useState([]);
  const isMobileDevice = useMediaQuery('(max-width:600px)');

  useEffect(() => {
    // Add or remove the blur-background class from the body element
    document.body.classList.toggle('blur-background', open);

    // Cleanup: remove the class when the component is unmounted
    return () => {
      document.body.classList.remove('blur-background');
    };
  }, [open]);

  const integrationsCard = (category, options = []) => {
    if (!options.length) return;
    return (
      <Box key={category} mb={"2rem"} maxHeight={"80vh"} overflow={"auto"}>
        <Typography variant="h6" mb={"0.5rem"}>
          {category}
        </Typography>
        {
          options.map((option, index) => {
            return(
              <Box key={index} display={"flex"} justifyContent={"space-between"} mb={"10px"}>
                <Box display={"flex"} alignItems={"center"} gap={"1rem"}>
                  <Avatar src={option.iconUrl} sx={{height: "36px", width: "36px"}}/>
                  <Typography variant="body1">{option.name}</Typography>
                </Box>
                {
                  integrations.some(obj => obj.name === option.name) ? (
                    <Typography variant="body1"><CheckIcon />{i18n.t('dataEnrichmentModal.integrations.integratedService')}</Typography>
                  ) : (
                    <Button 
                      className='btn-default' 
                      onClick={()=>setIntegrations([...integrations, {name: option.name, category}])}
                    >
                      {i18n.t('dataEnrichmentModal.integrations.integrateService')}
                    </Button>
                  )
                }
              </Box>
            )
          })
        }
      </Box>
    )
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
    },
    onSubmit: async (values, { setSubmitting }) => {
      try {
        const data = {
          name: props.name,
          storeUrl: props.storeUrl,
          integrations: integrations
        }
        const customer = await props.addCustomer(data);
        const { _id } = customer.data;
        const user = await props.updateUser({
          _id: props.user?._id, 
          customer: _id,
          name: props.userInfo?.name,
          telephone: props.userInfo?.telephone,
        });
        if (user.error) {
          toast.error(user.error);
          return;
        }
        await props.getUser();
        setOpen(false);
        props.setCurrentPage("");
        toast.success(i18n.t('dataEnrichmentModal.integrations.createdStore'));
        setSubmitting(false);
        props.handleShop(_id)
        props.getHealthScore(props.cycle)
        props.getViewContractSpecific()
        props.getViewCustomer(_id)
      } catch (error) {
        setSubmitting(false);
        toast.error(error.message);
      }
    }
  });

  const { handleSubmit, isSubmitting } = formik;

  return (
    <div className={open ? 'blur-background' : ''}>
      <Modal
        isOpen={open}
        centered={true}
        size="xl"
      >
      <FormikProvider value={formik}>
        <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
          <Grid padding={{xs: "1rem", md:"3.25rem"}} container spacing={"2rem"}>
            <Grid item xs={isMobileDevice ? 12 : 6}>
              <Typography variant="h1" mb={"1rem"}>
                {i18n.t('dataEnrichmentModal.integrations.store')} {props.name}
              </Typography>
              <Typography variant="h4" mb={"1.5rem"}>
                {props.storeUrl}
              </Typography>
              <Typography mb={"2.5rem"} width={{xs: '100%', md:'50%'}}>
                {i18n.t('dataEnrichmentModal.integrations.watchVideo')}
              </Typography>
              <Box display={"flex"} justifyContent={{xs:"center", md:"start"}}>
                <iframe src="https://www.youtube.com/embed/7Q8QXZ8Z5Z4?start=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"/>
              </Box>
            </Grid>
            <Grid item xs={isMobileDevice ? 12 : 6} borderLeft={{xs: 'none', md:"1px solid #D9D9D9"}}>
            {Object.entries(props.integrations).map(([category, items]) => (
              integrationsCard(category, items)
            ))}
            </Grid>
            <Grid item xs={12}>
              <LoadingButton
                sx={{
                  textTransform: 'none',
                }}
                fullWidth 
                variant="contained"
                type="submit"
                loading={isSubmitting}
                loadingIndicator={<CircularProgress size={20} sx={{color: '#fff'}}/>}
              >
                {i18n.t('dataEnrichmentModal.integrations.finishedIntegration')}
              </LoadingButton>
              <Typography 
                align="center"
                mt={"0.5rem"}
                sx={{
                  color: '#919191',
                  cursor: 'pointer', 
                  '&:hover': {
                    textDecoration: 'underline'
                  }
                }}
                onClick={()=>{
                  handleLogout();
                  logout();
                }}
                >
                {i18n.t('dataEnrichmentModal.integrations.continueAfter')}
              </Typography>
            </Grid>
          </Grid>
        </Form>
      </FormikProvider>
    </Modal>
  </div>
  )
}

const mapStateToProps = state =>
({
  user: state.auth.user,
  cycle: state.actions.cycle,
});

const mapDispatchToProps = (dispatch) => {
  return {
    addCustomer: (data) => dispatch(handleAddTrialCustomer(data)),
    updateUser: (data) => dispatch(updateTrialUser(data)),
    getUser: () => dispatch(viewPerfil()),
    handleShop: (shop) => dispatch(handleShop(shop)),
    getHealthScore: (cycle) => dispatch(getHealthScore(cycle)),
    getViewContractSpecific: () => dispatch(getViewContractSpecific()),
    getViewCustomer: (id) => dispatch(getViewCustomer(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Integrations);