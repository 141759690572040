import React, { useCallback, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { connect } from 'react-redux'
import * as actions from '../../../store/actions/contracts'
import { Grid, Paper, Button, Box, Typography, Popover } from '@mui/material';
import { debounce } from 'lodash';
import { toast } from 'react-toastify';
import { userPermissionCheck } from '../utils/userPermissionCheck';
import { isMobile } from 'react-device-detect';
import { i18n } from '../../../translate/i18n';
import { giveSelectTrimesters } from '../utils/HeaderButtons/CycleSelector';
import '../../../styles/contract/index.css'
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded';
import { FCDropdownButton, FCPagination, FCSelect, FCTextField } from 'components-fullcomm';

const ListContracts = (props) => {
    const history = useHistory();
    const cycles = giveSelectTrimesters(new Date())
    const [pageCurrent, setPageCurrent] = useState(1);
    const limit = 12;
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState("");
    const [status, setStatus] = useState("all");
    const [cycle, setCycle] = useState(cycles[2].label)
    const [anchorEl, setAnchorEl] = useState(null);
    const [customers, setCustomers] = useState([]);

    const handleClick = (event, customers) => {
        setAnchorEl(event.currentTarget);
        setCustomers(customers);
      };
    
      const handleClose = () => {
        setAnchorEl(null);
        setCustomers([]);
      };
    
      const open = Boolean(anchorEl);


    const debouncedGetContracts = useCallback(
        debounce(async (data) => await props.getContracts({ ...data }), 500),
        []
    );
    useEffect(() => {
        debouncedGetContracts({ page: pageCurrent, limit, name: search, status, cycle });
    }, [pageCurrent, search, status, cycle]);

    const finishedContract = async (id) => {
        setLoading(true)
        try {

            const result = await props.handleUpdateContract({ _id: id, status: 'finished' })
            if (result.error) {
                if (result.error.message.includes("As lojas")) {
                    result.error.message = result.error.message.replace("As lojas", i18n.t('sprint.contract.list.theStores'));
                    result.error.message = result.error.message.replace("já possuem contrato ativo!", i18n.t('sprint.contract.list.haveContract'));
                }

                if (result.error.message.includes("A loja")) {
                    result.error.message = result.error.message.replace("A loja", i18n.t('sprint.contract.list.theStore'));
                    result.error.message = result.error.message.replace("já possui contrato ativo!", i18n.t('sprint.contract.list.haveContract2'));
                }
                switch (result.error.message) {
                    case "Ocorreu um erro ao atualizar o contrato.": return toast.error(i18n.t('sprint.contract.list.updateError'));
                    case "Erro interno do servidor.": return toast.error(i18n.t('sprint.contract.list.internalError'));
                    default: return toast.error(result.error.message);;
                }
            } else {
                if (result.message === "Contrato atualizado com sucesso!") {
                    toast.success(i18n.t('sprint.contract.list.updateSuccess'));
                } else {
                    toast.success(result.message);
                }

            }
        } catch (error) {
            if (error.error.message === 'Erro interno do servidor.') {
                toast.error(i18n.t('sprint.contract.list.internalError'));
            } else {
                toast.error(error.error.message);
            }
        }
        
        setLoading(false)
        debouncedGetContracts({ pageCurrent, limit });
    }

    const getColourBalance = (data) => {
        if (data > 0) {
          return '#00D6CF';
        } else if (data < 0)  {
          return '#E74C3C';
        } else {
          return '#000000';
        }
      }

    let contracts = [];

    if (props.contracts) {
        contracts = props.contracts.docs
    }

    return (
        <div style={{ padding: isMobile ? '16px 16px 66px 16px' : '16px' }} className={!isMobile & "p-4"} >
            <div className="mr-auto mb-2 w-100">
                <div className='d-flex justify-content-between'>
                    <Typography variant="h4">{i18n.t('sprint.contract.list.contracts')}</Typography>
                    {
                        userPermissionCheck(props.user, ["create_contract"]) && (
                            <Link to={"/add-contract"}>
                                <Button className="btn-action medium">{i18n.t('sprint.contract.list.register')}</Button>
                            </Link>
                        )
                    }
                </div>
                <br />
                <Box display="flex" gap="1rem" alignItems="center" mb="10px">
                    <FCTextField
                        sx={{ width: isMobile ? '44vw' : '290px' }}
                        placeholder={i18n.t('sprint.contract.list.search')}
                        searchIcon
                        onChange={e => setSearch(e.target.value)}
                    />
                    <FCSelect
                        sx={{ width: isMobile ? '44vw' : '140px' }}
                        value={cycle}
                        onChange={(e) => setCycle(e.target.value)}
                        options={cycles.map((cycle) => ({ value: cycle.label, label: cycle.label }))}
                    />
                    <FCSelect
                        sx={{ width: isMobile ? '44vw' : '140px' }}
                        value={status}
                        onChange={(e) => setStatus(e.target.value)}
                        options={[
                            { value: 'all', label: i18n.t('sprint.contract.list.all') },
                            { value: 'active', label: i18n.t('sprint.contract.list.active') },
                            { value: 'finished', label: i18n.t('sprint.contract.list.finished') }
                        ]}
                    />
                </Box>
            </div>
            <Popover
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
            >
                <Box
                    sx={{
                        padding: '1rem',
                        minWidth: '200px',
                    }}
                >
                    {customers.map(elem => <Typography>{elem}</Typography>)}
                </Box>
            </Popover>
            <Grid container spacing={2} marginBottom={'16px'}>
                {contracts?.map((elem) => {
                    const balancePerformance = getColourBalance(elem?.creditsContract?.balancePerformance)
                    const balanceSupport = getColourBalance(elem?.creditsContract?.balanceSupport)
        
                    return (
                        <Grid item xs={12} sm={12} md={6} lg={4}>
                            <Paper elevation={3} style={{borderRadius: '4px', padding: '24px', gap: '24px', height: '100%'}}>
                                <Grid container spacing={2} marginBottom={'24px'}>
                                    <Grid item xs={7}>
                                        <Typography variant="h7">{elem.name}</Typography>
                                        <Typography variant="body2" color="#888888">{i18n.t('sprint.contract.list.cnpj')}: {elem.cnpj}</Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <div>
                                            <Typography variant="subtitle2">
                                                {i18n.t('sprint.contract.list.stores')} 
                                                <ArrowDropDownRoundedIcon 
                                                    sx={{ height: '24px', width: '24px', cursor: 'pointer', color: '#888888'}} 
                                                    onClick={(e) => {handleClick(e, elem.customers?.map(elem => elem.name))}}
                                                />
                                            </Typography>
                                            <Typography variant="body1" color="#888888">
                                                {i18n.t('sprint.contract.list.status')}
                                                <span
                                                    style={{color: elem.status === "active" ? "#00D6CF" : "#2C6ECB"}}
                                                >
                                                    {" "}{elem.status === "active" ? i18n.t('sprint.contract.list.active') : i18n.t('sprint.contract.list.finished')}
                                                </span>
                                            </Typography>
                                        </div>                                   
                                    </Grid>
                                    <Grid item xs={1} display={'flex'} justifyContent={'center'}>
                                        <FCDropdownButton
                                        items={[i18n.t('sprint.contract.list.edit'), elem.status === 'active' ? i18n.t('sprint.contract.list.close') : '']}
                                        itemClickHandlers={[() => history.push(`/edit-contract/${elem._id}`), () => finishedContract(elem._id)]}
                                        /> 
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2}>
                                    <Grid item xs={7} display='flex'>
                                        <div>
                                            <Typography variant="body2" color="888888" marginBottom={'6px'}>{i18n.t('sprint.contract.list.performance')}</Typography>
                                            <Typography variant="body2" marginBottom={'6px'}>
                                                {i18n.t('sprint.contract.list.creditsPerformance')}
                                                {" "}<span style={{color: elem?.creditsContract?.creditsPerformance > 0 ? '#00D6CF' : '#000'}}>{elem?.creditsContract?.creditsPerformance ? elem?.creditsContract?.creditsPerformance : "-"}</span>
                                            </Typography>
                                            <div style={{display: 'flex'}}>
                                            <Typography variant="body2" marginRight="2px">{i18n.t('sprint.contract.list.balancePerformance')} </Typography>
                                            <Typography variant="body2" color={balancePerformance}>{elem?.creditsContract?.balancePerformance ? elem?.creditsContract?.balancePerformance : "-"}</Typography>
                                            </div>
                                        </div>
                                        <div style={{marginLeft: '18px'}}> 
                                            <Typography variant="body2" color="888888" marginBottom={'6px'}>{i18n.t('sprint.contract.list.support')}</Typography>
                                            <Typography variant="body2" marginBottom={'6px'}>{i18n.t('sprint.contract.list.creditsSupport')} 
                                            {" "}<span style={{color: elem?.creditsContract?.creditsSupport > 0 ? '#00D6CF' : '#000'}}>{elem?.creditsContract?.creditsSupport ? elem?.creditsContract?.creditsSupport : "-"}</span>
                                            </Typography>
                                            <div style={{display: 'flex'}}>
                                            <Typography variant="body2" marginRight="2px">{i18n.t('sprint.contract.list.balanceSupport')}</Typography>
                                            <Typography variant="body2" color={balanceSupport} >{elem?.creditsContract?.balanceSupport ? elem?.creditsContract?.balanceSupport : "-"}</Typography>
                                            </div>
                                        </div>
                                    </Grid>
                                    <Grid item xs={5} display={'flex'} alignItems={'center'} >
                                        <div style={{display: 'flex', alignItems: 'center'}}>
                                            <div style={{marginLeft: '8px'}}>
                                                <Typography variant="body2" color="888888">{i18n.t('sprint.contract.list.comissionRate')}</Typography>
                                                <Typography variant="body1" fontSize={'16px'} fontWeight="500">{elem?.commissionRate?.percentComission ? elem?.commissionRate?.percentComission + "%" : "-"}</Typography>
                                            </div>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                    )
                })}
            </Grid>
            {!props.contracts ? "" :
                <FCPagination count={props.contracts.totalPages} page={pageCurrent} onChange={(e, page) => setPageCurrent(page)} />
            }
        </div>
    )
}


const mapStateToProps = state => ({
    user: state.auth.user,
    contracts: state.contract.contracts,
})

export default connect(mapStateToProps, actions)(ListContracts);

