export const  abbreviateNumber = (val, currency = false) => {    
  if (!val) return 0;
  if (isNaN(val)) return val;
  val = parseFloat(val);                  
  if (val > 999999) {
    return currency ? `R$ ${(val / 1000000).toFixed(1).toLocaleString('pt-BR')}M` : (val / 1000000).toFixed(2) + 'M';
  } else if (val > 999) {
      return currency ? `R$ ${(val / 1000).toFixed(1).toLocaleString('pt-BR')}k` :  (val / 1000).toFixed(1) + 'k';
  } else if (val < 10) {
    return currency ? `R$ ${val.toFixed(2).toLocaleString('pt-BR')}` : val.toFixed(1);
  } else {
    return currency ? `R$ ${val.toFixed(2).toLocaleString('pt-BR')}` : val.toFixed(0);
  }
}