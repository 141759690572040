const problems_reducer = (state = {}, actions) => {
  switch (actions.type) {
    case "GET_PROBLEMS":
          return {
              ...state,
              problems: actions.payload
          }
      case "GET_PROBLEM":
          return {
              ...state,
              problemDetails: actions.payload.problem
          }
      case "GET_PROBLEM_VIEW":
          return {
              ...state,
              problemDetailsView: actions.payload.problem
          }
      case "CLEAR_PROBLEM":
          return {
              ...state,
              problemDetails: null
          }
      case "CLEAR_PROBLEMS":
          return {
              ...state,
              problems: null
          }
      default:
          return state;
  }
}

export default problems_reducer;